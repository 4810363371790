<template>
  <div class="shuttleBackBox flex-container" v-loading="!showTree">
    <el-container>
      <CommonTree
        :isShowSearchInput="false"
        ref="commonTree"
        treeTitle="项目架构"
        v-if="showTree"
        :treeData="treeData"
        :urlParmas="{parentId}"
        :handleData="handleData"
        :isShowdig="formType != 'view' && isIndex ?true : false"
        :defaultProps="organizationPersonnelDefaultProps"
        :showCheckbox="false"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="handleNode"
        node-key="id"
      >
        <template slot="header">
          <avue-input-tree size="mini" class="treeHeader" :nodeClick="handleTree"
                           :props="{label:'title',value:'id'}" v-model="parentId" placeholder="请选择内容"
                           :dic="treeDic"></avue-input-tree>
          <!--          <el-select @change="handleTree" style="margin: 14px 10%;width: 80% !important;" v-model="parentId" placeholder="请选择内容">-->
          <!--            <el-option v-for="item in treeDic" :value="item.id" :label="item.title" :key="item.id"></el-option>-->
          <!--          </el-select>-->
        </template>
      </CommonTree>
      <div class="table-box">
        <head-layout
          :showIcon="false"
          head-title="人员管理"
          :head-btn-options="HeadBtnOptions"
          @head-quot="handleSelect"
          @head-import="headImport"
          @head-no-use="handleIsUse(1, 'batch')"
          @head-use="handleIsUse(0, 'batch')"
        ></head-layout>
        <grid-head-layout
          ref="organizationPersonnelGridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="organizationPersonnelGridHeadSearch"
          @grid-head-empty="organizationPersonnelGridHeadEmpty"
        ></grid-head-layout>
        <div class="flex-container eventBox">
          <div class=" cursorPointer flex-container flex-align-c eventItem" v-for="item in jsonList">
            <span>{{ item.label }}：</span>
            <span>{{ countObj[item.key] }}</span>
          </div>
        </div>
        <grid-layout
          :page="page"
          @grid-row-detail-click="rowUserView($event,'view')"
          :data-total="page.total"
          @page-current-change="getPrjstructureuserList"
          @page-size-change="getPrjstructureuserList"
          ref="gridLayOut"
          :table-options="corePersonnelTableOption"
          :table-data="organizationPersonnelTableData"
          :table-loading="tableLoading"
          @gird-handle-select-click="selectionChange"
          class="itemListGridNoPage"
        >
          <template slot="baseCode" slot-scope="{ row }">
            <el-image :preview-src-list="[formatter(row)]" style="padding-top:10px;width: 40px;"
                      :src="formatter(row)"></el-image>
          </template>
          <template slot="isUsed" slot-scope="{row}">
            <el-tag effect="dark" :type="isUsedDict[row.isUsed]">{{ row.$isUsed }}</el-tag>
          </template>
          <template #customBtn="{ row ,$index}" v-if="formType != 'view'">
            <sy-button :row="row" :index="$index" class="tableBorder flex-jus-c flex-wrap-w"
                       :button-list="btnFun(row)"></sy-button>
          </template>
        </grid-layout>
      </div>
    </el-container>
    <!-- 人员选择弹窗  -->
    <el-dialog
      title="人员选择"
      :visible.sync="deptShow"
      v-if="deptShow"
      width="80%"
      top="8vh">
      <UserDeptMultipleDialog
        @closeDia="deptShow=false"
        :dataCallBack="dataCallBack"
        closeCallBack
        :treeParams="{
          deptCategory:'2',
          parentId,ancestorsFlg:true
        }"
        ref="UserDeptMultipleDialog"
        @select-all-data="handleUserSelectConfirm"
      ></UserDeptMultipleDialog>
    </el-dialog>
    <!--    <user-select-->
    <!--      :params="treeNode.thirdFlg == 1 ? {userType:'3'} : {}"-->
    <!--      ref="userSelect"-->
    <!--      @callBack="handleUserSelectConfirm"-->
    <!--    ></user-select>-->
    <!-- 组织人员新增 -->
    <addOrganizationPersonnel
      ref="addOrganizationPersonnel"
      @callback="$refs.commonTree.getTreeData()"
    >
    </addOrganizationPersonnel>
    <el-dialog title="用户管理编辑"
               v-dialogDrag
               v-if="userShow"
               :visible.sync="userShow"
               width="60%">
      <el-table
        :data="userForm"
        border
        stripe
        size="medium"
        :height="300"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="50">
        </el-table-column>
        <el-table-column prop="userName" width="150" label="人员名称"></el-table-column>
        <el-table-column prop="deptName" label="组织名称" width="200">
          <template slot-scope="{row}">
            <avue-input-tree v-model="row.deptId" placeholder="请选择组织名称" :props="{ label: 'title',value: 'id' }"
                             type="tree" :dic="filterData"></avue-input-tree>
          </template>
        </el-table-column>
        <el-table-column
          label="角色"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row['roleCheck' + index]"
                         v-for="(item,index) in userListData" :key="index"
                         @change="checkData(scope,item)">{{ item.roleName }}
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="stPostId" label="工种" width="200">
          <template slot-scope="{row}">
            <avue-input-tree :parent="false" :props="{ label: 'postName',value: 'id' }" clearable v-model="row.stPostId"
                             placeholder="请选择工种" :dic="postList">
            </avue-input-tree>
          </template>
        </el-table-column>
      </el-table>
      <div class="avue-dialog__footer" slot="footer">
        <el-button @click="userShow=false">取消</el-button>
        <el-button @click="handleSaveRole()" type="primary">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="相关方人员导入"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          :upload-after="uploadAfter"
          v-if="excelBox"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{
                $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
    <OpenBlackDialog ref="openBlackDialog" :callback="getPrjstructureuserList"></OpenBlackDialog>
  </div>
</template>
<script>
import {
  prjthirduserPage, prjthirduserDetail, prjthirduserSubmit,
  prjthirduserRemove, prjthirdcompanyRemove, userCountByType, stpostList1
} from "@/api/safe/itemList";
import {dateFormat} from "@/util/date";
import addOrganizationPersonnel from "./addOrganizationPersonnel";
import CommonTree from "@/views/components/com_tree_new/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {getDeptTree, remove} from "@/api/system/dept";
import {
  prjthirduserBatchSubmit
} from "@/api/safe/itemList";
import {roleGetList} from "@/api/system/user";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";
import organization from "@/views/business/safe/itemList/organization";
import {mapGetters} from "vuex";
import {exportBlob} from "@/api/common";
import {downloadXls} from "@/util/util";
import OpenBlackDialog from "./openBlackDialog.vue";

export default {
  mixins: [organization],
  props: {
    isIndex: {
      type: Boolean,
      default: true,
    },
    roleId: {
      // 角色id 区过滤已绑定的人
      type: String,
      default: "",
    },
  },
  components: {
    OpenBlackDialog,
    UserDeptMultipleDialog,
    addOrganizationPersonnel,
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  name: "人员管理",
  keep:true,
  data() {
    return {
      parentId: '',
      treeDic: [],
      treeData: [],
      excelBox: false,
      showTree: false,
      postList: [],
      btnList: [
        {
          label: (row) => row.isUsed == 1 ? "入场" : "离场",
          handle: (row, index) => {
            this.handleIsUse(row.isUsed == 1 ? 0 : 1, 'only', row)
          }
        },
        {
          label: '删除',
          show:(row)=>row.isUsed == 1,
          handle: (row, index) => {
            this.rowdel(row)
          }
        },
        {
          label: '编辑',
          handle: (row, index) => {
            // this.getRole(row)
            this.rowUserView(row,'edit')
          }
        },
      ],
      countObj: {},
      jsonList: [
        {label: '总组织数', key: 'deptCountAll'},
        {label: '相关方组织总数', key: 'deptCountThird'},
        {label: '项目总人数', key: 'userCountAll'},
        {label: '相关方总人数', key: 'userCountThird'},
      ],
      isUsedDict: {
        '0': 'success',
        '1': 'info',
        '2': '',
        '3': 'danger',
      },
      userForm: {},
      userShow: false,
      deptShow: false,
      userListData: [],
      selectionList: [],
      formType: this.$route.query.type,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      treeNode: "",
      selectNode: "",
      filterData: [],
      organizationPersonnelDefaultProps: {
        children: "children",
        label: "title",
      },
      searchColumns: [
        {prop: "userName", span: 3, placeholder: "请输入人员姓名"},
        // {
        //   prop: "sex", span: 3, placeholder: "请选择性别",
        //   type: 'select',
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
        // },
        {prop: "phone", span: 3, placeholder: "请输入联系电话"},
        // {
        //   prop: "isSpecial", span: 3, placeholder: "特种人员",
        //   type: 'select',
        //   dicData: [
        //     {value: 1, label: '是'},
        //     {value: 0, label: '否'},
        //   ]
        // },
        // {
        //   prop: "trained", span: 4, placeholder: "入场培训",
        //   type: 'select',
        //   dicData: [
        //     {value: true, label: '是'},
        //     {value: false, label: '否'},
        //   ]
        // },
        {
          prop: "isUsed", span: 3,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          type: 'select',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=third_user_status",

          placeholder: "入场状态"
        },
      ],
      organizationPersonnelTableData: [],
      tableLoading: false,
      corePersonnelTableData: [],
      HeadBtnOptions: [
        {
          label: "新增人员",
          emit: "head-add",
          type: "button",
          show: () => this.treeNode.thirdFlg == 1,
          handle: () => {
            this.$router.push({
              path: '/safe/itemList/index/addThirduser',
              query: {type: 'add', deptId: this.treeNode.id, prjId: this.parentId}
            })
          },
        },
        {
          label: "选择人员",
          emit: "head-quot",
          type: "button",
          show: () => this.treeNode.isUsed != 1 && this.formType != 'view' && this.isIndex,
          // btnOptType: 'add',
        },
        {
          label: "导入",
          show: () => this.treeNode.thirdFlg == 1,
          emit: "head-import",
          type: "button",
        }
        // {
        //   label: "批量停用",
        //   emit: "head-no-use",
        //   type: "button",
        //   show: () => this.treeNode.isUsed != 1 && this.formType != 'view' && this.isIndex,
        //   btnOptType: "no-use",
        // },
        // {
        //   label: "批量启用",
        //   emit: "head-use",
        //   type: "button",
        //   show: () => this.treeNode.isUsed != 1 && this.formType != 'view' && this.isIndex,
        //   btnOptType: "use",
        // }
      ],
    };
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    excelOption() {
      return {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.excelFile"),
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            data: {prjId: this.parentId, deptId: this.treeNode.id},
            action: `/api/sinoma-hse-prj/prjthirduser/import`,
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      }
    },
    corePersonnelTableOption(){
      return {
        index: true,
        indexLabel: "序号",
        linklabel:'userName',
        menuWidth: 180,
        selection: this.$route.query.type != "view",
        menu: this.$route.query.type != "view" && this.isIndex,
        column: [
          {
            label: "姓名",
            prop: "userName",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: '人员类别',
            align: "center",
            prop: 'thirdFlg',
            html: true,
            formatter: (row) => {
              return `<span style="color: ${row.thirdFlg == '1' ? 'red' : '#333333'}">${row.thirdFlg == '1' ? '外部人员' : '内部人员'}</span>`
            },
          },
          {
            label: "性别",
            prop: "sex",
            minWidth: 80,
            align: "center",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
          },
          {
            label: "年龄",
            prop: "birthday",
            minWidth: 80,
            align: "center",
            overHidden: true,
            html: true,
            formatter: (row) => {
              let style = this.itemObject(row)
              return `<span style="color:${style.color}">${this.getAge(row.birthday)}</span>`
            },
          },
          {
            label: "联系电话",
            prop: "phone",
            align: "center",
            minWidth: 180,
            overHidden: true,
          },
          {
            label: "岗位",
            prop: "stPostName",
            width: 150,
            align: "center",
            overHidden: true
          },
          {
            label: "角色",
            prop: "roleIds",
            dataType: 'string',
            props: {
              label: "roleName",
              value: "id",
            },
            dicFormatter:(res)=>{
              return res.data.records
            },
            dicUrl: "/api/sinoma-system/role/classificationRole/list?current=1&size=9999&typeCode=PROJECT",
            width: 150,
            align: "center",
            overHidden: true
          },
          {
            label: "黑名单状态",
            prop: "inBlacklist",
            minWidth: 95,
            align: "center",
            formatter: (row, value, label) => {
              return row.inBlacklist ? "是" : "否";
            },
            overHidden: true,
          },
          {
            label: "状态",
            slot: true,
            prop: "isUsed",
            dataType: "number",
            minWidth: 80,
            props: {
              label: "dictValue",
              value: "dictKey",
            },

            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=third_user_status",
            align: "center",
          },
          {
            label: "更新时间",
            prop: "updateTime",
            minWidth: 170,
            align: "center",
            overHidden: true,
          },
        ],
      }
    },
  },
  mounted() {
    this.getTree()

  },
  activated() {
    this.getPrjstructureuserList()
  },
  methods: {
    handleTree(data, node, nodeComp) {

      this.parentId = data.id;
      this.$nextTick(() => {
        this.$refs.commonTree.getTreeData()
      })
    },
    getTree() {
      let parentId = this.userInfo.dept_id
      getDeptTree('', '5,2', parentId).then(res => {
        this.treeDic = this.setDisabled(res.data.data)
        this.parentId = this.treeDic[0].id;
        if (this.treeDic[0].deptCategory == 5) {
          this.treeData = undefined
        }
        this.getCount()
        this.getPost()
        this.showTree = true
      })
    },
    setDisabled(tree) {
      tree.forEach(item => {
        if (item.children) {
          this.setDisabled(item.children)
        }
        item.disabled = item.deptCategory == 5 || item.deptCategory == 3 ? false : true;
      })
      return tree
    },
    handleTemplate() {
      exportBlob(`/api/sinoma-hse-prj/prjthirduser/exportTemplate?prjId=${this.parentId}`).then(
        (res) => {
          downloadXls(res.data, "相关方人员模板.xlsx");
        }
      );
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.page.currentPage = 1;
      this.getPrjstructureuserList();
      done();
    },
    getPost() {
      stpostList1(this.parentId).then(res => {
        this.postList = res.data.data
      })
    },
    getCount() {
      userCountByType({orgId: this.parentId}).then(res => {
        this.countObj = res.data.data
      })
    },
    btnFun(row) {
      return row.thirdFlg == 1 ? this.userBtnOptions : this.btnList
    },
    // 保存
    handleSaveRole(type) {
      // 判断是否选择了角色
      let userForm = this.userForm
      let isEmptyRole = userForm.filter((item) => {
        if (item.roleId.length == 0) {
          return item;
        }
      })
      if (isEmptyRole.length != 0) {
        this.$message.warning('请确保每个部门中至少勾选一个角色');
        return;
      }

      userForm.forEach((item) => {
        item.roleIds = item.roleId.join(',');
        if (Array.isArray(item.roleName)) {
          item.roleName = item.roleName.join(',');
        }
      })
      prjthirduserSubmit(userForm[0]).then((res) => {
        this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
        this.userShow = false
        this.getPrjstructureuserList();
      })
    },
    // 选择角色
    checkData(scope) {
      let indexList = []
      Object.keys(scope.row).forEach(key => {
        if (scope.row[key] === true) {
          indexList.push(key.substring(9))
        }
      });
      scope.row.roleId = [];
      scope.row.roleName = [];
      for (let i = 0; i < this.userListData.length; i++) {
        indexList.forEach(e => {
          if (i.toString() === e) {
            scope.row.roleId.push(this.userListData[i].id)
            scope.row.roleName.push(this.userListData[i].roleName)
          }
        })
      }
    },
    getRole(row) {
      // 角色数据
      roleGetList(1, 500).then((res) => {
        this.userListData = res.data.data.records;
        this.getUserDetail(row);
      })
    },
    // 获取用户信息
    getUserDetail(row) {
      prjthirduserDetail({id: row.id}).then((res) => {
        if (res.data.code == 200) {
          let data = [res.data.data];
          data.forEach((e) => {
            if (e.roleIds.includes(',')) {
              e.roleId = e.roleIds.split(',');
            } else {
              e.roleId = [e.roleIds];
            }
            this.userListData.forEach((item, index) => {
              let checked = 'roleCheck' + index;
              this.$set(e, checked, false)
              e.roleId.forEach((roleId) => {
                if (roleId == item.id) {
                  this.$set(e, checked, true)
                }
              })
            })
          })

          this.userForm = data;
          this.userShow = true
        }
      })
    },
    itemObject(item) {
      let age = this.getAge(item.birthday) || 0
      if (
        (item.sex == 1 && age >= 50) ||
        age >= 60 || age < 18
      ) {
        // (item.sex == 2 && age >= 18 && age < 60)
        return {color: "red"};
      } else {
        return {color: "black"};
      }
    },
    //项目组织架构
    handleData(data) {
      return [
        {
          ...data[0],
          parentId: -1,
          children: data[0]['children']
        }
      ]
    },
    //项目组织架构树点击
    handleNode(data, node) {
      this.treeNode = data;
      this.selectNode = node
      this.page.currentPage = 1;
      this.getPrjstructureuserList();
    },
    //项目组织架构新增
    treeMenuAdd(row) {
      if (!this.treeNode) return this.$message.error('请选择父级在新增信息');
      //
      if (this.formType == 'view' && !this.isIndex) return
      if (this.treeNode.id !== this.parentId) {
        this.$refs.addOrganizationPersonnel.init(
          "add",
          this.parentId,
          {
            node: this.treeNode,
            list: this.organizationPersonnelTreeData,
          }
        );
        return;
      }
      this.$confirm('', '', {
        confirmButtonText: '新增系统组织',
        cancelButtonText: '新增相关方组织',
        center: true,
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
      }).then((attch) => {
        this.$refs.addOrganizationPersonnel.init(
          "add",
          this.parentId,
          {
            node: this.treeNode,
            list: this.organizationPersonnelTreeData
          }
        );
      }).catch((attch) => {
        if (attch == 'close') return
        this.$router.push({
          path: '/safe/itemList/index/addThirdPartyOrganization',
          query: {type: 'add', prjId: this.parentId}
        })
      });

    },
    //项目组织架构编辑
    treeMenuEdit(row) {
      if (this.treeNode.parentId == -1) return this.$message.error('根节点不允许编辑')
      if (this.formType == 'view' && !this.isIndex) return
      if (!this.treeNode.id) return this.$message.warning('请选择项目组织！');
      if (row.thirdFlg == 1 && this.selectNode.parent.data.thirdFlg == 0) {
        this.$router.push({path: '/safe/itemList/index/addThirdPartyOrganization', query: {type: 'edit', id: row.id}})
      } else {
        this.$refs.addOrganizationPersonnel.init(
          "edit",
          this.treeNode,
          {
            list: this.organizationPersonnelTreeData
          }
        );
      }
    },
    //项目组织架构删除
    treeNodeDel(data) {
      if (this.treeNode.parentId == -1) return this.$message.error('根节点不允许删除')
      if (this.formType == 'view' && !this.isIndex) return
      this.$confirm("此操作将永久删除该项目组织, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.thirdFlg == 1) {
            return prjthirdcompanyRemove({ids: data.id, prjId: this.parentId,})
          } else {
            return remove(data.id)
          }
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }
          this.$refs.commonTree.getTreeData();
        });
    },
    //批量选择
    selectionChange(list) {
      this.selectionList = list;
      if (!this.isIndex) {
        // 项目角色授权 选择人员 弹框进来
        this.$emit("selectOPList", this.selectionList);
      }
    },
    headImport() {
      if (!this.treeNode) return this.$message.warning('请选择项目组织')
      this.excelBox = true;
    },
    //选择人员
    handleSelect() {
      if (!this.treeNode) return this.$message.warning('请选择项目组织')
      this.deptShow = true
    },
    dataCallBack(data) {
      return data
      // return data.filter((item) => {
      //   let age = this.getAge(item.birthday)
      //   return !((item.sex == 1 && age >= 50) || age >= 60 || age < 18);
      // })
    },
    //批量添加人员
    handleUserSelectConfirm(data) {
      if (data.selectAllData.length) {
        let exceed = false, userName = [];
        let queryData = data.selectAllData.map((item) => {
          // let age = this.getAge(item.birthday);
          // if (age < 18 || age > 60 || (item.sex == 1 && age > 50)) {
          //   exceed = true;
          //   userName.push(item.name)
          // }
          return {
            sex: item.sex,
            phone: item.phone,
            account: item.account,
            prjId: this.parentId,
            thirdFlg: item.userType == 3 ? '1' : '0',
            deptId: this.treeNode.id,
            birthday: item.birthday ? dateFormat(new Date(item.birthday), "yyyy-MM-dd") || "" : null,
            userId: item.id,
            userName: item.realName,
            stPostId: item.postId,
            stPostName: item.postName,
            isUsed: '0',
          };
        });
        console.log(data);
        // if (exceed) {
        //   this.$message.error(`(${userName.join(',')})等人员小于18岁大于60岁（女性大于50岁）不予录用`);
        // } else {
        this.prjstructureuserSubmitSave(queryData, data);
        // }
      }
    },
    //批量启用/停用
    handleIsUse(status, type, data) {
      let queryData = [];
      if (type != "only") {
        if (!this.selectionList.length) {
          this.$message({
            type: "warning",
            message: "请先选择需要操作的数据！",
          });
          return;
        } else {
          queryData = this.selectionList.map((item) => {
            return {
              ...item,
              prjId: this.parentId,
              createBaseCode: 'no',
              id: item.id,
              isUsed: status,
            };
          });
        }
      } else {
        queryData.push({
          ...data,
          prjId: this.parentId,
          createBaseCode: 'no',
          id: data.id,
          isUsed: status,
        });
      }
      let title = status === 0 ? "确认入场当前人？" : "确认离场当前人？";
      this.$confirm(title, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.prjstructureuserSubmitSave(queryData);
        })
        .catch(() => {
        });
    },
    //组织人员
    prjstructureuserSubmitSave(list, data) {
      prjthirduserBatchSubmit(list).then((res) => {
        if (res.data.code === 200) {
          this.$message.success('操作成功');
          // this.$refs.userSelect.closeDialog();
          data?.done()
          // TODO: 这里记得加关闭弹窗回调
          this.getPrjstructureuserList();
        }
      }).catch(err => {
        data?.loading()
      });
    },
    // 组织人员列表筛选条件
    // 搜索
    organizationPersonnelGridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.getPrjstructureuserList(this.page, searchForm);
    },
    // 清空
    organizationPersonnelGridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.getPrjstructureuserList(this.page, {});
    },
    removeEmptyValues(obj) {
      for (const key in obj) {
        if (!obj[key]) {
          delete obj[key];
        } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
      return obj;
    },
    //项目组织架构人员
    getPrjstructureuserList(page, params = {}) {
      this.page = page || this.page;
      let obj = {
        prjId: this.parentId,
        deptId: this.treeNode.id,
        current: this.page.currentPage,
        size: this.page.pageSize,
        ...params,
      };
      // 项目角色授权新增人员时候需要排除已绑定过的人
      if (!this.isIndex) {
        obj.excludeRoleId = this.roleId;
      }
      this.tableLoading = true
      prjthirduserPage(this.removeEmptyValues(obj)).then((res) => {
        this.organizationPersonnelTableData = res.data.data.records;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = res.data.data.total;
        this.tableLoading = false
        this.getCount()
      });
    },
    //列表删除
    rowdel(data) {
      this.$confirm("是否确认删除此数据?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
        .then(() => {
          return prjthirduserRemove({ids: data.id})
        }).then(() => {
        this.$message.success('操作成功');
        this.getPrjstructureuserList();
      })
        .catch(() => {
        });
    },
    // 相关方组织打开黑名单 / 相关方人员打开黑名单弹窗
    openBlackList(row, type) {
      if (row.id && type) {
        row.prjCode = this.userInfo.prjCode;
        this.$refs.openBlackDialog.init(type, row);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-box {
  margin-top: 5px;
  margin-bottom: 5px;

  .box-card {
    background: rgb(25, 144, 255);
    height: 100px;
    font-size: 18px;
    color: #FFFFFF;
    width: calc(25% - 20px);

    .margin-t-10 {
      margin-top: 10px;
    }
  }
}
.eventBox{
  margin-left: 12px;
  .eventItem{
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 0 8px;
    height: 26px;
    border-radius: 4px;
    background: rgb(255, 255, 255);
    //border: 1px solid #DCDFE6;
    color: #333;
    font-size: 12px;
  }
}

.treeHeader{
  margin: 14px 12px;
  width: calc(100% - 24px)!important;
}
.shuttleBackBox {
  width: 100%;
  justify-content: space-between;

  .table-box {
    background-color: #FFFFFF;
    width: calc(100% - 280px);
  }
}
</style>
