<template>
  <div style="position: relative" class="flex-container">
    <!-- <CommonTree
      ref="commonTree"
      treeTitle="项目架构"
      :treeData="treeData"
      :defaultProps="Props"
      :showCheckbox="false"
      @getNodeClick="handleNode"
      node-key="id"
    >
    </CommonTree> -->
    <CommonTreeNew
      ref="commonTree"
      treeTitle="项目架构"
      :defaultProps="Props"
      :showCheckbox="false"
      @getNodeClick="handleNode"
      :urlParmas="{
        tenantId: '',
        deptCategory: '',
        parentId: userInfo.deptId,
      }"
      node-key="id"
      @getTreeData="commonTreeData"
    ></CommonTreeNew>
    <div class="flex-one" style="background-color: #fff">
      <head-layout
        head-title="应急演练"
        :head-btn-options="headBtnOptions"
        @head-add="handleAdd"
        @head-delete="handleDelete"
      ></head-layout>
      <!-- 头部筛选条件 -->
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowView(row)"
            v-if="row.erStatus && row.erStatus == 'FINISHED'"
            >查看
          </el-button>
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowEdit(row)"
            v-if="
              row.erStatus &&
              row.erStatus == 'PREPARE' &&
              row.createUser == userInfo.user_id
            "
            >编辑
          </el-button>
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowDel(row)"
            v-if="
              row.erStatus &&
              row.erStatus == 'PREPARE' &&
              row.createUser == userInfo.user_id
            "
            >删除
          </el-button>
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowExecute(row)"
            v-if="row.erStatus && row.erStatus == 'APPROVING'"
            >执行
          </el-button>
        </template>
      </grid-layout>
    </div>
  </div>
</template>

    <script>
import {
  erexercisePage,
  erexerciseRemove,
} from "@/api/contingency/emergencyDrill";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { exportBlob } from "@/api/common";
import { dateNow } from "@/util/date";
import { getToken } from "@/util/auth";
import { downloadXls } from "@/util/util";
import CommonTree from "@/views/components/com_tree/index.vue";
import { getDeptTree } from "@/api/system/dept";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      treeNode: {},
      treeData: [],
      Props: {
        children: "children",
        label: "title",
      },
      form: {},
      query: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selectable: (row, index) => {
          return (
            row.createUser == this.userInfo.user_id &&
            row.erStatus != "FINISHED"
          );
        },
        menuWidth: "130",
        menuFixed: "right",
        linklabel: "exeCode",
        column: [
          {
            label: "计划编号",
            prop: "exeCode",
            align: "center",
          },
          {
            label: "计划名称",
            prop: "exeName",
            align: "left",
            overHidden: true,
          },
          {
            label: "预案类型",
            prop: "planType",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=er_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "预案名称",
            prop: "planName",
            align: "center",
          },
          {
            label: "计划开始日期",
            prop: "startDate",
            align: "center",
          },
          {
            label: "计划结束日期",
            prop: "endDate",
            align: "center",
          },
          {
            label: "状态",
            prop: "erStatus",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=ER_DRILL_STATUS",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "实际执行日期",
            prop: "exeDate",
            align: "center",
          },
        ],
      },
      searchColumns: [
        {
          prop: "exeCode",
          span: 3,
          placeholder: "请输入计划编号",
        },
        {
          prop: "exeName",
          span: 3,
          placeholder: "请输入计划名称",
        },
        {
          span: 3,
          prop: "planType",
          type: "select",
          dataType: "string",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=er_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: "请选择预案类型",
        },
        {
          prop: "planName",
          span: 3,
          type: "input",
          placeholder: "请输入预案名称",
        },
        {
          span: 3,
          prop: "erStatus",
          type: "select",
          dataType: "string",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ER_DRILL_STATUS",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: "请选择状态",
        },
        {
          prop: "startToEndTime",
          span: 4,
          type: "daterange",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          startPlaceholder: "开始时间",
          endPlaceholder: "结束时间",
        },
      ],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.erplan_add, false),
        viewBtn: this.vaildData(this.permission.erplan_view, false),
        delBtn: this.vaildData(this.permission.erplan_delete, false),
        editBtn: this.vaildData(this.permission.erplan_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        label: "新增",
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: "add",
      });
      buttonBtn.push({
        label: "删除",
        emit: "head-delete",
        type: "button",
        icon: "",
      });
      return buttonBtn;
    },
  },
  mounted() {
    // this.getPrjstructureList()
  },
  methods: {
    //项目组织架构
    getPrjstructureList() {
      const parentId = this.userInfo.dept_id;
      getDeptTree("", "", parentId).then((res) => {
        if ((res.data.code = 200)) {
          this.treeData = res.data.data;
          this.$refs.commonTree.setCurrentKey(this.treeData[0].id);
          this.$refs.commonTree.nodeClick(this.treeData[0], {});
        }
      });
    },
    handleNode(node) {
      this.treeNode = node;
      this.onLoad(this.page, {});
    },
    //   新增
    handleAdd() {
      this.$router.push({
        path: `/business/contingency/emergencyDrill/add`,
        query: {
          type: "add",
        },
      });
    },
    // 全局删除
    handleDelete() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erexerciseRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/business/contingency/emergencyDrill/edit`,
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    //查看
    rowView(row) {
      this.$router.push({
        path: `/business/contingency/emergencyDrill/view`,
        query: {
          type: "view",
          id: row.id,
        },
      });
    },
    // 单个删除
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erexerciseRemove(row.id);
        })
        .then(() => {
          this.gridHeadSearch({});
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 执行
    rowExecute(row) {
      this.$router.push({
        path: `/business/contingency/emergencyDrill/edit`,
        query: {
          type: "execute",
          id: row.id,
        },
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, {});
    },
    // 多选
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.gridHeadLayout.toggleSelection();
    },
    // 页码--第几页
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    // 页码--多少页
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    // 请求列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      if (
        this.$refs.gridHeadLayout.searchForm.startToEndTime &&
        this.$refs.gridHeadLayout.searchForm.startToEndTime.length != 0
      ) {
        this.$refs.gridHeadLayout.searchForm.startDate =
          this.$refs.gridHeadLayout.searchForm.startToEndTime[0];
        this.$refs.gridHeadLayout.searchForm.endDate =
          this.$refs.gridHeadLayout.searchForm.startToEndTime[1];
      } else {
        delete this.$refs.gridHeadLayout.searchForm.startDate;
        delete this.$refs.gridHeadLayout.searchForm.endDate;
      }
      let searchobj = {
        startDate: this.$refs.gridHeadLayout.searchForm.startDate,
        endDate: this.$refs.gridHeadLayout.searchForm.endDate,
        erStatus: this.$refs.gridHeadLayout.searchForm.erStatus,
        planName: this.$refs.gridHeadLayout.searchForm.planName,
        exeCode: this.$refs.gridHeadLayout.searchForm.exeCode,
        planType: this.$refs.gridHeadLayout.searchForm.planType,
        exeName: this.$refs.gridHeadLayout.searchForm.exeName,
      };
      erexercisePage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, searchobj, { orgId: this.treeNode.id })
      )
        .then((res) => {
          const data = res.data.data;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
  },
};
</script>

    <style>
</style>
