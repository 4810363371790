var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c("el-form-item", { attrs: { label: "计算公式" } }, [
        _c(
          "div",
          {
            staticClass: "pane-calc-preview",
            on: {
              click: function ($event) {
                _vm.expDialogVisible = true
              },
            },
          },
          [
            _vm.activeData.expression.length
              ? _vm._l(_vm.activeData.expression, function (item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: {
                        "calc-btn": typeof item !== "string",
                        error:
                          typeof item !== "string" && item.label === "无效的值",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(typeof item !== "string" ? item.label : item) +
                          "\n        "
                      ),
                    ]
                  )
                })
              : _c("span", { staticClass: "placeholder-text" }, [
                  _vm._v("编辑计算公式"),
                ]),
          ],
          2
        ),
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "是否隐藏" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.noShow,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "noShow", $$v)
              },
              expression: "activeData.__config__.noShow",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center",
          attrs: {
            title: "编辑计算公式",
            "custom-class": "calc-dialog",
            visible: _vm.expDialogVisible,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.expDialogVisible = $event
            },
            open: _vm.onOpen,
          },
        },
        [
          _c("div", { staticClass: "calc-box" }, [
            _c(
              "div",
              { staticClass: "calc-preview", class: { error: !_vm.expValid } },
              [
                _vm._v("\n        计算公式 =\n        "),
                _vm._l(_vm.expressionTemp, function (item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: {
                        "calc-btn": typeof item !== "string",
                        error:
                          typeof item !== "string" && item.label === "无效的值",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(typeof item !== "string" ? item.label : item) +
                          "\n        "
                      ),
                    ]
                  )
                }),
                _c(
                  "div",
                  { staticClass: "preview-actions" },
                  [
                    _c("el-tooltip", { attrs: { content: "删除" } }, [
                      _c("i", {
                        staticClass: "el-icon-price-tag",
                        staticStyle: { transform: "rotate(-90deg)" },
                        on: {
                          click: function ($event) {
                            return _vm.expressionTemp.pop()
                          },
                        },
                      }),
                    ]),
                    _c("el-tooltip", { attrs: { content: "清空" } }, [
                      _c("i", {
                        staticClass: "el-icon-delete",
                        on: {
                          click: function ($event) {
                            _vm.expressionTemp = []
                          },
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("div", { staticClass: "calc-tip" }, [
              _vm._v(
                "\n        编辑计算公式可用来完成审批单内数据的自动结算，例如：采购单内设置计算公式“合计=单价×数量”，发起人填写单价、数量后，组件将自动计算出合计金额，免手动计算\n      "
              ),
            ]),
            _c(
              "div",
              [
                _c("span", [_vm._v("计算对象：")]),
                _vm.calculateCmps.length
                  ? _vm._l(_vm.calculateCmps, function (item) {
                      return _c(
                        "span",
                        {
                          key: item.__vModel__,
                          staticClass: "calc-btn",
                          on: {
                            click: function ($event) {
                              return _vm.expressionTemp.push(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          ),
                        ]
                      )
                    })
                  : _c("span", { staticStyle: { color: "#aaa" } }, [
                      _vm._v("暂无数据"),
                    ]),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "comBtn", staticStyle: { margin: "10px 0" } },
              [
                _c("span", [_vm._v("计算符号：")]),
                _vm._l(["+", "-", "×", "÷", "(", ")"], function (item) {
                  return _c(
                    "span",
                    {
                      key: item,
                      staticClass: "calc-btn",
                      on: {
                        click: function ($event) {
                          return _vm.expressionTemp.push(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item))]
                  )
                }),
              ],
              2
            ),
            _c("div", { staticStyle: { margin: "10px 0" } }, [
              _c("span", { staticStyle: { float: "left" } }, [
                _vm._v("数字键盘："),
              ]),
              _c(
                "div",
                { staticClass: "numBtn comBtn" },
                _vm._l(
                  ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "."],
                  function (item) {
                    return _c(
                      "span",
                      {
                        key: item,
                        staticClass: "calc-btn",
                        on: {
                          click: function ($event) {
                            return _vm.expressionTemp.push(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item))]
                    )
                  }
                ),
                0
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.expDialogVisible = false
                      _vm.expressionTemp = []
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.checkExpression },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }