<template>
  <div>
    <head-layout
      :head-title="$t('cip.qm.business.qm.sample.field.picture')"
      :head-btn-options="headBtnOptions"
      @head-add-tabs="headAddTabs"
      @head-remove="headRemove"
    ></head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      @gird-handle-select-click="selectionChange"
      @row-remove="rowRemove"
      @row-edit="rowEdit"
      @grid-row-detail-click="rowDetail"
    >
    </grid-layout>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="60%">
      <form-layout
        ref="formLayout"
        v-if="formShow"
        :column="formColumn"
        :dataForm="dataForm"
        v-loading="loading"
      ></form-layout>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose"> {{ $t("cip.cmn.btn.celBtn") }}</el-button>
        <el-button type="primary" v-if="type!='view'" @click="submitForm">{{ $t("cip.cmn.btn.defBtn") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

export default {
  props: {
    mainForm: {
      type: Object,
      default: {},
    },
    type: {
      type: Object,
      default: "view"
    },
  },
  data() {
    return {
      tableLoading: false,
      dialogVisible: false,
      loading: false,
      formShow: false,
      dialogTitle: '',
      dataForm: {},
      selectionList: [],
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      tableData: []
    }
  },
  components: {
    GridLayout
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    formColumn() {
      return [
        {
          label: this.$t("cip.qm.business.qm.sample.field.pictureType"),
          labelWidth: 100,
          prop: "picType",
          type: 'select',
          placeholder: this.$t("cip.qm.business.qm.sample.field.afterTip.pictureType"),
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=qc_sample_pic_type",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          rules: [{
            required: true,
            message: this.$t("cip.qm.business.qm.sample.field.afterTip.pictureType"),
            trigger: ["blur", 'change']
          }],
          span: 24
        },
        {
          label: this.$t("cip.qm.business.qm.sample.field.literalness"),
          labelWidth: 100,
          type: "textarea",
          prop: "remark",
          placeholder: this.$t("cip.qm.business.qm.sample.field.afterTip.literalness"),
          rules: [{
            required: true,
            message: this.$t("cip.qm.business.qm.sample.field.afterTip.literalness"),
            trigger: ["blur", 'change']
          }],
          span: 24
        },
        {
          label: this.$t("cip.qm.business.qm.sample.field.pictureUpload"),
          labelWidth: 100,
          listType: 'picture-card',
          type: 'upload',
          propsHttp: {
            res: 'data',
            url: 'link',
            name: 'originalName'
          },
          action: '/api/sinoma-resource/oss/endpoint/put-file',
          prop: "picList",
          placeholder: this.$t("cip.qm.business.qm.sample.field.afterTip.pictureUpload"),
          rules: [{
            required: true,
            message: this.$t("cip.qm.business.qm.sample.field.afterTip.pictureUpload"),
            trigger: ["blur", 'change']
          }],
          span: 24
        }
      ]
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: this.$t("cip.cmn.title.SerialNumber"),
        menuWidth: 200,
        menu: ['add', 'edit','editRecord'].includes(this.type),
        // linklabel: "remark",
        column: [
          {
            label: this.$t("cip.qm.business.qm.sample.field.pictureType"),
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=qc_sample_pic_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            prop: "picType",
            span: 8
          },
          {
            label: this.$t("cip.qm.business.qm.sample.field.literalness"),
            prop: "remark",
            span: 8
          },
          {
            label: this.$t("cip.qm.business.qm.sample.field.picture"),
            prop: "picList",
            span: 8
          }
        ]
      }
    },
    gridRowBtn() {
      if (['add', 'edit','editRecord'].includes(this.type)) {
        return [
          {
            label: this.$t('cip.cmn.btn.editBtn'),
            emit: "row-edit",
            type: "button",
          },
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "row-remove",
            type: "button",
          }
        ]
      }
    },
    headBtnOptions() {
      if (['add', 'edit','editRecord'].includes(this.type)) {
        return [
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            icon: "",
            btnOptType: 'add',
          },
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove",
            type: "button",
            icon: "",
            btnOptType: 'remove',
          }
        ]
      }
    }
  },
  mounted() {
  },
  methods: {
    init(mainForm, type) {
      if(mainForm.sampleCode!=""){
        this.type = type;
        console.log("mainForm",mainForm)
        this.mainForm = mainForm
        this.onload();
      }
    },
    submitForm() {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.dataForm = this.$refs.formLayout.dataForm;
          this.dataForm.picList.forEach((item) => {
            item.attachId = item.label;
            item.picPath = item.value;
          })
          this.dataForm.sampleCode = this.mainForm.sampleCode;
          sampleRecordSub(this.dataForm).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(res.data.msg);
            }
            this.formShow = false;
            this.dialogVisible = false;
            this.onload();
          })
        }
      })
    },
    handleClose() {
      this.formShow = false;
      this.dialogVisible = false;
    },
    headAddTabs() {
      if (!this.mainForm.id) {
        this.$message.error(this.$t('cip.qm.business.qm.sample.field.afterTip.sample'))
        return
      }
      this.formShow = true;
      this.dataForm = {};
      this.dialogVisible = true;
      this.type = "add"
      this.dialogTitle = this.$t(`cip.cmn.btn.addBtn`);
    },
    headRemove() {
      sampleRecordRem(this.ids).then((res) => {
        if (res.data.code == 200) {
          this.onload();
          this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'))
        } else {
          this.$message.error(this.$t('cip.cmn.msg.error.delError'))
        }
      })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    rowRemove(row) {
      sampleRecordRem(row.id).then((res) => {
        if (res.data.code == 200) {
          this.onload();
          this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'))
        } else {
          this.$message.error(this.$t('cip.cmn.msg.error.delError'))
        }
      })
    },
    rowEdit(row) {
      this.formShow = true;
      this.dataForm = row;
      this.dataForm.picList.forEach((item) => {
        item.label = item.attachId;
        item.value = item.picPath;
      })
      this.dialogVisible = true;
      this.type = "edit"
      this.dialogTitle = this.$t(`cip.cmn.btn.editBtn`);
    },
    rowDetail(row) {
      this.formShow = true;
      this.dataForm = row;
      this.dataForm.picList.forEach((item) => {
        item.label = item.attachId;
        item.value = item.picPath;
      })
      this.dialogVisible = true;
      this.type = "eidt"
      this.dialogTitle = this.$t(`cip.cmn.btn.viewBtn`);
    },
    onload() {
      this.tableLoading = true;
      sampleRecordImgList(
        {
          sampleCode: this.mainForm.sampleCode
        }
      ).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.qmSamplePicList;
          this.tableLoading = false;
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .avue-crud .el-table {
  height: calc(100vh - 560px) !important;
  max-height: calc(100vh - 560px) !important;
}
</style>
