var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "areaUserList" },
    [
      _c(
        "el-container",
        { staticClass: "areaUserList_container" },
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "人员清单",
                  showIcon: false,
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-add-tabs": _vm.headAdd,
                  "head-remove": _vm.headRemove,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "grid-row-btn": _vm.gridRowBtn,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-edit": _vm.rowEdit,
                  "row-remove": _vm.rowRemove,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "负责人员",
            "before-close": _vm.handleCancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "avue-form",
            {
              ref: "cedForm",
              attrs: { option: _vm.dialogOption },
              on: { submit: _vm.handleSubmit },
              model: {
                value: _vm.dataForm,
                callback: function ($$v) {
                  _vm.dataForm = $$v
                },
                expression: "dataForm",
              },
            },
            [
              _c(
                "template",
                { slot: "dutyUnit" },
                [
                  _c("el-input", {
                    ref: "dutyUnitInput",
                    attrs: { "suffix-icon": "el-icon-search" },
                    on: {
                      focus: function ($event) {
                        return _vm.handleChangeDept("dutyUnit")
                      },
                    },
                    model: {
                      value: _vm.dataForm.dutyUnitName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "dutyUnitName", $$v)
                      },
                      expression: "dataForm.dutyUnitName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "dutyUser" },
                [
                  _c("el-input", {
                    ref: "dutyUserInput",
                    attrs: { "suffix-icon": "el-icon-search" },
                    on: {
                      focus: function ($event) {
                        return _vm.handleChangeUser("dutyUser")
                      },
                    },
                    model: {
                      value: _vm.dataForm.dutyUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "dutyUserName", $$v)
                      },
                      expression: "dataForm.dutyUserName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "safetyUser" },
                [
                  _c("el-input", {
                    ref: "safetyUserInput",
                    attrs: { "suffix-icon": "el-icon-search" },
                    on: {
                      focus: function ($event) {
                        return _vm.handleChangeUser("safetyUser")
                      },
                    },
                    model: {
                      value: _vm.dataForm.safetyUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "safetyUserName", $$v)
                      },
                      expression: "dataForm.safetyUserName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "manageUser" },
                [
                  _c("el-input", {
                    ref: "manageUserInput",
                    attrs: { "suffix-icon": "el-icon-search" },
                    on: {
                      focus: function ($event) {
                        return _vm.handleChangeUser("manageUser")
                      },
                    },
                    model: {
                      value: _vm.dataForm.manageUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "manageUserName", $$v)
                      },
                      expression: "dataForm.manageUserName",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userShow = $event
            },
          },
        },
        [
          _c("rectifyDataOrg", {
            ref: "rectifyDataOrg",
            attrs: { prjId: _vm.prjId },
            on: { callback: _vm.selectUserData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "部门选择", visible: _vm.deptShow, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            attrs: { deptChild: "" },
            on: { "select-data": _vm.selectDept },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }