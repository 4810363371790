<template>
  <div style="position: relative">
    <div>
      <!-- 黑名单组织 -->
      <head-layout
        head-title="黑名单组织"
        :head-btn-options="headBtnOptions"
        @head-add="handleAdd"
        @head-export="handleExport"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowUpdate(row)"
            >编辑
          </el-button>
        </template>
      </grid-layout>
    </div>
    <blackCompanyDialog
      ref="blackCompanyDialog"
      :isPerson="false"
    ></blackCompanyDialog>
  </div>
</template>

  <script>
import { getList } from "@/api/block/organization";
import { fieldInfo } from "@/api/base/length";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import blackCompanyDialog from "./components/blackCompanyDialog";
import { exportBlob } from "@/api/common";
import { dateNow } from "@/util/date";
import { getToken } from "@/util/auth";
import { downloadXls } from "@/util/util";

export default {
  components: {
    blackCompanyDialog,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      form: {},
      query: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        linklabel: "companyCode",
        column: [
          {
            label: "统一社会信用代码",
            prop: "companyCode",
            align: fieldInfo.USCI.align,
            minWidth: fieldInfo.USCI.length,
            overHidden: true,
          },
          {
            label: "公司名称",
            prop: "companyName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true,
          },
          {
            label: "法定代表人",
            prop: "legalRep",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
          },
          {
            label: "注册地",
            prop: "regLocation",
            align: fieldInfo.fullAddress.align,
            minWidth: fieldInfo.fullAddress.length,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "blState",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=prj_state",
            align: fieldInfo.Words4.align,
            minWidth: fieldInfo.Words4.length,
            overHidden: true,
          },
          {
            label: "成立日期",
            prop: "curDate",
            align: fieldInfo.date.align,
            minWidth: fieldInfo.date.length,
            overHidden: true,
          },
          {
            label: "更新日期",
            prop: "updateTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },
        ],
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "导出",
          emit: "head-export",
          type: "button",
          icon: "",
          btnOptType: "export",
        },
      ],
      searchColumns: [
        {
          label: "项目名称",
          prop: "condition",
          span: 5,
          placeholder: "公司名称/统一社会信用代码/法定代表人",
        },
        {
          label: "更新日期",
          prop: "updateTime",
          span: 4,
          type: "date",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd HH:mm:ss",
          placeholder: "请选择更新日期",
        },
        {
          label: "状态",
          prop: "blState",
          span: 4,
          type: "select",
          dataType: "number",
          placeholder: "请选择状态",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_state`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.thirdcompany_add, false),
        viewBtn: this.vaildData(this.permission.thirdcompany_view, false),
        delBtn: this.vaildData(this.permission.thirdcompany_delete, false),
        editBtn: this.vaildData(this.permission.thirdcompany_edit, false),
      };
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    handleAdd() {
      this.$refs.blackCompanyDialog.init("add");
    },
    rowUpdate(row) {
      this.$router.push({
        path: `/safe/blacklistOrganization/index/add`,
        query: {
          type: "edit",
          companyRow: JSON.stringify(row),
        },
      });
    },
    //查看
    rowView(row) {
      this.$router.push({
        path: `/safe/blacklistOrganization/index/add`,
        query: {
          type: "view",
          companyRow: JSON.stringify(row),
        },
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    // 导出
    handleExport() {
      this.$confirm("是否导出黑名单组织数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        exportBlob(
          `/api/sinoma-hse-prj/prjblacklistorghead/export?condition=${
            this.$refs.gridHeadLayout.searchForm.condition
              ? this.$refs.gridHeadLayout.searchForm.condition
              : ""
          }&updateTime=${
            this.$refs.gridHeadLayout.searchForm.updateTime
              ? this.$refs.gridHeadLayout.searchForm.updateTime
              : ""
          }&${this.website.tokenHeader}=${getToken()}`
        ).then((res) => {
          downloadXls(res.data, `黑名单组织${dateNow()}.xlsx`);
        });
      });
    },
  },
};
</script>

  <style>
</style>
