<template>
  <div>
    <tree-layout :tree-options="treeOption" :tree-data="treeData" @tree-load="treeLoad"></tree-layout>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import {region} from "@/api/system/region"
import TreeLayout from "@/views/components/layout/tree-layout";


export default {
  name: "grid",
  components: {
    TreeLayout,
    GridLayout
  },
  data() {
    return {
      treeOption:{
        nodeKey: 'id',
        lazy: true,
        tip: false,
        simplePage: true,
        searchShow: false,
        searchMenuSpan: 6,
        dialogWidth: "60%",
        tree: true,
        border: true,
        index: true,
        selection: true,
        viewBtn: false,
        menuWidth: 300,
        dialogClickModal: false,
        props: {
          label: 'title',
          value: 'id',
          children: 'children'
        },

      },
      treeData:[],
    };

  },
  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
    this.onLoad({"parentCode":"00"});
  },

  methods: {
    onLoad(params) {
      region(params).then(res => {
        this.treeData = res.data.data;
      });
    },
    treeLoad(tree, treeNode, resolve) {
      const parentId = tree.key;
alert("1");
      region({"parentCode":parentId}).then(res => {
        resolve(res.data.data);
      });
    }
  }
};
</script>
<style>
.el-drawer__body {
  margin-top: 1px;
}
</style>
