<template>
  <div style="position: relative">
    <div>
      <!-- 闸机列表 -->
      <head-layout head-title="摄像头信息" :head-btn-options="headBtnOptions" @head-add="handleAdd" @head-import="handleImport" @head-export="handleExport" @head-user="handleUser"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout class="table-container" ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template #customBtn="{ row }">
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowUpdate(row)">视频播放
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel(row)">解绑
          </el-button>
        </template>
      </grid-layout>
    </div>
  </div>
</template>

<script>
  import {
    getList,
    getDetail,
    add,
    update,
    remove,
    saveBatch
  } from "@/api/safe/gate";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import {
    getAgeFromIdCard
  } from "@/util/util";
  import {
    exportBlob
  } from "@/api/common";
  import {
    dateNow
  } from "@/util/date";
  import {
    getToken
  } from "@/util/auth";
  import {
    downloadXls
  } from "@/util/util";

  export default {
    components: {
      GridLayout,
      HeadLayout,
    },
    props: {
      prjId: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        selectionList: [],
        tableOption: {
          linklabel: "eqtCode",
          reserveSelection:true,
          column: [{
              label: "摄像头编码",
              prop: "eqtCode",
              align: "center",
              overHidden: true,
            },
            {
              label: "摄像头名称",
              prop: "eqtName",
              align: "center",
              overHidden: true,
            },
            {
              label: "外系统编码",
              prop: "eqtType",
              align: "center",
              // dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineEqtType",
            },
            {
              label: "视频流地址",
              prop: "modelSpec",
              align: "center",
              overHidden: true,
            },
            {
              label: "播放地址",
              prop: "manufacturerName",
              align: "center",
              overHidden: true,
            },
            {
              label: "sip用户ID",
              prop: "installSite",
              align: "center",
              overHidden: true,
            },
            {
              label: "安装位置",
              prop: "eqtStatus",
              align: "center",
              // dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineStatus",
            },
            {
              label: "厂商",
              prop: "installSite",
              align: "center",
              overHidden: true,
            },
            {
              label: "外观类型",
              prop: "installSite",
              align: "center",
              overHidden: true,
            },
            {
              label: "信号类型",
              prop: "installSite",
              align: "center",
              overHidden: true,
            },
            {
              label: "支持云控制",
              prop: "installSite",
              align: "center",
              overHidden: true,
            },
            {
              label: "支持高清",
              prop: "installSite",
              align: "center",
              overHidden: true,
            },
            {
              label: "备注",
              prop: "installSite",
              align: "center",
              overHidden: true,
            },
            {
              label: "所属设备",
              prop: "installSite",
              align: "center",
              overHidden: true,
            },

          ],
        },
        headBtnOptions: [{
            label: "选中绑定",
            emit: "head-user",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },
          {
            label: "选中解绑",
            emit: "head-add",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },
          // {
          //   label: "导入",
          //   emit: "head-import",
          //   type: "button",
          //   icon: "",
          //   btnOptType: "import",
          // },
          // {
          //   label: "导出",
          //   emit: "head-export",
          //   type: "button",
          //   icon: "",
          //   btnOptType: "export",
          // },
        ],
        searchColumns: [{
            label: "设备号",
            prop: "eqtCode",
            span: 4,
            placeholder: "请输入设备号",
          },
          {
            label: "设备名称",
            prop: "eqtName",
            span: 4,
            placeholder: "请输入设备名称",
          },
          {
            label: "厂商名称",
            prop: "manufacturerName",
            span: 4,
            placeholder: "请输入厂商名称",
          },
        ],
        tableData: [],
        excelBox: false,
        excelOption: {
          submitBtn: false,
          emptyBtn: false,
          excelForm: {},
          column: [{
              label: "数据导入",
              prop: "excelFile",
              type: "upload",
              drag: true,
              loadText: this.$t("cip.plat.sys.user.field.waiting"),
              span: 24,
              propsHttp: {
                res: "data",
              },
              tip: this.$t("cip.plat.sys.user.field.format"),
              action: "/api/sinoma-hse-prj/thirduser/import",
            },

            {
              label: this.$t("cip.plat.sys.user.field.excelTemplate"),
              prop: "excelTemplate",
              formslot: true,
              span: 24,
            },
          ],
        },
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirduser_add, false),
          viewBtn: this.vaildData(this.permission.thirduser_view, false),
          delBtn: this.vaildData(this.permission.thirduser_delete, false),
          editBtn: this.vaildData(this.permission.thirduser_edit, false),
        };
      },
    },
    watch: {
      "excelForm.isCovered"() {
        if (this.excelForm.isCovered !== "") {
          const column = this.findObject(this.excelOption.column, "excelFile");
          column.action = `/api/sinoma-hse-prj/thirduser/import?isCovered=${this.excelForm.isCovered}`;
        }
      },
    },
    mounted() {
      this.onLoad(this.page, {});
    },
    methods: {
      handleAdd() {
        this.$refs.addDevice.init("add", null, this.prjId);
      },
      rowUpdate(row) {
        this.$refs.addDevice.init("edit", row, this.prjId);
      },
      //查看
      rowView(row) {
        this.$refs.addDevice.init("view", row, this.prjId);
      },
      // 多选
      selectionChange(list) {
        this.selectionList = list;
      },
      //人员设置
      handleUser() {
        if (this.selectionList.length) {
          if (this.selectionList.length > 1) {
            this.$refs.userAllSelect.init(this.prjId, null)
          } else {
            this.$refs.userAllSelect.init(this.prjId, this.selectionList[0].id)
          }
        } else {
          this.$message.warning("请选择至少一条数据");
        }

      },
      saveUser(data) {
        let newId = this.selectionList.map((item) => {
          return item.id
        })
        let newuser = data.map((item) => {
          return {
            ...item,
            prjId: this.prjId,
            // gateMachineId: newId.join(','),
          }
        })
        let query = {
          prjId: this.prjId,
          gateMachineIdList: newId,
          list: newuser
        }
        saveBatch(query).then((res) => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
      },
      getAge(data) {
        return getAgeFromIdCard(data);
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.crud.toggleSelection();
          });
      },
      // 搜索
      gridHeadSearch(searchForm) {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      onLoad(page, params = {}) {
        this.tableLoading = false;
        this.page = page;
        params.prjId = this.prjId
        // getList(
        //   page.currentPage,
        //   page.pageSize,
        //   Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        // ).then((res) => {
        //   if (res.data.code === 200) {
        //     const data = res.data.data;
        //     this.page.total = res.data.data.total;
        //     this.$refs.gridLayOut.page.total = data.total;
        //     this.tableData = data.records;
        //     this.tableLoading = false;
        //   } else {
        //     this.tableLoading = false;
        //   }
        // });
      },

    },
  };

</script>

<style lang="less" scoped>
  ::v-deep .table-container {
    .el-table {
      height: calc(100vh - 334px) !important;
      max-height: calc(100vh - 334px) !important;
    }
  }

</style>
