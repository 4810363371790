<template>
  <el-main>
    <grid-layout
        ref="gridLayOut"
        :table-options="tableOptions"
        :table-data="tableData"
        :table-loading="false"
        class="disposeOf"
    >
    </grid-layout>
    <el-form :model="dataForm" :rules="rules" ref="managementForm" label-width="100px" v-loading="formLoading" style="margin-right: 20px">
      <el-row >
        <el-col :span="12" >
          <el-form-item label="类型" prop="wasteType">
            <el-radio-group v-model="dataForm.processType" value="1" @change="handleTypeChange">
              <el-radio label="1">委托处置</el-radio>
              <el-radio label="2">内部利用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!-- 处置单位 -->
        <el-col :span="12" v-if="dataForm.processType=='1'">
          <el-form-item label="处置单位" prop="processOrg">
            <el-input v-model="dataForm.processOrg" maxlength="200" show-word-limit>
              <template #append>
                <el-button
                    icon="el-icon-search"
                    @click="handleSearch"
                    size="small"
                    class="search-button"
                />
              </template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row>
          <el-col :span="12" v-if="dataForm.processType=='1'">
            <el-form-item label="许可证号" prop="licences">
              <el-input v-model="dataForm.licences" placeholder="请输入许可证号" maxlength="200" show-word-limit></el-input>
            </el-form-item>
          </el-col>

          <!-- 处置时间 -->
          <el-col :span="12">
            <el-form-item label="处置时间" prop="processTime">
              <el-date-picker
                  v-model="dataForm.processTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择处置时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 许可证号 -->

        <el-row>
          <!-- 回执文件 -->
          <el-col :span="12" v-if="dataForm.processType=='1'">
            <el-form-item label="回执文件" prop="documentUrl">
              <el-upload
                  action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                  :headers="headers"
                  :file-list="fileList"
                  file="file"
                  :on-preview="handleFileCoverPreview"
                  :on-success="handleFileCoverSuccess"
                  :on-change="uploadImgChange"
                  :on-remove="handleRemove"
                  accept=".doc,.docx,.pdf,.png,.jpg,.zip"
                  :limit=limitCountImg
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>

          <!-- 处置详情 -->
          <el-col :span="24" v-if="dataForm.processType=='2'">
            <el-form-item label="处置详情" prop="processDetail">
              <el-input type="textarea" v-model="dataForm.processDetail" :rows="5" maxlength="500" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>

    </el-form>
    <el-dialog
        title="处置记录"
        :visible.sync="managementVisible"
        :modal-append-to-body="false"
        :append-to-body="true"
        width="40%"
    >
      <managementHistory ref="managementHistory" @getManagement="getManagement"></managementHistory>
    </el-dialog>
    <el-dialog
        :visible.sync="imageVisible"
        width="60%"
        :append-to-body="true">
      <img  style="margin-top:20px ; width: 100%; height: auto; display: block; "  :src="imageUrl" alt="">
    </el-dialog>
  </el-main>

</template>
<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {detail} from "@/api/siteManagement/management";
import managementHistory from "@/views/siteManagement/solidHazardousWaste/managementHistory.vue";
import {getToken} from "@/util/auth";
import {downloadFileBlob} from "@/util/util";

export default {
  components: {managementHistory, FormLayout, HeadLayout,GridLayout},
  data() {

    return {
      imageUrl:'',
      imageVisible:false,
      limitCountImg: 1,
      showImg: true, //默认展示
      tableData:[],
      isClose: false,
      fileList: [],
      itemData: [],
      viewFlag: true,
      formLoading: false,
      type: 'view',
      dataForm: {},
      isWasteType:true,
      isInside:false,
      managementVisible:false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {

    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    rules() {

      return {
        processOrg: [{ required: true, message: '请输入处置单位', trigger: 'blur' }],
        processTime: [{ required: true, message: '请选择处置时间', trigger: 'blur' }],
        licences: [{ required: true, message: '请输入许可证号', trigger: 'blur' }],
        processDetail: [{ required: true, message: '请输入处置详情', trigger: 'blur' }],
        documentUrl: [{ required: true, message: '请上传回执文件', trigger: 'blur' }]
      };
    },
    tableOptions() {

      return {
        height: 0,
        menu:false,
        selection:false,
        column: [{label: '固危废名称', prop: 'wasteName', align: "center", overHidden: true,
        }, {label: '入库量', prop: 'quantity', align: "center", overHidden: true,
        }, {label: '入库时间', prop: 'receptTime', align: "center", overHidden: true,
        }, {label: '来源', prop: 'source', align: "center", overHidden: true,
        }],
      }
    },
  },
  methods: {
    handleRemove(file, fileList) {
      this.dataForm.documentUrl = ''
      this.fileList = fileList
    },
    handleFileCoverPreview(file){
      const fileExtension = file.name?.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
      let url = file.url;
      if(!file.url){
        url = file.response.data.link
      }
      if(["docx", "doc", "zip", "pdf"].includes(fileExtension)){
        downloadFileBlob(url, file.name);
      }else{
        this.imageUrl =  url;
        this.imageVisible = true
      }
    },

    handleFileCoverSuccess(res,fileList){
      this.dataForm.documentUrl = res.data.attachId;
      this.fileList = fileList
    },
    uploadImgChange(file, fileList) {
      this.fileList = fileList
    },
    getManagement(row){
      this.dataForm.processOrg = row.processOrg
      this.dataForm.licences = row.licences
      this.managementVisible = false
    },
    handleSearch(){
      this.managementVisible = true
      this.$nextTick(() => {
        this.$refs.managementHistory.onLoad(this.page);
      })
    },
    handleTypeChange(value){
      if(value.value =="1"){
        this.isWasteType = true
        this.isInside = false
      }else{
        this.isWasteType = false
        this.isInside = true
      }
    },
    getData(){
      if(this.dataForm.processType=="2"){
        this.dataForm.processOrg = ''
        this.dataForm.licences = ''
        this.dataForm.documentUrl = ''
        this.fileList = []
      }
      return Object.assign(this.dataForm,{receptIds:this.tableData.map(item => item.id).join(',')});
    },
    formData(id) {
      detail({id:id}).then(res => {
        this.dataForm = res.data.data;
        this.fileList = [{
          name: this.dataForm.attach.originalName,
          url: this.dataForm.attach.link,
          id: this.dataForm.attach.id,
        }]
        if(this.dataForm.processType=="2"){
          this.fileList = []
        }
      })
    },
    resetForm(){
      this.fileList=[]
      this.dataForm={
        processType:"1",
        processOrg:'',
        processTime:'',
        licences:'',
        processDetail:'',
        documentUrl:'',
      }
    }
  },
};
</script>

<style scoped lang="scss">
.disposeOf ::v-deep .avue-crud .el-table {
  height: calc(100vh - 500px) !important;
  max-height: calc(100vh - 500px) !important;
}

</style>
