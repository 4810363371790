<template>
  <div class="board" id="board">
    <div class="borderTabs">
      <div class="tabsTitle" :style="{color:tabsActive == index?themeColor:''}" v-for="(item,index) in tabsList"
           :key="index" @click="chooseTabs(item,index)">
        {{ item }}
        <div class="tabsBottom" :style="{backgroundColor:tabsActive == index?themeColor:''}"></div>
      </div>
    </div>
    <!--安全管理概览-->
    <safetyManagement v-if="tabsActive == 0"></safetyManagement>
    <!--风险与隐患-->
    <risksAdnDangers v-if="tabsActive == 1"></risksAdnDangers>
    <!--资质与活动-->
    <qualifications v-if="tabsActive == 2"></qualifications>
    <!--安全教育-->
    <safetyEducation v-if="tabsActive == 3"></safetyEducation>
    <!--安全事故-->
    <safetyAccidents v-if="tabsActive == 4"></safetyAccidents>
  </div>
</template>
<script>
import safetyManagement from './safetyManagement.vue';
import risksAdnDangers from './risksAdnDangers.vue';
import qualifications from './qualifications.vue';
import safetyEducation from './safetyEducation.vue';
import safetyAccidents from './safetyAccidents.vue';
import {mapGetters} from "vuex";

export default {
  components: {safetyManagement, risksAdnDangers, qualifications, safetyEducation, safetyAccidents},
  data() {
    return {
      tabsList: ['安全基本信息', '风险与隐患', '资质与活动', '安全教育', '安全事故与事件'],
      tabsActive: 0
    }
  },
  computed: {
    ...mapGetters(['themeColor'])
  },
  created() {
    this.$nextTick(() => {
      this.bodyScale();
    });
  },
  methods: {
    chooseTabs(item, index) {
      this.tabsActive = index;
    },
    bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var scale = devicewidth / 1920; // 分母——设计稿的尺寸
      document.getElementById("board").style.zoom = scale; //放大缩小相应倍数
      //解决页面缩放echarts图标鼠标位置偏移问题
      document.styleSheets[document.styleSheets.length - 1].insertRule(
        "canvas {zoom: " + 1 / scale + "}"
      );
      document.styleSheets[document.styleSheets.length - 1].insertRule(
        "canvas {transform: scale(" + scale + ")}"
      );
      document.styleSheets[document.styleSheets.length - 1].insertRule(
        "canvas {transform-origin: 0 0}"
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.board {
  width: 100%;
}

.borderTabs {
  width: calc(100% - 68px);
  background-color: #FFFFFF;
  padding: 25px 34px 0 34px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  display: flex;
}

.tabsTitle {
  color: #1D2129;
  font-size: 21px;
  font-weight: bold;
  margin-right: 80px;
  text-align: center;
  cursor: pointer;
}

.tabsBottom {
  width: 50px;
  height: 4px;
  background-color: transparent;
  margin: 21px auto 0;
}
</style>
