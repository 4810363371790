<template>
    <el-container>
        <el-main>

            <head-layout
                head-title="投保记录"
                :head-btn-options="headBtnOptions"
                @head-add="headAdd"
                @head-remove="rowRemove"
            />
            <grid-layout
                ref="gridLayOut"
                :grid-row-btn="gridRowBtn"
                :table-options="tableOptions"
                :table-data="tableData"
                :table-loading="tableLoading"
                :data-total="page.total"
                :page="page"
                :editType="inline"
                @page-current-change="handleCurrentChange"
                @page-size-change="handleSizeChange"
                @page-refresh-change="onLoad"
                @gird-handle-select-click="selectionChange"
                @row-edit="rowEdit"
                @row-remove="rowRemove"
            >
                <template #insuranceEndDate="{row}">
                    <span :class="overDue(row.insuranceEndDate) ? 'overDue' : ''">{{ row.insuranceEndDate }}</span>
                </template>
                <template #docUrl="{row,index}">
                    <el-link type="primary" v-for="(item,i) in getFiles(row.docUrl)" :key="item.url" :href="item.url">{{ item.name }}{{ i == getFiles(row.docUrl).length-1 ? '' : ',' }}</el-link>
                </template>
            </grid-layout>
            <!-- 操作页 -->
            <el-dialog :visible.sync="dialogVisible" :title="title" :before-close="handleCancel">
                <avue-form :option="dialogOption" ref="cedForm" v-model="form" @submit="handleSubmit">
                    <template slot="docUrl">
                        <el-upload
                          v-if="formType != 'view'"
                          accept=".zip,.doc,.docx,.pdf"
                          style="margin-bottom: 12px; align-items: center;"
                          class="upload-demo"
                          action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                          :on-success="handleDocSuccess"
                          ref="uploadRef"
                          :headers="headers"
                          :file-list="files"
                          :on-remove="handleDocRemove"
                          >
                          <!-- :show-file-list="false" -->
                        <div slot="tip" class="el-upload__tip">
                            图片支持*.zip/*.doc/*.docx/*.pdf格式,文件大小不超过5M
                        </div>
                          <el-button
                            size="small"
                            type="primary"
                            icon="el-icon-upload"
                          >点击上传
                          </el-button>
                        </el-upload>
                    </template>
                </avue-form>
                <template slot="footer">
                    <el-button size="small" @click="handleCancel()" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.celBtn`) }} </el-button>
                    <el-button size="small" type="primary" @click="handleSave" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.defBtn`) }} </el-button>
                </template>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {ohinsurancePage, ohinsuranceSubmit, ohinsuranceRemove, ohhazardcontactPage, ohhazardcontactSubmit, ohhazardcontactRemove} from '@/api/healthManage/healthArchives'
import {mapGetters} from 'vuex'
import hazardAnalysis from './compontents/hazardAnalysis.vue'
import {dateFormat} from "@/util/date";

export default {
    props: {
        active: {
            type: String,
            default: ''
        },
        documentInfo: {
            type: Object,
            default: ()=>{}
        }
    },

    components: {
        HeadLayout,
        GridLayout,
        hazardAnalysis
    },

    data () {
        return {
            headTitle: '',
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            selectionList: [],
            query: {},
            dialogVisible: false,
            title: '',
            form: {},
            files: [],
        }
    },

    watch: {
        active: {
            handler () {
                this.init()
            },
            immediate: true,
        },
        "documentInfo.id" : {
            handler () {
                this.init()
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters(["permission", 'userInfo']),

        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.healthArchives_ced_add, true),
                editBtn: this.vaildData(this.permission.healthArchives_ced_edit, true),
                delBtn: this.vaildData(this.permission.healthArchives_ced_delete, true),
            };
        },
        headBtnOptions () {
            let btnList = []
            if(this.active == 'insurance' || this.active == 'hazard') {
                btnList.push({
                    emit: "head-add",
                    type: "button",
                    icon: "",
                    btnOptType: 'add',
                });
                btnList.push({
                    label: '移除',
                    emit: "head-remove",
                    type: "button",
                    icon: "",
                    btnOptType: 'remove',
                });
            } 
            return btnList
        },

        tableOptions () {
            return {
                selection: true,
                // linklabel: 'docUrl',
                column: [
                    {
                        label: "保单号",
                        prop: "insuranceNo",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "保单名称",
                        prop: "insuranceName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "投保日期",
                        prop: "insuranceDate",
                        align: "center",
                        overHidden: true,
                        width: '120px'
                    },
                    {
                        label: "保险截止日期",
                        prop: "insuranceEndDate",
                        align: "center",
                        overHidden: true,
                        slot: true,
                        width: '120px'
                    },
                    {
                        label: "承保单位",
                        prop: "orgName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "附件",
                        prop: "docUrl",
                        align: "center",
                        overHidden: true,
                        slot: true,
                    },
                ]
            }
        },

        gridRowBtn () {
            let btnList = []
            if (this.permissionList.editBtn){
                btnList.push({
                    label: '编辑',
                    emit: "row-edit",
                    type: "button",
                    icon: "",
                    btnOptType: 'edit',
                });
            }
            if (this.permissionList.delBtn){
                btnList.push({
                    label: '删除',
                    emit: "row-remove",
                    type: "button",
                    icon: "",
                    btnOptType: 'del',
                });
            }
            return btnList
        },

        dialogOption () {
            return {
                size: 'small',
                submitBtn: false,
                emptyBtn: false,
                labelWidth: 120,
                column: [
                    {
                        label: "保单号",
                        prop: "insuranceNo",
                        placeholder: '请输入保单号',
                        span: 12,
                        maxlength: 200,
                        rules: [{
                            required: true,
                            message: '请输入保单号',
                            trigger: ['change','bulr']
                        }]
                    },
                    {
                        label: "保单名称",
                        prop: "insuranceName",
                        placeholder: '请输入保单名称',
                        span: 12,
                        maxlength: 200,
                        rules: [{
                            required: true,
                            message: '请输入保单名称',
                            trigger: ['change','bulr']
                        }]
                    },
                    {
                        label: "承保单位",
                        prop: "orgName",
                        placeholder: '请输入承保单位',
                        span: 12,
                        maxlength: 200,
                        rules: [{
                            required: true,
                            message: '请输入承保单位',
                            trigger: ['change','bulr']
                        }]
                    },
                    {
                        label: "投保日期",
                        prop: "insuranceDate",
                        labelWidth: 120,
                        placeholder: "请选择",
                        span: 12,
                        type: 'date',
                        format: 'yyyy-MM-dd', // 设置日期格式
                        valueFormat: 'yyyy-MM-dd', // 设置数据库中存储的日期格式
                        rules: [{
                            required: true,
                            message: "请填写",
                            trigger: "change"
                        }],
                        change: (val)=>{
                            if(val.value){
                                var currentDate = new Date(val.value);
                                var millisecondsPerYear = 365 * 24 * 60 * 60 * 1000;
                                
                                var newMilliseconds = currentDate.getTime() + millisecondsPerYear;
                                var newDate = new Date(newMilliseconds);
                                console.log(newDate)
                                // var currentDate = new Date(val.value);
                                // var year = newDate.getFullYear();
                                // var month = newDate.getMonth() +1;
                                // var day = newDate.getDate();
                                this.form.insuranceEndDate = dateFormat(newDate, 'yyyy-MM-dd') 
                            }
                        }
                    },
                    {
                        label: "保险截止日期",
                        prop: "insuranceEndDate",
                        labelWidth: 120,
                        placeholder: "请选择",
                        span: 12,
                        type: 'date',
                        format: 'yyyy-MM-dd', // 设置日期格式
                        valueFormat: 'yyyy-MM-dd', // 设置数据库中存储的日期格式
                        rules: [{
                            required: true,
                            message: "请填写",
                            trigger: "change"
                        }]
                    },
                    {
                        label: "附件",
                        prop: "docUrl",
                        type: 'textarea',
                        span: 24,
                        formslot: true,
                    }
                ]
            }
        },
    },

    mounted () {
        // this.init()
    },

    methods: {
        init () {
            this.page.current = 1 
            this.page.pageSize = 20 
            this.onLoad(this.page)
        },
        headAdd () {
            this.title = '投保记录新增'
            this.dialogVisible = true
        },
        rowRemove (row,index) {
            let ids = ''
            if(row) {
                ids = row.id
            } else {
                ids = this.selectionList.map(val=>val.id).toString()
                if(!ids){
                    this.$message.warning('请选择操作数据')
                }
            }
            if(ids){
                this.$confirm("确认删除当前数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    ohinsuranceRemove(ids).then((res) => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功")
                            this.onLoad(this.page);
                        }
                    });
                }).catch((error) => {
                        this.$message({
                            message: "取消删除",
                            type: "info",
                        });
                    });
            }
        },

        onLoad (page,params={}) {
            this.tableLoading = true; 
            this.page = page;
            this.query = {documentId: this.documentInfo.id}
            ohinsurancePage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.tableData = data.records;
                this.tableLoading = false;
                this.$refs.gridLayOut.selectionClear()
            });
        },

        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page)
        },
        selectionChange (list) {
            this.selectionList = list
        },

        rowEdit (row, index) {
            this.form = row
            this.files = this.getFiles(row.docUrl)
            this.title = '投保记录编辑'
            this.dialogVisible = true
        },

        getFiles (docUrl) {
            const fileUrl = docUrl ? docUrl.split(',') : [] 
            let files = fileUrl.map(val => {
                let pos = val.lastIndexOf("/")
                return {
                    name: val.substring(pos+1, val.length),
                    url: val
                }
            })
            return files
        },

        handleCancel () {
            this.$refs.cedForm.resetForm()
            delete this.form.id
            this.files =[]
            this.dialogVisible = false
        },
        handleSubmit (data,done) {
            this.$refs.cedForm.validate(valid=>{
                if(valid) {
                    this.dialogLoading = true
                    this.form.documentId = this.documentInfo.id
                    this.form.docUrl = this.files.map(val=>val.url).toString()
                    ohinsuranceSubmit(this.form).then(() => {
                        // this.onLoad()
                        this.$message.success('操作成功')
                        this.dialogLoading = false
                        this.handleCancel()
                        this.init()
                        done()
                    }).catch(err => {
                        done()
                    }).finally(() => {
                        this.dialogLoading = false
                    })
                }
            })
        },
        handleSave () {
            this.$refs.cedForm.submit();
        },

        handleDocSuccess(res, file, fileList) {
            console.log(res,file)
            this.files = [
                {
                    name: file.response.data.originalName,
                    url: file.response.data.link,
                } 
            ]
        },

        handleDocRemove (file,fileList) {
            this.files = fileList.map(val=>{
                return{
                    name: val.name,
                    url: val.url
                } 
            })
        },

        overDue (date) {
            if(date){
                let newDate = new Date(dateFormat(new Date(), 'yyyy-MM-dd')).getTime()
                let str = new Date(date).getTime()
                return str<newDate
            }
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
.overDue{
    color: #C5211B;
}
</style>