<template>
  <div class="main-box riskIdentification">
    <div class="flex-container">
      <nodeCommonTree class="introduceTree" treeTitle="节点配置" pageType='camera' node-key="id" isLeafIcons isShowMore risk :defaultExpandedKeys="defaultExpandedKeys" ref="commonTreeCore" @getTreeAdd="getTreeAdd" @getTreeAddChild="getTreeAddChild" @getTreeDelete="getTreeDelete" @getTreeEdit="getTreeEdit" :treeData="corePersonnelTreeData" :defaultProps="defaultProps" @getNodeClick="TreeNodeClick"
        :showCheckbox="false" />
      <div class="table-box">
        <el-tabs v-model="activeName" @tab-click="handleClick" v-if="activeTab=='node'">
          <el-tab-pane label="基础信息" name="base">
            <nodeBase :selectTree="selectTree"></nodeBase>
          </el-tab-pane>
          <!-- <el-tab-pane label="摄像头" name="task">
            <nodeCamera></nodeCamera>
          </el-tab-pane> -->
        </el-tabs>
        <el-tabs v-model="activeName" @tab-click="handleClick" v-if="activeTab=='camera'">
          <el-tab-pane label="基础信息" name="base">
            <cameraBase :selectTree="selectTree"></cameraBase>
          </el-tab-pane>
          <!-- <el-tab-pane label="绑定AI盒子/服务器" name="cameraAiBox">
            <cameraAiBox></cameraAiBox>
          </el-tab-pane>
          <el-tab-pane label="算法任务" name="cameraAlgorithmTask">
            <cameraAlgorithmTask></cameraAlgorithmTask>
          </el-tab-pane>
          <el-tab-pane label="视频展示" name="cameraView">
            <cameraView></cameraView>
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <addCameraTree ref="addCameraTree"></addCameraTree>
  </div>
</template>
<script>
  import nodeCommonTree from "../algorithmManagement/nodeCommonTree";
  import Template from "@/views/message/template/list.vue";
  import * as API from "@/api/safetyTtandard/riskIdentification";
  import {
    exportBlob
  } from "@/api/common";
  import {
    downloadXls
  } from "@/util/util";
  import GridLayout from "@/views/components/layout/grid-layout";

  import {
    getTree,
    remove
  } from "@/api/safe/algorithmManagement";
  import addCameraTree from "./addCameraTree"
  import nodeBase from "../algorithmManagement/node/base";
  import cameraBase from "./camera/base";
  import cameraAlgorithmTask from "./camera/algorithmTask";
  import cameraAiBox from "./camera/aiBox";
  import cameraView from "./camera/view";

  export default {
    name: "index",
    components: {
      Template,
      GridLayout,
      nodeCommonTree,
      addCameraTree,
      cameraBase,
      nodeBase,
      cameraAiBox,
      cameraAlgorithmTask,
      cameraView
    },

    data() {
      return {
        activeTab: 'node',
        activeName: 'base',
        selectTree: undefined,
        jcxflForm: {
          parentId: '',
          isObj: 0,
        },
        jcxflModel: false,
        TableLoading: false,
        tableData: [],
        treeData: [],
        query: {},
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        defaultProps: {
          children: "children",
          value: 'id',
          label: "name",
        },
        corePersonnelTreeData: [],
        defaultExpandedKeys: [],
      }
    },
    mounted() {
      this.typeTree();
    },
    methods: {
      handleClick(tab) {
        this.activeName = tab.name
      },

      handleSubmit(form, done) {
        let data = {
          ...form,
          parentId: this.jcxflForm.parentId || -1,
        };
        let URL = form.isObj == 0 ? API.submit : API.strisksourceSubmit
        URL(data)
          .then((res) => {
            this.jcxflModel = false;
            this.$message.success(res.data.msg);
            this.typeTree();
          })
          .catch((err) => {
            done();
          });
      },

      getTreeAdd(row) {
        this.$refs.addCameraTree.init("addNode", row, 'node');
      },
      getTreeAddChild(node, type) {
        this.$refs.addCameraTree.init("add", node, type);

      },
      getTreeDelete(data) {

        this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
            confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
            cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
            type: "warning",
          })
          .then(() => {
              let removeData = {
                id: data.id,
                type: data.type
              }
              remove(removeData).then(() => {
                this.$refs.commonTreeCore.nodeData = {};
                this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
                this.typeTree();
              });
            })
            },

            getTreeEdit(row) {
              this.jcxflForm = {
                ...row,
                isObj: row.isSource ? 1 : 0,
              };
              const data = JSON.parse(JSON.stringify(this.corePersonnelTreeData));
              this.treeData = this.getParentData(data)
              this.jcxflModel = true;
            },
            getParentData(data, id) {
              data.forEach((val, index) => {
                if (val.id == this.jcxflForm.id) {
                  data.splice(index, 1);
                } else if (val.children) {
                  this.getParentData(val.children, this.jcxflForm.id);
                }
              });
              return data
            },

            TreeNodeClick(data) {
              this.selectTree = data
              this.activeTab = data.type
              this.activeName = 'base'
            },

            typeTree() {
              this.$refs.commonTreeCore.nodeData = {}
              this.selectTree = {}
              this.defaultExpandedKeys = []
              getTree({
                type: "camera"
              }).then((res) => {
                this.corePersonnelTreeData = res.data.data;
                if (res.data.data.length != 0) {
                  this.selectTree = res.data.data[0] || {};
                  if (!this.selectTree.id) return;
                  this.defaultExpandedKeys = [this.selectTree.id];
                  this.$nextTick(() => {
                    this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                      this.selectTree.id
                    );
                    this.$refs.commonTreeCore.nodeData = this.selectTree;
                    this.TreeNodeClick(this.selectTree);
                  });
                }
              });
            },

          },
      }

</script>
<style scoped lang="scss">
  .riskIdentification {
    background-color: #FFFFFF;

    .common_tree_handle .el-tree {
      height: calc(100vh - 278px) !important;
    }

    .table-box {
      width: calc(100% - 281px);


    }
  }

  ::v-deep .el-tabs {
    width: 100%;

    .el-tabs__nav-scroll {
      width: 100% !important;
    }
  }

  ::v-deep .el-tabs__nav {
    margin-left: 20px;
  }

  ::v-deep .introduceTable .avue-crud .el-table {
    height: calc(100vh - 330px) !important;
    max-height: calc(100vh - 330px) !important;
  }

  .introduceTree ::v-deep .common_tree_handle .el-tree {
    height: calc(100vh - 278px) !important;
  }

  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }

  ::v-deep .el-tabs--border-card {
    border-left: none;
    border-bottom: none;
  }

  ::v-deep .el-tabs__content {
    padding: 0 !important;
  }

</style>
