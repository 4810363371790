<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.sys.dict.title.editHeadTitle') : $t('cip.plat.sys.dict.title.addHeadTitle')"
        @head-save="headSave('save')"
        @head-save-back="headSave('back')"
        @head-cancel="headCancel">
      </head-layout>
      <!-- <form-layout ref="formLayout" icon="" :head-title="headtitle" page-type="self" :option="option"
      :btn-options="btnOptions" @form-btn-save-click="save" :dataForm="form"></form-layout>-->
      <form-layout :column="optionParent.column" :dataForm="dataObj" ref="formLayout"></form-layout>
    </el-main>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {
  update,
  add,
  getDictDetail
} from "@/api/system/dict";
import { optionParent } from "@/option/system/dictbiz";
import {mapGetters} from "vuex";
import {getDict} from "../../api/system/dict";
export default {
  components: { FormLayout, HeadLayout },
  computed: {
    ...mapGetters(["userInfo", "permission", "language"]),
  },
  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
      {
        label: this.$t('cip.cmn.btn.saveBackBtn'),
        emit: "head-save-back",
        type: "button",
        icon: ""
      }, {
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: "head-cancel",
        type: "button",
        icon: ""
      }],
      dataObj: {

      },
      optionParent,
    }
  },
  created() {
    this.optionParent = this.optionParent(this,this.language)
    console.log(this.$route.query)
    if (this.$route.query.type == 'edit') {
      //console.log(decodeURIComponent(this.$route.query.data))
      //this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
      // const column = this.findObject(this.optionParent.column, "code");
      // column.display = false
      getDictDetail(this.$route.query.id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    }
  },
  methods: {
    headSave(type) {
      this.$refs.formLayout.$refs.form.form.code=this.$refs.formLayout.$refs.form.form.code.trim()
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading()
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then((res) => {
            if (type == 'back') {
              this.close()
            }
            this.dataObj=res.data.data
            this.getValue(this.dataObj.id)
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.isShow = false
          }, error => {

            window.console.log(error);
            this.$refs.formLayout.$refs.form.allDisabled = false
          }).finally(()=>{
            this.$loading().close()
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }


      })

    },
    getValue(id) {
      getDict(id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    },
    initData() {
      getDeptTree().then(res => {
        const column = this.findObject(this.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
