<template>
  <div style="height: 100%">
    <grid-head-layout
      ref="searchFrom"
      :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset"
    >
    </grid-head-layout>
    <grid-layout
      ref="gridLayout"
      class="SignsCrud"
      :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
      :data-total="page.total"
      @page-size-change="handleSizeChange"
      @page-current-change="handleCurrentChange"
      @page-refresh-change="onLoad"
      :page="page"
      @gird-handle-select-click="selectionChange"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
    >
    </grid-layout>
    <div slot="footer" style="text-align: right; margin-top: 50px">
      <!-- <div
        class="drawer-footer"
        style="position: fixed; bottom: 5px; right: 10px"
      > -->
      <el-button @click="dialogVisibledialog">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </div>
    <!-- 预览标识清单图片 -->
    <el-dialog
      v-dialog-drag
      title="安全标识图片预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="signShowFileDialog"
      width="70%"
    >
      <iframe
        ref="signFileIframe"
        :src="signFileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="signShowFileDialog = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { getSignList } from "@/api/bssettings/safetySignsList";

export default {
  components: {
    GridLayout
  },
  data() {
    return {
      list: [],
      tableLoading: false,
      selectionList: [],
      // 标识选择弹窗
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableOptions: {
        index: true,
        indexLabel: "序号",
        menu: false,
        column: [
          {
            label: "安全标识",
            prop: "picUrl",
            align: "center",
            type: "upload",
            width: 200,
            hight: 200,
            overHidden: true,
          },
          {
            label: "标识名称",
            prop: "signName",
            width: 150,
            align: "center",
            overHidden: true,
          },
          {
            label: "设置范围和说明",
            prop: "description",
            align: "left",
            overHidden: true,
          },
        ],
      },
    };
  },
  computed: {
    // 标识选择弹窗
    searchColumns() {
      return [
        {
          label: "标识名称",
          prop: "signName",
          span: 4,
          clearable: true,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "标识名称",
        },
        {
          label: "标识类型",
          prop: "signType",
          span: 4,
          type: "select",
          dataType: "string",
          clearable: true,
          dicData: [],
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "标识类型",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=BS_SIGN_TYPE",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ];
    },
  },
  created() {
    this.selectionList = [];
    this.onLoad(this.page);
  },
  mounted() {
    this.selectionList = [];
    this.onLoad(this.page);
  },
  methods: {
    // 标识清单弹窗确定
    handleSave() {
      this.$emit("select-data", this.selectionList);
      this.dialogVisibledialog();
      this.query = {};
    },
    dialogVisibledialog() {
      this.$emit("close-dialog");
    },
    // 标识列表加载
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getSignList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.selectionClear();
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayout.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    //预览安全标识图片
    signShowFile(item) {
      this.signFileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.url));
      console.log(this.signFileUrl, item, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
      this.signShowFileDialog = true;
      //exls表格预览隐藏打印按钮6
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.signFileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    // 标识弹窗查询
    searchChange(params, done) {
      if (params.signName == "") {
        delete params.signName;
      } else if (params.signType == "") {
        delete params.signType;
      } else if (params.signName == "" && params.signType == "") {
        params = {};
      }
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    // 标识弹窗查询重置
    searchReset(data) {
      this.query = {};
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 标识弹窗选择变化
    selectionChange(list) {
      this.selectionList = list;
    },
    // 标识弹窗选择清空
    selectionClear() {
      this.selectionList = [];
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep #gridLayout {
  height: calc(100% - 138px) !important;
}

::v-deep .avue-crud {
  height: 100% !important;

  .el-card {
    height: 100% !important;
  }

  .el-card__body {
    height: 100% !important;

    .el-form {
      height: 100% !important;

      .el-table {
        height: 100% !important;
      }
    }
  }
  .avue-crud__pagination {
    height: auto !important;
  }
}
</style>
