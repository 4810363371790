<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="rowView"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
    >
      <!-- <template #hdLevel="{ row }">
        <el-tag
          v-if="hdLevel[row.hdLevel]"
          :type="hdLevelIcon[row.hdLevel]"
          effect="dark"
          >{{ hdLevel[row.hdLevel] }}
        </el-tag>
      </template> -->
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";

import { holographyHtPage } from "@/api/safe/safeatyHolography";

export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      organizationId: "",
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          prop: "templateName",
          placeholder: "模板名称",
          span: 4,
        },
      ];
    },
    tableOptions() {
      return {
        menu: false,
        selection: false,
        linklabel: "code",
        column: [
          {
            label: "模板编号",
            prop: "code",
            align: "left",
            width: 200,
            overHidden: true,
          },
          {
            label: "作业票类型",
            type: "select",
            align: "center",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=WORK_TICKET_TYEP",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            prop: "type",
            overHidden: true,
            width: 150,
          },
          {
            label: "模板名称",
            prop: "templateName",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "版本号",
            prop: "version",
            align: "center",
            overHidden: true,
          },
          {
            label: "起草人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
            width: 100,
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: "center",
            width: 170,
          },
          {
            label: "启用时间",
            prop: "templateEnableTime",
            align: "center",
            width: 170,
          },
          {
            label: "状态",
            prop: "templateIsEnable",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=kicket_template_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            align: "center",
            width: 100,
          },
        ],
      };
    },
  },
  methods: {
    init(id) {
      this.organizationId = id;
      this.query = {}
      // this.query.organizationId = id
      this.page.currentPage = 1
      this.$refs.gridHeadLayout.searchForm = {}
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      // params.organizationId = this.organizationId;
      // params.templateType = ""
        holographyHtPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query,{organizationId:this.organizationId})
        ).then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    rowView(row) {
            this.$router.push({
                path: `/jobSlip/jobSlipTemplate/view`,
                query: {
                    type: 'view',
                    id: row.id
                }
            })
        },
  },
};
</script>
<style scoped lang="scss"></style>
