<template>
  <el-dialog
    v-dialog-drag
    title="用户列表"
    :modal="true"
    append-to-body
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="userSelectDialog"
    width="75%"
    class="user-dialog"
    @click="closeDialog"
  >
    <el-container>
      <CommonTree
        :titleShow="false"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :treeTitle="$t(`cip.plat.sys.user.title.treeTitle`)"
        :isShowdig="false"
        :showCheckbox="false"
        :popover-show="true"
        @getNodeClick="getNodeClick"
        @include-down="includeDown"
      />
      <el-main>
        <div class="headers">
          <grid-head-layout
            ref="gridHeadLayout"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :search-columns="searchColumns"
            @grid-head-search="searchChange"
            @grid-head-empty="searchReset">
          </grid-head-layout>
        </div>

        <grid-layout
          ref="gridLayout"
          :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
          :gridRowBtn="gridRowBtn"
          :tableOptions="tableOptions"
          :tableData="tableData"
          :tableLoading="tableLoading"
          :data-total="page.total"
          :page="page"
          @grid-single="gridRowBtnClick"
          @page-size-change="onLoad"
          @page-current-change="onLoad"
          @page-refresh-change="onLoad"
        >
        </grid-layout>
      </el-main>
    </el-container>
  </el-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {getList} from "@/api/system/user";
import {selectChildren} from "@/api/system/dept";
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  name: "userSelectDialog",
  components: {
    GridLayout,
    CommonTree,
  },
  props: {
    knowInfoId: {
      type: Number,
      default: undefined,
    },
    knowInfoCode: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      query: {},
      //   用户
      userSelectDialog: false,
      treeData: [],
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "key",
      },
      treeDeptId: "",
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableOptions: {
        menu: true,
        menuWidth: 80,
        selection: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            cell: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.isEnabled"),
            prop: "isEnabled",
            cell: true,
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          },
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            dataType: "number",
            prop: "userType",
            hide: true,
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(["userInfo"]),
    searchColumns() {
      return [
        {
          label: "",
          prop: "account",
          span: 4,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.account"),
        },
        {
          label: "",
          prop: "realName",
          span: 4,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.realName"),
        },
        // {
        //   prop: "roleId",
        //   span: 4,
        //   multiple: true,
        //   type: "tree",
        //   dicUrl: "api/sinoma-system/role/treeByUserType?tenantId=000000",
        //   props: {
        //     label: "title",
        //   },
        //   checkStrictly: true,
        //   slot: true,
        //   placeholder:
        //     this.$t("cip.cmn.rule.selectWarning") +
        //     this.$t("cip.plat.sys.user.field.roleName"),
        // },
        // {
        //   label: "",
        //   prop: "postId",
        //   span: 4,
        //   type: "tree",
        //   multiple: true,
        //   dicUrl: "/api/sinoma-system/post/select?tenantId=000000",
        //   props: {
        //     label: "postName",
        //     value: "id",
        //   },
        //   placeholder:
        //     this.$t("cip.cmn.rule.selectWarning") +
        //     this.$t("cip.plat.sys.user.field.postId"),
        // },
      ];
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let buttonBtn = [];
      buttonBtn.push(
        {
          label: this.$t("cip.cmn.btn.defBtn"),
          emit: "grid-single",
          type: "text",
        })
      return buttonBtn;
    },
  },

  methods: {
    // 点击确定按钮的修改方法
    gridRowBtnClick(row){
      this.$emit('user-select', row)
      this.userSelectDialog = false;
    },
    openDialog() {
      this.getTreeData();
      this.onLoad(this.page);
      this.userSelectDialog = true;
    },
    closeDialog() {
      this.userSelectDialog = false;
    },
    getTreeData() {
      selectChildren().then((res) => {
        this.treeData = res.data.data;
      });
    },
    getNodeClick(data) {
      this.treeDeptId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params)
    },
    searchReset() {
      this.treeDeptId = this.userInfo.dept_id;
      this.page.currentPage = 1;
      this.onLoad(this.page)
    },
    onLoad(page, params = {}) {
      if (this.treeDeptId === ""){
        this.treeDeptId = this.userInfo.dept_id
      }
      this.page = page;
      this.tableLoading = true;
      getList(
        {
          ...params,...this.query,
          current:page.currentPage,
          size:page.pageSize,
          deptId: this.treeDeptId
        }
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
  },
}
</script>

<style scoped>

</style>
