<template>
  <div class="outWardBox">
    <div class="inWardBox" v-for="(item,index) in cardData" :key="index">
      <div style="padding: 16px">
        <span class="titleBox" v-if="item.terminalName!=null">{{ item.terminalName }}</span>
        <span class="titleBox" v-if="item.terminalName == '' || item.terminalName == null">&nbsp;</span>
        <div style="margin: 30px 0;">

          <div class="contentBox">
            {{ $t(`cip.cps.monitoring.field.ipLocation`) }}：{{ item.ipLocation }}
          </div>
          <div class="contentBox">
            {{ $t(`cip.cps.monitoring.field.clientId`) }}：{{ item.terminalCoding }}
          </div>
          <div v-if="item.heartbeatTopic <=0" class="contentBox">
            {{ $t(`cip.cps.monitoring.field.heartbeatTopic`) }}：0 {{ $t(`cip.cps.monitoring.field.second`) }}
          </div>
          <div v-else class="contentBox">
            {{ $t(`cip.cps.monitoring.field.heartbeatTopic`) }}：{{ item.heartbeatTopic }} {{ $t(`cip.cps.monitoring.field.second`) }}
          </div>
        </div>
      </div>
      <div class="bottomBox" style="display: flex;justify-content: space-between">
        <div style="font-size: 15px; display: flex; line-height: 28px" v-if="item.isStatus == 1">
          <div class="stateBox"></div>
          {{ $t(`cip.cps.monitoring.field.break`) }}
        </div>
        <div style="font-size: 15px; display: flex;line-height: 28px" v-if="item.isStatus == 2">
          <div class="stateBox" style="background-color: #00a680"></div>
          {{ $t(`cip.cps.monitoring.field.connect`) }}
        </div>
        <div>
          <el-button round size="mini" @click="rowEdit(item)" style="margin-right: 4px" v-if="permission.monitoring_enable">{{ $t('cip.cmn.btn.editBtn') }}</el-button>
          <el-button
            round
            @click="valid(item)"
            size="mini"
            v-if="item.isStatus == '1' && permission.monitoring_invalidBtn"
          >{{ $t('cip.cmn.btn.validBtn') }}
          </el-button>
          <el-button
            round
            @click="invalid(item)"
            size="mini"
            v-else-if="permission.monitoring_editBtn"
          >{{ $t('cip.cmn.btn.invalidBtn') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Button from "@/views/plugin/workflow/design/button";
import {updateEmqxStatus} from "@/api/cps/monitoring";
import Template from "@/views/message/template/list";
import {mapGetters} from "vuex";
export default {
  inject: ['onLoad'],
  props: {
    cardData: Array
  },
  data() {
    return {
      page: {
        pageSize: 8,
        currentPage: 1,
        total: 0
      },
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["permission"]),
  },
  methods: {
    //设备启用
    valid(item) {
      // this.$emit('valid', item);
      updateEmqxStatus(item.ipLocation, 2, 2).then(() => {
        this.$parent.onLoad(this.page);
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
      });
    },
    //设备失效
    invalid(item) {
      // this.$emit('invalid', item);
      updateEmqxStatus(item.ipLocation, 2, 1).then(() => {
        this.$parent.onLoad(this.page);
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
      });
    },
    rowEdit(item) {
      this.$router.push({
        path: `/cps/monitoring/edit`,
        query: {
          id: item.id,
          terminalCoding: item.terminalCoding,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
  },
  components: {Template, Button}
}
</script>
<style scoped>
.outWardBox {
  width: calc(100% - 12px);
  padding: 0 6px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
  height: calc(100vh - 375px);
}

.inWardBox {
  width: 24%;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px #dde9ef;
  border: 1px solid #e7eff3;
  margin: 16px calc(0.5% - 1px) 0;
  text-align: center;
  border-radius: 10px;
  /*height: 40%;*/
  overflow: hidden;
}

.titleBox {
  font-size: 20px;
  color: #333333;
  font-weight: bolder;
  display: block;
}

.contentBox {
  color: #666666;
  font-size: 15px;
  text-align: center;
  margin: 10px 0.2% 0;
}

.contentBox div {
  width: 100px;
  text-align: right;
}

.bottomBox {
  width: calc(100% - 16px);
  background-color: rgba(64, 158, 255, 0.55);
  padding: 10px;
  text-align: center;
  justify-content: space-between;
}

.stateBox {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin: 10px 6px;
}

.el-dropdown + .el-dropdown {
  margin-left: 15px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
