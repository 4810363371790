var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mainReportBox" }, [
    _c(
      "div",
      {
        staticClass: "topReportBox",
        staticStyle: {
          height: "46px",
          "background-color": "#F2F3F5",
          "border-bottom": "1px solid rgb(204, 204, 204)",
        },
      },
      [
        _c("div", { staticClass: "leftReportTitle" }, [
          _vm._v("\n      报表查看\n    "),
        ]),
        _c("div", { staticClass: "rightReportSidder" }, [
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "menu-list",
                  attrs: {
                    "default-active": _vm.activeIndex,
                    mode: "horizontal",
                  },
                },
                [
                  _vm._l(_vm.topSidderData, function (item) {
                    return [
                      item.children.length == 0
                        ? _c(
                            "el-menu-item",
                            {
                              key: item.id,
                              attrs: { index: item.id },
                              on: {
                                click: function ($event) {
                                  return _vm.openReport(item)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-menu",
                                staticStyle: {
                                  "font-size": "20px",
                                  color: "#333333",
                                },
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.reportCategoryName) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _c(
                            "el-submenu",
                            { key: item.id, attrs: { index: item.id } },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  { attrs: { title: item.reportCategoryName } },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-menu",
                                      staticStyle: {
                                        "font-size": "20px",
                                        color: "#333333",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title",
                                      },
                                      [_vm._v(_vm._s(item.reportCategoryName))]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._l(
                                item.children,
                                function (oneChild, oneIndex) {
                                  return _c(
                                    "el-menu-item",
                                    {
                                      key: oneIndex,
                                      staticStyle: {
                                        "background-color": "#F2F3F5",
                                      },
                                      attrs: { index: oneChild.id },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openReport(oneChild)
                                        },
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "oneChildNameBox" },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-s-order",
                                              staticStyle: {
                                                "font-size": "20px",
                                                color: "#333333",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "oneChildName" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    oneChild.reportCategoryName
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                    ],
                                    2
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _c(
          "el-button",
          {
            staticClass: "closeBtn",
            attrs: { size: "medium", icon: "el-icon-close" },
            on: { click: _vm.occlude },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "topReportBox" }, [
      _c("div", { staticClass: "leftReportBox" }, [
        _c(
          "div",
          { staticClass: "leftReportTree" },
          [
            _c("CommonTree", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.leftShow,
                  expression: "leftShow",
                },
              ],
              ref: "CommonTree",
              staticStyle: { "border-right": "none" },
              attrs: {
                loading: _vm.treeLoading,
                treeTitle: "组织架构",
                popoverShow: true,
                treeData: _vm.treeData,
                defaultProps: _vm.defaultProps,
                searchTitle: _vm.searchTitle,
                currentNodeKey: _vm.deptId,
                defaultExpandedKeys: _vm.defaultExpandedKeys,
                nodeKey: "id",
                isShowdig: false,
                showCheckbox: false,
                treeExpand: false,
              },
              on: {
                "update:treeData": function ($event) {
                  _vm.treeData = $event
                },
                "update:tree-data": function ($event) {
                  _vm.treeData = $event
                },
                getNodeClick: _vm.treeNodeClick,
                "include-down": _vm.includeDown,
              },
            }),
            _vm.leftShow
              ? _c("el-button", {
                  staticClass: "rightBtn",
                  staticStyle: {
                    "margin-right": "5px",
                    "clip-path": "polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%)",
                    background: "#d2d3d6",
                  },
                  attrs: {
                    circle: "",
                    type: "primary",
                    icon: "el-icon-arrow-right",
                  },
                  on: {
                    click: function ($event) {
                      _vm.leftShow = !_vm.leftShow
                    },
                  },
                })
              : _c("el-button", {
                  staticClass: "rightBtn",
                  staticStyle: {
                    "margin-right": "5px",
                    "clip-path": "polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%)",
                    background: "#d2d3d6",
                    left: "-20px",
                  },
                  attrs: {
                    circle: "",
                    type: "primary",
                    icon: "el-icon-arrow-left",
                  },
                  on: {
                    click: function ($event) {
                      _vm.leftShow = !_vm.leftShow
                    },
                  },
                }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "rightReportBox",
          style: { width: _vm.leftShow ? "calc(100% - 280px)" : "100%" },
        },
        [
          _c(
            "div",
            { staticClass: "rightTags" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.openTag, "tab-remove": _vm.removeTag },
                  model: {
                    value: _vm.activeTag,
                    callback: function ($$v) {
                      _vm.activeTag = $$v
                    },
                    expression: "activeTag",
                  },
                },
                _vm._l(_vm.tagList, function (item) {
                  return _c("el-tab-pane", {
                    key: item.id,
                    attrs: {
                      closable: item.reportCategoryName != "首页",
                      label: item.reportCategoryName,
                      name: item.reportCategoryName,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "iframeBox" }, [
            _vm.iframeUrl != ""
              ? _c("iframe", {
                  staticClass: "iframe",
                  attrs: { src: _vm.iframeUrl },
                })
              : _c("img", {
                  attrs: { src: require("@/assets/images/reportView.png") },
                }),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }