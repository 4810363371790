var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.introduceShow
    ? _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          staticClass: "qmDialog",
          attrs: {
            title: "风险引用",
            modal: false,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.introduceShow,
            width: "80%",
            fullscreen: _vm.fullscreen,
          },
          on: {
            "update:visible": function ($event) {
              _vm.introduceShow = $event
            },
          },
        },
        [
          _c(
            "el-container",
            { staticStyle: { height: "500px" } },
            [
              _c("CommonTree", {
                staticStyle: { "font-size": "13px" },
                attrs: {
                  treeTitle: "风险源分类",
                  defaultProps: _vm.defaultProps,
                  isShowdig: false,
                  searchTitle: _vm.searchTitle,
                  showCheckbox: false,
                  treeData: _vm.introduceTree,
                  treeExpand: true,
                  risk: true,
                },
                on: { getNodeClick: _vm.handleNodeIntroduce },
              }),
              _c(
                "el-main",
                [
                  _c(
                    "div",
                    {
                      staticClass: "company",
                      staticStyle: { padding: "0 10px" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "selectTree",
                          attrs: { clearable: "", placeholder: "请选择公司" },
                          on: { clear: _vm.clearHandle },
                          model: {
                            value: _vm.companyName,
                            callback: function ($$v) {
                              _vm.companyName = $$v
                            },
                            expression: "companyName",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            {
                              attrs: {
                                value: _vm.companyId,
                                label: _vm.companyName,
                              },
                            },
                            [
                              _c("el-tree", {
                                attrs: {
                                  id: "tree-option",
                                  data: _vm.companySelection,
                                  "highlight-current": true,
                                  props: _vm.companydefaultProps,
                                  accordion: true,
                                  "node-key": "id",
                                  "default-expanded-keys":
                                    _vm.defaultExpandedKey,
                                },
                                on: {
                                  "node-click": _vm.handleNodeClickListTree,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("grid-head-layout", {
                    ref: "searchForm",
                    attrs: { "search-columns": _vm.introduceSearchColumns },
                    on: {
                      "grid-head-search": _vm.introduceSearch,
                      "grid-head-empty": _vm.introduceReset,
                    },
                    model: {
                      value: _vm.searchForm,
                      callback: function ($$v) {
                        _vm.searchForm = $$v
                      },
                      expression: "searchForm",
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    attrs: {
                      "data-total": _vm.introduceTableTotal,
                      gridRowBtn: _vm.introduceGridRowBtn,
                      page: _vm.introducePage,
                      tableData: _vm.introduceTableData,
                      tableLoading: _vm.introduceTableLoading,
                      tableOptions: _vm.introduceTableOptions,
                      "search-columns": _vm.introduceSearchColumns,
                    },
                    on: {
                      "page-size-change": _vm.handleSizeIntroduce,
                      "page-current-change": _vm.handleCurrentIntroduce,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.introduceSelectionChange,
                      "grid-select": _vm.introduceSelect,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "riskCode",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkDetail(row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.riskCode))]
                              ),
                            ]
                          },
                        },
                        {
                          key: "riskLevel",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-item": "center",
                                    padding: "4px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        border: `1px solid ${row.lecdColor}`,
                                        borderRadius: "4px",
                                        color: row.lecdColor,
                                        backgroundColor: _vm.computedDlevel(
                                          row.lecdD
                                        ).bg,
                                        textAlign: "center",
                                        display: "inline-block",
                                        padding: "0 20px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.lecdD) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1536089717
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.introduceShow = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }