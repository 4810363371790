var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flow-form",
      style: { margin: "0 auto", width: _vm.formConf.fullScreenWidth },
    },
    [
      !_vm.loading
        ? _c("parser", {
            key: _vm.key,
            ref: "dynamicForm",
            attrs: { "form-conf": _vm.formConf },
            on: { submit: _vm.submitForm },
          })
        : _vm._e(),
      _c("candidate-form", {
        attrs: {
          visible: _vm.candidateVisible,
          candidateList: this.candidateList,
          branchList: _vm.branchList,
          formData: _vm.dataForm,
          isCustomCopy: _vm.isCustomCopy,
        },
        on: {
          "update:visible": function ($event) {
            _vm.candidateVisible = $event
          },
          submitCandidate: _vm.selfHandleRequest,
        },
      }),
      _c("error-form", {
        attrs: { visible: _vm.errorVisible, nodeList: _vm.errorNodeList },
        on: {
          "update:visible": function ($event) {
            _vm.errorVisible = $event
          },
          submit: _vm.handleError,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }