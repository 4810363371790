import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
  },
  data() {
    return {
      tableOption: {
        selectable: (row) => {
          return row.accStatus == 'PREPARE' && row.createUser == this.userInfo.user_id
        },
        menuFixed:'right',
        linklabel: 'accCode',
        column: [{
          label: "事故编号",
          prop: "accCode",
          align: "left",
          overHidden: true,
          width: 150
        },
          {
            label: "事故标题",
            prop: "accTitle",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "事故发生地点",
            prop: "address",
            align: "center",
            overHidden: true,
          },
          {
            label: "事故等级",
            prop: "accLevel",
            align: "left",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=ACC_LEVEL`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true,
          },
          {
            label: "发生时间",
            prop: "accTime",
            width: 170,
            overHidden: true
          },

          {
            label: "报告时间",
            prop: "reportTime",
            align: "center",
            width: 120,
            overHidden: true
          },

          {
            label: "报告人",
            prop: "userName",
            align: "center",
            overHidden: true
          },

          {
            label: "状态",
            prop: "accStatus",
            align: "center",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=ACC_STATUS`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },
        ],
      },
      tableData: [],
      tableLoading: true,
    }
  },
}
