<template>
    <el-container class="managementView">
      <CommonTree ref="commonTreeCore" treeTitle="组织列表" :searchTitle="searchTitle" :treeData="treeData" :defaultProps="defaultProps" @getNodeClick="treeNodeClick" :isShowdig="false" :showCheckbox="false"  node-key="id" />

      <el-main>
        <el-tabs style="padding: 0 12px" v-model="activeName">
          <el-tab-pane label="隐患清单" name="ysqd"></el-tab-pane>
        </el-tabs>
<!--        <grid-head-layout @export-Fun="exportFun" btn-span="1" ref="gridHeadLayout" :grid-head-btn="gridHeadBtn" :search-columns="searchColumns" :searchSpan="2"-->
<!--                          @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>-->
        <div class="searchTopBox">
          <div class="leftSearch">
            <div style="width: 100%;display: flex;margin-bottom: 6px">
              <el-input v-model="searchForm.hdCode" size="mini" placeholder="请输入隐患编号" class="searchBox"></el-input>
              <el-input v-model="searchForm.hdDesc" size="mini" placeholder="请输入隐患描述" class="searchBox"></el-input>
              <el-input v-model="searchForm.location" size="mini" placeholder="请输入隐患地点" class="searchBox"></el-input>
              <el-select v-model="searchForm.hdLevel" placeholder="请选择隐患级别" size="mini" class="searchBox">
                <el-option
                  v-for="item in hdLevelData"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey">
                </el-option>
              </el-select>
            </div>
            <div style="width: 100%;display: flex">
              <el-select v-model="searchForm.hdStatus" placeholder="请选择状态" size="mini" class="searchBox">
                <el-option
                  v-for="item in hdStatusData"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey">
                </el-option>
              </el-select>
              <el-date-picker
                size="mini"
                class="searchBox"
                v-model="searchForm.startTime"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="请选择开始时间">
              </el-date-picker>
              <el-date-picker
                size="mini"
                class="searchBox"
                v-model="searchForm.endTime"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="请选择结束时间">
              </el-date-picker>
              <el-switch
                class="searchBox"
                style="height: 28px"
                active-value="1"
                inactive-value="0"
                v-model="searchForm.isTimeOut"
                inactive-text="是否超期">
              </el-switch>
            </div>
          </div>
          <div class="rightSearch">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-search" @click="gridHeadSearch"></el-button>
              <el-button size="mini" icon="reset-refresh icon-refresh" @click="gridHeadEmpty" style="height: 28px;"></el-button>
            </el-button-group>
            <el-button size="mini" style="margin-left: 36px" @click="exportFun">导出</el-button>
          </div>
        </div>
        <grid-layout ref="gridLayOut" :grid-row-btn="gridRowBtn" :table-options="tableOptions" :table-data="tableData"
                     @grid-row-detail-click="rowDetail"
                     :table-loading="tableLoading" :data-total="dataTotal" @page-current-change="onLoad" @page-size-change="onLoad"
                     @page-refresh-change="onLoad" >
          <template #hdLevel="{row}">
            <el-tag v-if="hdLevel[row.hdLevel]" :type="hdLevelIcon[row.hdLevel]"
                    effect="dark">{{hdLevel[row.hdLevel]}}
            </el-tag>
          </template>
          <template #customBtn="{row}">
            <el-button style="margin: 0 3px" type="text" size="small" @click="rowDetail(row,'view')" >查看</el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
</template>

<script>
import { mapGetters } from "vuex";
import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import dynTableDialog from "@/views/business/index/appIndex/common/dynTableDialog.vue";
import * as API from "@/api/riskitemView/checklist";
const CONTROLLER_NAME = "/index/indexcategory";
import riskView from "@/views/hiddenTrouble/riskItemView/riskView";
import {getLazyDeptTree} from "@/api/system/dept";
import {exportFile} from "@/api/riskitemView/checklist";
import {downloadXls} from "@/util/util";
import {getDictionary} from "@/api/system/dictbiz";
import {deptChildTree} from "@/api/reportTemplate";

export default {
  name: "index",
  mixins:[riskView],
  data() {
    return {
      form: {
        parentId: '',
      },
      searchForm:{
        isTimeOut:'0'
      },
      activeName:'ysqd',
      page:{
        currentPage:1,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      },
      treeReadOnlyFlag: false,
      dataTotal: null,
      tableData: [],
      treeLoading: false,
      tableLoading: false,
      crudLoading: false,
      treeData: [],
      query: {
        themeId: "",
      },
      indexCatCode: '', // 点击左侧树的code
      isTheme: '',
      node: {},   //左侧树node
      searchTitle: 'deptName',
      defaultProps: {
        children: "children",
        label: "deptName",
      },
      dataForm: {},
      hdLevelData:[],
      hdStatusData:[]
    };
  },
  props: {
    dataFormExt: {},
    shouIndexManage: {
      default: true
    }
  },
  computed: {
    ...mapGetters(["permission","userInfo"]),

    tableOptions() {
     return {
       height:500,
       selection:false,
       linklabel: "hdCode",
       column: [{
         label: "隐患编号",
         prop: "hdCode",
         align: "left",
         overHidden: true,
         width: 200
       },
         {
           label: "隐患级别",
           prop: "hdLevel",
           align: "center",
           width: 150,
           type: 'select',
           dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
           props: {
             label: 'dictValue',
             value: 'dictKey',
           },
           slot:true,
         },
         {
           label: "隐患地点",
           prop: "location",
           align: "center",
           width: 120,
           overHidden: true
         },
         {
           label: "所属组织",
           prop: "organizationName",
           align: "left",
           width: 120,
           overHidden: true
         },
         {
           label: "报告人",
           prop: "reportUserName",
           align: "center",
           width: 120,
           overHidden: true
         },
         {
           label: "责任部门",
           prop: "respDeptName",
           align: "left",
           overHidden: true
         }, {
           label: "整改期限",
           prop: "rectifyLimitDate",
           align: "left",
           overHidden: true
         },
         {
           label: "状态",
           prop: "hdStatus",
           align: "center",
           type: 'select',
           dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
           props: {
             label: 'dictValue',
             value: 'dictKey',
           },
           overHidden: true
         },
         {
           label: "创建时间",
           prop: "createTime",
           align: "center",
           overHidden: true
         },

       ],
     }
    },
    gridHeadBtn() {
      return [
        {
          emit: "export-Fun",
          type: "button",
          btnOptType: "export",
        },
      ]
    },
    searchColumns() {
      return [{
        label: '隐患编号',
        prop: "hdCode",
        span: 3,
        placeholder: '请输入隐患编号'
      },{
        label: '隐患描述',
        prop: "hdDesc",
        span: 3,
        placeholder: '请输入隐患描述'
      },
        {
          label: '地点',
          prop: "location",
          span: 3,
          placeholder: '请输入地点'
        },
        {
          label: '隐患级别',
          prop: "hdLevel",
          span: 2,
          type: 'select',
          placeholder: '请选择隐患级别',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },{
          label: '状态',
          prop: "hdStatus",
          span: 2,
          type: 'select',
          placeholder: '请选择状态',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          label: '开始时间',
          prop: "startTime",
          type: 'date-format',
          span: 3,
          placeholder: '请选择开始时间'
        },
        {
          label: '结束时间',
          prop: "endTime",
          type: 'date-format',
          span: 3,
          placeholder: '请选择结束时间'
        },
        {
          inactiveText: "是否超期",
          span: 2,
          activeValue:'1',
          inactiveValue:'0',
          prop:'isTimeOut',
          type: 'switch',
        }
      ]
    },
    gridRowBtn() {
      return []
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    dynTableDialog
  },
  created() {
    let p1 = sessionStorage.getItem("zbparms")

    p1 = JSON.parse(p1);
    let p2 = JSON.parse(JSON.stringify(p1))
    if (p1) {
      if (p1.query.themeId) {
        this.themeId = p1.query.themeId
        this.query.themeId = p1.query.themeId
      }
      this.$nextTick(() => {
        this.searchForm = p2.query
        this.onLoad({ pageSize: p2.size, currentPage: p2.current }, p2.query)
      })
    }
    this.lazyDeptTree();
    this.getDictory();
  },

  methods: {
    getDictory() {
      getDictionary({
        code:'hd_level'
      }).then((res) =>{
        this.hdLevelData = res.data.data;
      })
      getDictionary({
        code:'hd_status'
      }).then((res) =>{
        this.hdStatusData = res.data.data;
      })
    },
    //获取树数据
    lazyDeptTree() {
      deptChildTree().then((res) => {
        this.treeData = [res.data.data];
        this.node=res.data.data
        this.$nextTick(()=>{
          this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(this.node.id)
          this.treeNodeClick(this.node)
        })
      });
    },
    rowDetail(row) {
      this.$router.push({
        path: `/hiddenTrouble/managementView/edit`,
        query: {
          id: row.id,
          pageType: 'view'
        }
      })
    },
    exportFun(){
      API.exportFile({organizationId:this.node.id,...this.searchForm}).then(res=>{
        downloadXls(res.data,this.node[this.searchTitle])
      })
    },
    gridHeadSearch() {
      this.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, this.searchForm)
    },
    gridHeadEmpty() {
      this.searchForm = {
        isTimeOut:'0'
      };
      this.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, this.searchForm)
    },
    treeNodeClick(node) {
      this.node = node;
      this.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, this.searchForm);
    },
    onLoad(page, params = {}) {
      this.crudLoading = true;
      API.getList({
        current:page.currentPage,
        size:page.pageSize,
        organizationId:this.node.id,
        ...params
      })
        .then((res) => {
          const data = res.data.data;
          this.dataTotal = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
          sessionStorage.setItem("zbparms", p2)
          this.$nextTick(() => {
            this.searchForm = p1.query
          })

        })
        .catch(() => {
          this.crudLoading = true;
        });
    },
  },
};
</script>
<style lang="scss" >
.managementView{
  .searchTopBox {
    padding: 14px 12px;
    width: calc(100% - 24px);
    display: flex;
    justify-content: space-between;
  }
  .avue-crud .el-table {
    height: calc(100vh - 314px) !important;
    max-height: calc(100vh - 314px) !important;
  }
  .leftSearch {
    width: 70%;
    //display: flex;
  }
  .searchBox{
    width: 25% !important;
    margin-right: 5px;
  }
}

</style>

