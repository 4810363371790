var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-main",
    [
      _c("grid-layout", {
        ref: "gridLayOut",
        staticClass: "disposeOf",
        attrs: {
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": false,
        },
      }),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "managementForm",
          staticStyle: { "margin-right": "20px" },
          attrs: {
            model: _vm.dataForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "wasteType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { value: "1" },
                          on: { change: _vm.handleTypeChange },
                          model: {
                            value: _vm.dataForm.processType,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "processType", $$v)
                            },
                            expression: "dataForm.processType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("委托处置"),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("内部利用"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataForm.processType == "1"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "处置单位", prop: "processOrg" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "200", "show-word-limit": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c("el-button", {
                                        staticClass: "search-button",
                                        attrs: {
                                          icon: "el-icon-search",
                                          size: "small",
                                        },
                                        on: { click: _vm.handleSearch },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3788840743
                            ),
                            model: {
                              value: _vm.dataForm.processOrg,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "processOrg", $$v)
                              },
                              expression: "dataForm.processOrg",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.dataForm.processType == "1"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "许可证号", prop: "licences" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入许可证号",
                              maxlength: "200",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.dataForm.licences,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "licences", $$v)
                              },
                              expression: "dataForm.licences",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "处置时间", prop: "processTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "请选择处置时间",
                        },
                        model: {
                          value: _vm.dataForm.processTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "processTime", $$v)
                          },
                          expression: "dataForm.processTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.dataForm.processType == "1"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "回执文件", prop: "documentUrl" } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action:
                                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                headers: _vm.headers,
                                "file-list": _vm.fileList,
                                file: "file",
                                "on-preview": _vm.handleFileCoverPreview,
                                "on-success": _vm.handleFileCoverSuccess,
                                "on-change": _vm.uploadImgChange,
                                "on-remove": _vm.handleRemove,
                                accept: ".doc,.docx,.pdf,.png,.jpg,.zip",
                                limit: _vm.limitCountImg,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataForm.processType == "2"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "处置详情", prop: "processDetail" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              maxlength: "500",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.dataForm.processDetail,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "processDetail", $$v)
                              },
                              expression: "dataForm.processDetail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "处置记录",
            visible: _vm.managementVisible,
            "modal-append-to-body": false,
            "append-to-body": true,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.managementVisible = $event
            },
          },
        },
        [
          _c("managementHistory", {
            ref: "managementHistory",
            on: { getManagement: _vm.getManagement },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.imageVisible,
            width: "60%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.imageVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: {
              "margin-top": "20px",
              width: "100%",
              height: "auto",
              display: "block",
            },
            attrs: { src: _vm.imageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }