<template>
  <div style="position: relative">
    <div>
      <!-- 闸机列表 -->
      <head-layout head-title="算法模型" :head-btn-options="headBtnOptions" @head-add="handleAdd" @head-import="handleImport" @head-export="handleExport" @head-user="handleUser"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout class="table-container" ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template slot="gateMachineVOList" slot-scope="{ row }">
          <div v-if="row.gateMachineVOList && row.gateMachineVOList.length">
            <span v-for="item in row.gateMachineVOList" :key="item.id">
              {{item.eqtCode}}</span>
          </div>
        </template>
        <template #customBtn="{ row }">
          <el-button style="margin: 0 3px" type="text" size="small" @click="openVersion(row)">历史版本
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel(row)">下载
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowUpdate(row)">编辑
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel(row)">删除
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="openIdentify(row)">识别配置
          </el-button>
        </template>
      </grid-layout>
    </div>
    <addModel ref="addModel" @callback="onLoad(page, {})"></addModel>
    <el-drawer title="算法版本" :modal-append-to-body="false"  :visible.sync="drawerVersion" direction="rtl" :before-close="handleClose" size='80%'>
      <algorithmVersion ref='algorithmVersion' :checkRowData="checkRowData"></algorithmVersion>
    </el-drawer>
    <el-drawer title="识别配置" :modal-append-to-body="false"  :visible.sync="drawerIdentify" direction="rtl" :before-close="handleCloseIdentify" size='80%'>
      <identifyConfiguration ref='identifyConfiguration' :checkRowData="checkRowData"></identifyConfiguration>
    </el-drawer>

  </div>
</template>

<script>
  import {
    pageList,
    add,
    remove
  } from "@/api/safe/algorithmModel";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import addModel from "./addModel";
  import {
    getAgeFromIdCard
  } from "@/util/util";
  import {
    exportBlob
  } from "@/api/common";
  import {
    dateNow
  } from "@/util/date";
  import {
    getToken
  } from "@/util/auth";
  import {
    downloadXls
  } from "@/util/util";
  import {
    ALGO_MODEL_CATEGORY
  } from '../algorithmManagement/nodeDict.js'
import algorithmVersion from './algorithmVersion/list'
import identifyConfiguration from './identifyConfiguration/list'

  export default {
    components: {
      addModel,
      GridLayout,
      HeadLayout,
      algorithmVersion,
      identifyConfiguration
    },
    props: {
      prjId: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        drawerVersion:false,
        drawerIdentify:false,
        page: {
          pageSize: 20,
          pageIndex: 1,
          total: 0,
        },
        selectionList: [],
        tableOption: {
          linklabel: "code",
          menuWidth:260,
          selection: false,
          column: [{
              label: "编码",
              prop: "code",
              align: "center",
              overHidden: true,
            },
            {
              label: "名称",
              prop: "name",
              align: "center",
              overHidden: true,

            },
            {
              label: "类别",
              prop: "category",
              align: "center",
              props: {
                label: "name",
                value: "value",
              },
              dicData: ALGO_MODEL_CATEGORY,

            },

            {
              label: "创建时间",
              prop: "createdDate",
              align: "center",
              overHidden: true,
            },

          ],
        },
        checkRowData:'',
        headBtnOptions: [{
            label: "上传模型",
            emit: "head-add",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },

        ],
        searchColumns: [{
            label: "编码/名称",
            prop: "visitName",
            span: 4,
            placeholder: "请输入编码/名称",
          },
          {
            label: "类别",
            prop: "visitStatus",
            span: 4,
            placeholder: "请选择类别",
            props: {
              label: "name",
              value: "value",
            },
            type: "select",
            dicData: ALGO_MODEL_CATEGORY,
          },


        ],
        tableData: [],
        excelBox: false,
        excelOption: {
          submitBtn: false,
          emptyBtn: false,
          excelForm: {},
          column: [{
              label: "数据导入",
              prop: "excelFile",
              type: "upload",
              drag: true,
              loadText: this.$t("cip.plat.sys.user.field.waiting"),
              span: 24,
              propsHttp: {
                res: "data",
              },
              tip: this.$t("cip.plat.sys.user.field.format"),
              action: "/api/sinoma-hse-prj/thirduser/import",
            },

            {
              label: this.$t("cip.plat.sys.user.field.excelTemplate"),
              prop: "excelTemplate",
              formslot: true,
              span: 24,
            },
          ],
        },
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirduser_add, false),
          viewBtn: this.vaildData(this.permission.thirduser_view, false),
          delBtn: this.vaildData(this.permission.thirduser_delete, false),
          editBtn: this.vaildData(this.permission.thirduser_edit, false),
        };
      },
    },

    mounted() {
      this.onLoad(this.page, {});
    },
    methods: {
      handleAdd() {
        this.$refs.addModel.init("add", null, this.prjId);
      },
      rowUpdate(row) {
        this.$refs.addModel.init("edit", row, this.prjId);
      },
      //查看
      rowView(row) {
        this.$refs.addModel.init("view", row, this.prjId);
      },
      openVersion(row){
        this.checkRowData=row
        this.drawerVersion=true
      },
      openIdentify(row){
        this.checkRowData=row
        this.drawerIdentify=true
      },
      handleClose(){
        this.drawerVersion=false
      },
      handleCloseIdentify(){
        this.drawerIdentify=false
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove({
              id: row.id
            });
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.gridLayOut.selectionClear();
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.crud.toggleSelection();
          });
      },
      // 搜索
      gridHeadSearch(searchForm) {
        this.page.pageIndex = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.pageIndex = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.pageIndex = page.pageIndex
        params.pageSize = page.pageSize
        pageList(Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.tableLoading = false;
          } else {
            this.tableLoading = false;
          }
        });
      },
      // 导入
      handleImport() {
        this.excelBox = true;
        this.excelOption.excelForm.excelFile = [];
      },
      uploadAfter(res, done, loading, column) {
        window.console.log(column);
        this.excelBox = false;
        this.onLoad(this.page);
        this.excelOption.excelForm.excelFile = [];
        done();
      },
      handleTemplate() {
        exportBlob(
          `/api/sinoma-hse-prj/thirduser/exportTemplate?${
          this.website.tokenHeader
        }=${getToken()}`
        ).then((res) => {
          downloadXls(res.data, "相关方人员模板" + ".xlsx");
        });
      },
      // 导出
      handleExport() {
        this.$confirm("是否导出相关方人员数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          exportBlob(
            `/api/sinoma-hse-prj/thirduser/export?userName=${
            this.$refs.gridHeadLayout.searchForm.userName
              ? this.$refs.gridHeadLayout.searchForm.userName
              : ""
          }&isUsed=${
            this.$refs.gridHeadLayout.searchForm.isUsed
              ? this.$refs.gridHeadLayout.searchForm.isUsed
              : ""
          }&updateTime=${
            this.$refs.gridHeadLayout.searchForm.updateTime
              ? this.$refs.gridHeadLayout.searchForm.updateTime
              : ""
          }&${this.website.tokenHeader}=${getToken()}`
          ).then((res) => {
            downloadXls(res.data, `相关方人员${dateNow()}.xlsx`);
          });
        });
      },
    },
  };

</script>

<style lang="less" scoped>
  ::v-deep .el-drawer__header{
    margin-left:25px;
    margin-top:16px;
  }
  ::v-deep .table-container {
    .el-table {
      height: calc(100vh - 334px) !important;
      max-height: calc(100vh - 334px) !important;
    }
  }

</style>
