<template>
    <div class="parent_height">
      <!-- 项目区域 -->
      <el-container v-if="!isShowEdit">
        <!-- <CommonTree
          class="commonTreeCore"
          ref="commonTreeCore"
          treeTitle="区域分类"
          :treeData="treeData"
          nodeKey="id"
          searchTitle="typeName"
          :defaultProps="{ label: 'typeName' }"
          :isShowdig="!this.dialogType"
          :expandOnClickNode="false"
          @getTreeAdd="getTreeAdd"
          @getTreeEdit="getTreeEdit"
          @getTreeDelete="getTreeDelete"
          @getNodeClick="handleNodeClick"
        /> -->
        <CommonTreeNew
          class="commonTreeCore"
          ref="commonTreeCore"
          treeTitle="区域分类"
          :isShowSearchInput="false"
          :treeData="treeData"
          nodeKey="id"
          searchTitle="typeName"
          :defaultProps="{ label: 'typeName' }"
          :isShowdig="!this.dialogType"
          :expandOnClickNode="false"
          @getTreeAdd="getTreeAdd"
          @getTreeEdit="getTreeEdit"
          @getTreeDelete="getTreeDelete"
          @getNodeClick="handleNodeClick"
        >
          <template slot="header">
            <avue-input-tree 
              size="mini" 
              class="treeHeader" 
              :nodeClick="handleTree"
              :props="{label:'title',value:'id'}" 
              v-model="parentId" 
              placeholder="请选择内容"
              :dic="treeDic"></avue-input-tree>
          </template>
        </CommonTreeNew>
        <el-main>
          <head-layout
            head-title="项目区域"
            :showIcon="false"
            :head-btn-options="headBtnOptions"
            @head-add-tabs="headAdd"
            @head-remove="headRemove"
          />
          <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
          <!--  -->
          <grid-layout
            class="itemListGrid"
            ref="gridLayOut"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @page-current-change="handleCurrentChange"
            @page-size-change="handleSizeChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
            :grid-row-btn="gridRowBtn"
            @row-edit="rowEdit"
            @row-remove="rowRemove"
            @row-change="rowChange"
            @row-down="rowDown"
          >
            <template #areaCode="{row,index}">
              <span v-if="type=='view'">{{ row.areaCode }}</span>
              <el-link type="primary" @click="rowEdit(row)" v-else>{{ row.areaCode }}</el-link>
            </template>
            <template slot="codeUrl" slot-scope="{ row }">
              <div style="padding: 5px 0">
                <el-image style="width: 40px;height: 40px;vertical-align:middle" :src="`data:image/png;base64,${row.baseCode.codeUrl}`" :preview-src-list="[`data:image/png;base64,${row.baseCode.codeUrl}`]"></el-image>
              </div>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
      <!-- 区域分类左侧树操作 -->
      <el-dialog
        :visible.sync="dialogVisible"
        title="区域分类"
        :before-close="handleTreeCancel"
      >
        <avue-form
          :option="treeDialogOption"
          ref="cedForm"
          v-model="treeForm"
          @submit="handleSubmit"
        ></avue-form>
        <template slot="footer">
          <el-button
            size="small"
            @click="handleTreeCancel()"
            v-loading="treeDialogLoading"
          >
            {{ $t(`cip.cmn.btn.celBtn`) }}
          </el-button>
          <el-button
            size="small"
            type="primary"
            @click="handleTreeNodeSave"
            v-loading="treeDialogLoading"
          >
            {{ $t(`cip.cmn.btn.defBtn`) }}
          </el-button>
        </template>
      </el-dialog>
      <projectAreaAdd ref="projectAreaAdd" v-if="isShowEdit" :prjId="prjId" :typeId="typeId" :areaId="areaId"  @callback="handleCallback" class="parent_height"/>
      <qrcodeDialog ref="qrcodeDialog"></qrcodeDialog>
    </div>
  </template>

  <script>
  import CommonTree from "@/views/components/com_tree/index";
  import HeadLayout from "@/views/components/layout/head-layout.vue";
  import GridLayout from "@/views/components/layout/grid-layout.vue";
  import qrcodeDialog from "./qrcode.vue";
  import {getDeptTree} from "@/api/system/dept";

  import {
    getAreaClassTree,
    getClassSubmit,
    getClassRemove,
    getAreaClassPage,
    getAreaClassLinRemove,
  } from "@/api/safe/projectArea";
  import { mapGetters } from "vuex";
  import projectAreaAdd from "./form.vue";

  export default {
    props: {
      dialogType: {
        type: Boolean,
        default: false
      }
    },
    components: {
      CommonTree,
      HeadLayout,
      GridLayout,
      projectAreaAdd,
      qrcodeDialog,
    },

    data() {
      return {
        treeData: [],
        dialogVisible: false,
        treeForm: {
          typeName: "",
          parentId: "",
          id: "",
          prjId: "",
        },
        treeDialogLoading: false,
        nodeData: {},
        tableData: [],
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        query: {},

        treeParentData: [],
        prjId: "", // 项目id
        selectionList: [],
        searchColumns: [
          {
            label: "",
            prop: "areaName",
            span: 4,
            placeholder: "请输入区域名称",
          },
        ],
        isShowEdit: false,
        typeId: '',
        areaId: '',
        type: '',
        treeDic: [],
        parentId: '',
      };
    },

    computed: {
      ...mapGetters(["permission", "colorName",'userInfo']),
      ids() {
        let ids = [];
        this.selectionList.forEach((ele) => {
          ids.push(ele.id);
        });
        return ids.join(",");
      },

      headBtnOptions() {
        if (this.$route.query.type != "view" && !this.dialogType) {
          return [
            {
              label: this.$t(`cip.cmn.btn.addBtn`),
              emit: "head-add-tabs",
              type: "button",
              icon: "",
              btnOptType: "add",
            },
            {
              label: this.$t("cip.cmn.btn.delBtn"),
              emit: "head-remove",
              type: "button",
              icon: "",
            },
          ];
        }
      },

      tableOptions() {
        return {
          menu:this.$route.query.type != 'view',
          selection: this.$route.query.type != "view" && !this.dialogType,
          column: [
            {
              label: "区域编码",
              prop: "areaCode",
              align: "center",
              minWidth: 120,
              overHidden: true,
              slot: true
            },
            {
              label: "区域名称",
              prop: "areaName",
              align: "center",
              minWidth: 200,
              overHidden: true,
            },
            {
              label: "二维码",
              prop: "codeUrl",
              align: "center",
              minWidth: 200,
              overHidden: true,
              slot: true
            },
            {
              label: "所属分类",
              prop: "typeId",
              align: "center",
              minWidth: 200,
              overHidden: true,
              type: 'tree',
              dicData: this.treeData,
              props: {
                label: 'typeName',
                value: 'id'
              }
            },
          ],
        };
      },

      gridRowBtn() {
        if (this.$route.query.type != "view" && !this.dialogType) {
          return [
            {
              label: this.$t(`cip.cmn.btn.editBtn`),
              emit: "row-edit",
              type: "button",
              icon: "",
            },
            {
              label: this.$t(`cip.cmn.btn.delBtn`),
              emit: "row-remove",
              type: "button",
              icon: "",
            },
            {
              label: "下载二维码",
              emit: "row-down",
              type: "button",
              icon: "",
            },
          ];
        }
        if (this.dialogType) {
          return [
            {
              label: '选择',
              emit: "row-change",
              type: "button",
              icon: "",
            },
          ];
        }
      },

      treeDialogOption() {
        return {
          size: "small",
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 120,
          column: [
            {
              label: "分类名称",
              prop: "typeName",
              maxlength: 20,
              rules: [
                {
                  required: true,
                  message: "请输入分类名称",
                  trigger: ["blur"],
                },
              ],
            },
            {
              label: "上级名称",
              prop: "parentId",
              type: "tree",
              disabled: false,
              dicData: this.treeParentData,
              props: {
                label: "typeName",
                value: "id",
              },
              rules: [
                {
                  validator: this.partentRule,
                },
              ],
            },
          ],
        };
      },
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.getTreeDic()
        this.query = {};
      },
      partentRule(rule, value, callback) {
        if (value === "" || !this.treeForm.id) {
          callback();
        } else if (value == this.treeForm.id) {
          callback(new Error("不能选择自己作为上级!"));
        } else {
          callback();
        }
      },
      /** 左侧列表操作 */
      getTreeDic () {
        let parentId = this.userInfo.dept_id
        getDeptTree('', '5,2', parentId).then(res => {
          this.treeDic = this.setDisabled(res.data.data)
          this.parentId = this.treeDic[0].id;
          if (this.treeDic[0].deptCategory == 5) {
            // this.treeData = undefined
            // this.getTreeData(this.treeDic[0].id)
            this.handleTree(this.treeDic[0])
          }
        })
      },
      setDisabled(tree) {
        tree.forEach(item => {
          if (item.children) {
            this.setDisabled(item.children)
          } 
          item.disabled = item.deptCategory == 5 || item.deptCategory == 3 ? false : true;
        })
        return tree
      },
      handleTree (data) {
        console.log('',data)
        this.prjId = data.id;
        this.treeForm.prjId = this.prjId;
        this.getTreeData()
        this.onLoad(this.page)
      },
      getTreeData() {
        getAreaClassTree({ prjId: this.prjId }).then((res) => {
          this.treeData = res.data.data;
          // this.$nextTick(() => {
          //     this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
          //         this.treeData[0].id
          //     );
          // });
        });
      },
      handleNodeClick(data, node) {
        this.nodeData = data;
        this.query.typeId = data.id;
        this.page.currentPage = 1;
        this.onLoad(this.page);
      },
      getTreeAdd() {
        this.treeParentData = this.treeData;
        this.treeForm = {
          typeName: "",
          parentId: "0",
          id: "",
          prjId: this.prjId,
        };
        if (this.nodeData.id) this.treeForm.parentId = this.nodeData.id;
        this.dialogVisible = true;
      },
      getTreeEdit() {
        const data = JSON.parse(JSON.stringify(this.treeData));
        const { typeName, id, parentId, prjId } = this.nodeData;
        this.getParentData(data, id);
        this.treeForm = { typeName, id, parentId, prjId };
        this.dialogVisible = true;
      },
      getParentData(data, id) {
        const arr = data.map((val, index) => {
          if (val.id == id) {
            data.splice(index, 1);
          } else if (val.children) {
            this.getParentData(val.children, id);
            return val;
          } else {
            return val;
          }
        });
        this.treeParentData = arr;
      },
      getTreeDelete(data, done) {
        if (data.children && data.children.length) {
          this.$message({
            type: "warning",
            message: "当前分类下有子级，禁止删除",
          });
          return;
        }
        this.$confirm("确认删除数据", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            getClassRemove(this.nodeData.id).then((res) => {
              if (res.data.code == 200) {
                this.getTreeData();
                this.$message.success("操作成功");
              }
            });
          })
          .catch(() => {});
      },
      handleTreeCancel() {
        this.$refs.cedForm.resetFields();
        this.dialogVisible = false;
      },
      handleSubmit(data, done) {
        this.$refs.cedForm.validate((valid) => {
          if (valid) {
            this.treeDialogLoading = true;
            getClassSubmit(this.treeForm)
              .then(() => {
                this.getTreeData();
                this.$message.success("操作成功");
                this.treeDialogLoading = false;
                this.handleTreeCancel();
                done();
              })
              .catch((err) => {
                done();
              })
              .finally(() => {
                this.treeDialogLoading = false;
              });
          }
        });
      },
      handleTreeNodeSave() {
        if (!this.treeForm.parentId) this.treeForm.parentId = "0";
        this.$refs.cedForm.submit();
      },
      /**----end---- */
      // 筛选列表
      // 搜索
      gridHeadSearch(searchForm) {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        this.onLoad(this.page);
      },
      /** 右侧表格操作 */
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.prjId = this.prjId;
        getAreaClassPage(
          page.currentPage,
          page.pageSize,
          Object.assign(page.currentPage, page.pageSize, params, this.query)
        ).then((res) => {
          const data = res.data.data;
          // this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total
          this.tableData = data.records;
          this.tableLoading = false;
          //重新渲染表格，解决勾选框错行问题
          this.$nextTick(() => {
            this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
          });
          this.$refs.gridLayOut.selectionClear();
        }).catch(()=>{
          this.tableLoading = false;
        })
      },
      //页数改变
      handleCurrentChange(e) {
        this.page.currentPage = e.currentPage;
        this.onLoad(this.page,this.$refs.gridHeadLayout.searchForm);
      },
      //size改变
      handleSizeChange(e) {
        this.page.currentPage = 1;
        this.page.pageSize = e.pageSize;
        this.onLoad(this.page,this.$refs.gridHeadLayout.searchForm);
      },
      // 选择列表数据
      selectionChange(list) {
        this.selectionList = list;
      },
      // 批量删除
      headRemove() {
        let selectionList = this.$refs.gridLayOut.selectionList;
        if (selectionList.length === 0) {
          this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
          return;
        }
        this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: "warning",
        })
          .then(() => {
            return getAreaClassLinRemove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          });
      },
      rowEdit(row, index) {
        this.isShowEdit = true
        this.typeId = row.typeId
        this.areaId = row.id
          // this.$nextTick(()=>{
          //      this.$refs.projectAreaAdd.init("edit", row);
          // })
      },
      // 单条删除
      rowRemove(row, index) {
        this.$confirm("确定删除当前数据?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          getAreaClassLinRemove(row.id).then(() => {
            this.onLoad(this.page);
            this.$message.success("操作成功！");
          });
        });
      },
      headAdd() {
        if (this.nodeData.id) {
          // const data = { typeId: this.nodeData.id };
          this.typeId = this.nodeData.id
          this.areaId = ''
          this.isShowEdit = true
          // this.$nextTick(()=>{
          //      this.$refs.projectAreaAdd.init('add',{...data,prjId:this.prjId})
          // })
        } else {
          this.$message.warning("请先选择分类");
        }
      },

      handleCallback(type) {
        this.isShowEdit = false
        if(type=='submit'){
          this.page.currentPage = 1;
        }
        this.init();
      },

      rowChange (row) {
        this.$emit('prjAreaCallback', row)
      },

      // 下载二维码
      rowDown(row) {
        let qrcodeUrl = `data:image/png;base64,${row.baseCode.codeUrl}`
        this.$refs.qrcodeDialog.init(
          qrcodeUrl,
          row.areaName,
          row.areaCode,
          'haveTitle'
        );
      },
    },
  };
  </script>

  <style lang="scss" scoped>
  .parent_height{
    height: 100%
  }
  ::v-deep .el-container{
    height: 100%;
  }

  ::v-deep .itemListGrid .avue-crud .el-table {
    // height: calc(100vh - 340px) !important;
    // max-height: calc(100vh - 340px) !important;
    // box-sizing: border-box;
  }
  
  ::v-deep .el-upload {
    text-align: left;
  }
  .upload-text {
    font-size: 12px;
    color: #606266;
    margin-top: 7px;
  }
  .treeHeader{
    margin: 14px 12px;
    width: calc(100% - 24px)!important;
  }
  </style>
