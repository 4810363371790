var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { background: "#fff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "模型结果",
        },
        on: { "head-cancel": _vm.cancelFn },
      }),
      _c(
        "div",
        { staticStyle: { padding: "20px" } },
        [
          _c(
            "el-row",
            { staticStyle: { "margin-bottom": "20px" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm._v("\n        x轴: "),
                  _vm.xArr.length
                    ? _c(
                        "el-button",
                        { attrs: { plain: "", size: "mini", type: "danger" } },
                        [_vm._v(_vm._s(_vm.xArr))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm._v("\n        y轴:"),
                  _vm._l(_vm.yArr, function (item, index) {
                    return _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { plain: "", size: "mini", type: "primary" },
                      },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.resultTable },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "roll_gap", label: "roll_gap" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "raw_material_moisture",
                  label: "raw_material_moisture",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "raw_material_size",
                  label: "raw_material_size",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "classification", label: "classification" },
              }),
              _c("el-table-column", {
                attrs: { prop: "classification", label: "classification" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }