<template>
  <div>
    <el-container>
      <div>
        <div style="padding: 14px 12px 0 12px;background-color: #FFFFFF">
          <avue-input-tree
            default-expand-all
            v-form="deptId"
            placeholder="请选择项目组织"
            :dic="treeData"
            @change="deptChange"
            class="project-area-tree"
          />
        </div>
        <CommonTree
          :treeData="leftTreeData"
          :defaultProps="defaultProps"
          :searchTitle="searchTitle"
          treeTitle="区域分类"
          :isShowdig="false"
          :showCheckbox="false"
          @getNodeClick="getNodeClick"
        />
      </div>
      <el-container style="display:flex;">
        <el-main style="width: 40%">
          <head-layout
            head-title="区域"
          />
          <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="leftSearchColumns"
            @grid-head-search="leftGridHeadSearch"
            @grid-head-empty="leftGridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            class="itemListGrid"
            ref="leftGridLayOut"
            :table-options="leftTableOptions"
            :table-data="leftTableData"
            :table-loading="leftTableLoading"
            :data-total="leftPage.total"
            :page="leftPage"
            @page-current-change="handleLeftCurrentChange"
            @page-size-change="handleLeftSizeChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="leftSelectionChange"
            :grid-row-btn="gridRowBtn"
            @row-edit="rowEdit"
            @row-remove="rowRemove"
          >
          </grid-layout>
        </el-main>
        <el-main style="width: 60%">
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="摄像头管理"
            @head-remove="rowRemove"
            @head-add-tabs="headAddTabs"
          ></head-layout>
          <div class="headers">
            <grid-head-layout
              ref="gridHeadLayout"
              :search-columns="searchColumns"
              @grid-head-search="gridHeadSearch"
              @grid-head-empty="gridHeadEmpty"
            ></grid-head-layout>
          </div>
          <grid-layout
            class="itemListGrid"
            ref="gridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @gird-handle-select-click="selectionChange"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
            @page-refresh-change="onLoad"
            @grid-row-detail-click="rowDetail"
            @row-view="rowDetail"
            @row-remove="rowRemove"
            @row-edit="rowEdit">
          </grid-layout>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index"
import {getList, remove} from "@/api/desk/videoPlayerConfig";
import {selectUserChildrenWithoudProject} from "@/api/system/dept";
import {queryProjectAreaCategoryByDeptId} from "@/api/safe/itemList"
import {getAreaPage} from "@/api/safe/projectArea";

export default {
  name: "videoPlayerConfig",
  components: {CommonTree, HeadLayout, GridLayout},
  data() {
    return {
      deptId: '',
      defaultProps: {
        label: "typeName",
        value: "id"
      },
      treeData: [],
      leftTreeData: [],
      searchTitle: 'typeName',
      projectArea: null,
      query: {},
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      leftPage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      tableLoading: false,
      leftTableLoading: false,
      tableData: [],
      leftTableData: [],
      headBtnOptions: [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          // btnOptType: 'add',
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          // btnOptType: 'remove',
        },
      ],
      searchColumns: [
        {
          prop: "name",
          span: 6,
          maxlength: 20,
          minlength: 2,
          placeholder: '请输入摄像头名称',
        },
      ],
      leftSearchColumns: [
        {
          label: "",
          prop: "areaName",
          span: 8,
          maxlength: 20,
          minlength: 2,
          placeholder: "请输入区域名称",
        },
      ],
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        },
        {
          label: '查看',
          emit: "row-view",
          type: "button",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        }
      ],
      tableOptions: {
        customAddBtn: true,
        linklabel: "name",
        menuWidth: 150,
        column: [
          {
            label: '摄像头名称',
            prop: 'name',
            overHidden: true,
            width: 200,
            align: 'center',
          },
          {
            label: '项目区域',
            prop: 'projectAreaName',
            align: 'center',
            width: 200,
            overHidden: true,
          },
          {
            label: '视频流地址',
            prop: 'address',
            align: 'left',
            width: 200,
            overHidden: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            overHidden: true,
            width: 200,
            align: 'center',
          },
        ],
      },
      selectList: [],
      leftSelectList: []
    }
  },
  computed: {
    leftTableOptions() {
      return {
        menu: false,
        selection: this.$route.query.type != "view",
        column: [
          {
            label: "区域名称",
            prop: "areaName",
            align: "center",
            overHidden: true,
          },
          {
            label: "区域编码",
            prop: "areaCode",
            align: "center",
            overHidden: true,
          },
          {
            label: "创建人单位",
            prop: "createDeptName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },
  created() {
    // this.onLoad(this.page, {})
  },
  watch:{
    leftTableData:{
      handler(newValue,oldValue){
        if (newValue.length < 1) return
        this.onLoad(this.page, {projectAreaId: newValue.map(e=>e.id).join(',')})
      }
    }
  },
  mounted() {
    this.lazyDeptTree()
  },
  methods: {
    handleLeftCurrentChange() {
      this.leftPage.currentPage = e.currentPage;
      this.onLoad(this.leftPage);
    },
    handleLeftSizeChange() {
      this.leftPage.currentPage = 1;
      this.leftPage.pageSize = e.pageSize;
      this.onLoad(this.leftPage);
    },
    deptChange: async function (value) {
      this.leftTreeData = []
      this.leftTableData = []
      this.tableData = []
      if(!value) return
      this.$loading()
      await queryProjectAreaCategoryByDeptId({deptId: value}).then(res => {
        this.leftTreeData = res.data.data.areaClass
        this.prjId = res.data.data.typeIds
      })
      this.$loading().close()
      if (this.leftTreeData && this.leftTreeData.length > 1 )this.leftOnLoad(this.leftPage,{typeIds:this.leftTreeData.map(e=>e.id).join(',')})

    },
    //获取树数据
    lazyDeptTree() {
      selectUserChildrenWithoudProject().then((res) => {
        let resData = res.data.data
        this.forEachTree(resData)
        this.treeData = resData
      });
    },
    forEachTree(treeData) {
      treeData.forEach(e => {
        e.label = e.title
        if (e.children && e.children.length > 0) {
          this.forEachTree(e.children)
        }
      })
    },
    selectionChange(list) {
      this.selectList = list
    },
    leftSelectionChange(list) {
      this.leftSelectList = list
      if (list.length < 1) list = this.leftTableData
      if (list.length < 1) return
      console.log(list)
      this.onLoad(this.page, {projectAreaId: list.map(e=>e.id).join(',')})
    },
    getNodeClick(data) {
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.page, {typeId: data.id});
    },
    leftOnLoad(page, param = {}) {
      this.leftTableLoading = true;
      this.leftPage = page;
      getAreaPage(
        page.currentPage,
        page.pageSize,
        Object.assign(page.currentPage, page.pageSize, param)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.leftGridLayOut.page.total = data.total;
        this.leftTableData = data.records;
        this.leftTableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.leftGridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.leftGridLayOut.selectionClear();
      });
    },
    headAddTabs() {
      if (this.leftSelectList.length < 1) {
        return this.$message.warning('请选择一条区域信息')
      }
      if (this.leftSelectList.length > 1) {
        return this.$message.warning('只能选择一条区域信息')
      }
      this.$router.push({
        path: `/desk/videoPlayerConfig/add`,
        query: {
          type: 'add',
          pageType: 'add',
          projectArea: this.leftSelectList[0]
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/desk/videoPlayerConfig/add`,
        query: {
          type: 'view',
          id: row.id
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/desk/videoPlayerConfig/add`,
        query: {
          type: 'edit',
          id: row.id
        }
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          let ids = ''
          try {
            ids = row.id
          } catch (e) {
            ids = this.selectList.map(e => e.id).join(',')
          }
          remove(ids).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              this.onLoad(this.page);
            }
          });
        })
    },
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    leftGridHeadSearch(searchForm) {
      if (!this.prjId) return this.$message.warning('请选择项目组织')
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage, searchForm);
    },
    leftGridHeadEmpty() {
      // this.query = {};
      this.page.currentPage = 1;
      this.leftOnLoad(this.leftPage);
    },
    gridHeadEmpty() {
      this.query = {};
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        params
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }


  }
}
</script>

<style scoped lang="scss">
::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 268px) !important;
}
</style>
