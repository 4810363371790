var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "avue-skeleton",
        { attrs: { loading: _vm.waiting, avatar: "", rows: 8 } },
        [
          _c("avue-title", {
            staticStyle: { "margin-bottom": "20px" },
            attrs: { styles: { fontSize: "20px" }, value: _vm.process.name },
          }),
          _c(
            "el-card",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { shadow: "never" },
            },
            [_c("avue-form", { attrs: { option: _vm.option } })],
            1
          ),
          _vm.showExamForm
            ? _c(
                "el-card",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { shadow: "never" },
                },
                [
                  _c("wf-examine-form", {
                    ref: "examineForm",
                    attrs: { process: _vm.process },
                    on: { "user-select": _vm.handleUserSelect },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticStyle: { height: "120px" } }),
          _c(
            "el-row",
            {
              staticClass: "foot-item avue-affix",
              style: {
                width:
                  _vm.layoutType == "functional"
                    ? "100%"
                    : _vm.isCollapse
                    ? "calc(100% - 68px)"
                    : "calc(100% - 220px)",
              },
              attrs: { id: "avue-view" },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("发起")]
              ),
              _vm.permission.wf_process_draft
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { type: "success", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.handleDraft(
                            _vm.process.id,
                            _vm.process.formKey,
                            _vm.form
                          )
                        },
                      },
                    },
                    [_vm._v("存为草稿")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("wf-user-select", {
        ref: "user-select",
        attrs: {
          "check-type": _vm.checkType,
          "default-checked": _vm.defaultChecked,
        },
        on: { onConfirm: _vm.handleUserSelectConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }