var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("tree-layout", {
            ref: "treeLayout",
            staticStyle: { "margin-right": "5px" },
            attrs: {
              treeTitle: "组织架构",
              "tree-options": _vm.treeOptions,
              "default-expanded-keys": _vm.defaultExpandedKeys,
              "highlight-current": "",
              treeLoading: false,
            },
            on: { "tree-node-click": _vm.handleNodeClick },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "健康档案",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-save": _vm.handleHeadSave,
                  "head-donwnload": _vm.handleHeadDownload,
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { padding: "0 12px" },
                },
                [
                  _c(
                    "el-container",
                    [
                      _c(
                        "el-aside",
                        { staticStyle: { width: "200px" } },
                        [
                          _c("el-image", {
                            staticStyle: {
                              width: "178px",
                              height: "178px",
                              padding: "10px",
                            },
                            attrs: { src: _vm.form.avatar },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-main",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "form",
                              attrs: {
                                model: _vm.form,
                                "label-width": "85px",
                                rules: _vm.rules,
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "姓名:",
                                            prop: "userName",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.form.userName))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "性别:",
                                            prop: "sexName",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.form.sexName))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "出生年月:",
                                            prop: "birthday",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.form.birthday))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "所在部门:",
                                            prop: "deptName",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.form.deptName))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "岗位:",
                                            prop: "postName",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.form.postName))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "入职日期:",
                                            prop: "entryTime",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.form.entryTime))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "血型:",
                                            prop: "bloodType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: _vm.form.bloodType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "bloodType",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.bloodType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.bloodTypeList,
                                              function (item) {
                                                return _c(
                                                  "el-radio",
                                                  {
                                                    key: item.dictKey,
                                                    attrs: {
                                                      label: item.dictKey,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.dictValue)
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "建档日期:",
                                            prop: "documentDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "date",
                                              placeholder: "选择日期",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                            },
                                            model: {
                                              value: _vm.form.documentDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "documentDate",
                                                  $$v
                                                )
                                              },
                                              expression: "form.documentDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "职业禁忌:",
                                            prop: "contraindication",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              maxlength: "255",
                                              "show-word-limit": "",
                                            },
                                            model: {
                                              value: _vm.form.contraindication,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "contraindication",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.contraindication",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "接害因素:",
                                            prop: "hazard",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              maxlength: "255",
                                              "show-word-limit": "",
                                            },
                                            model: {
                                              value: _vm.form.hazard,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "hazard",
                                                  $$v
                                                )
                                              },
                                              expression: "form.hazard",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.id
                    ? [
                        _c(
                          "el-tabs",
                          {
                            attrs: { type: "border-card" },
                            on: { "tab-click": _vm.handleClick },
                            model: {
                              value: _vm.activeName,
                              callback: function ($$v) {
                                _vm.activeName = $$v
                              },
                              expression: "activeName",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "保险投保记录",
                                  name: "insurance",
                                },
                              },
                              [
                                _vm.activeName == "insurance"
                                  ? _c("insurance", {
                                      ref: "insurance",
                                      attrs: {
                                        active: _vm.activeName,
                                        documentInfo: _vm.form,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              {
                                attrs: { label: "危害接触史", name: "hazard" },
                              },
                              [
                                _vm.activeName == "hazard"
                                  ? _c("hazard", {
                                      ref: "hazard",
                                      attrs: {
                                        active: _vm.activeName,
                                        documentInfo: _vm.form,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "体检记录",
                                  name: "testRecords",
                                },
                              },
                              [
                                _vm.activeName == "testRecords"
                                  ? _c("testRecords", {
                                      ref: "testRecords",
                                      attrs: {
                                        active: _vm.activeName,
                                        documentInfo: _vm.form,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "劳保用品领用记录",
                                  name: "requisition",
                                },
                              },
                              [
                                _vm.activeName == "requisition"
                                  ? _c("requisition", {
                                      ref: "requisition",
                                      attrs: {
                                        active: _vm.activeName,
                                        documentInfo: _vm.form,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }