var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("h3", [_vm._v("标签")]),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small" },
          on: {
            click: function ($event) {
              return _vm.$router.push("/test/index")
            },
          },
        },
        [_vm._v("打开一个页面\n  ")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small" },
          on: {
            click: function ($event) {
              return _vm.$router.$avueRouter.closeTag("/test/index")
            },
          },
        },
        [_vm._v("关闭打开的页面\n  ")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small" },
          on: {
            click: function ($event) {
              return _vm.$router.$avueRouter.closeTag()
            },
          },
        },
        [_vm._v("关闭本标签\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }