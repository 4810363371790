var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        _vm._l(_vm.quDataList, function (quData, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "chatList-item",
              staticStyle: { "margin-bottom": "24px", position: "relative" },
            },
            [
              quData.boolean
                ? _c("img", {
                    staticClass: "determineImg",
                    attrs: { src: require("@/assets/images/correct.png") },
                  })
                : _c("img", {
                    staticClass: "determineImg",
                    attrs: { src: require("@/assets/images/error.png") },
                  }),
              _c(
                "el-col",
                {
                  staticStyle: { float: "unset", margin: "auto" },
                  attrs: { span: 18, xs: 24 },
                },
                [
                  _c("el-card", { staticClass: "qu-content" }, [
                    quData.topicName
                      ? _c("p", [
                          _vm._v(
                            _vm._s(i + 1) + "." + _vm._s(quData.topicName)
                          ),
                        ])
                      : _vm._e(),
                    quData.topicType === "1" || quData.topicType === "3"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { disabled: true },
                                model: {
                                  value: quData.answer,
                                  callback: function ($$v) {
                                    _vm.$set(quData, "answer", $$v)
                                  },
                                  expression: "quData.answer",
                                },
                              },
                              _vm._l(
                                quData.eduQuestionsAnswersMap.answerList,
                                function (item, index) {
                                  return _c(
                                    "el-radio",
                                    { key: index, attrs: { label: item.id } },
                                    [
                                      _vm._v(
                                        _vm._s(item.answerOption) +
                                          "." +
                                          _vm._s(item.answerOptionValue) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    quData.topicType === "2"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: { disabled: true },
                                model: {
                                  value: quData.answer,
                                  callback: function ($$v) {
                                    _vm.$set(quData, "answer", $$v)
                                  },
                                  expression: "quData.answer",
                                },
                              },
                              _vm._l(
                                quData.eduQuestionsAnswersMap.answerList,
                                function (item, index) {
                                  return _c(
                                    "el-checkbox",
                                    { key: index, attrs: { label: item.id } },
                                    [
                                      _vm._v(
                                        _vm._s(item.answerOption) +
                                          "." +
                                          _vm._s(item.answerOptionValue) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("p", { staticStyle: { color: "#f74e57" } }, [
                      _vm._v(
                        "正确答案：" +
                          _vm._s(quData.eduQuestionsAnswersMap.answerOption)
                      ),
                    ]),
                    _c("p", { staticStyle: { color: "#f74e57" } }, [
                      _vm._v(
                        "答案解析：" +
                          _vm._s(quData.eduQuestionsAnswersMap.analysis)
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }