var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center transfer-dialog",
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            "lock-scroll": "",
            "append-to-body": "",
            width: "800px",
            "modal-append-to-body": false,
          },
          on: { open: _vm.onOpen },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.type === "user"
        ? _c("userTransfer", {
            ref: "userTransfer",
            attrs: { multiple: "" },
            model: {
              value: _vm.selectedData,
              callback: function ($$v) {
                _vm.selectedData = $$v
              },
              expression: "selectedData",
            },
          })
        : _vm.type === "users"
        ? _c("usersTransfer", {
            ref: "usersTransfer",
            attrs: { multiple: "" },
            model: {
              value: _vm.selectedData,
              callback: function ($$v) {
                _vm.selectedData = $$v
              },
              expression: "selectedData",
            },
          })
        : _vm.type === "role"
        ? _c("roleTransfer", {
            ref: "roleTransfer",
            attrs: { multiple: "" },
            model: {
              value: _vm.selectedData,
              callback: function ($$v) {
                _vm.selectedData = $$v
              },
              expression: "selectedData",
            },
          })
        : _c("SNOWSTransfer", {
            ref: "SNOWSTransfer",
            attrs: {
              loading: _vm.loading,
              treeData: _vm.treeData,
              type: _vm.type,
              multiple: "",
            },
            model: {
              value: _vm.selectedData,
              callback: function ($$v) {
                _vm.selectedData = $$v
              },
              expression: "selectedData",
            },
          }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeTransfer } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }