var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: { "head-title": "", "head-btn-options": _vm.headBtnOptions },
            on: { "head-reg": _vm.headReg },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.tableOptions,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.page.total,
              page: _vm.page,
              editType: _vm.inline,
            },
            on: {
              "page-current-change": _vm.handleCurrentChange,
              "page-size-change": _vm.handleSizeChange,
              "page-refresh-change": _vm.onLoad,
            },
            scopedSlots: _vm._u([
              {
                key: "issueCode",
                fn: function ({ row, index }) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.headRegDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.issueCode))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }