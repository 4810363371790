var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "关联弹窗" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择关联弹窗", clearable: "" },
              model: {
                value: _vm.activeData.relationField,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "relationField", $$v)
                },
                expression: "activeData.relationField",
              },
            },
            _vm._l(_vm.options, function (item, i) {
              return _c("el-option", {
                key: i,
                attrs: { label: item.__config__.label, value: item.prop },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "关联字段" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入关联字段" },
            model: {
              value: _vm.activeData.showField,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "showField", $$v)
              },
              expression: "activeData.showField",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }