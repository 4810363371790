<template>
  <div class="center">
    <!--列表label-->
    <head-layout head-title="推流地址维护"
                 :head-btn-options="headBtnOptions"
                 @head-add="headAdd"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 head-title="推流地址维护"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :page="page"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @gird-handle-select-click="selectionChange"
                 @row-edit="rowEdit"
                 @row-del="rowDel">
      <template #customBtn="{row}">
        <el-button
          @click="rowEdit(row)"
          size="small"
          type="text"
          v-if="row.isStart == '0' "
        >{{ "编辑" }}
        </el-button>
        <el-button
          @click="rowDel(row)"
          size="small"
          type="text"
          v-if="row.isStart == '0' "
        >{{ "删除" }}
        </el-button>
        <el-button
          @click="handleStart(row)"
          size="small"
          type="text"
          v-if="row.isStart == '0' "
        >{{ '开启' }}
        </el-button>
        <el-button
          @click="handleStop(row)"
          size="small"
          type="text"
          v-if="row.isStart == '1' "
        >{{ '关闭' }}
        </el-button>
        <el-button
          @click="handleGet(row)"
          size="small"
          type="text"
          v-if="row.isStart == '1' "
        >{{ '获取地址' }}
        </el-button>
      </template>
    </grid-layout>
    <el-dialog
      title="获取视频地址"
      :visible.sync="dialogVisible"
      width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="地址">
          <el-input v-model="url" disabled></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {get, getList, remove, start, stop} from "@/api/desk/videoPlay";
import {mapGetters} from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  components: {HeadLayout, GridLayout},
  data() {
    return {
      url:'',
      selOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [{
          label: this.$t(`cip.plat.app.broadcast.menu.selectBroadcast`),
          prop: 'select',
          type: 'select',
          multiple: true,
          dicData: [],
          span: 24,
          props: {
            label: "address",
            value: "id"
          },
        }]
      },
      selForm: {},
      form: {},
      query: {},
      loading: true,
      dialogVisible:false,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        loadingText: "Loading...",
        loadingSpinner: "svg",
        loadingSvgViewBox: "-10, -10, 50, 50",
        loadingBackground: "rgba(122, 122, 122, 0.8)",
        selection: false,
        index: true,
        indexLabel: this.$t("cip.cmn.title.SerialNumber"),
        menuWidth: 200,
        column: [
          {
            label: "名称",
            prop: "name",
            width: 300,
            align: "left",
            overHidden: true,
          },
          {
            label: "RTSP协议地址",
            prop: "rtspUrl",
            overHidden: true,
          },
          {
            label: "是否开始切片",
            prop: "isStart",
            type: 'select',
            width: 120,
            align: "center",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yesNo",
          },
        ]
      },
      settings: false,
      data: []
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {};
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: "名称",
          prop: "name",
          span: 4,
          placeholder: "请输入名称",
        },
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          btnOptType: 'add',
        });
      return buttonBtn;
    },
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    headAdd() {
      this.$router.push({
        path: `/desk/videoPlayEdit`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowView(row) {
      this.$router.push({
        path: `/desk/videoPlayEdit`,
        query: {
          id: row.id,
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/desk/videoPlayEdit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(row.id).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    handleStart(row) {
      this.loading = true;
      start(row)
        .then(res => {
          console.log("res.data", res.data)
          this.$message.success("开始切片");
          this.onLoad(this.page, {})
        })
    },
    handleStop(row) {
      this.loading = true;
      stop(row)
        .then(res => {
          console.log("res.data", res.data)
          this.$message.success("停止切片");
          this.onLoad(this.page, {})
        })
    },
    handleGet(row) {
      get(row)
        .then(res => {
          console.log("res.data", res.data)
          this.url = res.data.data.rtmpUrl
          this.dialogVisible = true
        })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(this.ids).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    submit() {
      if (this.selForm.select.length == 0) {
        this.$message.warning(this.$t("cip.desk.notice.msg.broadcast"))
        return;
      }
      let ids = [];
      this.selForm.select.forEach(ele => {
        ids.push(ele);
      });
      play(ids.join(","), this.broadcast.text).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"))
          this.settings = false
        }
      })
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionList = [];
      });
    }
  },
  // 初始化数据 add by steve
  mounted() {
    this.onLoad(this.page, {});
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__title {
  font-size: 14px;
}
</style>
