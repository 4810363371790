<template>
  <div style="background: #fff;">
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="'模型结果'"
      @head-cancel="cancelFn"

    >
    </head-layout>
    <div style="padding:20px">
      <el-row :gutter="20" style="margin-bottom: 20px;">
        <el-col :span="12">
          x轴: <el-button v-if="xArr.length" plain size='mini' type="danger" >{{xArr}}</el-button>
        </el-col>
        <el-col :span="12">
          y轴:<el-button plain size='mini' style="margin-right: 10px" type="primary" v-for="(item,index) in yArr">{{item}}</el-button>
        </el-col>
      </el-row>
      <el-table  border
                 :data="resultTable"
                 style="width: 100%">
        <el-table-column
          prop="roll_gap"
          label="roll_gap"
        >
        </el-table-column>
        <el-table-column
          prop="raw_material_moisture"
          label="raw_material_moisture"
        >
        </el-table-column>
        <el-table-column
          prop="raw_material_size"
          label="raw_material_size"
        >
        </el-table-column>
        <el-table-column
          prop="classification"
          label="classification"
        >
        </el-table-column>
        <el-table-column
          prop="classification"
          label="classification"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getPmmlPredictData} from "@/api/Ai";

export default {
  name: "resultDetail",
  data(){
    return {
      headBtnOptions: [{
        label: this.$t("cip.cmn.btn.celBtn"),
        emit: "head-cancel",
        type: "button",
        icon: ""
      }],
      xArr:'',
      yArr:[],
      resultTable:[]
    }

  },
  created() {
    this.getPmmlPredictDataFn({
      modelId:this.$route.query.id,
      predictId:this.$route.query.picId,
    });
  },
  methods:{
    getPmmlPredictDataFn(params){
      getPmmlPredictData(params).then((res)=>{
        this.resultTable = res.data.data;
        this.xArr =  this.resultTable[0].model_input.split('&')[1]
        this.yArr =  this.resultTable[0].model_input.split('&')[0].split(',')
        this.resultTable.map((item,index)=>{
          let predict_value = predict_value.split(',')
          item.roll_gap = predict_value[0]+'.'+'0000'
          item.raw_material_moisture = predict_value[0]+'.'+'0000'
          item.raw_material_size = predict_value[0]+'.'+'0000'
          item.classification = predict_value[0]+'.'+'0000'
          item.classification = predict_value[0]+'.'+'0000'
        })
      })
    },
    cancelFn(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
