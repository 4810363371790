var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "qmDialog",
      attrs: {
        title: _vm.title,
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "avue-form",
        {
          ref: "addForm",
          attrs: { option: _vm.options },
          on: { submit: _vm.treeNodeSave },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "areaId" },
            [
              _c("el-cascader", {
                ref: "prjAreaCascader",
                staticStyle: { width: "100%" },
                attrs: {
                  placeholder: "请选择注册位置",
                  disabled: _vm.pageType == "view",
                  filterable: "",
                  options: _vm.areaDic,
                },
                on: { change: _vm.handleChangeArea },
                model: {
                  value: _vm.form.areaId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "areaId", $$v)
                  },
                  expression: "form.areaId",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              attrs: { size: "small" },
              on: { click: _vm.closeDialog },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
              ),
            ]
          ),
          _vm.pageType != "view"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleTreeNodeSave },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "append-to-body": "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }