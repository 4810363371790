<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-btn-options="headBtnOptions"
                 head-title="表单默认值"
                 @head-add="dynamicRoute({processDefKey}, 'start')"
                 @head-delete="handleDelete"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @page-refresh-change="onLoad"
                 @row-view="handleDetail"
                 @row-flow="handleFlow"></grid-layout>

    <!--  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :permission="permissionList"
               v-model="form"
               ref="crud"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template #datetime="{row}">
        <span v-if="row.datetime">{{row.datetime.split(',')[0]}} ~ {{row.datetime.split(',')[1]}}</span>
      </template>
      <template #menuLeft>
        <el-button type="primary"
                   size="mini"
                   icon="el-icon-plus"
                   @click="dynamicRoute({processDefKey}, 'start')">
          创 建</el-button>
        <el-button type="danger"
                   size="mini"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.wf_demo_leave_delete"
                   @click="handleDelete">删 除
        </el-button>
      </template>
      <template #menu="{row}">
        <el-button v-if="row.status != '18'"
                   type="text"
                   size="small"
                   icon="el-icon-info"
                   @click="handleDetail(row)">详情</el-button>
        <el-button v-if="row.status != '18'"
                   type="text"
                   size="small"
                   icon="el-icon-search"
                   @click="handleFlow(row)">流程图</el-button>
      </template>
    </avue-crud>

    <el-drawer :visible.sync="detailVisible"
               :title="form.processDefinitionName"
               custom-class="wf-drawer"
               size="60%"
               append-to-body>
      <task-detail v-if="detailVisible"
                   :taskId="form.taskId"
                   :processInstanceId="form.processInsId"></task-detail>
    </el-drawer>

    <el-dialog :visible.sync="bpmnVisible"
               append-to-body
               destroy-on-close
               title="流程图"
               width="70%"
               custom-class="wf-dialog">
      <wf-design ref="bpmn"
                 style="height: 60vh;"
                 :options="bpmnOption"></wf-design>
    </el-dialog>
  </basic-container>-->
    <el-drawer :visible.sync="detailVisible"
               :title="form.processDefinitionName"
               custom-class="wf-drawer"
               size="60%"
               append-to-body>
      <task-detail v-if="detailVisible"
                   :taskId="form.taskId"
                   :processInstanceId="form.processInsId"></task-detail>
    </el-drawer>

    <el-dialog :visible.sync="bpmnVisible"
               append-to-body
               destroy-on-close
               title="流程图"
               width="70%"
               custom-class="wf-dialog">
      <wf-design ref="bpmn"
                 style="height: 60vh;"
                 :options="bpmnOption"></wf-design>
    </el-dialog>
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import { getList, remove } from "@/api/plugin/workflow/demo/leave";
import { detail } from "@/api/plugin/workflow/process";
import { mapGetters } from "vuex";

import exForm from '../../mixins/ex-form'
import statusDic from '../../util/status'
import TaskDetail from '../../ops/detail.vue'
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

export default {
  mixins: [exForm],
  components: { TaskDetail, GridLayout, HeadLayout },
  data () {
    return {
      processDefKey: 'leave',
      detailVisible: false,
      bpmnVisible: false,
      bpmnOption: {},
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        index: true,
        selection: true,
        size: 'mini',
        searchSize: 'mini',
        align: 'center',
        column: [
          {
            label: "创建人",
            prop: "creator",
            search: true,
            type: 'tree',
            dicUrl: '/api/sinoma-user/list?size=-1',
            props: {
              label: 'name',
              value: 'id',
              desc: 'deptName'
            },
            dicFormatter: (res) => {
              return res.data.records
            }
          },
          {
            label: "创建部门",
            prop: "creatorDept",
            search: true,
            type: 'tree',
            dicUrl: '/api/sinoma-system/dept/lazy-list?parentId={{key}}',
            props: {
              label: 'deptName',
              value: 'id',
            },
            lazy: true,
            treeLoad: (node, resolve) => {
              let id = 0
              if (node.level != 0) {
                id = node.data.id
              }
              this.$http.get(`/api/sinoma-system/dept/lazy-list?parentId=${id}`).then(res => {
                resolve(res.data.data)
              })
            }
          },
          {
            label: "时间范围",
            prop: "datetime",
            width: 260
          },
          {
            label: "请假天数",
            prop: "days",
            align:'right',
            width: 80,
            overHidden: true,
          },
          {
            label: "请假理由",
            prop: "reason",
            overHidden: true,
          },
          {
            label: "附件",
            prop: "attachment",
            type: 'upload',
            overHidden: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            align:'center',
            width: 150,
            overHidden: true
          },
          {
            label: '当前节点',
            prop: 'currentNode',
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            align:'center',
            dicData: statusDic,
          }
        ]
      },
      data: []
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.wf_demo_leave_add, false),
        viewBtn: this.vaildData(this.permission.wf_demo_leave_view, false),
        delBtn: this.vaildData(this.permission.wf_demo_leave_delete, false),
        editBtn: this.vaildData(this.permission.wf_demo_leave_edit, false)
      };
    },
    ids () {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns () {
      return [
        {
          label: "名称",
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: '请输入名称',
        },
      ]
    },
    headBtnOptions () {
      let result = [];
      //新增按钮
      result.push({
        label: '新增',
        emit: "head-add",
        type: "primary",
        icon: "el-icon-plus",
      });
      result.push({
        label: '删除',
        emit: "head-delete",
        type: "danger",
        icon: "el-icon-delete",
      });
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn () {
      //新增按钮
      return [
        {
          label: '详情',
          emit: "row-view",
          type: "primary",
          icon: "el-icon-plus",
        },
        {
          label: '流程图',
          emit: "row-flow",
          type: "primary",
          icon: "el-icon-plus",
        },
      ]
    },
  },
  mounted () {
    this.onLoad({ pageSize: 10, currentPage: 1 }, {});
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch (searchForm) {
      this.onLoad({ pageSize: 10, currentPage: 1 }, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty (searchForm) {
      this.onLoad({ pageSize: 10, currentPage: 1 }, searchForm);
    },
    handleDetail (row) {
      this.dynamicRoute({ processInstanceId: row.processInsId }, 'detail', true).then(() => {
        this.form = { ...row }
        this.detailVisible = true
      })
    },
    handleFlow (row) {
      const { processInsId } = row
      detail({ processInsId }).then(res => {
        const { process, flow } = res.data.data

        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow)
        }

        this.bpmnVisible = true
      })
    },
    rowDel (row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id).then(() => {
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess')
            });
            this.onLoad(this.page);
          });
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$refs.crud.toggleSelection();
        });
    },
    searchReset () {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange (params, done) {
      if (params.creator) {
        params.createUser = params.creator
        delete params.creator
      }
      if (params.creatorDept) {
        params.createDept = params.creatorDept
        delete params.creatorDept
      }
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange (list) {
      this.selectionList = list;
    },
    selectionClear () {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange (currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange (pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange () {
      this.onLoad(this.page, this.query);
    },
    onLoad (page, params = {}) {
      this.loading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    }
  }
};
</script>

<style>
</style>
