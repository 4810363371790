<template>
  <div>
    <common-dialog
      v-if="show"
      width="70%"
      dialogTitle="折线图"
      :showBtn="false"
      @cancel="show = false">
      <div id="chartDiscount" v-if="show"></div>
    </common-dialog>
  </div>
</template>
<script>
import CommonDialog from "@/components/CommonDialog";
export default {
  components: { CommonDialog },
  data() {
    return {
      show: false
    }
  },
  methods: {
    getChartDiscount() {
      var myChart = this.$echarts.init(
        document.getElementById("chartDiscount")
      );
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Email'],
          right:0
        },
        grid: { // 让图表占满容器
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "25px"
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Email',
            color: '#FB3636',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
        ]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    }
  }
}
</script>
<style lang="scss" scoped>
#chartDiscount {
  width: 100%;
  height: 50vh;
}
</style>
