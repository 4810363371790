import { render, staticRenderFns } from "./aiBox.vue?vue&type=template&id=46983e62&scoped=true"
import script from "./aiBox.vue?vue&type=script&lang=js"
export * from "./aiBox.vue?vue&type=script&lang=js"
import style0 from "./aiBox.vue?vue&type=style&index=0&id=46983e62&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46983e62",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/hse-web-227/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('46983e62')) {
      api.createRecord('46983e62', component.options)
    } else {
      api.reload('46983e62', component.options)
    }
    module.hot.accept("./aiBox.vue?vue&type=template&id=46983e62&scoped=true", function () {
      api.rerender('46983e62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/safe/itemList/cameraManagement/camera/aiBox.vue"
export default component.exports