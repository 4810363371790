var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.dc.AIDComission.title2"),
        },
        on: {
          "head-cancel": _vm.cancelFn,
          "head-save": function ($event) {
            return _vm.saveFn("save")
          },
          "head-saveback": function ($event) {
            return _vm.saveFn("back")
          },
        },
      }),
      _c(
        "div",
        { staticStyle: { background: "#fff", padding: "10px" } },
        [
          [
            _c(
              "el-form",
              { attrs: { model: "form", "label-width": "100px" } },
              [
                _c("div", { staticStyle: { width: "100%", height: "20px" } }),
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _vm._v(_vm._s(_vm.$t("cip.dc.AiSuanfa.field.placeholder"))),
                ]),
                _c("div", { staticStyle: { width: "100%", height: "20px" } }),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("cip.dc.AiSuanfa.field.modeName"),
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "new-form-input",
                              model: {
                                value: _vm.form.algorithmName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "algorithmName", $$v)
                                },
                                expression: "form.algorithmName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("cip.dc.AIDComission.url") },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.trainingUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "trainingUrl", $$v)
                                },
                                expression: "form.trainingUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("cip.dc.AIDComission.sort"),
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: { controls: false, min: 0 },
                              model: {
                                value: _vm.form.algorithmSort,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "algorithmSort",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "form.algorithmSort",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("cip.dc.AiSuanfa.field.x") },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: _vm.form.xaxis,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "xaxis", $$v)
                                  },
                                  expression: "form.xaxis",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.AIDComission.danxuan"
                                    ),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: "$t('cip.dc.AIDComission.duoxuan')",
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("cip.dc.AiSuanfa.field.y") },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: _vm.form.yaxis,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "yaxis", $$v)
                                  },
                                  expression: "form.yaxis",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.AIDComission.danxuan"
                                    ),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: _vm.$t("cip.dc.AIDComission.buxuan"),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticStyle: { width: "100%", height: "20px" } }),
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v(" " + _vm._s(_vm.$t("cip.dc.AIDComission.tiaocan")) + " "),
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addtc } },
              [_vm._v(_vm._s(_vm.$t("cip.dc.AIDComission.add")) + " ")]
            ),
            _c("div", { staticStyle: { width: "100%", height: "10px" } }),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.form.argsList, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: _vm.$t("cip.dc.AIDComission.argName") },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            model: {
                              value: scope.row.argName,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "argName", $$v)
                              },
                              expression: "scope.row.argName",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: _vm.$t("cip.dc.AIDComission.argCode") },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            model: {
                              value: scope.row.argCode,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "argCode", $$v)
                              },
                              expression: "scope.row.argCode",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: _vm.$t("cip.dc.AIDComission.argSort") },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { controls: false, min: 0 },
                            model: {
                              value: scope.row.argSort,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "argSort", _vm._n($$v))
                              },
                              expression: "scope.row.argSort",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("cip.dc.apiDComission.caozuo"),
                    width: "120",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.deltc(scope.row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("cip.dc.apiDComission.del")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }