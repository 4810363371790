var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c(
      "div",
      [
        _c("head-layout", {
          attrs: {
            "head-title": "隐患排查计划",
            "head-btn-options": _vm.headBtnOptions,
          },
          on: { "head-romve": _vm.headRomve, "head-add": _vm.headAdd },
        }),
        _c("grid-head-layout", {
          ref: "gridHeadLayout",
          attrs: { "search-columns": _vm.searchColumns },
          on: {
            "grid-head-search": _vm.gridHeadSearch,
            "grid-head-empty": _vm.gridHeadEmpty,
          },
        }),
        _c("grid-layout", {
          ref: "gridLayOut",
          attrs: {
            "table-options": _vm.tableOption,
            "table-data": _vm.tableData,
            "table-loading": _vm.tableLoading,
            "data-total": _vm.page.total,
            page: _vm.page,
          },
          on: {
            "grid-row-detail-click": _vm.rowView,
            "gird-handle-select-click": _vm.selectionChange,
            "page-current-change": _vm.onLoad,
            "page-size-change": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "customBtn",
              fn: function ({ row }) {
                return [
                  row.enableFlag != "Y"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 3px" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.rowEdit(row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                  row.enableFlag != "Y"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 3px" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.rowRemove(row)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 3px" },
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.rowView(row)
                        },
                      },
                    },
                    [_vm._v("查看")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 3px" },
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.rowStartEnd(row)
                        },
                      },
                    },
                    [_vm._v(_vm._s(row.enableFlag == "Y" ? "停用" : "启用"))]
                  ),
                ]
              },
            },
            {
              key: "enableFlag",
              fn: function ({ row }) {
                return [
                  _c("div", { staticClass: "enableFlag-box" }, [
                    _c(
                      "div",
                      {
                        class:
                          row.enableFlag == "Y"
                            ? "enableFlag-Y"
                            : "enableFlag-N",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.statusMap[row.enableFlag]) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }