var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-full-dialog",
      attrs: {
        visible: _vm.visible,
        fullscreen: "",
        "lock-scroll": "",
        "show-close": false,
        modal: false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "SNOWS-full-dialog-header" },
        [
          _c("div", { staticClass: "header-title" }, [
            _c("img", {
              staticClass: "header-logo",
              attrs: { src: require("@/assets/images/snow.png") },
            }),
            _c("p", { staticClass: "header-txt" }, [_vm._v(" · 代码生成")]),
          ]),
          _c(
            "el-steps",
            {
              staticClass: "steps steps2",
              attrs: {
                active: _vm.activeStep,
                "finish-status": "success",
                simple: "",
              },
            },
            [
              _c("el-step", {
                attrs: { title: "基础设置" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.stepChick(0)
                  },
                },
              }),
              _c("el-step", {
                attrs: { title: "表单设计" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.stepChick(1)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.activeStep <= 0 },
                  on: { click: _vm.prev },
                },
                [_vm._v(_vm._s(_vm.$t("common.prev")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.activeStep >= 1 || _vm.loading },
                  on: { click: _vm.next },
                },
                [_vm._v(_vm._s(_vm.$t("common.next")) + "\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.loading,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.dataFormSubmit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("common.saveButton")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          !_vm.activeStep
            ? _c(
                "el-row",
                {
                  staticClass: "basic-box",
                  attrs: { type: "flex", justify: "center", align: "middle" },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "basicForm",
                      attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 10 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          attrs: {
                            model: _vm.dataForm,
                            rules: _vm.dataRule,
                            "label-width": "80px",
                            "label-position": "right",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "模板名称", prop: "fullName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "模板名称",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.dataForm.fullName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "fullName", $$v)
                                  },
                                  expression: "dataForm.fullName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "模板编码", prop: "enCode" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "模板编码",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.dataForm.enCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "enCode", $$v)
                                  },
                                  expression: "dataForm.enCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "模板分类", prop: "category" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "选择分类" },
                                  model: {
                                    value: _vm.dataForm.category,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "category", $$v)
                                    },
                                    expression: "dataForm.category",
                                  },
                                },
                                _vm._l(_vm.categoryList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.fullName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "模板说明", prop: "description" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "模板说明",
                                  type: "textarea",
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.dataForm.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "description", $$v)
                                  },
                                  expression: "dataForm.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "数据连接" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择数据库",
                                    clearable: "",
                                  },
                                  on: { change: _vm.onDbChange },
                                  model: {
                                    value: _vm.dataForm.dbLinkId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "dbLinkId", $$v)
                                    },
                                    expression: "dataForm.dbLinkId",
                                  },
                                },
                                _vm._l(_vm.dbOptions, function (group) {
                                  return _c(
                                    "el-option-group",
                                    {
                                      key: group.fullName,
                                      attrs: { label: group.fullName },
                                    },
                                    _vm._l(group.children, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.fullName,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              staticClass: "SNOWS-common-table",
                              attrs: {
                                data: _vm.tables,
                                "empty-text":
                                  "点击“新增”可选择1条(单表)或2条以上(多表)",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "50",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "typeId",
                                  label: "类别",
                                  width: "65",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.typeId == "1"
                                            ? _c("el-tag", [_vm._v("主表")])
                                            : _c(
                                                "el-tag",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    type: "warning",
                                                    title: "点击设置成主表",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeTable(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("从表")]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  407046229
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "table", label: "表名" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  scope.row.tableName ||
                                                  scope.row.table,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(scope.row.table)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1916873032
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "tableField",
                                  label: "外键字段",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return scope.row.typeId !== "1"
                                          ? [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value: scope.row.tableField,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "tableField",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.tableField",
                                                  },
                                                },
                                                _vm._l(
                                                  scope.row.fields,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.field,
                                                      attrs: {
                                                        label: item.field,
                                                        value: item.field,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          : undefined
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "relationField",
                                  label: "关联主键",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return scope.row.typeId !== "1"
                                          ? [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.relationField,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "relationField",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.relationField",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.mainTableFields,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.field,
                                                      attrs: {
                                                        label: item.field,
                                                        value: item.field,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          : undefined
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  fixed: "right",
                                  width: "50",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "SNOWS-table-delBtn",
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delItem(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("移除\n                ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2291124763
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "table-actions",
                              on: { click: _vm.openTableBox },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                },
                                [_vm._v("新增一行")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeStep == 1
            ? [
                _c("Generator", {
                  ref: "generator",
                  attrs: {
                    conf: _vm.formData,
                    modelType: _vm.dataForm.type,
                    webType: _vm.dataForm.webType,
                    dbType: _vm.dbType,
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("TableForm", {
        ref: "tableForm",
        attrs: { visible: _vm.formVisible, dbLinkId: _vm.dataForm.dbLinkId },
        on: {
          "update:visible": function ($event) {
            _vm.formVisible = $event
          },
          closeForm: _vm.closeForm,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }