import {mapGetters} from "vuex";
import { fieldInfo } from "@/api/base/length";
export default {
  computed:{
    ...mapGetters([
      'userInfo'
    ]),
  },
  data(){
    return {
      tableOption: {
        selectable:(row)=>{
          return  row.actStatus == 'PREPARE' && row.startUserId == this.userInfo.user_id
        },
        linklabel:'actCode',
        column: [{
          label: "检查编号",
          prop: "actCode",
          align: "center",
          overHidden: true,
          width: 150
        },
          {
            label: "检查名称",
            prop: "actName",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "分类",
            prop: "checkType",
            type:'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=check_type`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: fieldInfo.Words5.align,
            minWidth: fieldInfo.Words5.length,
            overHidden: true,
          },

          {
            label: "所属组织",
            prop: "organizationName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true,
          },
          {
            label: "起草人",
            prop: "createUserName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
          },
          {
            label: "检查日期",
            prop: "checkTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "actStatus",
            type:'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=act_status`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: fieldInfo.Words4.align,
            minWidth: fieldInfo.Words4.length,
            overHidden: true,
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },

        ],
      },
      tableData: [],
      tableLoading: true,
    }
  },
}
