<template>
  <el-dialog
    :title="'储位二维码'"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="40%"
    :custom-class="'qrcodeDialog'"
    @close="closeDialog"
  >
    <!-- <div id="qrCode" ref="qrCodeDiv" class="qrcodeDiv"></div> -->
    <div class="qrcodeBox" ref="qrcodeBox">
      <div class="qrcodeBox_text">{{ qrcodeText }}</div>
      <div class="qrcodeBox_text">{{ qrcodeCode }}</div>
      <img :src="qrcodeSrc" style="width: 200px; height: 200px" alt="" />
      <!-- <el-image :src="qrcodeSrc" style="width: 200px;height:200px"></el-image> -->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showDialog = false">取 消</el-button>
      <el-button type="primary" @click="downloadImg">下载</el-button>
    </span>
  </el-dialog>
</template>
<script>
import html2canvas from "html2canvas";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      showDialog: false,
      qrcodeText: "",
      qrcodeSrc: "",
    };
  },
  methods: {
    init(qrcodeSrc, qrcodeText, prjCode) {
      this.showDialog = true;
      this.qrcodeText = qrcodeText;
      this.qrcodeCode = prjCode;
      this.qrcodeSrc = "data:image/png;base64," + qrcodeSrc;
      // this.bindQRCode()
      // this.$nextTick(() => {
      //   this.downloadImg();
      // });
    },
    closeDialog() {
      this.showDialog = false;
    },
    bindQRCode() {
      // this.$nextTick(() => {
      //   document.getElementById("qrCode").innerHTML = ""; //避免多次生成重复的二维码
      //   // console.log("显示二维码的元素(div)=" + this.$refs.qrCodeDiv);
      //   this.qrcode = new QRCode(this.$refs.qrCodeDiv, {
      //     text: this.qrcodeText, // 二维码要展示的内容
      //     width: 200,
      //     height: 200,
      //     colorDark: "#333333", //二维码颜色
      //     colorLight: "#ffffff", //二维码背景色
      //     correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      //   });
      // });
    },
    downloadImg() {
      html2canvas(this.$refs.qrcodeBox).then((canvas) => {
        // 创建一个图片元素
        var img = canvas.toDataURL("image/png");
        // 创建一个链接元素
        var link = document.createElement("a");
        link.href = img;
        // 设置下载的文件名
        link.download = this.qrcodeText;
        // 触发链接点击
        link.click();
      });
    },
  },
};
</script>
<style scoped lang="scss">
// ::v-deep .el-dialog{
//   visibility: hidden;
// }
::v-deep .qrcodeDialog .el-dialog__body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.qrcodeBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.qrcodeBox_text {
  line-height: 30px;
}
.qrcodeDiv {
  width: 200px;
  height: 200px;
}
</style>
