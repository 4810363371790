<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-08-28 13:51:28
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
    <avue-form :option="{ ...options,disabled:pageType == 'view' ? true : false,}" ref="addForm" v-model="form" @submit="treeNodeSave">

    </avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    add
  } from "@/api/safe/algorithmManagement";
  import {
    from
  } from "rxjs";
  import Template from "@/views/message/template/list.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {
    mapGetters
  } from "vuex";
  import {NODE_TYPES} from './nodeDict.js'

  export default {
    components: {
      Template
    },
    mixins: [organization],
    data() {
      return {
        APITYPE: 'user',
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        title: '',
        isEdit: false,
        searchData: [],
        pageType: '',

      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "编码",
              prop: "code",
              rules: [{
                required: true,
                message: "请输入编码",
                trigger: "blur"
              }, {
                max: 64,
                message: '编码不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "名称",
              prop: "name",
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }, {
                max: 64,
                message: '名称不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "类型",
              prop: "type",
              type: 'select',
              dataType: 'string',
              disabled:true,
              props: {
                label: "name",
                value: "value",
              },
              dicData:NODE_TYPES,
              // dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineEqtType",
              rules: [{
                required: true,
                message: "请选择类型",
                trigger: "blur"
              }]
            },

            {
              label: "外系统编码",
              prop: "outsideCode",
              rules: [{
                message: "请输入外系统编码",
                trigger: "blur"
              }, {
                max: 64,
                message: '外系统编码不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "外系统ID",
              prop: "outsideId",
              rules: [{
                message: "请输入外系统ID",
                trigger: "blur"
              }, {
                max: 64,
                message: '外系统ID不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "备注",
              prop: "remark",
              rules: [{
                message: "请输入备注",
                trigger: "blur"
              }, {
                max: 200,
                message: '备注不能超过200个字符',
                trigger: 'blur'
              }]
            },

          ],
        }
      },
    },

    methods: {
      init(type, data,nodeType) {
        this.form = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.pageType = type
        this.form.type=nodeType
        if(type=='add'){
          this.form.parentId=data.data.id
        }else{
          this.form.parentId='0'
        }
        this.title = '新增节点组'
      },

      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      treeNodeSave(data) {
        this.$refs.addForm.validate((valid, done) => {
          if (valid) {
            this.dialogLoading = true
            add(data).then(res => {
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(res.data.msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.showDialog = false;
              this.dialogLoading = false
            }).catch(err => {
              done()
            }).finally(() => {
              done()
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {}
        this.searchData = []
        this.$refs.addForm.resetFields();
        this.showDialog = false
      },
    }
  }

</script>
<style lang="scss" scoped>
  .el-autocomplete {
    width: 100%
  }

</style>
