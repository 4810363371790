var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("h3", [_vm._v(_vm._s(_vm.$route.query.id ? "编辑" : "新增"))]),
      _c(
        "avue-form",
        {
          attrs: { option: _vm.option },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuForm" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-back" },
                  on: {
                    click: function ($event) {
                      return _vm.handleBack()
                    },
                  },
                },
                [_vm._v("返 回")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }