var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "qmDialog",
      attrs: {
        title: _vm.title,
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-steps",
        { attrs: { active: _vm.activeName } },
        [
          _c("el-step", {
            attrs: { title: "创建模型" },
            nativeOn: {
              click: function ($event) {
                return _vm.handleStepClick(1)
              },
            },
          }),
          _c("el-step", {
            attrs: { title: "上传模型" },
            nativeOn: {
              click: function ($event) {
                return _vm.handleStepClick(2)
              },
            },
          }),
        ],
        1
      ),
      _vm.activeName === 1
        ? _c(
            "avue-form",
            {
              ref: "addForm",
              attrs: {
                option: {
                  ..._vm.options,
                  disabled: _vm.pageType == "view" ? true : false,
                },
              },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [
              _c("template", { slot: "tags" }, [
                _c(
                  "div",
                  [
                    _vm._l(_vm.chooseTags, function (item, index) {
                      return _c(
                        "el-tag",
                        {
                          key: index,
                          attrs: { closable: "", type: "info" },
                          on: {
                            close: function ($event) {
                              return _vm.handleClose(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.tagsListData.find((tag) => tag.id === item)
                                  ?.enName
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    }),
                    _c(
                      "el-popover",
                      { attrs: { width: "70", trigger: "click" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.chooseTag },
                            model: {
                              value: _vm.chooseTags,
                              callback: function ($$v) {
                                _vm.chooseTags = $$v
                              },
                              expression: "chooseTags",
                            },
                          },
                          _vm._l(_vm.tagsListData, function (item) {
                            return _c(
                              "el-checkbox",
                              { key: item.id, attrs: { label: item.id } },
                              [_vm._v(" " + _vm._s(item.enName))]
                            )
                          }),
                          1
                        ),
                        _c("template", { slot: "reference" }, [
                          _c("i", { staticClass: "el-icon-circle-plus" }),
                        ]),
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.activeName === 2
        ? _c("avue-form", {
            ref: "uploadFormRef",
            attrs: {
              option: {
                ..._vm.uploadOptions,
                disabled: _vm.pageType == "view" ? true : false,
              },
              "upload-preview": _vm.handlePreview,
              "upload-after": _vm.uploadAfter,
              "upload-error": _vm.uploadError,
            },
            on: { submit: _vm.treeNodeSave },
            model: {
              value: _vm.uploadForm,
              callback: function ($$v) {
                _vm.uploadForm = $$v
              },
              expression: "uploadForm",
            },
          })
        : _vm._e(),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              attrs: { size: "small" },
              on: { click: _vm.closeDialog },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
              ),
            ]
          ),
          _vm.activeName == 1
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextSave(2)
                    },
                  },
                },
                [_vm._v("\n      下一步\n    ")]
              )
            : _vm._e(),
          _vm.activeName == 2
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextSave(1)
                    },
                  },
                },
                [_vm._v("\n      上一步\n    ")]
              )
            : _vm._e(),
          _vm.activeName == 2
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleTreeNodeSave },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }