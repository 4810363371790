<template>
  <div>
    <el-main>
      <head-layout :head-btn-options="headBtnOptions"
        :head-title="this.$route.query.type == 'edit' ? `[${tittle}]` + $t('cip.plat.sys.dict.title.editConfigHeadTitle') : `[${tittle}]` + $t('cip.plat.sys.dict.title.addConfigHeadTitle')"
        @head-save="headSave('save')" @head-save-back="headSave('back')" @head-cancel="headCancel">
      </head-layout>
      <!-- <form-layout ref="formLayout" icon="" :head-title="headtitle" page-type="self" :option="option"
      :btn-options="btnOptions" @form-btn-save-click="save" :dataForm="form"></form-layout>-->
      <form-layout :column="optionChild.column" :dataForm="dataObj" ref="formLayout"></form-layout>
    </el-main>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";
import {
  update,
  add,
  getDictTree,
  getDictDetail,
  getDict
} from "@/api/system/dictbiz";
import { optionChild } from "@/option/system/dictbiz";
export default {
  components: { FormLayout, HeadLayout },
  computed: {
    ...mapGetters(["userInfo", "permission", "language"]),
  },
  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
      {
        label: this.$t('cip.cmn.btn.saveBackBtn'),
        emit: "head-save-back",
        type: "button",
        icon: ""
      }, {
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: "head-cancel",
        type: "button",
        icon: ""
      }],
      tittle: '',
      dataObj: {

      },
      parentId: '',
      optionChild,
    }
  },
  created() {
    this.optionChild = this.optionChild(this,this.language)
    this.initData()
    console.log(this.$route.query)
    this.parentId = this.$route.query.pid
    this.dataObj.parentId = this.parentId
    this.dataObj.code = this.$route.query.code
    this.findObject(this.optionChild.column, "code").disabled = true;
    this.findObject(this.optionChild.column, "parentId").disabled = true;
    if (this.$route.query.type == 'edit') {
      //console.log(decodeURIComponent(this.$route.query.data))
      // this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
      // const column = this.findObject(this.optionParent.column, "code");
      // column.display = false
      getDictDetail(this.$route.query.id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    } else if (this.$route.query.type == 'addChild') {

    }
  },

  mounted() {
    this.tittle = this.$route.query.title
    console.log(this.tittle)
  },
  methods: {
    headSave(type) {
      this.$refs.formLayout.$refs.form.form.dictKey=this.$refs.formLayout.$refs.form.form.dictKey.trim()
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading()
          let obj = this.$refs.formLayout.dataForm
          obj.parentId = this.parentId
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then((res) => {
            if (type == 'back') {
              this.close()
            }
            console.log(res,"11111111111111")
            this.dataObj=res.data.data
            console.log(this.dataObj,"11111111111")
            this.getValue(this.dataObj.id)
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });
            this.isShow = false
            // done();
          }, error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false
          }).finally(()=>{
            this.$loading().close()
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })


    },

    getValue(id) {
      getDict(id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    },
    initData() {
      getDictTree().then(res => {
        const column = this.findObject(this.optionChild.column, "parentId");
        column.dicData = res.data.data;
      });
    },

    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
