<template>
  <div
    class="daily"
    v-loading="!headTitle ? true : false"
    element-loading-background="rgba(255,255,255,1)"
    element-loading-spinner="el-icon-loading"
  >
    <div class="flex-container">
      <!-- <CommonTree
         ref="commonTree"
         treeTitle="项目架构"
         :treeData="treeData"
         :defaultProps="Props"
         :showCheckbox="false"
         @getNodeClick="handleNode"
         node-key="id"
       >
       </CommonTree> -->
      <CommonTreeNew
        ref="commonTree"
        treeTitle="项目架构"
        :defaultProps="Props"
        :showCheckbox="false"
        @getNodeClick="handleNode"
        :urlParmas="{
          tenantId: '',
          deptCategory: '',
          parentId: userInfo.deptId,
        }"
        node-key="id"
        @getTreeData="commonTreeData"
      ></CommonTreeNew>
      <div class="clacWidth">
        <head-layout
          :head-title="headTitle"
          :head-btn-options="headBtnOptions"
          @head-romve="headRomve"
          @head-add="headAdd"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOption"
          :table-data="tableData"
          :table-loading="tableLoading"
          @grid-row-detail-click="rowView"
          @gird-handle-select-click="selectionChange"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{ row }">
            <el-button
              style="margin: 0 3px"
              v-if="
                row.actStatus != 'FINISHED' &&
                row.startUserId == userInfo.user_id
              "
              type="text"
              size="small"
              @click="rowEdit(row)"
              >编辑</el-button
            >
            <el-button
              style="margin: 0 3px"
              type="text"
              v-if="row.actStatus == 'FINISHED'"
              size="small"
              @click="rowView(row)"
              >查看</el-button
            >
            <el-button
              style="margin: 0 3px"
              v-if="
                row.actStatus == 'PREPARE' &&
                row.startUserId == userInfo.user_id
              "
              type="text"
              size="small"
              @click="rowDel([row])"
              >删除</el-button
            >
            <el-button
              style="margin: 0 3px"
              v-if="row.actStatus != 'PREPARE'"
              type="text"
              size="small"
              @click="rowCopy(row)"
              >复制</el-button
            >
          </template>
        </grid-layout>
      </div>
    </div>

    <el-dialog
      title="检查类型选择"
      :visible.sync="checkTypeVisible"
      width="940px"
    >
      <div class="kicket-content">
        <div
          v-for="(item, index) in checkTypeList"
          :key="index"
          class="kicket"
          @click="selectKicket(item)"
        >
          <div :style="computedStyle">
            <img class="img-item" :src="item.kictectIcon" alt="" />
          </div>
          <div class="text-item">{{ item.dictValue }}</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkTypeVisible = false">{{
          $t("cip.cmn.btn.celBtn")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as API from "@/api/check/daily";
import GridLayout from "@/views/components/layout/grid-layout";

import tableOption from "@/views/hiddenTrouble/check/tableOption";
import { dictionaryBiz } from "@/api/reportTasks";
import { mapGetters } from "vuex";
import CommonTree from "@/views/components/com_tree/index.vue";
import { getDeptTree } from "@/api/system/dept";
export default {
  components: {
    CommonTree,
    GridLayout,
  },
  mixins: [tableOption],
  props: {
    checkType: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      treeData: [],
      Props: {
        children: "children",
        label: "title",
      },
      searchColumns: [
        {
          label: "检查编号",
          prop: "actCode",
          span: 3,
          placeholder: "请输入检查编号",
        },
        {
          label: "检查名称",
          prop: "actName",
          span: 3,
          placeholder: "请输入检查名称",
        },
        {
          label: "检查类型",
          prop: "checkType",
          span: 3,
          placeholder: "请选择检查类型",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=check_type",
          dicFormatter: (res) => {
            let data = res.filter((item) => item.dictKey != "7");
            return data;
          },
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "组织名称",
          prop: "organizationName",
          span: 4,
          placeholder: "请输入组织名称",
        },
        {
          prop: "time",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          span: 4,
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期",
          type: "daterange",
        },
        {
          label: "状态",
          prop: "actStatus",
          span: 3,
          placeholder: "请选择状态",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=act_status",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],
      showPage: false,
      check_type: [],
      selectionList: [],
      isCycle: "",
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "primary",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: "",
        },
      ],
      kictectIcon: [
        require("@/assets/images/checkType/zhjc.png"),
        require("@/assets/images/checkType/rcjc.png"),
        require("@/assets/images/checkType/dqjc.png"),
        require("@/assets/images/checkType/zxjc.png"),
        require("@/assets/images/checkType/jjxjc.png"),
        require("@/assets/images/checkType/jjrjc.png"),
        require("@/assets/images/checkType/bdqjc.png"),
      ],
      checkTypeList: [],
      checkTypeVisible: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "themeClass", "themeColor"]),
    headTitle() {
      return "检查计划";
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    computedStyle() {
      return [
        {
          backgroundColor: this.themeColor,
          width: "50px",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          marginLeft: "10px",
          marginRight: "20px",
        },
      ];
    },
  },
  mounted() {
    this.getPrjstructureList();
    dictionaryBiz("check_type").then((res) => {
      this.check_type = res.data.data;
      this.checkTypeList = res.data.data
        .filter((item) => item.dictKey != "7")
        .map((item) => {
          return {
            ...item,
            kictectIcon:
              item.dictKey == "ZHJC"
                ? this.kictectIcon[0]
                : this.kictectIcon[item.dictKey],
          };
        });
    });
  },
  methods: {
    //项目组织架构
    getPrjstructureList() {
      const parentId = this.userInfo.dept_id;
      getDeptTree("", "", parentId).then((res) => {
        if ((res.data.code = 200)) {
          this.treeData = res.data.data;
          this.$refs.commonTree.setCurrentKey(this.treeData[0].id);
          this.$refs.commonTree.nodeClick(this.treeData[0], {});
        }
      });
    },
    handleNode(node) {
      this.treeNode = node;
      this.onLoad(this.page, {});
    },
    headAdd() {
      this.checkTypeVisible = true;
      // this.$router.push({
      //   path: `/hiddenTrouble/check/daily/edit`,
      //   query: {
      //     type: 'add',
      //     checkType:this.checkType
      //   }
      // })
    },
    selectKicket(item) {
      this.$router.push({
        path: `/hiddenTrouble/check/daily/edit`,
        query: {
          type: "add",
          checkType: item.dictKey,
        },
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      if (searchForm.time) {
        searchForm.startTime = searchForm.time[0] + " 00:00:00";
        searchForm.endTime = searchForm.time[1] + " 23:59:59";
      } else {
        searchForm.checkStartTime = "";
        searchForm.checkEndTime = "";
      }

      delete searchForm.time;
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    headRomve() {
      this.rowDel(this.selectionList);
    },
    rowDel(row) {
      this.selectionList = row;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let formData = new FormData();
          formData.append("ids", this.ids);
          return API.remove(formData);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    rowCopy(row) {
      this.$confirm("确定复制当前数据？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return API.copy({
            id: row.id,
          });
        })
        .then((res) => {
          this.rowEdit({ id: res.data.data });
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    // 获取列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      API.getList(page.currentPage, page.pageSize, {
        ...params,
        organizationId: this.treeNode.id,
        from: "plan",
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/hiddenTrouble/check/daily/edit`,
        query: {
          id: row.id,
          type: "edit",
        },
      });
    },
    rowView(row) {
      this.$router.push({
        path: `/hiddenTrouble/check/daily/edit`,
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.daily {
  width: 100%;
}
.clacWidth {
  width: calc(100% - 280px);
}
::v-deep .el-dialog__body {
  padding: 20px;
}
.kicket-content {
  display: flex;
  padding: 0 20px;
  flex-wrap: wrap;

  .kicket {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ededed;
    width: 250px;
    height: 90px;
    border-radius: 8px;
    margin: 10px 20px;
    .img-item {
      width: 40px;
      height: 40px;
    }
    .text-item {
      color: #333333;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
