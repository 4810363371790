var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "head-search" },
        [
          _c("head-layout", { attrs: { "head-title": "知识维护" } }),
          _c("searchCommon", {
            on: {
              searchGrid: _vm.searchGrid,
              searchClass: _vm.searchClass,
              searchGridAll: _vm.searchGridAll,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tag-content" },
        [
          _vm.showClassification
            ? _c("knowledge-classification", {
                ref: "knowledgeClass",
                on: { "select-tag": _vm.selectTag },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                _vm.isAdmin
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function ($event) {
                            return _vm.rowEdit(row)
                          },
                        },
                      },
                      [_vm._v("\n        编辑\n      ")]
                    )
                  : _vm._e(),
                _vm.isAdmin
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function ($event) {
                            return _vm.rowRemove(row)
                          },
                        },
                      },
                      [_vm._v("\n        删除\n      ")]
                    )
                  : _vm._e(),
                row.isPublish == "0"
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function ($event) {
                            return _vm.publish(row, "1")
                          },
                        },
                      },
                      [_vm._v("\n        发布\n      ")]
                    )
                  : _vm._e(),
                row.isPublish == "1"
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function ($event) {
                            return _vm.publish(row, "0")
                          },
                        },
                      },
                      [_vm._v("\n        取消发布\n      ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }