<template>
  <div>
    <el-container>
      <!-- <CommonTree
                ref="commonTree"
                treeTitle="设备分类"
                :treeData="treeData"
                nodeKey="id"
                searchTitle="className"
                :defaultProps="{label: 'className'}"
                isShowdig
                @getTreeAdd="getTreeAdd"
                @getTreeEdit="getTreeEdit"
                @getTreeDelete="getTreeDelete"
                @getNodeClick="handleNodeClick"
            /> -->
      <CommonTreeNew
        ref="commonTree"
        treeTitle="设备分类"
        :treeData="treeData"
        nodeKey="id"
        searchTitle="className"
        :defaultProps="{ label: 'className' }"
        isShowdig
        @getTreeAdd="getTreeAdd"
        @getTreeEdit="getTreeEdit"
        @getTreeDelete="getTreeDelete"
        @getNodeClick="handleNodeClick"
        url="/api/sinoma-hse-prj/bsdeviceclasshead/tree"
        :urlParmas="{}"
      />
      <el-main>
        <head-layout
          head-title="设备分类设置"
          :head-btn-options="headBtnOptions"
          @head-add-tabs="headAdd"
          @head-import="headImport"
          @head-export="headExport"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @row-edit="rowEdit"
          @row-remove="rowRemove"
        ></grid-layout>
        <!-- :
                        @grid-row-detail-click="rowDetail"
                    @row-edit="rowEdit" -->
      </el-main>
    </el-container>
    <!-- 设备分类左侧树操作 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="设备分类"
      :before-close="handleTreeCancel"
    >
      <avue-form
        :option="treeDialogOption"
        ref="cedForm"
        v-model="treeForm"
        @submit="handleSubmit"
      ></avue-form>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleTreeCancel()"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
    <Add ref="add" @callback="callback" />
    <!-- 导入 -->
    <el-dialog
      title="设备分类导入"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          :upload-after="uploadAfter"
          v-if="excelBox"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{ $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  getClassTree,
  getClassSubmit,
  getClassRemove,
  getClassLinPage,
  getClassLinRemove,
  getClassLinExport,
} from "@/api/bssettings/DeviceClass";
import { mapGetters } from "vuex";
import Add from "./add.vue";
import { downloadXls } from "@/util/util";
import { exportBlob } from "@/api/common";
import { getToken } from "@/util/auth";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    Add,
  },

  data() {
    return {
      treeData: [],
      dialogVisible: false,
      treeForm: {
        className: "",
        parentId: "",
        id: "",
      },
      treeDialogLoading: false,
      nodeData: {},
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      excelBox: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.excelFile"),
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            data: { classHeadId: "" },
            action: "/api/sinoma-hse-prj/bsdeviceclassline/import",
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
      treeParentData: [],
    };
  },

  created() {
    this.getTreeData();
    this.onLoad(this.page);
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
      };
    },
    searchColumns() {
      return [
        {
          prop: "deviceName",
          placeholder: this.$t("search"),
          span: 6,
        },
      ];
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permissionList.addBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
      }
      buttonBtn.push({
        label: "导入",
        emit: "head-import",
        type: "button",
        icon: "",
        btnOptType: "import",
      });
      buttonBtn.push({
        label: "导出",
        emit: "head-export",
        type: "button",
        icon: "",
        btnOptType: "export",
      });
      return buttonBtn;
    },

    tableOptions() {
      return {
        customRowAddBtn: false,
        menuWidth: 200,
        menuFixed: "right",
        selection: false,
        index: true,
        indexLabel: "序号",
        linklabel: "deviceCode",
        column: [
          {
            label: "编号",
            prop: "deviceCode",
            align: "center",
            overHidden: true,
            // slot: true,
          },
          {
            label: "名称",
            prop: "deviceName",
            align: "left",
            overHidden: true,
          },
          {
            label: "品牌",
            prop: "deviceBrand",
            align: "center",
            overHidden: true,
          },
          {
            label: "厂家",
            prop: "deviceFactory",
            align: "center",
            overHidden: true,
          },
          {
            label: "出厂日期",
            prop: "factoryDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "加入日期",
            prop: "addDate",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },

    gridRowBtn() {
      let buttonBtn = [];
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },

    treeDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "className",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur"],
              },
            ],
          },
          {
            label: "上级名称",
            prop: "parentId",
            type: "tree",
            disabled: false,
            dicData: this.treeParentData,
            props: {
              label: "className",
              value: "id",
            },
            rules: [
              {
                validator: this.partentRule,
              },
            ],
          },
        ],
      };
    },
  },

  methods: {
    partentRule(rule, value, callback) {
      if (value === "" || !this.treeForm.id) {
        callback();
      } else if (value == this.treeForm.id) {
        callback(new Error("不能选择自己作为上级!"));
      } else {
        callback();
      }
    },
    /** 左侧列表操作 */
    getTreeData() {
      getClassTree().then((res) => {
        this.treeData = res.data.data;
      });
    },
    handleNodeClick(data, node) {
      // console.log(data,node)
      this.nodeData = data;
      this.query.classHeadId = data.id;
      this.excelOption.column[0].data = { classHeadId: data.id };
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    getTreeAdd() {
      this.treeParentData = this.treeData;
      this.treeForm = { className: "", parentId: "", id: "" };
      if (this.nodeData.id) this.treeForm.parentId = this.nodeData.id;
      this.dialogVisible = true;
    },
    getTreeEdit() {
      // treeParentData
      const data = JSON.parse(JSON.stringify(this.treeData));
      const { className, id, parentId } = this.nodeData;
      this.getParentData(data, id);
      this.treeForm = { className, id, parentId };
      this.dialogVisible = true;
    },
    getParentData(data, id) {
      const arr = data.map((val, index) => {
        if (val.id == id) {
          data.splice(index, 1);
        } else if (val.children) {
          this.getParentData(val.children, id);
          return val;
        } else {
          return val;
        }
      });
      this.treeParentData = arr;
    },
    getTreeDelete() {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        getClassRemove(this.nodeData.id).then(() => {
          this.getTreeData();
          this.$message.success("操作成功");
        });
      });
    },
    handleTreeCancel() {
      this.dialogVisible = false;
    },
    handleSubmit(data, done) {
      console.log(data);
      this.$refs.cedForm.validate((valid) => {
        if (valid) {
          this.treeDialogLoading = true;
          getClassSubmit(this.treeForm)
            .then(() => {
              this.getTreeData();
              this.$message.success("操作成功");
              this.treeDialogLoading = false;
              this.handleTreeCancel();
              done();
            })
            .catch((err) => {
              done();
            })
            .finally(() => {
              this.treeDialogLoading = false;
            });
        }
      });
      // if(this.treeForm.id != this.treeForm.parentId || !this.treeForm.parentId ){
      // }
    },
    handleTreeNodeSave() {
      // if(this.treeForm.id != this.treeForm.parentId || !this.treeForm.parentId ){
      this.$refs.cedForm.submit();
      // }
    },
    /**----end---- */
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      getClassLinPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        console.log();
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },

    rowEdit(row, index) {
      this.$refs.add.init("edit", row);
    },
    rowView(row) {
      this.$refs.add.init("view", row);
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        getClassLinRemove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    headAdd() {
      if (this.nodeData.id) {
        const data = { classHeadId: this.nodeData.id };
        this.$refs.add.init("add", data);
      } else {
        this.$message.warning("请先选择分类");
      }
    },
    headImport() {
      if (this.nodeData.id) {
        this.excelBox = true;
      } else {
        this.$message.warning("请先选择分类");
      }
    },
    headExport() {
      const classHeadId = this.nodeData.id ? this.nodeData.id : "";
      const deviceName = this.query.deviceName ? this.query.deviceName : "";
      const title = this.nodeData.className
        ? this.nodeData.className
        : "设备分类设置";
      exportBlob(
        `/api/sinoma-hse-prj/bsdeviceclassline/export?classHeadId=${classHeadId}&deviceName=${deviceName}`
      ).then((res) => {
        downloadXls(res.data, title);
      });
    },
    callback() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },

    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.page.currentPage = 1;
      this.onLoad(this.page);
      done();
    },
    handleTemplate() {
      exportBlob(
        `/api/sinoma-hse-prj/bsdeviceclassline/exportTemplate?${
          this.website.tokenHeader
        }=${getToken()}`
      ).then((res) => {
        downloadXls(res.data, "设备分类设置模板.xlsx");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  text-align: left;
}
.upload-text {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}
</style>
