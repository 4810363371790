var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "columnDesign-box" },
    [
      _c("column-main", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTab === "pc",
            expression: "currentTab==='pc'",
          },
        ],
        ref: "columnMain",
        attrs: {
          conf: _vm.columnData,
          modelType: _vm.modelType,
          webType: _vm.webType,
        },
      }),
      _c("column-main-app", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTab === "app",
            expression: "currentTab==='app'",
          },
        ],
        ref: "columnMainApp",
        attrs: { conf: _vm.appColumnData, modelType: _vm.modelType },
      }),
      _c(
        "div",
        { staticClass: "head-tabs" },
        [
          _c(
            "el-button",
            {
              class: { "unActive-btn": _vm.currentTab !== "pc" },
              attrs: {
                icon: "icon-ym icon-ym-pc",
                type: "text",
                size: "medium",
              },
              on: {
                click: function ($event) {
                  _vm.currentTab = "pc"
                },
              },
            },
            [_vm._v("桌面端\n    ")]
          ),
          _c(
            "el-button",
            {
              class: { "unActive-btn": _vm.currentTab !== "app" },
              attrs: {
                icon: "icon-ym icon-ym-mobile",
                type: "text",
                size: "medium",
              },
              on: {
                click: function ($event) {
                  _vm.currentTab = "app"
                },
              },
            },
            [_vm._v("移动端\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }