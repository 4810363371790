<template>
  <el-container>
    <!-- <el-container> -->
    <!-- <CommonTree
      :treeData="treeData"
      :defaultProps="treePropss"
      searchTitle="dictValue"
      :isShowdig="false"
      :showCheckbox="false"
      treeTitle="风险等级"
      @getNodeClick="nodeTreeClick"
    /> -->

    <CommonTreeNew
      :defaultProps="treePropss"
      searchTitle="dictValue"
      :isShowdig="false"
      :showCheckbox="false"
      treeTitle="风险等级"
      nodeKey="dictKey"
      @getNodeClick="nodeTreeClick"
      url="/api/sinoma-system/dict-biz/dictionary"
      :urlParmas="{
        code: 'area_rank',
      }"
      :handleData="handleTreeData"
    />
    <!-- </el-container> -->
    <!-- :searchTitle="searchTitle" -->
    <!-- @getNodeClick="getNodeClick" -->
    <!-- <avue-tree
        :option="treeOption"
        :data="treeData"
        @node-click="nodeTreeClick"
        style="font-size: 12px; border-radius: 4px; padding: 0 5px 0 12px"
      ></avue-tree> -->
    <!-- style="border-right: 1px solid #cccccc" -->
    <!-- <div class="center"> -->
    <!-- </div> -->

    <el-main>
      <!--列表label-->
      <head-layout
        :head-btn-options="headBtnOptions"
        head-title="区域维护"
        @head-addhighrisk="handleAdd(3)"
        @head-addmediumrisk="handleAdd(2)"
        @head-addlowrisk="handleAdd(1)"
        @head-addsafe="handleAdd(0)"
        @head-loseEfficacy="handleLoseEfficacy"
      >
      </head-layout>
      <!--列表查询条件和头部按钮-->
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableoptions"
        :table-data="tableData"
        :table-loading="loading"
        :data-total="page.total"
        :page="page"
        @page-current-change="handleCurrentChange"
        @page-size-change="handleSizeChange"
        @page-refresh-change="onLoad"
        @gird-handle-select-click="selectionChange"
      >
        <!-- @page-current-change="handleCurrentChange" -->
        <!-- @page-size-change="handleSizeChange" -->
        <template #areaRank="{ row }">
          <div v-if="row.areaRank == 3" :style="{ color: '#C5211B' }">高危</div>
          <div v-else-if="row.areaRank == 2" :style="{ color: '#e6a23c' }">
            中危
          </div>
          <div v-else-if="row.areaRank == 1" :style="{ color: '#0d7fea' }">
            低危
          </div>
          <div v-else-if="row.areaRank == 0" :style="{ color: '#67c23a' }">
            安全
          </div>
        </template>
        <!-- 失效红色 -->
        <template #areaState="{ row }">
          <div v-if="row.areaState == 1" :style="{ color: '#c5211b' }">
            失效
          </div>

          <div v-else>正常</div>
        </template>
        <template #customBtn="{ row }">
          <el-button
            type="text"
            size="small"
            v-if="row.areaState == 0"
            @click="loseEfficacy(row)"
            :style="{ color: '#c5211b' }"
          >
            失效
          </el-button>
        </template>
        <!-- :grid-row-btn="gridRowBtn" -->
      </grid-layout>
    </el-main>
    <el-dialog :title="risktitle" :visible.sync="dialogTree" width="550px">
      <el-select
        v-model="value"
        multiple
        placeholder="请选择区域名称"
        :popper-append-to-body="false"
        @remove-tag="removetag"
        @clear="clearall"
        clearable
      >
        <el-option :value="selectTree" class="setstyle" disabled>
          <el-input
            ref="input"
            v-model="filterText"
            clearable
            placeholder="请输入地区名称"
            prefix-icon="el-icon-search"
            size="small"
            style="margin-bottom: 10px"
          />

          <el-tree
            :props="treeProps"
            node-key="id"
            ref="treeRef"
            :data="areaTreeList"
            @check-change="handleNodeClick"
            :show-checkbox="true"
            :check-strictly="true"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
          ></el-tree>
        </el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTree = false">取 消</el-button>
        <el-button type="primary" @click="allotRights">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import { getList as getll, getAllTree, getLazyTree } from "@/api/base/region";
import {
  getList,
  update,
  bsareadangersubmitBatch,
} from "@/api/bssettings/bsareadanger";
import { getDictionaryCode } from "@/api/system/dictbiz";
import { mapGetters } from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

export default {
  components: { CommonTree, GridLayout, HeadLayout },
  data() {
    return {
      filterText: "",
      alltreedata: [],
      form: {},
      query: {},
      value: [],
      selectTree: [],
      addrisk: 0,
      risktitle: "设置",
      loading: true,
      searchTitle: "msgTypeName",
      dialogTree: false,
      topCode: "10",
      treeCode: "",
      treeParentCode: "",
      areaTreeList: [],
      // treeProps: {
      //   nodeKey: "id",
      //   // lazy: true,
      //   multiple: true,
      //   // treeLoad: function (node, resolve) {
      //   //   const parentCode = node.level === 0 ? "10" : node.data.id;
      //   //   getLazyTree(parentCode).then((res) => {
      //   //     console.log(res);
      //   //     resolve(
      //   //       res.data.data.map((item) => {
      //   //         return {
      //   //           ...item,
      //   //           leaf: !item.hasChildren,
      //   //         };
      //   //       })
      //   //     );
      //   //   });
      //   // },
      //   addBtn: false,
      //   menu: false,
      //   // filter: false, //去掉搜索框
      //   size: "small",
      //   props: {
      //     labelText: "标题",
      //     label: "name",
      //     value: "code",
      //     children: "childList",
      //   },
      // },
      treeProps: {
        children: "childList",
        label: "name",
        value: "code",
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },

      // gridLayOut数据
      selectionList: [],

      // 左侧树，由数据字典得
      data: [],
      treeData: [],
      treePropss: {
        label: "dictValue",
        value: "dictKey",
      },
      // treeOption: {
      //   size: "mini",
      //   addBtn: false,
      //   props: {
      //     label: "dictValue",
      //     value: "dictKey",
      //     // value: "dictValue",
      //   },
      // },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.bsareadanger_add, false),
        viewBtn: this.vaildData(this.permission.bsareadanger_view, false),
        delBtn: this.vaildData(this.permission.bsareadanger_delete, false),
        editBtn: this.vaildData(this.permission.bsareadanger_edit, false),
      };
    },
    // searchColumns() {
    //             return [
    //                 {
    //                     prop: "deviceName",
    //                     placeholder: this.$t('search'),
    //                     span: 6,
    //                 },
    //             ]
    //         },
    tableoptions() {
      return {
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        selection: true,
        dialogClickModal: false,
        index: true,
        indexLabel: "序号",
        menuFixed: "right", //操作列冻结
        column: [
          {
            label: "编号",
            prop: "areaCode",
            overHidden: true,
          },
          {
            label: "名称",
            prop: "areaName",
            // span: 12,
            width: 300,
            overHidden: true,
          },
          {
            label: "风险等级",
            prop: "areaRank",
            dicData: [
              { label: "安全", value: "0" },
              { label: "低危", value: "1" },
              { label: "中危", value: "2" },
              { label: "高危", value: "3" },
            ],
            // dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=area_rank",
            props: {
              label: "label",
              value: "value",
              // label: "dictValue",
              // value: "dictKey",
            },
            slot: true,
            align: "center",
            dataType: "number",
            //     message: "请输入风险等级(area_rank):0安全;1低危;2中危;3高危",
          },
          {
            label: "状态",
            prop: "areaState",
            dicData: [
              { label: "正常", value: "0" },
              { label: "失效", value: "1" },
            ],
            props: {
              label: "label",
              value: "value",
            },
            slot: true,
            // type: "select",
            align: "center",
            dataType: "number",
            //     message: "请输入状态(area_state):0 正常1 失效",
          },
          {
            label: "加入时间",
            prop: "areaDate",
            align: "center",
            width: 120,
          },
        ],
      };
    },
    searchColumns() {
      return [
        {
          prop: "areaName",
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "名称",
          span: 6,
        },
      ];
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        label: "设置高危",
        emit: "head-addhighrisk",
      });
      buttonBtn.push({
        label: "设置中危",
        emit: "head-addmediumrisk",
      });
      buttonBtn.push({
        label: "设置低危",
        emit: "head-addlowrisk",
      });
      buttonBtn.push({
        label: "设置安全",
        emit: "head-addsafe",
      });

      buttonBtn.push({
        label: "批量失效",
        emit: "head-loseEfficacy",
      });
      return buttonBtn;
    },
    // ids() {
    //   let ids = [];
    //   let selectionList = this.$refs.gridLayOut.selectionList;
    //   selectionList.forEach((ele) => {
    //     ids.push(ele.id);
    //   });
    //   return ids.join(",");
    // },
  },

  mounted() {
    // this.getDialogTreeData();
    this.initTree();
    // this.getgetDictionaryCode();

    this.onLoad(this.page, {});
  },
  created() {},
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val); //根据el-tree的ref进行过滤
    },
  },
  methods: {
    // 筛选节点
    filterNode(value, data, node) {
      console.log(value, data, node, "vvvvvvvvvv");
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 显示区域风险等级
    showState(statename) {
      var state = "";
      this.dicData.map((item) => {
        if (item.dictKey == statename) {
          state = item.dictValue;
        }
      });
      return state;
    },
    // 获取数据字典
    getDictory() {
      dictionaryBiz("area_rank").then((res) => {
        this.dicData = res.data.data;
      });
    },
    handleTreeData(treeData) {
      console.log(treeData);
      treeData.unshift({
        dictValue: "全部",
      });
      this.treeData = treeData;
      // console.log(this.treeData);
      return treeData;
    },
    //左侧风险等级获取自数据字典
    getgetDictionaryCode() {
      getDictionaryCode("area_rank").then((res) => {
        this.treeData = res.data.data;
        console.log(this.treeData);
        this.treeData.unshift({
          dictValue: "全部",
          dictKey: 0,
        });
      });
    },
    // 点击左侧左侧树
    nodeTreeClick(node) {
      this.node = node;
      this.query.areaRank = this.node.dictKey;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 载入触发事件
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;
      if (this.dictValue) {
        if (this.dictValue == "全部") delete params.dictValue;
        else params.dictValue = this.dictValue;
      }
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        // console.log(this.$refs.gridLayOut.page.total);
        // console.log(data.total);
        this.tableData = data.records;
        this.loading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },

    //弹窗选择树初始化
    initTree() {
      this.areaTreeList = [];
      getAllTree().then((res) => {
        this.areaTreeList = res.data.data.map((item) => {
          return {
            ...item,
            leaf: !item.hasChild,
          };
        });
        console.log(res, "ssss");
      });
      // getLazyTree(this.topCode).then((res) => {
      //   this.areaTreeList = res.data.data.map((item) => {
      //     return {
      //       ...item,
      //       leaf: !item.hasChildren,
      //     };
      //   });
      // });
    },
    // 点击弹窗选择树
    handleNodeClick(data, self, child) {
      // console.log(this.$refs.treeRef.getCheckedNodes());
      let datalist = this.$refs.treeRef.getCheckedNodes();

      // let datalist = this.$refs.treeRef.getCurrentNode();
      // console.log("====", datalist);

      this.selectTree = []; //置空
      this.value = [];
      // datalist.forEach((item) => {
      datalist.forEach((item) => {
        // console.log("bbbbbbbbbb", item);
        // if (item.parentId !== "10") {
        // getAllTree().then((res) => {
        //   let code = this.parentId;
        //   console.log("ccccccccccc", res);
        //   console.log("ppppppppppppppp", code);
        // });
        // console.log("xxxxxxxxxx", item.parentId);
        // }
        // this.selectTree.push({
        //   id: item.id,
        //   label: item.title,
        //   value: item.value,
        // });
        // this.filterText = item.name;
        this.selectTree.push(item);
        // console.log("xxxxxxxxxx", item);
        this.value.push(item.name);
        // console.log("====", item);
      });
    },
    //  removetag() {
    //       this.selectTree.splice(0, 1);
    //       let setlist = this.$refs.treeRef.getCheckedNodes();
    //       setlist.splice(0, 1);
    //       this.$nextTick(() => {
    //         this.$refs.treeRef.setCheckedNodes(setlist);
    //       });
    //     },
    removetag(tag) {
      let selectlist = this.$refs.treeRef.getCheckedNodes();
      // 删除节点
      for (let i = 0; i < selectlist.length; ++i) {
        if (selectlist[i].name == tag) {
          selectlist.splice(i, 1);

          this.selectTree.splice(i, 1);
          // console.log(this.selectTree, "===============");
          break;
        }
      }

      // console.log("---------------", selectlist);
      // this.selectTree.splice(0, 1);
      // let setlist = this.$refs.treeRef.getCheckedNodes();
      // setlist.splice(0, 1);
      // console.log("===============", setlist);
      this.$nextTick(() => {
        this.$refs.treeRef.setCheckedNodes(selectlist);
      });
    },

    clearall() {
      this.selectTree = [];
      this.$nextTick(() => {
        this.$refs.treeRef.setCheckedNodes([]);
      });
    },

    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },

    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },

    selectionChange(list) {
      this.selectionList = list;
    },

    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },

    // 点击设置安全、低危、中危、高危
    handleAdd(n) {
      // this.selectTree = []; //置空
      // while (this.selectTree.length > 0) {
      //   this.selectTree.pop();
      // }
      // Vue.forceUpdate();
      // this.$forceUpdate();
      this.filterText = "";
      this.initTree();
      this.value = [];

      switch (n) {
        case 0:
          this.risktitle = "设置安全";
          break;
        case 1:
          this.risktitle = "设置低危";
          break;
        case 2:
          this.risktitle = "设置中危";
          break;
        case 3:
          this.risktitle = "设置高危";
          break;
      }

      this.addrisk = n;
      this.dialogTree = true;
    },

    enabledOrDisabled(row, areaState) {
      row.areaState = areaState;
      update(row).then(() => {
        this.onLoad(this.page);
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      });
    },
    // 行数据失效
    loseEfficacy(row) {
      this.enabledOrDisabled(row, 1);
    },

    // handleenabledOrDisabled(areaCode, areaState) {
    //   areaCode.areaState = areaState;
    //   update(areaCode).then(() => {
    //     this.onLoad(this.page);
    //     return this.$message({
    //       type: "success",
    //       message: "操作成功！",
    //     });
    //   });

    // },
    // 批量失效
    handleLoseEfficacy() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      // 选中的每一条设置状态为失效，0正常1失效
      this.selectionList.forEach((item) => (item.areaState = 1));
      //批量修改
      bsareadangersubmitBatch(this.selectionList).then(() => {
        this.onLoad(this.page);
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      });
    },
    getnames(code, values) {
      //
      for (let i = 0; i < values.length; ++i) {
        if (values[i].code == code) {
          return values[i];
        }
      }
    },
    // 弹窗确定
    allotRights() {
      let getinfo = [];
      // 时间
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      let timetime = `${year}-${month}-${day}`;
      console.log(this.areaTreeList);

      let qqw = this.areaTreeList;
      this.selectTree.forEach((v, i) => {
        let ancestors = v.ancestors.split(",");
        let name = "";
        let values = qqw;

        for (let jj = 0; jj < ancestors.length; ++jj) {
          if (ancestors[jj] == undefined || ancestors[jj].length <= 0) {
            continue;
          }
          let t = this.getnames(ancestors[jj], values);
          if (t == undefined) {
            continue;
          }
          if (name.length <= 0) {
            name = t.name;
          } else {
            name += "-" + t.name;
          }

          values = t.childList;
        }
        if (name.length <= 0) {
          name = v.name;
        } else {
          name += "-" + v.name;
        }
        // if (v.parentCode == "10") {
        //   name = v.name;
        // } else {
        //   name += "-" + v.name;
        // }
        // name += "-" + v.name;

        console.log(name, "xxxxxxxxxxxzz");
        let valueinfo = {
          areaCode: v.code,
          areaDate: timetime,
          areaName: name,
          areaRank: this.addrisk,
          areaState: 0,
        };
        getinfo.push(valueinfo);
      });
      // 批量新增
      bsareadangersubmitBatch(getinfo).then((res) => {
        if (res.data.code == 200) {
          this.onLoad(this.page);
          this.dialogTree = false;
          return this.$message({
            type: "success",
            message: "操作成功！",
          });
        }
      });
      this.selectTree = [];
      this.$forceUpdate();
    },
    // console.log(this.value);
    // console.log(this.$refs.treeRef.getCheckedNodes());
    // let xxxx = this.$refs.treeRef.getCheckedNodes();
    // this.selectTree = []; //置空
    // this.value = [];x
    // xxxx.forEach((item) => {
    //   this.selectTree.push({ id: item.id, label: item.title });
    //   this.value.push(item.title);
  },
};
</script>

<style>
</style>
