var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("h3", [_vm._v("点击新增或编辑跳转到新的页面")]),
      _c(
        "avue-crud",
        {
          attrs: { option: _vm.option, data: _vm.data },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function ({ row }) {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "text",
                        icon: "el-icon-edit",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleForm(row.id)
                        },
                      },
                    },
                    [_vm._v("edit")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleForm()
                    },
                  },
                },
                [_vm._v("add")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }