<template>
  <div style="position: relative">
    <div>
      <!-- 闸机列表 -->
      <head-layout head-title="算法任务" :head-btn-options="headBtnOptions" @head-add="handleAdd" @head-import="handleImport" @head-export="handleExport" @head-user="handleUser"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout class="table-container" ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template #customBtn="{ row }">
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowBatchOpt(row)">启动
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="taskIdentify(row)">任务配置
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowUpdate(row)">编辑
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel(row)">删除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <addTask ref="addTask"  @callback="onLoad(page, {})"></addTask>
    <taskIdentifyConfiguration ref="taskIdentifyConfiguration" @callback="onLoad(page, {})"></taskIdentifyConfiguration>
  </div>
</template>

<script>
  import {
    taskPageList,
    taskBatchOpt,
    add,
    update,
    taskRemove,
    saveBatch
  } from "@/api/safe/algorithmManagement";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import {
    getToken
  } from "@/util/auth";
  import {
    ALGO_TASK_STATUS
  } from '../nodeDict.js'
  import addTask from "./addTask";
import taskIdentifyConfiguration from './taskIdentifyConfiguration/index'

  export default {
    components: {
      GridLayout,
      HeadLayout,
      addTask,
      taskIdentifyConfiguration
    },
    props: {
      selectTree: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        page: {
          pageSize: 20,
          pageIndex: 1,
          total: 0,
        },
        selectionList: [],
        tableOption: {
          // linklabel: "eqtCode",
          menuWidth: 230,
          reserveSelection: true,
          column: [{
              label: "任务编码",
              prop: "code",
              align: "center",
              overHidden: true,
            },
            {
              label: "任务名称",
              prop: "name",
              align: "center",
              overHidden: true,
            },
            {
              label: "任务执行状态",
              prop: "status",
              align: "center",
              // dataType: "number",
              props: {
                label: "name",
                value: "value",
              },
              dicData: ALGO_TASK_STATUS,
            },
            {
              label: "算法名称",
              prop: "modelName",
              align: "center",
              overHidden: true,
            },
            {
              label: "算法版本",
              prop: "modelVersion",
              align: "center",
              overHidden: true,
            },
            {
              label: "所属终端",
              prop: "serverName",
              align: "center",
              overHidden: true,
            },

            {
              label: "备注",
              prop: "remark",
              align: "center",
              overHidden: true,
            },


          ],
        },
        headBtnOptions: [{
            label: "添加任务",
            emit: "head-add",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },
          {
            label: "批量操作",
            emit: "head-add",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },
          // {
          //   label: "导入",
          //   emit: "head-import",
          //   type: "button",
          //   icon: "",
          //   btnOptType: "import",
          // },
          // {
          //   label: "导出",
          //   emit: "head-export",
          //   type: "button",
          //   icon: "",
          //   btnOptType: "export",
          // },
        ],
        searchColumns: [{
            label: "编码/名称",
            prop: "eqtCode",
            span: 4,
            placeholder: "请输入编码/名称",
          },
          {
            label: "状态",
            prop: "visitStatus",
            span: 4,
            placeholder: "请选择状态",
            props: {
              label: "name",
              value: "value",
            },
            type: "select",
            dicData: ALGO_TASK_STATUS,
          },
        ],
        tableData: [],
        excelBox: false,

      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirduser_add, false),
          viewBtn: this.vaildData(this.permission.thirduser_view, false),
          delBtn: this.vaildData(this.permission.thirduser_delete, false),
          editBtn: this.vaildData(this.permission.thirduser_edit, false),
        };
      },
    },
    watch: {
      selectTree: {
        immediate: true,
        handler: function (data) {
          if (data) this.onLoad(this.page, {});
        },
      },

    },
    mounted() {
      // this.onLoad(this.page, {});
    },
    methods: {
      handleAdd() {
        this.$refs.addTask.init("add", null,this.selectTree);
      },
      rowUpdate(row) {
        this.$refs.addTask.init("edit", row,this.selectTree);
      },
      //查看
      rowView(row) {
        this.$refs.addTask.init("view", row,this.selectTree);
      },
      taskIdentify(row){
        this.$refs.taskIdentifyConfiguration.init(row);
      },
      // 多选
      selectionChange(list) {
        this.selectionList = list;
      },
      rowBatchOpt(row) {
        taskBatchOpt({
          id: row.id
        }).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
      },


      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return taskRemove({
              id: row.id
            });
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.crud.toggleSelection();
          });
      },
      // 搜索
      gridHeadSearch(searchForm) {
        this.page.pageIndex = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.pageIndex = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      onLoad(page, params = {}) {
        this.tableLoading = false;
        this.page = page;
        params.serverId = this.selectTree.id
        params.pageIndex = page.pageIndex
        params.pageSize = page.pageSize
        this.$nextTick(() => {
          taskPageList(
          Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.$nextTick(() => {
              this.$refs.gridLayOut.grid.refreshTable(); //解决表格错位
            })

            this.tableLoading = false;
          } else {
            this.tableLoading = false;
          }
        });
        })

      },

    },
  };

</script>

<style lang="less" scoped>
  ::v-deep .table-container {
    .el-table {
      height: calc(100vh - 334px) !important;
      max-height: calc(100vh - 334px) !important;
    }
  }

</style>
