<template>
  <div>
    <div class="disFlex">
      <div class="card-pd-2" style="width: calc(37% - 60px)">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 风险等级分布 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div style="display: flex; margin-top: 37px">
          <div id="chart-six"></div>
          <div class="chart-six-info">
            <div class="chart-six-info-box">
              <div class="info-circle" style="background-color: #fb3636"></div>
              <span>重大风险</span>
              <span class="info-number">{{ formatWithCommas(30) }}个</span>
              <span>3.75%</span>
            </div>
            <div class="chart-six-info-box" style="margin-top: 18px">
              <div class="info-circle" style="background-color: #ffab2d"></div>
              <span>较大风险</span>
              <span class="info-number">{{ formatWithCommas(270) }}个</span>
              <span>33.75%</span>
            </div>
            <div class="chart-six-info-box" style="margin-top: 18px">
              <div class="info-circle" style="background-color: #ffed8c"></div>
              <span>一般风险</span>
              <span class="info-number">{{ formatWithCommas(170) }}个</span>
              <span>21.25%</span>
            </div>
            <div class="chart-six-info-box" style="margin-top: 18px">
              <div class="info-circle" style="background-color: #4aa3ff"></div>
              <span style="width: 64px">低风险</span>
              <span class="info-number">{{ formatWithCommas(330) }}个</span>
              <span>41.25%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-pd-2" style="width: calc(63% - 60px)">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 安全检查次数 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">安全检查次数</div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value1) || 0 }}<span>次</span>
            </div>
            <slider :value="68" />
            <div class="botLitte2-info">
              计划检查次数：<span>{{ formatWithCommas(31) }}次</span>
            </div>
            <div class="botLitte2-info">
              完成率：<span>{{ formatWithCommas(66) }}%</span>
            </div>
          </div>
          <div id="chart-seven"></div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 公司安全检查次数TOP5 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-eight"></div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 每月安全检查情况 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-nine"></div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 隐患发现及整改次数 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div class="leftChartBox">
            <div id="chart-ten"></div>
            <div class="chart-ten-info">
              <div class="chart-six-info-box">
                <div
                  class="info-circle"
                  style="background-color: #fb3636"
                ></div>
                <span>重大风险</span>
                <span class="info-number">{{ formatWithCommas(30) }}个</span>
                <span>64%</span>
              </div>
              <div class="chart-six-info-box">
                <div
                  class="info-circle"
                  style="background-color: #ffab2d"
                ></div>
                <span>较大风险</span>
                <span class="info-number">{{ formatWithCommas(270) }}个</span>
                <span>36%</span>
              </div>
              <div class="chart-six-info-box">
                <div
                  class="info-circle"
                  style="background-color: #ffed8c"
                ></div>
                <span>一般风险</span>
                <span class="info-number">{{ formatWithCommas(170) }}个</span>
                <span>36%</span>
              </div>
              <div class="chart-six-info-box">
                <div
                  class="info-circle"
                  style="background-color: #4aa3ff"
                ></div>
                <span style="width: 64px">低风险</span>
                <span class="info-number">{{ formatWithCommas(330) }}个</span>
                <span>36%</span>
              </div>
            </div>
          </div>
          <div class="rightChartBox">
            <div id="chart-eleven"></div>
            <div
              class="chart-six-info-box"
              style="justify-content: center; margin-top: 18px"
            >
              <div class="info-circle" style="background-color: #fb3636"></div>
              <span>隐患整改数</span>
              <span style="margin-left: 12px">{{ formatWithCommas(30) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 下属组织隐患整改率TOP5 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-twelve"></div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2" style="width: 100%">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 隐患发现及整改趋势 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-thirteen"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import slider from "@/views/business/board/components/slider";
import { appIndex } from "@/api/user";
import { mapGetters } from "vuex";

export default {
  components: { slider },
  data() {
    return {
      year: "",
      month: "",
      dataForm: {
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: "",
        value6: "",
        value7: "",
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.year = new Date().getFullYear();
  },
  mounted() {
    setTimeout(() => {
      this.getChartSix();
      this.getChartSeven();
      this.getChartEight();
      this.getChartNine();
      this.getChartTen();
      this.getChartEleven();
      this.getChartTwelve();
      this.getChartThirteen();
    }, 200);
    this.getIndexData();
  },
  methods: {
    formatWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getIndexData() {
      // 安全检查次数
      appIndex({
        indexCode: "CHECK_CNT_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value1 = res.data.data.records[0]._value;
        }
      });
    },
    getChartSix() {
      var myChart = this.$echarts.init(document.getElementById("chart-six"));
      var colorList = [
        {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#FF8181", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#FB3636", // 100% 处的颜色
            },
          ],
        },
        {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#FFAB2D", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#FFCD82", // 100% 处的颜色
            },
          ],
        },
        {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#FDE252", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#FFED8C", // 100% 处的颜色
            },
          ],
        },
        {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#4AA3FF", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#74B8FF", // 100% 处的颜色
            },
          ],
        },
      ];
      var option = {
        title: {
          text: "风险总数",
          top: "35%",
          textAlign: "center",
          left: "47%",
          textStyle: {
            color: "#484A4D",
            fontSize: 16,
            fontWeight: 400,
          },
          subtext: "800个",
          subtextStyle: {
            color: "#484A4D",
            fontSize: 26,
            fontWeight: 700,
          },
          itemGap: 10, //主副标题间距
        },
        tooltip: {
          formatter: (params) => {
            if (params.name != "") {
              return params.name + " : " + params.value + "\n" + "(个)";
            }
          },
        },
        series: [
          {
            type: "pie",
            radius: ["63%", "90%"],
            avoidLabelOverlap: false,
            padAngle: 2,
            itemStyle: {
              borderRadius: 4,
              borderColor: "#fff",
              borderWidth: 2,
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            color: ["#FB3636", "#FFAB2D", "#FFED8C", "#4AA3FF"],
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 30,
                name: "重大风险",
              },
              {
                value: 270,
                name: "较大风险",
              },
              {
                value: 170,
                name: "一般风险",
              },
              {
                value: 330,
                name: "低风险",
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartSeven() {
      var myChart = this.$echarts.init(document.getElementById("chart-seven"));
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        grid: {
          // 让图表占满容器
          top: "00px",
          left: "100px",
          right: "40px",
          bottom: "25px",
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "rgba(134, 144, 156, 1)",
          },
          interval: 50,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "category",
          axisLabel: {
            color: "rgba(78, 89, 105, 0.6)",
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: ["#E8E8E8"],
            },
          },
          data: ["专项检查", "政府检查", "项目自查", "领导带班检查"],
        },
        series: [
          {
            name: "次数",
            type: "bar",
            stack: "total",
            color: "#FB3636",
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: "linear",
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#FB3636", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FF8181", // 100% 处的颜色
                      },
                    ],
                  };
                },
              },
            },
            barWidth: 25,
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " 次";
              },
            },
            data: [320, 302, 301, 334, 390],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartEight() {
      var myChart = this.$echarts.init(document.getElementById("chart-eight"));
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        grid: {
          // 让图表占满容器
          top: "40px",
          left: "60px",
          right: "40px",
          bottom: "25px",
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "rgba(134, 144, 156, 1)",
          },
          interval: 30,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "category",
          axisLabel: {
            color: "rgba(78, 89, 105, 0.6)",
          },
          nameTextStyle: {
            //y轴上方单位的颜色
            color: "rgba(120, 122, 128, 1)",
          },
          name: "单位：次",
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: ["#E8E8E8"],
            },
          },
          data: ["北京中材", "北京中材", "北京中材", "北京中材", "北京中材"],
        },
        series: [
          {
            name: "次数",
            type: "bar",
            stack: "total",
            color: "#FB3636",
            barWidth: 25,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: "linear",
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#FB3636", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FF8181", // 100% 处的颜色
                      },
                    ],
                  };
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " 次";
              },
            },
            data: [320, 302, 301, 334, 390],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartNine() {
      var myChart = this.$echarts.init(document.getElementById("chart-nine"));
      var option = {
        color: ["#FF7410", "#C7C7C8"],
        backgroundColor: "#FFFFFF",
        grid: {
          top: "40px",
          left: "10px",
          right: "0",
          bottom: "0",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            data: ["1月", "2月", "3月", "4月", "5月"],
          },
        ],
        yAxis: [
          {
            type: "value",
            nameTextStyle: {
              //y轴上方单位的颜色
              color: "rgba(120, 122, 128, 1)",
            },
            name: "单位：次",
          },
        ],
        series: [
          {
            type: "line",
            smooth: true,
            symbol: "none",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(254, 116, 116, 0.3)", // 0% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(255, 244, 243, 0.6)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            lineStyle: {
              color: "rgba(251, 54, 54, 1)",
            },
            emphasis: {
              focus: "series",
            },
            data: [32, 65, 95, 22, 185],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartTen() {
      appIndex({
        indexCode: "HD_CNT_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm.value1 = res.data.data.records[0]._value;

          var myChart = this.$echarts.init(
            document.getElementById("chart-ten")
          );
          var colorList = [
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#FF8181", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#FB3636", // 100% 处的颜色
                },
              ],
            },
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#FFAB2D", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#FFCD82", // 100% 处的颜色
                },
              ],
            },
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#FDE252", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#FFED8C", // 100% 处的颜色
                },
              ],
            },
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#4AA3FF", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#74B8FF", // 100% 处的颜色
                },
              ],
            },
          ];
          var option = {
            title: {
              text: "隐患发现数",
              top: "35%",
              textAlign: "center",
              left: "47%",
              textStyle: {
                color: "#484A4D",
                fontSize: 16,
                fontWeight: 400,
              },
              subtext: "238",
              subtextStyle: {
                color: "#484A4D",
                fontSize: 26,
                fontWeight: 700,
              },
              itemGap: 10, //主副标题间距
            },
            tooltip: {
              formatter: (params) => {
                if (params.name != "") {
                  return params.name + " : " + params.value + "\n" + "(个)";
                }
              },
            },
            series: [
              {
                type: "pie",
                radius: ["63%", "90%"],
                avoidLabelOverlap: false,
                padAngle: 2,
                itemStyle: {
                  borderRadius: 4,
                  borderColor: "#fff",
                  borderWidth: 2,
                  normal: {
                    color: function (params) {
                      return colorList[params.dataIndex];
                    },
                  },
                },
                color: ["#FB3636", "#FFAB2D", "#FFED8C", "#4AA3FF"],
                label: {
                  show: false,
                  position: "center",
                },
                labelLine: {
                  show: false,
                },
                data: [
                  {
                    value: 30,
                    name: "重大风险",
                  },
                  {
                    value: 270,
                    name: "较大风险",
                  },
                  {
                    value: 170,
                    name: "一般风险",
                  },
                  {
                    value: 330,
                    name: "低风险",
                  },
                ],
              },
            ],
          };
          myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        }
      });
    },
    getChartEleven() {
      // 安全隐患整改完成率
      appIndex({
        indexCode: "HD_RECTIFICATE_RATE_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          let value = res.data.data.records[0]._value || 0;

          var myChart = this.$echarts.init(
            document.getElementById("chart-eleven")
          );
          var colorList = [
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#FF8181", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#FB3636", // 100% 处的颜色
                },
              ],
            },
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#DEE2EA", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#DEE2EA", // 100% 处的颜色
                },
              ],
            },
          ];
          var option = {
            title: {
              text: "隐患整改率",
              top: "35%",
              textAlign: "center",
              left: "47%",
              textStyle: {
                color: "#484A4D",
                fontSize: 16,
                fontWeight: 400,
              },
              subtext: value + "%",
              subtextStyle: {
                color: "#484A4D",
                fontSize: 26,
                fontWeight: 700,
              },
              itemGap: 10, //主副标题间距
            },
            tooltip: {
              formatter: (params) => {
                if (params.name != "") {
                  return params.name + " : " + params.value + "\n" + "(个)";
                }
              },
            },
            series: [
              {
                type: "pie",
                radius: ["63%", "90%"],
                avoidLabelOverlap: false,
                padAngle: 2,
                itemStyle: {
                  borderRadius: 4,
                  borderColor: "#fff",
                  borderWidth: 2,
                  normal: {
                    color: function (params) {
                      return colorList[params.dataIndex];
                    },
                  },
                },
                color: ["#FB3636", "#DEE2EA"],
                label: {
                  show: false,
                  position: "center",
                },
                labelLine: {
                  show: false,
                },
                data: [
                  {
                    value: 30,
                    name: "隐患已整改数",
                  },
                  {
                    value: 270,
                    name: "隐患未整改数",
                  },
                ],
              },
            ],
          };
          myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        }
      });
    },
    getChartTwelve() {
      var myChart = this.$echarts.init(document.getElementById("chart-twelve"));
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
          right: "0%",
        },
        grid: {
          // 让图表占满容器
          top: "40px",
          left: "60px",
          right: "40px",
          bottom: "25px",
        },
        xAxis: {
          type: "value",
          axisLabel: {
            //刻度标签的内容格式器，支持字符串模板和回调函数两种形式。
            formatter: "{value}%",
          },
          max: 100,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "category",
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: ["#E8E8E8"],
            },
          },
          data: ["北京中材", "北京中材", "北京中材", "北京中材", "北京中材"],
        },
        series: [
          {
            name: "已整改",
            type: "bar",
            stack: "total",
            color: "#FB3636",
            barWidth: 25,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: "linear",
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#FB3636", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FF8181", // 100% 处的颜色
                      },
                    ],
                  };
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " %";
              },
            },
            data: [30, 30, 30, 30, 30, 30, 30],
          },
          {
            name: "待整改",
            type: "bar",
            stack: "total",
            color: "#FFAB2D",
            barWidth: 25,
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " %";
              },
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: "linear",
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFAB2D", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FFCD82", // 100% 处的颜色
                      },
                    ],
                  };
                },
              },
            },
            data: [40, 40, 40, 40, 40, 40, 40],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartThirteen() {
      var myChart = this.$echarts.init(
        document.getElementById("chart-thirteen")
      );
      const rawData = [
        [100, 302, 301, 334, 390, 330, 320],
        [320, 132, 101, 134, 90, 230, 210],
        [220, 182, 191, 234, 290, 330, 310],
      ];
      const totalData = [];
      for (let i = 0; i < rawData[0].length; ++i) {
        let sum = 0;
        for (let j = 0; j < rawData.length; ++j) {
          sum += rawData[j][i];
        }
        totalData.push(sum);
      }
      const grid = {
        left: 40,
        right: 40,
        top: 50,
        bottom: 25,
      };
      var option = {
        legend: {
          selectedMode: false,
          right: "0",
        },
        grid,
        yAxis: {
          type: "value",
          name: "单位：个",
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        xAxis: {
          type: "category",
          data: ["1月", "2月", "3月", "4月", "5月", "6月"],
        },
        series: [
          {
            name: "已整改",
            type: "bar",
            stack: "total",
            color: "#FB3636",
            barWidth: "35%",
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#FB3636", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FF8181", // 100% 处的颜色
                      },
                    ],
                  };
                },
              },
            },
            data: [320, 302, 301, 334, 390, 330, 320],
          },
          {
            name: "待整改",
            type: "bar",
            stack: "total",
            color: "#FFAB2D",
            barWidth: "35%",
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFAB2D", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FFCD82", // 100% 处的颜色
                      },
                    ],
                  };
                },
              },
            },
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "逾期未整改",
            type: "bar",
            stack: "total",
            color: "#FFED8C",
            barWidth: "35%",
            emphasis: {
              focus: "series",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#FDE252", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FFED8C", // 100% 处的颜色
                      },
                    ],
                  };
                },
              },
            },
            data: [320, 302, 301, 334, 390, 330, 320],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.disFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.card-pd-2 {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-2-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-2-top-Title {
  color: #15161a;
  font-size: 21px;
  font-weight: bold;
}

.card-pd-2-top-btn {
  display: flex;
  justify-content: flex-end;
}

.card-pd-2-top-btn img {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}

.card-pd-2-Bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

#chart-six {
  width: 209px;
  height: 209px;
  margin-left: 77px;
}

#chart-seven {
  width: 70%;
  height: 248px;
}

#chart-eight {
  width: 100%;
  height: 320px;
}

#chart-nine {
  width: 100%;
  height: 320px;
}

.chart-six-info {
  padding: 33px 0;
  margin-left: 46px;
}

.chart-six-info-box {
  color: #1d2129;
  font-size: 16px;
  display: flex;
}

.info-number {
  margin: 0 12px;
  width: 50px;
}

.info-circle {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin: 6px 6px 6px 0;
}

.card-pd-2-BotLitte2 {
  width: 30%;
  border-radius: 8px;
  padding: 21px;
  background: linear-gradient(180deg, #ffe1e761 -32.69%, #fffcfc 100%);
}

.botLitte2-number {
  color: #15161a;
  font-size: 37px;
  font-weight: bold;
  margin-top: 21px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}

.botLitte2-number span {
  color: #484a4d;
  font-size: 18px;
  margin-left: 8px;
}

.botLitte2-title {
  width: 100%;
  color: #484a4d;
  font-size: 21px;
}

.botLitte2-info {
  color: #15161a;
  font-size: 18px;
  margin-top: 13px;
}

.botLitte2-info span {
  color: #fb3636;
}

.leftChartBox {
  width: 60%;
}

.rightChartBox {
  width: 40%;
}

#chart-ten {
  width: 209px;
  height: 209px;
  margin: auto;
}

#chart-eleven {
  width: 209px;
  height: 209px;
  margin: auto;
}

#chart-twelve {
  width: 100%;
  height: 320px;
}

#chart-thirteen {
  width: 100%;
  height: 320px;
}

.chart-ten-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chart-ten-info .chart-six-info-box {
  margin-top: 18px;
}

::v-deep .el-slider__runway.disabled .el-slider__bar {
  background-color: #fb3636;
}

::v-deep .el-slider__runway.disabled .el-slider__button {
  border-color: #fb3636;
}
</style>
