<template>
  <div class="main-content flex-container">
    <!-- <CommonTree
      ref="commonTree"
      treeTitle="项目架构"
      :treeData="treeData"
      :defaultProps="Props"
      :showCheckbox="false"
      @getNodeClick="handleNode"
      node-key="id"
    >
    </CommonTree> -->
    <CommonTreeNew
      ref="commonTree"
      treeTitle="项目架构"
      :defaultProps="Props"
      :showCheckbox="false"
      @getNodeClick="handleNode"
      :urlParmas="{
        tenantId: '',
        deptCategory: '',
        parentId: userInfo.deptId,
      }"
      node-key="id"
    ></CommonTreeNew>
    <div class="clacWidth">
      <head-layout
        head-title="风险告知清单"
        :head-btn-options="headBtnOptions"
        @head-export="headExport"
        @head-add="headAdd"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <div class="list" v-if="list.length">
        <el-row :gutter="10">
          <el-col :span="8" v-for="(item, index) in list" :key="item.id">
            <el-card
              :body-style="{ padding: '10px' }"
              :class="
                item.selected ? 'list-card-selected list-card' : 'list-card'
              "
            >
              <div class="content" @click="handleItemClick(index, item)">
                <el-image
                  style="width: 100%; height: 200px"
                  :src="item.picUrl"
                  @click.stop="showFile(item.picUrl)"
                  fit="fit"
                >
                  <div
                    slot="error"
                    class="image-slot"
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <i
                      class="el-icon-picture-outline"
                      style="font-size: 20px"
                    ></i>
                    <div style="text-align: center; margin-top: 10px">
                      暂无图片
                    </div>
                  </div>
                </el-image>

                <el-divider class="el-divider"></el-divider>
                <div class="bottom">
                  <el-tooltip placement="top" :content="item.cardName">
                    <div class="name">{{ item.cardName }}</div>
                  </el-tooltip>
                  <div class="btn">
                    <i
                      class="el-icon-view"
                      style="margin-right: 8px; cursor: pointer"
                      @click.stop="viewItem(item)"
                    ></i>
                    <i
                      class="el-icon-download"
                      style="margin-right: 8px; cursor: pointer"
                      @click.stop="downloadItem(item)"
                    ></i>
                    <i
                      class="el-icon-edit"
                      style="margin-right: 8px; cursor: pointer"
                      @click.stop="editItem(item)"
                      v-if="item.createUser == userInfo.id"
                    ></i>
                    <i
                      class="el-icon-delete"
                      v-if="item.createUser == userInfo.id"
                      @click.stop="removeList(item)"
                      style="cursor: pointer"
                    ></i>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div class="list-empty" v-loading.body="listLoading" v-if="!list.length">
        <el-empty description="暂无数据"></el-empty>
      </div>
      <div style="text-align: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[9, 18, 27, 36, 45]"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
    <Add ref="add" @getStatus="getList"></Add>
    <el-dialog
      v-dialog-drag
      title="风险告知卡预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- <ViewImage ref="viewImage"></ViewImage> -->
  </div>
</template>

<script>
import { getbsrisklecddlevelMap } from "@/api/setting/riskAssessment/index";
import { getList, deleteList, exportList } from "@/api/riskManage/card";
import Add from "./add.vue";
import { mapGetters } from "vuex";
import website from "@/config/website";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";
import ViewImage from "@/views/business/components/viewImage";
import CommonTree from "@/views/components/com_tree/index.vue";
import { getDeptTree } from "@/api/system/dept";

export default {
  components: {
    CommonTree,
    Add,
    ViewImage,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      treeNode: {},
      treeData: [],
      Props: {
        children: "children",
        label: "title",
      },
      listLoading: false,
      showFileDialog: false,
      page: {
        total: 0,
        current: 1,
        size: 9,
      },
      list: [],
      searchColumns: [
        {
          prop: "cardName",
          span: 4,
          placeholder: "请输入告知卡名称",
        },
        {
          prop: "sourceName",
          span: 4,
          placeholder: "请输入风险源名称",
        },
        {
          placeholder: "请输入风险等级",
          type: "select",
          prop: "riskLevel",
          span: 4,
          dicData: [],
          props: {
            label: "levelName",
            value: "levelName",
          },
          dataType: "number",
        },
      ],
      headBtnOptions: [
        {
          label: "新增告知卡",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "primary",
        },
        {
          label: "导出清单",
          emit: "head-export",
          type: "button",
          icon: "",
        },
      ],
      nowIndex: -1,
      itemId: "",
      querys: {},
      D: [],
    };
  },
  created() {
    this.getbsrisklecddlevelMap();
    this.getPrjstructureList();
  },
  methods: {
    //项目组织架构
    getPrjstructureList() {
      const parentId = this.userInfo.dept_id;
      getDeptTree("", "", parentId).then((res) => {
        if ((res.data.code = 200)) {
          this.treeData = res.data.data;
          this.$refs.commonTree.setCurrentKey(this.treeData[0].id);
          this.$refs.commonTree.nodeClick(this.treeData[0], {});
        }
      });
    },
    handleNode(node) {
      this.treeNode = node;
      this.getList();
    },
    getList(p = {}) {
      this.listLoading = true;
      let parmas = {
        ...this.page,
        ...p,
        organizationId: this.treeNode.id,
      };
      getList(parmas).then((res) => {
        this.list = res.data.data.records;
        this.page.total = res.data.data.total;
        this.page.current = res.data.data.current;
        this.listLoading = false;
      });
    },
    // 列表查询
    gridHeadSearch(searchForm) {
      console.log(searchForm);
      this.querys = {};
      if (searchForm.cardName) {
        this.querys.cardName = searchForm.cardName;
      }
      if (searchForm.sourceName) {
        this.querys.sourceName = searchForm.sourceName;
      }
      if (searchForm.riskLevel) {
        this.querys.riskLevel = searchForm.riskLevel;
      }

      this.page.current = 1;
      this.getList(this.querys);
      3;
      ii62;
    },
    // 查询重置
    gridHeadEmpty() {
      this.querys = {};
      this.$refs.gridHeadLayout.searchForm = {};
      this.page.current = 1;
      this.getList();
    },
    showFile(link) {
      // this.$refs.viewImage.showFile(link);
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    // 获取D映射
    getbsrisklecddlevelMap() {
      //  createDept: this.userInfo.dept_id
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
        this.searchColumns[2].dicData = res.data.data;
      });
    },

    handleItemClick(index, row) {
      if (this.nowIndex == index) {
        return;
      }
      this.nowIndex = index;
      this.list.forEach((item) => {
        item.selected = false;
      });
      this.itemId = row.id;
      this.$set(this.list[index], "selected", true);

      //   this.getListDetail(row);
      //   this.getkcList(this.kcPage, row.id);
      console.log(index, this.list);
    },
    //   导出清单
    headExport() {
      exportBlob(
        `/api/sinoma-hse-prj/riskcard/exportWord?organizationId=${this.userInfo.dept_id}`
      ).then((res) => {
        downloadXls(res.data, `安全告知卡清单.docx`);
      });
    },

    // 新增告知卡
    headAdd() {
      this.$refs.add.init("add");
    },
    editItem(item) {
      this.$refs.add.init("edit", item);
    },
    viewItem(item) {
      this.$refs.add.init("view", item);
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    removeList(item) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteList({ ids: item.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.page.current = 1;
              this.getList();
            }
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    downloadPic(url, name) {
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob);
          let Link = document.createElement("a");
          Link.download = name;
          Link.href = url;
          Link.click();
          Link.remove();
          // 用完释放URL对象
          URL.revokeObjectURL(url);
        });
      };
    },
    // 下载
    downloadItem(item) {
      this.downloadPic(item.picUrl, item.cardName);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  background-color: #fff;
  widows: 100%;
  height: 100%;
}
.clacWidth {
  width: calc(100% - 280px);
}
.list-empty {
  padding: 10px 5px 10px 10px;
  height: calc(100% - 164px);
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep.list-card .el-card__body {
  padding: 10px !important;
}
.list {
  padding: 10px 5px 10px 10px;
  height: calc(100% - 164px);
  overflow-y: scroll;
  overflow-x: hidden;
  .list-card {
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 10px;
    border: 1px solid transparent !important;
    .name {
      text-align: center;
      white-space: nowrap; /* 确保文本在一行内显示 */
      overflow: hidden; /* 隐藏超出容器的文本 */
      text-overflow: ellipsis; /* 使用省略符号表示被截断的文本 */
    }
    .el-divider {
      width: calc(100% + 20px);
      transform: translateX(-10px);
    }
    .bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn {
        margin-left: 10px;
        flex-shrink: 0;
      }
    }
  }
}
</style>
