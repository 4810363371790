<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :treeTitle="$t(`cip.plat.sys.user.title.treeTitle`)"
        :isShowdig="false"
        :showCheckbox="false"
        :popover-show="true"
        @getTreeAdd="getTreeAdd"
        @getTreeEdit="getTreeEdit"
        @getTreeDelete="getTreeDelete"
        @getNodeClick="getNodeClick"
        @include-down="includeDown"
      />
      <!--      <tree-layout :tree-options="treeOptions" @tree-node-click="treeNodeClick" style="margin-right:5px;"></tree-layout>-->
      <!-- <avue-tree :option="treeOption" :data="treeData" @node-click="nodeClick"/> -->
      <el-container>
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            @grid-enable="handleEnable"
            @grid-disable="handleDisable"
            @grid-import="handleImport"
            @grid-export="handleExport"
            @grid-unblock="handleLock"
            @grid-roleConfig="handleGrant"
            @grid-reset="handleReset"
            @grid-platformConfig="handlePlatform"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            @head-add="headAdd"
            @head-romve="headRomve"
            @batch-config="batchConfig"
          >
          </head-layout>
          <grid-head-layout
            ref="searchFrom"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :search-columns="searchColumns"
            v-model="searchForm"
            @grid-head-search="searchChange"
            @grid-head-empty="searchReset"
          >
          </grid-head-layout>
          <grid-layout
            ref="gridLayout"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :data-total="page.total"
            @page-size-change="onLoad"
            @page-current-change="onLoad"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="selectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :searchColumns="searchColumns"
            :gridRowBtn="gridRowBtn"
            @grid-edit="rowEdit"
            @grid-romve="rowDel"
            @role-config="roleConfig"
          >
          </grid-layout>
        </el-main>
      </el-container>

      <!-- <avue-crud :option="option" :search.sync="search" :table-loading="loading" :data="data" ref="crud"
          v-model="form" :permission="permissionList" @row-del="rowDel" @row-update="rowUpdate" @row-save="rowSave"
          :before-open="beforeOpen" :page.sync="page" @search-change="searchChange" @search-reset="searchReset"
          @selection-change="selectionChange" @current-change="currentChange" @size-change="sizeChange"
          @refresh-change="refreshChange" @on-load="onLoad">
          <template slot="menuLeft">
            <el-button type="danger" size="small" plain icon="el-icon-delete" v-if="permission.user_delete"
              @click="handleDelete">删 除
            </el-button>
            <el-button type="info" size="small" plain v-if="permission.user_role" icon="el-icon-user"
              @click="handleGrant">角色配置
            </el-button>
            <el-button type="info" size="small" plain v-if="permission.user_reset" icon="el-icon-refresh"
              @click="handleReset">密码重置
            </el-button>
            <el-button type="info" size="small" plain v-if="userInfo.role_name.includes('administrator')" icon="el-icon-setting"
              @click="handlePlatform">平台配置
            </el-button>
            <el-button type="info" size="small" plain v-if="userInfo.role_name.includes('administrator')"
              icon="el-icon-coordinate" @click="handleLock">账号解封
            </el-button>
            <el-button type="success" size="small" plain v-if="userInfo.role_name.includes('administrator')"
              icon="el-icon-upload2" @click="handleImport">导入
            </el-button>
            <el-button type="warning" size="small" plain v-if="userInfo.role_name.includes('administrator')"
              icon="el-icon-download" @click="handleExport">导出
            </el-button>
          </template>
      <template slot-scope="{row}" slot="tenantName">
            <el-tag>{{ row.tenantName }}</el-tag>
          </template>
          <template slot-scope="{row}" slot="roleName">
            <el-tag>{{ row.roleName }}</el-tag>
          </template>
          <template slot-scope="{row}" slot="deptName">
            <el-tag>{{ row.deptName }}</el-tag>
          </template>
          <template slot-scope="{row}" slot="userTypeName">
            <el-tag>{{ row.userTypeName }}</el-tag>
          </template>
      </avue-crud> -->
        <CommonDialog
          v-if="roleConfigBatchDialog"
          :showFullscreen="true"
          :dialogTitle="$t('cip.plat.sys.user.field.roleConfig')"
          width="80%"
          @cancel="celRole"
          @confirm="submitRole"
        >
        <roleConfigBatchDialog ref="roleConfigBatchDialogRef" :roleConfigRow="roleConfigRow" v-if="roleConfigBatchDialog"
                               :type="type"></roleConfigBatchDialog>
      </CommonDialog>
    </el-container>
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import CommonDialog from "@/components/CommonDialog";
import {getSysConfig, grantMultiDept} from "@/api/system/user";
import {
  getList,
  getUser,
  getUserPlatform,
  remove,
  update,
  updatePlatform,
  add,
  grant,
  resetPassword, unlock, enable, disable
} from "@/api/system/user";
import roleConfigBatchDialog from "@/views/gradingManager/roleConfigBatchDialog";
import {
  remove as delDept,
  getList as deptList,
  selectChildren
} from "@/api/system/dept";
import {exportBlob} from "@/api/common";
import {treeByUserId} from "@/api/system/dept";
import {getRoleTree} from "@/api/system/role";
import {getPostList} from "@/api/system/post";
import {mapGetters} from "vuex";
import website from '@/config/website';
import {getToken} from '@/util/auth';
import {downloadXls} from "@/util/util";
import {dateNow} from "@/util/date";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index"

export default {
  name: "user",
  components: {
    TreeLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
    roleConfigBatchDialog,
    CommonDialog
  },

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("cip.plat.sys.user.field.inputPassword")));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("cip.plat.sys.user.field.checkPassword")));
      } else if (value !== this.$refs.formLayout.$refs.form.form.password) {
        callback(new Error(this.$t("cip.plat.sys.user.field.uncheckPassword")));
      } else {
        callback();
      }
    };
    return {
      roleConfigBatchDialog: false,
      roleConfigRow: {},
      type: "roleConfigBatchDialog",
      deptList: [],
      searchTitle: 'title',
      defaultProps: {
        label: "title",
        value: "key"
      },
      tenantStatus: "",
      searchForm: {},
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.roleConfig'),
          emit: "role-config",
          type: "text",
          icon: ""
        }
      ],
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.batchConfig'),
        emit: "batch-config",
        type: "button",
        icon: ""
      }
      ],
      // searchColumns: [
      //   {
      //     label: "",
      //     prop: "account",
      //     span: 4,
      //     placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
      //   },
      //   {
      //     label: "",
      //     prop: "realName",
      //     span: 4,
      //     placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName"),
      //   },
      //   {
      //     prop: "roleId",
      //     span: 4,
      //     multiple: true,
      //     type: "tree",
      //     dicUrl: 'api/sinoma-system/role/treeByUserType?tenantId=' + this.userInfo.tenant_id,
      //     props: {
      //       label: "title"
      //     },
      //     checkStrictly: true,
      //     slot: true,
      //     placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.roleName"),
      //   },
      //   {
      //     label: "",
      //     prop: "postId",
      //     span: 4,
      //     type: "tree",
      //     multiple: true,
      //     dicUrl: '/api/sinoma-system/post/select?tenantId=' + website.tenantId + '',
      //     props: {
      //       label: "postName",
      //       value: "id"
      //     },
      //     placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.postId"),
      //   },
      //   // {
      //   //   label: "",
      //   //   type: "select",
      //   //   span:4,
      //   //   dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
      //   //   props: {
      //   //     label: "dictValue",
      //   //     value: "dictKey"
      //   //   },
      //   //   placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.userType"),
      //   //   prop: "userType",
      //   // },
      // ],

      dataTotal: null,

      tableData: [],
      tableLoading: false,
      form: {},
      search: {},
      roleBox: false,
      excelBox: false,
      platformBox: false,
      initFlag: true,
      selectionList: [],
      query: {},
      loading: true,
      platformLoading: false,
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      platformPage: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      init: {
        roleTree: [],
        deptTree: [],
      },
      props: {
        label: "title",
        value: "key"
      },
      roleGrantList: [],
      roleTreeObj: [],
      treeDeptId: '',
      treeData: [],
      /*treeOptions: {
        defaultExpandAll: false,
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentId = (node.level === 0) ? 0 : node.data.id;
          getDeptLazyTree(parentId).then(res => {
            resolve(res.data.data.map(item => {
              return {
                ...item,
                leaf: !item.hasChildren
              }
            }))
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: 'title',
          value: 'value',
          children: 'children'
        }
      },*/
      tableOptions: {
        customRowAddBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.tenantName"),
            prop: "tenantName",
            cell: true,
            hide: '',
            width: 150,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
            width: 150,
            align: 'center',
            overHidden: true,
          },
          /*{
            label: this.$t("cip.plat.sys.user.field.roleName"),
            prop: "roleName",
            cell: true,
            align: 'left',
            overHidden: true,
          },*/
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            cell: true,
            align: 'left',
            overHidden: true,
          },
          /*{
            label: this.$t("cip.plat.sys.user.field.isEnabled"),
            prop: "isEnabled",
            cell: true,
            // dicData: [
            //   {
            //     label: this.$t("cip.plat.sys.user.field.enable"),
            //     value: 0
            //   },
            //   {
            //     label: this.$t("cip.plat.sys.user.field.disable"),
            //     value: 1
            //   }
            // ],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            width: 150,
            align: 'center',
            overHidden: true,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          },*/
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            align: 'center',
            dataType: "number",
            prop: "userType",
            hide: true,
            width: 150,
            overHidden: true,
          },
        ],
        group: [
          {
            label: this.$t("cip.plat.sys.user.field.baseInfo"),
            prop: 'baseInfo',
            icon: 'el-icon-user-solid',
            column: [
              {
                label: this.$t("cip.plat.sys.user.field.tenantName"),
                prop: "tenantId",
                type: "tree",
                dicUrl: "/api/sinoma-system/tenant/select",
                props: {
                  label: "tenantName",
                  value: "tenantId"
                },
                hide: !website.tenantMode,
                addDisplay: website.tenantMode,
                editDisplay: website.tenantMode,
                viewDisplay: website.tenantMode,
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.tenantName"),
                  trigger: "click"
                }],
                span: 24,
              },
              {
                label: this.$t("cip.plat.sys.user.field.account"),
                prop: "account",
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
                  trigger: "blur"
                }],
              },
              {
                label: this.$t("cip.plat.sys.user.field.userType"),
                type: "select",
                dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
                props: {
                  label: "dictValue",
                  value: "dictKey"
                },
                dataType: "number",
                slot: true,
                prop: "userType",
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.userType"),
                  trigger: "blur"
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.password"),
                prop: 'password',
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [{required: true, validator: validatePass, trigger: 'blur'}]
              },
              {
                label: this.$t("cip.plat.sys.user.field.password2"),
                prop: 'password2',
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [{required: true, validator: validatePass2, trigger: 'blur'}]
              },
            ]
          },
          {
            label: this.$t("cip.plat.sys.user.field.detailInfo"),
            prop: 'detailInfo',
            icon: 'el-icon-s-order',
            column: [
              {
                label: this.$t("cip.plat.sys.user.field.name"),
                prop: "name",
                hide: true,
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.name"),
                  trigger: "blur"
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.realName"),
                prop: "realName",
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName"),
                  trigger: "blur"
                }, {
                  min: 2,
                  max: 5,
                  message: this.$t("cip.plat.sys.user.field.nameLength"),
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.phone"),
                prop: "phone",
                overHidden: true
              },
              {
                label: this.$t("cip.plat.sys.user.field.email"),
                prop: "email",
                hide: true,
                overHidden: true
              },
              {
                label: this.$t("cip.plat.sys.user.field.sex"),
                prop: "sex",
                type: "select",
                // dicData: [
                //   {
                //     label: this.$t("cip.plat.sys.user.field.man"),
                //     value: 1
                //   },
                //   {
                //     label: this.$t("cip.plat.sys.user.field.women"),
                //     value: 2
                //   },
                //   {
                //     label: this.$t("cip.plat.sys.user.field.unknow"),
                //     value: 3
                //   }
                // ],
                dataType: 'number',
                props: {
                  label: "dictValue",
                  value: "dictKey"
                },
                dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
                hide: true
              },
              {
                label: this.$t("cip.plat.sys.user.field.birthday"),
                type: "date",
                prop: "birthday",
                format: "yyyy-MM-dd hh:mm:ss",
                valueFormat: "yyyy-MM-dd hh:mm:ss",
                hide: true
              },
              {
                label: this.$t("cip.plat.sys.user.field.statusName"),
                prop: "statusName",
                hide: true,
                display: false
              }
            ]
          },
          {
            label: this.$t("cip.plat.sys.user.field.dutyInfo"),
            prop: 'dutyInfo',
            icon: 'el-icon-s-custom',
            column: [
              {
                label: this.$t("cip.plat.sys.user.field.code"),
                prop: "code",
              },
              {
                label: this.$t("cip.plat.sys.user.field.roleName"),
                prop: "roleId",
                multiple: true,
                type: "tree",
                dicData: [],
                props: {
                  label: "title"
                },
                checkStrictly: true,
                slot: true,
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.roleName"),
                  trigger: "click"
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.roleName"),
                prop: "deptId",
                type: "tree",
                multiple: true,
                dicData: [],
                props: {
                  label: "title"
                },
                checkStrictly: true,
                slot: true,
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.roleName"),
                  trigger: "click"
                }]
              },
              {
                label: this.$t("cip.plat.sys.user.field.postId"),
                prop: "postId",
                type: "tree",
                multiple: true,
                dicData: [],
                props: {
                  label: "postName",
                  value: "id"
                },
                rules: [{
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.postId"),
                  trigger: "click"
                }],
              },
            ]
          },
        ]
      },
      data: [],
      platformQuery: {},
      platformSelectionList: [],
      platformData: [],
      platformForm: {},
      platformOption: {
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        selection: true,
        viewBtn: false,
        dialogClickModal: false,
        menuWidth: 120,
        editBtnText: this.$t("cip.plat.sys.user.field.config"),
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            search: true,
            display: false
          },
          {
            label: this.$t("cip.plat.sys.user.field.tenantName"),
            prop: "tenantName",
            slot: true,
            display: false
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            search: true,
            display: false
          },
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            prop: "userTypeName",
            slot: true,
            display: false
          },
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            prop: "userType",
            rules: [{
              required: true,
              message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.userType"),
              trigger: "blur"
            }]
          },
          {
            label: this.$t("cip.plat.sys.user.field.userExt"),
            prop: "userExt",
            type: "textarea",
            minRows: 8,
            span: 24,
            overHidden: true,
            row: true,
            hide: true,
          },
        ],
      },
      excelForm: {},
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.excelFile"),
            prop: 'excelFile',
            type: 'upload',
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: 'data'
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            action: "/api/sinoma-user/import-user"
          },
          {
            label: this.$t("cip.plat.sys.user.field.isCovered"),
            prop: "isCovered",
            type: "select",
            align: "center",
            width: 80,
            // dicData: [
            //   {
            //     label: this.$t("cip.plat.sys.user.field.no"),
            //     value: 0
            //   },
            //   {
            //     label: this.$t("cip.plat.sys.user.field.yes"),
            //     value: 1
            //   }
            // ],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yesNo",
            value: 0,
            slot: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.yes") + this.$t("cip.plat.sys.user.field.no") + this.$t("cip.plat.sys.user.field.isCovered"),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: 'excelTemplate',
            formslot: true,
            span: 24,
          }
        ]
      }
    };
  },
  watch: {
    'form.tenantId'() {
      if (this.form.tenantId !== '' && this.initFlag) {
        this.initData(this.form.tenantId);
      }
    },
    'excelForm.isCovered'() {
      if (this.excelForm.isCovered !== '') {
        const column = this.findObject(this.excelOption.column, "excelFile");
        column.action = `/api/sinoma-user/import-user?isCovered=${this.excelForm.isCovered}`;
      }
    }
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.user_add, false),
        viewBtn: this.vaildData(this.permission.user_view, false),
        delBtn: this.vaildData(this.permission.user_delete, false),
        editBtn: this.vaildData(this.permission.user_edit, false)
      };
    },
    platformPermissionList() {
      return {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: this.vaildData(this.permission.user_edit, false)
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "account",
          span: 4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
        },
        {
          label: "",
          prop: "realName",
          span: 4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName"),
        },
        {
          prop: "roleId",
          span: 4,
          multiple: true,
          type: "tree",
          dicUrl: 'api/sinoma-system/role/treeByUserType?tenantId=' + this.userInfo.tenant_id,
          props: {
            label: "title"
          },
          checkStrictly: true,
          slot: true,
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.roleName"),
        },
        {
          label: "",
          prop: "postId",
          span: 4,
          type: "tree",
          multiple: true,
          dicUrl: '/api/sinoma-system/post/select?tenantId=' + website.tenantId + '',
          props: {
            label: "postName",
            value: "id"
          },
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.postId"),
        }
      ]
    }
  },
  created() {
    this.getSysConfig()
    // this.permission.user_role=false
    this.headBtnOptions.forEach((item, index) => {
      if (!this.userInfo.role_name.includes('administrator')) {
        if (item.remark == 'admin') {
          this.headBtnOptions.splice(index, 1)
        }
      }

      if (!this.permission.user_reset) {
        if (item.remark == 'user_reset') {
          this.headBtnOptions.splice(index, 1)
        }
      }
      if (!this.permission.user_role) {
        if (item.remark == 'user_role') {
          this.headBtnOptions.splice(index, 1)
        }
      }
      if (!this.permission.user_delete) {
        if (item.remark == 'user_delete') {
          this.headBtnOptions.splice(index, 1)
        }
      }
      if (!this.permission.user_add) {
        if (item.remark == 'user_add') {
          this.headBtnOptions.splice(index, 1)
        }
      }
    })

    this.gridRowBtn.forEach((item, index) => {
      if (!this.permission.user_edit) {
        if (item.remark == 'edit') {
          this.gridRowBtn.splice(index, 1)
        }
      }
      if (!this.permission.user_delete) {
        if (item.remark == 'remove') {
          this.gridRowBtn.splice(index, 1)
        }
      }
    })
  },
  mounted() {
    // 非租户模式默认加载管理组数据
    // if (!website.tenantMode) {
    //   this.initData(website.tenantId);
    // }
    this.getTreeData();
    this.initData(website.tenantId)
    this.deptData();

  },
  methods: {
    getSysConfig() {
      getSysConfig().then(res => {
        let data = res.data.data
        this.tenantStatus = data.is_tenant;
        if (this.tenantStatus != "true") {
          this.$refs.gridLayout.tableOptions.column[1].hide = true;
        }
      })
    },
    deptData() {

      deptList(0, Object.assign({}, {})).then(res => {
        this.deptList = res.data.data;
      });
    },
    // 机构新增
    getTreeAdd() {
      this.$router.push({
        path: '/dept/deptEdit',
        query: {
          type: 'add',
          tenantStatus: this.tenantStatus
        }
      })
    },
    // 机构删除
    getTreeDelete(data) {
      console.log(data, 'sdfs');
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return delDept(data.id);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          this.getTreeData()
        });
    },
    // 机构编辑
    getTreeEdit(data) {

      for (let i = 0; i < this.deptList.length; i++) {
        if (data.id == this.deptList[i].id) {
          console.log(this.deptList[i], 't');
          this.getEditPush(this.deptList[i]);
        }
      }
    },
    getEditPush(cdata) {
      let data = encodeURIComponent(JSON.stringify(cdata))
      this.$router.push({
        path: '/dept/deptEdit',
        query: {
          type: 'edit',
          data: data
        }
      })
    },
    getTreeData() {
      selectChildren("").then((res) => {
        this.treeData = res.data.data
        this.treeDeptId = this.treeData[0].id;
        this.onLoad({
          pageSize: 20,
          currentPage: 1
        }, {});
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: '/user/edit',
        query: {
          type: 'edit',
          id: row.id
        }
      })
    },
    gridHeadSearch() {
      console.log(JSON.stringify(this.$refs.gridLayOut.searchForm))
      // this.$message.success(
      //   '您点击了GRID-HEAD区域的搜索' + JSON.stringify(this.$refs.gridLayOut.searchForm)
      // )
    },
    gridHeadEmpty() {
      console.log(JSON.stringify(this.$refs.gridLayOut.searchForm))
      // this.$message.success(
      //   '您点击了GRID-HEAD区域的清除' + JSON.stringify(this.$refs.gridLayOut.searchForm)
      // )
    },
    gridHeadAdd() {
      this.isDataForm = true;
      this.isShow = true;
      // this.$message.success(
      //   '您点击了GRID-HEAD区域的新增'
      // )
    },
    gridHeadAddTable() {
      this.isGridData = true;
      this.isShow = true;
      // this.$message.success(
      //   '您点击了GRID-HEAD区域的新增'
      // )
    },
    gridSearchClick(searchForm) {
      // this.$message.success(
      //   '搜索' + JSON.stringify(searchForm)
      // )

    },
    gridClearClick(searchForm) {
      // this.$message.success(
      //   '清除' + JSON.stringify(searchForm)
      // )

    },
    gridRowSave(rowForm) {
      // this.$message.success(
      //   '保存的数据：' + JSON.stringify(rowForm)
      // )
    },
    gridRowRemove(rowForm) {
      // this.$message.success(
      //   '删除的数据：' + JSON.stringify(rowForm)
      // )
    },
    headAdd() {
      this.$router.push({
        path: "/user/edit",
        query: {
          type: 'add',
          tenantStatus: this.tenantStatus
        }
        // query: {
        //   data: encodeURIComponent(JSON.stringify(row)),
        //   type: 'add'
        // }
      })
    },

    headRomve() {
      console.log(111111)
      // const selectRows = this.$refs.gridLayout.$refs.crud.tableSelect;
      this.selectionList = this.$refs.gridLayout.$refs.grid.tableSelect;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });

        });
    },
    headRemoveClick() {

    },
    getNodeClick(data) {
      this.treeDeptId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    treeNodeClick(node) {
      this.treeDeptId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    nodeClick(data) {
      this.treeDeptId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    initData(tenantId) {
      // console.log(tenantId,'1136')
      // getRoleTree(tenantId).then(res => {
      //   const column = this.findObject(this.option.group, "roleId");
      //   column.dicData = res.data.data;
      // });
      // getDeptTree(tenantId).then(res => {
      //   const column = this.findObject(this.option.group, "deptId");
      //   column.dicData = res.data.data;
      // });
      getPostList('000000').then(res => {
        const column = this.findObject(this.searchColumns.group, "postId");
        // console.log(column,'1148')
        // column.dicData = res.data.data;
        // this.postDicData = res.data.data;
      })
    },
    celRole() {
      this.roleConfigBatchDialog = false
    },
    submitRole() {
      var submit = this.$refs.roleConfigBatchDialogRef.submit();
      // submit.forEach(e=>{
      //   e.isDefaultDept = e.isDefaultDept.toString()
      // })
      this.$loading();
      if (this.type != "") {
        this.roleConfigRow.roleIds = submit
        this.roleConfigRow.realName = `{"zh":"${this.roleConfigRow.realName}"}`
        update(this.roleConfigRow).then((res) => {
          this.roleConfigRow.realName = this.roleConfigRow.realName.substring(7, this.roleConfigRow.realName.length - 2);
          this.roleConfigBatchDialog = false
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$loading().close()
          this.onLoad(this.page);
        })
      } else {
        let data = {
          "userIds": this.roleTreeObj.toString(),
          "roleIds": submit
        }
        //调用多用户批量设置接口
        grantMultiDept(data).then(() => {
          this.roleConfigDialog = false
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$loading().close()
        })
      }
      this.roleConfigBatchDialog = false
    },
    rowSave(row, done, loading) {
      row.deptId = row.deptId.join(",");
      row.roleId = row.roleId.join(",");
      row.postId = row.postId.join(",");
      add(row).then(() => {
        this.initFlag = false;
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess")
        });
        done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowUpdate(row, index, done, loading) {
      row.deptId = row.deptId.join(",");
      row.roleId = row.roleId.join(",");
      row.postId = row.postId.join(",");
      update(row).then(() => {
        this.initFlag = false;
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess")
        });
        done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    roleConfig(row) {
      this.type = "roleConfigBatchDialog"
      this.roleConfigRow = row
      this.roleConfigBatchDialog = true
      this.$nextTick(()=>{
        this.$refs.roleConfigBatchDialogRef.initData("roleConfigBatchDialog")
      })
    },
    batchConfig() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.type = ""
      this.roleConfigBatchDialog = true
      this.roleTreeObj = [];
      let roleIdList = []
      this.selectionList.forEach(e => {
        roleIdList.push(e.id)
      })
      this.roleTreeObj = roleIdList
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    searchReset(data) {
      this.query = {};
      this.treeDeptId = '';
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (params.account == '') {
        delete params.account
      }
      if (params.realName == '') {
        delete params.realName
      }

      if (params.userTypeName == '') {
        delete params.userTypeName
      }
      delete params.$userTypeName
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);

    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    handleDelete() {

    },
    handleReset() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.plat.sys.user.btn.restPassword'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return resetPassword(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.plat.sys.user.msg.iPSWarning")
          });

        });
    },
    handleGrant() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.roleTreeObj = [];
      if (this.selectionList.length === 1) {
        this.roleTreeObj = this.selectionList[0].roleId.split(",");
      }
      getRoleTree().then(res => {
        this.roleGrantList = res.data.data;
        this.roleBox = true;
      });
    },
    handlePlatform() {
      this.platformBox = true;
    },
    handleLock() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.plat.sys.user.field.restAccount'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return unlock(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.plat.sys.user.msg.dlocWarning")
          });
        });
    },
    handleEnable() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.plat.sys.user.msg.enable'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return enable(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.plat.sys.user.msg.enableSuccess")
          });
        });
    },
    handleDisable() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.plat.sys.user.msg.disable'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return disable(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.plat.sys.user.msg.disableSuccess")
          });
        });
    },
    handleImport() {
      this.excelBox = true;
    },
    uploadAfter(res, done, loading, column) {
      window.console.log(column);
      this.excelBox = false;
      this.refreshChange();
      done();
    },
    handleExport() {
      this.$confirm(this.$t('cip.plat.sys.user.field.exportData'), this.$t('cip.plat.sys.user.field.notice'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        NProgress.start();
        exportBlob(`/api/sinoma-user/export-user?${this.website.tokenHeader}=${getToken()}&account=${this.searchForm.account || ''}&realName=${this.searchForm.realName || ''}`).then(res => {
          downloadXls(res.data, this.$t('cip.plat.sys.user.field.userData') + `${dateNow()}.xlsx`);
          NProgress.done();
        })
      });
    },
    handleTemplate() {
      exportBlob(`/api/sinoma-user/export-template?${this.website.tokenHeader}=${getToken()}`).then(res => {
        downloadXls(res.data, this.$t('cip.plat.sys.user.field.userDataTemplate') + ".xlsx");
      })
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getUser(this.form.id).then(res => {
          this.form = res.data.data;
          if (this.form.hasOwnProperty("deptId")) {
            this.form.deptId = this.form.deptId.split(",");
          }
          if (this.form.hasOwnProperty("roleId")) {
            this.form.roleId = this.form.roleId.split(",");
          }
          if (this.form.hasOwnProperty("postId")) {
            this.form.postId = this.form.postId.split(",");
          }
        });
      }
      this.initFlag = true;
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.param = params;
      this.page = page;
      this.tableLoading = true;
      this.query.isEnabled = 0;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query), this.treeDeptId).then(res => {
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        // this.selectionClear();
      });
    },
    platformRowUpdate(row, index, done, loading) {
      updatePlatform(row.id, row.userType, row.userExt).then(() => {
        this.platformOnLoad(this.platformPage);
        this.$message({
          type: "success",
          message: this.$t("cip.plat.sys.user.msg.CfgWarning")
        });
        done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    platformBeforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getUserPlatform(this.platformForm.id).then(res => {
          this.platformForm = res.data.data;
        });
      }
      done();
    },
    platformSearchReset() {
      this.platformQuery = {};
      this.platformOnLoad(this.platformPage);
    },
    platformSearchChange(params, done) {
      this.platformQuery = params;
      this.platformPage.currentPage = 1;
      this.platformOnLoad(this.platformPage, params);

    },
    platformSelectionChange(list) {
      this.platformSelectionList = list;
    },
    platformSelectionClear() {
      this.platformSelectionList = [];
      this.$refs.platformCrud.toggleSelection();
    },
    platformCurrentChange(currentPage) {
      this.platformPage.currentPage = currentPage;
    },
    platformSizeChange(pageSize) {
      this.platformPage.pageSize = pageSize;
    },
    platformRefreshChange() {
      this.platformOnLoad(this.platformPage, this.platformQuery);
    },
    platformOnLoad(page, params = {}) {
      this.platformLoading = true;
      this.page = page;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query), this.treeDeptId).then(res => {
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.platformData = data.records;
        this.platformLoading = false;
        this.selectionClear();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// .box {
//   height: 800px;
// }

.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}

// .import {
//   height: 240px;
// }
</style>
