var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "board", attrs: { id: "board" } },
    [
      _c(
        "div",
        { staticClass: "borderTabs" },
        _vm._l(_vm.tabsList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "tabsTitle",
              style: { color: _vm.tabsActive == index ? _vm.themeColor : "" },
              on: {
                click: function ($event) {
                  return _vm.chooseTabs(item, index)
                },
              },
            },
            [
              _vm._v("\n      " + _vm._s(item) + "\n      "),
              _c("div", {
                staticClass: "tabsBottom",
                style: {
                  backgroundColor:
                    _vm.tabsActive == index ? _vm.themeColor : "",
                },
              }),
            ]
          )
        }),
        0
      ),
      _vm.tabsActive == 0 ? _c("safetyManagement") : _vm._e(),
      _vm.tabsActive == 1 ? _c("risksAdnDangers") : _vm._e(),
      _vm.tabsActive == 2 ? _c("qualifications") : _vm._e(),
      _vm.tabsActive == 3 ? _c("safetyEducation") : _vm._e(),
      _vm.tabsActive == 4 ? _c("safetyAccidents") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }