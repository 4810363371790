<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.dc.AiResult.name')"
      @head-add="addFn"
    >
    </head-layout>
    <el-container class="new-sino-box">
      <el-header class="new-sino-header search-form"  style="display: flex;align-items: center;height:56px">
        <el-input style="width: 200px;" :placeholder="$t('cip.dc.AiResult.msg')" v-model="queryList.param1" class="new-sino-input" size="mini" />
        <el-button-group style="margin-left: 10px;">
        <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
        <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
        </el-button-group>
      </el-header>
      <grid-layout
        ref="gridLayout"
        :data-total="total"
        @page-current-change="handleCurrentChange"
        @page-size-change="handleSizeChange"
        @page-refresh-change="onLoad"
        :page="page"
        :tableOptions="tableOptions"
        :tableData="tableData"
        :tableLoading="tableLoading"
        :gridRowBtn="gridRowBtn"
        @grid-detail="detailFn"
        :searchColumns="searchColumns"

      >
      </grid-layout>
    </el-container>
  </div>
</template>

<script>
import {
  totalCoList,
  totalCollectUp,
  totalCollectDel, deleteDataSource,
} from "@/api/dataAcquisition/index";
import {getModelPredictResults} from '@/api/Ai/index'
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      tableLoading:false,
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 100,
        column: [
          {
            label: this.$t('cip.dc.AiResult.field.predictId'),
            prop: "predictId",
            cell: true,
            slot:true
          },
          {
            label: this.$t('cip.dc.AiResult.field.modelId'),
            prop: "modelId",
            cell: true,
          },
          {
            label: this.$t('cip.dc.AiResult.field.predict_name'),
            prop: "predict_name",
            cell: true,
          },
          {
            label: this.$t('cip.dc.AiResult.field.algorithm_name'),
            prop: "algorithm_name",
            cell: true,
          },
          {
            label: this.$t('cip.dc.AiResult.field.model_name'),
            prop: "model_name",
            cell: true,
          },
        ],

      },
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-detail",
          remark: 'edit',
          type: "text",
          icon: ""
        },
      ],
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      activeName: '1',
      multipleSelection:[],
      tableData: [],
      total:0,
      queryList:{
        query:{
          current:1,
          size:20,
        },
        param1:''
      },
      headBtnOptions: [{
        label: this.$t("cip.cmn.btn.addBtn"),
        emit: "head-add",
        type: "button",
        icon: ""
      }],
      form: null,
      formLabelWidth: '120px',
      dialogFormVisible:false,
      loading:false,
      oldTableData:[],

    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      getModelPredictResults({}).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.oldTableData = obj;
        this.tableData = this.oldTableData.splice(0,10);
        this.page.total = obj.length;

      });
    },
    addFn(){

    },
    detailFn(scope){
      this.$router.push({
        path: '/resultDetail',
        query: {
          id:scope.modelId,
          picId:scope.predictId
        }
      })
    },
    deleteFn(){
      if(this.multipleSelection.length == 0){
        this.$message.error('请选择删除数据')
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return item.id
      })
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        totalCollectDel(ids).then((res)=>{
          this.$message.success('删除成功');
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    czFn(){
      this.queryList.param1 = '';
      this.onLoad()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.page.pageSize  = val.pageSize;
      this.tableData= this.oldTableData.slice((val.currentPage-1) * val.pageSize,val.currentPage * val.pageSize)

    },
    handleCurrentChange(val) {
      this.page.currentPage  = val.currentPage;
      this.tableData= this.oldTableData.slice((val.currentPage-1) * val.pageSize,val.currentPage * val.pageSize)
    },
  },
  created() {
    this.onLoad();
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.search-form::v-deep{
   background: #FFFFFF;
   padding: 14px 12px;
  .el-form-item--small .el-form-item__content{
    line-height: 28px !important;
  }
  .el-button{
    height: 28px;
    margin-left:0;
   }
  .el-input__inner{
   height: 28px;
   line-height: 28px;
  }
}
</style>
<style>
.el-button+.el-button {
  margin-left: 0px;
}

</style>
