var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "areaUserList" },
    [
      _c(
        "el-container",
        { staticClass: "areaUserList_container" },
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "人员清单",
                  showIcon: false,
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-add-tabs": _vm.headAdd,
                  "head-remove": _vm.headRemove,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "grid-row-btn": _vm.gridRowBtn,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-change": _vm.rowChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }