var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-common-layout" },
    [
      _c(
        "div",
        { staticClass: "SNOWS-common-layout-center" },
        [
          _c(
            "el-row",
            { staticClass: "SNOWS-common-search-box", attrs: { gutter: 16 } },
            [
              _c(
                "el-form",
                {
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键词" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入关键词查询",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search()
                              },
                            },
                            model: {
                              value: _vm.query.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "keyword", $$v)
                              },
                              expression: "query.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属分类" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择所属分类",
                                clearable: "",
                              },
                              model: {
                                value: _vm.category,
                                callback: function ($$v) {
                                  _vm.category = $$v
                                },
                                expression: "category",
                              },
                            },
                            _vm._l(_vm.categoryList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.fullName, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.search()
                                },
                              },
                            },
                            [_vm._v("\n              查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-refresh-right" },
                              on: {
                                click: function ($event) {
                                  return _vm.reset()
                                },
                              },
                            },
                            [_vm._v("重置\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "SNOWS-common-layout-main SNOWS-flex-main" },
            [
              _c(
                "div",
                { staticClass: "SNOWS-common-head" },
                [
                  _c("topOpts", {
                    attrs: { addText: "新建模板" },
                    on: {
                      add: function ($event) {
                        return _vm.addOrUpdateHandle("", 1)
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "SNOWS-common-head-right" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "刷新",
                            placement: "top",
                          },
                        },
                        [
                          _c("el-link", {
                            attrs: {
                              icon: "icon-ym icon-ym-Refresh SNOWS-common-head-icon",
                              underline: false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.initData()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "SNOWS-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: { data: _vm.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "fullName",
                      label: "名称",
                      "show-overflow-tooltip": "",
                      "min-width": "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "enCode", label: "编码", width: "200" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "category", label: "分类", width: "150" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creatorUser",
                      label: "创建人",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creatorTime",
                      label: "创建时间",
                      formatter: _vm.sinoma.tableDateFormat,
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "lastModifyTime",
                      label: "最后修改时间",
                      formatter: _vm.sinoma.tableDateFormat,
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "tableOpts",
                              {
                                on: {
                                  edit: function ($event) {
                                    return _vm.addOrUpdateHandle(scope.row.id)
                                  },
                                  del: function ($event) {
                                    return _vm.handleDel(scope.row.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-dropdown",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("common.moreBtn"))
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-down el-icon--right",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.copy(scope.row.id)
                                              },
                                            },
                                          },
                                          [_vm._v("复制模板")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.download(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("下载代码")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.preview(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("预览代码")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.currentPage,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "currentPage", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.initData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.formVisible
        ? _c("Form", { ref: "Form", on: { close: _vm.closeForm } })
        : _vm._e(),
      _vm.previewVisible
        ? _c("Preview", {
            ref: "preview",
            on: {
              close: function ($event) {
                _vm.previewVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.downloadFormVisible
        ? _c("DownloadForm", {
            ref: "downloadForm",
            on: {
              close: function ($event) {
                _vm.downloadFormVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }