var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "always" } },
    [
      _c(
        "el-form",
        { ref: "queryForm", attrs: { model: _vm.queryParams, inline: true } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "字段名称", prop: "fieldName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入字段名称",
                  clearable: "",
                  size: "small",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleQuery.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.queryParams.fieldName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "fieldName", $$v)
                  },
                  expression: "queryParams.fieldName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", margin: "15px 0" },
          attrs: {
            data: _vm.changeRecordList,
            border: "",
            "tooltip-effect": "dark",
            size: _vm.tableSize,
            height: _vm.tableHeight,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "序号", width: "55", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                },
              },
            ]),
          }),
          _vm._l(_vm.tableColumns, function (item, index) {
            return [
              item.show
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      formatter: item.formatter,
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  })
                : _vm._e(),
            ]
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      { attrs: { placement: "left", trigger: "click" } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPerm",
                                rawName: "v-hasPerm",
                                value: ["metadata:changerecord:edit"],
                                expression: "['metadata:changerecord:edit']",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-edit-outline",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPerm",
                                rawName: "v-hasPerm",
                                value: ["metadata:changerecord:detail"],
                                expression: "['metadata:changerecord:detail']",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-view",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPerm",
                                rawName: "v-hasPerm",
                                value: ["metadata:changerecord:remove"],
                                expression: "['metadata:changerecord:remove']",
                              },
                            ],
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "text",
                            },
                            slot: "reference",
                          },
                          [_vm._v("操作")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "page-sizes": [10, 20, 50, 100],
          layout: "total, sizes, prev, pager, next, jumper",
          "current-page": _vm.queryParams.pageNum,
          "page-size": _vm.queryParams.pageSize,
          total: _vm.total,
        },
        on: {
          "update:currentPage": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:current-page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:pageSize": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          "update:page-size": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }