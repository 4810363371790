var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("h3", [_vm._v("这个页面会被 keep-alive")]),
      _c("el-tag", [
        _vm._v(
          "在下面的输入框输入任意字符后，切换到其它页面，再回到此页时输入框文字保留，证明被缓存"
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("el-tag", [_vm._v("同时滚动下拉，返回时还会保持滚动条所在的位置")]),
      _c("br"),
      _c("br"),
      _c("el-input", {
        attrs: { placeholder: "input here" },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c("div", { staticStyle: { height: "1000px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }