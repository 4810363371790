var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%" } },
    [
      _c(
        "el-container",
        { staticStyle: { position: "relative" } },
        [
          _c("CommonTreeNew", {
            ref: "commonTreeCore",
            attrs: {
              treeTitle: "组织架构",
              defaultProps: _vm.treeDfaultProps,
              showCheckbox: false,
              urlParmas: {
                tenantId: "",
                deptCategory: "2",
                parentId: _vm.userInfo.dept_id,
              },
              "node-key": "id",
              isShowdig: false,
              isZoom: true,
              show: _vm.show,
            },
            on: {
              getNodeClick: _vm.handleNodeClick,
              showChange: _vm.showChange,
            },
          }),
          _c("div", { staticStyle: { height: "100%", width: "100%" } }, [
            _c(
              "div",
              { staticClass: "safetyOrg-container" },
              [
                _c(
                  "div",
                  { staticClass: "setting-tab" },
                  [
                    _c(
                      "el-tabs",
                      {
                        attrs: { type: "border-card" },
                        on: { "tab-click": _vm.handleClick },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      _vm._l(_vm.orgList, function (item) {
                        return _c("el-tab-pane", {
                          key: item.id,
                          attrs: { name: item.id, label: item.soOrgName },
                        })
                      }),
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab-button",
                        on: { click: _vm.addOrgTab },
                      },
                      [
                        _c("el-button", { attrs: { icon: "el-icon-plus" } }, [
                          _vm._v("新增"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "safetyOrg-container-setting" }, [
                  _c("div", { staticClass: "container-setting" }, [
                    _c("div", { staticClass: "setting-tool" }, [
                      _c("div", { staticClass: "setting-tool-title" }, [
                        _vm._v("组织架构图"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "setting-tool-button" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "el-tooltip-text",
                              attrs: {
                                effect: "dark",
                                content: "删除组织",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isEditModel,
                                    expression: "!isEditModel",
                                  },
                                ],
                                attrs: { icon: "el-icon-close" },
                                on: { click: _vm.deleteOrg },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "el-tooltip-text",
                              attrs: {
                                effect: "dark",
                                content: "编辑架构图",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isEditModel,
                                    expression: "!isEditModel",
                                  },
                                ],
                                attrs: { icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    _vm.isEditModel = !_vm.isEditModel
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "el-tooltip-text",
                              attrs: {
                                effect: "dark",
                                content: "下载组织图",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isEditModel,
                                    expression: "!isEditModel",
                                  },
                                ],
                                attrs: { icon: "el-icon-download" },
                                on: { click: _vm.downloadOrgImg },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "el-tooltip-text",
                              attrs: {
                                effect: "dark",
                                content: "保存",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isEditModel,
                                    expression: "isEditModel",
                                  },
                                ],
                                attrs: { icon: "el-icon-s-order" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "el-tooltip-text",
                              attrs: {
                                effect: "dark",
                                content: "退出",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isEditModel,
                                    expression: "isEditModel",
                                  },
                                ],
                                attrs: { icon: "el-icon-right" },
                                on: {
                                  click: function ($event) {
                                    _vm.isEditModel = false
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "setting-warp" },
                      [
                        _c("zm-tree-org", {
                          ref: "orgTree",
                          attrs: {
                            data: _vm.treeData,
                            draggable: false,
                            props: _vm.defaultProps,
                            disabled: _vm.disaled,
                            collapsable: _vm.collapsable,
                            "node-draggable": false,
                            "default-expand-level": 1,
                            "only-one-node": _vm.onlyOneNode,
                            "clone-node-drag": _vm.cloneNodeDrag,
                            "node-draging": _vm.nodeDragMove,
                            "node-drag-end": _vm.nodeDragEnd,
                            toolBar: _vm.toolBar,
                            filterNodeMethod: _vm.filterNodeMethod,
                            "node-add": _vm.nodeAdd,
                            "node-edit": _vm.nodeEdit,
                            "node-delete": _vm.nodeDelete,
                            "define-menus": _vm.defineMenus,
                          },
                          on: {
                            "on-contextmenu": _vm.onMenus,
                            "on-expand": _vm.onExpand,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ node }) {
                                return [
                                  _c("div", { staticClass: "org-warp" }, [
                                    _c("div", { staticClass: "org-name" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(node.nodeName) +
                                          "\n                    "
                                      ),
                                    ]),
                                    node.userNames
                                      ? _c(
                                          "div",
                                          { staticClass: "person-list" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(node.userNames)),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isEditModel
                                      ? _c(
                                          "div",
                                          { staticClass: "tool-list" },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-edit",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.nodeEdit(node)
                                                },
                                              },
                                            }),
                                            _c("i", {
                                              staticClass: "el-icon-user",
                                              staticStyle: {
                                                "margin-left": "5px",
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.personSetting(node)
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "container-table" }, [
                    _c(
                      "div",
                      { staticClass: "table-box mb-10" },
                      [
                        _c("personList", {
                          ref: "personList",
                          attrs: { soOrgId: _vm.currentTab },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "table-box" },
                      [
                        _c("safetyMeeting", {
                          ref: "safetyMeeting",
                          attrs: { soOrgId: _vm.currentTab },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "el-dialog",
                  {
                    directives: [
                      { name: "dialogDrag", rawName: "v-dialogDrag" },
                    ],
                    attrs: {
                      title: _vm.orgDialogTitle,
                      visible: _vm.orgDialogVisible,
                      width: "40%",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.orgDialogVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "orgForm",
                        attrs: {
                          model: _vm.orgForm,
                          rules: _vm.orgRules,
                          "label-width": "100px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "组织名称", prop: "soOrgName" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入组织名" },
                              model: {
                                value: _vm.orgForm.soOrgName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.orgForm, "soOrgName", $$v)
                                },
                                expression: "orgForm.soOrgName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "footer" },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.orgDialogLoading,
                                expression: "orgDialogLoading",
                              },
                            ],
                            attrs: { size: "small" },
                            on: { click: _vm.handleOrgCancel },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.orgDialogLoading,
                                expression: "orgDialogLoading",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleOrgSave },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "el-dialog",
                  {
                    directives: [
                      { name: "dialogDrag", rawName: "v-dialogDrag" },
                    ],
                    attrs: {
                      title: _vm.nodeDialogTitle,
                      visible: _vm.nodeDialogVisible,
                      width: "40%",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.nodeDialogVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "nodeForm",
                        attrs: {
                          model: _vm.nodeForm,
                          rules: _vm.nodeRules,
                          "label-width": "100px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "节点名称", prop: "nodeName" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入节点名称" },
                              model: {
                                value: _vm.nodeForm.nodeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.nodeForm, "nodeName", $$v)
                                },
                                expression: "nodeForm.nodeName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "footer" },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.nodeDialogLoading,
                                expression: "nodeDialogLoading",
                              },
                            ],
                            attrs: { size: "small" },
                            on: { click: _vm.handleNodeCancel },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.nodeDialogLoading,
                                expression: "nodeDialogLoading",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleNodeSave },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "el-dialog",
                  {
                    directives: [
                      { name: "dialogDrag", rawName: "v-dialogDrag" },
                    ],
                    attrs: {
                      title: _vm.personDialogTitle,
                      visible: _vm.personDialogVisible,
                      width: "40%",
                      "before-close": _vm.handlePersonCancel,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.personDialogVisible = $event
                      },
                    },
                  },
                  [
                    _c("el-transfer", {
                      ref: "transferRef",
                      attrs: {
                        filterable: "",
                        "filter-method": _vm.filterMethod,
                        "filter-placeholder": "请输入搜索内容",
                        titles: ["人员清单", "节点人员"],
                        data: _vm.personData,
                        props: _vm.transferProps,
                        "right-default-checked": _vm.rightDefaultChecked,
                      },
                      on: { change: _vm.rightCheckChange },
                      model: {
                        value: _vm.personListValue,
                        callback: function ($$v) {
                          _vm.personListValue = $$v
                        },
                        expression: "personListValue",
                      },
                    }),
                    _c(
                      "template",
                      { slot: "footer" },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.personDialogLoading,
                                expression: "personDialogLoading",
                              },
                            ],
                            attrs: { size: "small" },
                            on: { click: _vm.handlePersonCancel },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.personDialogLoading,
                                expression: "personDialogLoading",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handlePersonSave },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }