<template>
  <div class="main-box riskIdentification">
    <div class="flex-container">
      <div class="table-box">
        <head-layout :head-btn-options="headBtnOptions " @head-cancel="headCancel" @head-save="headSave" @head-edit="headEdit" head-title="当前盒子信息"></head-layout>
        <el-form class="form-base" ref="dataForm" :model="dataForm" label-width="160px" :disabled="formType == 'view'" :rules="rules">
          <el-col :span="8">
            <el-form-item label="编码" prop="code">
              <el-input v-model="dataForm.code" placeholder="请填写编码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input v-model="dataForm.name" placeholder="请填写名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类型" prop="type">
              <el-select v-model="dataForm.type" placeholder="请选择类型">
                <el-option v-for="item in NODE_TYPES_list" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="请填写备注"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <head-layout head-title="设备信息"></head-layout>
        <el-form class="form-base" ref="dataForm" :model="dataForm" label-width="160px" :disabled="formType == 'view'" :rules="rules">
          <el-col :span="8">
            <el-form-item label="IP" prop="ip">
              <el-input v-model="dataForm.ip" placeholder="请填写IP"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="端口" prop="port">
              <el-input v-model="dataForm.port" placeholder="请填写端口"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="MAC" prop="macAddress">
              <el-input v-model="dataForm.macAddress" placeholder="请填写MAC"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作系统" prop="os">
              <el-input v-model="dataForm.os" placeholder="请填写操作系统"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="内存（MB）" prop="memory">
              <el-input v-model="dataForm.memory" placeholder="请填写内存（MB）"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="硬盘内容（GB）" prop="hardDiskCap">
              <el-input v-model="dataForm.hardDiskCap" placeholder="请填写硬盘内容（GB）"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="CPU核心数" prop="cpu">
              <el-input v-model="dataForm.cpu" placeholder="请填写CPU核心数"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="GPU" prop="gpu">
              <el-input v-model="dataForm.gpu" placeholder="请填写GPU"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="NPU" prop="npu">
              <el-input v-model="dataForm.npu" placeholder="请填写NPU"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
    </div>
    <el-dialog title="评估单元及风险点" v-if="jcxflModel" v-dialogDrag :visible.sync="jcxflModel" width="50%">
      <avue-form :option="jcxflOption" @reset-change="resetChange" @submit="handleSubmit" v-model="jcxflForm">
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
  import CommonTree from "@/views/components/com_tree/index.vue";
  import Template from "@/views/message/template/list.vue";
  import * as API from "@/api/safetyTtandard/riskIdentification";
  import {
    exportBlob
  } from "@/api/common";
  import {
    downloadXls
  } from "@/util/util";
  import GridLayout from "@/views/components/layout/grid-layout";
  import {
    dictionaryBiz
  } from "@/api/reportTasks";
  import {
    getServerById,
    serverEdit
  } from "@/api/safe/algorithmManagement";
  import {
    NODE_TYPES
  } from '../nodeDict.js'

  export default {
    name: "index",
    components: {
      Template,
      GridLayout,
      CommonTree
    },
    props: {
      selectTree: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        activeName: 'base',
        jcxflModel: false,
        TableLoading: false,
        NODE_TYPES_list: [],
        tableData: [],
        treeData: [],
        query: {},
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        dataForm: {},
        defaultExpandedKeys: [],
        formType: 'view'
      }
    },
    computed: {
      headBtnOptions() {
        let result = [];
        if (["edit"].includes(this.formType)) {
          result.push({
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          });
          result.push({
            label: "取消",
            emit: "head-cancel",
            type: "button",
            btnOptType: "cancel",
          });
        } else {
          result.push({
            label: "编辑",
            emit: "head-edit",
            type: "button",
            btnOptType: "edit",
          });
        }

        return result;
      },
    },
    watch: {
      selectTree: {
        immediate: true,
        handler: function (data) {
          if (data) this.getFormData();
        },
      },

    },
    mounted() {
      this.NODE_TYPES_list = NODE_TYPES
    },
    methods: {
      handleClick() {
        this.typeTree();
      },
      getFormData() {
        getServerById({
          id: this.selectTree.id
        }).then((res) => {
          this.formType = 'view'
          this.dataForm = res.data.data
        })
      },
      headEdit() {
        this.formType = 'edit'
      },
      headCancel() {
        this.formType = 'view'
      },
      headSave() {
        let data = {
          ...this.dataForm,
        };
        serverEdit(data).then((res) => {
            this.$message.success('操作成功');
            this.getFormData();
          })
          .catch((err) => {
            done();
          });
      },

    },
  }

</script>
<style scoped lang="scss">
  .riskIdentification {
    background-color: #FFFFFF;

    .common_tree_handle .el-tree {
      height: calc(100vh - 278px) !important;
    }

    .table-box {
      width: 100%;
    }
  }

  .form-base {
    margin-top: 16px;
    margin-right: 10px;
  }

  ::v-deep .introduceTable .avue-crud .el-table {
    height: calc(100vh - 330px) !important;
    max-height: calc(100vh - 330px) !important;
  }

  .introduceTree ::v-deep .common_tree_handle .el-tree {
    height: calc(100vh - 278px) !important;
  }

  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }

  ::v-deep .el-tabs--border-card {
    border-left: none;
    border-bottom: none;
  }

  ::v-deep .el-tabs__content {
    padding: 0 !important;
  }

</style>
