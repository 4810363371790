<template>
  <div>
    <head-layout
      head-title="排放记录清单"
      :showIcon="false"
      :head-btn-options="headBtnOptions"
      @head-add="headAdd"
      @head-romve="headRomve"
    ></head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-span="3"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      @grid-row-detail-click="detailView"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @gird-handle-select-click="selectionChange"
    >
      <template #customBtn="{ row }">
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          v-if="!row.status&&row.createUser===userInfo.user_id"
          @click="rowView(row, 'edit')"
          >编辑</el-button
        >
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          v-if="!row.status&&row.createUser===userInfo.user_id"
          @click="rowRemove(row)"
          >删除</el-button
        >
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          v-if="row.status"
          @click="rowView(row, 'view')"
          >查看</el-button
        >
      </template>
    </grid-layout>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import { mapGetters } from "vuex";

import * as API from "@/api/environmentProtect/pollutionEmissions";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableData: [],
      selectionList: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: "",
        },
      ];
    },
    tableOption() {
      return {
        linklabel: "emissionCode",
        selectable: (row,index)=>{
          return row.status?false:row.createUser!=this.userInfo.user_id?false:true
        },
        menuFixed: "right",
        menuWidth: 150,
        column: [
          {
            label: "记录编号",
            prop: "emissionCode",
            align: "left",
            overHidden: true,
            width: 200,
          },
          {
            label: "污染物类型",
            prop: "pollutionType",
            align: "center",
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=ep_pollution_type`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "污染物明细",
            prop: "pollutionDetail",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "排放量",
            prop: "quantity",
            align: "center",
            overHidden: true,
          },
          {
            label: "排放开始时间",
            prop: "startTime",
            align: "center",
            width: 200,
            overHidden: true,
          },
          {
            label: "排放结束时间",
            prop: "endTime",
            align: "center",
            width: 200,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            type: "select",
            dicData: [
              { dictValue: "完成", dictKey: 1 }, // APPROVED
              { dictValue: "草稿", dictKey: 0 }, // PREPARE
            ],
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
        ],
      };
    },
    searchColumns() {
      return [
        {
          label: "记录编号",
          prop: "emissionCode",
          span: 3,
          placeholder: "请输入记录编号",
        },
        {
          label: "污染物类型",
          prop: "pollutionType",
          span: 3,
          type: "select",
          placeholder: "请选择污染物类型",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=ep_pollution_type`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "污染物明细",
          prop: "pollutionDetail",
          span: 3,
          placeholder: "请输入污染物明细",
        },
        {
          label: "日期",
          prop: "daterange",
          type: "daterange",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          span: 4,
          startPlaceholder: "排放开始日期",
          endPlaceholder: "排放结束日期",
        },
      ];
    },
  },
  methods: {
    init() {
      this.onLoad(this.page, {});
    },
    // 搜索
    gridHeadSearch(searchForm) {
      if (
        this.$refs.gridHeadLayout.searchForm.daterange &&
        this.$refs.gridHeadLayout.searchForm.daterange.length != 0
      ) {
        this.$refs.gridHeadLayout.searchForm.startTime =
          this.$refs.gridHeadLayout.searchForm.daterange[0] + ' 00:00:00';
        this.$refs.gridHeadLayout.searchForm.endTime =
          this.$refs.gridHeadLayout.searchForm.daterange[1] + ' 23:59:59';
      } else {
        delete this.$refs.gridHeadLayout.searchForm.startTime;
        delete this.$refs.gridHeadLayout.searchForm.endTime;
      }
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      API.pollutionRecordPage({
        current: page.currentPage,
        size: page.pageSize,
        orgId: this.userInfo.dept_id,
        ...params,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
          //重新渲染表格，解决勾选框错行问题
          this.$nextTick(() => {
            this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
          });
          this.$refs.gridLayOut.selectionClear();
        }
      });
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 批量删除
    headRomve() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return API.pollutionRecordRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 单条删除
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.pollutionRecordRemove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    headAdd() {
      this.$router.push({
        path: `/environmentProtect/pollutionEmissions/pollutionRecord/edit`,
        query: {
          type: "add",
        },
      });
    },
    detailView(row) {
      this.rowView(row, "view");
    },
    rowView(row, type, hide) {
      this.$router.push({
        path: "/environmentProtect/pollutionEmissions/pollutionRecord/edit",
        query: {
          id: row.id,
          type: type || "view",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
