var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "areaPostList" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: { "head-title": "问题清单", showIcon: false },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowView,
                },
                scopedSlots: _vm._u([
                  {
                    key: "hdLevel",
                    fn: function ({ row }) {
                      return [
                        _vm.hdLevel[row.hdLevel]
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.hdLevelIcon[row.hdLevel],
                                  effect: "dark",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.hdLevel[row.hdLevel]) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }