var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "访客预约", name: "second" } }),
          _c("el-tab-pane", { attrs: { label: "访客记录", name: "third" } }),
        ],
        1
      ),
      _vm.activeName == "second"
        ? _c("visitorReservation", {
            ref: "visitorReservation",
            attrs: { prjId: _vm.dataForm.id },
          })
        : _vm._e(),
      _vm.activeName == "third"
        ? _c("visitorRecord", {
            ref: "visitorRecord",
            attrs: { prjId: _vm.dataForm.id },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }