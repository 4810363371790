var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "开启展示值" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入开启展示值" },
            model: {
              value: _vm.activeData.activeTxt,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "activeTxt", $$v)
              },
              expression: "activeData.activeTxt",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "关闭展示值" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入关闭展示值" },
            model: {
              value: _vm.activeData.inactiveTxt,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "inactiveTxt", $$v)
              },
              expression: "activeData.inactiveTxt",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-switch", {
            attrs: {
              "active-value": _vm.activeData["active-value"],
              "inactive-value": _vm.activeData["inactive-value"],
            },
            model: {
              value: _vm.activeData.__config__.defaultValue,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
              },
              expression: "activeData.__config__.defaultValue",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }