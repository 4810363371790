var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "print", staticClass: "offer-container" }, [
    _c("div", { staticClass: "tem_list" }, [
      _c("div", { staticClass: "tem_main" }, [
        _c("h1", [_vm._v("引迈信息技术有限公司")]),
        _c("h2", [_vm._v("报 价 单")]),
        _c("h4", [_vm._v("TO：")]),
        _c("p", { staticClass: "title" }, [
          _vm._v("感谢惠顾，现将贵公司所需配件报价如下："),
        ]),
        _c("p", { staticClass: "lip" }, [_vm._v("一、产品价格")]),
        _c(
          "div",
          { staticStyle: { padding: "0 1px 0 0" } },
          [
            _c(
              "el-table",
              { attrs: { data: _vm.tableData, border: "" } },
              [
                _c("el-table-column", {
                  attrs: { prop: "order", label: "序号", width: "50" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "品名", width: "150" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "standards", label: "规格", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "num", label: "数量", width: "60" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "unit", label: "单位", width: "60" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "unitprice", label: "单价" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "total", label: "金额" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "remarks", label: "备注" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("p", { staticClass: "lip" }, [_vm._v("二、通讯联络")]),
        _vm._m(0),
        _c("div", { staticClass: "temdate" }, [_vm._v("日 期：2017-11-29")]),
        _c("div", { staticClass: "seal" }, [_vm._v("盖章：")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content1" }, [
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("需方联系人员：")]),
        _c("span", [_vm._v("丰梦琪")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("供方联系人员：")]),
        _c("span", [_vm._v("应白梦")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("需方联系电话：")]),
        _c("span", [_vm._v("13802141322")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("供方联系电话：")]),
        _c("span", [_vm._v("13802141322")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("需方传真号码：")]),
        _c("span", [_vm._v("010-88888888")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("供方传真号码：")]),
        _c("span", [_vm._v("021-88888888")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("供方地址：")]),
        _c("span", [_vm._v("北京市海淀区西直门北大街42号")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("供方地址：")]),
        _c("span", [_vm._v("上海市青浦区")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("供方邮编：")]),
        _c("span", [_vm._v("100000")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("供方邮编：")]),
        _c("span", [_vm._v("201799")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }