var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "print" }, [
    _c(
      "div",
      { staticClass: "record" },
      [
        _c(
          "el-table",
          { attrs: { data: _vm.tableData3 } },
          [
            _c(
              "el-table-column",
              { attrs: { label: "基本信息" } },
              [
                _c("el-table-column", {
                  attrs: { prop: "data1", label: "工号" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "data2", label: "100001" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "data3", label: "姓名" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "data4", label: "红幻波" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { "border-top": "none" },
            attrs: { data: _vm.tableData, border: "" },
          },
          [
            _c(
              "el-table-column",
              { attrs: { label: "受教育情况" } },
              [
                _c("el-table-column", {
                  attrs: { prop: "date", label: "时间范围" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "education", label: "教育名称" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "reward", label: "获得奖励" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "prove", label: "证明人" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }