<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.dc.AIDComission.title2')"
      @head-cancel="cancelFn"
      @head-save="saveFn('save')"
     @head-saveback="saveFn('back')"
    >
    </head-layout>
    <div style="background: #fff; padding: 10px">
      <template>
        <el-form model="form" label-width="100px">
          <div style="width: 100%; height: 20px"></div>
          <el-divider content-position="left">{{
            $t("cip.dc.AiSuanfa.field.placeholder")
          }}</el-divider>

          <div style="width: 100%; height: 20px"></div>
          <el-row>
            <el-col :span="8">
              <el-form-item :label="$t('cip.dc.AiSuanfa.field.modeName')">
                <el-input
                  v-model="form.algorithmName"
                  class="new-form-input"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item :label="$t('cip.dc.AIDComission.url')">
                <el-input v-model="form.trainingUrl"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item :label="$t('cip.dc.AIDComission.sort')">
                <el-input-number
                  style="width: 100%"
                  :controls="false"
                  v-model.number="form.algorithmSort"
                  :min="0"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item :label="$t('cip.dc.AiSuanfa.field.x')">
                <el-select v-model="form.xaxis">
                  <el-option :label="$t('cip.dc.AIDComission.danxuan')" :value="1"></el-option>
                  <el-option label="$t('cip.dc.AIDComission.duoxuan')" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item :label="$t('cip.dc.AiSuanfa.field.y')">
                <el-select v-model="form.yaxis">
                  <el-option :label="$t('cip.dc.AIDComission.danxuan')" :value="1"></el-option>
                  <el-option :label="$t('cip.dc.AIDComission.buxuan')" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div style="width: 100%; height: 20px"></div>

        <el-divider content-position="left"> {{ $t('cip.dc.AIDComission.tiaocan') }} </el-divider>
        <el-button type="primary" @click="addtc">{{  $t('cip.dc.AIDComission.add') }} </el-button>
        <div style="width: 100%; height: 10px"></div>
        <el-table :data="form.argsList" border style="width: 100%">
          <el-table-column :label="$t('cip.dc.AIDComission.argName')">
            <template slot-scope="scope">
              <el-input v-model="scope.row.argName"></el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('cip.dc.AIDComission.argCode')">
            <template slot-scope="scope">
              <el-input v-model="scope.row.argCode"></el-input>
            </template>
          </el-table-column>
          <el-table-column :label="$t('cip.dc.AIDComission.argSort')">
            <template slot-scope="scope">
              <el-input-number
                style="width: 100%"
                :controls="false"
                v-model.number="scope.row.argSort"
                :min="0"
              ></el-input-number>
            </template>
          </el-table-column>

          <el-table-column :label="$t('cip.dc.apiDComission.caozuo')" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="deltc(scope.row)">
                {{ $t('cip.dc.apiDComission.del') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>

<script>
import { suanfaSetDetail, suanfaSetUpdate } from "@/api/Ai";

export default {
  data() {
    return {
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
          loading: false,
        },
         {
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-saveback",
          type: "button",
          icon: "",
          loading: false,
        },
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        },
      ],
      form: {
        argsList: [
          {
            argName: "",
            argCode: "",
            argSort: 0,
          },
        ],
      },
    };
  },
  created() {
    this.MsuanfaSetDetail(this.$route.query.id);
  },

  methods: {
    //详情
    MsuanfaSetDetail(e) {
      suanfaSetDetail({ id: e }).then((res) => {
        this.form = res.data.data;
      });
    },

    //添加调参
    addtc() {
      this.form.argsList.push({
        argName: "",
        argCode: "",
        argSort: 0,
      });
    },
    //删除调参
    deltc(e) {
      this.form.argsList.forEach((element, index) => {
        if (e == element) {
          this.form.argsList.splice(index, 1);
        }
      });
    },
    //保存
    saveFn(e) {
      this.headBtnOptions[0].loading = true;
      this.headBtnOptions[1].loading = true;
      let parms = {
        algorithmId:this.form.algorithmId,
        xaxis:this.form.xaxis,
        yaxis:this.form.yaxis,
        algorithmSort:this.form.algorithmSort,
        argsList:this.form.argsList,
      }
      suanfaSetUpdate(parms)
        .then((res) => {
          this.$message.success(this.$t("cip.dc.Token.msg"));
          this.headBtnOptions[0].loading = false;
          this.headBtnOptions[1].loading = false;
            if(e=="back"){
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      // this.$router.push({
      //  path: '/suanfaSet',
       
      // })
      }
        })
        .catch((res) => {
          this.headBtnOptions[0].loading = false;
          this.headBtnOptions[1].loading = false;
        });
    },

    //返回

    cancelFn(){
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      // this.$router.push({
      //  path: '/suanfaSet',
       
      // })
    }
  },
};
</script>

<style scoped>
::v-deep .seltAllbtnDis .cell {
  visibility: hidden;
}
</style>
