var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "root" }, [
    _c("div", { staticClass: "container-shell" }, [
      _c("div", { staticClass: "container-shell-title" }, [
        _vm._v("jessibuca Pro demo player "),
        _vm.version
          ? _c("span", { staticClass: "tag-version" }, [
              _vm._v("(" + _vm._s(_vm.version) + ")"),
            ])
          : _vm._e(),
      ]),
      _c("div", { attrs: { id: "container" } }),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c("div", { staticClass: "input input-wrap" }, [
        _c("div", [
          _vm._v("\n        当前浏览器：\n        "),
          _vm.supportMSE
            ? _c(
                "span",
                { staticStyle: { color: "green", "margin-right": "10px" } },
                [_vm._v("支持MSE H264解码；")]
              )
            : _vm._e(),
          !_vm.supportMSE
            ? _c("span", { staticStyle: { color: "red" } }, [
                _vm._v("不支持MSE H264解码；"),
              ])
            : _vm._e(),
          _vm.supportMSEHevc
            ? _c(
                "span",
                { staticStyle: { color: "green", "margin-right": "10px" } },
                [_vm._v("支持MSE H265解码;")]
              )
            : _vm._e(),
          !_vm.supportMSEHevc
            ? _c(
                "span",
                { staticStyle: { color: "red", "margin-right": "10px" } },
                [_vm._v("不支持MSE H265解码,会自动切换成wasm(simd)解码")]
              )
            : _vm._e(),
        ]),
        _c("div", [
          _vm.playing && _vm.decodeType
            ? _c("div", [
                _vm._v("\n          当前解码："),
                _c("span", [_vm._v(_vm._s(_vm.decodeType))]),
              ])
            : _vm._e(),
          _vm.playing && _vm.renderType
            ? _c("div", [
                _vm._v("\n          当前模式："),
                _c("span", [_vm._v(_vm._s(_vm.playType))]),
                _vm._v(" 当前渲染："),
                _c("span", [_vm._v(_vm._s(_vm.renderType))]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "input" }, [
        _vm._v("\n      当前浏览器：\n      "),
        _vm.supportWCS
          ? _c("span", { staticStyle: { color: "green" } }, [
              _vm._v("支持Webcodec H264解码；"),
            ])
          : _vm._e(),
        !_vm.supportWCS
          ? _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("不支持Webcodec H265解码(需要https/localhost),"),
            ])
          : _vm._e(),
        _vm.supportWCSHevc
          ? _c("span", { staticStyle: { color: "green" } }, [
              _vm._v("支持Webcodec H265解码；"),
            ])
          : _vm._e(),
        !_vm.supportWCSHevc
          ? _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(
                "不支持Webcodec H265解码(需要https/localhost),会自动切换成wasm(simd)解码"
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "input" }, [
        _vm._v("\n      当前浏览器：\n      "),
        _vm.supportSIMDHevc
          ? _c("span", { staticStyle: { color: "green" } }, [
              _vm._v("支持SIMD解码；"),
            ])
          : _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("不支持SIMD解码,会自动切换成wasm解码；"),
            ]),
        _vm.supportMT
          ? _c("span", { staticStyle: { color: "green" } }, [
              _vm._v("支持wasm,wasm(SIMD)多线程解码；"),
            ])
          : _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("不支持wasm,wasm(SIMD)多线程解码；"),
            ]),
      ]),
      _c("div", { staticClass: "input" }, [
        _vm._v("\n      当前浏览器：\n      "),
        _vm.supportWebgpu
          ? _c("span", { staticStyle: { color: "green" } }, [
              _vm._v("支持webgpu渲染"),
            ])
          : _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("不支持webgpu渲染,会自动切换成webgl渲染"),
            ]),
        _vm._v("（适用于wasm(simd)解码+canvas渲染）\n    "),
      ]),
      _c("div", { staticClass: "input" }, [
        _c("span", [_vm._v("最大网络延迟:")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.networkDelay,
              expression: "networkDelay",
            },
          ],
          staticStyle: { width: "50px" },
          attrs: { type: "number" },
          domProps: { value: _vm.networkDelay },
          on: {
            change: _vm.changeNetworkDelay,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.networkDelay = $event.target.value
            },
          },
        }),
        _c("span", { staticStyle: { "margin-right": "5px" } }, [_vm._v("秒")]),
        _c("span", [_vm._v("最大延迟:")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.videoBufferDelay,
              expression: "videoBufferDelay",
            },
          ],
          staticStyle: { width: "50px" },
          attrs: { type: "number" },
          domProps: { value: _vm.videoBufferDelay },
          on: {
            change: _vm.changeBufferDelay,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.videoBufferDelay = $event.target.value
            },
          },
        }),
        _c("span", { staticStyle: { "margin-right": "5px" } }, [_vm._v("秒")]),
        _c("span", [_vm._v("缓冲:")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.videoBuffer,
              expression: "videoBuffer",
            },
          ],
          staticStyle: { width: "50px" },
          attrs: { type: "number" },
          domProps: { value: _vm.videoBuffer },
          on: {
            change: _vm.changeBuffer,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.videoBuffer = $event.target.value
            },
          },
        }),
        _c("span", { staticStyle: { "margin-right": "5px" } }, [_vm._v("秒")]),
      ]),
      _c("div", { staticClass: "input" }, [
        _c("span", [_vm._v("解码器：")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.useMSE,
              expression: "useMSE",
            },
          ],
          ref: "vod",
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.useMSE)
              ? _vm._i(_vm.useMSE, null) > -1
              : _vm.useMSE,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.useMSE,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.useMSE = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.useMSE = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.useMSE = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay("mse")
              },
            ],
          },
        }),
        _c("span", [_vm._v("MediaSource")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.useWCS,
              expression: "useWCS",
            },
          ],
          ref: "vod",
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.useWCS)
              ? _vm._i(_vm.useWCS, null) > -1
              : _vm.useWCS,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.useWCS,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.useWCS = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.useWCS = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.useWCS = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay("wcs")
              },
            ],
          },
        }),
        _c("span", [_vm._v("webcodecs")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.useSIMD,
              expression: "useSIMD",
            },
          ],
          ref: "vod",
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.useSIMD)
              ? _vm._i(_vm.useSIMD, null) > -1
              : _vm.useSIMD,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.useSIMD,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.useSIMD = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.useSIMD = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.useSIMD = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay("simd")
              },
            ],
          },
        }),
        _c("span", [_vm._v("SIMD")]),
      ]),
      _c("div", { staticClass: "input" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.demuxUseWorker,
              expression: "demuxUseWorker",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.demuxUseWorker)
              ? _vm._i(_vm.demuxUseWorker, null) > -1
              : _vm.demuxUseWorker,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.demuxUseWorker,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.demuxUseWorker = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.demuxUseWorker = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.demuxUseWorker = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay()
              },
            ],
          },
        }),
        _c("span", [_vm._v("硬解码(MediaSource，Webcodec)worker解封装")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.mseDecoderUseWorker,
              expression: "mseDecoderUseWorker",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.mseDecoderUseWorker)
              ? _vm._i(_vm.mseDecoderUseWorker, null) > -1
              : _vm.mseDecoderUseWorker,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.mseDecoderUseWorker,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.mseDecoderUseWorker = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.mseDecoderUseWorker = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.mseDecoderUseWorker = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay()
              },
            ],
          },
        }),
        _c("span", [_vm._v("硬解码(MediaSource)worker解码")]),
      ]),
      _c("div", { staticClass: "input" }, [
        _c("div", [
          _vm.supportMT
            ? _c("div", { staticStyle: { display: "inline-block" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.useMT,
                      expression: "useMT",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.useMT)
                      ? _vm._i(_vm.useMT, null) > -1
                      : _vm.useMT,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.useMT,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.useMT = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.useMT = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.useMT = $$c
                        }
                      },
                      function ($event) {
                        return _vm.restartPlay()
                      },
                    ],
                  },
                }),
                _c("span", [_vm._v("使用多线程解码")]),
              ])
            : _vm._e(),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isMute,
                expression: "isMute",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.isMute)
                ? _vm._i(_vm.isMute, null) > -1
                : _vm.isMute,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.isMute,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isMute = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isMute = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isMute = $$c
                  }
                },
                function ($event) {
                  return _vm.restartPlay()
                },
              ],
            },
          }),
          _c("span", [_vm._v("静音播放")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isFlv,
                expression: "isFlv",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.isFlv)
                ? _vm._i(_vm.isFlv, null) > -1
                : _vm.isFlv,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.isFlv,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isFlv = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isFlv = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isFlv = $$c
                  }
                },
                function ($event) {
                  return _vm.restartPlay()
                },
              ],
            },
          }),
          _c("span", [_vm._v("设置Flv格式")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isFmp4,
                expression: "isFmp4",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.isFmp4)
                ? _vm._i(_vm.isFmp4, null) > -1
                : _vm.isFmp4,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.isFmp4,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isFmp4 = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isFmp4 = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isFmp4 = $$c
                  }
                },
                function ($event) {
                  return _vm.restartPlay()
                },
              ],
            },
          }),
          _c("span", [_vm._v("设置Fmp4格式")]),
        ]),
      ]),
      _c("div", { staticClass: "input" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.networkDelayTimeoutReplay,
              expression: "networkDelayTimeoutReplay",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.networkDelayTimeoutReplay)
              ? _vm._i(_vm.networkDelayTimeoutReplay, null) > -1
              : _vm.networkDelayTimeoutReplay,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.networkDelayTimeoutReplay,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      (_vm.networkDelayTimeoutReplay = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.networkDelayTimeoutReplay = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.networkDelayTimeoutReplay = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay()
              },
            ],
          },
        }),
        _c("span", [_vm._v("网络延迟重新播放")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.hiddenAutoPause,
              expression: "hiddenAutoPause",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.hiddenAutoPause)
              ? _vm._i(_vm.hiddenAutoPause, null) > -1
              : _vm.hiddenAutoPause,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.hiddenAutoPause,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.hiddenAutoPause = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.hiddenAutoPause = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.hiddenAutoPause = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay()
              },
            ],
          },
        }),
        _c("span", [_vm._v("最小化自动暂停")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.hasVideo,
              expression: "hasVideo",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.hasVideo)
              ? _vm._i(_vm.hasVideo, null) > -1
              : _vm.hasVideo,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.hasVideo,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.hasVideo = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.hasVideo = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.hasVideo = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay()
              },
            ],
          },
        }),
        _c("span", [_vm._v("解码视频")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.hasAudio,
              expression: "hasAudio",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.hasAudio)
              ? _vm._i(_vm.hasAudio, null) > -1
              : _vm.hasAudio,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.hasAudio,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.hasAudio = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.hasAudio = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.hasAudio = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay()
              },
            ],
          },
        }),
        _c("span", [_vm._v("解码音频")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.checkFirstIFrame,
              expression: "checkFirstIFrame",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.checkFirstIFrame)
              ? _vm._i(_vm.checkFirstIFrame, null) > -1
              : _vm.checkFirstIFrame,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.checkFirstIFrame,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkFirstIFrame = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.checkFirstIFrame = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.checkFirstIFrame = $$c
                }
              },
              function ($event) {
                return _vm.restartPlay()
              },
            ],
          },
        }),
        _c("span", [_vm._v("检查首帧是否I帧")]),
      ]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("span", [_vm._v("渲染标签：")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.renderDom,
                  expression: "renderDom",
                },
              ],
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.renderDom = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.renderDomChange,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "video" } }, [_vm._v("video")]),
              _c("option", { attrs: { value: "canvas" } }, [_vm._v("canvas")]),
            ]
          ),
          _vm.renderDom === "canvas"
            ? [
                _c("span", [_vm._v("渲染引擎：")]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.canvasRenderType,
                        expression: "canvasRenderType",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.canvasRenderType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.renderDomChange,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "webgl" } }, [
                      _vm._v("webgl"),
                    ]),
                    _c("option", { attrs: { value: "webgpu" } }, [
                      _vm._v("webgpu"),
                    ]),
                  ]
                ),
              ]
            : _vm._e(),
          _c("span", [_vm._v("音频引擎：")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.audioEngine,
                  expression: "audioEngine",
                },
              ],
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.audioEngine = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.replay,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("自动")]),
              _c("option", { attrs: { value: "worklet" } }, [
                _vm._v("worklet(https)"),
              ]),
              _c("option", { attrs: { value: "script" } }, [
                _vm._v("script(默认)"),
              ]),
              _c("option", { attrs: { value: "active" } }, [
                _vm._v("active(安卓)"),
              ]),
            ]
          ),
          _c(
            "span",
            { staticClass: "span-row", staticStyle: { "margin-left": "10px" } },
            [
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v("录制格式"),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.recordType,
                      expression: "recordType",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.recordType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.restartPlay()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "webm" } }, [_vm._v("webm")]),
                  _c("option", { attrs: { value: "mp4" } }, [_vm._v("mp4")]),
                ]
              ),
            ]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isDebug,
                expression: "isDebug",
              },
            ],
            ref: "isDebug",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.isDebug)
                ? _vm._i(_vm.isDebug, null) > -1
                : _vm.isDebug,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.isDebug,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isDebug = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isDebug = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isDebug = $$c
                  }
                },
                _vm.restartPlay,
              ],
            },
          }),
          _c("span", [_vm._v("控制台日志")]),
          _vm.isDebug
            ? _c(
                "span",
                {
                  staticClass: "span-row",
                  staticStyle: { "margin-left": "10px" },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("日志等级"),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.debugLevel,
                          expression: "debugLevel",
                        },
                      ],
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.debugLevel = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.restartPlay()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "debug" } }, [
                        _vm._v("debug"),
                      ]),
                      _c("option", { attrs: { value: "warn" } }, [
                        _vm._v("warn"),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "input" }, [
        _c("div", { staticStyle: { "line-height": "30px" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.showOperateBtns,
                expression: "showOperateBtns",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.showOperateBtns)
                ? _vm._i(_vm.showOperateBtns, null) > -1
                : _vm.showOperateBtns,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.showOperateBtns,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.showOperateBtns = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.showOperateBtns = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.showOperateBtns = $$c
                  }
                },
                _vm.restartPlay,
              ],
            },
          }),
          _c("span", [_vm._v("操作按钮")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.showBandwidth,
                expression: "showBandwidth",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.showBandwidth)
                ? _vm._i(_vm.showBandwidth, null) > -1
                : _vm.showBandwidth,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.showBandwidth,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.showBandwidth = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.showBandwidth = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.showBandwidth = $$c
                  }
                },
                _vm.restartPlay,
              ],
            },
          }),
          _c("span", [_vm._v("网速")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.hotKey,
                expression: "hotKey",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.hotKey)
                ? _vm._i(_vm.hotKey, null) > -1
                : _vm.hotKey,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.hotKey,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.hotKey = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.hotKey = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.hotKey = $$c
                  }
                },
                function ($event) {
                  return _vm.restartPlay()
                },
              ],
            },
          }),
          _c("span", [_vm._v("键盘快捷键")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.controlAutoHide,
                expression: "controlAutoHide",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.controlAutoHide)
                ? _vm._i(_vm.controlAutoHide, null) > -1
                : _vm.controlAutoHide,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.controlAutoHide,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.controlAutoHide = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.controlAutoHide = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.controlAutoHide = $$c
                  }
                },
                function ($event) {
                  return _vm.restartPlay()
                },
              ],
            },
          }),
          _c("span", [_vm._v("控制栏自动隐藏")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.syncAudioAndVideo,
                expression: "syncAudioAndVideo",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.syncAudioAndVideo)
                ? _vm._i(_vm.syncAudioAndVideo, null) > -1
                : _vm.syncAudioAndVideo,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.syncAudioAndVideo,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.syncAudioAndVideo = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.syncAudioAndVideo = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.syncAudioAndVideo = $$c
                  }
                },
                function ($event) {
                  return _vm.restartPlay()
                },
              ],
            },
          }),
          _c("span", [_vm._v("音视频同步")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isDropSameTimestampGop,
                expression: "isDropSameTimestampGop",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.isDropSameTimestampGop)
                ? _vm._i(_vm.isDropSameTimestampGop, null) > -1
                : _vm.isDropSameTimestampGop,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.isDropSameTimestampGop,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        (_vm.isDropSameTimestampGop = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isDropSameTimestampGop = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isDropSameTimestampGop = $$c
                  }
                },
                function ($event) {
                  return _vm.restartPlay()
                },
              ],
            },
          }),
          _c("span", [_vm._v("遇到相同时间戳是否丢gop")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.showPerformance,
                expression: "showPerformance",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.showPerformance)
                ? _vm._i(_vm.showPerformance, null) > -1
                : _vm.showPerformance,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.showPerformance,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.showPerformance = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.showPerformance = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.showPerformance = $$c
                  }
                },
                _vm.togglePerformance,
              ],
            },
          }),
          _c("span", [_vm._v("显示性能面板")]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("div", [_vm._v("输入URL：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.playUrl,
                expression: "playUrl",
              },
            ],
            attrs: {
              placeholder: "支持 hls/ws-raw/ws-flv/http-flv/fmp4协议",
              type: "input",
              autocomplete: "on",
            },
            domProps: { value: _vm.playUrl },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.playUrl = $event.target.value
              },
            },
          }),
          !_vm.playing
            ? [
                _vm.playType === "" || _vm.playType === "play"
                  ? _c("button", { on: { click: _vm.play } }, [_vm._v("播放")])
                  : _vm._e(),
                _vm.playType === "" || _vm.playType === "playback"
                  ? _c("button", { on: { click: _vm.playback } }, [
                      _vm._v("播放录像流"),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.loading || _vm.playing
            ? [
                _vm.playType === "play"
                  ? [
                      _c("button", { on: { click: () => _vm.pause() } }, [
                        _vm._v("停止"),
                      ]),
                      _c("button", { on: { click: () => _vm.pause(true) } }, [
                        _vm._v("停止(清屏)"),
                      ]),
                    ]
                  : _vm._e(),
                _vm.playType === "playback"
                  ? _c("button", { on: { click: _vm.pause } }, [
                      _vm._v("停止录像流"),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.loaded
        ? _c(
            "div",
            { staticClass: "input", staticStyle: { "line-height": "30px" } },
            [
              _c("button", { on: { click: _vm.destroyPlayer } }, [
                _vm._v("销毁"),
              ]),
              _vm.quieting
                ? _c("button", { on: { click: _vm.cancelMute } }, [
                    _vm._v("取消静音"),
                  ])
                : [
                    _c("button", { on: { click: _vm.mute } }, [_vm._v("静音")]),
                    _c("span", [_vm._v("音量：")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.volume,
                            expression: "volume",
                          },
                        ],
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.volume = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.volumeChange,
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("100"),
                        ]),
                        _c("option", { attrs: { value: "0.75" } }, [
                          _vm._v("75"),
                        ]),
                        _c("option", { attrs: { value: "0.5" } }, [
                          _vm._v("50"),
                        ]),
                        _c("option", { attrs: { value: "0.25" } }, [
                          _vm._v("25"),
                        ]),
                      ]
                    ),
                  ],
              _c("span", [_vm._v("旋转：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rotate,
                      expression: "rotate",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.rotate = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.rotateChange,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "0" } }, [_vm._v("0")]),
                  _c("option", { attrs: { value: "90" } }, [_vm._v("90")]),
                  _c("option", { attrs: { value: "18" } }, [_vm._v("180")]),
                  _c("option", { attrs: { value: "270" } }, [_vm._v("270")]),
                ]
              ),
              _c("span", [_vm._v("镜像旋转:")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mirrorRotate,
                      expression: "mirrorRotate",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.mirrorRotate = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.mirrorRotateChange,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "none" } }, [_vm._v("无")]),
                  _c("option", { attrs: { value: "level" } }, [_vm._v("水平")]),
                  _c("option", { attrs: { value: "vertical" } }, [
                    _vm._v("垂直"),
                  ]),
                ]
              ),
              _c("button", { on: { click: _vm.fullscreen } }, [_vm._v("全屏")]),
              _c("button", { on: { click: _vm.screenShot } }, [_vm._v("截图")]),
              _c("button", { on: { click: _vm.screenshotWatermark1 } }, [
                _vm._v("截图(水印文字)"),
              ]),
              _c("button", { on: { click: _vm.screenshotWatermark2 } }, [
                _vm._v("截图(水印图片)"),
              ]),
              _vm.playType === "playback"
                ? [
                    _c("span", [_vm._v("切换播放倍率:")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.playbackRate,
                            expression: "playbackRate",
                          },
                        ],
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.playbackRate = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.playbackRateChange,
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("1倍"),
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("2倍"),
                        ]),
                        _c("option", { attrs: { value: "3" } }, [
                          _vm._v("3倍"),
                        ]),
                        _c("option", { attrs: { value: "4" } }, [
                          _vm._v("4倍"),
                        ]),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.loaded
        ? _c("div", { staticClass: "input" }, [
            _c("button", { on: { click: _vm.screenShot } }, [_vm._v("截图")]),
            _c("button", { on: { click: _vm.screenShotBlob } }, [
              _vm._v("截图(Blob)"),
            ]),
            _c("button", { on: { click: _vm.screenShotBase64 } }, [
              _vm._v("截图(base64)"),
            ]),
            _c("button", { on: { click: _vm.screenshotWatermark1 } }, [
              _vm._v("截图(水印文字)"),
            ]),
            _c("button", { on: { click: _vm.screenshotWatermark1Blob } }, [
              _vm._v("截图(水印文字)(Blob)"),
            ]),
            _c("button", { on: { click: _vm.screenshotWatermark2 } }, [
              _vm._v("截图(水印图片)"),
            ]),
            _c("button", { on: { click: _vm.screenshotWatermark2Blob } }, [
              _vm._v("截图(水印图片)(Blob)"),
            ]),
          ])
        : _vm._e(),
      _vm.loaded
        ? _c(
            "div",
            { staticClass: "input" },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scale,
                      expression: "scale",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.scale = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.scaleChange,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "0" } }, [
                    _vm._v("完全填充(拉伸)"),
                  ]),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("等比缩放")]),
                  _c("option", { attrs: { value: "2" } }, [
                    _vm._v("完全填充(未拉伸)"),
                  ]),
                ]
              ),
              !_vm.playing
                ? _c("button", { on: { click: _vm.clearView } }, [
                    _vm._v("清屏"),
                  ])
                : _vm._e(),
              _vm.playing
                ? [
                    !_vm.recording
                      ? _c("button", { on: { click: _vm.startRecord } }, [
                          _vm._v("录制"),
                        ])
                      : _vm._e(),
                    !_vm.recording
                      ? _c("button", { on: { click: _vm.stopAndSaveRecord } }, [
                          _vm._v("暂停录制(下载)"),
                        ])
                      : _vm._e(),
                    !_vm.recording
                      ? _c(
                          "button",
                          { on: { click: _vm.stopAndSaveRecord2 } },
                          [_vm._v("暂停录制(blob)")]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c("button", { on: { click: _vm.clearBufferDelay } }, [
                _vm._v("手动消除延迟"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.toggleZoom,
                    expression: "toggleZoom",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.toggleZoom)
                    ? _vm._i(_vm.toggleZoom, null) > -1
                    : _vm.toggleZoom,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.toggleZoom,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.toggleZoom = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.toggleZoom = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.toggleZoom = $$c
                      }
                    },
                    _vm.toggleZoomOperate,
                  ],
                },
              }),
              _c("span", [_vm._v("切换电子放大")]),
            ],
            2
          )
        : _vm._e(),
      _vm.loaded
        ? _c("div", { staticClass: "input" }, [
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("画框（文字）（随机坐标）"),
            ]),
            _vm._v("：\n      "),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.handleAddCanvas("text")
                  },
                },
              },
              [_vm._v("文本")]
            ),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.handleAddCanvas("rect")
                  },
                },
              },
              [_vm._v("框子")]
            ),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.handleAddCanvas("rect+text")
                  },
                },
              },
              [_vm._v("文本+框子")]
            ),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.handleAddCanvasClean()
                  },
                },
              },
              [_vm._v("清空")]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input input-annnie" }, [
      _c("div", { staticClass: "input-tips", staticStyle: { color: "red" } }, [
        _vm._v(
          "Tips:支持录制MP4(MPEG-4)格式的视频(仅录制视频，不包含音频)\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input input-annnie" }, [
      _c("div", { staticClass: "input-tips", staticStyle: { color: "red" } }, [
        _vm._v("Tips:支持录制FLV格式的视频(视频+音频)\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input input-annnie" }, [
      _c(
        "div",
        { staticClass: "input-tips", staticStyle: { color: "green" } },
        [
          _vm._v("Tips:"),
          _c("a", { attrs: { href: "/pro-module.html" } }, [
            _vm._v("MP4录制扩展模块"),
          ]),
          _vm._v("支持(视频H264/H265+音频AAC,MP3,G711a/u)\n      "),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }