<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :title="title" :before-close="handleCancel">
            <el-form :model="form" ref="form" :rules="rules" label-width="100px" :disabled="type == 'view'">
                <el-form-item label="设备编号" prop="deviceCode">
                    <el-input v-model="form.deviceCode" maxlength="100" placeholder="请输入设备编号"></el-input>
                    <el-switch v-model="generate" active-text="自动生成" @change="handleSwidth"></el-switch>
                </el-form-item>
                <el-form-item label="设备名称" prop="deviceName">
                    <el-input v-model="form.deviceName" maxlength="100" placeholder="请输入设备名称"></el-input>
                </el-form-item>
                <el-form-item label="品牌" prop="deviceBrand">
                    <el-input v-model="form.deviceBrand" maxlength="100" placeholder="请输入品牌"></el-input>
                </el-form-item>
                <el-form-item label="厂家" prop="deviceFactory">
                    <el-input v-model="form.deviceFactory" maxlength="50" placeholder="请输入厂家"></el-input>
                </el-form-item>
                <el-form-item label="出厂日期" prop="factoryDate">
                    <el-date-picker v-model="form.factoryDate" type="date" placeholder="请选择日期" value-format="yyyy-MM-dd"> </el-date-picker>
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button size="small" @click="handleCancel()" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.celBtn`) }} </el-button>
                <el-button size="small" type="primary" @click="handleSave" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.defBtn`) }} </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { getPageCode } from '@/api/system/serialNumber'
import { getClassLinSubmit } from '@/api/bssettings/DeviceClass'

export default {
    data () {
        return {
            dialogVisible: false,
            title: '',
            form: {
                deviceCode: '',
                deviceName: '',
                deviceBrand: '',
                deviceFactory: '',
                factoryDate: '',
                classHeadId: '',
            },
            type:'add',
            generate: false, // 自动生成
            rules: {
                deviceCode: [{required: true, message: '请填写设备编码', trigger: ['blur','change']}],
                deviceName: [{required: true, message: '请填写设备名称', trigger: 'blur'}],
            },
            dialogLoading: false
        }
    },

    methods: {
        init (type,data) {
            for(let key in this.form) {
                console.log(key,key in data)
                if(key in data){
                    this.form[key] = data[key]
                } else {
                    this.form[key] = ''
                }
            }
            this.type = type
            this.title = type == 'add' ?  "新增设备" :  type == 'edit' ? '编辑设备' : '查看设备'
            if(type == 'edit' || type ==  'view'){this.form.id = data.id}
            this.dialogVisible = true
        },

        handleSwidth (val) {
            if(val) {
                getPageCode({ruleCode: 'device_code'}).then(res=>{
                    this.form.deviceCode = res.data.data
                })
            }
        },

        handleCancel () {
            this.$refs.form.resetFields()
            this.dialogLoading = false
            this.generate = false
            this.dialogVisible = false
        },

        handleSave () {
            this.$refs.form.validate(valid => {
                if(valid){
                    // this.form.factoryDate = this.form.factoryDate ? new Date(this.form.factoryDate) : ''
                    this.dialogLoading = true
                    getClassLinSubmit(this.form).then(res=>{
                        this.$message.success("操作成功")
                        this.handleCancel()
                        this.$emit('callback')
                    }).catch(()=>{
                        this.dialogLoading = false
                    })
                }
            })
        },
    }
}
</script> 

<style lang="scss" scoped>
::v-deep .el-input{
    width: calc(100% - 120px);
}
.el-switch{
    margin-left: 10px;
}

</style>