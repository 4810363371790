<template>
  <div class="videoMain">
    <div class="videoTopBox">
      <div class="videoTopLeft">
        <avue-input-tree
          default-expand-all
          placeholder="请选择组织"
          v-model="deptId"
          :dic="deptTreeData"
          size="small"
          :clearable="false"
          :props="deptProps"
          @change="deptTreeDataChange"
          @blur="deptFocus"
          class="project-area-tree"
        />
        <avue-input-tree
          default-expand-all
          placeholder="请选择区域分类"
          v-model="areaTypeId"
          :dic="areaTypeTreeData"
          :props="inputTreeProps"
          size="small"
          :clearable="false"
          @change="areaTypeTreeDataChange"
          @blur="typeFocus"
          class="project-area-tree"
        />
        <avue-input-tree
          default-expand-all
          v-model="areaId"
          placeholder="请选择区域"
          :dic="areaTreeData"
          :props="areaTreeProps"
          size="small"
          :clearable="false"
          @change="projectAreaChange"
          @blur="areaFocus"
          class="project-area-tree"
        />
      </div>
      <div class="videoTopLeft">
        <el-popover
          placement="bottom"
          v-model="addShow"
          ref="addPopover"
          width="340"
          @show="openAddPopover"
          @hide="closeAddPopover"
          trigger="manual">
          <el-form ref="form" :inline="true" :model="boardForm" :rules="boardRules" label-width="80px">
            <el-form-item label="看板名称" prop="boardName">
              <el-input v-model="boardForm.boardName" placeholder="请输入看板名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="addBoard">确定</el-button>
            </el-form-item>
          </el-form>
          <el-button type="primary" size="small" slot="reference"  @click="addShow = true">加入看板</el-button>
        </el-popover>
        <el-popover placement="bottom" width="250" trigger="manual" ref="boardPopover" v-model="boardShow" @show="openBoardPopover"
                    @hide="closeBoardPopover">
          <div class="videoBoardBox">
            <div
              class="videoBoard"
              :style="{color:boardActiveId == item.id?themeColor:''}"
              v-for="(item, index) in boardList"
              :key="index"
              @click="chooseBoard(item, index)"
            >
              {{ item.boardName }} <i class="el-icon-circle-close" style="margin-left: 12px" @click.stop="removeBoard(item)"></i>
            </div>
          </div>
          <el-button type="primary" size="small" slot="reference" style="margin: 0 12px" @click="boardShow = true">查看看板</el-button>
        </el-popover>
        <el-button type="primary" size="mini" icon="icon-quanping" style="padding: 6px" @click="fullVideo = true"></el-button>
      </div>
    </div>
    <div class="videoBig">
      <div class="videoLeft">
        <div class="leftTopGrid">
          <el-button
            :type="item.value == value ? 'primary' : ''"
            v-for="(item, index) in buttonList"
            :key="index"
            size="mini"
            :icon="item.icon"
            @click="chooseBtn(item, index)"
          ></el-button>
          <el-select
            v-model="value"
            placeholder="请选择分屏数量"
            style="margin-left: 5px"
            @change="chooseValue"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="leftListBox">
          <el-radio-group
            v-model="radioBoxGroup"
            size="small"
            @change="chooseRadio"
            v-if="value == '1'"
          >
            <el-radio
              :label="item.id"
              border
              v-for="(item, index) in videoList"
              :key="index"
            >{{ item.name }}
            </el-radio
            >
          </el-radio-group>
          <el-checkbox-group
            v-model="checkBoxGroup"
            size="small"
            @change="chooseCheck"
            v-else
          >
            <el-checkbox
              :label="item.id"
              border
              v-for="(item, index) in videoList"
              :key="index"
            >{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="videoRight">
        <div :class="fullVideo?'videoMainFullBox':'videoMainBox'">
          <el-button type="primary" size="mini" icon="icon-tuichuquanping" v-if="fullVideo == true" style="position: absolute;top: 12px;right: 12px;padding: 6px;z-index: 10000" @click="fullVideo = false"></el-button>
          <div class="videoBox" v-show="value == '1'">
            <div class="oneVideo">
              <video
                class="Video"
                id="video"
                :src="radioUrl"
                autoplay
                controls
                v-show="radioUrl != ''"
              ></video>
              <span v-show="radioUrl == ''" style="line-height: 120px;">暂无播放源</span>
            </div>
          </div>
          <div class="videoBox" v-if="value == '4'">
            <div
              class="fourVideo"
              v-for="(item, index) in checkBoxGroup"
              :key="index"
            >
              <video class="Video" :id="'morevideo' + index" :src="item" autoplay controls></video>
            </div>
            <div
              class="fourVideo"
              v-for="(item, index) in residueNumber"
              :key="index"
            >
              暂无播放源
            </div>
          </div>
          <div class="videoBox" v-if="value == '9'">
            <div
              class="nineVideo"
              v-for="(item, index) in checkBoxGroup"
              :key="index"
            >
              <video class="Video" :id="'morevideo' + index" :src="item" autoplay controls></video>
            </div>
            <div
              class="nineVideo"
              v-for="(item, index) in residueNumber"
              :key="index"
            >
              暂无播放源
            </div>
          </div>
          <div class="videoBox" v-if="value == '12'">
            <div
              class="twelveVideo"
              v-for="(item, index) in checkBoxGroup"
              :key="index"
            >
              <video class="Video" :id="'morevideo' + index" :src="item" autoplay controls></video>
            </div>
            <div
              class="twelveVideo"
              v-for="(item, index) in residueNumber"
              :key="index"
            >
              暂无播放源
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../util/webrtcstreamer";
import "../util/adapter.min";
import {queryProjectAreaCategoryByDeptId} from "@/api/safe/itemList";
import {selectUserChildrenWithoudProject} from "@/api/system/dept";
import {add, getList, remove} from "@/api/desk/cameraBoard";
import {getAreaClassList} from "@/api/safe/projectArea";
import {listByareaId} from "@/api/desk/videoPlayerConfig";
import {mapGetters} from 'vuex';

export default {
  components: {},
  data() {
    return {
      selectVideo: [],
      deptId: "",
      areaTypeId: "",
      areaId: "",
      fullVideo: false,
      inputTreeProps: {
        label: "typeName",
        value: "id",
      },
      areaTreeProps: {
        label: "areaName",
        value: "id",
      },
      deptProps: {
        label: "deptName",
        value: "id",
      },
      deptTreeData: [],
      areaTypeTreeData: [],
      areaTreeData: [],
      treeData: [],
      currentNode: {},
      boardList: [],
      boardActiveId: null,
      boardShow: false,
      addShow:false,
      boardForm:{},
      boardRules:{
        boardName: [
          { required: true, message: '请输入看板名称', trigger: 'blur' }
        ],
      },
      value: "1",
      webRtcServer: null,
      checkBoxGroup: [],
      radioBoxGroup: "",
      radioUrl: "",
      residueNumber: null,
      buttonList: [
        {
          icon: "el-icon-s-platform",
          value: "1",
        },
        {
          icon: "el-icon-menu",
          value: "4",
        },
        {
          icon: "el-icon-s-grid",
          value: "9",
        },
      ],
      videoList: [],
      options: [
        {
          value: "1",
          label: "1分屏",
        },
        {
          value: "4",
          label: "4分屏",
        },
        {
          value: "9",
          label: "9分屏",
        },
        {
          value: "12",
          label: "12分屏",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['themeColor'])
  },
  created() {
    this.initTreeData();
    this.getBoardList();
  },
  mounted() {
    // this.initVideo();
  },
  beforeDestroy() {
    // this.webRtcServer.disconnect();
    this.webRtcServer = null;
  },
  methods: {
    openBoardPopover() {
      document.addEventListener('click', this.hideBoardPopover, false)
    },
    closeBoardPopover() {
      document.removeEventListener('click', this.hideBoardPopover, false)
    },
    hideBoardPopover(e) {
      if (!this.$refs.boardPopover.$el.contains(e.target)) {
        this.boardShow = false
      }
    },
    openAddPopover() {
      document.addEventListener('click', this.hideAddPopover, false)
    },
    closeAddPopover() {
      document.removeEventListener('click', this.hideAddPopover, false)
    },
    hideAddPopover(e) {
      if (!this.$refs.addPopover.$el.contains(e.target)) {
        this.addShow = false
      }
    },
    // 获取看板列表
    getBoardList() {
      getList().then((res) => {
        if (res.data.code == 200) {
          this.boardList = res.data.data;
        }
      });
    },
    // 选择看板
    chooseBoard(item) {
      this.boardActiveId = item.id;
      this.boardShow = false;
      this.deptId = item.deptId;
      this.areaTypeId = item.areaTypeId;
      this.areaId = item.areaId;
      this.value = item.viewType;

      if (this.value == 1) {
        this.radioBoxGroup = item.videoPlayerConfigIds;
        // this.initVideo(this.radioBoxGroup)
      } else {
        this.checkBoxGroup = item.videoPlayerConfigIds.split(',');
      }
    },
    // 删除看板
    removeBoard(item){
      this.$confirm("是否删除此条看板?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        remove(item.id).then((res) =>{
          if (res.data.code == 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getBoardList()
          }
        })
      });
    },
    // 添加看板
    addBoard() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm("是否将当前配置加入看板列表?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let param = {
              deptId: this.deptId,
              areaTypeId: this.areaTypeId,
              areaId: this.areaId,
              viewType: this.value,
              videoPlayerConfigIds: "",
              boardName: this.boardForm.boardName
            };
            if (this.value == 1) {
              param.videoPlayerConfigIds = this.radioBoxGroup
            } else {
              param.videoPlayerConfigIds = this.checkBoxGroup.join(",")
            }
            add(param).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                });
                this.addShow = false;
                this.getBoardList();
              }
            });
          });
        }
      });
    },
    // 获取组织架构数据
    initTreeData() {
      selectUserChildrenWithoudProject().then((res) => {
        if (res.data.code == 200) {
          this.deptTreeData = res.data.data;
        }
      });
    },
    // 获取区域分类数据
    deptTreeDataChange(deptId) {
      if (!deptId) return;
      queryProjectAreaCategoryByDeptId({deptId: deptId}).then((res) => {
        if (res.data.code == 200) {
          this.areaTypeTreeData = res.data.data.areaClass;
        }
      });
    },
    deptFocus() {
      this.restVideoChoose();
      this.areaTypeId = '';
      this.areaId = '';
      this.boardActiveId = null;
    },
    // 获取区域数据
    areaTypeTreeDataChange(typeId) {
      if (!typeId) return;
      getAreaClassList({typeId: typeId}).then((res) => {
        if (res.data.code == 200) {
          this.areaTreeData = res.data.data;
        }
      });
    },
    typeFocus() {
      this.restVideoChoose();
      this.areaId = '';
      this.boardActiveId = null;
    },
    // 获取视频播放列表
    projectAreaChange(id) {
      if (!id) return;
      listByareaId({projectAreaId: id}).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          data.forEach((item) =>{
            item.url = 'rtsp://admin:*****HAOren778@60.174.100.175:554/cam/realmonitor?channel=1&subtype=0&unicast=true'
          })
          this.videoList = data;

          let videoList = JSON.parse(JSON.stringify(this.videoList));
          if (this.value == 1 && this.radioBoxGroup != '') {
            this.radioUrl = videoList.find((item) => (item.id == this.radioBoxGroup)).url;
            this.initVideo(this.radioUrl)
          }
          if (this.value != 1 && this.checkBoxGroup.length != 0){
            let urlList = new Array();
            videoList.forEach((item) =>{
              this.checkBoxGroup.forEach((items) =>{
                if (item.id == items) {
                  urlList.push(item.url)
                }
              })
            })
            this.residueNumber = Number(this.value) - this.checkBoxGroup.length;
            this.initMoreVideo(urlList);
          }
        }
      });
    },
    areaFocus() {
      this.restVideoChoose();
      this.boardActiveId = null;
    },
    restVideoChoose() {
      this.radioBoxGroup = '';
      this.checkBoxGroup = [];
      this.videoList = [];
      this.webRtcServer = null;
      if (this.value == 1) {
        this.radioUrl = '';
      } else if (this.value == 4) {
        this.residueNumber = 4;
      } else if (this.value == 9) {
        this.residueNumber = 9;
      } else if (this.value == 12) {
        this.residueNumber = 12;
      }
    },
    // 初始化一分屏
    initVideo(e) {
      this.webRtcServer = null;
      this.webRtcServer = new WebRtcStreamer(
        "video",
        location.protocol + "//113.201.213.122:8000"
      );
      this.webRtcServer.connect(e);
    },
    initMoreVideo(e) {
      this.webRtcServer = null;
      e.forEach((item,index) =>{
        this.webRtcServer = new WebRtcStreamer(
          "morevideo" + index,
          location.protocol + "//113.201.213.122:8000"
        );
        this.webRtcServer.connect(item);
      })
    },
    chooseBtn(item) {
      this.value = item.value;
      this.residueNumber = Number(this.value) - this.checkBoxGroup.length;
    },
    chooseCheck(e) {
      let videoList = JSON.parse(JSON.stringify(this.videoList));
      let urlList = new Array();
      videoList.forEach((item) =>{
        e.forEach((items) =>{
          if (item.id == items) {
            urlList.push(item.url)
          }
        })
      })
      this.residueNumber = Number(this.value) - e.length;
      this.initMoreVideo(urlList);
    },
    chooseRadio(e) {
      let videoList = JSON.parse(JSON.stringify(this.videoList));
      this.radioUrl = videoList.find((item) => (item.id == e)).url;
      this.initVideo(this.radioUrl)
    },
    chooseValue(e) {
      this.residueNumber = e - this.checkBoxGroup.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.videoMain {
  width: 100%;
  height: 100%;
}

.videoTopBox {
  width: calc(100% - 12px);
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 6px 6px 0 6px;
}

.videoTopLeft {
  display: flex;
}

.videoBoardBox {
  width: 100%;
  padding: 6px;
}

.videoBoard {
  width: calc(100% - 12px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  font-size: 18px;
  padding: 0 6px;
}

.videoBoard:hover {
  background-color: #F2F6FC;
}

.videoBig {
  width: calc(100% - 12px);
  height: calc(100% - 52px);
  display: flex;
  background-color: #ffffff;
  padding: 6px;
}

.videoLeft {
  width: 280px;
  border-right: 1px solid rgb(204, 204, 204);
}

.leftTopGrid {
  width: calc(100% - 18px);
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 6px;
  border-radius: 4px;
  margin-bottom: 6px;
}

.leftTopGrid .el-button {
  font-size: 20px;
  padding: 4px;
}

.leftListBox {
  width: calc(100% - 20px);
  height: calc(100% - 64px);
  overflow: auto;
  padding: 6px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
}

.el-checkbox {
  width: 100%;
  margin: 0 0 8px 0 !important;
}

.el-radio-group {
  width: 100%;
}

.el-radio {
  width: 100%;
  margin: 0 0 8px 0 !important;
}

.videoRight {
  width: calc(100% - 281px);
}

.videoMainBox {
  width: calc(100% - 20px);
  height: calc(100% - 14px);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 6px;
  margin-left: 6px;
}

.videoMainFullBox {
  width: calc(100vw - 12px);
  height: calc(100vh - 14px);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 6px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #FFFFFF;
}

.Video {
  width: 100%;
  height: 100%;
}

.videoBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.oneVideo {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #333333;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}

.fourVideo {
  width: calc(50% - 13px);
  height: calc(50% - 14px);
  border-radius: 4px;
  background-color: #333333;
  padding: 6px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}

.nineVideo {
  width: calc(33.3% - 13px);
  height: calc(33.3% - 14px);
  border-radius: 4px;
  background-color: #333333;
  padding: 6px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}

.twelveVideo {
  width: calc(33.3% - 13px);
  height: calc(25% - 14px);
  border-radius: 4px;
  background-color: #333333;
  padding: 6px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}

.project-area-tree {
  width: 200px;
  margin-right: 12px;
}

.top-div {
  background-color: #fff;
  margin-left: 5px;
  padding-top: 10px;
}
</style>
