<template>
  <div style="position: relative">
    <el-container>
      <!-- <CommonTree
        ref="commonTreeCore"
        treeTitle="组织架构"
        :searchTitle="searchTitle"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @getNodeClick="handleNodeClick"
        :isShowdig="false"
        :showCheckbox="false"
        node-key="id"
        :isZoom="true"
        :show="show"
        @showChange="showChange"
      /> -->
      <CommonTreeNew
        ref="commonTreeCore"
        treeTitle="组织架构"
        :defaultProps="defaultProps"
        :showCheckbox="false"
        @getNodeClick="handleNodeClick"
        :urlParmas="{
          tenantId: '',
          deptCategory: '2',
          parentId: userInfo.dept_id,
        }"
        node-key="id"
        :isShowdig="false"
        :isZoom="true"
        :show="show"
        @showChange="showChange"
      ></CommonTreeNew>
      <el-main>
        <head-layout
          head-title="安全费用"
          :head-btn-options="headBtnOptions"
          @head-add="handleHeadAdd"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          :editType="inline"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @row-view="rowView"
          @row-edit="rowEdit"
          @row-del="rowRemove"
        >
          <template #costAim="{ row }">
            <el-link type="primary" @click="rowView(row)">{{
              row.costAim
            }}</el-link>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// 安全会议
import CommonTree from "@/views/components/com_tree/index";
// import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { costPage, costRemove } from "@/api/responsibility/securityCost";
import { mapGetters } from "vuex";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import { getDeptTree } from "@/api/system/dept";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    UserDetpDialog,
  },

  data() {
    return {
      nodeData: {},
      treeData: [],
      searchTitle: "title",
      defaultProps: {
        children: "children",
        label: "title",
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      show: false,
    };
  },

  created() {
    // this.lazyDeptTree();
  },

  computed: {
    ...mapGetters(["permission", "colorName", "userInfo"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.securityCost_add, true),
        viewBtn: this.vaildData(this.permission.securityCost_view, true),
        editBtn: this.vaildData(this.permission.securityCost_edit, true),
        delBtn: this.vaildData(this.permission.securityCost_delete, true),
      };
    },
    searchColumns() {
      return [
        {
          prop: "costAim",
          placeholder: "费用类别",
          span: 4,
        },
        {
          prop: "costDate",
          placeholder: "支出时间",
          span: 4,
          type: "date-format",
        },
      ];
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: "add",
      });
      return buttonBtn;
    },

    gridRowBtn() {
      let buttonBtn = [];
      if (this.permissionList.viewBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-del",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    tableOptions() {
      return {
        menuFixed: "right",
        customRowAddBtn: false,
        menuWidth: 260,
        selection: false,
        column: [
          {
            label: "费用类别",
            prop: "costAim",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "费用金额",
            prop: "cost",
            formatter: (row, value, column) => {
              return new Intl.NumberFormat("en-US").format(value);
            },
            align: "right",
          },
          {
            label: "支出时间",
            prop: "costDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "登记人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },

          {
            label: "登记时间",
            prop: "createTime",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },

  methods: {
    showChange(v) {
      this.show = v;
    },
    /** 左侧列表操作 */
    //获取树数据
    // lazyDeptTree() {
    //   const parentId = this.userInfo.dept_id;
    //   getDeptTree('', '', parentId).then((res) => {
    //       if (res.data.code == 200) {
    //           this.treeData = res.data.data
    //           if (res.data.data.length > 0) {
    //             this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(this.treeData[0].id)
    //               this.handleNodeClick(this.treeData[0])
    //           }
    //       }
    //   });
    // },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.page.currentPage = 1;
      this.query.belongOrgId = data.id;
      this.onLoad(this.page);
    },

    /**----end---- */
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      costPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },

    rowEdit(row, index) {
      this.$router.push({
        path: "/business/responsibility/securityCost/edit",
        query: {
          row: JSON.stringify({ id: row.id }),
          type: "edit",
        },
      });
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        costRemove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    rowSave(row, index) {},
    rowView(row) {
      this.$router.push({
        path: "/business/responsibility/securityCost/view",
        query: {
          row: JSON.stringify({ id: row.id }),
          type: "view",
        },
      });
    },

    handleHeadAdd() {
      if (this.nodeData.id) {
        this.$router.push({
          path: "/business/responsibility/securityCost/add",
          query: {
            row: JSON.stringify({ belongOrgId: this.nodeData.id }),
            type: "add",
          },
        });
      } else {
        this.$message.warning("请先选择左侧组织机构");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-name {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
</style>
