<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-07 15:51:56
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-05-17 15:46:37
 * @Description: 
-->
<template>
  <div class="evaluate">
    <el-tabs type="border-card" @tab-click="handleClick" v-model="activeName">
      <el-tab-pane label="排放记录" name="pollutionRecord"> </el-tab-pane>
      <el-tab-pane label="排污许可证" name="pollutionPermit"> </el-tab-pane>
    </el-tabs>
    <pollutionRecord
      v-show="activeName == 'pollutionRecord'"
      ref="pollutionRecord"
    ></pollutionRecord>
    <pollutionPermit v-show="activeName == 'pollutionPermit'" ref="pollutionPermit"></pollutionPermit>
  </div>
</template>

<script>
import pollutionRecord from "./pollutionRecord/index.vue";
import pollutionPermit from "./pollutionPermit/index.vue";
export default {
  components: {
    pollutionRecord,
    pollutionPermit,
  },
  data() {
    return {
      activeName: "pollutionRecord",
    };
  },
  mounted() {
    this.handleClick()
  },
  methods: {
    handleClick() {
      this.$refs[this.activeName].init();
    },
  },
};
</script>

<style lang="scss" scoped></style>
