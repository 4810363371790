var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-box riskIdentification" },
    [
      _c("div", { staticClass: "flex-container" }, [
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c("head-layout", {
              attrs: {
                "head-btn-options": _vm.headBtnOptions,
                "head-title": "当前盒子信息",
              },
              on: {
                "head-cancel": _vm.headCancel,
                "head-save": _vm.headSave,
                "head-edit": _vm.headEdit,
              },
            }),
            _c(
              "el-form",
              {
                ref: "dataForm",
                staticClass: "form-base",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "160px",
                  disabled: _vm.formType == "view",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "编码", prop: "code" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写编码" },
                          model: {
                            value: _vm.dataForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "code", $$v)
                            },
                            expression: "dataForm.code",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "名称", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写名称" },
                          model: {
                            value: _vm.dataForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "name", $$v)
                            },
                            expression: "dataForm.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "类型", prop: "type" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择类型" },
                            model: {
                              value: _vm.dataForm.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "type", $$v)
                              },
                              expression: "dataForm.type",
                            },
                          },
                          _vm._l(_vm.NODE_TYPES_list, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.name, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写备注" },
                          model: {
                            value: _vm.dataForm.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "remark", $$v)
                            },
                            expression: "dataForm.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("head-layout", { attrs: { "head-title": "设备信息" } }),
            _c(
              "el-form",
              {
                ref: "dataForm",
                staticClass: "form-base",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "160px",
                  disabled: _vm.formType == "view",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "IP", prop: "ip" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写IP" },
                          model: {
                            value: _vm.dataForm.ip,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "ip", $$v)
                            },
                            expression: "dataForm.ip",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "端口", prop: "port" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写端口" },
                          model: {
                            value: _vm.dataForm.port,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "port", $$v)
                            },
                            expression: "dataForm.port",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "MAC", prop: "macAddress" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写MAC" },
                          model: {
                            value: _vm.dataForm.macAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "macAddress", $$v)
                            },
                            expression: "dataForm.macAddress",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "操作系统", prop: "os" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写操作系统" },
                          model: {
                            value: _vm.dataForm.os,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "os", $$v)
                            },
                            expression: "dataForm.os",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "内存（MB）", prop: "memory" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写内存（MB）" },
                          model: {
                            value: _vm.dataForm.memory,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "memory", $$v)
                            },
                            expression: "dataForm.memory",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "硬盘内容（GB）", prop: "hardDiskCap" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写硬盘内容（GB）" },
                          model: {
                            value: _vm.dataForm.hardDiskCap,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "hardDiskCap", $$v)
                            },
                            expression: "dataForm.hardDiskCap",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "CPU核心数", prop: "cpu" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写CPU核心数" },
                          model: {
                            value: _vm.dataForm.cpu,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "cpu", $$v)
                            },
                            expression: "dataForm.cpu",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "GPU", prop: "gpu" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写GPU" },
                          model: {
                            value: _vm.dataForm.gpu,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "gpu", $$v)
                            },
                            expression: "dataForm.gpu",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "NPU", prop: "npu" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写NPU" },
                          model: {
                            value: _vm.dataForm.npu,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "npu", $$v)
                            },
                            expression: "dataForm.npu",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.jcxflModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "评估单元及风险点",
                visible: _vm.jcxflModel,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.jcxflModel = $event
                },
              },
            },
            [
              _c("avue-form", {
                attrs: { option: _vm.jcxflOption },
                on: {
                  "reset-change": _vm.resetChange,
                  submit: _vm.handleSubmit,
                },
                model: {
                  value: _vm.jcxflForm,
                  callback: function ($$v) {
                    _vm.jcxflForm = $$v
                  },
                  expression: "jcxflForm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }