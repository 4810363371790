var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flow-form",
    },
    [
      _c("div", { staticClass: "com-title" }, [
        _c("h1", [_vm._v("销售订单")]),
        _c("span", { staticClass: "number" }, [
          _vm._v("流程编码：" + _vm._s(_vm.dataForm.billNo)),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "100px",
            disabled: _vm.setting.readonly,
          },
        },
        [
          _c(
            "el-row",
            [
              _vm.judgeShow("flowTitle")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "流程标题", prop: "flowTitle" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "流程标题",
                              disabled: _vm.judgeWrite("flowTitle"),
                            },
                            model: {
                              value: _vm.dataForm.flowTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "flowTitle", $$v)
                              },
                              expression: "dataForm.flowTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("customerName")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称", prop: "customerName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "客户名称",
                              disabled: _vm.judgeWrite("customerName"),
                            },
                            model: {
                              value: _vm.dataForm.customerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "customerName", $$v)
                              },
                              expression: "dataForm.customerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("salesDate")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开单日期", prop: "salesDate" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期",
                              "value-format": "timestamp",
                              format: "yyyy-MM-dd HH:mm",
                              editable: false,
                              disabled: _vm.judgeWrite("salesDate"),
                            },
                            model: {
                              value: _vm.dataForm.salesDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "salesDate", $$v)
                              },
                              expression: "dataForm.salesDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("contacts")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人员", prop: "contacts" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "联系人员",
                              disabled: _vm.judgeWrite("contacts"),
                            },
                            model: {
                              value: _vm.dataForm.contacts,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "contacts", $$v)
                              },
                              expression: "dataForm.contacts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("contactPhone")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "contactPhone" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "联系电话",
                              disabled: _vm.judgeWrite("contactPhone"),
                            },
                            model: {
                              value: _vm.dataForm.contactPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "contactPhone", $$v)
                              },
                              expression: "dataForm.contactPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("customerAddres")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "客户地址", prop: "customerAddres" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "客户地址",
                              disabled: _vm.judgeWrite("customerAddres"),
                            },
                            model: {
                              value: _vm.dataForm.customerAddres,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "customerAddres", $$v)
                              },
                              expression: "dataForm.customerAddres",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("salesman")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务人员", prop: "salesman" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "业务人员",
                              disabled: _vm.judgeWrite("salesman"),
                            },
                            model: {
                              value: _vm.dataForm.salesman,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "salesman", $$v)
                              },
                              expression: "dataForm.salesman",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("ticketNum")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发票编码", prop: "ticketNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "发票编码",
                              disabled: _vm.judgeWrite("ticketNum"),
                            },
                            model: {
                              value: _vm.dataForm.ticketNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "ticketNum", $$v)
                              },
                              expression: "dataForm.ticketNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("paymentMethod")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "付款方式", prop: "paymentMethod" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "付款方式",
                              disabled: _vm.judgeWrite("paymentMethod"),
                            },
                            model: {
                              value: _vm.dataForm.paymentMethod,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "paymentMethod", $$v)
                              },
                              expression: "dataForm.paymentMethod",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("invoiceType")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发票类型", prop: "invoiceType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled: _vm.judgeWrite("invoiceType"),
                              },
                              model: {
                                value: _vm.dataForm.invoiceType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "invoiceType", $$v)
                                },
                                expression: "dataForm.invoiceType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "普通发票" } }, [
                                _vm._v("普通发票"),
                              ]),
                              _c("el-radio", { attrs: { label: "专业发票" } }, [
                                _vm._v("专业发票"),
                              ]),
                              _c("el-radio", { attrs: { label: "其他" } }, [
                                _vm._v("其他"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("paymentMoney")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "付款金额", prop: "paymentMoney" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "付款金额",
                              type: "number",
                              disabled: _vm.judgeWrite("paymentMoney"),
                            },
                            model: {
                              value: _vm.dataForm.paymentMoney,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "paymentMoney", $$v)
                              },
                              expression: "dataForm.paymentMoney",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("flowTfileJsonitle")
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "相关附件", prop: "fileJson" } },
                        [
                          _c("JNPF-UploadFz", {
                            attrs: {
                              type: "workFlow",
                              disabled: _vm.judgeWrite("fileJson"),
                            },
                            model: {
                              value: _vm.fileList,
                              callback: function ($$v) {
                                _vm.fileList = $$v
                              },
                              expression: "fileList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("description")
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开单备注", prop: "description" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "开单备注",
                              type: "textarea",
                              rows: 3,
                              disabled: _vm.judgeWrite("description"),
                            },
                            model: {
                              value: _vm.dataForm.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "description", $$v)
                              },
                              expression: "dataForm.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.judgeShow("entryList")
        ? [
            _vm._m(0),
            _c(
              "el-table",
              { attrs: { data: _vm.dataForm.entryList, size: "mini" } },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    width: "50",
                    label: "序号",
                    align: "center",
                  },
                }),
                _vm.judgeShow("entryList-goodsName")
                  ? _c(
                      "el-table-column",
                      {
                        attrs: { prop: "goodsName", label: "商品名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.judgeWrite("entryList") ||
                                        _vm.judgeWrite("entryList-goodsName"),
                                    },
                                    model: {
                                      value: scope.row.goodsName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "goodsName", $$v)
                                      },
                                      expression: "scope.row.goodsName",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          768202398
                        ),
                      },
                      [
                        _vm.judgeRequired("entryList-goodsName")
                          ? _c("template", { slot: "header" }, [
                              _c("span", { staticClass: "required-sign" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v("商品名称\n        "),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.judgeShow("entryList-specifications")
                  ? _c(
                      "el-table-column",
                      {
                        attrs: { prop: "specifications", label: "规格型号" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.judgeWrite("entryList") ||
                                        _vm.judgeWrite(
                                          "entryList-specifications"
                                        ),
                                    },
                                    model: {
                                      value: scope.row.specifications,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "specifications",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.specifications",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1896845438
                        ),
                      },
                      [
                        _vm.judgeRequired("entryList-specifications")
                          ? _c("template", { slot: "header" }, [
                              _c("span", { staticClass: "required-sign" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v("规格型号\n        "),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.judgeShow("entryList-unit")
                  ? _c(
                      "el-table-column",
                      {
                        attrs: { prop: "unit", label: "单位" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.judgeWrite("entryList") ||
                                        _vm.judgeWrite("entryList-unit"),
                                    },
                                    model: {
                                      value: scope.row.unit,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "unit", $$v)
                                      },
                                      expression: "scope.row.unit",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2260796286
                        ),
                      },
                      [
                        _vm.judgeRequired("entryList-unit")
                          ? _c("template", { slot: "header" }, [
                              _c("span", { staticClass: "required-sign" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v("单位\n        "),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.judgeShow("entryList-qty")
                  ? _c(
                      "el-table-column",
                      {
                        attrs: { prop: "qty", label: "数量" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      disabled:
                                        _vm.judgeWrite("entryList") ||
                                        _vm.judgeWrite("entryList-qty"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.count(scope.row)
                                      },
                                    },
                                    model: {
                                      value: scope.row.qty,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "qty", $$v)
                                      },
                                      expression: "scope.row.qty",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2591148853
                        ),
                      },
                      [
                        _vm.judgeRequired("entryList-qty")
                          ? _c("template", { slot: "header" }, [
                              _c("span", { staticClass: "required-sign" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v("数量\n        "),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.judgeShow("entryList-price")
                  ? _c(
                      "el-table-column",
                      {
                        attrs: { prop: "price", label: "单价" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      disabled:
                                        _vm.judgeWrite("entryList") ||
                                        _vm.judgeWrite("entryList-price"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.count(scope.row)
                                      },
                                    },
                                    model: {
                                      value: scope.row.price,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "price", $$v)
                                      },
                                      expression: "scope.row.price",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1946565781
                        ),
                      },
                      [
                        _vm.judgeRequired("entryList-price")
                          ? _c("template", { slot: "header" }, [
                              _c("span", { staticClass: "required-sign" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v("单价\n        "),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.judgeShow("entryList-amount")
                  ? _c(
                      "el-table-column",
                      {
                        attrs: { prop: "amount", label: "金额" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      readonly: "",
                                      disabled:
                                        _vm.judgeWrite("entryList") ||
                                        _vm.judgeWrite("entryList-amount"),
                                    },
                                    model: {
                                      value: scope.row.amount,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "amount", $$v)
                                      },
                                      expression: "scope.row.amount",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1314826542
                        ),
                      },
                      [
                        _vm.judgeRequired("entryList-amount")
                          ? _c("template", { slot: "header" }, [
                              _c("span", { staticClass: "required-sign" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v("金额\n        "),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.judgeShow("entryList-description")
                  ? _c(
                      "el-table-column",
                      {
                        attrs: { prop: "description", label: "备注" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.judgeWrite("entryList") ||
                                        _vm.judgeWrite("entryList-description"),
                                    },
                                    model: {
                                      value: scope.row.description,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "description", $$v)
                                      },
                                      expression: "scope.row.description",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          698039038
                        ),
                      },
                      [
                        _vm.judgeRequired("entryList-description")
                          ? _c("template", { slot: "header" }, [
                              _c("span", { staticClass: "required-sign" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v("备注\n        "),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                !_vm.setting.readonly && !_vm.judgeWrite("entryList")
                  ? _c("el-table-column", {
                      attrs: { label: "操作", width: "50" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "JNPF-table-delBtn",
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDel(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2079021643
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            !_vm.setting.readonly && !_vm.judgeWrite("entryList")
              ? _c(
                  "div",
                  {
                    staticClass: "table-actions",
                    on: {
                      click: function ($event) {
                        return _vm.addHandle()
                      },
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          icon: "el-icon-plus",
                          disabled: _vm.setting.readonly,
                        },
                      },
                      [_vm._v("新增")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "JNPF-common-title" }, [
      _c("h2", [_vm._v("销售明细")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }