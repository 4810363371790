var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTreeNew", {
            ref: "commonTree",
            attrs: {
              treeTitle: "项目架构",
              defaultProps: _vm.Props,
              showCheckbox: false,
              urlParmas: {
                tenantId: "",
                deptCategory: "",
                parentId: _vm.userInfo.deptId,
              },
              "node-key": "id",
            },
            on: {
              getNodeClick: _vm.treeNodeClick,
              getTreeData: _vm.commonTreeData,
            },
          }),
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-title": "指标数据",
                      "head-btn-options": _vm.headBtnOptions,
                    },
                    on: { "head-add": _vm.headAddDialog },
                  }),
                  _c("div", { staticClass: "searchTopBox" }, [
                    _c("div", { staticClass: "leftSearch" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "margin-bottom": "6px",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "searchBoxcc",
                              staticStyle: {},
                              attrs: {
                                size: "mini",
                                placeholder: "请选择组织数据查看形式",
                                clearable: false,
                              },
                              model: {
                                value: _vm.selectValue,
                                callback: function ($$v) {
                                  _vm.selectValue = $$v
                                },
                                expression: "selectValue",
                              },
                            },
                            _vm._l(_vm.selectOption, function (item) {
                              return _c("el-option", {
                                key: item.opationValue,
                                attrs: {
                                  label: item.opationLabel,
                                  value: item.opationValue,
                                },
                              })
                            }),
                            1
                          ),
                          _c("el-input", {
                            staticClass: "searchBox",
                            staticStyle: {},
                            attrs: {
                              size: "mini",
                              placeholder: "请输入指标编号",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.indicatorKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "indicatorKey", $$v)
                              },
                              expression: "searchForm.indicatorKey",
                            },
                          }),
                          _c("el-input", {
                            staticClass: "searchBox",
                            attrs: {
                              size: "mini",
                              placeholder: "请输入指标名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.indicatorName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "indicatorName", $$v)
                              },
                              expression: "searchForm.indicatorName",
                            },
                          }),
                          _c("el-input", {
                            staticClass: "searchBox",
                            attrs: {
                              size: "mini",
                              placeholder: "请输入指标子类",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.orgName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "orgName", $$v)
                              },
                              expression: "searchForm.orgName",
                            },
                          }),
                          _c("el-input", {
                            staticClass: "searchBox",
                            attrs: {
                              size: "mini",
                              placeholder: "请输入指标年份",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "year", $$v)
                              },
                              expression: "searchForm.year",
                            },
                          }),
                          _c("el-input", {
                            staticClass: "searchBox",
                            attrs: {
                              size: "mini",
                              placeholder: "请输入指标月份",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.month,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "month", $$v)
                              },
                              expression: "searchForm.month",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "rightSearch" },
                      [
                        _c(
                          "el-button-group",
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.gridHeadSearch },
                            }),
                            _c("el-button", {
                              staticStyle: { height: "28px" },
                              attrs: {
                                size: "mini",
                                icon: "reset-refresh icon-refresh",
                              },
                              on: { click: _vm.gridHeadEmpty },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    attrs: {
                      "data-total": _vm.page.total,
                      page: _vm.page,
                      tableOptions: _vm.tableOptions,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                    },
                    on: {
                      "page-size-change": _vm.onLoad,
                      "page-current-change": _vm.onLoad,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "indicatorKey",
                        fn: function ({ row, index }) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowView(row, index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.indicatorKey))]
                            ),
                          ]
                        },
                      },
                      {
                        key: "customBtn",
                        fn: function ({ row, index }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowCopy(row, index)
                                  },
                                },
                              },
                              [_vm._v("\n              复制\n            ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowEdit(row, index)
                                  },
                                },
                              },
                              [_vm._v("\n              编辑\n            ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowRemove(row, index)
                                  },
                                },
                              },
                              [_vm._v("\n              删除\n            ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dialogVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: this.dialogTitle,
                    visible: _vm.dialogVisible,
                    width: "70%",
                    hight: "140vh",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.form, rules: _vm.rules },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "指标编码:",
                                    "label-width": "150px",
                                    prop: "indicatorKey",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "20",
                                      clearable: "",
                                      disabled:
                                        this.type == "view" ||
                                        this.type == "copy" ||
                                        this.type == "edit",
                                    },
                                    model: {
                                      value: _vm.form.indicatorKey,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "indicatorKey", $$v)
                                      },
                                      expression: "form.indicatorKey",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 指标编码:"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "指标名称:",
                                    "label-width": "150px",
                                    prop: "indicatorName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "255",
                                      clearable: "",
                                      disabled: this.type == "view",
                                    },
                                    model: {
                                      value: _vm.form.indicatorName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "indicatorName", $$v)
                                      },
                                      expression: "form.indicatorName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "组织:",
                                    "label-width": "150px",
                                    prop: "companyName",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择组织",
                                        disabled:
                                          this.type == "view" ||
                                          this.type == "edit",
                                      },
                                      model: {
                                        value: _vm.form.companyName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "companyName", $$v)
                                        },
                                        expression: "form.companyName",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-option",
                                        {
                                          attrs: {
                                            value: _vm.treeDataValue,
                                            label: _vm.treeDataLabel,
                                          },
                                        },
                                        [
                                          _c("el-tree", {
                                            ref: "tree",
                                            attrs: {
                                              data: this.deptDicData,
                                              "node-key": "id",
                                              "highlight-current": "",
                                              props: _vm.defaultProps,
                                            },
                                            on: {
                                              "node-click": _vm.handleNodeClick,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "label",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 组织:"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "统计年份",
                                    "label-width": "150px",
                                    prop: "year",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      disabled:
                                        this.type == "view" ||
                                        this.type == "edit",
                                    },
                                    model: {
                                      value: _vm.form.year,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "year", $$v)
                                      },
                                      expression: "form.year",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 统计年份:"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "统计月份:",
                                    "label-width": "150px",
                                    prop: "month",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      disabled:
                                        this.type == "view" ||
                                        this.type == "edit",
                                    },
                                    model: {
                                      value: _vm.form.month,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "month", $$v)
                                      },
                                      expression: "form.month",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "指标子类:",
                                    "label-width": "150px",
                                    prop: "orgName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      disabled:
                                        this.type == "view" ||
                                        this.type == "edit",
                                    },
                                    model: {
                                      value: _vm.form.orgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "orgName", $$v)
                                      },
                                      expression: "form.orgName",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 指标子类:"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "指标值:",
                                    "label-width": "150px",
                                    prop: "value",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      disabled: this.type == "view",
                                    },
                                    model: {
                                      value: _vm.form.value,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "value", $$v)
                                      },
                                      expression: "form.value",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 指标值:"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.type != "view",
                              expression: "this.type != 'view'",
                            },
                          ],
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.confirmDialog },
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }