<template>
    <el-dialog
      title="安全巡检执行统计"
      :visible.sync="dialogShow"
      width="1200px"
      class="inspection-dialog"
      v-if="dialogShow"
    >
        <div class="inspection-dialog-main">
            <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="initData"
            @grid-head-empty="resetData"
            ></grid-head-layout>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="图表" name="first"></el-tab-pane>
                <el-tab-pane label="表示" name="second"></el-tab-pane>
                <el-tab-pane label="巡检记录" name="third"></el-tab-pane>
                <el-tab-pane label="不合格记录" name="fourth"></el-tab-pane>
            </el-tabs>
            <div class="chart-container" id="chart-container" v-show="activeName == 'first'">
                <p class="chart-title">{{timeRange[0]}}至{{timeRange[1]}}一岗双责执行统计</p>
                <p class="child-title">安全检查：{{resObj.sum}}次，<span class="green">隐患整改完成：{{resObj.finishNum}}项，</span><label class="red">待整改总计：{{resObj.unrectifyNum}}项</label></p>
                <div class="chart-main" id="chart-main"></div>
            </div>
            <grid-layout
                v-if="activeName != 'first'"
                :key="activeName"
                ref="gridLayout"
                :tableOptions="tableOptions"
                :tableData="tableData"
                :tableLoading="tableLoading"
                :data-total="page.total"
                :page="page"
                @page-size-change="initData"
                @page-current-change="initData"
                @page-refresh-change="initData"
                @grid-row-click="rowClick"
            >
            <template #customBtn="{ row }">
                <template v-if="row.hiddenDangerId">
                <el-button type="text" size="small" @click.stop="toadd(row,'view')">查看整改记录</el-button>
                <el-button type="text" size="small" @click.stop="toRelevance(row)">修改关联记录</el-button>
                </template>
                <template v-else>
                    <el-button type="text" size="small" @click.stop="toadd(row,'add')">发起违章整改</el-button>
                    <el-button type="text" size="small" @click.stop="toRelevance(row)">关联整改记录</el-button>
                </template>
            </template>
            </grid-layout>
        </div>
        <detail ref="detail"></detail>
        <hiddenList ref="hiddenList" :checkedProject="checkedProject" @bindBack="resetData"></hiddenList>
    </el-dialog>
</template>
<script>
import {hiddenDangerList,inspectionRecord,unqualifiedRecord,hiddenDangerTable} from '@/api/safetyAndEnvironmentalProtection/oneJob'
import {dateFormat} from '@/util/date'
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {mapGetters} from "vuex";
import detail from './detail'
import hiddenList from './hiddenList'
export default {
    components: {
        GridLayout,
        detail,
        hiddenList
    },
    computed:{
        ...mapGetters(["userInfo"]),
    },
    props:{
        checkedProject:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            dialogShow:false,
            activeName:'first',
            timeRange:[],
            resObj:{},
            tableData:[],
            page: {
                pageSize: PAGE_CONSTANT.TWENTY,
                currentPage: 1,
                total: 0
            },
            tableLoading:false,
            secondColumns:[{
                    label: '检查人员',
                    prop: "reportUserName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '检查总数',
                    prop: "sum",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '已整改',
                    prop: "finishNum",
                    align: 'center',
                    overHidden: true,
                },
            ],
            thirdColumns:[{
                    label: '巡检区域',
                    prop: "regionName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '巡检人员',
                    prop: "createUserName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '巡检单位',
                    prop: "createDeptName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '巡检时间',
                    prop: "createTime",
                    align: 'center',
                    overHidden: true,
                },
            ],
            fourthColumns:[{
                    label: '状态',
                    prop: "hdStatus",
                    align: 'center',
                    type: 'select',
                    dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
                    props: {
                        label: 'dictValue',
                        value: 'dictKey',
                    },
                    overHidden: true,
                },{
                    label: '巡检区域',
                    prop: "regionName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '检查点',
                    prop: "inspectionObject",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '巡检人员',
                    prop: "createUserName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '巡检单位',
                    prop: "createDeptName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '巡检时间',
                    prop: "createTime",
                    align: 'center',
                    overHidden: true,
                },
            ],
            tableOptions: {
                customRowAddBtn: false,
                menuWidth: 150,
                selection: false,
                indexLabel: "序号",
                index: true,
                column: [],
                menu:false
            },
            searchColumns:[{
                    placeholder: "请选择统计类型",
                    prop: "type",
                    type: "select",
                    dicData: [{
                        dictValue:'按人员统计',
                        dictKey:1
                    },{
                        dictValue:'按区域统计',
                        dictKey:2
                    }],
                    props: {
                        label: "dictValue",
                        value: "dictKey",
                    },
                    span: 5,
                    change:(val)=>{
                        let index = this.activeName == 'fourth'? 2 : 1
                        if(val.value == 1){
                            this.searchColumns[index].placeholder = '请选择人员'
                            this.searchColumns[index].prop = 'reportUser'
                            this.searchColumns[index].dicUrl = "/api/sinoma-hse-prj/prjbasisareauser/list?prjId="+this.userInfo.dept_id
                            this.searchColumns[index].props = {label:'dutyUserName',value:'dutyUser'}
                            this.secondColumns[0].label = '检查人员'
                            this.secondColumns[0].prop = 'reportUserName'
                            // this.secondColumns.splice(3,2)
                        }else{
                            this.searchColumns[index].placeholder = '请选择区域'
                            this.searchColumns[index].prop = 'area'
                            this.searchColumns[index].dicUrl = "/api/sinoma-hse-prj/prjbasisarea/areaList?prjId="+this.userInfo.dept_id
                            this.searchColumns[index].props = {label:'areaName',value:'areaCode'}
                            this.secondColumns[0].label = '检查区域'
                            this.secondColumns[0].prop = 'area'
                            // this.secondColumns.push({
                            //     label: '不合格项',
                            //     prop: "canceledNum",
                            //     align: 'center',
                            //     overHidden: true,
                            // },{
                            //     label: '待整改',
                            //     prop: "unrectifyNum",
                            //     align: 'center',
                            //     overHidden: true,
                            // })
                        }
                        this.$nextTick(()=>{
                            this.$refs.gridHeadLayout?.initSearch();
                            this.$refs.gridLayout?.headerDragend()
                        })
                    }
                },
                {
                    placeholder: "请选择人员",
                    prop: "reportUser",
                    type: "select",
                    dicUrl: '',
                    dicData: [],
                    props: {
                        label: "dutyUserName",
                        value: "dutyUser",
                    },
                    span: 5,
                },
                {
                    placeholder: "请选择检查日期",
                    prop: "time",
                    type: "daterange",
                    dicData: [],
                    span: 5,
                },]
        }
    },
    methods:{
        init(){
            // this.searchColumns[1].dicUrl = "/api/sinoma-hse-prj/prjbasisarea/page?prjId="+this.userInfo.dept_id
            this.dialogShow = true
            this.$nextTick(()=>{
                this.activeName = 'first'
                if(this.searchColumns.length == 4){
                    this.searchColumns.splice(1,1)
                }
                this.$set(this.$refs.gridHeadLayout.searchForm,'type',1)
                this.$set(this.$refs.gridHeadLayout.searchForm,'time' , this.getLastDate())
                this.getChart()
            })
        },
        toadd(row,type){
            this.$router.push({
                path: `/hiddenTrouble/riskitemView/checklist/edit`,
                query: {
                    type: type,
                    ...type=='add'?{securityInspectionId:row.id}:{id:row.hiddenDangerId}
                },
            });
        },
        toRelevance(row){
            this.$refs.hiddenList.init(row)
        },
        initData(page){
            this.page.pageSize = page.pageSize
            this.page.currentPage = page.currentPage
            this.getChart()
            this.getChartTable(this.activeName)
        },
        resetData(){
            this.page.pageSize = PAGE_CONSTANT.TWENTY
            this.page.currentPage = 1
            this.page.total = 0
            this.$set(this.$refs.gridHeadLayout.searchForm,'type',1)
            this.$set(this.$refs.gridHeadLayout.searchForm,'time' , this.getLastDate())
            this.getChart()
            this.getChartTable(this.activeName)
        },
        handleClick(val){
            console.log(val)
            let active = val._props.name
            if(active == 'fourth'&&this.searchColumns.length != 4){
                this.searchColumns.splice(1,0,{
                    placeholder: "请选择整改状态",
                    prop: "hdStatus",
                    type: "select",
                    dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=hd_status",
                    dicData: [],
                    props: {
                        label: "dictValue",
                        value: "dictKey",
                    },
                    span: 5,
                })
                this.tableOptions.menu = true
                this.$refs.gridHeadLayout.initSearch();
            }else if(active != 'fourth'&&this.searchColumns.length == 4){
                 this.searchColumns.splice(1,1)
                 this.tableOptions.menu = false
            }
            this.page.total = 0
            this.$nextTick(()=>{
                if(active != 'first'){
                    this.tableOptions.column = active == 'second' ? this.secondColumns : active == 'third' ? this.thirdColumns : this.fourthColumns
                    this.getChartTable(active)
                }
            })

        },
        getLastDate(){
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [dateFormat(start,'yyyy-MM-dd'), dateFormat(end,'yyyy-MM-dd')]
        },
        getChart(){
            let {type,reportUser,area,time,hdStatus} = this.$refs.gridHeadLayout.searchForm
            console.log('type:',type)
            this.timeRange = time
            hiddenDangerList({type:type==1?'user':'area',reportUser:reportUser,area:area,startTime:time[0],endTime:time[1],hdStatus:hdStatus,orgId:this.checkedProject}).then(res=>{
                let data = res.data.data
                var myChart = this.$echarts.init(
                    document.getElementById("chart-main")
                );
                this.resObj = data
                var option = {
                    grid: {
                        top: '10px',
                        left: '30px',
                        bottom:'20px',
                        right:0
                    },
                    color:['rgb(116, 142, 222)','rgb(0,211,73)'],
                    xAxis: {
                        type: 'category',
                        data: data.reportList.map(item=>{return type==1?item.reportUserName:item.area}),
                        axisLine: {
                            lineStyle: {
                            color: ['#E8E8E8']
                            }
                        },
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                    },
                    tooltip: {
                        show:true,
                        formatter:'{a0}: {c0}<br />{a1}: {c1}',
                        trigger:'axis'
                    },
                    series:[{
                        data: data.reportList.map(item=>{return item.sum || 0}),
                        type: 'bar',
                        barWidth: 25,
                        name:'安全巡检'
                    },{
                        data: data.reportList.map(item=>{return item.finishNum || 0}),
                        type: 'bar',
                        barWidth: 25,
                        name:'隐患整改完成'
                    }]
                };
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        getChartTable(active){
            let {type,reportUser,area,time,hdStatus} = this.$refs.gridHeadLayout.searchForm
            this.timeRange = time
            this.tableLoading = true
            let url = active == 'second' ? hiddenDangerTable : active == 'third' ? inspectionRecord : unqualifiedRecord
            url({type:type==1?'user':'area',reportUser:reportUser,area:area||'',startTime:time[0],endTime:time[1],
            hdStatus:hdStatus,orgId:this.checkedProject,current:this.page.currentPage,size:this.page.pageSize}).then(res=>{
                let data = res.data.data
                this.tableData = data.records
                this.page.pageSize = data.size
                this.page.currentPage = data.current
                this.page.total = data.total
                this.tableLoading = false
            })
        },
        rowClick(row){
            if(this.activeName == 'third' || this.activeName == 'fourth'){
                this.$refs.detail.init(row.row,this.activeName == 'third'? '巡检记录详情' : '不合格巡检记录详情')
            }
        }

    }
}
</script>
<style scoped lang="scss">
    p{
        margin: 0;
        padding: 0;
    }
    .inspection-dialog{
        .inspection-dialog-main{
            padding: 10px;
            height: 500px;
            .el-tabs{
                margin: 20px 0 10px 0;
            }
        }
    }
    .chart-container{
        text-align: center;
        p{
            margin-bottom: 10px;
        }
        .chart-main{
            height: 300px;
            margin-top: 10px;
        }
    }
    ::v-deep #gridHeadLayout > div{
        padding: 0!important;
    }
     ::v-deep .avue-crud .el-table{
        height: 320px!important;
        max-height: 320px!important;
     }
    .green{
        color: rgb(0,211,73);
    }
    .red{
        color: rgb(255,0,0);
    }
</style>
