<template>
    <div>
    <!-- 高拍仪视频预览区   -->
    <div id="view">
        <img id="view1" src="http://127.0.0.1:38088/video=stream&camidx=0" alt="主摄像头">
        <img id="view2" src="http://127.0.0.1:38088/video=stream&camidx=1" alt="副摄像头">
    </div>
    <!-- 缩略图 -->
    <div id="suoluetu"></div>
    <!-- 功能按钮 -->
    <div id="myactive">
        主摄像头视频模式：<select id="view1_mode" v-model="view1_mode_selected" @change="view1_mode_change(view1_mode_selected)"><option v-for="item in view1_mode_list" :key="item.key" :value="item.key">{{item.value}}</option></select>
        主摄像头分辨率：<select id="view1_resolution_power" v-model="view1_resolution_selected"><option v-for="(item, index) in view1_resolution_list" :key="index" :value="item">{{item}}</option></select>
        副摄像头视频模式：<select id="view2_mode" v-model="view2_mode_selected" @change="view2_mode_change(view2_mode_selected)"><option v-for="item in view2_mode_list" :key="item.key" :value="item.key">{{item.value}}</option></select>
        副摄像头分辨率：<select id="view2_resolution_power" v-model="view2_resolution_selected"><option v-for="(item, index) in view2_resolution_list" :key="index" :value="item">{{item}}</option></select>
        <br>
        <button @click="open_view1">打开主摄像头视频</button>
        <button @click="close_view1">关闭主摄像头视频</button>
        <button @click="open_view2">打开副摄像头视频</button>
        <button @click="close_view2">关闭副摄像头视频</button>
        <button @click="rotate(90)">左转</button>
        <button @click="rotate(270)">右转</button>
        <br>
        <button @click="view1_scan">主摄像头拍照</button>
        <button @click="view2_scan">副摄像头拍照</button>
        <button @click="flat_scan">展平拍照</button>
        <button @click="open_auto_scan">开启自动拍照</button>
        <button @click="close_auto_scan">关闭自动拍照</button>
        <br>
        <button @click="getcode">条码识别</button>
        <button @click="getbiokey">获取指纹</button>
        <button @click="getidcard2">读取身份证</button>
        <button @click="sign_a">弹出签字窗口：GW500A & GW1000A</button>
        <button @click="sign_a_get">获取签名图片：GW500A & GW1000A</button>
        <br>
        <button @click="start_video">开始录制视频</button>
        <button @click="end_video">结束录制视频</button>
        <button @click="get_state_video">获取视频录制状态</button>
        <button @click="get_audio">获取音频设备列表</button>
        <br>
        <button @click="open_a3a4">开启A3A4幅面自动切换：S1840</button>
        <button @click="close_a3a4">关闭A3A4幅面自动切换：S1840</button>
        <br>
        <button @click="add_pdf">拍照并加入PDF队列</button>
        <button @click="save_pdf">生成PDF文件</button>
        <br>
        <button @click="is_living_person">活体检测</button>
        <button @click="person_and_IDCard">人证比对</button>
        <br>
        <button @click="ocr">OCR</button>
        <br>
        <button @click="getequipmenttype">获取设备型号</button>
        <button @click="getsonixserialnumber">获取设备序列号</button>
        <button @click="get_state">获取设备状态</button>
        <button @click="is_con">判断设备是否连接</button>
        <br>
        <button @click="open_serialport">打开串口</button>
        <button @click="close_serialport">关闭串口</button>
        <!-- <button>评价窗口弹出</button> -->
        <button @click="sign_r">签字窗口弹出：GW500R & GW1000R</button>
        <button @click="sign_r_get">获取签名图片: GW500R & GW1000R</button>
    </div>
    <!-- 操作日志 -->
    <div id="mylog">首次打开页面，没有显示出分辨率信息？不要急，刷新下页面就可以了<br>我是操做日志，如果我出现问题，请看控制台信息......</div>
    </div>
</template>
<script>
import axiosOrigin from  'axios';
import {defineComponent, ref} from 'vue'
export default {
    name: "highSpeedCam",
    data() {

    },
    created() {
        // 打开主摄像头
        let open_view1 = ()=>{
            document.getElementById('view1').src = 'http://127.0.0.1:38088/video=stream&camidx=0?1'
            mylog('打开主摄像头成功')
        }

        // 打开副摄像头
        let open_view2 = ()=>{
            document.getElementById('view2').src = 'http://127.0.0.1:38088/video=stream&camidx=1?1'
            mylog('打开副摄像头成功')
        }

        // 加载主摄像头视频模式
        let view1_mode_data1 = { "camidx": "0", "mode": "0" };
        let view1_mode_data2 = { "camidx": "0", "mode": "1" };

        fetch('http://127.0.0.1:38088/device=getresolution', {
            method: 'POST',
            body: JSON.stringify(view1_mode_data2)
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                console.log("data.data.split(\"|\").length:" + data.data.split("|").length)
                if(data.data.split("|").length > 1){
                    let data = new Object()
                    data.key = '1'
                    data.value = 'MJPG'
                    this.view1_mode_list.push(data)
                    console.log(data)

                }
            })

    },
    methods: {
        grabimage() {
            axiosOrigin.post("http://127.0.0.1:38088/video=grabimage", this.msg1)
                .then(response => {
                    this.src1 = "data:image/png;base64," + response.data.photoBase64;
                    console.log(' successfully:', response.data.photoBase64);

                })
                .catch(error => {
                    console.error('Error :', error);
                });
        },
        getidcard() {
            axiosOrigin.post("http://127.0.0.1:38088/card=idcard")
                .then(response => {
                    console.log(' successfully:', response);
                    console.log('身份证UID：' + response.data.IDCardInfo.strIDUID)
                    console.log('身份证附加信息：' + response.data.IDCardInfo.appendMsg)
                    console.log('身份证民族代码：' + response.data.IDCardInfo.nationCode)
                    console.log('身份证性别代码：' + response.data.IDCardInfo.sexCode)
                    console.log('身份证有效终止日期：' + response.data.IDCardInfo.validEnd)
                    console.log('身份证有效起始日期：' + response.data.IDCardInfo.validStart)
                    console.log('身份证发卡机构：' + response.data.IDCardInfo.issueOrgan)
                    console.log('身份证号码：' + response.data.IDCardInfo.cardID)
                    console.log('身份证地址：' + response.data.IDCardInfo.address)
                    console.log('身份证生日：' + response.data.IDCardInfo.birthday)
                    console.log('身份证性别：' + response.data.IDCardInfo.sex)
                    console.log('身份证姓名：' + response.data.IDCardInfo.name)

                })
                .catch(error => {
                    console.error('Error :', error);
                });
        },
        getidcard2() {
            fetch('http://127.0.0.1:38088/card=idcard', {
                method: 'POST'
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    //add_image(res.data.IDCardInfo.photoBase64)
                    //add_image(res.data.IDCardInfo.photoBase64_Z)
                    //add_image(res.data.IDCardInfo.photoBase64_F)

                    let img = document.createElement('img');
                    img.src = "data:image/jpg;base64," + data.IDCardInfo.photoBase64;
                    img.style.width = '80px';
                    img.style.height = '80px';
                    document.getElementById('suoluetu').appendChild(img)

                    let img1 = document.createElement('img1');
                    img1.src = "data:image/jpg;base64," + data.IDCardInfo.photoBase64_Z;
                    img1.style.width = '80px';
                    img1.style.height = '80px';
                    document.getElementById('suoluetu').appendChild(img1)

                    let img2 = document.createElement('img2');
                    img2.src = "data:image/jpg;base64," + data.IDCardInfo.photoBase64_F;
                    img2.style.width = '80px';
                    img2.style.height = '80px';
                    document.getElementById('suoluetu').appendChild(img2)


                    console.log('身份证UID：' + data.IDCardInfo.strIDUID)
                    console.log('身份证附加信息：' + data.IDCardInfo.appendMsg)
                    console.log('身份证民族代码：' + data.IDCardInfo.nationCode)
                    console.log('身份证性别代码：' + data.IDCardInfo.sexCode)
                    console.log('身份证有效终止日期：' + data.IDCardInfo.validEnd)
                    console.log('身份证有效起始日期：' + data.IDCardInfo.validStart)
                    console.log('身份证发卡机构：' + data.IDCardInfo.issueOrgan)
                    console.log('身份证号码：' + data.IDCardInfo.cardID)
                    console.log('身份证地址：' + data.IDCardInfo.address)
                    console.log('身份证生日：' + data.IDCardInfo.birthday)
                    console.log('身份证性别：' + data.IDCardInfo.sex)
                    console.log('身份证姓名：' + data.IDCardInfo.name)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
        // 打印日志
        mylog(val){
            let element = document.getElementById('mylog')
            let old_val = element.innerHTML
            let date = new Date().toString().slice(16, 24)
            element.innerHTML = date + '&nbsp;&nbsp;' + val + '<br>' + old_val;
        },
        // 添加缩略图
        add_image(img_base64){
            let img = document.createElement('img');
            img.src = "data:image/jpg;base64," + img_base64;
            img.style.width = '80px';
            img.style.height = '80px';
            document.getElementById('suoluetu').appendChild(img)
        },
        // 旋转
        rotate(angle){
            let data = {"camidx": '0', "rotate": String(angle)}
            fetch("http://127.0.0.1:38088/video=rotate", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                //mylog("旋转" + String(angle) + "度成功")
                console.log("旋转" + String(angle) + "度成功")
            })
        },

        // 切换主摄像头视频模式，重新加载主摄像头分辨率
        view1_mode_change(val){
            let data = {"camidx": '0', "mode": val}
            fetch("http://127.0.0.1:38088/device=getresolution", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                let resolution_list = data.data.split("|");
                // view1_resolution_selected = resolution_list[0]
                // view1_resolution_list = []
                for(var i=0; i<resolution_list.length; i++){
                    view1_resolution_list.push(resolution_list[i])
                }
            })
        },

        // 切换副摄像头视频模式，重新加载副摄像头分辨率
        view2_mode_change(val){
            let data = {"camidx": '1', "mode": val}
            fetch("http://127.0.0.1:38088/device=getresolution", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                let resolution_list = data.data.split("|");
                //view2_resolution_selected = resolution_list[0]
                //view2_resolution_list = []
                for(var i=0; i<resolution_list.length; i++){
                    view2_resolution_list.push(resolution_list[i])
                }
            })
        },


        // 关闭主摄像头
        close_view1(){
            let data = {"camidx": "0"}
            fetch("http://127.0.0.1:38088/video=close", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                document.getElementById('view1').src = ''
                console.log('关闭主摄像头成功')
            })
        },
        // 关闭副摄像头
        close_view2(){
            let data = {"camidx": "1"}
            fetch("http://127.0.0.1:38088/video=close", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                document.getElementById('view2').src = ''
                console.log('关闭副摄像头成功')
            })
        },
        // 打开主摄像头
        open_view1(){
            let data = {"camidx": "0"}
            fetch("http://127.0.0.1:38088/video=stream&camidx=0?1", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                document.getElementById('view1').src = 'http://127.0.0.1:38088/video=stream&camidx=0?1'
                console.log('打開主摄像头成功')
            })
        },
        // 打开副摄像头
        open_view2(){
            let data = {"camidx": "1"}
            fetch("http://127.0.0.1:38088/video=stream&camidx=1?1", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                document.getElementById('view2').src = 'http://127.0.0.1:38088/video=stream&camidx=1?1'
                console.log('打開副摄像头成功')
            })
        },
        // 主头拍照
        view1_scan(){
            let data = {
                "filepath": "base64",
                "rotate": "0",
                "cutpage": "0",
                "camidx": "0",
                "ColorMode": "0",
                "quality": "3"
            }
            fetch("http://127.0.0.1:38088/video=grabimage", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                //add_image(data.photoBase64)
                let img = document.createElement('img');
                img.src = "data:image/jpg;base64," + data.photoBase64;
                img.style.width = '80px';
                img.style.height = '80px';
                document.getElementById('suoluetu').appendChild(img)
                console.log("主头拍照成功")
                console.log('图片base64： ' + data.photoBase64)
            })
        },

        // 副头拍照
        view2_scan(){
            let data = {
                "filepath": "base64",
                "rotate": "0",
                "cutpage": "0",
                "camidx": "1",
                "ColorMode": "0",
                "quality": "3"
            }
            fetch("http://127.0.0.1:38088/video=grabimage", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                //add_image(data.photoBase64)
                let img = document.createElement('img');
                img.src = "data:image/jpg;base64," + data.photoBase64;
                img.style.width = '80px';
                img.style.height = '80px';
                document.getElementById('suoluetu').appendChild(img)
                console.log("副头拍照成功")
                console.log('图片base64： ' + data.photoBase64)
            })
        },

        // 展平拍照
        flat_scan(){
            let data = {
                "filepath": "",
                "rotate": "0",
                "camidx": "0",
                "cutpage": "0",
                "autoflat": {
                    "flat": "1",
                    "leftfilepath": "D://left.jpg",
                    "rightfilepath": "D://right.jpg",
                    "removefinger": "1",
                    "doublepage": "1"
                }
            }
            fetch("http://127.0.0.1:38088/video=autoflat", {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.json()).then((data)=>{
                //add_image(data.photoBase64)
                //add_image(data.leftphotoBase64)
                //add_image(data.rightphotoBase64)


                let img = document.createElement('img');
                img.src = "data:image/jpg;base64," + data.photoBase64;
                img.style.width = '80px';
                img.style.height = '80px';
                document.getElementById('suoluetu').appendChild(img)


                let img1 = document.createElement('img1');
                img1.src = "data:image/jpg;base64," + data.leftphotoBase64;
                img1.style.width = '80px';
                img1.style.height = '80px';
                document.getElementById('suoluetu').appendChild(img1)

                let img2 = document.createElement('img2');
                img2.src = "data:image/jpg;base64," + data.rightphotoBase64;
                img2.style.width = '80px';
                img2.style.height = '80px';
                document.getElementById('suoluetu').appendChild(img2)

                console.log("展平拍照成功")
            })
        },

        // 获取设备状态
        get_state(){
            fetch("http://127.0.0.1:38088/video=status",{
                method: 'POST'
            }).then(response => response.json()).then((data)=>{
                console.log("提示：no:未连接；ok:已连接；run:已连接且运行")
                console.log("副摄像头：" + data.video1)
                console.log("主摄像头：" + data.video0)
            })
        },

        // 判断设备是否连接
        is_con(){
            fetch("http://127.0.0.1:38088/device=isconnect",{
                method: 'POST'
            }).then(response => response.json()).then((data)=>{
                console.log("设备连接数：" + data.data)
            })
        }
    }
};
</script>

<style scoped>
/* 全局 */
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 10px;
}
/* 视频预览 */
#view1, #view2{
    width: 400px;
    height: 300px;
    border: 1px solid red;
    margin-right: 5px;
}
/* 缩略图 */
#suoluetu{
    width: 100%;
    height: 85px;
    border: 1px solid blue;
}
#suoluetu img{
    margin-right: 10px;
}
/* 操作按钮 */
#myactive{
    border: 1px solid yellowgreen;
    margin-top: 10px;
    padding: 10px 5px;
}
/* 操作日志 */
#mylog{
    border: 1px solid black;
    padding: 10px;
    margin-top: 10px;
    overflow: auto;
}
</style>
