/*
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-07 09:46:08
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-05-23 16:25:15
 * @Description:
 */
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      tableOption: {
        linklabel: "complainCode",
        selectable: (row, index) => {
          return row.status
            ? false
            : row.createUser != this.userInfo.user_id
            ? false
            : true;
        },

        column: [
          {
            label: "记录编号",
            prop: "complainCode",
            align: "left",
            overHidden: true,
            width: 200,
          },
          {
            label: "投诉人",
            prop: "userName",
            align: "left",
            overHidden: true,
          },
          {
            label: "投诉渠道",
            prop: "channel",
            dataType: "string",
            align: "center",
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=ep_complain_channel`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "投诉内容",
            prop: "content",
            align: "center",
            width: 150,
            overHidden: true,
          },
          {
            label: "投诉日期",
            prop: "complainDate",
            align: "center",
            width: 200,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            type: "select",
            // dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
            dicData: [
              { dictValue: "完成", dictKey: 1 }, // FINISHED
              { dictValue: "草稿", dictKey: 0 }, // PREPARE
            ],
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
    };
  },
};
