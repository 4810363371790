var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-container", [
        _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c("head-layout", { attrs: { "head-title": "数据填报" } }),
            _c("grid-head-layout", {
              ref: "gridHeadLayout",
              attrs: { "search-columns": _vm.searchColumns },
              on: {
                "grid-head-search": _vm.gridHeadSearch,
                "grid-head-empty": _vm.gridHeadEmpty,
              },
            }),
            _c(
              "div",
              {
                staticStyle: {
                  padding: "0 12px",
                  "background-color": "#FFFFFF",
                },
              },
              [
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "border-card" },
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c("el-tab-pane", {
                      attrs: { label: "全部", name: "all" },
                    }),
                    _c("el-tab-pane", {
                      attrs: { label: _vm.writeName, name: "write" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("grid-layout", {
              ref: "gridLayOut",
              attrs: {
                "table-options": _vm.tableOption,
                "table-data": _vm.tableData,
                "table-loading": _vm.tableLoading,
                "data-total": _vm.page.total,
                page: _vm.page,
              },
              on: {
                "grid-row-detail-click": _vm.rowView,
                "page-current-change": _vm.onLoad,
                "page-size-change": _vm.onLoad,
              },
              scopedSlots: _vm._u([
                {
                  key: "customBtn",
                  fn: function ({ row }) {
                    return [
                      row.status == 1 || row.status == 3
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { margin: "0 3px" },
                              attrs: { type: "text", size: "medium" },
                              on: {
                                click: function ($event) {
                                  return _vm.rowFill(row)
                                },
                              },
                            },
                            [_vm._v("填报\n          ")]
                          )
                        : _vm._e(),
                      row.status !== 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "medium" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFlow(row)
                                },
                              },
                            },
                            [_vm._v("\n            流程图\n          ")]
                          )
                        : _vm._e(),
                      row.status !== 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "medium" },
                              on: {
                                click: function ($event) {
                                  return _vm.headProgress(row)
                                },
                              },
                            },
                            [_vm._v("\n            流程进度\n          ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm.bpmnVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "70%",
                customClass: "wf-dialog",
                dialogTitle: _vm.$t(`cip.desk.workflow.title.indexHeadVisible`),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.bpmnVisible = false
                },
              },
            },
            [
              _c("wf-design", {
                ref: "bpmn",
                staticStyle: { height: "60vh" },
                attrs: { options: _vm.bpmnOption },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.circulationVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "35%",
                dialogTitle: _vm.$t(
                  `cip.plat.wfOps.process.title.processProgressTitle`
                ),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.circulationVisible = false
                },
              },
            },
            [_c("wf-flow", { attrs: { flow: _vm.flow } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }