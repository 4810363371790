<template>
  <el-main>
    <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
        ref="gridLayOut"
        :table-options="tableOptions"
        :table-data="tableData"
        :table-loading="false"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
        @page-refresh-change="onLoad"
    >
      <template #customBtn="{row}">
        <el-button
            type="text"
            size="small"
            @click="rowConfirm(row)"
        >选择
        </el-button>
      </template>
    </grid-layout>
  </el-main>
</template>
<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {getReceptHistory} from "@/api/siteManagement/warehous";

export default {
  components: { FormLayout, HeadLayout,GridLayout},
  data() {
    return {
      tableData:[],
      isClose: false,
      itemData: [],
      viewFlag: true,
      formLoading: false,
      type: 'view',
      dataForm: {},
      isWasteType:true,
      isInside:false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          prop: "wasteName",
          span: 6,
          maxlength: 20,
          minlength: 2,
          placeholder: '请输入固废名称',
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        selection:false,
        menuWidth:100,
        column: [
          {
            label: '固废名称',
            prop: 'wasteName',
            overHidden: true,
          }
        ],
      }
    },
  },
  methods: {
    rowConfirm(row){
      this.$emit("getSolid",row.wasteName)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getReceptHistory(
          page.currentPage,
          page.pageSize,
          Object.assign(params)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty() {
      this.onLoad(this.page)
    },
    formData(id) {
      detail({id:id}).then(res => {
        this.dataForm = res.data.data;
      })
    }
  },
  mounted() {
    this.onLoad(this.page);
  }
};
</script>

<style scoped lang="scss">
::v-deep .avue-crud .el-table {
  height: calc(100vh - 400px) !important;
  max-height: calc(100vh - 400px) !important;
}
</style>
