<template>
  <div>
    <head-layout head-title="成绩补录" :head-btn-options="headBtnOptions">
    </head-layout>
    <grid-head-layout
      ref="searchForm"
      v-model="query"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset"
    />
    <grid-layout
      ref="gridLayout"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="toView"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-view="toView"
      :searchColumns="searchColumns"
    >
      <template #customBtn="{ row, index }">
        <el-button size="small" type="text" @click.stop="toView(row, index)">
          查看
        </el-button>
        <el-button size="small" type="text" @click.stop="toEdit(row, index)">
          成绩补录
        </el-button>
      </template>
    </grid-layout>
  </div>
</template>

<script>
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import { trainingTaskDelete, trainingTaskPage } from "@/api/safe/index";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import WfFlow from "@/views/plugin/workflow/process/components/flow.vue";
import { mapGetters } from "vuex";
import CommonDialog from "@/components/CommonDialog";
export default {
  components: {
    HeadLayout,
    GridLayout,
    WfFlow,
    CommonDialog,
  },
  props: {},
  mixins: [exForm],
  data() {
    return {
      bpmnOption: {},
      circulationVisible: false,
      bpmnVisible: false,
      query: {},
      total: "",
      dialogtext: "",
      runState: "",
      btnType: "",
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0,
      },
      dialogVisible: false,
      tableLoading: false,
      ExecuteId: "",
      tableData: [],

      searchColumns: [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "培训任务名称",
          prop: "trainTheme",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "状态",
          type: "select",
          prop: "status",
          dicUrl:
            "/api/sinoma-system/dict-biz/dictionary?code=is_training_task_status",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 4,
          dataType: "number",
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "课程名称",
          type: "input",
          prop: "courseName",
          span: 4,
        },
      ],

      tableOptions: {
        selection: false,
        customRowAddBtn: false,
        menuWidth: 250,
        indexLabel: "序号",
        index: true,
        linklabel: "trainTheme",
        column: [
          {
            label: "任务名称",
            prop: "trainTheme",
            align: "left",
            overHidden: true,
          },
          {
            label: "培训时间",
            prop: "trainingTime",
            align: "center",
            width: 240,
            overHidden: true,
          },
          {
            label: "下发时间",
            prop: "updateTime",
            align: "center",
            width: 180,
            overHidden: true,
          },
          {
            label: "通知人数",
            prop: "numberOfNotifiedPersons",
            align: "right",
            width: 80,
            overHidden: true,
          },
          {
            label: "参训人数",
            prop: "numberOfParticipants",
            align: "right",
            width: 80,
            overHidden: true,
          },
          {
            label: "是否考试",
            prop: "isExam",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            width: 80,
            overHidden: true,
          },
          {
            label: "通过人数",
            prop: "passingNumber",
            align: "right",
            width: 80,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            width: 80,
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=is_training_task_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            overHidden: true,
          },
        ],
      },
      trainingEducationStatisticsIndexDrawer: false,
      rowData: {},
    };
  },
  computed: {
    ...mapGetters(["permission", "language"]),
    gridRowBtn() {
      let rowBtn = [];
      rowBtn.push({
        label: this.$t("cip.cmn.btn.viewBtn"),
        emit: "grid-view",
        remark: "view",
        type: "text",
        icon: "",
      });
      return rowBtn;
    },
  },

  methods: {
    searchReset() {
      this.$refs.searchForm.searchForm = {};
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      trainingTaskPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        page.total = data.total;
        this.$refs.gridLayout.page.total = data.total;
        //进行数据构造
        data.records.forEach((e) => {
          e.endTime = e.trainEnd.substring(0, 10);
          e.trainingTime =
            e.trainStart.substring(0, 10) +
            " 至 " +
            e.trainEnd.substring(0, 10);
        });
        this.tableData = data.records;
        this.tableData.forEach((e) => {
          e.processInstanceId = e.taskId;
          e.taskId = null;
        });
        this.tableLoading = false;
      });
    },
    toEdit(item) {
      this.$router.push({
        path: "/business/safetyenment/train/trainRecordScores/edit",
      });
      sessionStorage.setItem("taskId", item.id);
      sessionStorage.setItem("taskType", "edit");
    },
    //查看
    toView(item) {
      this.$router.push({
        path: "/business/safetyenment/train/trainRecordScores/view",
      });
      sessionStorage.setItem("taskId", item.id);
      sessionStorage.setItem("taskType", "view");
    },
  },
  mounted() {
    this.query.courseName = this.$route.query.courseName || "";
    this.$refs.searchForm.searchForm.courseName = this.query.courseName;
    this.onLoad(this.page);
  },
};
</script>
<style lang="scss" scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}

.el-button + .el-button {
  margin-left: 5px !important;
}

::v-deep .bjs-powered-by {
  display: none;
}
</style>
