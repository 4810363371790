<template>
  <div style="height: 100%">
    <div class="videoTopSet">
      <el-button v-for="(item,index) in numberList" :type="activeNumber == index?'primary':''" :key="index"
                 @click="chooseNumber(item,index)">{{ item }} 屏
      </el-button>
      <el-select v-model="videoUrl" ref="Select" placeholder="请选择设备" v-if="activeNumber == 0" @change="chooseOneVideo"
                 style="width: 300px !important;margin: 0 12px">
        <el-option
          v-for="item in videoUrls"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="moreVideoUrl" ref="Select" multiple placeholder="请选择设备" v-if="activeNumber != 0" style="width: 800px !important;margin: 0 12px" @change="chooseVideo">
        <el-option
          v-for="item in videoUrls"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" @click="analysis" v-if="activeNumber != 0">解析</el-button>
      <el-button type="primary" @click="resetting" v-if="activeNumber != 0">重置</el-button>
    </div>
    <div class="videoOne" v-if="activeNumber == 0">
      <video ref="videoPlayer" class="video-player"></video>
    </div>
    <div class="video-container" v-if="activeNumber == 1">
      <div v-for="(url, index) in moreUrlList" :key="index" class="video-wrapper">
        <video :ref="'videoPlayer' + index" class="video-player-two">
        </video>
        <img class="loadingGif" v-if="loadingShow" src="@/assets/images/videoLoading.gif">
      </div>
    </div>
    <div class="video-container-six" v-if="activeNumber == 2">
      <div class="topVideo">
        <div v-for="(url, index) in moreUrlList.slice(0,1)" :key="index" class="bigVideo">
          <video :ref="'videoPlayer' + index" class="video-player-two">
          </video>
          <img class="loadingGif" v-if="loadingShow" src="@/assets/images/videoLoading.gif">
        </div>
        <div style="width: 33.3%;height: 100%;">
          <div v-for="(url, index) in moreUrlList.slice(1,3)" :key="index" class="twoVideo">
            <video :ref="'videoPlayer' + Number(index + 1)" class="video-player-two">
            </video>
            <img class="loadingGif" v-if="loadingShow" src="@/assets/images/videoLoading.gif">
          </div>
        </div>
      </div>
      <div class="bottomVideo">
        <div v-for="(url, index) in moreUrlList.slice(3,6)" :key="index" class="threeVideo">
          <video :ref="'videoPlayer' + Number(index + 3)" class="video-player-two">
          </video>
          <img class="loadingGif" v-if="loadingShow" src="@/assets/images/videoLoading.gif">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js';
import {getList as videoPlayList,get} from "@/api/desk/videoPlay";
export default {
  data() {
    return {
      numberList: [1, 4, 6],
      activeNumber: 0,
      oneData:{},
      videoUrl: '',
      moreVideoUrl: [],
      moreUrlList:[],
      videoUrls: [],
      hlsInstances: [],
      loadingShow:true
    };
  },
  created() {
    this.getDataList();
  },
  beforeDestroy() {
    this.destroyHls();
  },
  methods: {
    chooseVideo(e) {

      if (this.activeNumber == 1) {
        if (e.length > 4) {
          this.$message({
            message: '最多展示四个设备监控信息',
            type: 'warning'
          });
        } else {
          this.moreUrlList = e;
        }
      } else {
        if (e.length > 6) {
          this.$message({
            message: '最多展示六个设备监控信息',
            type: 'warning'
          });
        } else {
          this.moreUrlList = e;
        }
      }
    },
    chooseOneVideo() {
      this.initHls();
    },
    getDataList() {
      videoPlayList().then((res) =>{
        this.videoUrls = res.data.data.records;
        this.videoUrl = this.videoUrls[0].id;
        this.oneData = this.videoUrls[0];
        this.initHls();
      })
    },
    chooseNumber(item, index) {
      this.$refs.Select.selectedLabel = '';
      this.activeNumber = index;
      if (this.activeNumber == 0) {
        this.videoUrl = this.videoUrls[0].id;
        this.oneData = this.videoUrls[index];
        this.initHls();
      } else {
        this.videoUrl = '';
        this.moreUrlList = [];
        this.moreVideoUrl = [];
      }
    },
    analysis() {
      if (this.activeNumber == 1) {
        if (this.moreVideoUrl.length > 4) {
          this.$message({
            message: '最多展示四个设备监控信息',
            type: 'warning'
          });
          return;
        }
      } else {
        if (this.moreVideoUrl.length > 6) {
          this.$message({
            message: '最多展示六个设备监控信息',
            type: 'warning'
          });
          return;
        }
      }
      let list = new Array();
      let urlList = new Array();
      this.videoUrls.forEach((item) =>{
        this.moreVideoUrl.forEach((items) =>{
          if (item.id == items) {
            list.push(item)
          }
        })
      })
      let data = new Promise((resolve) => {
        list.forEach((item) =>{
          get(item).then((res) => {
            urlList.push(res.data.data);
            if (list.length == urlList.length) {
              resolve(urlList);
            }
          })
        })
      });
      Promise.all([data]).then((res) => {
        this.moreUrlList = res[0];
        setTimeout(() =>{
          this.initMoreHls(res[0]);
        },1000)
      })
    },
    resetting() {
      this.videoUrl = '';
      this.moreUrlList = [];
      this.moreVideoUrl = [];
    },
    initHls() {
      get(this.oneData).then((res) =>{
        let rtmpUrl = res.data.data.rtmpUrl;
        if (Hls.isSupported()) {
          this.hls = new Hls();
          this.hls.loadSource(rtmpUrl);
          this.hls.attachMedia(this.$refs.videoPlayer);
          this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            this.$refs.videoPlayer.play();
          });
        } else if (this.$refs.videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
          this.$refs.videoPlayer.src = rtmpUrl;
          this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
            this.$refs.videoPlayer.play();
          });
        }
      })
    },
    initMoreHls(data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(item.rtmpUrl);
          hls.attachMedia(this.$refs['videoPlayer' + i][0]);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            this.$refs['videoPlayer' + i][0].play();
          });
          this.hlsInstances.push(hls);
          this.loadingShow = false;
        } else if (this.$refs['videoPlayer' + i][0].canPlayType('application/vnd.apple.mpegurl')) {
          this.$refs['videoPlayer' + i][0].src = item.rtmpUrl;
          this.$refs['videoPlayer' + i][0].addEventListener('loadedmetadata', () => {
            this.$refs['videoPlayer' + i][0].play();
          });
        }
      }
    },
    destroyHls() {
      for (let i = 0; i < this.hlsInstances.length; i++) {
        this.hlsInstances[i].destroy();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.videoTopSet {
  width: calc(100% - 6px);
  display: flex;
  padding: 6px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.videoNumber {
  width: 70px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #333333;
  background-color: #FFFFFF;
  border: 1px solid #333333;
  border-radius: 5px;
  margin-right: 12px;
  font-size: 12px;
}

.videoOne {
  width: calc(100% - 6px);
  padding: 6px;
  background-color: #FFFFFF;
  height: calc(100% - 60px);
}

.video-container {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 60px);
}

.video-container-six {
  width: 100%;
  height: calc(100% - 48px);
}

.video-wrapper {
  width: calc(50% - 12px);
  height: calc(50% - 6px);
  padding: 6px;
  background-color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.loadingGif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-player-two {
  width: 100%;
  height: 100%;
}

.video-player {
  width: 100%;
  height: 100%;
}

.topVideo {
  width: 100%;
  display: flex;
  height: 66.6%;
}
.bottomVideo {
  width: 100%;
  display: flex;
  height: 33.3%;
}

.twoVideo {
  position: relative;
  width: calc(100% - 12px);
  height: calc(50% - 12px);
  padding: 6px;
  background-color: #FFFFFF;
}

.threeVideo {
  position: relative;
  width: calc(33.3% - 12px);
  height: calc(100% - 12px);
  padding: 6px;
  background-color: #FFFFFF;
}

.bigVideo{
  position: relative;
  width: calc(66.6% - 12px);
  height: calc(100% - 12px);
  padding: 6px;
  background-color: #FFFFFF;
}
</style>
