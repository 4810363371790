<template>
  <div>
   <head-layout  :head-title="$t('cip.dc.DataAggregation.title.name')"  :head-btn-options="headBtnOptions" >
    </head-layout>
    <el-tabs v-model="activeName" @tab-click="handleClick" >
      <el-tab-pane :label="$t('cip.dc.DataAggregation.title.DataAggregationGrouping')" name="first">
        <task1></task1>
      </el-tab-pane>
      <el-tab-pane :label="$t('cip.dc.DataAggregation.title.DataAggregationconfiguration')" name="second">
        <task2></task2>
      </el-tab-pane>
      <el-tab-pane :label="$t('cip.dc.DataAggregation.title.DataAggregationoutput')" name="third">
        <task3></task3>
      </el-tab-pane>
    </el-tabs>
  </div>
  </template>
  <script>
  import task1 from "./grouping.vue"
  import task2 from "./config.vue"
  import task3 from "./zInterfaceAc.vue"
  import eventBus from "../../../../util/eventBus"
  export default {
      data() {
        return {
          activeName: 'first'
        };
      },
      components:{
    task1,task2,task3,
  },
  mounted(){
    // activeName
    if(sessionStorage.getItem("currenthj")){
      this.activeName =  sessionStorage.getItem("currenthj")
    }else{
      this.activeName = 'first'
    }
    
  },
      methods: {
        handleClick(e) {
          if(e.name == "first"){
            eventBus.$emit("huiju1","huiju1")
            sessionStorage.setItem("currenthj","first")
          }
          if(e.name == "second"){
            eventBus.$emit("huiju2","huiju2")
            sessionStorage.setItem("currenthj","second")
          }
          if(e.name == "third"){
            eventBus.$emit("huiju3","huiju3")
            sessionStorage.setItem("currenthj","third")
          }
        }
      }
    };
  </script>
  <style scoped>
  ::v-deep .el-tabs__header{
    margin: 0;
    background: #FFFFFF;
    padding: 0 12px;
  }
  </style>