<template>
  <div class="main-box riskIdentification">
    <div class="flex-container">
      <div class="table-box">
        <head-layout :head-btn-options="headBtnOptions " @head-cancel="headCancel" @head-save="headSave" @head-edit="headEdit" head-title="当前摄像头信息"></head-layout>
        <el-form class="form-base" ref="dataForm" :model="dataForm" label-width="160px" :disabled="formType == 'view'" :rules="rules">
          <el-col :span="8">
            <el-form-item label="编码" prop="code">
              <el-input v-model="dataForm.code" placeholder="请填写编码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input v-model="dataForm.name" placeholder="请填写名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="别名" prop="alias">
              <el-input v-model="dataForm.alias" placeholder="请填写别名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类型" prop="type">
              <el-select v-model="dataForm.type" placeholder="请选择类型">
                <el-option v-for="item in NODE_TYPES_list" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备型号" prop="deviceModel">
              <el-input v-model="dataForm.deviceModel" placeholder="请填写设备型号"></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="8">
            <el-form-item label="所属上级" prop="coordinate">
              <el-select v-model="dataForm.stage" placeholder="请选择">
                <el-option v-for="item in stageList" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="状态" prop="status">
              <el-select v-model="dataForm.status" placeholder="请选择">
                <el-option v-for="item in STATUS_BOOLEAN_list" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户名" prop="userName">
              <el-input v-model="dataForm.userName" placeholder="请填写用户名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="密码" prop="password">
              <el-input v-model="dataForm.password" placeholder="请填写密码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属上级" prop="parentName">
              <el-input v-model="dataForm.parentName" placeholder="请填写所属上级" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SIP用户ID" prop="sipUserId">
              <el-input v-model="dataForm.sipUserId" placeholder="请填写SIP用户ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="序列号" prop="sn">
              <el-input v-model="dataForm.sn" placeholder="请填写序列号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="视频流协议类型" prop="streamProtocol">
              <el-select v-model="dataForm.streamProtocol" placeholder="请选择">
                <el-option v-for="item in CAMERA_STREAM_PROTOCOL" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="视频流地址" prop="streamUrl">
              <el-input v-model="dataForm.streamUrl" placeholder="请填写视频流地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="视频通道ID" prop="channelId">
              <el-input v-model="dataForm.channelId" placeholder="请填写视频通道ID"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="播放地址" prop="playUrl">
              <el-input v-model="dataForm.playUrl" placeholder="请填写播放地址"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="安装位置" prop="position">
              <el-input v-model="dataForm.position" placeholder="请填写安装位置"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="厂商" prop="vendor">
              <el-input v-model="dataForm.vendor" placeholder="请填写厂商"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="外观类型" prop="appearanceType">
              <el-select v-model="dataForm.appearanceType" placeholder="请选择">
                <el-option v-for="item in CAMERA_APPEARANCE_TYPES_list" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="信号类型" prop="signalType">
              <el-select v-model="dataForm.signalType" placeholder="请选择">
                <el-option v-for="item in CAMERA_SIGNAL_TYPES_list" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="支持云控台" prop="cloudControl">
              <el-radio-group v-model="dataForm.cloudControl">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否高清" prop="supportHd">
              <el-radio-group v-model="dataForm.supportHd">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SIP用户ID" prop="sipUserId">
              <el-input v-model="dataForm.sipUserId" placeholder="请填写SIP用户ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="视频通道ID" prop="channelId">
              <el-input v-model="dataForm.channelId" placeholder="请填写视频通道ID"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="请填写备注"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
    </div>
    <el-dialog title="评估单元及风险点" v-if="jcxflModel" v-dialogDrag :visible.sync="jcxflModel" width="50%">
      <avue-form :option="jcxflOption" @reset-change="resetChange" @submit="handleSubmit" v-model="jcxflForm">
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
  import CommonTree from "@/views/components/com_tree/index.vue";
  import Template from "@/views/message/template/list.vue";
  import * as API from "@/api/safetyTtandard/riskIdentification";
  import {
    exportBlob
  } from "@/api/common";
  import {
    downloadXls
  } from "@/util/util";
  import GridLayout from "@/views/components/layout/grid-layout";
  import {
    dictionaryBiz
  } from "@/api/reportTasks";
  import {
    strisksourceRemove
  } from "@/api/safetyTtandard/riskIdentification";
  import {
    NODE_TYPES,
    CAMERA_SIGNAL_TYPES,
    CAMERA_APPEARANCE_TYPES,
    STATUS_BOOLEAN,
    CAMERA_STREAM_PROTOCOL
  } from '../../algorithmManagement/nodeDict.js'
  import {
    getById,edit
  } from "@/api/safe/cameraManagement";

  export default {
    name: "index",
    components: {
      Template,
      GridLayout,
      CommonTree
    },
    props: {
      selectTree: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        activeName: 'base',
        selectTree: undefined,
        jcxflModel: false,
        TableLoading: false,
        NODE_TYPES_list: [],
        STATUS_BOOLEAN_list: [],
        CAMERA_SIGNAL_TYPES_list:[],
        CAMERA_APPEARANCE_TYPES_list:[],
        tableData: [],
        treeData: [],
        stageList: [],
        query: {},
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        defaultProps: {
          children: "children",
          value: 'id',
          label: "sourceTypeName",
        },
        corePersonnelTreeData: [],

        dataForm: {},
        defaultExpandedKeys: [],
        formType:'view'
      }
    },
    computed: {
      headBtnOptions() {
        let result = [];
        if (["edit"].includes(this.formType)) {
          result.push({
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          });
          result.push({
            label: "取消",
            emit: "head-cancel",
            type: "button",
            btnOptType: "cancel",
          });
        } else {
          result.push({
            label: "编辑",
            emit: "head-edit",
            type: "button",
            btnOptType: "edit",
          });
        }

        return result;
      },
    },
    watch: {
      selectTree: {
        immediate: true,
        handler: function (data) {
          if (data) this.getFormData();
        },
      },

    },
    mounted() {
      this.NODE_TYPES_list = NODE_TYPES
      this.STATUS_BOOLEAN_list = STATUS_BOOLEAN
      this.CAMERA_SIGNAL_TYPES_list=CAMERA_SIGNAL_TYPES
      this.CAMERA_APPEARANCE_TYPES_list=CAMERA_APPEARANCE_TYPES
      this.dataForm.cloudControl = '1'
      this.dataForm.supportHd = '1'
    },
    methods: {
      handleClick() {
        this.typeTree();
      },
      getFormData() {
        getById({
          id: this.selectTree.id
        }).then((res) => {
          this.formType = 'view'
          this.dataForm = res.data.data
        })
      },
      headEdit() {
        this.formType = 'edit'
      },
      headCancel() {
        this.formType = 'view'
      },
      headSave() {
        let data = {
          ...this.dataForm,
        };
        edit(data).then((res) => {
            this.$message.success('操作成功');
            this.getFormData();
          })
          .catch((err) => {
            done();
          });
      },


    },
  }

</script>
<style scoped lang="scss">
  .riskIdentification {
    background-color: #FFFFFF;

    .common_tree_handle .el-tree {
      height: calc(100vh - 278px) !important;
    }

    .table-box {
      width: 100%;
    }
  }

  .form-base {
    margin-top: 16px;
    margin-right: 10px;
  }

  ::v-deep .introduceTable .avue-crud .el-table {
    height: calc(100vh - 330px) !important;
    max-height: calc(100vh - 330px) !important;
  }

  .introduceTree ::v-deep .common_tree_handle .el-tree {
    height: calc(100vh - 278px) !important;
  }

  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }

  ::v-deep .el-tabs--border-card {
    border-left: none;
    border-bottom: none;
  }

  ::v-deep .el-tabs__content {
    padding: 0 !important;
  }

</style>
