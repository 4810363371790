import { render, staticRenderFns } from "./paramEdit.vue?vue&type=template&id=61630cf1&scoped=true"
import script from "./paramEdit.vue?vue&type=script&lang=js"
export * from "./paramEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61630cf1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/hse-web-227/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61630cf1')) {
      api.createRecord('61630cf1', component.options)
    } else {
      api.reload('61630cf1', component.options)
    }
    module.hot.accept("./paramEdit.vue?vue&type=template&id=61630cf1&scoped=true", function () {
      api.rerender('61630cf1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/paramEdit.vue"
export default component.exports