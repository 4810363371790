<template>
    <el-dialog :visible.sync="dialogVisible" title="区域选择">
        <grid-layout
                ref="areaGriLayOut"
                :grid-row-btn="areaBtn"
                :table-options="AreaOPtions"
                :table-data="tableData"
                @area-change="araeChange"
                :table-loading="false"
                @gird-handle-select-click="selectionChange"
                key="areaGriLayOut"
            >
        </grid-layout>
        <div style="display: flex;justify-content: center" v-if="multiple">
            <el-button size="medium" type="primary" @click="confirm" style="margin-right: 24px">确认</el-button>
            <el-button size="medium" @click="closeDialog">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { areaList} from '@/api/healthManage/hazardAnalysis'
import {mapGetters} from 'vuex'


export default {
    props: {
        multiple: {
            type: Boolean,
            default: false
        }
    },


    components: {
        GridLayout
    },

    data () {
        return {
            dialogVisible: false,
            tableData: [],
            selectionList: [],
            AreaOPtions: {
                selection: this.multiple,
                menu: !this.multiple,
                column: [
                    {
                        label: "区域/场所",
                        prop: "name",
                        align: "center",
                        overHidden: true,
                    },
                ]
            },
            areaBtn:[{
                    label: '选择',
                    emit: "area-change",
                    type: "button",
                    icon: "",
                }
            ]
        }
    },

    computed: {
        ...mapGetters(['userInfo']),
    },

    methods: {
        init () {
            this.tableData = []
            areaList({organizationId: this.userInfo.dept_id}).then(res => {
                res.data.data.forEach((val,index)=>{
                    this.tableData.push({name: val,id:index})
                })
                this.$nextTick(() => {
                    this.$refs.areaGriLayOut.$refs.grid.$refs.table.doLayout();
                    this.$refs.areaGriLayOut.$refs.grid.refreshTable();
                });
            })
            this.dialogVisible = true
        },

        selectionChange (list) {
            this.selectionList = list
        },

        araeChange (row, index) {
            this.$emit('callback',row)
            this.closeDialog();
        },

        confirm () {
            this.$emit('callback',this.selectionList)
            this.closeDialog();
        },

        closeDialog() {
            this.dialogVisible = false
        },
    }
}
</script>

<style>

</style>
