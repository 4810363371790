var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "占位提示" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData.placeholder,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "placeholder", $$v)
              },
              expression: "activeData.placeholder",
            },
          }),
        ],
        1
      ),
      _vm.activeData.__config__.snowsKey === "popupSelect"
        ? _c(
            "el-form-item",
            { attrs: { label: "弹窗标题" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入弹窗标题" },
                model: {
                  value: _vm.activeData.popupTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "popupTitle", $$v)
                  },
                  expression: "activeData.popupTitle",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.activeData.__config__.snowsKey === "popupSelect" &&
      _vm.showType === "pc"
        ? _c(
            "el-form-item",
            { attrs: { label: "弹窗类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择弹窗类型" },
                  model: {
                    value: _vm.activeData.popupType,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData, "popupType", $$v)
                    },
                    expression: "activeData.popupType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "居中弹窗", value: "dialog" },
                  }),
                  _c("el-option", {
                    attrs: { label: "右侧弹窗", value: "drawer" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeData.__config__.snowsKey === "popupSelect" &&
      _vm.showType === "pc"
        ? _c(
            "el-form-item",
            { attrs: { label: "弹窗宽度" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择弹窗宽度" },
                  model: {
                    value: _vm.activeData.popupWidth,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData, "popupWidth", $$v)
                    },
                    expression: "activeData.popupWidth",
                  },
                },
                _vm._l(_vm.popupWidthOptions, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "存储字段" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入存储字段" },
            model: {
              value: _vm.activeData.propsValue,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "propsValue", $$v)
              },
              expression: "activeData.propsValue",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示字段" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入显示字段" },
            model: {
              value: _vm.activeData.relationField,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "relationField", $$v)
              },
              expression: "activeData.relationField",
            },
          }),
        ],
        1
      ),
      _vm.activeData.templateJson && _vm.activeData.templateJson.length
        ? _c(
            "el-table",
            { attrs: { data: _vm.activeData.templateJson } },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "50",
                  label: "序号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "field", label: "参数名称" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "required-sign" }, [
                            _vm._v(_vm._s(scope.row.required ? "*" : "")),
                          ]),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                scope.row.fieldName
                                  ? scope.row.field +
                                      "(" +
                                      scope.row.fieldName +
                                      ")"
                                  : scope.row.field
                              ) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3471177175
                ),
              }),
              _c("el-table-column", {
                attrs: { prop: "value", label: "表单字段" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择表单字段",
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onRelationFieldChange(
                                    $event,
                                    scope.row
                                  )
                                },
                              },
                              model: {
                                value: scope.row.relationField,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "relationField", $$v)
                                },
                                expression: "scope.row.relationField",
                              },
                            },
                            _vm._l(_vm.formFieldsOptions, function (item) {
                              return _c("el-option", {
                                key: item.realVModel,
                                attrs: {
                                  label: item.realLabel,
                                  value: item.realVModel,
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3708884993
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("el-divider", [_vm._v("列表字段")]),
      _c(
        "draggable",
        {
          attrs: {
            list: _vm.activeData.columnOptions,
            animation: 340,
            group: "selectItem",
            handle: ".option-drag",
          },
        },
        _vm._l(_vm.activeData.columnOptions, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "select-item" },
            [
              _c("div", { staticClass: "select-line-icon option-drag" }, [
                _c("i", { staticClass: "icon-ym icon-ym-darg" }),
              ]),
              _c("el-input", {
                attrs: { placeholder: "列名", size: "small" },
                model: {
                  value: item.label,
                  callback: function ($$v) {
                    _vm.$set(item, "label", $$v)
                  },
                  expression: "item.label",
                },
              }),
              _c("el-input", {
                attrs: { placeholder: "字段", size: "small" },
                model: {
                  value: item.value,
                  callback: function ($$v) {
                    _vm.$set(item, "value", $$v)
                  },
                  expression: "item.value",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "close-btn select-line-icon",
                  on: {
                    click: function ($event) {
                      return _vm.activeData.columnOptions.splice(index, 1)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-remove-outline" })]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticStyle: { "margin-left": "29px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "padding-bottom": "0" },
              attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
              on: { click: _vm.addSelectItem },
            },
            [_vm._v("\n      添加字段\n    ")]
          ),
        ],
        1
      ),
      _c("el-divider", [_vm._v("列表分页")]),
      _c(
        "el-form-item",
        { attrs: { label: "分页设置" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.hasPage,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "hasPage", $$v)
              },
              expression: "activeData.hasPage",
            },
          }),
        ],
        1
      ),
      _vm.activeData.hasPage
        ? _c(
            "el-form-item",
            { attrs: { label: "分页条数", "label-width": "80px" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.activeData.pageSize,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData, "pageSize", $$v)
                    },
                    expression: "activeData.pageSize",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 20 } }, [
                    _vm._v("20条"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 50 } }, [
                    _vm._v("50条"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 100 } }, [
                    _vm._v("100条"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 500 } }, [
                    _vm._v("500条"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("el-divider"),
      _c(
        "el-form-item",
        { attrs: { label: "能否清空" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.clearable,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "clearable", $$v)
              },
              expression: "activeData.clearable",
            },
          }),
        ],
        1
      ),
      _vm.activeData.__config__.snowsKey === "popupTableSelect"
        ? _c(
            "el-form-item",
            { attrs: { label: "能否搜索" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.activeData.filterable,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "filterable", $$v)
                  },
                  expression: "activeData.filterable",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.activeData.__config__.snowsKey === "popupTableSelect"
        ? _c(
            "el-form-item",
            { attrs: { label: "能否多选" } },
            [
              _c("el-switch", {
                on: { change: _vm.multipleChange },
                model: {
                  value: _vm.activeData.multiple,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "multiple", $$v)
                  },
                  expression: "activeData.multiple",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }