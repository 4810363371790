var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-title": "职业病病历",
              "head-btn-options": _vm.headBtnOptions,
            },
            on: { "head-add": _vm.headAdd, "head-remove": _vm.rowRemove },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "grid-row-btn": _vm.gridRowBtn,
              "table-options": _vm.tableOptions,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.page.total,
              page: _vm.page,
              editType: _vm.inline,
            },
            on: {
              "page-current-change": _vm.handleCurrentChange,
              "page-size-change": _vm.handleSizeChange,
              "page-refresh-change": _vm.onLoad,
              "gird-handle-select-click": _vm.selectionChange,
              "row-edit": _vm.rowEdit,
              "row-remove": _vm.rowRemove,
            },
            scopedSlots: _vm._u([
              {
                key: "documentUrl",
                fn: function ({ row, index }) {
                  return _vm._l(
                    _vm.getFiles(row.documentUrl),
                    function (item, i) {
                      return _c(
                        "el-link",
                        {
                          key: item.url,
                          attrs: { type: "primary", href: item.url },
                        },
                        [
                          _vm._v(
                            _vm._s(item.name) +
                              _vm._s(
                                i == _vm.getFiles(row.documentUrl).length - 1
                                  ? ""
                                  : ","
                              )
                          ),
                        ]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogVisible,
                title: _vm.title,
                "before-close": _vm.handleCancel,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "avue-form",
                {
                  ref: "cedForm",
                  attrs: { option: _vm.dialogOption },
                  on: { submit: _vm.handleSubmit },
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "documentUrl" },
                    [
                      _vm.formType != "view"
                        ? _c(
                            "el-upload",
                            {
                              ref: "uploadRef",
                              staticClass: "upload-demo",
                              staticStyle: {
                                "margin-bottom": "12px",
                                "align-items": "center",
                              },
                              attrs: {
                                accept: ".zip,.doc,.docx,.pdf,.jpg,.png",
                                action:
                                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
                                "on-success": _vm.handleDocSuccess,
                                headers: _vm.headers,
                                "file-list": _vm.files,
                                "on-remove": _vm.handleDocRemove,
                                "before-upload": _vm.handleBeforeUpload,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "\n                        支持*.zip/*.doc/*.docx/*.pdf/*.jpg/*.png格式,文件大小不超过5M\n                    "
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    icon: "el-icon-upload",
                                  },
                                },
                                [_vm._v("点击上传\n                      ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.dialogLoading,
                          expression: "dialogLoading",
                        },
                      ],
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCancel()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.dialogLoading,
                          expression: "dialogLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + " ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }