<template>
  <div style="position: relative">
    <div>
      <head-layout
        head-title="下发任务"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @grid-row-detail-click="rowDetail"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template #customBtn="{row}">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowDetail(row)"
          >详情
          </el-button>
        </template>
      </grid-layout>
    </div>
  </div>
</template>
<script>
import {distributePage} from "@/api/reportTemplate"
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        linklabel: "taskCode",
        menu: false,
        column: [
          // {
          //   label: "模板编码",
          //   prop: "templateCode",
          //   align: "left",
          //   overHidden: true,
          //   width: 170
          // },
          // {
          //   label: "模板名称",
          //   prop: "templateName",
          //   align: "left",
          //   overHidden: true,
          //   width: 170
          // },
          {
            label: "任务编码",
            prop: "taskCode",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "任务名称",
            prop: "taskName",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "下发人员",
            prop: "weaveUser",
            align: "center",
            width: 150,
            overHidden: true,
          },
          {
            label: "下发单位",
            prop: "weaveDept",
            align: "left",
            overHidden: true,
          },
          {
            label: "下发时间",
            prop: "createTime",
            align: "center",
            width: 200,
            overHidden: true,
          },
          {
            label: "任务分类",
            prop: "isCycle",
            align: "center",
            dataType: "number",
            width: 100,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=task_classification`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: '任务名称',
          prop: "taskName",
          span: 4,
          placeholder: '请输入任务名称'
        },
        {
          label: '任务编码',
          prop: "taskCode",
          span: 4,
          placeholder: '请输入任务编码'
        },
        {
          label: "下发时间",
          prop: "createTime",
          type: 'date-format',
          span: 4,
          placeholder: '请选择下发时间'
        },
        {
          label: "任务分类",
          prop: "isCycle",
          type: 'select',
          span: 4,
          placeholder: '请选择任务分类',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=task_classification`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
      ],
    }
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    // 搜索填报设置
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      if (searchForm.createTime !== undefined) {
        searchForm.createTime = new Date(searchForm.createTime)
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        };
        searchForm.createTime = searchForm.createTime.toLocaleString('en-US', options)
          .replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2')
          .replace(',', ' ');
      }
      console.log(searchForm, "searchForm")
      this.onLoad(this.page, searchForm);
    },
    // 清空填报设置搜索条件
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 获取列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      distributePage(page.currentPage, page.pageSize, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/reportManager/distributeTasks/detail`,
        query: {
          captureTaskId: row.captureTaskId,
          captureFormId: row.captureFormId,
          templateCode: row.templateCode,
          templateClassify:row.templateClassify,
          type: 'detail',
          pageType: '',
        }
      })
    }
  }
}
</script>
