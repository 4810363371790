
<template>
  <div>
    <video ref="videoPlayer" :src="videoUrl" class="video-player"></video>
  </div>
</template>

<script>
import Hls from 'hls.js';

export default {
  data() {
    return {
      videoUrl: 'http://172.28.20.94:5080/hls/HAIKANG_363ee3e8b90d44388cad142242523566.m3u8'
    };
  },
  mounted() {
    this.initHls();
  },
  beforeDestroy() {
    this.destroyHls();
  },
  methods: {
    initHls() {
      if (Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.loadSource(this.videoUrl);
        this.hls.attachMedia(this.$refs.videoPlayer);
        console.log("this.$refs.videoPlayer",this.$refs.videoPlayer)

        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          this.$refs.videoPlayer.play();
        });
      } else if (this.$refs.videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
        this.$refs.videoPlayer.src = this.videoUrl;
        this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
          this.$refs.videoPlayer.play();
        });
      }
    },
    destroyHls() {
      if (this.hls) {
        this.hls.destroy();
      }
    }
  },
  watch: {
    videoUrl() {
      this.destroyHls();
      this.initHls();
    }
  }
};
</script>

<style scoped>
.video-player {
  width: 100%;
  height: auto;
}
</style>
