var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "占位提示" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData.placeholder,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "placeholder", $$v)
              },
              expression: "activeData.placeholder",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-input", {
            attrs: {
              value: _vm.setDefaultValue(
                _vm.activeData.__config__.defaultValue
              ),
              placeholder: "请输入默认值",
            },
            on: { input: _vm.onDefaultValueInput },
          }),
        ],
        1
      ),
      _vm.activeData.autosize
        ? _c(
            "el-form-item",
            { attrs: { label: "最小行数" } },
            [
              _c("el-input-number", {
                attrs: {
                  min: 1,
                  placeholder: "最小行数",
                  "controls-position": "right",
                },
                model: {
                  value: _vm.activeData.autosize.minRows,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.autosize, "minRows", $$v)
                  },
                  expression: "activeData.autosize.minRows",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.activeData.autosize
        ? _c(
            "el-form-item",
            { attrs: { label: "最大行数" } },
            [
              _c("el-input-number", {
                attrs: {
                  min: 1,
                  placeholder: "最大行数",
                  "controls-position": "right",
                },
                model: {
                  value: _vm.activeData.autosize.maxRows,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.autosize, "maxRows", $$v)
                  },
                  expression: "activeData.autosize.maxRows",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "最多输入" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入字符长度", type: "number" },
              model: {
                value: _vm.activeData.maxlength,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "maxlength", $$v)
                },
                expression: "activeData.maxlength",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("个字符")])],
            2
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否只读" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.readonly,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "readonly", $$v)
              },
              expression: "activeData.readonly",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }