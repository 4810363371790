<template>
  <div>
    <el-container>
      <CommonTree
        v-if="treeShow"
        :loading="treeLoading"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        treeTitle="组织架构"
        :isShowdig="false"
        :showCheckbox="false"
        :default-expand-all="true"
        :treeExpand="true"
        :expandOnClickNode="false"
        :currentNodeKey="currentNodeKey"
        :defaultExpandedKeys="defaultExpandedKeys"
        :defaultCheckedKeys="defaultCheckedKeys"
        :nodeKey="'id'"
        @getNodeClick="treeNodeClick"
      />
      <el-main style="position: relative">
        <head-layout
          head-title="生态环保月报"
          :head-btn-options="headBtnOptions"
          @head-approve="headApprove"
          @head-reject="headReject"
          @head-urging="headUrging"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          class="overseasPerson"
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{row}">
            <el-button
              type="text"
              v-if="row.status === 2"
              @click="rowApprove(row)"
            > 通过
            </el-button>
            <el-button
              type="text"
              v-if="row.status === 2"
              @click="rowReject(row)"
            > 驳回
            </el-button>
<!--            <el-button-->
<!--              type="text"-->
<!--              v-if="row.status === 1 || row.status === 3"-->
<!--              @click="rowUrging(row)"-->
<!--            > 催办-->
<!--            </el-button>-->
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <CommonDialog
      v-if="rejectDiaShow"
      dialogTitle="审批驳回"
      width="500px"
      @cancel="closeReject"
      @confirm="rejectSubmit"
    >
      <el-form :model="rejectForm" :rules="rejectRules" ref="rejectForm" v-if="rejectDiaShow">
        <div class="rejectTop">
          <el-popover
            width="400"
            ref="popover"
            trigger="manual"
            @show="openPopover"
            @hide="closePopover"
            v-model="popoverShow">
            <div class="selectContent">
              <el-input v-model="searchValue" placeholder="请输入内容" @input="inputSearch"></el-input>
              <div class="selectModel" :style="{color:selectActive == index?themeColor:''}"
                   v-for="(item,index) in selectModelList" :key="item.id" @click="chooseSelct(item,index)">
                {{ item.name }}
              </div>
              <div class="line"></div>
              <el-button type="primary" icon="el-icon-circle-plus-outline" class="addBtn" @click="addMore"></el-button>
            </div>
            <el-button slot="reference" class="approvalBtn" :disabled="type == 'view'"
                       style="width: 100%;text-align: left" @click="popoverShow = true">请选择常用批复意见
            </el-button>
          </el-popover>
        </div>
        <el-form-item prop="comment">
          <el-input v-model="rejectForm.comment" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" maxlength="200"
                    show-word-limit placeholder="请输入审批意见"></el-input>
        </el-form-item>
      </el-form>
    </CommonDialog>
    <process-detail ref="processDetailComponent"></process-detail>
    <approval-opinion-dialog
      ref="approvalOpinionDialog"
      @closeDialog="getOpinionList">
    </approval-opinion-dialog>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree";
import ProcessDetail from "@/views/business/reportManager/reportApproval/components/detail.vue";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonDialog from "@/components/CommonDialog";
import ApprovalOpinionDialog from "@/views/plugin/workflow/process/components/approvalOpinionDialog";
import {reportReviewTree as reportTree} from "@/api/reportFixationTasks";
import {auditPage as selectPage} from "@/api/report/safetyProductionMonthly";
import {completeTask, detail, todoListByDept as todoList} from "@/api/plugin/workflow/process";
import draft from "@/views/plugin/workflow/mixins/draft";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import {getList as getApprovalList} from "@/api/plugin/workflow/process/approval-opinion-api";
import {mapGetters} from "vuex";
import {reviewAndUrging} from "@/api/reportReview";

export default {
  mixins: [exForm, draft],
  data() {
    return {
      query: {},
      processTemplateKey: "monthlySafetyProductionStatistics",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      nodeId: '',
      tableData: [],
      currentData: {},
      currentDataList: [],
      todoList: [],
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      currentNodeKey: '',
      treeShow: false,
      treeLoading: false,
      crudLoading: false,
      rejectDiaShow: false,
      popoverShow: false,
      searchValue: '',
      selectModelList: [],
      selectActive: null,
      rejectForm: {
        comment: ''
      },
      rejectRules: {
        comment: [
          {required: true, message: '请输入审批意见', trigger: 'blur'},
        ],
      },
      type: "",
      treeData: [],
      selectionList: [],
      node: {}, //左侧树node
      searchTitle: "title",
      defaultProps: {
        label: "deptName",
        value: "deptName",
        key: "id",
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
    };
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters(['themeColor']),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 150,
        selection: true,
        indexFixed: "left",
        // linklabel: "courseName",
        menuFixed: 'right',
        column: [

          {
            label: "统计月份",
            prop: 'dateAndTime',
            align: "center",
            overHidden: true,
            fixed: 'left',
            width: 90,
          },
          {
            label: "业务类型",
            prop: "statType",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=statistical_categories",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "本月安全生产投入（万元）",
            prop: "safeInvest",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月企业开展安全培训次数",
            prop: "trainTimes",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月参加安全培训人数",
            prop: "trainPeopleCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月检查数",
            prop: "checkCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月排查出安全隐患数量",
            prop: "hdCnt",
            align: "right",
            width: 140,
            overHidden: true,
          }, {
            label: "其中排查出重大隐患数量",
            prop: "majorHDCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月整改完成数量",
            prop: "rectifiedHdCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "本月应急演练次数",
            prop: "emergencDrillCnt",
            align: "right",
            width: 140,
            overHidden: true,
          },
          {
            label: "数据来源",
            prop: "dateSource",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_sources",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "数据状态",
            prop: "status",
            align: "center",
            type: "select",
            fixed: 'right',
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "创建日期",
            prop: 'createTime',
            type: 'datetime',
            align: "center",
            format: 'yyyy-MM-dd HH:mm:ss',
            width: 180,
          },

          {
            label: "创建人",
            prop: "createName",
            align: "center",
            width: 70,
            overHidden: true,
          },
          {
            label: "更新日期",
            prop: "updateTime",
            type: 'datetime',
            align: "center",
            format: 'yyyy-MM-dd HH:mm:ss',
            width: 180,
          },
          {
            label: "更新人",
            prop: "updateName",
            align: "center",
            width: 70,
            overHidden: true,
          },
        ],
      };
    },
    headBtnOptions() {
      return [
        {
          label: "通过",
          emit: "head-approve",
          type: "button",
        },
        {
          label: "驳回",
          emit: "head-reject",
          type: "button",
        },
        // {
        //   label: "催办",
        //   emit: "head-urging",
        //   type: "button",
        // },
      ];
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "status",
          align: "center",
          type: "select",
          dicData: [],
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 4,
          placeholder: "请选择状态",
        },
        {
          label: "",
          prop: 'dateAndTime',
          type: "month",
          span: 4,
          format: 'yyyy-MM',
          valueFormat: 'yyyy-MM',
          placeholder: "请选择填报年月",
        },
      ];
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    ProcessDetail,
    CommonDialog,
    ApprovalOpinionDialog
  },
  mounted() {
    this.query.statType = 'ENV'
    this.initTree()
    this.getProcessId()
    this.getOpinionList();
  },
  methods: {
    receiveData(row) {
      this.$refs.gridHeadLayout.searchForm.dateAndTime = row.statDate
      // this.query.dateAndTime = row.statDate
      this.query.unitCode = row.fillDeptId
      this.defaultExpandedKeys.push(row.fillDeptId)
      this.currentNodeKey = row.fillDeptId;
      this.nodeId = row.fillDeptId;
      this.getTodoList()
      // this.onLoad(this.page);
      this.treeShow = true;
    },
    initData() {
      if (this.treeData.length != 0) {
        this.defaultCheckedKeys.push(this.treeData[0].id)
      }
      this.onLoad(this.page);
      this.treeShow = true;
    },
    audit(taskId, processInsId, pass, comment) {
      this.$refs.processDetailComponent.getDetail(taskId, processInsId, pass, comment);
    },
    // 头部通过
    headApprove() {
      if (this.selectionList.length == 0) {
        this.$message.warning("请勾选数据！")
        return;
      }
      const hasDataStateNotEqualToTwo = this.selectionList.some(item => item.status !== 2);
      if (hasDataStateNotEqualToTwo) {
        this.$message.warning("请确认勾选的数据状态均为已提交！")
      } else {
        let processData = []
        this.selectionList.forEach((item) => {
          processData.push(this.todoList.find(e => e.processInstanceId === item.processInstanceId))
        })
        const hasUndefined = processData.some(item => item === undefined);
        if (hasUndefined) {
          this.$message.warning("数据已更新，请点击当前组织树后重试！");
        } else {
          if (processData.length === 0) {
            this.$message.warning('请确保当前用户拥有报表审核员的角色！');
          } else {
            this.$loading();
            Promise.all(processData.map(e => this.audit(e.taskId, e.processInstanceId, true)))
              .finally(() => {
                setTimeout(() => {
                  this.$loading().close();
                  this.onLoad(this.page);
                }, 2000)
              })
              .catch(error => {
                console.error('出现错误，请尝试行操作', error);
                this.$loading().close();
              });
          }
        }
      }
    },
    // 头部驳回
    headReject() {
      if (this.selectionList.length == 0) {
        this.$message.warning("请勾选数据！")
        return;
      }
      const hasDataStateNotEqualToTwo = this.selectionList.some(item => item.status !== 2);
      if (hasDataStateNotEqualToTwo) {
        this.$message.warning("请确认勾选的数据状态均为已提交！")
      } else {
        let processData = []
        this.selectionList.forEach((item) => {
          processData.push(this.todoList.find(e => e.processInstanceId === item.processInstanceId))
        })
        const hasUndefined = processData.some(item => item === undefined);
        if (hasUndefined) {
          this.$message.warning("数据已更新，请点击当前组织树后重试！");
        } else {
          if (processData.length === 0) {
            this.$message.warning('请确保当前用户拥有报表审核员的角色！');
          } else {
            this.currentDataList = processData;
            this.rejectDiaShow = true;
          }
        }
      }
    },
    // 头部催办
    headUrging() {
      if (this.selectionList.length == 0) {
        this.$message.warning("请勾选数据！")
        return;
      }
      const hasDataStateNotEqualToTwo = this.selectionList.some(item => item.status != 1 && item.status != 3);
      if (hasDataStateNotEqualToTwo) {
        this.$message.warning("请确认勾选的数据状态为草稿或驳回！")
      } else {
        let data = {deptId: this.selectionList[0].unitCode, reportCode: "ecologicalProductionMonthly"}
        reviewAndUrging(data)
          .then((result) => {
            if (result.data.code == 200) {
              this.$message.success("催办成功!")
            }
          })
      }
    },
    // 行内通过
    async rowApprove(row) {
      if (this.todoList.length === 0) {
        this.$message.warning("数据已更新，请点击当前组织树后重试！");
        return;
      }
      let processData = this.todoList.find(e => e.processInstanceId === row.processInstanceId);
      if (!processData) {
        this.$message.warning("数据已更新，请点击当前组织树后重试！");
        return;
      }
      this.$loading();
      let data = {
        attachment: [],
        comment: "",
        pass: true,
        processDefinitionId: processData.processDefinitionId,
        processDefinitionName: processData.processDefinitionName,
        processInstanceId: processData.processInstanceId,
        taskDefinitionKey: processData.taskDefinitionKey,
        taskId: processData.taskId,
        variables: {},
      }

      completeTask(data).then((res) =>{
        if (res.data.code == 200) {
          this.$loading().close();
          this.$message.success("操作成功!");
          this.onLoad(this.page);
        }
      })
    },
    // 行内驳回
    rowReject(row) {
      console.log(this.todoList, 'todoList')
      if (this.todoList.length === 0) {
        this.$message.warning("数据已更新，请点击当前组织树后重试！");
        return;
      }
      this.currentData = row;
      this.rejectDiaShow = true
    },
    // 行内催办
    rowUrging(row) {
      console.log(row, 'row')
      let data = {deptId: row.unitCode, reportCode: "ecologicalProductionMonthly"}
      reviewAndUrging(data)
        .then((result) => {
          if (result.data.code == 200) {
            this.$message.success("催办成功!")
          }
        })
    },
    // 审批提交
    rejectSubmit() {
      if (this.rejectForm.comment) {
        if (this.todoList.length === 0) {
          this.$message.warning("数据已更新，请点击当前组织树后重试！")
        } else {
          let processData = {}
          this.todoList.forEach((e) => {
            if (e.processInstanceId === this.currentData.processInstanceId) {
              processData = e;
            }
          })
          if (processData.taskId || this.currentDataList.length > 0) {
            this.$loading();
            try {
              // 这里由于都是通过驳回弹框触发所以通过this.currentDataList判断是否是批量
              if (this.currentDataList.length > 0) {
                this.currentDataList.forEach((e) => {
                  this.audit(e.taskId, e.processInstanceId, false, this.rejectForm.comment)
                })
              } else {
                this.audit(processData.taskId, processData.processInstanceId, false, this.rejectForm.comment)
              }
            } finally {
              this.rejectDiaShow = false
              setTimeout(() => {
                this.currentData = {}
                this.currentDataList = []
                this.$loading().close();
                this.onLoad(this.page);
              }, 2000)
            }
          } else {
            this.$message.warning("请确保当前用户角色包含报表审核人！")
          }
        }
      } else {
        this.$message.warning("请输入批复意见！")
      }
    },
    inputSearch(e) {
      let result = [];
      this.selectMainList.forEach((item) => {
        if (item.name.indexOf(e) != -1) {
          result.push(item);
          this.selectModelList = result;
        }
      })
    },
    chooseSelct(item, index) {
      this.selectActive = index;
      this.rejectForm.comment = item.name;
      this.popoverShow = false;
    },
    closeReject() {
      this.rejectDiaShow = false;
      this.rejectForm.comment = '';
      this.selectActive = null;
    },
    getOpinionList() {
      getApprovalList().then((res) => {
        this.selectModelList = res.data.data;
        this.selectMainList = res.data.data;
      });
    },
    addMore() {
      this.$refs.approvalOpinionDialog.openDialog();
    },
    openPopover() {
      this.popoverWidth = this.$refs.popover.$el.offsetWidth
      document.addEventListener('click', this.hidePopover, false)
    },
    closePopover() {
      document.removeEventListener('click', this.hidePopover, false)
    },
    hidePopover(e) {
      if (!this.$refs.popover.$el.contains(e.target)) {
        this.popoverShow = false;
        this.searchValue = '';
      }
    },
    getTodoList() {
      let params = {
        deptId: this.nodeId
      }
      this.crudLoading = true;
      todoList(1, 100, params).then((res) => {
        this.todoList = res.data.data.records;
        this.onLoad(this.page);
      })
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    initTree() {
      this.treeLoading = true;
      let code = "bbshry"
      let reportCode = "ecologicalProductionMonthly"
      reportTree(code, reportCode)
        .then((result) => {
          this.treeData = result.data.data;
          if (this.currentNodeKey == '') {
            this.$nextTick(() => {
              this.defaultCheckedKeys.push(this.treeData[0].id)
            })
          }
          this.treeLoading = false;
        })
        .catch((err) => {
          this.treeLoading = false;
        });
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.nodeId = node.id;
      this.query.unitCode = this.nodeId;
      this.classifyId = this.nodeId;
      this.unitCode = this.nodeId;
      this.page.currentPage = 1;
      this.getTodoList();
      // this.onLoad(this.page);
    },
    headReportView() {
      if (this.nodeId) {
        window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=ecologicalProductionMonthly")
      } else {
        this.$message.warning("请先选择组织")
      }
    },
    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    gridHeadEmpty() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.page = page;
      if (!this.query.unitCode) {
        return;
      }
      this.crudLoading = true;
      this.treeShow = true;
      selectPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total;
          data.records.forEach(item => {
            if (item.status == -1) {
              item.status = null
            }
            item.safeWorkHours == -1 ? item.safeWorkHours = null : item.safeWorkHours
            item.safeInvest == -1 ? item.safeInvest = null : item.safeInvest
            item.trainTimes == -1 ? item.trainTimes = null : item.trainTimes
            item.trainPeopleCnt == -1 ? item.trainPeopleCnt = null : item.trainPeopleCnt
            item.checkCnt == -1 ? item.checkCnt = null : item.checkCnt
            item.hdCnt == -1 ? item.hdCnt = null : item.hdCnt
            item.rectifiedHdCnt == -1 ? item.rectifiedHdCnt = null : item.rectifiedHdCnt
            item.majorHDCnt == -1 ? item.majorHDCnt = null : item.majorHDCnt
            item.emergencDrillCnt == -1 ? item.emergencDrillCnt = null : item.emergencDrillCnt
          })
          // delete this.query.dateAndTime
          this.tableData = data.records;
          this.crudLoading = false;
          this.selectionClear();
        })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .overseasPerson .avue-crud .el-table {
  height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}

.rejectTop {
  width: calc(100% - 2px);
  background-color: #F7F7F7;
  height: 40px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #DCDFE6;
  border-bottom: none;
  position: relative;
}

::v-deep .el-textarea .el-textarea__inner {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.selectModel {
  width: 100%;
  height: 36px;
  line-height: 36px;
  color: #333333;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 10px;
}

.selectModel:hover {
  background-color: #f5f7fa;
}

.selectActive {
  color: #2979FF;
}

.line {
  border-top: 1px dashed #303133;
  width: 100%;
  margin: 6px 0;
}

.approvalBtn {
  width: 200px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: absolute;
  top: 4px;
  right: 4px;
}

.addBtn {
  width: 100%;
}

::v-deep .addBtn i {
  font-size: 22px;
}
</style>
