<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.dc.AiResult.title')"
      @head-cancel="cancelFn"
    >
    </head-layout>
    <div style="background: #fff; padding: 20px">
      <template v-if="this.$route.query.type != 'Resultdetail'">
        <el-form model="form" :disabled="disabled" label-width="100px">
          <el-row>
            <el-col :span="6">
              <el-form-item
                :label="$t('cip.dc.AiSuanfa.field.modeName')"
                required
              >
                <el-input v-model="form.model_name"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item :label="$t('cip.dc.AiSuanfa.field.label')">
                <el-select v-model="form.algorithm_id" disabled>
                  <el-option
                    v-for="(item, index) in algorithmList"
                    :key="index"
                    :label="item.algorithm_name"
                    :value="item.algorithm_id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <div style="float: right" v-if="$route.query.type === 'xunlian'">
                <el-button @click="xuanlianmoxingFn" type="primary">{{
                  $t("cip.dc.AiSuanfa.field.xl")
                }}</el-button>
              </div>
            </el-col>
          </el-row>
          <div style="width: 100%; height: 20px"></div>
          <el-divider content-position="left">{{
            $t("cip.dc.AiSuanfa.field.placeholder")
          }}</el-divider>
          <div style="width: 100%; height: 20px"></div>
          <el-row>
            <el-col :span="6">
              <el-form-item
                :label="$t('cip.dc.QTemplate.field.DataTheme')"
                prop="sourceId"
              >
                <el-cascader
                  :show-all-levels="false"
                  v-model="form.sourceId2"
                  :options="sourceList"
                  style="width: 100%"
                  :props="{
                    value: 'id',
                    label: 'name',
                    children: 'children',
                    expandTrigger: 'hover',
                  }"
                  @change="sourceChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                :label="$t('cip.dc.QTemplate.field.DataObject')"
                prop="data_obj_id"
              >
                <el-select v-model="form.sourceId" @change="sourceChange2">
                  <el-option
                    v-for="(item, index) in sourceList2"
                    :key="index"
                    :label="item.code"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item :label="$t('cip.dc.AIDComission.sort')">
                <el-input-number style="width:100%" min="0" :controls="false" v-model="form.algorithm_sort"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>

          <el-divider content-position="left">{{ $t('cip.dc.AIDComission.tiaocan') }}</el-divider>
          <div style="width: 100%; height: 20px"></div>
          <el-row>
            <el-col v-for="(item, index) in tcParms" :key="index" :span="6">
              <el-form-item :label="item.argName">
                <el-input v-model="item.value"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row v-if="$route.query.type != 'detail'">
          <el-col :span="24">
            <!-- <p>{{ $t("cip.dc.AiSuanfa.field.x1") }}</p> -->
            <p>{{ $t('cip.dc.AIDComission.choosezhou') }}</p>
            <el-table
              border
              :data="columnList"
              style="width: 100%"
              ref="x1table"
              @select="selectx1"
              :header-cell-class-name="leftheaderStyle"
            >
              <el-table-column
                prop="fieldComment"
                :label="$t('cip.dc.AiSuanfa.field.zh')"
              >
              </el-table-column>
              <el-table-column
                prop="fieldName"
                :label="$t('cip.dc.AiSuanfa.field.field')"
              >
              </el-table-column>
              <el-table-column
                prop="dataType"
                :label="$t('cip.dc.AiSuanfa.field.dataType')"
              >
              </el-table-column>

              <el-table-column :label="$t('cip.dc.AIDComission.isX')" width="120">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.x"
                    active-value="1"
                    inactive-value="0"
                    @change="changeX($event, scope.row)"
                  >
                  </el-switch>
                </template>
              </el-table-column>

              <el-table-column :label="$t('cip.dc.AIDComission.isY')" width="120">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.y"
                    active-value="1"
                    inactive-value="0"
                     @change="changeY($event, scope.row)"
                  >
                  </el-switch>
                </template>
              </el-table-column>

              <el-table-column :label="$t('cip.dc.AIDComission.sort')">
                <template slot-scope="scope">
                  <el-select
                    clearable
                    class="tableSclect"
                    v-model="scope.row.sortRule"
                  >
                    <el-option :label="$t('cip.dc.dataobjDComission.upsort')" value="0"> </el-option>
                    <el-option :label="$t('cip.dc.dataobjDComission.downsort')" value="1"> </el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <div style="width: 100%; height: 20px"></div>
        <el-divider content-position="left"
          >{{ $t("cip.dc.AiSuanfa.field.TrainingResults") }}
        </el-divider>
        <div style="width: 100%; height: 20px"></div>
        <img
          :src="xlimage"
          alt=""
          style="width: 60vw; clear: both; display: block; margin: auto"
        />
      </template>
      <template v-if="this.$route.query.type == 'Resultdetail'">
        <el-divider content-position="left">{{
          $t("AiSuanfa.detail.ycjg")
        }}</el-divider>
        <el-row>
          <el-col :span="12">
            {{ $t("cip.dc.AiSuanfa.field.x") }}
            <el-button plain size="mini" type="danger">{{ xArr }}</el-button>
          </el-col>
          <el-col :span="12">
            {{ $t("cip.dc.AiSuanfa.field.y") }}:<el-button
              plain
              size="mini"
              style="margin-right: 10px"
              type="primary"
              v-for="(item, index) in yArr"
              :key="index"
              >{{ item }}</el-button
            >
          </el-col>
        </el-row>
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column prop="date" label="powerconsumption">
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>

<script>
import {
  getModelDetailsByModelId,
  getAlgorithmAllList,
  getTableColumnList,
  getPowerconsumption,
  getPmmlPredictData,
  saveModelPredict,
  getPowerconsumption2,
  getPowerconsumption3,
  aixlimg,
  suanfaSetDetail,
} from "@/api/Ai";

import { getDataTree, ObjectList } from "@/api/dataAcquisition/index";
import { ObjectList2, getDataOBJXQ2 } from "@/api/dataAcquisition/index";
import { listAll } from "@/api/dataAcquisition/DataServices";
import { modelList } from "@/api/dataAcquisition/DataDirectory";
import { exportBlob } from "@/api/common";
export default {
  name: "AimodelDetail",
  data() {
    return {
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        },
      ],
      tcParms: [],
      xlength: "",
      ylength: "",
      form: {
        algorithm_id: "", //2
        algorithm_name: "", //2
        algorithm_sort: "",
        chart_type: "", //12y
        dbname: "", //12y
        dbtype: "", //12y
        model_id: "", //1y
        model_name: "", //2
        model_path: null, //2
        password: "platform!@#123", //12y
        predict_name: "",
        tablename: "", //12y
        url: "172.28.20.187", //12y
        username: "platform", //12y
        x: "", //12y
        x_description: "", //12y
        y: "", //12y
        y_description: "", //12y
        sourceId: "",
        data_obj_id: "",
        sortFields: [],
        algorithm_args: [],
      },
      checkX:0,
      checkY:0,
      timecheck: "",
      tableData: [],
      algorithmList: [],
      sourceList: [],
      sourceList2: [],
      biaoList: [],
      columnList: [],
      metadataTableNameList: [],
      templateRadio: "",
      templateSelection: "",
      multipleSelection: [],
      xArr: [],
      yArr: [],
      title: "模型详情",
      disabled: false,
      xlimage: "",
    };
  },
  created() {
    this.MgetDataTree();
    this.getAlgorithmAllListFn();
    if (this.$route.query.type == "xunlian") {
      this.MsuanfaSetDetail(this.$route.query.algorithm_id);
      this.$nextTick(() => {
        this.form.algorithm_id = this.$route.query.algorithm_id;
      });
    } else if (this.$route.query.type == "yuce") {
      this.getModelDetailsByModelIdFn();
      this.Maixlimg(this.$route.query.id);
      //this.title = '模型预测'
    } else if (this.$route.query.type == "detail") {
      this.getModelDetailsByModelIdFn();
      //this.title = '模型详情'
      this.disabled = true;
    } else if (this.$route.query.type == "Resultdetail") {
      this.getPmmlPredictDataFn({
        modelId: this.$route.query.modelId,
        predictId: this.$route.query.predictId,
      });
    }
  },

  methods: {
    //详情
    MsuanfaSetDetail(e) {
      suanfaSetDetail({ id: e }).then((res) => {
        this.tcParms = res.data.data.argsList;
        // this.form.algorithm_args = res.data.data.argsList;

        this.xlength = res.data.data.xaxis;
        this.ylength = res.data.data.yaxis;
      });
    },

    leftheaderStyle({ row, column, rowIndex, columnIndex }) {
      if (
        this.$route.query.algorithm_id == 31 ||
        this.$route.query.algorithm_id == 21
      ) {
        if (columnIndex === 0) {
          return "seltAllbtnDis";
        }
      }
    },
    canBeSelect(row) {
      if (this.$route.query.algorithm_id == 31) {
        if (row.dataType == "datetime" || row.dataType == "timestam") {
          return true; // 返回true 可以选择
        } else {
          return false; // 返回false 禁止选择
        }
      } else {
        return true;
      }
    },

    Maixlimg(id) {
      exportBlob(
        `/api/sinoma-datacenter/algorithm/ai-algorithm/get-train-image-result?${"id"}=${id}`
      ).then((res) => {
        this.xlimage = window.URL.createObjectURL(res.data);
      });
    },
    MgetDataTree() {
      getDataTree({ object: 0 }).then((res) => {
        if (res.status == 200) {
          this.sourceList = res.data.data;
          this.sourceList.forEach((element) => {
            element.children.forEach((element2) => {
              if (element2.children) {
                element2.children = undefined;
              }
            });
          });
        }
      });
    },
    //todo 模型详情
    getModelDetailsByModelIdFn() {
      getModelDetailsByModelId({
        modelId: this.$route.query.id,
      }).then((res) => {
        let detail = res.data.data[0];
        this.form.sourceId2 = [parseInt(detail.themePid), detail.themeId];
        this.form.sourceId = detail.dataset_source;
        this.form.model_name = detail.model_name;
        this.form.algorithm_id = detail.algorithm_id.toString();
       this.tcParms = detail.algorithm_args
        if (detail.themePid) {
          let parms = {
            modelId: detail.themeId,
            query: { current: 1, size: 100 },
          };

          ObjectList(parms).then((res) => {
            this.sourceList2 = res.data.data.records;
          });
        }
        if (detail.dataset_source) {
          getDataOBJXQ2({
            id: detail.dataset_source,
          }).then((res) => {


            this.columnList = res.data.data.saveFields;
this.columnList.forEach(item => {
   this.$set(item, "sortRule", "");
   this.$set(item, "x", "");
    this.$set(item, "y", "");
});
            



            detail.xList.forEach((element) => {
              this.columnList.forEach((element2, index) => {
                if (element == element2.fieldName) {
                  this.$nextTick(() => {
                   element2.x=1

                  });
                }
              });
            });
            this.templateRadio = detail.yList[0];
            this.columnList.forEach((element) => {
              if (element.fieldName == detail.yList[0]) {
                element.y=1
              }
            });

            this.columnList.forEach(element => {
             detail.sortFields.forEach(element2 => {
               if(element.fieldId==element2.fieldId){
                element.sortRule = element2.sortRule
               }
             });
            });



          });
        }
      });
    },
    //todo 算法下拉
    getAlgorithmAllListFn() {
      getAlgorithmAllList({}).then((res) => {
        this.algorithmList = res.data.data;
      });
    },
    //todo 数据源下拉
    sourceListFn() {
      ObjectList2({}).then((res) => {
        this.sourceList = res.data.data;
      });
    },

    sourceChange(value) {
      let parms = {
        modelId: value[1],
        query: { current: 1, size: 100 },
      };

      ObjectList(parms).then((res) => {
        this.sourceList2 = res.data.data.records;
      });

      this.form.sourceId = null;
    },
    sourceChange2(value) {
      this.metadataTableNameList = [];
      let source = this.sourceList2.find((item) => item.id == value);
      this.source = source;
      getDataOBJXQ2({
        id: value,
      }).then((res) => {
        this.columnList = res.data.data.saveFields;
      });
    },
    //todo 获取x y 轴特征
    getTableColumnListFn(name) {
      getTableColumnList({
        dbType: this.source.dbtype,
        host_id: this.source.name,
        table_name: name,
      }).then((res) => {
        this.columnList = res.data.data;
        this.columnList.columnList.map((item, index) => {
          item.myId = index + 1;
        });
      });
    },
    biaoChangeFn(value) {
      this.getTableColumnListFn(value);
    },
    singleElection(row) {
      this.templateRadio = row.fieldName;
      this.form.y_description = row.fieldComment;
    },

    selectx1(selection, row) {
      if (
        this.$route.query.algorithm_id == 31 ||
        this.$route.query.algorithm_id == 21
      ) {
        this.timecheck = row.id;
        // 清除 所有勾选项
        this.$refs.x1table.clearSelection();
        // 当表格数据都没有被勾选的时候 就返回
        // 主要用于将当前勾选的表格状态清除
        if (selection.length == 0) return;
        this.$refs.x1table.toggleRowSelection(row, true);
        this.multipleSelection = this.$refs.x1table.selection;
      } else {
        this.multipleSelection = selection;
      }
    },
    xuanlianmoxingFn() {
      if (!this.form.algorithm_id) {
        this.$message.error(
          this.$t("cip.cmn.rule.selectWarning") +
            this.$t("cip.dc.AiSuanfa.field.label")
        );
        return;
      }

      let algorithm_name = this.algorithmList.find(
        (item) => item.algorithm_id == this.form.algorithm_id
      ).comment;
      this.form.algorithm_name = algorithm_name;
      this.form.dbname = this.source.name;
      this.form.dbtype = this.source.dbtype;

      this.form.sortFields = [];
      this.columnList.forEach((element) => {
        if (element.sortRule) {
          this.form.sortFields.push({
            fieldId: element.fieldId,
            sortRule: element.sortRule,
          });
        }
      });
      this.form.algorithm_args = [];
      this.tcParms.forEach((element) => {
        this.form.algorithm_args.push({
          id: element.id,
          arg_name: element.argName,
          arg_code: element.argCode,
          arg_sort: element.argSort,
          value: element.value,
        });
      });
      let xArr = [];
      let xArrd = [];
      let yArr = [];
      let yArrd = [];
      this.columnList.forEach((element) => {
        if (element.x == 1) {
          xArr.push(element.fieldName);
          xArrd.push(element.fieldComment);
        }
        if (element.y == 1) {
          yArr.push(element.fieldName);
          yArrd.push(element.fieldComment);
        }
      });
      this.form.x = xArr.join(",");
      this.form.x_description = xArrd.join(",");
      this.form.y = yArr.join(",");
      this.form.y_description = yArrd.join(",");

      this.algorithmList.forEach((element) => {
        if (element.algorithm_id == this.form.algorithm_id) {
          this.form.algorithm_name = element.algorithm_name;
        }
      });

      let form3 = JSON.parse(JSON.stringify(this.form));
      form3.sourceId = undefined;
      form3.data_obj_id = this.form.sourceId;

      getPowerconsumption2(form3).then((res) => {
        let aa = JSON.parse(res.data.data);
        this.Maixlimg(aa.model_id);
        this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
      });
    },

    getPmmlPredictDataFn(params) {
      getPmmlPredictData(params).then((res) => {
        this.resultTable = res.data.data;
      });
    },

    cancelFn() {
      this.$router.go(-1);
    },

    //x轴限制
    changeX(e, row) {
      if (this.xlength == 1) {
        let xl = [];
        this.columnList.forEach((element) => {
          if (element.x==1) {
            xl.push(element)
          }
        });
         this.checkX = xl.length
        if(this.checkX>1){
          row.x = 0
          this.$message.warning(this.$t('cip.dc.AIDComission.xmsg'));
        }
      }
    },
  //y轴限制
     changeY(e, row) {
      if (this.ylength == 1) {
        let yl = [];
        this.columnList.forEach((element) => {
          if (element.y==1) {
            yl.push(element)
          }
        });
         this.checkY = yl.length
        if(this.checkY>1){
          row.y = 0
          this.$message.warning(this.$t('cip.dc.AIDComission.ymsg'));
        }
      }
       if (this.ylength == 2) {
        let yl = [];
        this.columnList.forEach((element) => {
          if (element.y==1) {
            yl.push(element)
          }
        });
         this.checkY = yl.length
        if(this.checkY>0){
          row.y = 0
          this.$message.warning(this.$t('cip.dc.AIDComission.noy'));
        }
      }
    },
  },
};
</script>

<style scoped>
::v-deep .seltAllbtnDis .cell {
  visibility: hidden;
}
</style>
