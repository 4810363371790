var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "10vh",
            title: _vm.title,
            visible: _vm.show,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [_vm.show ? _c("div", { attrs: { id: "chartDiscount" } }) : _vm._e()]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }