<template>
  <el-dialog title="数据选择" :close-on-click-modal="false" width="800px"
             class="SNOWS-dialog SNOWS-dialog_center table-dialog" lock-scroll append-to-body v-bind="$attrs"
             v-on="$listeners" @open="onOpen">
    <el-row class="SNOWS-common-search-box" :gutter="16">
      <el-form @submit.native.prevent>
        <el-col :span="10">
          <el-form-item label="关键词">
            <el-input v-model="keyword" placeholder="请输入关键词查询" clearable
                      @keyup.enter.native="initData()"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="initData()">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-right" @click="refresh()">重置
            </el-button>
          </el-form-item>
        </el-col>
      </el-form>
      <div class="SNOWS-common-search-box-right">
        <el-tooltip effect="dark" content="$t('common.refresh')" placement="top">
          <el-link icon="icon-ym icon-ym-Refresh SNOWS-common-head-icon" :underline="false"
                   @click="initData()"/>
        </el-tooltip>
      </div>
    </el-row>
    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange"
              :border="false">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column prop="table_name" label="表名" width="300"/>
      <el-table-column prop="table_comment" label="说明" show-overflow-tooltip/>
      <el-table-column prop="sum" label="总数" width="90"/>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="select()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {DataModelList} from '@/api/onlineDev/dataModel'
import SNOWSTable from '@/components/SNOWS-table'

export default {
  props: ['dbLinkId'],
  components: {SNOWSTable},
  data() {
    return {
      listLoading: true,
      keyword: '',
      list: [],
      checked: []
    }
  },
  methods: {
    onOpen() {
      this.initData()
    },
    closeDialog() {
      this.$emit('update:visible', false)
    },
    refresh() {
      this.keyword = ''
      this.initData()
    },
    initData() {
      this.listLoading = true
      const dbLinkId = this.dbLinkId || '0'
      DataModelList(dbLinkId, {keyword: this.keyword}).then(res => {
        this.list = res.data.list
        this.listLoading = false
      })
    },
    select() {
      this.$emit('closeForm', this.checked)
      this.$emit('update:visible', false)
    },
    handleSelectionChange(val) {
      this.checked = val
    }
  }
}
</script>
<style lang="scss" scoped>
.table-dialog {
 ::v-deep .el-dialog__body {
    height: 70vh;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .SNOWS-common-search-box {
      margin-bottom: 0;

      .SNOWS-common-search-box-right {
        padding: 10px 10px 0 0;
      }
    }
  }
}
</style>
