var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "开始占位" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData["start-placeholder"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "start-placeholder", $$v)
              },
              expression: "activeData['start-placeholder']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "结束占位" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData["end-placeholder"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "end-placeholder", $$v)
              },
              expression: "activeData['end-placeholder']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-time-picker", {
            attrs: {
              "start-placeholder": "开始时间",
              "end-placeholder": "结束时间",
              clearable: "",
              "value-format": _vm.activeData["value-format"],
              format: _vm.activeData.format,
              "is-range": "",
              "range-separator": "至",
            },
            model: {
              value: _vm.activeData.__config__.defaultValue,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
              },
              expression: "activeData.__config__.defaultValue",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "分隔符" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入分隔符" },
            model: {
              value: _vm.activeData["range-separator"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "range-separator", $$v)
              },
              expression: "activeData['range-separator']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "时间格式" } },
        [
          _c("el-input", {
            attrs: {
              value: _vm.activeData.format,
              placeholder: "请输入时间格式",
            },
            on: {
              input: function ($event) {
                return _vm.setTimeValue($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "能否清空" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.clearable,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "clearable", $$v)
              },
              expression: "activeData.clearable",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否只读" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.readonly,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "readonly", $$v)
              },
              expression: "activeData.readonly",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }