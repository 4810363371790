<template>
  <div style="height: 100%" id="homeIndex">
    <!-- v-if="userInfo.deptCategory != 5" -->
    <div
      class="home-Index-default"
      style="height: 100%"
      v-if="userInfo.company_category != 5"
    >
      <div class="disFlex" style="height: 40%">
        <div class="cardBox6">
          <div
            class="cardTitleHome"
            style="margin-bottom: 10px; line-height: 46px"
          >
            数据简报
            <!--          <el-button size="medium" class="board" @click="goBoard">进入看板-->
            <!--            <i class="el-icon-right el-icon&#45;&#45;right"></i>-->
            <!--        </el-button>-->
          </div>
          <div class="disFlex" style="height: calc(100% - 56px)">
            <div class="targetBox">
              <div
                class="targetLittle"
                v-for="(item, index) in dataList"
                :key="index"
              >
                <div class="targetTitle">
                  <div class="iconContent" :style="{ background: themeColor }">
                    <img class="income" :src="item.iconUrl" />
                  </div>
                  <div class="disFlex-left">
                    <span class="number">{{
                      sinoma.formatNumber(item.value)
                    }}</span>
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-box">
          <div class="quick-app-list">
            <div class="menu-title">
              <span>快捷入口</span>
              <i
                class="el-icon-s-tools"
                @click="openMenuDialog"
                style="font-size: 24px; cursor: pointer; color: #000000"
              ></i>
            </div>
            <div class="list-content">
              <div
                class="quick-app-item"
                v-for="(item, index) of menuData"
                :key="index"
                @click="menuClick(item)"
                @contextmenu.prevent="rightClick(item)"
              >
                <div class="imageBox" :style="{ background: themeColor }">
                  <i :class="item.menuSource"></i>
                </div>
                <span>{{ item.menuNameEn || item.menuNameZh }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="disFlex" style="margin-top: 16px; height: calc(60% - 16px)">
        <div style="width: calc(66.6% - 8px)">
          <div
            class="cardBox6"
            style="width: calc(100% - 40px); height: calc(100% - 32px)"
          >
            <div
              class="cardTitleHome"
              style="height: 100%; margin: 0; position: relative"
            >
              <!--              @tab-click="handleTabClick"-->
              <el-tabs v-model="todoActive">
                <el-tab-pane name="0">
                  <span slot="label">待办【{{ todoTotal }}】</span>
                  <div class="newsBox">
                    <div
                      class="newsLittle"
                      v-for="(item, index) in todoList"
                      :key="index"
                      @click="rowTrackPending(item)"
                    >
                      <el-tooltip
                        placement="top"
                        effect="dark"
                        v-fix-zoom
                        :disabled="item.showTip"
                        :content="item.taskName"
                      >
                        <div
                          class="newsTitle"
                          :class="'todoTitle' + index"
                          style="width: 637px"
                        >
                          {{ item.typeName }}—{{
                            item.businessType == 0 ? "流程待办" : "任务待办"
                          }}—{{ item.taskName }}
                        </div>
                      </el-tooltip>
                      <div class="newsTime" style="width: calc(100% - 637px)">
                        {{ item.createTime }}
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <!--                <el-tab-pane name="1">-->
                <!--                  <span slot="label"-->
                <!--                    >任务待办【{{ taskListComplete.length }}】</span-->
                <!--                  >-->
                <!--                  <div class="newsBox">-->
                <!--                    <div-->
                <!--                      class="newsLittle"-->
                <!--                      v-for="(item, index) in taskList"-->
                <!--                      :key="index"-->
                <!--                      @click="goToTaskDetail(item)"-->
                <!--                    >-->
                <!--                      <el-tooltip-->
                <!--                        placement="top"-->
                <!--                        effect="dark"-->
                <!--                        v-fix-zoom-->
                <!--                        :disabled="item.showTip"-->
                <!--                        :content="item.taskName + ' | ' + item.issuer"-->
                <!--                      >-->
                <!--                        <div-->
                <!--                          class="newsTitle"-->
                <!--                          :class="'taskTitle' + index"-->
                <!--                          style="width: 637px"-->
                <!--                        >-->
                <!--                          {{ taskTypeFormatter(item.taskType) }}&nbsp; |-->
                <!--                          &nbsp;{{ item.taskName }} <span class="point"></span>-->
                <!--                          {{ item.issuer }} <span class="point"></span>-->
                <!--                          {{ item.taskDeptName }}-->
                <!--                        </div>-->
                <!--                      </el-tooltip>-->
                <!--                      <div class="newsTime" style="width: calc(100% - 637px)">-->
                <!--                        {{ item.taskDeadline }}-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </el-tab-pane>-->
              </el-tabs>
              <div
                class="allNameBox"
                :style="{ color: themeColor }"
                @click="runTodo"
              >
                更多
              </div>
            </div>
          </div>
        </div>
        <div style="width: calc(33.3% - 7px); margin-left: 16px">
          <div
            class="cardBox3"
            style="height: calc(100% - 32px); position: relative"
          >
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="系统公告" name="0">
                <div class="newsBox">
                  <div
                    class="newsLittle"
                    v-for="(item, index) in noticeList"
                    :key="index"
                    @click="runNotice(item)"
                  >
                    <div class="newsTitle">
                      {{ item.title }}
                    </div>
                    <div class="newsTime">
                      {{ item.createTime }}
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="环境要闻" name="1">
                <div class="newsBox">
                  <div
                    class="newsLittle"
                    v-for="(item, index) in newsList"
                    :key="index"
                    @click="runNews(item)"
                  >
                    <div class="newsTitle">
                      {{ item.parameter_3 }}
                    </div>
                    <div class="newsTime">
                      {{ item.parameter_2 }}
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="内部分享" name="2">
                <div class="newsBox">
                  <div
                    class="newsLittle"
                    v-for="(item, index) in shareList"
                    :key="index"
                    @click="runShare(item)"
                  >
                    <div class="newsTitle">
                      {{ item.title }}
                    </div>
                    <div class="newsTime">
                      {{ item.createTime }}
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="moreTitle" :style="{ color: themeColor }">
              <span style="cursor: pointer" @click="openMore">更多</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- v-else -->
    <div class="home-index-prj" v-else style="height: 100%">
      <IndexPrj></IndexPrj>
    </div>
    <el-dialog title="公告详情" :visible.sync="noticeShow" width="50%">
      <div class="noticeTitle">
        {{ noticeForm.title }}
      </div>
      <div class="noticeTime">
        {{ noticeForm.createTime }}
      </div>
      <div class="noticeContent" v-html="noticeForm.content"></div>
    </el-dialog>
    <el-dialog
      :title="this.$t('tagsView.shortcutMenu')"
      custom-class="noticeClass"
      :visible.sync="menuShow"
      :close-on-click-modal="false"
      width="60%"
      :modal-append-to-body="false"
      append-to-body
      @close="closeMenuDialog"
    >
      <menu-dialog
        ref="menuDialog"
        :disabledData="menuData"
        @getNewMenu="addMenu"
      ></menu-dialog>
    </el-dialog>
  </div>
</template>
<script>
import customerServer from "@/components/customerServer/index.vue";
import { todoList, detail, sinomatodo } from "@/api/plugin/workflow/process";
import { getMyTaskList } from "@/api/system/myTasks";
import { dateFormat } from "@/util/date";
import { getList as noticeList } from "@/api/desk/notice";
import { getList as shareList } from "@/api/desk/personalSharing";
import { getDictionary } from "@/api/system/dict";
import { mapGetters } from "vuex";
import { appIndex } from "@/api/user";
import { categoryTree } from "@/api/process/process";
import website from "@/config/website";
import { getList as getMenuList, remove } from "@/api/system/shortcutMenu";
import menuDialog from "@/components/menu/menuDialog";
import { getDept } from "@/api/system/dept";
import { formatTime } from "@/util";
import { myWtTicketPage, ticketType } from "@/api/workTicket";
import IndexPrj from "@/views/home/index_prj.vue";
import { prjbasicinfoDetail, prjQrcode } from "@/api/safe/itemList";

export default {
  components: {
    customerServer,
    menuDialog,
    IndexPrj,
  },
  data() {
    return {
      noticeShow: false,
      noticeForm: {},
      todoList: [],
      todoTotal: 0,
      taskList: [],
      taskListComplete: [],
      categoryOption: [],
      kictectTypeList: [],
      timeOfDay: "",
      dateStr: "",
      weekStr: "",
      dataList: [
        {
          name: "在建项目人数",
          value: 0,
          iconUrl: require("@/assets/welIndex/people.png"),
        },
        {
          name: "在建项目数量",
          value: 0,
          iconUrl: require("@/assets/welIndex/project.png"),
        },
        {
          name: "安环事故",
          value: 0,
          iconUrl: require("@/assets/welIndex/accident.png"),
        },
        {
          name: "安全隐患",
          value: 0,
          iconUrl: require("@/assets/welIndex/danger.png"),
        },
        {
          name: "培训次数",
          value: 0,
          iconUrl: require("@/assets/welIndex/train.png"),
        },
        {
          // 安全生产工时
          name: "安全检查",
          value: 0,
          iconUrl: require("@/assets/welIndex/knowledge.png"),
        },
      ],
      todoActive: 0,
      activeName: 0,
      newsList: [],
      shareList: [],
      statusDict: {
        1: "待处理",
        2: "已关闭",
      },
      taskTypeDict: {
        1: "填报任务",
        2: "汇总任务",
        3: "培训任务",
        4: "考试任务",
        5: "签署任务",
        7: "签署任务",
        10: "作业票清单",
      },
      defaultMenu: [
        // {
        //   menuPath: this.website.webUrl + "/workflow/#/plugin/workflow/process/index",
        //   menuNameEn: this.$t("cip.desk.workflow.title.indexHeadMyTodo"),
        //   menuSource: "iconfont iconicon_savememo",
        // },
        // {
        //   menuPath: "/system/myReport",
        //   menuNameEn: this.$t("cip.plat.reportcenter.myReport.title.headTitle"),
        //   menuSource: "el-icon-s-data",
        // },
        // {
        //   menuPath: "/system/knowledge",
        //   menuNameEn: this.$t("cip.plat.sys.knowledge.title.knowledgeTitle"),
        //   menuSource: "iconfont iconicon_star",
        // },
        // {
        //   menuPath: "/desk/notice",
        //   menuNameEn: this.$t("cip.desk.notice.title.indexHeadTitle"),
        //   menuSource: "iconfont iconicon_sms",
        // },

        {
          menuPath: "/knowledge/knowledgeSearch",
          menuNameEn: "知识库",
          menuSource: "iconfont iconicon_star",
        },
        {
          menuPath: "/business/safetyenment/train/trainingEducation/index",
          menuNameEn: "我的培训",
          menuSource: "iconfont iconicon_exchange",
        },
        {
          menuPath: "/business/safetyenment/train/myExam/eduuserexam",
          menuNameEn: "我的考试",
          menuSource: "iconfont iconicon_glass",
        },
        {
          menuPath: "/plugin/workflow/process/index",
          menuNameEn: "流程待办",
          menuSource: "iconfont iconicon_compile",
        },
        {
          menuPath: "/desk/taskTodo",
          menuNameEn: "任务待办",
          menuSource: "iconfont iconicon_setting",
        },
        {
          menuPath:
            window.location.origin +
            "#/business/reportManager/fillReport/index?isNewWindow=true",
          menuNameEn: "报表填报",
          menuSource: "iconfont iconicon_compile",
        },
      ],
      menuData: [],
      year: "",
      menuShow: false,
      todoTimer: "",
      myTaskTimer: "",
      timerTodoLeave: true,
      timerMyTaskLeave: true,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "openChatState", "themeColor"]),
  },
  watch: {
    userInfo(newVal, oldVal) {
      // 当myState变化时，这个函数会被调用
      console.log(666, newVal);
      // 在这里处理状态变化
    },
  },
  mounted() {
    if (this.userInfo.deptCategory != 5) {
      if (
        this.userInfo.real_name.indexOf("zh") != -1 ||
        this.userInfo.real_name.indexOf("en") != -1
      ) {
        this.userInfo.real_name = JSON.parse(this.userInfo.real_name);
      }
      this.getPersonNum();
      this.getIndexData();
      this.getMenuListFn();
      this.getDictory();
      this.getDataList();
      this.getMyTask();
      this.getDate();
      // this.resize();
      this.getTicketList();
      window.addEventListener("storage", (e) => {
        if (e.key === "sinoma-refresh") {
          this.getTodoList(); //刷新列表
          localStorage.setItem("sinoma-refresh", JSON.stringify(false)); //变成false , 可以来回监听
          //要做的操作
        } else if (e.key === "sinoma-report") {
          this.getMyTask(); //刷新列表
          localStorage.setItem("sinoma-report", JSON.stringify(false)); //变成false , 可以来回监听
          //要做的操作
        }
      });
    } else if (this.userInfo.deptCategory == 5) {
      this.getPrjDetail();
    }
  },
  created() {
    this.timerTodoLeave = false;
    this.timerMyTaskLeave = true;
    this.year = new Date().getFullYear();
    this.$nextTick(() => {
      this.bodyScale();
    });
  },
  beforeDestroy() {
    this.timerTodoLeave = true;
    this.timerMyTaskLeave = true;
  },
  methods: {
    getPrjDetail() {
      prjbasicinfoDetail({
        id: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.$store.commit("SET_USER_INFO", {
            ...this.userInfo,
            prjCode: data.prjCode,
            deptSegment: data.deptSegment,
          });
        }
      });
    },
    bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var scale = devicewidth / 1920; // 分母——设计稿的尺寸
      document.getElementById("homeIndex").style.zoom = scale; //放大缩小相应倍数
      //解决页面缩放echarts图标鼠标位置偏移问题
      document.styleSheets[document.styleSheets.length - 1].insertRule(
        "canvas {zoom: " + 1 / scale + "}"
      );
      document.styleSheets[document.styleSheets.length - 1].insertRule(
        "canvas {transform: scale(" + scale + ")}"
      );
      document.styleSheets[document.styleSheets.length - 1].insertRule(
        "canvas {transform-origin: 0 0}"
      );
    },
    // 加载浏览器窗口变化自适应
    resize() {
      this.bodyScale();
      window.onresize = () => {
        this.bodyScale();
      };
    },
    openMenuDialog() {
      this.menuShow = true;
      this.$refs.menuDialog.onLoad();
    },
    addMenu() {
      this.getMenuListFn();
    },
    getPersonNum() {
      appIndex({
        indexCode: "ZB0002_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          // if (res.data.data.records.length != 0 && res.data.data.records[0] && res.data.data.records[0]._value)
          //   this.dataList[0].value = res.data.data.records[0]._value;
          this.dataList[0].value = res.data.data.records.length
            ? res.data.data.records[0]._value
            : 0;
        }
      });
    },
    getIndexData() {
      // 在建项目数量
      appIndex({
        indexCode: "INDEX_ZB0001_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          // if (res.data.data.records.length != 0 && res.data.data.records[0] && res.data.data.records[0]._value)
          //   this.dataList[1].value = res.data.data.records[0]._value;
          this.dataList[1].value = res.data.data.records.length
            ? res.data.data.records[0]._value
            : 0;
        }
      });

      // 安环事故
      appIndex({
        indexCode: "ZB0084_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          // if (res.data.data.records.length != 0 && res.data.data.records[0] && res.data.data.records[0]._value)
          //   this.dataList[2].value = res.data.data.records[0]._value;
          this.dataList[2].value = res.data.data.records.length
            ? res.data.data.records[0]._value
            : 0;
        }
      });

      // 安全隐患
      appIndex({
        indexCode: "ZB00100_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          // if (res.data.data.records.length != 0 && res.data.data.records[0] && res.data.data.records[0]._value)
          //   this.dataList[3].value = res.data.data.records[0]._value;
          this.dataList[3].value = res.data.data.records.length
            ? res.data.data.records[0]._value
            : 0;
        }
      });
      // 培训次数
      appIndex({
        indexCode: "ZB0067_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          // if (res.data.data.records.length != 0 && res.data.data.records[0] && res.data.data.records[0]._value)
          //   this.dataList[4].value = res.data.data.records[0]._value;
          this.dataList[4].value = res.data.data.records.length
            ? res.data.data.records[0]._value
            : 0;
        }
      });
      // 安全生产工时
      appIndex({
        indexCode: "ZB00108_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          // if (res.data.data.records.length != 0 && res.data.data.records[0] && res.data.data.records[0]._value)
          //   this.dataList[5].value = res.data.data.records[0]._value;
          this.dataList[5].value = res.data.data.records.length
            ? res.data.data.records[0]._value
            : 0;
        }
      });
    },
    handleOpen() {
      this.$store.commit("SET_SHORTCUTMENU", false);
    },
    goBoard() {
      this.$router.push("/business/board/index");
    },
    getTicketList() {
      ticketType("WT_TICKET_TYPE").then((res) => {
        if (res.data.code == 200) {
          res.data.data.forEach((item) => {
            if (item.dictKey == "1") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/heightWork`,
                query: {
                  type: "add",
                },
              };
            } else if (item.dictKey == "2") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/wtBaseDirt`,
                query: {
                  type: "add",
                },
              };
            } else if (item.dictKey == "3") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/hotWork`,
                query: {
                  type: "add",
                },
              };
            } else if (item.dictKey == "4") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/hoistingWork`,
                query: {
                  type: "add",
                },
              };
            } else if (item.dictKey == "5") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/limitSpace`,
                query: {
                  type: "add",
                },
              };
            } else if (item.dictKey == "6") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/electricWork`,
                query: {
                  type: "add",
                },
              };
            } else if (item.dictKey == "7") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/openCircuitWork`,
                query: {
                  type: "add",
                },
              };
            } else if (item.dictKey == "10") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/wtBaseBpp`,
                query: {
                  type: "add",
                },
              };
            } else if (item.dictKey == "11") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/explodeWork`,
                query: {
                  type: "add",
                },
              };
            } else if (item.dictKey == "12") {
              item["routeObj"] = {
                path: `/jobSlip/jobTicketList/otherWork`,
                query: {
                  type: "add",
                },
              };
            }
          });
          this.kictectTypeList = res.data.data;
        }
      });
    },
    openTicket(data) {
      let code = data.split("-")[0];
      myWtTicketPage(1, 5, {
        createUser: this.userInfo.user_id,
        ticketCode: code,
      }).then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data;
          let ticketDetail = data.records[0];
          let item = this.kictectTypeList.find(
            (e) => e.dictKey === ticketDetail.ticketType
          );
          item.routeObj.query.ticketCode = ticketDetail.ticketCode;
          item.routeObj.query.id = ticketDetail.id;
          item.routeObj.query.type = "sign";
          item.routeObj.query.businessStatus = ticketDetail.businessStatus;
          this.$router.push(item.routeObj);
        }
      });
    },
    getMenuListFn() {
      getMenuList(1, 100).then((res) => {
        let menuData = this.defaultMenu.concat(res.data.data.records);
        if (menuData.length > 8) {
          menuData = menuData.slice(0, 8);
        }
        this.menuData = menuData;
      });
    },
    rightClick(item) {
      let menuName =
        item.menuNameEn != "" && item.menuNameEn != undefined
          ? item.menuNameEn
          : item.menuNameZh;
      if (item.id == undefined) {
        this.$message({
          type: "warning",
          message: "固定菜单不可删除!",
        });
        return;
      }
      this.$confirm(
        "此操作将删除" + " " + menuName + "菜单, 是否继续?",
        "提示(删除后请刷新本页面)",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      ).then(() => {
        remove(item.id).then(() => {
          this.getMenuListFn();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
        // 这里写的是确定删除的数据刷新 也就是 快捷菜单的新数据
      });
    },
    // 快捷菜单点击
    menuClick(item) {
      let urlHeader = item.menuPath.slice(0, 4);
      if ("http" != urlHeader) {
        this.$router.push({
          path: item.menuPath,
        });
      } else if (item.menuPath.indexOf("isNewWindow=true") != -1) {
        window.open(item.menuPath);
      } else {
        this.$router.push({
          path: `/myiframe/urlPath?name=${item.menuName}&src=${item.menuPath}`,
        });
      }
    },
    // bodyScale() {
    //   var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
    //   var scale = devicewidth / 1920; // 分母——设计稿的尺寸
    //   document.getElementById("homeIndex").style.zoom = scale; //放大缩小相应倍数
    // },
    async rowTrackPending(row) {
      let path = row.url.split("?")[0];
      if (path == "/#/process/external/iframe/detail") {
        window.open(row.url, "_blank");
      } else {
        this.$router.push(row.url);
      }
      // let param = Buffer.from(
      //   JSON.stringify({
      //     processId: row.id,
      //     taskId: row.taskId,
      //     processInsId: row.processInstanceId || row.processId,
      //     processDefKey: row.processDefKey,
      //   })
      // ).toString("base64");
      // //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
      // if (row.cilckFlag) {
      //   let sUrl =
      //     window.location.origin +
      //     "#/process/external/iframe/detail?p=" +
      //     param +
      //     "&isNewWindow=true&isSelectUser=1";
      //   window.open(sUrl, "_blank");
      // } else {
      //   let sUrl =
      //     window.location.origin +
      //     "#/process/external/iframe/detail?p=" +
      //     param +
      //     "&isNewWindow=true";
      //   window.open(sUrl, "_blank");
      // }
    },
    runNotice(item) {
      // this.noticeForm = item;
      // this.noticeShow = true;
      this.$router.push({
        path: "/notice/detail",
        query: {
          id: item.id,
        },
      });
    },
    urlToJson(url) {
      let obj = {},
        index = url.indexOf("?"), // 看url有没有参数
        params = url.substr(index + 1); // 截取url参数部分 name = aaa & age = 20

      if (index != -1) {
        // 有参数时
        let parr = params.split("&"); // 将参数分割成数组 ["name = aaa", "age = 20"]
        for (let i of parr) {
          // 遍历数组
          let arr = i.split("="); // 1） i name = aaa   arr = [name, aaa]  2）i age = 20  arr = [age, 20]
          obj[arr[0]] = arr[1]; // obj[arr[0]] = name, obj.name = aaa   obj[arr[0]] = age, obj.age = 20
        }
      }
      return obj;
    },
    runNews(item) {
      window.open(item.parameter_1);
    },
    runShare(item) {
      let path = item.shareLink.replace(/&amp;/g, "&");
      window.open(path);
      // let queryIndex = path.indexOf('?');
      // if (queryIndex !== -1) {
      //   let obj = this.urlToJson(path)
      //   let url = path.substring(path.lastIndexOf('/#/') + 3, queryIndex);
      //   this.$router.push({
      //     path: '/' + url,
      //     query: obj
      //   })
      // } else {
      //   this.$message({
      //     message: '未获取到页面路径',
      //     type: 'warning'
      //   });
      // }
    },
    changeChatStatus() {
      if (!this.openChatState) {
        window.$chat.emit("openChatWin");
      } else {
        window.postMessage({ type: "closeWindow" }, "*");
      }
    },
    getDate() {
      var date = new Date();
      var day = date.getDay();
      const currentTime = new Date().getHours();
      if (currentTime < 12) {
        this.timeOfDay = "早上";
      } else if (currentTime >= 12 && currentTime < 18) {
        this.timeOfDay = "下午";
      } else {
        this.timeOfDay = "晚上";
      }
      var arr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      this.dateStr = dateFormat(new Date(), "yyyy年MM月dd日");
      this.weekStr = arr[day];
    },
    getTodoList() {
      sinomatodo(1, 10, { ownerName: this.userInfo.account }).then((res) => {
        if (res.data.code == 200) {
          this.todoList = res.data.data.records;
          this.todoTotal = res.data.data.total;
          this.todoList.forEach((item, index) => {
            item.showTip = true;
            this.countTodoLength(item, index);
          });
        }
      });
    },
    // 判断tooltip是否展示
    countTodoLength(item, index) {
      this.$nextTick(() => {
        let element = document.getElementsByClassName("todoTitle" + index);
        if (element.length == 0) return;
        let boolean = element[0].scrollWidth > element[0].offsetWidth;
        item.showTip = !boolean;
      });
    },
    countTaskLength(item, index) {
      this.$nextTick(() => {
        let element = document.getElementsByClassName("taskTitle" + index);
        if (element.length == 0) return;
        let boolean = element[0].scrollWidth > element[0].offsetWidth;
        item.showTip = !boolean;
      });
    },
    getDataList() {
      this.getTodoList();
      // todoList(1, 20).then((res) => {
      //   if (res.data.code == 200) {
      //     this.todoList = res.data.data.records.slice(0, 10);
      //     this.todoTotal = res.data.data.total;
      //     this.todoList.forEach((item, index) => {
      //       item.showTip = true;
      //       this.countTodoLength(item, index);
      //     });
      //   }
      // });
      noticeList(1, 500).then((res) => {
        if (res.data.code == 200) {
          this.noticeList = res.data.data.records.slice(0, 10);
        }
      });
      shareList(1, 500).then((res) => {
        if (res.data.code == 200) {
          this.shareList = res.data.data.records.slice(0, 10);
        }
      });
      //20240403
      // appIndex("HJYW_NEWS_M").then((res) => {
      //   if (res.data.code == 200) {
      //     this.newsList = res.data.data.records.slice(0, 10);
      //   }
      // })
    },
    getMyTask() {
      let myTask = {};
      myTask.taskUser = this.userInfo.user_id;
      myTask.taskRole = this.userInfo.role_id;
      myTask.taskDept = this.userInfo.dept_id;
      getMyTaskList(myTask).then((res) => {
        // 截取前 10 条数据
        this.taskList = res.data.data.slice(0, 10);
        this.taskList.forEach((item, index) => {
          item.showTip = true;
          this.countTaskLength(item, index);
        });
        this.taskListComplete = res.data.data;
      });
    },
    statusFormatter(cellValue) {
      return this.statusDict[cellValue] || "";
    },
    taskTypeFormatter(cellValue) {
      return this.taskTypeDict[cellValue] || "";
    },
    runTodo() {
      if (this.todoActive == 0) {
        this.$router.push({
          path: `/plugin/workflow/process/todo`,
        });
      } else {
        this.$router.push({
          path: `/desk/taskTodo`,
        });
      }
    },
    goToTaskDetail(row) {
      console.log("我进了任务代办");
      if (row.taskType === 1) {
        if ("overseasCode" == row.taskCode || "domesticCode" == row.taskCode) {
          let formDate = formatTime(row.createTime, "yyyy-MM-dd");
          window.open(
            "/#/business/reportManager/fillReport/index?isNewWindow=true&reportCode=" +
              row.taskCode +
              "&statDate=" +
              formDate +
              "&fillDeptId=" +
              row.taskDept
          );
        } else {
          let formDate = formatTime(row.createTime, "yyyy-MM");
          window.open(
            "/#/business/reportManager/fillReport/index?isNewWindow=true&reportCode=" +
              row.taskCode +
              "&statDate=" +
              formDate +
              "&fillDeptId=" +
              row.taskDept
          );
        }
      } else if (row.taskType === 2) {
        getDept(row.taskDept).then((res) => {
          let dateForm = res.data.data;
          let deptName = JSON.parse(dateForm.deptName).zh;
          if (
            "overseasCode" == row.taskCode ||
            "domesticCode" == row.taskCode
          ) {
            let formDate = formatTime(row.createTime, "yyyy-MM-dd");
            window.open(
              "/#/business/reportManager/summaryReport/index?isNewWindow=true&reportCode=" +
                row.taskCode +
                "&statDate=" +
                formDate +
                "&fillDeptId=" +
                row.taskDept +
                "&fillDeptName=" +
                deptName
            );
          } else {
            let formDate = formatTime(row.createTime, "yyyy-MM");
            window.open(
              "/#/business/reportManager/summaryReport/index?isNewWindow=true&reportCode=" +
                row.taskCode +
                "&statDate=" +
                formDate +
                "&fillDeptId=" +
                row.taskDept +
                "&fillDeptName=" +
                deptName
            );
          }
        });
      } else if (row.taskType === 3) {
        this.$router.push({
          path: `/business/safetyenment/train/trainingEducation/index`,
          query: {},
        });
      } else if (row.taskType === 4) {
        this.$router.push({
          path: `/business/safetyenment/train/myExam/eduuserexam`,
          query: { taskId: row.taskCode },
        });
      } else if (row.taskType === 5) {
        this.$router.push({
          path: `/jobSlip/jobSlipInventory/add`,
          query: {
            type: "sign",
            taskId: row.taskCode,
          },
        });
      } else if (row.taskType === 7) {
        this.openTicket(row.taskCode);
      } else if (row.taskType == 10) {
        let code = "";
        if (row.taskCode.includes("-")) {
          code = row.taskCode.split("-")[0];
        } else {
          code = row.taskCode;
        }
        myWtTicketPage(1, 5, {
          type: "my",
          ticketCode: code,
          orgId: this.userInfo.dept_id,
          createDept: this.userInfo.dept_id,
        }).then(async (res) => {
          const data = res.data.data;
          var record = data.records[0];
          if (record.statuSort == 2) {
            //线下
            this.$router.push({
              path: "/jobSlip/jobTicketList/offline",
              query: {
                type: "flow",
                ticketCode: record.ticketCode,
                id: record.id,
                businessStatus: record.businessStatus,
                characters: record.characters,
                processInstanceId: record.processInstanceId,
              },
            });
          } else {
            //线上
            let item = this.kictectTypeList.find(
              (e) => e.dictKey == record.ticketType
            );
            item.routeObj.query.ticketCode = record.ticketCode;
            item.routeObj.query.id = record.id;
            item.routeObj.query.type = "flow";
            item.routeObj.query.businessStatus = record.businessStatus;
            item.routeObj.query.characters = record.characters;
            item.routeObj.query.processInstanceId = record.processInstanceId;
            this.$router.push(item.routeObj);
          }
        });
      } else if (row.taskType == 11) {
        // 隐患待办
        this.$router.push({
          path: `/hiddenTrouble/riskitemView/rectify/edit?id=${row.taskCode}&type=edit`,
        });
      }
    },
    getDictory() {
      // getDictionary({code: "notice"}).then((res) => {
      //   this.categoryOption = res.data.data;
      // });
      categoryTree().then((res) => {
        let data = this.fnTree(res.data.data);
        data.forEach((item) => {
          item.dictKey = item.id;
          item.dictValue = item.name;
        });
        this.categoryOption = data;
      });
    },
    fnTree(arr) {
      let data = JSON.parse(JSON.stringify(arr));
      let newData = [];
      const callback = (item) => {
        (item.children || (item.children = [])).map((v) => {
          callback(v);
        });
        delete item.children;
        newData.push(item);
      };
      data.map((v) => callback(v));
      return newData;
    },
    getSNNews() {
      let params = {
        indexCode: "SNZX_NEWS_M",
      };
      oneChartData(params).then((res) => {
        if (res.data.code == 200) {
          let news = [];
          res.data.data.records.forEach((item) => {
            news.push(item.parameter_3);
          });
          this.newsList = news;
        }
      });
    },
    openMore() {
      if (this.activeName == 0) {
        this.$router.push({
          path: `/desk/notice`,
        });
      }
      if (this.activeName == 1) {
        window.open("https://www.mee.gov.cn/ywdt/");
      }
      if (this.activeName == 2) {
        this.$router.push({
          path: `/desk/personalSharing`,
        });
      }
    },

    handleTabClick() {
      if (this.todoActive == 0) {
        this.timerTodoLeave = false;
        this.timerMyTaskLeave = true;
        this.getTodoList();
      } else {
        this.timerTodoLeave = true;
        this.timerMyTaskLeave = false;
        this.getMyTask();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-carousel__arrow {
  width: 24px;
  height: 24px;
}

//::v-deep .el-carousel__item {
//  width: 95%;
//  left: 2.5%;
//}

::v-deep .el-carousel__arrow--left {
  left: 0;
  background-color: #fbe5e3;
  display: none;
}

::v-deep .el-carousel__arrow--left:hover {
  background-color: #e64741;
}

::v-deep .el-carousel__arrow--right {
  right: 0;
  background-color: #fbe5e3;
  display: none;
}

::v-deep .el-carousel__arrow--right:hover {
  background-color: #e64741;
}

.disFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cardBox6 {
  width: calc(66.6% - 48px);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 #0000000f;
  padding: 16px 20px;
}

.cardBox3 {
  width: calc(100% - 38px);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 #0000000f;
  padding: 16px 20px;

  ::v-deep .el-tabs__content {
    height: calc(100% - 40px);
  }

  ::v-deep .el-tabs {
    height: 100%;
  }

  ::v-deep .el-tab-pane {
    height: 100%;
  }

  ::v-deep .el-table__body-wrapper {
    height: calc(100% - 44px);
    overflow-y: auto;
  }
}

.cardTitleHome {
  width: 100%;
  color: #1d2129;
  font-size: 18px;
  margin-bottom: 11px;

  .board {
    font-size: 16px;
    margin-left: 16px;

    .el-icon-right {
      font-size: 16px;
    }
  }

  ::v-deep .el-tabs__content {
    height: calc(100% - 40px);
  }

  ::v-deep .el-tabs {
    height: 100%;
  }

  ::v-deep .el-tab-pane {
    height: 100%;
  }

  ::v-deep .el-table__body-wrapper {
    height: calc(100% - 44px);
    overflow-y: auto;
  }
}

.leftChange {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin: 103px 0;
  cursor: pointer;
}

.rightChange {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin: 103px 0;
  cursor: pointer;
}

.targetBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}

.targetLittle {
  width: 30%;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 7px;
  background: linear-gradient(125deg, #ebf0f694 2.35%, #f3f6fa94 94.99%);
  display: flex;
  align-items: center;
}

.targetTitle {
  width: 100%;
  display: flex;
  align-items: center;
  color: #333333;
}

.iconContent {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.disFlex-left {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.income {
  width: 25px;
  height: 25px;
}

.number {
  font-size: 26px;
  font-weight: bold;
  color: #15161a;
}

.menu-box {
  width: calc(33.3% - 8px);
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 #0000000f;

  .quick-app-list {
    padding: 16px 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .menu-title {
      width: 100%;
      color: #1d2129;
      font-size: 18px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f4f5f6;
      padding-bottom: 20px;
    }

    .list-content {
      display: flex;
      flex-wrap: wrap;
      height: calc(100% - 56px);

      .quick-app-item {
        width: 25%;
        text-align: center;
        font-weight: 400;
        color: #666666;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        padding-top: 7px;

        .imageBox {
          width: 40px;
          height: 40px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          i {
            color: #ffffff;
            font-weight: bold;
            font-size: 20px;
          }
        }

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 80px;
        }
      }
    }
  }
}

.unit {
  font-size: 13px;
  color: #484a4d;
  margin-left: 6px;
}

.homeHtBox {
  font-size: 14px;
  color: #484a4d;
  display: flex;
  height: 20px;
  line-height: 20px;
  margin-top: 8px;
}

.htImg {
  width: 14px;
  height: 14px;
  margin: 3px 0;
}

.htNumber {
  margin: 0 7px;
}

.morningBox {
  width: 100%;
  background-image: url("~@/assets/images/morning.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 17px;
  height: calc(50% - 8px);
}

.assistantBox {
  width: 100%;
  background-image: url("~@/assets/images/assistant.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(50% - 8px);
  cursor: pointer;
}

.callBox {
  color: #15161a;
  font-size: 25px;
  font-weight: bold;
  padding-top: 11%;
  margin-left: 20px;
}

.dateAndWeather {
  color: #484a4d;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 14px;
}

.taskHomeBox {
  width: 200px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  padding: 14px 0;
}

.taskHomeBox:hover {
  background-color: #f8fafb;
}

.taskHomeImg {
  width: 40px;
  height: 40px;
  margin: 7px 0;
  margin-right: 20px;
}

.taskNumber {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 6px;
}

.taskName {
  font-size: 14px;
  color: #484a4d;
}

.needTodo {
  line-height: 25px;
  display: flex;
  justify-content: space-between;
}

.allNameBox {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  position: absolute;
  top: 11px;
  right: 0;
}

.todoContentBox {
  width: 100%;
  overflow: auto;
}

.todoContentLittle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 6px;
}

.todoContentLittle:hover {
  text-decoration: underline;
}

.todoContentLittle:last-child {
  margin-bottom: 0;
}

.commonReport {
  width: 33.3%;
  text-align: center;
  font-size: 14px;
  color: #484a4d;
  cursor: pointer;
}

.commonReport span {
  white-space: normal;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
}

.commonReportImg {
  width: 40px;
  height: 40px;
  display: block;
  margin: auto;
  margin-bottom: 12px;
}

.serviceHomeBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.serviceLittleBox {
  width: 27%;
  border-radius: 4px;
  background-color: #f7f7f8;
  padding: 10px;
  color: #484a4d;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
}

.serviceLittleBox:nth-child(3n) {
  margin-right: 0px;
}

.servicImg {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

::v-deep .el-tabs__content {
  background-color: transparent;
}

::v-deep .el-tabs__header {
  margin: 0;
}

.moreTitle {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  position: absolute;
  top: 27px;
  right: 20px;
}

.newsBox {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.newsBox::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.newsLittle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  border-bottom: 1px solid #ebeef5;
  cursor: pointer;
  color: #606266;
}

.point {
  padding: 5px;
}

.point::after {
  content: "●";
  font-size: 8px;
}

.newsTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  width: 60%;
}

.newsTime {
  width: 40%;
  text-align: right;
  font-size: 14px;
}

.newsLittle:hover {
  color: #2979ff !important;
}

.noticeTitle {
  width: 90%;
  margin: auto;
  text-align: center;
  color: #333333;
  font-size: 26px;
  font-weight: bold;
}

.noticeTime {
  width: 100%;
  text-align: left;
  color: #606266;
  font-size: 18px;
  padding-top: 36px;
  padding-bottom: 12px;
  border-bottom: 1px solid #c0c4cc;
}

.noticeContent {
  width: 100%;
}

::v-deep .el-table th {
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-table--medium .el-table__cell {
  padding: 5px 0;
}
</style>
