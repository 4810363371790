<template>
  <div>
    <head-layout head-title="待我审核"></head-layout>
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      class="home"
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="rowView"
      @page-current-change="onLoad"
      @page-size-change="onLoad">
    </grid-layout>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {reportReview as getList} from "@/api/reportFixationTasks";

export default {
  components: {HeadLayout, GridLayout},
  data() {
    return {
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      searchColumns: [
        {
          label: "报表名称",
          prop: "reportCode",
          type: "select",
          dicUrl: "/api/sinoma-report/acquisition/attributeFixation/authorityReportByAudit",
          dicData: [],
          props: {
            label: "templateName",
            value: "templateCode",
          },
          span: 4,
          placeholder: "请选择报表名称",
        },
        {
          label: "填报组织",
          prop: "fillDeptId",
          type: "select",
          dicUrl: "/api/sinoma-report/acquisition/attributeFixation/reportReviewListByAudit",
          dicData: [],
          props: {
            label: "deptName",
            value: "id",
          },
          span: 4,
          placeholder: "请选择填报组织",
        },
      ],
      tableData: [],
      tableLoading: false,
      tableOption: {
        menu: false,
        index: true,
        indexLabel: "序号",
        linklabel: "reportName",
        column: [
          {
            label: "报表名称",
            prop: "reportName",
            align: "center",
            overHidden: true,
          },
          {
            label: "填报组织",
            prop: "fillDeptName",
            align: "left",
            overHidden: true,
          },
          {
            label: "统计日期",
            prop: "statDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "通知填报日期",
            prop: "taskInDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "截至日期",
            prop: "deadlineFillingDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "待审批数",
            prop: "pendingApproval",
            align: "center",
            overHidden: true,
          },
          {
            label: "已审批数",
            prop: "adoptApproval",
            align: "center",
            overHidden: true,
          },
          // {
          //   label: "待提交数",
          //   prop: "totalApprovals",
          //   align: "center",
          //   overHidden: true,
          // },
        ]
      }
    }
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.selectionList = [];
      });
    },
    rowView(row) {
      this.$emit('runApproval',row)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .home .avue-crud .el-table {
  height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}
</style>
