<template>
  <div>
      <el-container>
          <el-main>
              <head-layout
                  head-title="劳保用品领用记录"
                  :head-btn-options="headBtnOptions"
                  @head-add="handleAdd"
                  @head-import="headImport"
                  @head-del="rowRemove"
              />
              <grid-head-layout
                  ref="gridHeadLayout"
                  :search-columns="searchColumns"
                  @grid-head-search="gridHeadSearch"
                  @grid-head-empty="gridHeadEmpty"
                  ></grid-head-layout>
              <grid-layout
              ref="gridLayOut"
                  :table-options="tableOptions"
                  :table-data="tableData"
                  :table-loading="tableLoading"
                  :data-total="page.total"
                  :page="page"
                  :editType="inline"
                  @page-current-change="handleCurrentChange"
                  @page-size-change="handleSizeChange"
                  @page-refresh-change="onLoad"
                  @gird-handle-select-click="selectionChange"
                  >
                    <template #issueCode="{row,index}">
                        <el-link type="primary" @click="rowView(row,index)">{{ row.issueCode }}</el-link>
                    </template>
                    <template #customBtn="{row,index}">
                        <template v-if="row.issueStatus == 'prepare' && row.createUser == userInfo.user_id">
                        <el-button  type="text" size="small" @click="rowEdit(row,index)"> 编辑 </el-button>
                        <el-button  type="text" size="small" @click="rowRemove(row,index)"> 删除 </el-button>
                        </template>
                        <template v-else>
                        <el-button  type="text" size="small" @click="rowView(row,index)"> 查看 </el-button>
                        </template>
                    </template>
              </grid-layout>
          </el-main>
      </el-container>
      <el-dialog title="劳保用品领用数据导入" append-to-body :visible.sync="excelBox" width="555px">
        <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter">
            <template slot="excelTemplate">
                <el-button type="primary" @click="handleTemplate">
                点击下载<i class="el-icon-download el-icon--right"></i>
                </el-button>
            </template>
        </avue-form>
      </el-dialog>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
// import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { ohissuePage, ohissueRemove } from '@/api/healthManage/appliance'
import {mapGetters} from 'vuex'
import {exportBlob} from "@/api/common";
import {downloadXls} from "@/util/util";
export default {
  components: {
      HeadLayout,
      GridLayout,
  },

  data () {
      return {
          searchColumns: [
              {
                  prop: "issueCode",
                  placeholder: "请输入记录编号",
                  span: 4,
              },
              {
                  prop: "userName",
                  placeholder: "请输入领用人",
                  span: 4,
              },
              {
                  prop: "issueDat",
                  startPlaceholder: "领用开始日期",
                  endPlaceholder: "结束日期",
                  span: 4,
                  type: 'daterange',
              },
          ],
          tableData: [],
          tableLoading: false,
          page: {
              pageSize: 20,
              currentPage: 1,
              total: 0
          },
          query: {},
          selectionList: [],
          excelBox: false,
          excelForm: {},

      }
  },

  created () {
      this.onLoad(this.page)
  },

  computed: {
      ...mapGetters(["permission", 'colorName',"userInfo"]),
      permissionList() {
          return {
              addBtn: this.vaildData(this.permission.appliance_add, true),
              viewBtn: this.vaildData(this.permission.appliance_view, true),
              editBtn: this.vaildData(this.permission.appliance_edit, true),
              delBtn: this.vaildData(this.permission.appliance_delete, true),
              importBtn: this.vaildData(this.permission.appliance_import, true),
          };
      },

      headBtnOptions() {
          let buttonBtn = [];
          if(this.permissionList.addBtn){
              buttonBtn.push(
              {
                  label: '新增',
                  emit: "head-add",
                  type: "button",
                  icon: ""
              });
          }
          if(this.permissionList.importBtn){
              buttonBtn.push(
              {
                  label: '导入',
                  emit: "head-import",
                  type: "button",
                  icon: ""
              });
          }
          if(this.permissionList.delBtn){
              buttonBtn.push(
              {
                  label: '删除',
                  emit: "head-del",
                  type: "button",
                  icon: ""
              });
          }
          return buttonBtn;
      },

      tableOptions () {
          return{
              customRowAddBtn: false,
              menuWidth: 260,
              menu: this.type == 'view'? false: true,
              selection: this.type == 'view'? false: true,
              linklable: 'issueCode',
              column: [
                  {
                      label: "领用记录编号",
                      prop: "issueCode",
                      align: "center",
                      overHidden: true,
                      slot: true
                  },
                  {
                      label: "领用人",
                      prop: "userName",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: "所在部门",
                      prop: "userDept",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: "物资名称",
                      prop: "itemNames",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: "领用日期",
                      prop: "issueDat",
                      align: "center",
                      overHidden: true,
                      width: '120px',
                  },
                  {
                      label: "状态",
                      prop: "issueStatus",
                      align: "center",
                      // dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=',
                      // props: {
                      //     label: 'dictValue',
                      //     value: 'dictKey',
                      // },
                      dicData: [
                            { label: "完成", value: "finished" },
                            { label: "草稿", value: "prepare" },
                        ],
                      overHidden: true,
                  },
              ],
          }
      },

      excelOption () {
        return {
            submitBtn: false,
            emptyBtn: false,
            column: [
            {
                label: '模板上传',
                prop: 'excelFile',
                type: 'upload',
                drag: true,
                loadText: '模板上传中，请稍等',
                span: 24,
                propsHttp: {
                res: 'data'
                },
                tip: '请上传 .xls,.xlsx 标准格式文件',
                action: `/api/sinoma-hse-prj/ohissue/import?orgId=${this.userInfo.dept_id}&orgCode=${this.userInfo.deptCode ? this.userInfo.deptCode : ''}`
            },
            {
                label: '模板下载',
                prop: 'excelTemplate',
                formslot: true,
                span: 24,
            }
            ]
        }
      },
  },

  methods: {
    handleAdd () {
        this.$router.push({
            path: '/business/healthManage/appliance/form',
            query: {
                type: 'add'
            }
        })
    },
      onLoad(page, params = {}) {
          this.tableLoading = true;
          this.page = page;
          params.orgId = this.userInfo.dept_id
          let queryObj = {...this.query}
          if(this.query.issueDat)delete queryObj.issueDat
          ohissuePage(page.currentPage, page.pageSize, Object.assign(params, queryObj)).then(res => {
              const data = res.data.data;
              this.$refs.gridLayOut.page.total = data.total;
              this.tableData = data.records;
              this.tableLoading = false;
              this.$refs.gridLayOut.selectionClear()
          });
      },
      //页数改变
      handleCurrentChange(e) {
          this.page.currentPage = e.currentPage
          this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
      },
      //size改变
      handleSizeChange(e) {
          this.page.pageSize = e.pageSize
          this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
      },
      // 搜索
      gridHeadSearch(query) {
          const data = this.query
          this.page.currentPage = 1;
            query.startTime = query.issueDat ? query.issueDat[0] : ''
            query.endTime = query.issueDat ? query.issueDat[1] : ''
          this.query = {...data, ...query};
          this.onLoad(this.page)
      },
      // 重置
      gridHeadEmpty(query) {
          this.page.currentPage = 1;
          const data = this.query
          this.query = {...data, ...query};
          this.onLoad(this.page)
      },

      selectionChange (list) {
        this.selectionList = list
      },

      rowEdit (row,index) {
        this.$router.push({
            path: '/business/healthManage/appliance/form',
            query: {
                row: JSON.stringify({id:row.id}),
                type: 'edit'
            }
        })
      },
      rowRemove (row,index) {
        let type = this.selectionList.find(val => val.createUser != this.userInfo.user_id)
        if(type && type.id){
            this.$message.warning('非当前账户录入信息不得操作')
            return
        } else {
            let ids = ''
            if(row) {
                ids = row.id
            } else {
                ids = this.selectionList.map(val=>val.id).toString()
                if(!ids){
                    this.$message.warning('请选择操作数据')
                }
            }
            if(ids){
                this.$confirm("确认删除当前数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                        ohissueRemove(ids).then((res) => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功")
                            this.onLoad(this.page);
                        }
                    });
                }).catch((error) => {
                        this.$message({
                            message: "取消删除",
                            type: "info",
                        });
                    });
            }
        }
      },
      rowView (row) {
        this.$router.push({
            path: '/business/healthManage/appliance/form',
            query: {
                row: JSON.stringify({id:row.id}),
                type: 'view'
            }
        })
      },

      headImport () {
        this.excelBox = true
      },

      handleTemplate() {
            exportBlob(`/api/sinoma-hse-prj/ohissue/exportTemplate`).then(res => {
                downloadXls(res.data, "劳保用品领用模板.xlsx");
            })
      },

      uploadAfter(res, done, loading, column) {
            this.page.currentPage = 1
            this.excelBox = false;
            this.onLoad(this.page);
            this.excelForm.excelFile = [];
            done();
        },
  }
}
</script>

<style lang="scss" scoped>
.file-name{
  color: #6F91C1;
  cursor: pointer;
  text-decoration: underline;
}
</style>
