import riskView from "@/views/hiddenTrouble/riskItemView/riskView";
import {mapGetters} from "vuex";
import {fieldInfo} from '@/api/base/length'

export default {
  mixins: [riskView],
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  data() {
    return {
      searchForm:{},
      tableOption: {
        selectable: (row) => {
          return row.hdStatus == 'prepare' && this.userInfo.user_id == row.createUser
        },
        linklabel: "hdCode",
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "状态",
            prop: "hdStatus",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: fieldInfo.Words4.align,
            minWidth: fieldInfo.date.length,
            overHidden: true,
            slot: true,
          },
          {
            label: '项目名称',
            prop: 'organizationName',
            align: 'left',
            overHidden: true,
            width: 200,
            formatter: (value) => {
              return (value.organizationName == -1 || !value.organizationName) ? '' : value.organizationName
            }
          },
          {
          label: "隐患编号",
          prop: "hdCode",
          align: "left",
          overHidden: true,
          width: 200
        },
          {
            label: "隐患描述",
            prop: "hdDesc",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "隐患级别",
            prop: "hdLevel",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: fieldInfo.Words5.align,
            minWidth: fieldInfo.Words5.length,
            overHidden: true,
            slot: true,
          },
          // {
          //   label: "隐患地点",
          //   prop: "location",
          //   align: fieldInfo.fullAddress.align,
          //   minWidth: fieldInfo.fullAddress.length,
          //   overHidden: true,
          // },
          {
            label: "报告人",
            prop: "reportUserName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
          },
          {
            label: "整改单位",
            prop: "rectifyDeptName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true,
          },
          {
            label: "整改人",
            prop: "rectifyUserName",
            align: fieldInfo.name_company.align,
            overHidden: true,
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },
          {
            label: "整改期限",
            prop: "rectifyLimitDate",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
            formatter: (value) => {
              return value.rectifyLimitDate ? value.rectifyLimitDate.slice(0,10) : ''
            }
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      isCycle: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [{
        label: "新增",
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: 'primary',
      },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: ""
        }
      ],
      searchColumns: [{
        label: '隐患编号',
        prop: "hdCode",
        span: 3,
        placeholder: '请输入隐患编号'
      }, {
        label: '隐患描述',
        prop: "hdDesc",
        span: 3,
        placeholder: '请输入隐患描述'
      },
        {
          label: '地点',
          prop: "location",
          span: 3,
          placeholder: '请输入地点'
        },
        {
          label: '隐患级别',
          prop: "hdLevel",
          span: 2,
          type: 'select',
          placeholder: '请选择隐患级别',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        }, {
          label: '状态',
          prop: "hdStatus",
          span: 3,
          type: 'select',
          placeholder: '请选择状态',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          label: '开始时间',
          prop: "startTime",
          type: 'date-format',
          span: 2,
          placeholder: '请选择开始时间'
        },
        {
          label: '结束时间',
          prop: "endTime",
          type: 'date-format',
          span: 2,
          placeholder: '请选择结束时间'
        }, {
          activeText: '是否超期',
          prop: "isTimeOut",
          type: 'switch',
          activeValue:1,
          inactiveValue:0,
          span: 2,
        },
      ],
    }
  },
mounted()
{


  },
  methods:{
    initLine(myChart,data){
      let seriesData=[]
      for (const dataKey in data) {
            let item=data[dataKey]
        seriesData.push({
          name: this.hdLevel[dataKey],
          type: "line",
          itemStyle:{
            color:this.hdLevelColor[dataKey],
          },
          smooth: true,
          symbolSize: 8,
          data: item,
        })
      }

      let option = {
        tooltip: {
          show: true,
          trigger: "axis",
        },
        legend: {
          show: true,
          x: "center",
          y: "15",
          icon:'circle',
          data: this.hdLevelText,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              formatter: "{value}",
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#11366e",
              },
            },
          },
        ],
        series: seriesData
      };
      option && myChart.setOption(option);
    },
    initPie(myChart,data){
      let that=this;
      let newData=data.map(item=>{
            return   { value: item.countNumber,groupId:item.hdLevel, name: item.name || that.hdLevel[item.hdLevel] }
        })
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },

        series: [
          {
            zlevel:1111,
            name: '',
            type: 'pie',
            radius:[0, '50%'],
            labelLine: {
              length: 30
            },
            itemStyle:{
              color:function (colors) {
                return that.hdLevelColor[colors.data.groupId]
              },
            },
            data:newData
          }
        ]
      };
      option && myChart.setOption(option);
    },
    initPie1(myChart,data){
      let newData=[]
      seriesDataFun(data)
      function seriesDataFun(){
        newData=data.map(item=>{
            return   { value: item.countNumber,groupId:item.hdLevel, name: item.name || item.deptName}
        })
      };
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },

        series: [
          {
            radius:[0, '50%'],
            zlevel:1111,
            name: '',
            type: 'pie',
            labelLine: {
              length: 30
            },
            data:newData
          }
        ]
      };
      option && myChart.setOption(option);
    },
  },
}
