<template>
  <div class="main-content">
    <head-layout
      head-title="重大危险源备案清单"
      :head-btn-options="headBtnOptions"
      @head-add="addList"
      @head-export="headExport"
    >
    </head-layout>

    <grid-head-layout
      ref="searchForm"
      v-model="searchForm"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset"
    />

    <grid-layout
      ref="gridLayout"
      :data-total="total"
      :gridRowBtn="gridRowBtn"
      :page="page"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :tableOptions="tableOptions"
      @page-size-change="getList"
      @page-current-change="getList"
      @page-refresh-change="getList"
      @grid-edit="rowEdit"
      @grid-romve="rowDel"
      :search-columns="searchColumns"
    >
      <template slot="endDate" slot-scope="{ row }">
        {{ row.startDate + "至" + row.endDate }}
      </template>

      <template #customBtn="{ row, index }">
        <el-button size="small" type="text" @click.stop="editItem(row, index)">
          编辑
        </el-button>
        <el-button
          size="small"
          type="text"
          @click.stop="removeItem(row, index)"
        >
          删除
        </el-button>
      </template>
    </grid-layout>
    <el-dialog
      :title="dtitle"
      v-if="dialogVisible"
      append-to-body
      :visible.sync="dialogVisible"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :rules="rules"
        class="from"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="重大危险源名称" prop="dangersName">
              <el-input
                v-model="form.dangersName"
                placeholder="请输入重大危险源名称"
                clearable
                maxlength="255"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="重大危险源所在地" prop="location">
              <el-input
                v-model="form.location"
                placeholder="请输入重大危险源所在地"
                clearable
                maxlength="255"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="生产单元(设计产能/实际产能)"
              prop="productionUnit"
            >
              <el-input
                v-model="form.productionUnit"
                placeholder="请输入生产单元"
                clearable
                maxlength="255"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="存储单元(设计储量/实际产量)"
              prop="storageUnit"
            >
              <el-input
                v-model="form.storageUnit"
                placeholder="请输入存储单元"
                clearable
                maxlength="255"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="临界量(t)" prop="critical">
              <el-input-number
                v-model="form.critical"
                :min="1"
                @input="handleInput"
                :precision="0"
                placeholder="请输入临界量(t)"
                clearable
                :maxlength="255"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="重大危险源等级" prop="dangersLevelName">
              <el-select
                v-model="levels"
                placeholder="请选择重大危险源等级"
                value-key="dictKey"
                @change="levelsChange"
                clearable
              >
                <el-option
                  v-for="item in levelArr"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备案号" prop="recordNum">
              <el-input
                v-model="form.recordNum"
                placeholder="请输入备案号"
                maxlength="255"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="startDate">
              <el-date-picker
                v-model="times"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="timesChange"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="包保责任人" prop="respUsers">
              <!-- <el-input
                v-model="form.respUsers"
                placeholder="请选择包保责任人"
                clearable
                @focus="personClick"
              ></el-input> -->
              <el-input v-model="form.respUsers" placeholder="请选择包保责任人">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="personClick"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button
          size="small"
          @click="dialogVisible = false"
          v-loading="saveLoading"
        >
          取消
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="saveLoading"
          v-if="type != 'detail'"
        >
          保存
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="人员选择"
      :visible.sync="personShow"
      width="80%"
      top="8vh"
    >
      <!-- <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectData"
      ></UserDetpDialog> -->
      <UserDeptMultipleDialog
        ref="UserDeptMultipleDialog"
        @select-all-data="selectData"
      ></UserDeptMultipleDialog>
    </el-dialog>
  </div>
</template>

<script>
// DM_DANGERS_LEVEL
import {
  getList,
  getDetail,
  addList,
  removeList,
} from "@/api/riskManage/majorRisk";
import { getDictionaryCode } from "@/api/system/dictbiz";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import { mapGetters } from "vuex";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";

export default {
  components: {
    HeadLayout,
    GridLayout,
    UserDeptMultipleDialog,
  },
  data() {
    return {
      dtitle: "重大危险源备案新增",
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0,
      },
      total: 0,
      saveLoading: false,
      dialogVisible: false,
      tableLoading: false,
      form: {
        respUsers: "",
      },
      times: [],
      levels: {},
      tableData: [],
      gridRowBtn: [],
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
        },
        {
          label: "导出",
          emit: "head-export",
          type: "button",
          icon: "",
        },
      ],
      levelArr: [],
      searchColumns: [
        // {
        //   placeholder: this.$t('cip.cmn.rule.inputWarning') + "计划年度",
        //   prop: "annualPlan",
        //   span: 4,
        // },
        // {
        //   placeholder: this.$t('cip.cmn.rule.inputWarning') + "培训组织",
        //   prop: "reportDept",
        //   span: 4,
        // },
        // {
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + "状态",
        //   type: "select",
        //   prop: "status",
        //   span: 4,
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_training_plan_status",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey"
        //   },
        //   dataType: "number",
        // },
      ],

      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 150,
        selection: false,
        indexLabel: "序号",
        index: true,
        column: [
          {
            label: "重大危险源名称",
            prop: "dangersName",
            align: "center",
            overHidden: true,
            width: 120,
          },
          {
            label: "重大危险源所在地址",
            prop: "location",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "重大危险源参数",
            align: "center",
            overHidden: true,
            width: 240,
            children: [
              {
                label: "生产单元(设计产能/实际产能)",
                prop: "productionUnit",
                align: "center",
                width: 120,
              },
              {
                label: "存储单元(设计储量/实际产量)",
                prop: "storageUnit",
                align: "center",
                width: 120,
              },
            ],
          },
          {
            label: "临界量(t)",
            prop: "critical",
            width: 120,
            align: "center",
            overHidden: true,
          },
          {
            label: "重大危险源等级",
            prop: "dangersLevelName",
            align: "center",
            overHidden: true,
            width: 120,
          },
          {
            label: "备案号",
            prop: "recordNum",
            align: "center",
            overHidden: true,
            width: 120,
          },
          {
            label: "有效期",
            prop: "endDate",
            align: "center",
            overHidden: true,
            slot: true,
            width: 240,
          },
          {
            label: "包保责任人",
            prop: "respUsers",
            align: "left",
            overHidden: true,
          },
        ],
      },
      selectionList: [],
      rules: {
        dangersName: [
          { required: true, message: "请输入重大危险源名称", trigger: "blur" },
        ],
        location: [
          {
            required: true,
            message: "请输入重大危险源所在地址",
            trigger: "blur",
          },
        ],
        productionUnit: [
          {
            required: true,
            message: "请输入生产单元(设计产能/实际产能)",
            trigger: "blur",
          },
        ],
        storageUnit: [
          {
            required: true,
            message: "请输入存储单元(设计储量/实际产量)",
            trigger: "blur",
          },
        ],
        critical: [
          { required: true, message: "请输入临界量(t)", trigger: "blur" },
        ],
        dangersLevelName: [
          {
            required: true,
            message: "请选择重大危险源等级",
            trigger: "change",
          },
        ],
        recordNum: [
          { required: true, message: "请输入备案号", trigger: "blur" },
        ],
        startDate: [
          {
            required: true,
            message: "请选择有效期",
            trigger: "change",
          },
        ],
        respUsers: [
          {
            required: true,
            message: "请选择包保责任人",
            trigger: "change",
          },
        ],
      },
      personShow: false,
      type: "add",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.getList(this.page);
    this.getDictionaryCode();
  },
  methods: {
    //   导出清单
    headExport() {
      exportBlob(
        `/api/sinoma-hse-prj/dmdangerslist/export?organizationId=${this.userInfo.dept_id}`
      ).then((res) => {
        downloadXls(res.data, `重大危险源备案清单-${dateNow()}.xls`);
      });
    },
    personClick() {
      this.personShow = true;
    },
    handleInput(value) {
      value = value.replace(/[^0-9]/g, "");
      if (value.indexOf(0) == 0) {
        value = "";
      }
      this.form.critical = value;
    },
    selectData(row) {
      this.personShow = false;
      // this.listForm.respUserId = row.id;
      // this.listForm.respUserName = row.realName;
      // this.listForm.respUserAccount = row.account;
      // this.listForm.respNickName = row.nickName;

      let newRow = row.selectAllData.map((item) => {
        return item.realName || item.nickName;
      });
      // this.form.respUsers = newRow.join(",");
      this.$set(this.form, "respUsers", newRow.join(","));
    },
    addList() {
      this.form = {
        respUsers: "",
      };
      this.times = [];
      this.levels = {};
      this.dtitle = "重大危险源备案新增";
      this.dialogVisible = true;
    },
    levelsChange() {
      if (this.levels) {
        // this.form.dangersLevel = this.levels.dictKey;
        // this.form.dangersLevelName = this.levels.dictValue;
        this.$set(this.form, "dangersLevel", this.levels.dictKey);
        this.$set(this.form, "dangersLevelName", this.levels.dictValue);
      }
    },
    timesChange() {
      if (this.times.length) {
        // this.form.startDate = this.times[0];
        // this.form.endDate = this.times[1];
        this.$set(this.form, "startDate", this.times[0]);
        this.$set(this.form, "endDate", this.times[1]);
      }
    },
    personChange(val) {
      this.$set(this.form, "respUsers", val);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          addList({ ...this.form, organizationId: this.userInfo.dept_id })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              }
              this.getList(this.page);
              this.saveLoading = false;
              this.dialogVisible = false;
            })
            .catch((error) => {
              this.$message({
                message: "保存失败",
                type: "warning",
              });

              this.saveLoading = false;
              this.dialogVisible = false;
            });
        }
      });
    },
    getDetail(id) {
      getDetail({ id }).then((res) => {
        this.form = res.data.data;
        this.times = [this.form.startDate, this.form.endDate];
        this.levels = {
          dictKey: this.form.dangersLevel,
          dictValue: this.form.dangersLevelName,
        };
        this.dialogVisible = true;
      });
    },

    editItem(row) {
      this.dtitle = "重大危险源备案编辑";
      this.getDetail(row.id);
    },
    removeItem(row) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeList({ ids: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getList(this.page);
          });
        })
        .catch((error) => {
          this.$message({
            message: "已取消删除",
            type: "success",
          });
        });
    },
    getDictionaryCode() {
      getDictionaryCode("DM_DANGERS_LEVEL").then((res) => {
        this.levelArr = res.data.data;
      });
    },
    getList(page, query = {}) {
      this.page = page;
      this.tableLoading = true;
      getList({
        organizationId: this.userInfo.dept_id,
        size: this.page.pageSize,
        current: this.page.currentPage,
        ...query,
      })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          }
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}
.main-content {
  width: 100%;
  height: 100%;
}

.headers ::v-deep .el-button {
  height: 28px;
}
::v-deep .bjs-powered-by {
  display: none;
}
::v-deep .el-range-editor--small.el-input__inner {
  width: 100%;
}
::v-deep .el-input-number.el-input-number--small {
  width: 100%;
}
</style>
