<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="rowView"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
    >
      <!-- <template #hdLevel="{ row }">
        <el-tag
          v-if="hdLevel[row.hdLevel]"
          :type="hdLevelIcon[row.hdLevel]"
          effect="dark"
          >{{ hdLevel[row.hdLevel] }}
        </el-tag>
      </template> -->
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";

import { holographyAdPage } from "@/api/safe/safeatyHolography";

export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      organizationId: "",
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          prop: "accTitle",
          placeholder: "事故标题",
          span: 4,
        },
      ];
    },
    tableOptions() {
      return {
        menu: false,
        selection: false,
        linklabel: "accCode",
        column: [{
          label: "事故编号",
          prop: "accCode",
          align: "center",
          overHidden: true,
          width: 150
        },
          {
            label: "事故标题",
            prop: "accTitle",
            align: "center",
            overHidden: true,
            width: 200
          },
          {
            label: "事故发生地点",
            prop: "address",
            align: "center",
            width: 170,
            overHidden: true,
          },
          {
            label: "事故等级",
            prop: "accLevel",
            align: "left",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=ACC_LEVEL`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true,
          },
          {
            label: "发生时间",
            prop: "accTime",
            width: 170,
            overHidden: true
          },

          {
            label: "报告时间",
            prop: "reportTime",
            align: "center",
            width: 120,
            overHidden: true
          },

          {
            label: "报告人",
            prop: "userName",
            align: "center",
            overHidden: true
          },

          {
            label: "状态",
            prop: "accStatus",
            align: "center",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=ACC_STATUS`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },
        ],
      };
    },
  },
  methods: {
    init(id) {
      this.organizationId = id;
      this.query = {}
      // this.query.organizationId = id
      this.page.currentPage = 1
      this.$refs.gridHeadLayout.searchForm = {}
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      // params.organizationId = this.organizationId;
        holographyAdPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query,{organizationId:this.organizationId})
        ).then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    rowView(row,path){
      this.$router.push({
        path: '/eventManagement/edit',
        query: {
          id: row.id,
          type: 'view',
        }
      })
    },
  },
};
</script>
<style scoped lang="scss"></style>
