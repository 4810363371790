<template>
  <div>
    <el-form :model="dataForm" :rules="rules" ref="form" label-width="100px" v-loading="formLoading" style="margin-right: 20px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="类型" prop="wasteType">
            <el-radio-group v-model="dataForm.wasteType" :disabled="type" @change="handleTypeChange">
              <el-radio label="solid">固废</el-radio>
              <el-radio label="danger">危废</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="名称" prop="wasteName">
            <el-input v-if="dataForm.wasteType==='solid'" v-model="dataForm.wasteName" :disabled="type" placeholder="请输入名称"  maxlength="200" show-word-limit>
              <template #append>
                <el-button
                    icon="el-icon-search"
                    @click="handleHistory"
                    size="small"
                    class="search-button"
                />
              </template>
            </el-input>
            <el-input v-else v-model="dataForm.wasteName" :disabled="true" placeholder="请输入名称">
              <template #append>
                <el-button
                    v-if="!type"
                    icon="el-icon-search"
                    @click="handleIsDanger"
                    size="small"
                    class="search-button"
                />
              </template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="入库时间" prop="receptTime">
            <el-date-picker
                :disabled="type"
                v-model="dataForm.receptTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择入库时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="入库量" prop="quantity">
            <el-input v-model="dataForm.quantity" placeholder="请输入入库数量" :disabled="type" maxlength="20" show-word-limit ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="来源" prop="source">
            <el-input v-model="dataForm.source" placeholder="请输入物品来源" :disabled="type"  maxlength="200" show-word-limit ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :rows="5" maxlength="500" placeholder="请输入备注" :disabled="type" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog
        title="危险废品名录"
        :visible.sync="wasteVisible"
        :modal-append-to-body="false"
        :append-to-body="true"
        width="40%"
    >
      <wasteList ref="wasteList" @getWaste="getWaste"></wasteList>
    </el-dialog>
    <el-dialog
        title="入库记录"
        :visible.sync="solidVisible"
        :modal-append-to-body="false"
        :append-to-body="true"
        width="40%"
    >
      <receptHistory ref="receptHistory" @getSolid="getSolid"></receptHistory>
    </el-dialog>
  </div>
</template>

<script>
import { detail } from "@/api/siteManagement/warehous";
import wasteList from "@/views/siteManagement/solidHazardousWaste/wasteList.vue";
import receptHistory from "@/views/siteManagement/solidHazardousWaste/receptHistory.vue";

export default {
  data() {
    return {
      type:false,
      wasteVisible: false,
      solidVisible: false,
      formLoading: false,
      dataForm: {
        id: '',
        wasteType: 'solid',
        wasteName: '',
        receptTime: '',
        quantity: '',
        source: '',
        remark: ''
      },
      tableData: [],
      rules: {
        wasteType: [{ required: true, message: '请选择类型', trigger: 'change' }],
        wasteName: [{ required: true, message: '请输入名称', trigger: 'blur' },
          { max: 200, message: '名称不能超过200个字符', trigger: 'blur' }],
        receptTime: [{ required: true, message: '请选择入库时间', trigger: 'change' }],
        quantity: [{ required: true, message: '请输入入库数量', trigger: 'blur' },
          { max: 20, message: '入库数量不能超过20个字符', trigger: 'blur' }],
        source: [{ required: true, message: '请输入物品来源', trigger: 'blur' },
          { max: 200, message: '物品来源不能超过200个字符', trigger: 'blur' }],
      }
    };
  },
  components:{
    wasteList,receptHistory
  },
  methods: {
    getWaste(data){
      this.dataForm.wasteName = data
      this.wasteVisible = false
    },
    getSolid(data){
      this.dataForm.wasteName = data
      this.solidVisible = false
    },
    handleIsDanger(){
      this.wasteVisible = true;
      this.$nextTick(() =>{
        this.$refs.wasteList.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
      })
    },
    handleTypeChange(value) {
      this.dataForm.wasteType = value;
      this.dataForm.wasteName = '';
    },
    formData(id,type) {
      detail({ id }).then(res => {
        this.dataForm = res.data.data;
        this.type = (type === 'view');
      });
    },
    handleHistory(){
      this.solidVisible = true
    }
  }
};
</script>
