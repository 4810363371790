<template>
    <div>
        <grid-layout
            ref="griLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOPtions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :editType="inline"
            @row-change="rowChange"
            :spanMethod="spanMethod"
            >
        </grid-layout>
    </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {mapGetters} from 'vuex'
import {ohhazardList, ohhazardSubmit, ohhazardRemove, areaList} from '@/api/healthManage/hazardAnalysis'
import { getPageCode } from "@/api/system/serialNumber";
export default {
    name: 'hazardAnalysis', // 危害分析

    components: {
        GridLayout
    },

    data () {
        return {
            tableData: [],
            tableLoading: false,
        }
    },

    computed: {
        ...mapGetters(["permission", 'userInfo']),

        gridRowBtn () {
            let btnList = [
                {
                    label: '选择',
                    emit: "row-change",
                    type: "button",
                    icon: "",
                }
            ]
            return btnList
        },
        tableOPtions () {
            return {
                selection: false,
                column: [
                    {
                        label: "区域/场所",
                        prop: "site",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "危害因素",
                        prop: "hazardName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "防护措施",
                        prop: "remark",
                        align: "center",
                        overHidden: true,
                    },
                ]
            }
        },
    },

    created () {
        // this.onLoad()
    },

    methods: {
        onLoad( params = {}) {
            this.tableData = []
            this.tableLoading = true;
            ohhazardList(Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.getNewData(data)
                this.tableLoading = false;
                this.$nextTick(() => {
                    this.$refs.griLayOut.$refs.grid.$refs.table.doLayout();
                    this.$refs.griLayOut.$refs.grid.refreshTable();
                });
            });
        },
        getNewData (data) {
            let arr = []
            data.map(val => {
                let i = 0
                if(arr.length==0){
                    arr.push({site:val.site,list: [val],len: 1})
                } else {
                    arr.map((item, index) => {
                        if(val.site == item.site){
                            item.len ++
                            item.list.push(val)
                        } else {
                            i ++;
                            if(i==arr.length){
                                arr.push({site:val.site,list: [val],len: 1})
                            }
                        }
                    })
                }
            })
            let table = []
            arr.forEach((val)=>{
                table = table.concat(val.list.map((v,index) => {
                    v.len = val.len
                    v.index = index
                    return v
                }))
            })
            this.tableData = table
        },

        spanMethod ({row, column, rowIndex, columnIndex}) {
            // 合并单元格
            if(columnIndex == 0){
                if(row.index==0){
                    return {
                       rowspan: row.len,
                       colspan: 1
                    }
                } else{
                    return{
                        rowspan: 0,
                        colspan: 1
                    }
                }
            }
        },

        rowChange (row) {
            this.$emit('callback',row)
        }
    }
}
</script>

<style lang="scss" scoped>
.input-icon{
    cursor: pointer;
}
</style>
