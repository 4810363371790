import { render, staticRenderFns } from "./addTask.vue?vue&type=template&id=284602da&scoped=true"
import script from "./addTask.vue?vue&type=script&lang=js"
export * from "./addTask.vue?vue&type=script&lang=js"
import style0 from "./addTask.vue?vue&type=style&index=0&id=284602da&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284602da",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/hse-web-227/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('284602da')) {
      api.createRecord('284602da', component.options)
    } else {
      api.reload('284602da', component.options)
    }
    module.hot.accept("./addTask.vue?vue&type=template&id=284602da&scoped=true", function () {
      api.rerender('284602da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projectSettings/algorithmManage/algorithmTask/addTask.vue"
export default component.exports