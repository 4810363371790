var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common_tree_handle", staticStyle: { width: "auto" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "common_tree_handle",
        },
        [
          _vm.titleShow
            ? _c(
                "el-header",
                {
                  ref: "elHeader",
                  staticStyle: {
                    "text-align": "right",
                    "font-size": "12px",
                    height: "auto",
                    "min-height": "46px",
                    "background-color": "#ffffff",
                    padding: "0 12px",
                    display: "flex",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        width: "100%",
                        "line-height": "45px",
                        "border-bottom": "1px solid #cccccc",
                      },
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            float: "left",
                            display: "flex",
                            "align-items": "center",
                          },
                          attrs: { span: "18" },
                        },
                        [
                          _vm.showTopTip == true
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      _vm.treeTitle ||
                                      this.$t("cip.cmn.title.typeTitle"),
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        "text-align": "left",
                                        "white-space": "nowrap",
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.treeTitle === ""
                                            ? _vm.$t("cip.cmn.title.typeTitle")
                                            : _vm.treeTitle
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "label",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "text-align": "left",
                                    "white-space": "nowrap",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.treeTitle === ""
                                        ? _vm.$t("cip.cmn.title.typeTitle")
                                        : _vm.treeTitle
                                    )
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                      _vm.isShowdig
                        ? _c(
                            "el-col",
                            { attrs: { span: "6" } },
                            [
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: { width: "100" },
                                    model: {
                                      value: _vm.visible,
                                      callback: function ($$v) {
                                        _vm.visible = $$v
                                      },
                                      expression: "visible",
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "popv_p",
                                        on: {
                                          click: function ($event) {
                                            return _vm.add()
                                          },
                                        },
                                      },
                                      [_vm._v("新增根节点组")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-setting",
                                          staticStyle: {
                                            "font-size": "18px",
                                            color: "black",
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isShowSearchInput
            ? _c(
                "el-row",
                {
                  staticStyle: {
                    padding: "14px 12px",
                    background: "#FFFFFF",
                    position: "relative",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "searchInput",
                    class: _vm.popoverShow ? "inputWidth" : "",
                    attrs: {
                      size: "small",
                      placeholder: _vm.$t(
                        "cip.cmn.msg.warning.filterateWarning"
                      ),
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _vm.popoverShow
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "80",
                            trigger: "hover",
                          },
                        },
                        [
                          _vm.treeExpand == false && _vm.expandShow
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "font-size": "14px" },
                                  attrs: { type: "text", size: "mini" },
                                  on: { click: _vm.openAll },
                                },
                                [_vm._v("展开全部")]
                              )
                            : _vm._e(),
                          _vm.treeExpand == true && _vm.expandShow
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "font-size": "14px" },
                                  attrs: { type: "text", size: "mini" },
                                  on: { click: _vm.openAll },
                                },
                                [_vm._v("收缩全部")]
                              )
                            : _vm._e(),
                          _vm.isInclude == false
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0",
                                    "font-size": "14px",
                                  },
                                  attrs: { type: "text", size: "mini" },
                                  on: { click: _vm.includeDown },
                                },
                                [_vm._v("包含下级")]
                              )
                            : _vm._e(),
                          _vm.isInclude == true
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0",
                                    "font-size": "14px",
                                  },
                                  attrs: { type: "text", size: "mini" },
                                  on: { click: _vm.includeDown },
                                },
                                [_vm._v("不包含下级")]
                              )
                            : _vm._e(),
                          _c("el-button", {
                            staticClass: "moreBtn",
                            attrs: {
                              slot: "reference",
                              type: "primary",
                              icon: "el-icon-more",
                            },
                            slot: "reference",
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("el-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "commonTree",
            staticStyle: {
              "font-size": "12px",
              "border-radius": "4px",
              padding: "0 5px 0 12px",
            },
            attrs: {
              data: _vm.treeChangeData,
              props: _vm.defaultProps,
              "default-expand-all": _vm.treeExpand,
              "highlight-current": true,
              "show-checkbox": _vm.showCheckbox,
              "check-strictly": _vm.checkStrictly,
              "default-expanded-keys": _vm.defaultExpandedKeys,
              "expand-on-click-node": false,
              "default-checked-keys": _vm.defaultCheckedKeys,
              "current-node-key": _vm.currentNodeKey,
              "node-key": _vm.nodeKey,
              "filter-node-method": _vm.filterNode,
            },
            on: {
              "node-click": _vm.nodeClick,
              "check-change": _vm.handleCheckChange,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ node, data }) {
                  return _c(
                    "span",
                    { staticStyle: { display: "flex" } },
                    [
                      data.type == "node"
                        ? _c("i", {
                            staticClass: "el-icon-folder",
                            staticStyle: { "line-height": "28px" },
                          })
                        : data.type == "camera"
                        ? _c("i", {
                            staticClass: "el-icon-camera-solid",
                            staticStyle: { "line-height": "28px" },
                          })
                        : _c("i", {
                            staticClass: "el-icon-box",
                            staticStyle: { "line-height": "28px" },
                          }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                            "white-space": "nowrap",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            display: "block",
                          },
                          style: { width: 190 - (node.level - 1) * 18 + "px" },
                        },
                        [_vm._v(_vm._s(node.label))]
                      ),
                      _c(
                        "el-dropdown",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isShowMore,
                              expression: "isShowMore",
                            },
                          ],
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _c("i", { staticClass: "el-icon-more" }),
                          ]),
                          _vm.pageType != "camera"
                            ? _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  data.type != "ai_box"
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addChild(node, "node")
                                            },
                                          },
                                        },
                                        [_vm._v("新增节点组")]
                                      )
                                    : _vm._e(),
                                  data.type != "ai_box"
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addChild(
                                                node,
                                                "ai_box"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("新增AI盒子")]
                                      )
                                    : _vm._e(),
                                  data.type != "ai_box"
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addChild(
                                                node,
                                                "server"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("新增AI服务器")]
                                      )
                                    : _vm._e(),
                                  data.type == "ai_box"
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.getDrag(node)
                                            },
                                          },
                                        },
                                        [_vm._v("移动")]
                                      )
                                    : _vm._e(),
                                  data.parentId != "0"
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.getdelete(node)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  data.type != "camera"
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addChild(
                                                node,
                                                "camera"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("新增摄像头")]
                                      )
                                    : _vm._e(),
                                  data.type == "camera"
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.getdelete(node)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.isZoom
        ? _c(
            "div",
            [
              _vm.show
                ? _c("el-button", {
                    staticClass: "rightBtn",
                    staticStyle: {
                      "margin-right": "5px",
                      "clip-path": "polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%)",
                      background: "#d2d3d6",
                    },
                    attrs: {
                      circle: "",
                      type: "primary",
                      icon: "el-icon-arrow-right",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.click_arrow(0)
                      },
                    },
                  })
                : _c("el-button", {
                    staticClass: "rightBtn",
                    staticStyle: {
                      "margin-right": "5px",
                      "clip-path": "polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%)",
                      background: "#d2d3d6",
                      left: "-30px",
                    },
                    attrs: {
                      circle: "",
                      type: "primary",
                      icon: "el-icon-arrow-left",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.click_arrow(1)
                      },
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }