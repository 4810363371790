<template>
    <div class="">
      <transition name="el-zoom-in-top">
        <data-source-add v-if="options.showAdd" :data="options.data" @showCard="showCard"></data-source-add>
      </transition>
      <transition name="el-zoom-in-top">
        <data-source-edit v-if="options.showEdit" :data="options.data" @showCard="showCard"></data-source-edit>
      </transition>
      <transition name="el-zoom-in-bottom">
        <data-source-detail v-if="options.showDetail" :data="options.data" @showCard="showCard"></data-source-detail>
      </transition>
    </div>
  </template>
  
  <script>
  import DataSourceAdd from './DataSourceAdd'
  import DataSourceEdit from './DataSourceEdit'
  import DataSourceDetail from './DataSourceDetail'
  
  export default {
    name: 'DataSource',
    components: { DataSourceAdd, DataSourceEdit, DataSourceDetail },
    data () {
      return {
        options: {
          data: {},
          showAdd: false,
          showEdit: false,
          showDetail: false
        }
      }
    },
    mounted(){
      if(this.$route.query.type=='add'){
        this.options.showAdd = true
      }
      if(this.$route.query.type=='see'){
        this.options.showDetail = true
      }
      if(this.$route.query.type=='edit'){
        this.options.showEdit = true
      }
    },
    methods: {
      showCard (data) {
        Object.assign(this.options, data)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  