var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-box riskIdentification" },
    [
      _c(
        "div",
        { staticClass: "flex-container" },
        [
          _c("nodeCommonTree", {
            ref: "commonTreeCore",
            staticClass: "introduceTree",
            attrs: {
              treeTitle: "节点配置",
              pageType: "camera",
              "node-key": "id",
              isLeafIcons: "",
              isShowMore: "",
              risk: "",
              defaultExpandedKeys: _vm.defaultExpandedKeys,
              treeData: _vm.corePersonnelTreeData,
              defaultProps: _vm.defaultProps,
              showCheckbox: false,
            },
            on: {
              getTreeAdd: _vm.getTreeAdd,
              getTreeAddChild: _vm.getTreeAddChild,
              getTreeDelete: _vm.getTreeDelete,
              getTreeEdit: _vm.getTreeEdit,
              getNodeClick: _vm.TreeNodeClick,
            },
          }),
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _vm.activeTab == "node"
                ? _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基础信息", name: "base" } },
                        [
                          _c("nodeBase", {
                            attrs: { selectTree: _vm.selectTree },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeTab == "camera"
                ? _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基础信息", name: "base" } },
                        [
                          _c("cameraBase", {
                            attrs: { selectTree: _vm.selectTree },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("addCameraTree", { ref: "addCameraTree" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }