<template>
  <div>
      <el-container>
          <el-main>
              <head-layout
                  head-title="体检记录"
                  :head-btn-options="headBtnOptions"
                  @head-add="handleAdd"
                  @head-import="headImport"
                  @head-del="rowRemove"
              />
              <grid-head-layout
                  ref="gridHeadLayout"
                  :search-columns="searchColumns"
                  @grid-head-search="gridHeadSearch"
                  @grid-head-empty="gridHeadEmpty"
                  :searchSpan="2"
                  ></grid-head-layout>
              <grid-layout
                  ref="gridLayOut"
                  :table-options="tableOptions"
                  :table-data="tableData"
                  :table-loading="tableLoading"
                  :data-total="page.total"
                  :page="page"
                  :editType="inline"
                  @page-current-change="handleCurrentChange"
                  @page-size-change="handleSizeChange"
                  @page-refresh-change="onLoad"
                  @gird-handle-select-click="selectionChange"
                  >
                  <template #hcCode="{row,index}">
                    <el-link type="primary" @click="rowView(row,index)">{{ row.hcCode }}</el-link>
                  </template>
                  <template #customBtn="{row,index}">
                    <template v-if="row.hcStatus == 'prepare' && row.createUser == userInfo.user_id">
                      <el-button  type="text" size="small" @click="rowEdit(row,index)"> 编辑 </el-button>
                      <el-button  type="text" size="small" @click="rowRemove(row,index)"> 删除 </el-button>
                    </template>
                    <template v-else>
                      <el-button  type="text" size="small" @click="rowView(row,index)"> 查看 </el-button>
                    </template>
                  </template>
              </grid-layout>
          </el-main>
          <el-dialog title="体检记录数据导入" append-to-body :visible.sync="excelBox" width="555px">
            <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter">
            <template slot="excelTemplate">
                <el-button type="primary" @click="handleTemplate">
                点击下载<i class="el-icon-download el-icon--right"></i>
                </el-button>
            </template>
            </avue-form>
          </el-dialog>
      </el-container>
  </div>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { ohhcPage, ohhcRemove } from '@/api/healthManage/testRecords'
import {mapGetters} from 'vuex'
import {exportBlob} from "@/api/common";
import {downloadXls} from "@/util/util";
export default {
  components: {
      HeadLayout,
      GridLayout,
  },

  data () {
      return {
          searchColumns: [
              {
                  prop: "hcCode",
                  placeholder: "请输入记录编号",
                  span: 4,
              },
              {
                  prop: "userName",
                  placeholder: "请输入体检人",
                  span: 4,
              },
              {
                  prop: "hcType",
                  placeholder: "请选择体检类型",
                  span: 4,
                  type: 'select',
                  dicUrl:"/api/sinoma-system/dict-biz/dictionary-tree?code=oh_hc_type",
                  props: {
                    label: "dictValue",
                    value: "dictKey",
                  },
              },
              {
                  prop: "hcOrg",
                  placeholder: "请输入体检单位",
                  span: 4,
                  type: 'input'
              },
              {
                  prop: "hcDate",
                  startPlaceholder: "体检开始日期",
                  endPlaceholder: "结束日期",
                  span: 6,
                  type: 'daterange',
              },
          ],
          tableData: [],
          tableLoading: false,
          page: {
              pageSize: 20,
              currentPage: 1,
              total: 0
          },
          query: {},
          selectionList: [],
          excelBox: false,
          excelForm: {},
          excelOption: {
            submitBtn: false,
            emptyBtn: false,
            column: [
            {
                label: '模板上传',
                prop: 'excelFile',
                type: 'upload',
                drag: true,
                loadText: '模板上传中，请稍等',
                span: 24,
                propsHttp: {
                    res: 'data'
                },
                tip: '请上传 .xls,.xlsx 标准格式文件',
                action: "/api/sinoma-hse-prj/ohhc/import"
            },
            {
                label: '模板下载',
                prop: 'excelTemplate',
                formslot: true,
                span: 24,
            }
            ]
        },
      }
  },

  created () {
      this.onLoad(this.page)
  },

  mounted () {
    this.excelOption.column[0].action = `/api/sinoma-hse-prj/ohhc/import?orgId=${this.userInfo.dept_id}&orgCode=${this.userInfo.deptCode ? this.userInfo.deptCode : ''}`
  },

  computed: {
      ...mapGetters(["permission", 'colorName', "userInfo"]),
      permissionList() {
          return {
              addBtn: this.vaildData(this.permission.testRecords_add, true),
              viewBtn: this.vaildData(this.permission.testRecords_view, true),
              editBtn: this.vaildData(this.permission.testRecords_edit, true),
              delBtn: this.vaildData(this.permission.testRecords_delete, true),
              importBtn: this.vaildData(this.permission.testRecords_import, true),
          };
      },

      headBtnOptions() {
          let buttonBtn = [];
          if(this.permissionList.addBtn){
              buttonBtn.push(
              {
                  label: '新增',
                  emit: "head-add",
                  type: "button",
                  icon: ""
              });
          }
          if(this.permissionList.importBtn){
            buttonBtn.push(
              {
                  label: '导入',
                  emit: "head-import",
                  type: "button",
                  icon: ""
              });
          }
          if(this.permissionList.delBtn){
              buttonBtn.push(
              {
                  label: '删除',
                  emit: "head-del",
                  type: "button",
                  icon: ""
              });
          }
          return buttonBtn;
      },

      tableOptions () {
          return{
              customRowAddBtn: false,
              menuWidth: 260,
              selection: true,
              linklable: 'hcCode',
              column: [
                  {
                      label: "记录编号",
                      prop: "hcCode",
                      align: "center",
                      overHidden: true,
                      slot: true,
                  },
                  {
                      label: "体检人",
                      prop: "userName",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: "所在部门",
                      prop: "deptName",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: "体检类型",
                      prop: "hcType",
                      align: "center",
                      overHidden: true,
                      dicUrl:"/api/sinoma-system/dict-biz/dictionary-tree?code=oh_hc_type",
                      props: {
                        label: "dictValue",
                        value: "dictKey",
                      },
                  },
                  {
                      label: "体检日期",
                      prop: "hcDate",
                      align: "center",
                      overHidden: true,
                      width: '120px',
                  },
                  {
                      label: "体检机构",
                      prop: "hcOrg",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: "状态",
                      prop: "hcStatus",
                      align: "center",
                      dicData: [
                            { label: "完成", value: "finished" },
                            { label: "草稿", value: "prepare" },
                        ],
                      overHidden: true,
                  },
              ],
          }
      },
  },

  methods: {
    handleAdd () {
        this.$router.push({
            path: '/business/healthManage/testRecords/form',
            query: {
                type: 'add'
            }
        })
    },
      onLoad(page, params = {}) {
          this.tableLoading = true;
          this.page = page;
          params.orgId = this.userInfo.dept_id
          let queryObj = {...this.query}
          if(this.query.hcDate)delete queryObj.hcDate
          ohhcPage(page.currentPage, page.pageSize, Object.assign(params, queryObj)).then(res => {
              const data = res.data.data;
              this.$refs.gridLayOut.page.total = data.total;
              this.tableData = data.records;
              this.tableLoading = false;
              this.$refs.gridLayOut.selectionClear()
          });
      },
      //页数改变
      handleCurrentChange(e) {
          this.page.currentPage = e.currentPage
          this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
      },
      //size改变
      handleSizeChange(e) {
          this.page.pageSize = e.pageSize
          this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
      },
      // 搜索
      gridHeadSearch(query) {
          const data = this.query
          this.page.currentPage = 1;
            query.startTime = query.hcDate ? query.hcDate[0] : ''
            query.endTime = query.hcDate ? query.hcDate[1] : ''
          this.query = {...data, ...query};
          this.onLoad(this.page)
      },
      // 重置
      gridHeadEmpty(query) {
          this.page.currentPage = 1;
          const data = this.query
          this.query = {...data, ...query};
          this.onLoad(this.page)
      },

      selectionChange (list) {
        this.selectionList = list
      },

      rowEdit (row,index) {
        const {id} = row
        this.$router.push({
            path: '/business/healthManage/testRecords/form',
            query: {
                row: JSON.stringify({id}),
                type: 'edit'
            }
        })
      },
      rowRemove (row,index) {
        let type = this.selectionList.find(val => val.createUser != this.userInfo.user_id)
        if(type && type.id){
            this.$message.warning('非当前账户录入信息不得操作')
            return
        } else {
            let ids = ''
            if(row) {
                ids = row.id
            } else {
                ids = this.selectionList.map(val=>val.id).toString()
                if(!ids){
                    this.$message.warning('请选择操作数据')
                }
            }
            if(ids){
                this.$confirm("确认删除当前数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                        ohhcRemove(ids).then((res) => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功")
                            this.onLoad(this.page);
                        }
                    });
                }).catch((error) => {
                        this.$message({
                            message: "取消删除",
                            type: "info",
                        });
                    });
            }
        }
      },
      rowView (row) {
        const {id} = row
        this.$router.push({
            path: '/business/healthManage/testRecords/form',
            query: {
                row: JSON.stringify({id}),
                type: 'view'
            }
        })
      },

      headImport () {
        this.excelBox = true
      },

      handleTemplate() {
            exportBlob(`/api/sinoma-hse-prj/ohhc/exportTemplate`).then(res => {
                downloadXls(res.data, "体检记录模板.xlsx");
            })
        },

        uploadAfter(res, done, loading, column) {
            this.page.currentPage = 1
            this.excelBox = false;
            this.onLoad(this.page);
            this.excelForm.excelFile = [];
            done();
        },

  }
}
</script>

<style lang="scss" scoped>
.file-name{
  color: #6F91C1;
  cursor: pointer;
  text-decoration: underline;
}
</style>
