var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "affix" },
    [
      _c("avue-affix", { attrs: { id: "avue-view" } }, [
        _c("span", { staticClass: "affix-affix" }, [_vm._v("固定在最顶部")]),
      ]),
      _c("div", { staticClass: "affix-line" }),
      _c("avue-affix", { attrs: { id: "avue-view", "offset-top": 50 } }, [
        _c("span", { staticClass: "affix-affix" }, [
          _vm._v("固定在距离顶部 50px 的位置"),
        ]),
      ]),
      _c("div", { staticClass: "affix-line" }),
      _c("avue-affix", { attrs: { id: "avue-view", "offset-top": 100 } }, [
        _c("span", { staticClass: "affix-affix" }, [
          _vm._v("固定在距离顶部 100px 的位置"),
        ]),
      ]),
      _c("div", { staticClass: "affix-line" }),
      _c("avue-affix", { attrs: { id: "avue-view", "offset-top": 150 } }, [
        _c("span", { staticClass: "affix-affix" }, [
          _vm._v("固定在距离顶部 150px 的位置"),
        ]),
      ]),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { height: "2000px" } }, [
      _c(
        "div",
        { staticStyle: { padding: "15px 20px", "font-size": "18px" } },
        [_vm._v("往下拉就会出现图钉")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }