<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-12 13:55:15
-->
<template>
  <el-drawer :title="title" :close-on-click-modal="false" :visible.sync="showDialog" size="80%" class="qmDialog" @close="closeDialog">
    <el-steps :active="activeName">
      <el-step title="输入源设置" @click.native="handleStepClick(1)"></el-step>
      <el-step title="任务设置" @click.native="handleStepClick(2)"></el-step>
      <el-step title="运行配置" @click.native="handleStepClick(3)"></el-step>
      <el-step title="输出配置" @click.native="handleStepClick(4)"></el-step>
    </el-steps>
    <div class="form-box">
      <avue-form v-show="activeName === 1" :option="{ ...options,disabled:pageType == 'view' ? true : false,}" ref="addForm" v-model="form">
      </avue-form>
      <avue-form v-show="activeName === 2" :option="{ ...uploadOptions,disabled:pageType == 'view' ? true : false,}" ref="uploadFormRef" v-model="uploadForm">
      </avue-form>
      <runSetup v-if="activeName === 3" ref='runSetup'  :url="videoUrl" :runSetupData="runSetupData" @callback='getRunSetupData'></runSetup>
      <avue-form v-show="activeName === 4" :option="{ ...pushOptions,disabled:pageType == 'view' ? true : false,}" ref="pushFormRef" v-model="pushForm" @submit="treeNodeSave">
      </avue-form>
      <div class="btn-box">
        <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" v-if="activeName!=4" @click="nextSave(+1)" v-loading="dialogLoading">
          下一步
        </el-button>
        <el-button size="small" type="primary" v-if="activeName!=1" @click="backStep(-1)" v-loading="dialogLoading">
          上一步
        </el-button>
        <el-button size="small" type="primary" @click="handleTreeNodeSave" v-if="activeName==4" v-loading="dialogLoading">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import {
    listByServer,
    usableModel,
    taskEditConfig,
    taskGetById
  } from "@/api/safe/algorithmManagement";
  import {
    from
  } from "rxjs";
  import Template from "@/views/message/template/list.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {
    mapGetters
  } from "vuex";
  import {
    ALGO_MODEL_CATEGORY,
    ALGO_RECOGNITION_RESULT_PUSH_CYCLE
  } from '../../nodeDict.js'
  import {
    getToken
  } from "@/util/auth";
  import runSetup from './runSetup/index'

  export default {
    components: {
      Template,
      runSetup
    },
    mixins: [organization],
    data() {
      return {
        APITYPE: 'user',
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        uploadForm: {},
        pushForm: {},
        title: '',
        isEdit: false,
        searchData: [],
        pageType: '',
        activeName: 1,
        cameraDataList: [],
        usableModelList: [],
        categoryOptions: [],
        runSetupData: {},
        listRowData: {}
      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      headers: function () {
        return {
          "Sinoma-Auth": getToken(),
        };
      },
      pageDisabled() {
        // 如果查看 或者 提交状态则禁用
        if (this.formType == "view") {
          return true;
        } else {
          return false;
        }
      },
      videoUrl() {
        return this.cameraDataList.find(item => item.id === this.form.cameraId) ?.playUrl;
      },
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "摄像头",
              prop: "cameraId",
              type: 'select',
              dataType: 'string',
              span: 24,
              props: {
                label: "name",
                value: "id",
              },
              dicData: this.cameraDataList,
              // dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineEqtType",
              rules: [{
                required: true,
                message: "请选择摄像头",
                trigger: "blur"
              }]
            },

            {
              label: "抽帧周期（秒）",
              prop: "frameFreq",
              controlsPosition:'',
              type:'number',
              span: 24,
              rules: [{
                required: true,
                message: "请输入抽帧周期（秒）",
                trigger: "blur"
              },]
            },
            {
              label: "抽帧数",
              prop: "frameNum",
              controlsPosition:'',
              type:'number',
              span: 24,
              rules: [{
                required: true,
                message: "请输入抽帧数",
                trigger: "blur"
              },
            ]
            },
          ],
        }
      },
      uploadOptions() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "算法模型",
              prop: "modelId",
              type: 'select',
              dataType: 'string',
              span: 24,
              props: {
                label: "name",
                value: "id",
              },
              dicData: this.usableModelList,
              event: {
                change: (val) => {
                  if (!val) return
                  if (val) {
                    this.categoryOptions = this.usableModelList.find(item => item.id === val) ?.recognitionConfigs || []
                  }
                }
              },
              rules: [{
                required: true,
                message: "请选择算法模型",
                trigger: "blur"
              }]
            },
            {
              label: "识别类型",
              prop: "algoConfigId",
              type: 'select',
              dataType: 'string',
              span: 24,
              props: {
                label: "name",
                value: "id",
              },
              dicData: this.categoryOptions,
              // dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineEqtType",
              rules: [{
                required: true,
                message: "请选择识别类型",
                trigger: "blur"
              }]
            },
            {
              label: "版本号",
              prop: "modelVersion",
              span: 24,
              rules: [{
                message: "请输入版本号",
                trigger: "blur"
              }, {
                max: 64,
                message: '版本号不能超过64个字符',
                trigger: 'blur'
              }]
            },
          ],
        }
      },
      pushOptions() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "推送周期",
              prop: "pushFreq",
              align: "center",
              type: 'select',
              dataType: 'string',
              span: 24,
              dicData: ALGO_RECOGNITION_RESULT_PUSH_CYCLE,
              props: {
                label: "name",
                value: "value",
              },
              // dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=PrjEqVisitAppointment_idType",
            },
            {
              label: "识别地址",
              prop: "pushUrl",
              align: "center",
              span: 24,
              overHidden: true,
            },
          ],
        }
      },
    },

    methods: {
      init(data) {
        this.$nextTick(async()=>{
          this.form = {}
          this.uploadForm = {}
          this.pushForm = {}
          this.showDialog = true
          this.treeReadOnlyFlag = false;
          this.listRowData = data
          this.activeName=1
          await this.getCameraList(data)
          await this.getUsableModel(data)
          this.getTaskGetById(data)
          this.title = '编辑识别配置'
        })

      },
      handleStepClick(tab) {
        if (tab === 2) {
          this.$refs.addForm.validate((valid, done) => {
            if (valid) {
              this.activeName = tab
              done()
            }
          })
        } else if (tab === 3) {
          this.$refs.uploadFormRef.validate((valid, done) => {
            if (valid) {
              this.activeName = tab
              done()
            }
          })
        } else if (tab == 4) {
          this.$refs.runSetup.handleSubmit()
          this.activeName = tab
        } else {
          this.activeName = tab
        }

      },
      async getCameraList(data) {
        listByServer({
          serverId: data.serverId
        }).then((res) => {
          this.cameraDataList = res.data.data
        })
      },
      async getUsableModel(data) {
        usableModel({
          serverId: data.serverId
        }).then((res) => {
          this.usableModelList = res.data.data
        })
      },
      getTaskGetById(data) {
        taskGetById({
          id: data.id
        }).then((res) => {
          const nweForm = {
            cameraId: res.data.data.cameraId,
            frameFreq: res.data.data.frameFreq,
            frameNum: res.data.data.frameNum
          }
          const newUploadForm = {
            modelId: res.data.data.modelId,
            algoConfigId: res.data.data.algoConfigId,
            modelVersion: res.data.data.modelVersion
          }
          const newPushForm = {
            pushFreq: res.data.data.pushFreq,
            pushUrl: res.data.data.pushUrl,
          }
          this.form = nweForm
          this.uploadForm = newUploadForm
          this.pushForm = newPushForm
          this.runSetupData=res.data.data.config
          // this.$set(this.uploadForm, 'modelId', res.data.data.modelId)
        })

      },
      getRunSetupData(data) {
        this.runSetupData = data
      },

      nextSave(tab) {
        let toTab = this.activeName + tab
        if (toTab == 2) {
          this.$refs.addForm.validate((valid, done) => {
            if (valid) {
              this.activeName = toTab
              done()
            }
          })
          // this.$nextTick(()=>{
          //   this.$set(this.uploadForm, 'modelId', this.uploadForm.modelId)
          // })

          console.log('this.form===', this.form)
          console.log('this.uploadForm===', this.uploadForm)
          console.log('this.pushForm===', this.pushForm)
        } else if (toTab === 3) {
          this.$refs.uploadFormRef.validate((valid, done) => {
            if (valid) {
              this.activeName = toTab
              done()
            }
          })
        } else if (tab == 4) {
          this.$refs.runSetup.handleSubmit()
          this.activeName = tab
        } else {
          this.activeName = toTab
        }

      },
      backStep(tab) {
        let toTab = this.activeName + tab
        this.activeName = toTab
      },

      handleTreeNodeSave() {
        this.$refs.pushFormRef.submit();
      },
      treeNodeSave(data) {
        this.$refs.pushFormRef.validate((valid, done) => {
          if (valid) {
            let queryData = {
              id: this.listRowData.id,
              ...this.form,
              ...this.uploadForm,
              ...this.pushForm,
              config: {
                ...this.runSetupData,
                taskId: this.listRowData.id,
              },

            }
            const result = Object.fromEntries(
              Object.entries(queryData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
            );
            this.dialogLoading = true
            taskEditConfig(result).then(res => {
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(res.data.msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.showDialog = false;
              this.dialogLoading = false
            }).catch(err => {
              done()
            }).finally(() => {
              done()
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {
          id: '',
          code: '',
          name: '',
          category: '',
          outputType: '',
          widthPx: '',
          heightPx: '',
          tags: '',
          thresholdVal: '',
          remark: '',
        }
        this.searchData = []
        this.uploadForm = {
          code: '',
          name: '',
          docId: '',
          fileList: '',
          fileName: '',
          fileVerifyCode: '',
          remark: '',
          version: '',
          filePath: ''
        }
        this.showDialog = false
      },
    }
  }

</script>
<style lang="scss" scoped>
  ::v-deep .el-tabs__content {
    margin-top: 30px;
  }
  ::v-deep .el-drawer__header{
    margin-left:25px;
    margin-top:16px;
  }

  .el-autocomplete {
    width: 100%
  }

  .el-steps {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 16px;
  }

  .el-icon-circle-plus {
    font-size: 25px;
    margin-top: 3px;
  }

  ::v-deep .form-box {
    display: flex;
    flex-flow: column;
    justify-content: center;

    .avue-form {
      width: 60% !important;
    }

    .btn-box {
      display: flex;
      justify-content: center;
      margin-top: 5px;
    }
  }

</style>
