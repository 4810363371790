<template>
  <div style="position: relative">
    <div>
      <!-- 闸机列表 -->
      <head-layout head-title="识别配置" :head-btn-options="headBtnOptions" @head-add="handleAdd" @head-import="handleImport" @head-export="handleExport" @head-user="handleUser"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout class="table-container" ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template slot="ifOutputOrigin" slot-scope="{ row }">
          <span >
              {{row.ifOutputOrigin?'是':''}}</span>
        </template>
        <template #customBtn="{ row }">
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowUpdate(row)">编辑
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel(row)">删除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <addIdentify ref="addIdentify" @callback="onLoad(page, {})"></addIdentify>

  </div>
</template>

<script>
  import {
    recognizePageList,
    recognizeRemove,
  } from "@/api/safe/algorithmModel";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import addIdentify from "./addIdentify";
  import {
    getAgeFromIdCard
  } from "@/util/util";
  import {
    exportBlob
  } from "@/api/common";
  import {
    dateNow
  } from "@/util/date";
  import {
    getToken
  } from "@/util/auth";
  import {
    downloadXls
  } from "@/util/util";


  export default {
    components: {
      addIdentify,
      GridLayout,
      HeadLayout,

    },
    props: {
      checkRowData: {
        type: Object,
        default: () => {}
      },
    },
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        page: {
          pageSize: 20,
          pageIndex: 1,
          total: 0,
        },
        selectionList: [],
        tableOption: {
          // linklabel: "visitName",
          reserveSelection: true,
          column: [{
              label: "识别名称",
              prop: "name",
              align: "center",
              overHidden: true,
            },
            {
              label: "识别逻辑",
              prop: "logic2Show",
              align: "center",
              overHidden: true,

            },
            {
              label: "识别标签（限英文字符）",
              prop: "tag",
              align: "center",
              overHidden: true,
            },
            {
              label: "是否输出原始图片",
              prop: "ifOutputOrigin",
              align: "center",
              overHidden: true,
              slot: true,
            },
            {
              label: "备注",
              prop: "remark",
              align: "center",
              overHidden: true,
            },
          ],
        },
        headBtnOptions: [{
            label: "上传模型",
            emit: "head-add",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },

        ],
        searchColumns: [{
            label: "编码/名称",
            prop: "visitName",
            span: 4,
            placeholder: "请输入编码/名称",
          },
          {
            label: "类别",
            prop: "visitStatus",
            span: 4,
            placeholder: "请选择类别",
            type: "select",
            // dicData: [{
            //     value: 1,
            //     label: '是'
            //   },
            //   {
            //     value: 0,
            //     label: '否'
            //   },
            // ]
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=PrjEqVisitAppointment_visitStatus",
          },


        ],
        tableData: [],
        excelBox: false,
        excelOption: {
          submitBtn: false,
          emptyBtn: false,
          excelForm: {},
          column: [{
              label: "数据导入",
              prop: "excelFile",
              type: "upload",
              drag: true,
              loadText: this.$t("cip.plat.sys.user.field.waiting"),
              span: 24,
              propsHttp: {
                res: "data",
              },
              tip: this.$t("cip.plat.sys.user.field.format"),
              action: "/api/sinoma-hse-prj/thirduser/import",
            },

            {
              label: this.$t("cip.plat.sys.user.field.excelTemplate"),
              prop: "excelTemplate",
              formslot: true,
              span: 24,
            },
          ],
        },
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirduser_add, false),
          viewBtn: this.vaildData(this.permission.thirduser_view, false),
          delBtn: this.vaildData(this.permission.thirduser_delete, false),
          editBtn: this.vaildData(this.permission.thirduser_edit, false),
        };
      },
    },
 
    mounted() {
      this.onLoad(this.page, {});
    },
    methods: {
      handleAdd() {
        this.$refs.addIdentify.init("add", null, this.checkRowData);
      },
      rowUpdate(row) {
        this.$refs.addIdentify.init("edit", row, this.checkRowData);
      },
      //查看
      rowView(row) {
        this.$refs.addIdentify.init("view", row, this.checkRowData);
      },
      
      // 多选
      selectionChange(list) {
        this.selectionList = list;
      },
      //人员设置
      handleUser() {
        if (this.selectionList.length) {
          this.$refs.deviceAllSelect.init(null, this.prjId, this.selectionList)
        } else {
          this.$message.warning("请选择至少一条数据");
        }

      },
      getAge(data) {
        return getAgeFromIdCard(data);
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return recognizeRemove({id:row.id});
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.gridLayOut.selectionClear();
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.crud.toggleSelection();
          });
      },
      // 搜索
      gridHeadSearch(searchForm) {
        this.page.pageIndex = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.pageIndex = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.modelId = this.checkRowData.id
        params.pageIndex = page.pageIndex
        params.pageSize = page.pageSize
        recognizePageList(
          Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.tableLoading = false;
          } else {
            this.tableLoading = false;
          }
        });
      },
      // 导入
      handleImport() {
        this.excelBox = true;
        this.excelOption.excelForm.excelFile = [];
      },
      uploadAfter(res, done, loading, column) {
        window.console.log(column);
        this.excelBox = false;
        this.onLoad(this.page);
        this.excelOption.excelForm.excelFile = [];
        done();
      },

    },
  };

</script>

<style lang="less" scoped>
  ::v-deep .table-container {
    .el-table {
      height: calc(100vh - 334px) !important;
      max-height: calc(100vh - 334px) !important;
    }
  }

</style>
