<template>
  <basic-container class="safe-intelligent">
    <div class="video">
      <div class="big-video">
        <VideoOnePro></VideoOnePro>
      </div>
      <div class="small-video">
        <div class="top-video">
          <div class="video-title">视频监控列表</div>
          <ul class="video-list">
            <li>
              <VideoA></VideoA>
            </li>
            <li>
              <VideoB></VideoB>
            </li>
            <li>
              <VideoC></VideoC>
            </li>
            <li>
              <VideoD></VideoD>
            </li>
          </ul>
        </div>
        <div class="bottom-text">
          <div class="video-title">项目基础信息</div>
          <el-row>
            <el-col :span="12">
              <label>子公司:</label>
              <span>{{ form.name }}</span>
            </el-col>
            <el-col :span="12">
              <label>项目进展:</label>
              <span class="green-style">{{ form.name }}</span>
            </el-col>
            <el-col :span="12">
              <label>项目名称:</label>
              <span>{{ form.name }}</span>
            </el-col>
            <el-col :span="12">
              <label>项目地址:</label>
              <span>{{ form.name }}</span>
            </el-col>
            <el-col :span="12">
              <label>项目业主:</label>
              <span>{{ form.name }}</span>
            </el-col>
            <el-col :span="12">
              <label>项目规模:</label>
              <span>{{ form.name }}</span>
            </el-col>
            <el-col :span="12">
              <label>合同范围:</label>
              <span>{{ form.name }}</span>
            </el-col>
            <el-col :span="12">
              <label>合同周期:</label>
              <span>{{ form.name }}</span>
            </el-col>
            <el-col :span="12">
              <label>项目简介:</label>
              <span>{{ form.name }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </basic-container>
</template>

<script>
import VideoA from "@/views/large-screen/videoPage/videoA.vue";
import VideoB from "@/views/large-screen/videoPage/videoB.vue";
import VideoC from "@/views/large-screen/videoPage/videoC.vue";
import VideoD from "@/views/large-screen/videoPage/videoD.vue";
import VideoOnePro from "@/views/large-screen/videoPage/videoOnePro.vue";
export default {
  data() {
    return {
      form: {
        name: '测试',
      }
    };
  },
  components: {
    VideoA,
    VideoB,
    VideoC,
    VideoD,
    VideoOnePro
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
// @function vw($px) {
//   @return ($px/4300) * 100vw;
// }
// @function vh($px) {
//   @return ($px/1290) * 100vh;
// }
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.safe-intelligent {
  .video {
    display: flex;
    height: 100%;
  }
}
.big-video {
  width: vh(1737);
  height: vh(1055);
  margin-top: vh(20);
  margin-right: vh(35);
  background: #ccc;
  opacity: 0.8;
}
.small-video {
  height: 100%;
  .top-video {
    margin-top: vh(20);
    background-image: url("../../../assets/videoBox.png");
    background-size: vh(1250) vh(700);
    background-repeat: no-repeat;
    width: vh(1250);
    height: vh(700);
    .video-title {
      padding-left: vh(45);
      line-height: vh(57);
      color: #fff;
      font-size: vh(18);
    }
    .video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li {
        width: vh(600);
        height: vh(300);
        margin-bottom: vh(10);
        background-color: #fff;
      }
    }
  }
  .bottom-text {
    margin-top: vh(30);
    width: vh(1250);
    height: vh(325);
    background-image: url("../../../assets/formBox.png");
    background-size: vh(1250) vh(325);
    background-repeat: no-repeat;
    .video-title {
      padding-left: vh(45);
      line-height: vh(57);
      color: #fff;
      font-size: vh(18);
    }
  }
}
.el-col {
  line-height: vh(50);
  display: flex;
  label {
    padding-left: vh(10);
    margin-right: vh(10);
    min-width: 8vh;
    font-size: vh(18);
    text-align: end;
    color: #fff;
  }
  span {
    font-size: vh(18);
    color: #fff;
  }
}
.el-col > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.green-style {
  color: #15db6d !important;
}
</style>
