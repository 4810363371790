var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c(
      "div",
      [
        _c("head-layout", {
          attrs: {
            "head-title": "任务设置",
            "head-btn-options": _vm.headBtnOptions,
          },
          on: { "head-remove": _vm.handleDelete, "head-add": _vm.headAdd },
        }),
        _c("grid-head-layout", {
          ref: "gridHeadLayout",
          attrs: { "search-columns": _vm.searchColumns },
          on: {
            "grid-head-search": _vm.gridHeadSearch,
            "grid-head-empty": _vm.gridHeadEmpty,
          },
        }),
        _c("grid-layout", {
          ref: "gridLayOut",
          attrs: {
            "table-options": _vm.tableOption,
            "table-data": _vm.tableData,
            "table-loading": _vm.tableLoading,
            "data-total": _vm.page.total,
            page: _vm.page,
          },
          on: {
            "grid-row-detail-click": _vm.rowView,
            "gird-handle-select-click": _vm.selectionChange,
            "page-current-change": _vm.onLoad,
            "page-size-change": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "customBtn",
              fn: function ({ row }) {
                return [
                  row.acquisitionStatus == 1
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 3px" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.rowEdit(row)
                            },
                          },
                        },
                        [_vm._v("编辑\n          ")]
                      )
                    : _vm._e(),
                  row.acquisitionStatus == 1
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 3px" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.rowDel(row)
                            },
                          },
                        },
                        [_vm._v("删除\n          ")]
                      )
                    : _vm._e(),
                  row.acquisitionStatus == 3
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 3px" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.rowPublish(row)
                            },
                          },
                        },
                        [_vm._v("失效\n          ")]
                      )
                    : _vm._e(),
                  row.acquisitionStatus == 2 || row.acquisitionStatus == 1
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 3px" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.rowPublish(row)
                            },
                          },
                        },
                        [_vm._v("发布\n          ")]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }