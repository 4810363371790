<template>
  <div class="top-square-box clear-fix color-text">
    <ul class="left-square-y clear-fix">
      <li v-for="t in yTextList" :key="t" class="right-border">{{ t }}</li>
    </ul>
    <div class="right-wrap">
      <ul class="top-square-x clear-fix">
        <li v-for="i in hours" :key="i">{{ i - 1 }}</li>
      </ul>
      <ul class="bottom-square-x clear-fix">
        <li class="row" v-for="w in weeks" :key="w">
          <ul class="row-list clear-fix">
            <li v-for="h in hours" :key="h" :x="h - 1" :y="w - 1" :class="[
                              { 'right-border': h < 24 },
                              { select: tableResultList.includes(`${h - 1}_${w - 1}`) }
                          ]" @mousedown="handleMouseDown" @mousemove="handleMouseMove"></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Template from "@/views/message/template/list.vue";
  export default {
    name: "index",
    components: {},
    props: {
      allData: {
        type: Array,
        default: () => ([])
      }
    },
    data() {
      return {
        yTextList: ['时 (h)', '周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        hours: 24,
        weeks: 7,
        tableResultList: [],
        tableData: {
          // 移动容纳盒
          moveList: [],
          // 判断是否移动，移动-按下事件，反之点击事件
          isMove: false,
          // 是否准备开始移动
          firstClick: false,
          // 初始坐标
          start: 0,
          // 结束坐标
          end: 0,
          tag: 2
        }
      }
    },
    watch: {
      allData: {
        immediate: true,
        handler: function (data) {
          if (data){
            this.getTimeDataFromDetail(data)
          }
        },
      },

    },
    mounted() {
      // this.typeTree();
    },
    methods: {
      handleMouseDown(event) {
        const target = event.target;
        this.tableData.isExitClass = target.classList.contains('select');
        let that=this
        this.tableData.isMove = false;
        this.tableData.moveList = [];
        if (this.tableData.firstClick) return false;
        this.tableData.start = {
          x: Number(target.getAttribute('x')),
          y: Number(target.getAttribute('y'))
        };
        this.tableData.firstClick = true;

        document.onmouseup = function () {
          that.mouseupfunc();
          document.onmouseup = null;
        };

        return false;
      },
      handleMouseMove(event) {
        const target = event.target;
        if (!this.tableData.firstClick) return false;
        this.tableData.isMove = true;
        this.tableData.end = {
          x: Number(target.getAttribute('x')),
          y: Number(target.getAttribute('y'))
        };
        let xStart = Math.min(this.tableData.start.x, this.tableData.end.x);
        let xEnd = Math.max(this.tableData.start.x, this.tableData.end.x);
        let yStart = Math.min(this.tableData.start.y, this.tableData.end.y);
        let yEnd = Math.max(this.tableData.start.y, this.tableData.end.y);
        this.tableData.moveList = [];

        for (let i = xStart; i <= xEnd; i++) {
          if (i >= 24) break;
          for (let j = yStart; j <= yEnd; j++) {
            if (j >= 7) break;
            if (this.tableData.isExitClass) {
              this.tableResultList = this.tableResultList.filter(v => v !== `${i}_${j}`);
            } else {
              if (!this.tableResultList.includes(`${i}_${j}`)) {
                this.tableResultList.push(`${i}_${j}`);
              }
            }
            this.tableData.moveList.push(JSON.stringify({
              x: i,
              y: j
            }));
          }
        }
      },
      mouseupfunc() {
        let mvl = [];
        if (!this.tableData.isMove) {
          const start = this.tableData.start;
          this.tableData.moveList = [JSON.stringify(start)];
          mvl = [`${start.x}_${start.y}`];
        }

        mvl = this.tableData.moveList.map(m => {
          const mo = JSON.parse(m);
          return `${mo.x}_${mo.y}`;
        });

        if (this.tableData.isExitClass) {
          // 删除
          this.tableResultList = this.tableResultList.filter(v => !mvl.includes(v));
        } else {
          // 添加
          this.tableResultList = this.tableResultList.concat(mvl);
        }

        // 上色渲染
        let dataList = [];
        if (this.tableResultList.length > 0) {
          this.tableResultList = this.uniq(this.tableResultList);
          this.tableResultList.forEach(v => {
            if (!v) return false;
            dataList.push(v);
          });
        } else {
          this.tableResultList = [];
        }
        this.tableResultList = dataList;
        this.tableData = {};
        this.sendData();
      },
      uniq(array) {
        array.sort();
        let temp = [array[0]];
        for (let i = 1; i < array.length; i++) {
          if (array[i] !== temp[temp.length - 1]) {
            temp.push(array[i]);
          }
        }
        return temp;
      },
      mergeTime(list) {
        let mergeList = [];
        if (list.length == 1) {
          let timeSlot = [];
          timeSlot.push(list[0].x);
          timeSlot.push(list[0].x);
          mergeList.push(timeSlot);
        }
        if (list.length > 1) {
          let timeSlot = [];
          timeSlot.push(list[0].x);
          for (let i = 0; i < list.length - 1; i++) {
            if (list[i + 1].x - list[i].x == 1) {
              if (i == list.length - 2) {
                timeSlot.push(list[i + 1].x);
                mergeList.push(timeSlot);
              }
            } else if (i == list.length - 2) {
              timeSlot.push(list[i].x);
              mergeList.push(timeSlot);
              timeSlot = [];
              timeSlot.push(list[i + 1].x);
              timeSlot.push(list[i + 1].x);
              mergeList.push(timeSlot);
            } else {
              timeSlot.push(list[i].x);
              mergeList.push(timeSlot);
              timeSlot = [];
              timeSlot.push(list[i + 1].x);
            }
          }
        }
        return mergeList.map(function (ele) {
          let timeSlot = {
            start: null,
            end: null
          };
          timeSlot.start = ele[0] + ':00:00';
          let num = Number(ele[1]) + 1;
          if (num <= 23) {
            timeSlot.end = num + ':00:00';
          } else {
            timeSlot.end = ele[1] + ':59:59';
          }
          return timeSlot;
        });
      },

      sortTime(list) {
        list.sort(function (a, b) {
          return a.x - b.x;
        });
      },
      createTimeBlock(list, day) {
        return {
          week: day,
          times: list
        };
      },
      transformData() {
        let data = this.tableResultList;
        let times = [];
        let monday = [];
        let tuesday = [];
        let wednesday = [];
        let thursday = [];
        let friday = [];
        let saturday = [];
        let sunday = [];
        data.forEach(function (ele) {
          ele = ele.split('_');
          ele.x = Number(ele[0]);
          ele.y = Number(ele[1]);
          if (Number(ele.x) < 24) {
            switch (ele.y) {
              case 0:
                monday.push(ele);
                break;
              case 1:
                tuesday.push(ele);
                break;
              case 2:
                wednesday.push(ele);
                break;
              case 3:
                thursday.push(ele);
                break;
              case 4:
                friday.push(ele);
                break;
              case 5:
                saturday.push(ele);
                break;
              case 6:
                sunday.push(ele);
                break;
            }
          }
        });
        this.sortTime(monday);
        this.sortTime(tuesday);
        this.sortTime(wednesday);
        this.sortTime(thursday);
        this.sortTime(friday);
        this.sortTime(saturday);
        this.sortTime(sunday);

        let mondayTimeList = this.mergeTime(monday);
        times.push(this.createTimeBlock(mondayTimeList, 'MONDAY'));
        let tuesdayTimeList =this.mergeTime(tuesday);
        times.push(this.createTimeBlock(tuesdayTimeList, 'TUESDAY'));
        let wednesdayTimeList = this.mergeTime(wednesday);
        times.push(this.createTimeBlock(wednesdayTimeList, 'WEDNESDAY'));
        let thursdayTimeList = this.mergeTime(thursday);
        times.push(this.createTimeBlock(thursdayTimeList, 'THURSDAY'));
        let fridayTimeList = this.mergeTime(friday);
        times.push(this.createTimeBlock(fridayTimeList, 'FRIDAY'));
        let saturdayTimeList = this.mergeTime(saturday);
        times.push(this.createTimeBlock(saturdayTimeList, 'SATURDAY'));
        let sundayTimeList = this.mergeTime(sunday);
        times.push(this.createTimeBlock(sundayTimeList, 'SUNDAY'));
        return times;
      },
      sendData() {
        this.$emit('update', this.transformData())
      },
      getTimeDataFromDetail(list) {
        let timeDataList = [];
        let start = null;
        let end = null;
        let x = null;
        let y = null;
        if (list && list.length > 0) {
          list.forEach((ele, index) => {
            y = index;
            ele.times.forEach(function (ele) {
              start = parseInt(ele.start.split(':', 1));
              end = parseInt(ele.end.split(':', 1));
              for (let i = 0; i < end - start; i++) {
                x = start + i;
                timeDataList.push(`${x}_${y}`);
              }
              if (parseInt(ele.end.split(':', 2)[1]) == 59) {
                x = 23;
                timeDataList.push(`${x}_${y}`);
              }
            });
          });
        }
        this.tableResultList = timeDataList;
      }


    },
  }

</script>
<style lang="scss" scoped>


  .disabled {
    pointer-events: none;
    /*禁用当前元素的所有鼠标事件*/
  }

  .clear-fix:after {
    content: '';
    display: block;
    clear: both;
  }

  .top-square-box {
    min-width: 702px;
    border: 1px solid #e5e6eb;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;

  }

  .left-square-y {
    width: 4%;
    min-width: 28px;
    background-color: #f2f3f5;

    li {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      border-right: 1px solid #e5e6eb;
      border-bottom: 1px solid #e5e6eb;
      text-align: center;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .right-wrap {
    width: 96%;
    overflow: hidden;
    white-space: nowrap;
  }

  .top-square-x {
    white-space: nowrap;
    background-color: #f2f3f5;

    ul {
      display: block;
      overflow: hidden;
      margin: 0px;
      padding: 0px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      border: 1px solid #e5e6eb;
      border-bottom: none;
    }

    li {
      width: 4.066%;
      min-width: 28px;
      height: 40px;
      float: left;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #e5e6eb;
      border-bottom: 1px solid #e5e6eb;

      &:last-child {
        border-right: none;
      }
    }
  }

  .bottom-square-x {
    display: block;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

    .row+.row {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
    }

    .row-list li {
      float: left;
      height: 40px;
      width: 4.066%;
      min-width: 28px;
      background: #ffffff;
      border-bottom: 1px solid #e5e6eb;
      cursor: pointer;
      line-height: 40px;

      &:last-child {
        border-right: none;
        width: 4.166%;
      }

      &.select {
        background: #bedaff;
      }
    }

    .row:last-child li {
      border-bottom: none;
    }
  }

  li.right-border {
    border-right: 1px solid #e5e6eb;
  }

</style>
