var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "文本内容" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入文本内容" },
            model: {
              value: _vm.activeData.__config__.defaultValue,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
              },
              expression: "activeData.__config__.defaultValue",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "行高" } },
        [
          _c("el-input-number", {
            attrs: {
              min: 12,
              placeholder: "请输入行高",
              "controls-position": "right",
            },
            model: {
              value: _vm.activeData.textStyle["line-height"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData.textStyle, "line-height", $$v)
              },
              expression: "activeData.textStyle['line-height']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "字体大小" } },
        [
          _c("el-input-number", {
            attrs: {
              min: 12,
              placeholder: "请输入字体大小",
              "controls-position": "right",
            },
            model: {
              value: _vm.activeData.textStyle["font-size"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData.textStyle, "font-size", $$v)
              },
              expression: "activeData.textStyle['font-size']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "对齐方式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.activeData.textStyle["text-align"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData.textStyle, "text-align", $$v)
                },
                expression: "activeData.textStyle['text-align']",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "left" } }, [
                _vm._v("左对齐"),
              ]),
              _c("el-radio-button", { attrs: { label: "center" } }, [
                _vm._v("居中对齐"),
              ]),
              _c("el-radio-button", { attrs: { label: "right" } }, [
                _vm._v("右对齐"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "字体颜色" } },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.activeData.textStyle["color"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData.textStyle, "color", $$v)
              },
              expression: "activeData.textStyle['color']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否加粗" } },
        [
          _c("el-switch", {
            attrs: { "active-value": "bold", "inactive-value": "normal" },
            model: {
              value: _vm.activeData.textStyle["font-weight"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData.textStyle, "font-weight", $$v)
              },
              expression: "activeData.textStyle['font-weight']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否斜体" } },
        [
          _c("el-switch", {
            attrs: { "active-value": "italic", "inactive-value": "normal" },
            model: {
              value: _vm.activeData.textStyle["font-style"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData.textStyle, "font-style", $$v)
              },
              expression: "activeData.textStyle['font-style']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "下划线样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.activeData.textStyle["text-decoration"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData.textStyle, "text-decoration", $$v)
                },
                expression: "activeData.textStyle['text-decoration']",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "none" } }, [
                _vm._v("无样式"),
              ]),
              _c("el-radio-button", { attrs: { label: "underline" } }, [
                _vm._v("下划线"),
              ]),
              _c("el-radio-button", { attrs: { label: "line-through" } }, [
                _vm._v("删除线"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }