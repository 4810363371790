<script>


import {prjblacklistorgheadPage} from "@/api/block/organization";
import OpenBlackDialog from "@/views/business/safe/itemList/openBlackDialog.vue";
import {prjblacklistuserheadPage} from "@/api/block/personnel";
import {dateFormat} from "@/util/date";
import GridLayout from "@/views/components/layout/grid-layout";
import {mapGetters} from "vuex";

export default  {
  components: {OpenBlackDialog,GridLayout},
data(){
  return {
    blacklistPersonnelTableData: [],
    // 黑名单人员 表格设置
    blacklistPersonnelTableOption: {
      index: true,
      indexLabel: "序号",
      selection: false,
      column: [
        {
          label: "姓名",
          prop: "userName",
          minWidth: 120,
          align: "center",
          overHidden: true,
        },
        {
          label: "性别",
          prop: "sex",
          minWidth: 80,
          align: "center",
          type:'select',
          dataType: "number",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
        },
        {
          label: "年龄",
          prop: "birthday",
          minWidth: 80,
          align: "center",
          overHidden: true,
          slot: true,
        },
        {
          label: "联系电话",
          prop: "phone",
          align: "center",
          minWidth: 180,
          overHidden: true,
        },
        {
          label: "身份证",
          prop: "idCoding",
          minWidth: 185,
          align: "center",
          overHidden: true,
        },
        {
          label: "籍贯",
          prop: "domicile",
          minWidth: 150,
          align: "left",
          overHidden: true,
        },
        {
          label: "岗位",
          prop: "post",
          minWidth: 150,
          align: "center",
          overHidden: true,
        },
        {
          label: "黑名单公司",
          prop: "companyName",
          minWidth: 200,
          align: "left",
          overHidden: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          minWidth: 170,
          align: "center",
          overHidden: true,
        },
        {
          label: "原因",
          prop: "illustrate",
          minWidth: 200,
          align: "left",
          overHidden: true,
        },
      ],
    },
    // 黑名单人员 筛选条件
    blacklistPersonnelSearchColumns: [
      {
        label: "",
        prop: "condition",
        span: 4,
        placeholder: "姓名/籍贯/身份证号码",
      },
    ],
    formType:'',
    blacklistOrganizationTableData: [],
    // 黑名单组织 表格设置
    blacklistOrganizationTableOption: {
      index: true,
      indexLabel: "序号",
      selection: false,
      column: [
        {
          label: "统一社会信用代码",
          prop: "companyCode",
          align: "center",
          minWidth: 185,
          overHidden: true,
        },
        {
          label: "公司名称",
          prop: "companyName",
          minWidth: 200,
          align: "left",
          overHidden: true,
        },
        {
          label: "法定代表人",
          prop: "legalRep",
          minWidth: 120,
          align: "center",
          overHidden: true,
        },
        {
          label: "注册地",
          prop: "regLocation",
          minWidth: 150,
          align: "left",
          overHidden: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          minWidth: 170,
          align: "center",
          overHidden: true,
        },
        {
          label: "原因",
          prop: "illustrate",
          minWidth: 200,
          align: "left",
          overHidden: true,
        },
      ],
    },
    tableLoading: false,
    activeName:'second',
    // 黑名单组织 筛选条件
    blacklistOrganizationSearchColumns: [
      {
        label: "项目名称",
        prop: "condition",
        span: 5,
        placeholder: "公司名称/统一社会信用代码/法定代表人",
      },
    ],
  }
},
  mounted() {
    this.handleClick()
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
  },
  methods:{
    removeBlack(row, type) {
      if (row.id && type) {
        row.prjCode = this.userInfo.prjCode;
        this.$refs.openBlackDialog.init(type, row, true);
      }
    },
    handleClick(){
      if(this.activeName == 'second'){
        this.getBlacklistOrganizationPage()
      }else {
        this.getBlacklistPersonnelPage()
      }
    },
    // 黑名单组织 列表 筛选
    // 搜索
    blacklistOrganizationGridHeadSearch(searchForm) {
      this.getBlacklistOrganizationPage(searchForm);
    },
    // 清空
    blacklistOrganizationGridHeadEmpty() {
      this.getBlacklistOrganizationPage();
    },
    // 黑名单组织 列表
    getBlacklistOrganizationPage(params = {}) {
      // 根据接口获取

      let obj = {prjCode: this.userInfo.prjCode};
      prjblacklistorgheadPage(Object.assign(params, obj)).then((res) => {
        if (res.data.code == 200) {
          this.blacklistOrganizationTableData = res.data.data.records;
        } else {
          this.blacklistOrganizationTableData = [];
        }
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.blacklistOrganizationGridLayOut.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    // 黑名单人员 列表 筛选
    // 搜索
    blacklistPersonnelGridHeadSearch(searchForm) {
      this.getBlacklistPersonnelPage(searchForm);
    },
    // 清空
    blacklistPersonnelGridHeadEmpty() {
      this.getBlacklistPersonnelPage();
    },
    // 黑名单人员 列表
    getBlacklistPersonnelPage(params = {}) {
      let obj = {prjCode: this.userInfo.prjCode};
      prjblacklistuserheadPage(Object.assign(params, obj)).then((res) => {
        if (res.data.code == 200) {
          this.blacklistPersonnelTableData = res.data.data.records;
        } else {
          this.blacklistPersonnelTableData = [];
        }
      });
    },
    itemObject(item) {
      let age = this.getAge(item.birthday)
      let overDue = false;
      if (item.insuranceEndDate) {
        let newDate = new Date(
          dateFormat(new Date(), "yyyy-MM-dd")
        ).getTime();
        let str = new Date(item.insuranceEndDate).getTime();
        overDue = str < newDate;
      }
      if (
        (item.sex == 1 && age >= 50) ||
        age >= 60 || age < 18 ||
        overDue
      ) {
        return {color: "red"};
      } else {
        return {color: "black"};
      }
    },
  },
}
</script>

<template>
  <basic-container>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="黑名单组织" name="second"></el-tab-pane>
      <el-tab-pane label="黑名单人员" name="third"></el-tab-pane>
    </el-tabs>
    <div v-show="activeName == 'second'">
      <head-layout
        head-title="黑名单组织"
        :showIcon="false"
      />
      <grid-head-layout
                        ref="blacklistOrganizationGridHeadLayout"
                        :search-columns="blacklistOrganizationSearchColumns"
                        @grid-head-search="blacklistOrganizationGridHeadSearch"
                        @grid-head-empty="blacklistOrganizationGridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="blacklistOrganizationGridLayOut"
        :table-loading="tableLoading"
        :table-options="blacklistOrganizationTableOption"
        :table-data="blacklistOrganizationTableData"
        class="itemListGridNoPage"
      >
        <template #customBtn="{ row }" >
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="removeBlack(row,'organization')"
          >移除黑名单
          </el-button>
        </template>
      </grid-layout>
    </div>
    <div v-show="activeName == 'third'">
      <head-layout
        head-title="黑名单人员"
        :showIcon="false"
      />
      <grid-head-layout
                        ref="blacklistPersonnelGridHeadLayout"
                        :search-columns="blacklistPersonnelSearchColumns"
                        @grid-head-search="blacklistPersonnelGridHeadSearch"
                        @grid-head-empty="blacklistPersonnelGridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
                   ref="blacklistPersonnelGridLayOut"
                   :table-loading="tableLoading"
                   :table-options="blacklistPersonnelTableOption"
                   :table-data="blacklistPersonnelTableData"
                   class="itemListGridNoPage"
      >
        <template slot="birthday" slot-scope="{ row }">
                <span :style="itemObject(row)">
                  {{
                    getAge(row.birthday)
                  }}
                </span>
        </template>
        <template #customBtn="{ row }" >
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="removeBlack(row,'personnel')"
          >移除黑名单
          </el-button>
        </template>
      </grid-layout>
    </div>
    <!-- 相关方组织/相关方人员拉黑名单弹窗 -->
    <open-black-dialog ref="openBlackDialog"></open-black-dialog>
  </basic-container>
</template>

<style scoped lang="scss">
::v-deep .el-tabs__content{
  display: none;
}
</style>
