var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "org-select",
      class: { "org-select__button": _vm.buttonType === "button" },
    },
    [
      _c(
        "div",
        { staticClass: "tags" },
        [
          _vm.buttonType === "button"
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "6px" },
                  attrs: { icon: "el-icon-plus" },
                  on: {
                    click: function ($event) {
                      _vm.show = true
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "input-box",
              class: { "as-input": _vm.buttonType === "input" },
              on: {
                click: function ($event) {
                  _vm.show = true
                },
              },
            },
            _vm._l(_vm.selectedData, function (item, i) {
              return _c(
                "el-tag",
                _vm._b(
                  {
                    key: item.key,
                    staticClass: "org-tag",
                    on: {
                      close: function ($event) {
                        return _vm.onClose(item, i)
                      },
                    },
                  },
                  "el-tag",
                  _vm.tagConfig,
                  false
                ),
                [_vm._v("\n        " + _vm._s(item.fullName) + "\n      ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("org-transfer", {
        ref: "transfer",
        attrs: {
          value: _vm.innerValue,
          title: _vm.title,
          visible: _vm.show,
          type: _vm.type,
        },
        on: {
          "update:visible": function ($event) {
            _vm.show = $event
          },
          confirm: _vm.onConfirm,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }