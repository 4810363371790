var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "managementView" },
    [
      _c("CommonTree", {
        ref: "commonTreeCore",
        attrs: {
          treeTitle: "组织列表",
          searchTitle: _vm.searchTitle,
          treeData: _vm.treeData,
          defaultProps: _vm.defaultProps,
          isShowdig: false,
          showCheckbox: false,
          "node-key": "id",
        },
        on: { getNodeClick: _vm.treeNodeClick },
      }),
      _c(
        "el-main",
        [
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: {
              "btn-span": "1",
              "grid-head-btn": _vm.gridHeadBtn,
              "search-columns": _vm.searchColumns,
              searchSpan: 2,
            },
            on: {
              "export-Fun": _vm.exportFun,
              "grid-head-search": _vm.gridHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "grid-row-btn": _vm.gridRowBtn,
              "table-options": _vm.tableOptions,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.dataTotal,
            },
            on: {
              "grid-row-detail-click": _vm.rowDetail,
              "page-current-change": _vm.onLoad,
              "page-size-change": _vm.onLoad,
              "page-refresh-change": _vm.onLoad,
            },
            scopedSlots: _vm._u([
              {
                key: "hdLevel",
                fn: function ({ row }) {
                  return [
                    _vm.hdLevel[row.hdLevel]
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.hdLevelIcon[row.hdLevel],
                              effect: "dark",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.hdLevel[row.hdLevel]) + "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowDetail(row, "view")
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }