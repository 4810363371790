<template>
  <el-main>
    <form-layout ref="formLayout" class="deptForm" :dataForm="form" :group="group"></form-layout>
    <userDialog
      class="roleTable"
      ref="userDialog"
      :userFrom="form"
      @submitList="submitList"
      :isFixed="false"
    ></userDialog>
  </el-main>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import {getDeptTree} from "@/api/system/dept";
import HeadLayout from "@/views/components/layout/head-layout"
import UploadLayout from "@/views/components/layout/upload-layout"

const CONTROLLER_NAME = "md/subDevice"
import website from '@/config/website';
import {mapGetters} from "vuex";
import userDialog from "@/views/system/deptAndRoleConfig/userDialogUtils";
let deptListInput = {}

export default {
  name: "tab-form",
  components: {FormLayout, HeadLayout, UploadLayout, userDialog},
  data() {
    return {
      classCodeList: '',
      deptDicData: [],
      form: {},
      initFlag: true,
      treeList: [],
      deptList: [],
      observer: null
    };
  },
  computed: {
    ...mapGetters(['language']),
    group() {
      const that = this
      return [

        {
          column: [
            {
              label: this.$t("cip.plat.sys.user.field.dept"),
              prop: "deptId",
              span: 24,
              type: "tree",
              multiple: true,
              // tags:true,
              dicData: this.deptDicData,
              props: {
                label: "title"
              },
              checkStrictly: true,
              slot: true,
              change(row) {
                deptListInput = row;
                if (row.value.length == 0) {
                  that.$refs.userDialog.clear();
                }
              },
            },
          ]
        },
      ]
    }
  },
  watch: {},
  mounted() {
    this.initData(website.tenantId);
    this.listenerDom()
  },
  destroyed() {
    this.observer.disconnect();
  },
  methods: {
    listenerDom() {
      // 目标DOM元素
      const targetElement = document.getElementsByClassName(
        "el-input el-input--small el-input--suffix"
      );
      // 创建观察器对象
      this.observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "attributes") {
            // 只处理属性变化
            if (mutation.target.className.indexOf("is-focus") == -1) {
              this.deptList = []
              this.treeList = deptListInput.value;
              const column = this.findObject(this.group, "deptId");
              var item = this.deptDicData;
              this.treeList.forEach(e => {
                //查询上级部门
                let findParentNode = this.findParentNode(column.dicData, e);
                //查询本级部门
                let findChildData = this.findChildData(item, e);
                if (findParentNode == null) {
                  let findParentNode1 = {}
                  findParentNode1.departmentId = findChildData.id
                  findParentNode1.department = findChildData.title
                  // findParentNode1.deptAncestor = findChildData.organizeIds
                  findParentNode1.superiorDepartment = findChildData.title
                  findParentNode1.superiorDepartmentId = findChildData.id
                  this.deptList.push(findParentNode1);
                } else {
                  let find = {}
                  find.departmentId = findChildData.id
                  find.department = findChildData.title
                  // find.deptAncestor = findChildData.organizeIds
                  find.superiorDepartment = findParentNode.title
                  find.superiorDepartmentId = findParentNode.id
                  this.deptList.push(find);
                }
                //查询出所有的部门
              })
              this.$refs.userDialog.deptList = this.deptList;
              this.$refs.userDialog.getDeptList();
            }
          }
        }
      });
      // 配置观察选项
      const config = {attributes: true};
      // 开始观察目标元素
      this.observer.observe(targetElement[0], config);
    },
    findChildData(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === childId) {
          // 如果当前节点就是目标子节点，直接返回其数据
          return node;
        } else if (node.children) {
          // 否则递归查找当前节点的子节点
          const childData = this.findChildData(node.children, childId);
          if (childData) {
            // 如果找到了目标子节点的数据，返回该数据
            return childData;
          }
        }
      }
      // 没有找到目标子节点的数据，返回 null
      return null;
    },
    findParentNode(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          // 判断当前节点的子节点是否包含目标子节点
          const isChild = node.children.some(child => child.id === childId);
          if (isChild) {
            // 如果存在目标子节点，返回当前节点
            return node;
          } else {
            // 否则递归查找当前节点的子节点
            const parentNode = this.findParentNode(node.children, childId);
            if (parentNode) {
              // 如果找到了目标子节点的上一级节点，返回该节点
              return parentNode;
            }
          }
        }
      }
      // 没有找到目标子节点的上一级节点，返回 null
      return null;
    },
    submit(){
      return this.$refs.userDialog.submit();
    },
    initData(tenantId) {
      getDeptTree(tenantId).then(res => {
        const column = this.findObject(this.group, "deptId");
        this.deptDicData = res.data.data;
        column.dicData = res.data.data;
      });
    },
    backData(roleIds) {
      this.deptList = []
      if (roleIds.length == 0) {
        this.$refs.userDialog.getDataList();
        return;
      }
      roleIds.forEach((item)=>{
        // let findChildData = this.findChildData(this.deptDicData, item.deptId);
        let findParentNode1 = {}
        findParentNode1.departmentId =  item.deptId
        findParentNode1.department = item.deptName
        // findParentNode1.superiorDepartment = item.deptAncestorName
        // findParentNode1.superiorDepartmentId = item.deptAncestor
        // findParentNode1.deptAncestor = findChildData.organizeIds
        findParentNode1.isDefaultDept = item.isDefaultDept
        findParentNode1.roleId = item.roleId
        findParentNode1.roleName = item.roleName
        this.deptList.push(findParentNode1);
      })
      //查询出所有的部门
      this.$refs.userDialog.getDataList();
      this.$refs.userDialog.deptList = this.deptList;
      // setTimeout(() =>{
      //   this.$refs.userDialog.updateDeptList();
      // },5000)
    },

  }
}
</script>

<style scoped lang="scss">
.deptForm ::v-deep .el-form-item__label{
  width: 50px !important;
}
.deptForm ::v-deep .el-form-item__content{
  margin-left: 50px !important;
}
//.roleTable ::v-deep .el-table{
//  max-height: 380px;
//}
//.roleTable ::v-deep .el-table__body-wrapper{
//  max-height: 280px;
//  overflow-y: auto;
//}
</style>
