<template>
    <div>
        <head-layout head-title="安全考核" :head-btn-options="headBtnOptions" @head-export="headExport" @head-add="openDialog()"></head-layout>
        <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="resetData"
            @grid-head-empty="resetData"
        ></grid-head-layout>
        <grid-layout
            ref="gridLayout"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :data-total="page.total"
            :page="page"
            @page-size-change="pageCurrentChange"
            @page-current-change="pageCurrentChange"
            @page-refresh-change="resetData"
        >
        <template #customBtn="{ row }">
            <el-button type="text" size="small" @click="openDialog(row.id,'detail')">查看</el-button>
            <el-button type="text" size="small" @click="openDialog(row.id)">编辑</el-button>
            <el-button type="text" size="small" @click="rowDel(row.id)">删除</el-button>
        </template>
        </grid-layout>
    </div>
</template>
<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import { page,remove } from '@/api/safetyAssessment/list'
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {mapGetters} from "vuex";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";
export default {
    components: {
        GridLayout,
        HeadLayout,
    },
    computed:{
        ...mapGetters(["userInfo"]),
    },
    data(){
        return{
            timeRange:[],
            dateType:1,
            tableData:[],
            page: {
                pageSize: PAGE_CONSTANT.TWENTY,
                currentPage: 1,
                total: 0
            },
            tableLoading:false,
            headBtnOptions: [{
                label: "新增",
                emit: "head-add",
                type: "button",
                icon: "",
                btnOptType: 'primary',
            }, {
                label: '导出',
                emit: "head-export",
                type: "button",
                icon: ""
            }],
            tableOptions: {
                selection: false,
                indexLabel: "序号",
                index: true,
                column: [{
                    label: "考核日期",
                    prop: "assTime",
                    overHidden: true,
                    align: 'center',
                },{
                    label: '考核对象',
                    prop: "assObject",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '考核人员',
                    prop: "userName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: "部门",
                    prop: "deptName",
                    overHidden: true,
                    align: 'center',
                },{
                    label: "扣分",
                    prop: "points",
                    overHidden: true,
                    align: 'center',
                },{
                    label: "经济处罚（元）",
                    prop: "fine",
                    overHidden: true,
                    align: 'center',
                },{
                    label: "教育培训（学时）",
                    prop: "eduTrain",
                    overHidden: true,
                    align: 'center',
                },{
                    label: "待岗（月）",
                    prop: "awaitJob",
                    overHidden: true,
                    align: 'center',
                },{
                    label: "考核原因",
                    prop: "assReason",
                    overHidden: true,
                    align: 'center',
                },]
            },
            searchColumns:[{
                    placeholder: "请选择考核日期",
                    prop: "time",
                    type: "daterange",
                    dicData: [],
                    span: 5,
                },{
                    placeholder: "请选择考核对象",
                    prop: "assObject",
                    type: "select",
                    dicData: [{
                        dictValue:'人员',
                        dictKey:'人员'
                    },{
                        dictValue:'部门',
                        dictKey:'部门'
                    }],
                    props: {
                        label: "dictValue",
                        value: "dictKey",
                    },
                    span: 5,
                    change:(val)=>{
                        let index = 2
                        if(val.value == '人员'){
                            this.searchColumns[index].placeholder = '请输入人员'
                            this.searchColumns[index].prop = 'userName'
                        }else{
                            this.searchColumns[index].placeholder = '请输入部门'
                            this.searchColumns[index].prop = 'deptName'
                        }
                        this.$nextTick(()=>{
                            this.$refs.gridHeadLayout?.initSearch();
                        })
                    }
                },
                {

                    placeholder: "请输入人员",
                    prop: "userName",
                    span: 5,
                },
            ]
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.searchColumns[2].dicUrl = "/api/sinoma-hse-prj/prjbasisareauser/list?prjId="+this.userInfo.dept_id
            this.resetData()
        })
    },
    methods:{
        openDialog(id,type){
            this.$router.push({path:`/safetyAssessment/${id?type=='detail'?'detail':'edit':'add'}`,query:{id:id,type:type}})
        },
        resetData(){
            this.page.pageSize = PAGE_CONSTANT.TWENTY
            this.page.currentPage = 1
            this.getTableData()
        },
        pageCurrentChange(page){
            this.page.pageSize = page.pageSize
            this.page.currentPage = page.currentPage
            this.getTableData()
        },
        getTableData(){
            let {time,assObject,userName,deptName} = this.$refs.gridHeadLayout.searchForm
            this.tableLoading = true
            time = time || []
            page({current:this.page.currentPage,size:this.page.pageSize,
            startTime:time[0],endTime:time[1],assObject:assObject,userName:userName,deptName:deptName,orgId:this.userInfo.dept_id}).then(res=>{
                let data = res.data.data
                this.tableData = data.records.map(item=>{
                    for(let key in item){
                        item[key] = item[key] == -1?'':item[key]
                    }
                    return item
                })
                this.page.pageSize = data.size
                this.page.currentPage = data.current
                this.page.total = data.total
                this.tableLoading = false
            })
        },
        rowDel(id) {
            this.$confirm("确定删除当前数据?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                remove({ids:id}).then(() => {
                    this.$message.success('删除成功！')
                    this.resetData()
                })
            });
        },
        headExport() {
            let {time,assObject,userName,deptName} = this.$refs.gridHeadLayout.searchForm
            time = time || []
            exportBlob(
                `/api/sinoma-hse-prj/safetyassess/export?assObject=${assObject||''}&startTime=${time[0]||''}&endTime=${time[1]||''}&userName=${userName||''}&deptName=${deptName||''}&orgId=${this.userInfo.dept_id}`
            ).then((res) => {
                downloadXls(res.data, `安全考核${dateNow()}.xls`);
            });
        },
    },
}
</script>
<style scoped lang="scss">
</style>
