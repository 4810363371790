var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.handleCurrentChange,
          "page-size-change": _vm.handleSizeChange,
          "page-refresh-change": _vm.onLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "riskCode",
            fn: function ({ row }) {
              return [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.linkDetail(row)
                      },
                    },
                  },
                  [_vm._v(_vm._s(row.riskCode))]
                ),
              ]
            },
          },
          {
            key: "riskLevel",
            fn: function ({ row }) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                      "align-item": "center",
                      padding: "4px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          border: `1px solid ${row.lecdColor}`,
                          borderRadius: "4px",
                          color: row.lecdColor,
                          backgroundColor: _vm.computedDlevel(row.lecdD).bg,
                          textAlign: "center",
                          display: "inline-block",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(row.lecdD) + "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "libraryStatus",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(row.libraryStatus == "FINISHED" ? "完成" : "草稿") +
                    "\n    "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }