var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("h3", [_vm._v("数据展示")]),
      _c("avue-data-pay", { attrs: { option: _vm.option } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }