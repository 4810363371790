<template>
  <div class="log">
    <el-tabs type="border-card" @tab-click="handleClick" v-model="activeName">
      <el-tab-pane label="我的" name="myLog"></el-tab-pane>
      <el-tab-pane label="全部" name="allLog"></el-tab-pane>
    </el-tabs>
    <head-layout
      head-title="安全日志"
      :head-btn-options="headBtnOptions"
      @head-remove="handleDelete"
      @head-add="headAdd"
    ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-span="3"
        :search-columns="activeName == 'myLog' ? mySearchColumns : allSearchColumns"
        @grid-head-search="GridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      @grid-row-detail-click="detailView"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @gird-handle-select-click="selectionChange"
    >
      <template #logCode="{ row }">
        <el-link type="primary" @click="rowView(row)">{{
          row.logCode
        }}</el-link>
      </template>
      <template #customBtn="{ row }">
        <template v-if="row.status == 1 && row.createUser == userInfo.user_id">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowEdit(row)"
            >编辑</el-button
          >
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowDel(row)"
            >删除</el-button>
        </template>
        <template v-else>
          <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowView(row)"
          >查看</el-button
        >
        <el-button
          size="small"
          type="text"
          @click.stop="handleFlow(row, index)"
        >
          流程图
        </el-button>
        <el-button
          size="small"
          type="text"
          @click.stop="headProgress(row, index)"
        >
          流程进度
        </el-button>
        </template>
      </template>
    </grid-layout>
    <!-- 流程图 -->
    <CommonDialog v-if="bpmnVisible" width="70%" class="wf-dialog" :showBtn="false" :dialogTitle="$t(`cip.desk.workflow.title.indexHeadVisible`)" @cancel="bpmnVisible = false">
      <wf-design ref="bpmn"
                 style="height: 60vh;"
                 :options="bpmnOption"></wf-design>
    </CommonDialog>
    <CommonDialog v-if="circulationVisible" width="35%" :showBtn="false" :dialogTitle="$t(`cip.plat.wfOps.process.title.processProgressTitle`)" @cancel="circulationVisible = false">
      <wf-flow :flow="flow"></wf-flow>
    </CommonDialog>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonDialog from "@/components/CommonDialog";
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue';

import {
  LogList,
  getLogDetail,
  addLog,
  remove,
  getCode,
} from "@/api/siteManagement/securityLog.js";
import { mapGetters } from "vuex";
import {getDictionaryCode} from "@/api/system/dictbiz";
import {detail} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";

export default {
    mixins:[exForm],
  components: {
    GridLayout,
    HeadLayout,
    CommonDialog,
    WfFlow
  },

  data() {
    return {
      query: {},
      showLayout: true,
      // activeName: "",
      activeName: "myLog",
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableLoading: true,
      statusList: [],
      bpmnOption: {},
      bpmnVisible: false,
      circulationVisible: false,
    };
  },

  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        label: "新增",
        emit: "head-add",
        btnOptType: "add",
      });
      buttonBtn.push({
        label: "删除",
        emit: "head-remove",
        btnOptType: "remove",
      });
      return buttonBtn;
    },
    tableOption () {
      return {
        rowkey: "id",
        linklabel: "logCode",
        menuFixed: "right", //操作列冻结
        menuWidth: 180,
        column: [
          {
            label: "日志编号",
            prop: "logCode",
            slot: true,
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "标题",
            prop: "title",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "内容摘要",
            prop: "content",
            align: "left",
            overHidden: true,
          },
          {
            label: "编制人",
            prop: "nickName",
            align: "center",
            overHidden: true,
            width: 100,
          },
          {
            label: "所在部门",
            prop: "projectName",
            align: "center",
            overHidden: true,
            width: 120,
          },
          {
            label: "日期",
            prop: "logDate",
            align: "center",
            overHidden: true,
            width: 120,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            overHidden: true,
            width: 100,
            // dicUrl: '/api/sinoma-system/dict-biz/dictionary-tree?code=workflow_status',
            // props: {
            //     label: 'dictValue',
            //     value: 'dictKey',
            // },
            formatter: (row,value) => {
              const obj = this.statusList.find(val => val.dictKey==value)
              return obj&&obj.dictValue ? obj.dictValue : ''
            }
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: "center",
            overHidden: true,
            width: 180,
          },
        ],
      }
    },

    mySearchColumns () {
      return [
        {
          label: "日志编号",
          prop: "logCode",
          span: 3,
          type: 'input',
          placeholder: "请输入日志编号",
        },
        {
          label: "日志标题",
          prop: "title",
          span: 3,
          type: 'input',
          placeholder: "请输入日志标题",
        },
        {
          label: "内容摘要",
          prop: "content",
          span: 3,
          type: 'input',
          placeholder: "请输入内容摘要",
        },
        {
          label: "状态",
          prop: "status",
          span: 3,
          placeholder: "请选择状态",
          type: 'select',
          // dicData: this.statusList,
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=workflow_status',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          }
        },
        {
          label: "时间范围",
          prop: "logDate",
          type: "daterange",
          format: "yyyy-MM-dd",
          clearable: true,
          span: 5,
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期",
        },
      ]
    },

    allSearchColumns () {
      return [
        {
          label: "日志编号",
          prop: "logCode",
          span: 3,
          type: 'input',
          placeholder: "请输入日志编号",
        },
        {
          label: "日志标题",
          prop: "title",
          span: 3,
          type: 'input',
          placeholder: "请输入日志标题",
        },
        {
          label: "内容摘要",
          prop: "content",
          span: 3,
          type: 'input',
          placeholder: "请输入内容摘要",
        },
        {
          label: "编制人",
          prop: "nickName",
          span: 3,
          type: 'input',
          placeholder: "请输入编制人",
        },
        {
          label: "状态",
          prop: "status",
          span: 3,
          placeholder: "请选择状态",
          type: 'select',
          // dicData: this.statusList,
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=workflow_status',
          props: {
            value: 'dictKey',
            label: 'dictValue'
          }
        },
        {
          label: "时间范围",
          prop: "logDate",
          type: "daterange",
          format: "yyyy-MM-dd",
          clearable: true,
          span: 5,
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期",
        },
      ]
    }

  },
  created () {
    getDictionaryCode('workflow_status').then(res=>{
      this.statusList = res.data.data;
      this.mySearchColumns[3].dicData = this.statusList
      this.allSearchColumns[4].dicData = this.statusList
    })
  },

  mounted() {
    this.onLoad(this.page, {});
    console.log(0,this.$refs.gridHeadLayout.searchForm)
  },
  methods: {
    headAdd() {
      this.$router.push({
        path: `/siteManagement/securityLog/edit`,
        query: {
          type: "add",
        },
      });
    },
    handleDelete() {
      let delStatus = true
      let ids = this.selectionList.map(val => {
        if(val.status!=1){
          delStatus = false
        }
        return val.id
      }).toString()
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      } else if (!delStatus) {
        this.$message.warning("已提交数据不可删除")
        return
      } else {
        this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: "warning",
        }).then(() => {
          remove(ids).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess"),
              });
            }
          });
        });
      }
    },
    handleClick() {
      console.log(this.$refs.gridHeadLayout)
      console.log(1,this.$refs.gridHeadLayout.searchForm)
      this.page.currentPage = 1
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page)
      //   this.onLoad(this.page);
      // if (this.activeName == "myLog") {
      //   this.activeName = "myLog";
      //   this.$refs.gridHeadLayoutcc.searchForm = {};
      //   this.onLoad(this.page);
      // } else if (this.activeName == "allLog") {
      //   this.activeName = "allLog";
      //   this.$refs.gridHeadLayout.searchForm = {};
      //   this.onLoad(this.page);
      // }
    },
    // 查看
    rowView(row) {
      this.$router.push({
        path: `/siteManagement/securityLog/edit`,
        query: {
          id: row.id,
          logCode: row.logCode,
          type: "view",
        },
      });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/siteManagement/securityLog/edit`,
        query: {
          id: row.id,
          logCode: row.logCode,
          type: "edit",
          status: row.status,
        },
      });
    },

    //行删除
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        remove(row.id).then((res) => {
          if (res.data.code == 200) {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
    GridHeadSearch(searchForm) {
      if (searchForm.logDate) {
        searchForm.logDateStart = searchForm.logDate[0];
        searchForm.logDateEnd = searchForm.logDate[1];
      }
      delete searchForm.logDate;
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },

    gridHeadEmpty() {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page);
    },

    selectionChange(list) {
      this.selectionList = list;
    },

    // 获取 我的 列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      this.query.organizationId = this.userInfo.dept_id;
      if (this.activeName == "myLog") {
        this.query.userId = this.userInfo.user_id;
      }
      if (this.activeName == "allLog") {
        delete this.query.userId;
      }
      LogList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
          this.selectionList = [];
        }
      });
    },

    handleFlow(row) {
      const {processInstanceId} = row
      detail({processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data
        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }

        this.bpmnVisible = true
      })
    },
    headProgress(row) {
      const {processInstanceId} = row
      this.getTaskDetail('', processInstanceId).then(() => {
        this.waiting = false // 关闭骨架屏
      })
      this.circulationVisible = true
    },
  },
};
</script>
<style lang="scss">
.log {
  .el-tabs__content {
    display: none;
  }
  .avue-crud .el-table {
    height: calc(100vh - 314px) !important;
    max-height: calc(100vh - 314px) !important;
  }
}
</style>
