<template>
  <div>
    <el-container>
      <CommonTree
        :loading="treeLoading"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        treeTitle="组织架构"
        :isShowdig="false"
        :showCheckbox="false"
        :default-expand-all="true"
        :treeExpand="true"
        :expandOnClickNode="false"
        :currentNodeKey="currentNodeKey"
        :defaultExpandedKeys="defaultExpandedKeys"
        :defaultCheckedKeys="defaultCheckedKeys"
        :nodeKey="'id'"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <!--本级组织的项目人员汇总信息-->
        <head-layout head-title="本级组织的项目人员汇总信息"></head-layout>
        <grid-head-layout
          ref="levelHeadLayout"
          :search-columns="levelSearchColumns"
          @grid-head-search="levelSearch"
          @grid-head-empty="levelEmpty"
        ></grid-head-layout>
        <grid-layout
          class="overseasPerson"
          ref="gridLayOut"
          :table-options="levelTableOptions"
          :table-data="levelTableData"
          :table-loading="levelTableLoading"
          :data-total="levelPage.total"
          :page="levelPage"
          @grid-row-detail-click="rowView"
          @page-current-change="levelOnLoad"
          @page-size-change="levelOnLoad"
          @page-refresh-change="levelOnLoad"
        >
          <template #customBtn="{ row }">
            <el-button type="text" @click="rowApprove(row)">填报</el-button>
            <el-button type="text" @click="rowApprove(row)">流程详情</el-button>
          </template>
        </grid-layout>
        <!--下级组织及项目人员信息-->
        <head-layout head-title="下级组织及项目人员信息"></head-layout>
        <grid-head-layout
          ref="belowHeadLayout"
          :search-columns="belowSearchColumns"
          @grid-head-search="belowSearch"
          @grid-head-empty="belowEmpty"
        ></grid-head-layout>
        <grid-layout
          class="overseasPerson"
          ref="gridLayOut"
          :table-options="belowTableOptions"
          :table-data="belowTableData"
          :table-loading="belowTableLoading"
          :data-total="belowPage.total"
          :page="belowPage"
          @grid-row-detail-click="rowView"
          @page-current-change="belowOnLoad"
          @page-size-change="belowOnLoad"
          @page-refresh-change="belowOnLoad"
        >
          <template #customBtn="{ row }">
            <el-button type="text" @click="rowApprove(row)">填报</el-button>
            <el-button type="text" @click="rowApprove(row)">流程详情</el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import { levelPageList, belowPageList } from "@/api/fillAndSummary";
import {
  getAuditPage,
  reportReviewTree as reportTree,
} from "@/api/foreignReport/domesticReport";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      // 左侧树参数
      treeLoading: true,
      treeData: [],
      defaultProps: {
        label: "deptName",
        value: "deptName",
        key: "id",
      },
      searchTitle: "deptName",
      currentNodeKey: "",
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      nodeId: "",
      // 本级列表参数
      levelPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      levelTableData: [],
      levelTableLoading: true,
      levelQuery: {
        orgCode: "",
        projectArea: "2",
        dateSource: "2",
      },
      levelSearchColumns: [
        {
          label: "数据状态",
          prop: "dataState",
          span: 4,
          type: "select",
          dataType: "string",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: "请选择数据状态",
        },
        {
          label: "统计日期",
          prop: "statDate",
          type: "date",
          span: 4,
          placeholder: "请选择统计日期",
        },
      ],
      levelTableOptions: {
        index: true,
        indexLabel: "序号",
        menuFixed: "right",
        column: [
          {
            label: "统计日期",
            prop: "statDate",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "中方员工总数",
            prop: "chineseCnt",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "本公司派出在册中方职工数",
            prop: "regeditedChineseCnt",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "劳务公司派遣中方员工数",
            prop: "dispatchedChineseCnt",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "第三方中方技术服务人员数",
            prop: "thirdPartyChineseCnt",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "分包商中方员工数",
            prop: "subcontractorChineseCnt",
            align: "center",
            overHidden: true,
            width: 180,
          },
          {
            label: "外籍员工总数",
            prop: "foreignerCntS",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "外籍正式合同员工数",
            prop: "regularForeignerCnt",
            align: "center",
            overHidden: true,
            width: 180,
          },
          {
            label: "其他外籍人员数",
            prop: "otherForeignerCnt",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "内部人员增减说明",
            prop: "remark1",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "外部人员增减说明",
            prop: "remark2",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "数据状态",
            prop: "dataState",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
            fixed: "right",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
            width: 100,
          },
        ],
      },
      // 下级列表参数
      belowPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      belowTableData: [],
      belowTableLoading: true,
      belowQuery: {
        affiliationDept: "",
        projectArea: "2",
        templateCode: "overseasCode",
      },
      belowSearchColumns: [
        {
          label: "项目名称",
          prop: "projectName",
          span: 4,
          placeholder: "请输入项目编号或项目名称",
        },
        {
          label: "数据状态",
          prop: "dataState",
          span: 4,
          type: "select",
          dataType: "string",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: "请选择数据状态",
        },
      ],
      belowTableOptions: {
        index: true,
        indexLabel: "序号",
        menuFixed: "right",
        column: [
          {
            label: "组织/项目编号",
            prop: "projectCode",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "组织/项目名称",
            prop: "projectName",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "中方员工总数",
            prop: "chineseCnt",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "本公司派出在册中方职工数",
            prop: "regeditedChineseCnt",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "劳务公司派遣中方员工数",
            prop: "dispatchedChineseCnt",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "第三方中方技术服务人员数",
            prop: "thirdPartyChineseCnt",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "分包商中方员工数",
            prop: "subcontractorChineseCnt",
            align: "center",
            overHidden: true,
            width: 180,
          },
          {
            label: "外籍员工总数",
            prop: "foreignerCntS",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "外籍正式合同员工数",
            prop: "regularForeignerCnt",
            align: "center",
            overHidden: true,
            width: 180,
          },
          {
            label: "其他外籍人员数",
            prop: "otherForeignerCnt",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "内部人员增减说明",
            prop: "remark1",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "外部人员增减说明",
            prop: "remark2",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "数据状态",
            prop: "dataState",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
            fixed: "right",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
            width: 100,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  mounted() {},
  methods: {
    treeNodeClick(node) {
      this.nodeId = node.id;
      this.levelOnLoad(this.levelPage);
      this.belowOnLoad(this.belowPage);
    },
    initData() {
      this.initTree();
    },
    initTree() {
      this.treeLoading = true;
      reportTree().then((result) => {
        this.treeData = result.data.data;
        if (this.currentNodeKey == "") {
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.treeData[0].id);
          });
        }
        this.treeLoading = false;
      });
    },
    levelSearch(searchForm) {
      this.levelPage.currentPage = 1;
      this.levelOnLoad(this.levelPage);
    },
    levelEmpty() {
      this.levelPage.currentPage = 1;
      this.levelOnLoad(this.levelPage);
    },
    levelOnLoad(page, params = {}) {
      if (this.nodeId) {
        this.levelPage = page;
        this.levelTableLoading = true;
        this.levelQuery.orgCode = this.nodeId;
        levelPageList(
          page.currentPage,
          page.pageSize,
          Object.assign(
            params,
            this.$refs.levelHeadLayout.searchForm,
            this.levelQuery
          )
        )
          .then((res) => {
            const data = res.data.data;
            this.levelPage.total = data.total;
            this.levelTableData = data.records;
          })
          .finally(() => {
            this.levelTableLoading = false;
          });
      }
    },
    belowOnLoad(page, params = {}) {
      if (this.nodeId) {
        this.belowPage = page;
        this.belowTableLoading = true;
        this.belowQuery.affiliationDept = this.nodeId;
        belowPageList(
          page.currentPage,
          page.pageSize,
          Object.assign(
            params,
            this.$refs.belowHeadLayout.searchForm,
            this.belowQuery
          )
        )
          .then((res) => {
            const data = res.data.data;
            this.belowPage.total = data.total;
            this.belowTableData = data.records;
          })
          .finally(() => {
            this.belowTableLoading = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .overseasPerson .avue-crud .el-table {
  height: calc(100vh - 678px) !important;
  max-height: calc(100vh - 678px) !important;
}
</style>
