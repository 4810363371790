<template>
  <div :class="['flex center', { disabled: disabled }]">
    <div class="flex col mr-16">
      <el-button class="mb-16" type="primary" plain :disabled="!canDrawBtn || isDrawing || disabled || canDelete" @click="handleStartDraw">开始绘制
      </el-button>
      <el-button class="mb-16 m-0" type="primary" plain :disabled="!isDrawing || disabled" @click="handleCancelDraw">结束绘制
      </el-button>
      <el-button class="mb-16 m-0" type="primary" plain :disabled="!canDelete || disabled" @click="handleDelete">删除
      </el-button>
      <el-button class="mb-16 m-0" type="primary" plain :disabled="!canClear || disabled" @click="handleClear">清空
      </el-button>
    </div>
    <div class="img-style">
      <smPlayer class="my-image-ctr" v-if="url" :videoUrl="url"
       @videoClose="onVideoClose" style="width: 600px; height: 400px" />
      <img v-else class="my-image-ctr" :src="bgImg" />
      <canvas id="myCanvas" width="500" height="350"></canvas>
    </div>
    <div style="width: 100px"></div>
  </div>
</template>

<script>
import smPlayer from '../comments/smPlayerNew';
  import bgImg from '@/assets/video.jpg';
  export default {
    name: "index",
    components: {smPlayer},
    props: {
      // 禁用
      disabled: {
        type: Boolean,
        default: false
      },
       // 视频播放
      url: {
        type: String,
        default: null
      },
      allData: {
        type: Array,
        default: () => ([])
      }
    },
    data() {
      return {
        canDrawBtn: true,
        isDrawing: false,
        canDelete: false,
        canDraw: false,
        chosenRois: [],
        rois: [],
        flag: false,
        canvas: null,
        cvsClientRect: null,
        ctx: null,
        currentPoints: [],
        floatRois: [],
        isShowRoi: [],


      }
    },
    mounted() {
      // this.typeTree();
    },
    methods: {

      initCanvas() {
        this.flag = false;
        this.currentPoints = [];
        this.canvas = document.getElementById('myCanvas');
        if (this.canvas) {
          this.cvsClientRect = this.canvas.getBoundingClientRect();
          this.canvas.addEventListener('mousedown', this.mousedownHandler, false);
          this.ctx = this.canvas.getContext('2d');
          this.ctx.lineWidth = 4;
        }
      },
      translateFloatToRois(list) {
        this.rois = [];
        if (list.length > 0) {
          list.forEach(function (ele) {
            if (ele.vertex.length > 0) {
              let vertex = [];
              ele.vertex.forEach(function (ele) {
                let x = parseInt(ele[0] * 500);
                let y = parseInt(ele[1] * 350);
                vertex.push({
                  x: x,
                  y: y
                });
              });
              this.rois.push(vertex);
              this.isShowRoi.push({
                show: true
              });
            }
          });
        }
      },
      handleStartDraw() {
        this.canDraw = true;
        this.isDrawing = true;
        this.initCanvas();
        this.ctx.setLineDash([]);
      },
      onVideoClose() {
        this.$set(this.videoUrl, "");
      },
      handleCancelDraw() {
        this.isDrawing = false;
        this.ctx.closePath();
        this.canvas.removeEventListener('mousedown', this.mousedownHandler);
        this.canvas.removeEventListener('mousemove', this.mousemoveHandler);
        this.canDraw = false;
        this.drawPastPolygon();
      },
      handleDelete() {
        this.chosenRois.sort(function (a, b) {
          return b - a;
        });
        this.chosenRois.forEach(function (ele) {
          this.rois.splice(ele, 1);
          this.isShowRoi.splice(ele, 1);
        });
        this.chosenRois = [];
        this.drawPastPolygon();
        this.sendRoiToDetail();
      },
      handleClear() {
        this.rois = [];
        this.canDelete = false;
        this.canDrawBtn = true;
        this.chosenRois = [];
        this.drawPastPolygon();
        this.sendRoiToDetail();
      },
      transformRoiToFloat() {
        this.floatRois = [];
        if (this.rois.length > 0) {
          this.rois.forEach(function (ele) {
            let vertex = [];
            ele.forEach(function (ele) {
              let x = (parseFloat(ele.x) / 500).toFixed(3);
              let y = (parseFloat(ele.y) / 350).toFixed(3);
              vertex.push([x, y]);
            });
            this.floatRois.push(vertex);
          });
        }
      },
      mousemoveHandler(event) {
        let endPoint = {
          x: event.pageX - this.cvsClientRect.x,
          y: event.pageY - this.cvsClientRect.y
        };
        this.drawPastPolygon();
        this.drawPolygon();
        this.ctx.beginPath();

        this.ctx.moveTo(endPoint.x, endPoint.y);
        this.ctx.lineTo(
          this.currentPoints[this.currentPoints.length - 1].x,
          this.currentPoints[this.currentPoints.length - 1].y
        );
        // ctx.closePath();
        this.ctx.stroke();
      },
      isInChosen(num) {
        let result = false;
        if (this.chosenRois ?.length > 0) {
          this.chosenRois.forEach(function (ele) {
            if (ele == num) {
              result = true;
            }
          });
        }
        return result;
      },
      drawPastPolygon() {
        if (!this.ctx) return;
        this.ctx.clearRect(0, 0, 1080, 1080);
        let i = 0;
        while (i < this.rois.length) {
          if (this.isInChosen(i)) {
            this.ctx.strokeStyle = 'black';
            this.ctx.setLineDash([5]);
          } else {
            this.ctx.strokeStyle = '#7CFC00';
            this.ctx.setLineDash([]);
          }
          if (this.rois[i].length > 0) {
            this.ctx.beginPath();
            this.ctx.moveTo(this.rois[i][0].x, this.rois[i][0].y);
            for (let j = 1; j < this.rois[i].length; j++) {
              this.ctx.lineTo(this.rois[i][j].x, this.rois[i][j].y);
            }
            this.ctx.closePath();
            this.ctx.stroke();
          }
          i++;
        }
      },
      drawPastPolygon2(e, canvasInfo) {
        this.ctx.clearRect(0, 0, 1080, 1080);
        let i = 0;
        while (i < this.rois.length) {
          if (this.isInChosen(i)) {
            this.ctx.strokeStyle = 'black';
          } else if (isShowRoi[i].show) {
            this.ctx.strokeStyle = '#7CFC00';
          } else {
            this.ctx.strokeStyle = 'chocolate';
          }
          if (this.rois[i].length > 0) {
            this.ctx.beginPath();
            this.ctx.moveTo(this.rois[i][0].x, this.rois[i][0].y);
            for (let j = 1; j < this.rois[i].length; j++) {
              this.ctx.lineTo(this.rois[i][j].x, this.rois[i][j].y);
            }
            this.ctx.closePath();
            this.ctx.stroke();
            if (canvasInfo) {
              if (ctx.isPointInPath(e.clientX - canvasInfo.left, e.clientY - canvasInfo.top)) {
                this.chosenRois.push(i);
              }
            }
          }
          i++;
        }
        this.drawPastPolygon();
      },
      drawPolygon() {
        this.ctx.strokeStyle = 'blue';
        this.ctx.beginPath();
        if (this.currentPoints.length > 0) {
          this. ctx.moveTo(this.currentPoints[0].x, this.currentPoints[0].y);
          for (let i = 1; i < this.currentPoints.length; i++) {
            this.ctx.lineTo(this.currentPoints[i].x, this.currentPoints[i].y);
          }
        }
        if (this.flag == true) {
          this.ctx.closePath();
        }
        this.ctx.stroke();
      },
      mousedownHandler(event) {
        if (event.button == 0 && canDraw) {
          let clickPoint = {
            x: event.pageX - this.cvsClientRect.x,
            y: event.pageY - this.cvsClientRect.y
          };
          const start = this.currentPoints ?.[0];
          if (
            this.currentPoints.length >= 2 &&
            (start.x - clickPoint.x) * (start.x - clickPoint.x) +
            (start.y - clickPoint.y) * (start.y - clickPoint.y) <
            200
          ) {
            this.rois.push(this.currentPoints);
            this.isShowRoi.push({
              show: false
            });
            this.flag = true;
            this.canvas.removeEventListener('mousemove', this.mousemoveHandler);
            this.drawPastPolygon();
            this.isDrawing = false;
            this.canDraw = false;
            this.sendRoiToDetail();
          } else {
            this.currentPoints.push(clickPoint);
            this.canvas.addEventListener('mousemove', this.mousemoveHandler, false);
          }
          // drawPolygon(points);
        } else if (event.button == 2 || this.flag == true) {
          this.canvas.removeEventListener('mousemove', this.mousemoveHandler);
        }
      },
      sendRoiToDetail() {
        this.transformRoiToFloat();
        let regions = [];
        this.floatRois.forEach(ele => {
          regions.push({
            vertex: ele
          });
        });
        console.log('regions :>> ', regions);
        emit('update:modelValue', regions);
      },
      mouseClickHandler(event) {
        this.ctx.clearRect(0, 0, 1080, 1080);
        let canvasInfo = this.canvas.getBoundingClientRect();
        chosenRois = [];
        this. drawPastPolygon2(event, canvasInfo);
      }



    },
  }

</script>
<style lang="scss" scoped>
  .disabled {
    pointer-events: none;
    /*禁用当前元素的所有鼠标事件*/
  }

  .form-row {
    display: flex;
    width: 300px;
    margin-bottom: 5px;
  }

  .ml-50 {
    margin-left: 50px;
  }

  .div-border {
    border: 1px solid #cccccc;
    margin: 10px;
  }

  .btnWidth {
    width: 55px;
    margin-left: 5px;
  }

  .inputWidth {
    width: 100px;
    /* margin-right: 3px; */
  }

  .roiDrawLayout {
    height: 450px;
    float: left;
  }

  .floatLayout {
    float: left;
  }

  .img-style {
    width: 500px;
    height: 350px;
  }

  #myCanvas {
    position: relative;
    top: -355px;
    left: -1px;
  }

  .my-image-ctr {
    height: 350px;
    width: 500px;
  }

</style>
