var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c(
      "div",
      { staticClass: "videoTopSet" },
      [
        _vm._l(_vm.numberList, function (item, index) {
          return _c(
            "el-button",
            {
              key: index,
              attrs: { type: _vm.activeNumber == index ? "primary" : "" },
              on: {
                click: function ($event) {
                  return _vm.chooseNumber(item, index)
                },
              },
            },
            [_vm._v(_vm._s(item) + " 屏\n    ")]
          )
        }),
        _vm.activeNumber == 0
          ? _c(
              "el-select",
              {
                ref: "Select",
                staticStyle: { width: "300px !important", margin: "0 12px" },
                attrs: { placeholder: "请选择设备" },
                on: { change: _vm.chooseOneVideo },
                model: {
                  value: _vm.videoUrl,
                  callback: function ($$v) {
                    _vm.videoUrl = $$v
                  },
                  expression: "videoUrl",
                },
              },
              _vm._l(_vm.videoUrls, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            )
          : _vm._e(),
        _vm.activeNumber != 0
          ? _c(
              "el-select",
              {
                ref: "Select",
                staticStyle: { width: "800px !important", margin: "0 12px" },
                attrs: { multiple: "", placeholder: "请选择设备" },
                on: { change: _vm.chooseVideo },
                model: {
                  value: _vm.moreVideoUrl,
                  callback: function ($$v) {
                    _vm.moreVideoUrl = $$v
                  },
                  expression: "moreVideoUrl",
                },
              },
              _vm._l(_vm.videoUrls, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            )
          : _vm._e(),
        _vm.activeNumber != 0
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.analysis } },
              [_vm._v("解析")]
            )
          : _vm._e(),
        _vm.activeNumber != 0
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.resetting } },
              [_vm._v("重置")]
            )
          : _vm._e(),
      ],
      2
    ),
    _vm.activeNumber == 0
      ? _c("div", { staticClass: "videoOne" }, [
          _c("video", { ref: "videoPlayer", staticClass: "video-player" }),
        ])
      : _vm._e(),
    _vm.activeNumber == 1
      ? _c(
          "div",
          { staticClass: "video-container" },
          _vm._l(_vm.moreUrlList, function (url, index) {
            return _c("div", { key: index, staticClass: "video-wrapper" }, [
              _c("video", {
                ref: "videoPlayer" + index,
                refInFor: true,
                staticClass: "video-player-two",
              }),
              _vm.loadingShow
                ? _c("img", {
                    staticClass: "loadingGif",
                    attrs: { src: require("@/assets/images/videoLoading.gif") },
                  })
                : _vm._e(),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm.activeNumber == 2
      ? _c("div", { staticClass: "video-container-six" }, [
          _c(
            "div",
            { staticClass: "topVideo" },
            [
              _vm._l(_vm.moreUrlList.slice(0, 1), function (url, index) {
                return _c("div", { key: index, staticClass: "bigVideo" }, [
                  _c("video", {
                    ref: "videoPlayer" + index,
                    refInFor: true,
                    staticClass: "video-player-two",
                  }),
                  _vm.loadingShow
                    ? _c("img", {
                        staticClass: "loadingGif",
                        attrs: {
                          src: require("@/assets/images/videoLoading.gif"),
                        },
                      })
                    : _vm._e(),
                ])
              }),
              _c(
                "div",
                { staticStyle: { width: "33.3%", height: "100%" } },
                _vm._l(_vm.moreUrlList.slice(1, 3), function (url, index) {
                  return _c("div", { key: index, staticClass: "twoVideo" }, [
                    _c("video", {
                      ref: "videoPlayer" + Number(index + 1),
                      refInFor: true,
                      staticClass: "video-player-two",
                    }),
                    _vm.loadingShow
                      ? _c("img", {
                          staticClass: "loadingGif",
                          attrs: {
                            src: require("@/assets/images/videoLoading.gif"),
                          },
                        })
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "bottomVideo" },
            _vm._l(_vm.moreUrlList.slice(3, 6), function (url, index) {
              return _c("div", { key: index, staticClass: "threeVideo" }, [
                _c("video", {
                  ref: "videoPlayer" + Number(index + 3),
                  refInFor: true,
                  staticClass: "video-player-two",
                }),
                _vm.loadingShow
                  ? _c("img", {
                      staticClass: "loadingGif",
                      attrs: {
                        src: require("@/assets/images/videoLoading.gif"),
                      },
                    })
                  : _vm._e(),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }