var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personnelHolography-detail" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "人员全息详情",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-cancel": _vm.headCancel },
      }),
      _c(
        "div",
        { staticStyle: { height: "calc(100% - 52px)" } },
        [
          _c(
            "el-tabs",
            {
              attrs: { "tab-position": "left", type: "border-card" },
              on: { "tab-click": _vm.handleTabClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "人员基本信息", name: "personnelBaseInfo" } },
                [_c("personnelBaseInfo", { ref: "personnelBaseInfo" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "项目记录", name: "projectRecord" } },
                [_c("projectRecord", { ref: "projectRecord" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "出入境记录", name: "entryExitRecord" } },
                [_c("entryExitRecord", { ref: "entryExitRecord" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "人员证照", name: "personnelLicence" } },
                [_c("personnelLicence", { ref: "personnelLicence" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "培训记录", name: "trainRecord" } },
                [_c("trainRecord", { ref: "trainRecord" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "考试记录", name: "examRecord" } },
                [_c("examRecord", { ref: "examRecord" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "参会记录", name: "meetingRecord" } },
                [_c("meetingRecord", { ref: "meetingRecord" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "奖惩记录", name: "jiangchengRecord" } },
                [_c("jiangchengRecord", { ref: "jiangchengRecord" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }