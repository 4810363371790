var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("h3", [_vm._v("存储")]),
      _c("el-tag", { staticClass: "title", attrs: { size: "small" } }, [
        _vm._v("基本读写删(持久化存储) "),
      ]),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.setItem({ name: "username", value: "avuex" })
                },
              },
            },
            [_vm._v("set('username', 'avuex')\n    ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.getItem({ name: "username" })
                },
              },
            },
            [_vm._v("get('username')\n    ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.delItem({ name: "username" })
                },
              },
            },
            [_vm._v("remove('username')\n    ")]
          ),
        ],
        1
      ),
      _c("el-tag", { staticClass: "title", attrs: { size: "small" } }, [
        _vm._v("设置session(session存储) "),
      ]),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.setItem({
                    name: "username",
                    value: "avuex",
                    type: "session",
                  })
                },
              },
            },
            [_vm._v("set('username', 'avuex')\n    ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.getItem({ name: "username", type: "session" })
                },
              },
            },
            [_vm._v("get('username')\n    ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.delItem({ name: "username", type: "session" })
                },
              },
            },
            [_vm._v("remove('username')\n    ")]
          ),
        ],
        1
      ),
      _c("el-tag", { staticClass: "title", attrs: { size: "small" } }, [
        _vm._v("获取所有可以获得的数据 "),
      ]),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.getAll()
                },
              },
            },
            [_vm._v("getAll(持久化存储)\n    ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.getAll({ type: "session" })
                },
              },
            },
            [_vm._v("getAll(session存储)\n    ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.clearAll()
                },
              },
            },
            [_vm._v("delAll(持久化存储)\n    ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.clearAll({ type: "session" })
                },
              },
            },
            [_vm._v("delAll(session存储)\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }