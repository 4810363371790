var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "always" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "el-button-group",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPerm",
                      rawName: "v-hasPerm",
                      value: ["metadata:changerecord:add"],
                      expression: "['metadata:changerecord:add']",
                    },
                  ],
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    round: "",
                    loading: _vm.loadingOptions.loading,
                    disabled: _vm.loadingOptions.isDisabled,
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v(_vm._s(_vm.loadingOptions.loadingText))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-back", round: "" },
                  on: { click: _vm.showCard },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "body-wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "源数据表主键", prop: "objectId" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入源数据表主键" },
                    model: {
                      value: _vm.form.objectId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "objectId", $$v)
                      },
                      expression: "form.objectId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "源数据表的字段名", prop: "fieldName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入源数据表的字段名" },
                    model: {
                      value: _vm.form.fieldName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "fieldName", $$v)
                      },
                      expression: "form.fieldName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "原来的值", prop: "fieldOldValue" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入原来的值" },
                    model: {
                      value: _vm.form.fieldOldValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "fieldOldValue", $$v)
                      },
                      expression: "form.fieldOldValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最新的值", prop: "fieldNewValue" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入最新的值" },
                    model: {
                      value: _vm.form.fieldNewValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "fieldNewValue", $$v)
                      },
                      expression: "form.fieldNewValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本号", prop: "version" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入版本号" },
                    model: {
                      value: _vm.form.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "version", $$v)
                      },
                      expression: "form.version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (dict) {
                      return _c(
                        "el-radio",
                        { key: dict.id, attrs: { label: dict.itemText } },
                        [_vm._v(_vm._s(dict.itemValue))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入内容" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }