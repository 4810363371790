<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        :head-title="this.$route.query.type == 'edit' ? ($t('cip.plat.wfDesign.category.title.editHeadTitle')) : ($t('cip.plat.wfDesign.category.title.addHeadTitle'))"
        @head-save="headSave('save')"
        @head-save-back="headSave('back')"
        @head-cancel="headCancel">
      </head-layout>

      <form-layout
        :column="option.column"
        :dataForm="dataObj"
        ref="formLayout">
      </form-layout>
    </el-main>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {update, add,getDetail} from "@/api/plugin/workflow/category";

export default {
  components: {FormLayout, HeadLayout},

  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      dataObj: {},
      option: {
        dialogType: 'drawer',
        size: 'mini',
        align: 'center',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        searchMenuSpan: 6,
        border: true,
        index: true,
        selection: true,
        tree: true,
        defaultExpandAll: true,
        column: [
          {
            label: this.$t('cip.plat.wfDesign.category.field.name'),
            prop: "name",
            maxlength:64,
            showWordLimit:true,
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.category.field.name`),
              trigger: "blur"
            }, {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }],
            align: 'left',
            search: true
          },
          {
            label: this.$t('cip.plat.wfDesign.category.field.parentId'),
            prop: "pid",
            hide: true,
            type: 'tree',
            dicUrl: '/api/sinoma-workflow/design/category/tree',
            props: {
              label: 'name',
              value: 'id'
            },
          },
          {
            label: this.$t('cip.plat.wfDesign.category.field.sort'),
            prop: "sort",
            type: 'number',
            minRows: 0,
            maxRows: 1000000000,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t('cip.plat.wfDesign.category.field.sort'),
                trigger: "blur",
              },
            ],
          },
          {
            label: this.$t('cip.plat.wfDesign.category.field.isEnable'),
            prop: 'status',
            type: 'select',
            // dicData: [{
            //   label: this.$t('cip.plat.wfDesign.category.field.available'),
            //   value: 1
            // }, {
            //   label: this.$t('cip.plat.wfDesign.category.field.forbidden'),
            //   value: 2
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_available",
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.category.field.status`),
              trigger: "change"
            }],
            search: true
          },
        ]
      },
    }
  },
  created() {
    if (this.$route.query.type == 'edit') {
      this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
    }
  },
  methods: {
    initData(id){
      getDetail(id).then(res => {
        const {data} = res.data;
        this.dataObj = data;
      })
    },
    headSave(type) {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then((res) => {
            if (type == 'back') {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess'),
            });
            this.dataObj = res.data.data
            this.initData(this.dataObj.id)
            this.$refs.formLayout.$refs.form.allDisabled = false
            this.isShow = false
            //
          }, error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
