<template>
    <el-container class="inspection_records-index">
        <el-main>
            <head-layout
                head-title="检查记录"
                :head-btn-options="headBtnOptions"
                @head-add="headAdd"
            />
            <grid-head-layout
                ref="gridHeadLayout"
                :search-columns="searchColumns"
                @grid-head-search="gridHeadSearch"
                @grid-head-empty="gridHeadEmpty"
            ></grid-head-layout>
            <grid-layout ref="inspectionGridLayOut"
                    :table-options="tableOptions"
                    :table-data="tableData"
                    :table-loading="tableLoading"
                    :data-total="page.total"
                    :page="page"
                    :editType="inline"
                    @page-current-change="handleCurrentChange"
                    @page-size-change="handleSizeChange"
                    @page-refresh-change="onLoad"
                >
                <template #taskCode="{row}">
                    <el-link type="primary" @click="rowView(row)">{{ row.taskCode }}</el-link>
                </template>
                </grid-layout>
        </el-main>
    </el-container>
    <!-- <div class="inspectionRecords">
    </div> -->
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex"
import { taskRecord } from '@/api/equipmentFacilities/equipmentInspection'
import GridLayout from "@/views/components/layout/grid-layout";
export default {
    components: {
        GridLayout,
        HeadLayout
    },

    data () {
        return {
            searchColumns: [
                {
                    prop: "objectName",
                    placeholder: "请输入设备编号/名称",
                    span: 6,
                },
                {
                    prop: "time",
                    startPlaceholder: "开始日期",
                    endPlaceholder: "结束日期",
                    span: 8,
                    type: 'daterange',
                },
                {
                    prop: "taskStatus",
                    placeholder: "请选择任务状态",
                    span: 6,
                    search: true,
                    type: 'select',
                    dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=CHECK_TASK_STATUS',
                    props: {
                        label: 'dictValue',
                        value: 'dictKey',
                    },
                },
            ],
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0,
            },
            nodeData: {},
        }
    },

    computed: {
        ...mapGetters(['permission','userInfo']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.inspectionRecords_add, true),
                // viewBtn: this.vaildData(this.permission.eduquestions_view, true),
                // editBtn: this.vaildData(this.permission.eduquestions_edit, true),
                // delBtn: this.vaildData(this.permission.eduquestions_delete, true),
            };
        },

        headBtnOptions () {
            let buttonBtn = [];
            if (this.permissionList.addBtn) {
                buttonBtn.push({
                label: this.$t(`cip.cmn.btn.addBtn`),
                emit: "head-add",
                type: "button",
                icon: "",
                btnOptType: "add",
                });
            }
            return buttonBtn
        },
        tableOptions () {
            return {
                selection: false,
                menu: false,
                linklabel: 'taskCode',
                column: [
                    {
                        label: '任务编号',
                        prop: 'taskCode',
                        align: "center",
                        overHidden: true,
                        slot: true,
                    },
                    {
                        label: '设备编号',
                        prop: 'objectCode',
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '设备名称',
                        prop: 'objectName',
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '检查日期',
                        prop: 'checkTime',
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '检查人',
                        prop: 'userName',
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '任务状态',
                        prop: 'taskStatus',
                        align: "center",
                        overHidden: true,
                        // type: 'select',
                        dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=CHECK_TASK_STATUS`,
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                    },
                ]
            }
        }
    },

    methods:  {
        headAdd(){
            this.$router.push({
                path: `/hiddenTrouble/check/daily/edit`,
                query: {
                type: 'add',
                checkType: 3
                }
            })
        },

        init (data) {
            // 对象类型：设备设施
            this.nodeData = data
            this.tableData = []
            if(data){
                this.onLoad(this.page)
            } else {
                this.$refs.inspectionGridLayOut.page.total = 0;
                this.page.total = 0
                this.tableData = [];
            }
        },

        onLoad(page, params = {}) {
            // console.log("检查记录")
            this.tableLoading = true;
            // this.operition = false
            this.page = page;
            let queryObj = {...this.query,objectType: 'EQ',objectCode: this.nodeData.equipmentCode}
            delete queryObj.time
            taskRecord(page.currentPage, page.pageSize, Object.assign(params, queryObj,{organizationId:this.userInfo.dept_id})).then(res => {
                const data = res.data.data;
                console.log(data,'---',this.$refs.inspectionGridLayOut)
                this.$refs.inspectionGridLayOut.page.total = data.total;
                this.page.total = 0
                // this.$refs.inspectionGridLayOut.page.total = 1;
                this.tableData = data.records;
                // this.tableData = [{id:1,taskCode:'123'}]
                this.tableLoading = false;
                this.$refs.inspectionGridLayOut.selectionClear()
            });
        },
        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        // 搜索
        gridHeadSearch(query) {
            if(this.nodeData.equipmentCode){
                const data = this.query
                this.page.currentPage = 1;
                query.startTime = query.time ? query.time[0] + ' 00:00:00' :''
                query.endTime = query.time ? query.time[1]+ ' 23:59:59' :''
                this.query = { ...data, ...query };
                this.onLoad(this.page)
            } else {
                this.$message.warning('未选择设备')
            }
        },
        // 重置
        gridHeadEmpty(query) {
            if(this.nodeData.equipmentCode){
                this.page.currentPage = 1;
                const data = this.query
                this.query = { ...data, ...query };
                this.onLoad(this.page)
            } else {
                this.$message.warning('未选择设备')
            }
        },

        rowView(row){
            this.$router.push({
                path: `/hiddenTrouble/check/checkTasks/edit`,
                query: {
                actId: row.actId,
                id: row.id,
                type: 'view',
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.inspection_records-index{
    height: 100% !important;
    // overflow: hidden;
    .el-main{
        height: 100%;
        overflow: hidden;
    }

}
::v-deep #gridLayout {
  height: calc(100% - 130px) !important;
//   overflow: hidden;
}
::v-deep .avue-crud {
  height: 100% !important;

  .el-card{
      height: 100% !important;
    .el-card__body  {
      height: 100% !important;

      .el-form {
        height: 100% !important;

        .el-table {
          height: 100% !important;
        }
      }
  }

  }
}
</style>
