<template>
  <div class="riskItemView-checklist">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane
        :key="index"
        v-for="(item, index) in MD_SECTION"
        :label="item.label"
        :name="item.value"
      ></el-tab-pane>
    </el-tabs>
    <div class="flex-container">
      <!-- <CommonTree
        v-show="activeName == 1"
        ref="commonTree"
        treeTitle="项目架构"
        :treeData="treeData"
        :defaultProps="Props"
        :showCheckbox="false"
        @getNodeClick="handleNode"
        node-key="id"
      >
      </CommonTree> -->
      <CommonTreeNew
        ref="commonTree"
        treeTitle="项目架构"
        :defaultProps="Props"
        :showCheckbox="false"
        @getNodeClick="handleNode"
        :urlParmas="{
          tenantId: '',
          deptCategory: '',
          parentId: userInfo.dept_id,
        }"
        node-key="id"
        @getTreeData="commonTreeData"
        v-show="activeName == 1"
      ></CommonTreeNew>
      <div :class="activeName == 1 ? 'calcWidth' : 'Width'">
        <div v-show="activeName == '2'">
          <head-layout head-title="隐患统计" class="head-titles"></head-layout>
          <div class="charts-box">
            <el-card class="charts-icon">
              <div ref="bar0" class="icon charts-box no-border-right"></div>
            </el-card>
            <el-card class="charts-icon">
              <div
                class="bar charts-box no-border-right"
                ref="bar"
                v-loading="loading"
              ></div>
            </el-card>
            <el-card class="charts-icon">
              <div
                ref="bar1"
                class="icon charts-box no-border-right"
                v-loading="loading"
              ></div>
            </el-card>
          </div>
          <el-card class="pie-box">
            <div class="pie charts-icon" ref="pie" v-loading="loading1"></div>
          </el-card>
        </div>
        <div class="table-box" v-if="activeName != '2'">
          <head-layout
            head-title="隐患清单"
            :head-btn-options="headBtnOptions"
            @head-romve="headRomve"
            @head-add="headAdd"
          ></head-layout>
          <grid-head-layout
            ref="gridHeadLayout"
            :search-span="3"
            v-model="searchForm"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            ref="gridLayOut"
            :table-options="tableOption"
            :table-data="tableData"
            :table-loading="tableLoading"
            @grid-row-detail-click="rowView"
            @gird-handle-select-click="selectionChange"
            :data-total="page.total"
            :page="page"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
          >
            <template #hdLevel="{ row }">
              <el-tag
                v-if="row.hdLevel"
                :type="hdLevelIcon[row.hdLevel]"
                effect="dark"
                >{{ row.$hdLevel }}
              </el-tag>
            </template>
            <template #hdStatus="{ row }">
              <el-tag
                effect="dark"
                class="hdStatus"
                v-if="row.hdStatus && !row.outOfTime"
                :style="{
                  backgroundColor: hdStatusIcon[row.hdStatus],
                  borderColor: hdStatusIcon[row.hdStatus],
                }"
                >{{ row.$hdStatus }}
              </el-tag>
              <el-tag
                effect="dark"
                class="hdStatus"
                v-if="row.outOfTime"
                :style="{ backgroundColor: '#bd3124', borderColor: '#bd3124' }"
                >超期未整改
              </el-tag>
            </template>
            <template #customBtn="{ row }">
              <el-button
                style="margin: 0 3px"
                type="text"
                size="small"
                v-if="row.hdStatus != 'prepare'"
                @click="rowView(row)"
                >查看
              </el-button>
              <el-button
                style="margin: 0 3px"
                type="text"
                size="small"
                v-if="row.assessmentFlag && row.hdStatus == 'unassess'"
                @click="rowAssess(row)"
                >评估
              </el-button>
              <el-button
                style="margin: 0 3px"
                type="text"
                size="small"
                v-if="row.hdStatus == 'unrectify' && row.rectifyFlag"
                @click="rowRectification(row)"
                >整改
              </el-button>

              <el-button
                style="margin: 0 3px"
                type="text"
                size="small"
                v-if="
                  row.hdStatus == 'unaccept' &&
                  row.acceptUser == userInfo.user_id &&
                  row.acceptDept == userInfo.dept_id
                "
                @click="rowChecked(row)"
                >验收
              </el-button>
              <el-button
                style="margin: 0 3px"
                v-if="
                  row.hdStatus == 'prepare' &&
                  userInfo.user_id == row.createUser
                "
                type="text"
                size="small"
                @click="rowEdit(row)"
                >编辑
              </el-button>
              <el-button
                style="margin: 0 3px"
                v-if="
                  row.hdStatus == 'prepare' &&
                  userInfo.user_id == row.createUser
                "
                type="text"
                size="small"
                @click="rowRemove([row])"
                >删除
              </el-button>
            </template>
          </grid-layout>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import * as API from "@/api/riskitemView/checklist";
import checklist from "@/views/hiddenTrouble/riskItemView/checklist/checklist";
import riskView from "@/views/hiddenTrouble/riskItemView/riskView";
import * as echarts from "echarts";
import { mapGetters } from "vuex";
import {
  levelStatistics,
  statusStatistics,
} from "@/api/riskitemView/checklist";
import CommonTree from "@/views/components/com_tree/index.vue";
import { getDeptTree } from "@/api/system/dept";

export default {
  mixins: [checklist, riskView],
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      treeNode: {},
      treeData: [],
      Props: {
        children: "children",
        label: "title",
      },
      MD_SECTION: [
        { label: "全部", value: "1" },
        { label: "我的", value: "0" },
        // { label: "统计图", value: "2" },
      ],
      activeName: "1",
      selectionList: [],
      myPie: undefined,
      myBar0: undefined,
      myBar1: undefined,
      myBar: undefined,
      loading1: true,
      loading: true,
    };
  },
  mounted() {
    // this.handleClick();
    // this.getPrjstructureList();
  },
  methods: {
    //项目组织架构
    // getPrjstructureList() {
    //   const parentId = this.userInfo.dept_id;
    //   getDeptTree('', '', parentId).then((res) => {
    //     if ((res.data.code = 200)) {
    //       this.treeData = res.data.data
    //       if (res.data.data.length > 0) {
    //         this.$refs.commonTree.setCurrentKey(this.treeData[0].id)
    //         this.treeNode = this.treeData[0]
    //         this.handleClick()
    //       }
    //     }
    //   });
    // },
    handleNode(node) {
      this.treeNode = node;
      this.onLoad(this.page, {});
    },
    handleClick() {
      this.searchForm = {};
      if (this.activeName != "2") {
        this.onLoad(this.page, {});
      } else {
        this.$nextTick(() => {
          this.myPie = !this.myPie ? echarts.init(this.$refs.pie) : this.myPie;
          this.myBar0 = !this.myBar
            ? echarts.init(this.$refs.bar0)
            : this.myBar;
          this.myBar1 = !this.myBar
            ? echarts.init(this.$refs.bar1)
            : this.myBar;
          this.myBar = !this.myBar ? echarts.init(this.$refs.bar) : this.myBar;
          this.initChartData();
        });
      }
    },
    headAdd() {
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          type: "add",
        },
      });
    },
    async initChartData() {
      let data = await API.getPolicyDocumentList({
        organizationId: this.userInfo.dept_id,
      }).then((res) => res.data.data);
      let levelData = await API.levelStatistics({
        organizationId: this.userInfo.dept_id,
      }).then((res) => res.data.data);
      let statusData = await API.statusStatistics({
        organizationId: this.userInfo.dept_id,
      }).then((res) => res.data.data);
      let lineData = await API.getStatistics({
        organizationId: this.userInfo.dept_id,
      }).then((res) => res.data.data);

      this.loading = false;
      this.loading1 = false;

      this.initLine(this.myPie, lineData);
      this.initPie1(this.myBar1, statusData);
      this.initPie(this.myBar, levelData);
      if (data.statistics) {
        this.initPie1(this.myBar0, data.statistics);
      }
    },
    // 搜索
    gridHeadSearch() {
      this.page.currentPage = 1;
      let obj = {};
      if (this.searchForm.isTimeOut == 1) {
        obj.hdStatus = "unrectify";
      }
      this.onLoad(this.page, { ...this.searchForm, ...obj });
    },
    // 清空
    gridHeadEmpty() {
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    setData(list) {
      let newList = list.map((item) => {
        return {
          ...item,
          hdStatus: item.outOfTime ? "5" : item.hdStatus,
        };
      });
      return newList;
    },
    // 获取列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      API.getList({
        current: page.currentPage,
        size: page.pageSize,
        ...params,
        organizationId: this.activeName == "0" ? "" : this.treeNode.id,
        currentUserId: this.activeName == "0" ? this.userInfo.user_id : "",
        isMy: this.activeName == "0" ? "yes" : "",
        needChild: this.activeName == "1" ? "yes" : "",
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          id: row.id,
          type: "edit",
        },
      });
    },
    rowView(row) {
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          id: row.id,
          type: "view",
          hide: "hide",
        },
      });
    },
    rowAssess(row) {
      this.$router.push({
        path: "/hiddenTrouble/riskitemView/evaluate/edit",
        query: {
          id: row.id,
          type: "edit",
          hide: "",
        },
      });
    },
    rowRectification(row) {
      this.$router.push({
        path: "/hiddenTrouble/riskitemView/rectify/edit",
        query: {
          id: row.id,
          type: "edit",
          hide: "",
        },
      });
    },
    rowChecked(row) {
      this.$router.push({
        path: "/hiddenTrouble/riskitemView/acceptance/edit",
        query: {
          id: row.id,
          type: "edit",
          hide: "",
        },
      });
    },
    headRomve() {
      this.rowRemove(this.selectionList);
    },
    rowRemove(row) {
      this.selectionList = row;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let formData = new FormData();
          formData.append("ids", this.ids);
          return API.remove(formData);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.riskItemView-checklist {
  height: 100%;
  width: 100%;
  .table-box {
    height: calc(100% - 56px) !important;
  }

  .calcWidth {
    width: calc(100% - 280px);
  }
  .Width {
    width: 100%;
  }
  #gridLayout {
    height: calc(100% - 100px);
  }
  ::v-deep .common_tree_handle .el-tree {
    height: calc(100vh - 260px) !important;
  }
  ::v-deep .avue-crud .el-table {
    height: calc(100vh - 312px) !important;
    max-height: calc(100vh - 312px) !important;
  }
  .charts-box {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .no-border-right {
      border-right: 0;
    }
  }

  .charts-box {
    height: 260px;
  }

  .charts-icon {
    width: calc(100% / 3 - 16px);
    height: 260px;
  }

  .pie-box {
    margin-top: 15px;
  }

  .pie {
    width: 100%;
  }
}

.head-titles {
  line-height: 28px;
}

::v-deep .el-tabs__header {
  margin-bottom: 0;
}

::v-deep .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}

::v-deep .el-tabs__content {
  padding: 0 !important;
}
</style>
