var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            ref: "commonTreeCore",
            attrs: {
              treeTitle: "组织架构",
              searchTitle: _vm.searchTitle,
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              isShowdig: false,
              showCheckbox: false,
              "node-key": "id",
            },
            on: { getNodeClick: _vm.handleNodeClick },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "相关方人员注册审批",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-batch-pass": _vm.handleBacthPass },
              }),
              _c(
                "el-tabs",
                {
                  staticStyle: { padding: "0 12px" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "待审批", name: "pending" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "已通过", name: "passed" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "未通过", name: "refuse" },
                  }),
                ],
                1
              ),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  editType: _vm.inline,
                  "grid-row-btn": _vm.gridRowBtn,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-pass": _vm.handlePass,
                  "row-refuse": _vm.handleRefuse,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }