var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTreeNew", {
            ref: "commonTree",
            attrs: {
              treeTitle: "项目架构",
              defaultProps: _vm.Props,
              showCheckbox: false,
              urlParmas: {
                tenantId: "",
                deptCategory: "2,5",
                parentId: _vm.userInfo.deptId,
              },
              "node-key": "id",
            },
            on: {
              getNodeClick: _vm.treeNodeClick,
              getTreeData: _vm.commonTreeData,
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "培训任务",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-add": _vm.addFn },
              }),
              _c("grid-head-layout", {
                ref: "searchForm",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.searchChange,
                  "grid-head-empty": _vm.searchReset,
                },
                model: {
                  value: _vm.query,
                  callback: function ($$v) {
                    _vm.query = $$v
                  },
                  expression: "query",
                },
              }),
              _c("grid-layout", {
                ref: "gridLayout",
                attrs: {
                  tableOptions: _vm.tableOptions,
                  tableData: _vm.tableData,
                  tableLoading: _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  searchColumns: _vm.searchColumns,
                },
                on: {
                  "grid-row-detail-click": _vm.toView,
                  "page-size-change": _vm.onLoad,
                  "page-current-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "grid-view": _vm.toView,
                  "grid-romve": _vm.toDel,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row, index }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toView(row, index)
                              },
                            },
                          },
                          [_vm._v("\n            查看\n          ")]
                        ),
                        row.trainingStatus != 3 &&
                        _vm.isDateBeforeCurrent(row.endTime)
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toEdit(row, index)
                                  },
                                },
                              },
                              [_vm._v("\n            编辑\n          ")]
                            )
                          : _vm._e(),
                        row.trainingStatus == 1 &&
                        _vm.isDateBeforeCurrent(row.endTime)
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.startTraining(row, index)
                                  },
                                },
                              },
                              [_vm._v("\n            开始培训\n          ")]
                            )
                          : _vm._e(),
                        row.trainingStatus == 2 &&
                        _vm.isDateBeforeCurrent(row.endTime)
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.continuingTraining(row, index)
                                  },
                                },
                              },
                              [_vm._v("\n            扫码培训\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm.dialogVisible
                ? _c(
                    "CommonDialog",
                    {
                      attrs: { width: "30%", dialogTitle: "提示" },
                      on: {
                        cancel: function ($event) {
                          _vm.dialogVisible = false
                        },
                        confirm: _vm.SureToExecute,
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.dialogtext))])]
                  )
                : _vm._e(),
              _vm.trainingEducationStatisticsIndexDrawer
                ? _c(
                    "el-drawer",
                    {
                      attrs: {
                        title: "培训记录",
                        visible: _vm.trainingEducationStatisticsIndexDrawer,
                        direction: "rtl",
                        "append-to-body": true,
                        size: "70%",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.trainingEducationStatisticsIndexDrawer = $event
                        },
                      },
                    },
                    [
                      _c("trainingEducationStatisticsIndex", {
                        ref: "trainingEducationStatisticsIndex",
                        attrs: {
                          rowData: _vm.rowData,
                          type: "trainingEducationStatistics",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }