<template>
    <div>
        <el-container>
            <el-main>
                <head-layout
                    head-title="赋码管理"
                    :head-btn-options="headBtnOptions"
                    @head-download="headDownload"
                ></head-layout>
                <grid-head-layout
                    ref="gridHeadLayout"
                    :search-columns="searchColumns"
                    @grid-head-search="gridHeadSearch"
                    @grid-head-empty="gridHeadEmpty"
                ></grid-head-layout>
                <grid-layout
                    ref="gridLayOut"
                    :grid-row-btn="gridRowBtn"
                    :table-loading="tableLoading"
                    :table-options="tableOptions"
                    :table-data="tableData"
                    :page="page"
                    :data-total="page.total"
                    :editType="inline"
                    @page-current-change="handleCurrentChange"
                    @page-size-change="handleSizeChange"
                    @page-refresh-change="onLoad"
                    @row-view="rowView"
                    @row-download="rowDownload"
                    @gird-handle-select-click="selectionChange"
                >
                <template #qrcode="{row,index}">
                    <div class="column-qrcode">
                        <el-image :src="`data:image/png;base64,${row.codeUrl}`" style="width:30px;height:30px" @click="rowView(row,index,'qrcode')"></el-image>
                    </div>
                </template>
            </grid-layout>
            </el-main>
            <el-dialog :visible.sync="dialogVisible" title="赋码查看">
                <div class="coding-dialog" v-if="showInfo">
                    <div class="coding-img">
                        <el-image :src="`data:image/png;base64,${dataForm.codeUrl}`" style="width:200px;height:200px"></el-image>
                    </div>
                    <form-layout :column="formColumn" :dataForm="dataForm" ref="formLayout"></form-layout>
                </div>
                <div class="coding-qrcode" ref="qrcodeInfo" v-else>
                    <img :src="`data:image/png;base64,${dataForm.codeUrl}`" style="width: 200px;height:200px" alt="">
                    <p class="coding-content">{{ dataForm.objName }}</p>
                </div>
                <template slot="footer">
                    <el-button size="small" @click="download" v-if="!showInfo">下载 </el-button>
                    <el-button size="small" @click="dialogVisible=false" > {{ $t(`cip.cmn.btn.celBtn`) }} </el-button>
                </template>
            </el-dialog>
            <!-- 批量预览下载 -->
            <qrcode ref="qrcode" />
        </el-container>
    </div>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import formLayout from '@/views/components/layout/form-layout'
import { mapGetters } from 'vuex'
import {basecodePage, basecodeExportDoc} from '@/api/setting/coding/index'
import html2canvas from "html2canvas";
import {downloadXls} from "@/util/util";

export default {
    components: {
        HeadLayout,
        GridLayout,
        formLayout,
    },

    data () {
        return {
            searchColumns: [
                {
                    prop: "objCode",
                    placeholder: "请输入对象编号",
                    span: 4
                },
                {
                    prop: "objName",
                    placeholder: "请输入对象名称",
                    span: 4
                },
                {
                    prop: "objType",
                    placeholder: "请选择对象类型",
                    span: 4,
                    type: 'select',
                    dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=bs_code_obj_type',
                    props: {
                        label: 'dictValue',
                        value: 'dictKey',
                    },
                },
                {
                    prop: "time",
                    startPlaceholder: "开始日期",
                    endPlaceholder: "结束日期",
                    span: 4,
                    type: 'daterange',
                },
            ],
            page: {
                currentPage: 1,
                pageSize: 20,
                total: 0,
            },
            query: {},
            tableData: [],
            tableLoading: false,
            dialogVisible: false,
            dataForm: {},
            showInfo: true,
            selectionList: [],
        }
    },

    computed: {
        ...mapGetters(["permission", 'colorName']),

        permissionList() {
            return {
                downloadBtn: this.vaildData(this.permission.coding_download, true),
                viewBtn: this.vaildData(this.permission.coding_view, true),
            };
        },

        headBtnOptions () {
            let btnList = []
            if (this.permissionList.downloadBtn){
                btnList.push({
                    label: '下载',
                    emit: "head-download",
                    type: "button",
                    icon: "",
                    btnOptType: 'download',
                })
            }
            return btnList
        },

        tableOptions () {
            return {
                selection: true,
                column: [
                    {
                        label: '二维码',
                        prop: 'qrcode',
                        align: 'center',
                        slot: true
                    },
                    {
                        label: "二维码内容",
                        prop: "codeContent",
                        align: "left",
                        overHidden: true,
                    },
                    {
                        label: "对象类型",
                        prop: "objType",
                        align: "center",
                        overHidden: true,
                        dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=bs_code_obj_type',
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                    },
                    {
                        label: "对象编号",
                        prop: "objCode",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "对象名称",
                        prop: "objName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "生成时间",
                        prop: "generateTime",
                        align: "center",
                        overHidden: true,
                        // width: 150,
                        formatter: (row, value, column) => {
                            if(value){
                                let arr = value.split("T")
                                return `${arr[0]} ${arr[1]}`
                            }
                            return ''
                        }
                    },
                ]
            }
        },

        gridRowBtn () {
            let btnList = []
            if (this.permissionList.viewBtn) {
                btnList.push({
                    label: this.$t(`cip.cmn.btn.viewBtn`),
                    emit: "row-view",
                    type: "button",
                    icon: "",
                    btnOptType: 'view',
                })
            }
            if (this.permissionList.downloadBtn){
                btnList.push({
                    label: '下载',
                    emit: "row-download",
                    type: "button",
                    icon: "",
                    btnOptType: 'download',
                })
            }
            return btnList
        },

        formColumn() {
            return [
                {
                    label: "二维码内容",
                    prop: "codeContent",
                    labelWidth: 120,
                    placeholder: "请填写",
                    span:24,
                },
                {
                    label: "对象类型",
                    prop: "objType",
                    labelWidth: 120,
                    placeholder: "请选择",
                    span:24,
                    type: 'select',
                    dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=bs_code_obj_type',
                    props: {
                        label: 'dictValue',
                        value: 'dictKey',
                    },
                },
                {
                    label: "对象编号",
                    prop: "objCode",
                    labelWidth: 120,
                    span:24,
                },
                {
                    label: "对象名称",
                    prop: "objName",
                    labelWidth: 120,
                    span:24,
                },
                {
                    label: "生成时间",
                    prop: "generateTime",
                    labelWidth: 120,
                    overHidden: true,
                    span: 24,
                },
            ]
        },
    },

    created () {
        this.onLoad(this.page)
    },

    methods: {
        gridHeadSearch(query) {
            const data = this.query
            this.page.currentPage = 1;
                query.startTime = query.time? query.time[0] : ''
                query.endTime = query.time? query.time[1] : ''
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },
        gridHeadEmpty(query) {
            this.page.currentPage = 1;
            const data = this.query
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },

        onLoad (page, params={}) {
            this.tableLoading = true;
            this.page = page;
            let queryObj = {...this.query}
            delete queryObj.time
            basecodePage(page.currentPage, page.pageSize, Object.assign(params, queryObj)).then(res => {
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.page.total = res.data.data.total;
                this.tableData = data.records;
                this.tableLoading = false;
                this.$refs.gridLayOut.selectionClear()
            });
            this.tableData = [{id:1}]
        },

        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },

        rowDownload (row,index) {
            this.showInfo = false
            this.dataForm = row
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.download()
            })
        },
        rowView (row,index,type) {
            this.showInfo = !type
            this.dataForm = row
            this.dialogVisible = true
            this.$nextTick(()=>{
                if(this.showInfo){
                    this.$refs.formLayout.$refs.form.allDisabled = true;
                }
            })
        },

        selectionChange (list) {
            this.selectionList = list
        },

        download () {
            html2canvas(this.$refs.qrcodeInfo,{useCORS: true}).then(canvas=>{
                // 创建一个图片元素
                var img = canvas.toDataURL("image/png");
                // 创建一个链接元素
                var link = document.createElement("a");
                link.href = img;
                // 设置下载的文件名
                link.download = this.dataForm.objName;
                // 触发链接点击
                link.click();
            });
        },

        headDownload () {
            if(this.selectionList.length){
                const ids = this.selectionList.map(val=>val.id).toString()
                basecodeExportDoc(ids).then(res => {
                    this.downloadDoc(res.data,'赋码信息')
                })
            } else {
                this.$message.warning("请选择需要下载的数据")
            }
        },

        downloadDoc (fileArrayBuffer, filename){
            let data = new Blob([fileArrayBuffer], {type: 'application/msword'});
            if (typeof window.chrome !== 'undefined') {
                // Chrome
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(data);
                link.download = filename;
                link.click();
            } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE
                var blob = new Blob([data], {type: 'application/force-download'});
                window.navigator.msSaveBlob(blob, filename);
            } else {
                // Firefox
                var file = new File([data], filename, {type: 'application/force-download'});
                window.open(URL.createObjectURL(file));
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.coding-dialog{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.coding-qrcode{
    text-align: center;
}
.coding-content{
    width: 300px;
    margin: 0 auto;
}
.column-qrcode{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
</style>
