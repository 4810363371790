var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "提示文本" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入提示文本" },
            model: {
              value: _vm.activeData.title,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "title", $$v)
              },
              expression: "activeData.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "提示图标" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData["show-icon"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "show-icon", $$v)
              },
              expression: "activeData['show-icon']",
            },
          }),
        ],
        1
      ),
      _c("el-form-item", { attrs: { label: "样式效果" } }, [
        _c(
          "div",
          { staticClass: "type-list" },
          _vm._l(_vm.typeList, function (item, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "type-list-item",
                class: { active: _vm.activeData.type === item.id },
                on: {
                  click: function ($event) {
                    _vm.activeData.type = item.id
                  },
                },
              },
              [
                _c("el-alert", {
                  attrs: {
                    title: item.fullName,
                    type: item.id,
                    closable: false,
                    center: "",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }