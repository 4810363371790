var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "casually" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "我的记录", name: "first" } }),
          _c("el-tab-pane", { attrs: { label: "全部", name: "second" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-c" },
        [
          _c("CommonTree", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName == "second",
                expression: "activeName == 'second'",
              },
            ],
            ref: "commonTreeCore",
            attrs: {
              treeTitle: "组织列表",
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              isShowdig: false,
              showCheckbox: false,
              "node-key": "id",
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "随手拍记录",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-romve": _vm.headRomve, "head-add": _vm.headAdd },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
                model: {
                  value: _vm.searchForm,
                  callback: function ($$v) {
                    _vm.searchForm = $$v
                  },
                  expression: "searchForm",
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOption,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowCodeView,
                  "gird-handle-select-click": _vm.selectionChange,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "hdStatus1",
                    fn: function ({ row }) {
                      return [
                        _c("div", {
                          staticClass: "status-box",
                          domProps: { innerHTML: _vm._s(_vm.formatter(row)) },
                        }),
                      ]
                    },
                  },
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        row.hdStatus != "submit" &&
                        row.createUser == _vm.userInfo.user_id
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowEdit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        row.hdStatus == "submit"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowCodeView(row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                        row.hdStatus == "prepare" &&
                        row.createUser == _vm.userInfo.user_id
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowDel([row])
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }