<template>
  <el-container class="eventManagement">
    <head-layout
      head-title="事故记录"
      :head-btn-options="headBtnOptions"
      @head-romve="headRomve"
      @head-add="headAdd"
    ></head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      @grid-row-detail-click="rowView"
      @gird-handle-select-click="selectionChange"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
    >
      <template #customBtn="{ row }">
        <el-button
          style="margin: 0 3px"
          v-if="
            row.accStatus == 'PREPARE' && row.createUser == userInfo.user_id
          "
          type="text"
          size="small"
          @click="rowEdit(row)"
          >编辑</el-button
        >
        <el-button
          style="margin: 0 3px"
          type="text"
          v-if="row.accStatus == 'FINISHED'"
          size="small"
          @click="rowView(row)"
          >查看</el-button
        >
        <el-button
          style="margin: 0 3px"
          type="text"
          v-if="row.accStatus == 'APPROVED'"
          size="small"
          @click="rowEdit(row)"
          >结果录入</el-button
        >
        <el-button
          style="margin: 0 3px"
          v-if="
            row.accStatus == 'PREPARE' && row.createUser == userInfo.user_id
          "
          type="text"
          size="small"
          @click="rowDel([row])"
          >删除</el-button
        >
      </template>
    </grid-layout>
  </el-container>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import * as API from "@/api/eventManagement/index";

import tableOption from "./tableOption";
import { mapGetters } from "vuex";
import Template from "@/views/message/template/list.vue";
import CommonTree from "@/views/components/com_tree/index";

export default {
  mixins: [tableOption],
  components: { CommonTree, Template, GridLayout, HeadLayout },
  data() {
    return {
      searchColumns: [
        {
          label: "",
          prop: "accCode",
          span: 3,
          placeholder: "请输入事故编号",
        },
        {
          label: "",
          prop: "accTitle",
          span: 3,
          placeholder: "请输入事故标题",
        },
        {
          label: "",
          prop: "address",
          span: 3,
          placeholder: "请输入发生地点",
        },
        {
          label: "",
          prop: "accLevel",
          type: "select",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=ACC_LEVEL`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 3,
          placeholder: "请选择事故等级",
        },
        {
          prop: "time",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          span: 4,
          startPlaceholder: "发生开始日期",
          endPlaceholder: "结束日期",
          type: "daterange",
        },
        {
          prop: "time1",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          span: 4,
          startPlaceholder: "上报开始日期",
          endPlaceholder: "结束日期",
          type: "daterange",
        },
      ],
      selectionList: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "primary",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: "",
        },
        {
          label: "月报",
          emit: "head-xxx",
          type: "button",
          btnOptType: "xxx",
          icon: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),

    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  async mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    handleClick() {
      if (this.activeName == "first") {
        this.onLoad(this.page, {});
      } else {
        this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
          this.treeData[0].id
        );
        this.treeNodeClick(this.node);
      }
    },
    headAdd() {
      this.$router.push({
        path: `/abortive/edit`,
        query: {
          type: "add",
        },
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      if (searchForm.time) {
        searchForm.accStartTime = searchForm.time[0] + " 00:00:00";
        searchForm.accEndTime = searchForm.time[1] + " 23:59:59";
      } else {
        searchForm.accStartTime = "";
        searchForm.accEndTime = "";
      }
      if (searchForm.time1) {
        // searchForm.reportStartTime=searchForm.time1[0]+' 00:00:00'
        // searchForm.reportEndTime=searchForm.time1[1]+' 23:59:59'
        searchForm.reportStartTime = searchForm.time1[0];
        searchForm.reportEndTime = searchForm.time1[1];
      } else {
        searchForm.reportStartTime = "";
        searchForm.reportEndTime = "";
      }
      delete searchForm.time;
      delete searchForm.time1;
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    headRomve() {
      this.rowDel(this.selectionList);
    },
    rowDel(row) {
      this.selectionList = row;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let formData = new FormData();
          formData.append("ids", this.ids);
          return API.remove(formData);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    // 获取列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      let organizationId = this.userInfo.dept_id;
      API.getList({
        current: page.currentPage,
        size: page.pageSize,
        ...params,
        organizationId: organizationId,
        accSource:1
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/abortive/edit`,
        query: {
          id: row.id,
          type: "edit",
        },
      });
    },

    rowView(row, path) {
      this.$router.push({
        path: "/abortive/edit",
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
  },
};
</script>
<style lang="scss">
.eventManagement {
  flex-direction: column !important;
  .flex-c {
    display: flex;
  }
  .avue-crud .el-table {
    height: calc(100vh - 334px) !important;
    max-height: calc(100vh - 334px) !important;
  }
}
</style>
