import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
  },
  watch: {
    activeName(newVal) {
      if (newVal == 'second') {
        this.tableOption.column[2].hide = false;
        this.tableOption.column[3].hide = false;
        // this.findObject(this.tableOption.column, "reportUserName").hide = false;
        // this.findObject(this.tableOption.column, "reportDeptName").hide = false ;
      } else {
        this.tableOption.column[2].hide = true;
        this.tableOption.column[3].hide = true;
        // this.findObject(this.tableOption.column, "reportUserName").hide = true;
        // this.findObject(this.tableOption.column, "reportDeptName").hide = true;
      }
    },
  },
  data() {
    return {
      tableOption: {
        selectable: (row) => {
          return row.hdStatus == 'prepare' && row.createUser == this.userInfo.user_id
        },
        menuFixed:'right',
        linklabel: 'shotCode',
        column: [{
          label: "记录编号",
          prop: "shotCode",
          align: "left",
          overHidden: true,
          width: 200
        },
          {
            label: "内容描述",
            prop: "description",
            align: "left",
            overHidden: true,
          },
          {
            label: "提交人",
            prop: "reportUserName",
            align: "center",
            overHidden: true,
            hide: true
          },
          {
            label: "提交组织",
            prop: "reportDeptName",
            align: "left",
            hide: true,
            overHidden: true,
          },
          {
            label: "业务类型",
            prop: "shotType",
            width: 150,
            align: "center",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=SHOT_TYPE`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },

          {
            label: "所属组织",
            prop: "organizationName",
            align: "left",
            overHidden: true
          },

          {
            label: "起草时间",
            prop: "createTime",
            align: "center",
            width: 170,
            overHidden: true
          },

          {
            label: "状态",
            prop: "hdStatus",
            align: "center",
            width: 100,
            type: 'select',
            dicData:[
              {dictKey:'submit',dictValue:'提交'},
              {dictKey:'prepare',dictValue:'草稿'}
            ] ,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },
          {
            width: 100,
            label: "处理进度",
            prop: "hdStatus1",
            align: "center",
            slot: true,
            overHidden: true
          },
        ],
      },
      tableData: [],
      tableLoading: true,
    }
  },
}
