var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "formBox",
        style: { height: _vm.type != "workflow" ? "calc(100vh - 251px)" : "" },
      },
      [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.dataForm,
                  rules: _vm.rules,
                  "label-width": "160px",
                },
              },
              [
                _c("head-layout", {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: {
                    "head-title": "基本信息",
                    "head-btn-options": _vm.headBtnOptions,
                  },
                }),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "统计日期", prop: "statDate" } },
                          [
                            _c("template", { slot: "label" }, [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("统计日期:")]),
                            ]),
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                disabled: _vm.type === "view",
                                placeholder: "请选择统计日期",
                              },
                              on: { change: _vm.chooseDate },
                              model: {
                                value: _vm.dataForm.statDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "statDate", $$v)
                                },
                                expression: "dataForm.statDate",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "填报日期", prop: "updateTime" } },
                          [
                            _c("template", { slot: "label" }, [
                              _c("span", [_vm._v("填报日期:")]),
                            ]),
                            _c("el-date-picker", {
                              attrs: {
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                disabled: "true",
                                placeholder: "保存后自动生成",
                              },
                              model: {
                                value: _vm.dataForm.updateTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "updateTime", $$v)
                                },
                                expression: "dataForm.updateTime",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "项目名称", prop: "projectName" } },
                          [
                            _c("template", { slot: "label" }, [
                              _c("span", [_vm._v("项目名称:")]),
                            ]),
                            _c("el-input", {
                              attrs: {
                                disabled: "true",
                                "controls-position": "right",
                                placeholder: "",
                              },
                              model: {
                                value: _vm.dataForm.projectName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "projectName", $$v)
                                },
                                expression: "dataForm.projectName",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "业务类型",
                              prop: "businessTypeName",
                            },
                          },
                          [
                            _c("template", { slot: "label" }, [
                              _c("span", [_vm._v("业务类型:")]),
                            ]),
                            _c("el-input", {
                              attrs: {
                                disabled: "true",
                                "controls-position": "right",
                                placeholder: "",
                              },
                              model: {
                                value: _vm.dataForm.businessTypeName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "businessTypeName",
                                    $$v
                                  )
                                },
                                expression: "dataForm.businessTypeName",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "项目状态:",
                              prop: "projectStatusName",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "",
                                  "popper-append-to-body": false,
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.dataForm.projectStatusName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "projectStatusName",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.projectStatusName",
                                },
                              },
                              _vm._l(_vm.projectStatusList, function (item) {
                                return _c("el-option", {
                                  key: item.dictKey,
                                  attrs: {
                                    label: item.dictValue,
                                    value: item.dictKey,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "州/省", prop: "provinceName" } },
                          [
                            _c("template", { slot: "label" }, [
                              _c("span", [_vm._v("州/省:")]),
                            ]),
                            _c("el-input", {
                              attrs: {
                                disabled: "true",
                                "controls-position": "right",
                                placeholder: "",
                              },
                              model: {
                                value: _vm.dataForm.provinceName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "provinceName", $$v)
                                },
                                expression: "dataForm.provinceName",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "项目经理", prop: "pmCame" } },
                          [
                            _c("template", { slot: "label" }, [
                              _c("span", [_vm._v("项目经理:")]),
                            ]),
                            _c("el-input", {
                              attrs: {
                                disabled: "true",
                                "controls-position": "right",
                                placeholder: "",
                              },
                              model: {
                                value: _vm.dataForm.pmCame,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "pmCame", $$v)
                                },
                                expression: "dataForm.pmCame",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "联系电话", prop: "phoneNumber" } },
                          [
                            _c("template", { slot: "label" }, [
                              _c("span", [_vm._v("联系电话:")]),
                            ]),
                            _c("el-input", {
                              attrs: {
                                disabled: "true",
                                "controls-position": "right",
                                placeholder: "",
                              },
                              model: {
                                value: _vm.dataForm.phoneNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "phoneNumber", $$v)
                                },
                                expression: "dataForm.phoneNumber",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "总人数", prop: "totalCount" } },
                          [
                            _c("template", { slot: "label" }, [
                              _c("span", [_vm._v("总人数:")]),
                            ]),
                            _c("el-input", {
                              staticStyle: { width: "calc(100% - 30px)" },
                              attrs: {
                                disabled: "true",
                                "controls-position": "right",
                                placeholder: "",
                              },
                              model: {
                                value: _vm.totalCount,
                                callback: function ($$v) {
                                  _vm.totalCount = $$v
                                },
                                expression: "totalCount",
                              },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "light",
                                  content:
                                    _vm.totalCountS < 0 ||
                                    _vm.totalCountS == null ||
                                    false
                                      ? "上次填报数据：0"
                                      : "上次填报数据：" + _vm.totalCountS,
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-warning-outline iconLine",
                                }),
                              ]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("head-layout", { attrs: { "head-title": "项目人员信息" } }),
            _c(
              "el-form",
              {
                ref: "formLayout1",
                staticClass: "demo-ruleForm",
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  model: _vm.dataForm,
                  rules: _vm.rules,
                  "label-width": "160px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "公司在册职工:",
                              prop: "regeditedChineseCnt",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "calc(100% - 30px)" },
                              attrs: {
                                "controls-position": "right",
                                placeholder: "",
                                maxlength: "8",
                                "show-word-limit": "",
                                readonly: _vm.type == "view",
                              },
                              model: {
                                value: _vm.dataForm.regeditedChineseCnt,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "regeditedChineseCnt",
                                    $$v
                                  )
                                },
                                expression: "dataForm.regeditedChineseCnt",
                              },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "light",
                                  content:
                                    _vm.regeditedChineseCntS < 0 ||
                                    _vm.regeditedChineseCntS == null ||
                                    false
                                      ? "上次填报数据：0"
                                      : "上次填报数据：" +
                                        _vm.regeditedChineseCntS,
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-warning-outline iconLine",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分包商(含劳务)人数:",
                              prop: "subcontractorChineseCnt",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "calc(100% - 30px)" },
                              attrs: {
                                "controls-position": "left",
                                placeholder: "",
                                maxlength: "8",
                                "show-word-limit": "",
                                readonly: _vm.type == "view",
                              },
                              model: {
                                value: _vm.dataForm.subcontractorChineseCnt,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "subcontractorChineseCnt",
                                    $$v
                                  )
                                },
                                expression: "dataForm.subcontractorChineseCnt",
                              },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "light",
                                  content:
                                    _vm.subcontractorChineseCntS < 0 ||
                                    _vm.subcontractorChineseCntS == null ||
                                    false
                                      ? "上次填报数据：0"
                                      : "上次填报数据：" +
                                        _vm.subcontractorChineseCntS,
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-warning-outline iconLine",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "人员增减说明:", prop: "remark1" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "calc(100% - 30px)" },
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 4, maxRows: 4 },
                                placeholder: "",
                                maxlength: "200",
                                "show-word-limit": true,
                                readonly: _vm.type == "view",
                              },
                              model: {
                                value: _vm.dataForm.remark1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "remark1", $$v)
                                },
                                expression: "dataForm.remark1",
                              },
                            }),
                            _c("i", {
                              staticClass: "el-icon-refresh iconLine",
                              on: { click: _vm.remark1 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("head-layout", {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: { "head-title": "项目现场信息" },
                }),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "项目进展情况:",
                              prop: "projectProgress",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "calc(100% - 30px)" },
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 4, maxRows: 4 },
                                maxlength: "200",
                                "show-word-limit": true,
                                readonly: _vm.type == "view",
                              },
                              model: {
                                value: _vm.dataForm.projectProgress,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "projectProgress", $$v)
                                },
                                expression: "dataForm.projectProgress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "dialog-footer" },
          [
            _vm.type != "view"
              ? _c("el-button", { on: { click: _vm.headSave } }, [
                  _vm._v("保 存"),
                ])
              : _vm._e(),
            _vm.type != "view"
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.headSubmit } },
                  [_vm._v("提 交")]
                )
              : _vm._e(),
            _vm.type == "view"
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.headCancel } },
                  [_vm._v("取 消")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }