<template>
  <el-container class="camera">
    <CommonTree ref="commonTreeCore" treeTitle="组织列表" :searchTitle="searchTitle" :treeData="treeData"
                :defaultProps="defaultProps" @getNodeClick="treeNodeClick" :isShowdig="false" :showCheckbox="false"
                node-key="id"/>

    <el-main>
      <head-layout
        head-title="随手拍记录"
        :head-btn-options="gridHeadBtn"
        @head-add="headAdd"
      ></head-layout>
      <grid-head-layout btn-span="1" ref="gridHeadLayout" :search-columns="searchColumns" :searchSpan="2"
                        @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>

      <grid-layout ref="gridLayOut" :grid-row-btn="gridRowBtn" :table-options="tableOptions" :table-data="tableData"
                   :table-loading="tableLoading" :data-total="dataTotal" @page-current-change="onLoad"
                   @gird-handle-select-click="selectionChange"
                   @page-size-change="onLoad"
                   @page-refresh-change="onLoad">
        <template #customBtn="{row}">
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel([row])">删除</el-button>
        </template>
      </grid-layout>
    </el-main>
    <el-dialog title="配置摄像头"
               v-dialogDrag
               :visible.sync="cameraShow"
               class="avue-dialog camera-dialog"
               width="40%">
      <grid-head-layout btn-span="1" ref="gridHeadLayout1" :search-columns="searchColumns1" :searchSpan="2"
                        @grid-head-search="gridHeadSearch1" @grid-head-empty="gridHeadEmpty1"></grid-head-layout>
      <grid-layout ref="gridLayOut" :table-options="option" :table-data="cameraData"
                   @gird-handle-select-click="handleSelectionChange"
                   :table-loading="tableLoading" :data-total="cameraTotal" @page-current-change="onLoad1"
                   @page-size-change="onLoad1"
                   @page-refresh-change="onLoad1">
      </grid-layout>
      <div class="avue-dialog__footer">
        <el-button @click="cameraShow=false">取 消</el-button>
        <el-button @click="handleSubmit" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import {mapGetters} from "vuex";
import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import * as API from "@/api/camera/index";
import {deptChildTree} from "@/api/reportTemplate";
import {camerabasePage} from "@/api/camera";

export default {
  name: "index",
  data() {
    return {
      multipleSelection: undefined,
      cameraData: [],
      selectionList: [],
      page: {
        currentPage: 1,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      },
      treeReadOnlyFlag: false,
      cameraShow: false,
      dataTotal: null,
      cameraTotal: null,
      tableData: [],
      treeLoading: false,
      tableLoading: false,
      treeData: [],
      query: {
        themeId: "",
      },
      indexCatCode: '', // 点击左侧树的code
      isTheme: '',
      node: {},   //左侧树node
      searchTitle: 'deptName',
      defaultProps: {
        children: "children",
        label: "deptName",
      },
    };
  },

  computed: {
    ...mapGetters(["permission", "userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    tableOptions() {
      return {
        height: 500,
        cameraList:[],
        selection: false,
        linklabel: "hdCode",
        column: [{
          label: "摄像头别名",
          prop: "cameraAlias",
          align: "left",
          overHidden: true,
        },
          {
            label: "摄像头名字",
            prop: "cameraName",
            align: "left",
            overHidden: true,
          },
          {
            label: "通道号",
            prop: "channelNo",
            align: "center",
          },
          {
            label: "系统类别",
            prop: "sysType",
            align: "left",
            type: 'select',
            dicData: [
              {dictKey: '0', dictValue: '海康'},
              {dictKey: '1', dictValue: '萤石'}
            ],
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: "项目状态",
            prop: "projectState",
            align: "center",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=PRO_LIST_STATE`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: "center",
            overHidden: true
          },

        ],
      }
    },
    gridHeadBtn() {
      return [
        {
          emit: "head-add",
          type: "button",
          btnOptType: "add",
        },
      ]
    },
    searchColumns1() {
      return [
        {
          label: '摄像头名字',
          prop: "cameraName",
          span: 5,
          placeholder: '请输入摄像头名字'
        },
        {
          label: '系统类别',
          prop: "sysType",
          span: 4,
          placeholder: '请选择状态',
          type: 'select',
          dicData: [
            {dictKey: 0, dictValue: '海康'},
            {dictKey: 1, dictValue: '萤石'}
          ],
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          span: 8,
          label: "视频系统",
          prop: "videoSystemId",
          align: "center",
          type: 'select',
          dicUrl: `/api/sinoma-sync/videosystem/page?current=1&size=500`,
          props: {
            label: 'sysName',
            value: 'id',
          },
          dicFormatter:(res)=>{
            return res.records
          },
          overHidden: true
        },
      ]
    },
    searchColumns() {
      return [{
        label: '',
        prop: "cameraName",
        span: 3,
        placeholder: '摄像头名字'
      },
        {
          label: 'cameraAlias',
          prop: "cameraAlias",
          span: 3,
          placeholder: '请输入地点'
        },
        {
          label: '状态',
          prop: "projectState",
          span: 2,
          type: 'select',
          placeholder: '请选择状态',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=PRO_LIST_STATE`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },

      ]
    },
    gridRowBtn() {
      return []
    },
    option(){
      return {
        menu:false,
        column: [
          {
            span:12,
            label: "摄像头名称",
            prop: "cameraName",
            align: "left",
            overHidden: true,
            props: {
              label: 'cameraName',
              value: 'id',
            },
            rules: [{
              required: true,
              message: "请选择摄像头名称",
              trigger: "blur"
            }],
          },
          {
            span:12,
            disabled:true,
            label: "系统类别",
            prop: "sysType",
            type: 'select',
            dicData: [
              {dictKey: 0, dictValue: '海康'},
              {dictKey: 1, dictValue: '萤石'}
            ],
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            span:12,
            disabled:true,
            label: "通道号",
            prop: "channelNo"
          },
          {
            disabled:true,
            label: "说明",
            minRows:3,
            type:'textarea',
            prop: "cameraInfo"
          }
        ]
      }
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
  },
  created() {
    let p1 = sessionStorage.getItem("zbparms")

    p1 = JSON.parse(p1);
    let p2 = JSON.parse(JSON.stringify(p1))
    if (p1) {
      if (p1.query.themeId) {
        this.themeId = p1.query.themeId
        this.query.themeId = p1.query.themeId
      }
      this.$nextTick(() => {
        this.searchForm = p2.query
        this.onLoad({pageSize: p2.size, currentPage: p2.current}, p2.query)
      })
    }
    this.lazyDeptTree();
  },

  methods: {
    headAdd() {
      this.cameraShow=!this.cameraShow;
      this.onLoad1({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1})
    },
    handleSubmit(){
      if(!this.multipleSelection)return this.$message.warning('请勾选需要绑定的摄像头')
      let multipleSelection=this.multipleSelection.map(item=>{
        return {
          cameraCode: item.cameraCode,
          cameraInfo: item.cameraInfo,
          cameraName: item.cameraName,
          channelNo: item.channelNo,
          cameraBaseId: item.id,
          deptId: this.node.id,
        }
      })
      API.cameraSave(multipleSelection).then(res=>{
        this.$message.success('保存成功')
        this.cameraShow=false
        this.gridHeadSearch()
      }).catch(err=>{
        this.$message.error(err)
      })
    },
    //获取树数据
    lazyDeptTree() {
      deptChildTree().then((res) => {
        this.treeData = [res.data.data];
        this.node = res.data.data
        this.$nextTick(() => {
          this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(this.node.id)
          this.treeNodeClick(this.node)
        })
      });
    },

    gridHeadSearch() {
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, this.searchForm)
    },
    gridHeadEmpty() {
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {})
    },
    gridHeadSearch1(val) {
      this.onLoad1({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, val)
    },
    gridHeadEmpty1() {
      this.onLoad1({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1})
    },
    treeNodeClick(node) {
      this.node = node;
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1},this.searchForm);
    },
    onLoad(page, params = {}) {
      API.cameraPage({
        current: page.currentPage,
        size: page.pageSize,
        deptId: this.node.id,
        ...params
      })
        .then((res) => {
          const data = res.data.data;
          this.dataTotal = data.total;
          this.tableData = data.records;
          sessionStorage.setItem("zbparms", p2)
          this.$nextTick(() => {
            this.searchForm = p1.query
          })

        })
        .catch(() => {
        });
    },
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    rowDel(row){
      this.selectionList=row
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let formData =new  FormData()
          formData.append('ids',this.ids)
          return API.remove(formData);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
    onLoad1(page, params = {}) {
      API.camerabasePage({
        current: page.currentPage,
        size: page.pageSize,
        ...params
      })
        .then((res) => {
          const data = res.data.data;
          this.cameraTotal = data.total;
          this.cameraData = data.records;
        })
        .catch(() => {
        });
    },
  },
};
</script>
<style lang="scss">
.camera {
  .searchTopBox {
    padding: 14px 12px;
    width: calc(100% - 24px);
    display: flex;
    justify-content: space-between;
  }

  .avue-crud .el-table {
    height: calc(100vh - 314px) !important;
    max-height: calc(100vh - 314px) !important;
  }
.camera-dialog{
  .avue-crud .el-table {
    height: 400px !important;
    max-height: 400px !important;
  }
}
  .leftSearch {
    width: 70%;
    //display: flex;
  }

  .searchBox {
    width: 25% !important;
    margin-right: 5px;
  }
}

</style>

