var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "transfer__body",
      style: { height: _vm.height },
    },
    [
      _c("div", { staticClass: "transfer-pane" }, [
        _c(
          "div",
          { staticClass: "transfer-pane__tools" },
          [
            _c(
              "el-input",
              {
                attrs: { placeholder: "输入关键词进行搜索", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  on: { click: _vm.search },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "transfer-pane__body" },
          [
            _c(
              "el-tabs",
              {
                staticClass: "transfer-pane__body-tab",
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "组织", name: "organize" } },
                  [
                    _c("el-tree", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "tree2",
                      staticClass: "SNOWS-common-el-tree",
                      attrs: {
                        data: _vm.treeData2,
                        props: _vm.props,
                        "default-expand-all": "",
                        "filter-node-method": _vm.filterNode,
                        "expand-on-click-node": false,
                        "check-on-click-node": "",
                        "node-key": "onlyId",
                      },
                      on: { "node-click": _vm.handleNodeClick },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ node, data }) {
                            return _c(
                              "span",
                              { staticClass: "custom-tree-node" },
                              [
                                _c("i", { class: data.icon }),
                                _c("span", { staticClass: "text" }, [
                                  _vm._v(_vm._s(node.label)),
                                ]),
                              ]
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "全局", name: "all" } },
                  [
                    _c("el-tree", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "tree1",
                      staticClass: "SNOWS-common-el-tree",
                      attrs: {
                        data: _vm.treeData,
                        props: _vm.props,
                        "default-expand-all": "",
                        "filter-node-method": _vm.filterNode,
                        "expand-on-click-node": false,
                        "check-on-click-node": "",
                        "node-key": "onlyId",
                      },
                      on: { "node-click": _vm.handleNodeClick },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ node, data }) {
                            return _c(
                              "span",
                              { staticClass: "custom-tree-node" },
                              [
                                _c("i", { class: data.icon }),
                                _c("span", { staticClass: "text" }, [
                                  _vm._v(_vm._s(node.label)),
                                ]),
                              ]
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "transfer-pane" }, [
        _c(
          "div",
          { staticClass: "transfer-pane__tools" },
          [
            _c("span", [_vm._v("已选")]),
            _c(
              "el-button",
              {
                staticClass: "removeAllBtn",
                attrs: { type: "text" },
                on: { click: _vm.removeAll },
              },
              [_vm._v("清空列表")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "transfer-pane__body shadow right-pane" },
          [
            _vm._l(_vm.selectedData, function (item, index) {
              return _c("div", { key: index, staticClass: "selected-item" }, [
                _c("span", [_vm._v(_vm._s(item.fullName))]),
                _c("i", {
                  staticClass: "el-icon-delete",
                  on: {
                    click: function ($event) {
                      return _vm.removeData(index)
                    },
                  },
                }),
              ])
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }