<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-13 11:41:21
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
    <nodeCommonTree class="introduceTree" treeTitle="节点数" node-key="id" pageType='node' :defaultExpandedKeys="defaultExpandedKeys" ref="commonTreeCore" @getTreeAdd="getTreeAdd" @getTreeAddChild="getTreeAddChild" @getTreeDelete="getTreeDelete" @getTreeEdit="getTreeEdit" :treeData="treeData" :defaultProps="defaultProps" @getNodeClick="TreeNodeClick" @getTreeDrag="getTreeDragClick"
      :showCheckbox="false" />

    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    add,
    getTree,
    move
  } from "@/api/safe/algorithmManagement";
  import {
    from
  } from "rxjs";
  import Template from "@/views/message/template/list.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {
    mapGetters
  } from "vuex";
  import {
    NODE_TYPES
  } from './nodeDict.js'
  import nodeCommonTree from "./nodeCommonTree";

  export default {
    components: {
      Template,
      nodeCommonTree
    },
    mixins: [organization],
    data() {
      return {
        APITYPE: 'user',
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        title: '',
        isEdit: false,
        searchData: [],
        pageType: '',
        treeData: [],
        defaultProps: {
          children: "children",
          value: 'id',
          label: "name",
        },
        defaultExpandedKeys: [],
        selectTree: {},
        parentNode: {},
        first: true
      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),

    },

    methods: {
      init(data) {
        console.log('===',data)
        this.form = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.parentNode = data
        this.typeTree()
        this.title = '选择节点'
      },
      typeTree() {

        this.selectTree = {}
        this.defaultExpandedKeys = []
        getTree({
          type: "node"
        }).then((res) => {
          this.treeData = res.data.data;
          if (res.data.data.length != 0) {
            if (!this.selectTree.id) return;
            this.defaultExpandedKeys = [this.parentNode.parentId];
            this.$nextTick(() => {
              this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                this.parentNode.parentId
              );
              // this.$refs.commonTreeCore.nodeData = this.parentNode;
              // this.TreeNodeClick(this.parentNode);
            });
          }
        });
      },
      TreeNodeClick(data) {
        this.selectTree = data
      },
      handleTreeNodeSave() {
        let queryData = {
          id: this.parentNode.id,
          targetId: this.selectTree.id,
          type: "server"
        }
        move(queryData).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
          } else {
            this.$message.success(res.data.msg);
          }
          this.$emit('callback')
          this.showDialog = false
        })
      },
      closeDialog() {
        this.showDialog = false
      },
    }
  }

</script>
<style lang="scss" scoped>
  .el-autocomplete {
    width: 100%
  }

  ::v-deep .common_tree_handle {
    width: 100%;
    border-right: none
  }

</style>
