<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="true"
        :showCheckbox="false"
        :popover-show="false"
        :expandOnClickNode="false"
        treeTitle="培训类型"
        @include-down="includeDown"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <head-layout
          :head-btn-options="headBtnOptions"
          head-title="继续教育课程设置"
          @head-remove="headRemove"
          @head-add-tabs="headAdd"
          @head-startUsing="headStartUsing"
          @head-loseEfficacy="headLoseEfficacy"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          @row-remove="rowRemove"
          @row-edit="rowEdit"
        >
          <template #customBtn="{row}">
            <el-button
              type="text"
              size="small"
              v-if="row.status != 2&&row.status != 1"
              @click="rowEdit(row)">
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              v-if="row.status == 1"
              @click="loseEfficacy(row)">
              失效
            </el-button>
            <el-button
              type="text"
              size="small"
              v-if="row.status == 0"
              @click="startUsing(row)">
              发布
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="remove(row)">
              删除
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>

    <el-dialog
      v-dialog-drag
      title="新增内容场景"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showTreeDialog"
      v-if="showTreeDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <avue-form
        :option="treeDialogOption"
        ref="addForm"
        v-model="form"
        @submit="treeNodeSave"
      ></avue-form>
      <span slot="footer">
        <el-button size="small" @click="showTreeDialog = false">{{
            $t(`cip.cmn.btn.celBtn`)
          }}</el-button>
        <el-button size="small" type="primary" @click="handleTreeNodeSave">{{
            $t(`cip.cmn.btn.defBtn`)
          }}</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  remove,
  saveList,
  getSafetyTrainingTree,
  removeSafetyTrainingTree,
  submitSafetyTrainingTree
} from "@/api/knowledgeClassifcation/knowledge";
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import ServerNameEnum from "@/util/ServerNameEnum";
import {enableInvalidation, getTrainGroupScenePage} from "@/api/onTheJobTraining/training";
import {
  getTrainingEducationSetHeadePage,
  getTrainingRducationSettingTreeTree,
  removeTrainingRducationSetting,
  submitTrainingRducationSetting, trainingEducationSetHeadEnableInvalidation, trainingEducationSetHeadRemoveLine
} from "@/api/training/reEducationSetting";
import {saveOrUpdate as saveEducation} from "@/api/training/trainingEducation";

export default {
  data() {
    return {
      form: {},
      query: {},
      treeReadOnlyFlag: false,
      tableData: [],
      treeLoading: false,
      crudLoading: false,
      treeData: [],
      dicData: [],
      selectionList: [],
      node: {}, //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      classifyId: '',
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "value",
        key: "key",
      },
      showDialog: false,
      formType: '',
      formList: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        // {
        //   label: "发布",
        //   emit: "head-startUsing",
        //   type: "button",
        //   icon: "",
        //   btnOptType: "loseEfficacy",
        // },
        {
          label: "失效",
          emit: "head-loseEfficacy",
          type: "button",
          icon: "",
          btnOptType: "loseEfficacy",
        },
        // {
        //   label: "删除",
        //   emit: "head-remove",
        //   type: "button",
        //   icon: "",
        //   btnOptType: "remove",
        // },
      ];
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 150,
        selection: true,
        linklabel: "courseName",
        column: [

          {
            label: "课程",
            prop: 'courseCover',
            type: 'upload',
            width: 140,
          },
          {
            label: "名称",
            prop: "courseName",
            align: "center",
            width: 240,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=site_training_educationSetting",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "创建人",
            prop: "userName",
            align: "center",
            width: 150,
            overHidden: true,
          },
          {
            label: "创建单位",
            prop: "deptName",
            align: "center",
            width: 280,
            overHidden: true,
          }, {
            label: "达标课时",
            prop: "meetLesson",
            align: "center",
            width: 80,
            overHidden: true,
          }, {
            label: "合计课时",
            prop: "totalHours",
            align: "center",
            width: 80,
            overHidden: true,
          },
          {
            label: '更新时间',
            prop: 'updateTime',
            align: "center",
            width: 180,
            overHidden: true
          },
        ],
      };
    },
    gridHeadBtn() {
      return [];
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "courseName",
          span: 4,
          placeholder: "请输入名称",
        },
        {
          prop: "status",
          align: "center",
          type: "select",
          dataType: "string",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=site_training_educationSetting",
          span: 4,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: "请选择状态",
        },
        {
          label: "更新时间",
          prop: "updateTime",
          type: 'date-format',
          span: 4,
          placeholder: '请选择更新时间'
        },
      ];
    },
    gridRowBtn() {

    },
    treeDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "培训名称",
            prop: "trainingName",
            placeholder: "请输入培训名称",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入培训名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          // {
          //   label: "培训编码",
          //   prop: "trainingCode",
          //   maxlength: 20,
          //   placeholder: "请输入培训编码",
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入培训编码",
          //       trigger: ["blur", "change", "click"],
          //     },
          //   ],
          // },
          {
            label: "上级类型",
            prop: "parentId",
            type: "tree",
            props: {
              label: "title",
              value: "value",
              key: "key",
            },
            placeholder: "上级类型",
            dicUrl: `${ServerNameEnum.SERVER_TRAINING_}/trainingRducationSettingTree/tree`,

          },
          // {
          //   label: "描述",
          //   prop: "remark",
          //   maxlength: 20,
          //   placeholder: "请输入描述",
          // },
        ],
      };
    },
    dialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "cateLineName",
            placeholder: "请输入分类名称",
            disabled: !['edit', 'add'].includes(this.formType),
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "分类编号",
            prop: "cateLineCode",
            placeholder: "请输入分类编号",
            maxlength: 20,
            disabled: !['edit', 'add'].includes(this.formType),
            rules: [
              {
                required: true,
                message: "请输入分类编号",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "描述",
            prop: "remark",
            type: "textarea",
            rows: 3,
            span: 24,
            placeholder: "请输入描述",
            maxlength: 200,
            showWordLimit: true,
            disabled: !['edit', 'add'].includes(this.formType),
            rules: [
              {
                required: false,
                message: "请输入描述",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
        ],
      };
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
  },
  created() {
    this.initTree()
  },
  mounted() {
    this.classifyId = ''
  },
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    // 左侧树新增
    treeMenuAdd(data) {
      this.form = {};
      this.form.parentId = data.parentId;
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },

    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },
    initTree() {
      getTrainingRducationSettingTreeTree()
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
          this.onLoad(this.page, {});
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    // 新增左侧树节点
    treeNodeSave(data, done) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$loading();
          if (this.form.parentId == "") {
            this.form.parentId = 0
          }
          submitTrainingRducationSetting(this.form)
            .then((res) => {
              const {msg, data} = res.data;
              this.treeReadOnlyFlag = true;
              this.$message.success(msg);
              this.initTree();
              this.showTreeDialog = false;
            }).finally(() => {
            this.$loading().close();
          })
            .catch((err) => {
              done();
            });
        }
      });
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren && data.children.length != 0) {
        this.$message({
          type: "warning",
          message: "当前分类下有子级，禁止删除",
        });
        return;
      }
      this.$confirm("确认删除数据", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeTrainingRducationSetting(data.id).then((res) => {
            if (res.data.data) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.initTree();
            } else {
              this.$message.warning("删除失败，当前培训类型下有绑定的再教育！")
            }
          });
        })
        .catch(() => {
        });
    },

    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      this.query.trainContentCode = this.node.id;
      this.classifyId = this.node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    //知识分类新增
    closeDialog() {
      this.formList = {};
      this.$refs.addFormList.resetForm();
      this.showDialog = false
    },
    handleNodeSave() {
      this.$refs.addFormList.submit();
    },
    saveList(data, done) {
      this.$refs.addFormList.validate((valid) => {
        if (valid) {
          this.$loading();
          let params = {
            ...this.formList,
            cateCode: this.node.cateCode,
            cateEn: this.node.cateEn,
            cateName: this.node.cateName,
            cateId: this.node.id,
            knowledgeClassify: this.node.knowledgeClassify
          }
          if (this.formList.id) {
            params.id = this.formList.id
          }
          saveList(params)
            .then((res) => {
              const {msg, data} = res.data;
              this.$message.success(msg);
              this.formList = {};
              this.$refs.addFormList.resetForm();
              this.onLoad(this.page, {});
              this.showDialog = false;
            }).finally(() => {
            this.$loading().close();
          })
            .catch((err) => {
              done();
            });
        }
      });
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("确定将选择数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove(this.ids).then(() => {
            this.onLoad(this.page, {});
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.selectionClear();
          });
        })
        .catch(() => {
        });
    },

    rowRemove(row) {
      this.$confirm("确定删除数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove(row.id).then((res) => {
            if (res.data.data) {
              this.onLoad(this.page, {});
              this.$message({
                type: "success",
                message: "删除成功",
              });
            } else {
              this.$message.warning("删除失败，请先删除子集")
            }
          });
        })
    },
    rowEdit(row) {
      this.$router.push({
        path: '/business/training/reEducationSetting/edit',
        query: {
          row: JSON.stringify(row),
          type: 'edit',
        }
      });
    },
    rowView(row) {
      this.$router.push({
        path: '/business/training/reEducationSetting/view',
        query: {
          row: JSON.stringify(row),
          type: 'view',
        }
      });
    },
    enabledOrDisabled(id, status) {
      trainingEducationSetHeadEnableInvalidation(id, status).then(res => {
        this.onLoad(this.page);
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      }).then(() => {
        if (status == 1) {
          saveEducation(id)
        }
      })
    },
    startUsing(row) {
      this.enabledOrDisabled(row.id, 1)
    },
    remove(row) {
      this.$confirm("确定将选择数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          trainingEducationSetHeadRemoveLine(row.id).then(res => {
            this.onLoad(this.page);
            return this.$message({
              type: "success",
              message: "操作成功！",
            });
          })
        })
    },
    loseEfficacy(row) {
      this.$confirm("确定将选择数据失效", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.enabledOrDisabled(row.id, 2)
        })
    },
    /**
     * 启用
     */
    headStartUsing() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      try {
        this.selectionList.forEach((item) => {
          if (item.status == 2) {
            throw new Error("已经失效的培训不能再启用")
          }
        })
      } catch (e) {
        return this.$message({
          type: "error",
          message: "已经失效的培训不能再启用！",
        });
      }
      var ids = this.selectionList.map(item => item.id);
      this.enabledOrDisabled(ids.join(","), 1)
    },
    /**
     * 失效
     */
    headLoseEfficacy() {
      let dataFlg = []
      this.selectionList.forEach((e) => {
        if (e.status !== 1) {
          dataFlg.push(e)
        }
      })
      if (dataFlg.length > 0) {
        this.$message.warning("选择的数据包含以失效或草稿数据请重新选择！");
      } else {
        this.$confirm("确定将选择数据失效", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.selectionList.length === 0) {
              this.$message.warning("请选择数据");
              return;
            }
            var ids = this.selectionList.map(item => item.id);
            this.enabledOrDisabled(ids.join(","), 2)
          })
      }
    },
    // 新增
    headAdd() {
      if (this.classifyId === '') {
        this.$message.warning("请先选择左侧培训类型")
      } else {
        this.$router.push({
          path: "/business/training/reEducationSetting/add",
          query: {
            type: "add",
            classifyId: this.classifyId,
            trainContentId: this.node.id,
            trainContentName: this.node.trainingName
          },
        });
      }
    },

    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    gridHeadEmpty() {
      this.query = {};
      this.onLoad(this.page);
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.crudLoading = true;
      this.query.type = 1

      this.$refs.gridHeadLayout.searchForm.whenIsCreatedDate = this.$refs.gridHeadLayout.searchForm.updateTime
      delete this.$refs.gridHeadLayout.searchForm.updateTime;

      getTrainingEducationSetHeadePage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
          this.selectionClear();
        })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
  },
};
</script>

