import { render, staticRenderFns } from "./addLabel.vue?vue&type=template&id=566e42e8&scoped=true"
import script from "./addLabel.vue?vue&type=script&lang=js"
export * from "./addLabel.vue?vue&type=script&lang=js"
import style0 from "./addLabel.vue?vue&type=style&index=0&id=566e42e8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "566e42e8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/hse-web-227/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('566e42e8')) {
      api.createRecord('566e42e8', component.options)
    } else {
      api.reload('566e42e8', component.options)
    }
    module.hot.accept("./addLabel.vue?vue&type=template&id=566e42e8&scoped=true", function () {
      api.rerender('566e42e8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/safe/itemList/labelManagement/addLabel.vue"
export default component.exports