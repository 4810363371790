var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v("训练模型")]),
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            { attrs: { lable: "模型名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入模型名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { lable: "算法名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入模型名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { lable: "模型路径" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入模型名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { lable: "数据来源" } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini" },
                  on: { change: _vm.sourceChange },
                  model: {
                    value: _vm.form.sourceId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sourceId", $$v)
                    },
                    expression: "form.sourceId",
                  },
                },
                _vm._l(_vm.sourceList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.desc, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { lable: "数据表" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入模型名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("p", [_vm._v("请选择y特征")]),
              _c("grid-layout", {
                ref: "gridLayout",
                attrs: {
                  tableData: _vm.tableData,
                  tableLoading: _vm.tableLoading,
                  tableOptions: _vm.tableOptions,
                },
              }),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("p", [_vm._v("请选择x特征")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }