var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-container", staticStyle: { position: "relative" } },
    [
      _c("CommonTreeNew", {
        ref: "commonTree",
        attrs: {
          treeTitle: "项目架构",
          defaultProps: _vm.Props,
          showCheckbox: false,
          urlParmas: {
            tenantId: "",
            deptCategory: "",
            parentId: _vm.userInfo.deptId,
          },
          "node-key": "id",
        },
        on: { getNodeClick: _vm.handleNode, getTreeData: _vm.commonTreeData },
      }),
      _c(
        "div",
        {
          staticClass: "flex-one",
          staticStyle: { "background-color": "#fff" },
        },
        [
          _c("head-layout", {
            attrs: {
              "head-title": "应急物资",
              showIcon: false,
              "head-btn-options": _vm.headBtnOptions,
            },
            on: {
              "head-add": _vm.handleAdd,
              "head-import": _vm.handleImport,
              "head-export": _vm.handleExport,
              "head-delete": _vm.handleDelete,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.tableOption,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.page.total,
              page: _vm.page,
            },
            on: {
              "grid-row-detail-click": _vm.rowView,
              "gird-handle-select-click": _vm.selectionChange,
              "page-current-change": _vm.currentChange,
              "page-size-change": _vm.sizeChange,
            },
            scopedSlots: _vm._u([
              {
                key: "files",
                fn: function ({ row }) {
                  return [
                    _c("img", {
                      staticClass: "courseCoverImg",
                      attrs: { src: row.imgUrl },
                    }),
                  ]
                },
              },
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    row.createUser == _vm.userInfo.user_id
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.rowEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑\n        ")]
                        )
                      : _vm._e(),
                    row.createUser == _vm.userInfo.user_id
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.rowDel(row)
                              },
                            },
                          },
                          [_vm._v("删除\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("add", { ref: "add", on: { callback: _vm.callback } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "应急物资导入",
            "append-to-body": "",
            visible: _vm.excelBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.excelBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "import" },
            [
              _c(
                "avue-form",
                {
                  attrs: {
                    option: _vm.excelOption,
                    "upload-after": _vm.uploadAfter,
                  },
                  model: {
                    value: _vm.excelOption.excelForm,
                    callback: function ($$v) {
                      _vm.$set(_vm.excelOption, "excelForm", $$v)
                    },
                    expression: "excelOption.excelForm",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "excelTemplate" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleTemplate },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("cip.plat.sys.user.field.download"))
                          ),
                          _c("i", {
                            staticClass: "el-icon-download el-icon--right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }