var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { attrs: { id: "suoluetu" } }),
    _c("div", { attrs: { id: "myactive" } }, [
      _vm._v("\n    主摄像头视频模式："),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.view1_mode_selected,
              expression: "view1_mode_selected",
            },
          ],
          attrs: { id: "view1_mode" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.view1_mode_selected = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function ($event) {
                return _vm.view1_mode_change(_vm.view1_mode_selected)
              },
            ],
          },
        },
        _vm._l(_vm.view1_mode_list, function (item) {
          return _c(
            "option",
            { key: item.key, domProps: { value: item.key } },
            [_vm._v(_vm._s(item.value))]
          )
        }),
        0
      ),
      _vm._v("\n    主摄像头分辨率："),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.view1_resolution_selected,
              expression: "view1_resolution_selected",
            },
          ],
          attrs: { id: "view1_resolution_power" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.view1_resolution_selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.view1_resolution_list, function (item, index) {
          return _c("option", { key: index, domProps: { value: item } }, [
            _vm._v(_vm._s(item)),
          ])
        }),
        0
      ),
      _vm._v("\n    副摄像头视频模式："),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.view2_mode_selected,
              expression: "view2_mode_selected",
            },
          ],
          attrs: { id: "view2_mode" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.view2_mode_selected = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function ($event) {
                return _vm.view2_mode_change(_vm.view2_mode_selected)
              },
            ],
          },
        },
        _vm._l(_vm.view2_mode_list, function (item) {
          return _c(
            "option",
            { key: item.key, domProps: { value: item.key } },
            [_vm._v(_vm._s(item.value))]
          )
        }),
        0
      ),
      _vm._v("\n    副摄像头分辨率："),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.view2_resolution_selected,
              expression: "view2_resolution_selected",
            },
          ],
          attrs: { id: "view2_resolution_power" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.view2_resolution_selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.view2_resolution_list, function (item, index) {
          return _c("option", { key: index, domProps: { value: item } }, [
            _vm._v(_vm._s(item)),
          ])
        }),
        0
      ),
      _c("br"),
      _c("button", { on: { click: _vm.open_view1 } }, [
        _vm._v("打开主摄像头视频"),
      ]),
      _c("button", { on: { click: _vm.close_view1 } }, [
        _vm._v("关闭主摄像头视频"),
      ]),
      _c("button", { on: { click: _vm.open_view2 } }, [
        _vm._v("打开副摄像头视频"),
      ]),
      _c("button", { on: { click: _vm.close_view2 } }, [
        _vm._v("关闭副摄像头视频"),
      ]),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.rotate(90)
            },
          },
        },
        [_vm._v("左转")]
      ),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.rotate(270)
            },
          },
        },
        [_vm._v("右转")]
      ),
      _c("br"),
      _c("button", { on: { click: _vm.view1_scan } }, [_vm._v("主摄像头拍照")]),
      _c("button", { on: { click: _vm.view2_scan } }, [_vm._v("副摄像头拍照")]),
      _c("button", { on: { click: _vm.flat_scan } }, [_vm._v("展平拍照")]),
      _c("button", { on: { click: _vm.open_auto_scan } }, [
        _vm._v("开启自动拍照"),
      ]),
      _c("button", { on: { click: _vm.close_auto_scan } }, [
        _vm._v("关闭自动拍照"),
      ]),
      _c("br"),
      _c("button", { on: { click: _vm.getcode } }, [_vm._v("条码识别")]),
      _c("button", { on: { click: _vm.getbiokey } }, [_vm._v("获取指纹")]),
      _c("button", { on: { click: _vm.getidcard2 } }, [_vm._v("读取身份证")]),
      _c("button", { on: { click: _vm.sign_a } }, [
        _vm._v("弹出签字窗口：GW500A & GW1000A"),
      ]),
      _c("button", { on: { click: _vm.sign_a_get } }, [
        _vm._v("获取签名图片：GW500A & GW1000A"),
      ]),
      _c("br"),
      _c("button", { on: { click: _vm.start_video } }, [
        _vm._v("开始录制视频"),
      ]),
      _c("button", { on: { click: _vm.end_video } }, [_vm._v("结束录制视频")]),
      _c("button", { on: { click: _vm.get_state_video } }, [
        _vm._v("获取视频录制状态"),
      ]),
      _c("button", { on: { click: _vm.get_audio } }, [
        _vm._v("获取音频设备列表"),
      ]),
      _c("br"),
      _c("button", { on: { click: _vm.open_a3a4 } }, [
        _vm._v("开启A3A4幅面自动切换：S1840"),
      ]),
      _c("button", { on: { click: _vm.close_a3a4 } }, [
        _vm._v("关闭A3A4幅面自动切换：S1840"),
      ]),
      _c("br"),
      _c("button", { on: { click: _vm.add_pdf } }, [
        _vm._v("拍照并加入PDF队列"),
      ]),
      _c("button", { on: { click: _vm.save_pdf } }, [_vm._v("生成PDF文件")]),
      _c("br"),
      _c("button", { on: { click: _vm.is_living_person } }, [
        _vm._v("活体检测"),
      ]),
      _c("button", { on: { click: _vm.person_and_IDCard } }, [
        _vm._v("人证比对"),
      ]),
      _c("br"),
      _c("button", { on: { click: _vm.ocr } }, [_vm._v("OCR")]),
      _c("br"),
      _c("button", { on: { click: _vm.getequipmenttype } }, [
        _vm._v("获取设备型号"),
      ]),
      _c("button", { on: { click: _vm.getsonixserialnumber } }, [
        _vm._v("获取设备序列号"),
      ]),
      _c("button", { on: { click: _vm.get_state } }, [_vm._v("获取设备状态")]),
      _c("button", { on: { click: _vm.is_con } }, [_vm._v("判断设备是否连接")]),
      _c("br"),
      _c("button", { on: { click: _vm.open_serialport } }, [
        _vm._v("打开串口"),
      ]),
      _c("button", { on: { click: _vm.close_serialport } }, [
        _vm._v("关闭串口"),
      ]),
      _c("button", { on: { click: _vm.sign_r } }, [
        _vm._v("签字窗口弹出：GW500R & GW1000R"),
      ]),
      _c("button", { on: { click: _vm.sign_r_get } }, [
        _vm._v("获取签名图片: GW500R & GW1000R"),
      ]),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "view" } }, [
      _c("img", {
        attrs: {
          id: "view1",
          src: "http://127.0.0.1:38088/video=stream&camidx=0",
          alt: "主摄像头",
        },
      }),
      _c("img", {
        attrs: {
          id: "view2",
          src: "http://127.0.0.1:38088/video=stream&camidx=1",
          alt: "副摄像头",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "mylog" } }, [
      _vm._v("首次打开页面，没有显示出分辨率信息？不要急，刷新下页面就可以了"),
      _c("br"),
      _vm._v("我是操做日志，如果我出现问题，请看控制台信息......"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }