var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogVisible, title: "区域选择" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("grid-layout", {
        key: "areaGriLayOut",
        ref: "areaGriLayOut",
        attrs: {
          "grid-row-btn": _vm.areaBtn,
          "table-options": _vm.AreaOPtions,
          "table-data": _vm.tableData,
          "table-loading": false,
        },
        on: {
          "area-change": _vm.araeChange,
          "gird-handle-select-click": _vm.selectionChange,
        },
      }),
      _vm.multiple
        ? _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "center" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "24px" },
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.confirm },
                },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                { attrs: { size: "medium" }, on: { click: _vm.closeDialog } },
                [_vm._v("取消")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }