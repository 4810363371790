<template>
  <div>
    <head-layout
      head-title="会议记录"
      :head-btn-options="headBtnOptions"
      @meeting-record="mettingRecord"
    ></head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
    >
      <template #meetingName="{ row, index }">
        <el-link type="primary" @click="rowView(row)">{{
          row.meetingName
        }}</el-link>
      </template>
    </grid-layout>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import { mapGetters } from "vuex";
import { mettingHeadPage } from "@/api/responsibility/securityMetting";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableOption: {
        selection: false,
        menu: false,
        column: [
          {
            label: "会议标题",
            prop: "meetingName",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "会议时间",
            prop: "meetingDate",
            align: "center",
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: "会议记录",
          emit: "meeting-record",
          type: "button",
          icon: "",
          btnOptType: "meetingRecord",
        },
      ],
      searchColumns: [
        {
          label: "会议标题",
          prop: "meetingName",
          span: 6,
          placeholder: "请输入会议标题",
        },
        {
          label: "会议时间",
          prop: "time",
          type: "daterange",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          span: 12,
          startPlaceholder: "开始时间",
          endPlaceholder: "结束时间",
        },
      ],
      soOrgId: "",
    };
  },
  computed: {
    ...mapGetters(["permission", "userInfo"]),
  },

  methods: {
    initDate(soOrgId) {
      this.soOrgId = soOrgId;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    mettingRecord() {
      this.$router.push({
        path: "/business/responsibility/securityMetting/add",
        query: {
          row: JSON.stringify({ belongOrgId: this.userInfo.deptId }),
          type: "add",
        },
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      if (
        this.$refs.gridHeadLayout.searchForm.time &&
        this.$refs.gridHeadLayout.searchForm.time.length != 0
      ) {
        this.$refs.gridHeadLayout.searchForm.meetingDateStart =
          this.$refs.gridHeadLayout.searchForm.time[0];
        this.$refs.gridHeadLayout.searchForm.meetingDateEnd =
          this.$refs.gridHeadLayout.searchForm.time[1];
      } else {
        delete this.$refs.gridHeadLayout.searchForm.meetingDateStart;
        delete this.$refs.gridHeadLayout.searchForm.meetingDateEnd;
      }
      // if(searchForm.time){
      //   searchForm.meetingDateStart=searchForm.time[0]
      //   searchForm.meetingDateEnd=searchForm.time[1]
      // }else {
      //   searchForm.meetingDateStart=''
      //   searchForm.meetingDateEnd=''
      // }
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },

    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;

      params.soOrgId = this.soOrgId;
      mettingHeadPage(page.currentPage, page.pageSize, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    rowView(row) {
      this.$router.push({
        path: "/business/responsibility/securityMetting/view",
        query: {
          row: JSON.stringify({ id: row.id }),
          type: "view",
        },
      });
    },
  },
};
</script>
