var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-box riskIdentification" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.MD_SECTION, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: item.dictValue, name: item.dictKey },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "flex-container" },
        [
          _c("CommonTreeNew", {
            ref: "commonTreeCore",
            staticClass: "introduceTree",
            attrs: {
              treeTitle: "风险源配置",
              "node-key": "id",
              isLeafIcons: "",
              isShowdig: "",
              risk: "",
              defaultExpandedKeys: _vm.defaultExpandedKeys,
              defaultProps: _vm.defaultProps,
              treeData: _vm.corePersonnelTreeData,
              showCheckbox: false,
            },
            on: {
              getTreeAdd: _vm.getTreeAdd,
              getTreeDelete: _vm.getTreeDelete,
              getTreeEdit: _vm.getTreeEdit,
              getNodeClick: _vm.corePersonnelTreeNodeClick,
              getTreeData: _vm.commonTreeData,
            },
          }),
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.corePersonnelHeadBtnOptions,
                  "head-title": "风险清单",
                },
                on: { "head-del": _vm.headDel, "head-add": _vm.addjcx },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                staticClass: "introduceTable",
                attrs: {
                  "table-options": _vm.corePersonnelTableOption,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.TableLoading,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.addjcx(row, "edit")
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.rowDel([row])
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.jcxflModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "评估单元及风险点",
                visible: _vm.jcxflModel,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.jcxflModel = $event
                },
              },
            },
            [
              _c("avue-form", {
                ref: "avueForm",
                attrs: { option: _vm.jcxflOption },
                on: {
                  "reset-change": _vm.resetChange,
                  submit: _vm.handleSubmit,
                },
                model: {
                  value: _vm.jcxflForm,
                  callback: function ($$v) {
                    _vm.jcxflForm = $$v
                  },
                  expression: "jcxflForm",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "avue-dialog__footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.jcxflModel = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.avueForm.submit()
                        },
                      },
                    },
                    [_vm._v("\n        保存\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }