var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
    _c("div", { staticClass: "JNPF-preview-main order-form" }, [
      _c(
        "div",
        { staticClass: "JNPF-common-page-header" },
        [
          _c("el-page-header", {
            attrs: { content: "订单详细" },
            on: { back: _vm.goBack },
          }),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-printer" },
                      on: {
                        click: function ($event) {
                          return _vm.print()
                        },
                      },
                    },
                    [_vm._v("打印")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-download" },
                      on: { click: _vm.exportExcel },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-arrow-left" },
                      on: { click: _vm.prev },
                    },
                    [_vm._v("前单")]
                  ),
                  _c("el-button", { on: { click: _vm.next } }, [
                    _vm._v("\n            后单"),
                    _c("i", {
                      staticClass: "el-icon-arrow-right el-icon--right",
                    }),
                  ]),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("common.cancelButton")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          _c(
            "div",
            { ref: "print1", staticClass: "form" },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.dataForm,
                    rules: _vm.dataRule,
                    "label-width": "90px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "客户名称" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.customerName)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "业务人员" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.salesmanName)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "订单日期" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.orderDate)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "订单编码" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.orderCode)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "付款方式" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.paymentMode)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "付款金额" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.receivableMoney)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "定金比率" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.earnestRate)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "预付定金" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.prepayEarnest)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "运输方式" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.transportMode)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "发货日期" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.deliveryDate)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "发货地址" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.deliveryAddress)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "相关附件", prop: "fileJson" } },
                            [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "el-upload-list el-upload-list--text",
                                },
                                _vm._l(_vm.fileList, function (item, i) {
                                  return _c(
                                    "li",
                                    {
                                      key: i,
                                      staticClass: "el-upload-list__item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.download(item.fileId)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "el-upload-list__item-name",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-document",
                                          }),
                                          _vm._v(
                                            _vm._s(item.fileName) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-form-item", { attrs: { label: "订单备注" } }, [
                            _c("p", [_vm._v(_vm._s(_vm.dataForm.description))]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "制单人员" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.creatorUserId)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "制单时间" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toDate")(_vm.dataForm.creatorTime)
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "修改人员" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.dataForm.lastModifyUserId)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-form-item", { attrs: { label: "修改时间" } }, [
                            _c("p", { staticClass: "text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toDate")(_vm.dataForm.lastModifyTime)
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "订单商品", name: "goods" } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.list,
                            size: "small",
                            "show-summary": "",
                            "summary-method": _vm.getSummaries,
                            id: "myTable1",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              width: "50",
                              label: "序号",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsName",
                              label: "商品名称",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "specifications",
                              label: "规格型号",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "unit", label: "单位", width: "80" },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "qty", label: "数量", width: "80" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: "单价",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "amount",
                              label: "金额",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "discount",
                              label: "折扣%",
                              width: "90",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "cess",
                              label: "税率%",
                              width: "90",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "actualPrice",
                              label: "实际单价",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "actualAmount",
                              label: "实际金额",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "description",
                              label: "备注",
                              width: "80",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "收款计划", name: "plan" } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.planList,
                            size: "small",
                            "show-summary": "",
                            "summary-method": _vm.getSummaries,
                            id: "myTable2",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              width: "50",
                              label: "序号",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "receivableDate",
                              label: "收款日期",
                              formatter: _vm.jnpf.tableDateFormat,
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "receivableRate",
                              label: "收款比率%",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "receivableMoney",
                              label: "收款金额",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "receivableMode",
                              label: "收款方式",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "abstract", label: "收款摘要" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }