var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("avue-back-top", { attrs: { id: "avue-view" } }),
      _c(
        "avue-back-top",
        { attrs: { id: "avue-view", height: 100, bottom: 200 } },
        [_c("div", { staticClass: "top" }, [_vm._v("返回顶端")])]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { height: "2000px", "background-color": "#fff" } },
      [
        _c(
          "div",
          { staticStyle: { padding: "15px 20px", "font-size": "18px" } },
          [_vm._v("往下拉就会出现返回菜单")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }