var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "disFlex" }, [
      _c("div", { staticClass: "card-pd-2" }, [
        _vm._m(0),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { staticClass: "card-pd-2-BotLitte" }, [
            _c("img", {
              staticClass: "groupImg",
              attrs: { src: require("@/assets/images/board/group.png") },
            }),
            _c("div", { staticClass: "priceNumber" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value1) || 0)
              ),
              _c("span", [_vm._v("人")]),
            ]),
            _c("div", { staticClass: "lineBox" }),
            _c("div", { staticClass: "priceContent" }, [
              _vm._v("\n            安全责任书签订数\n          "),
            ]),
          ]),
          _c("div", { staticClass: "card-pd-2-BotLitte" }, [
            _c("img", {
              staticClass: "groupImg",
              attrs: { src: require("@/assets/images/board/group.png") },
            }),
            _c("div", { staticClass: "priceNumber" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value2) || 0)
              ),
              _c("span", [_vm._v("人")]),
            ]),
            _c("div", { staticClass: "lineBox" }),
            _c("div", { staticClass: "priceContent" }, [
              _vm._v("\n            环保责任书签订数\n          "),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-2" }, [
        _vm._m(1),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { staticClass: "card-pd-2-BotLitte" }, [
            _c("img", {
              staticClass: "groupImg",
              attrs: { src: require("@/assets/images/board/people.png") },
            }),
            _c("div", { staticClass: "priceNumber" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value3) || 0)
              ),
              _c("span", [_vm._v("人")]),
            ]),
            _c("div", { staticClass: "lineBox" }),
            _c("div", { staticClass: "priceContent" }, [
              _vm._v("\n            注册安全工程师证书\n          "),
            ]),
          ]),
          _c("div", { staticClass: "card-pd-2-BotLitte" }, [
            _c("img", {
              staticClass: "groupImg",
              attrs: { src: require("@/assets/images/board/people.png") },
            }),
            _c("div", { staticClass: "priceNumber" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value4) || 0)
              ),
              _c("span", [_vm._v("人")]),
            ]),
            _c("div", { staticClass: "lineBox" }),
            _c("div", { staticClass: "priceContent" }, [
              _vm._v("\n            注册环保工程师证书\n          "),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "disFlex" }, [
      _c("div", { staticStyle: { width: "calc(50% - 8px)" } }, [
        _vm._m(2),
        _c(
          "div",
          {
            staticClass: "card-pd-2",
            staticStyle: { width: "calc(100% - 52px)", "margin-top": "16px" },
          },
          [
            _vm._m(3),
            _c("div", { staticClass: "card-pd-2-Bottom" }, [
              _c("div", { staticClass: "card-pd-2-BotLitte" }, [
                _c("img", {
                  staticClass: "groupImg",
                  attrs: { src: require("@/assets/images/board/drill.png") },
                }),
                _c("div", { staticClass: "priceNumber" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value5) || 0)
                  ),
                  _c("span", [_vm._v("次")]),
                ]),
                _c("div", { staticClass: "lineBox" }),
                _c("div", { staticClass: "priceContent" }, [
                  _vm._v("\n              境外项目、机构\n            "),
                ]),
              ]),
              _c("div", { staticClass: "card-pd-2-BotLitte" }, [
                _c("img", {
                  staticClass: "groupImg",
                  attrs: { src: require("@/assets/images/board/drill.png") },
                }),
                _c("div", { staticClass: "priceNumber" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value6) || 0)
                  ),
                  _c("span", [_vm._v("次")]),
                ]),
                _c("div", { staticClass: "lineBox" }),
                _c("div", { staticClass: "priceContent" }, [
                  _vm._v("\n              境内项目、机构\n            "),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "card-pd-2",
            staticStyle: { width: "calc(100% - 52px)", "margin-top": "16px" },
          },
          [
            _vm._m(4),
            _c("div", { staticClass: "card-pd-2-Bottom" }, [
              _c("div", { staticClass: "card-pd-2-BotLitte" }, [
                _c("img", {
                  staticClass: "groupImg",
                  attrs: { src: require("@/assets/images/board/activity.png") },
                }),
                _c("div", { staticClass: "priceNumber" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value7) || 0)
                  ),
                  _c("span", [_vm._v("人")]),
                ]),
                _c("div", { staticClass: "lineBox" }),
                _c("div", { staticClass: "priceContent" }, [
                  _vm._v("\n              个人\n            "),
                ]),
              ]),
              _c("div", { staticClass: "card-pd-2-BotLitte" }, [
                _c("img", {
                  staticClass: "groupImg",
                  attrs: { src: require("@/assets/images/board/activity.png") },
                }),
                _c("div", { staticClass: "priceNumber" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value8) || 0)
                  ),
                  _c("span", [_vm._v("人")]),
                ]),
                _c("div", { staticClass: "lineBox" }),
                _c("div", { staticClass: "priceContent" }, [
                  _vm._v("\n              班组\n            "),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._m(5),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v("\n          安全/环保责任签订数\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v("\n          注册安全/环保工程师证书\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-pd-2", staticStyle: { width: "calc(100% - 52px)" } },
      [
        _c("div", { staticClass: "card-pd-2-top" }, [
          _c("span", { staticClass: "card-pd-2-top-Title" }, [
            _vm._v("\n          持证率统计\n        "),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { staticClass: "bottomChartBox" }, [
            _c("div", { staticClass: "bottomChartTitle" }, [
              _vm._v("特种作业人员持证率"),
            ]),
            _c("div", { attrs: { id: "chart-fourteen" } }),
          ]),
          _c("div", { staticClass: "bottomChartBox" }, [
            _c("div", { staticClass: "bottomChartTitle" }, [
              _vm._v("三类人员持证率"),
            ]),
            _c("div", { attrs: { id: "chart-fifteen" } }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v("\n          应急演练次数\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v("\n          当前年度“零违章”活动统计\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { width: "calc(50% - 8px)" } }, [
      _c(
        "div",
        {
          staticClass: "card-pd-2",
          staticStyle: { width: "calc(100% - 52px)" },
        },
        [
          _c("div", { staticClass: "card-pd-2-top" }, [
            _c("span", { staticClass: "card-pd-2-top-Title" }, [
              _vm._v(
                "\n          下属组织专职安全、环保管理持证情况统计(百分比)\n        "
              ),
            ]),
          ]),
          _c("div", { staticClass: "card-pd-2-Bottom" }, [
            _c("div", { attrs: { id: "chart-sixteen" } }),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card-pd-2",
          staticStyle: { width: "calc(100% - 52px)", "margin-top": "16px" },
        },
        [
          _c("div", { staticClass: "card-pd-2-top" }, [
            _c("span", { staticClass: "card-pd-2-top-Title" }, [
              _vm._v("\n          每月“零违章”活动统计\n        "),
            ]),
          ]),
          _c("div", { staticClass: "card-pd-2-Bottom" }, [
            _c("div", { attrs: { id: "chart-seventeen" } }),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }