<template>
  <el-container class="main-box">
    <!-- <CommonTree
      class="introduceTree weitiao"
      treeTitle="设备设施类型清单"
      node-key="id"
      isLeafIcons
      isShowdig
      risk
      :defaultExpandedKeys="[]"
      ref="commonTreeCore"
      @getTreeAdd="getTreeAdd"
      @getTreeDelete="rowDel([$event],0)"
      @getTreeEdit="getTreeEdit"
      :treeData="tableData"
      :defaultProps="defaultProps"
      @getNodeClick="gridRowClick"
      :showCheckbox="false"
    /> -->
    <CommonTreeNew
      class="introduceTree weitiao"
      treeTitle="设备设施类型清单"
      node-key="id"
      isLeafIcons
      isShowdig
      risk
      :defaultExpandedKeys="[]"
      ref="commonTreeCore"
      @getTreeAdd="getTreeAdd"
      @getTreeDelete="rowDel([$event], 0)"
      @getTreeEdit="getTreeEdit"
      :defaultProps="defaultProps"
      @getNodeClick="gridRowClick"
      :showCheckbox="false"
      @getTreeData="commonTreeData"
      url="/api/sinoma-hse-prj/steqtype/typeTree"
      :urlParmas="{}"
      :handleData="convertData"
    ></CommonTreeNew>
    <div class="table-box">
      <head-layout
        :head-btn-options="corePersonnelHeadBtnOptions"
        @head-export="headExport"
        @head-del="headDel"
        @handleImport="handleImport"
        @head-add="addjcx"
        head-title="安全检查项"
      ></head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="corePersonnelTableOption"
        :page="page"
        :data-total="page.total"
        @page-current-change="onload1"
        @page-size-change="onload1"
        :table-data="tableData1"
        :table-loading="TableLoading1"
        @gird-handle-select-click="selectionChange"
      >
        <template #customBtn="{ row }">
          <el-button type="text" size="small" @click="addjcx(row)"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="rowDel([row], 1)"
            >删除</el-button
          >
        </template>
      </grid-layout>
    </div>
    <el-dialog
      title="设备设施类型清单"
      v-if="jcxflModel"
      v-dialogDrag
      :visible.sync="jcxflModel"
      width="30%"
    >
      <avue-form
        v-loading="jcxflLoadin1"
        ref="avueForm"
        :option="jcxflOption"
        @reset-change="resetChange"
        @submit="handleSubmit"
        v-model="jcxflForm"
      >
      </avue-form>
      <div class="avue-dialog__footer" slot="footer">
        <el-button
          size="small"
          v-loading="jcxflLoadin1"
          @click="jcxflModel = false"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          v-loading="jcxflLoadin1"
          type="primary"
          @click="$refs.avueForm.submit()"
        >
          保存
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="安全检查项"
      v-if="jcxflModel1"
      v-dialogDrag
      :visible.sync="jcxflModel1"
      width="50%"
    >
      <avue-form
        v-loading="jcxflloading"
        ref="avueForm1"
        :option="jcxflOption1"
        @reset-change="resetChange1"
        @submit="handleSubmit1"
        v-model="jcxflForm1"
      >
      </avue-form>
      <div class="avue-dialog__footer">
        <el-button
          v-loading="jcxflloading"
          size="small"
          @click="jcxflModel1 = false"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          v-loading="jcxflloading"
          size="small"
          type="primary"
          @click="$refs.avueForm1.submit()"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index.vue";
import Template from "@/views/message/template/list.vue";
import * as API from "@/api/safetyTtandard/equipment";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  name: "index",
  components: { Template, GridLayout, CommonTree },
  computed: {
    corePersonnelTableOption() {
      let column = [
        {
          label: "检查内容",
          prop: "content",
          align: "left",
          overHidden: true,
        },
      ];
      return {
        index: false,
        menuWidth: 100,
        column: column,
      };
    },
    tableOption() {
      let column = [
        {
          label: "设备设施类型名称",
          prop: "typeName",
          align: "left",
          overHidden: true,
        },
      ];
      return {
        index: false,
        highlightCurrentRow: true,
        selection: false,
        menuWidth: 100,
        column: column,
      };
    },
    jcxflOption() {
      return {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            disabled: this.jcxflForm.id ? true : false,
            display: this.jcxflForm.parentId != -1,
            span: 24,
            labelWidth: 200,
            label: "类型",
            prop: "eqType",
            type: "radio",
            dicData: [
              {
                label: "分类",
                value: 1,
              },
              {
                label: "子节点",
                value: 2,
              },
            ],
          },
          {
            span: 24,
            props: this.defaultProps,
            dicData: this.treeData,
            type: "tree",
            labelWidth: 200,
            clearable: true,
            label: "上级岗位分类",
            prop: "parentId",
          },
          {
            span: 24,
            maxlength: 20,
            labelWidth: 200,
            showWordLimit: true,
            label: "设备设施类型名称",
            prop: "typeName",
            rules: [
              {
                required: true,
                message: "请输入设备设施类型名称",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    jcxflOption1() {
      return {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            span: 24,
            labelWidth: 200,
            label: "检查内容",
            maxlength: 500,
            showWordLimit: true,
            type: "textarea",
            rows: 3,
            prop: "content",
            rules: [
              {
                required: true,
                message: "请输入检查内容",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
  },
  data() {
    return {
      jcxflloading: false,
      jcxflLoadin1: false,
      defaultProps: {
        children: "children",
        value: "id",
        label: "typeName",
      },
      selectTree: undefined,
      jcxflForm: {},
      jcxflForm1: {},
      jcxflModel: false,
      jcxflModel1: false,
      TableLoading: false,
      TableLoading1: false,
      tableData: [],
      tableData1: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },

      corePersonnelHeadBtnOptions: [
        { label: "新增", emit: "head-add", type: "button", icon: "" },
        // {label: "导入", emit: "handleImport", type: "button", icon: ""},
        // {label: "导出", emit: "head-export", type: "button", icon: ""},
        { label: "删除", emit: "head-del", type: "button", icon: "" },
      ],
      options: [{ label: "新增", emit: "head-add", type: "button", icon: "" }],
    };
  },
  mounted() {
    this.onload();
  },
  methods: {
    commonTreeData(treeData) {
      this.tableData = treeData;
      // this.tableLoading = false;
      // if (treeData.length) {
      //   this.tableData = this.convertData(treeData);
      //   this.gridRowClick({ row: treeData[0] });
      // }
    },
    resetChange() {
      this.jcxflModel = false;
      this.jcxflForm = {};
    },
    resetChange1() {
      this.jcxflModel1 = false;
      this.jcxflForm1 = {};
    },
    handleSubmit(form, done) {
      this.jcxflLoadin1 = true;
      API.submit(this.jcxflForm)
        .then((res) => {
          this.jcxflLoadin1 = false;
          this.jcxflModel = false;
          this.$message.success(res.data.msg);
          this.onload();
        })
        .catch((err) => {
          this.jcxflLoadin1 = false;
          done();
        });
    },
    handleSubmit1(form, done) {
      this.jcxflloading = true;
      API.stworkchecklistSubmit({
        ...this.jcxflForm1,
        typeId: this.selectTree.id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.jcxflModel1 = false;
            this.$message.success(res.data.msg);
            this.onload1();
            this.jcxflloading = false;
          }
        })
        .catch((err) => {
          done();
          this.jcxflloading = false;
        });
    },
    getTreeAdd(row = {}) {
      if (row.eqType == 2)
        return this.$message.error("当前节点为设备设施子节点，不可新增节点");
      this.jcxflForm = {
        eqType: row.eqType || 1,
        parentId: row.id || -1,
      };
      this.treeData = this.tableData;
      this.jcxflModel = true;
    },
    getTreeDelete(data) {
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", data.id);
          return API.stworkchecklistRemove(formData);
        })
        .then(() => {
          this.$refs.commonTreeCore.nodeData = {};
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.typeTree();
        });
    },
    getParentData(data, id) {
      data.forEach((val, index) => {
        if (val.id == this.jcxflForm.id || val.eqType == 2) {
          data.splice(index, 1);
        } else if (val.children) {
          this.getParentData(val.children, this.jcxflForm.id);
        }
      });
      return data;
    },
    getTreeEdit(row) {
      this.jcxflForm = row;
      const data = JSON.parse(JSON.stringify(this.tableData));
      this.treeData = this.getParentData(data);
      this.jcxflModel = true;
    },
    handleImport() {
      // if (!this.selectTree?.isObj)
      //   return this.$message.success("请选择检查项对象");
      this.excelBox = true;
      this.excelOption.excelForm.excelFile = [];
    },
    headExport() {
      if (!this.selectTree?.isObj)
        return this.$message.success("请选择检查项对象");
      exportBlob(
        `/api/sinoma-hse-prj//hdcheckindex/export?objectId=${this.selectTree.id}`
      ).then((res) => {
        downloadXls(res.data, this.selectTree.typeName);
      });
    },
    async addjcx(row = {}) {
      if (!this.selectTree)
        return this.$message.error("请选择设备设施类型清单");
      if (this.selectTree.eqType == 1)
        return this.$message.error("请选择设备设施类型清单子节点新增");
      this.jcxflForm1 = row;
      this.jcxflModel1 = true;
      this.$forceUpdate();
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headDel() {
      this.rowDel(this.selectionList, 1);
    },
    rowDel(data, type) {
      this.selectionList = data;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", this.ids());
          return type == 0
            ? API.remove(formData)
            : API.stworkchecklistRemove(formData);
        })
        .then(() => {
          this.selectionList = [];
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          type == 0 ? this.onload() : this.onload1(this.page);
        });
    },
    selectionChange(data) {
      this.selectionList = [];
      this.selectionList = data;
    },
    gridRowClick(row) {
      this.selectTree = row;
      this.tableData1 = [];
      this.$refs.gridLayOut.page.total = 0;
      if (row.eqType == 1) return;
      this.page.currentPage = 1;
      this.onload1(this.page);
    },
    convertData(data) {
      // 如果传入的数据为空或不是数组类型，则返回空数组
      if (!data || !Array.isArray(data)) {
        return [];
      }

      // 将子节点转换为目标格式，并存储到新数组中
      const result = data.map((item) => {
        return {
          eqType: item.eqType,
          parentId: item.parentId,
          typeName: item.typeName,
          id: item.id,
          isSource: item.eqType == 2 ? true : false, //这里的item根据自己的业务进行改变
          children: this.convertData(item.children), // 递归调用自身处理所有子孙节点
        };
      });
      return result;
    },
    onload() {
      this.tableLoading = true;
      API.list().then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = this.convertData(res.data.data);
        }
      });
    },
    onload1(page, params = {}) {
      this.page = page || this.page;
      this.tableLoading = true;
      API.getList(this.page.currentPage, this.page.pageSize, {
        ...params,
        typeId: this.selectTree.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading1 = false;
          this.tableData1 = res.data.data.records;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.left-box {
  width: 324px;
}
.table-box {
  flex: 1;
}
.introduceTree {
  margin-right: 20px;
  ::v-deep .common_tree_handle .el-tree {
    height: calc(100vh - 240px) !important;
  }
}
::v-deep .avue-crud .el-table {
  height: calc(100vh - 223px) !important;
  max-height: calc(100vh - 223px) !important;
}
</style>
