
<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="true"
        :showCheckbox="false"
        :popover-show="true"
        :expandOnClickNode="false"
        treeTitle="分类列表"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
        @include-down="includeDown"
      />
      <el-main>
        <head-layout
          :head-btn-options="headBtnOptions"
          head-title="知识关联"
          @head-remove="headRemove"
          @head-add-tabs="headAdd"
        ></head-layout>
        <div >
          <grid-head-layout
            ref="gridHeadLayout"
            :grid-head-btn="gridHeadBtn"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
        </div>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          @row-remove="rowRemove"
        ></grid-layout>
      </el-main>
    </el-container>

    <common-dialog
      v-if="showTreeDialog"
      width="60%"
      class="qmDialog"
      dialogTitle="分类管理"
      :show-fullscreen="true"
      @cancel="showTreeDialog = false"
      @confirm="handleTreeNodeSave">
      <avue-form
        :option="treeDialogOption"
        ref="addForm"
        v-model="form"
        @submit="treeNodeSave"
      ></avue-form>
    </common-dialog>

    <common-dialog
      v-if="showDialog"
      width="60%"
      dialogTitle="知识关联"
      @cancel="closeDialog"
      @confirm="handleNodeSave">
      <grid-head-layout
        ref="gridHeadLayoutDialog"
        :grid-head-btn="gridHeadBtn"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearchDialog"
        @grid-head-empty="gridHeadEmptyDialog"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOutDialog"
        class="diaTable"
        :table-options="tableOptionsDialog"
        :table-data="tableDataDialog"
        :table-loading="crudLoadingDialog"
        :data-total="pageDialog.total"
        :page="pageDialog"
        @page-current-change="onLoadDialog"
        @page-size-change="onLoadDialog"
        @page-refresh-change="onLoadDialog"
        @gird-handle-select-click="selectionChangeDialog"
      ></grid-layout>
    </common-dialog>

  </div>
</template>

<script>
import {
  infoTypeTree,
  saveInfoTypeTree,
  removeInfo,
  listRelation,
  saveInfoType,
  removeInfoType,
  listAllInfo,
} from "@/api/knowledgeClassifcation/knowledge";
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import ServerNameEnum from "@/util/ServerNameEnum";
import CommonDialog from "@/components/CommonDialog";
export default {
  data() {
    return {
      form: {},
      typeId: '',
      query: {},
      treeReadOnlyFlag: false,
      tableData: [],
      treeLoading: false,
      crudLoading: false,
      treeData: [],
      selectionList: [],
      node: {}, //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      searchTitle: "knowTypeName",
      defaultProps: {
        label: "knowTypeName",
        value: "knowTypeCode",
        key: "knowTypeCode",
      },
      showDialog: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      crudLoadingDialog: false,
      treeDataDialog: [],
      selectionListDialog: [],
      pageDialog: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    idsDialog() {
      let ids = [];
      this.selectionListDialog.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        },
      ];
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        selection: true,
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "文档类型",
            prop: "extension",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "上传时间",
            prop: "updateTime",
            align: "center",
            width: 180,
            overHidden: true,
          },
        ],
      };
    },
    tableOptionsDialog() {
      return {
        index: true,
        indexLabel: "序号",
        selection: true,
        menu: false,
        column: [
          {
            label: "名称",
            align: "center",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "文档类型",
            prop: "extension",
            align: "center",
            width: 120,
          },
          {
            label: "上传时间",
            prop: "updateTime",
            align: "center",
            width: 180,
          },
        ],
      };
    },
    gridHeadBtn() {
      return [];
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "fileName",
          span: 6,
          placeholder: "请输入知识名称",
        },
        // {
        //   label: "",
        //   prop: "cateLineCode",
        //   span: 4,
        //   placeholder: "请输入知识分类编号",
        // },
      ];
    },
    gridRowBtn() {
      return [
        {
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        },
      ];
    },
    treeDialogOption() {
      let that = this;
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "上级类型",
            prop: "parentId",
            type: "tree",
            props: {
              label: "knowTypeName",
              value: "id",
              key: "parentId",
            },
            placeholder: "上级类型",
            dicUrl: `${ServerNameEnum.SERVER_KNOWLEDGE}/infoType/tree`,
            nodeClick(row) {
              let length = row.ancestorsId.split(',').length;
              if (length == 5) {
                that.$nextTick(()=>{
                  that.form.parentId = '';
                  that.form.$parentId = '';
                })
                that.$message({
                  type: "warning",
                  message: "分类最多添加五层",
                });
              }
            }
          },
          {
            label: "分类名称",
            prop: "knowTypeName",
            placeholder: "请输入维度名称",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入维度名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
        ],
      };
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    CommonDialog,
  },
  created() {
    this.initTree();
  },
  mounted() {},
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    // 左侧树新增
    treeMenuAdd() {
      this.form = {};
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },

    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },

    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },

    initTree() {
      infoTypeTree()
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
          this.onLoad(this.page, {});
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    // 新增左侧树节点
    treeNodeSave(data, done) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$loading();
          saveInfoTypeTree(this.form)
            .then((res) => {
              const { msg, data } = res.data;
              this.treeReadOnlyFlag = true;
              this.$message.success(msg);
              this.initTree();
              this.showTreeDialog = false;
            })
            .finally(() => {
              this.$loading().close();
            })
            .catch((err) => {
              done();
            });
        }
      });
    },
    // 删除左侧树节点
    treeNodeDel(data) {
      if (data.children.length != 0) {
        this.$message({
          type: "warning",
          message: "当前分类下有子级，禁止删除",
        });
        return;
      }
      this.$confirm("确认删除数据", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeInfo(data.id).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.initTree();
          });
        })
        .catch(() => {});
    },

    // 点击左侧树节点
    treeNodeClick(node) {
      this.typeId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    //知识分类新增
    closeDialog() {
      this.selectionClearDialog();
      this.showDialog = false;
    },
    handleNodeSave() {
      if (this.selectionListDialog.length > 0) {
        this.$loading();
        let params = {
          typeId: this.typeId,
          ids: this.idsDialog,
        };
        saveInfoType(params)
          .then((res) => {
            const { msg, data } = res.data;
            this.$message.success(msg);
            this.onLoad(this.page, {});
            this.showDialog = false;
          }).finally(() => {
            this.$loading().close();
          })
          .catch((err) => {
            done();
          });
      } else {
        this.$message.warning("请选择数据");
      }
    },
    headRemove() {
      if (!this.typeId) {
        this.$message.warning(
          this.$t("cip.cmn.rule.selectWarning") +
          this.$t("cip.plat.sys.knowledge.title.treeTitle")
        );
        return;
      }
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("确定将选择数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return removeInfoType(this.ids, this.typeId).then(() => {
            this.onLoad(this.page, {});
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.selectionClear();
          });
        })
        .catch(() => {});
    },

    rowRemove(row) {
      if (!this.typeId) {
        this.$message.warning(
          this.$t("cip.cmn.rule.selectWarning") +
          this.$t("cip.plat.sys.knowledge.title.treeTitle")
        );
        return;
      }
      this.$confirm("确定删除数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return removeInfoType(row.id, this.typeId);
        })
        .then(() => {
          this.onLoad(this.page, {});
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
    },
    // 新增
    headAdd() {
      if (!this.typeId) {
        this.$message.warning(
          this.$t("cip.cmn.rule.selectWarning") +
            this.$t("cip.plat.sys.knowledge.title.treeTitle")
        );
        return;
      }
      this.showDialog = true;
      this.$nextTick(() => {
        this.onLoadDialog(this.pageDialog, {});
      });
    },

    gridHeadSearch(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    gridHeadEmpty() {
      this.query = {};
      this.onLoad(this.page);
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.crudLoading = true;
      listRelation(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query),
        this.typeId
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.crudLoading = false;
        this.selectionClear();
      });
    },
    gridHeadSearchDialog(query) {
      this.pageDialog.currentPage = 1;
      this.onLoadDialog(this.pageDialog, query);
    },
    gridHeadEmptyDialog(query) {
      this.onLoadDialog(this.pageDialog, query);
    },
    onLoadDialog(page, params = {}) {
      this.pageDialog = page;
      this.crudLoadingDialog = true;
      listAllInfo(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayoutDialog.searchForm)
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOutDialog.page.total = data.total;
          this.tableDataDialog = data.records;
          this.crudLoadingDialog = false;
          this.selectionClearDialog();
        })
        .catch(() => {
          this.crudLoadingDialog = true;
        });
    },
    selectionClearDialog() {
      this.selectionListDialog = [];
    },
    selectionChangeDialog(list) {
      this.selectionListDialog = list;
    },
    selectionChange(list) {
      this.selectionList = list;
    },
  },
};
</script>
<style lang="scss" scoped>
.diaTable ::v-deep .avue-crud .el-table {
  height: calc(100vh - 500px) !important;
  max-height: calc(100vh - 500px) !important;
}
</style>

