var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("tree-layout", {
        attrs: { "tree-options": _vm.treeOption, "tree-data": _vm.treeData },
        on: { "tree-load": _vm.treeLoad },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }