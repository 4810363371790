var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "考试管理",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-add-tabs": _vm.headAdd,
                  "head-remove": _vm.headRemove,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowDetail,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row, index }) {
                      return [
                        row.status === 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.rowEdit(row)
                                  },
                                },
                              },
                              [_vm._v("\n            编辑\n          ")]
                            )
                          : _vm._e(),
                        row.status === 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.rowRemove(row)
                                  },
                                },
                              },
                              [_vm._v("\n            删除\n          ")]
                            )
                          : _vm._e(),
                        row.status !== 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.processFig(row)
                                  },
                                },
                              },
                              [_vm._v("\n            流程图\n          ")]
                            )
                          : _vm._e(),
                        row.status !== 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.processProgress(row)
                                  },
                                },
                              },
                              [_vm._v("\n            流程进度\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.bpmnVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "70%",
                customClass: "wf-dialog",
                dialogTitle: _vm.$t(`cip.desk.workflow.title.indexHeadVisible`),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.bpmnVisible = false
                },
              },
            },
            [
              _c("wf-design", {
                ref: "bpmn",
                staticStyle: { height: "60vh" },
                attrs: { options: _vm.bpmnOption },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.circulationVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "35%",
                dialogTitle: _vm.$t(
                  `cip.plat.wfOps.process.title.processProgressTitle`
                ),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.circulationVisible = false
                },
              },
            },
            [_c("wf-flow", { attrs: { flow: _vm.flow } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }