<!--
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-26 09:44:23
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
    <avue-form :option="options" ref="addForm" v-model="form" @submit="treeNodeSave">
      <!-- <template slot="idFiles">
        <el-upload :class="{ uoloadSty: showImg, disUoloadSty: noneUploadImg }" action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform" :headers="headers" :on-preview="handlePreview" list-type="picture-card" :file-list="fileList" file="file" :on-remove="handleDeleteImgRemove" :on-success="handleIdFilesSuccess" :on-change="uploadImgChange" :before-upload="beforeAvatarUpload"
          accept=".jpeg,.jpg,.gif,.png" :limit="limitCountImg">
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </template>
      <template slot="certificateFiles">
        <el-upload :class="{ uoloadSty: showImg, disUoloadSty: noneUploadImgCer }" action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform" :headers="headers" :on-preview="handlePreview" list-type="picture-card" :file-list="certificateFilesFileList" file="file" :on-remove="handleDeleteImgRemoveCer" :on-success="handleIdFilesSuccessCer" :on-change="uploadImgChangeCer"
          :before-upload="beforeAvatarUpload" accept=".jpeg,.jpg,.gif,.png" :limit="limitCountImg">
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </template> -->
      <template slot="areaId">
        <el-cascader placeholder="请选择注册位置" :disabled="pageType == 'view'" ref="prjAreaCascader" filterable style="width:100%" v-model="form.areaId" :options="areaDic" @change="handleChangeArea"></el-cascader>
      </template>
    </avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button v-if="pageType!='view'" size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </el-dialog>
</template>

<script>
  import {
    prjstructureSubmit
  } from "@/api/safe/itemList";
  import {
    getDetail,
    add
  } from "@/api/safe/thirdcompany";
  import {
    getToken
  } from "@/util/auth";
  import {getLazyTree} from "@/api/base/region";
  export default {
    data() {
      return {
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {

        },
        title: '',
        pageType: '',
        fileUrl: "",
        showImg: true, //默认展示
        noneUploadImg: false, //
        noneUploadImgCer: false, //
        fileList: [],
        certificateFilesFileList: [],
        limitCountImg: 2,
        dialogImageUrl: "",
        dialogVisible: false,
        areaDic: [], // 区域位置
      }
    },
    computed: {
      headers: function () {
        return {
          "Sinoma-Auth": getToken()
        };
      },
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "公司名称",
              prop: "companyName",
              disabled: this.pageType == 'view',
              rules: [{
                required: true,
                message: "请输入公司名称",
                trigger: "blur"
              }]
            },
            {
              label: "统一社会信用代码",
              prop: "companyCode",
              disabled: this.pageType == 'view',
              rules: [{
                required: true,
                message: "请输入统一社会信用代码",
                trigger: "blur"
              }]
            },
            {
              label: "法定代表人",
              prop: "legalRep",
              disabled: this.pageType == 'view',
              rules: [
                {
                  required: false,
                  message: "请输入法定代表人",
                  trigger: "blur"
                },
                { max: 20, message: '法定代表人不能超过20个字符', trigger: 'blur' }
              ]
            },
            {
              label: "成立日期",
              prop: "curDate",
              type: "date",
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd",
              disabled: this.pageType == 'view',
              "picker-options": {
                disabledDate: (time) => {
                  return time.getTime() > Date.now() - 3600 * 1000 * 24;
                },
              },
              rules: [{
                required: false,
                message: "请输入成立日期",
                trigger: "blur"
              }]
            },
            {
              label: "注册位置",
              prop: "areaId",
              formslot: true,
              type: 'cascader',
              dicData: this.areaDic,
              disabled: this.pageType == 'view',
              rules: [{
                required: false,
                message: "请选择注册位置",
                trigger: "change"
              }]
            },
            {
              label: "详细位置",
              prop: "regLocation",
              disabled: this.pageType == 'view',
              rules: [{
                required: false,
                message: "请输入详细位置",
                trigger: "blur"
              }]
            },
            {
              label: '是否启用',
              prop: 'isUsed',
              disabled: this.pageType == 'view',
              type: 'switch',
            },
            // {
            //   label: '证件照片',
            //   prop: 'idFiles',
            //   span: 24,

            // },
            // {
            //   label: '资质、证书',
            //   prop: 'certificateFiles',
            //   span: 24,
            // },
          ],
        }
      }
    },
    methods: {
      //   项目位置
      getAreaData(parentCode){
          getLazyTree(parentCode).then(res => {
              if(res.data.data && res.data.data.length != 0){
                  this.areaDic = this.generateOptions(res.data.data)
              }
          })
      },
      generateOptions(params) {
          var result = []
          for (const param of params) {
              if (param.parentId == '10') {  // 判断是否为顶层节点
              var parent = {
                  'id': param.id,
                  'label': param.title,
                  'value':param.id,
                  'title':param.title
              }
                  parent.children = this.getchilds(param.id, params)  // 获取子节点
                  result.push(parent)
              }
          }
          return result
      },
      getchilds(id, array) {
          const childs = []
          for (const arr of array) {  // 循环获取子节点
              if (arr.parentId === id) {
                  childs.push({
                      'id': arr.id,
                      'label': arr.title,
                      'value':arr.id,
                      'title':arr.title
                  })
              }
          }
          for (const child of childs) { // 获取子节点的子节点
              const childscopy = this.getchilds(child.id, array)// 递归获取子节点
              if (childscopy.length > 0) {
                  child.children = childscopy
              }
          }
          return childs
      },
      // 区域位置获取
      handleChangeArea(value){
        const regionList = this.$refs['prjAreaCascader'].getCheckedNodes()[0].pathLabels
        if(regionList && regionList.length != 0){
          this.form.areaName = regionList.join(',')
        }
          // const selectedLabel = this.getNodeLabel(this.areaDic, value);
          // console.log('selectedLabel--区域位置---',selectedLabel)
      },
      init(type, data) {
        this.form = {
          isUsed:true
        }
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.noneUploadImgCer = false
        this.noneUploadImg = false
        this.pageType = type
        if (type == 'edit') {
          this.title = '编辑相关方单位'
          this.form = {
            ...data
          }
          if (this.form.idFiles != '') {
            this.form.idFiles = JSON.parse(this.form.idFiles)
            this.fileList = this.form.idFiles.map((item) => {
              return {
                ...item,
                url:item.link,
                fileUrl: item.link
              }
            })
          }
          if (this.form.certificateFiles != '') {
            this.form.certificateFiles = JSON.parse(this.form.certificateFiles)
            this.certificateFilesFileList = this.form.certificateFiles.map((item) => {
              return {
                ...item,
                url:item.link,
                fileUrl: item.link
              }
            })
          }
          this.noneUploadImg = this.fileList.length >= this.limitCountImg;
          this.noneUploadImgCer = this.certificateFilesFileList.length >= this.limitCountImg;
          this.form.isUsed = this.form.isUsed === 1 ? false : true
          this.form.areaId = this.form.areaId  ? this.form.areaId.split(',') : []
        } else if (type == 'view') {
          this.title = '查看相关方单位'
          this.showImg = false
          this.noneUploadImg = true
          this.noneUploadImgCer = true
          this.form = {
            ...data
          }
          if (this.form.idFiles != '') {
            this.form.idFiles = JSON.parse(this.form.idFiles)
            this.fileList = this.form.idFiles.map((item) => {
              return {
                ...item,
                url:item.link,
                fileUrl: item.link
              }
            })
          }
          if (this.form.certificateFiles != '') {
            this.form.certificateFiles = JSON.parse(this.form.certificateFiles)
            this.certificateFilesFileList = this.form.certificateFiles.map((item) => {
              return {
                ...item,
                url:item.link,
                fileUrl: item.link
              }
            })
          }
          this.form.isUsed = this.form.isUsed === 1 ? false : true
          this.form.areaId = this.form.areaId  ? this.form.areaId.split(',') : []
        } else {
          this.title = '新增相关方单位'
          this.form.areaId = []
        }
        this.getAreaData()
      },
      // 新增左侧树节点
      handleTreeNodeSave() {
        if (this.pageType != 'view') {
          this.$refs.addForm.submit();
        }
      },
      //证件照片
      handleIdFilesSuccess(res, file, fileList) {
        this.form.idFiles = fileList.map((item) => {
          if (item.response) {
            return item.response.data
          } else {
            return item
          }
        });
      },
      // 判断数量，是否显示上传icon
      uploadImgChange(file, fileList) {
        this.noneUploadImg = fileList.length >= this.limitCountImg;
      },
      // 删除图片，判断数量，是否显示icon
      handleDeleteImgRemove(file, fileList) {
        this.form.idFiles = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item;
          }
        });
        this.noneUploadImg = fileList.length >= this.limitCountImg;
      },
      //资质、证书
      handleIdFilesSuccessCer(res, file, fileList) {
        this.form.certificateFiles = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item;
          }
        });
      },
      // 判断数量，是否显示上传icon
      uploadImgChangeCer(file, fileList) {
        this.noneUploadImgCer = fileList.length >= this.limitCountImg;
      },
      // 删除图片，判断数量，是否显示icon
      handleDeleteImgRemoveCer(file, fileList) {
        this.form.certificateFiles = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item;
          }
        });
        this.noneUploadImgCer = fileList.length >= this.limitCountImg;
      },
      handleAvatarSuccess(res, file, fileList) {
        this.$message({
          type: "success",
          message: "上传成功！",
        });
      },
      beforeUpload(file) {
        const fileName = file.name;
        const fileType = fileName.substring(fileName.lastIndexOf('.'));
        if (fileType !== '.jpg' && fileType !== '.jpeg' && fileType !== '.png' && fileType !== '.pdf' && fileType !== '.excel' && fileType !== '.word' && fileType !== '.doc' && fileType !== '.docx' && fileType !== '.xls' && fileType !== '.xlsx' && fileType !== '.txt') {
          this.$message({
            type: "warning",
            message: "请上传jpg、jpeg、png、pdf、excel、word、doc、docx、xls、xlsx、txt类型文件！",
          });
          return false;
        }
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
          this.$message.warning('上传文件大小不能超过 20MB!');
        }
        return isLt20M;
      },
      handlePreview(file) {
        //判断只有图片可以点击打开放大图
        console.log('file==', file)
        const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (
          fileName == "jpg" ||
          fileName == "png" ||
          fileName == "jpeg" ||
          fileName == "bmp"
        ) {
          this.dialogVisible = true;
          if (file.response) {
            //判断新增和编辑时候 地址不一样
            this.dialogImageUrl = file.response.data.objectUrl ? file.response.data.objectUrl : file.response.data.link;
          } else {
            this.dialogImageUrl = file.link;
          }
        }
      },
      beforeAvatarUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        const isJPG = (file.type === 'image/jpeg' || file.type === 'image/jpg' ||
          file.type === 'image/gif' || file.type === 'image/png');

        if (!isJPG) {
          this.$message.warning('上传图片只能是 自定义文件 格式!');
        }
        if (!isLt20M) {
          this.$message.warning('上传文件大小不能超过 20MB!');
        }
        return isJPG && isLt20M;
      },
      treeNodeSave(data) {
        /// isUsed == 0启用 == 1 禁用
        data.isUsed = data.isUsed == true ? 0 : 1
        data.areaId = data.areaId.length?data.areaId.join(','):''
        this.$refs.addForm.validate((valid, done) => {
          if (valid) {
            if (this.pageType != 'edit') {
              data.id = ''
            }
            this.dialogLoading = true
            let queryData = {
              ...data
            }
            if (queryData.idFiles && queryData.idFiles.length!=0) {
              queryData.idFiles = JSON.stringify(queryData.idFiles)
            }
            if (queryData.certificateFiles && queryData.certificateFiles.length != 0) {
              queryData.certificateFiles = JSON.stringify(queryData.certificateFiles)
            }
            this.$loading();
            add(queryData).then(res => {
              const {
                msg,
                data
              } = res.data;
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.fileList = [],
              this.certificateFilesFileList = [],
              this.showDialog = false;
              this.dialogLoading = false
              this.$loading().close();
            }).catch(err => {
              this.$loading().close();
              done()
            }).finally(() => {
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {}
        this.fileList = [],
        this.certificateFilesFileList = [],
        this.$refs.addForm.resetFields();
        this.showDialog = false
      },
    }
  }

</script>
