var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "log" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "我的", name: "myLog" } }),
          _c("el-tab-pane", { attrs: { label: "全部", name: "allLog" } }),
        ],
        1
      ),
      _c("head-layout", {
        attrs: {
          "head-title": "安全日志",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-remove": _vm.handleDelete, "head-add": _vm.headAdd },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: {
          "search-span": 3,
          "search-columns":
            _vm.activeName == "myLog"
              ? _vm.mySearchColumns
              : _vm.allSearchColumns,
        },
        on: {
          "grid-head-search": _vm.GridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOption,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "grid-row-detail-click": _vm.detailView,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
        },
        scopedSlots: _vm._u([
          {
            key: "logCode",
            fn: function ({ row }) {
              return [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.rowView(row)
                      },
                    },
                  },
                  [_vm._v(_vm._s(row.logCode))]
                ),
              ]
            },
          },
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                row.status == 1 && row.createUser == _vm.userInfo.user_id
                  ? [
                      _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 3px" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.rowEdit(row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 3px" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.rowDel(row)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]
                  : [
                      _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 3px" },
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.rowView(row)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleFlow(row, _vm.index)
                            },
                          },
                        },
                        [_vm._v("\n        流程图\n      ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.headProgress(row, _vm.index)
                            },
                          },
                        },
                        [_vm._v("\n        流程进度\n      ")]
                      ),
                    ],
              ]
            },
          },
        ]),
      }),
      _vm.bpmnVisible
        ? _c(
            "CommonDialog",
            {
              staticClass: "wf-dialog",
              attrs: {
                width: "70%",
                showBtn: false,
                dialogTitle: _vm.$t(`cip.desk.workflow.title.indexHeadVisible`),
              },
              on: {
                cancel: function ($event) {
                  _vm.bpmnVisible = false
                },
              },
            },
            [
              _c("wf-design", {
                ref: "bpmn",
                staticStyle: { height: "60vh" },
                attrs: { options: _vm.bpmnOption },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.circulationVisible
        ? _c(
            "CommonDialog",
            {
              attrs: {
                width: "35%",
                showBtn: false,
                dialogTitle: _vm.$t(
                  `cip.plat.wfOps.process.title.processProgressTitle`
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.circulationVisible = false
                },
              },
            },
            [_c("wf-flow", { attrs: { flow: _vm.flow } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }