<template>
  <div class="common_tree_handle" style="width: auto;">
    <div class="common_tree_handle" v-show="show">
      <el-header style="text-align: right; font-size: 12px;height:auto;min-height: 46px;background-color: #ffffff;padding:0 12px;display:flex;align-items:center;" ref="elHeader" v-if="titleShow">
        <el-row style="display:flex;align-items:center;width: 100%;line-height: 45px;border-bottom: 1px solid #cccccc;">
          <el-col span="18" style="float: left;display:flex;align-items:center;">
            <el-tooltip effect="dark" :content="treeTitle || this.$t('cip.cmn.title.typeTitle')" placement="top-start" v-if="showTopTip == true">
              <label style="font-size: 14px; text-align: left;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{
      treeTitle === "" ? $t('cip.cmn.title.typeTitle') : treeTitle
    }}</label>
            </el-tooltip>
            <label v-else style="font-size: 14px; text-align: left;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{
                treeTitle === "" ? $t('cip.cmn.title.typeTitle') : treeTitle
              }}</label>
          </el-col>
          <el-col span="6" v-if="isShowdig">
            <template>
              <el-popover width="100" v-model="visible">
                <p class="popv_p" @click="add()">新增根节点组</p>
                <!-- <p class="popv_p" @click="edit()">{{ $t('cip.cmn.btn.editBtn') }}</p>
                <p class="popv_p" @click="getdelete()">{{ $t('cip.cmn.btn.delBtn') }}</p> -->
                <el-button slot="reference" type="text">
                  <i class="el-icon-setting" style="font-size:18px;color:black"></i>
                </el-button>
              </el-popover>

            </template>
          </el-col>
        </el-row>
      </el-header>
      <el-row style="padding: 14px 12px;background: #FFFFFF;position: relative" v-if="isShowSearchInput">
        <el-input size="small" :placeholder="$t('cip.cmn.msg.warning.filterateWarning')" v-model="filterText" class="searchInput" :class="popoverShow ? 'inputWidth' : ''">
        </el-input>
        <el-popover placement="right" width="80" v-if="popoverShow" trigger="hover">
          <el-button type="text" size="mini" style="font-size: 14px" @click="openAll" v-if="treeExpand == false && expandShow">展开全部</el-button>
          <el-button type="text" size="mini" style="font-size: 14px" @click="openAll" v-if="treeExpand == true && expandShow">收缩全部</el-button>
          <el-button type="text" size="mini" style="margin: 0;font-size: 14px" v-if="isInclude == false" @click="includeDown">包含下级</el-button>
          <el-button type="text" size="mini" style="margin: 0;font-size: 14px" v-if="isInclude == true" @click="includeDown">不包含下级</el-button>
          <el-button slot="reference" type="primary" icon="el-icon-more" class="moreBtn"></el-button>
        </el-popover>
      </el-row>
      <el-tree ref="commonTree" v-loading="loading" :data="treeChangeData" :props="defaultProps" :default-expand-all="treeExpand" :highlight-current="true" :show-checkbox="showCheckbox" :check-strictly="checkStrictly" :default-expanded-keys="defaultExpandedKeys" :expand-on-click-node="false" :default-checked-keys="defaultCheckedKeys" :current-node-key="currentNodeKey" :node-key="nodeKey"
        @node-click="nodeClick" @check-change="handleCheckChange" :filter-node-method="filterNode" style="font-size: 12px;border-radius: 4px;padding:0 5px 0 12px;">
        <span slot-scope="{ node, data }" style="display: flex">
          <i class="el-icon-folder" style="line-height: 28px" v-if="data.type=='node' "></i>
          <i class="el-icon-camera-solid" v-else-if="data.type=='camera'" style="line-height: 28px"></i>
          <i class="el-icon-box" v-else="data.type=='camera'" style="line-height: 28px"></i>
          <span style="margin-left: 10px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis; display: block" :style="{width: 190 - (node.level - 1)*18 + 'px'}">{{
      node.label }}</span>
          <el-dropdown v-show="isShowMore">
            <span class="el-dropdown-link"><i class="el-icon-more"></i>
            </span>

            <el-dropdown-menu slot="dropdown" v-if="pageType!='camera'">
              <el-dropdown-item @click.native="addChild(node,'node')" v-if="data.type!='ai_box'">新增节点组</el-dropdown-item>
              <el-dropdown-item @click.native="addChild(node,'ai_box')" v-if="data.type!='ai_box'">新增AI盒子</el-dropdown-item>
              <el-dropdown-item @click.native="addChild(node,'server')" v-if="data.type!='ai_box'">新增AI服务器</el-dropdown-item>
              <el-dropdown-item @click.native="getDrag(node)" v-if="data.type=='ai_box'">移动</el-dropdown-item>
              <el-dropdown-item @click.native="getdelete(node)" v-if="data.parentId!='0'">删除</el-dropdown-item>
            </el-dropdown-menu>
            <el-dropdown-menu slot="dropdown" v-else>
              <el-dropdown-item @click.native="addChild(node,'camera')" v-if="data.type!='camera'">新增摄像头</el-dropdown-item>
              <el-dropdown-item @click.native="getdelete(node)" v-if="data.type=='camera'">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </el-tree>
    </div>
    <!-- <el-button v-if="isZoom && !show" ref="button" :style="style_button" type="text">
      <i class="el-icon-d-arrow-right" style="font-size:18px;color:black" @click="click_arrow(1)"></i>
    </el-button> -->
    <div v-if="isZoom">
      <el-button class="rightBtn" v-if="show" style="margin-right: 5px;clip-path:polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);background:#d2d3d6" circle type="primary" icon="el-icon-arrow-right" @click="click_arrow(0)"></el-button>
      <el-button class="rightBtn" circle v-else type="primary" icon="el-icon-arrow-left" style="margin-right: 5px;clip-path:polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);background:#d2d3d6;left: -30px;" @click="click_arrow(1)"></el-button>
    </div>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        filterText: '',
        isInclude: false,
        defaultExpandAll: false,
        treeChangeData: [],
        nodeData: {},
        // show: true,
        style_button: {
          float: "right",
          background: "#ffffff",
          position: "float",
          height: "18px",
          width: "18px"
        }
      }
    },

    // treeData            树展示的数据
    // defaultProps        树展示的数据格式
    // searchTitle         过滤的字段名称
    // isShowdig           是否展示搜索旁边的下拉
    // showCheckbox        是否开启多选
    // isShowSearchInput   是否显示搜索框
    // @getTreeAdd         下拉新增方法
    // @getTreeEdit        下拉编辑方法
    // @getTreeDelete      下拉删除方法
    // @getNodeClick       节点点击方法
    // @getHandleCheckChange  多选节点选中状态发生变化时的回调
    computed: {
      disabled_treetip(item) {
        console.log(item, "asdfasdfasdfasdf");
        return false;
      }

    },
    props: {
      nodeKey: {
        type: String,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      treeTitle: {
        type: String,
        default: ""
      },
      pageType:{
        type: String,
        default: "node"
      },
      treeData: {
        type: Array,
      },
      treeForm: {
        type: Object,
        default: () => ({})
      },
      treeFormOption: {
        type: Object,
        default: () => ({})
      },
      show: {
        type: Boolean,
        default: true,
      },
      showTopTip: {
        type: Boolean,
        default: true,
      },
      isZoom: {
        type: Boolean,
        default: false
      },
      defaultProps: {
        type: Object,
        default: () => {
          return {
            children: "children",
            label: "label",
          }
        }
      },
      treeExpand: {
        type: Boolean,
        default: true,
      },
      expandShow: {
        type: Boolean,
        default: false,
      },
      popoverShow: {
        type: Boolean,
        default: false,
      },
      checkStrictly: {
        type: Boolean,
        default: false,
      },
      defaultCheckedKeys: {
        type: Array,
      },
      defaultExpandedKeys: {
        type: Array,
      },
      currentNodeKey: {
        type: String
      },
      searchTitle: {
        type: String,
        default: ""
      },
      showCheckbox: {
        type: Boolean,
        default: false,
      },
      isShowdig: {
        type: Boolean,
        default: false,
      },
      isShowMore: {
        type: Boolean,
        default: false,
      },
      isShowSearchInput: {
        type: Boolean,
        default: true
      },
      titleShow: {
        type: Boolean,
        default: true
      },
      risk: {
        type: Boolean, // 区分子节点和父节点不同类型的图标（风险管理）
        default: false
      },
      isLeafIcons: {
        type: Boolean, // 区分子节点和父节点不同类型的图标（通用版本）
        default: false
      },
      iconsFlied: {
        type: String, // 区分子节点和父节点不同类型的图标（通用版本）
        default: ''
      }
    },
    methods: {
      click_arrow(a) {
        switch (a) {
          case 0:
            this.show = false;

            // this.style_button.width = "18px";
            // this.style_button.height = "18px";
            break;
          case 1:
            // this.style_button.width = "0px";
            // this.style_button.height = "0px";
            this.show = true;
            break
          default: {

          }
        }
        this.$emit("showChange", this.show);
      },
      openAll() {
        this.treeExpand = !this.treeExpand;
      },
      includeDown() {
        this.isInclude = !this.isInclude
        this.$emit("include-down", this.isInclude);
      },
      // 新增
      add() {
        this.$emit('getTreeAdd', this.nodeData)
        this.visible = false;
      },
      addChild(data, type) {
        this.$emit('getTreeAddChild', data, type)
        this.visible = false;
      },
      //移动
      getDrag(data) {
        this.$emit('getTreeDrag', data)
      },
      // 编辑
      edit() {
        if (this.nodeData.id) {
          this.$emit('getTreeEdit', this.nodeData)
        } else {
          this.$message({
            message: this.$t('cip.cmn.msg.warning.editWarning'),
            type: 'warning'
          });
        }

        this.visible = false;
      },
      // 删除
      getdelete(data) {
        this.$emit('getTreeDelete', data)
        this.visible = false;
      },
      nodeClick(data, node) {
        // this.isInclude = false;
        this.nodeData = data;
        this.$emit('getNodeClick', this.nodeData, node)
      },
      // 多选
      handleCheckChange(data, choosed) {
        this.$emit('getHandleCheckChange', data, choosed)
      },

      filterNode(value, data) {
        if (!value) return true;

        return data[this.defaultProps.label].indexOf(value) !== -1;
      },
      /**
       * 递归过滤嵌套数组（树形结构）
       * @param arr {Array}要过滤的原始数据
       * @param teamName {Boolean} 你要过滤的关键词
       *
       */
      recursiveFilter(arr, teamName) {
        let result = []
        arr.forEach((item1) => {

          if (item1[this.searchTitle].indexOf(teamName) != -1) {
            result.push(item1)
            if (item1.children) {
              //递归循环
              item1.children = this.recursiveFilter(item1.children, teamName)
            }
          } else {
            if (item1.children) {
              //递归循环
              item1.children = this.recursiveFilter(item1.children, teamName)
            }
          }
        })
        this.treeChangeData = result;
        return result;
        // this.searchResult = result
        // let data = arr.filter(item => item[this.searchTitle].indexOf(teamName)).map((item) => {
        //   item = Object.assign({}, item)
        //   if (item.children) {
        //     //递归循环
        //     item.children = this.recursiveFilter(item.children, teamName)
        //   }
        //   return item
        // })
        // this.treeChangeData = data;
        // return data
      },
      checkNode(val) {
        this.$nextTick(() => {
          this.$refs.commonTree.setCurrentNode(this.nodeData)
        })
      }

    },
    watch: {
      filterText(val) {
        this.$refs.commonTree.filter(val);
      },

      // filterText(n, o) {
      //   if (n && n.length > 0) {
      //     this.recursiveFilter(this.treeData, n);
      //   } else {
      //     this.treeChangeData = this.treeData;
      //   }
      // },
      treeData(n) {
        if (n) {
          this.treeChangeData = n;
        }
      },
      currentNodeKey(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.commonTree.setCurrentKey(val)
          })
        }
      }
    },
    mounted() {
      // this.treeChangeData = this.treeData;
    },
  }

</script>

<style lang='scss' scoped>
  .inputWidth {
    width: calc(100% - 34px);
  }

  .common_tree_handle {
    width: 280px;
    // padding-right: 12px;
    box-sizing: border-box;
    border-right: 1px solid #cccccc;
    background: #ffffff;
    flex-shrink: 0;
  }

  .popv_p {
    line-height: 32px;
    text-align: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .searchInput {
    // padding: 5px 0 6px;
    font-size: 12px;

    ::v-deep input {
      height: 28px;
    }
  }

  .moreBtn {
    position: absolute;
    top: 14px;
    right: 9px;
    transform: rotate(90deg);
    padding: 7px;
  }

</style>
<style lang='scss' scoped>
  .common_tree_handle {
    .el-tree {
      /* height:100% !important; */
      // height: calc(100vh - 300px) !important;
      overflow-y: scroll;
    }
  }

  ::v-deep .el-tree-node__content {
    line-height: 26px;
  }

  .el-popover {
    min-width: 80px !important;
  }

  .rightBtn {
    width: 48px;
    height: 96px;
    transform: rotate(180deg);
    position: absolute;
    left: 264px;
    top: calc(50vh - 148px);
    z-index: 99;
  }

</style>
