var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "设备维修记录清单",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-add": _vm.headAdd,
          "head-del": function ($event) {
            return _vm.rowRemove()
          },
        },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.handleCurrentChange,
          "page-size-change": _vm.handleSizeChange,
          "page-refresh-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectChange,
        },
        scopedSlots: _vm._u([
          {
            key: "recordCode",
            fn: function ({ row, index }) {
              return [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.rowView(row)
                      },
                    },
                  },
                  [_vm._v(_vm._s(row.recordCode))]
                ),
              ]
            },
          },
          {
            key: "customBtn",
            fn: function ({ row, index }) {
              return [
                row.eqRepairStatus == "0" &&
                row.createUser == _vm.userInfo.user_id
                  ? [
                      _vm.permissionList.editBtn
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.rowEdit(row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm.permissionList.delBtn
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.rowRemove(row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ]
                  : [
                      _vm.permissionList.viewBtn
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.rowView(row)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }