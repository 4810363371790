<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="title"
      :before-close="handleCancel"
    >
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="区域名称" prop="areaName">
          <el-input v-model="form.areaName" maxlength="100" placeholder="请输入区域名称" style="width: 100%;"></el-input>
        </el-form-item>
        <el-form-item label="区域编码" prop="areaCode">
          <!-- <el-radio-group v-model="form.areaRank">
            <el-radio v-for="item in area_rank" :key="item.dictKey" :label="item.dictKey">{{item.dictValue}}</el-radio>
          </el-radio-group> -->
          <el-input v-model="form.areaCode" maxlength="10" placeholder="请输入区域编码" style="width: 100%;"></el-input>
        </el-form-item>
        <el-form-item label="工作范围" prop="remark">
          <el-input
            type="textarea"
            v-model="form.remark"
            maxlength="200"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleCancel()"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { dictionaryBiz } from "@/api/reportTasks";
import { getAreaClassLinSubmit } from "@/api/safe/projectArea";
export default {
  data() {
    return {
      dialogVisible: false,
      title: "",
      form: {
        areaName: "",
        // areaRank: "",
        areaCode: "",
        remark: "",
        typeId: '',
      },
      rules: {
        areaName: [
          { required: true, message: "请输入区域名称", trigger: "blur" },
        ],
        areaCode: [
          { required: true, message: "请输入区域编码", trigger: "blur" },
        ],
      },
      dialogLoading: false,
      area_rank: [], // 风险等级列表
    };
  },

  methods: {
    init(type, data) {
      for (let key in this.form) {
        // console.log(key, key in data);
        if (key in data) {
          // this.form[key] = key=='areaRank' ? String(data[key]) : data[key];
          this.form[key] = data[key];
        } else {
          this.form[key] = "";
        }
      }
      this.title = type == "add" ? "新增区域" : "编辑区域";
      if (type == "edit") {
        this.form.id = data.id;
      }
      // if(type=='add') this.form.areaRank = '1'
      this.dialogVisible = true
      // dictionaryBiz("area_rank").then((res) => {
      //   this.area_rank = res.data.data;
      // });
    },

    handleCancel() {
      this.$refs.form.resetFields();
      this.dialogLoading = false;
      this.dialogVisible = false;
    },

    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          getAreaClassLinSubmit(this.form)
            .then((res) => {
              this.$message.success("操作成功");
              this.handleCancel();
              this.$emit("callback");
            })
            .catch(() => {
              this.dialogLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input {
  width: calc(100% - 120px);
}
.el-switch {
  margin-left: 10px;
}
</style>
