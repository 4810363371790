<template>
  <div style="height: 100%; width: 100%">
    <el-container style="position: relative">
      <!-- <CommonTree ref="commonTreeCore" treeTitle="组织列表" :searchTitle="searchTitle" :treeData="treeData1"
        :defaultProps="treeDfaultProps" @getNodeClick="handleNodeClick" :isShowdig="false" :showCheckbox="false"
        node-key="id" :isZoom="true" :show="show" @showChange="showChange" /> -->
      <CommonTreeNew
        ref="commonTreeCore"
        treeTitle="组织架构"
        :defaultProps="treeDfaultProps"
        :showCheckbox="false"
        @getNodeClick="handleNodeClick"
        :urlParmas="{
          tenantId: '',
          deptCategory: '2',
          parentId: userInfo.dept_id,
        }"
        node-key="id"
        :isShowdig="false"
        :isZoom="true"
        :show="show"
        @showChange="showChange"
      ></CommonTreeNew>

      <div style="height: 100%; width: 100%">
        <div class="safetyOrg-container">
          <div class="setting-tab">
            <el-tabs
              type="border-card"
              @tab-click="handleClick"
              v-model="activeName"
            >
              <el-tab-pane
                v-for="item in orgList"
                :key="item.id"
                :name="item.id"
                :label="item.soOrgName"
              ></el-tab-pane>
            </el-tabs>
            <!-- <div class="tab-warp">
              <div
                :class="
                  currentTab == item.id
                    ? 'tab-item tab-item-active'
                    : 'tab-item'
                "
                @click="handleTab(item)"
                v-for="item in orgList"
                :key="item.id"
              >
                <el-tooltip
                  class="el-tooltip-text"
                  effect="dark"
                  :content="item.soOrgName"
                  placement="top-start"
                >
                  <div>{{ item.soOrgName }}</div>
                </el-tooltip>
              </div>
            </div> -->
            <div @click="addOrgTab" class="tab-button">
              <el-button icon="el-icon-plus">新增</el-button>
            </div>
          </div>
          <div class="safetyOrg-container-setting">
            <div class="container-setting">
              <div class="setting-tool">
                <div class="setting-tool-title">组织架构图</div>
                <div class="setting-tool-button">
                  <el-tooltip
                    class="el-tooltip-text"
                    effect="dark"
                    content="删除组织"
                    placement="top-start"
                  >
                    <el-button
                      v-show="!isEditModel"
                      @click="deleteOrg"
                      icon="el-icon-close"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="el-tooltip-text"
                    effect="dark"
                    content="编辑架构图"
                    placement="top-start"
                  >
                    <el-button
                      v-show="!isEditModel"
                      @click="isEditModel = !isEditModel"
                      icon="el-icon-edit"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="el-tooltip-text"
                    effect="dark"
                    content="下载组织图"
                    placement="top-start"
                  >
                    <el-button
                      v-show="!isEditModel"
                      icon="el-icon-download"
                      @click="downloadOrgImg"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="el-tooltip-text"
                    effect="dark"
                    content="保存"
                    placement="top-start"
                  >
                    <el-button
                      v-show="isEditModel"
                      icon="el-icon-s-order"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="el-tooltip-text"
                    effect="dark"
                    content="退出"
                    placement="top-start"
                  >
                    <el-button
                      v-show="isEditModel"
                      @click="isEditModel = false"
                      icon="el-icon-right"
                    ></el-button>
                  </el-tooltip>
                </div>
              </div>
              <div class="setting-warp">
                <zm-tree-org
                  ref="orgTree"
                  :data="treeData"
                  :draggable="false"
                  :props="defaultProps"
                  :disabled="disaled"
                  :collapsable="collapsable"
                  :node-draggable="false"
                  :default-expand-level="1"
                  :only-one-node="onlyOneNode"
                  :clone-node-drag="cloneNodeDrag"
                  :node-draging="nodeDragMove"
                  :node-drag-end="nodeDragEnd"
                  :toolBar="toolBar"
                  :filterNodeMethod="filterNodeMethod"
                  :node-add="nodeAdd"
                  :node-edit="nodeEdit"
                  :node-delete="nodeDelete"
                  @on-contextmenu="onMenus"
                  @on-expand="onExpand"
                  :define-menus="defineMenus"
                >
                  <!-- 自定义节点内容 -->
                  <template slot-scope="{ node }">
                    <div class="org-warp">
                      <div class="org-name">
                        {{ node.nodeName }}
                      </div>
                      <div class="person-list" v-if="node.userNames">
                        <span>{{ node.userNames }}</span>
                      </div>
                      <div class="tool-list" v-if="isEditModel">
                        <i
                          style="cursor: pointer"
                          @click="nodeEdit(node)"
                          class="el-icon-edit"
                        ></i>
                        <i
                          style="margin-left: 5px; cursor: pointer"
                          @click="personSetting(node)"
                          class="el-icon-user"
                        ></i>
                      </div>
                    </div>
                  </template>
                  <!-- 自定义展开按钮 -->
                  <!-- <template v-slot:expand="{ node }">
         <div>{{ node.children.length }}</div>
       </template> -->
                </zm-tree-org>
              </div>
            </div>
            <div class="container-table">
              <div class="table-box mb-10">
                <personList :soOrgId="currentTab" ref="personList"></personList>
              </div>
              <div class="table-box">
                <safetyMeeting
                  :soOrgId="currentTab"
                  ref="safetyMeeting"
                ></safetyMeeting>
              </div>
            </div>
          </div>
          <el-dialog
            :title="orgDialogTitle"
            v-dialogDrag
            :visible.sync="orgDialogVisible"
            width="40%"
          >
            <el-form
              :model="orgForm"
              ref="orgForm"
              :rules="orgRules"
              label-width="100px"
            >
              <el-form-item label="组织名称" prop="soOrgName">
                <el-input
                  v-model="orgForm.soOrgName"
                  placeholder="请输入组织名"
                ></el-input>
              </el-form-item>
            </el-form>
            <template slot="footer">
              <el-button
                size="small"
                @click="handleOrgCancel"
                v-loading="orgDialogLoading"
              >
                {{ $t(`cip.cmn.btn.celBtn`) }}
              </el-button>
              <el-button
                size="small"
                type="primary"
                @click="handleOrgSave"
                v-loading="orgDialogLoading"
              >
                {{ $t(`cip.cmn.btn.defBtn`) }}
              </el-button>
            </template>
          </el-dialog>
          <el-dialog
            :title="nodeDialogTitle"
            v-dialogDrag
            :visible.sync="nodeDialogVisible"
            width="40%"
          >
            <el-form
              :model="nodeForm"
              ref="nodeForm"
              :rules="nodeRules"
              label-width="100px"
            >
              <el-form-item label="节点名称" prop="nodeName">
                <el-input
                  v-model="nodeForm.nodeName"
                  placeholder="请输入节点名称"
                ></el-input>
              </el-form-item>
            </el-form>
            <template slot="footer">
              <el-button
                size="small"
                @click="handleNodeCancel"
                v-loading="nodeDialogLoading"
              >
                {{ $t(`cip.cmn.btn.celBtn`) }}
              </el-button>
              <el-button
                size="small"
                type="primary"
                @click="handleNodeSave"
                v-loading="nodeDialogLoading"
              >
                {{ $t(`cip.cmn.btn.defBtn`) }}
              </el-button>
            </template>
          </el-dialog>
          <el-dialog
            :title="personDialogTitle"
            v-dialogDrag
            :visible.sync="personDialogVisible"
            width="40%"
            :before-close="handlePersonCancel"
          >
            <el-transfer
              ref="transferRef"
              filterable
              :filter-method="filterMethod"
              filter-placeholder="请输入搜索内容"
              :titles="['人员清单', '节点人员']"
              v-model="personListValue"
              :data="personData"
              :props="transferProps"
              :right-default-checked="rightDefaultChecked"
              @change="rightCheckChange"
            >
            </el-transfer>
            <template slot="footer">
              <el-button
                size="small"
                @click="handlePersonCancel"
                v-loading="personDialogLoading"
              >
                {{ $t(`cip.cmn.btn.celBtn`) }}
              </el-button>
              <el-button
                size="small"
                type="primary"
                @click="handlePersonSave"
                v-loading="personDialogLoading"
              >
                {{ $t(`cip.cmn.btn.defBtn`) }}
              </el-button>
            </template>
          </el-dialog>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import personList from "./personList.vue";
import safetyMeeting from "./safetyMeeting.vue";
import {
  getOrgList,
  orgSubmit,
  orgRemove,
  getNodeList,
  nodeSubmit,
  nodeRemove,
  getPersonList,
} from "@/api/responsibility/safetyOrg";
import { getPageCode } from "@/api/system/serialNumber";
import { platform } from "@/api/system/user";
import { mapGetters } from "vuex";
import html2canvas from "html2canvas";
import { getDeptTree } from "@/api/system/dept";
import CommonTree from "@/views/components/com_tree/index";
export default {
  components: {
    CommonTree,
    personList,
    safetyMeeting,
  },
  data() {
    return {
      nodeData: {},
      treeData1: [],
      searchTitle: "title",
      treeDfaultProps: {
        children: "children",
        label: "title",
      },
      orgList: [],
      currentTab: "",
      currentOrgName: "",
      isEditModel: false,
      toolBar: {
        scale: false,
      },
      defaultProps: {
        id: "id",
        pid: "parentId",
        label: "nodeName",
        expand: "expand",
        children: "children",
      },
      treeData: {},
      collapsable: true,
      onlyOneNode: false,
      cloneNodeDrag: false,
      expandAll: true,
      disaled: false,
      style: {
        background: "#fff",
        color: "#5e6d82",
      },
      orgDialogTitle: "",
      orgDialogVisible: false,
      orgDialogType: "",
      orgDialogLoading: false,
      orgForm: {
        soOrgName: "",
        soOrgCode: "",
        orgId: "",
      },
      orgRules: {
        soOrgName: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
        ],
      },
      nodeDialogTitle: "",
      nodeDialogVisible: false,
      nodeDialogType: "",
      nodeDialogLoading: false,
      nodeForm: {
        nodeName: "",
        soOrgId: "",
        orgId: "",
        parentId: "",
      },
      nodeRules: {
        nodeName: [
          { required: true, message: "请输入节点名称", trigger: "blur" },
        ],
      },
      currentNode: {},
      personDialogTitle: "",
      personDialogVisible: false,
      personDialogType: "",
      personDialogLoading: false,
      personData: [],
      personListValue: [],
      rightDefaultChecked: [],
      filterMethod(query, item) {
        return item.userName.indexOf(query) > -1;
      },
      transferProps: {
        key: "userId",
        label: "userName",
        disabled: false,
      },
      show: false,
      activeName: "",
    };
  },
  mounted() {
    // this.lazyDeptTree();
  },
  computed: {
    ...mapGetters(["userInfo"]),
    defineMenus() {
      return this.isEditModel
        ? [
            { name: "新增节点", command: "add" },
            { name: "编辑节点", command: "edit" },
            // { name: "人员配置", command: "personSetting" },
            { name: "删除节点", command: "delete" },
          ]
        : [];
    },
  },
  methods: {
    showChange(v) {
      this.show = v;
    },
    /** 左侧列表操作 */
    //获取树数据
    // lazyDeptTree() {
    //   const parentId = this.userInfo.dept_id;
    //   getDeptTree('', '', parentId).then((res) => {
    //       if (res.data.code == 200) {
    //           this.treeData1 = res.data.data
    //           if (res.data.data.length > 0) {
    //               this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(this.treeData1[0].id)
    //               this.handleNodeClick(this.treeData1[0])
    //           }
    //       }
    //   });
    // },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.getOrgListMethod(true);
    },

    getOrgListMethod(isInit = false) {
      getOrgList({ orgId: this.nodeData.id }).then((res) => {
        this.orgList = res.data.data;
        this.currentOrgName = this.orgList[0].soOrgName;
        this.activeName = this.orgList[0].id;
        this.currentTab = isInit
          ? res.data.data[0].id
          : res.data.data[res.data.data.length - 1].id;
        this.getNodeListMethod(
          this.orgList.find((item) => item.id === this.currentTab).imgUrl
        );
        this.getPersonListMethod();
        this.$refs.personList.initDate(this.currentTab);
        this.$refs.safetyMeeting.initDate(this.currentTab);
      });
    },
    addOrgTab() {
      this.orgDialogType = "addOrg";
      this.orgDialogTitle = "新增组织";
      this.orgDialogVisible = true;
      getPageCode({ ruleCode: "so_org_code" }).then((res) => {
        this.orgForm.soOrgCode = res.data.data;
      });
      this.orgForm.orgId = this.nodeData.id;
    },
    handleClick(e) {
      console.log("--------", e);
      this.activeName = e.name;
      let selectItem = this.orgList.find((item) => item.id === e.name);
      this.handleTab(selectItem);
    },
    handleTab(val) {
      this.currentTab = val.id;
      this.currentOrgName = val.soOrgName;
      this.getNodeListMethod(val.imgUrl);
      this.$refs.personList.initDate(this.currentTab);
      this.$refs.safetyMeeting.initDate(this.currentTab);
      this.getPersonListMethod();
    },
    deleteOrg() {
      if (this.currentOrgName == "安委会")
        return this.$message.warning("安委会组织不可删除！");
      this.$confirm(`确定删除名称为${this.currentOrgName}的安全组织?`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        orgRemove(this.currentTab).then(() => {
          this.getOrgListMethod();
          this.$message.success("操作成功！");
        });
      });
    },
    downloadOrgImg() {
      html2canvas(this.$refs.orgTree.$refs.zoom).then((canvas) => {
        // 创建一个图片元素
        var img = canvas.toDataURL("image/png");

        // 创建一个链接元素
        var link = document.createElement("a");
        link.href = img;
        // 设置下载的文件名
        link.download = this.currentOrgName + ".png";
        // 触发链接点击
        link.click();
      });
    },
    // 保存组织架构图
    saveOrgImg() {
      html2canvas(this.$refs.orgTree.$refs.zoom).then((canvas) => {
        let dataUrl = canvas.toDataURL("image/png");
        let blob = this.dataURLtoBlob(dataUrl);
        let formData = new FormData();
        let fileOfBlob = new File([blob], new Date().getTime() + ".jpg");
        formData.append("file", fileOfBlob);
        platform(formData).then((res) => {
          if (res.data.code == 200) {
            console.log("aaa", res.data.data);
            orgSubmit({ id: this.currentTab, imgUrl: res.data.data.link })
              .then((res) => {})
              .catch(() => {
                this.orgDialogLoading = false;
              });
          }
        });
      });
    },
    // 将base64转成blob 为了后续能上传
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(",");
      //注意base64的最后面中括号和引号是不转译的
      var _arr = arr[1].substring(0, arr[1].length - 2);
      var mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(_arr),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime,
      });
    },

    getNodeListMethod(isImgUrl) {
      // isImgUrl 字符串或者布尔值
      // 参数为当前组织中是否有imgUrl 没有则需要把当前组织下的组织架构图存到当前组织数据中
      // 参数为false代表节点增删改的操作
      getNodeList({
        soOrgId: this.currentTab,
        // orgId: this.userInfo.dept_id,
      }).then((res) => {
        this.treeData = res.data.data[0];
        if (!isImgUrl) {
          this.$nextTick(() => {
            this.saveOrgImg();
          });
        }

        this.toggleExpand(this.treeData, this.expandAll);
      });
    },
    onMenus({ node, command }) {
      // console.log(node, command);
      if (command == "personSetting") {
        this.personSetting(node);
      }
    },
    personSetting(node) {
      this.personDialogTitle = "节点人员配置";
      this.personDialogType = "personSetting";
      this.currentNode = node;
      this.personListValue = node.userIds ? node.userIds.split(",") : [];
      this.getPersonListMethod();
      this.personDialogVisible = true;
    },
    getPersonListMethod() {
      getPersonList({ soOrgId: this.currentTab }).then((res) => {
        this.personData = res.data.data;
      });
    },
    nodeAdd(node) {
      this.nodeDialogType = "addNode";
      this.nodeDialogTitle = "新增节点";
      this.nodeForm.parentId = node.id;
      this.nodeForm.soOrgId = node.soOrgId;
      this.nodeForm.orgId = node.orgId;
      this.nodeForm.nodeName = "";
      this.currentNode = node;
      this.nodeDialogVisible = true;
    },
    nodeEdit(node) {
      this.currentNode = node;
      this.nodeDialogType = "editNode";
      this.nodeDialogTitle = "编辑节点";
      for (let key in this.nodeForm) {
        this.nodeForm[key] = node[key];
      }
      this.nodeDialogVisible = true;
    },
    nodeDelete(node) {
      this.$confirm(`确定删除名称为${node.nodeName}的节点?`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        nodeRemove(node.id).then(() => {
          this.$message.success("操作成功！");
          this.getNodeListMethod(false); // 操作节点后需要更新当前组织下的组织架构图 所以参数为false代表接收参数isImgurl为空
        });
      });
    },
    onExpand(e, data) {
      // console.log(e, data);
    },
    nodeDragMove(data) {
      console.log(data);
    },
    nodeDragEnd(data, isSelf) {
      // console.log(data, isSelf);
      isSelf && this.$Message.info("移动到自身");
    },

    expandChange() {
      this.toggleExpand(this.treeData, this.expandAll);
    },
    toggleExpand(data, val) {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          this.$set(item, "expand", val);
          if (item.children) {
            this.toggleExpand(item.children, val);
          }
        });
      } else {
        this.$set(data, "expand", val);
        if (data.children) {
          this.toggleExpand(data.children, val);
        }
      }
    },
    handleOrgSave() {
      this.$refs.orgForm.validate((valid) => {
        if (valid) {
          orgSubmit(this.orgForm)
            .then((res) => {
              this.$message.success("操作成功");
              this.handleOrgCancel();
              this.getOrgListMethod();
            })
            .catch(() => {
              this.orgDialogLoading = false;
            });
        }
      });
    },
    handleOrgCancel() {
      this.$refs.orgForm.resetFields();
      this.orgDialogVisible = false;
    },
    handleNodeSave() {
      if (this.nodeDialogType == "editNode") {
        this.nodeForm.id = this.currentNode.id;
      } else {
        this.nodeForm.id = "";
      }
      this.$refs.nodeForm.validate((valid) => {
        if (valid) {
          nodeSubmit(this.nodeForm)
            .then((res) => {
              this.$message.success("操作成功");
              this.getNodeListMethod(false);
              this.handleNodeCancel();
              this.nodeDialogVisible = false;
            })
            .catch(() => {
              this.nodeDialogLoading = false;
            });
        }
      });
    },
    handleNodeCancel() {
      this.$refs.nodeForm.resetFields();
      this.nodeDialogVisible = false;
    },
    handlePersonSave() {
      let arr = this.personListValue.map((v) => {
        return {
          userId: v,
          userName: this.personData.find((item) => item.userId === v).userName,
        };
      });
      let params = {
        id: this.currentNode.id,
        userIds: arr.map((v) => v.userId).join(","),
        userNames: arr.map((v) => v.userName).join(","),
      };
      nodeSubmit(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("操作成功");
          this.getNodeListMethod(false);
          this.handlePersonCancel();
        }
      });
    },
    handlePersonCancel() {
      this.$refs.transferRef.clearQuery("left");
      this.$refs.transferRef.clearQuery("right");
      this.personDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-container {
  width: 100%;
  height: 100%;
}

.safetyOrg-container {
  width: 100%;

  height: 100%;

  .setting-tab {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background: #f5f7fa;
    ::v-deep .el-tabs{
      max-width: calc(100% - 83px);
    }
    .tab-warp {
      max-width: calc(100% - 20px);
      overflow-x: auto;
      display: flex;
      align-items: center;

      .tab-item {
        // min-width: 60px;
        width: 70px;
        padding: 5px 0;
        border: 1px solid #e8e8e8;
        margin-right: 5px;
        font-size: 14px;
        background-color: #fafafa;
        text-align: center;
        cursor: pointer;

        ::v-deep .el-tooltip {
          width: 100%;
        }

        .el-tooltip-text {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          white-space: nowrap; //文本不会换行
        }
      }

      .tab-item-active {
        background-color: #fff;
        color: #1990ff;
      }
    }
    .tab-button {
      margin-left: 10px;
      font-size: 14px;
      cursor: pointer;
    }
    ::v-deep .el-tabs__content {
      padding: 0;
    }
  }

  .safetyOrg-container-setting {
    display: flex;
    height: calc(100% - 40px);

    .container-setting {
      background-color: #fff;
      width: 54%;

      .setting-tool {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        padding: 4px 6px;

        .setting-tool-title {
          font-size: 16px;
          font-weight: 500;
        }
      }

      .setting-warp {
        height: calc(100% - 40.4px);
        position: relative;

        ::v-deep .zm-tree-org {
          box-sizing: border-box;
        }

        ::v-deep .zoom-container {
          display: flex;
          // align-items: center;
          justify-content: center;
        }

        .org-warp {
          background-color: #108fee;
          color: #fff;
          font-size: 14px;

          .org-name {
            padding: 5px 10px;
          }

          .person-list {
            border-top: 1px solid #ccc;
            padding: 5px 10px;
          }

          .tool-list {
            border-top: 1px solid #ccc;
            padding: 5px 10px;
            text-align: right;
            font-size: 16px;
          }
        }
      }
    }

    .container-table {
      margin-left: 10px;
      width: 45%;

      .mb-10 {
        margin-bottom: 10px;
      }

      .table-box {
        overflow-y: auto;
        height: calc(50% - 5px);
        background-color: white;
      }
    }
  }
}
</style>
