<template>
  <div class="container">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in tabsArr"
        :label="item.dictValue"
        :name="item.dictKey"
        :key="item.dictKey"
      ></el-tab-pane>
      <!-- <el-tab-pane label="环境" name="second"></el-tab-pane>
      <el-tab-pane label="非煤矿山" name="third"></el-tab-pane> -->
    </el-tabs>
    <div class="content flex-container">
      <!-- <CommonTree
        class="introduceTree"
        treeTitle="岗位工种/清单"
        node-key="id"
        isLeafIcons
        isShowdig
        risk
        :defaultExpandedKeys="[]"
        ref="commonTreeCore"
        @getTreeAdd="opDialog"
        @getTreeDelete="rowDel"
        @getTreeEdit="rowEdit"
        :treeData="tableData"
        :defaultProps="defaultProps"
        @getNodeClick="gridRowClick"
        :showCheckbox="false"
      /> -->

      <CommonTreeNew
        class="introduceTree"
        treeTitle="岗位工种/清单"
        node-key="id"
        isLeafIcons
        isShowdig
        risk
        :defaultExpandedKeys="[]"
        ref="commonTreeCore"
        @getTreeAdd="opDialog"
        @getTreeDelete="rowDel"
        @getTreeEdit="rowEdit"
        :treeData="tableData"
        :defaultProps="defaultProps"
        @getNodeClick="gridRowClick"
        :showCheckbox="false"
      />
      <div v-if="selectTree.postType == 2" class="center center-div">
        <div class="flex-content he-50">
          <head-layout
            :head-btn-options="selectTree.id ? topheadBtnOptions : []"
            head-title="岗位接害因素"
            @head-save="saveHazard"
          >
          </head-layout>
          <el-input
            style="margin-top: 10px"
            type="textarea"
            v-model="selectTree.hazard"
            :maxlength="255"
            rows="7"
            show-word-limit
          ></el-input>
        </div>
        <div class="flex-content he-50">
          <head-layout
            :head-btn-options="selectTree.id ? midheadBtnOptions : []"
            head-title="资质证书要求"
            @head-add="addCertifi"
            @head-save="saveCertifis"
          >
          </head-layout>
          <el-form
            label-width="80px"
            style="margin-top: 10px"
            v-for="(item, index) in certificateData"
            :key="index"
          >
            <el-form-item label="证书类型">
              <div class="form-content">
                <!-- <el-cascader
                      placeholder="请选择证件类型"
                      :disabled="type == 'view'"
                      v-model="item.cardType"
                      :options="certificateTypes"
                      :props="{
                        label: 'dictValue',
                        value: 'dictKey',
                      }"
                      @change="handleChangeType"
                    >
                    </el-cascader> -->
                <el-select
                  v-model="item.certificateCode"
                  placeholder="请选择证件类型"
                  @change="(val) => certificateChange(val, index)"
                >
                  <el-option
                    v-for="item in certificateTypes"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
                <div class="btn" style="display: flex">
                  <el-button
                    @click="deleteCertifi(item, index)"
                    type="text"
                    class="spacing-l-10"
                    >删除
                  </el-button>
                  <el-button
                    @click="saveCertifi(item, index)"
                    type="text"
                    class="spacing-l-10 spacing-r-10"
                    >保存
                  </el-button>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex-content he-50" style="margin-bottom: 0">
          <head-layout
            :head-btn-options="selectTree.id ? botheadBtnOptions : []"
            head-title="安全培训及考试"
            @head-add="addStpost"
            @head-save="saveStposts"
          >
          </head-layout>
          <el-form
            label-width="80px"
            style="margin-top: 10px"
            v-for="(item, index) in stpostData"
            :key="index"
          >
            <el-form-item label="培训课程">
              <div class="form-content">
                <el-input
                  v-model="item.className"
                  placeholder="请选择培训课程"
                  @focus="opTrainDialog(index)"
                >
                </el-input>
                <div class="btn" style="display: flex">
                  <el-button
                    @click="deleteStpost(item, index)"
                    type="text"
                    class="spacing-l-10"
                    >删除
                  </el-button>
                  <el-button
                    @click="saveStpost(item, index)"
                    type="text"
                    class="spacing-l-10 spacing-r-10"
                    >保存
                  </el-button>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div
        v-if="selectTree.postType == 2"
        style="width: calc(100% - 40% - 280px); height: 100%; background: #fff"
      >
        <head-layout
          :head-btn-options="selectTree.id ? rightheadBtnOptions : []"
          head-title="风险告知卡"
          @head-add="cardAdd"
        >
        </head-layout>
        <el-row
          style="height: calc(100% - 60px); overflow: auto; padding: 10px"
        >
          <el-col :span="24" v-for="(item, index) in cardList" :key="item.id">
            <el-card
              :body-style="{ padding: '10px' }"
              :class="
                item.selected ? 'list-card-selected list-card' : 'list-card'
              "
              style="margin-bottom: 20px"
            >
              <div class="content" @click="handleItemClick(index, item)">
                <el-image
                  style="width: 100%; height: 200px"
                  :src="item.picUrl"
                  @click.stop="showFile(item.picUrl)"
                  fit="fit"
                >
                  <div
                    slot="error"
                    class="image-slot"
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <i
                      class="el-icon-picture-outline"
                      style="font-size: 20px"
                    ></i>
                    <div style="text-align: center; margin-top: 10px">
                      暂无图片
                    </div>
                  </div>
                </el-image>

                <el-divider class="el-divider"></el-divider>
                <div class="bottom">
                  <el-tooltip placement="top" :content="item.cardName">
                    <div class="name">{{ item.cardName }}</div>
                  </el-tooltip>
                  <div class="btn">
                    <i
                      class="el-icon-view"
                      style="margin-right: 8px; cursor: pointer"
                      @click.stop="viewItem(item)"
                    ></i>
                    <i
                      class="el-icon-download"
                      style="margin-right: 8px; cursor: pointer"
                      @click.stop="downloadItem(item)"
                    ></i>
                    <i
                      class="el-icon-edit"
                      style="margin-right: 8px; cursor: pointer"
                      @click.stop="editItem(item)"
                    ></i>
                    <i
                      class="el-icon-delete"
                      @click.stop="removeList(item)"
                      style="cursor: pointer"
                    ></i>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <el-empty
        v-if="!selectTree.postType || selectTree.postType == 1"
        style="width: 100%"
        :image-size="200"
        description="请点击左侧岗位查询"
      >
      </el-empty>
    </div>
    <el-dialog
      title="岗位/工种"
      width="30%"
      :visible.sync="visible"
      v-if="visible"
      class="avue-dialog"
    >
      <avue-form
        ref="avueForm"
        :option="jcxflOption"
        @submit="handleSave"
        v-model="form"
      >
      </avue-form>
      <div class="avue-dialog__footer">
        <el-button
          size="small"
          @click="visible = false"
          v-loading="saveLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="$refs.avueForm.submit()"
          v-loading="saveLoading"
        >
          保存
        </el-button>
      </div>
    </el-dialog>
    <Training ref="training" @getSelectRow="getSelectRow"></Training>
    <CardAdd ref="cardAdd" @getStatus="getCardList"></CardAdd>
    <el-dialog
      v-dialog-drag
      title="风险告知卡预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { getDictionaryCode } from "@/api/system/dictbiz";
import Training from "@/views/business/safetyTtandard/occupationConfig/training.vue";
import CardAdd from "@/views/business/safetyTtandard/occupationConfig/add.vue";
import {
  jobSubmit,
  getCode,
  jobList,
  jobRemove,
  jobDetail,
  cateSubmit,
  cateList,
  cateSubmitBatch,
  cateRemove,
  cateDetail,
  stpostList,
  stpostSubmit,
  stpostRemove,
  stpostSubmitBatch,
  stpostDetail,
  cardList,
  cardRemove,
} from "@/api/safetyTtandard/occupationConfig.js";
import CommonTree from "@/views/components/com_tree/index.vue";
import Template from "@/views/message/template/list.vue";

export default {
  components: {
    Template,
    CommonTree,
    HeadLayout,
    GridLayout,
    Training,
    CardAdd,
  },
  computed: {
    jcxflOption() {
      return {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            disabled: this.form.id ? true : false,
            display: this.form.parentId != -1,
            span: 24,
            labelWidth: 200,
            label: "类型",
            prop: "postType",
            type: "radio",
            dicData: [
              {
                label: "分类",
                value: 1,
              },
              {
                label: "岗位",
                value: 2,
              },
            ],
          },
          {
            span: 24,
            props: this.defaultProps,
            dicData: this.treeData,
            type: "tree",
            labelWidth: 200,
            clearable: true,
            label: "上级岗位分类",
            prop: "parentId",
          },
          {
            span: 24,
            labelWidth: 200,
            label: "岗位/工种名称",
            prop: "postName",
            rules: [
              {
                required: true,
                message: "请输入岗位/工种名称",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
  },
  data() {
    return {
      showFileDialog: false,
      cardList: [],
      tabsArr: [],
      isAuto: true,
      leftheadBtnOptions: [
        {
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
      ],
      topheadBtnOptions: [
        {
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        },
      ],
      midheadBtnOptions: [
        {
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        },
      ],
      rightheadBtnOptions: [
        {
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
      ],
      botheadBtnOptions: [
        {
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        },
      ],
      certificateTypes: [],
      certificateData: [],
      visible: false,
      tableData: [],
      defaultProps: {
        children: "children",
        value: "id",
        label: "postName",
      },
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-edit",
          remark: "edit",
          type: "text",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          remark: "remove",
          type: "text",
          icon: "",
        },
      ],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      activeName: "1",
      sectionName: "",
      tableLoading: false,
      type: "add",
      form: {
        postType: 1,
        parentId: -1,
        postName: "",
        id: "",
      },
      saveLoading: false,
      selectTree: {},
      stpostData: [],
      rules: {
        postCode: [
          { required: true, message: "请输入或生成岗位编码", trigger: "blur" },
        ],
        postName: [
          {
            required: true,
            message: "请输入岗位名称",
            trigger: "blur",
          },
        ],
      },
      stpostIndex: -1,
      certificateMaps: {},
    };
  },
  created() {
    // tabs映射
    getDictionaryCode("MD_SECTION").then((res) => {
      this.tabsArr = res.data.data;
      if (this.tabsArr.length) {
        this.sectionName = this.tabsArr[0].dictValue;
        this.getJobList();
      }
    });
    // getTreeData().then((res) => {
    //   this.certificateTypes = res.data.data;
    // });
    getDictionaryCode("certificate_type").then((res) => {
      this.certificateTypes = res.data.data;
      this.certificateTypes.map((item) => {
        this.$set(this.certificateMaps, item.dictKey, item.dictValue);
      });
    });
  },
  mounted() {},
  methods: {
    // 打开
    opTrainDialog(index) {
      this.stpostIndex = index;
      this.$refs.training.init();
    },
    downloadPic(url, name) {
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob);
          let Link = document.createElement("a");
          Link.download = name;
          Link.href = url;
          Link.click();
          Link.remove();
          // 用完释放URL对象
          URL.revokeObjectURL(url);
        });
      };
    },
    // 下载
    downloadItem(item) {
      this.downloadPic(item.picUrl, item.cardName);
    },

    // tabs切换
    handleClick() {
      this.tabsArr.map((item) => {
        if (item.dictKey == this.activeName) {
          this.sectionName = item.dictValue;
        }
      });
      this.selectTree = {};
      this.stpostData = [];
      this.certificateData = [];
      this.cardList = [];
      // console.log(this.sectionName, this.activeName);
      this.getJobList();
    },
    // 新增岗位工种
    opDialog(row) {
      if (this.selectTree.postType == 2)
        return this.$message.error("当前节点为岗位分类节点，不可新增节点");
      this.$set(this, "form", {
        sectionId: this.activeName,
        postType: this.selectTree.postType || 1,
        parentId: this.selectTree.id || -1,
      });
      this.treeData = this.tableData;
      this.visible = true;
    },
    gridRowClick(row) {
      this.selectTree = row;
      // this.form = row;
      // 执行右侧数据的请求
      this.getCateList();
      this.getStpostList();
      this.getCardList();
    },
    // 保存岗位接害因素
    saveHazard() {
      if (this.selectTree.postType == 1)
        return this.$message.error("岗位接害因素只能保存在岗位节点");
      jobSubmit({
        ...this.selectTree,
      })
        .then((res) => {
          if ((res.data.code = 200)) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
          }
          this.saveLoading = false;
          this.visible = false;
          this.getJobList();
        })
        .catch((error) => {
          this.$message({
            message: "保存失败",
            type: "warning",
          });
          this.saveLoading = false;
          this.visible = false;
        });
    },
    convertData(data) {
      // 如果传入的数据为空或不是数组类型，则返回空数组
      if (!data || !Array.isArray(data)) {
        return [];
      }

      // 将子节点转换为目标格式，并存储到新数组中
      const result = data.map((item) => {
        return {
          postType: item.postType,
          parentId: item.parentId,
          postName: item.postName,
          id: item.id,
          isSource: item.postType == 2 ? true : false, //这里的item根据自己的业务进行改变
          children: this.convertData(item.children), // 递归调用自身处理所有子孙节点
        };
      });
      return result;
    },
    getJobList() {
      let parmas = {
        sectionId: this.activeName,
        sectionName: this.sectionName,
      };
      this.selectTree = {};
      console.log(this.sectionName);
      this.tableLoading = true;
      jobList(parmas)
        .then((res) => {
          this.tableData = this.convertData(res.data.data);
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    // 编辑
    rowEdit(row) {
      this.$set(this, "form", {
        postType: row.postType,
        parentId: row.parentId,
        postName: row.postName,
        id: row.id,
      });
      const data = JSON.parse(JSON.stringify(this.tableData));
      this.treeData = this.getParentData(data);
      this.visible = true;
    },
    getParentData(data, id) {
      data.forEach((val, index) => {
        if (val.id == this.form.id || val.postType == 2) {
          data.splice(index, 1);
        } else if (val.children) {
          this.getParentData(val.children, this.form.id);
        }
      });
      return data;
    },
    // 保存列表数据
    handleSave(form, done) {
      this.saveLoading = true;
      jobSubmit({
        ...this.form,
        sectionId: this.activeName,
        sectionName: this.sectionName,
      })
        .then((res) => {
          if ((res.data.code = 200)) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
          }
          this.saveLoading = false;
          this.visible = false;
          this.getJobList();
        })
        .catch((error) => {
          this.$message({
            message: "保存失败",
            type: "warning",
          });
          done();
          this.saveLoading = false;
          this.visible = false;
        });
    },
    // 删除
    rowDel(row) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          jobRemove({ ids: row.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getJobList(this.page);
            }
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });

      //  e.id
    },
    // 获取资质证书列表
    getCateList() {
      cateList({
        postId: this.selectTree.id,
      }).then((res) => {
        this.certificateData = res.data.data;
      });
    },
    // saveCertifis批量保存
    saveCertifis() {
      if (this.selectTree.postType == 1)
        return this.$message.error("资质证书要求只能保存在岗位节点");
      if (!this.certificateData.length) {
        this.$message({
          message: "请新增数据",
          type: "error",
        });
        return false;
      }
      let flag = true;
      this.certificateData.map((item) => {
        if (!item.certificateName) {
          flag = false;
        }
      });
      if (!flag) {
        this.$message({
          message: "请填充完列表数据再保存",
          type: "error",
        });
        return false;
      }
      cateSubmitBatch(this.certificateData)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getCateList();
          } else {
            this.$message({
              message: "保存失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.$message({
            message: "保存失败",
            type: "error",
          });
        });
    },
    // 单个保存数据
    saveCertifi(item, index) {
      if (this.selectTree.postType == 1)
        return this.$message.error("资质证书要求只能保存在岗位节点");
      console.log(this.certificateData, index);
      if (this.certificateData[index].certificateName) {
        cateSubmit({ ...this.certificateData[index] })
          .then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.getCateList();
            } else {
              this.$message({
                message: "保存失败",
                type: "error",
              });
            }
          })
          .catch((error) => {
            this.$message({
              message: "保存失败",
              type: "error",
            });
          });
      } else {
        this.$message({
          message: "请选择当前行的资质证书",
          type: "warning",
        });
      }
    },
    // 添加一行资质证书
    addCertifi() {
      if (this.selectTree.postType == 1)
        return this.$message.error("资质证书要求只能保存在岗位节点");
      this.certificateData.push({ certificateCode: "", certificateName: "" });
    },
    // 删除一行资质证书 区分两种情况，一种是未保存的数据,一种是已经存在数据库的数据
    deleteCertifi(item, index) {
      // 这种需要调用接口+本地删除
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (item.id) {
            cateRemove({ ids: item.id }).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.certificateData.splice(index, 1);
              this.getCateList();
            });
          } else {
            this.certificateData.splice(index, 1);
          }
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    // 选中值改变的时候填入postId和certificateName
    certificateChange(val, index, item) {
      this.certificateData[index].certificateName = this.certificateMaps[val];
      if (this.selectTree.id) {
        this.certificateData[index].postId = this.selectTree.id;
      }
      console.log(this.certificateData, "certificateData");
    },

    // 添加一行培训课程
    addStpost() {
      if (this.selectTree.postType == 1)
        return this.$message.error("安全培训及考试只能保存在岗位节点");
      this.stpostData.push({ classId: "", className: "" });
    },
    getSelectRow(row) {
      if (this.stpostIndex != -1) {
        let obj = {
          classId: row.id,
          className: row.courseName,
          postId: this.selectTree.id,
        };
        // this.stpostData[this.stpostIndex] = obj;
        this.$set(this.stpostData, this.stpostIndex, obj);
      }
      console.log(this.stpostData, "stpostData");
    },
    // 删除一行培训课程
    deleteStpost(item, index) {
      // 这种需要调用接口+本地删除
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (item.id) {
            stpostRemove({ ids: item.id }).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.stpostData.splice(index, 1);
              this.getStpostList();
            });
          } else {
            this.stpostData.splice(index, 1);
          }
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    // 批量保存
    saveStposts() {
      if (this.selectTree.postType == 1)
        return this.$message.error("安全培训及考试只能保存在岗位节点");
      if (!this.stpostData.length) {
        this.$message({
          message: "请新增数据",
          type: "error",
        });
        return false;
      }
      let flag = true;
      this.stpostData.map((item) => {
        if (!item.className) {
          flag = false;
        }
      });
      if (!flag) {
        this.$message({
          message: "请填充完列表数据再保存",
          type: "error",
        });
        return false;
      }
      stpostSubmitBatch(this.stpostData)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getCateList();
          } else {
            this.$message({
              message: "保存失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.$message({
            message: "保存失败",
            type: "error",
          });
        });
    },
    // 单个保存
    saveStpost(item, index) {
      if (this.stpostData[index].className) {
        stpostSubmit({ ...this.stpostData[index] })
          .then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.getCateList();
            } else {
              this.$message({
                message: "保存失败",
                type: "error",
              });
            }
          })
          .catch((error) => {
            this.$message({
              message: "保存失败",
              type: "error",
            });
          });
      } else {
        this.$message({
          message: "请选择当前行的课程",
          type: "warning",
        });
      }
    },
    // 获取培训课程列表
    getStpostList() {
      stpostList({
        postId: this.selectTree.id,
      }).then((res) => {
        this.stpostData = res.data.data;
      });
    },
    // 新增告知卡
    cardAdd() {
      if (this.selectTree.postType == 1)
        return this.$message.error("风险告知卡只能保存在岗位节点");
      this.$refs.cardAdd.init("add", { postId: this.selectTree.id });
    },
    editItem(item) {
      this.$refs.cardAdd.init("edit", item);
    },
    viewItem(item) {
      this.$refs.cardAdd.init("view", item);
    },
    getCardList() {
      cardList({ postId: this.selectTree.id }).then((res) => {
        this.cardList = res.data.data;
      });
    },
    showFile(link) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    removeList(item) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cardRemove({ ids: item.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getCardList();
            }
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  // background: #fff;
  height: 100%;

  .center {
    display: flex;
    flex-direction: column;
  }
}
.introduceTree ::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 290px) !important;
}
.introduceTree {
  width: 280px;
}
.content {
  height: calc(100% - 70px);

  el-row {
    height: 100%;
  }
}
::v-deep .el-tabs__content {
  padding: 0 !important;
}
.center-div {
  height: 100%;
  width: calc(100% - 40% - 280px);
  margin: 0 20px;
}

.form-content {
  display: flex;
}

.main-content {
  background-color: #fff;
  widows: 100%;
  height: 100%;
}

.list-empty {
  padding: 10px 5px 10px 10px;
  height: calc(100% - 164px);
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep.list-card .el-card__body {
  padding: 10px !important;
}

.spacing-l-10 {
  margin-left: 10px;
}

.spacing-r-10 {
  margin-right: 10px;
}

.flex-content {
  background: #fff;
  margin-bottom: 10px;
  padding: 0 10px 10px 10px;
}
.he-50 {
  overflow: scroll;
  height: 33%;
}
.list {
  padding: 10px 5px 10px 10px;
  height: calc(100% - 164px);
  overflow-y: scroll;
  overflow-x: hidden;

  .list-card {
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 10px;
    border: 1px solid transparent !important;

    .name {
      text-align: center;
      white-space: nowrap; /* 确保文本在一行内显示 */
      overflow: hidden; /* 隐藏超出容器的文本 */
      text-overflow: ellipsis; /* 使用省略符号表示被截断的文本 */
    }

    .el-divider {
      width: calc(100% + 20px);
      transform: translateX(-10px);
    }

    .bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        margin-left: 10px;
        flex-shrink: 0;
      }
    }
  }
}
</style>
