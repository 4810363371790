var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center",
      attrs: {
        title: "配置表单",
        "close-on-click-modal": false,
        "append-to-body": "",
        visible: _vm.visible,
        "lock-scroll": "",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "70px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "弹窗标题" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入弹窗标题" },
                model: {
                  value: _vm.dataForm.popupTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "popupTitle", $$v)
                  },
                  expression: "dataForm.popupTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "弹窗类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择弹窗类型" },
                  model: {
                    value: _vm.dataForm.popupType,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "popupType", $$v)
                    },
                    expression: "dataForm.popupType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "居中弹窗", value: "dialog" },
                  }),
                  _c("el-option", {
                    attrs: { label: "右侧弹窗", value: "drawer" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "弹窗宽度" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择弹窗宽度" },
                  model: {
                    value: _vm.dataForm.popupWidth,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "popupWidth", $$v)
                    },
                    expression: "dataForm.popupWidth",
                  },
                },
                _vm._l(_vm.popupWidthOptions, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "远端数据", prop: "interfaceName" } },
            [
              _c("interface-dialog", {
                attrs: {
                  value: _vm.dataForm.interfaceId,
                  title: _vm.dataForm.interfaceName,
                },
                on: { change: _vm.onInterfaceChange },
              }),
            ],
            1
          ),
          _c("el-form-item", {
            staticStyle: { "margin-bottom": "0" },
            attrs: { label: "参数设置" },
          }),
          _c(
            "el-table",
            {
              staticStyle: { "margin-bottom": "18px" },
              attrs: { data: _vm.dataForm.templateJson },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "50",
                  label: "序号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "field", label: "参数名称", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "required-sign" }, [
                          _vm._v(_vm._s(scope.row.required ? "*" : "")),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.fieldName
                                ? scope.row.field +
                                    "(" +
                                    scope.row.fieldName +
                                    ")"
                                : scope.row.field
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "value", label: "表单字段" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择表单字段",
                              clearable: "",
                              filterable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onRelationFieldChange(
                                  $event,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value: scope.row.relationField,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "relationField", $$v)
                              },
                              expression: "scope.row.relationField",
                            },
                          },
                          _vm._l(_vm.formFieldsOptions, function (item) {
                            return _c("el-option", {
                              key: item.__vModel__,
                              attrs: {
                                label: item.__config__.label
                                  ? item.__vModel__ +
                                    "(" +
                                    item.__config__.label +
                                    ")"
                                  : item.__vModel__,
                                value: item.__vModel__,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-form-item", {
            staticStyle: { "margin-bottom": "0" },
            attrs: { label: "设置列表字段", "label-width": "100px" },
          }),
          _c(
            "el-table",
            { attrs: { data: _vm.dataForm.columnOptions } },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "50",
                  label: "序号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "field", label: "列名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "请输入列名" },
                          model: {
                            value: scope.row.label,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "label", $$v)
                            },
                            expression: "scope.row.label",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "value", label: "字段" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "请输入字段" },
                          model: {
                            value: scope.row.value,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "value", $$v)
                            },
                            expression: "scope.row.value",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "SNOWS-table-delBtn",
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDel(
                                  scope.$index,
                                  "columnOptions"
                                )
                              },
                            },
                          },
                          [_vm._v("删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "table-actions",
              staticStyle: { "margin-bottom": "18px" },
              on: { click: _vm.addColumn },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "text", icon: "el-icon-plus" } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c("el-form-item", {
            staticStyle: { "margin-bottom": "0" },
            attrs: { label: "设置关联字段", "label-width": "100px" },
          }),
          _c(
            "el-table",
            { attrs: { data: _vm.dataForm.relationOptions } },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "50",
                  label: "序号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "field", label: "目标表单字段" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择表单字段",
                              filterable: "",
                            },
                            model: {
                              value: scope.row.field,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "field", $$v)
                              },
                              expression: "scope.row.field",
                            },
                          },
                          _vm._l(_vm.childList, function (item) {
                            return _c("el-option", {
                              key: item.__vModel__,
                              attrs: {
                                label: item.__config__.label,
                                value: item.__vModel__,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "映射来源", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择映射来源" },
                            on: {
                              change: function ($event) {
                                return _vm.onTypeChange(scope.row)
                              },
                            },
                            model: {
                              value: scope.row.type,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "type", $$v)
                              },
                              expression: "scope.row.type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "接口字段", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "固定值", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "value", label: "映射值/业务对象" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 1
                          ? _c("el-input", {
                              attrs: { placeholder: "请输入接口字段" },
                              model: {
                                value: scope.row.value,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "value", $$v)
                                },
                                expression: "scope.row.value",
                              },
                            })
                          : _c("el-input", {
                              attrs: { placeholder: "请输入固定值" },
                              model: {
                                value: scope.row.value,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "value", $$v)
                                },
                                expression: "scope.row.value",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "SNOWS-table-delBtn",
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDel(
                                  scope.$index,
                                  "relationOptions"
                                )
                              },
                            },
                          },
                          [_vm._v("删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "table-actions",
              staticStyle: { "margin-bottom": "18px" },
              on: { click: _vm.addRelationOption },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "text", icon: "el-icon-plus" } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.cancelButton")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("common.confirmButton")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }