<template>
    <div class="one-job-container">
        <p class="one-job-title">一岗双责执行统计综合展示
            <el-select v-model="checkedProject" @change="checkChange">
                <el-option v-for="item in projectList" :key="item.id" :label="item.title" :value="item.id"></el-option>
            </el-select>
        </p>
        <div class="inspection-container item-container">
            <p class="chart-title">
                安全巡检执行统计
                <span>近一个月<i class="el-icon-more" @click="openDialog('inspectionDialog')"></i></span>
            </p>
            <div class="chart-count">巡检总数：{{hiddenDangerObj.sum}}次，<span class="green">隐患整改已完成：{{hiddenDangerObj.finishNum}}项</span></div>
            <div class="chart-container" id="inspection-chart-main"></div>
        </div>
        <div class="one-job-bottom">
            <div class="work-permit-container item-container">
                <p class="chart-title">
                    作业许可证执行统计
                    <span>近一个月<i class="el-icon-more" @click="openDialog('workDialog')"></i></span>
                </p>
                <div class="chart-count">作业许可证审批总数：{{workTicketObj.totalCount}}次，<span class="yellow">超期处理：{{workTicketObj.timeoutCount}}项</span></div>
                <div class="chart-container" id="work-permit-chart-main"></div>
            </div>
            <div class="meeting-container item-container">
                <p class="chart-title">
                    班前会及交底执行统计
                    <span>近一周<i class="el-icon-more" @click="openDialog('meetingDialog')"></i></span>
                </p>
                <div class="chart-count">参与班前会：{{beforeClassObj.sum}}次，<span class="red">未达标人数：{{beforeClassObj.unstandards}}人</span></div>
                <div class="chart-container" id="meeting-chrat-main"></div>
            </div>
        </div>
        <inspectionDialog ref="inspectionDialog" :checkedProject="checkedProject"></inspectionDialog>
        <workDialog ref="workDialog" :checkedProject="checkedProject"></workDialog>
        <meetingDialog ref="meetingDialog" :checkedProject="checkedProject"></meetingDialog>
    </div>
</template>
<script>
import inspectionDialog from './inspectionDialog.vue'
import workDialog from './workDialog.vue'
import meetingDialog from './meetingDialog.vue'
import {hiddenDanger,workTicketChart,beforeClassChart} from '@/api/safetyAndEnvironmentalProtection/oneJob'
import {getDeptTree} from '@/api/system/dept'
import {mapGetters} from "vuex";
export default{
    components: {inspectionDialog,workDialog,meetingDialog},
    computed:{
        ...mapGetters(["userInfo"]),
    },
    data(){
        return {
            basicOption:{
                grid: {
                    top: '20px',
                    left: '30px',
                    bottom:'20px',
                    right:0
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                        color: ['#E8E8E8']
                        }
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                yAxis: {
                    type: 'value',
                    // min:3,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                },
                tooltip: {
                    show:true,
                    formatter:'{a0}: {c0}<br />{a1}: {c1}',
                    trigger:'axis'
                },
                series:[{
                    data: [],
                    type: 'bar',
                    barWidth: 25,
                },{
                    data: [],
                    type: 'bar',
                    barWidth: 25,
                }]
            },
            hiddenDangerObj:{},
            workTicketObj:{},
            beforeClassObj:{},
            projectList:[],
            checkedProject:''
        }
    },
    methods:{
        checkChange(){
            this.getWorkChart()
            this.gethiddenDanger()
            this.getBeforeClassChart()
        },
        getProject(){
            getDeptTree('',5,this.userInfo.parent_dept_id!=0?this.userInfo.dept_id:'').then(res=>{
                this.projectList = res.data.data
                this.checkedProject = this.projectList[0].id
                this.checkChange()
            })
        },
        getWorkChart(){
            workTicketChart({orgId:this.checkedProject}).then(res=>{
                let data = res.data.data
                this.workTicketObj = data
                var myChart = this.$echarts.init(
                    document.getElementById("work-permit-chart-main")
                );
                var option = JSON.parse(JSON.stringify(this.basicOption));
                option.xAxis.data = data.detailList.map(item=>{return item.approverName})
                option.series[0].data = data.detailList.map(item=>{return item.approveCount})
                option.series[1].data = data.detailList.map(item=>{return item.timeoutCount})
                option.series[0].name = '审批总数'
                option.series[1].name = '审批超时'
                option.color = ['rgb(0, 172, 248)','rgb(255,116,0)']
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        gethiddenDanger(){
            hiddenDanger({orgId:this.checkedProject}).then(res=>{
                let data = res.data.data
                this.hiddenDangerObj = data
                var myChart = this.$echarts.init(
                    document.getElementById("inspection-chart-main")
                );
                var option = JSON.parse(JSON.stringify(this.basicOption));
                option.xAxis.data = data.reportList.map(item=>{return item.reportUserName})
                option.series[0].data = data.reportList.map(item=>{return item.sum || 0})
                option.series[1].data = data.reportList.map(item=>{return item.finishNum || 0})
                option.series[0].name = '安全巡检'
                option.series[1].name = '隐患整改完成'
                option.color = ['rgb(116, 142, 222)','rgb(0,211,73)']
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        getBeforeClassChart(){
            beforeClassChart({orgId:this.checkedProject}).then(res=>{
                let data = res.data.data
                this.beforeClassObj = data
                var myChart = this.$echarts.init(
                    document.getElementById("meeting-chrat-main")
                );
                var option = JSON.parse(JSON.stringify(this.basicOption));
                option.xAxis.data = data.beforeClassUserVOList.map(item=>{return item.userName})
                option.yAxis.minInterval = 1
                option.color = ['rgb(255,0,0)']
                option.tooltip.formatter = '{b}<br/>{a}: {c}'
                option.series = [{
                    data: data.beforeClassUserVOList.map(item=>{return item.sum}),
                    type: 'bar',
                    barWidth: 25,
                    name:'班前会管理',
                    markLine:{
                        silent:true,
                        label:{
                            show:true,
                            position:'insideEndTop',
                            formatter(){
                                return '达标值：1次'
                            }
                        },
                        data:[{
                             yAxis:1
                        }]
                    }
                }]
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        openDialog(key){
            this.$refs[key].init()
        }
    },
    mounted(){
        this.getProject()
    },
}
</script>
<style scoped lang="scss">
    p{
        margin: 0;
        padding: 0;
    }
    .one-job-title{
        font-size: 20px;
        margin-bottom: 10px;
    }
    .one-job-bottom{
        display: flex;
        justify-content: space-around;
        .item-container{
            flex: 1;
            height: 100%;
            &+.item-container{
                margin-left: 16px;
            }
        }
    }
    .inspection-container,.one-job-bottom{
        height: calc((100% - 167px) / 2);
    }
    .item-container{
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
        margin-bottom:16px;
        padding:26px;
        .chart-title{
            font-size: 18px;
            text-align: center;
            margin-bottom: 20px;
            span{
                float: right;
                i{
                    display: inline-block;
                    margin-left:10px;
                    cursor: pointer;
                }
            }
        }
        .chart-count{
            span{

            }
        }
        .chart-container{
            height: calc(100%  - 85px);
            margin-top: 10px;
        }
    }
    .green{
        color: rgb(0,211,73);
    }
    .yellow{
        color: rgb(255,116,0);
    }
    .red{
        color: rgb(255,0,0);
    }
    .el-select{
        width: 100px!important;
    }
</style>