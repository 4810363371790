<template>
  <div style="position: relative">
    <div>
      <head-layout head-title="相关方单位" :head-btn-options="headBtnOptions" @head-add="handleAdd" @head-import="handleImport" @head-export="handleExport"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template #customBtn="{row}">
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowUpdate(row)">编辑
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel(row)">删除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <addThirdCompany ref="addThirdCompany" @callback='onLoad(page,{})'></addThirdCompany>
    <el-dialog title="相关方公司导入" append-to-body :visible.sync="excelBox" width="555px">
      <div class="import">
        <avue-form :option="excelOption" v-model="excelOption.excelForm" :upload-after="uploadAfter">
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{
                  $t("cip.plat.sys.user.field.download")
                }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getList,
    getDetail,
    add,
    update,
    remove
  } from "@/api/safe/thirdcompany";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import addThirdCompany from './addThirdCompany'
  import {
    exportBlob
  } from "@/api/common";
  import {
    dateNow
  } from "@/util/date";
  import {
    getToken
  } from "@/util/auth";
  import {
    downloadXls
  } from "@/util/util";

  export default {
    components: {
      addThirdCompany,
      GridLayout,
      HeadLayout,
    },
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0
        },
        selectionList: [],
        tableOption: {
          linklabel: "companyName",
          column: [{
              label: "统一社会信用代码",
              prop: "companyCode",
              align: "center",
            },
            {
              label: "公司名称",
              prop: "companyName",
              align: "center",
            },
            {
              label: "法定代表人",
              prop: "legalRep",
              align: "center",
            },
            {
              label: "详细位置",
              prop: "regLocation",
              align: "center",
            },
            {
              label: "成立日期",
              prop: "curDate",
              align: "center",

            },
            {
              label: "启用",
              prop: "isUsed",
              dataType: "number",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
              align: "center",
            },
          ]

        },
        headBtnOptions: [{
            label: "新增",
            emit: "head-add",
            type: "button",
            icon: "",
            btnOptType: 'add',
          },
          {
            label: "导入",
            emit: "head-import",
            type: "button",
            icon: "",
            btnOptType: 'import',
          },
          {
            label: "导出",
            emit: "head-export",
            type: "button",
            icon: "",
            btnOptType: 'export',
          },
        ],
        searchColumns: [{
            label: '项目名称',
            prop: "companyCode",
            span: 4,
            placeholder: '公司名称或统一社会信用代码'
          },
          {
            label: '公司状态',
            prop: "isUsed",
            span: 4,
            type: 'select',
            placeholder: '请选择公司状态',
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          },
          {
            label: '成立日期',
            prop: "curDate",
            span: 4,
            type: 'date-format',
            placeholder: '请选择成立日期',
          },
        ],
        tableData: [],
        excelBox: false,
        excelOption: {
          submitBtn: false,
          emptyBtn: false,
          excelForm: {},
          column: [{
              label: '数据导入',
              prop: 'excelFile',
              type: 'upload',
              drag: true,
              loadText: this.$t("cip.plat.sys.user.field.waiting"),
              span: 24,
              propsHttp: {
                res: 'data'
              },
              tip: this.$t("cip.plat.sys.user.field.format"),
              action: "/api/sinoma-hse-prj/thirdcompany/import"
            },
            // {
            //   label: this.$t("cip.plat.sys.user.field.isCovered"),
            //   prop: "isCovered",
            //   type: "select",
            //   align: "center",
            //   width: 80,
            //   // dicData: [
            //   //   {
            //   //     label: this.$t("cip.plat.sys.user.field.no"),
            //   //     value: 0
            //   //   },
            //   //   {
            //   //     label: this.$t("cip.plat.sys.user.field.yes"),
            //   //     value: 1
            //   //   }
            //   // ],
            //   dataType: 'number',
            //   props: {
            //     label: "dictValue",
            //     value: "dictKey"
            //   },
            //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yesNo",
            //   value: 0,
            //   slot: true,
            //   rules: [{
            //     required: true,
            //     message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.yes") + this.$t("cip.plat.sys.user.field.no") + this.$t("cip.plat.sys.user.field.isCovered"),
            //     trigger: "blur"
            //   }]
            // },
            {
              label: this.$t("cip.plat.sys.user.field.excelTemplate"),
              prop: 'excelTemplate',
              formslot: true,
              span: 24,
            }
          ]
        }
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirdcompany_add, false),
          viewBtn: this.vaildData(this.permission.thirdcompany_view, false),
          delBtn: this.vaildData(this.permission.thirdcompany_delete, false),
          editBtn: this.vaildData(this.permission.thirdcompany_edit, false)
        };
      },
    },
    watch: {
      'excelForm.isCovered'() {
        if (this.excelForm.isCovered !== '') {
          const column = this.findObject(this.excelOption.column, "excelFile");
          column.action = `/api/sinoma-hse-prj/thirdcompany/import?isCovered=${this.excelForm.isCovered}`;
        }
      }
    },
    mounted() {
      this.onLoad(this.page, {});
    },
    methods: {
      handleAdd() {
        this.$refs.addThirdCompany.init('add')
      },
      rowUpdate(row) {
        this.$refs.addThirdCompany.init('edit', row)
      },
       //查看
       rowView(row) {
        this.$refs.addThirdCompany.init('view', row)
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      // 搜索
      gridHeadSearch(searchForm) {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      currentChange(currentPage) {
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize) {
        this.page.pageSize = pageSize;
      },
      onLoad(page, params = {}) {
        this.page = page;
        this.tableLoading = true;
        getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
          const data = res.data.data;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        });
      },
      //导入
      handleImport() {
        this.excelBox = true;
        this.excelOption.excelForm.excelFile=[]
      },
      uploadAfter(res, done, loading, column) {
        this.excelBox = false;
        this.onLoad(this.page);
        this.excelOption.excelForm.excelFile=[]
        done();
      },
      handleTemplate() {
        exportBlob(`/api/sinoma-hse-prj/thirdcompany/exportTemplate?${this.website.tokenHeader}=${getToken()}`).then(res => {
          downloadXls(res.data, '相关方公司模板' + ".xlsx");
        })
      },
      // 导出
      handleExport() {
        this.$confirm("是否导出相关方公司数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          exportBlob(`/api/sinoma-hse-prj/thirdcompany/export?companyCode=${this.$refs.gridHeadLayout.searchForm.companyCode?this.$refs.gridHeadLayout.searchForm.companyCode:''}&isUsed=${this.$refs.gridHeadLayout.searchForm.isUsed?this.$refs.gridHeadLayout.searchForm.isUsed:''}&curDate=${this.$refs.gridHeadLayout.searchForm.curDate?this.$refs.gridHeadLayout.searchForm.curDate:''}&${this.website.tokenHeader}=${getToken()}`).then(res => {
            downloadXls(res.data, `相关方公司${dateNow()}.xlsx`);
          })
        });
      },
    }
  };

</script>

<style>
</style>
