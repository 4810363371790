var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("h3", [_vm._v("表格例子")]),
      _c("avue-crud", {
        attrs: { option: _vm.option, page: _vm.page, data: _vm.data },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }