<template>
  <div>
    <!-- 项目区域 -->
    <el-container>
      <CommonTree
        ref="commonTreeCore"
        treeTitle="区域分类"
        :treeData="treeData"
        nodeKey="id"
        searchTitle="typeName"
        :defaultProps="{ label: 'typeName' }"
        :isShowdig="this.$route.query.type != 'view'"
        :expandOnClickNode="false"
        @getTreeAdd="getTreeAdd"
        @getTreeEdit="getTreeEdit"
        @getTreeDelete="getTreeDelete"
        @getNodeClick="handleNodeClick"
      />
      <el-main>
        <head-layout
          head-title="项目区域"
          :showIcon="false"
          :head-btn-options="headBtnOptions"
          @head-add-tabs="headAdd"
          @head-romve="headRomve"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <!--  -->
        <grid-layout
          class="itemListGrid"
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          :grid-row-btn="gridRowBtn"
          @row-edit="rowEdit"
          @row-remove="rowRemove"
        >
        </grid-layout>
      </el-main>
    </el-container>
    <!-- 区域分类左侧树操作 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="区域分类"
      :before-close="handleTreeCancel"
    >
      <avue-form
        :option="treeDialogOption"
        ref="cedForm"
        v-model="treeForm"
        @submit="handleSubmit"
      ></avue-form>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleTreeCancel()"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
    <projectAreaAdd ref="projectAreaAdd" @callback="callback" />
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";

import {
  getAreaClassTree,
  getClassSubmit,
  getClassRemove,
  getAreaClassPage,
  getAreaClassLinRemove,
} from "@/api/safe/projectArea";
import { mapGetters } from "vuex";
import projectAreaAdd from "./projectAreaAdd.vue";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    projectAreaAdd,
  },

  data() {
    return {
      treeData: [],
      dialogVisible: false,
      treeForm: {
        typeName: "",
        parentId: "",
        id: "",
        prjId: "",
      },
      treeDialogLoading: false,
      nodeData: {},
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},

      treeParentData: [],
      prjId: "", // 项目id
      selectionList: [],
      searchColumns: [
        {
          label: "",
          prop: "areaName",
          span: 4,
          placeholder: "请输入区域名称",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },

    headBtnOptions() {
      if (this.$route.query.type != "view") {
        return [
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            icon: "",
            btnOptType: "add",
          },
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-romve",
            type: "button",
            icon: "",
          },
        ];
      }
    },

    tableOptions() {
      return {
        menu:this.$route.query.type != 'view',
        selection: this.$route.query.type != "view",
        column: [
          {
            label: "区域名称",
            prop: "areaName",
            align: "left",
            minWidth: 200,
            overHidden: true,
          },
          {
            label: "区域编码",
            prop: "areaCode",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          // {
          //   label: "风险等级",
          //   prop: "areaRank",
          //   align: "center",
          //   dataType: "number",
          //   dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=area_rank`,
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   overHidden: true,
          // },
          {
            label: "工作范围",
            prop: "remark",
            align: "center",
            minWidth: 200,
            overHidden: true,
          },
          {
            label: "更新时间",
            prop: "updateTime",
            align: "center",
            minWidth: 170,
            overHidden: true,
          },
          {
            label: "创建人",
            prop: "createUserName",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "创建人单位",
            prop: "createDeptName",
            align: "left",
            minWidth: 200,
            overHidden: true,
          },
        ],
      };
    },

    gridRowBtn() {
      if (this.$route.query.type != "view") {
        return [
          {
            label: this.$t(`cip.cmn.btn.editBtn`),
            emit: "row-edit",
            type: "button",
            icon: "",
          },
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "row-remove",
            type: "button",
            icon: "",
          },
        ];
      }
    },

    treeDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "typeName",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur"],
              },
            ],
          },
          {
            label: "上级名称",
            prop: "parentId",
            type: "tree",
            disabled: false,
            dicData: this.treeParentData,
            props: {
              label: "typeName",
              value: "id",
            },
            rules: [
              {
                validator: this.partentRule,
              },
            ],
          },
        ],
      };
    },
  },

  methods: {
    init(prjId) {
      this.prjId = prjId;
      this.treeForm.prjId = prjId;
      this.getTreeData();
      this.query = {};
      this.onLoad(this.page);
    },
    partentRule(rule, value, callback) {
      if (value === "" || !this.treeForm.id) {
        callback();
      } else if (value == this.treeForm.id) {
        callback(new Error("不能选择自己作为上级!"));
      } else {
        callback();
      }
    },
    /** 左侧列表操作 */
    getTreeData() {
      getAreaClassTree({ prjId: this.prjId }).then((res) => {
        this.treeData = res.data.data;
        // if (this.treeData.length) {
        //   this.$nextTick(() => {
        //     this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
        //       this.treeData[0].id
        //     );
        //     this.handleNodeClick(this.treeData[0]);
        //   });
        // }
      });
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.query.typeId = data.id;

      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    getTreeAdd() {
      this.treeParentData = this.treeData;
      this.treeForm = {
        typeName: "",
        parentId: "0",
        id: "",
        prjId: this.prjId,
      };
      if (this.nodeData.id) this.treeForm.parentId = this.nodeData.id;
      this.dialogVisible = true;
    },
    getTreeEdit() {
      const data = JSON.parse(JSON.stringify(this.treeData));
      const { typeName, id, parentId, prjId } = this.nodeData;
      this.getParentData(data, id);
      this.treeForm = { typeName, id, parentId, prjId };
      this.dialogVisible = true;
    },
    getParentData(data, id) {
      const arr = data.map((val, index) => {
        if (val.id == id) {
          data.splice(index, 1);
        } else if (val.children) {
          this.getParentData(val.children, id);
          return val;
        } else {
          return val;
        }
      });
      this.treeParentData = arr;
    },
    getTreeDelete(data, done) {
      if (data.children && data.children.length) {
        this.$message({
          type: "warning",
          message: "当前分类下有子级，禁止删除",
        });
        return;
      }
      this.$confirm("确认删除数据", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getClassRemove(this.nodeData.id).then((res) => {
            if (res.data.code == 200) {
              this.getTreeData();
              this.$message.success("操作成功");
            }
          });
        })
        .catch(() => {});
    },
    handleTreeCancel() {
      this.$refs.cedForm.resetFields();
      this.dialogVisible = false;
    },
    handleSubmit(data, done) {
      this.$refs.cedForm.validate((valid) => {
        if (valid) {
          this.treeDialogLoading = true;
          getClassSubmit(this.treeForm)
            .then(() => {
              this.getTreeData();
              this.$message.success("操作成功");
              this.treeDialogLoading = false;
              this.handleTreeCancel();
              done();
            })
            .catch((err) => {
              done();
            })
            .finally(() => {
              this.treeDialogLoading = false;
            });
        }
      });
    },
    handleTreeNodeSave() {
      if (!this.treeForm.parentId) this.treeForm.parentId = "0";
      this.$refs.cedForm.submit();
    },
    /**----end---- */
    // 筛选列表
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      params.prjId = this.prjId;
      getAreaClassPage(
        page.currentPage,
        page.pageSize,
        Object.assign(page.currentPage, page.pageSize, params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 批量删除
    headRomve() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return getAreaClassLinRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    rowEdit(row, index) {
      this.$refs.projectAreaAdd.init("edit", row);
    },
    // 单条删除
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        getAreaClassLinRemove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    headAdd() {
      if (this.nodeData.id) {
        const data = { typeId: this.nodeData.id };
        this.$refs.projectAreaAdd.init("add", data);
      } else {
        this.$message.warning("请先选择分类");
      }
    },

    callback() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .itemListGrid .avue-crud .el-table {
  height: calc(100vh - 350px) !important;
  max-height: calc(100vh - 350px) !important;
  box-sizing: border-box;
}
::v-deep .el-upload {
  text-align: left;
}
.upload-text {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}
</style>
