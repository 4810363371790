<template>
  <div>
    <head-layout
      head-title="相关方出入境"
      :head-btn-options="headBtnOptions"
      @head-romve="headRomve"
      @head-add="headAdd"
    ></head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-edit="rowEdit"
      @row-view="rowDetail"
      @row-remove="rowRemove"
      @grid-row-detail-click="rowDetail"
    >
    </grid-layout>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { mapGetters } from "vuex";
import * as API from "@/api/siteManagement/collaborativeEAExit";
import { dateFormat } from "@/util/date";
export default {
  components: {
    GridLayout,
    HeadLayout
  },
  data() {
    return {
      tableOption: {
        selection: false,
        menuFixed: "right",
        menuWidth: 150,
        column: [
          {
            label: "姓名",
            prop: "partnerUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "性别",
            prop: "sex",
            align: "center",
            dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=project_sex_type`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "状态",
            prop: "tripState",
            align: "center",
            dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=trip_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "隶属组织",
            prop: "partnerName",
            align: "left",
            overHidden: true,
          },
          {
            label: "出境项目",
            prop: "prjName",
            align: "left",
            overHidden: true,
          },
          {
            label: "行程日期",
            prop: "startDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "登记人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
      ],
      searchColumns: [
        {
          label: "人员姓名",
          prop: "partnerUserName",
          span: 4,
          placeholder: "请输入人员姓名",
        },
        {
          label: "",
          prop: "startDate",
          span: 4,
          type: "date-format",
          placeholder: "行程日期",
        },
        {
          label: "",
          prop: "tripState",
          span: 4,
          type: "select",
          placeholder: "状态",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=trip_state`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    gridRowBtn() {
      return [
        {
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-view",
          type: "button",
          icon: "",
        },
        {
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        },
      ];
    },
  },
  mounted() {
    this.initDate();
  },

  methods: {
    initDate() {
      // this.$nextTick(()=>{
      //   this.$refs.gridHeadLayout.searchForm.startDate = dateFormat(new Date(),'yyyy-MM-dd');
      //   this.$refs.gridHeadLayout.searchForm.tripState = '1';
      //   this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
      // })
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    rowRemove(row) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.remove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    headAdd() {
      this.$router.push({
        path: `/siteManagement/collaborativeEAExit/edit`,
        query: {
          type: "add",
        },
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      // this.$refs.gridHeadLayout.searchForm = {};
      // this.onLoad(this.page, searchForm);
      this.initDate();
    },

    // 获取列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      API.getList(page.currentPage, page.pageSize, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/siteManagement/collaborativeEAExit/edit`,
        query: {
          id: row.id,
          type: "edit",
        },
      });
    },
    rowDetail(row) {
      this.$router.push({
        path: `/siteManagement/collaborativeEAExit/edit`,
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
  },
};
</script>
