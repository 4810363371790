var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bigBox" }, [
    _c("div", { staticClass: "noticeTitle" }, [
      _vm._v("\n    " + _vm._s(_vm.noticeForm.title) + "\n  "),
    ]),
    _c("div", { staticClass: "noticeTime" }, [
      _vm._v("\n    " + _vm._s(_vm.noticeForm.createTime) + "\n  "),
    ]),
    _c("div", {
      staticClass: "noticeContent",
      domProps: { innerHTML: _vm._s(_vm.noticeForm.content) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }