var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-main",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type == "edit"
              ? _vm.$t("cip.plat.sys.param.title.editHeadTitle")
              : this.type == "add"
              ? _vm.$t("cip.plat.sys.param.title.addHeadTitle")
              : _vm.$t("cip.plat.sys.param.title.viewHeadTitle"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-cancel": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("form-layout", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.formLoading,
            expression: "formLoading",
          },
        ],
        ref: "formLayout",
        attrs: { column: _vm.column, dataForm: _vm.dataForm },
        on: {
          "update:column": function ($event) {
            _vm.column = $event
          },
          "update:dataForm": function ($event) {
            _vm.dataForm = $event
          },
          "update:data-form": function ($event) {
            _vm.dataForm = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }