<template>
  <div style="position: relative">
    <div>
      <!-- 闸机列表 -->
      <head-layout head-title="标签管理" :head-btn-options="headBtnOptions" @head-add="handleAdd" @head-import="handleImport" @head-export="handleExport" @head-user="handleUser"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout class="table-container" ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template slot="createdDate" slot-scope="{ row }">
          <span> {{getDateTime(row.createdDate)}}</span>
        </template>
        <template #customBtn="{ row }">
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowUpdate(row)">编辑
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel(row)">删除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <addLabel ref="addLabel" @callback="onLoad(page, {})"></addLabel>

  </div>
</template>

<script>
  import {
    pageList,
    add,
    remove,
  } from "@/api/safe/labelManagement";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import addLabel from "./addLabel";
  import {
    getAgeFromIdCard
  } from "@/util/util";
  import {
    exportBlob
  } from "@/api/common";
  import {
    dateNow
  } from "@/util/date";
  import {
    getToken
  } from "@/util/auth";
  import {
    downloadXls
  } from "@/util/util";
  import {
    dateFormat
  } from "@/util/date";

  export default {
    components: {
      addLabel,
      GridLayout,
      HeadLayout,
    },
    props: {
      prjId: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        page: {
          pageSize: 20,
          pageIndex: 1,
          total: 0,
        },
        selectionList: [],
        tableOption: {
          // linklabel: "visitName",
          selection: false,
          column: [{
              label: "标签名称",
              prop: "cnName",
              align: "center",
              overHidden: true,
            },

            {
              label: "英文名",
              prop: "enName",
              align: "center",
              overHidden: true,

            },
            {
              label: "标签值",
              prop: "val",
              align: "center",
              overHidden: true,
            },
            {
              label: "创建时间",
              prop: "createdDate",
              align: "center",
              slot: true,
              overHidden: true,
            },

            {
              label: "备注",
              prop: "remark",
              align: "center",
              overHidden: true,
            },
          ],
        },
        headBtnOptions: [{
            label: "新增",
            emit: "head-add",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },
          // {
          //   label: "导入",
          //   emit: "head-import",
          //   type: "button",
          //   icon: "",
          //   btnOptType: "import",
          // },
          // {
          //   label: "导出",
          //   emit: "head-export",
          //   type: "button",
          //   icon: "",
          //   btnOptType: "export",
          // },
        ],
        searchColumns: [{
            label: "标签名称",
            prop: "visitName",
            span: 4,
            placeholder: "请输入标签名称",
          },
          {
            label: "英文名",
            prop: "visitName",
            span: 4,
            placeholder: "请输入英文名",
          },
        ],
        tableData: [],
        excelBox: false,
        excelOption: {
          submitBtn: false,
          emptyBtn: false,
          excelForm: {},
          column: [{
              label: "数据导入",
              prop: "excelFile",
              type: "upload",
              drag: true,
              loadText: this.$t("cip.plat.sys.user.field.waiting"),
              span: 24,
              propsHttp: {
                res: "data",
              },
              tip: this.$t("cip.plat.sys.user.field.format"),
              action: "/api/sinoma-hse-prj/thirduser/import",
            },

            {
              label: this.$t("cip.plat.sys.user.field.excelTemplate"),
              prop: "excelTemplate",
              formslot: true,
              span: 24,
            },
          ],
        },
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirduser_add, false),
          viewBtn: this.vaildData(this.permission.thirduser_view, false),
          delBtn: this.vaildData(this.permission.thirduser_delete, false),
          editBtn: this.vaildData(this.permission.thirduser_edit, false),
        };
      },
    },
    watch: {
      "excelForm.isCovered"() {
        if (this.excelForm.isCovered !== "") {
          const column = this.findObject(this.excelOption.column, "excelFile");
          column.action = `/api/sinoma-hse-prj/thirduser/import?isCovered=${this.excelForm.isCovered}`;
        }
      },
    },
    mounted() {
      this.onLoad(this.page, {});
    },
    methods: {
      handleAdd() {
        this.$refs.addLabel.init("add", null);
      },
      rowUpdate(row) {
        this.$refs.addLabel.init("edit", row);
      },
      //查看
      rowView(row) {
        this.$refs.addLabel.init("view", row);
      },
      getDateTime(date) {
        let srt = ''
        if (date) {
          let dd = new Date(date)
          srt = dateFormat(dd, 'yyyy-MM-dd hh:mm:ss')

        }
        return srt
      },
      saveDevice(data) {
        let that = this
        let newId = this.selectionList.map((item) => {
          return item.id
        })
        let newuser = data.map((item) => {
          return item.id
        })
        let query = {
          prjId: this.prjId,
          appointmentIdList: newId,
          machineIdList: newuser
        }
        saveSelect(query).then((res) => {
          this.selectionList = []
          this.$refs.gridLayOut.selectionClear();
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
      },
      // 多选
      selectionChange(list) {
        this.selectionList = list;
      },
   
      getAge(data) {
        return getAgeFromIdCard(data);
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove({id:row.id});
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.gridLayOut.selectionClear();
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.crud.toggleSelection();
          });
      },
      // 搜索
      gridHeadSearch(searchForm) {
        this.page.pageIndex = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.pageIndex = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.pageIndex = page.pageIndex
        params.pageSize = page.pageSize
        pageList(
          Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.tableLoading = false;
          } else {
            this.tableLoading = false;
          }
        });
      },
    },
  };

</script>

<style lang="less" scoped>
  ::v-deep .table-container {
    .el-table {
      height: calc(100vh - 334px) !important;
      max-height: calc(100vh - 334px) !important;
    }
  }

</style>
