var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "print" }, [
    _c(
      "div",
      { staticClass: "storage" },
      [
        _c(
          "el-table",
          { attrs: { data: _vm.tableData3 } },
          [
            _c(
              "el-table-column",
              { attrs: { label: "配送信息", align: "center" } },
              [
                _c("el-table-column", {
                  attrs: { label: "凭证日期", prop: "data1" },
                }),
                _c("el-table-column", {
                  attrs: { label: "客户经理号:", prop: "data2" },
                }),
                _c("el-table-column", {
                  attrs: { label: "客户经理名", prop: "data3" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { "border-top": "none" },
            attrs: { data: _vm.tableData4 },
          },
          [
            _c(
              "el-table-column",
              { attrs: { label: "入账机构：黄河银行营业部" } },
              [
                _c(
                  "el-table-column",
                  { attrs: { label: "入库详情", width: "120" } },
                  [
                    _c("el-table-column", {
                      attrs: { label: "合同编码", prop: "data1" },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label:
                        "电票测试二的电子银承汇票业务按要求办理以下物的抵（质）押，请你部按照本通知书要求，办理记账入库手续：",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "2147483647", prop: "data2" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "客户编码", prop: "data3" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "65000416", prop: "data4" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }