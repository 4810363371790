var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-color-picker", {
            key: _vm.renderKey,
            attrs: {
              "color-format": _vm.activeData["color-format"],
              "show-alpha": _vm.showAlpha,
            },
            model: {
              value: _vm.activeData.__config__.defaultValue,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
              },
              expression: "activeData.__config__.defaultValue",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "颜色格式" } },
        [
          _c(
            "el-select",
            {
              style: { width: "100%" },
              attrs: { placeholder: "请选择颜色格式", clearable: "" },
              on: { change: _vm.colorFormatChange },
              model: {
                value: _vm.activeData["color-format"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "color-format", $$v)
                },
                expression: "activeData['color-format']",
              },
            },
            _vm._l(_vm.colorFormatOptions, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "组件尺寸" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.activeData.size,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "size", $$v)
                },
                expression: "activeData.size",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "medium" } }, [
                _vm._v("中等"),
              ]),
              _c("el-radio-button", { attrs: { label: "small" } }, [
                _vm._v("较小 "),
              ]),
              _c("el-radio-button", { attrs: { label: "mini" } }, [
                _vm._v("迷你"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }