<template>
  <el-dialog
    v-dialog-drag
    title="风险引用"
    :modal="false"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="introduceShow"
    v-if="introduceShow"
    width="80%"
    :fullscreen="fullscreen"
    class="qmDialog"
  >
    <el-container style="height: 500px">
      <CommonTree
        treeTitle="风险源分类"
        :defaultProps="defaultProps"
        :isShowdig="false"
        :searchTitle="searchTitle"
        :showCheckbox="false"
        :treeData="introduceTree"
        :treeExpand="true"
        style="font-size: 13px"
        :risk="true"
        @getNodeClick="handleNodeIntroduce"
      />
      <el-main>
        <div class="company" style="padding: 0 10px">
          <el-select
            v-model="companyName"
            clearable
            ref="selectTree"
            @clear="clearHandle"
            placeholder="请选择公司"
          >
            <el-option :value="companyId" :label="companyName">
              <el-tree
                id="tree-option"
                :data="companySelection"
                :highlight-current="true"
                :props="companydefaultProps"
                :accordion="true"
                node-key="id"
                :default-expanded-keys="defaultExpandedKey"
                @node-click="handleNodeClickListTree"
              >
              </el-tree>
            </el-option>
          </el-select>
        </div>
        <grid-head-layout
          ref="searchForm"
          v-model="searchForm"
          :search-columns="introduceSearchColumns"
          @grid-head-search="introduceSearch"
          @grid-head-empty="introduceReset"
        />
        <grid-layout
          ref="gridLayout"
          :data-total="introduceTableTotal"
          :gridRowBtn="introduceGridRowBtn"
          :page="introducePage"
          :tableData="introduceTableData"
          :tableLoading="introduceTableLoading"
          :tableOptions="introduceTableOptions"
          @page-size-change="handleSizeIntroduce"
          @page-current-change="handleCurrentIntroduce"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="introduceSelectionChange"
          @grid-select="introduceSelect"
          :search-columns="introduceSearchColumns"
        >
          <template slot="riskCode" slot-scope="{ row }">
            <el-link type="primary" @click="linkDetail(row)">{{
              row.riskCode
            }}</el-link>
          </template>
          <template slot="riskLevel" slot-scope="{ row }">
            <div
              style="
                display: flex;
                justify-content: center;
                align-item: center;
                padding: 4px;
              "
            >
              <div
                :style="{
                  border: `1px solid ${row.lecdColor}`,
                  borderRadius: '4px',
                  color: row.lecdColor,
                  backgroundColor: computedDlevel(row.lecdD).bg,
                  textAlign: 'center',
                  display: 'inline-block',
                    padding: '0 20px',
                }"
              >
                {{ row.lecdD }}
              </div>
            </div>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <span slot="footer">
      <el-button
        size="small"
        @click="introduceShow = false"
        v-loading="treeDialogLoading"
      >
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <!-- <el-button size="small" type="primary" @click="handleIntroduceSave">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {
  getTree,
  getPage,
  improtList,
  getDetTree,
} from "@/api/riskManage/project";
import { getbsrisklecddlevelMap } from "@/api/setting/riskAssessment/index";
import { mapGetters } from "vuex";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      introduceShow: false,
      needTreatment: false,
      searchTitle: "sourceTypeName",
      defaultProps: {
        children: "children",
        id: "id",
        label: "sourceTypeName",
      },
      introduceNowTreeNode: {},
      IntroduceNowNodeId: "",
      introduceTableLoading: false,
      introduceTableData: [],
      introduceTableTotal: 0,
      companyName: "",
      introducePage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      introduceGridRowBtn: [
        {
          label: "选择",
          emit: "grid-select",
          remark: "select",
          type: "text",
          icon: "",
        },
      ],
      introduceTableOptions: {
        customRowAddBtn: false,
        menuWidth: 100,
        menuFixed: "right",
        showTooltip: true,
        indexLabel: "序号",
        selection: false,
        index: true,
        column: [
          {
            label: "风险编号",
            prop: "riskCode",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
          {
            label: "风险源",
            prop: "sourceName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "事故类型",
            prop: "event",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "风险等级",
            prop: "riskLevel",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
        ],
      },
      introduceTree: [],
      companySelection: [],
      introduceSearchColumns: [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险编号",
          prop: "riskCode",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "事故类型",
          prop: "event",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "风险等级",
          type: "select",
          prop: "riskLevel",
          span: 4,
          dicData: [],
          props: {
            label: "levelName",
            value: "levelName",
          },
          dataType: "number",
        },
      ],
      D: [],
      companydefaultProps: {
        children: "children",
        id: "id",
        label: "title",
      },
    };
  },
  methods: {
    computedDlevel(levelCode) {
      let result = this.D.find((item) => item.levelCode == levelCode);
      if (result) {
        result.bg =
          result.color.substring(0, 3) +
          result.color.substring(3, result.color.length - 2) +
          "0.1)";
      }

      return (
        result || {
          color: "rgba(252, 233, 119, 1)",
          bg: "rgba(252, 233, 119, 0.1)",
        }
      );
    },
    introduceSelect(row) {
      // row.organizationId = this.userInfo.dept_id;
      // row.sourceId = this.nowTreeNode.id;
      // row.sourceCode = this.nowTreeNode.sourceCode;
      // row.sourceName = this.nowTreeNode.sourceName;
      //   improtList([row], this.userInfo.dept_id, this.nowTreeNode.id).then(
      //     (res) => {
      //       this.introduceShow = false;
      //       // 引入成功
      //       this.$message({
      //         message: "引入成功",
      //         type: "success",
      //       });
      //     }
      //   );
      this.$emit("getSelection", row);
      this.introduceShow = false;
    },
    init(needTreatment) {
      this.introduceShow = true;
      this.getbsrisklecddlevelMap();
      this.getDetTree();
      this.companyId = this.userInfo.dept_id;
      this.companyName = this.userInfo.dept_name;
      this.organizationId = this.userInfo.dept_id;
      this.needTreatment = needTreatment || false;
      this.getIntroducetree(this.companyId);
    },
    getIntroducetree(organizationId) {
      getTree({ organizationId: organizationId }).then((res) => {
        if (res.status == 200) {
          this.introduceTree = res.data.data;
        }
      });
    },
    // 点击树显示列表 getPageManage
    handleNodeIntroduce(data) {
      this.IntroduceNowNodeId = data.id;
      this.introduceNowTreeNode = data;
      let params = {
        size: this.introducePage.pageSize,
        current: this.introducePage.currentPage,
        organizationId: this.organizationId,
      };
      if (this.introduceNowTreeNode.isSource) {
        params.sourceId = this.IntroduceNowNodeId;
      } else {
        params.sourceTypeId = this.IntroduceNowNodeId;
      }
      if (this.needTreatment) {
        params.needTreatment = this.needTreatment;
      }
      // 调用列表接口;
      this.introduceTableLoading = true;
      getPage(params)
        .then((res) => {
          this.introduceTableData = res.data.data.records;
          this.introduceTableTotal = res.data.data.total;
          this.introduceTableLoading = false;
        })
        .catch((error) => {
          this.introduceTableLoading = false;
        });
    },
    clearHandle() {
      this.companyName = "";
      this.companyId = "";
      this.defaultExpandedKey = [];
      this.introduceTableData = [];
      this.introducePage = {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      };
      this.clearSelected();
    },
    // 获取D映射
    getbsrisklecddlevelMap() {
      //  createDept: this.userInfo.dept_id
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
        this.introduceSearchColumns[2].dicData = res.data.data;
      });
    },
    // 获取公司树
    getDetTree() {
      getDetTree({ categorys: "2" }).then((res) => {
        this.companySelection = res.data.data;
      });
    },
    clearSelected() {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
    handleNodeClickListTree(node, obj) {
      this.companyName = node["title"];
      this.companyId = node["id"];
      this.introduceTableData = [];
      this.introducePage = {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      };
      this.getIntroducetree(this.companyId);
      this.defaultExpandedKey = [];
      this.$refs.selectTree.blur();
    },
    handleSizeIntroduce(e) {
      this.introducePage.pageSize = e.pageSize;
      this.getIntroducePage(this.companyId);
    },

    handleCurrentIntroduce(e) {
      this.introducePage.currentPage = e.currentPage;
      this.getIntroducePage(this.companyId);
    },
    getIntroducePage(organizationId) {
      if (this.IntroduceNowNodeId) {
        return;
      }
      let params = {
        size: this.introducePage.pageSize,
        current: this.introducePage.currentPage,
        organizationId,
      };
      if (this.introduceNowTreeNode.isSource) {
        params.sourceId = this.IntroduceNowNodeId;
      } else {
        params.sourceTypeId = this.IntroduceNowNodeId;
      }
      console.log(this.needTreatment);
      if (this.needTreatment) {
        params.needTreatment = this.needTreatment;
      }
      this.introduceTableLoading = true;
      getPage(params)
        .then((res) => {
          this.introduceTableData = res.data.data.records;
          this.introduceTableTotal = res.data.data.total;
          this.introduceTableLoading = false;
        })
        .catch((error) => {
          this.introduceTableLoading = false;
        });
    },
    handleIntroduceSave() {
      if (!this.introduceArr.length) {
        this.$message({
          message: "请勾选需要引入的风险源",
          type: "warning",
        });
      }
      // this.introduceArr.forEach((item) => {
      //   item.organizationId = this.userInfo.dept_id;
      //   item.sourceId = this.nowTreeNode.id;
      //   item.sourceCode = this.nowTreeNode.sourceCode;
      //   item.sourceName = this.nowTreeNode.sourceName;
      // });
      improtList(
        this.introduceArr,
        this.userInfo.dept_id,
        this.nowTreeNode.id
      ).then((res) => {
        this.introduceShow = false;
        // 引入成功
        this.$message({
          message: "引入成功",
          type: "success",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .common_tree_handle {
  height: 100%;

  .el-tree {
    height: calc(100% - 112px) !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

::v-deep #gridLayout {
  height: calc(100% - 138px) !important;
}

::v-deep .avue-crud {
  height: 100% !important;

  .el-card {
    height: 100% !important;
  }

  .el-card__body {
    height: 100% !important;

    .el-form {
      height: 100% !important;

      .el-table {
        height: 100% !important;
      }
    }
  }
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .echarts-box {
      border-radius: 4px;
      width: 50%;
      height: 100%;
      background: #fff;
      position: relative;

      .title {
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 10px;
      }
    }

    .canvas-box {
      border-radius: 4px;
      width: 50%;
      height: 100%;
      background: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;

      .title-box {
        width: calc(100% - 20px);
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .bottom {
    border-radius: 4px;
    margin-top: 10px;
    background: #fff;
    display: flex;
    width: 100%;
    height: calc(50% - 10px);
    overflow: scroll;

    .el-container {
      height: 100%;

      .el-main {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

.el-main {
  overflow: hidden;
}
</style>
