var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "成绩补录",
          "head-btn-options": _vm.headBtnOptions,
        },
      }),
      _c("grid-head-layout", {
        ref: "searchForm",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.query,
          callback: function ($$v) {
            _vm.query = $$v
          },
          expression: "query",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayout",
        attrs: {
          tableOptions: _vm.tableOptions,
          tableData: _vm.tableData,
          tableLoading: _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
          searchColumns: _vm.searchColumns,
        },
        on: {
          "grid-row-detail-click": _vm.toView,
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-view": _vm.toView,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row, index }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toView(row, index)
                      },
                    },
                  },
                  [_vm._v("\n        查看\n      ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toEdit(row, index)
                      },
                    },
                  },
                  [_vm._v("\n        成绩补录\n      ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }