var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-box riskIdentification" },
    [
      _c("div", { staticClass: "flex-container" }, [
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c("head-layout", {
              attrs: {
                "head-btn-options": _vm.headBtnOptions,
                "head-title": "当前摄像头信息",
              },
              on: {
                "head-cancel": _vm.headCancel,
                "head-save": _vm.headSave,
                "head-edit": _vm.headEdit,
              },
            }),
            _c(
              "el-form",
              {
                ref: "dataForm",
                staticClass: "form-base",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "160px",
                  disabled: _vm.formType == "view",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "编码", prop: "code" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写编码" },
                          model: {
                            value: _vm.dataForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "code", $$v)
                            },
                            expression: "dataForm.code",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "名称", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写名称" },
                          model: {
                            value: _vm.dataForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "name", $$v)
                            },
                            expression: "dataForm.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "别名", prop: "alias" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写别名" },
                          model: {
                            value: _vm.dataForm.alias,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "alias", $$v)
                            },
                            expression: "dataForm.alias",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "类型", prop: "type" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择类型" },
                            model: {
                              value: _vm.dataForm.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "type", $$v)
                              },
                              expression: "dataForm.type",
                            },
                          },
                          _vm._l(_vm.NODE_TYPES_list, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.name, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "设备型号", prop: "deviceModel" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写设备型号" },
                          model: {
                            value: _vm.dataForm.deviceModel,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "deviceModel", $$v)
                            },
                            expression: "dataForm.deviceModel",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "状态", prop: "status" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.dataForm.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "status", $$v)
                              },
                              expression: "dataForm.status",
                            },
                          },
                          _vm._l(_vm.STATUS_BOOLEAN_list, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.name, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户名", prop: "userName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写用户名" },
                          model: {
                            value: _vm.dataForm.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "userName", $$v)
                            },
                            expression: "dataForm.userName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "密码", prop: "password" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写密码" },
                          model: {
                            value: _vm.dataForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "password", $$v)
                            },
                            expression: "dataForm.password",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属上级", prop: "parentName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请填写所属上级",
                            disabled: true,
                          },
                          model: {
                            value: _vm.dataForm.parentName,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "parentName", $$v)
                            },
                            expression: "dataForm.parentName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "SIP用户ID", prop: "sipUserId" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写SIP用户ID" },
                          model: {
                            value: _vm.dataForm.sipUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "sipUserId", $$v)
                            },
                            expression: "dataForm.sipUserId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "序列号", prop: "sn" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写序列号" },
                          model: {
                            value: _vm.dataForm.sn,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "sn", $$v)
                            },
                            expression: "dataForm.sn",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "视频流协议类型",
                          prop: "streamProtocol",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.dataForm.streamProtocol,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "streamProtocol", $$v)
                              },
                              expression: "dataForm.streamProtocol",
                            },
                          },
                          _vm._l(_vm.CAMERA_STREAM_PROTOCOL, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.name, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "视频流地址", prop: "streamUrl" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写视频流地址" },
                          model: {
                            value: _vm.dataForm.streamUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "streamUrl", $$v)
                            },
                            expression: "dataForm.streamUrl",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "视频通道ID", prop: "channelId" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写视频通道ID" },
                          model: {
                            value: _vm.dataForm.channelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "channelId", $$v)
                            },
                            expression: "dataForm.channelId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "安装位置", prop: "position" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写安装位置" },
                          model: {
                            value: _vm.dataForm.position,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "position", $$v)
                            },
                            expression: "dataForm.position",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "厂商", prop: "vendor" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写厂商" },
                          model: {
                            value: _vm.dataForm.vendor,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "vendor", $$v)
                            },
                            expression: "dataForm.vendor",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "外观类型", prop: "appearanceType" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.dataForm.appearanceType,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "appearanceType", $$v)
                              },
                              expression: "dataForm.appearanceType",
                            },
                          },
                          _vm._l(
                            _vm.CAMERA_APPEARANCE_TYPES_list,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "信号类型", prop: "signalType" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.dataForm.signalType,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "signalType", $$v)
                              },
                              expression: "dataForm.signalType",
                            },
                          },
                          _vm._l(_vm.CAMERA_SIGNAL_TYPES_list, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.name, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "支持云控台", prop: "cloudControl" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.dataForm.cloudControl,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "cloudControl", $$v)
                              },
                              expression: "dataForm.cloudControl",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否高清", prop: "supportHd" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.dataForm.supportHd,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "supportHd", $$v)
                              },
                              expression: "dataForm.supportHd",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "SIP用户ID", prop: "sipUserId" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写SIP用户ID" },
                          model: {
                            value: _vm.dataForm.sipUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "sipUserId", $$v)
                            },
                            expression: "dataForm.sipUserId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "视频通道ID", prop: "channelId" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写视频通道ID" },
                          model: {
                            value: _vm.dataForm.channelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "channelId", $$v)
                            },
                            expression: "dataForm.channelId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写备注" },
                          model: {
                            value: _vm.dataForm.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "remark", $$v)
                            },
                            expression: "dataForm.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.jcxflModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "评估单元及风险点",
                visible: _vm.jcxflModel,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.jcxflModel = $event
                },
              },
            },
            [
              _c("avue-form", {
                attrs: { option: _vm.jcxflOption },
                on: {
                  "reset-change": _vm.resetChange,
                  submit: _vm.handleSubmit,
                },
                model: {
                  value: _vm.jcxflForm,
                  callback: function ($$v) {
                    _vm.jcxflForm = $$v
                  },
                  expression: "jcxflForm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }