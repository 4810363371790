<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
    >
      <template slot="riskCode" slot-scope="{ row }">
        <el-link type="primary" @click="linkDetail(row)">{{
          row.riskCode
        }}</el-link>
      </template>
      <template slot="riskLevel" slot-scope="{ row }">
        <div
          style="
            display: flex;
            justify-content: center;
            align-item: center;
            padding: 4px;
          "
        >
          <div
            :style="{
              border: `1px solid ${row.lecdColor}`,
              borderRadius: '4px',
              color: row.lecdColor,
              backgroundColor: computedDlevel(row.lecdD).bg,
              textAlign: 'center',
              display: 'inline-block',
            }"
          >
            {{ row.lecdD }}
          </div>
        </div>
      </template>
      <template slot="libraryStatus" slot-scope="{ row }">
        {{ row.libraryStatus == "FINISHED" ? "完成" : "草稿" }}
      </template>
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";

import { holographyRiskPage } from "@/api/safe/safeatyHolography";
import { getbsrisklecddlevelMap } from "@/api/setting/riskAssessment/index";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      organizationId: "",
      D: [],
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          prop: "riskName",
          placeholder: "风险名称",
          span: 4,
        },
      ];
    },
    tableOptions() {
      return {
        menu: false,
        selection: false,
        linklabel: "riskCode",
        column: [
          {
            label: "风险编号",
            prop: "riskCode",
            align: "center",
            overHidden: true,
            minWidth: 200,
            slot: true,
          },
          {
            label: "风险名称",
            prop: "riskName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "风险源",
            prop: "sourceName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "行为事件",
            prop: "event",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "可能后果",
            prop: "lecdC",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "风险评价",
            prop: "location",
            children: [
              {
                label: "L",
                prop: "lecdLScore",
                align: "center",
                overHidden: true,
              },
              {
                label: "E",
                prop: "lecdEScore",
                align: "center",
                overHidden: true,
              },
              {
                label: "C",
                prop: "lecdCScore",
                align: "center",
                overHidden: true,
              },
              {
                label: "D",
                prop: "lecdDScore",
                align: "center",
                overHidden: true,
              },
            ],
          },
          {
            label: "风险等级",
            prop: "riskLevel",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
          {
            label: "状态",
            prop: "libraryStatus",
            align: "center",
            overHidden: true,
            slot: true,
          },
        ],
      };
    },
  },
  methods: {
    init(id) {
      this.organizationId = id;
      this.getbsrisklecddlevelMap();
      this.query = {}
      // this.query.organizationId = id
      this.page.currentPage = 1
      this.$refs.gridHeadLayout.searchForm = {}
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      // (params.organizationId = this.organizationId),
        holographyRiskPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query,{organizationId:this.organizationId})
        ).then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    computedDlevel(levelCode) {
      let result = this.D.find((item) => item.levelCode == levelCode);
      if (result) {
        result.bg =
          result.color.substring(0, 3) +
          result.color.substring(3, result.color.length - 2) +
          "0.1)";
      }

      return (
        result || {
          color: "rgba(252, 233, 119, 1)",
          bg: "rgba(252, 233, 119, 0.1)",
        }
      );
    },
    // 获取D映射
    getbsrisklecddlevelMap() {
      // createDept: this.userInfo.dept_id
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
        // this.searchColumns[3].dicData = res.data.data;
      });
    },
    linkDetail(row){
      this.$router.push({
        // path: "/riskProject/riskProjectDetail",
        path: "/business/safe/safeatyHolography/riskDetail",
        query: {
          type: "detail",
          nowRuleFormId: row.id,
          organizationId: row.organizationId,
        },
      });
    }
  },
};
</script>
<style scoped lang="scss"></style>
