var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" }, attrs: { id: "homeIndex" } },
    [
      _vm.userInfo.company_category != 5
        ? _c(
            "div",
            {
              staticClass: "home-Index-default",
              staticStyle: { height: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "disFlex", staticStyle: { height: "40%" } },
                [
                  _c("div", { staticClass: "cardBox6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cardTitleHome",
                        staticStyle: {
                          "margin-bottom": "10px",
                          "line-height": "46px",
                        },
                      },
                      [_vm._v("\n          数据简报\n          ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "disFlex",
                        staticStyle: { height: "calc(100% - 56px)" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "targetBox" },
                          _vm._l(_vm.dataList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "targetLittle" },
                              [
                                _c("div", { staticClass: "targetTitle" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "iconContent",
                                      style: { background: _vm.themeColor },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "income",
                                        attrs: { src: item.iconUrl },
                                      }),
                                    ]
                                  ),
                                  _c("div", { staticClass: "disFlex-left" }, [
                                    _c("span", { staticClass: "number" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sinoma.formatNumber(item.value)
                                        )
                                      ),
                                    ]),
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "menu-box" }, [
                    _c("div", { staticClass: "quick-app-list" }, [
                      _c("div", { staticClass: "menu-title" }, [
                        _c("span", [_vm._v("快捷入口")]),
                        _c("i", {
                          staticClass: "el-icon-s-tools",
                          staticStyle: {
                            "font-size": "24px",
                            cursor: "pointer",
                            color: "#000000",
                          },
                          on: { click: _vm.openMenuDialog },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "list-content" },
                        _vm._l(_vm.menuData, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "quick-app-item",
                              on: {
                                click: function ($event) {
                                  return _vm.menuClick(item)
                                },
                                contextmenu: function ($event) {
                                  $event.preventDefault()
                                  return _vm.rightClick(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imageBox",
                                  style: { background: _vm.themeColor },
                                },
                                [_c("i", { class: item.menuSource })]
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.menuNameEn || item.menuNameZh)
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "disFlex",
                  staticStyle: {
                    "margin-top": "16px",
                    height: "calc(60% - 16px)",
                  },
                },
                [
                  _c("div", { staticStyle: { width: "calc(66.6% - 8px)" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "cardBox6",
                        staticStyle: {
                          width: "calc(100% - 40px)",
                          height: "calc(100% - 32px)",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cardTitleHome",
                            staticStyle: {
                              height: "100%",
                              margin: "0",
                              position: "relative",
                            },
                          },
                          [
                            _c(
                              "el-tabs",
                              {
                                model: {
                                  value: _vm.todoActive,
                                  callback: function ($$v) {
                                    _vm.todoActive = $$v
                                  },
                                  expression: "todoActive",
                                },
                              },
                              [
                                _c("el-tab-pane", { attrs: { name: "0" } }, [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "待办【" + _vm._s(_vm.todoTotal) + "】"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "newsBox" },
                                    _vm._l(
                                      _vm.todoList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "newsLittle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.rowTrackPending(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                directives: [
                                                  {
                                                    name: "fix-zoom",
                                                    rawName: "v-fix-zoom",
                                                  },
                                                ],
                                                attrs: {
                                                  placement: "top",
                                                  effect: "dark",
                                                  disabled: item.showTip,
                                                  content: item.taskName,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "newsTitle",
                                                    class: "todoTitle" + index,
                                                    staticStyle: {
                                                      width: "637px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(item.typeName) +
                                                        "—" +
                                                        _vm._s(
                                                          item.businessType == 0
                                                            ? "流程待办"
                                                            : "任务待办"
                                                        ) +
                                                        "—" +
                                                        _vm._s(item.taskName) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "newsTime",
                                                staticStyle: {
                                                  width: "calc(100% - 637px)",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.createTime) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "allNameBox",
                                style: { color: _vm.themeColor },
                                on: { click: _vm.runTodo },
                              },
                              [_vm._v("\n              更多\n            ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "calc(33.3% - 7px)",
                        "margin-left": "16px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cardBox3",
                          staticStyle: {
                            height: "calc(100% - 32px)",
                            position: "relative",
                          },
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.handleClick },
                              model: {
                                value: _vm.activeName,
                                callback: function ($$v) {
                                  _vm.activeName = $$v
                                },
                                expression: "activeName",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "系统公告", name: "0" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "newsBox" },
                                    _vm._l(
                                      _vm.noticeList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "newsLittle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.runNotice(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "newsTitle" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.title) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "newsTime" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.createTime) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "环境要闻", name: "1" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "newsBox" },
                                    _vm._l(
                                      _vm.newsList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "newsLittle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.runNews(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "newsTitle" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.parameter_3) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "newsTime" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.parameter_2) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "内部分享", name: "2" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "newsBox" },
                                    _vm._l(
                                      _vm.shareList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "newsLittle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.runShare(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "newsTitle" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.title) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "newsTime" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.createTime) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "moreTitle",
                              style: { color: _vm.themeColor },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.openMore },
                                },
                                [_vm._v("更多")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _c(
            "div",
            { staticClass: "home-index-prj", staticStyle: { height: "100%" } },
            [_c("IndexPrj")],
            1
          ),
      _c(
        "el-dialog",
        {
          attrs: { title: "公告详情", visible: _vm.noticeShow, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.noticeShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "noticeTitle" }, [
            _vm._v("\n      " + _vm._s(_vm.noticeForm.title) + "\n    "),
          ]),
          _c("div", { staticClass: "noticeTime" }, [
            _vm._v("\n      " + _vm._s(_vm.noticeForm.createTime) + "\n    "),
          ]),
          _c("div", {
            staticClass: "noticeContent",
            domProps: { innerHTML: _vm._s(_vm.noticeForm.content) },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: this.$t("tagsView.shortcutMenu"),
            "custom-class": "noticeClass",
            visible: _vm.menuShow,
            "close-on-click-modal": false,
            width: "60%",
            "modal-append-to-body": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.menuShow = $event
            },
            close: _vm.closeMenuDialog,
          },
        },
        [
          _c("menu-dialog", {
            ref: "menuDialog",
            attrs: { disabledData: _vm.menuData },
            on: { getNewMenu: _vm.addMenu },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }