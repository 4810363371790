<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-08-21 16:28:01
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
    <avue-form :option="{ ...options,disabled:pageType == 'view' ? true : false,}" ref="addForm" v-model="form" @submit="treeNodeSave">

    </avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    add
  } from "@/api/safe/gate";
  import {
    getList
  } from "@/api/safe/thirduser";
  import {
    getAgeFromIdCard,
    getBirthDate
  } from "@/util/util";
  import {
    PATTERN_ID_CARD
  } from "@/util/validate"
  import {
    from
  } from "rxjs";
  import Template from "@/views/message/template/list.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {
    mapGetters
  } from "vuex";
  import {
    cateList
  } from "@/api/safetyTtandard/occupationConfig";

  export default {
    components: {
      Template
    },
    mixins: [organization],
    data() {
      return {
        APITYPE: 'user',
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        title: '',
        isEdit: false,
        searchData: [],
        pageType: '',
        fileAppend: {
          label: '',
          prop: '',
          type: 'upload',
          props: {
            label: 'originalName',
            value: 'thumbnailLink'
          },
          dataType: 'object',
          listType: 'picture-card',
          accept: 'image/*',
          fileSize: 5000,
          span: 24,
          propsHttp: {
            res: 'data'
          },
          tip: '图片支持*.jpg/*.png 格式，文件大小不超过5M',
          action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km'
        },
      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "设备号",
              prop: "eqtCode",
              rules: [{
                required: true,
                message: "请输入设备号",
                trigger: "blur"
              }, {
                max: 64,
                message: '设备号不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "设备名称",
              prop: "eqtName",
              rules: [{
                required: true,
                message: "请输入设备名称",
                trigger: 'blur'
              }, {
                max: 64,
                message: '设备名称不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "设备类型",
              prop: "eqtType",
              type: 'select',
              dataType: 'string',
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineEqtType",
              rules: [{
                required: true,
                message: "请选择设备类型",
                trigger: "blur"
              }]
            },
            {
              label: "型号规格",
              prop: "modelSpec",
              rules: [{
                required: true,
                message: "请输入型号规格",
                trigger: "blur"
              },{
                max: 64,
                message: '型号规格不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "厂商名称",
              prop: "manufacturerName",
              rules: [
              {
                required: true,
                message: "请输入厂商名称",
                trigger: "blur"
              },{
                max: 64,
                message: '厂商名称不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "安装位置",
              prop: "installSite",
              rules: [{
                required: true,
                message: "请输入安装位置",
                trigger: "blur"
              },{
                max: 64,
                message: '安装位置不能超过64个字符',
                trigger: 'blur'
              }]

            },

            {
              label: '备注',
              prop: 'remarks',
              formslot: true,
              rules: [
              {
                max: 200,
                message: '备注不能超过200个字符',
                trigger: 'blur'
              }]
            },

          ],
        }
      },
    },

    methods: {
      init(type, data,id) {
        this.form = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.pageType = type
        this.form.prjId=id
        if (type == 'edit') {
          this.title = '编辑设备'
          this.form = data
        } else if (type == 'view') {
          this.title = '查看设备'
          this.form = data
        } else {
          this.form.id=''
          this.title = '新增设备'
        }

      },

      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      treeNodeSave(data) {
        this.$refs.addForm.validate((valid, done) => {
          if (valid) {
            this.dialogLoading = true
            add(data).then(res => {
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(res.data.msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.showDialog = false;
              this.dialogLoading = false
            }).catch(err => {
              done()
            }).finally(() => {
              done()
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {}
        this.searchData = []
        this.$refs.addForm.resetFields();
        this.showDialog = false
      },
    }
  }

</script>
<style lang="scss" scoped>
  .el-autocomplete {
    width: 100%
  }

</style>
