var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid-head-layout", {
        ref: "searchForm",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayout",
        staticClass: "customCourses",
        attrs: {
          tableOptions: _vm.tableOptions,
          tableData: _vm.tableData,
          tableLoading: _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
          searchColumns: _vm.searchColumns,
        },
        on: {
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-row-detail-click": _vm.View,
        },
        scopedSlots: _vm._u([
          {
            key: "courseCover",
            fn: function ({ row }) {
              return [
                row.courseCover == ""
                  ? _c("img", {
                      staticClass: "courseCoverImg",
                      attrs: {
                        src: require("@/assets/training/trainingDefaultPicture.png"),
                      },
                    })
                  : _c("img", {
                      staticClass: "courseCoverImg",
                      attrs: { src: row.courseCover },
                    }),
              ]
            },
          },
          {
            key: "customBtn",
            fn: function ({ row, index }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.submit(row, index)
                      },
                    },
                  },
                  [_vm._v("\n        确定\n      ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }