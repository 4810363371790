<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="rowDetail"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
    >
      <template #hdLevel="{row}">
          <el-tag v-if="hdLevel[row.hdLevel]" :type="hdLevelIcon[row.hdLevel]"
            effect="dark">{{hdLevel[row.hdLevel]}}
          </el-tag>
        </template>
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";

import riskView from "@/views/hiddenTrouble/riskItemView/riskView";

import { holographyHdPage } from "@/api/safe/safeatyHolography";

export default {
  mixins:[riskView],
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      organizationId: "",
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          prop: "hdDesc",
          placeholder: "隐患描述",
          span: 4,
        },
      ];
    },
    tableOptions() {
      return {
        menu: false,
        selection: false,
        linklabel: "hdCode",
        column: [
          {
            label: "隐患编号",
            prop: "hdCode",
            align: "left",
            overHidden: true,
            width: 200,
          },
          {
            label: "隐患描述",
            prop: "hdDesc",
            align: "left",
            width: 120,
            overHidden: true,
          },
          {
            label: "隐患级别",
            prop: "hdLevel",
            align: "center",
            width: 150,
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            slot: true,
          },
          {
            label: "隐患地点",
            prop: "location",
            align: "center",
            overHidden: true,
          },
          {
            label: "报告人",
            prop: "reportUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "责任部门",
            prop: "respDeptName",
            align: "center",
            overHidden: true,
          },
          {
            label: "整改期限",
            prop: "rectifyLimitDate",
            align: "center",
            width: 200,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "hdStatus",
            align: "center",
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: "center",
            width: 200,
            overHidden: true,
          },
        ],
      };
    },
  },
  methods: {
    init(id) {
      this.organizationId = id;
      this.query = {}
      // this.query.organizationId = id
      this.page.currentPage = 1
      this.$refs.gridHeadLayout.searchForm = {}
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      // (params.organizationId = this.organizationId),
        holographyHdPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query,{organizationId:this.organizationId})
        ).then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    rowDetail(row) {
      this.$router.push({
        path: `/hiddenTrouble/managementView/edit`,
        query: {
          id: row.id,
          pageType: 'view'
        }
      })
    },
  },
};
</script>
<style scoped lang="scss"></style>
