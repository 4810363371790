<template>
    <div>
        <el-container>
            <CommonTree
                ref="commonTreeCore"
                treeTitle="组织架构"
                :searchTitle="searchTitle"
                :treeData="treeData"
                :defaultProps="defaultProps"
                @getNodeClick="handleNodeClick"
                :isShowdig="false"
                :showCheckbox="false"
                node-key="id"
            />
            <el-main>
                <head-layout
                    head-title="相关方人员注册审批"
                    :head-btn-options="headBtnOptions"
                    @head-batch-pass="handleBacthPass"
                />
                <el-tabs
                    v-model="activeName"
                    @tab-click="handleClick"
                    style="padding: 0 12px;"
                >
                    <el-tab-pane label="待审批" name="pending"></el-tab-pane>
                    <el-tab-pane label="已通过" name="passed"></el-tab-pane>
                    <el-tab-pane label="未通过" name="refuse"></el-tab-pane>
                </el-tabs>
                <grid-head-layout
                    ref="gridHeadLayout"
                    :search-columns="searchColumns"
                    @grid-head-search="gridHeadSearch"
                    @grid-head-empty="gridHeadEmpty"
                    ></grid-head-layout>
                <grid-layout
                    ref="gridLayOut"
                    :table-options="tableOptions"
                    :table-data="tableData"
                    :table-loading="tableLoading"
                    :data-total="page.total"
                    :page="page"
                    :editType="inline"
                    :grid-row-btn="gridRowBtn"
                    @page-current-change="handleCurrentChange"
                    @page-size-change="handleSizeChange"
                    @page-refresh-change="onLoad"
                    @gird-handle-select-click="selectionChange"
                    @row-pass="handlePass"
                    @row-refuse="handleRefuse"
                    >
                </grid-layout>
            </el-main>
        </el-container>

    </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
// import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
// import { prjuserregisterPage, enterpriseSubmit, enterpriseRemove } from '@/api/responsibility/licenceEnterprise'
import { prjuserregisterPage, prjuserregisterUpdate, prjuserregisterUpdateBatch } from "@/api/thirdAudit/index"
import {mapGetters} from 'vuex'
import {downloadFileBlob} from "@/util/util";
import {dateFormat} from '@/util/date'
// import { deptChildTree } from "@/api/reportTemplate";
import {selectUserChildrenWithoudProject} from "@/api/system/dept";
export default {
    components: {
        CommonTree,
        HeadLayout,
        GridLayout,
    },

    data () {
        return {
            nodeData: {},
            treeData: [],
            searchTitle: "deptName",
            defaultProps: {
                children: "children",
                // label: "deptName",
                label: "title",
            },
            searchColumns: [
                {
                    prop: "userName",
                    placeholder: "姓名",
                    span: 4,
                },
                {
                    prop: "idCoding",
                    placeholder: "身份证号",
                    span: 4,
                    search: true,
                },
            ],
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            query: {},
            activeName: 'pending',
            selectionList: [],
        }
    },

    created () {
        this.lazyDeptTree()
        // this.onLoad(this.page)
    },

    computed: {
        ...mapGetters(["permission", 'colorName','userInfo']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.thirdAudit_add, true),
                viewBtn: this.vaildData(this.permission.thirdAudit_view, true),
                editBtn: this.vaildData(this.permission.thirdAudit_edit, true),
                delBtn: this.vaildData(this.permission.thirdAudit_delete, true),
            };
        },

        headBtnOptions() {
            let buttonBtn = [];
            if(this.activeName == 'pending'){
                buttonBtn.push({
                    label: "批量通过",
                    emit: "head-batch-pass",
                    type: "button",
                    icon: "",
                    btnOptType: 'batch-pass',
                });
            }
            return buttonBtn;
        },

        tableOptions () {
            return{
                customRowAddBtn: false,
                menuWidth: 160,
                selection: true,
                column: [
                    {
                        label: "姓名",
                        prop: "userName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "性别",
                        prop: "sex",
                        align: "center",
                        type: 'string',
                        props: {
                            label: "dictValue",
                            value: "dictKey",
                        },
                        dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
                    },
                    {
                        label: "身份证号",
                        prop: "idCoding",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "归属项目",
                        prop: "prjName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "状态",
                        prop: "auditStatus",
                        align: "center",
                        overHidden: true,
                        dicData: [
                            {
                                label: '未审核',
                                value: 0
                            },
                            {
                                label: '审核通过',
                                value: 1
                            },
                            {
                                label: '拒绝',
                                value: 2
                            }
                        ],
                    },
                ],
            }
        },
        gridRowBtn(row) {
            let buttonBtn = [];
            if (this.activeName == 'pending' || this.activeName == 'refuse') {
                buttonBtn.push(
                {
                    label: '通过',
                    emit: "row-pass",
                    type: "button",
                    icon: ""
                });
            }
            if(this.activeName == 'pending' || this.activeName == 'passed'){
                buttonBtn.push(
                {
                    label: '拒绝',
                    emit: "row-refuse",
                    type: "button",
                    icon: ""
                });
            }
            return buttonBtn;
        },

    },

    methods: {
        /** 左侧列表操作 */
        //获取树数据
        lazyDeptTree() {
            selectUserChildrenWithoudProject().then((res) => {
                this.treeData = res.data.data;
                this.nodeData = res.data.data;
                this.$nextTick(() => {
                this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                    this.nodeData.id
                );
                this.handleNodeClick(this.nodeData);
                });
            });
        },
        handleNodeClick (data,node) {
            this.nodeData = data
            this.page.currentPage = 1;
            this.query.prjId = data.id
            this.onLoad(this.page)
        },


        /**----end---- */
        /** 右侧表格操作 */
        handleClick (str) {
            this.onLoad(this.page)
        },
        onLoad(page, params = {}) {
            this.tableLoading = true;
            this.page = page;
            const tabName = this.activeName
            this.query.auditStatus = tabName == 'pending' ? 0 : tabName == 'passed' ? 1 : tabName == 'refuse' ? 2 : ''
            prjuserregisterPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.tableData = data.records ? data.records.map(val => {
                    val.sex = val.sex.toString()
                    return val
                }) : []
                this.tableLoading = false;
                this.$refs.gridLayOut.selectionClear()
            });
        },
        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        // 搜索
        gridHeadSearch(query) {
            const data = this.query
            this.page.currentPage = 1;
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },
        // 重置
        gridHeadEmpty(query) {
            this.page.currentPage = 1;
            const data = this.query
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },

        selectionChange (data) {
            this.selectionList = data
        },

        handleBacthPass () {
            if(this.selectionList.length){
                console.log(this.selectionList)
                const ids = this.selectionList.map(val=>val.id)
                const params = ids.map(val=>{
                    return {
                        id: val,
                        auditStatus: 1,
                    }
                })
                prjuserregisterUpdateBatch(params).then(() => {
                    this.$message.success('操作成功')
                    this.onLoad(this.page)
                })
            } else {
                this.$message.warning("请选择需审批数据")
            }
        },

        handlePass (row) {
            this.handleAudit(row, 1)
        },
        handleRefuse (row) {
            this.handleAudit(row, 2)
        },

        handleAudit (row,auditStatus) {
            console.log(row)
            const title = auditStatus == 1 ? "审批通过" : '拒绝'
            this.$confirm(`是否${title}?`, {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
            }).then(() => {
                const param = {
                    id: row.id,
                    auditStatus,
                }
                prjuserregisterUpdate(param).then(() => {
                    this.$message.success('操作成功')
                    this.onLoad(this.page)
                })
                console.log(param)
            })

        }

    }
}
</script>

<style lang="scss" scoped>
.file-name{
    color: #6F91C1;
    cursor: pointer;
    text-decoration: underline;
}
::v-deep .el-tabs__header{
    margin: 0;
    background: #ffffff ;
  }
</style>
