<template>
  <div style="position: relative">
    <el-container>
      <CommonTree
        ref="commonTreeCore"
        treeTitle="组织架构"
        :searchTitle="searchTitle"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @getNodeClick="handleNodeClick"
        :isShowdig="false"
        :showCheckbox="false"
        node-key="id"
        :isZoom="true"
        :show="show"
        @showChange="showChange"
      />
      <el-main>
        <head-layout
          head-title="安全生产标准化企业申报"
          :head-btn-options="headBtnOptions"
          @head-add="handleHeadAdd"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @row-edit="rowEdit"
          @row-del="rowRemove"
        >
          <template #meetingName="{ row }">
            <el-link type="primary" @click="rowView(row)">{{
              row.meetingName
            }}</el-link>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
      :title="
        type == 'add'
          ? '新增申报'
          : type == 'view'
          ? '查看申报详情'
          : '编辑申报'
      "
      :close-on-click-modal="false"
      :visible.sync="visible"
      lock-scroll
      width="1000px"
    >
      <div class="main-content" v-loading="saveLoading">
        <el-form
          :model="form"
          ref="form"
          label-width="100px"
          :rules="rules"
          :disabled="type == 'view'"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="企业名称" prop="orgName">
                <!-- <el-input
                  v-model="form.orgName"
                  placeholder="请输入企业名称"
                  :maxlength="255"
                  disabled
                ></el-input> -->
                <div>{{ form.orgName }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业类型" prop="orgTypeName">
                <el-select
                  v-model="orgType"
                  placeholder="请选择企业类型"
                  value-key="dictKey"
                  @change="orgTypeChange"
                >
                  <el-option
                    v-for="item in orgTypes"
                    :label="item.dictValue"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="级别" prop="safetyLevelName">
                <el-select
                  v-model="level"
                  placeholder="请选择级别"
                  value-key="dictKey"
                  @change="levelChange"
                >
                  <el-option
                    v-for="item in levels"
                    :label="item.dictValue"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="申请年度" prop="safetyDate">
                <el-date-picker
                  v-model="form.safetyDate"
                  value-format="yyyy"
                  type="year"
                  placeholder="选择申请年度"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  type="textarea"
                  placeholder="请输入备注"
                  v-model="form.remark"
                  maxlength="255"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button
          size="small"
          @click="visible = false"
          v-loading="saveLoading"
        >
          取消
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="saveLoading"
          v-if="type != 'view'"
        >
          保存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 安全会议
import CommonTree from "@/views/components/com_tree/index";
// import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  getList,
  addList,
  removeList,
  getDetail,
} from "@/api/setting/safetyProduction/index";
import { getDictionaryCode } from "@/api/system/dictbiz";
import { mapGetters } from "vuex";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import { deptChildTree } from "@/api/reportTemplate";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    UserDetpDialog,
  },

  data() {
    return {
      visible: false,
      nodeData: {},
      treeData: [],
      form: {
        orgName: "",
        safetyDate: String(new Date().getFullYear()),
      },
      levels: [],
      orgTypes: [],
      level: {},
      orgType: {},
      type: "add",
      orgId: "",
      searchTitle: "deptName",
      defaultProps: {
        children: "children",
        label: "deptName",
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      total: 0,
      query: {},
      show: true,
      rules: {
        orgTypeName: [
          { required: true, message: "请选择企业类型", trigger: "change" },
        ],
        safetyLevelName: [
          {
            required: true,
            message: "请选择级别",
            trigger: "change",
          },
        ],
        safetyDate: [
          {
            required: true,
            message: "请选择年度",
            trigger: "change",
          },
        ],
      },
    };
  },

  created() {
    this.lazyDeptTree();
    getDictionaryCode("org_type").then((res) => {
      this.orgTypes = res.data.data;
    });
    getDictionaryCode("safety_level").then((res) => {
      this.levels = res.data.data;
    });
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
      };
    },
    searchColumns() {
      return [
        // {
        //   prop: "meetingName",
        //   placeholder: "会议名称",
        //   span: 4,
        // },
        // {
        //   prop: "meetingDate",
        //   placeholder: "会议时间",
        //   span: 4,
        //   type: "date-format",
        // },
      ];
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: "add",
      });
      return buttonBtn;
    },

    gridRowBtn() {
      let buttonBtn = [];
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-del",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    tableOptions() {
      return {
        menuWidth: 260,
        selection: false,
        linklabel: "safetyDate",
        column: [
          {
            label: "申报年度",
            prop: "safetyDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "企业类型",
            prop: "orgTypeName",
            align: "center",
            overHidden: true,
          },
          {
            label: "级别",
            prop: "safetyLevelName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },

  methods: {
    orgTypeChange() {
      //   this.form.orgType = this.orgType.dictKey;
      //   this.form.orgTypeName = this.orgType.dictValue;
      this.$set(this.form, "orgType", this.orgType.dictKey);
      this.$set(this.form, "orgTypeName", this.orgType.dictValue);
    },
    levelChange() {
      //   this.form.safetyLevel = this.level.dictKey;
      //   this.form.safetyLevelName = this.level.dictValue;
      this.$set(this.form, "safetyLevel", this.level.dictKey);
      this.$set(this.form, "safetyLevelName", this.level.dictValue);
    },
    handleSave() {
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          addList({ ...this.form })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              }
              this.saveLoading = false;
              this.visible = false;
              this.onLoad(this.page);
            })
            .catch((error) => {
              this.$message({
                message: "保存失败",
                type: "warning",
              });
              this.saveLoading = false;
              this.visible = false;
            });
        }
      });
    },
    showChange(v) {
      this.show = v;
    },
    /** 左侧列表操作 */
    //获取树数据
    lazyDeptTree() {
      deptChildTree().then((res) => {
        this.treeData = [res.data.data];
        this.nodeData = res.data.data;
        this.$nextTick(() => {
          this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
            this.nodeData.id
          );
          this.handleNodeClick(this.nodeData);
        });
      });
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.page.currentPage = 1;
      this.total = 0;
      this.form.orgName = this.nodeData.deptName;
      this.form.orgId = this.nodeData.id;
      this.orgId = data.id;
      this.onLoad(this.page);
    },

    /**----end---- */
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      let parmas = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        orgId: this.orgId,
      };
      getList(parmas)
        .then((res) => {
          const data = res.data.data;
          this.page.total = data.total;
          this.$nextTick(() => {
            this.$refs.gridLayOut.page.total = data.total;
          });
          this.tableData = data.records;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 编辑
    rowEdit(row, index) {
      getDetail({ id: row.id }).then((res) => {
        this.form = res.data.data;
        this.orgType = {
          dictKey: this.form.orgType,
          dictValue: this.form.orgTypeName,
        };
        this.level = {
          dictKey: this.form.safetyLevel,
          dictValue: this.form.safetyLevelName,
        };
        this.type = "edit";
        this.visible = true;
      });
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeList({ ids: row.id }).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    rowSave(row, index) {},
    //查看详情
    rowView(row) {
      getDetail({ id: row.id }).then((res) => {
        this.form = res.data.data;
        this.orgType = {
          dictKey: this.form.orgType,
          dictValue: this.form.orgTypeName,
        };
        this.level = {
          dictKey: this.form.safetyLevel,
          dictValue: this.form.safetyLevelName,
        };
        this.type = "view";
        this.visible = true;
      });
    },

    handleHeadAdd() {
      if (this.nodeData.id) {
        this.form = {
          orgName: "",
          safetyDate: String(new Date().getFullYear()),
        };
        this.$set(this.form, "orgName", this.nodeData.deptName);
        this.$set(this.form, "orgId", this.nodeData.id);
        this.orgType = {};
        this.level = {};
        // this.$set(this.form, "safetyDate", new Date().getFullYear());
        console.log(this.form);
        this.type = "add";
        this.visible = true;
      } else {
        this.$message.warning("请先选择左侧组织机构");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-name {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
</style>
