var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flow-form",
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "90px",
            disabled: _vm.setting.readonly,
          },
        },
        [
          _c(
            "el-row",
            [
              _vm.judgeShow("customerName")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称", prop: "customerName" } },
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              "fetch-suggestions": _vm.querySearchAsync,
                              placeholder: "请输入客户名称",
                              disabled: _vm.judgeWrite("customerName"),
                            },
                            on: { select: _vm.handleSelect },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ item }) {
                                    return [
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(_vm._s(item.text)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2715730703
                            ),
                            model: {
                              value: _vm.dataForm.customerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "customerName", $$v)
                              },
                              expression: "dataForm.customerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("salesmanId")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务人员", prop: "salesmanId" } },
                        [
                          _c("user-select", {
                            attrs: {
                              placeholder: "选择人员",
                              disabled: _vm.judgeWrite("salesmanId"),
                            },
                            on: { change: _vm.onChange },
                            model: {
                              value: _vm.dataForm.salesmanId,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "salesmanId", $$v)
                              },
                              expression: "dataForm.salesmanId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("orderDate")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单日期", prop: "orderDate" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期",
                              "value-format": "timestamp",
                              format: "yyyy-MM-dd HH:mm",
                              editable: false,
                              disabled: _vm.judgeWrite("orderDate"),
                            },
                            model: {
                              value: _vm.dataForm.orderDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "orderDate", $$v)
                              },
                              expression: "dataForm.orderDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("orderCode")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单编码" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "订单编码",
                              readonly: "",
                              disabled: _vm.judgeWrite("orderCode"),
                            },
                            model: {
                              value: _vm.dataForm.orderCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "orderCode", $$v)
                              },
                              expression: "dataForm.orderCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("paymentMode")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "付款方式", prop: "paymentMode" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "选择付款方式",
                                disabled: _vm.judgeWrite("paymentMode"),
                              },
                              model: {
                                value: _vm.dataForm.paymentMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "paymentMode", $$v)
                                },
                                expression: "dataForm.paymentMode",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("receivableMoney")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "付款金额" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "付款金额",
                              disabled: _vm.judgeWrite("receivableMoney"),
                            },
                            model: {
                              value: _vm.dataForm.receivableMoney,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm,
                                  "receivableMoney",
                                  _vm._n($$v)
                                )
                              },
                              expression: "dataForm.receivableMoney",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("earnestRate")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "定金比率" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "定金比率",
                              type: "number",
                              disabled: _vm.judgeWrite("earnestRate"),
                            },
                            model: {
                              value: _vm.dataForm.earnestRate,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "earnestRate", $$v)
                              },
                              expression: "dataForm.earnestRate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("prepayEarnest")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "预付定金" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "预付定金",
                              type: "number",
                              disabled: _vm.judgeWrite("prepayEarnest"),
                            },
                            model: {
                              value: _vm.dataForm.prepayEarnest,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "prepayEarnest", $$v)
                              },
                              expression: "dataForm.prepayEarnest",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("transportMode")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "运输方式" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "选择运输方式",
                                disabled: _vm.judgeWrite("transportMode"),
                              },
                              model: {
                                value: _vm.dataForm.transportMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "transportMode", $$v)
                                },
                                expression: "dataForm.transportMode",
                              },
                            },
                            _vm._l(_vm.transportOptions, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("deliveryDate")
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货日期" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              "value-format": "timestamp",
                              editable: false,
                              disabled: _vm.judgeWrite("deliveryDate"),
                            },
                            model: {
                              value: _vm.dataForm.deliveryDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "deliveryDate", $$v)
                              },
                              expression: "dataForm.deliveryDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("deliveryAddress")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货地址" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "发货地址",
                              disabled: _vm.judgeWrite("deliveryAddress"),
                            },
                            model: {
                              value: _vm.dataForm.deliveryAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "deliveryAddress", $$v)
                              },
                              expression: "dataForm.deliveryAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("fileJson")
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "相关附件", prop: "fileJson" } },
                        [
                          _c("JNPF-UploadFz", {
                            attrs: {
                              type: "workFlow",
                              disabled: _vm.judgeWrite("fileJson"),
                            },
                            model: {
                              value: _vm.fileList,
                              callback: function ($$v) {
                                _vm.fileList = $$v
                              },
                              expression: "fileList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("description")
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单备注" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "订单备注",
                              type: "textarea",
                              rows: 3,
                              disabled: _vm.judgeWrite("description"),
                            },
                            model: {
                              value: _vm.dataForm.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "description", $$v)
                              },
                              expression: "dataForm.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.formOperates
        ? _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "订单商品", name: "goods" } },
                    [
                      _vm.judgeShow("goodsList")
                        ? _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.dataForm.goodsList,
                                size: "mini",
                                "show-summary": "",
                                "summary-method": _vm.getSummaries,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  width: "50",
                                  label: "序号",
                                  align: "center",
                                },
                              }),
                              _vm.judgeShow("goodsList-goodsName")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "goodsName",
                                        label: "商品名称",
                                      },
                                    },
                                    [
                                      _vm.judgeRequired("goodsList-goodsName")
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("商品名称\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-specifications")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "specifications",
                                        label: "规格型号",
                                        width: "80",
                                      },
                                    },
                                    [
                                      _vm.judgeRequired(
                                        "goodsList-specifications"
                                      )
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("规格型号\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-unit")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "unit",
                                        label: "单位",
                                        width: "80",
                                      },
                                    },
                                    [
                                      _vm.judgeRequired("goodsList-unit")
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("单位\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-qty")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "qty",
                                        label: "数量",
                                        width: "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "goodsList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "goodsList-qty"
                                                      ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.count(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.qty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "qty",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "scope.row.qty",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3967362392
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired("goodsList-qty")
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("数量\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-price")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "price",
                                        label: "单价",
                                        width: "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "goodsList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "goodsList-price"
                                                      ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.count(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "price",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.price",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1370397112
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired("goodsList-price")
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("单价\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-amount")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "amount",
                                        label: "金额",
                                        width: "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    readonly: "",
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "goodsList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "goodsList-amount"
                                                      ),
                                                  },
                                                  model: {
                                                    value: scope.row.amount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "amount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.amount",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1131456238
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired("goodsList-amount")
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("金额\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-discount")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "discount",
                                        label: "折扣%",
                                        width: "90",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input-number", {
                                                  attrs: {
                                                    precision: 2,
                                                    step: 0.1,
                                                    min: 0,
                                                    max: 100,
                                                    "controls-position":
                                                      "right",
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "goodsList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "goodsList-discount"
                                                      ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.count(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.discount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "discount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.discount",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1091029670
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired("goodsList-discount")
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("折扣%\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-cess")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "cess",
                                        label: "税率%",
                                        width: "90",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input-number", {
                                                  attrs: {
                                                    precision: 2,
                                                    step: 0.1,
                                                    min: 0,
                                                    max: 100,
                                                    "controls-position":
                                                      "right",
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "goodsList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "goodsList-cess"
                                                      ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.count(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.cess,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "cess",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.cess",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        36356742
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired("goodsList-cess")
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("税率%\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-actualPrice")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "actualPrice",
                                        label: "实际单价",
                                        width: "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    readonly: "",
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "goodsList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "goodsList-actualPrice"
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.actualPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "actualPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.actualPrice",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2611988750
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired("goodsList-actualPrice")
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("实际单价\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-actualAmount")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "actualAmount",
                                        label: "实际金额",
                                        width: "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    readonly: "",
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "goodsList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "goodsList-actualAmount"
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.actualAmount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "actualAmount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.actualAmount",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2152725550
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired(
                                        "goodsList-actualAmount"
                                      )
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("实际金额\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("goodsList-description")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "description",
                                        label: "备注",
                                        width: "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "goodsList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "goodsList-description"
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.description,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.description",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2429198782
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired("goodsList-description")
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("备注\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              !_vm.setting.readonly &&
                              !_vm.judgeWrite("goodsList")
                                ? _c("el-table-column", {
                                    attrs: { label: "操作", width: "50" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "JNPF-table-delBtn",
                                                  attrs: {
                                                    size: "mini",
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDel(
                                                        scope.$index,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4217417801
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.setting.readonly && !_vm.judgeWrite("goodsList")
                        ? _c(
                            "div",
                            {
                              staticClass: "table-actions",
                              on: { click: _vm.choice },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                },
                                [_vm._v("新增商品")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "收款计划", name: "plan" } },
                    [
                      _vm.judgeShow("collectionPlanList")
                        ? _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.dataForm.collectionPlanList,
                                size: "mini",
                                "show-summary": "",
                                "summary-method": _vm.getSummaries,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  width: "50",
                                  label: "序号",
                                  align: "center",
                                },
                              }),
                              _vm.judgeShow("collectionPlanList-receivableDate")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "receivableDate",
                                        label: "收款日期",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    type: "date",
                                                    "value-format": "timestamp",
                                                    editable: false,
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "collectionPlanList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "collectionPlanList-receivableDate"
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.receivableDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "receivableDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.receivableDate",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2613549965
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired(
                                        "collectionPlanList-receivableDate"
                                      )
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("收款日期\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("collectionPlanList-receivableRate")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "receivableRate",
                                        label: "收款比率%",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input-number", {
                                                  attrs: {
                                                    precision: 2,
                                                    step: 0.1,
                                                    min: 0,
                                                    max: 100,
                                                    "controls-position":
                                                      "right",
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "collectionPlanList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "collectionPlanList-receivableRate"
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.receivableRate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "receivableRate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.receivableRate",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2874205760
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired(
                                        "collectionPlanList-receivableRate"
                                      )
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("收款比率%\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow(
                                "collectionPlanList-receivableMoney"
                              )
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "receivableMoney",
                                        label: "收款金额",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "collectionPlanList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "collectionPlanList-receivableMoney"
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.receivableMoney,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "receivableMoney",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.receivableMoney",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3311984286
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired(
                                        "collectionPlanList-receivableMoney"
                                      )
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("收款金额\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("collectionPlanList-receivableMode")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "receivableMode",
                                        label: "收款方式",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "collectionPlanList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "collectionPlanList-receivableMode"
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.receivableMode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "receivableMode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.receivableMode",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2032304638
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired(
                                        "collectionPlanList-receivableMode"
                                      )
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("收款方式\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.judgeShow("collectionPlanList-abstract")
                                ? _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        prop: "abstract",
                                        label: "收款摘要",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.judgeWrite(
                                                        "collectionPlanList"
                                                      ) ||
                                                      _vm.judgeWrite(
                                                        "collectionPlanList-abstract"
                                                      ),
                                                  },
                                                  model: {
                                                    value: scope.row.abstract,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "abstract",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.abstract",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        608805406
                                      ),
                                    },
                                    [
                                      _vm.judgeRequired(
                                        "collectionPlanList-abstract"
                                      )
                                        ? _c("template", { slot: "header" }, [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("收款摘要\n            "),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              !_vm.setting.readonly &&
                              !_vm.judgeWrite("collectionPlanList")
                                ? _c("el-table-column", {
                                    attrs: { label: "操作", width: "50" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "JNPF-table-delBtn",
                                                  attrs: {
                                                    size: "mini",
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDelPlan(
                                                        scope.$index,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4173220250
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.setting.readonly &&
                      !_vm.judgeWrite("collectionPlanList")
                        ? _c(
                            "div",
                            {
                              staticClass: "table-actions",
                              on: { click: _vm.addPlan },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                },
                                [_vm._v("新增计划")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.goodsBoxVisible
        ? _c("GoodsBox", {
            ref: "goodsBox",
            on: { refreshDataList: _vm.initList },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }