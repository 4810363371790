var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogShow
    ? _c(
        "el-dialog",
        {
          staticClass: "inspection-dialog",
          attrs: {
            title: "安全巡检执行统计",
            visible: _vm.dialogShow,
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "inspection-dialog-main" },
            [
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.initData,
                  "grid-head-empty": _vm.resetData,
                },
              }),
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "图表", name: "first" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "表示", name: "second" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "巡检记录", name: "third" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "不合格记录", name: "fourth" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeName == "first",
                      expression: "activeName == 'first'",
                    },
                  ],
                  staticClass: "chart-container",
                  attrs: { id: "chart-container" },
                },
                [
                  _c("p", { staticClass: "chart-title" }, [
                    _vm._v(
                      _vm._s(_vm.timeRange[0]) +
                        "至" +
                        _vm._s(_vm.timeRange[1]) +
                        "一岗双责执行统计"
                    ),
                  ]),
                  _c("p", { staticClass: "child-title" }, [
                    _vm._v("安全检查：" + _vm._s(_vm.resObj.sum) + "次，"),
                    _c("span", { staticClass: "green" }, [
                      _vm._v(
                        "隐患整改完成：" + _vm._s(_vm.resObj.finishNum) + "项，"
                      ),
                    ]),
                    _c("label", { staticClass: "red" }, [
                      _vm._v(
                        "待整改总计：" + _vm._s(_vm.resObj.unrectifyNum) + "项"
                      ),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "chart-main",
                    attrs: { id: "chart-main" },
                  }),
                ]
              ),
              _vm.activeName != "first"
                ? _c("grid-layout", {
                    key: _vm.activeName,
                    ref: "gridLayout",
                    attrs: {
                      tableOptions: _vm.tableOptions,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                    },
                    on: {
                      "page-size-change": _vm.initData,
                      "page-current-change": _vm.initData,
                      "page-refresh-change": _vm.initData,
                      "grid-row-click": _vm.rowClick,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              row.hiddenDangerId
                                ? [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.toadd(row, "view")
                                          },
                                        },
                                      },
                                      [_vm._v("查看整改记录")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.toRelevance(row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改关联记录")]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.toadd(row, "add")
                                          },
                                        },
                                      },
                                      [_vm._v("发起违章整改")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.toRelevance(row)
                                          },
                                        },
                                      },
                                      [_vm._v("关联整改记录")]
                                    ),
                                  ],
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1650205380
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("detail", { ref: "detail" }),
          _c("hiddenList", {
            ref: "hiddenList",
            attrs: { checkedProject: _vm.checkedProject },
            on: { bindBack: _vm.resetData },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }