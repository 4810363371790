<template>
  <el-main>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="this.type == 'edit' ? $t('cip.plat.sys.param.title.editHeadTitle') : ( this.type == 'add' ?$t('cip.plat.sys.param.title.addHeadTitle'):$t('cip.plat.sys.param.title.viewHeadTitle'))"
                 @head-save="headSave()"
                 @head-save-cancel="headSave(true)"
                 @head-cancel="headCancel">
    </head-layout>
    <form-layout :column.sync="column" ref="formLayout" :dataForm.sync="dataForm" v-loading="formLoading"></form-layout>
  </el-main>
</template>
<script>
import {getDetail, add as submit} from "@/api/system/param";
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";

export default {
  components: {FormLayout, HeadLayout},
  data() {
    return {
      imageUrl: '',
      isClose: false,
      itemData: [],
      viewFlag: true,//是否展示按钮
      formLoading: false,
      type: 'view',
      dataForm: {},
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission", "language"]),
    headBtnOptions() {
      let res = [];
      if (['edit', 'add'].includes(this.type)) {
        res.push({
          btnOptType: 'save',
          label: this.$t('cip.cmn.btn.saveBtn'),
          emit: 'head-save',
        });
        res.push({
          btnOptType: 'saveBack',
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: 'head-save-cancel',
        });
      }
      res.push({
        btnOptType: 'cancel',
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: 'head-cancel',
      });
      return res;
    },
    column() {
      let validatePass = (rule, value, callback) => {
        if (value != '') {
          let val = JSON.parse(value)
          let language = this.language
          let val2 = val[language]
          if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
            callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
          }
          if (val2.length > 100) {
            callback(new Error(this.$t('cip.cmn.rule.nameLength10Warning')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t("cip.plat.sys.param.field.paramName") + this.$t('cip.cmn.rule.noEmptyWarning')));
        }
      };
      return [
        {
          label: this.$t("cip.plat.sys.param.field.paramName"),
          prop: "paramName",
          search: true,
          span: 8,
          isGlobal: true,
          readonly: this.viewFlag,
          rules: [{required: true, validator: validatePass, trigger: ['blur', 'change']}],
          placeholder: this.$t("cip.plat.sys.param.field.paramName"),
        },
        {
          label: this.$t("cip.plat.sys.param.field.paramKey"),
          prop: "paramKey",
          search: true,
          span: 8,
          maxlength: 100,
          showWordLimit: true,
          readonly: this.viewFlag,
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.param.field.paramKey"),
            trigger: "blur"
          },
          ],
          placeholder: this.$t("cip.plat.sys.param.field.paramKey"),
        },
        {
          label: this.$t("cip.plat.sys.param.field.paramValue"),
          prop: "paramValue",
          type: "textarea",
          maxlength: 200,
          showWordLimit: true,
          span: 16,
          readonly: this.viewFlag,
          minRows: 6,
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.param.field.paramValue"),
            trigger: "blur"
          },
          ],
          placeholder: this.$t("cip.plat.sys.param.field.paramValue"),
        }
      ];
    },

  },
  methods: {
    //保存
    headSave(clo) {
      this.dataForm.paramKey = this.dataForm.paramKey.trim()
      this.dataForm.paramValue = this.dataForm.paramValue.trim()
      this.$refs.formLayout.getForm().validate(valid => {
        if (valid) {
          this.$loading()
          submit(this.dataForm).then(res => {
            let {
              msg,
              data,
            } = res.data;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            this.dataForm = data;
            this.type = 'edit';
            if (clo) {
              this.close()
            }
          }).finally(() => {
            this.$refs.formLayout.$refs.form.allDisabled = false;
            this.$loading().close()
          })
        }
      })
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    formData(id) {
      //加载主表信息
      getDetail(id).then(res => {
        this.dataForm = res.data.data;
      })
    },
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type == 'add') {
      this.viewFlag = false;
    } else if (this.type == 'edit') {
      this.viewFlag = false;
      this.formData(this.$route.query.id);
    } else if (this.type == 'view') {
      this.viewFlag = true;
      this.formData(this.$route.query.id);
    }
  },
};
</script>

<style scoped>

</style>
