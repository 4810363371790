<template>
  <el-container style="position: relative">
    <!-- <CommonTree
      ref="commonTreeCore"
      treeTitle="组织架构"
      :searchTitle="searchTitle"
      :treeData="treeData"
      :defaultProps="defaultProps"
      @getNodeClick="handleNodeClick"
      :isShowdig="false"
      :showCheckbox="false"
      node-key="id"

    /> -->
    <CommonTreeNew
      ref="commonTree"
      treeTitle="项目架构"
      :defaultProps="defaultProps"
      :showCheckbox="false"
      @getNodeClick="handleNodeClick"
      :urlParmas="{
        tenantId: '',
        deptCategory: '2,5',
        parentId: userInfo.deptId,
      }"
      node-key="id"
      @getTreeData="commonTreeData"
    ></CommonTreeNew>
    <el-main>
      <head-layout
        head-title="告警处理"
        :head-btn-options="headBtnOptions"
        @batch-confirm="rowAlarmConfirm"
      />
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      >
      </grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOptions"
        :table-data="tableData"
        :table-loading="tableLoading"
        :data-total="page.total"
        :page="page"
        @grid-row-detail-click="rowView"
        @page-current-change="handleCurrentChange"
        @page-size-change="handleSizeChange"
        @page-refresh-change="onLoad"
        @gird-handle-select-click="selectionChange"
      >
        <template slot="levelCode" slot-scope="{ row }">
          <span
            :class="
              row.levelCode == 'important' || row.levelCode == 'urgent'
                ? 'redCloumn'
                : ''
            "
            >{{ getDicLableByValue(levelCodeList, row.levelCode) }}</span
          >
        </template>
        <template slot="status" slot-scope="{ row }">
          <span :class="row.status ? '' : 'redCloumn'">{{
            getDicLableByValue(statusList, row.status)
          }}</span>
        </template>
        <template slot="processResult" slot-scope="{ row }">
          <span :class="row.processResult == '3' ? 'redCloumn' : ''">{{
            getDicLableByValue(processResultList, row.processResult)
          }}</span>
        </template>
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            v-if="!row.status"
            type="text"
            size="small"
            @click="rowAlarmConfirm(row)"
          >
            告警确认
          </el-button>
          <el-button
            style="margin: 0 3px"
            type="text"
            v-if="row.status"
            size="small"
            @click="rowView(row)"
          >
            查看
          </el-button>
        </template>
      </grid-layout>
      <el-dialog
        :title="title"
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        :width="opType != 'batch' ? '58%' : '45%'"
        class="qmDialog"
        @close="closeDialog"
      >
        <template>
          <div class="formBox">
            <div
              class="formBox-left"
              v-if="opType == 'single' || opType == 'view'"
            >
              <el-carousel v-if="currentRow.pics" indicator-position="none">
                <el-carousel-item
                  v-for="item in currentRow.pics.split(',')"
                  :key="item"
                >
                  <img :src="item" alt="" />
                </el-carousel-item>
              </el-carousel>
              <div v-else class="noImg-box" style="width: 100%; height: 100%">
                <img src="@/assets/images/noImg.png" alt="" />
                <span class="noImg-text">暂无图片</span>
              </div>
            </div>
            <div
              :class="
                opType != 'batch'
                  ? 'formBox-right-yesImg formBox-right'
                  : 'formBox-right-noImg formBox-right'
              "
            >
              <el-form
                ref="addForm"
                :model="dataForm"
                :rules="rules"
                label-width="80px"
              >
                <template v-if="opType == 'single' || opType == 'view'">
                  <div class="formTop">
                    <div class="formTopic">
                      {{ currentRow.content }}
                    </div>
                    <div
                      v-if="opType == 'view' && dataForm.processResult"
                      :class="
                        dataForm.processResult == '3'
                          ? 'processResul-bg-item processResult'
                          : 'processResul-bg-item normal'
                      "
                    >
                      {{
                        getDicLableByValue(
                          processResultList,
                          currentRow.processResult
                        )
                      }}
                    </div>
                  </div>
                  <!-- <div
                    class="formTopic"
                    style="
                      font-weight: 600 !important;
                      font-size: 18px;
                      margin-bottom: 10px;
                    "
                  >
                    {{ currentRow.content }}
                  </div>
                  <div class="text-item">
                    <div class="text-label">所在项目</div>
                    <span class="text-content">{{
                      currentRow.projectName
                    }}</span>
                  </div>
                  <div class="text-item">
                    <div class="text-label">发生地点</div>
                    <span class="text-content">{{
                      currentRow.projectName
                    }}</span>
                  </div>
                  <div class="text-item">
                    <div class="text-label">告警时间</div>
                    <span class="text-content">{{ currentRow.alarmTime }}</span>
                  </div>
                  <div class="text-item">
                    <div class="text-label">告警类型</div>
                    <span class="text-content">{{ currentRow.typeCode }}</span>
                  </div>
                  <div class="text-item">
                    <div class="text-label">告警级别</div>
                    <span class="text-content">{{ currentRow.levelCode }}</span>
                  </div> -->
                  <el-form-item label="所在项目">
                    <el-input
                      v-model="dataForm.projectName"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="发生地点" v-if="opType != 'batch'">
                    <el-input v-model="dataForm.address" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="告警时间">
                    <el-input v-model="dataForm.alarmTime" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="告警类型">
                    <el-select
                      disabled
                      v-model="dataForm.typeCode"
                      placeholder="请选择告警类型"
                    >
                      <el-option
                        v-for="item in typeCodetList"
                        :key="item.dictKey"
                        :label="item.dictValue"
                        :value="item.dictKey"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="告警级别"
                    :class="
                      dataForm.levelCode == 'important' ||
                      dataForm.levelCode == 'urgent'
                        ? 'red-form-item'
                        : ''
                    "
                  >
                    <el-select
                      disabled
                      v-model="dataForm.levelCode"
                      placeholder="请选择告警级别"
                    >
                      <el-option
                        v-for="item in levelCodeList"
                        :key="item.dictKey"
                        :label="item.dictValue"
                        :value="item.dictKey"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
                <el-divider v-if="opType != 'batch'"></el-divider>
                <el-form-item label="响应时长" v-if="opType == 'view'">
                  <el-input
                    v-model="dataForm.processDuration"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item label="响应结果" prop="processResult">
                  <el-select
                    v-model="dataForm.processResult"
                    :placeholder="opType != 'view' ? '请选择活响应结果' : ''"
                    :disabled="opType == 'view'"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in processResultList"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="处理时间" v-if="opType == 'view'">
                  <el-input v-model="dataForm.processTime" disabled></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="remark">
                  <el-input
                    v-model="dataForm.remark"
                    :disabled="opType == 'view'"
                    :placeholder="opType != 'view' ? '请输入描述' : ''"
                    type="textarea"
                    style="width: 100%"
                    maxlength="255"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </template>
        <span slot="footer">
          <el-button
            size="small"
            @click="closeDialog"
            v-loading="dialogLoading"
          >
            {{ $t(`cip.cmn.btn.celBtn`) }}
          </el-button>
          <el-button
            v-if="opType != 'view'"
            size="small"
            type="primary"
            @click="handleSave"
            v-loading="dialogLoading"
          >
            {{ $t(`cip.cmn.btn.defBtn`) }}
          </el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";

import { alarmHandlingPage, alarmHandlingSubmit } from "@/api/alarmCenter";
import { mapGetters } from "vuex";
import { dictionaryBiz } from "@/api/reportTasks";

import { deptChildTree } from "@/api/reportTemplate";
export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
  },

  data() {
    return {
      nodeData: {},
      treeData: [],
      searchTitle: "deptName",
      defaultProps: {
        children: "children",
        label: "title",
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "告警时间",
          prop: "dateRange",
          type: "daterange",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          span: 4,
          startPlaceholder: "告警开始日期",
          endPlaceholder: "告警结束日期",
        },
        {
          label: "告警类型",
          prop: "typeCode",
          span: 3,
          type: "select",
          filterable: true,
          placeholder: "请选择告警类型",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=alarm_type`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "告警级别",
          prop: "levelCode",
          span: 3,
          type: "select",
          placeholder: "请选择告警级别",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=alarm_level`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "响应状态",
          prop: "status",
          span: 3,
          dataType: "string",
          type: "select",
          placeholder: "请选择响应状态",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=alarm_status`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "响应结果",
          prop: "processResult",
          span: 3,
          type: "select",

          placeholder: "请选择响应结果",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=alarm_process_result`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],
      query: {},
      dialogVisible: false,
      selectionList: [],
      dataForm: {
        processResult: "",
        remark: "",
        processTime: "",
        processDuration: "",
        projectName: "",
        alarmTime: "",
        typeCode: "",
        levelCode: "",
        address: "",
      },
      rules: {
        processResult: [
          { required: true, message: "请选择响应结果", trigger: "change" },
        ],
      },
      opType: "",
      currentRow: {},
      isShowImg: false,
      processResultList: [],
      typeCodetList: [],
      levelCodeList: [],
      statusList: [],
    };
  },

  created() {
    dictionaryBiz("alarm_process_result").then((res) => {
      this.processResultList = res.data.data;
    });
    dictionaryBiz("alarm_type").then((res) => {
      this.typeCodetList = res.data.data;
    });
    dictionaryBiz("alarm_level").then((res) => {
      this.levelCodeList = res.data.data;
    });
    dictionaryBiz("alarm_status").then((res) => {
      this.statusList = res.data.data;
    });
    // this.lazyDeptTree();
  },
  computed: {
    ...mapGetters(["permission", "colorName", "userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.licenceEmployees_add, true),
        viewBtn: this.vaildData(this.permission.licenceEmployees_view, true),
      };
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        emit: "batch-confirm",
        type: "button",
        icon: "",
        btnOptType: "batchConfirm",
      });

      return buttonBtn;
    },

    tableOptions() {
      return {
        menuWidth: 160,
        menuFixed: "right",
        selectable: (row, index) => {
          return row.status ? false : true;
        },
        linklabel: "content",
        column: [
          {
            label: "所属项目",
            prop: "projectName",
            align: "center",
            minWidth: 160,
            overHidden: true,
          },
          {
            label: "告警内容",
            prop: "content",
            align: "center",
            width: 200,
            overHidden: true,
          },
          {
            label: "告警类型",
            prop: "typeCode",
            align: "center",
            overHidden: true,
            type: "select",
            minWidth: 160,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=alarm_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "告警时间",
            prop: "alarmTime",
            align: "center",
            width: 200,
            overHidden: true,
          },
          {
            label: "告警级别",
            prop: "levelCode",
            align: "center",
            overHidden: true,
            slot: true,
            type: "select",

            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=alarm_level",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "响应状态",
            prop: "status",
            align: "center",
            slot: true,
            dataType: "number",
            overHidden: true,
            type: "select",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=alarm_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "响应结果",
            prop: "processResult",
            align: "center",
            overHidden: true,
            slot: true,
            type: "select",
            dataType: "number",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=alarm_process_result",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
        ],
      };
    },
  },

  methods: {
    handleClose() {
      this.dialogVisible = false;
    },

    /** 左侧列表操作 */
    //获取树数据
    lazyDeptTree() {
      deptChildTree().then((res) => {
        this.treeData = [res.data.data];
        this.nodeData = res.data.data;
        this.$nextTick(() => {
          this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
            this.nodeData.id
          );
          this.handleNodeClick(this.nodeData);
        });
      });
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },

    getDicLableByValue(list, code) {
      return list.find((item) => item.dictKey == code)?.dictValue;
    },

    /**----end---- */
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      let query = { ...params };
      delete query.dateRange;
      alarmHandlingPage(
        page.currentPage,
        page.pageSize,
        Object.assign(query, { orgId: this.nodeData.id })
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      // if (
      //   this.$refs.gridHeadLayout.searchForm.dateRange &&
      //   this.$refs.gridHeadLayout.searchForm.dateRange.length != 0
      // ) {
      //   this.$refs.gridHeadLayout.searchForm.alarmStartTime =
      //     this.$refs.gridHeadLayout.searchForm.dateRange[0] + " 00:00:00";
      //   this.$refs.gridHeadLayout.searchForm.alarmEndTime =
      //     this.$refs.gridHeadLayout.searchForm.dateRange[1] + " 23:59:59";
      // } else {
      //   delete this.$refs.gridHeadLayout.searchForm.alarmStartTime;
      //   delete this.$refs.gridHeadLayout.searchForm.alarmEndTime;
      // }

      this.page.currentPage = 1;
      query.alarmStartTime = query.dateRange
        ? query.dateRange[0] + " 00:00:00"
        : "";
      query.alarmEndTime = query.dateRange
        ? query.dateRange[1] + " 23:59:59"
        : "";

      // const data = this.query;

      // this.query = { ...data, ...query };
      // this.onLoad(this.page);
      this.onLoad(this.page, query);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, query);
    },

    rowAlarmConfirm(row) {
      if (row) {
        this.title = "告警确认";
        this.opType = "single";
        this.currentRow = row;
        Object.keys(this.dataForm).forEach((key) => {
          this.dataForm[key] = row[key];
        });
        this.dataForm.processResult = "";
        this.dataForm.remark = "";
      } else {
        let selectionList = this.$refs.gridLayOut.selectionList;
        if (selectionList.length === 0) {
          this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
          return;
        }
        this.title = "批量确认";
        this.dataForm.processResult = "";
        this.dataForm.remark = "";
        this.opType = "batch";
      }
      this.dialogVisible = true;
    },
    rowView(row) {
      this.title = "告警详情";
      this.opType = "view";
      Object.keys(this.dataForm).forEach((key) => {
        this.$set(
          this.dataForm,
          key,
          key == "processResult"
            ? row[key] > 0
              ? String(row[key])
              : ""
            : row[key]
        );
      });

      this.currentRow = row;
      this.dialogVisible = true;
      this.$refs.addForm.clearValidate();
    },
    handleHeadAdd() {
      this.dialogVisible = true;
    },
    handleSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let queryData = {
            processResult: this.dataForm.processResult,
            remark: this.dataForm.remark,
            ids: this.opType == "single" ? this.currentRow.id : this.ids,
          };
          this.dialogLoading = true;

          alarmHandlingSubmit(queryData).then((res) => {
            const { msg, data } = res.data;
            if (res.data.code == 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
              this.page.currentPage = 1;
              this.onLoad(this.page);
              this.closeDialog();
              this.dialogLoading = false;
            } else {
              this.$message.success(msg);
            }
          });
        }
      });
    },

    closeDialog() {
      this.$refs.addForm.clearValidate();
      this.currentRow = {};
      Object.keys(this.dataForm).forEach((item) => {
        this.$set(this.dataForm, item, "");
      });
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.redCloumn {
  color: red;
}
.formBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  .formBox-left {
    width: 400px;
    height: 400px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep .el-carousel {
      width: 100%;
      height: 100%;
    }
    ::v-deep .el-carousel__container {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .noImg-box {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      img {
        width: 280px;
        height: 280px;
      }
      .noImg-text {
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #a1a2a2;
        margin-top: 10px;
      }
    }
  }
  .formBox-right-noImg {
    width: 100%;
  }
  .formBox-right {
    ::v-deep .el-form {
      width: 100%;
    }
    ::v-deep .el-textarea.is-disabled .el-textarea__inner {
      color: #606266 !important;
    }
    .red-form-item {
      ::v-deep .el-input.is-disabled .el-input__inner {
        color: red !important;
      }
    }
    ::v-deep .el-divider {
      // background-color: #F8F8F8;
      // background-color: #e6e6e6;
      background-color: #f2f2f2;
    }
    ::v-deep .el-divider--horizontal {
      margin: 20px 0 !important;
    }
  }
  .formBox-right-yesImg {
    width: calc(100% - 400px);
  }
  .formTop {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .formTopic {
      font-weight: bold;
      font-size: 18px;
      white-space: normal;
      padding-left: 12px;
    }
    .processResul-bg-item {
      width: 60px;
      text-align: center;
      color: #fff;
      padding: 3px 6px;
      margin-left: 12px;
    }
    .processResult {
      background-color: #f59a23;
    }
    .normal {
      background-color: #46a6ff;
    }
  }

  .text-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    .text-label::after {
      display: inline-block;
      content: ":";
    }
    .text-content {
      margin-left: 10px;
    }
  }
}
</style>
