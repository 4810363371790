var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flow-form",
    },
    [
      _c("div", { staticClass: "com-title" }, [
        _c("h1", [_vm._v("请假申请")]),
        _c("span", { staticClass: "number" }, [
          _vm._v("流程编码：" + _vm._s(_vm.dataForm.billNo)),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "100px",
            disabled: _vm.setting.readonly,
          },
        },
        [
          _c(
            "el-row",
            [
              _vm.judgeShow("flowTitle")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "流程标题", prop: "flowTitle" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "流程标题",
                              disabled: _vm.judgeWrite("flowTitle"),
                            },
                            model: {
                              value: _vm.dataForm.flowTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "flowTitle", $$v)
                              },
                              expression: "dataForm.flowTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("applyUser")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请人员", prop: "applyUser" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "申请人员",
                              readonly: "",
                              disabled: _vm.judgeWrite("applyUser"),
                            },
                            model: {
                              value: _vm.dataForm.applyUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "applyUser", $$v)
                              },
                              expression: "dataForm.applyUser",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("applyDate")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请日期", prop: "applyDate" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              "value-format": "timestamp",
                              format: "yyyy-MM-dd",
                              editable: false,
                              readonly: "",
                              disabled: _vm.judgeWrite("applyDate"),
                            },
                            model: {
                              value: _vm.dataForm.applyDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "applyDate", $$v)
                              },
                              expression: "dataForm.applyDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("applyDept")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请部门", prop: "applyDept" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "申请部门",
                              readonly: "",
                              disabled: _vm.judgeWrite("applyDept"),
                            },
                            model: {
                              value: _vm.dataForm.applyDept,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "applyDept", $$v)
                              },
                              expression: "dataForm.applyDept",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("applyPost")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请职位", prop: "applyPost" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "申请职位",
                              readonly: "",
                              disabled: _vm.judgeWrite("applyPost"),
                            },
                            model: {
                              value: _vm.dataForm.applyPost,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "applyPost", $$v)
                              },
                              expression: "dataForm.applyPost",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("leaveType")
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "请假类别", prop: "leaveType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.judgeWrite("leaveType") },
                              model: {
                                value: _vm.dataForm.leaveType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "leaveType", $$v)
                                },
                                expression: "dataForm.leaveType",
                              },
                            },
                            _vm._l(_vm.leaveTypeOptions, function (item) {
                              return _c(
                                "el-radio",
                                { key: item, attrs: { label: item } },
                                [_vm._v(_vm._s(item) + "\n            ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("leaveReason")
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "请假原因", prop: "leaveReason" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请假原因",
                              type: "textarea",
                              rows: 3,
                              disabled: _vm.judgeWrite("leaveReason"),
                            },
                            model: {
                              value: _vm.dataForm.leaveReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "leaveReason", $$v)
                              },
                              expression: "dataForm.leaveReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("leaveStartTime")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "起始时间", prop: "leaveStartTime" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期",
                              "value-format": "timestamp",
                              format: "yyyy-MM-dd HH:mm",
                              editable: false,
                              disabled: _vm.judgeWrite("leaveStartTime"),
                            },
                            model: {
                              value: _vm.dataForm.leaveStartTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "leaveStartTime", $$v)
                              },
                              expression: "dataForm.leaveStartTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("leaveEndTime")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "结束时间", prop: "leaveEndTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期",
                              "value-format": "timestamp",
                              format: "yyyy-MM-dd HH:mm",
                              editable: false,
                              disabled: _vm.judgeWrite("leaveEndTime"),
                            },
                            model: {
                              value: _vm.dataForm.leaveEndTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "leaveEndTime", $$v)
                              },
                              expression: "dataForm.leaveEndTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("leaveDayCount")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "请假天数", prop: "leaveDayCount" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请假天数",
                                disabled: _vm.judgeWrite("leaveDayCount"),
                              },
                              model: {
                                value: _vm.dataForm.leaveDayCount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "leaveDayCount", $$v)
                                },
                                expression: "dataForm.leaveDayCount",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("天"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("leaveHour")
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "请假小时", prop: "leaveHour" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请假小时",
                              disabled: _vm.judgeWrite("leaveHour"),
                            },
                            model: {
                              value: _vm.dataForm.leaveHour,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "leaveHour", $$v)
                              },
                              expression: "dataForm.leaveHour",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.judgeShow("fileJson")
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "相关附件", prop: "fileJson" } },
                        [
                          _c("JNPF-UploadFz", {
                            attrs: {
                              type: "workFlow",
                              disabled: _vm.judgeWrite("fileJson"),
                            },
                            model: {
                              value: _vm.fileList,
                              callback: function ($$v) {
                                _vm.fileList = $$v
                              },
                              expression: "fileList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }