<template>
<!--  <basic-container>-->
<!--    <avue-tabs :option="option" @change="handleChange"></avue-tabs>-->
<!--    <span v-if="type.prop === 'tab1'">-->
      <eduexam :type="type"></eduexam>
<!--    </span>-->
<!--    <span v-else-if="type.prop === 'tab2'">-->
<!--      <eduuserexam :type="type"></eduuserexam>-->
<!--    </span>-->
<!--  </basic-container>-->
</template>

<script>
import eduexam from "./eduexam";
// import eduuserexam from "./eduuserexam";

export default {
  data() {
    return {
      type: {},
      option: {
        column: [
          {
            icon: 'el-icon-date',
            label: '试卷发布',
            prop: 'tab1',
          },
          // {
          //   icon: 'el-icon-picture',
          //   label: '我的试卷',
          //   prop: 'tab2',
          // }
        ]
      }
    };
  },
  components: {eduexam},
  methods: {
    handleChange(column) {
      this.type = column
    }
  },
  created() {
      this.type = this.option.column[0];
  },
};
</script>
