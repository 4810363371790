var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content", staticStyle: { position: "relative" } },
    [
      _c(
        "el-container",
        [
          _c("CommonTreeNew", {
            ref: "commonTree",
            attrs: {
              treeTitle: "组织架构",
              defaultProps: _vm.defaultProps,
              showCheckbox: false,
              urlParmas: {
                tenantId: "",
                deptCategory: "2",
                parentId: _vm.userInfo.dept_id,
              },
              "node-key": "id",
              isShowdig: false,
              isZoom: true,
              show: _vm.show,
            },
            on: {
              getNodeClick: _vm.handleNodeClick,
              getTreeData: _vm.commonTreeData,
              showChange: _vm.showChange,
            },
          }),
          _c(
            "el-main",
            { staticStyle: { height: "100%" } },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "border-card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "企业证照", name: "license" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "企业安标", name: "declaration" },
                  }),
                ],
                1
              ),
              _vm.activeName == "license"
                ? _c(
                    "div",
                    { staticClass: "license" },
                    [
                      _c("head-layout", {
                        attrs: {
                          "head-title": "企业证照",
                          "head-btn-options": _vm.headBtnOptions,
                        },
                        on: { "head-upload": _vm.handleUpload },
                      }),
                      _vm.activeName == "license"
                        ? _c("grid-head-layout", {
                            key: "license",
                            ref: "gridHeadLayout",
                            attrs: { "search-columns": _vm.searchColumns },
                            on: {
                              "grid-head-search": _vm.gridHeadSearch,
                              "grid-head-empty": _vm.gridHeadEmpty,
                            },
                          })
                        : _vm._e(),
                      _c("grid-layout", {
                        ref: "gridLayOut",
                        attrs: {
                          "grid-row-btn": _vm.gridRowBtn,
                          "table-options": _vm.tableOptions,
                          "table-data": _vm.tableData,
                          "table-loading": _vm.tableLoading,
                          "data-total": _vm.page.total,
                          page: _vm.page,
                          editType: _vm.inline,
                        },
                        on: {
                          "page-current-change": _vm.handleCurrentChange,
                          "page-size-change": _vm.handleSizeChange,
                          "page-refresh-change": _vm.onLoad,
                          "row-view": _vm.rowView,
                          "row-download": _vm.rowDownload,
                          "row-edit": _vm.rowEdit,
                          "row-remove": _vm.rowRemove,
                          "row-setting": _vm.rowSetting,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "fileName",
                              fn: function ({ row, index }) {
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowView(row, index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getFileName(row.fileName))
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2583247367
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeName == "declaration"
                ? _c(
                    "div",
                    { staticClass: "declaration" },
                    [
                      _c("head-layout", {
                        attrs: {
                          "head-title": "企业安标",
                          "head-btn-options": _vm.orgheadBtnOptions,
                        },
                        on: { "head-add": _vm.orghandleHeadAdd },
                      }),
                      _vm.activeName == "declaration"
                        ? _c("grid-head-layout", {
                            key: "declaration",
                            ref: "gridHeadLayout1",
                            on: {
                              "grid-head-search": _vm.orggridHeadSearch,
                              "grid-head-empty": _vm.orggridHeadEmpty,
                            },
                          })
                        : _vm._e(),
                      _c("grid-layout", {
                        ref: "gridLayOut",
                        attrs: {
                          "grid-row-btn": _vm.orggridRowBtn,
                          "table-options": _vm.orgtableOptions,
                          "table-data": _vm.orgtableData,
                          "table-loading": _vm.orgtableLoading,
                          "data-total": _vm.orgpage.total,
                          page: _vm.orgpage,
                        },
                        on: {
                          "grid-row-detail-click": _vm.orgrowView,
                          "page-current-change": _vm.orghandleCurrentChange,
                          "page-size-change": _vm.orghandleSizeChange,
                          "page-refresh-change": _vm.orgonLoad,
                          "row-edit": _vm.orgrowEdit,
                          "row-del": _vm.orgrowRemove,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "meetingName",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.orgrowView(row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(row.meetingName))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2672256555
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "设置提醒", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.remaindForm, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "提前提醒天数" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1 },
                    model: {
                      value: _vm.remaindForm.dateRemind,
                      callback: function ($$v) {
                        _vm.$set(_vm.remaindForm, "dateRemind", $$v)
                      },
                      expression: "remaindForm.dateRemind",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通知人" } },
                [
                  _c("el-input", {
                    ref: "remaindFormUser",
                    on: { focus: _vm.changeUser },
                    model: {
                      value: _vm.remaindForm.noticeUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.remaindForm, "noticeUserName", $$v)
                      },
                      expression: "remaindForm.noticeUserName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "证件查看", visible: _vm.imageDiavlog },
          on: {
            "update:visible": function ($event) {
              _vm.imageDiavlog = $event
            },
          },
        },
        [
          _vm.fileUrl.type == "pdf"
            ? _c("pdf", { attrs: { src: _vm.fileUrl.src } })
            : _c("el-image", { attrs: { src: _vm.fileUrl.src } }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userVisible,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.type == "add"
                ? "新增"
                : _vm.type == "view"
                ? "查看申报详情"
                : "编辑",
            "close-on-click-modal": false,
            visible: _vm.visible,
            "lock-scroll": "",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.saveLoading,
                  expression: "saveLoading",
                },
              ],
              staticClass: "main-content",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "100px",
                    rules: _vm.rules,
                    disabled: _vm.type == "view",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业名称", prop: "orgName" } },
                            [_c("div", [_vm._v(_vm._s(_vm.form.orgName))])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "企业类型", prop: "orgTypeName" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择企业类型",
                                    "value-key": "dictKey",
                                  },
                                  on: { change: _vm.orgTypeChange },
                                  model: {
                                    value: _vm.orgType,
                                    callback: function ($$v) {
                                      _vm.orgType = $$v
                                    },
                                    expression: "orgType",
                                  },
                                },
                                _vm._l(_vm.orgTypes, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "级别", prop: "safetyLevelName" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择级别",
                                    "value-key": "dictKey",
                                  },
                                  on: { change: _vm.levelChange },
                                  model: {
                                    value: _vm.level,
                                    callback: function ($$v) {
                                      _vm.level = $$v
                                    },
                                    expression: "level",
                                  },
                                },
                                _vm._l(_vm.levels, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "取证日期", prop: "safetyDate" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd",
                                  type: "date",
                                  placeholder: "取证日期",
                                },
                                model: {
                                  value: _vm.form.safetyDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "safetyDate", $$v)
                                  },
                                  expression: "form.safetyDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入备注",
                                  maxlength: "255",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.saveLoading,
                      expression: "saveLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
              _vm.type != "view"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.saveLoading,
                          expression: "saveLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.orghandleSave },
                    },
                    [_vm._v("\n        保存\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("cedForm", { ref: "cedForm", on: { callback: _vm.callback } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }