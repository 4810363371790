<template>
  <div>
    <head-layout head-title="自定义课程" :head-btn-options="headBtnOptions">
    </head-layout>
    <grid-head-layout
      ref="searchForm"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset"
    />
    <grid-layout
      class="customCourses"
      ref="gridLayout"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      :searchColumns="searchColumns"
      @grid-row-detail-click="View"
    >
      <template #courseCover="{ row }">
        <img class="courseCoverImg" v-if="row.courseCover == ''" src="@/assets/training/trainingDefaultPicture.png">
        <img class="courseCoverImg" v-else :src="row.courseCover">
      </template>
      <template #customBtn="{ row, index }">
<!--        <el-button size="small" type="text" @click.stop="View(row, index)">-->
<!--          查看-->
<!--        </el-button>-->
        <el-button
          size="small"
          type="text"
          v-if="row.isTopping == 2 && row.status == 1"
          @click.stop="top(row, index)"
        >
          收藏
        </el-button>
        <el-button
          size="small"
          type="text"
          v-if="row.isTopping == 1"
          @click.stop="cancelTop(row, index)"
        >
          取消收藏
        </el-button>
        <el-button
          size="small"
          type="text"
          @click.stop="generationTask(row, index)"
        >
          生成任务
        </el-button>
        <el-button
          size="small"
          type="text"
          @click.stop="trainingRecord(row, index)"
        >
          培训任务
        </el-button>

      </template>
    </grid-layout>

  </div>
</template>

<script>
import {
  whetherToStartTraining,
  getList,
  saveCorrespondingData,
  saveTopRecord,
  cancelTop,
} from "@/api/training/trainGroupScene";
import { getDetailBySiteTrainingId } from "@/api/training/recording";
import { companyProjects } from "@/api/system/dept";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import WfFlow from "@/views/plugin/workflow/process/components/flow.vue";
import { mapGetters } from "vuex";
import { dateFormat } from "@/util/date";
import {fieldInfo} from "@/api/base/length"
import trainingRecordEjectLayer from "@/views/business/training/onSiteTraining/trainingRecordEjectLayer";
export default {
  components: {
    HeadLayout,
    GridLayout,
    WfFlow,
    trainingRecordEjectLayer,
  },
  props: {
    initTree: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      trainingInformation: {},
      trainingRecordEjectLayer: false,
      query: {
        trainContentCode: "",
      },
      total: "",
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0,
      },
      tableLoading: false,
      tableData: [],
      headBtnOptions: [],
      searchColumns: [
        {
          label: "",
          prop: "courseName",
          span: 4,
          placeholder: "请输入课程名称",
        },
        {
          prop: 'whenIsCreatedDate',
          type: 'datetimerange',
          span: 5,
          startPlaceholder: "创建开始时间",
          endPlaceholder: "创建结束时间",
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
      ],

      tableOptions: {
        indexLabel: "序号",
        index: true,
        linklabel: "courseName",
        menuWidth: 160,
        column: [
          {
            label: "课程名称",
            prop: "courseName",
            align: fieldInfo.name_file.align,
            minWidth: fieldInfo.name_file.length,
            overHidden: true,
          },
          {
            label: "课程",
            prop: "courseCover",
            slot: true,
            width: 120
          },

          {
            label: "时长",
            prop: "duration",
            align: fieldInfo.time.align,
            minWidth: fieldInfo.time.length,
            overHidden: true,
          },
          {
            label: "标签",
            prop: "ticket",
            align: "center",
            width: 145,
            overHidden: true,
          },
          {
            label: "创建人",
            prop: "userName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
          },
          {
            label: "创建单位",
            prop: "deptName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true,
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["permission", "language", "userInfo"]),
    gridRowBtn() {
      let rowBtn = [];
      rowBtn.push(
        {
          label: this.$t("cip.cmn.btn.viewBtn"),
          emit: "grid-view",
          remark: "view",
          type: "text",
          icon: "",
        },
        {
          label: "开始培训",
          emit: "grid-status",
          remark: "status",
          type: "text",
          icon: "",
        }
      );
      return rowBtn;
    },
  },
  methods: {
    /**
     * 培训记录
     */
    trainingRecord(row) {
      //通过课程去查看所有的任务
      // this.trainingRecordEjectLayer = true
      // this.trainingInformation = row;
      this.$router.push({
        path: "/business/safetyenment/train/trainingTaskList/index",
        query: {
          courseName: row.courseName,
        },
      });
    },
    /**
     * 生成任务
     * @param row
     */
    generationTask(row) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingTaskListPageAdd",
        query: {
          row: JSON.stringify(row),
          type: "curriculumCenter",
        },
      });
    },
    // 置顶
    top(row) {
      let data = {
        trainContentId: row.trainContentId,
        scentSetHeadId: row.id,
        userId: this.userInfo.id,
        deptId: this.userInfo.deptId,
        groupType: row.type,
      };
      saveTopRecord(data)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("操作成功！");
          }
        })
        .then(() => {
          this.onLoad(this.page);
        });
    },
    // 取消置顶
    cancelTop(row) {
      if (row.sceneCollectId) {
        cancelTop(row.sceneCollectId)
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success("操作成功！");
            }
          })
          .then(() => {
            this.onLoad(this.page);
          });
      }
    },
    searchReset(params) {
      this.$refs.searchForm.searchForm = {};
      // this.initTree()
      this.query = {}
      this.onLoad(this.page);
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      let trainContentCode = this.$parent.$parent.$parent.$parent.trainingCode;
      params.trainContentCode = trainContentCode
      params.type = "2"
      params.createDept = this.userInfo.dept_id
      if (params.whenIsCreatedDate) {
        params.startTime = params.whenIsCreatedDate[0]
        params.endTime = params.whenIsCreatedDate[1]
      }
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        page.total = data.total;
        this.tableData = data.records;
        this.$nextTick(() => {
          this.$refs.gridLayout.page.total = data.total;
        });
        this.tableLoading = false;
      });
    },
    //查看
    View(item) {
      this.$router.push({
        path: "/business/knowledge/onTheJobTraining/add",
        query: {
          row: JSON.stringify(item),
          type: "view",
        },
      });
    },
    //开始培训
    startTraining(row) {
      getDetailBySiteTrainingId(row.id).then((res) => {
        if (
          row.whetherToStartTraining == 1 &&
          res.data.code == 200 &&
          res.data.msg != "暂无承载数据"
        ) {
          if (res.data.data) {
            this.$router.push({
              path: "/business/training/onSiteTraining/edit",
              query: {
                row: JSON.stringify(res.data.data),
                type: "edit",
              },
            });
          }
        } else {
          if (row.status == 1) {
            let status = 1;
            whetherToStartTraining(row.id, status).then((res) => {
              if (res.data.code == 200) {
                let time = dateFormat(new Date(), "yyyy-MM-dd");
                companyProjects().then((res) => {
                  if (res.data.code == "200") {
                    let data = {
                      siteTrainingId: row.id,
                      trainingName: row.courseName,
                      trainingTime: time,
                      trainingScenarios: row.trainingScenarios,
                      sponsor: this.userInfo.user_id,
                      project: res.data.data.project,
                      isFinish: 1,
                      firm: res.data.data.firm,
                    };
                    saveCorrespondingData(data).then((res) => {
                      if (res.data.code == 200) {
                        this.$router.push({
                          path: "/business/training/onSiteTraining/edit",
                          query: {
                            row: JSON.stringify(res.data.data),
                            type: "edit",
                          },
                        });
                      } else {
                        this.$message.success(msg);
                      }
                    });
                  } else {
                    this.$message.success("数据错误,请刷新重试!");
                  }
                });
              }
            });
          } else {
            this.$message.warning("此任务已失效!");
          }
        }
      });
    },
  },
  mounted() {
    this.onLoad(this.page);
  },
};
</script>
<style lang="scss" scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

::v-deep .customCourses .avue-crud .el-table {
  height: calc(100vh - 316px) !important;
  max-height: calc(100vh - 316px) !important;
}

.headers ::v-deep .el-button {
  height: 28px;
}

.el-button + .el-button {
  margin-left: 5px !important;
}

::v-deep .bjs-powered-by {
  display: none;
}
.courseCoverImg {
  width: 80px;
  padding: 5px;
}
</style>
