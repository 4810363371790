<template>
  <div style="position: relative">
    <div>
      <head-layout
        head-title="隐患排查计划"
        :head-btn-options="headBtnOptions"
        @head-romve="headRomve"
        @head-add="headAdd"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            v-if="row.enableFlag != 'Y'"
            type="text"
            size="small"
            @click="rowEdit(row)"
            >编辑</el-button
          >
          <el-button
            style="margin: 0 3px"
            v-if="row.enableFlag != 'Y'"
            type="text"
            size="small"
            @click="rowRemove(row)"
            >删除</el-button
          >
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowView(row)"
            >查看</el-button
          >
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowStartEnd(row)"
            >{{ row.enableFlag == "Y" ? "停用" : "启用" }}</el-button
          >
        </template>
        <template slot="enableFlag" slot-scope="{ row }">
          <div class="enableFlag-box">
            <div
              :class="row.enableFlag == 'Y' ? 'enableFlag-Y' : 'enableFlag-N'"
            >
              {{ statusMap[row.enableFlag] }}
            </div>
          </div>
        </template>
      </grid-layout>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { mapGetters } from "vuex";
import { fieldInfo } from "@/api/base/length";
import {
  getList, //获取计划列表
  startEndServe, // 计划启停服务
  removeList, // 删除计划
} from "@/api/check/troubleshootingPlan";
export default {
  components: {
    GridLayout,
    HeadLayout
  },
  data() {
    return {
      treeData: [],
      selectionList: [],
      tableOption: {
        linklabel: "planCode",
        menuWidth: "160px",
        selectable: (row) => {
          return row.enableFlag == "PREPARE";
        },
        column: [
          {
            label: "配置编号",
            prop: "planCode",
            align: "center",
            overHidden: true,
            width: 150,
          },
          {
            label: "配置名称",
            prop: "planName",
            align: "left",
            overHidden: true,
            width: 200,
          },
          {
            label: "检查类型",
            prop: "checkTypeName",
            align: fieldInfo.Words5.align,
            minWidth: fieldInfo.Words5.length,
            overHidden: true,
          },

          {
            label: "关联项目",
            prop: "organizationName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true,
          },
          {
            label: "执行周期",
            prop: "cycleFrequency",
            align: fieldInfo.Words4.align,
            minWidth: fieldInfo.Words4.length,
            overHidden: true,
          },
          {
            label: "起草人",
            prop: "createUserName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
          },
          // {
          //   label: "检查日期",
          //   prop: "isDomestic",
          //   dataType: "number",
          //   align: "left",
          //   dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_domestic`,
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   overHidden: true,
          // },
          {
            label: "状态",
            prop: "enableFlag",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      isCycle: "",
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "primary",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: "",
        },
      ],
      searchColumns: [
        {
          label: "计划编号",
          prop: "planCode",
          span: 4,
          placeholder: "请输入计划编号",
        },
        {
          label: "计划名称",
          prop: "planName",
          span: 4,
          placeholder: "请输入计划名称",
        },
        {
          label: "请输入起草人",
          prop: "createUserName",
          span: 4,
          type: "input",
          placeholder: "请输入起草人",
        },
        // {
        //   label: "项目名称",
        //   prop: "projectName",
        //   span: 3,
        //   placeholder: "请输入项目名称",
        // },
        // {
        //   label: "组织名称",
        //   prop: "organizationName",
        //   span: 3,
        //   placeholder: "请输入组织名称",
        // },
        {
          label: "状态",
          prop: "enableFlag",
          span: 4,
          type: "select",
          placeholder: "请选择状态",
          dicData: [
            { dictValue: "启用", dictKey: "Y" },
            { dictValue: "未启用", dictKey: "N" },
          ],
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],
      statusMap: { Y: "启用", N: "停用" },
      selectionStatus: [],
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
        this.selectionStatus.push(ele.enableFlag);
      });
      return ids.join(",");
    },
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.onLoad(this.page);
  },
  methods: {
    // 批量删除
    headRomve() {
      if (this.selectionStatus.indexOf("Y") != -1) {
        this.$message({
          message: "选中计划在启用中，不可删除",
          type: "warning",
        });
        return;
      }
      if (!this.ids) {
        this.$message({
          message: "请勾选需要删除的计划",
          type: "warning",
        });
        return;
      }
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeList({ ids: this.ids }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.onLoad(this.page);
          });
        })
        .catch((error) => {
          this.$message({
            message: "已取消删除",
            type: "success",
          });
        });
    },
    // 单删
    rowRemove(row) {
      if (row.enableFlag == "Y") {
        this.$message({
          message: "当前计划在启用中，不可删除",
          type: "warning",
        });
        return;
      }
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeList({ ids: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.onLoad(this.page);
          });
        })
        .catch((error) => {
          this.$message({
            message: "已取消删除",
            type: "success",
          });
        });
    },
    headAdd() {
      this.$router.push({
        path: `/hiddenTrouble/check/troubleshootingPlan/add`,
        query: {
          type: "add",
        },
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      let pramas = {};
      for (let key in searchForm) {
        if (searchForm[key]) {
          pramas[key] = searchForm[key];
        }
      }
      this.page.currentPage = 1;
      this.onLoad(this.page, pramas);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(page.currentPage, page.pageSize, {
        ...params,
        organizationId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        }
      });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/hiddenTrouble/check/troubleshootingPlan/edit`,
        query: {
          id: row.id,
          type: "edit",
        },
      });
    },
    rowView(row) {
      this.$router.push({
        path: `/hiddenTrouble/check/troubleshootingPlan/detail`,
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
    rowStartEnd(row) {
      // 启停服务
      this.$confirm(
        `确认${row.enableFlag == "Y" ? "停用" : "启用"}该计划吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          startEndServe({
            ids: row.id,
            enableFlag: row.enableFlag == "Y" ? "N" : "Y",
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: row.enableFlag == "Y" ? "停用成功" : "启用成功",
                type: "success",
              });
              this.onLoad(this.page);
            } else {
              this.$message({
                message: "操作失败",
                type: "error",
              });
            }
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消操作",
            type: "success",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.enableFlag-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  .enableFlag-Y {
    border: 1px solid #67c23a;
    color: #67c23a;
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
  }
  .enableFlag-N {
    border: 1px solid #606266;
    color: #606266;
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
  }
}
</style>
