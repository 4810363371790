<template>
  <div>
    <div class="disFlex">
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value1) || 0 }}<span>个</span>
          </div>
          <img src="@/assets/images/board/one.png">
        </div>
        <div class="card-pd-4-26-bottom">
          在建境内项目、机构、工厂总数量
        </div>
      </div>
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value2) || 0 }}<span>人</span>
          </div>
          <img src="@/assets/images/board/two.png">
        </div>
        <div class="card-pd-4-26-bottom">
          在建境内机构总人数量
        </div>
      </div>
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value3) || 0 }}<span>人</span>
          </div>
          <img src="@/assets/images/board/three.png">
        </div>
        <div class="card-pd-4-26-bottom">
          专职安全环保人员
        </div>
      </div>
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value4) || 0 }}<span>小时</span>
          </div>
          <img src="@/assets/images/board/four.png">
        </div>
        <div class="card-pd-4-26-bottom">
          项目、工厂安全生产工时
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            安全环保投入统计
          </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" @click="openDiscount">
            <img src="@/assets/images/board/six.png" @click="openColumnar">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value5) || 0 }}<span>万元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              安全环保投入总额
            </div>
          </div>
          <div class="card-pd-2-BotLitte">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value6) || 0 }}<span>万元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              安全投入金额
            </div>
          </div>
          <div class="card-pd-2-BotLitte">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value7) || 0 }}<span>万元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              环保投入金额
            </div>
          </div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            项目分类统计
          </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div>
        </div>
        <div style="display: flex;margin-top: 22px;">
          <div id="chart-one">
          </div>
          <div class="chart-one-info">
            <div class="chart-one-info-box">
              <div class="info-circle"></div>
              <span>建设项目</span>
              <span style="margin: 0 12px">{{ formatWithCommas(380) }}个</span>
              <span>64%</span>
            </div>
            <div class="chart-one-info-box" style="margin-top: 18px">
              <div class="info-circle" style="background: linear-gradient(180deg, #FFAB2D 0%, #FFCD82 100%);"></div>
              <span>运维项目</span>
              <span style="margin: 0 12px">{{ formatWithCommas(211) }}个</span>
              <span>36%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            境外在建项目、运营机构与人员分布情况
          </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              项目、机构
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value8) || 0 }}<span>个</span>
            </div>
            <slider :value="68"/>
            <div class="botLitte2-info">
              风险地区项目：<span>{{ formatWithCommas(dataForm.value9) || 0}}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ formatWithCommas(dataForm.value10) || 0 }}%</span>
            </div>
          </div>
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              工厂
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value11) || 0}}<span>个</span>
            </div>
            <slider :value="68"/>
            <div class="botLitte2-info">
              风险地区工厂：<span>{{ formatWithCommas(dataForm.value12) || 0}}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ formatWithCommas(dataForm.value13) || 0}}%</span>
            </div>
          </div>
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              人员
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value14) || 0}}<span>个</span>
            </div>
            <slider :value="68"/>
            <div class="botLitte2-info">
              风险地区人员：<span>{{ formatWithCommas(dataForm.value15) || 0}}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ formatWithCommas(dataForm.value16) || 0}}%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            境内在建项目、运营机构与人员分布情况
          </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              项目、机构
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value17) || 0}}<span>个</span>
            </div>
            <slider :value="68"/>
            <div class="botLitte2-info">
              风险地区项目：<span>{{ formatWithCommas(dataForm.value18) || 0}}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ formatWithCommas(dataForm.value19) || 0}}%</span>
            </div>
          </div>
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              工厂
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value20) || 0}}<span>个</span>
            </div>
            <slider :value="68"/>
            <div class="botLitte2-info">
              风险地区工厂：<span>{{ formatWithCommas(dataForm.value21) || 0}}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ formatWithCommas(dataForm.value22) || 0}}%</span>
            </div>
          </div>
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              人员
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value23) || 0}}<span>个</span>
            </div>
            <slider :value="68"/>
            <div class="botLitte2-info">
              风险地区人员：<span>{{ formatWithCommas(dataForm.value24) || 0}}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ formatWithCommas(dataForm.value25) || 0}}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            境外国家项目数TOP5及人数统计
          </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-two"></div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            境内省区项目数TOP5及人数统计
          </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-three"></div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            下属组织在建项目、运营机构数量TOP5
          </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-four"></div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            下属组织在建项目、运营机构人员分布TOP5
          </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-five"></div>
        </div>
      </div>
    </div>
    <discount ref="discount"></discount>
    <columnar ref="columnar"></columnar>
  </div>
</template>
<script>
import slider from "@/views/business/board/components/slider";
import discount from "@/views/business/board/components/discount";
import columnar from "@/views/business/board/components/columnar";
import {appIndex} from "@/api/user";
import {mapGetters} from "vuex";

export default {
  components: {slider, discount, columnar},
  data() {
    return {
      year: '',
      month: '',
      dataForm: {
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
        value7: '',
        value8: '',
        value9: '',
        value10: '',
        value11: '',
        value12: '',
        value13: '',
        value14: '',
        value15: '',
        value16: '',
        value17: '',
        value18: '',
        value19: '',
        value20: '',
        value21: '',
        value22: '',
        value23: '',
        value24: '',
        value25: ''
      }
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  created() {
    this.year = new Date().getFullYear();
  },
  mounted() {
    setTimeout(() => {
      this.getChartOne();
      this.getChartTwo();
      this.getChartThree();
      this.getChartFour();
      this.getChartFive();
    }, 200);
    this.getIndexData();
  },
  methods: {
    formatWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    openDiscount() {
      this.$refs.discount.show = true;
      this.$nextTick(() => {
        this.$refs.discount.getChartDiscount();
      })
    },
    openColumnar() {
      this.$refs.columnar.show = true;
      this.$nextTick(() => {
        this.$refs.columnar.getChartColumnar();
      })
    },
    getIndexData() {
      let oneData = new Promise((resolve, reject) => {
        // 在建境内项目数
        appIndex({
          indexCode: 'ORG_DOMESTIC_PRJ_CNT_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      let twoData = new Promise((resolve, reject) => {
        // 工厂数量
        appIndex({
          indexCode: 'ORG_PLANT_CNT_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      Promise.all([oneData, twoData]).then((res) => {
        console.log(res,'402');
        this.dataForm.value17 = res[0];
        this.dataForm.value11 = res[1];
        let number = 0;
        res.forEach((item) =>{
          number += Number(item)
        })
        this.dataForm.value1 = number;
      })

      // 境外项目中风险地区项目占比
      appIndex({
        indexCode: 'ORG-OBROAD_IN_RISK_PRJ_CNT_Y',
        _year: this.year,
        // companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value10 = res.data.data.records[0]._value;
        }
      })

      // 境外高风险地区人数占比
      appIndex({
        indexCode: 'PEOPLE_OBROAD_PRJ_PEOPLE_RATIO_Y',
        _year: this.year,
        // companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value16 = res.data.data.records[0]._value;
        }
      })

      // 境外在建项目数
      appIndex({
        indexCode: 'ORG_OBROAD_PRJ_CNT_Y',
        _year: this.year,
        // companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value8 = res.data.data.records[0]._value;
        }
      })

      // 境外项目总人数
      appIndex({
        indexCode: 'PEOPLE_OBROAD_PRJ_PEOPLE_CNT_Y',
        _year: this.year,
        // companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value14 = res.data.data.records[0]._value;
        }
      })

      // 专职安全环保人员
      appIndex({
        indexCode: 'CETIFICATE_PROFESSIONAL_PEOPLE_CNT_Y',
        // _year: this.year,
        // companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value3 = res.data.data.records[0]._value;
        }
      })

      // 项目、工厂安全生产工时
      appIndex({
        indexCode: 'PRODUCTION_SAFE_WORKING_HOURS_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value4 = res.data.data.records[0]._value;
        }
      })

      // 安全环保投入总额
      appIndex({
        indexCode: 'INVEST_SAFE_ENV_INVEST_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value5 = res.data.data.records[0]._value;
        }
      })

      // 安全投入金额
      appIndex({
        indexCode: 'INVEST_SAFE_INVEST_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value6 = res.data.data.records[0]._value;
        }
      })

      // 环保投入金额
      appIndex({
        indexCode: 'INVEST_ENV_INVEST_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value7 = res.data.data.records[0]._value;
        }
      })
    },
    getChartOne() {
      // appIndex('ORG_PLANT_CNT_Y').then((res) =>{
      //   console.log(res,'356')
      // })
      // appIndex('ORG_PRJ_CNT_Y').then((res) =>{
      //   console.log(res,'356')
      // })
      var myChart = this.$echarts.init(
        document.getElementById("chart-one")
      );
      var colorList = [{
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
          offset: 0,
          color: '#FF8181' // 0% 处的颜色
        },
          {
            offset: 1,
            color: '#FB3636' // 100% 处的颜色
          }
        ],
      }, {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
          offset: 0,
          color: '#FFAB2D' // 0% 处的颜色
        },
          {
            offset: 1,
            color: '#FFCD82' // 100% 处的颜色
          }
        ],
      }]
      var option = {
        title: {
          text: "总项目数",
          top: "35%",
          textAlign: "center",
          left: "47%",
          textStyle: {
            color: "#484A4D",
            fontSize: 16,
            fontWeight: 400
          },
          subtext: "591",
          subtextStyle: {
            color: "#484A4D",
            fontSize: 26,
            fontWeight: 700
          },
          itemGap: 10, //主副标题间距
        },
        tooltip: {
          formatter: (params) => {
            if (params.name != "") {
              return params.name + " : " + params.value + "\n" + "(个)";
            }
          },
        },
        series: [
          {
            type: "pie",
            radius: ["63%", "90%"],
            avoidLabelOverlap: false,
            padAngle: 2,
            itemStyle: {
              borderRadius: 4,
              borderColor: "#fff",
              borderWidth: 2,
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex]
                }
              }
            },
            color: ["#FB3636", "#FFAB2D"],
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 380,
                name: "建设项目",
              },
              {
                value: 211,
                name: "运维项目",
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartTwo() {
      var myChart = this.$echarts.init(
        document.getElementById("chart-two")
      );
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: ['项目数', '人数'],
          right: "15%",
        },
        grid: { // 让图表占满容器
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "25px"
        },
        xAxis: [
          {
            type: 'category',
            data: ['巴基斯坦', '柬埔寨', '缅甸', '尼泊尔', '菲律宾'],
            axisLine: {
              lineStyle: {
                color: ['#E8E8E8']
              }
            },
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '单位：个',
            interval: 100,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },


            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '单位：人',
            interval: 50,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: '项目数',
            type: 'bar',
            barWidth: 25,
            color: '#FB3636',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 个';
              }
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0,
                      color: '#FB3636' // 0% 处的颜色
                    },
                      {
                        offset: 1,
                        color: '#FF8181' // 100% 处的颜色
                      }
                    ],
                  }
                }
              }
            },
            data: [200, 300, 100, 500, 200]
          },
          {
            name: '人数',
            type: 'line',
            color: '#FFAB2D',
            symbol: 'none',
            smooth: true,
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 人';
              }
            },
            data: [16, 32, 33, 45, 63]
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartThree() {
      var myChart = this.$echarts.init(
        document.getElementById("chart-three")
      );
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: ['项目数', '人数'],
          right: "15%",
        },
        grid: { // 让图表占满容器
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "25px"
        },
        xAxis: [
          {
            type: 'category',
            data: ['广东', '江苏', '湖北', '湖南', '山东'],
            axisLine: {
              lineStyle: {
                color: ['#E8E8E8']
              }
            },
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '单位：个',
            interval: 100,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '单位：人',
            interval: 50,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: '项目数',
            type: 'bar',
            color: '#FB3636',
            barWidth: 25,
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0,
                      color: '#FB3636' // 0% 处的颜色
                    },
                      {
                        offset: 1,
                        color: '#FF8181' // 100% 处的颜色
                      }
                    ],
                  }
                }
              }
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 个';
              }
            },
            data: [200, 300, 100, 500, 200]
          },
          {
            name: '人数',
            type: 'line',
            color: '#FFAB2D',
            smooth: true,
            symbol: 'none',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 人';
              }
            },
            data: [16, 32, 33, 45, 63]
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartFour() {
      var myChart = this.$echarts.init(
        document.getElementById("chart-four")
      );
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          right: "0%",
        },
        grid: { // 让图表占满容器
          top: "40px",
          left: "60px",
          right: "40px",
          bottom: "25px"
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
        },
        yAxis: {
          type: 'category',
          name: '单位：个',
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              color: ['#E8E8E8']
            }
          },
          data: ['北京中材', '北京中材', '北京中材', '北京中材', '北京中材']
        },
        series: [
          {
            name: '境外项目数',
            type: 'bar',
            stack: 'total',
            color: '#FB3636',
            barWidth: 25,
            emphasis: {
              focus: 'series'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 个';
              }
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: 'linear',
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [{
                      offset: 0,
                      color: '#FB3636' // 0% 处的颜色
                    },
                      {
                        offset: 1,
                        color: '#FF8181' // 100% 处的颜色
                      }
                    ],
                  }
                }
              }
            },
            data: [320, 302, 301, 334, 390, 330, 320]
          },
          {
            name: '境内项目数',
            type: 'bar',
            stack: 'total',
            color: '#FFAB2D',
            barWidth: 25,
            emphasis: {
              focus: 'series'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 个';
              }
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: 'linear',
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [{
                      offset: 0,
                      color: '#FFAB2D' // 0% 处的颜色
                    },
                      {
                        offset: 1,
                        color: '#FFCD82' // 100% 处的颜色
                      }
                    ],
                  }
                }
              }
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartFive() {
      var myChart = this.$echarts.init(
        document.getElementById("chart-five")
      );
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          right: "0%",
        },
        grid: { // 让图表占满容器
          top: "40px",
          left: "60px",
          right: "40px",
          bottom: "25px"
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
        },
        yAxis: {
          type: 'category',
          name: '单位：个',
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              color: ['#E8E8E8']
            }
          },
          data: ['北京中材', '北京中材', '北京中材', '北京中材', '北京中材']
        },
        series: [
          {
            name: '境外项目数',
            type: 'bar',
            stack: 'total',
            color: '#FB3636',
            barWidth: 25,
            emphasis: {
              focus: 'series'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 个';
              }
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: 'linear',
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [{
                      offset: 0,
                      color: '#FB3636' // 0% 处的颜色
                    },
                      {
                        offset: 1,
                        color: '#FF8181' // 100% 处的颜色
                      }
                    ],
                  }
                }
              }
            },
            data: [320, 302, 301, 334, 390, 330, 320]
          },
          {
            name: '境内项目数',
            type: 'bar',
            stack: 'total',
            color: '#FFAB2D',
            barWidth: 25,
            emphasis: {
              focus: 'series'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 个';
              }
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: 'linear',
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [{
                      offset: 0,
                      color: '#FFAB2D' // 0% 处的颜色
                    },
                      {
                        offset: 1,
                        color: '#FFCD82' // 100% 处的颜色
                      }
                    ],
                  }
                }
              }
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.disFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.card-pd-4-26 {
  width: calc(25% - 64px);
  padding: 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-4-26-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-4-26-topLeft {
  line-height: 61px;
  height: 61px;
  color: #15161A;
  font-size: 42px;
  font-weight: bold;
}

.card-pd-4-26-topLeft span {
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
}

.card-pd-4-26-top img {
  width: 61px;
  height: 61px;
  flex-shrink: 0;
}

.card-pd-4-26-bottom {
  width: 100%;
  margin-top: 48px;
  color: #15161A;
  font-size: 18px;
}

.card-pd-2 {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-2-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-2-top-Title {
  color: #15161A;
  font-size: 21px;
  font-weight: bold;
}

.card-pd-2-top-btn {
  display: flex;
  justify-content: flex-end;
}

.card-pd-2-top-btn img {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}

.card-pd-2-Bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.card-pd-2-BotLitte {
  width: calc(33.3% - 64px);
  border: 1px solid #EDEDED;
  border-radius: 10px;
  padding: 40px 26px;
}

.card-pd-2-BotLitte2 {
  width: calc(33.3% - 52px);
  border-radius: 8px;
  padding: 21px;
  background-color: #F5F7FA;
}

.botLitte2-number {
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
  margin-top: 21px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}

.botLitte2-number span {
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
}

.botLitte2-title {
  width: 100%;
  color: #484A4D;
  font-size: 21px;
}

.botLitte2-info {
  color: #15161A;
  font-size: 18px;
  margin-top: 13px;
}

.botLitte2-info span {
  color: #FB3636;
}

.priceNumber {
  width: 100%;
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
}

.priceNumber span {
  font-weight: 400;
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
}

.lineBox {
  width: 40px;
  height: 3px;
  background-color: #FB3636;
  margin: 21px 0 26px;
}

.priceContent {
  width: 100%;
  color: #15161A;
  font-size: 18px;
}

#chart-one {
  width: 209px;
  height: 209px;
  margin-left: 93px;
}

.chart-one-info {
  padding: 73px 0;
  margin-left: 58px;
}

.chart-one-info-box {
  color: #1D2129;
  font-size: 16px;
  display: flex;
}

.info-circle {
  width: 10px;
  height: 10px;
  background: linear-gradient(26deg, #FF8181 16.57%, #FB3636 83.6%);
  border-radius: 50px;
  margin: 6px 6px 6px 0;
}

#chart-two {
  width: 100%;
  height: 320px;
}

#chart-three {
  width: 100%;
  height: 320px;
}

#chart-four {
  width: 100%;
  height: 320px;
}

#chart-five {
  width: 100%;
  height: 320px;
}

::v-deep .el-slider__runway.disabled .el-slider__bar {
  background-color: #FB3636;
}

::v-deep .el-slider__runway.disabled .el-slider__button {
  border-color: #FB3636;
}
</style>
