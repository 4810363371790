<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t(`cip.index.intf.title`)"
      @head-request="headRequest()"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
    <AppIndexWindow ref="appIndexWindow" @confirmExt="confirm"></AppIndexWindow>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import AppIndexWindow from "@/views/business/index/appIntf/AppIndexWindow.vue";
import {appIndexDataApiIntf} from "@/api/index/indexManage";
export default {
  components: {
    HeadLayout,
    FormLayout,
    AppIndexWindow
  },
  data() {
    return {
      appIndexWindowShow: false,
      intfShowInfo:{
        currentPage: 1,
        pageSize: 50,
        indexCode: '',
        timeStart:'',
        timeEnd:''
      },
      dataForm: {
        intfUrl: '',
        timeDimension: 'M',
        timeSelect: [],
        indexCode: '',
        currentPage: 1,
        pageSize: 50,
        intfParams: {},
        intfResultRows: JSON.stringify({}),
      }
    }
  },
  computed: {
    formColumn() {
      return [
        {
          label: this.$t('cip.index.intf.intfUrl'),
          labelWidth: 130,
          prop: "intfUrl",
          readonly: true,
          placeholder: this.$t(`cip.index.intf.intfUrl`),
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.intf.intfUrl'),
            trigger: "blur"
          }],
          span: 24
        },
        {
          label: this.$t('cip.index.intf.timeDimension'),
          labelWidth: 130,
          prop: "timeDimension",
          type: 'select',
          dataType: 'string',
          placeholder: this.$t(`cip.index.intf.timeDimension`),
          span: 8,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_time_dimension",
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.intf.timeDimension'),
            trigger: "blur"
          }],
        },
        {
          label: this.$t('cip.index.intf.timeSelect'),
          labelWidth: 130,
          prop: "timeSelect",
          type: 'datetimerange',
          defaultTime: ['00:00:00', '23:59:59'],
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          startPlaceholder: this.$t(`cip.index.intf.timeSelectStart`),
          endPlaceholder: this.$t(`cip.index.intf.timeSelectEnd`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.intf.timeSelect'),
            trigger: "blur"
          }],
          span: 16
        },
        {
          label: this.$t('cip.index.app.category.indexCode'),
          labelWidth: 130,
          prop: "indexCode",
          type: 'textarea',
          readonly: true,
          placeholder: this.$t(`cip.index.app.category.indexCode`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.app.category.indexCode'),
            trigger: "blur"
          }],
          span: 24,
          click: () => {
            this.indexCodeClick();
          }
        },
        {
          label: this.$t('cip.index.intf.currentPage'),
          labelWidth: 130,
          prop: "currentPage",
          type: 'number',
          placeholder: this.$t(`cip.index.intf.currentPage`),
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.intf.currentPage'),
            trigger: "blur"
          }],
          span: 8
        },
        {
          label: this.$t('cip.index.intf.pageSize'),
          labelWidth: 130,
          prop: "pageSize",
          type: 'select',
          dataType: 'string',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicData: [
            {dictKey: "10", dictValue: "10"},
            {dictKey: "20", dictValue: "20"},
            {dictKey: "30", dictValue: "30"},
            {dictKey: "40", dictValue: "40"},
            {dictKey: "50", dictValue: "50"},
            {dictKey: "100", dictValue: "100"},
            {dictKey: "500", dictValue: "500"},
            {dictKey: "1000", dictValue: "1000"}
          ],
          placeholder: this.$t(`cip.index.intf.pageSize`),
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.intf.pageSize'),
            trigger: "blur"
          }],
          span: 8
        },
        {
          label: this.$t('cip.index.intf.intfParams'),
          labelWidth: 130,
          prop: "intfParams",
          type: 'textarea',
          minRows: 15,
          maxRows: 20,
          readonly: true,
          placeholder: this.$t(`cip.index.intf.intfParams`),
          span: 12
        },
        {
          label: this.$t('cip.index.intf.intfResultRows'),
          labelWidth: 130,
          prop: "intfResultRows",
          type: 'textarea',
          minRows: 15,
          maxRows: 20,
          readonly: true,
          placeholder: this.$t(`cip.index.intf.intfResultRows`),
          span: 12
        }
      ]
    },
    headBtnOptions() {
      let result = [];
      result.push(
        {
          label: this.$t(`cip.index.intf.requestBtn`),
          emit: "head-request",
          type: "button",
          icon: ""
        }
      );
      return result;
    }
  },
  methods: {
    headRequest() {
      const _this = this;
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          _this.$refs.formLayout.$refs.form.allDisabled = false;
          let params = {
            indexCode: _this.dataForm.indexCode,
            timeStart: _this.dataForm.timeSelect[0],
            timeEnd: _this.dataForm.timeSelect[1]
          };
          appIndexDataApiIntf(
            _this.dataForm.currentPage,
            _this.dataForm.pageSize,
            params
          ).then(res => {
            _this.dataForm.intfResultRows = JSON.stringify(res.data)
          })
        }
      });
    },
    intfShowInfoFun(){
      this.intfShowInfo.currentPage = this.dataForm.currentPage;
      this.intfShowInfo.pageSize = this.dataForm.pageSize;
      this.intfShowInfo.indexCode = this.dataForm.indexCode;
      // this.dataForm.intfParams = JSON.stringify(this.intfShowInfo)
      let aa = JSON.stringify(this.intfShowInfo)
      this.dataForm.intfParams =  JSON.stringify(JSON.parse(aa), null, '\t')
      if(this.dataForm.timeSelect.length===2){
        this.intfShowInfo.timeStart = this.dataForm.timeSelect[0];
        this.intfShowInfo.timeEnd = this.dataForm.timeSelect[1];
      }else{
        this.intfShowInfo.timeStart = '';
        this.intfShowInfo.timeEnd = '';
      }

    },


    /**
     * 确认按钮
     */
    confirm(selectionList) {
      let indexCodeStr = selectionList.map(e => e.indexCode).join(',');
      this.dataForm.intfUrl = `${process.env.VUE_APP_BASE_INDEXINTFURL}`+'/sinoma-index/api/appIndexIntf?indexCode='+indexCodeStr;
      this.dataForm.indexCode = indexCodeStr;
      this.intfShowInfoFun();
    },
    indexCodeClick(){
      this.$refs.appIndexWindow.openDialog(this.dataForm.timeDimension)
    }
  },
  watch:{
    'dataForm':{
      deep:true,
      immediate:true,
      handler() {
        this.intfShowInfoFun();
      }
    }
  },
  created() {

  }
}
</script>
<style scoped>

</style>
