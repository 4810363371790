var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-main",
    [
      _c("form-layout", {
        ref: "formLayout",
        staticClass: "deptForm",
        attrs: { dataForm: _vm.form, group: _vm.group },
      }),
      _c("userDialog", {
        ref: "userDialog",
        staticClass: "roleTable",
        attrs: { userFrom: _vm.form, isFixed: false },
        on: { submitList: _vm.submitList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }