<template>
  <div>
    <common-dialog
      v-if="show"
      width="70%"
      dialogTitle="柱状图"
      :showBtn="false"
      @cancel="show = false">
      <div id="chartColumnar" v-if="show"></div>
    </common-dialog>
  </div>
</template>
<script>
import CommonDialog from "@/components/CommonDialog";

export default {
  components: { CommonDialog },
  data() {
    return {
      show: false
    }
  },
  methods: {
    getChartColumnar() {
      var myChart = this.$echarts.init(
        document.getElementById("chartColumnar")
      );
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: { // 让图表占满容器
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "25px"
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            color: '#FB3636',
            barWidth: '40',
            data: [10, 52, 200, 334, 390, 330, 220]
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    }
  }
}
</script>
<style lang="scss" scoped>
#chartColumnar {
  width: 100%;
  height: 50vh;
}
</style>
