<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        @head-cancel="headCancel"
      ></head-layout>
      <form-layout
        ref="formLayout1"
        :column="form1Column"
        :dataForm.sync="dataForm"
      ></form-layout>
      <head-layout
        head-title="项目人员信息"
      ></head-layout>
      <form-layout
        ref="formLayout2"
        :column="form2Column"
        :dataForm.sync="dataForm"
      ></form-layout>
      <head-layout
        head-title="项目现场信息"
      ></head-layout>
      <form-layout
        ref="formLayout3"
        :column="form3Column"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-main>
    <div class="dialog-footer">
      <el-button v-if="type != 'view' || (this.type == 'workflow' && this.dataForm.dataState == 3)" @click="headSave">保 存</el-button>
      <el-button v-if="type != 'view' && this.type != 'workflow'" type="primary" @click="headSubmit">提 交</el-button>
      <el-button v-if="type == 'view' && this.type != 'workflow'" type="primary" @click="headCancel">取 消</el-button>
    </div>
  </div>
</template>
<script>

import GridLayout from "@/views/components/layout/grid-layout.vue";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import {getDetail, submit, submitVerify} from "@/api/foreignReport/domesticReport";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  mixins: [exForm, draft],
  props: {
    rowData: {
      type: Object,
    }
  },
  data() {
    return {
      type: "",
      id: "",
      processTemplateKey: "fill_report_overseas",
      formProcess: {
        id: '',
        processId: '',
        deptId: '',
        paperName: '',
      },
      dataForm: {
        projectCode: "",
        projectName: "",
        projectArea: "1",
        provinceName: "",
        countryName: "",
        businessTypeName: "",
        status: "",
        orgCode: "",
        totalCount: "",
        chineseCnt: "",
        regeditedChineseCnt: "",
        dispatchedChineseCnt: "",
        thirdPartyChineseCnt: "",
        subcontractorChineseCnt: "",
        foreignerCnt: "",
        regularForeignerCnt: "",
        otherForeignerCnt: "",
        remark1: "",
        remark2: "",
        onsiteDirectorName: "",
        onsiteDirectorPost: "",
        onsiteDirectorContact: "",
        onsiteDirectorCellphone: "",
        projectProgress: "",
        statDate: "",
        dataState: "",
        dateSource: "1",
        createUserName: "",
        updateUserName: "",
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let buttonBtn = [];
      return buttonBtn;
    },
    form1Column() {
      let that = this
      return [
        // {
        //   label: "项目编码",
        //   prop: "projectCode",
        //   labelWidth: 120,
        //   disabled: true,
        // },
        {
          label: "统计日期",
          prop: "statDate",
          labelWidth: 120,
          type: "date",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          disabled: !['edit', 'add'].includes(this.type),
          rules: [
            {
              required: true,
              message: "请选择日期",
              trigger: ["blur", "change"]
            }
          ],
          row: true,
        },
        {
          label: "项目名称",
          prop: "projectName",
          placeholder: "由项目数据带出",
          disabled: true,
          labelWidth: 120,
        },
        {
          label: "项目状态",
          placeholder: "由项目数据带出",
          prop: "projectStatusName",
          dataType: "number",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=prj_state",
          dicData: [],
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          labelWidth: 120,
          disabled: true,
        },
        {
          label: "业务类型",
          placeholder: "由项目数据带出",
          prop: "businessTypeName",
          disabled: true,
          labelWidth: 120,
        },
        {
          label: "国别",
          placeholder: "由项目数据带出",
          prop: "countryName",
          disabled: true,
          labelWidth: 120,
        },
        {
          label: "州/省",
          placeholder: "由项目数据带出",
          prop: "provinceName",
          disabled: true,
          labelWidth: 120,
        },
        {
          label: "总人数",
          prop: "totalCount",
          placeholder: "由项目人员信息计算得出",
          disabled: true,
          labelWidth: 120,
          overHidden: true,
        },
        {
          label: "中方员工总数",
          prop: "chineseCnt",
          placeholder: "由项目人员信息计算得出",
          disabled: true,
          labelWidth: 120,
          overHidden: true,
          change: ({value, column}) => {
            that.$nextTick(() => {
              if(Number(value) + Number(that.dataForm.foreignerCnt) != 0) {
                that.dataForm.totalCount = Number(value) + Number(that.dataForm.foreignerCnt)
              }
            })
          },
        },
        {
          label: "外籍人员总人数",
          prop: "foreignerCnt",
          placeholder: "由项目人员信息计算得出",
          disabled: true,
          labelWidth: 120,
          overHidden: true,
          change: ({value, column}) => {
            that.$nextTick(() => {
              if(Number(value) + Number(that.dataForm.chineseCnt) != 0) {
                that.dataForm.totalCount = Number(value) + Number(that.dataForm.chineseCnt)
              }

            })
          },
        },
      ]
    },
    form2Column() {
      let that = this
      return [
        {
          label: "在册中方职工数",
          prop: "regeditedChineseCnt",
          labelWidth: 200,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          readonly: !['edit','workflow'].includes(this.type),
          rules: [
            {
              required: true,
              message: "请输入在册中方职工数",
              trigger: "blur"
            },
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
          ],
          blur: ({value, column}) => {
            that.dataForm.chineseCnt = Number(value) + Number(that.dataForm.dispatchedChineseCnt) + Number(that.dataForm.thirdPartyChineseCnt) + Number(that.dataForm.subcontractorChineseCnt)
          },
        },
        {
          label: "劳务公司派遣中方员工数",
          prop: "dispatchedChineseCnt",
          align: "left",
          labelWidth: 200,
          maxlength: 6,
          showWordLimit: true,
          readonly: !['edit','workflow'].includes(this.type),
          rules: [
            {
              required: true,
              message: "请输入劳务公司派遣中方员工数",
              trigger: "blur"
            },
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
          ],
          blur: ({value, column}) => {
            that.dataForm.chineseCnt = Number(value) + Number(that.dataForm.regeditedChineseCnt) + Number(that.dataForm.thirdPartyChineseCnt) + Number(that.dataForm.subcontractorChineseCnt)
          },
        },
        {
          label: "第三方中方技术服务人员数",
          prop: "thirdPartyChineseCnt",
          labelWidth: 200,
          maxlength: 6,
          showWordLimit: true,
          readonly: !['edit','workflow'].includes(this.type),
          rules: [
            {
              required: true,
              message: "请输入第三方中方技术服务人员数",
              trigger: "blur"
            },
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
          ],
          blur: ({value, column}) => {
            that.dataForm.chineseCnt = Number(value) + Number(that.dataForm.regeditedChineseCnt) + Number(that.dataForm.dispatchedChineseCnt) + Number(that.dataForm.subcontractorChineseCnt)
          },
        },
        {
          label: "分包商（含劳务）人数",
          prop: "subcontractorChineseCnt",
          labelWidth: 200,
          maxlength: 6,
          showWordLimit: true,
          readonly: !['edit','workflow'].includes(this.type),
          rules: [
            {
              required: true,
              message: "请输入分包商（含劳务）人数",
              trigger: "blur"
            },
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
          ],
          blur: ({value, column}) => {
            that.dataForm.chineseCnt = Number(value) + Number(that.dataForm.regeditedChineseCnt) + Number(that.dataForm.dispatchedChineseCnt) + Number(that.dataForm.thirdPartyChineseCnt)
          },
        },
        {
          label: "在册外籍职工数",
          prop: "regularForeignerCnt",
          labelWidth: 200,
          maxlength: 6,
          showWordLimit: true,
          readonly: !['edit','workflow'].includes(this.type),
          rules: [
            {
              required: true,
              message: "请输入在册外籍职工",
              trigger: "blur"
            },
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
          ],
          blur: ({value, column}) => {
            that.dataForm.foreignerCnt = Number(value) + Number(that.dataForm.otherForeignerCnt)
          },
        },
        {
          label: "其他外籍人员数",
          prop: "otherForeignerCnt",
          align: "left",
          labelWidth: 200,
          overHidden: true,
          maxlength: 6,
          showWordLimit: true,
          readonly: !['edit','workflow'].includes(this.type),
          rules: [
            {
              required: true,
              message: "请输入其他外籍人员数",
              trigger: "blur"
            },
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
          ],
          blur: ({value, column}) => {
            that.dataForm.foreignerCnt = Number(value) + Number(that.dataForm.regularForeignerCnt)
          },
        },
        {
          label: "内部人员增减说明",
          prop: "remark1",
          labelWidth: 200,
          maxlength: 200,
          showWordLimit: true,
          readonly: !['edit','workflow'].includes(this.type),
          type: "textarea",

        },
        {
          label: "外部人员增减说明",
          prop: "remark2",
          labelWidth: 200,
          maxlength: 200,
          showWordLimit: true,
          type: "textarea",
          readonly: !['edit','workflow'].includes(this.type),
        },
      ]
    },
    form3Column() {
      return [
        {
          label: "现场负责人姓名",
          prop: "onsiteDirectorName",
          maxlength: 30,
          showWordLimit: true,
          labelWidth: 200,
          readonly: !['edit','workflow'].includes(this.type),
        },
        {
          label: "现场负责人职务",
          prop: "onsiteDirectorPost",
          maxlength: 30,
          showWordLimit: true,
          labelWidth: 200,
          readonly: !['edit','workflow'].includes(this.type),
        },
        {
          label: "现场负责人联系方式",
          prop: "onsiteDirectorContact",
          labelWidth: 200,
          maxlength: 11,
          showWordLimit: true,
          readonly: !['edit','workflow'].includes(this.type),
          rules: [
            {pattern: /\b(?:\d{3}-\d{8}|\d{4}-\d{7}|\d{11})\b/, message: "手机号码格式错误"},
          ]
        },
        {
          label: "现场负责人卫星电话数量",
          prop: "onsiteDirectorCellphone",
          labelWidth: 200,
          maxlength: 6,
          showWordLimit: true,
          readonly: !['edit','workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
          ]
        },
      ]
    },
  },
  methods: {
    getByIdData() {
      getDetail(this.dataForm).then(res => {
        if (res.data.code === 200) {
          this.dataForm = res.data.data;
          this.dataForm.taskId = "";
        }
      })
    },
    //提交
    headSubmit() {
      if (this.dataForm.statDate) {
        this.$refs.formLayout1.$refs.form.validate((valid1) => {
          if (valid1) {
            this.$refs.formLayout2.$refs.form.validate((valid2) => {
              if (valid2) {
                this.$refs.formLayout3.$refs.form.validate((valid3) => {
                  if (valid3) {
                    if (this.dataForm.statDate) {
                      this.$loading();
                      if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
                        this.dataForm.dataState = '1';
                      }
                      submit(this.dataForm, this.type).then(res => {
                        if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                          this.dataForm = res.data.data;
                          this.type ='edit';
                          this.$confirm("确定将提交审核?", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning"
                          })
                            .then(() => {
                              this.$loading();
                              let dataForm = this.dataForm
                              this.rpTaskAttributeSubmit(dataForm);
                            }).catch(() =>{
                            this.$refs.formLayout1.$refs.form.allDisabled = false;
                            this.$refs.formLayout2.$refs.form.allDisabled = false;
                            this.$refs.formLayout3.$refs.form.allDisabled = false;
                          })
                            .finally(() => {
                              this.$loading().close();
                            });
                        } else {
                          this.$message.warning(res.data.data);
                        }
                      }).finally(()=>{
                        this.$refs.formLayout1.$refs.form.allDisabled = false;
                        this.$refs.formLayout2.$refs.form.allDisabled = false;
                        this.$refs.formLayout3.$refs.form.allDisabled = false;
                        this.$loading().close();
                      })
                    } else {
                      this.$message.warning("统计日期为空");
                    }
                  }
                })
              }
            })
          }
        })
      } else {
        this.$message.warning("统计日期为空");
      }
    },
    rpTaskAttributeSubmit(data) {
      if (!this.dataForm.id) {
        this.dataForm.createUserName = this.userInfo.real_name
      }
      this.dataForm.updateUserName = this.userInfo.real_name
      this.dataForm.projectArea = 1
      this.formProcess.id = data.id;
      this.formProcess.deptId = this.rowData.orgCode
      this.formProcess.paperName = this.dataForm.paperName;
      this.handleStartProcess5(true,true).then((res) => {
        data.processInsId = res.data.data;
        data.dataState = '2'; // 设置状态为新增生成 审核中
        submit(data).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.$emit('overseasDialog')
          }
        })
      });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    headSave() {
      this.$refs.formLayout1.$refs.form.validate((valid1) => {
        if (valid1) {
          this.$refs.formLayout2.$refs.form.validate((valid2) => {
            if (valid2) {
              this.$refs.formLayout3.$refs.form.validate((valid3) => {
                if (valid3) {
                  if (this.dataForm.statDate) {
                    this.$loading();
                    if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
                      this.dataForm.dataState = '1';
                    }
                    this.dataForm.processInsId = null
                    submit(this.dataForm, this.type).then(res => {
                      if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                        this.dataForm = res.data.data;
                        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                      } else {
                        this.$message.warning(res.data.data);
                      }
                    })
                      .finally(() => {
                        this.$loading().close();
                        this.$refs.formLayout1.$refs.form.allDisabled = false;
                        this.$refs.formLayout2.$refs.form.allDisabled = false;
                        this.$refs.formLayout3.$refs.form.allDisabled = false;
                      })
                  } else {
                    this.$message.warning("统计日期为空");
                  }
                }
              })

            }
          })
        }

      })
    },
    headCancel() {
      this.$emit('overseasDialog')
    },
  },
  created() {
    let type = this.rowData.type;
    this.type = type;
  },

  mounted() {
    this.getProcessId();
    if (this.type === 'workflow') {
      this.dataForm.id = this.rowData.id
      this.getByIdData();
    } else {
      let row = JSON.parse(this.rowData.row);
      this.dataForm.orgCode = this.rowData.orgCode;
      if (row.id != -1) {
        this.dataForm.projectCode = row.projectCode
        this.dataForm.projectName = row.projectName
        this.dataForm.businessTypeName = row.businessTypeName
        this.dataForm.countryName = row.countryName
        this.dataForm.provinceName = row.provinceName
        this.dataForm.status = row.status
        this.dataForm.id = row.id;
        this.getByIdData();
      } else {
        this.dataForm.projectCode = row.projectCode
        this.dataForm.projectName = row.projectName
        this.dataForm.businessTypeName = row.businessTypeName
        this.dataForm.status = row.status
        this.dataForm.countryName = row.countryName
        this.dataForm.provinceName = row.provinceName
      }
    }
  },
}
</script>
<style scoped>
::v-deep .el-textarea__inner {
  height: 70vh;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

</style>
