var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "占位提示" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData.placeholder,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "placeholder", $$v)
              },
              expression: "activeData.placeholder",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-input-number", {
            staticStyle: { width: "100%" },
            attrs: {
              placeholder: "默认值",
              min: _vm.activeData.min,
              max: _vm.activeData.max,
              step: _vm.activeData.step,
              precision: _vm.activeData.precision,
              "controls-position": "right",
            },
            model: {
              value: _vm.activeData.__config__.defaultValue,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
              },
              expression: "activeData.__config__.defaultValue",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "精度" } },
        [
          _c("el-input-number", {
            attrs: {
              min: 0,
              placeholder: "精度",
              "controls-position": "right",
            },
            model: {
              value: _vm.activeData.precision,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "precision", $$v)
              },
              expression: "activeData.precision",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "按钮位置" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.activeData["controls-position"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "controls-position", $$v)
                },
                expression: "activeData['controls-position']",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "" } }, [_vm._v("默认")]),
              _c("el-radio-button", { attrs: { label: "right" } }, [
                _vm._v("右侧"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示大写" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.showChinese,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "showChinese", $$v)
              },
              expression: "activeData.showChinese",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }