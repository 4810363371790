<template>
  <div>
    <el-container>
      <common-tree
        ref="commonTree"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="true"
        :loading="treeDialogLoading"
        :showCheckbox="false"
        treeTitle="题库分类"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <head-layout
          head-title="题库"
          :head-btn-options="headBtnOptions"
          @head-add-tabs="headAdd"
          @head-remove="headRemove"
          @head-import="headImport"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowDetail"
          @row-remove="rowRemove"
          @row-edit="rowEdit"
        ></grid-layout>
      </el-main>
    </el-container>
    <CommonDialog v-if="showTreeDialog" width="60%" :showFullscreen="true" :dialogTitle="$t(`cip.plat.sys.knowledge.title.treeHeadCateTitle`)" @cancel="showTreeDialog = false" @confirm="handleTreeNodeSave">
      <avue-form :option="treeDialogOption" ref="addForm" v-model="form" @submit="treeNodeSave"></avue-form>
    </CommonDialog>
    <CommonDialog v-if="excelBox" width="30%" :showBtn="false" dialogTitle="题库导入" @cancel="excelBox = false">
      <div class="import">
        <avue-form
          :option="excelOption"
          :upload-after="uploadAfter"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{
                $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </CommonDialog>
  </div>
</template>

<script>

import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import {exportBlob} from "@/api/common";
import {getToken} from '@/util/auth';
import {downloadXls} from "@/util/util";
import website from '@/config/website';
import {
  addEdu,
  getEduTree, removeEdu
} from '@/api/training/edutree'
import {getEduList, getList, remove} from "@/api/training/eduquestions";
import {mapGetters} from "vuex";
import {getDeptSetting} from "@/api/system/dept";
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "eduquestions",
  components: {
    CommonTree,
    GridLayout,
    CommonDialog
  },
  data() {
    return {
      treeParentIdDicData: [],
      node: {
        id: '',
        parentId: '0'
      },
      form: {},
      showTreeDialog: false,
      fullscreen: false,
      subjectType: undefined,
      treeData: [],
      defaultProps: {
        label: "title",
        value: "id",
        key: "id"
      },
      searchTitle: "name",
      query: {
        isInclude: "1"
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      tableData: [],
      tableLoading: false,
      treeDialogLoading: false,
      excelBox: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.excelFile"),
            prop: 'excelFile',
            type: 'upload',
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: 'data'
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            action: "/api/sinoma-training/eduquestions/import-question"
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: 'excelTemplate',
            formslot: true,
            span: 24,
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true)
      };
    },
    searchColumns() {
      return [
        {
          prop: "subjectName",
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "题目名称",
          span: 3,
        },
        // {
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + "出题依据",
        //   prop: "accordingTo",
        //   search: true,
        //   type: 'select',
        //   span: 3,
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        // },
        // {
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + "考试级别",
        //   prop: "lexaminationLevel",
        //   sortable: true,
        //   search: true,
        //   type: 'select',
        //   span: 3,
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        // },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "难易度",
          prop: "facilityType",
          sortable: true,
          search: true,
          type: 'select',
          span: 3,
          dicData:[],
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_FACILITY_VALUE',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "题目类型",
          prop: "topicType",
          sortable: true,
          search: true,
          type: 'select',
          span: 3,
          dicData:[],
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_TOPIC_TYPE',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permissionList.addBtn) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            icon: "",
            btnOptType: 'add',
          });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove",
            type: "button",
            icon: "",
            btnOptType: 'remove',
          });
      }
      buttonBtn.push(
        {
          label: "导入",
          emit: "head-import",
          type: "button",
          icon: "",
          btnOptType: 'import',
        });
      return buttonBtn;
    },
    gridRowBtn() {
      let buttonBtn = [];
      if (this.permissionList.editBtn) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.editBtn`),
            emit: "row-edit",
            type: "button",
            icon: ""
          });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "row-remove",
            type: "button",
            icon: ""
          })
      }
      return buttonBtn;
    },
    tableOptions() {
      return {
        linklabel: "code",
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "编号",
            prop: "code",
            width: 180,
            align: 'left',
            overHidden: true,

          },
          {
            label: "分类",
            prop: "subjectType",
            type: 'tree',
            dicData: [],
            props: {
              label: "title",
              value: "value",
            },
            dataType: 'number',
            overHidden: true,
            align: 'center',
            width: 100,
          },
          {
            label: "题目名称",
            prop: "subjectName",
            align: 'left',
            overHidden: true,
          },
          // {
          //   label: "出题依据",
          //   prop: "accordingTo",
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
          //   align: 'center',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   overHidden: true,
          //   width: 150,
          // },
          // {
          //   label: "考试级别",
          //   prop: "lexaminationLevel",
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   width: 100,
          //   overHidden: true,
          //   align: 'center',
          // },
          {
            label: "难易度",
            prop: "facilityType",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_FACILITY_VALUE',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 80,
            overHidden: true,
            align: 'center',
          },
          {
            label: "题目类型",
            prop: "topicType",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_TOPIC_TYPE',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 100,
            overHidden: true,
            align: 'center',
          },
        ]
      }
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "name",
            maxlength: 30,
            rules: [{
              required: true,
              message: "请输入分类名称",
              trigger: "blur"
            }]
          },
          {
            label: '上级名称',
            prop: 'parentId',
            type: 'tree',
            disabled: false,
            dicData: this.treeParentIdDicData,
            props: {
              label: 'title',
              value: 'id',
            },
            // rules: [{
            //   required: true,
            //   message: "请选择上级名称",
            //   trigger: "blur"
            // }]
          },
          {
            label: "编码",
            prop: "code",
            readonly: true,
            placeholder: "保存后自动生成",
            rules: [{
              required: false,
              message: "请输入编码",
              trigger: "blur"
            }]
          },
          {
            label: "分类",
            prop: "cate",
            type: 'select',
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=CATEGORY',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            rules: [{
              required: false,
              message: "请输入编码",
              trigger: "blur"
            }]
          },
          {
            label: "排序",
            prop: "sort",
            rules: [{
              required: true,
              message: "请输入排序",
              trigger: "blur"
            }]
          },
          {
            label: '备注',
            prop: 'note',
            type: 'textarea',
            maxlength: 100,
            overHidden: true,
            span: 24,
            minRows: "2",
            maxRows: "4",
            showWordLimit: true,
          }],
      }
    }
  },
  methods: {
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.page.currentPage = 1;
      this.onLoad(this.page)
      done();
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    handleTemplate() {
      exportBlob(`/api/sinoma-training/eduquestions/export-template?${this.website.tokenHeader}=${getToken()}`).then(res => {
        downloadXls(res.data, "题库模板.xlsx");
      })
    },
    initTree() {
      getEduTree(this.userInfo.tenantId)
        .then(res => {
          const {data} = res.data;
          this.treeData = data;
          const columns = this.findObject(this.$refs.gridLayOut.option.column, 'subjectType');
          this.treeParentIdDicData = res.data.data
          columns.dicData = res.data.data
        })
    },
    treeNodeDel(data) {
      if (data.children && data.children.length != 0) {
        this.$message({
          type: "warning",
          message: "当前分类下有子项，禁止删除",
        });
        return;
      }
      if (this.tableData.length != 0) {
        this.$message({
          type: "warning",
          message: "当前题库分类下有题目，禁止删除",
        });
        return;
      }
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        return removeEdu(data.id)
      }).then((res) => {
        if (res.data.msg == "操作成功") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        }
        this.node = this.$refs.commonTree.nodeData = {}
        this.initTree();
      })
    },
    treeNodeClick(node) {
      this.node = node;
      this.query.subjectType = node.id
      this.subjectType = node.id
      this.page.currentPage = 1
      this.onLoad(this.page)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      params.subjectType = this.subjectType
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear()
      });
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.query = query;
      this.query.subjectType = this.subjectType
      this.onLoad(this.page)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.query = query;
      this.query.subjectType = this.subjectType
      this.onLoad(this.page)
    },
    headAdd() {
      if (this.subjectType) {
        this.$router.push({
          path: '/training/question/eduquestionsEdit',
          query: {
            subjectType: this.subjectType,
            type: 'add',
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "请先选择分类!"
        });
      }
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.map(item => {
        return item.id
      }).join(',');
      if (selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.$refs.gridLayOut.selectionClear()
        });
    },
    headImport() {
      this.excelBox = true;
    },
    rowDetail(row) {
      this.$router.push({
        path: '/training/question/eduquestionsView',
        query: {
          row: JSON.stringify(row),
          type: 'view',
        }
      });
    },
    rowRemove(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
    rowEdit(row) {
      this.$router.push({
        path: '/training/question/eduquestionsEdit',
        query: {
          row: JSON.stringify(row),
          type: 'edit',
        }
      });
    },
    treeNodeSave(data, done) {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.treeDialogLoading = true
          if(!data.parentId){
            data.parentId = "0"
          }
          addEdu(data).then(res => {
            const {msg, data} = res.data;
            this.node = this.$refs.commonTree.nodeData = {}
            this.treeReadOnlyFlag = true;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            this.initTree();
            this.showTreeDialog = false;
          }).catch(err => {
            done()
          }).finally(() => {
            this.treeDialogLoading = false
          })
        }
      })
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      // if (this.$refs.addForm.form.parentId.length > 1) {
      //   this.$message({
      //     type: "warning",
      //     message: "题库分类不允许添加三级分类"
      //   });
      //   return;
      // }
      this.$refs.addForm.submit();
    },
    treeMenuAdd() {
      // if (this.node.parentId == '0') {
        this.form = {};
        this.form.parentId = this.node.id||'0';
        this.treeReadOnlyFlag = false;
        this.fullscreen = false;
        this.showTreeDialog = true;
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "题库分类不允许添加三级分类"
      //   });
      // }
    },
    treeMenuEdit() {
      this.form = this.node
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.query.subjectType = this.subjectType
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.query.subjectType = this.subjectType
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
  },
  created() {
    this.initTree()
    this.onLoad(this.page)
  }
}
</script>

<style scoped>

</style>
