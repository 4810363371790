var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      staticClass: "SNOWS-common-drawer board",
      attrs: {
        title: "切换门户",
        visible: _vm.drawer,
        direction: "rtl",
        size: "260px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
          attrs: { "element-loading-text": _vm.loadingText },
        },
        [
          _vm.list.length
            ? _c(
                "div",
                _vm._l(_vm.list, function (item, i) {
                  return _c("div", { key: i, staticClass: "item" }, [
                    _c("p", { staticClass: "item-title" }, [
                      _vm._v(_vm._s(item.fullName)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "item-list" },
                      _vm._l(item.children, function (child, ii) {
                        return _c(
                          "div",
                          {
                            key: ii,
                            staticClass: "item-list-item",
                            class: { active: _vm.activeId === child.id },
                            on: {
                              click: function ($event) {
                                return _vm.selectItem(child.id)
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "com-hover" }, [
                              _vm._v(_vm._s(child.fullName)),
                            ]),
                            _c("i", { staticClass: "el-icon-success" }),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                }),
                0
              )
            : _c("p", { staticClass: "noData-txt" }, [
                _vm._v(_vm._s(_vm.$t("common.noData"))),
              ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }