<template>
  <div class="checklist">
    <div>
      <head-layout head-title="事项统计" ></head-layout>
    <div class="charts-box">
      <div class="icon charts-icon no-border-right" >
        <img src="~@/assets/2187a1797801a26ad9801d468b41cb0.png"  width="auto" height="auto">
      </div>
      <div class="bar charts-icon no-border-right" ref="bar"  v-loading="loading"></div>
      <div class="pie charts-icon " ref="pie"  v-loading="loading1"></div>
    </div>
    </div>
    <div class="table-box">
      <head-layout head-title="文明规范事项清单" :head-btn-options="headBtnOptions" @head-romve="headRomve" @head-add="headAdd" ></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-span="3" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template #hdLevel="{row}">
          <el-tag v-if="hdLevel[row.hdLevel]" :type="hdLevelIcon[row.hdLevel]"
            effect="dark">{{hdLevel[row.hdLevel]}}
          </el-tag>
        </template>
        <template #customBtn="{row}">
          <el-button style="margin: 0 3px" type="text" size="small" v-if="row.hdStatus !='prepare' " @click="rowView(row)">查看</el-button>
          <el-button style="margin: 0 3px" v-if="row.hdStatus =='prepare'&& userInfo.user_id == row.createUser" type="text" size="small" @click="rowEdit(row)">编辑</el-button>
          <el-button style="margin: 0 3px" v-if="row.hdStatus=='prepare' && userInfo.user_id == row.createUser" type="text" size="small" @click="rowRemove(row)">删除</el-button>
        </template>
      </grid-layout>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import * as  API from "@/api/civilizedNorm/checklist";
import checklist from "@/views/hiddenTrouble/civilizedNorm/checklist/checklist";
import riskView from "@/views/hiddenTrouble/civilizedNorm/riskView";
import {mapGetters} from "vuex";
export default {
  mixins:[checklist,riskView],
  components: {
    GridLayout,
    HeadLayout
  },

  data() {
    return {
      treeData: [],
      selectionList: [],
      myPie:null,
      myBar:null,
      loading1:true,
      loading:true,
    }
  },
  mounted() {
    this.onLoad(this.page, {});
    this.initChartData()
  },
  methods: {
    headAdd(){
      this.$router.push({
        path: `/hiddenTrouble/civilizedNorm/checklist/edit`,
        query: {
          type: 'add',
        }
      })
    },
    async initChartData(){
      let data=await API.getPolicyDocumentList({organizationId:this.userInfo.dept_id}).then(res=>res.data.data)
      let lineData=await API.getStatistics({organizationId:this.userInfo.dept_id}).then(res=>res.data.data)

      this.loading=false
      this.loading1=false

      this.initLine(this.myPie,lineData)
      if(data.statistics){
        this.initPie(this.myBar,data.statistics)
      }
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 获取列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      API.getList({current:page.currentPage, size:page.pageSize, ...params,organizationId:this.userInfo.dept_id}).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      })
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/hiddenTrouble/civilizedNorm/checklist/edit`,
        query: {
          id: row.id,
          type: 'edit',
        }
      })
    },
    rowView(row){
      this.$router.push({
        path: `/hiddenTrouble/civilizedNorm/checklist/edit`,
        query: {
          id: row.id,
          type: 'view',
        }
      })
    },
      headRomve() {
          this.rowRemove(this.selectionList)
      },
    rowRemove(row){
      this.selectionList=row
        if (this.selectionList.length === 0) {
            this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
            return;
        }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let formData =new  FormData()
          formData.append('ids',this.ids)
          return API.remove(formData);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
  }
}

</script>
<style lang="scss">
.checklist{
  .table-box{
    height: calc(100% - 307px) !important;
  }
  .avue-crud .el-table {
    height: calc(100vh - 581px) !important;
    max-height: calc(100vh - 581px) !important;
  }
.charts-box{
  background-color: #ffffff;
  padding: 0 12px;
  display: flex;
  .charts-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 3);
    height: 260px;
    background: #ffffff;
    border: 1px solid rgb(204, 204, 204);
    border-top:0;
  }
  .no-border-right{
    border-right:0;
  }

}
}
</style>
