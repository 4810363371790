var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", { ref: "pie-echart", staticClass: "echarts-box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("风险等级分布")]),
          _c(
            "div",
            { staticStyle: { width: "100%", height: "100%" } },
            [
              _vm.echartsData.length
                ? _c("div", {
                    ref: "pieChart",
                    staticClass: "echart",
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { id: "pieChart" },
                  })
                : _c("el-empty", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { description: "暂无数据", "image-size": 120 },
                  }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { ref: "", staticClass: "canvas-box" },
          [
            _c("div", { staticClass: "title-box" }, [
              _c("div", { staticClass: "title" }, [_vm._v("项目风险区域分布")]),
              _c(
                "div",
                { staticClass: "right-btn" },
                [
                  _vm.mapUrl
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.linkTool("edit")
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                  _vm.mapUrl
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.linkTool("export")
                            },
                          },
                        },
                        [_vm._v("导出")]
                      )
                    : _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.linkTool("add")
                            },
                          },
                        },
                        [_vm._v("去上传")]
                      ),
                ],
                1
              ),
            ]),
            _vm.mapUrl
              ? _c("el-image", {
                  staticClass: "image-box",
                  staticStyle: { "max-width": "300px", "max-height": "300px" },
                  attrs: { src: _vm.mapUrl, fit: "fill" },
                })
              : _c("el-empty", {
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { description: "暂无数据", "image-size": 120 },
                }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "el-container",
            [
              _c("CommonTree", {
                staticStyle: { "font-size": "13px" },
                attrs: {
                  treeTitle: "风险源",
                  defaultProps: _vm.defaultProps,
                  isShowdig: true,
                  searchTitle: _vm.searchTitle,
                  showCheckbox: false,
                  treeData: _vm.treeData,
                  treeExpand: true,
                  risk: true,
                },
                on: {
                  getNodeClick: _vm.handleNodeClick,
                  getTreeAdd: _vm.getTreeAdd,
                  getTreeDelete: _vm.getTreeDelete,
                  getTreeEdit: _vm.getTreeEdit,
                },
              }),
              _c(
                "el-main",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.headBtnOptions,
                      "head-title": "风险库",
                    },
                    on: {
                      "head-add": function ($event) {
                        return _vm.opDialog()
                      },
                      "head-delete": _vm.deletesList,
                      "head-export": _vm.handleExport,
                      "head-import": _vm.handleImport,
                      "head-introduce": _vm.headIntroduce,
                    },
                  }),
                  _c("grid-head-layout", {
                    ref: "searchForm",
                    attrs: {
                      searchSpan: "4",
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.searchChange,
                      "grid-head-empty": _vm.searchReset,
                    },
                    model: {
                      value: _vm.searchForm,
                      callback: function ($$v) {
                        _vm.searchForm = $$v
                      },
                      expression: "searchForm",
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    attrs: {
                      "data-total": _vm.tableTotal,
                      gridRowBtn: _vm.gridRowBtn,
                      page: _vm.page,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                      tableOptions: _vm.tableOptions,
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "page-size-change": _vm.handleSizeChange,
                      "page-current-change": _vm.handleCurrentChange,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                      "grid-edit": _vm.rowEdit,
                      "grid-romve": _vm.rowDel,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "riskLevel",
                        fn: function ({ row }) {
                          return [
                            row.lecdD
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                      "align-item": "center",
                                      padding: "4px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        style: {
                                          border: `1px solid ${row.lecdColor}`,
                                          borderRadius: "4px",
                                          color: row.lecdColor,
                                          backgroundColor: _vm.computedDlevel(
                                            row.lecdD
                                          ).bg,
                                          textAlign: "center",
                                          display: "inline-block",
                                          padding: "0 20px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.lecdD) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "lecdDScore",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  row.lecdDScore == -1 ? "" : row.lecdDScore
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "riskCode",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.linkDetail(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.riskCode))]
                            ),
                          ]
                        },
                      },
                      {
                        key: "libraryStatus",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  row.libraryStatus == "FINISHED"
                                    ? "完成"
                                    : "草稿"
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showTreeDialog
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              staticClass: "qmDialog",
              attrs: {
                title: "风险源配置",
                modal: false,
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.showTreeDialog,
                width: "60%",
                fullscreen: _vm.fullscreen,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showTreeDialog = $event
                },
              },
            },
            [
              _c("div", { staticClass: "positionabs" }, [
                _c("i", {
                  staticClass: "el-icon-full-screen",
                  on: {
                    click: function ($event) {
                      return _vm.fullClick()
                    },
                  },
                }),
              ]),
              _c("avue-form", {
                ref: "addForm",
                attrs: { option: _vm.treeDialogOption },
                on: { submit: _vm.treeNodeSave },
                model: {
                  value: _vm.treeForm,
                  callback: function ($$v) {
                    _vm.treeForm = $$v
                  },
                  expression: "treeForm",
                },
              }),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.treeDialogLoading,
                          expression: "treeDialogLoading",
                        },
                      ],
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.showTreeDialog = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.treeDialogLoading,
                          expression: "treeDialogLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleTreeNodeSave },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.introduceShow
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              staticClass: "qmDialog",
              attrs: {
                title: "风险引用",
                modal: false,
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.introduceShow,
                width: "80%",
                fullscreen: _vm.fullscreen,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.introduceShow = $event
                },
              },
            },
            [
              _c(
                "el-container",
                { staticStyle: { height: "500px" } },
                [
                  _c("CommonTree", {
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      treeTitle: "风险源分类",
                      defaultProps: _vm.defaultProps,
                      isShowdig: false,
                      searchTitle: _vm.searchTitle,
                      showCheckbox: false,
                      treeData: _vm.introduceTree,
                      treeExpand: true,
                      risk: true,
                    },
                    on: { getNodeClick: _vm.handleNodeIntroduce },
                  }),
                  _c(
                    "el-main",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "company",
                          staticStyle: { padding: "0 10px" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "selectTree",
                              attrs: {
                                clearable: "",
                                placeholder: "请选择公司",
                              },
                              on: { clear: _vm.clearHandle },
                              model: {
                                value: _vm.companyName,
                                callback: function ($$v) {
                                  _vm.companyName = $$v
                                },
                                expression: "companyName",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                {
                                  attrs: {
                                    value: _vm.companyId,
                                    label: _vm.companyName,
                                  },
                                },
                                [
                                  _c("el-tree", {
                                    attrs: {
                                      id: "tree-option",
                                      data: _vm.companySelection,
                                      "highlight-current": true,
                                      props: _vm.companydefaultProps,
                                      accordion: true,
                                      "node-key": "id",
                                      "default-expanded-keys":
                                        _vm.defaultExpandedKey,
                                    },
                                    on: {
                                      "node-click": _vm.handleNodeClickListTree,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("grid-head-layout", {
                        ref: "searchForm",
                        attrs: { "search-columns": _vm.introduceSearchColumns },
                        on: {
                          "grid-head-search": _vm.introduceSearch,
                          "grid-head-empty": _vm.introduceReset,
                        },
                        model: {
                          value: _vm.searchForm,
                          callback: function ($$v) {
                            _vm.searchForm = $$v
                          },
                          expression: "searchForm",
                        },
                      }),
                      _c("grid-layout", {
                        ref: "gridLayout",
                        attrs: {
                          "data-total": _vm.introduceTableTotal,
                          gridRowBtn: _vm.introduceGridRowBtn,
                          page: _vm.introducePage,
                          tableData: _vm.introduceTableData,
                          tableLoading: _vm.introduceTableLoading,
                          tableOptions: _vm.introduceTableOptions,
                          "search-columns": _vm.introduceSearchColumns,
                        },
                        on: {
                          "page-size-change": _vm.handleSizeIntroduce,
                          "page-current-change": _vm.handleCurrentIntroduce,
                          "page-refresh-change": _vm.onLoad,
                          "gird-handle-select-click":
                            _vm.introduceSelectionChange,
                          "grid-select": _vm.introduceSelect,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "riskCode",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.linkDetail(row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(row.riskCode))]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "lecdDScore",
                              fn: function ({ row }) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        row.lecdDScore == -1
                                          ? ""
                                          : row.lecdDScore
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "riskLevel",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-item": "center",
                                        padding: "4px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            border: `1px solid ${row.lecdColor}`,
                                            borderRadius: "4px",
                                            color: row.lecdColor,
                                            backgroundColor: _vm.computedDlevel(
                                              row.lecdD
                                            ).bg,
                                            textAlign: "center",
                                            display: "inline-block",
                                            padding: "0 4px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(row.lecdD) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3027067821
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.treeDialogLoading,
                          expression: "treeDialogLoading",
                        },
                      ],
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.introduceShow = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleIntroduceSave },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.excelBox
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "检查项导入",
                "append-to-body": "",
                visible: _vm.excelBox,
                width: "555px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.excelBox = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "import" },
                [
                  _c(
                    "avue-form",
                    {
                      attrs: {
                        option: _vm.excelOption,
                        "upload-after": _vm.uploadAfter,
                      },
                      model: {
                        value: _vm.excelOption.excelForm,
                        callback: function ($$v) {
                          _vm.$set(_vm.excelOption, "excelForm", $$v)
                        },
                        expression: "excelOption.excelForm",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "excelTemplate" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplate },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("cip.plat.sys.user.field.download")
                                  )
                              ),
                              _c("i", {
                                staticClass: "el-icon-download el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }