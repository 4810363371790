var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "重大危险源备案清单",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-add": _vm.addList, "head-export": _vm.headExport },
      }),
      _c("grid-head-layout", {
        ref: "searchForm",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayout",
        attrs: {
          "data-total": _vm.total,
          gridRowBtn: _vm.gridRowBtn,
          page: _vm.page,
          tableData: _vm.tableData,
          tableLoading: _vm.tableLoading,
          tableOptions: _vm.tableOptions,
          "search-columns": _vm.searchColumns,
        },
        on: {
          "page-size-change": _vm.getList,
          "page-current-change": _vm.getList,
          "page-refresh-change": _vm.getList,
          "grid-edit": _vm.rowEdit,
          "grid-romve": _vm.rowDel,
        },
        scopedSlots: _vm._u([
          {
            key: "endDate",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(row.startDate + "至" + row.endDate) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "customBtn",
            fn: function ({ row, index }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.editItem(row, index)
                      },
                    },
                  },
                  [_vm._v("\n        编辑\n      ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.removeItem(row, index)
                      },
                    },
                  },
                  [_vm._v("\n        删除\n      ")]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dtitle,
                "append-to-body": "",
                visible: _vm.dialogVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "from",
                  attrs: {
                    model: _vm.form,
                    "label-width": "100px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "重大危险源名称",
                                prop: "dangersName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入重大危险源名称",
                                  clearable: "",
                                  maxlength: "255",
                                },
                                model: {
                                  value: _vm.form.dangersName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "dangersName", $$v)
                                  },
                                  expression: "form.dangersName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "重大危险源所在地",
                                prop: "location",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入重大危险源所在地",
                                  clearable: "",
                                  maxlength: "255",
                                },
                                model: {
                                  value: _vm.form.location,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "location", $$v)
                                  },
                                  expression: "form.location",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生产单元(设计产能/实际产能)",
                                prop: "productionUnit",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入生产单元",
                                  clearable: "",
                                  maxlength: "255",
                                },
                                model: {
                                  value: _vm.form.productionUnit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "productionUnit", $$v)
                                  },
                                  expression: "form.productionUnit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "存储单元(设计储量/实际产量)",
                                prop: "storageUnit",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入存储单元",
                                  clearable: "",
                                  maxlength: "255",
                                },
                                model: {
                                  value: _vm.form.storageUnit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "storageUnit", $$v)
                                  },
                                  expression: "form.storageUnit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "临界量(t)", prop: "critical" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 1,
                                  precision: 0,
                                  placeholder: "请输入临界量(t)",
                                  clearable: "",
                                  maxlength: 255,
                                },
                                on: { input: _vm.handleInput },
                                model: {
                                  value: _vm.form.critical,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "critical", $$v)
                                  },
                                  expression: "form.critical",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "重大危险源等级",
                                prop: "dangersLevelName",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择重大危险源等级",
                                    "value-key": "dictKey",
                                    clearable: "",
                                  },
                                  on: { change: _vm.levelsChange },
                                  model: {
                                    value: _vm.levels,
                                    callback: function ($$v) {
                                      _vm.levels = $$v
                                    },
                                    expression: "levels",
                                  },
                                },
                                _vm._l(_vm.levelArr, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备案号", prop: "recordNum" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入备案号",
                                  maxlength: "255",
                                },
                                model: {
                                  value: _vm.form.recordNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "recordNum", $$v)
                                  },
                                  expression: "form.recordNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "有效期", prop: "startDate" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "daterange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                on: { change: _vm.timesChange },
                                model: {
                                  value: _vm.times,
                                  callback: function ($$v) {
                                    _vm.times = $$v
                                  },
                                  expression: "times",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "包保责任人", prop: "respUsers" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请选择包保责任人" },
                                  model: {
                                    value: _vm.form.respUsers,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "respUsers", $$v)
                                    },
                                    expression: "form.respUsers",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: { click: _vm.personClick },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.saveLoading,
                          expression: "saveLoading",
                        },
                      ],
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("\n        取消\n      ")]
                  ),
                  _vm.type != "detail"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.saveLoading,
                              expression: "saveLoading",
                            },
                          ],
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.handleSave },
                        },
                        [_vm._v("\n        保存\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.personShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.personShow = $event
            },
          },
        },
        [
          _c("UserDeptMultipleDialog", {
            ref: "UserDeptMultipleDialog",
            on: { "select-all-data": _vm.selectData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }