var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type != "trainingTaskList"
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.headBtnOptions,
                      "head-title":
                        this.$route.query.type == "edit"
                          ? "平台课程编辑"
                          : this.$route.query.type == "view"
                          ? "平台课程查看"
                          : "平台课程新增",
                    },
                    on: {
                      "head-save": _vm.headSave,
                      "head-save-back": function ($event) {
                        return _vm.headSave(true)
                      },
                      "head-cancel": _vm.headCancel,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "newZtFormBox",
          staticStyle: {
            "background-color": "white",
            "padding-top": "10px",
            "padding-right": "10px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _vm.type == "trainingTaskList"
                    ? _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "课程名称" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请选择课程名称",
                                  maxlength: "255",
                                  readonly: "",
                                  disabled:
                                    this.trainingTaskListType == "edit" ||
                                    this.trainingTaskListType == "view",
                                },
                                on: { focus: _vm.chooseACourse },
                                model: {
                                  value: _vm.form.courseName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "courseName", $$v)
                                  },
                                  expression: "form.courseName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "课程名称", prop: "courseName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.readonly,
                                  placeholder: "课程名称",
                                  maxlength: "255",
                                },
                                model: {
                                  value: _vm.form.courseName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "courseName", $$v)
                                  },
                                  expression: "form.courseName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "培训类型",
                            prop: "trainingScenarios",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "培训类型",
                              maxlength: "50",
                            },
                            model: {
                              value: _vm.form.trainingScenarios,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "trainingScenarios", $$v)
                              },
                              expression: "form.trainingScenarios",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合计课时" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              step: 0.1,
                              max: 10000,
                              disabled: "",
                            },
                            model: {
                              value: _vm.form.totalHours,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "totalHours", $$v)
                              },
                              expression: "form.totalHours",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "达标课时", prop: "meetLesson" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              disabled: _vm.readonly,
                              precision: 1,
                              step: 0.5,
                              max: 10000,
                            },
                            model: {
                              value: _vm.form.meetLesson,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "meetLesson", $$v)
                              },
                              expression: "form.meetLesson",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "课程类型:", prop: "courseType" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择课程类型",
                                "popper-append-to-body": false,
                                disabled: _vm.readonly,
                              },
                              model: {
                                value: _vm.form.courseType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "courseType", $$v)
                                },
                                expression: "form.courseType",
                              },
                            },
                            _vm._l(_vm.courseTypeList, function (item) {
                              return _c("el-option", {
                                key: item.dictKey,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "课程简介", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.readonly,
                              placeholder: "说明",
                              type: "textarea",
                              maxlength: "300",
                              "show-word-limit": "",
                              rows: 3,
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.subTableheadBtnOptions,
          "head-title": "视频",
        },
        on: {
          "head-remove": _vm.headRemove,
          "head-add-tabs": _vm.subTableAdd,
          "head-knowledge": _vm.headKnowledge,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayoutVide",
        staticClass: "serviceType",
        attrs: {
          "grid-row-btn": _vm.gridRow,
          "table-options": _vm.subTableOption,
          "table-data": _vm.videdata,
          "table-loading": false,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "row-view": _vm.rowView,
          "row-remove": _vm.rowRemove,
          "row-add": _vm.rowAdd,
        },
      }),
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.subTableheadBtnOptions,
          "head-title": "文档",
        },
        on: {
          "head-remove": _vm.headRemove,
          "head-add-tabs": _vm.subTableAddHeadCite,
          "head-knowledge": _vm.coursewareHeadKnowledge,
        },
      }),
      _c("grid-layout", {
        ref: "crud",
        staticClass: "serviceType",
        attrs: {
          "grid-row-btn": _vm.coursewareGridRowBtn,
          "table-options": _vm.coursewareTableOption,
          "table-data": _vm.coursewareData,
          "table-loading": false,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "row-view": _vm.rowView,
          "row-remove": _vm.rowRemove,
        },
      }),
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.testItemContent,
          "head-title": "试题",
        },
        on: {
          "head-remove": _vm.headRemove,
          "head-cite": _vm.testQuestionsHeadCite,
        },
      }),
      _c("grid-layout", {
        ref: "crud",
        staticClass: "serviceType",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.testQuestionssubTableOption,
          "table-data": _vm.testQuestionsData,
          "table-loading": false,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "row-view": _vm.rowViewTestQuestions,
          "row-remove": _vm.rowRemove,
        },
      }),
      _vm.showKnowledgeDialog
        ? _c(
            "el-dialog",
            {
              staticClass: "paperDialog knowledge",
              attrs: {
                top: "9vh",
                title: "选择知识",
                modal: true,
                "modal-append-to-body": false,
                visible: _vm.showKnowledgeDialog,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showKnowledgeDialog = $event
                },
                close: _vm.closeKnowledgeDialog,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("CommonTree", {
                    staticStyle: { "flex-shrink": "0" },
                    attrs: {
                      treeData: _vm.treeData,
                      defaultProps: _vm.defaultProps,
                      loading: _vm.treeLoading,
                      searchTitle: _vm.searchTitle,
                      showCheckbox: false,
                      treeTitle: "知识库",
                    },
                    on: { getNodeClick: _vm.repositoryTreeNodeClick },
                  }),
                  _c(
                    "div",
                    { staticStyle: { width: "calc(100% - 280px)" } },
                    [
                      _c("grid-layout", {
                        ref: "gridKnowledgeLayOut",
                        attrs: {
                          "table-options": _vm.tableKnowledgeOptions,
                          "table-data": _vm.tableKnowledgeData,
                          "table-loading": _vm.tableKnowledgeLoading,
                          "data-total": _vm.pageKnowledge.total,
                          page: _vm.pageKnowledge,
                          selectable: _vm.selectable,
                        },
                        on: {
                          "page-current-change": _vm.onLoadKnowledge,
                          "page-size-change": _vm.onLoadKnowledge,
                          "page-refresh-change": _vm.onLoadKnowledge,
                          "gird-handle-select-click":
                            _vm.selectionKnowledgeChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialogBtn",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.confirmKnowledge },
                    },
                    [_vm._v("确认")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: { click: _vm.closeKnowledgeDialog },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "paperDialog",
          attrs: {
            title: "选择试卷",
            "modal-append-to-body": false,
            visible: _vm.showExaminationDialog,
            width: "80%",
            top: "9vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showExaminationDialog = $event
            },
          },
        },
        [
          _vm.showExaminationDialog
            ? _c(
                "el-container",
                [
                  _c("CommonTree", {
                    attrs: {
                      titleShow: false,
                      treeData: _vm.examinationTreeData,
                      defaultProps: _vm.examinationDefaultProps,
                      searchTitle: _vm.searchTitle,
                      showCheckbox: false,
                    },
                    on: { getNodeClick: _vm.treeNodeClick },
                  }),
                  _c(
                    "el-main",
                    [
                      _c("grid-head-layout", {
                        ref: "gridHeadLayout",
                        attrs: { "search-columns": _vm.searchColumns },
                        on: {
                          "grid-head-search": _vm.gridHeadSearch,
                          "grid-head-empty": _vm.gridHeadEmpty,
                        },
                      }),
                      _c("grid-layout", {
                        ref: "gridLayOut",
                        staticClass: "paperCurd",
                        attrs: {
                          "table-options": _vm.tableOptions,
                          "table-data": _vm.tableData,
                          "table-loading": _vm.tableLoading,
                          "data-total": _vm.paperPage.total,
                          page: _vm.paperPage,
                          selectable: _vm.selectable,
                        },
                        on: {
                          "page-current-change": _vm.onLoad,
                          "page-size-change": _vm.onLoad,
                          "page-refresh-change": _vm.onLoad,
                          "gird-handle-select-click": _vm.selectionChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialogBtn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.confirm },
                },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                { attrs: { size: "medium" }, on: { click: _vm.closeDialog } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "上传",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showVideo,
            width: "35%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVideo = $event
            },
          },
        },
        [
          _vm.showVideo
            ? _c(
                "el-form",
                {
                  ref: "rulesPopups",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rulesPopups,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.dialogType == "courseware"
                                    ? "课件名称"
                                    : "课程名称",
                                prop: "courseName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "240px" },
                                attrs: {
                                  placeholder:
                                    _vm.dialogType == "courseware"
                                      ? "课件名称"
                                      : "课程名称",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.formVideo.fileName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formVideo, "fileName", $$v)
                                  },
                                  expression: "formVideo.fileName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "附件上传" } },
                            [
                              _c("SingleFIleUpload", {
                                ref: "SingleFIleUpload",
                                attrs: {
                                  accept: "",
                                  fileSize: "2000",
                                  beforeUpload: _vm.beforeUpload,
                                },
                                on: {
                                  fileIds: _vm.handleAvatarSuccess,
                                  handleRemove: _vm.handleRemove,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showVideo = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.videoButton,
                  },
                  on: { click: _vm.videoSubmission },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("video", {
        ref: "video",
        staticStyle: { display: "none" },
        attrs: { id: "myVideo", src: _vm.videoUrl, crossOrigin: "anonymous" },
      }),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: { id: "myCanvas", crossOrigin: "anonymous" },
      }),
      _vm.showKnowledgeSearchDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                top: "9vh",
                title: "知识库选择",
                visible: _vm.showKnowledgeSearchDialog,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showKnowledgeSearchDialog = $event
                },
              },
            },
            [
              _c("KnowledgeSearchDialog", {
                ref: "knowledgeSearchDialog",
                attrs: {
                  dialogType: _vm.dialogType,
                  selectable: _vm.knowledgeSelectable,
                },
                on: {
                  "close-dialog": _vm.closeKnowledgeSearchDialog,
                  "select-data": _vm.selectData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }