<template>
  <div>
    <div class="disFlex">
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value1) || 0 }}<span>个</span>
          </div>
          <img src="@/assets/images/board/one.png">
        </div>
        <div class="card-pd-4-26-bottom">
          在建项目、机构总数量
        </div>
      </div>
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value2) || 0 }}<span>人</span>
          </div>
          <img src="@/assets/images/board/two.png">
        </div>
        <div class="card-pd-4-26-bottom">
          在建项目机构总人数量
        </div>
      </div>
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value3) || 0 }}<span>人</span>
          </div>
          <img src="@/assets/images/board/three.png">
        </div>
        <div class="card-pd-4-26-bottom">
          专职安全环保人员
        </div>
      </div>
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value4) || 0 }}<span>小时</span>
          </div>
          <img src="@/assets/images/board/four.png">
        </div>
        <div class="card-pd-4-26-bottom">
          项目安全生产工时
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            安全环保投入统计
          </span>
          <div class="card-pd-2-top-btn">
            <!-- <img src="@/assets/images/board/five.png" @click="openDiscount('安全环保投入统计','ZB00104_M')">
            <img src="@/assets/images/board/six.png" @click="openTableData('安全环保投入统计（单位：万元）','ZB00105_Y',['安全环保投入','安全投入','环保投入'])"> -->
            <!-- <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png"> -->
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value5) || 0 }}<span>万元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              安全环保投入总额
            </div>
          </div>
          <div class="card-pd-2-BotLitte">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value6) || 0 }}<span>万元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              安全投入金额
            </div>
          </div>
          <div class="card-pd-2-BotLitte">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value7) || 0 }}<span>万元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              环保投入金额
            </div>
          </div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            项目分类统计
          </span>
          <div class="card-pd-2-top-btn">
<!--            <img src="@/assets/images/board/five.png">-->
            <!-- <img src="@/assets/images/board/six.png" @click="openTableData('项目分类统计（单位：个）','ZB00106_Y',['项目总数','建设项目数','运维项目数'])"> -->
            <!-- <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png"> -->
          </div>
        </div>
        <div style="display: flex;margin-top: 22px;">
          <div id="chart-one">
          </div>
          <div class="chart-one-info">
            <div class="chart-one-info-box">
              <div class="info-circle"></div>
              <span>建设项目</span>
              <span style="margin: 0 12px">{{ formatWithCommas(dataForm.value8) || 0 }}个</span>
              <span>{{ dataForm.value10 || 0 }}%</span>
            </div>
            <div class="chart-one-info-box" style="margin-top: 18px">
              <div class="info-circle" style="background: linear-gradient(180deg, #FFAB2D 0%, #FFCD82 100%);"></div>
              <span>运维项目</span>
              <span style="margin: 0 12px">{{ formatWithCommas(dataForm.value9) || 0 }}个</span>
              <span>{{ dataForm.value11 || 0 }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            境外在建项目、运营机构与人员分布情况
          </span>
          <!-- <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div> -->
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              项目
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value12) || 0 }}<span>个</span>
            </div>
            <slider :value="dataForm.value14"/>
            <div class="botLitte2-info">
              风险地区项目：<span>{{ formatWithCommas(dataForm.value13) || 0 }}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ dataForm.value14 || 0 }}%</span>
            </div>
          </div>
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              机构
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value15) || 0 }}<span>个</span>
            </div>
            <slider :value="dataForm.value17"/>
            <div class="botLitte2-info">
              风险地区机构：<span>{{ formatWithCommas(dataForm.value16) || 0 }}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ dataForm.value17 || 0 }}%</span>
            </div>
          </div>
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              人员
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value18) || 0 }}<span>个</span>
            </div>
            <slider :value="dataForm.value20"/>
            <div class="botLitte2-info">
              风险地区人员：<span>{{ formatWithCommas(dataForm.value19) || 0 }}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ dataForm.value20 || 0 }}%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            境内在建项目、运营机构与人员分布情况
          </span>
          <!-- <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div> -->
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              项目
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value21) || 0 }}<span>个</span>
            </div>
            <slider :value="dataForm.value23"/>
            <div class="botLitte2-info">
              风险地区项目：<span>{{ formatWithCommas(dataForm.value22) || 0 }}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ dataForm.value23 || 0 }}%</span>
            </div>
          </div>
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
            机构
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value24) || 0 }}<span>个</span>
            </div>
            <slider :value="dataForm.value26"/>
            <div class="botLitte2-info">
              风险地区机构：<span>{{ formatWithCommas(dataForm.value25) || 0 }}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ dataForm.value26 || 0 }}%</span>
            </div>
          </div>
          <div class="card-pd-2-BotLitte2">
            <div class="botLitte2-title">
              人员
            </div>
            <div class="botLitte2-number">
              {{ formatWithCommas(dataForm.value27) || 0 }}<span>个</span>
            </div>
            <slider :value="dataForm.value29"/>
            <div class="botLitte2-info">
              风险地区人员：<span>{{ formatWithCommas(dataForm.value28) || 0 }}</span>
            </div>
            <div class="botLitte2-info">
              占比：<span>{{ dataForm.value29 || 0 }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            境外国家项目数TOP5及人数统计
          </span>
          <!-- <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div> -->
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-two"></div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            境内省区项目数TOP5及人数统计
          </span>
          <!-- <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div> -->
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-three"></div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            下属组织在建项目、运营机构数量TOP5
          </span>
          <!-- <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div> -->
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-four"></div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            下属组织在建项目、运营机构人员分布TOP5
          </span>
          <!-- <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div> -->
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-five"></div>
        </div>
      </div>
    </div>
    <discount ref="discount"></discount>
    <columnar ref="columnar"></columnar>
    <tableData ref="tableData"></tableData>
  </div>
</template>
<script>
import slider from "@/views/business/boardDemo/components/slider";
import discount from "@/views/business/boardDemo/components/discount";
import columnar from "@/views/business/boardDemo/components/columnar";
import tableData from "@/views/business/boardDemo/components/tableData";
import {appIndex, appIndexChild} from "@/api/user";
import {mapGetters} from "vuex";

export default {
  components: {slider, discount, columnar, tableData},
  data() {
    return {
      year: '',
      month: '',
      dataForm: {
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
        value7: '',
        value8: '',
        value9: '',
        value10: '',
        value11: '',
        value12: '',
        value13: '',
        value14: '',
        value15: '',
        value16: '',
        value17: '',
        value18: '',
        value19: '',
        value20: '',
        value21: '',
        value22: '',
        value23: '',
        value24: '',
        value25: '',
        value26: '',
        value27: '',
        value28: '',
        value29: '',
        value30: ''
      }
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  created() {
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth() + 1;
  },
  mounted() {
    setTimeout(() => {
      this.getChartOne();
      this.getChartTwo();
      this.getChartThree();
      this.getChartFour();
      this.getChartFive();
    }, 200);
    this.getIndexData();
  },
  methods: {
    formatWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    openDiscount(title,code) {
      this.$refs.discount.show = true;
      this.$nextTick(() => {
        this.$refs.discount.title = title;
        this.$refs.discount.getChartDiscount(code);
      })
    },
    openTableData(title,code,tableName) {
      this.$refs.tableData.show = true;
      this.$nextTick(() => {
        this.$refs.tableData.title = title;
        this.$refs.tableData.getTable(code,tableName);
      })
    },
    openColumnar() {
      this.$refs.columnar.show = true;
      this.$nextTick(() => {
        this.$refs.columnar.getChartColumnar();
      })
    },
    getIndexData() {
      // 在建境内项目、机构、工厂总数量
      appIndex({
        indexCode: 'ZB0001_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value1 = res.data.data.records[0]._value;
        }
      })

      // 在建境内机构总人数量
      appIndex({
        indexCode: 'ZB0002_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value2 = res.data.data.records[0]._value;
        }
      })

      // 专职安全环保人员
      appIndex({
        indexCode: 'ZB0003_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value3 = res.data.data.records[0]._value;
        }
      })

      // 项目、工厂安全生产工时
      appIndex({
        indexCode: 'ZB0004_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value4 = res.data.data.records[0]._value;
        }
      })

      // 安全环保投入总额
      appIndex({
        indexCode: 'ZB0005_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value5 = res.data.data.records[0]._value;
        }
      })

      // 安全投入金额
      appIndex({
        indexCode: 'ZB0006_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value6 = res.data.data.records[0]._value;
        }
      })

      // 环保投入金额
      appIndex({
        indexCode: 'ZB0007_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value7 = res.data.data.records[0]._value;
        }
      })

      // 境外在建项目、运营机构数量
      let oneData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0010_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      // 境外风险地区项目数量
      let twoData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0011_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      Promise.all([oneData, twoData]).then((res) => {
        this.dataForm.value12 = res[0];
        this.dataForm.value13 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value14 = 0;
          return;
        }
        this.dataForm.value14 = Math.round(Number(res[1]) / Number(res[0]) * 100);
      })

      // 境外工厂数量
      let threeData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0012_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      // 境外风险工厂数量
      let fourData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0013_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      Promise.all([threeData, fourData]).then((res) => {
        this.dataForm.value15 = res[0];
        this.dataForm.value16 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value17 = 0;
          return;
        }
        this.dataForm.value17 = Math.round(Number(res[1]) / Number(res[0]) * 100);
      })

      // 境外人员数量
      let fiveData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0014_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      // 境外风险人员数量
      let sixData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0015_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      Promise.all([fiveData, sixData]).then((res) => {
        this.dataForm.value18 = res[0];
        this.dataForm.value19 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value20 = 0;
          return;
        }
        this.dataForm.value20 = Math.round(Number(res[1]) / Number(res[0]) * 100);
      })

      // 境内在建项目、运营机构数量
      let sevenData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0016_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      // 境内风险地区项目数量
      let eightData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0017_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      Promise.all([sevenData, eightData]).then((res) => {
        this.dataForm.value21 = res[0];
        this.dataForm.value22 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value23 = 0;
          return;
        }
        this.dataForm.value23 = Math.round(Number(res[1]) / Number(res[0]) * 100);
      })

      // 境内工厂数量
      let nineData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0018_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      // 境内风险工厂数量
      let tenData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0019_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      Promise.all([nineData, tenData]).then((res) => {
        this.dataForm.value24 = res[0];
        this.dataForm.value25 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value26 = 0;
          return;
        }
        this.dataForm.value26 = Math.round(Number(res[1]) / Number(res[0]) * 100);
      })

      // 境内人员数量
      let elevenData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0020_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      // 境内风险人员数量
      let twelveData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0021_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      Promise.all([elevenData, twelveData]).then((res) => {
        this.dataForm.value27 = res[0];
        this.dataForm.value28 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value29 = 0;
          return;
        }
        this.dataForm.value29 = Math.round(Number(res[1]) / Number(res[0]) * 100);
      })
    },
    getChartOne() {
      // 建设项目数
      let oneData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0008_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });
      // 运维项目数
      let twoData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: 'ZB0009_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      Promise.all([oneData, twoData]).then((res) => {
        let allNumber = Number(res[0]) + Number(res[1]);
        this.dataForm.value8 = res[0];
        this.dataForm.value9 = res[1];
        if (allNumber == 0) {
          this.dataForm.value10 = 0;
          this.dataForm.value11 = 0;
        } else {
          this.dataForm.value10 = (Number(res[0]) / allNumber * 100).toFixed(2);
          this.dataForm.value11 = (Number(res[1]) / allNumber * 100).toFixed(2);
        }

        var myChart = this.$echarts.init(
          document.getElementById("chart-one")
        );
        var colorList = [{
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: '#FF8181' // 0% 处的颜色
          },
            {
              offset: 1,
              color: '#FB3636' // 100% 处的颜色
            }
          ],
        }, {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: '#FFAB2D' // 0% 处的颜色
          },
            {
              offset: 1,
              color: '#FFCD82' // 100% 处的颜色
            }
          ],
        }]
        var option = {
          title: {
            text: "总项目数",
            top: "35%",
            textAlign: "center",
            left: "47%",
            textStyle: {
              color: "#484A4D",
              fontSize: 16,
              fontWeight: 400
            },
            subtext: Number(res[0]) + Number(res[1]) + '个',
            subtextStyle: {
              color: "#484A4D",
              fontSize: 26,
              fontWeight: 700
            },
            itemGap: 10, //主副标题间距
          },
          tooltip: {
            formatter: (params) => {
              if (params.name != "") {
                return params.name + " : " + params.value + "\n" + "(个)";
              }
            },
          },
          series: [
            {
              type: "pie",
              radius: ["63%", "90%"],
              avoidLabelOverlap: false,
              padAngle: 2,
              itemStyle: {
                borderRadius: 4,
                borderColor: "#fff",
                borderWidth: 2,
                normal: {
                  color: function (params) {
                    return colorList[params.dataIndex]
                  }
                }
              },
              color: ["#FB3636", "#FFAB2D"],
              label: {
                show: false,
                position: "center",
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: res[0],
                  name: "建设项目",
                },
                {
                  value: res[1],
                  name: "运维项目",
                },
              ],
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })
    },
    getChartTwo() {
      // 境外国家项目数TOP5及人数统计
      appIndex({
        indexCode: 'ZB0024_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        let orgName = [];
        let value1 = [];
        let value2 = [];
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          let data = res.data.data.records;
          data.forEach((item) => {
            orgName.push(item.org_name);
            value1.push(item._value1);
            value2.push(item._value2);
          })
        }
        var myChart = this.$echarts.init(
          document.getElementById("chart-two")
        );
        var option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['项目数', '人数'],
            right: "15%",
          },
          grid: { // 让图表占满容器
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "25px"
          },
          xAxis: [
            {
              type: 'category',
              data: orgName,
              axisLine: {
                lineStyle: {
                  color: ['#E8E8E8']
                }
              },
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '单位：个',
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed'
                }
              },
              axisLabel: {
                formatter: '{value}'
              }
            },
            {
              type: 'value',
              name: '单位：人',
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed'
                }
              },
              axisLabel: {
                formatter: '{value}'
              }
            }
          ],
          series: [
            {
              name: '项目数',
              type: 'bar',
              barWidth: 25,
              color: '#FB3636',
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 个';
                }
              },
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [{
                        offset: 0,
                        color: '#FB3636' // 0% 处的颜色
                      },
                        {
                          offset: 1,
                          color: '#FF8181' // 100% 处的颜色
                        }
                      ],
                    }
                  }
                }
              },
              data: value1
            },
            {
              name: '人数',
              type: 'line',
              color: '#FFAB2D',
              symbol: 'none',
              smooth: true,
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 人';
                }
              },
              data: value2
            }
          ]
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })
    },
    getChartThree() {
      // 境内省区项目数TOP5及人数统计
      appIndex({
        indexCode: 'ZB0026_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        let orgName = [];
        let value1 = [];
        let value2 = [];
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          let data = res.data.data.records;
          data.forEach((item) => {
            orgName.push(item.org_name);
            value1.push(item._value1);
            value2.push(item._value2);
          })
        }

        var myChart = this.$echarts.init(
          document.getElementById("chart-three")
        );
        var option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['项目数', '人数'],
            right: "15%",
          },
          grid: { // 让图表占满容器
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "25px"
          },
          xAxis: [
            {
              type: 'category',
              data: orgName,
              axisLine: {
                lineStyle: {
                  color: ['#E8E8E8']
                }
              },
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '单位：个',
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed'
                }
              },
              axisLabel: {
                formatter: '{value}'
              }
            },
            {
              type: 'value',
              name: '单位：人',
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed'
                }
              },
              axisLabel: {
                formatter: '{value}'
              }
            }
          ],
          series: [
            {
              name: '项目数',
              type: 'bar',
              color: '#FB3636',
              barWidth: 25,
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [{
                        offset: 0,
                        color: '#FB3636' // 0% 处的颜色
                      },
                        {
                          offset: 1,
                          color: '#FF8181' // 100% 处的颜色
                        }
                      ],
                    }
                  }
                }
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 个';
                }
              },
              data: value1
            },
            {
              name: '人数',
              type: 'line',
              color: '#FFAB2D',
              smooth: true,
              symbol: 'none',
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 人';
                }
              },
              data: value2
            }
          ]
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });

      })
    },
    getChartFour() {
      // 下属组织在建项目、运营机构数量TOP5
      appIndexChild({
        indexCode: 'ZB0028_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id,
        // limit: 5
      }).then((res) => {
        let orgName = [];
        let value1 = [];
        let value2 = [];
        if (res.data.code == 200 && res.data.data.length != 0) {
          let data = res.data.data;
          data.forEach((item) => {
            orgName.push(item.org_name);
            value1.push(item._value1?item._value1:0);
            value2.push(item._value2?item._value2:0);
          })
        }
        value1.reverse()
        value2.reverse()
        orgName.reverse()
        orgName = orgName.map(item=>{
          return {
            value: item,
            textStyle:{
              width: 100,
              overflow: 'break'
            }
          }
        })
        var myChart = this.$echarts.init(
          document.getElementById("chart-four")
        );
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
          },
          legend: {
            right: "0%",
          },
          grid: { // 让图表占满容器
            top: "40px",
            left: "110px",
            right: "40px",
            bottom: "25px"
          },
          xAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
          },
          yAxis: {
            type: 'category',
            name: '单位：个',
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLine: {
              lineStyle: {
                color: ['#E8E8E8']
              }
            },
            data: orgName
          },
          series: [
            {
              name: '境外项目机构数',
              type: 'bar',
              stack: 'total',
              color: '#FB3636',
              barWidth: 25,
              emphasis: {
                focus: 'series'
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 个';
                }
              },
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: 'linear',
                      x: 1,
                      y: 0,
                      x2: 0,
                      y2: 0,
                      colorStops: [{
                        offset: 0,
                        color: '#FB3636' // 0% 处的颜色
                      },
                        {
                          offset: 1,
                          color: '#FF8181' // 100% 处的颜色
                        }
                      ],
                    }
                  }
                }
              },
              data: value1
            },
            {
              name: '境内项目机构数',
              type: 'bar',
              stack: 'total',
              color: '#FFAB2D',
              barWidth: 25,
              emphasis: {
                focus: 'series'
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 个';
                }
              },
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: 'linear',
                      x: 1,
                      y: 0,
                      x2: 0,
                      y2: 0,
                      colorStops: [{
                        offset: 0,
                        color: '#FFAB2D' // 0% 处的颜色
                      },
                        {
                          offset: 1,
                          color: '#FFCD82' // 100% 处的颜色
                        }
                      ],
                    }
                  }
                }
              },
              data: value2
            }
          ]
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });

      })
    },
    getChartFive() {
      // 下属组织在建项目、运营机构人员分布TO5
      appIndexChild({
        indexCode: 'ZB0030_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id,
        // limit: 5
      }).then((res) => {
        let orgName = [];
        let value1 = [];
        let value2 = [];
        if (res.data.code == 200 && res.data.data.length != 0) {
          let data = res.data.data;
          data.forEach((item) => {
            orgName.push(item.org_name);
            value1.push(item._value1?item._value1:0);
            value2.push(item._value2?item._value2:0);
          })
        }
        value1.reverse()
        value2.reverse()
        orgName.reverse()
        orgName = orgName.map(item=>{
          return {
            value: item,
            textStyle:{
              width: 100,
              overflow: 'break'
            }
          }
        })
        var myChart = this.$echarts.init(
          document.getElementById("chart-five")
        );
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
          },
          legend: {
            right: "0%",
          },
          grid: { // 让图表占满容器
            top: "40px",
            left: "110px",
            right: "40px",
            bottom: "25px"
          },
          xAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
          },
          yAxis: {
            type: 'category',
            name: '单位：人',
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLine: {
              lineStyle: {
                color: ['#E8E8E8']
              }
            },
            data: orgName
          },
          series: [
            {
              name: '境外人员数',
              type: 'bar',
              stack: 'total',
              color: '#FB3636',
              barWidth: 25,
              emphasis: {
                focus: 'series'
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 人';
                }
              },
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: 'linear',
                      x: 1,
                      y: 0,
                      x2: 0,
                      y2: 0,
                      colorStops: [{
                        offset: 0,
                        color: '#FB3636' // 0% 处的颜色
                      },
                        {
                          offset: 1,
                          color: '#FF8181' // 100% 处的颜色
                        }
                      ],
                    }
                  }
                }
              },
              data: value2
            },
            {
              name: '境内人员数',
              type: 'bar',
              stack: 'total',
              color: '#FFAB2D',
              barWidth: 25,
              emphasis: {
                focus: 'series'
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 人';
                }
              },
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: 'linear',
                      x: 1,
                      y: 0,
                      x2: 0,
                      y2: 0,
                      colorStops: [{
                        offset: 0,
                        color: '#FFAB2D' // 0% 处的颜色
                      },
                        {
                          offset: 1,
                          color: '#FFCD82' // 100% 处的颜色
                        }
                      ],
                    }
                  }
                }
              },
              data: value1
            }
          ]
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });

      })
    }
  }
}
</script>
<style lang="scss" scoped>
.disFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.card-pd-4-26 {
  width: calc(25% - 64px);
  padding: 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-4-26-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-4-26-topLeft {
  line-height: 61px;
  height: 61px;
  color: #15161A;
  font-size: 42px;
  font-weight: bold;
}

.card-pd-4-26-topLeft span {
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
}

.card-pd-4-26-top img {
  width: 61px;
  height: 61px;
  flex-shrink: 0;
}

.card-pd-4-26-bottom {
  width: 100%;
  margin-top: 48px;
  color: #15161A;
  font-size: 18px;
}

.card-pd-2 {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-2-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-2-top-Title {
  color: #15161A;
  font-size: 21px;
  font-weight: bold;
}

.card-pd-2-top-btn {
  display: flex;
  justify-content: flex-end;
}

.card-pd-2-top-btn img {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}

.card-pd-2-Bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.card-pd-2-BotLitte {
  width: calc(33.3% - 64px);
  border: 1px solid #EDEDED;
  border-radius: 10px;
  padding: 40px 26px;
}

.card-pd-2-BotLitte2 {
  width: calc(33.3% - 52px);
  border-radius: 8px;
  padding: 21px;
  background-color: #F5F7FA;
}

.botLitte2-number {
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
  margin-top: 21px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}

.botLitte2-number span {
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
}

.botLitte2-title {
  width: 100%;
  color: #484A4D;
  font-size: 21px;
}

.botLitte2-info {
  color: #15161A;
  font-size: 18px;
  margin-top: 13px;
}

.botLitte2-info span {
  color: #FB3636;
}

.priceNumber {
  width: 100%;
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
}

.priceNumber span {
  font-weight: 400;
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
}

.lineBox {
  width: 40px;
  height: 3px;
  background-color: #FB3636;
  margin: 21px 0 26px;
}

.priceContent {
  width: 100%;
  color: #15161A;
  font-size: 18px;
}

#chart-one {
  width: 209px;
  height: 209px;
  margin-left: 93px;
}

.chart-one-info {
  padding: 73px 0;
  margin-left: 58px;
}

.chart-one-info-box {
  color: #1D2129;
  font-size: 16px;
  display: flex;
}

.info-circle {
  width: 10px;
  height: 10px;
  background: linear-gradient(26deg, #FF8181 16.57%, #FB3636 83.6%);
  border-radius: 50px;
  margin: 6px 6px 6px 0;
}

#chart-two {
  width: 100%;
  height: 320px;
}

#chart-three {
  width: 100%;
  height: 320px;
}

#chart-four {
  width: 100%;
  height: 320px;
}

#chart-five {
  width: 100%;
  height: 320px;
}

::v-deep .el-slider__runway.disabled .el-slider__bar {
  background-color: #FB3636;
}

::v-deep .el-slider__runway.disabled .el-slider__button {
  border-color: #FB3636;
}
</style>
