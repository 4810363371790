var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: true,
              showCheckbox: false,
              "popover-show": false,
              expandOnClickNode: false,
              treeTitle: "培训类型",
            },
            on: {
              "include-down": _vm.includeDown,
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.treeNodeClick,
            },
          }),
          _c(
            "el-main",
            { staticStyle: { position: "relative" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": "平台课程",
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.headAdd,
                  "head-loseEfficacy": _vm.headLoseEfficacy,
                  "head-startUsing": _vm.headStartUsing,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c(
                "div",
                { staticClass: "tableShowRadio" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.tableRadio,
                        callback: function ($$v) {
                          _vm.tableRadio = $$v
                        },
                        expression: "tableRadio",
                      },
                    },
                    [_vm._v("卡片模式")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.tableRadio,
                        callback: function ($$v) {
                          _vm.tableRadio = $$v
                        },
                        expression: "tableRadio",
                      },
                    },
                    [_vm._v("列表模式")]
                  ),
                ],
                1
              ),
              _vm.tableRadio == "2"
                ? _c("card-layout", {
                    ref: "cardLayout",
                    attrs: {
                      "card-loading": _vm.crudLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                      "card-data": _vm.tableData,
                      cardProps: _vm.cardProps,
                    },
                    on: {
                      "card-view": _vm.rowView,
                      "page-size-change": _vm.onLoad,
                      "page-current-change": _vm.onLoad,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    width: "100%",
                                  },
                                  style:
                                    scope.row.status == 2
                                      ? "justify-content: space-between;"
                                      : "justify-content:flex-end",
                                },
                                [
                                  scope.row.status == 2
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowEdit(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              编辑\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-text": "启用",
                                      "inactive-text": "失效",
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ff4949",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.isEnable(scope.row)
                                      },
                                    },
                                    model: {
                                      value: scope.row.status == 1,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "status == 1", $$v)
                                      },
                                      expression: "scope.row.status == 1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1216694649
                    ),
                  })
                : _vm._e(),
              _vm.tableRadio == "1"
                ? _c("grid-layout", {
                    ref: "gridLayOut",
                    attrs: {
                      "grid-row-btn": _vm.gridRowBtn,
                      "table-options": _vm.tableOptions,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.crudLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                    },
                    on: {
                      "grid-row-detail-click": _vm.rowView,
                      "page-current-change": _vm.onLoad,
                      "page-size-change": _vm.onLoad,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                      "row-remove": _vm.rowRemove,
                      "row-edit": _vm.rowEdit,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "courseCover",
                          fn: function ({ row }) {
                            return [
                              row.courseCover == ""
                                ? _c("img", {
                                    staticClass: "courseCoverImg",
                                    attrs: {
                                      src: require("@/assets/training/trainingDefaultPicture.png"),
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "courseCoverImg",
                                    attrs: { src: row.courseCover },
                                  }),
                            ]
                          },
                        },
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              row.status == 2
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowEdit(row)
                                        },
                                      },
                                    },
                                    [_vm._v("\n            编辑\n          ")]
                                  )
                                : _vm._e(),
                              _c("el-switch", {
                                attrs: {
                                  "active-text": "启用",
                                  "inactive-text": "失效",
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.isEnable(row)
                                  },
                                },
                                model: {
                                  value: row.status == 1,
                                  callback: function ($$v) {
                                    _vm.$set(row, "status == 1", $$v)
                                  },
                                  expression: "row.status == 1",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1056441828
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showTreeDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                showFullscreen: true,
                width: "60%",
                dialogTitle: _vm.treeTitle + "内容场景",
              },
              on: {
                cancel: function ($event) {
                  _vm.showTreeDialog = false
                },
                confirm: _vm.handleTreeNodeSave,
              },
            },
            [
              _c("avue-form", {
                ref: "addForm",
                attrs: { option: _vm.treeDialogOption },
                on: { submit: _vm.treeNodeSave },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }