<template>
  <div class="main-content">
    <div class="top">
      <div class="echarts-box" ref="pie-echart">
        <div class="title">风险等级分布</div>
        <div style="width: 100%; height: 100%">
          <div
            ref="pieChart"
            class="echart"
            id="pieChart"
            style="width: 100%; height: 100%"
            v-if="echartsData.length"
          ></div>

          <el-empty
            description="暂无数据"
            style="width: 100%; height: 100%"
            :image-size="120"
            v-else
          ></el-empty>
        </div>
      </div>
      <div class="canvas-box" ref="">
        <div class="title-box">
          <div class="title">项目风险区域分布</div>
          <div class="right-btn">
            <el-button v-if="mapUrl" @click="linkTool('edit')">编辑</el-button>
            <el-button v-if="mapUrl" @click="linkTool('export')"
              >导出</el-button
            >
            <el-button v-else @click="linkTool('add')">去上传</el-button>
          </div>
        </div>

        <el-image
          class="image-box"
          :src="mapUrl"
          fit="fill"
          style="max-width: 300px; max-height: 300px"
          v-if="mapUrl"
        ></el-image>
        <el-empty
          description="暂无数据"
          style="width: 100%; height: 100%"
          :image-size="120"
          v-else
        ></el-empty>
      </div>
    </div>
    <div class="bottom">
      <el-container>
        <CommonTree
          treeTitle="风险源"
          :defaultProps="defaultProps"
          :isShowdig="true"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          :treeData="treeData"
          :treeExpand="true"
          :risk="true"
          style="font-size: 13px"
          @getNodeClick="handleNodeClick"
          @getTreeAdd="getTreeAdd"
          @getTreeDelete="getTreeDelete"
          @getTreeEdit="getTreeEdit"
        />
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="风险库"
            @head-add="opDialog()"
            @head-delete="deletesList"
            @head-export="handleExport"
            @head-import="handleImport"
            @head-introduce="headIntroduce"
          >
          </head-layout>
          <grid-head-layout
            ref="searchForm"
            v-model="searchForm"
            :searchSpan="'4'"
            :search-columns="searchColumns"
            @grid-head-search="searchChange"
            @grid-head-empty="searchReset"
          />
          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal"
            :gridRowBtn="gridRowBtn"
            :page="page"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
            @grid-edit="rowEdit"
            @grid-romve="rowDel"
            :search-columns="searchColumns"
          >
            <template slot="riskLevel" slot-scope="{ row }">
              <div
                v-if="row.lecdD"
                style="
                  display: flex;
                  justify-content: center;
                  align-item: center;
                  padding: 4px;
                "
              >
                <div
                  :style="{
                    border: `1px solid ${row.lecdColor}`,
                    borderRadius: '4px',
                    color: row.lecdColor,
                    backgroundColor: computedDlevel(row.lecdD).bg,
                    textAlign: 'center',
                    display: 'inline-block',
                    padding: '0 20px',
                  }"
                >
                  {{ row.lecdD }}
                </div>
              </div>
            </template>
            <template slot="lecdDScore" slot-scope="{ row }">
              <div>{{ row.lecdDScore == -1 ? "" : row.lecdDScore }}</div>
            </template>
            <template slot="riskCode" slot-scope="{ row }">
              <el-link type="primary" @click="linkDetail(row)">{{
                row.riskCode
              }}</el-link>
            </template>
            <template slot="libraryStatus" slot-scope="{ row }">
              {{ row.libraryStatus == "FINISHED" ? "完成" : "草稿" }}
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </div>

    <el-dialog
      v-dialog-drag
      title="风险源配置"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showTreeDialog"
      v-if="showTreeDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <avue-form
        :option="treeDialogOption"
        ref="addForm"
        v-model="treeForm"
        @submit="treeNodeSave"
      ></avue-form>
      <span slot="footer">
        <el-button
          size="small"
          @click="showTreeDialog = false"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="风险引用"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="introduceShow"
      v-if="introduceShow"
      width="80%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <el-container style="height: 500px">
        <CommonTree
          treeTitle="风险源分类"
          :defaultProps="defaultProps"
          :isShowdig="false"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          :treeData="introduceTree"
          :treeExpand="true"
          style="font-size: 13px"
          :risk="true"
          @getNodeClick="handleNodeIntroduce"
        />
        <el-main>
          <div class="company" style="padding: 0 10px">
            <el-select
              v-model="companyName"
              clearable
              ref="selectTree"
              @clear="clearHandle"
              placeholder="请选择公司"
            >
              <el-option :value="companyId" :label="companyName">
                <el-tree
                  id="tree-option"
                  :data="companySelection"
                  :highlight-current="true"
                  :props="companydefaultProps"
                  :accordion="true"
                  node-key="id"
                  :default-expanded-keys="defaultExpandedKey"
                  @node-click="handleNodeClickListTree"
                >
                </el-tree>
              </el-option>
            </el-select>
          </div>
          <grid-head-layout
            ref="searchForm"
            v-model="searchForm"
            :search-columns="introduceSearchColumns"
            @grid-head-search="introduceSearch"
            @grid-head-empty="introduceReset"
          />
          <grid-layout
            ref="gridLayout"
            :data-total="introduceTableTotal"
            :gridRowBtn="introduceGridRowBtn"
            :page="introducePage"
            :tableData="introduceTableData"
            :tableLoading="introduceTableLoading"
            :tableOptions="introduceTableOptions"
            @page-size-change="handleSizeIntroduce"
            @page-current-change="handleCurrentIntroduce"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="introduceSelectionChange"
            @grid-select="introduceSelect"
            :search-columns="introduceSearchColumns"
          >
            <template slot="riskCode" slot-scope="{ row }">
              <el-link type="primary" @click="linkDetail(row)">{{
                row.riskCode
              }}</el-link>
            </template>

            <template slot="lecdDScore" slot-scope="{ row }">
              <div>{{ row.lecdDScore == -1 ? "" : row.lecdDScore }}</div>
            </template>
            <template slot="riskLevel" slot-scope="{ row }">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-item: center;
                  padding: 4px;
                "
              >
                <div
                  :style="{
                    border: `1px solid ${row.lecdColor}`,
                    borderRadius: '4px',
                    color: row.lecdColor,
                    backgroundColor: computedDlevel(row.lecdD).bg,
                    textAlign: 'center',
                    display: 'inline-block',
                    padding: '0 4px',
                  }"
                >
                  {{ row.lecdD }}
                </div>
              </div>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
      <span slot="footer">
        <el-button
          size="small"
          @click="introduceShow = false"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleIntroduceSave">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="检查项导入"
      v-if="excelBox"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          v-model="excelOption.excelForm"
          :upload-after="uploadAfter"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{ $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTree,
  addTree,
  deleteTree,
  getPage,
  getTreeDetail,
  getTreesDetail,
  addTreeList,
  deleteTrees,
  deletePage,
  getChartPie,
  exportList,
  getRiskSourceTypeTree,
  getParentDet,
  improtList,
  getDetTree,
  getPmriskmap,
} from "@/api/riskManage/project";
// import {
//   getTree as getTreeManage,
//   getPage as getPageManage,
// } from "@/api/riskManage/manage";
import { getbsrisklecddlevelMap } from "@/api/setting/riskAssessment/index";

import { mapGetters } from "vuex";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";
import * as echarts from "echarts";
import website from "@/config/website";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  data() {
    return {
      excelBox: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        excelForm: {},
        column: [
          {
            label: "数据导入",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            action: `/api/sinoma-hse-prj/pmrisklibrary/import`,
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
      companySelection: [],
      companyId: "",
      companyName: "",
      introduceTableData: [],
      introduceQuery: {},
      introduceArr: [],
      introduceTree: [],
      introduceShow: false,
      introduceTableTotal: 0,
      introduceTableLoading: false,
      introduceGridRowBtn: [
        {
          label: "选择",
          emit: "grid-select",
          remark: "select",
          type: "text",
          icon: "",
        },
      ],
      introduceTableOptions: {
        customRowAddBtn: false,
        menuWidth: 100,
        menuFixed: "right",
        showTooltip: true,
        indexLabel: "序号",
        index: true,
        column: [
          {
            label: "风险编号",
            prop: "riskCode",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
          {
            label: "风险源",
            prop: "sourceName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "事故类型",
            prop: "event",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "风险等级",
            prop: "riskLevel",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
        ],
      },
      introduceSearchColumns: [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险编号",
          prop: "riskCode",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "事故类型",
          prop: "event",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "风险等级",
          type: "select",
          prop: "riskLevel",
          span: 4,
          dicData: [],
          props: {
            label: "levelName",
            value: "levelName",
          },
          dataType: "number",
        },
      ],
      introducePage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      myChartStyle: {
        float: "left",
        width: "100%",
        height: "500px",
        margin: "5px",
      }, // 图表样式
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      treeForm: {},
      searchColumns: [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险编号",
          prop: "riskCode",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险描述",
          prop: "riskName",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "风险源",
          prop: "sourceName",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "风险等级",
          type: "select",
          prop: "riskLevel",
          span: 4,
          dicData: [],
          props: {
            label: "levelName",
            value: "levelName",
          },
          dataType: "number",
        },
        {
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "状态",
          type: "select",
          prop: "libraryStatus",
          span: 4,
          dicData: [
            { label: "完成", value: "FINISHED" },
            { label: "草稿", value: "PREPARE" },
          ],
          props: {
            label: "label",
            value: "value",
          },
        },
      ],
      treeDialogOption: {
        size: "small",
        menuFixed: "right",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "风险源类型",
            prop: "risktype",
            type: "radio",
            props: {
              label: "label",
              value: "value",
            },
            dicData: [
              { label: "风险源分类", value: 1 },
              { label: "风险源", value: 2 },
            ],
            rules: [
              {
                required: true,
                message: "请选择风险源分类",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上级分类",
            prop: "parentId",
            type: "tree",
            dicData: [],
            value: -1,
            props: {
              label: "sourceTypeName",
              value: "id",
            },
            change: (column, id) => {
              this.getNodeId(column, id);
            },
            rules: [
              {
                required: true,
                message: "请选择上级分类",
                trigger: "change",
              },
            ],
          },
          {
            label: "名称",
            prop: "sourceTypeName",
            type: "input",
            maxlength: 64,
            overHidden: true,
            span: 24,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      searchTitle: "sourceTypeName",
      tableOptions: {
        menuFixed: "right",
        customRowAddBtn: false,
        menuWidth: 100,
        showTooltip: true,
        indexLabel: "序号",
        index: true,
        selectable: (row) => {
          return row.libraryStatus == "PREPARE";
        },
        column: [
          {
            label: "风险编号",
            prop: "riskCode",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
          {
            label: "风险描述",
            prop: "riskName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "风险源",
            prop: "sourceName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "事故类型",
            prop: "event",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          // {
          //   label: "可能后果",
          //   prop: "lecdC",
          //   align: "center",
          //   overHidden: true,
          //   minWidth: 100,
          // },
          {
            label: "L",
            prop: "lecdLScore",
            align: "center",
            overHidden: true,
          },
          {
            label: "E",
            prop: "lecdEScore",
            align: "center",
            overHidden: true,
          },
          {
            label: "C",
            prop: "lecdCScore",
            align: "center",
            overHidden: true,
          },
          {
            slot: true,
            label: "D",
            prop: "lecdDScore",
            align: "center",
            overHidden: true,
          },
          {
            label: "风险等级",
            prop: "riskLevel",
            align: "center",
            overHidden: true,
            minWidth: 100,
            slot: true,
          },
          {
            label: "状态",
            prop: "libraryStatus",
            align: "center",
            overHidden: true,
            slot: true,
          },
        ],
      },
      showTreeDialog: false,
      tableID: "",
      tableData: [],
      treeData: [],
      defaultProps: {
        children: "children",
        id: "id",
        label: "sourceTypeName",
      },
      companydefaultProps: {
        children: "children",
        id: "id",
        label: "title",
      },
      tableTotal: 0,
      tableLoading: false,
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-edit",
          remark: "edit",
          type: "text",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          remark: "remove",
          type: "text",
          icon: "",
        },
      ],
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
        },
        {
          label: "导入",
          emit: "head-import",
          type: "button",
          icon: "",
        },
        {
          label: "导出",
          emit: "head-export",
          type: "button",
          icon: "",
        },
        {
          label: "引入",
          emit: "head-introduce",
          type: "button",
          icon: "",
        },
        {
          label: "删除",
          emit: "head-delete",
          type: "button",
          icon: "",
        },
      ],
      treeArrData: [],
      isSource: false,
      option: {},
      nowTreeNode: {},
      ids: "",
      D: [],
      query: {},
      echartsData: [],
      mapUrl: "",
      maps: {},
    };
  },
  mounted() {
    this.getPage();
    this.getdemotree();
    this.getChartPie();
    this.getbsrisklecddlevelMap();
    this.getParentDet();
    this.getDetTree();
    this.getPmriskmap();
    this.excelOption.column[0].action = `/api/sinoma-hse-prj/pmrisklibrary/import?organizationId=${this.userInfo.dept_id}`;
  },

  methods: {
    //导入
    handleImport() {
      this.excelBox = true;

      this.excelOption.excelForm.excelFile = [];
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.getdemotree();
      this.getPage();
      this.excelOption.excelForm.excelFile = [];
      done();
    },
    handleTemplate() {
      exportBlob(`/api/sinoma-hse-prj/pmrisklibrary/exportTemplate`).then(
        (res) => {
          downloadXls(res.data, "风险库数据模板" + ".xls");
        }
      );
    },
    // 导出
    handleExport() {
      if (!this.nowNodeId) {
        this.$message({
          message: "请选择风险源分类或者风险源",
          type: "warning",
        });
        return;
      }
      this.$confirm("是否导出检查项数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          organizationId: this.userInfo.dept_id,
          ...this.query,
        };
        if (this.isSource) {
          params.sourceId = this.nowNodeId;
        } else {
          params.sourceTypeId = this.nowNodeId;
        }
        let paramsStr = "";
        for (let key in params) {
          paramsStr += `${key}=${params[key]}&`;
        }
        if (paramsStr.length) {
          paramsStr = paramsStr.slice(0, -1);
        }
        exportBlob(
          `/api/sinoma-hse-prj/pmrisklibrary/export?${paramsStr}`
        ).then((res) => {
          downloadXls(
            res.data,
            `${this.nowTreeNode.sourceTypeName}-风险库数据${dateNow()}.xls`
          );
        });
      });
    },
    linkTool(type) {
      if (type == "export") {
        const src = this.mapUrl;
        const fileName = this.maps.mapImgName;
        const canvas = document.createElement("canvas");
        const img = document.createElement("img");
        // 解决跨域 Canvas 污染问题
        img.setAttribute("crossOrigin", "Anonymous");
        //将资源链接赋值过去，才能触发img.onload事件
        img.src = src;
        img.onload = function (e) {
          canvas.width = img.width;
          canvas.height = img.height;
          const context = canvas.getContext("2d");
          //绘制图片
          context.drawImage(img, 0, 0, img.width, img.height);
          canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height);
          //将canvas转base64码，然后创建一个a连接自动下载图片
          canvas.toBlob((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
          });
        };
        return;
      }
      let url = `${process.env.VUE_APP_BASE_HMIURL}?organizationId=${this.userInfo.dept_id}`;
      if (type == "add") {
        url = `${process.env.VUE_APP_BASE_HMIURL}?organizationId=${this.userInfo.dept_id}`;
      } else {
        url = `${process.env.VUE_APP_BASE_HMIURL}?id=${this.maps.id}&organizationId=${this.userInfo.dept_id}`;
      }

      window.open(url);
    },
    getPmriskmap() {
      getPmriskmap({ organizationId: this.userInfo.dept_id }).then((res) => {
        this.mapUrl = res.data.data.mapUrl || "";
        this.maps = res.data.data;
      });
    },
    getChartPie() {
      getChartPie({ organizationId: this.userInfo.dept_id }).then((res) => {
        let tempData = res.data.data;
        let dataArr = [];
        tempData.map((item) => {
          dataArr.push({
            ...item,
            itemStyle: { color: item.color || "rgba(252, 233, 119, 1)" },
          });
        });
        this.echartsData = dataArr;
        if (!this.echartsData.length) {
          return;
        }
        // todo echartsOption
        this.option = {
          tooltip: {
            trigger: "item",
          },
          label: {
            alignTo: "edge",
            formatter: "{name|{b}}\n{value|{c}}",
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 15,
            rich: {
              time: {
                fontSize: 10,
                color: "#999",
              },
            },
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 6,
            top: "middle",
            data: dataArr,
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              data: dataArr,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        this.initPieChart();
      });
    },
    linkDetail(row) {
      // if (row.organizationId) {
      //   this.$router.push({
      //     path: "/riskProject/riskProjectDetail",
      //     query: {
      //       type: "detail",
      //       nowRuleFormId: row.id,
      //     },
      //   });
      // }
      this.$router.push({
        path: "/riskProject/riskProjectDetail",
        query: {
          type: "detail",
          nowRuleFormId: row.id,
        },
      });
    },
    initEcharts(id, option) {
      const myChart = echarts.init(document.getElementById(id));
      myChart.setOption(option);
      // 随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    initPieChart() {
      this.$nextTick(() => {
        this.initEcharts("pieChart", this.option);
      });
    },
    // 获取D映射
    getbsrisklecddlevelMap() {
      //  createDept: this.userInfo.dept_id
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
        this.searchColumns[3].dicData = res.data.data;
        this.introduceSearchColumns[2].dicData = res.data.data;
      });
    },
    computedDlevel(levelCode) {
      let result = this.D.find((item) => item.levelCode == levelCode);
      if (result) {
        result.bg =
          result.color.substring(0, 3) +
          result.color.substring(3, result.color.length - 2) +
          "0.1)";
      }

      return (
        result || {
          color: "rgba(252, 233, 119, 1)",
          bg: "rgba(252, 233, 119, 0.1)",
        }
      );
    },
    getNodeId(clo, id) {
      if (id) {
        const result = this.treeArrData.find((item) => item.id == id);
        this.isSource = result.isSource;
        this.nowTreeNode = result || {};
      } else if (clo && clo.value) {
        const result = this.treeArrData.find((item) => item.id == clo.value);
        this.isSource = result.isSource || false;
        this.nowTreeNode = result || {};
      }
    },
    //编辑
    rowEdit(e, index) {
      this.$router.push({
        path: "/riskProject/riskProjectEdit",
        query: {
          type: "edit",
          nowRuleFormId: e.id,
        },
      });
    },
    rowDel(row) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletePage({ ids: row.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getPage();
              this.getChartPie();
            }
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    //树
    getdemotree() {
      getTree({ organizationId: this.userInfo.dept_id }).then((res) => {
        if (res.status == 200) {
          this.treeData = res.data.data;
          this.treeArrData = this.flattenTree(res.data.data);
          // this.treeDialogOption.column[1].dicData = res.data.data;
        }
      });
    },
    getTreeDialogOption(id) {
      let params = { organizationId: this.userInfo.dept_id };
      if (id) {
        params.id = id;
      }
      getRiskSourceTypeTree(params).then((res) => {
        if (res.status == 200) {
          this.treeDialogOption.column[1].dicData = res.data.data;
        }
      });
    },

    //扁平化 树
    flattenTree(tree, parent = null) {
      let result = [];
      for (let node of tree) {
        let flatNode = { ...node, parent };
        result.push(flatNode);
        if (node.children && node.children.length) {
          result = result.concat(this.flattenTree(node.children, flatNode));
        }
        delete flatNode.children;
      }
      return result;
    },
    opDialog() {
      this.$router.push({
        path: "/riskProject/riskProjectAdd",
        query: {
          type: "add",
          nowRuleFormId: "",
          riskSource: this.isSource ? this.nowNodeId : "",
        },
      });
    },
    deletesList() {
      if (this.ids) {
        this.$confirm("确认删除当前选中数据吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            deletePage({ ids: this.ids }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getPage();
                this.getChartPie();
              }
            });
          })
          .catch((error) => {
            this.$message({
              message: "取消删除",
              type: "success",
            });
          });
      } else {
        this.$message({
          message: "请选择需要删除的数据",
          type: "warning",
        });
      }
    },

    //点击树节点显示列表
    handleNodeClick(data) {
      this.nowNodeId = data.id;
      this.getNodeId({}, this.nowNodeId);
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.userInfo.dept_id,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }
      // 调用列表接口;
      this.tableLoading = true;
      getPage(params)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    getPage() {
      this.tableLoading = true;
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.userInfo.dept_id,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }
      // 调用列表接口;
      getPage(params)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    handleNodeClickListTree(node, obj) {
      this.companyName = node["title"];
      this.companyId = node["id"];
      this.introduceTableData = [];
      this.introducePage = {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      };
      this.getIntroducetree(this.companyId);
      this.defaultExpandedKey = [];
      this.$refs.selectTree.blur();
    },
    clearHandle() {
      this.companyName = "";
      this.companyId = "";
      this.defaultExpandedKey = [];
      this.introduceTableData = [];
      this.introducePage = {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      };
      this.clearSelected();
    },
    clearSelected() {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
    // 获取上级公司id
    getParentDet() {
      getParentDet({ id: this.userInfo.dept_id }).then((res) => {
        console.log(res, "getParentDet");
        this.companyId = res.data.data.parentId || this.userInfo.dept_id;
        this.companyName = res.data.data.parentName || this.userInfo.dept_name;
      });
    },
    // 获取公司树
    getDetTree() {
      getDetTree({ categorys: "2" }).then((res) => {
        this.companySelection = res.data.data;
      });
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.getPage();
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.getPage();
    },
    handleSizeIntroduce(e) {
      this.introducePage.pageSize = e.pageSize;
      this.getIntroducePage(this.companyId);
    },

    handleCurrentIntroduce(e) {
      this.introducePage.currentPage = e.currentPage;
      this.getIntroducePage(this.companyId);
    },
    // 新增风险源
    getTreeAdd() {
      if (this.isSource) {
        this.$message({
          message: "当前选中的是风险源节点不可新增",
          type: "warning",
        });
        return;
      }
      this.treeForm = {};
      this.getTreeDialogOption();
      if (this.nowNodeId) {
        this.treeForm.parentId = this.nowNodeId;
        this.treeDialogOption.column[1].value = this.nowNodeId;
      }
      this.showTreeDialog = true;
    },
    // 删除
    getTreeDelete() {
      if (this.nowNodeId) {
        this.$confirm("确认删除当前选中节点吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (!this.isSource) {
              deleteTree({ ids: this.nowNodeId }).then((res) => {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                if (
                  this.nowTreeNode.children &&
                  this.nowTreeNode.children.length
                ) {
                  deleteTrees({ ids: this.nowNodeId }).then((res) => {});
                }
                this.getdemotree();
                this.getChartPie();
              });
            } else {
              deleteTrees({ ids: this.nowNodeId }).then((res) => {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getdemotree();
                this.getChartPie();
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message({
          message: "请选择一个节点",
          type: "warning",
        });
      }
    },
    getTreeEdit() {
      this.treeForm = {};
      this.getNodeId({}, this.nowNodeId);
      if (!this.nowNodeId) {
        this.$message({
          message: "请选中一个节点进行编辑",
          type: "warning",
        });
      }

      if (this.isSource) {
        this.getTreeDialogOption();
        getTreesDetail({ id: this.nowNodeId }).then((res) => {
          this.treeForm = res.data.data;
          this.treeForm.risktype = 2;
          this.treeForm.sourceTypeName = this.treeForm.sourceName;
          this.treeForm.parentId = this.treeForm.sourceTypeId;
          this.treeForm.sourceTypeCode = this.treeForm.sourceCode;
          this.showTreeDialog = true;
        });
      } else {
        this.getTreeDialogOption(this.nowNodeId);
        getTreeDetail({ id: this.nowNodeId }).then((res) => {
          this.treeForm = res.data.data;
          this.treeForm.risktype = 1;
          this.showTreeDialog = true;
        });
      }
    },
    selectionChange(arr) {
      this.ids = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },

    getIntroducetree(organizationId) {
      getTree({ organizationId: organizationId }).then((res) => {
        if (res.status == 200) {
          this.introduceTree = res.data.data;
        }
      });
    },
    // 点击树显示列表 getPageManage
    handleNodeIntroduce(data) {
      this.IntroduceNowNodeId = data.id;
      this.introduceNowTreeNode = data;
      let params = {
        size: this.introducePage.pageSize,
        current: this.introducePage.currentPage,
        organizationId: this.organizationId,
      };
      if (this.introduceNowTreeNode.isSource) {
        params.sourceId = this.IntroduceNowNodeId;
      } else {
        params.sourceTypeId = this.IntroduceNowNodeId;
      }
      // 调用列表接口;
      this.introduceTableLoading = true;
      getPage(params)
        .then((res) => {
          this.introduceTableData = res.data.data.records;
          this.introduceTableTotal = res.data.data.total;
          this.introduceTableLoading = false;
        })
        .catch((error) => {
          this.introduceTableLoading = false;
        });
    },
    introduceSelectionChange(arr) {
      this.introduceArr = arr;
      // .map((item) => {
      //   return item.id;
      // })
      // .join(",");
    },
    getIntroducePage(organizationId) {
      if (this.IntroduceNowNodeId) {
        return;
      }
      let params = {
        size: this.introducePage.pageSize,
        current: this.introducePage.currentPage,
        organizationId,
      };
      if (this.introduceNowTreeNode.isSource) {
        params.sourceId = this.IntroduceNowNodeId;
      } else {
        params.sourceTypeId = this.IntroduceNowNodeId;
      }
      this.introduceTableLoading = true;
      getPage(params)
        .then((res) => {
          this.introduceTableData = res.data.data.records;
          this.introduceTableTotal = res.data.data.total;
          this.introduceTableLoading = false;
        })
        .catch((error) => {
          this.introduceTableLoading = false;
        });
    },

    introduceSearch(query) {
      if (!this.IntroduceNowNodeId) {
        return;
      }
      this.introduceQuery = query;
      let params = {
        ...query,
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.userInfo.dept_id,
      };
      if (this.introduceNowTreeNode.isSource) {
        params.sourceId = this.IntroduceNowNodeId;
      } else {
        params.sourceTypeId = this.IntroduceNowNodeId;
      }
      this.introduceTableLoading = true;
      getPage(params)
        .then((res) => {
          this.introduceTableData = res.data.data.records;
          this.introduceTableTotal = res.data.data.total;
          this.introduceTableLoading = false;
        })
        .catch((error) => {
          this.introduceTableLoading = false;
        });
    },
    introduceReset() {
      this.introduceQuery = {};
    },
    searchReset() {
      this.query = {};
      this.getPage();
    },
    searchChange(query) {
      this.query = query;
      let params = {
        ...query,
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.userInfo.dept_id,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }
      // 调用列表接口;
      getPage(params)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 引入
    headIntroduce() {
      if (this.isSource) {
        console.log(this.companyId);
        this.getIntroducetree(this.companyId);
        this.introduceShow = true;
      } else {
        this.$message({
          message: "请选择风险源",
          type: "warning",
        });
      }
    },
    // 保存引用
    handleIntroduceSave() {
      if (!this.introduceArr.length) {
        this.$message({
          message: "请勾选需要引入的风险源",
          type: "warning",
        });
      }
      // this.introduceArr.forEach((item) => {
      //   item.organizationId = this.userInfo.dept_id;
      //   item.sourceId = this.nowTreeNode.id;
      //   item.sourceCode = this.nowTreeNode.sourceCode;
      //   item.sourceName = this.nowTreeNode.sourceName;
      // });
      improtList(
        this.introduceArr,
        this.userInfo.dept_id,
        this.nowTreeNode.id
      ).then((res) => {
        this.introduceShow = false;
        // 引入成功
        this.$message({
          message: "引入成功",
          type: "success",
        });
        this.getdemotree();
        this.getChartPie();
        this.getPage();
      });
    },
    introduceSelect(row) {
      // row.organizationId = this.userInfo.dept_id;
      // row.sourceId = this.nowTreeNode.id;
      // row.sourceCode = this.nowTreeNode.sourceCode;
      // row.sourceName = this.nowTreeNode.sourceName;
      improtList([row], this.userInfo.dept_id, this.nowTreeNode.id).then(
        (res) => {
          this.introduceShow = false;
          // 引入成功
          this.$message({
            message: "引入成功",
            type: "success",
          });
          this.getdemotree();
          this.getChartPie();
          this.getPage();
        }
      );
    },
    handleCurrentIntroduce(page) {},
    handleTreeNodeSave() {
      // 新增树节点
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (!this.isSource) {
            if (this.treeForm.risktype == 1) {
              addTree({
                ...this.treeForm,
                parentId: this.treeForm.parentId
                  ? this.treeForm.parentId
                  : "-1",
                createDept: this.userInfo.dept_id,
                organizationId: this.userInfo.dept_id,
              }).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.showTreeDialog = false;
                  this.getdemotree();
                }
              });
            } else {
              if (!this.nowTreeNode.id) {
                this.$message({
                  message: "没有上级分类不可新增风险源",
                  type: "warning",
                });
                this.showTreeDialog = false;
              } else {
                addTreeList({
                  ...this.treeForm,
                  sourceTypeCode: this.nowTreeNode.sourceTypeCode,
                  sourceTypeName: this.nowTreeNode.sourceTypeName,
                  sourceTypeId: this.nowTreeNode.id,
                  sourceName: this.treeForm.sourceTypeName,
                  createDept: this.userInfo.dept_id,
                  organizationId: this.userInfo.dept_id,
                }).then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    this.showTreeDialog = false;
                    this.getdemotree();
                  }
                });
              }
            }
          } else {
            this.$message({
              message: "上级分类节点不能为风险源",
              type: "warning",
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .common_tree_handle {
  height: 100%;

  .el-tree {
    height: calc(100% - 112px) !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

::v-deep #gridLayout {
  height: calc(100% - 138px) !important;
}

::v-deep .avue-crud {
  height: 100% !important;

  .el-card {
    height: 100% !important;
  }

  .el-card__body {
    height: 100% !important;

    .el-form {
      height: 100% !important;

      .el-table {
        height: 100% !important;
      }
    }
  }
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .echarts-box {
      border-radius: 4px;
      width: 50%;
      height: 100%;
      background: #fff;
      position: relative;

      .title {
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 10px;
      }
    }

    .canvas-box {
      border-radius: 4px;
      width: 50%;
      height: 100%;
      background: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;

      .title-box {
        width: calc(100% - 20px);
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .bottom {
    border-radius: 4px;
    margin-top: 10px;
    background: #fff;
    display: flex;
    width: 100%;
    height: calc(50% - 10px);
    overflow: scroll;

    .el-container {
      height: 100%;

      .el-main {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

.el-main {
  overflow: hidden;
}
</style>
