var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabsArr, function (item) {
          return _c("el-tab-pane", {
            key: item.dictKey,
            attrs: { label: item.dictValue, name: item.dictKey },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "content flex-container" },
        [
          _c("CommonTreeNew", {
            ref: "commonTreeCore",
            staticClass: "introduceTree",
            attrs: {
              treeTitle: "岗位工种/清单",
              "node-key": "id",
              isLeafIcons: "",
              isShowdig: "",
              risk: "",
              defaultExpandedKeys: [],
              treeData: _vm.tableData,
              defaultProps: _vm.defaultProps,
              showCheckbox: false,
            },
            on: {
              getTreeAdd: _vm.opDialog,
              getTreeDelete: _vm.rowDel,
              getTreeEdit: _vm.rowEdit,
              getNodeClick: _vm.gridRowClick,
            },
          }),
          _vm.selectTree.postType == 2
            ? _c("div", { staticClass: "center center-div" }, [
                _c(
                  "div",
                  { staticClass: "flex-content he-50" },
                  [
                    _c("head-layout", {
                      attrs: {
                        "head-btn-options": _vm.selectTree.id
                          ? _vm.topheadBtnOptions
                          : [],
                        "head-title": "岗位接害因素",
                      },
                      on: { "head-save": _vm.saveHazard },
                    }),
                    _c("el-input", {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        type: "textarea",
                        maxlength: 255,
                        rows: "7",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.selectTree.hazard,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectTree, "hazard", $$v)
                        },
                        expression: "selectTree.hazard",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex-content he-50" },
                  [
                    _c("head-layout", {
                      attrs: {
                        "head-btn-options": _vm.selectTree.id
                          ? _vm.midheadBtnOptions
                          : [],
                        "head-title": "资质证书要求",
                      },
                      on: {
                        "head-add": _vm.addCertifi,
                        "head-save": _vm.saveCertifis,
                      },
                    }),
                    _vm._l(_vm.certificateData, function (item, index) {
                      return _c(
                        "el-form",
                        {
                          key: index,
                          staticStyle: { "margin-top": "10px" },
                          attrs: { "label-width": "80px" },
                        },
                        [
                          _c("el-form-item", { attrs: { label: "证书类型" } }, [
                            _c(
                              "div",
                              { staticClass: "form-content" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择证件类型" },
                                    on: {
                                      change: (val) =>
                                        _vm.certificateChange(val, index),
                                    },
                                    model: {
                                      value: item.certificateCode,
                                      callback: function ($$v) {
                                        _vm.$set(item, "certificateCode", $$v)
                                      },
                                      expression: "item.certificateCode",
                                    },
                                  },
                                  _vm._l(_vm.certificateTypes, function (item) {
                                    return _c("el-option", {
                                      key: item.dictKey,
                                      attrs: {
                                        label: item.dictValue,
                                        value: item.dictKey,
                                      },
                                    })
                                  }),
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn",
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "spacing-l-10",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteCertifi(
                                              item,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除\n                ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "spacing-l-10 spacing-r-10",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveCertifi(item, index)
                                          },
                                        },
                                      },
                                      [_vm._v("保存\n                ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex-content he-50",
                    staticStyle: { "margin-bottom": "0" },
                  },
                  [
                    _c("head-layout", {
                      attrs: {
                        "head-btn-options": _vm.selectTree.id
                          ? _vm.botheadBtnOptions
                          : [],
                        "head-title": "安全培训及考试",
                      },
                      on: {
                        "head-add": _vm.addStpost,
                        "head-save": _vm.saveStposts,
                      },
                    }),
                    _vm._l(_vm.stpostData, function (item, index) {
                      return _c(
                        "el-form",
                        {
                          key: index,
                          staticStyle: { "margin-top": "10px" },
                          attrs: { "label-width": "80px" },
                        },
                        [
                          _c("el-form-item", { attrs: { label: "培训课程" } }, [
                            _c(
                              "div",
                              { staticClass: "form-content" },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请选择培训课程" },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.opTrainDialog(index)
                                    },
                                  },
                                  model: {
                                    value: item.className,
                                    callback: function ($$v) {
                                      _vm.$set(item, "className", $$v)
                                    },
                                    expression: "item.className",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn",
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "spacing-l-10",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteStpost(item, index)
                                          },
                                        },
                                      },
                                      [_vm._v("删除\n                ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "spacing-l-10 spacing-r-10",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveStpost(item, index)
                                          },
                                        },
                                      },
                                      [_vm._v("保存\n                ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm.selectTree.postType == 2
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "calc(100% - 40% - 280px)",
                    height: "100%",
                    background: "#fff",
                  },
                },
                [
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.selectTree.id
                        ? _vm.rightheadBtnOptions
                        : [],
                      "head-title": "风险告知卡",
                    },
                    on: { "head-add": _vm.cardAdd },
                  }),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        height: "calc(100% - 60px)",
                        overflow: "auto",
                        padding: "10px",
                      },
                    },
                    _vm._l(_vm.cardList, function (item, index) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 24 } },
                        [
                          _c(
                            "el-card",
                            {
                              class: item.selected
                                ? "list-card-selected list-card"
                                : "list-card",
                              staticStyle: { "margin-bottom": "20px" },
                              attrs: { "body-style": { padding: "10px" } },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "content",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleItemClick(index, item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        height: "200px",
                                      },
                                      attrs: { src: item.picUrl, fit: "fit" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.showFile(item.picUrl)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            "justify-content": "center",
                                            "align-items": "center",
                                            "flex-direction": "column",
                                          },
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline",
                                            staticStyle: {
                                              "font-size": "20px",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    暂无图片\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("el-divider", {
                                    staticClass: "el-divider",
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "bottom" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            placement: "top",
                                            content: item.cardName,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "name" }, [
                                            _vm._v(_vm._s(item.cardName)),
                                          ]),
                                        ]
                                      ),
                                      _c("div", { staticClass: "btn" }, [
                                        _c("i", {
                                          staticClass: "el-icon-view",
                                          staticStyle: {
                                            "margin-right": "8px",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.viewItem(item)
                                            },
                                          },
                                        }),
                                        _c("i", {
                                          staticClass: "el-icon-download",
                                          staticStyle: {
                                            "margin-right": "8px",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.downloadItem(item)
                                            },
                                          },
                                        }),
                                        _c("i", {
                                          staticClass: "el-icon-edit",
                                          staticStyle: {
                                            "margin-right": "8px",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editItem(item)
                                            },
                                          },
                                        }),
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.removeList(item)
                                            },
                                          },
                                        }),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.selectTree.postType || _vm.selectTree.postType == 1
            ? _c("el-empty", {
                staticStyle: { width: "100%" },
                attrs: { "image-size": 200, description: "请点击左侧岗位查询" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.visible
        ? _c(
            "el-dialog",
            {
              staticClass: "avue-dialog",
              attrs: { title: "岗位/工种", width: "30%", visible: _vm.visible },
              on: {
                "update:visible": function ($event) {
                  _vm.visible = $event
                },
              },
            },
            [
              _c("avue-form", {
                ref: "avueForm",
                attrs: { option: _vm.jcxflOption },
                on: { submit: _vm.handleSave },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
              _c(
                "div",
                { staticClass: "avue-dialog__footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.saveLoading,
                          expression: "saveLoading",
                        },
                      ],
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.visible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.saveLoading,
                          expression: "saveLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.avueForm.submit()
                        },
                      },
                    },
                    [_vm._v("\n        保存\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("Training", {
        ref: "training",
        on: { getSelectRow: _vm.getSelectRow },
      }),
      _c("CardAdd", { ref: "cardAdd", on: { getStatus: _vm.getCardList } }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "风险告知卡预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }