var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.show
        ? _c(
            "common-dialog",
            {
              attrs: { width: "70%", dialogTitle: "折线图", showBtn: false },
              on: {
                cancel: function ($event) {
                  _vm.show = false
                },
              },
            },
            [
              _vm.show
                ? _c("div", { attrs: { id: "chartDiscount" } })
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }