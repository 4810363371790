var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("common-tree", {
            ref: "commonTree",
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: true,
              loading: _vm.treeDialogLoading,
              showCheckbox: false,
              treeTitle: "题库分类",
            },
            on: {
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.treeNodeClick,
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "题库",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-add-tabs": _vm.headAdd,
                  "head-remove": _vm.headRemove,
                  "head-import": _vm.headImport,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowDetail,
                  "row-remove": _vm.rowRemove,
                  "row-edit": _vm.rowEdit,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showTreeDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                width: "60%",
                showFullscreen: true,
                dialogTitle: _vm.$t(
                  `cip.plat.sys.knowledge.title.treeHeadCateTitle`
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.showTreeDialog = false
                },
                confirm: _vm.handleTreeNodeSave,
              },
            },
            [
              _c("avue-form", {
                ref: "addForm",
                attrs: { option: _vm.treeDialogOption },
                on: { submit: _vm.treeNodeSave },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.excelBox
        ? _c(
            "CommonDialog",
            {
              attrs: { width: "30%", showBtn: false, dialogTitle: "题库导入" },
              on: {
                cancel: function ($event) {
                  _vm.excelBox = false
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "import" },
                [
                  _c(
                    "avue-form",
                    {
                      attrs: {
                        option: _vm.excelOption,
                        "upload-after": _vm.uploadAfter,
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "excelTemplate" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplate },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("cip.plat.sys.user.field.download")
                                  )
                              ),
                              _c("i", {
                                staticClass: "el-icon-download el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }