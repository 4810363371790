var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-rate", {
            attrs: {
              max: _vm.activeData.max,
              "allow-half": _vm.activeData["allow-half"],
            },
            model: {
              value: _vm.activeData.__config__.defaultValue,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
              },
              expression: "activeData.__config__.defaultValue",
            },
          }),
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  _vm.activeData.__config__.defaultValue = 0
                },
              },
            },
            [_vm._v("清空")]
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最大值" } },
        [
          _c("el-input-number", {
            attrs: { placeholder: "最大值", "controls-position": "right" },
            model: {
              value: _vm.activeData.max,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "max", $$v)
              },
              expression: "activeData.max",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "允许半选" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData["allow-half"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "allow-half", $$v)
              },
              expression: "activeData['allow-half']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showType === "pc",
              expression: "showType==='pc'",
            },
          ],
          attrs: { label: "辅助文字" },
        },
        [
          _c("el-switch", {
            on: { change: _vm.rateTextChange },
            model: {
              value: _vm.activeData["show-text"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "show-text", $$v)
              },
              expression: "activeData['show-text']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showType === "pc",
              expression: "showType==='pc'",
            },
          ],
          attrs: { label: "显示分数" },
        },
        [
          _c("el-switch", {
            on: { change: _vm.rateScoreChange },
            model: {
              value: _vm.activeData["show-score"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "show-score", $$v)
              },
              expression: "activeData['show-score']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }