<template>
  <div style="position: relative">
    <div>
      <!-- 闸机列表 -->
      <head-layout head-title="设备信息" :head-btn-options="headBtnOptions" @head-add="handleAdd" @head-import="handleImport" @head-export="handleExport" @head-user="handleUser"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout class="table-container" ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template #customBtn="{ row }">
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowUpdate(row)">编辑
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel(row)">删除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <addDevice ref="addDevice" @callback="onLoad(page, {})"></addDevice>
    <userAllSelect ref="userAllSelect" @callBack="saveUser"></userAllSelect>
  </div>
</template>

<script>
import {
  getList,
  getDetail,
  add,
  update,
  remove,
  saveBatch
} from "@/api/safe/gate";
import {
  mapGetters
} from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import addDevice from "./addDevice";
import {
  getAgeFromIdCard
} from "@/util/util";
import userAllSelect from "./user-all-select";

export default {
  components: {
    addDevice,
    GridLayout,
    HeadLayout,
    userAllSelect,
  },
  data() {
    return {
      prjId:'',
      form: {},
      query: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        linklabel: "eqtCode",
        reserveSelection:true,
        column: [{
          label: "设备号",
          prop: "eqtCode",
          align: "center",
          overHidden: true,
        },
          {
            label: "设备名称",
            prop: "eqtName",
            align: "center",
            overHidden: true,
          },
          {
            label: "设备类型",
            prop: "eqtType",
            align: "center",
            // dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineEqtType",
          },
          {
            label: "型号规格",
            prop: "modelSpec",
            align: "center",
            overHidden: true,
          },
          {
            label: "厂商名称",
            prop: "manufacturerName",
            align: "center",
            overHidden: true,
          },
          {
            label: "安装位置",
            prop: "installSite",
            width: 100,
            align: "center",
            overHidden: true,
          },
          {
            label: "设备状态",
            prop: "eqtStatus",
            width: 100,
            align: "center",
            // dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineStatus",
          },

        ],
      },
      headBtnOptions: [{
        label: "选择人员",
        emit: "head-user",
        type: "button",
        icon: "",
        // btnOptType: "add",
      },
        {
          label: "新增设备",
          emit: "head-add",
          type: "button",
          icon: "",
          // btnOptType: "add",
        },
        // {
        //   label: "导入",
        //   emit: "head-import",
        //   type: "button",
        //   icon: "",
        //   btnOptType: "import",
        // },
        // {
        //   label: "导出",
        //   emit: "head-export",
        //   type: "button",
        //   icon: "",
        //   btnOptType: "export",
        // },
      ],
      searchColumns: [{
        label: "设备号",
        prop: "eqtCode",
        span: 4,
        placeholder: "请输入设备号",
      },
        {
          label: "设备名称",
          prop: "eqtName",
          span: 4,
          placeholder: "请输入设备名称",
        },
        {
          label: "厂商名称",
          prop: "manufacturerName",
          span: 4,
          placeholder: "请输入厂商名称",
        },
      ],
      tableData: [],
      excelBox: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        excelForm: {},
        column: [{
          label: "数据导入",
          prop: "excelFile",
          type: "upload",
          drag: true,
          loadText: this.$t("cip.plat.sys.user.field.waiting"),
          span: 24,
          propsHttp: {
            res: "data",
          },
          tip: this.$t("cip.plat.sys.user.field.format"),
          action: "/api/sinoma-hse-prj/thirduser/import",
        },

          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["permission",'userInfo']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.thirduser_add, false),
        viewBtn: this.vaildData(this.permission.thirduser_view, false),
        delBtn: this.vaildData(this.permission.thirduser_delete, false),
        editBtn: this.vaildData(this.permission.thirduser_edit, false),
      };
    },
  },
  watch: {
    "excelForm.isCovered"() {
      if (this.excelForm.isCovered !== "") {
        const column = this.findObject(this.excelOption.column, "excelFile");
        column.action = `/api/sinoma-hse-prj/thirduser/import?isCovered=${this.excelForm.isCovered}`;
      }
    },
  },
  mounted() {
    this.prjId=this.userInfo.dept_id
    this.onLoad(this.page, {});
  },
  methods: {
    handleAdd() {
      this.$refs.addDevice.init("add", null, this.prjId);
    },
    rowUpdate(row) {
      this.$refs.addDevice.init("edit", row, this.prjId);
    },
    //查看
    rowView(row) {
      this.$refs.addDevice.init("view", row, this.prjId);
    },
    // 多选
    selectionChange(list) {
      this.selectionList = list;
    },
    //人员设置
    handleUser() {
      if (this.selectionList.length) {
        if (this.selectionList.length > 1) {
          this.$refs.userAllSelect.init(this.prjId, null,'batch')
        } else {
          this.$refs.userAllSelect.init(this.prjId, this.selectionList[0].id,'batch')
        }
      } else {
        this.$message.warning("请选择至少一条数据");
      }

    },
    saveUser(data) {
      let newId = this.selectionList.map((item) => {
        return item.id
      })
      let newuser = data.map((item) => {
        return {
          ...item,
          prjId: this.prjId,
          // gateMachineId: newId.join(','),
        }
      })
      let query = {
        prjId: this.prjId,
        gateMachineIdList: newId,
        list: newuser
      }
      saveBatch(query).then((res) => {
        this.selectionList=[]
        this.$refs.gridLayOut.selectionClear();
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      })
    },
    getAge(data) {
      return getAgeFromIdCard(data);
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      params.prjId = this.prjId
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
    },

  },
};

</script>

<style lang="less" scoped>
::v-deep .table-container {
  .el-table {
    height: calc(100vh - 334px) !important;
    max-height: calc(100vh - 334px) !important;
  }
}

</style>
