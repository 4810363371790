<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.plat.cip.plat.intf.interface.field.task.headTitle`)"
          @head-remove="headRemove"
          @head-add-tabs="headAddTabs"
        ></head-layout>
      </el-col>
    </el-row>
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-row-detail-click="rowDetail"
      @row-remove="rowRemove"
      @row-edit="rowEdit"
    >
    </grid-layout>
  </div>
</template>

<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {checkLogin} from '@/util/xxljob'

import {
  remove,
  pageList as getList
} from '@/api/xxlJob/jobUserList'

export default {
  name: "adInfo",
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "username",
          span: 4,
          placeholder: this.$t("cip.plat.xxljob.task.username"),
        },
        {
          prop: "role",
          type: 'select',
          dataType: 'string',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=AD_INFO_TYPE",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.xxljob.task.role`)
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.xxljob.task.username'),
            prop: 'username',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.xxljob.task.password'),
            prop: 'password',
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      return [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        },
      ]
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      return [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    headRemove() {

    },
    headAddTabs() {
      this.$router.push({
        path: `/xxlJob/userManage/edit`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/userManage/edit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/userManage/edit`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      let that = this
      checkLogin(function(){
        that.page = page;
        that.tableLoading = true;
        let searchForm = JSON.stringify(that.$refs.gridHeadLayout.searchForm)
        if(searchForm=='{}'){
          searchForm = {
            "username":"",
            "role":-1}
        }
        getList(page.currentPage, page.pageSize, Object.assign(searchForm,params)).then(res => {
          const data = res.data;
          that.$refs.gridLayOut.page.total = data.total;
          that.tableData = data.records;
          that.tableLoading = false;
        });
      })
    }
  }
};
</script>
<style>
</style>
