<template>
  <basic-container>
    <avue-skeleton :loading="waiting"
                   avatar
                   :rows="8">
      <!--      <div class="topBox">-->
      <!--        <div class="leftTopBox">-->
      <!--          <img src="@/assets/images/wander.png" class="wanderImg">-->
      <!--          <div class="leftTopTitle">-->
      <!--            <div> {{ process.processDefinitionName }}</div>-->
      <!--            <el-tabs v-model="activeName" @tab-click="handleClick">-->
      <!--              <el-tab-pane label="申请信息" name="first"></el-tab-pane>-->
      <!--              <el-tab-pane label="流转信息" name="second"></el-tab-pane>-->
      <!--              <el-tab-pane label="流程跟踪" name="third"></el-tab-pane>-->
      <!--            </el-tabs>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="rightTopBox" v-if="type != 'view'">-->
      <!--          <wf-button :loading="submitLoading"-->
      <!--                     :button-list="buttonList"-->
      <!--                     :process="process"-->
      <!--                     :comment="comment"-->
      <!--                     @examine="handleExamineBefore"-->
      <!--                     @temporary-save="handleTemporary"-->
      <!--                     @user-select="handleUserSelect"-->
      <!--                     @print="handlePrint"-->
      <!--                     @rollback="handleRollbackTask"-->
      <!--                     @terminate="handleTerminateProcess"-->
      <!--                     @withdraw="handleWithdrawTask"></wf-button>-->
      <!--        </div>-->
      <!--        <div class="statusIcon">-->
      <!--          <template v-if="process.processIsFinished === 'reject'">-->
      <!--            <i v-if="process.status === 'todo'">-->
      <!--              <img src="@/assets/statusIcon/submit.png" alt="">-->
      <!--            </i>-->
      <!--            <i v-if="process.status === 'done'">-->
      <!--              <img src="@/assets/statusIcon/refuse.png" alt="">-->
      <!--            </i>-->
      <!--          </template>-->
      <!--          <template v-if="process.processIsFinished === 'unfinished'">-->
      <!--            <i v-if="process.status === 'todo'">-->
      <!--              <img src="@/assets/statusIcon/audit.png" alt="">-->
      <!--            </i>-->
      <!--            <i v-if="process.status === 'done'">-->
      <!--              <img src="@/assets/statusIcon/audit.png" alt="">-->
      <!--            </i>-->
      <!--          </template>-->
      <!--          <template v-if="process.processIsFinished === 'finished'">-->
      <!--            <i v-if="process.status === 'todo'">-->
      <!--              <img src="@/assets/statusIcon/audit.png" alt="">-->
      <!--            </i>-->
      <!--            <i v-if="process.status === 'done'">-->
      <!--              <img src="@/assets/statusIcon/pass.png" alt="">-->
      <!--            </i>-->
      <!--          </template>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="middleBox">
        <div class="middleBorder">
          <div style="width: calc(100% - 2px);height: calc(100% - 2px);border: 1px solid #DCDFE6;border-radius: 4px">
            <iframe v-if="iframe_url && activeName == 'first'" id="iframeForm" ref="iframeForm" :src="iframe_url"
                    frameborder="0" style="width:100%;height:100%"></iframe>

            <wf-flow v-if="activeName == 'second'" :flow="flow" style="height: 100%;overflow: auto"></wf-flow>

            <wf-design ref="bpmn" v-if="activeName == 'third'" style="height: 100%;" :options="bpmnOption"></wf-design>
          </div>
        </div>
        <div class="formBox">
          <wf-examine-form ref="examineForm"
                           :comment.sync="comment"
                           :process="process"
                           :type="type"
                           :hideApproval="hideApproval"
                           @user-select="handleUserSelect"></wf-examine-form>
        </div>
      </div>
      <!-- 人员选择弹窗 -->
      <user-select ref="user-select"
                   :check-type="checkType"
                   :default-checked="defaultChecked"
                   @onConfirm="handleUserSelectConfirm"></user-select>
    </avue-skeleton>
  </basic-container>
</template>

<script>
import WfExamineForm from '@/views/plugin/workflow/process/components/examForm.vue'
import WfButton from '@/views/plugin/workflow/process/components/button.vue'
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue'
import userSelect from '@/views/plugin/workflow/process/components/user-select.vue'
import exForm from '@/views/plugin/workflow/mixins/ex-form'
import theme from '@/views/plugin/workflow/mixins/theme'
import request from '@/router/axios';
import {mapGetters} from "vuex";
import website from "@/config/website";

export default {
  mixins: [exForm, theme],
  components: {userSelect, WfExamineForm, WfButton, WfFlow},
  watch: {
    '$route.query.p': {
      handler(val) {
        if (val) {
          const param = JSON.parse(Buffer.from(val, 'base64').toString())
          const {taskId, processInsId, type} = param;
          this.type = type;
          if (taskId && processInsId) this.getDetail(taskId, processInsId)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["themeColor"]),
    refresh() {
      return this.$store.state.common.refresh
    },
    layoutType() {
      return this.$store.state.common.layoutType
    },
  },
  data() {
    return {
      pass: false,
      isValidateForm: false,
      activeName: 'first',
      iframe_url: '',
      type: '',
      option: {
        column: [
          {
            label: '属性1',
            prop: 'attr1',
            disabled: true
          },
          {
            label: '属性2',
            prop: 'attr2',
            disabled: true
          },
          {
            label: '属性3',
            prop: 'attr3',
            disabled: true
          },
          {
            label: '属性4',
            prop: 'attr4',
            disabled: true
          }
        ]
      },
      defaults: {},
      form: {},
      stagingTaskData: [],
      // option: {}, // 表单配置
      vars: [], // 需要提交的字段
      isStagingTask: false, // 是否是暂存
      submitLoading: false, // 提交时按钮loading
      callObj: {
        taskDefinitionKey: '',
        id: '',
        type: '',
      },
      process: {},
      eventData: {
        key: "",
        processBusinessName: "",
      },
      hideApproval: false, // 隐藏常用审批意见
    }
  },
  mounted() {
    this.waiting = false
    // this.vanishSidder();
    // 移动端  PC端 判断方法
    this.os = function () {
      var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
      return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
      };
    }();
    if (!this.os.isPc) {
      document.title = '南京板块数字建造平台'
    }
    window.addEventListener('message', this.handleMessage)
    // this.iframe_url="/safetyenment/#/projectDepartment/securityWeekly/edit?type=2&id="+this.form.id
  },
  methods: {
    handleClick() {
      if (this.activeName == 'first') {
        this.vanishSidder();

        this.$nextTick(() => {
          var iframe = document.getElementById("iframeForm");
          if (iframe.attachEvent) {
            iframe.attachEvent("onload", function () {
              iframe.contentWindow.postMessage({type: 'workFlow'}, "*")
            });
          } else {
            iframe.onload = function () {
              iframe.contentWindow.postMessage({type: 'workFlow'}, "*")
            };
          }
        })
      }
    },
    vanishSidder() {
      let that = this;
      that.$parent.$refs.avueLayout.style.cssText = 'padding-left: 0px;';
      that.$parent.$children[1].$el.style.cssText = 'display:none;';
      that.$parent.$children[2].$el.style.cssText = 'display:none;';
      if (that.layoutType == 'functional') {
        that.$parent.$refs.top.$el.style.cssText = 'display:none;';
      } else {
        that.$parent.$refs.avueLeft.style.cssText = 'display:none;';
      }
      setTimeout(() => {
        that.$parent.$refs.avueView.style.cssText = 'padding: 0px; height: 100vh !important;';
      }, 1500)
    },
    restRefresh() {
      this.$store.commit('SET_REFRESH', !this.refresh)
    },
    // 获取任务详情
    getDetail(taskId, processInsId, pass, comment) {
      this.getTaskDetail(taskId, processInsId).then(res => {
        const {process, form} = res
        const {variables, status} = process
        this.process = process
        this.form = variables
        // 自行处理相关逻辑
        // this.iframe_url="/safetyenment/#/projectDepartment/securityWeekly/edit?isApprove=true&type=2&id="+this.form.id
        // 当流程被驳回且处于待办时隐藏评论
        if (process.processIsFinished === 'reject' && process.status === 'todo') {
          this.findObject(this.$refs.examineForm.examineOption.column, "comment").display = false; // 隐藏评论
          this.hideApproval = true;
        }
        this.isValidateForm = process.isValidateForm
        let url = window.location.origin + '#';
        // let url = 'http://localhost:1888/#'
        //报表填报携带参数url 跳转报表 templateClassify 是填报分类需要携带 要不不能区分调转到那个路径  不改原有逻辑 如果携带templateClassify 就给路径上拼接
        if (process.formUrl.indexOf('?') >= 0) {
          if (this.form.templateClassify) {
            this.iframe_url = url + process.formUrl + `&id=${this.form.id}&templateClassify=${this.form.templateClassify}&type=workflow&hideMenu=true`
          } else {
            this.iframe_url = url + process.formUrl + `&id=${this.form.id}&type=workflow&hideMenu=true`
          }
        } else {
          if (this.form.templateClassify) {
            this.iframe_url = url + process.formUrl + `?id=${this.form.id}&templateClassify=${this.form.templateClassify}&type=workflow&hideMenu=true`
          } else {
            this.iframe_url = url + process.formUrl + `?id=${this.form.id}&type=workflow&hideMenu=true`
          }
        }
        this.callObj = {
          taskDefinitionKey: process.taskDefinitionKey,
          id: variables.id,
          type: '',
        }
        this.waiting = false // 关闭骨架屏
        this.$nextTick(() => {
          var iframe = document.getElementById("iframeForm");
          console.log(iframe, 'iframe')
          if (iframe.attachEvent) {
            iframe.attachEvent("onload", function () {
              iframe.contentWindow.postMessage({type: 'workFlow'}, "*")
            });
          } else {
            iframe.onload = function () {
              iframe.contentWindow.postMessage({type: 'workFlow'}, "*")
            };
          }
          window.addEventListener('message', (e) => {  // 监听 message 事件
            if (e.data.type == 'checkFinish') {
              if (this.isStagingTask) {
                this.handleTemporarySave(e.data.data)
              } else {
                this.handleExamine(this.pass, e.data.data)
              }
            }
          }, false);
        })
      }).finally(() => {
        this.handleExamine(pass, null, comment)
      })
    },
    handleTemporary() {
      this.isStagingTask = true
      let data = {
        type: 'check',
      }
      this.$refs.iframeForm.contentWindow.postMessage(data, "*")
      this.restRefresh();
    },
    //暂存
    handleTemporarySave(data) {
      this.submitLoading = true
      const variables = {}
      this.vars.forEach(v => {
        if (v != 'comment' && this.form[v]) variables[v] = this.form[v]
      })
      Object.assign(variables, {data: data})
      this.handleSaveTask(variables).then(() => {
        this.$message.success("处理成功")
        wx.miniProgram.navigateBack()
        window.close()
        this.restRefresh();
      }).catch(() => {
        this.submitLoading = false
      })
    },
    handleExamineBefore(pass) {
      this.isStagingTask = false
      if (this.isValidateForm) {
        this.pass = pass
        let data = {
          type: 'check',
        }
        this.$refs.iframeForm.contentWindow.postMessage(data, "*")
      } else {
        if (this.process.processIsFinished === 'reject' && this.process.status === 'todo' && pass) {
          this.$confirm("业务数据是否已保存?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            this.handleExamine(pass)
          })
        } else {
          this.handleExamine(pass)
        }
      }
    },
    // 审核
    handleExamine(pass, backData, comment) {
      let data = {type: 'workTicket'}
      this.$refs.iframeForm.contentWindow.postMessage(data, "*")
      this.submitLoading = true
      const variables = {}
      this.vars.forEach(v => {
        if (v != 'comment' && this.form[v]) variables[v] = this.form[v]
      })
      if (this.eventData.processBusinessName !== "") {
        Object.assign(variables, {processBusinessName: this.eventData.processBusinessName})
      }
      Object.assign(variables, {data: backData})
      this.$refs.examineForm.examineForm.comment = comment
      this.handleCompleteTask(pass, variables).then(() => {
        this.$message.success("处理成功")
        if (!this.os.isPc) {
          wx.miniProgram && wx.miniProgram.navigateBack()
          document.addEventListener("WeixinJSBridgeReady", function () {
            WeixinJSBridge && WeixinJSBridge.call("closeWindow")
          }, false)
          WeixinJSBridge && WeixinJSBridge.call("closeWindow");
        }
        if (pass) {
          this.callObj.type = '审批'
        } else {
          this.callObj.type = '驳回'
        }
        this.$message({
          type: "success",
          message: "操作成功",
        });
        // setTimeout(() => {
        //   this.$nextTick(() => {
        //     window.location.reload()
        //     window.close()
        //     this.restRefresh();
        //   })
        // }, 2000)
      }).catch(() => {
        this.submitLoading = false
      })
    },
    // 回调地址
    callbackFn() {
      request({
        url: "/api/sinoma-sm/safetyWeeklyReport/callBack",
        method: 'post',
        data: this.callObj,
      }).then(
        // window.close()
        // this.handleCloseTag('/plugin/workflow/process/todo')
      )
    },
    handleMessage(event) {
      // 处理接收到的消息
      if (event.data.key && event.data.key !== "" && event.data.key === "knowledgeUpload") {
        this.eventData = event.data;
      }
    },
  }
}
</script>
<!--<style>-->
<!--.leftNarrow {-->
<!--  display: none;-->
<!--}-->

<!--.avue-layout {-->
<!--  padding-left: 0;-->
<!--}-->

<!--.avue-top {-->
<!--  display: none;-->
<!--}-->

<!--.avue-tags {-->
<!--  display: none;-->
<!--}-->

<!--#avue-view {-->
<!--  padding: 0 !important;-->
<!--  height: 100vh !important;-->
<!--}-->
<!--</style>-->
<style lang="scss" scoped>
.topBox {
  width: calc(100% - 48px);
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  padding: 14px 24px 0;
  position: fixed;
  top: 0;
}

.leftTopBox {
  display: flex;
}

.leftTopTitle {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}

.wanderImg {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: block;
  margin-right: 8px;

}

.rightTopBox {
  display: flex;
  margin-top: 10px;
}

.rightTopBox .el-button {
  height: 32px;
}

.middleBox {
  width: calc(100% - 24px);
  display: flex;
  justify-content: space-between;
  padding: 12px;
  margin-top: 68px;
  height: calc(100vh - 118px);
}

.middleBorder {
  width: calc(80% - 24px);
  height: 100%;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  background-color: #FFFFFF;
}

.formBox {
  width: calc(20% - 26px);
  position: relative;
  height: 100%;
  padding: 12px 7px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  background-color: #FFFFFF;
}

.header {
  width: 100%;
  height: 60px;
  //background: #00053D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0px 30px;
  color: #ffffff;

  .icon {
    display: flex;
    align-items: center;

    i {
      margin-right: 5px;
      display: inline-block;
      width: 6px;
      height: 12px;
      background: #FFFFFF;
      border-radius: 20px 20px 20px 20px;
    }
  }
}

::v-deep .el-tabs__header {
  margin: 0;
}

::v-deep .el-tabs__content {
  display: none;
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep .el-tabs__item {
  height: 33px;
  line-height: 33px;
}

.el-card {
  border-color: #ffffff;
}

::v-deep .avue-affix {
  position: relative !important;
}

.statusIcon {
  position: absolute;
  right: 25%;
  z-index: 10;
  top: 40px;

  img {
    width: 70px;
    height: 70px;
    display: block;
  }
}

.exchangeCard {
  overflow: auto;
  height: calc(100vh - 226px);
}

::v-deep .bjs-powered-by {
  display: none;
}

// 流程详情不随主题色改变
::v-deep .el-tabs__item.is-active {
  color: #409EFF;
}

::v-deep .el-tabs__active-bar {
  background-color: #409EFF;
}

::v-deep .el-tabs__item:hover {
  color: #409EFF;
}
</style>
