var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "avue-skeleton",
        { attrs: { loading: _vm.waiting, avatar: "", rows: 8 } },
        [
          _c("div", { staticClass: "middleBox" }, [
            _c("div", { staticClass: "middleBorder" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "calc(100% - 2px)",
                    height: "calc(100% - 2px)",
                    border: "1px solid #DCDFE6",
                    "border-radius": "4px",
                  },
                },
                [
                  _vm.iframe_url && _vm.activeName == "first"
                    ? _c("iframe", {
                        ref: "iframeForm",
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          id: "iframeForm",
                          src: _vm.iframe_url,
                          frameborder: "0",
                        },
                      })
                    : _vm._e(),
                  _vm.activeName == "second"
                    ? _c("wf-flow", {
                        staticStyle: { height: "100%", overflow: "auto" },
                        attrs: { flow: _vm.flow },
                      })
                    : _vm._e(),
                  _vm.activeName == "third"
                    ? _c("wf-design", {
                        ref: "bpmn",
                        staticStyle: { height: "100%" },
                        attrs: { options: _vm.bpmnOption },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "formBox" },
              [
                _c("wf-examine-form", {
                  ref: "examineForm",
                  attrs: {
                    comment: _vm.comment,
                    process: _vm.process,
                    type: _vm.type,
                    hideApproval: _vm.hideApproval,
                  },
                  on: {
                    "update:comment": function ($event) {
                      _vm.comment = $event
                    },
                    "user-select": _vm.handleUserSelect,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("user-select", {
            ref: "user-select",
            attrs: {
              "check-type": _vm.checkType,
              "default-checked": _vm.defaultChecked,
            },
            on: { onConfirm: _vm.handleUserSelectConfirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }