var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content flex-container" },
    [
      _c("CommonTreeNew", {
        ref: "commonTree",
        attrs: {
          treeTitle: "项目架构",
          defaultProps: _vm.Props,
          showCheckbox: false,
          urlParmas: {
            tenantId: "",
            deptCategory: "",
            parentId: _vm.userInfo.deptId,
          },
          "node-key": "id",
        },
        on: { getNodeClick: _vm.handleNode },
      }),
      _c(
        "div",
        { staticClass: "clacWidth" },
        [
          _c("head-layout", {
            attrs: {
              "head-title": "风险告知清单",
              "head-btn-options": _vm.headBtnOptions,
            },
            on: { "head-export": _vm.headExport, "head-add": _vm.headAdd },
          }),
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: { "search-columns": _vm.searchColumns },
            on: {
              "grid-head-search": _vm.gridHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
          _vm.list.length
            ? _c(
                "div",
                { staticClass: "list" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    _vm._l(_vm.list, function (item, index) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 8 } },
                        [
                          _c(
                            "el-card",
                            {
                              class: item.selected
                                ? "list-card-selected list-card"
                                : "list-card",
                              attrs: { "body-style": { padding: "10px" } },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "content",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleItemClick(index, item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        height: "200px",
                                      },
                                      attrs: { src: item.picUrl, fit: "fit" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.showFile(item.picUrl)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            "justify-content": "center",
                                            "align-items": "center",
                                            "flex-direction": "column",
                                          },
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline",
                                            staticStyle: {
                                              "font-size": "20px",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    暂无图片\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("el-divider", {
                                    staticClass: "el-divider",
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "bottom" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            placement: "top",
                                            content: item.cardName,
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "name" }, [
                                            _vm._v(_vm._s(item.cardName)),
                                          ]),
                                        ]
                                      ),
                                      _c("div", { staticClass: "btn" }, [
                                        _c("i", {
                                          staticClass: "el-icon-view",
                                          staticStyle: {
                                            "margin-right": "8px",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.viewItem(item)
                                            },
                                          },
                                        }),
                                        _c("i", {
                                          staticClass: "el-icon-download",
                                          staticStyle: {
                                            "margin-right": "8px",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.downloadItem(item)
                                            },
                                          },
                                        }),
                                        item.createUser == _vm.userInfo.id
                                          ? _c("i", {
                                              staticClass: "el-icon-edit",
                                              staticStyle: {
                                                "margin-right": "8px",
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.editItem(item)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        item.createUser == _vm.userInfo.id
                                          ? _c("i", {
                                              staticClass: "el-icon-delete",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.removeList(item)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.list.length
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.listLoading,
                      expression: "listLoading",
                      modifiers: { body: true },
                    },
                  ],
                  staticClass: "list-empty",
                },
                [_c("el-empty", { attrs: { description: "暂无数据" } })],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.page.current,
                  "page-sizes": [9, 18, 27, 36, 45],
                  "page-size": _vm.page.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.page.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Add", { ref: "add", on: { getStatus: _vm.getList } }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "风险告知卡预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }