var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "占位提示" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData.placeholder,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "placeholder", $$v)
              },
              expression: "activeData.placeholder",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "格式" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "level",
              on: { change: _vm.levelChange },
              model: {
                value: _vm.activeData.level,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "level", $$v)
                },
                expression: "activeData.level",
              },
            },
            [
              _c("el-radio", { attrs: { label: 0 } }, [_vm._v("省")]),
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("省市")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("省市区")]),
              _c("el-radio", { attrs: { label: 3 } }, [_vm._v("省市区-街道")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "能否清空" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.clearable,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "clearable", $$v)
              },
              expression: "activeData.clearable",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "能否多选" } },
        [
          _c("el-switch", {
            on: { change: _vm.levelChange },
            model: {
              value: _vm.activeData.multiple,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "multiple", $$v)
              },
              expression: "activeData.multiple",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }