<template>
  <div class="main-box riskIdentification">
    <div class="flex-container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="运行设置" name="1">
          <RuntimeSetting :allData="runtimeSetting" @update="updateRunTime"></RuntimeSetting>
        </el-tab-pane>
        <el-tab-pane label="区域绘制" name="2">
          <RegionSetting :allData="regionSetting" :url="url" @update="updateRegion"></RegionSetting>
        </el-tab-pane>
        <el-tab-pane label="联合报警" name="3">
          <el-form class="form-base form-box" ref="dataForm" :model="dataForm" label-width="160px" :disabled="formType == 'view'" :rules="rules">
            <el-col :span="24">
              <el-form-item label="长度相对像素" prop="code">
                <div class="logic-box">
                  <el-input-number v-model="dataForm.lenMinValue"></el-input-number>
                  <el-select v-model="dataForm.lenLogicOperator" placeholder="请选择">
                    <el-option v-for="item in taskTypeData" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                  </el-select>
                  <el-input-number v-model="dataForm.lenMaxValue"></el-input-number>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="长度逻辑运算方式" prop="type">
                <el-select v-model="dataForm.operator" placeholder="请选择长度逻辑运算方式">
                  <el-option v-for="item in logicTypeData" :key="item.value" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="宽度相对像素" prop="outsideCode">
                <div class="logic-box">
                  <el-input-number v-model="dataForm.widMinValue"></el-input-number>
                  <el-select v-model="dataForm.widLogicOperator" placeholder="请选择">
                    <el-option v-for="item in taskTypeData" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                  </el-select>
                  <el-input-number v-model="dataForm.widMaxValue"></el-input-number>
                </div>
              </el-form-item>
            </el-col>

          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <addNodeTree ref="addNodeTree"></addNodeTree>
    <addBoxTree ref="addBoxTree"></addBoxTree>
    <addServerTree ref="addServerTree"></addServerTree>
  </div>
</template>
<script>
  import Template from "@/views/message/template/list.vue";
  import * as API from "@/api/safetyTtandard/riskIdentification";
  import {
    exportBlob
  } from "@/api/common";
  import {
    downloadXls
  } from "@/util/util";
  import GridLayout from "@/views/components/layout/grid-layout";
  import {
    dictionaryBiz
  } from "@/api/reportTasks";
  import {
    strisksourceRemove
  } from "@/api/safetyTtandard/riskIdentification";

  import {
    getTree,
    remove
  } from "@/api/safe/algorithmManagement";
  import RuntimeSetting from "./RuntimeSetting";
  import RegionSetting from "./RegionSetting"
  import {
    ALGO_TASK_COMPARE_TYPE,
    ALGO_TASK_LOGIC_OPERATOR
  } from '../../../nodeDict.js'

  export default {
    name: "index",
    components: {
      Template,
      GridLayout,
      RuntimeSetting,
      RegionSetting
    },
    props: {
      url: {
        type: String,
        default: null
      },
      runSetupData: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        activeName: '1',
        dataForm: {
          lenMinValue: null,
          lenLogicOperator: '',
          lenMaxValue: null,
          operator: '',
          widMinValue: null,
          widLogicOperator: '',
          widMaxValue: null
        },
        jcxflModel: false,
        TableLoading: false,
        tableData: [],
        treeData: [],
        query: {},
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        defaultProps: {
          children: "children",
          value: 'id',
          label: "name",
        },
        taskTypeData: [],
        logicTypeData: [],
        runtimeSetting: [],
        regionSetting: []
      }
    },
    watch: {
      runSetupData: {
        immediate: true,
        handler: function (data) {
          this.activeName = '1'
          if (data) {
            this.runtimeSetting = data.runtimeConfigs
            this.regionSetting = data.regionConfigs
            this.dataForm = data.unionAlarmConfig
          }
        },
      },

    },
    mounted() {
      this.taskTypeData = ALGO_TASK_COMPARE_TYPE
      this.logicTypeData = ALGO_TASK_LOGIC_OPERATOR

    },
    methods: {
      handleClick(tab) {
        this.activeName = tab.name
      },
      updateRunTime(val) {
        this.runtimeSetting = val
      },
      updateRegion(val) {
        this.regionSetting = val
      },

      handleSubmit() {
        let config = {
          runtimeConfigs: this.runtimeSetting,
          regionConfigs: this.regionSetting,
          unionAlarmConfig: this.dataForm
        };
        this.$emit('callback', config)
      },





    },
  }

</script>
<style scoped lang="scss">
  .riskIdentification {
    background-color: #FFFFFF;

    .common_tree_handle .el-tree {
      height: calc(100vh - 278px) !important;
    }

    .table-box {
      width: calc(100% - 281px);


    }
  }

  ::v-deep .el-tabs {
    width: 100%;

    .el-tabs__nav-scroll {
      width: 100% !important;
    }
  }

  ::v-deep .el-tabs__nav {
    margin-left: 20px;
  }

  ::v-deep .introduceTable .avue-crud .el-table {
    height: calc(100vh - 330px) !important;
    max-height: calc(100vh - 330px) !important;
  }

  .introduceTree ::v-deep .common_tree_handle .el-tree {
    height: calc(100vh - 278px) !important;
  }

  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }

  ::v-deep .el-tabs--border-card {
    border-left: none;
    border-bottom: none;
  }

  ::v-deep .el-tabs__content {
    padding: 0 !important;
  }

  .el-input-number {
    width: 100%;
  }

  .logic-box {
    display: flex;
  }

  ::v-deep .form-box {
    display: flex;
    flex-flow: column;
    justify-content: center;

    .avue-form {
      width: 60% !important;
    }


  }

</style>
