<template>
  <div>
    <head-layout
        head-title="设备维修记录清单"
        :head-btn-options="headBtnOptions"
        @head-add="headAdd"
        @head-del="rowRemove()"
    ></head-layout>
    <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
        ref="gridLayOut"
        :table-options="tableOptions"
        :table-data="tableData"
        :table-loading="tableLoading"
        :data-total="page.total"
        :page="page"
        @page-current-change="handleCurrentChange"
        @page-size-change="handleSizeChange"
        @page-refresh-change="onLoad"
        @gird-handle-select-click="selectChange"
    >
        <template #recordCode="{row,index}">
            <el-link type="primary" @click="rowView(row)">{{ row.recordCode }}</el-link>
        </template>
        <template #customBtn="{row,index}">
            <template v-if="row.eqRepairStatus == '0' && row.createUser == userInfo.user_id">
                <el-button type="text" @click="rowEdit(row)" v-if="permissionList.editBtn">编辑</el-button>
                <el-button type="text" @click="rowRemove(row)" v-if="permissionList.delBtn">删除</el-button>
            </template>
            <template v-else>
                <el-button type="text" @click="rowView(row)" v-if="permissionList.viewBtn">查看</el-button>
            </template>
        </template>
    </grid-layout>
  </div>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { mapGetters } from 'vuex';
import {serviceRecordPage,serviceRecordRemove} from '@/api/equipmentFacilities/equipmentMaintenance'

export default {
    components: {
        HeadLayout,
        GridLayout,
    },

    computed: {
        ...mapGetters(["permission", 'colorName','userInfo']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.equipmentMaintenance_service_add, true),
                viewBtn: this.vaildData(this.permission.equipmentMaintenance_service_view, true),
                editBtn: this.vaildData(this.permission.equipmentMaintenance_service_edit, true),
                delBtn: this.vaildData(this.permission.equipmentMaintenance_service_delete, true),
            };
        },

        headBtnOptions () {
            let btnList = []
            if(this.permissionList.addBtn){
                btnList.push({
                    label: this.$t(`cip.cmn.btn.addBtn`),
                    emit: "head-add",
                    type: "button",
                    icon: ""
                })
            }
            if(this.permissionList.delBtn){
                btnList.push({
                    label: this.$t(`cip.cmn.btn.delBtn`),
                    emit: "head-del",
                    type: "button",
                    icon: ""
                })
            }
            return btnList
        },

        tableOptions () {
            return {
                selection: true,
                linklabel: 'recordCode',
                column: [
                    {
                        label: '记录编号',
                        prop: 'recordCode',
                        align: 'center',
                        overHidden: true,
                        slot: true
                    },
                    {
                        label: '设备编号',
                        prop: 'equipmentCode',
                        align: 'center',
                        overHidden: true,
                    },
                    {
                        label: '设备名称',
                        prop: 'equipmentName',
                        align: 'center',
                        overHidden: true,
                    },
                    {
                        label: '维修单位',
                        prop: 'repairOrg',
                        align: 'center',
                        overHidden: true,
                    },
                    {
                        label: '维修内容',
                        prop: 'content',
                        align: 'center',
                        overHidden: true,
                    },
                    {
                        label: '维修开始时间',
                        prop: 'repairDate',
                        align: 'center',
                        overHidden: true,
                    },
                    {
                        label: '维修结束时间',
                        prop: 'repairDateEnd',
                        align: 'center',
                        overHidden: true,
                    },
                    {
                        label: '状态',
                        prop: "eqRepairStatus",
                        type: 'select',
                        align: 'center',
                        dicUrl:"/api/sinoma-system/dict-biz/dictionary?code=eq_repair_status",
                        props: {
                            label: "dictValue",
                            value: "dictKey",
                        },
                    },
                ]
            }
        }
    },

    data () {
        return {
            searchColumns: [
                {
                    prop: "equipmentCode",
                    placeholder: "请输入设备编号",
                    span: 4,
                },
                {
                    prop: "equipmentName",
                    placeholder: "请输入设备名称",
                    span: 4,
                },
                {
                    prop: "content",
                    placeholder: "请输入维修内容",
                    span: 4,
                },
                {
                    prop: "eqRepairStatus",
                    placeholder: "请选择状态",
                    type: 'select',
                    dicUrl:"/api/sinoma-system/dict-biz/dictionary?code=eq_repair_status",
                    props: {
                        label: "dictValue",
                        value: "dictKey",
                    },
                    span: 4,
                },
                {
                    prop: "time",
                    startPlaceholder: "开始日期",
                    endPlaceholder: "结束日期",
                    span: 4,
                    type: 'daterange',
                },
            ],
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            query: {},
            selectionList: [],
        }
    },

    // created () {
    //     this.onLoad(this.page)
    // },

    methods: {
        init() {
            this.onLoad(this.page)
        },

        headAdd () {
            this.$router.push({
                path: '/equipmentFacilities/equipmentMaintenance/service/form',
                query: {
                    type: 'add'
                }
            })
        },

        onLoad (page,params={}){
            this.tableLoading = true
            this.page = page
            let queryObj = {...this.query}
            delete queryObj.time
            queryObj.orgId = this.userInfo.dept_id
            serviceRecordPage(page.currentPage, page.pageSize, Object.assign(params, queryObj)).then(res=>{
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.page.total = data.total
                this.tableData = data.records ? data.records.map(val=>{
                    val.status = val.status.toString()
                    return val
                }) : []
                this.tableLoading = false;
                // this.$refs.gridLayOut.selectionClear()
                this.$nextTick(() => {
                    this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
                    this.$refs.gridLayOut.$refs.grid.refreshTable();
                });
            })
        },

        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        // 搜索
        gridHeadSearch(query) {
            const data = this.query
            this.page.currentPage = 1;
            query.startTime = query.time ? query.time[0] : ''
            query.endTime = query.time ? query.time[1] : ''
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },
        // 重置
        gridHeadEmpty(query) {
            this.page.currentPage = 1;
            const data = this.query
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },

        selectChange (list) {
            this.selectionList = list
        },

        rowEdit (row) {
            this.$router.push({
                path: '/equipmentFacilities/equipmentMaintenance/service/form',
                query: {
                    row: JSON.stringify({ id: row.id }),
                    type: 'edit'
                }
            })
        },

        rowRemove (row){
            let data = {}
            if(!row){
                data = this.selectionList.find(val=> val.createUser != this.userInfo.user_id || val.eqRepairStatus == 1)
            }
            if(data&&data.id){
                this.$message.warning('非创建人与已完成记录不可删除')
            } else {
                let ids = row ? row.id : this.selectionList.map(val=>val.id).toString()
                if(ids){
                    this.$confirm("确认删除当前数据吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        serviceRecordRemove(ids).then((res) => {
                            if (res.data.code == 200) {
                                this.$message.success("删除成功")
                                this.onLoad(this.page);
                            }
                        });
                    }).catch((error) => {
                        this.$message({
                            message: "取消删除",
                            type: "info",
                        });
                    });
                } else {
                    this.$message.warning('请选择操作数据')
                }
            }

        },

        rowView (row) {
            this.$router.push({
                path: '/equipmentFacilities/equipmentMaintenance/service/form',
                query: {
                    row: JSON.stringify({ id: row.id }),
                    type: 'view'
                }
            })
        }
    }
}
</script>

<style>

</style>
