<template>
  <div class="checkTasks">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="我的任务" name="first"></el-tab-pane>
      <el-tab-pane label="检查记录" name="second"></el-tab-pane>
    </el-tabs>
    <div class="flex-container">
      <CommonTree v-show="activeName == 'second'" ref="commonTree" treeTitle="组织列表"
                  :treeData="treeData" :defaultProps="Props" @getNodeClick="handleNode" :isShowdig="false"
                  :showCheckbox="false" node-key="id"/>
      <div :class="activeName == 'second' ? 'calcWidth':'first'">
      <div class="checkTasks-box">
        <el-card class="box-card charts-icon" style="width: 15%;" :body-style="{
            display:'flex',
            height:'100%',
            justifyContent:'center',
            paddingTop: '10px !important'
          }">
          <img width="100%" height="100%" style="object-fit: contain"
               src="~@/assets/images/a7a12ef4b75ad850a723316eaf63bee.png"/>
        </el-card>
        <el-card class="box-card charts-icon" style="width: 15%;background-color: rgb(25, 144, 255);color: #ffffff"
                 :body-style="{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            height:'100%',
            paddingTop: '10px !important'
          }">
          <div class="text-align-center">
            <div class="text">已完成检查次数</div>
            <div class="num" style="margin-top: 10px;font-size: 24px">{{ cardObj['finish'] }}</div>
          </div>
        </el-card>
        <el-card class="box-card charts-icon" style="width: 15%;background-color: rgb(242, 121, 6);color: #ffffff"
                 :body-style="{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            height:'100%',
            paddingTop: '10px !important'
          }">
          <div class="text-align-center">
            <div class="text">已发现的问题数量</div>
            <div class="num" style="margin-top: 10px;font-size: 24px">{{ cardObj['problem'] }}</div>
          </div>
        </el-card>
        <el-card class="box-card charts-icon" style="width: 50%;background-color: rgb(25, 144, 255);color: #ffffff"
                 :body-style="{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            height:'100%',
            paddingTop: '10px !important'
          }">
          <div class="text-align-center" style="width: calc(100% / 5);" v-for="(item,index) in cardList">
            <div class="text">{{ item.name }}</div>
            <div class="num" style="margin-top: 10px;font-size: 24px">{{ cardObj[item.key] }}</div>
          </div>
        </el-card>
      </div>
        <head-layout @head-add="headAdd" @head-remove="headRemove" :head-btn-options="headBtnOptions"
                     head-title="隐患排查任务"></head-layout>
        <grid-head-layout ref="gridHeadLayout" :search-span="3" :search-columns="searchColumns" v-model="searchForm"
                          @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
        <grid-layout v-if="loadPage" ref="gridLayOut" :table-options="tableOption"
                     @grid-row-detail-click="rowEdit($event,'view')" :table-data="tableData"
                     :table-loading="tableLoading" @gird-handle-select-click="selectionChange" :data-total="page.total"
                     :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
          <template #customBtn="{row}">
            <el-button style="margin: 0 3px" v-if="row.taskStatus == 'UNFINISHED' && activeName == 'first'" type="text"
                       size="small" @click="rowEdit(row,'edit')">执行
            </el-button>
            <el-button style="margin: 0 3px" v-if="row.taskStatus !== 'UNFINISHED' || activeName == 'second'"
                       type="text" size="small" @click="rowEdit(row,'view')">查看
            </el-button>
          </template>
        </grid-layout>
      </div>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import * as  API from "@/api/check/checkTasks";
import checklist from "./checklist";
import CommonTree from "@/views/components/com_tree/index.vue";
import {getDeptTree} from "@/api/system/dept";

export default {
  mixins: [checklist],
  components: {
    CommonTree,
    GridLayout,
    HeadLayout
  },
  computed: {
    headBtnOptions() {
      let result = [];
      result.push({
        label: "保存",
        emit: "head-add",
        type: "button",
        btnOptType: "add",
      }, {
        label: "删除",
        emit: "head-remove",
        type: "button",
        btnOptType: "remove",
      });
      return result;
    },
  },
  data() {
    return {
      searchForm:{},
      Props: {
        children: "children",
        label: "title",
      },
      query: {},
      loadPage: true,
      activeName: 'first',
      cardList: [
        {key: 'unassess', name: '待评估'},
        {key: 'unrectify', name: '待整改'},
        {key: 'unaccept', name: '待验收'},
        {key: 'canceled', name: '评估未通过'},
        {key: 'finished', name: '已完成'},
      ],
      cardObj: {},
      treeData: [],
      selectionList: [],
    }
  },
  mounted() {
    this.handleClick();
    this.getPrjstructureList();
  },
  methods: {
    //项目组织架构
    getPrjstructureList() {
      const parentId = this.userInfo.dept_id;
      getDeptTree('', '', parentId).then((res) => {
        if ((res.data.code = 200)) {
          this.treeData = res.data.data
          this.$refs.commonTree.setCurrentKey(this.treeData[0].id)
          this.treeNode=this.treeData[0]
        }
      });
    },
    handleNode(node) {
      this.treeNode = node
      this.onLoad(this.page, {});
    },
    headAdd() {
      this.$router.push({
        path: `/hiddenTrouble/check/checkTasks/edit`,
        query: {
          type: 'add',
        }
      })
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("确定将选择数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API.remove(this.ids).then(() => {
            this.onLoad(this.page, {});
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.selectionClear();
          });
        })
        .catch(() => {
        });
    },
    handleClick() {
      this.searchForm={}
      this.loadPage = false
      if (this.activeName == 'first') {
        this.tableOption.column[3].hide = false
        this.tableOption.column[4].hide = false
      } else {
        this.tableOption.column[3].hide = true
      }
      this.$nextTick(() => {
        this.loadPage = true
      })
      this.onLoad(this.page);
      this.getCount()
    },
    getCount() {
      API.getCount({
        organizationId: this.userInfo.dept_id,
        userName: this.activeName == 'first' ? this.userInfo.account : ''
      }).then(res => {
        this.cardObj = res.data.data
      })
    },
    rowEdit(row, type) {
      this.$router.push({
        path: `/hiddenTrouble/check/checkTasks/edit`,
        query: {
          actId: row.actId,
          id: row.id,
          type,
        }
      })
    },

    // 搜索
    gridHeadSearch() {
      let searchForm={
        ...this.searchForm
      }
      if (searchForm.time) {
        searchForm.startTime = searchForm.time[0] + ' 00:00:00'
        searchForm.endTime = searchForm.time[1] + ' 23:59:59'
      } else {
        searchForm.startTime = ''
        searchForm.endTime = ''
      }

      delete searchForm.time
      this.page.currentPage = 1;
      this.query = searchForm;
      this.onLoad();
    },
    // 清空
    gridHeadEmpty() {
      this.page.currentPage = 1;
      this.query = {};
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad();
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page || this.page;
      this.tableLoading = true;
      API.getList(this.page.currentPage, this.page.pageSize, {
        ...params, organizationId: this.activeName == 'first' ? this.userInfo.dept_id : this.treeNode.id,
        userName: this.activeName == 'first' ? this.userInfo.account : '', ...this.query
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      }).catch(err=>{
        this.tableLoading = false;
      })
    },
  }
}

</script>
<style lang="scss" scoped>
.checkTasks{
  .calcWidth{
    width: calc(100% - 280px);
  }
  .first{
    width: 100%;
  }
  .checkTasks-box {
    justify-content: space-between;
    background-color: #ffffff;
    padding: 10px 12px;
    display: flex;
    .charts-icon {
      width: calc(100% / 8 - 20px);
      height: 100px;
      background: #ffffff;
      border: 1px solid rgb(204, 204, 204);
      border-top: 0;
    }

    .no-border-right {
      border-right: 0;
    }
  }
}
::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 260px) !important;
}
::v-deep .avue-crud .el-table {
  //height: calc(100vh - 432px) !important;
  //max-height: calc(100vh - 432px) !important;
}
::v-deep .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}

::v-deep .el-tabs__header {
  margin: 0;
  background: #ffffff;
}

::v-deep .el-tabs__content {
  padding: 0 !important;
}
</style>
