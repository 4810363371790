var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "JNPF-common-layout" },
    [
      _c(
        "div",
        { staticClass: "JNPF-common-layout-center" },
        [
          _c(
            "el-row",
            { staticClass: "JNPF-common-search-box", attrs: { gutter: 16 } },
            [
              _c(
                "el-form",
                {
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键词" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入关键词查询",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search()
                              },
                            },
                            model: {
                              value: _vm.keyword,
                              callback: function ($$v) {
                                _vm.keyword = $$v
                              },
                              expression: "keyword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "日期" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "picker-options": _vm.pickerOptions,
                              "value-format": "timestamp",
                              clearable: "",
                              editable: false,
                            },
                            model: {
                              value: _vm.pickerVal,
                              callback: function ($$v) {
                                _vm.pickerVal = $$v
                              },
                              expression: "pickerVal",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.search()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.search"))
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-refresh-right" },
                              on: {
                                click: function ($event) {
                                  return _vm.refresh()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("common.reset")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "JNPF-common-layout-main JNPF-flex-main" },
            [
              _c("div", { staticClass: "JNPF-common-head" }, [
                _c(
                  "div",
                  [
                    _c("topOpts", {
                      attrs: { addText: "新建订单" },
                      on: {
                        add: function ($event) {
                          return _vm.addOrUpdateHandle()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "JNPF-common-head-right" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("common.refresh"),
                          placement: "top",
                        },
                      },
                      [
                        _c("el-link", {
                          attrs: {
                            icon: "icon-ym icon-ym-Refresh JNPF-common-head-icon",
                            underline: false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.initData()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "JNPF-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: { data: _vm.list, hasNO: false },
                  on: {
                    "expand-change": _vm.expandChange,
                    "sort-change": _vm.sortChange,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand", width: "40" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "el-tabs",
                              {
                                model: {
                                  value: props.row.activeName,
                                  callback: function ($$v) {
                                    _vm.$set(props.row, "activeName", $$v)
                                  },
                                  expression: "props.row.activeName",
                                },
                              },
                              [
                                _c(
                                  "el-tab-pane",
                                  { attrs: { label: "订单商品" } },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        attrs: {
                                          data: props.row.childTable,
                                          stripe: "",
                                          size: "mini",
                                          "show-summary": "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "goodsName",
                                            label: "商品名称",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "specifications",
                                            label: "规格型号",
                                            width: "80",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "unit",
                                            label: "单位",
                                            width: "80",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "qty",
                                            label: "数量",
                                            width: "80",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "price",
                                            label: "单价",
                                            width: "80",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "amount",
                                            label: "金额",
                                            width: "80",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "discount",
                                            label: "折扣%",
                                            width: "80",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "cess",
                                            label: "税率%",
                                            width: "80",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "actualPrice",
                                            label: "实际单价",
                                            width: "80",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "actualAmount",
                                            label: "实际金额",
                                            width: "80",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-tab-pane",
                                  { attrs: { label: "收款计划" } },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        attrs: {
                                          data: props.row.childTable1,
                                          stripe: "",
                                          size: "mini",
                                          "show-summary": "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "receivableDate",
                                            label: "收款日期",
                                            formatter: _vm.jnpf.tableDateFormat,
                                            width: "120",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "receivableRate",
                                            label: "收款比率%",
                                            width: "100",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "receivableMoney",
                                            label: "收款金额",
                                            width: "100",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "receivableMode",
                                            label: "收款方式",
                                            width: "100",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "abstract",
                                            label: "收款摘要",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "50",
                      label: "序号",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderCode",
                      label: "订单编码",
                      width: "150",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderDate",
                      label: "订单日期",
                      width: "120",
                      sortable: "custom",
                      formatter: _vm.jnpf.tableDateFormat,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customerName",
                      label: "客户名称",
                      width: "220",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "salesmanName",
                      label: "业务人员",
                      width: "120",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "receivableMoney",
                      label: "付款金额",
                      width: "100",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creatorUser",
                      label: "制单人员",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "description",
                      label: "订单备注",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "currentState",
                      label: "状态",
                      width: "100",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.currentState == 1
                              ? _c("el-tag", [_vm._v("等待审核")])
                              : scope.row.currentState == 2
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("审核通过"),
                                ])
                              : scope.row.currentState == 3
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("审核退回"),
                                ])
                              : scope.row.currentState == 4
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("流程撤回"),
                                ])
                              : scope.row.currentState == 5
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("审核终止"),
                                ])
                              : _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("等待提交"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "150", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "btn_edit",
                                    expression: "'btn_edit'",
                                  },
                                ],
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  disabled:
                                    [1, 2, 4, 5].indexOf(
                                      scope.row.currentState
                                    ) > -1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addOrUpdateHandle(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("编辑\n            ")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "btn_remove",
                                    expression: "'btn_remove'",
                                  },
                                ],
                                staticClass: "JNPF-table-delBtn",
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  disabled:
                                    [1, 2, 3, 5].indexOf(
                                      scope.row.currentState
                                    ) > -1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDel(
                                      scope.$index,
                                      scope.row.id
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除\n            ")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "btn_flowDetail",
                                    expression: "'btn_flowDetail'",
                                  },
                                ],
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  disabled: !scope.row.currentState,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toApprovalDetail(
                                      scope.row.id,
                                      scope.row.currentState
                                    )
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.currentPage,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "currentPage", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.initData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.formVisible
        ? _c("FlowBox", { ref: "Form", on: { close: _vm.closeForm } })
        : _vm._e(),
      _c("Detail", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.detailVisible,
            expression: "detailVisible",
          },
        ],
        ref: "detail",
        on: {
          close: function ($event) {
            _vm.detailVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }