<template>
  <div>
    <el-dialog
      top="10vh"
      :title="title"
      :visible.sync="show"
      width="70%"
      :before-close="handleClose"
      >
      <el-table
        v-if="show"
        :data="tableData"
        border
        size="medium"
        height="70vh"
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          label="排名"
          width="80">
        </el-table-column>
        <el-table-column
          prop="companyName"
          align="center"
          label="公司">
        </el-table-column>
        <el-table-column
          :prop="'_value' + (index != 0?index:'')"
          align="center"
          :label="item"
          v-for="(item,index) in tableName"
          :key="index"
        >
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import {appIndexChild} from "@/api/user";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      show: false,
      title: '',
      year: '',
      tableData:[]
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.year = new Date().getFullYear();
  },
  methods: {
    getTable(code,tableName) {
      this.tableName = tableName;
      appIndexChild({
        indexCode: code,
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
        }
      })
    },
    handleClose() {
      this.tableData = [];
      this.show = false;
    }
  }
}
</script>
