var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "人员清单",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-romve": _vm.headRomve, "head-add": _vm.headAdd },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOption,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "新增人员",
            visible: _vm.dialogVisible,
            "before-close": _vm.handleCancel,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员名称", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请选择人员" },
                    on: { focus: _vm.handleSelectPerson },
                    model: {
                      value: _vm.form.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "部门组织", prop: "userOrgName" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "选择人员后自动带出" },
                    model: {
                      value: _vm.form.userOrgName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userOrgName", $$v)
                      },
                      expression: "form.userOrgName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "岗位职责", prop: "duty" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "200",
                      placeholder: "请填写岗位职责",
                    },
                    model: {
                      value: _vm.form.duty,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "duty", $$v)
                      },
                      expression: "form.duty",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.handleCancel },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dLoading,
                      expression: "dLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.peopleShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.peopleShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            attrs: { "dept-category": [2, 5] },
            on: { "select-data": _vm.selectPeopleData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }