<template>
  <div style="position: relative">
    <div>
      <!-- 黑名单人员 -->
      <head-layout
        head-title="黑名单人员"
        :head-btn-options="headBtnOptions"
        @head-add="handleAdd"
        @head-export="handleExport"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template slot="birthday" slot-scope="{ row }">
          <span>
            {{ row.idCoding && row.idCoding != "" ? getAge(row.idCoding) : "" }}
          </span>
        </template>
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowUpdate(row)"
            >编辑
          </el-button>
        </template>
      </grid-layout>
    </div>
    <blackPersonnelDialog
      ref="blackPersonnelDialog"
      @personRowSelect="personRowSelect"
    ></blackPersonnelDialog>
  </div>
</template>

  <script>
import { getList } from "@/api/block/personnel";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import blackPersonnelDialog from "@/views/components/ThirdPersonDialog/personnel";
import { getAgeFromIdCard } from "@/util/util";
import { exportBlob } from "@/api/common";
import { dateNow } from "@/util/date";
import { getToken } from "@/util/auth";
import { downloadXls } from "@/util/util";
import {fieldInfo} from "@/api/base/length"
export default {
  components: {
    blackPersonnelDialog,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      form: {},
      query: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        linklabel: "userName",
        column: [
          {
            label: "姓名",
            prop: "userName",
            minWidth: fieldInfo.name_person.length,
            align: fieldInfo.name_person.align,
            overHidden: true,
          },
          {
            label: "身份证号码",
            prop: "idCoding",
            minWidth: fieldInfo.IdentificationNumber.length,
            align: fieldInfo.IdentificationNumber.align,
            overHidden: true,
          },
          {
            label: "性别",
            prop: "sex",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
            minWidth: fieldInfo.Words4.length,
            align: fieldInfo.Words4.align,
            overHidden: true,
          },
          {
            label: "年龄",
            prop: "birthday",
            minWidth: fieldInfo.Words4.length,
            align: fieldInfo.Words4.align,
            overHidden: true,
            slot: true,
          },
          {
            label: "籍贯",
            prop: "domicile",
            minWidth: fieldInfo.fullAddress.length,
            align: fieldInfo.fullAddress.align,
            overHidden: true,
          },
          {
            label: "工种",
            prop: "post",
            minWidth: fieldInfo.Words4.length,
            align: fieldInfo.Words4.align,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "blState",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'number',
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=prj_state",
            minWidth: fieldInfo.Words4.length,
            align: fieldInfo.Words4.align,
            overHidden: true,
          },
          {
            label: "维护日期",
            prop: "createTime",
            minWidth: fieldInfo.datetime.length,
            align: fieldInfo.datetime.align,
            overHidden: true,
          },
          {
            label: "更新日期",
            prop: "updateTime",
            minWidth: fieldInfo.datetime.length,
            align: fieldInfo.datetime.align,
            overHidden: true,
          },
        ],
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "导出",
          emit: "head-export",
          type: "button",
          icon: "",
          btnOptType: "export",
        },
      ],
      searchColumns: [
        {
          label: "",
          prop: "condition",
          span: 4,
          placeholder: "姓名/籍贯/身份证号码",
        },
        {
          label: "更新日期",
          prop: "updateTime",
          span: 4,
          type: "date",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd HH:mm:ss",
          placeholder: "请选择更新日期",
        },
        {
          label: "状态",
          prop: "blState",
          span: 4,
          type: "select",
          placeholder: "请选择状态",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_state`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.thirdcompany_add, false),
        viewBtn: this.vaildData(this.permission.thirdcompany_view, false),
        delBtn: this.vaildData(this.permission.thirdcompany_delete, false),
        editBtn: this.vaildData(this.permission.thirdcompany_edit, false),
      };
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    // 人员选择
    personRowSelect(row) {
      this.$router.push({
        path: `/safe/blacklistPersonnel/index/add`,
        query: {
          type: "add",
          row: JSON.stringify(row),
        },
      });
    },
    getAge(data) {
      return getAgeFromIdCard(data);
    },
    handleAdd() {
      this.$refs.blackPersonnelDialog.init("add");
    },
    rowUpdate(row) {
      this.$router.push({
        path: `/safe/blacklistPersonnel/index/add`,
        query: {
          type: "edit",
          row: JSON.stringify(row),
        },
      });
    },
    //查看
    rowView(row) {
      this.$router.push({
        path: `/safe/blacklistPersonnel/index/add`,
        query: {
          type: "view",
          row: JSON.stringify(row),
        },
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    // 导出
    handleExport() {
      this.$confirm("是否导出黑名单人员数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        exportBlob(
          `/api/sinoma-hse-prj/prjblacklistuserhead/export?condition=${
            this.$refs.gridHeadLayout.searchForm.condition
              ? this.$refs.gridHeadLayout.searchForm.condition
              : ""
          }&updateTime=${
            this.$refs.gridHeadLayout.searchForm.updateTime
              ? this.$refs.gridHeadLayout.searchForm.updateTime
              : ""
          }&${this.website.tokenHeader}=${getToken()}`
        ).then((res) => {
          downloadXls(res.data, `黑名单人员${dateNow()}.xlsx`);
        });
      });
    },
  },
};
</script>

  <style>
</style>
