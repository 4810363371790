var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "JNPF-dialog JNPF-dialog_center",
      attrs: {
        title: "选择商品",
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        "append-to-body": "",
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        { staticClass: "JNPF-common-search-box", attrs: { gutter: 16 } },
        [
          _c(
            "el-form",
            {
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键词" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入关键词查询",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.init()
                          },
                        },
                        model: {
                          value: _vm.keyword,
                          callback: function ($$v) {
                            _vm.keyword = $$v
                          },
                          expression: "keyword",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.init()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("common.search")) + "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh-right" },
                          on: {
                            click: function ($event) {
                              return _vm.refresh()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("common.reset")) + "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "JNPF-common-search-box-right" },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("common.refresh"),
                    placement: "top",
                  },
                },
                [
                  _c("el-link", {
                    attrs: {
                      icon: "icon-ym icon-ym-Refresh JNPF-common-head-icon",
                      underline: false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.init()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "JNPF-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.list, hasC: "", border: false },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "text", label: "商品名称", width: "200" },
          }),
          _c("el-table-column", { attrs: { prop: "code", label: "商品编码" } }),
          _c("el-table-column", {
            attrs: { prop: "specifications", label: "规格型号" },
          }),
          _c("el-table-column", { attrs: { prop: "unit", label: "单位" } }),
          _c("el-table-column", { attrs: { prop: "price", label: "售价" } }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.cancelButton")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.select()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.confirmButton")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }