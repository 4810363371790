<template>
  <div class="prj-index">
    <div class="home-statistics">
      <el-card shadow="always" class="todo-items card-item">
        <div class="svg-icon">
          <svg
            width="27"
            height="25"
            viewBox="0 0 27 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.468 0.896693L1.22166 6.24056C0.814833 6.41808 0.823811 6.99822 1.23594 7.16307L13.4971 12.0675C13.7397 12.1646 14.0106 12.1628 14.2519 12.0627L26.061 7.16074C26.4651 6.99302 26.4741 6.42393 26.0756 6.24347L14.2805 0.902278C14.0226 0.785528 13.7274 0.783498 13.468 0.896693Z"
              fill="white"
            />
            <path
              d="M2.30545 10.2606C2.42803 10.2071 2.56693 10.205 2.69111 10.2547L13.6665 14.6448C13.7878 14.6933 13.9233 14.6925 14.0439 14.6424L24.601 10.2602C24.7288 10.2071 24.8729 10.2094 24.999 10.2665L26.0558 10.7451C26.4543 10.9255 26.4453 11.4946 26.0413 11.6624L14.0439 16.6424C13.9233 16.6925 13.7878 16.6933 13.6665 16.6448L1.21616 11.6647C0.804037 11.4998 0.79506 10.9197 1.20189 10.7422L2.30545 10.2606Z"
              fill="white"
            />
            <path
              d="M2.30545 14.3041C2.42803 14.2506 2.56693 14.2484 2.69111 14.2981L13.6665 18.6883C13.7878 18.7368 13.9233 18.7359 14.0439 18.6858L24.601 14.3036C24.7288 14.2506 24.8729 14.2529 24.999 14.31L26.0558 14.7885C26.4543 14.969 26.4453 15.5381 26.0413 15.7058L14.0439 20.6858C13.9233 20.7359 13.7878 20.7368 13.6665 20.6883L1.21616 15.7081C0.804037 15.5433 0.79506 14.9632 1.20189 14.7856L2.30545 14.3041Z"
              fill="white"
            />
            <path
              d="M2.30545 18.3475C2.42803 18.294 2.56693 18.2919 2.69111 18.3416L13.6665 22.7317C13.7878 22.7802 13.9233 22.7794 14.0439 22.7293L24.601 18.3471C24.7288 18.294 24.8729 18.2963 24.999 18.3534L26.0558 18.832C26.4543 19.0125 26.4453 19.5815 26.0413 19.7493L14.0439 24.7293C13.9233 24.7794 13.7878 24.7803 13.6665 24.7317L1.21616 19.7516C0.804037 19.5867 0.79506 19.0066 1.20189 18.8291L2.30545 18.3475Z"
              fill="white"
            />
          </svg>
        </div>

        <div class="card">
          <div class="card-title">待办事项</div>
          <div class="card-content">
            <div class="num-box">
              <span class="underline-text num" @click="runTodo">{{
                todoNum
              }}</span
              ><span class="unit">项</span>
            </div>
          </div>
        </div>
      </el-card>
      <el-card shadow="always" class="security-inspection card-item">
        <div class="svg-icon">
          <svg
            t="1726190291733"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="26546"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="27"
            height="25"
          >
            <path
              d="M867.584 160.192c-149.632-16.928-262.208-57.408-334.592-120.352l-19.04-16.544-20.544 14.656C379.968 118.944 267.776 160 160 160H128v448c0 137.344 121.088 261.92 370.208 380.864l13.088 6.24 13.344-5.728C771.072 883.52 896 755.232 896 608V163.424l-28.416-3.232zM832 608c0 116.8-107.392 223.36-319.328 316.8C299.872 821.024 192 714.464 192 608V222.976c104.672-6.784 211.584-46.688 318.496-118.944C587.232 162.528 695.168 201.536 832 220.256V608z"
              p-id="26547"
              fill="#ffffff"
            ></path>
            <path
              d="M359.776 468.672a32 32 0 1 0-47.968 42.4l121.792 137.824c12.608 14.24 30.176 21.568 47.904 21.568a64.384 64.384 0 0 0 49.696-23.52l197.6-242.72a32 32 0 0 0-49.632-40.416l-197.6 242.688-121.792-137.824z"
              p-id="26548"
              fill="#ffffff"
            ></path>
          </svg>
        </div>
        <div class="card">
          <div class="card-title">安全巡检</div>
          <div class="card-content">
            <div class="num-box">
              <span class="underline-text num">{{
                safeStatis.projectCount || 0
              }}</span
              ><span class="unit">次</span>
            </div>
            <div class="card-b-tips">
              <div class="tips-1">
                今日巡检：{{ safeStatis.todayCount || 0 }}次
              </div>
              <div class="split-line">|</div>
              <div class="tips-2">人员：{{ safeStatis.userCount || 0 }}人</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card shadow="always" class="safety-training card-item">
        <div class="svg-icon">
          <svg
            t="1726189740886"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5979"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="27"
            height="25"
          >
            <path
              d="M938.666667 786.346667c0 14.933333-11.946667 26.88-26.453334 26.88h-197.973333c14.933333-3.413333 26.026667-16.64 26.026667-32.426667 0 0 0-122.026667-88.32-166.4-37.546667-18.773333-40.106667-15.36-58.88-19.626667l0.426666-52.906666s-20.48-15.786667-26.88-65.706667c-12.8 3.84-17.066667-15.36-17.92-27.306667-0.853333-11.52-7.253333-48.213333 8.106667-44.8-3.413333-24.32-5.546667-46.506667-4.266667-58.026666 3.84-40.96 43.093333-83.626667 103.253334-87.04 70.826667 2.986667 98.56 46.08 102.826666 87.04 1.28 11.52-1.28 33.706667-4.693333 58.026666 15.786667-3.413333 8.96 33.28 8.106667 44.8-0.853333 11.946667-5.12 31.146667-17.92 27.306667-6.4 49.92-26.88 65.706667-26.88 65.706667l0.426666 52.906666s12.8 7.68 67.84 30.72 37.546667 5.973333 82.346667 28.16c70.826667 34.986667 70.826667 132.693333 70.826667 132.693334z"
              fill="#ffffff"
              opacity=".99"
              p-id="5980"
            ></path>
            <path
              d="M539.733333 605.866667c60.586667 25.173333 41.386667 6.4 90.88 31.146666 77.653333 39.253333 77.653333 146.773333 77.653334 146.773334 0 16.213333-13.226667 29.44-29.013334 29.44H114.346667c-16.213333 0-29.013333-13.226667-29.013334-29.44 0 0 0-107.52 77.653334-146.773334 49.066667-24.746667 30.293333-4.693333 90.453333-29.866666 60.586667-25.173333 74.666667-34.133333 74.666667-34.133334l0.426666-58.453333s-22.613333-17.493333-29.44-72.533333c-14.08 4.266667-18.773333-16.64-19.626666-30.293334-0.853333-12.8-8.106667-52.906667 8.96-49.493333-3.413333-26.88-5.973333-51.2-4.693334-64 4.266667-44.8 47.36-92.16 113.493334-95.573333 77.653333 3.413333 108.373333 50.346667 113.066666 95.573333 1.28 12.8-1.28 37.12-5.12 64 17.066667-3.413333 9.813333 36.693333 8.96 49.493333-0.853333 13.226667-5.546667 34.133333-19.626666 29.866667-7.253333 54.613333-29.866667 72.106667-29.866667 72.106667l0.426667 58.026666c0.426667 0.853333 14.506667 8.96 74.666666 34.133334z"
              fill="#ffffff"
              opacity=".99"
              p-id="5981"
            ></path>
          </svg>
        </div>
        <div class="card">
          <div class="card-title">安全培训率</div>
          <div class="card-content">
            <div class="num-box">
              <span class="underline-text num">{{
                safeStatistics[1]
                  ? ((safeStatistics[1] / safeStatistics[3]) * 100).toFixed(2) +
                    "%"
                  : "0%"
              }}</span
              ><span class="unit">合格率</span>
            </div>
            <div class="card-b-tips">
              <div class="tips-1">已合格：{{ safeStatistics[1] || 0 }}人</div>
              <div class="split-line">|</div>
              <div class="tips-2">未合格：{{ safeStatistics[2] || 0 }}人</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card shadow="always" class="safe-production card-item">
        <div class="svg-icon">
          <svg
            t="1726189969135"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="12554"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="27"
            height="25"
          >
            <path
              d="M30.552 791.273c4.654 16.29 23.272 23.272 37.236 18.618 16.291-4.655 23.273-23.273 18.618-37.236C16.588 581.818 84.08 374.69 237.68 256c11.636-9.31 23.273-16.29 37.236-25.6 25.6-16.29 53.528-30.255 83.782-39.564 58.182-20.945 118.691-30.254 176.873-27.927h25.6c6.982 0 11.636 0 18.618 2.327 30.255 4.655 58.182 11.637 88.436 23.273 235.055 86.11 356.073 346.764 269.964 581.818-4.654 16.291 2.327 32.582 18.618 37.237 16.291 4.654 32.582-2.328 37.237-18.619 41.89-116.363 39.563-239.709 0-349.09h2.327C921.897 239.709 738.043 111.709 537.897 102.4h-48.873c-200.145 9.31-384 137.31-458.472 337.455-39.564 109.381-41.891 232.727 0 351.418z m707.49-386.328a29.498 29.498 0 0 0-41.89 0L521.606 579.491c-4.654-2.327-9.309-2.327-13.963-2.327-32.582 0-58.182 25.6-58.182 58.181s25.6 58.182 58.182 58.182 58.181-25.6 58.181-58.182c0-4.654 0-9.309-2.327-16.29l174.546-174.546c11.636-11.636 11.636-30.254 0-39.564z m228.073 458.473H58.48c-16.29 0-30.255 13.964-30.255 30.255 0 16.29 13.964 30.254 30.255 30.254h907.636c16.291 0 30.255-13.963 30.255-30.254 0-16.291-13.964-30.255-30.255-30.255z"
              p-id="12555"
              fill="#ffffff"
            ></path>
          </svg>
        </div>
        <div class="card">
          <div class="card-title">安全生产工时</div>
          <div class="card-content">
            <div class="num-box">
              <span class="underline-text num">{{
                statistics.produceWorkingDays * 24
              }}</span
              ><span class="unit">工时</span>
            </div>
            <div class="card-b-tips">
              <div class="tips-1">
                安全生产：{{ statistics.produceWorkingDays }}天
              </div>
              <div class="split-line">|</div>
              <div class="tips-2">距完工：{{ statistics.finishDay }}天</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card shadow="always" class="hazard-source-warning card-item">
        <div class="svg-icon">
          <svg
            t="1726190258300"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="24524"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="27"
            height="25"
          >
            <path
              d="M540.444 976.593c-195.318 0-352.71-157.393-352.71-352.712 0-79.644 36.029-182.044 113.777-221.866 5.689-3.793 11.378-1.896 17.067 0 5.689 3.792 9.481 9.481 9.481 15.17 0 0 7.585 73.956 39.822 128.948v-3.792C364.09 424.77 358.4 204.8 585.956 117.57c5.688-1.897 13.274-1.897 17.066 1.896 5.69 3.792 7.585 9.481 7.585 15.17v17.067c0 121.363 0 208.592 41.719 284.444 11.378-58.785 51.2-117.57 138.43-142.222 5.688-1.896 11.377 0 17.066 3.793 5.69 3.792 7.585 9.481 7.585 15.17 0 75.852 20.86 125.155 41.719 172.563 18.963 41.718 36.03 81.54 36.03 136.533 0 197.215-159.29 354.608-352.712 354.608zM293.926 451.319C248.415 491.14 223.763 563.2 223.763 623.88c0 174.46 140.326 314.786 314.785 314.786 174.46 0 314.785-140.326 314.785-314.786 0-45.51-15.17-81.54-34.133-121.362-18.963-43.615-41.719-91.023-45.511-161.186-96.711 39.823-91.022 132.741-89.126 151.704 1.896 7.585-3.793 17.067-11.378 18.963-7.585 3.793-17.066 1.896-22.755-5.689-79.645-92.918-81.541-199.111-81.541-343.23-176.356 83.438-170.667 265.482-166.874 377.363 0 22.756 1.896 43.615 0 58.786 0 7.585-5.69 13.274-11.378 15.17-5.689 1.896-13.274 1.896-18.963-1.896-47.407-39.823-68.267-117.57-77.748-161.185z"
              fill="#ffffff"
              p-id="24525"
            ></path>
          </svg>
        </div>
        <div class="card">
          <div class="card-title">危险源预警</div>
          <div class="card-content">
            <div class="num-box">
              <span class="underline-text num">{{
                statistics.thisMonthNum || 0
              }}</span
              ><span class="unit">项(本月发布)</span>
            </div>
            <div class="card-b-tips">
              <div class="tips-1">已辨识：{{ statistics.riskNum || 0 }}项</div>
              <div class="split-line">|</div>
              <div class="tips-2">
                累计发布：{{ statistics.totalPublish || 0 }}项
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="home-line-content">
      <div class="echarts block-box">
        <div class="title">
          <div class="icon"></div>
          <div class="text">一岗双责执行统计</div>
        </div>
        <template v-if="hiddenDangerObj.reportList.length">
          <div class="content" id="dual-echarts"></div>
        </template>
        <el-empty class="content" description="暂无数据" v-else></el-empty>
      </div>
      <div class="echarts block-box">
        <div class="title">
          <div class="icon"></div>
          <div class="text">安全隐患分析</div>
        </div>
        <div
          class="content"
          id="hazards-echarts"
          v-if="projectHiddenStatisticDTOList.length"
        ></div>
        <el-empty class="content" description="暂无数据" v-else></el-empty>
      </div>
    </div>
    <div class="home-line-content">
      <div class="echarts block-box">
        <div class="title">
          <div class="icon"></div>
          <div class="text">进场人员统计</div>
        </div>
        <div
          class="content"
          id="personnel-echarts"
          v-if="inCompanyStatistical.length"
        ></div>
        <el-empty class="content" description="暂无数据" v-else></el-empty>
      </div>
      <div class="pre-class-list block-box">
        <div class="title">
          <div class="icon"></div>
          <div class="text">班前会及交底</div>
        </div>
        <div class="class-list content" v-if="!classList.length">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div class="class-list content" v-else>
          <div class="list-item" v-for="item in classList" :key="item.id">
            <el-image
              style="width: 260px; height: 120px; flex-shrink: 0"
              :src="item.filesUser[0].link || ''"
              @click="showFile(item.filesUser[0].link)"
              :fit="fit"
            ></el-image>
            <div class="list-content">
              <!-- <div class="meetingTitle">
                {{ item.title }}
              </div> -->
              <div class="meetingContents">
                {{ item.meetingContents }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ViewImage ref="viewImage"></ViewImage>
  </div>
</template>

<script>
import {
  getPreClassList,
  getProjectStatistical,
  getStatis,
  getSafeStatis,
} from "@/api/home/index_prj.js";
import { sinomatodo } from "@/api/plugin/workflow/process";
import { hiddenDanger } from "@/api/safetyAndEnvironmentalProtection/oneJob";
import { getMyTaskList } from "@/api/system/myTasks";
import { mapGetters } from "vuex";
import ViewImage from "@/views/business/components/viewImage";
export default {
  components: {
    ViewImage,
  },
  data() {
    return {
      statistics: { produceWorkingDays: 0, finishDay: 0 }, // 统计数据集
      classList: [], // 班前会列表
      safeStatistics: { 1: 0, 2: 0, 3: 0 }, // 安全巡检，培训率
      todoNum: 0,
      safeStatis: {},
      projectHiddenStatisticDTOList: [],
      inCompanyStatistical: [],
      hiddenDangerObj: {
        reportList: [],
      },
      basicOption: {
        grid: {
          top: "20px",
          left: "30px",
          bottom: "20px",
          right: 0,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLine: {
            lineStyle: {
              color: ["#E8E8E8"],
            },
          },
          axisPointer: {
            type: "shadow",
          },
        },
        yAxis: {
          type: "value",
          // min:3,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        tooltip: {
          show: true,
          formatter: "{a0}: {c0}<br />{a1}: {c1}",
          trigger: "axis",
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 25,
          },
          {
            data: [],
            type: "bar",
            barWidth: 25,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo", "openChatState", "themeColor"]),
  },
  created() {
    this.getClassList();
    this.getProjectStatistical();
    this.getStatis();
    this.getToDoStatis();
    this.renderdualEcharts();
    this.getSafeStatis();
  },
  methods: {
    runTodo() {
      this.$router.push({
        path: `/plugin/workflow/process/todo`,
      });
    },
    showFile(link) {
      this.$refs.viewImage.showFile(link);
      //   this.fileUrl =
      //     process.env.VUE_APP_BASE_KKFILEURL +
      //     "?url=" +
      //     encodeURIComponent(Base64.encode(link));
      //   this.showFileDialog = true;
      //   //exls表格预览隐藏打印按钮
      //   if (process.env.NODE_ENV === "production") {
      //     const iframe = this.$refs.fileIframe;
      //     const iframeDocument =
      //       iframe.contentDocument || iframe.contentWindow.document;
      //     // 在 iframe 内部隐藏元素
      //     if (iframeDocument) {
      //       const elementToHide = iframeDocument.getElementById("button-area");
      //       if (elementToHide) {
      //         elementToHide.style.display = "none";
      //       }
      //     }
      //   }
    },
    // 班前会
    getClassList() {
      getPreClassList({
        current: 1,
        size: 5,
        createDept: this.userInfo.company_id || this.userInfo.dept_id,
      }).then((res) => {
        let tempArr = res.data.data.records || [];
        tempArr.forEach((item) => {
          item.filesUser = JSON.parse(item.filesUser);
        });
        this.classList = tempArr;
      });
    },
    getSafeStatis() {
      getSafeStatis({
        companyId: this.userInfo.company_id || this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.data.length) {
          this.safeStatis = res.data.data[0] || {};
        }
      });
    },
    // 安全统计4，5，7，8
    getProjectStatistical() {
      getProjectStatistical({
        projectId: this.userInfo.company_id || this.userInfo.dept_id,
      }).then((res) => {
        console.log(res);
        this.statistics = res.data.data;
        this.inCompanyStatistical = this.statistics.inCompanyStatistical;
        this.projectHiddenStatisticDTOList =
          this.statistics.projectHiddenStatisticDTOList;
        let inCompanyOptionData = [];
        this.inCompanyStatistical.map((item) => {
          inCompanyOptionData.push({
            name: item.inCompanyName,
            value: item.inCompanyUserNum,
          });
        });
        this.renderinCompanyEcharts(inCompanyOptionData);
        this.renderHazardsEcharts(this.projectHiddenStatisticDTOList);
      });
    },
    // 渲染一岗双责echarts
    renderdualEcharts() {
      hiddenDanger({
        orgId: this.userInfo.company_id || this.userInfo.dept_id,
      }).then((res) => {
        let data = res.data.data;
        this.hiddenDangerObj = data;
        this.$nextTick(() => {
          var myChart = this.$echarts.init(
            document.getElementById("dual-echarts")
          );
          var option = JSON.parse(JSON.stringify(this.basicOption));
          option.xAxis.data = data.reportList.map((item) => {
            return item.reportUserName;
          });
          option.series[0].data = data.reportList.map((item) => {
            return item.sum || 0;
          });
          option.series[1].data = data.reportList.map((item) => {
            return item.finishNum || 0;
          });
          option.series[0].name = "安全巡检";
          option.series[1].name = "隐患整改完成";
          option.color = ["rgb(116, 142, 222)", "rgb(0,211,73)"];
          myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        });
      });
    },
    // 渲染人员进场统计
    renderinCompanyEcharts(data) {
      this.$nextTick(() => {
        var myChart = this.$echarts.init(
          document.getElementById("personnel-echarts")
        );
        let option = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            right: "0",
            orient: "vertical",
            textStyle: {
              //图例文字的样式
              fontSize: 16,
            },
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        myChart.setOption(option);
        myChart.resize();
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    renderHazardsEcharts(data) {
      this.$nextTick(() => {
        var myChart = this.$echarts.init(
          document.getElementById("hazards-echarts")
        );
        // companyName	公司名称	string
        // hdTotal	总数	integer
        // overTime	超期未整改	integer
        // processed	已整改
        let xAxisData = data.map((item) => {
          return item.companyName;
        });
        let seriesData1 = data.map((item) => {
          return item.processed;
        });
        let seriesData2 = data.map((item) => {
          return item.overTime;
        });
        let seriesData3 = data.map((item) => {
          return item.hdTotal;
        });
        // 原数据
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          legend: {
            data: ["已整改", "超期未整改", "总数"],
          },
          grid: {
            // 让图表占满容器
            top: "40px",
            left: "40px",
            right: "0px",
            bottom: "40px",
          },
          xAxis: [
            {
              type: "category",
              data: xAxisData,
              axisPointer: {
                type: "shadow",
              },
              axisLabel: {
                interval: 0,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "已整改",
              type: "bar",
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              barWidth: "20px",
              itemStyle: {
                // 设置柱状图的宽度
                barWidth: "10", // 可以是具体像素值 '20px' 或百分比 '50%'
              },
              data: seriesData1,
            },
            {
              name: "超期未整改",
              type: "line",
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: seriesData2,
            },
            {
              name: "总数",
              type: "line",
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: seriesData3,
            },
          ],
        };
        myChart.setOption(option);
        myChart.resize();
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    // 获取安全培训率
    getStatis() {
      getStatis({
        firm: this.userInfo.company_id || this.userInfo.dept_id,
      }).then((res) => {
        console.log(res);
        this.safeStatistics = res.data.data;
      });
    },
    //获取待办事项
    getToDoStatis() {
      // let todo = new Promise((resolve, reject) => {
      //   todoList(1, 20)
      //     .then((res) => {
      //       if (res.data.code == 200) {
      //         let result = res.data.data.total;
      //         resolve(result);
      //       } else {
      //         reject();
      //       }
      //     })
      //     .catch((error) => {
      //       reject();
      //     });
      // });
      sinomatodo(1, 10, { ownerName: this.userInfo.account, status: 0 }).then(
        (res) => {
          const data = res.data.data;
          this.todoNum = data.total;
          // this.data = data.records;
          // this.loading = false;
        }
      );
      //   let myTask = new Promise((resolve, reject) => {
      //     let myTask = {};
      //     myTask.taskUser = this.userInfo.user_id;
      //     myTask.taskRole = this.userInfo.role_id;
      //     myTask.taskDept = this.userInfo.dept_id;
      //     getMyTaskList(myTask)
      //       .then((res) => {
      //         if (res.data.code == 200) {
      //           let list = res.data.data;
      //           resolve(list.length);
      //         } else {
      //           reject();
      //         }
      //       })
      //       .catch((error) => {
      //         reject();
      //       });
      //   });
      // Promise.all([todo]).then((res) => {
      //   this.todoNum = res.reduce((acc, curr) => acc + curr, 0);
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.prj-index {
  height: 100%;
}
.underline-text {
  text-decoration: underline;
  // color: #fff;
}
.card-b-tips {
  display: flex;
  flex-direction: row;
}
.split-line {
  padding: 0 20px;
}
.home-line-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  height: calc(50% - 79px);
}
.block-box {
  width: calc(50% - 10px);
  background-color: #fff;
  border-radius: 6px;
  height: 100%;
  .content {
    height: calc(100% - 32px);
  }
  .title {
    padding-top: 10px;
    position: relative;
    .icon {
      position: absolute;
      left: 0;
      top: 12px;
      width: 4px;
      height: 20px;
      background: #65abf6;
    }
    .text {
      margin-left: 20px;
    }
  }
}
.class-list {
  overflow-y: scroll;
  .list-item {
    padding: 20px;
    display: flex;
    .list-content {
      margin-left: 20px;
      //   .meetingTitle {
      //     font-size: 24px;
      //     font-weight: bold;
      //   }
      .meetingContents {
        // margin-top: 20px;
        height: 120px;
        white-space: normal; /* 允许自动换行 */
        overflow: auto; /* 当内容超出元素时显示滚动条 */
      }
    }
  }
}
.home-statistics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .card-item {
    color: #fff;
    width: calc(20% - 20px);
    position: relative;
    .svg-icon {
      position: absolute;
      right: 40px;
      top: 20px;
      //   transform: translateY(-50%);
    }
    .card {
      padding: 10px;
      width: 100%;
      .card-content {
        margin-top: 10px;
      }
      .card-b-tips {
        margin-top: 10px;
      }
      .unit {
        margin-left: 4px;
      }
      .num {
        font-size: 20px;
      }
    }
  }
}
::v-deep.el-card__body {
  height: 100%;
}
.todo-items {
  background: linear-gradient(to left, #33c7ee, #14b0d8);
  .num-box {
    margin-top: 40px;
  }
}
.security-inspection {
  background: linear-gradient(to left, #fdba72, #f2a858);
}
.safety-training {
  background: linear-gradient(to left, #4bdbdb, #3bcccc);
}
.safe-production {
  background: linear-gradient(to left, #65abf6, #4696ea);
}
.hazard-source-warning {
  background: linear-gradient(to left, #fd8866, #eb6a45);
}
</style>