<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-07 11:00:49
-->
<template>
  <el-dialog title="算法模型" top="5vh" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" v-if="showDialog" width="1240px" class="qmDialog" @close="closeDialog">
    <el-container>
      <el-main>
        <div class="headers">
          <grid-head-layout ref="gridHeadLayout" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')" :search-columns="searchColumns" @grid-head-search="searchChange" @grid-head-empty="searchReset">
          </grid-head-layout>
        </div>
        <grid-layout ref="gridLayout" class="userSelectGridLayout" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')" :data-total="page.total" @page-size-change="onLoad" @page-current-change="onLoad" @page-refresh-change="onLoad" :page="page" @gird-handle-select-click="selectionChange" :tableOptions="tableOptions" :tableData="tableData" :tableLoading="tableLoading"
          :gridRowBtn="gridRowBtn">
          <template slot="version" slot-scope="{ row }">
            <el-select v-model="row.version" placeholder="请选择类型">
                <el-option v-for="item in row.availableVersions" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="treeDialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleSave" v-loading="treeDialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    availableModel
  } from "@/api/safe/algorithmManagement";
  import {
    calculateAge
  } from "@/util/util";
  import GridLayout from "@/views/components/layout/grid-layout";
  import TreeLayout from "@/views/components/layout/tree-layout";
  import {
    ALGO_MODEL_CATEGORY
  } from '../../nodeDict.js'
  export default {
    components: {
      GridLayout,
      TreeLayout,
    },
    data() {
      return {
        showDialog: false,
        treeDialogLoading: false,
        form: {},
        title: "",
        treeData: [],
        gridRowBtn: [],
        searchTitle: "title",
        defaultProps: {
          label: "title",
          value: "key",
        },
        treeDeptId: "",
        tableLoading: false,
        page: {
          pageSize: 20,
          pageIndex: 1,
          total: 0,
        },
        tableOptions: {
          menu: false,
          reserveSelection: true,
          column: [{
              label: "模型编码",
              prop: "code",
              align: "center",
              overHidden: true,
            },
            {
              label: "模型名称",
              prop: "name",
              align: "center",
              overHidden: true,

            },
            {
              label: "类别",
              prop: "category",
              align: "center",
              props: {
                label: "name",
                value: "value",
              },
              dicData: ALGO_MODEL_CATEGORY,

            },
            {
              label: "当前版本",
              prop: "version",
              align: "center",
              overHidden: true,
              slot:true
            },
            {
              label: "备注",
              prop: "remark",
              align: "center",
              overHidden: true,
            },
          ],

        },
        selectionList: [],
        oldSelectionList: [],
        parentData: '',
        parentGateMachineId: '',
        first: true,
      };
    },
    computed: {

      searchColumns() {
        return [{
          label: "编码/名称",
          prop: "keyword",
          span: 4,
          placeholder: "请输入编码/名称",
        }, ];
      },

    },
    props: {
      userSure: { // 为true时，点击确认不关闭弹窗，需在父组件手动关闭
        type: Boolean,
        default: false
      }
    },
    methods: {
      //获取年龄
      getAge(data) {
        return calculateAge(data);
      },
      init(data) {
        this.showDialog = true
        this.parentData = data
        this.selectionList = []
        this.$nextTick(() => {
          this.onLoad(this.page);
        });
      },
      includeDown(e) {
        this.page.pageIndex = 1;
        this.query.isInclude = e;
        this.onLoad(this.page);
      },
      onLoad(page, params = {}) {
        this.page = page;
        this.tableLoading = true;
        params.serverId = this.parentData.id
        params.pageIndex = page.pageIndex
        params.pageSize = page.pageSize
        availableModel(
          Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          const data = res.data.data;

          this.tableData = data.records;
          this.tableLoading = false;
          this.$nextTick(() => {
            this.$refs.gridLayout.page.total = data.total;
          });
        });
      },
      selectionChange(list) {
        this.selectionList = list;
      },
      getNodeClick(data) {
        this.treeDeptId = data.id;
        this.page.pageIndex = 1;
        this.onLoad(this.page);
      },
      searchChange() {
        this.page.pageIndex = 1;
        this.onLoad(this.page);
      },
      searchReset() {
        this.page.pageIndex = 1;
        this.onLoad(this.page);
      },
      /**
       * 部门树结构点击事件
       * @param node
       */
      treeNodeClick(node) {
        this.treeDeptId = node.id;
        this.page.pageIndex = 1;
        this.onLoad(this.page);
      },
      // 新增左侧树节点
      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      handleSave() {
        if (this.selectionList.length) {
          this.showDialog = false;
          this.$emit("callBack", this.selectionList);
          this.$refs.gridHeadLayout.searchForm = {};
          this.page.pageIndex = 1;
        } else {
          this.$message.warning("请选择至少一条数据");
        }

      },
      closeDialog() {
        this.$refs.gridHeadLayout.searchForm = {};
        this.page.pageIndex = 1;
        this.treeDeptId = "";
        // this.$refs.CommonTree.filterText = ''
        this.showDialog = false;
      },
    },
  };

</script>
<style scoped lang="scss">
  ::v-deep .userSelectGridLayout .avue-crud .el-table {
    height: 50vh !important;
    max-height: 50vh !important;
    box-sizing: border-box;
  }

  ::v-deep .userSelectTreeLayout .el-tree {
    height: 50vh !important;
    max-height: 50vh !important;
    overflow-y: scroll;
  }

</style>
