<template>
  <div>
    <el-container>
      <!-- <CommonTree style="flex-shrink: 0" :treeData="componylist" :defaultProps="defaultProps" :searchTitle="searchTitle"
        :isShowdig="false" :showCheckbox="false" :popover-show="false" :expandOnClickNode="false" treeTitle="组织架构"
        @getNodeClick="treeNodeClick" /> -->
      <CommonTreeNew
        ref="commonTree"
        treeTitle="项目架构"
        :defaultProps="Props"
        :showCheckbox="false"
        @getNodeClick="treeNodeClick"
        :urlParmas="{
          tenantId: '',
          deptCategory: '2,5',
          parentId: userInfo.deptId,
        }"
        node-key="id"
        @getTreeData="commonTreeData"
      ></CommonTreeNew>
      <el-main>
        <head-layout
          head-title="培训任务"
          :head-btn-options="headBtnOptions"
          @head-add="addFn"
        >
        </head-layout>

        <grid-head-layout
          ref="searchForm"
          v-model="query"
          :search-columns="searchColumns"
          @grid-head-search="searchChange"
          @grid-head-empty="searchReset"
        ></grid-head-layout>
        <!-- <templete slot="status">
          <el-select size="mini" class="searchBoxcc" v-model="selectValue">
            <el-option value="2">已下发</el-option>
            <el-option value="2">已完成</el-option> -->
        <!-- <el-option
              v-for="item in selectOption"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option> -->
        <!-- </el-select>
        </templete> -->
        <grid-layout
          ref="gridLayout"
          :tableOptions="tableOptions"
          :tableData="tableData"
          :tableLoading="tableLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="toView"
          @page-size-change="onLoad"
          @page-current-change="onLoad"
          @page-refresh-change="onLoad"
          @grid-view="toView"
          @grid-romve="toDel"
          :searchColumns="searchColumns"
        >
          <template #customBtn="{ row, index }">
            <el-button
              size="small"
              type="text"
              @click.stop="toView(row, index)"
            >
              查看
            </el-button>
            <el-button
              size="small"
              type="text"
              @click.stop="toEdit(row, index)"
              v-if="row.trainingStatus != 3 && isDateBeforeCurrent(row.endTime)"
            >
              编辑
            </el-button>
            <!--        <el-button size="small" type="text" @click.stop="toDel(row, index)">-->
            <!--          删除-->
            <!--        </el-button>-->
            <!--        <el-button-->
            <!--          size="small"-->
            <!--          type="text"-->
            <!--          v-if="isDateBeforeCurrent(row.endTime) && row.status != 3"-->
            <!--          @click.stop="supplementaryDutyPersonnel(row, index)"-->
            <!--        >-->
            <!--          补充任务人员-->
            <!--        </el-button>-->
            <el-button
              size="small"
              type="text"
              @click.stop="startTraining(row, index)"
              v-if="row.trainingStatus == 1 && isDateBeforeCurrent(row.endTime)"
            >
              开始培训
            </el-button>
            <el-button
              size="small"
              type="text"
              @click.stop="continuingTraining(row, index)"
              v-if="row.trainingStatus == 2 && isDateBeforeCurrent(row.endTime)"
            >
              扫码培训
            </el-button>
            <!--        <el-button-->
            <!--          size="small"-->
            <!--          type="text"-->
            <!--          @click.stop="viewResultRecord(row, index)"-->
            <!--        >-->
            <!--          查看结果记录-->
            <!--        </el-button>-->
          </template>
        </grid-layout>
        <CommonDialog
          v-if="dialogVisible"
          width="30%"
          dialogTitle="提示"
          @cancel="dialogVisible = false"
          @confirm="SureToExecute"
        >
          <span>{{ dialogtext }}</span>
        </CommonDialog>
        <el-drawer
          title="培训记录"
          :visible.sync="trainingEducationStatisticsIndexDrawer"
          v-if="trainingEducationStatisticsIndexDrawer"
          direction="rtl"
          :append-to-body="true"
          size="70%"
        >
          <trainingEducationStatisticsIndex
            ref="trainingEducationStatisticsIndex"
            :rowData="rowData"
            type="trainingEducationStatistics"
          ></trainingEducationStatisticsIndex>
        </el-drawer>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getDictionaryCode } from "@/api/system/dictbiz";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import { trainingTaskDelete, trainingTaskPage } from "@/api/safe/index";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import { detail } from "@/api/plugin/workflow/process";
import WfFlow from "@/views/plugin/workflow/process/components/flow.vue";
import { mapGetters } from "vuex";
import { getDetailBySiteTrainingId } from "@/api/training/recording";
import trainingEducationStatisticsIndex from "@/views/business/training/trainingEducationStatistics/index";
import { dateFormat } from "@/util/date";
import { companyProjects } from "@/api/system/dept";
import { saveCorrespondingData } from "@/api/training/trainGroupScene";
import CommonDialog from "@/components/CommonDialog";
import { getDeptTree } from "@/api/system/dept";
import CommonTree from "@/views/components/com_tree";
export default {
  components: {
    HeadLayout,
    GridLayout,
    WfFlow,
    trainingEducationStatisticsIndex,
    CommonDialog,
    CommonTree,
  },
  props: {},
  mixins: [exForm],
  data() {
    return {
      status: "",
      selectOption: [],
      searchTitle: "title",
      defaultProps: {
        children: "children",
        label: "title",
        value: "id",
      },
      Props: {
        children: "children",
        label: "title",
      },
      treeDeptId: "",
      componylist: [],
      searchKey: "",
      bpmnOption: {},
      circulationVisible: false,
      bpmnVisible: false,
      query: {},
      total: "",
      dialogtext: "",
      runState: "",
      btnType: "",
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0,
      },
      dialogVisible: false,
      tableLoading: false,
      ExecuteId: "",
      tableData: [],
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
        },
      ],

      tableOptions: {
        selection: false,
        customRowAddBtn: false,
        menuWidth: 250,
        indexLabel: "序号",
        index: true,
        linklabel: "trainTheme",
        column: [
          {
            label: "任务名称",
            prop: "trainTheme",
            align: "left",
            width: 120,
            overHidden: true,
          },
          {
            label: "培训时间",
            prop: "trainingTime",
            align: "center",
            width: 240,
            overHidden: true,
          },
          {
            label: "下发时间",
            prop: "updateTime",
            align: "center",
            width: 180,
            overHidden: true,
          },
          {
            label: "培训人",
            prop: "createUserName",
            align: "left",
            width: 120,
          },
          {
            label: "培训发起组织",
            prop: "deptName",
            align: "left",
            width: 180,
            overHidden: true,
          },
          {
            label: "通知人数",
            prop: "numberOfNotifiedPersons",
            align: "right",
            width: 80,
            overHidden: true,
          },
          {
            label: "参训人数",
            prop: "numberOfParticipants",
            align: "right",
            width: 80,
            overHidden: true,
          },
          {
            label: "是否考试",
            prop: "isExam",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            width: 80,
            overHidden: true,
          },
          {
            label: "通过人数",
            prop: "passingNumber",
            align: "right",
            width: 80,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            width: 80,
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=is_training_task_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            overHidden: true,
          },
        ],
      },
      trainingEducationStatisticsIndexDrawer: false,
      rowData: {},
    };
  },
  computed: {
    ...mapGetters(["permission", "language", "userInfo"]),
    gridRowBtn() {
      let rowBtn = [];
      rowBtn.push(
        {
          label: this.$t("cip.cmn.btn.viewBtn"),
          emit: "grid-view",
          remark: "view",
          type: "text",
          icon: "",
        },
        // {
        //   label: this.$t("cip.cmn.btn.editBtn"),
        //   emit: "grid-edit",
        //   remark: "edit",
        //   type: "text",
        //   icon: "",
        // },

        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          remark: "remove",
          type: "text",
          icon: "",
        }
      );
      return rowBtn;
    },
    searchColumns() {
      return [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "培训任务名称",
          type: "input",
          prop: "trainTheme",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.selectWarning") + "状态",
          type: "select",
          prop: "status",
          dicData: [],
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 4,
          change: (val) => {
            this.status = val.value;
          },
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "课程名称",
          type: "input",
          prop: "courseName",
          span: 4,
        },
      ];
    },
  },

  methods: {
    initDictData() {
      //根据字典Code, 初始化字典数组
      getDictionaryCode("is_training_task_status").then((res) => {
        if (res.data.code == 200) {
          this.$nextTick(() => {
            this.findObject(
              this.$refs.searchForm.searchColumns,
              "status"
            ).dicData = res.data.data;
            this.status = res.data.data[0].dictKey;
            this.$refs.searchForm.searchForm.status = this.status;
          });
        }
      });
    },
    /**
     * 部门树结构点击事件
     * @param node
     */
    treeNodeClick(node) {
      this.treeDeptId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    handleSearch() {
      this.searchKey = this.searchKey.trim();
      this.$nextTick(() => {
        if (this.searchKey) {
          this.componylist = this.filterTreeData(this.oldData, this.searchKey);
        } else {
          this.componylist = this.oldData;
        }
      });
    },
    filterTreeData(list, searchKey) {
      return list.reduce((acc, item) => {
        let children = item.children
          ? this.filterTreeData(item.children, searchKey)
          : [];
        if (item.fullName.includes(searchKey) || children.length > 0) {
          acc.push({
            ...item,
            children,
          });
        }
        return acc;
      }, []);
    },
    getcomponylist() {
      getDeptTree("", "2,5", this.userInfo.dept_id).then((res) => {
        let treeData = res.data.data;
        if (treeData.length > 0) {
          this.componylist = treeData;
          this.treeNodeClick(treeData[0]);
          this.oldData = treeData;
        }
      });
    },
    isDateBeforeCurrent(trainingEndTime) {
      if (!trainingEndTime) return false;
      const currentTime = new Date();
      currentTime.setHours(0, 0, 0, 0);
      const endTime = new Date(trainingEndTime);
      endTime.setHours(0, 0, 0, 0);
      return currentTime <= endTime;
    },
    supplementaryDutyPersonnel(row) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingTaskListPageAdd",
        query: {
          id: row.id,
          type: "supplementaryDutyPersonnel",
        },
      });
    },
    continuingTraining(row) {
      getDetailBySiteTrainingId(row.id).then((res) => {
        if (res.data.code == 200 && res.data.msg != "暂无承载数据") {
          if (res.data.data) {
            window.open(
              window.location.origin +
                "#/business/training/onSiteTraining/edit?row=" +
                JSON.stringify(res.data.data) +
                "&type=edit&isNewWindow=true"
            );
            // window.open('http://localhost:1888/#/business/training/onSiteTraining/edit?row=' + JSON.stringify(res.data.data) + '&type=edit&isNewWindow=true');
          }
        } else {
          let status = 1;
          // whetherToStartTraining(row.id, status).then((res) => {
          //   if (res.data.code == 200) {
          let time = dateFormat(new Date(), "yyyy-MM-dd");
          companyProjects().then((res) => {
            if (res.data.code == "200") {
              let data = {
                siteTrainingId: row.id,
                trainingName: row.courseName,
                trainingTime: time,
                trainingScenarios: row.trainingScenarios,
                sponsor: this.userInfo.user_id,
                project: res.data.data.project,
                isFinish: 1,
                firm: res.data.data.firm,
                remark: row.remark,
                trainingType: "startTraining",
              };
              saveCorrespondingData(data).then((res) => {
                if (res.data.code == 200) {
                  window.open(
                    window.location.origin +
                      "#/business/training/onSiteTraining/edit?row=" +
                      JSON.stringify(res.data.data) +
                      "&type=edit&isNewWindow=true"
                  );
                  // window.open('http://localhost:1888/#/business/training/onSiteTraining/edit?row=' + JSON.stringify(res.data.data) + '&type=edit&isNewWindow=true');
                } else {
                  this.$message.success(msg);
                }
              });
            } else {
              this.$message.success("数据错误,请刷新重试!");
            }
          });
          // }
          // })
        }
      });
    },
    /**
     * 开始培训
     */
    startTraining(row) {
      //开始培训
      getDetailBySiteTrainingId(row.id).then((res) => {
        if (res.data.code == 200 && res.data.msg != "暂无承载数据") {
          if (res.data.data) {
            window.open(
              window.location.origin +
                "#/business/training/onSiteTraining/edit?row=" +
                JSON.stringify(res.data.data) +
                "&type=edit&isNewWindow=true"
            );
          }
        } else {
          //需要查询课程是否失效
          if (this.tableData[0].trainingStatus == 1) {
            let status = 1;
            // whetherToStartTraining(row.id, status).then((res) => {
            //   if (res.data.code == 200) {
            let time = dateFormat(new Date(), "yyyy-MM-dd");
            companyProjects().then((res) => {
              if (res.data.code == "200") {
                let data = {
                  siteTrainingId: row.id,
                  trainingName: row.courseName,
                  trainingTime: time,
                  trainingScenarios: row.trainingScenarios,
                  sponsor: this.userInfo.user_id,
                  project: res.data.data.project,
                  isFinish: 1,
                  firm: res.data.data.firm,
                  remark: row.remark,
                  trainingType: "startTraining",
                };
                saveCorrespondingData(data).then((res) => {
                  if (res.data.code == 200) {
                    window.open(
                      window.location.origin +
                        "#/business/training/onSiteTraining/edit?row=" +
                        JSON.stringify(res.data.data) +
                        "&type=edit&isNewWindow=true"
                    );
                  } else {
                    this.$message.success(msg);
                  }
                });
              } else {
                this.$message.success("数据错误,请刷新重试!");
              }
            });
            // }
            // })
          } else {
            this.$message.warning("此任务已失效!");
          }
        }
      });
    },
    viewResultRecord(row) {
      this.trainingEducationStatisticsIndexDrawer = true;
      this.rowData = row;
    },
    headProgress(row) {
      const { taskId, processInstanceId } = row;
      this.getTaskDetail(taskId, processInstanceId).then(() => {
        this.waiting = false; // 关闭骨架屏
      });
      this.circulationVisible = true;
    },
    handleFlow(row) {
      const { taskId, processInstanceId } = row;
      detail({ taskId, processInsId: processInstanceId }).then((res) => {
        const { process, flow } = res.data.data;
        this.bpmnOption = {
          mode: "view",
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language,
        };

        this.bpmnVisible = true;
      });
    },
    searchReset() {
      this.$refs.searchForm.searchForm = {};
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      this.query.createDept = this.treeDeptId;
      trainingTaskPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        page.total = data.total;
        this.$refs.gridLayout.page.total = data.total;
        //进行数据构造
        data.records.forEach((e) => {
          e.endTime = e.trainEnd.substring(0, 10);
          e.trainingTime =
            e.trainStart.substring(0, 10) +
            " 至 " +
            e.trainEnd.substring(0, 10);
        });
        this.tableData = data.records;
        this.tableData.forEach((e) => {
          e.processInstanceId = e.taskId;
          e.taskId = null;
        });
        this.tableLoading = false;
      });
    },
    toEdit(item) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingTaskListPageEdit",
      });
      sessionStorage.setItem("taskId", item.id);
      sessionStorage.setItem("taskType", "edit");
    },
    //查看
    toView(item) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingTaskListPageView",
      });
      sessionStorage.setItem("taskId", item.id);
      sessionStorage.setItem("taskType", "view");
    },
    addFn() {
      this.$router.push({
        path: "/business/safetyenment/train/trainingTaskListPageAdd",
      });
      sessionStorage.removeItem("taskId");
      sessionStorage.setItem("taskType", "add");
    },
    //执行和删除
    SureToExecute() {
      this.dialogVisible = false;

      if (this.btnType == "del") {
        trainingTaskDelete({ id: this.ExecuteId }).then(() => {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.onLoad(this.page);
        });
      }
    },
    toDel(item) {
      this.dialogVisible = true;
      this.dialogtext = "是否删除此任务？";
      this.ExecuteId = item.id;
      this.btnType = "del";
    },
    toStats(item) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingTask",
        query: {
          taskId: item.id,
        },
      });
    },
  },
  mounted() {
    // document.getElementById("status")[2].selected = true;
    this.initDictData();
    // this.onLoad(this.page);
    this.query.status = 2;
    this.query.courseName = this.$route.query.courseName || "";
    this.$refs.searchForm.searchForm.courseName = this.query.courseName;
    // this.getcomponylist();
    // this.onLoad(this.page);
    window.addEventListener("storage", (e) => {
      if (e.key === "sinoma-refresh") {
        this.onLoad(this.page); //刷新列表
        localStorage.setItem("sinoma-refresh", JSON.stringify(false)); //变成false , 可以来回监听
        //要做的操作
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}

.el-button + .el-button {
  margin-left: 5px !important;
}

::v-deep .bjs-powered-by {
  display: none;
}
</style>
