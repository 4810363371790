/*
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-07 09:50:17
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-05-23 16:24:03
 * @Description: 
 */
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      tableOption: {
        linklabel: "opinionCode",
        selectable: (row,index)=>{
          return row.status?false:row.createUser!=this.userInfo.user_id?false:true
        },
        column: [
          {
            label: "记录编号",
            prop: "opinionCode",
            align: "left",
            overHidden: true,
            width: 200,
          },
          {
            label: "舆情来源",
            prop: "source",
            dataType: 'string',
            align: "center",
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=ep_option_source`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            // slot: true,
          },
          {
            label: "来源详情",
            prop: "sourceDetail",
            align: "left",
            overHidden: true,
            width: 200,
          },

          {
            label: "舆情内容",
            prop: "content",
            align: "center",
            width: 200,
            overHidden: true,
          },
          {
            label: "发生日期",
            prop: "opinionDate",
            align: "center",
            width: 200,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            type: "select",
            // dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
            dicData: [
              { dictValue: "完成", dictKey: 1 }, // FINISHED
              { dictValue: "草稿", dictKey: 0 }, // PREPARE
            ],
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
    };
  },
  
};
