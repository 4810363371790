var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [_c("page", { attrs: { checkType: _vm.checkType } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }