var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "disFlex" }, [
      _c(
        "div",
        {
          staticClass: "card-pd-2",
          staticStyle: { width: "calc(37% - 60px)" },
        },
        [
          _vm._m(0),
          _c(
            "div",
            { staticStyle: { display: "flex", "margin-top": "37px" } },
            [
              _c("div", { attrs: { id: "chart-six" } }),
              _c("div", { staticClass: "chart-six-info" }, [
                _c("div", { staticClass: "chart-six-info-box" }, [
                  _c("div", {
                    staticClass: "info-circle",
                    staticStyle: { "background-color": "#fb3636" },
                  }),
                  _c("span", [_vm._v("重大风险")]),
                  _c("span", { staticClass: "info-number" }, [
                    _vm._v(_vm._s(_vm.formatWithCommas(30)) + "个"),
                  ]),
                  _c("span", [_vm._v("3.75%")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "chart-six-info-box",
                    staticStyle: { "margin-top": "18px" },
                  },
                  [
                    _c("div", {
                      staticClass: "info-circle",
                      staticStyle: { "background-color": "#ffab2d" },
                    }),
                    _c("span", [_vm._v("较大风险")]),
                    _c("span", { staticClass: "info-number" }, [
                      _vm._v(_vm._s(_vm.formatWithCommas(270)) + "个"),
                    ]),
                    _c("span", [_vm._v("33.75%")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "chart-six-info-box",
                    staticStyle: { "margin-top": "18px" },
                  },
                  [
                    _c("div", {
                      staticClass: "info-circle",
                      staticStyle: { "background-color": "#ffed8c" },
                    }),
                    _c("span", [_vm._v("一般风险")]),
                    _c("span", { staticClass: "info-number" }, [
                      _vm._v(_vm._s(_vm.formatWithCommas(170)) + "个"),
                    ]),
                    _c("span", [_vm._v("21.25%")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "chart-six-info-box",
                    staticStyle: { "margin-top": "18px" },
                  },
                  [
                    _c("div", {
                      staticClass: "info-circle",
                      staticStyle: { "background-color": "#4aa3ff" },
                    }),
                    _c("span", { staticStyle: { width: "64px" } }, [
                      _vm._v("低风险"),
                    ]),
                    _c("span", { staticClass: "info-number" }, [
                      _vm._v(_vm._s(_vm.formatWithCommas(330)) + "个"),
                    ]),
                    _c("span", [_vm._v("41.25%")]),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "card-pd-2",
          staticStyle: { width: "calc(63% - 60px)" },
        },
        [
          _vm._m(1),
          _c("div", { staticClass: "card-pd-2-Bottom" }, [
            _c(
              "div",
              { staticClass: "card-pd-2-BotLitte2" },
              [
                _c("div", { staticClass: "botLitte2-title" }, [
                  _vm._v("安全检查次数"),
                ]),
                _c("div", { staticClass: "botLitte2-number" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value1) || 0)
                  ),
                  _c("span", [_vm._v("次")]),
                ]),
                _c("slider", { attrs: { value: 68 } }),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            计划检查次数："),
                  _c("span", [_vm._v(_vm._s(_vm.formatWithCommas(31)) + "次")]),
                ]),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            完成率："),
                  _c("span", [_vm._v(_vm._s(_vm.formatWithCommas(66)) + "%")]),
                ]),
              ],
              1
            ),
            _c("div", { attrs: { id: "chart-seven" } }),
          ]),
        ]
      ),
    ]),
    _vm._m(2),
    _c("div", { staticClass: "disFlex" }, [
      _c("div", { staticClass: "card-pd-2" }, [
        _vm._m(3),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { staticClass: "leftChartBox" }, [
            _c("div", { attrs: { id: "chart-ten" } }),
            _c("div", { staticClass: "chart-ten-info" }, [
              _c("div", { staticClass: "chart-six-info-box" }, [
                _c("div", {
                  staticClass: "info-circle",
                  staticStyle: { "background-color": "#fb3636" },
                }),
                _c("span", [_vm._v("重大风险")]),
                _c("span", { staticClass: "info-number" }, [
                  _vm._v(_vm._s(_vm.formatWithCommas(30)) + "个"),
                ]),
                _c("span", [_vm._v("64%")]),
              ]),
              _c("div", { staticClass: "chart-six-info-box" }, [
                _c("div", {
                  staticClass: "info-circle",
                  staticStyle: { "background-color": "#ffab2d" },
                }),
                _c("span", [_vm._v("较大风险")]),
                _c("span", { staticClass: "info-number" }, [
                  _vm._v(_vm._s(_vm.formatWithCommas(270)) + "个"),
                ]),
                _c("span", [_vm._v("36%")]),
              ]),
              _c("div", { staticClass: "chart-six-info-box" }, [
                _c("div", {
                  staticClass: "info-circle",
                  staticStyle: { "background-color": "#ffed8c" },
                }),
                _c("span", [_vm._v("一般风险")]),
                _c("span", { staticClass: "info-number" }, [
                  _vm._v(_vm._s(_vm.formatWithCommas(170)) + "个"),
                ]),
                _c("span", [_vm._v("36%")]),
              ]),
              _c("div", { staticClass: "chart-six-info-box" }, [
                _c("div", {
                  staticClass: "info-circle",
                  staticStyle: { "background-color": "#4aa3ff" },
                }),
                _c("span", { staticStyle: { width: "64px" } }, [
                  _vm._v("低风险"),
                ]),
                _c("span", { staticClass: "info-number" }, [
                  _vm._v(_vm._s(_vm.formatWithCommas(330)) + "个"),
                ]),
                _c("span", [_vm._v("36%")]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "rightChartBox" }, [
            _c("div", { attrs: { id: "chart-eleven" } }),
            _c(
              "div",
              {
                staticClass: "chart-six-info-box",
                staticStyle: {
                  "justify-content": "center",
                  "margin-top": "18px",
                },
              },
              [
                _c("div", {
                  staticClass: "info-circle",
                  staticStyle: { "background-color": "#fb3636" },
                }),
                _c("span", [_vm._v("隐患整改数")]),
                _c("span", { staticStyle: { "margin-left": "12px" } }, [
                  _vm._v(_vm._s(_vm.formatWithCommas(30))),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._m(4),
    ]),
    _vm._m(5),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v(" 风险等级分布 "),
      ]),
      _c("div", { staticClass: "card-pd-2-top-btn" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/board/five.png") },
        }),
        _c("img", { attrs: { src: require("@/assets/images/board/six.png") } }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/seven.png") },
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/eight.png") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v(" 安全检查次数 "),
      ]),
      _c("div", { staticClass: "card-pd-2-top-btn" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/board/five.png") },
        }),
        _c("img", { attrs: { src: require("@/assets/images/board/six.png") } }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/seven.png") },
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/eight.png") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "disFlex" }, [
      _c("div", { staticClass: "card-pd-2" }, [
        _c("div", { staticClass: "card-pd-2-top" }, [
          _c("span", { staticClass: "card-pd-2-top-Title" }, [
            _vm._v(" 公司安全检查次数TOP5 "),
          ]),
          _c("div", { staticClass: "card-pd-2-top-btn" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/board/five.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/six.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/seven.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/eight.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { attrs: { id: "chart-eight" } }),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-2" }, [
        _c("div", { staticClass: "card-pd-2-top" }, [
          _c("span", { staticClass: "card-pd-2-top-Title" }, [
            _vm._v(" 每月安全检查情况 "),
          ]),
          _c("div", { staticClass: "card-pd-2-top-btn" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/board/five.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/six.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/seven.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/eight.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { attrs: { id: "chart-nine" } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v(" 隐患发现及整改次数 "),
      ]),
      _c("div", { staticClass: "card-pd-2-top-btn" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/board/five.png") },
        }),
        _c("img", { attrs: { src: require("@/assets/images/board/six.png") } }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/seven.png") },
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/eight.png") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2" }, [
      _c("div", { staticClass: "card-pd-2-top" }, [
        _c("span", { staticClass: "card-pd-2-top-Title" }, [
          _vm._v(" 下属组织隐患整改率TOP5 "),
        ]),
        _c("div", { staticClass: "card-pd-2-top-btn" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/board/five.png") },
          }),
          _c("img", {
            attrs: { src: require("@/assets/images/board/six.png") },
          }),
          _c("img", {
            attrs: { src: require("@/assets/images/board/seven.png") },
          }),
          _c("img", {
            attrs: { src: require("@/assets/images/board/eight.png") },
          }),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-2-Bottom" }, [
        _c("div", { attrs: { id: "chart-twelve" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "disFlex" }, [
      _c("div", { staticClass: "card-pd-2", staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "card-pd-2-top" }, [
          _c("span", { staticClass: "card-pd-2-top-Title" }, [
            _vm._v(" 隐患发现及整改趋势 "),
          ]),
          _c("div", { staticClass: "card-pd-2-top-btn" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/board/five.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/six.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/seven.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/eight.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { attrs: { id: "chart-thirteen" } }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }