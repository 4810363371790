<template>
  <div class="main-box sortSetting">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane
        :key="index"
        v-for="(item, index) in MD_SECTION"
        :label="item.dictValue"
        :name="item.dictKey"
      ></el-tab-pane>
    </el-tabs>
    <div class="flex-container">
      <CommonTreeNew
        treeTitle="隐患分类"
        node-key="id"
        isLeafIcons
        isShowdig
        :defaultExpandedKeys="defaultExpandedKeys"
        ref="commonTreeCore"
        @getTreeAdd="getTreeAdd"
        @getTreeDelete="getTreeDelete"
        @getTreeEdit="getTreeEdit"
        :treeData="corePersonnelTreeData"
        :defaultProps="defaultProps"
        @getNodeClick="corePersonnelTreeNodeClick"
        :showCheckbox="false"
      />
      
      <div class="table-box">
        <!--      @head-export="headExport"-->
        <!--      @handleImport="handleImport"-->
        <head-layout
          :head-btn-options="corePersonnelHeadBtnOptions"
          @head-del="headDel"
          @head-add="addjcx"
          head-title="隐患描述"
        ></head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="corePersonnelTableOption"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          :table-data="tableData"
          :table-loading="TableLoading"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{ row }">
            <el-button type="text" size="small" @click="addjcx(row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="rowDel([row])"
              >删除</el-button
            >
          </template>
        </grid-layout>
      </div>
    </div>
    <el-dialog
      title="隐患分类"
      v-if="jcxflModel"
      v-dialogDrag
      :visible.sync="jcxflModel"
      width="30%"
    >
      <avue-form
        ref="avueForm"
        :option="jcxflOption"
        @reset-change="resetChange"
        @submit="handleSubmit"
        v-model="jcxflForm"
      >
      </avue-form>
      <div class="avue-dialog__footer" slot="footer">
        <el-button
          size="small"
          @click="jcxflModel = false"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="$refs.avueForm.submit()"
        >
          保存
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="隐患描述"
      v-if="jcxflModel1"
      v-dialogDrag
      :visible.sync="jcxflModel1"
      width="30%"
    >
      <avue-form
        :option="jcxflOption1"
        @reset-change="resetChange1"
        @submit="handleSubmit1"
        v-model="jcxflForm1"
      >
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index.vue";
import Template from "@/views/message/template/list.vue";
import * as API from "@/api/safetyTtandard/sortSetting";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import GridLayout from "@/views/components/layout/grid-layout";
import { dictionaryBiz } from "@/api/reportTasks";
export default {
  name: "index",
  components: { Template, GridLayout, CommonTree },
  props:{

  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    corePersonnelTableOption() {
      let column = [
        {
          label: "隐患描述",
          prop: "content",
          align: "left",
          overHidden: true,
        },
      ];
      return {

        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        column: column,
      };
    },
    jcxflOption() {
      return {
        submitBtn:false,
        emptyBtn:false,
        span: 24,
        activeName: "",
        column: [
          {
            span: 24,
            props: {
              children: "children",
              label: "hdTypeName",
              value: "id",
            },
            dicData: this.treeData,
            type: "tree",
            labelWidth: 200,
            clearable: true,
            label: "上级检查项分类",
            prop: "parentId",
          },
          {
            span: 24,
            labelWidth: 200,
            maxlength: 20,
            showWordLimit: true,
            label: "分类名称",
            prop: "hdTypeName",
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    jcxflOption1() {
      return {
        span: 24,
        emptyText: "取消",
        emptyIcon: "x",
        submitIcon: "x",
        column: [
          {
            span: 24,
            labelWidth: 200,
            label: "隐患描述",
            maxlength: 225,
            showWordLimit: true,
            type: "textarea",
            rows: 3,
            prop: "content",
            rules: [
              {
                required: true,
                message: "请输入隐患描述",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
  },
  data() {
    return {
      activeName: "1",
      selectTree: undefined,
      jcxflForm: {},
      jcxflForm1: {},
      jcxflModel: false,
      jcxflModel1: false,
      TableLoading: false,
      MD_SECTION: [],
      tableData: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      defaultProps: {
        children: "children",
        label: "hdTypeName",
      },
      corePersonnelTreeData: [],
      treeData: [],
      corePersonnelHeadBtnOptions: [
        { label: "新增", emit: "head-add", type: "button", icon: "" },
        // {label: "导入", emit: "handleImport", type: "button", icon: ""},
        // {label: "导出", emit: "head-export", type: "button", icon: ""},
        { label: "删除", emit: "head-del", type: "button", icon: "" },
      ],
      defaultExpandedKeys: [],
    };
  },
  async mounted() {
    this.MD_SECTION = await dictionaryBiz("MD_SECTION").then(
      (res) => res.data.data
    );
    this.typeTree();
  },
  methods: {
    handleClick() {
      this.typeTree();
    },
    resetChange() {
      this.jcxflModel = false;
      this.jcxflForm = {};
    },
    resetChange1() {
      this.jcxflModel1 = false;
      this.jcxflForm1 = {};
    },
    handleSubmit(form, done) {
      let data = {
        ...form,
        parentId: this.jcxflForm.parentId || -1,
      };
      API.submit(data)
        .then((res) => {
          this.jcxflModel = false;
          this.$message.success(res.data.msg);
          this.typeTree();
        })
        .catch((err) => {
          done();
        });
    },
    handleSubmit1(form, done) {
      API.sthdlistSubmit({
        hdTypeId: this.selectTree.id,
        sectionId: this.activeName,
        ...form,
      })
        .then((res) => {
          this.jcxflModel1 = false;
          this.$message.success(res.data.msg);
          this.onLoad(this.page);
        })
        .catch((err) => {
          done();
        });
    },
    getTreeAdd(row) {
      this.jcxflForm = {
        parentId: row.id || -1,
        sectionId: this.activeName,
      };
      this.treeData = this.corePersonnelTreeData;
      this.jcxflModel = true;
    },
    getTreeDelete(data) {
      if (this.tableData.length != 0 || data.children.length != 0)
        return this.$message.error(
          "当前分类存在数据，或者下级，请先删除下级或者隐患描述数据"
        );
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", data.id);
          return API.remove(formData);
        })
        .then(() => {
          this.$refs.commonTreeCore.nodeData = {};
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.typeTree();
        });
    },
    corePersonnelTreeNodeClick(row) {
      this.selectTree = row;
      this.onLoad(this.page);
    },
    getTreeEdit(row) {
      this.jcxflForm = {
        ...row,
      };
      const data = JSON.parse(JSON.stringify(this.corePersonnelTreeData));
      this.treeData = this.getParentData(data);
      this.jcxflModel = true;
    },
    getParentData(data, id) {
      return data.map((val, index) => {
        if (val.id == this.jcxflForm.id) {
          data.splice(index, 1);
        } else if (val.children) {
          this.getParentData(val.children, this.jcxflForm.id);
          return val;
        } else {
          return val;
        }
      });
    },
    handleImport() {
      // if (!this.selectTree?.isObj)
      //   return this.$message.success("请选择检查项对象");
      this.excelBox = true;
      this.excelOption.excelForm.excelFile = [];
    },
    headExport() {
      if (!this.selectTree) return this.$message.success("请选择隐患分类");
      exportBlob(
        `/api/sinoma-hse-prj//hdcheckindex/export?objectId=${this.selectTree.id}`
      ).then((res) => {
        downloadXls(res.data, this.selectTree.typeName);
      });
    },
    async addjcx(row = {}) {
      if (!this.selectTree) return this.$message.success("请选择检查项对象");
      this.jcxflForm1 = row;
      this.jcxflModel1 = true;
      this.$forceUpdate();
    },
    headDel() {
      this.rowDel(this.selectionList);
    },
    rowDel(data) {
      this.selectionList = data;
      let idsarr = [];
      this.selectionList.forEach((ele) => {
        idsarr.push(ele.id);
      });
      let ids = idsarr.join(",");
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", ids);
          return API.sthdlistRemove(formData);
        })
        .then(() => {
          this.selectionList = [];
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.onLoad(this.page);
        });
    },
    selectionChange(data) {
      this.selectionList = data;
    },
    onLoad(page, params = {}) {
      this.page = page || this.page;
      this.tableLoading = true;
      API.getList(this.page.currentPage, this.page.pageSize, {
        ...params,
        ...this.query,
        hdTypeId: this.selectTree.id,
        sectionId: this.activeName,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    typeTree() {
      this.tableData=[]
      this.corePersonnelTreeData=[]
      this.$refs.commonTreeCore.nodeData = {};
      this.selectTree = {};
      API.getTree({
        sectionId: this.activeName,
      }).then((res) => {
        this.corePersonnelTreeData = res.data.data;
        if (res.data.data.length != 0) {
          this.selectTree = res.data.data[0] || {};
          if (!this.selectTree.id) return;
          this.defaultExpandedKeys = [this.selectTree.id];
          this.$nextTick(() => {
            this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
              this.selectTree.id
            );
            this.$refs.commonTreeCore.nodeData = this.selectTree;
            this.corePersonnelTreeNodeClick(this.selectTree);
          });
        }
      });
    },
  },
};
</script>
<style  lang="scss" scoped>
.sortSetting{
  .table-box {
    flex: 1;
  }
  .common_tree_handle .el-tree{
    height: calc(100vh - 272px) !important;
  }
}
::v-deep .el-tabs__content{
  padding: 0 !important;
}
</style>
