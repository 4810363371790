var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showDialog
    ? _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: {
            title: "算法模型",
            top: "5vh",
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showDialog,
            width: "1240px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c(
                    "div",
                    { staticClass: "headers" },
                    [
                      _c("grid-head-layout", {
                        ref: "gridHeadLayout",
                        attrs: {
                          "head-title": _vm.$t(
                            "cip.plat.sys.user.title.indexHeadTitle"
                          ),
                          "search-columns": _vm.searchColumns,
                        },
                        on: {
                          "grid-head-search": _vm.searchChange,
                          "grid-head-empty": _vm.searchReset,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    staticClass: "userSelectGridLayout",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "data-total": _vm.page.total,
                      page: _vm.page,
                      tableOptions: _vm.tableOptions,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                      gridRowBtn: _vm.gridRowBtn,
                    },
                    on: {
                      "page-size-change": _vm.onLoad,
                      "page-current-change": _vm.onLoad,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "version",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择类型" },
                                  model: {
                                    value: row.version,
                                    callback: function ($$v) {
                                      _vm.$set(row, "version", $$v)
                                    },
                                    expression: "row.version",
                                  },
                                },
                                _vm._l(row.availableVersions, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: { label: item, value: item },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      674766185
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.closeDialog },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + "\n    "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }