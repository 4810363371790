var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "占位提示" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData.placeholder,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "placeholder", $$v)
              },
              expression: "activeData.placeholder",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-cascader", {
            key: _vm.cascaderKey,
            attrs: {
              options: _vm.activeData.options,
              clearable: "",
              "show-all-levels": _vm.activeData["show-all-levels"],
              props: _vm.activeData.props.props,
              filterable: "",
            },
            model: {
              value: _vm.activeData.__config__.defaultValue,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
              },
              expression: "activeData.__config__.defaultValue",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "选项分隔符" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入选项分隔符" },
            model: {
              value: _vm.activeData.separator,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "separator", $$v)
              },
              expression: "activeData.separator",
            },
          }),
        ],
        1
      ),
      [
        _c("el-divider", [_vm._v("数据选项")]),
        _c(
          "el-form-item",
          { attrs: { label: "", "label-width": "40px" } },
          [
            _c(
              "el-radio-group",
              {
                staticStyle: { "text-align": "center" },
                attrs: { size: "small" },
                on: { change: _vm.dataTypeChange },
                model: {
                  value: _vm.activeData.__config__.dataType,
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData.__config__, "dataType", $$v)
                  },
                  expression: "activeData.__config__.dataType",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "static" } }, [
                  _vm._v("静态数据"),
                ]),
                _c("el-radio-button", { attrs: { label: "dictionary" } }, [
                  _vm._v("数据字典"),
                ]),
                _c("el-radio-button", { attrs: { label: "dynamic" } }, [
                  _vm._v("远端数据"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm.activeData.__config__.dataType === "static"
          ? [
              _c("el-tree", {
                attrs: {
                  draggable: "",
                  data: _vm.activeData.options,
                  "node-key": "id",
                  "expand-on-click-node": false,
                  "render-content": _vm.renderContent,
                  props: _vm.props,
                },
              }),
              _c(
                "div",
                { staticStyle: { "margin-left": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "text",
                      },
                      on: { click: _vm.addTreeItem },
                    },
                    [_vm._v("添加父级\n        ")]
                  ),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm.activeData.__config__.dataType === "dictionary"
          ? [
              _c(
                "el-form-item",
                { attrs: { label: "数据字典" } },
                [
                  _c("SNOWS-TreeSelect", {
                    attrs: {
                      options: _vm.dicOptions,
                      placeholder: "请选择数据字典",
                      lastLevel: "",
                      clearable: "",
                    },
                    on: { change: _vm.dictionaryTypeChange },
                    model: {
                      value: _vm.activeData.__config__.dictionaryType,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.activeData.__config__,
                          "dictionaryType",
                          $$v
                        )
                      },
                      expression: "activeData.__config__.dictionaryType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "存储字段" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择存储字段" },
                      model: {
                        value: _vm.activeData.props.props.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData.props.props, "value", $$v)
                        },
                        expression: "activeData.props.props.value",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "id", value: "id" } }),
                      _c("el-option", {
                        attrs: { label: "enCode", value: "enCode" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm.activeData.__config__.dataType === "dynamic"
          ? [
              _c(
                "el-form-item",
                { attrs: { label: "远端数据" } },
                [
                  _c("interface-dialog", {
                    attrs: {
                      value: _vm.activeData.__config__.propsUrl,
                      title: _vm.activeData.__config__.propsName,
                      popupTitle: "远端数据",
                    },
                    on: { change: _vm.propsUrlChange },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "存储字段" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入存储字段" },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.activeData.props.props.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData.props.props, "value", $$v)
                      },
                      expression: "activeData.props.props.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示字段" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入显示字段" },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.activeData.props.props.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData.props.props, "label", $$v)
                      },
                      expression: "activeData.props.props.label",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "子级字段" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入子级字段" },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.activeData.props.props.children,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData.props.props, "children", $$v)
                      },
                      expression: "activeData.props.props.children",
                    },
                  }),
                ],
                1
              ),
              _vm.activeData.__config__.templateJson &&
              _vm.activeData.__config__.templateJson.length
                ? _c(
                    "el-table",
                    { attrs: { data: _vm.activeData.__config__.templateJson } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          width: "50",
                          label: "序号",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "field", label: "参数名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "required-sign" }, [
                                    _vm._v(
                                      _vm._s(scope.row.required ? "*" : "")
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.fieldName
                                          ? scope.row.field +
                                              "(" +
                                              scope.row.fieldName +
                                              ")"
                                          : scope.row.field
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1504190679
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "value", label: "表单字段" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择表单字段",
                                        clearable: "",
                                        filterable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onRelationFieldChange(
                                            $event,
                                            scope.row
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.relationField,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "relationField",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.relationField",
                                      },
                                    },
                                    _vm._l(
                                      _vm.formFieldsOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.realVModel,
                                          attrs: {
                                            label: item.realLabel,
                                            value: item.realVModel,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3708884993
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          : _vm._e(),
        _c("el-divider"),
      ],
      _c(
        "el-form-item",
        { attrs: { label: "展示全路径" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData["show-all-levels"],
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "show-all-levels", $$v)
              },
              expression: "activeData['show-all-levels']",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "能否清空" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.clearable,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "clearable", $$v)
              },
              expression: "activeData.clearable",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "能否搜索" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.filterable,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "filterable", $$v)
              },
              expression: "activeData.filterable",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "能否多选" } },
        [
          _c("el-switch", {
            on: { change: _vm.onChange },
            model: {
              value: _vm.activeData.props.props.multiple,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.props.props, "multiple", $$v)
              },
              expression: "activeData.props.props.multiple",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
      _c("treeNode-dialog", {
        attrs: { visible: _vm.dialogVisible, title: "添加选项" },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          commit: _vm.addNode,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }