<template>
  <div style="position: relative">
    <div>
      <head-layout head-title="相关方企业证照"></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowUpdate(row)"
            >编辑
          </el-button>
        </template>
      </grid-layout>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/safe/thirdcompany";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      query: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },

      tableOption: {
        // linklabel: "companyName",
        selection: false,
        column: [
          {
            label: "公司名称",
            prop: "companyName",
            align: "center",
          },
          {
            label: "统一社会信用代码",
            prop: "companyCode",
            align: "center",
          },
          {
            label: "法定代表人",
            prop: "legalRep",
            align: "center",
          },
          {
            label: "注册详细位置",
            prop: "regLocation",
            align: "center",
          },
          {
            label: "成立日期",
            prop: "curDate",
            align: "center",
          },
          {
            label: "状态",
            prop: "isUsed",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
            align: "center",
          },
        ],
      },

      searchColumns: [
        {
          label: "项目名称",
          prop: "companyCode",
          span: 4,
          placeholder: "公司名称或统一社会信用代码",
        },
        {
          label: "公司状态",
          prop: "isUsed",
          span: 4,
          type: "select",
          placeholder: "请选择公司状态",
          dataType: "number",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
        },
        {
          label: "成立日期",
          prop: "curDate",
          span: 4,
          type: "date-format",
          placeholder: "请选择成立日期",
        },
      ],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.thirdcompany_add, false),
        viewBtn: this.vaildData(this.permission.thirdcompany_view, false),
        delBtn: this.vaildData(this.permission.thirdcompany_delete, false),
        editBtn: this.vaildData(this.permission.thirdcompany_edit, false),
      };
    },
  },

  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    rowUpdate(row) {
      this.$router.push({
        path: "/business/responsibility/relatedCompanyEnterprise/form",
        query: {
          row: JSON.stringify(row),
          type: "edit",
        },
      });
    },
    //查看
    rowView(row) {
      this.$refs.addThirdCompany.init("view", row);
    },

    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
  },
};
</script>

<style></style>
