var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "机构分组",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-add": _vm.headAdd },
      }),
      _c("grid-head-layout", {
        ref: "searchForm",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayout",
        attrs: {
          searchColumns: _vm.searchColumns,
          tableOptions: _vm.tableOptions,
          tableLoading: _vm.tableLoading,
          gridRowBtn: _vm.gridRowBtn,
          "data-total": _vm.page.total,
          tableData: _vm.tableData,
          page: _vm.page,
        },
        on: {
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-edit": _vm.rowEdit,
          "row-remove": _vm.rowRemove,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }