<template>
  <el-slider
    disabled
    v-model="value"
    :style="width"
    :max="maxNumber"
    @mousemove.native="updateTooltipPosition"
  ></el-slider>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    width: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },

  components: {},

  computed: {
    maxNumber() {
      return this.value > 100 ? this.value : 100;
    },
  },

  mounted() {},

  methods: {
    updateTooltipPosition(e) {
      let devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      let scale = devicewidth / 1920;
      if (
        e.target.className == "el-slider__button-wrapper" ||
        e.target.className == "el-slider__button-wrapper hover"
      ) {
        let tooltipId = e.target.firstChild.getAttribute("aria-describedby");
        let tooltipDom = document.getElementById(tooltipId);
        tooltipDom.style.display = "none"
          let top = this.subNum(tooltipDom.style.top);
          let left = this.subNum(tooltipDom.style.left);
          setTimeout(() => {
          tooltipDom.style.top = Math.round(top * scale) + "px";
          tooltipDom.style.left = Math.round(left * scale) + "px";
          tooltipDom.style.display = "block";
          }, 200);
      }
    },
    subNum(str) {
      let strIndex = str.indexOf("px");
      let strNum = str.substring(0, strIndex);
      return Number(strNum);
    },
  },
};
</script>

<style scoped lang='scss'>
</style>
