<template>
  <div >
    <head-layout
      :head-title="$t('cip.dc.AiModel.name')"
    >
    </head-layout>

         <div class="headers" style="display: flex">
         <el-input style="width: 200px;" :placeholder="$t('cip.dc.AiResult.msg')" v-model="queryList.modelName" class="new-sino-input" size="mini" />
          <el-button-group style="margin-left: 10px;">
          <el-button size="mini" type="primary" icon="el-icon-search"
           @click="onLoad('search')"></el-button>
          <el-button size="mini"  icon="el-icon-brush" @click="czFn"></el-button>
          </el-button-group>
      </div>

      <grid-layout
        ref="gridLayout"
        :data-total="total"
        @page-size-change="handleSizeChange"
        @page-current-change="handleCurrentChange"
        @page-refresh-change="onLoad"
        :page="page"
        @gird-handle-select-click="selectionChange"
        :tableOptions="tableOptions"
        :tableData="tableData"
        :tableLoading="tableLoading"
        :gridRowBtn="gridRowBtn"
        @grid-yuce="yuceFn"
        @grid-detail="detailFn"
        @grid-del="deleteFn"
        :searchColumns="searchColumns"

      >

      </grid-layout>
  </div>
</template>

<script>
import {
  totalCoList,
  totalCollectUp,
  totalCollectDel, deleteDataSource,delAimodel
} from "@/api/dataAcquisition/index";
  import {getModelList} from '@/api/Ai/index'
  import { mapGetters } from "vuex";
  import website from '@/config/website';
  import HeadLayout from "@/views/components/layout/head-layout"
  import GridLayout from "@/views/components/layout/grid-layout";
  import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
  import formLayout from '@/views/components/layout/form-layout'
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
  export default {
    components: {
      HeadLayout,
      GridLayout,
      dialogHeadBtn,
      formLayout
    },
    data() {
      return {
        tableLoading:false,
        tableOptions: {
          customRowAddBtn: false,
          menuWidth: 200,
          column: [
            // {
            //   label: this.$t('AiModel.label.id'),
            //   prop: "id",
            //   cell: true,
            //   // slot:true
            // },
            {
              label: this.$t('cip.dc.AiModel.field.model_name'),
              prop: "model_name",
              cell: true,
            },
            {
              label: this.$t('cip.dc.AiModel.field.algorithm_name'),
              prop: "algorithm_name",
              cell: true,
              width:250
            },
            // {
            //   label: this.$t('AiModel.label.model_input'),
            //   prop: "model_input",
            //   cell: true,
            // },
            // {
            //   label: this.$t('AiModel.label.model_path'),
            //   prop: "model_path",
            //   cell: true,
            // },

             {
              label: this.$t('cip.dc.DTRecord.field.ThemeDomain'),
              prop: "themePname",
              cell: true,
            },
            {
              label: this.$t('cip.dc.DTRecord.field.theme'),
              prop: "themeName",
              cell: true,
            },
            {
              label: this.$t('cip.dc.QTemplate.field.DataObject'),
              prop: "dataObjectName",
              cell: true,
            },

            {
              label: this.$t('cip.dc.DTManagement.field.Creator'),
              prop: "createby",
              cell: true,
            },

            {
              label: this.$t('cip.dc.DTManagement.field.CreationTime'),
              prop: "create_time",
              cell: true,
            },



            // {
            //   label: this.$t('AiModel.label.model_evaluation'),
            //   prop: "model_evaluation",
            //   cell: true,
            // },

          ],

        },
        gridRowBtn: [

          {
            label: this.$t('cip.cmn.btn.viewBtn'),
            // label: "查看",
            emit: "grid-yuce",
            remark: 'yuce',
            type: "text",
            icon: ""
          },
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            // label: "删除",
            emit: "grid-del",
            remark: 'del',
            type: "text",
            icon: ""
          },
        ],
        page: {
          pageSize: PAGE_CONSTANT.TWENTY,
          currentPage: 1,
          total: 0
        },
        activeName: '1',
        multipleSelection:[],
        tableData: [],
        total:0,
        queryList:{
            pageNum:1,
            pageSize:10,
          // param1:'',
          modelName:""

        },
        // headBtnOptions: [{
        //   label: this.$t(`cip.cmn.btn.addBtn`),
        //   emit: "head-add",
        //   type: "button",
        //   icon: ""
        // }],
        form: null,
        formLabelWidth: '120px',
        dialogFormVisible:false,
        loading:false,

      };
    },
    computed: {
      ...mapGetters(["userInfo", "permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.dept_add, false),
          viewBtn: this.vaildData(this.permission.dept_view, false),
          delBtn: this.vaildData(this.permission.dept_delete, false),
          editBtn: this.vaildData(this.permission.dept_edit, false)
        };
      },
    },
    methods: {
      onLoad(e) {
        this.loading = true;
        if(e == "search"){
          this.queryList.pageNum = 1
        }

        getModelList(this.queryList).then(res => {
          this.loading = false;
          this.tableData = res.data.data.list;

        this.page.total = res.data.data.total
        this.page.pageSize = res.data.data.pageSize
        this.page.currentPage = res.data.data.pageNum

        });
      },
      detailFn(scope){
        this.$router.push({
          path: '/Ai/AimodelDetail',
          query: {
            type: 'detail',
            id:scope.id
          }
        })
      },
      yuceFn(scope){
        this.$router.push({
          path: '/AimodelDetail',
          query: {
            type: 'yuce',
            id:scope.id,
            algorithm_id:scope.algorithm_id


          }
        })
      },
      handleEdit(scope){
        this.$router.push({
          path: '/AimodelDetail',
          query: {
            type: 'yuce',
            id:scope.id
          }
        })
      },
      deleteFn(e){
        // if(this.multipleSelection.length == 0){
        //   this.$message.error('请选择删除数据')
        //   return
        // }
        // let ids = this.multipleSelection.map((item)=>{
        //   console.log(item,item.id)
        //   return item.id
        // })
        // this.$confirm('确定删除, 是否继续?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   totalCollectDel(ids).then((res)=>{
        //     this.$message.success('删除成功');
        //     this.onLoad()
        //   })
        // }).catch(() => {

        // });
          if(e){
              this.$confirm(this.$t('cip.dc.shunt.msg.isDelect'), this.$t('cip.dc.dataSubject.msg.Tips') , {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: 'warning'
        }).then(() => {
          delAimodel({model_id:e.id}).then(res=>{
              this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'));
              this.onLoad()

})
        }).catch(() => {

        });

          }


      },
      czFn(){
        this.queryList.modelName = '';
        this.queryList.pageNum = 1
        this.queryList.pageSize = 10
        this.onLoad()
      },
      selectionChange(val) {
        this.multipleSelection = val;

      },
      handleSizeChange(val) {
        this.page.pageSize  = val.pageSize;
        this.queryList.pageSize = val.pageSize
       this.onLoad()

      },
      handleCurrentChange(val) {
        this.page.currentPage  = val.currentPage;
        this.queryList.pageNum = val.currentPage
        this.onLoad()
      },
    },
    created() {
      this.onLoad();
    },
    mounted() {
    },
  };
</script>
<style lang="less" scoped>
.search-form::v-deep{
   background: #FFFFFF;
   padding: 14px 12px;
  .el-form-item--small .el-form-item__content{
    line-height: 28px !important;
  }
  .el-button{
    height: 28px;
    margin-left:0;
   }
  .el-input__inner{
   height: 28px;
   line-height: 28px;
  }
}
</style>
<style scoped>
.el-button+.el-button {
  margin-left: 0px;
}
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}
</style>
