<template>
    <div style="position: relative">
        <el-container>
            <!-- <CommonTree ref="commonTreeCore" treeTitle="组织架构" :searchTitle="searchTitle" :treeData="treeData"
                :defaultProps="defaultProps" @getNodeClick="handleNodeClick" :isShowdig="false" :showCheckbox="false"
                node-key="id" :isZoom="true" :show="show" @showChange="showChange" /> -->
            <CommonTreeNew
                ref="commonTreeCore"
                treeTitle="组织架构"
                :defaultProps="defaultProps"
                :showCheckbox="false"
                @getNodeClick="handleNodeClick"
                :urlParmas="{
                  tenantId: '',
                  deptCategory: '2',
                  parentId: userInfo.dept_id,
                }"
                node-key="id"
                :isShowdig="false"
                :isZoom="true"
                :show="show"
                @showChange="showChange"
            ></CommonTreeNew>
            <el-main>
                <head-layout head-title="安全奖惩" :head-btn-options="headBtnOptions" @head-add="handleHeadAdd" />
                <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns"
                    @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
                <grid-layout ref="griLayOut" :grid-row-btn="gridRowBtn" :table-options="tableOPtions"
                    :table-data="tableData" :table-loading="tableLoading" :data-total="page.total" :page="page"
                    :editType="inline" @page-current-change="handleCurrentChange" @page-size-change="handleSizeChange"
                    @page-refresh-change="onLoad" @row-view="rowView" @row-edit="rowEdit" @row-del="rowDel">
                    <template #decideDocument="{ row, index }">
                        <el-link type="primary" @click="rowView(row)">{{ row.decideDocument }}</el-link>
                    </template>
                </grid-layout>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { mapGetters } from 'vuex'
import {getDeptTree} from "@/api/system/dept";
import { decideheadPage, decideheadRemove } from "@/api/responsibility/grsafetydecide"

export default {
    components: {
        CommonTree,
        HeadLayout,
        GridLayout
    },

    data() {
        return {
            treeData: [],
            searchTitle: 'title',
            defaultProps: {
                children: "children",
                label: "title",
            },
            nodeData: {},
            searchColumns: [
                {
                    prop: 'decideDocument',
                    placeholder: '文书名称',
                    span: 4,
                },
                {
                    prop: 'decideDate',
                    placeholder: '奖惩时间',
                    span: 4,
                    type: 'date-format',
                }
            ],
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            query: {},
            show: false
        }
    },

    created() {
       // this.lazyDeptTree()
    },

    computed: {
        ...mapGetters(["permission", 'colorName','userInfo']),

        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.grsafetydecide_add, true),
                viewBtn: this.vaildData(this.permission.grsafetydecide_view, true),
                editBtn: this.vaildData(this.permission.grsafetydecide_edit, true),
                delBtn: this.vaildData(this.permission.grsafetydecide_delete, true),
            };
        },
        headBtnOptions() {
            let buttonBtn = [];
            if (this.permissionList.addBtn) {
                buttonBtn.push({
                    emit: "head-add",
                    type: "button",
                    icon: "",
                    btnOptType: 'add',
                });
            }
            return buttonBtn;
        },

        tableOPtions() {
            return {
                menuFixed: 'right',
                customRowAddBtn: false,
                menuWidth: 160,
                selection: false,
                column: [
                    {
                        label: '奖惩文书名称',
                        prop: 'decideDocument',
                        align: 'center',
                        overHidden: true,
                        slot: true,
                    },
                    {
                        label: '颁发机构',
                        prop: 'decideOrg',
                        align: 'left',
                        overHidden: true,
                        dicData: this.treeData,
                        props: {
                            value: 'id',
                            label: 'deptName'
                        }
                    },
                    {
                        label: '奖惩类型',
                        prop: 'decideType',
                        align: 'center',
                        overHidden: true,
                        dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=decide_type",
                        props: {
                            label: "dictValue",
                            value: "dictKey"
                        },
                    },
                    {
                        label: '奖惩时间',
                        prop: 'decideDate',
                        align: 'center',
                        overHidden: true
                    },
                    {
                        label: '登记人',
                        prop: 'createUserName',
                        align: 'center',
                        overHidden: true
                    },
                    {
                        label: '登记时间',
                        prop: 'createTime',
                        align: 'center',
                        overHidden: true
                    },
                ]
            }
        },

        gridRowBtn() {
            let buttonBtn = [];
            if (this.permissionList.viewBtn) {
                buttonBtn.push(
                    {
                        label: this.$t(`cip.cmn.btn.viewBtn`),
                        emit: "row-view",
                        type: "button",
                        icon: ""
                    })
            }
            if (this.permissionList.editBtn) {
                buttonBtn.push(
                    {
                        label: this.$t(`cip.cmn.btn.editBtn`),
                        emit: "row-edit",
                        type: "button",
                        icon: ""
                    });
            }
            if (this.permissionList.delBtn) {
                buttonBtn.push(
                    {
                        label: this.$t(`cip.cmn.btn.delBtn`),
                        emit: "row-del",
                        type: "button",
                        icon: ""
                    })
            }
            return buttonBtn;
        },
    },

    methods: {
        showChange(v) {
            this.show = v;
        },
        /** 左侧列表操作 */
        //获取树数据
        lazyDeptTree() {
            const parentId = this.userInfo.dept_id;
            getDeptTree('', '', parentId).then((res) => {
                if (res.data.code == 200) {
                    this.treeData = res.data.data
                    if (res.data.data.length > 0) {
                        this.$refs.commonTreeCore.setCurrentKey(this.treeData[0].id)
                        // this.$refs.commonTreeCore.nodeClick(this.treeData[0], {});
                        this.handleNodeClick(this.treeData[0])
                    }
                }
            });
        },
        handleNodeClick(data, node) {
            this.nodeData = data
            this.page.currentPage = 1;
            this.query.belongOrgId = data.id
            this.onLoad(this.page)
        },

        handleHeadAdd() {
            if (this.nodeData.id) {
                this.$router.push({
                    path: '/business/responsibility/grsafetydecide/add',
                    query: {
                        row: JSON.stringify({ belongOrgId: this.nodeData.id }),
                        type: 'add',
                    }
                });
            } else {
                this.$message.warning('请先选择左侧组织机构')
            }
        },

        /** 右侧文书数据操作 */
        onLoad(page, params = {}) {
            this.tableLoading = true;
            this.page = page;
            decideheadPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                // this.$refs.gridLayOut.page.total = data.total;
                this.page.total = data.total;
                this.tableData = data.records ? data.records.map(val => {
                    val.decideType = val.decideType.toString()
                    return val
                }) : []
                this.tableLoading = false;
                // this.$refs.gridLayOut.selectionClear()
            });
        },
        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        // 搜索
        gridHeadSearch(query) {
            const data = this.query
            this.page.currentPage = 1;
            this.query = { ...data, ...query };
            this.onLoad(this.page)
        },
        // 重置
        gridHeadEmpty(query) {
            this.page.currentPage = 1;
            const data = this.query
            this.query = { ...data, ...query };
            this.onLoad(this.page)
        },

        rowView(row, index) {
            this.$router.push({
                path: '/business/responsibility/grsafetydecide/view',
                query: {
                    row: JSON.stringify({ id: row.id }),
                    type: 'view',
                }
            });
        },
        rowEdit(row, index) {
            this.$router.push({
                path: '/business/responsibility/grsafetydecide/edit',
                query: {
                    row: JSON.stringify({ id: row.id }),
                    type: 'edit',
                }
            });
        },
        rowDel(row, index) {
            this.$confirm("确定删除当前数据?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                decideheadRemove(row.id).then(() => {
                    this.onLoad(this.page);
                    this.$message.success('操作成功！')
                })
            });
        },
    }
}
</script>

<style></style>
