var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            permission: _vm.permissionList,
            "before-open": _vm.beforeOpen,
            "before-close": _vm.beforeClose,
          },
          on: {
            "row-del": _vm.rowDel,
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "isSealed",
              fn: function ({ row }) {
                return [
                  _c("el-tag", [
                    _vm._v(_vm._s(row.isSealed === 0 ? "否" : "是")),
                  ]),
                ]
              },
            },
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "text",
                        icon: "el-icon-check",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleAdd(scope.row, scope.index)
                        },
                      },
                    },
                    [_vm._v("新增子项\n      ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _vm.permission.dict_delete
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                        plain: "",
                      },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("删 除\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }