var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: { "head-title": _vm.$t("cip.dc.AiModel.name") },
      }),
      _c(
        "div",
        { staticClass: "headers", staticStyle: { display: "flex" } },
        [
          _c("el-input", {
            staticClass: "new-sino-input",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("cip.dc.AiResult.msg"), size: "mini" },
            model: {
              value: _vm.queryList.modelName,
              callback: function ($$v) {
                _vm.$set(_vm.queryList, "modelName", $$v)
              },
              expression: "queryList.modelName",
            },
          }),
          _c(
            "el-button-group",
            { staticStyle: { "margin-left": "10px" } },
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-search",
                },
                on: {
                  click: function ($event) {
                    return _vm.onLoad("search")
                  },
                },
              }),
              _c("el-button", {
                attrs: { size: "mini", icon: "el-icon-brush" },
                on: { click: _vm.czFn },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("grid-layout", {
        ref: "gridLayout",
        attrs: {
          "data-total": _vm.total,
          page: _vm.page,
          tableOptions: _vm.tableOptions,
          tableData: _vm.tableData,
          tableLoading: _vm.tableLoading,
          gridRowBtn: _vm.gridRowBtn,
          searchColumns: _vm.searchColumns,
        },
        on: {
          "page-size-change": _vm.handleSizeChange,
          "page-current-change": _vm.handleCurrentChange,
          "page-refresh-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
          "grid-yuce": _vm.yuceFn,
          "grid-detail": _vm.detailFn,
          "grid-del": _vm.deleteFn,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }