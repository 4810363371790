var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title":
                this.$route.query.type == "edit"
                  ? `[${_vm.tittle}]` +
                    _vm.$t("cip.plat.sys.dict.title.editConfigHeadTitle")
                  : `[${_vm.tittle}]` +
                    _vm.$t("cip.plat.sys.dict.title.addConfigHeadTitle"),
            },
            on: {
              "head-save": function ($event) {
                return _vm.headSave("save")
              },
              "head-save-back": function ($event) {
                return _vm.headSave("back")
              },
              "head-cancel": _vm.headCancel,
            },
          }),
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.optionChild.column, dataForm: _vm.dataObj },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }