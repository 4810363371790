<!--
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-05-29 10:18:59
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" width="40%" class="qmDialog" @close="closeDialog">
    <avue-form :option="options" ref="addForm" v-model="form" @submit="treeNodeSave"></avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { add} from "@/api/system/dept";
  import {mapGetters} from "vuex";
  export default {
    data() {
      return {
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        title: '',
        pageType:'',
        parentList: [],
      }
    },

    computed: {
      ...mapGetters(["language"]),
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 120,
          column: [{
              label: "组织名称",
              prop: "deptName",
               props:{ label: 'title',value: 'id' },
              rules: [{
                required: true,
                message: "请输入组织名称",
                trigger: "blur"
              }]
            },
            {
              label: "上级组织",
              prop: "parentId",
              type: 'tree',
              disabled:true,
              dicData: this.parentList,
              props: {
                label: "title",
                value: "id"
              }
            },
            {
              label: "单位类型",
              prop: "deptType",
              rules: [{
                required: false,
                message: "请输入单位类型",
                trigger: "blur"
              }],
              type: 'select',
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=third_company_type",
            },
            {
              label: '是否启用',
              prop: 'isEnable',
              type: 'switch',
              dicData: [{
                label: '',
                value: 1
              }, {
                label: '',
                value: 2
              }],
            },
          ],
        }
      }
    },
    methods: {
      uuid() {
        return 'xxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      },
      init(type, data, {node,list}) {
        this.form = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.pageType=type
        this.parentList = list
        if (type == 'edit') {
          this.title = '编辑项目组织'
          this.form ={
            ...data,
            deptName:data.title,
          }
        } else {
          this.title = '新增项目组织'
          this.form={
            deptCategory:3,
            companyId:data,
            prjId:data,
            isEnable:2,
            deptCode:this.uuid(),
            parentId:node.id,
            thirdFlg:node.thirdFlg,
          }
        }
      },
      // 新增左侧树节点
      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      treeNodeSave(data) {
        if(this.pageType!='edit'){
          data.id = ''
        }
        let obj={
          [this.language]:this.form.deptName
        }
        this.$refs.addForm.validate((valid,done) => {
          if (valid) {
            this.dialogLoading = true
            add({
              ...data,
              fullName:JSON.stringify(obj),
              name:JSON.stringify(obj),
              deptName:JSON.stringify(obj),
            }).then(res => {
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(res.data.msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.showDialog = false;
              this.dialogLoading = false
            }).catch(err => {

            }).finally(() => {
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog(){
        this.form = {}
        this.$refs.addForm.resetFields();
        this.showDialog = false
    },
    }
  }

</script>
