var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c("CommonTreeNew", {
        attrs: {
          defaultProps: _vm.treePropss,
          searchTitle: "dictValue",
          isShowdig: false,
          showCheckbox: false,
          treeTitle: "风险等级",
          nodeKey: "dictKey",
          url: "/api/sinoma-system/dict-biz/dictionary",
          urlParmas: {
            code: "area_rank",
          },
          handleData: _vm.handleTreeData,
        },
        on: { getNodeClick: _vm.nodeTreeClick },
      }),
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": "区域维护",
            },
            on: {
              "head-addhighrisk": function ($event) {
                return _vm.handleAdd(3)
              },
              "head-addmediumrisk": function ($event) {
                return _vm.handleAdd(2)
              },
              "head-addlowrisk": function ($event) {
                return _vm.handleAdd(1)
              },
              "head-addsafe": function ($event) {
                return _vm.handleAdd(0)
              },
              "head-loseEfficacy": _vm.handleLoseEfficacy,
            },
          }),
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: { "search-columns": _vm.searchColumns },
            on: {
              "grid-head-search": _vm.gridHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.tableoptions,
              "table-data": _vm.tableData,
              "table-loading": _vm.loading,
              "data-total": _vm.page.total,
              page: _vm.page,
            },
            on: {
              "page-current-change": _vm.handleCurrentChange,
              "page-size-change": _vm.handleSizeChange,
              "page-refresh-change": _vm.onLoad,
              "gird-handle-select-click": _vm.selectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "areaRank",
                fn: function ({ row }) {
                  return [
                    row.areaRank == 3
                      ? _c("div", { style: { color: "#C5211B" } }, [
                          _vm._v("高危"),
                        ])
                      : row.areaRank == 2
                      ? _c("div", { style: { color: "#e6a23c" } }, [
                          _vm._v("\n          中危\n        "),
                        ])
                      : row.areaRank == 1
                      ? _c("div", { style: { color: "#0d7fea" } }, [
                          _vm._v("\n          低危\n        "),
                        ])
                      : row.areaRank == 0
                      ? _c("div", { style: { color: "#67c23a" } }, [
                          _vm._v("\n          安全\n        "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "areaState",
                fn: function ({ row }) {
                  return [
                    row.areaState == 1
                      ? _c("div", { style: { color: "#c5211b" } }, [
                          _vm._v("\n          失效\n        "),
                        ])
                      : _c("div", [_vm._v("正常")]),
                  ]
                },
              },
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    row.areaState == 0
                      ? _c(
                          "el-button",
                          {
                            style: { color: "#c5211b" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.loseEfficacy(row)
                              },
                            },
                          },
                          [_vm._v("\n          失效\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.risktitle,
            visible: _vm.dialogTree,
            width: "550px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTree = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              attrs: {
                multiple: "",
                placeholder: "请选择区域名称",
                "popper-append-to-body": false,
                clearable: "",
              },
              on: { "remove-tag": _vm.removetag, clear: _vm.clearall },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            [
              _c(
                "el-option",
                {
                  staticClass: "setstyle",
                  attrs: { value: _vm.selectTree, disabled: "" },
                },
                [
                  _c("el-input", {
                    ref: "input",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      clearable: "",
                      placeholder: "请输入地区名称",
                      "prefix-icon": "el-icon-search",
                      size: "small",
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _c("el-tree", {
                    ref: "treeRef",
                    attrs: {
                      props: _vm.treeProps,
                      "node-key": "id",
                      data: _vm.areaTreeList,
                      "show-checkbox": true,
                      "check-strictly": true,
                      "expand-on-click-node": false,
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { "check-change": _vm.handleNodeClick },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogTree = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.allotRights } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }