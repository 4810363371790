<template>
  <div>
    <el-container>
      <common-tree
        ref="commonTree"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="true"
        :loading="treeDialogLoading"
        :showCheckbox="false"
        treeTitle="试卷分类"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <head-layout
          head-title="试卷"
          :head-btn-options="headBtnOptions"
          @head-add-tabs="headAdd"
          @head-remove="headRemove"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowDetail"
        >
          <template #customBtn="{row}">
            <el-button
              type="text"
              v-if="permissionList.editBtn && row.paperStatus == 0"
              @click="rowEdit(row)"
            >编辑
            </el-button>
            <el-button
              type="text"
              v-if="permissionList.delBtn && row.paperStatus == 0"
              @click="rowRemove(row)"
            > 删除
            </el-button>
            <el-button
              type="text"
              v-if="permissionList.publishBtn && row.paperStatus == 0"
              @click="rowPublish(row)"
            > 发布
            </el-button>
            <el-button
              type="text"
              v-if="permissionList.lapseBtn && row.paperStatus == 1"
              @click="rowLapse(row)"
            >失效
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <CommonDialog v-if="showTreeDialog" width="60%" :showFullscreen="true" dialogTitle="试卷分类管理" @cancel="showTreeDialog = false" @confirm="handleTreeNodeSave">
      <avue-form :option="treeDialogOption" ref="addForm" v-model="form" @submit="treeNodeSave"></avue-form>
    </CommonDialog>
  </div>
</template>

<script>

import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {mapGetters} from "vuex";
import {getList, remove, publishEduPaper, eduPaperLapse} from "@/api/training/edupaper";
import {addEdu, getEduTree, removeEdu} from "@/api/training/examinationPaper";
import CommonTree from "@/views/components/com_tree/index"
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "edupaper",

  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    CommonDialog
  },
  data() {
    return {
      query: {},
      showTreeDialog:false,
      form:{},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      tableData: [],
      treeData: [],
      tableLoading: false,
      searchTitle: "name",
      treeDialogLoading: false,
      node:{},
      defaultProps: {
        label: "title",
        value: "id",
        key: "id"
      },
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.edupaper_add, true),
        viewBtn: this.vaildData(this.permission.edupaper_view, true),
        delBtn: this.vaildData(this.permission.edupaper_delete, true),
        editBtn: this.vaildData(this.permission.edupaper_edit, true),
        publishBtn: this.vaildData(this.permission.edupaper_publish, true),
        lapseBtn: this.vaildData(this.permission.eduPaper_lapse, true)
      };
    },
    searchColumns() {
      return [
        {
          prop: "code",
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "试卷编号",
          span: 4,

        },
        {
          prop: "paperName",
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "试卷名称",
          span: 4,
        },
        // {
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + "考试级别",
        //   prop: "lexaminationLevel",
        //   type: 'select',
        //   span: 4,
        //   dicData:[],
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        // },
        // {
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + "出题依据",
        //   prop: "accordingTo",
        //   type: 'select',
        //   span: 4,
        //   dicData:[],
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        // },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "试卷状态",
          prop: "paperStatus",
          type: 'select',
          span: 4,
          dicData:[],
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=paper_status',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permissionList.addBtn) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            icon: "",
            btnOptType: 'add',
          });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove",
            type: "button",
            icon: "",
            btnOptType: 'remove',
          });
      }
      return buttonBtn;
    },
    tableOptions() {
      return {
        linklabel: "code",
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "试卷编号",
            prop: "code",
            width: 180,
            align: 'left',
            overHidden: true,
          },
          {
            label: "试卷名称",
            prop: "paperName",
            align: 'left',
            overHidden: true,
          },
          // {
          //   label: "考试级别",
          //   prop: "lexaminationLevel",
          //   align: "center",
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
          //   dicData:[],
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   width: 110,
          //   overHidden: true,
          // },
          // {
          //   label: "出题依据",
          //   prop: "accordingTo",
          //   align: "center",
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
          //   dicData:[],
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   width: 150,
          //   overHidden: true,
          // },
          {
            label: "专业分类",
            prop: "subjectType",
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            dataType: 'number',
            align: 'center',
            width: 200,
            overHidden: true,
          },
          {
            label: "答题时长(分钟)",
            prop: "answerTime",
            align: 'right',
            width: 120,
            overHidden: true,
          },
          {
            label: "试卷状态",
            prop: "paperStatus",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=paper_status',
            dataType: 'number',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 110,
            align: 'center',
            overHidden: true,
          },
          // {
          //   label: "是否公布答案",
          //   prop: "openAnswer",
          //   type: 'select',
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=YES_NO',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   align: 'center',
          //   width: 120,
          //   overHidden: true,
          // },
        ]
      }
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "name",
            maxlength: 30,
            rules: [{
              required: true,
              message: "请输入分类名称",
              trigger: "blur"
            }]
          },
          {
            label: '上级名称',
            prop: 'parentId',
            type: 'tree',
            disabled: false,
            dicData: this.treeParentIdDicData,
            props: {
              label: 'title',
              value: 'id',
            },
            // rules: [{
            //   required: true,
            //   message: "请选择上级名称",
            //   trigger: "blur"
            // }]
          },
          {
            label: "编码",
            prop: "code",
            readonly: true,
            placeholder: "保存后自动生成",
            rules: [{
              required: false,
              message: "请输入编码",
              trigger: "blur"
            }]
          },
          // {
          //   label: "分类",
          //   prop: "cate",
          //   type: 'select',
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=CATEGORY',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   rules: [{
          //     required: false,
          //     message: "请输入编码",
          //     trigger: "blur"
          //   }]
          // },
          {
            label: "排序",
            prop: "sort",
            rules: [{
              required: true,
              message: "请输入排序",
              trigger: "blur"
            }]
          },
          {
            label: '备注',
            prop: 'note',
            type: 'textarea',
            maxlength: 100,
            overHidden: true,
            span: 24,
            minRows: "2",
            maxRows: "4",
            showWordLimit: true,
          }],
      }
    }
  },
  methods: {
    // 新增左侧树节点
    handleTreeNodeSave() {

      this.$refs.addForm.submit();
    },
    treeNodeSave(data, done) {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.treeDialogLoading = true
          if(!data.parentId){
            data.parentId = "0"
          }
          addEdu(data).then(res => {
            const {msg, data} = res.data;
            this.node = this.$refs.commonTree.nodeData = {}
            this.treeReadOnlyFlag = true;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            this.initTree();
            this.showTreeDialog = false;
          }).catch(err => {
            done()
          }).finally(() => {
            this.treeDialogLoading = false
          })
        }
      })
    },
    treeMenuAdd() {
      // if (this.node.parentId == '0') {
      this.form = {};
      this.form.parentId = this.node.id||'0';
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "题库分类不允许添加三级分类"
      //   });
      // }
    },
    treeMenuEdit() {
      this.form = this.node
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },
    treeNodeDel(data) {
      if (data.children && data.children.length != 0) {
        this.$message({
          type: "warning",
          message: "当前分类下有子项，禁止删除",
        });
        return;
      }
      if (this.tableData.length != 0) {
        this.$message({
          type: "warning",
          message: "当前试卷分类下有题目，禁止删除",
        });
        return;
      }
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        return removeEdu(data.id)
      }).then((res) => {
        if (res.data.msg == "操作成功") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        }
        this.node = this.$refs.commonTree.nodeData = {}
        this.initTree();
      })
    },
    initTree() {
      getEduTree(this.userInfo.tenantId)
        .then(res => {
          const {data} = res.data;
          this.treeData = data;
          const columns = this.findObject(this.$refs.gridLayOut.option.column, 'subjectType');
          this.treeParentIdDicData = res.data.data
          columns.dicData = res.data.data
        })
    },
    headAdd() {
      this.$router.push({
        path: '/training/paper/edupaperAdd',
        query: {
          type: 'add',
          node:this.node
        }
      });
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(this.$func.join(ids));
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.$refs.gridLayOut.selectionClear();
        });
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    rowDetail(row) {
      this.$router.push({
        path: '/training/paper/edupaperView',
        query: {
          row: JSON.stringify(row),
          type: 'view',
        }
      });
    },
    rowLapse(row) {
      this.$confirm("确认失效试卷?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        eduPaperLapse(row.id).then((res) => {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.onLoad(this.page)
        })
      })
    },
    rowPublish(row) {
      this.$confirm("确认发布试卷?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        publishEduPaper(row.id).then((res) => {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.onLoad(this.page)
        })
      })
    },
    rowRemove(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
    rowEdit(row) {
      this.$router.push({
        path: '/training/paper/edupaperEdit',
        query: {
          row: JSON.stringify(row),
          type: 'edit',
        }
      });
    },
    treeNodeClick(node) {
      this.node = node;
      this.query.subjectType = node.id
      this.page.currentPage = 1
      this.onLoad(this.page)
    },
    initData() {
      getEduTree(this.userInfo.tenantId).then(res => {
        const column = this.findObject(this.$refs.gridLayOut.option.column, 'subjectType')
        column.dicData = res.data.data
      })
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page)
    },
  },
  mounted() {
    this.initData()
  },
  created() {
    this.initTree()
    this.onLoad(this.page)
  }
}
</script>

<style scoped>

</style>
