var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "qmDialog",
      attrs: {
        title: _vm.title,
        "modal-append-to-body": false,
        visible: _vm.showDialog,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "avue-form",
        {
          ref: "addForm",
          attrs: { option: _vm.options },
          on: { submit: _vm.treeNodeSave },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c("template", { slot: "birthday" }, [
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.form.idCoding && _vm.form.idCoding != ""
                      ? _vm.getAge(_vm.form.idCoding)
                      : ""
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _c(
            "template",
            { slot: "idFiles" },
            [
              _c(
                "el-upload",
                {
                  class: {
                    uoloadSty: _vm.showImg,
                    disUoloadSty: _vm.noneUploadImg,
                  },
                  attrs: {
                    action:
                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                    headers: _vm.headers,
                    "on-preview": _vm.handlePreview,
                    "list-type": "picture-card",
                    "file-list": _vm.fileList,
                    file: "file",
                    "on-remove": _vm.handleDeleteImgRemove,
                    "on-success": _vm.handleIdFilesSuccess,
                    "on-change": _vm.uploadImgChange,
                    "before-upload": _vm.beforeAvatarUpload,
                    accept: ".jpeg,.jpg,.gif,.png",
                    limit: _vm.limitCountImg,
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "certificateFiles" },
            [
              _c(
                "el-upload",
                {
                  class: {
                    uoloadSty: _vm.showImg,
                    disUoloadSty: _vm.noneUploadImgCer,
                  },
                  attrs: {
                    action:
                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                    headers: _vm.headers,
                    "on-preview": _vm.handlePreview,
                    "list-type": "picture-card",
                    "file-list": _vm.certificateFilesFileList,
                    file: "file",
                    "on-remove": _vm.handleDeleteImgRemoveCer,
                    "on-success": _vm.handleIdFilesSuccessCer,
                    "on-change": _vm.uploadImgChangeCer,
                    "before-upload": _vm.beforeAvatarUpload,
                    accept: ".jpeg,.jpg,.gif,.png",
                    limit: _vm.limitCountImg,
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              attrs: { size: "small" },
              on: { click: _vm.closeDialog },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
              ),
            ]
          ),
          _vm.pageType != "view"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleTreeNodeSave },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "append-to-body": "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }