var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "formBox",
        style: { height: _vm.type != "workflow" ? "calc(100vh - 251px)" : "" },
      },
      [
        _c(
          "el-form",
          {
            ref: "dataForm",
            attrs: {
              model: _vm.dataForm,
              rules: _vm.rules,
              "label-width": "210px",
            },
          },
          [
            _c("head-layout", {
              staticStyle: { "margin-bottom": "12px" },
              attrs: { "head-title": "基本信息" },
            }),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "统计日期：", prop: "statDate" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            disabled:
                              _vm.type == "view" || _vm.type == "workflow",
                            placeholder: "",
                          },
                          on: { change: _vm.chooseDate },
                          model: {
                            value: _vm.dataForm.statDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "statDate", $$v)
                            },
                            expression: "dataForm.statDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "填报日期", prop: "updateTime" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c("span", [_vm._v("填报日期:")]),
                        ]),
                        _c("el-date-picker", {
                          attrs: {
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            disabled: "true",
                            placeholder: "保存后自动生成",
                          },
                          model: {
                            value: _vm.dataForm.updateTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "updateTime", $$v)
                            },
                            expression: "dataForm.updateTime",
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "项目名称：", prop: "projectName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "", disabled: "" },
                          model: {
                            value: _vm.dataForm.projectName,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "projectName", $$v)
                            },
                            expression: "dataForm.projectName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "项目状态:",
                          prop: "projectStatusName",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "",
                              "popper-append-to-body": false,
                              disabled: true,
                            },
                            model: {
                              value: _vm.dataForm.projectStatusName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "projectStatusName", $$v)
                              },
                              expression: "dataForm.projectStatusName",
                            },
                          },
                          _vm._l(_vm.prjStateOption, function (item) {
                            return _c("el-option", {
                              key: item.dictKey,
                              attrs: {
                                label: item.dictValue,
                                value: item.dictKey,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "业务类型：",
                          prop: "businessTypeName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "", disabled: "" },
                          model: {
                            value: _vm.dataForm.businessTypeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "businessTypeName", $$v)
                            },
                            expression: "dataForm.businessTypeName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "国别：", prop: "countryName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "", disabled: "" },
                          model: {
                            value: _vm.dataForm.countryName,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "countryName", $$v)
                            },
                            expression: "dataForm.countryName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "州/省：", prop: "provinceName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "", disabled: "" },
                          model: {
                            value: _vm.dataForm.provinceName,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "provinceName", $$v)
                            },
                            expression: "dataForm.provinceName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "总人数：", prop: "totalCount" } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.totalCount) +
                            "\n            "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.totalCountS < 0 ||
                                _vm.dataForm.totalCountS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" + _vm.dataForm.totalCountS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "中方员工总数：", prop: "chineseCnt" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.chineseCnt) +
                            "\n            "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.chineseCntS < 0 ||
                                _vm.dataForm.chineseCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" + _vm.dataForm.chineseCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "外籍人员总人数：" } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.foreignerCnt) +
                            "\n            "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.foreignerCntS < 0 ||
                                _vm.dataForm.foreignerCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" +
                                    _vm.dataForm.foreignerCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("head-layout", {
              staticStyle: { "margin-bottom": "12px" },
              attrs: { "head-title": "人员信息" },
            }),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "在册中方职工数：",
                          prop: "regeditedChineseCnt",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "6",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.regeditedChineseCnt,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "regeditedChineseCnt", $$v)
                            },
                            expression: "dataForm.regeditedChineseCnt",
                          },
                        }),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.regeditedChineseCntS < 0 ||
                                _vm.dataForm.regeditedChineseCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" +
                                    _vm.dataForm.regeditedChineseCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "在册外籍职工数：",
                          prop: "regularForeignerCnt",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "6",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.regularForeignerCnt,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "regularForeignerCnt", $$v)
                            },
                            expression: "dataForm.regularForeignerCnt",
                          },
                        }),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.regularForeignerCntS < 0 ||
                                _vm.dataForm.regularForeignerCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" +
                                    _vm.dataForm.regularForeignerCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "公司员工总数：", prop: "chineseCnt" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.companyCnt) +
                            "\n            "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.companyCntS < 0 ||
                                _vm.dataForm.companyCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" + _vm.dataForm.companyCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 18 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "内部人员增减说明：", prop: "remark1" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 4, maxRows: 4 },
                            placeholder: "",
                            maxlength: "200",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.remark1,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "remark1", $$v)
                            },
                            expression: "dataForm.remark1",
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-refresh iconLine",
                          on: { click: _vm.remark1 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "borderLine" }),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "分包商（含劳务）人数：",
                          prop: "subcontractorChineseCnt",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "6",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.subcontractorChineseCnt,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dataForm,
                                "subcontractorChineseCnt",
                                $$v
                              )
                            },
                            expression: "dataForm.subcontractorChineseCnt",
                          },
                        }),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.subcontractorChineseCntS < 0 ||
                                _vm.dataForm.subcontractorChineseCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" +
                                    _vm.dataForm.subcontractorChineseCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "劳务公司派遣中方员工数：",
                          prop: "dispatchedChineseCnt",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "6",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.dispatchedChineseCnt,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dataForm,
                                "dispatchedChineseCnt",
                                $$v
                              )
                            },
                            expression: "dataForm.dispatchedChineseCnt",
                          },
                        }),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.dispatchedChineseCntS < 0 ||
                                _vm.dataForm.dispatchedChineseCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" +
                                    _vm.dataForm.dispatchedChineseCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "第三方中方技术服务人员数：",
                          prop: "thirdPartyChineseCnt",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "6",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.thirdPartyChineseCnt,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dataForm,
                                "thirdPartyChineseCnt",
                                $$v
                              )
                            },
                            expression: "dataForm.thirdPartyChineseCnt",
                          },
                        }),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.thirdPartyChineseCntS < 0 ||
                                _vm.dataForm.thirdPartyChineseCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" +
                                    _vm.dataForm.thirdPartyChineseCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "其他中方人员数：",
                          prop: "otherChineseCnt",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.otherChineseCnt) +
                            "\n            "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.otherChineseCntS < 0 ||
                                _vm.dataForm.otherChineseCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" +
                                    _vm.dataForm.otherChineseCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 18 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "外部人员增减说明：", prop: "remark2" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 4, maxRows: 4 },
                            placeholder: "",
                            maxlength: "200",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.remark2,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "remark2", $$v)
                            },
                            expression: "dataForm.remark2",
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-refresh iconLine",
                          on: { click: _vm.remark2 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "borderLine" }),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "其他外籍人员数：",
                          prop: "otherForeignerCnt",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "6",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.otherForeignerCnt,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "otherForeignerCnt", $$v)
                            },
                            expression: "dataForm.otherForeignerCnt",
                          },
                        }),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content:
                                _vm.dataForm.otherForeignerCntS < 0 ||
                                _vm.dataForm.otherForeignerCntS == null ||
                                false
                                  ? "上次填报数据：0"
                                  : "上次填报数据：" +
                                    _vm.dataForm.otherForeignerCntS,
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline iconLine",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("head-layout", {
              staticStyle: { "margin-bottom": "12px" },
              attrs: { "head-title": "现场信息" },
            }),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "现场负责人姓名：",
                          prop: "onsiteDirectorName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "30",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.onsiteDirectorName,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "onsiteDirectorName", $$v)
                            },
                            expression: "dataForm.onsiteDirectorName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "现场负责人职务：",
                          prop: "onsiteDirectorPost",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "30",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.onsiteDirectorPost,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "onsiteDirectorPost", $$v)
                            },
                            expression: "dataForm.onsiteDirectorPost",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "现场负责人联系方式：",
                          prop: "onsiteDirectorContact",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "11",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.onsiteDirectorContact,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dataForm,
                                "onsiteDirectorContact",
                                $$v
                              )
                            },
                            expression: "dataForm.onsiteDirectorContact",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "现场负责人卫星电话数量：",
                          prop: "onsiteDirectorCellphone",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "",
                            maxlength: "6",
                            "show-word-limit": true,
                            readonly: _vm.type == "view",
                          },
                          model: {
                            value: _vm.dataForm.onsiteDirectorCellphone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dataForm,
                                "onsiteDirectorCellphone",
                                $$v
                              )
                            },
                            expression: "dataForm.onsiteDirectorCellphone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dialog-footer" },
      [
        _vm.type != "view" ||
        (this.type == "workflow" && this.dataForm.dataState == 3)
          ? _c("el-button", { on: { click: _vm.headSave } }, [
              _vm._v("保\n      存\n    "),
            ])
          : _vm._e(),
        _vm.type != "view" && this.type != "workflow"
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.headSubmit } },
              [_vm._v("提 交")]
            )
          : _vm._e(),
        _vm.type == "view" && this.type != "workflow"
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.headCancel } },
              [_vm._v("取 消")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }