var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              staticClass: "SNOWS-dialog SNOWS-dialog_center",
              attrs: {
                "close-on-click-modal": false,
                "modal-append-to-body": false,
                width: "600px",
              },
              on: { open: _vm.onOpen, close: _vm.onClose },
            },
            "el-dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "el-row",
            { attrs: { gutter: 0 } },
            [
              _c(
                "el-form",
                {
                  ref: "elForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    size: "small",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "选项名", prop: "fullName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入选项名",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formData.fullName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "fullName", $$v)
                              },
                              expression: "formData.fullName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "选项值", prop: "id" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入选项值",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formData.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "id", $$v)
                              },
                              expression: "formData.id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handelConfirm },
                },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }