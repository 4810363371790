var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "el-container",
        [
          _c("tree-layout", {
            staticClass: "leftTree",
            attrs: {
              treeTitle: "组织架构",
              treeLoading: _vm.treeLoading,
              "tree-options": _vm.treeOptions,
            },
            on: {
              "tree-node-click": _vm.treeNodeClick,
              "include-down": _vm.includeDown,
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", { attrs: { "head-title": "人员全息" } }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayout",
                attrs: {
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  tableOptions: _vm.tableOptions,
                  tableData: _vm.tableData,
                  tableLoading: _vm.tableLoading,
                },
                on: {
                  "page-size-change": _vm.onLoad,
                  "page-current-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                  "grid-row-detail-click": _vm.rowView,
                },
                scopedSlots: _vm._u([
                  {
                    key: "birthday",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.birthday && row.birthday != ""
                                  ? _vm.getAgeByBirthday(row.birthday)
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }