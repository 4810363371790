<template>
  <div >
    <head-layout  :head-title="$t('cip.dc.AiSuanfa.title.suafa')" >
    </head-layout>
    <div style="height: 60px;background-color: #fff;display: flex;align-items: center;padding:0px 20px">
      {{$t('cip.dc.AiSuanfa.field.sfFl')}}：
      <el-link class="btn">{{$t('cip.dc.AiSuanfa.btn.all')}}</el-link>
<!--      <el-link  class="btn">全部</el-link>-->
    </div>

    <el-container class="new-sino-box" v-bodyHe>

      <el-main class="new-sino-main border-box" >
        <div style="height: 64px;background-color: #fff;display: flex;align-items: center;padding:0px 10px">
          <div style="display: flex;align-items: center;">
            {{$t('cip.dc.AiSuanfa.field.label')}}：
            <el-input   class="form-item" v-model="queryList.algorithm_name"></el-input>
            <el-button-group>
              <el-button  type="primary" @click="onLoad">{{$t('cip.dc.AiSuanfa.btn.search')}}</el-button>
            <el-button  type="primary" @click="onLoad2">{{ $t('cip.dc.AIDComission.chongzhi') }}</el-button>
            </el-button-group>

          </div>

        </div>
        <div class="box">
          <div class="box-item" v-for="(item,index) in tableData" :key="index">
            <div class="box-item-content"  >
              <el-card class="box-card">
                <div class="text item" style="display: flex;justify-content: center">
                  <img src="./../../../../assets/images/Ai/2.png" alt="">
                </div>
                <el-divider content-position="left"></el-divider>
                <div>{{item.algorithm_name}}</div>
                <div style = "display: flex; justify-content: center">
                  <el-button type="text" @click="xunlianFn(item)">{{$t('cip.dc.AiSuanfa.btn.itemBtn')}}</el-button>
                </div>
              </el-card>
            </div>
          </div>
        </div>
        <div style="float:right;height: 60px;">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryList.startIndex"
            :page-size="queryList.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </el-container>

  </div>
</template>

<script>
import {
  totalCoList,
  totalCollectUp,
  totalCollectDel, deleteDataSource,
} from "@/api/dataAcquisition/index";
  import { mapGetters } from "vuex";
  import website from '@/config/website';
  import HeadLayout from "@/views/components/layout/head-layout"
  import GridLayout from "@/views/components/layout/grid-layout";
  import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
  import formLayout from '@/views/components/layout/form-layout'
  import {getAlgorithmList,getDictValueByDictCode,aiAlgorithmUpload } from '@/api/Ai/index'
  export default {
    components: {
      HeadLayout,
      GridLayout,
      dialogHeadBtn,
      formLayout
    },
    data() {
      return {
        activeName: '1',
        multipleSelection:[],
        tableData: [],
        total:0,
        queryList:{
          "startIndex":1,
          "perPage":10,
          "algorithm_name":"",
          "algorithm_class_id":""
        },
        headBtnOptions: [{
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: ""
        }, {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: ""
        }],
        form: null,
        formLabelWidth: '120px',
        dialogFormVisible:false,
        loading:false,
        rightForm:{
          file:'',
          algorithmName: '测试',//算法名称
          algorithmClassification: '',//算法下拉
          algorithAuthor: '12',//算法描述
          algorithDescription: '123213',//算法描述
        },

      };
    },
    computed: {
      ...mapGetters(["userInfo", "permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.dept_add, false),
          viewBtn: this.vaildData(this.permission.dept_view, false),
          delBtn: this.vaildData(this.permission.dept_delete, false),
          editBtn: this.vaildData(this.permission.dept_edit, false)
        };
      },
    },
    created() {
      this.onLoad();
      this.getDictValueByDictCodeFn()
    },
    methods: {
      onLoad(page, params = {}) {
        this.loading = true;
        getAlgorithmList(this.queryList).then(res => {
          this.loading = false;
          let obj = res.data.data;
          this.tableData = obj.list
          this.total = obj.total
        });
      },
      onLoad2(){
        this.queryList = {
          startIndex:1,
          perPage:10,
          algorithm_name:"",
          algorithm_class_id:""
        }
        this.onLoad();
      },
      getDictValueByDictCodeFn(){
        getDictValueByDictCode({
          dict_code: "dict-code"
        }).then((res)=>{
          console.log(res)
        })
      },
      addFn(){

      },
      xunlianFn(item){
        this.$router.push({
          path: '/AimodelDetail',
          query: {
            type: 'xunlian',
            algorithm_id:item.algorithm_id,



          }
        })
      },
      aiAlgorithmUploadFn(){
        aiAlgorithmUpload(this.rightForm).then((res)=>{
          this.dialogFormVisible = false ;
          this.onLoad();
        })
      },
      deleteFn(){
        if(this.multipleSelection.length == 0){
          this.$message.error(this.$t("cip.cmn.msg.warning.tipDetrmineSelectDel"))
          return
        }
        let ids = this.multipleSelection.map((item)=>{
          return item.id
        })
        this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), this.$t("cip.dc.dataSubject.msg.Tips"), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: 'warning'
        }).then(() => {
          totalCollectDel(ids).then((res)=>{
            this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
            this.onLoad()
          })
        }).catch(() => {

        });

      },
      czFn(){
        this.queryList.param1 = '';
        this.onLoad()
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleSizeChange(val) {
        this.queryList.size = val;
        this.onLoad()
      },
      handleCurrentChange(val) {
        this.queryList.page = val;
        this.onLoad()
      },
    },

    mounted() {
    },
  };
</script>

<style scoped>
.el-button+.el-button {
  margin-left: 0px;
}
  .btn{
    margin-right: 20px;
  }
  .box{
    display: flex;
    background-color: #fff;

    flex-wrap: wrap;
  }
  .box-item{
    width: 20%;
    padding: 0px 8px;
    box-sizing: border-box;

    margin-bottom: 10px;
  }
  .box-item-content{
   min-height:200px;

  }
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 100%;
    /* box-shadow:10px 20px 30px 30px #ccc */
  }
  .new-sino-main .el-card.is-always-shadow{
    border:1px solid #f0f0f0!important;
  }
  .form-item{
    width: 200px;
    margin-right: 10px;
  }
  .border-box{
    border:1px solid #f0f0f0!important;
    padding:0px 20px!important;
    background-color: #fff;
  }
</style>
