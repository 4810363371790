<template>
  <div class="areaPostList">
    <!-- 项目区域-问题清单 -->
    <el-container>
      <el-main>
        <head-layout
          head-title="问题清单"
          :showIcon="false"
        />
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowView"
        >
        <template #hdLevel="{row}">
          <el-tag v-if="hdLevel[row.hdLevel]" :type="hdLevelIcon[row.hdLevel]"
            effect="dark">{{hdLevel[row.hdLevel]}}
          </el-tag>
        </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { hdchecktaskproPage } from "@/api/check/daily";
import { mapGetters } from "vuex";
import riskView from "@/views/hiddenTrouble/civilizedNorm/riskView";

export default {
  mixins: [riskView],
  components: {
    HeadLayout,
    GridLayout,
  },

  props: {
    areaId: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      // prjId: "", // 项目id
      selectionList: [],

    };
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),

    tableOptions() {
      return {
        menu: false,
        selection: false,
        linklabel: 'hdCode',
        column: [
          {
            label: "隐患编号",
            prop: "hdCode",
            align: "center",
            overHidden: true,
          },
          {
            label: "隐患描述",
            prop: "hdDesc",
            align: "center",
            overHidden: true,
          },
          {
            label: "隐患级别",
            prop: "hdLevel",
            align: "center",
            overHidden: true,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            slot: true
          },
          {
            label: "隐患分类",
            prop: "",
            align: "center",
            overHidden: true,
          },
          {
            label: "报告人",
            prop: "reportUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "责任部门",
            prop: "respDeptName",
            align: "center",
            overHidden: true,
          },
          {
            label: "整改期限",
            prop: "rectifyLimitDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "hdStatus",
            align: "center",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
        ],
      };
    },
  },

  methods: {
    init (data) {
      this.onLoad({areaCode: data.areaCode})
    },
    /** 右侧表格操作 */
    onLoad(params = {}) {
      this.tableLoading = true;
      hdchecktaskproPage(
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        // this.$refs.gridLayOut.page.total = data.total;
        // this.page.total = data.total
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad();
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad();
    },

    rowView(row) {
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          id: row.id,
          type: "view",
        },
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.areaPostList{
  height: 100%;
  .el-main{
      overflow: hidden;
  }
}
  ::v-deep #gridLayout {
    height: calc(100% - 100px) !important;
  }
  ::v-deep .avue-crud {
    height: 100% !important;
    .el-card{
        height: 100% !important;
        overflow: hidden;
      .el-card__body  {
        height: 100% !important;

        .el-form {
          height: 100% !important;

          .el-table {
            height: 100% !important;
            max-height: 100% !important
          }
        }
      }
    }
  }
</style>
