<template>
  <div>

    <el-row :gutter="6">
      <el-col :span="8">
        <el-card class="box-card" shadow="always">
          <div slot="header" class="clearfix">
            <span>省</span>
            <el-button style="float: right; padding: 3px 0" type="text"></el-button>
          </div>
          <el-row :gutter="12" :span="24" style="margin-left: 20px">
            <el-col :span="12" v-for="(item,index) in treeData" :key="index">
              <el-checkbox v-model="province" :label="item.code" :key="index" style="text-align: center"
                           :checked="item.checked" :disabled="item.checked">{{
                  item.name
                }}
              </el-checkbox>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>市</span>
            <el-button style="float: right; padding: 3px 0" type="text"></el-button>
          </div>
          <el-row :gutter="24" :span="24" v-for="(province,value) in cityData" :key="value" style="margin-left: 20px">
            <el-divider content-position="left">{{ province.provinceName }}</el-divider>
            <el-col :span="6" v-for="(item,index) in province.list">
              <el-checkbox v-model="city" :label="item" :key="index" :checked="item.checked" :disabled="item.checked">
                {{ item.name }}
              </el-checkbox>
            </el-col>
          </el-row>

        </el-card>
      </el-col>
      <!--      <el-col :span="12">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>区</span>
                  <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
                </div>
                <el-row :gutter="24" :span="24">
                  <el-col :span="8" v-for="(item,index) in areaData">
                    <el-checkbox v-model="area" :label="item" :key="index">{{ item.name }}</el-checkbox>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>-->
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import {select, selectCheck} from "@/api/system/region"
import TreeLayout from "@/views/components/layout/tree-layout";
import Sidebar from "@/page/index/sidebar";

export default {
  name: "grid",
  components: {
    Sidebar,
    TreeLayout,
    GridLayout
  },
  props: {
    provinceSelectList: {
      type: Array,
      default: []
    },
    citySelectList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      province: [],
      city: [],
      cityData: [],
      areaData: [],
      area: [],
      treeData: [],
      treeOption: {
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (tree, resolve) {
          const parentId = tree.key;
          console.log(tree);
          region({"parentCode": parentId}).then(res => {
            resolve(res.data.data);
          });
        },
        props: {
          labelText: '标题',
          label: 'title',
          value: 'id',
          children: 'children'
        },
        formOption: {
          labelWidth: 100,
          column: [{
            label: '自定义项',
            prop: 'title'
          }],
        },
      },
      tableLoading: true,
      tableData: [],
      dataTotal: 0,
      tableOptions: {
        lazy: true,
        tip: false,
        simplePage: true,
        searchShow: false,
        searchMenuSpan: 6,
        dialogWidth: "60%",
        tree: true,
        border: true,
        index: true,
        selection: true,
        viewBtn: false,
        menuWidth: 300,
        dialogClickModal: false,
        column: [
          {
            label: "名称",
            prop: "title"
          }
        ],
        customAddBtn: false
      },
      isShow: false,
      isDataForm: false,
      isGridData: false,
      grid: null,
    };

  },
  computed: {
    ...mapGetters(["permission"]),

  },
  mounted() {
    //this.treeData({"parentCode": "00"});
    // this.grid = this.$refs.grid.$refs.grid;
    this.treeLoadData({});
  },
  created() {


  },
  watch: {
    province(curVal, oldVal) {
      this.cityData = [];
      this.city = [];
      this.treeLoadCityData(curVal);
    },
    city(curVal, oldVal) {
      this.treeLoadAreaData(curVal);
    },

  },
  methods: {
    save() {
      this.isShow = false;
      if (this.isDataForm) {
        this.isDataForm = false;
        this.$message.success(
          '您点击了保存' + JSON.stringify(this.$refs.dataForm.$refs.formLayout.dataForm)
        )
      }
      if (this.isGridData) {
        this.isGridData = false
      }
    },
    treeLoadData(params) {
      let _this = this;
      select(params).then(res => {
        let provinceData = res.data.data;
        provinceData.forEach(function (item, index) {
          if (_this.provinceSelectList.indexOf(item.provinceCode) != -1) {
            item.checked = true
          }
        })
        this.treeData = provinceData;
      });
    },
    treeLoadCityData(params) {
      let _this = this;
      if (!this.$func.isEmpty(params) && params.length > 0) {
        selectCheck(params).then(res => {
          let groupData = this.$func.arrayGroupBy(res.data.data, function (item) {
            return item.provinceCode + ',' + item.provinceName
          })
          groupData.forEach(function (item, index) {
            item.list.forEach(function (city, index) {
              if (_this.citySelectList.indexOf(city.cityCode) != -1) {
                city.checked = true
              }
            })
          })
          this.cityData = groupData;

        });
      }
    },
    treeLoadAreaData(params) {
      let ids = [];
      params.forEach(function (item, index) {
        ids.push(item.code);
      })
      if (!this.$func.isEmpty(ids) && ids.length > 0) {
        selectCheck(ids).then(res => {
          this.areaData = res.data.data;

        });
      }

    }
  }
};
</script>
<style>
.el-drawer__body {
  margin-top: 1px;
}
</style>
