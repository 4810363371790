<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          head-title="固危废处置台账"
          @head-storage-record="headStorageRecord"
          @head-management="headManagement"
        ></head-layout>
      </el-col>
    </el-row>

    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      @gird-handle-select-click="selectionChange"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @grid-row-detail-click="rowView"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-remove="rowRemove"
      @row-edit="rowEdit"
    >
      <template slot-scope="scope" slot="wasteType">
        <el-tag type="danger" size="small" v-if="scope.row.wasteType=='solid'">固废</el-tag>
        <el-tag type="success" size="small" v-else>危废</el-tag>
      </template>
      <template slot-scope="scope" slot="status">
        <el-tag type="danger" size="small" v-if="scope.row.status==1">未处置</el-tag>
        <el-tag type="success" size="small" v-else>已处置</el-tag>
      </template>
      <template slot-scope="scope" slot="processType">
        <el-tag size="small" v-if="scope.row.processType=='1'">委托处置</el-tag>
        <el-tag size="small" v-if="scope.row.processType=='2'">内部利用</el-tag>
      </template>
      <template #customBtn="{row}">
        <el-button
          v-if="row.status=='1'"
          type="text"
          size="small"
          @click="rowEdit(row)"
        >编辑
        </el-button>
        <el-button
          v-if="row.status=='2'"
          type="text"
          size="small"
          @click="managementEdit(row)"
        >编辑
        </el-button>
        <el-button
          type="text"
          size="small"
          @click="rowDel(row)"
        >删除
        </el-button>
        <el-button
          v-if="row.status=='1'"
          type="text"
          size="small"
          @click="rowManagement(row)"
        >处置
        </el-button>
      </template>
    </grid-layout>
    <el-dialog
      title="入库登记"
      :modal-append-to-body="true"
      v-if="warehousingVisible"
      :visible.sync="warehousingVisible"
      :before-close="warehousingHandleClose"
      width="50%">
      <warehousing ref="warehousing"></warehousing>
      <span slot="footer">
        <el-button size="small" type="primary" v-loading="warehousingLoading" @click="warehousingSubmit">
         确定
        </el-button>
        <el-button size="small" @click="warehousingVisible=false">
          取消
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="处置记录"
      :modal-append-to-body="true"
      v-if="managementVisible"
      :visible.sync="managementVisible"
      :before-close="managementHandleClose"
      width="50%">
      <management ref="management" :managementList="managementList"></management>
      <span slot="footer">
        <el-button size="small" type="primary" v-loading="warehousingLoading" @click="managementSubmit">
         确定
        </el-button>
        <el-button size="small" @click="managementVisible=false">
          取消
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import warehousing from "@/views/siteManagement/solidHazardousWaste/warehous.vue";
import management from "@/views/siteManagement/solidHazardousWaste/management.vue";
import {mapGetters} from "vuex";
import {getPage, remove, submit} from "@/api/siteManagement/warehous";
import {submit as managementAdd} from "@/api/siteManagement/management";
import Template from "@/views/message/template/list.vue";

export default {
  components: {
    Template,
    GridLayout,
    HeadLayout,
    warehousing,
    management
  },
  data() {
    return {
      isSubmitting: false,
      managementList: [],
      warehousingLoading: false,
      warehousingVisible: false,
      managementVisible: false,
      selectionList: [],
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "wasteName",
          span: 3,
          maxlength: 20,
          minlength: 2,
          placeholder: '请输入固危废品名称',
        },
        {
          prop: "status",
          span: 3,
          type: "select",
          dicData: [
            {value: 1, label: '未处置'},
            {value: 2, label: '已处置'},
          ],
          placeholder: '请选择状态',
        },
        {
          prop: "processOrg",
          span: 3,
          maxlength: 20,
          minlength: 2,
          placeholder: '请输入处置单位',
        },
        {
          prop: 'receptDate',
          type: 'datetimerange',
          span: 5,
          startPlaceholder: "入库开始时间",
          endPlaceholder: "结束时间",
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
        {
          prop: 'processDate',
          type: 'datetimerange',
          span: 5,
          startPlaceholder: "处置开始时间",
          endPlaceholder: "结束时间",
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        menuWidth: 150,
        linklabel: "wasteName",
        column: [
          {
            label: '固危废名称',
            prop: 'wasteName',
            align: 'center',
            overHidden: true,
          },
          {
            label: '类型',
            prop: 'wasteType',
            width: 80,
            align: 'center',
            slot: true,
            overHidden: true,
          },
          {
            label: '入库量',
            prop: 'quantity',
            align: "right",
            overHidden: true,
          },
          {
            label: '入库时间',
            prop: 'receptTime',
            width: 180,
            overHidden: true,
          },
          {
            label: '来源',
            prop: 'source',
            overHidden: true,
          },
          {
            label: '处置状态',
            prop: 'status',
            slot: true,
            width: 100,
            align: "center",
            overHidden: true,
          },
          {
            label: '处置类型',
            prop: 'processType',
            width: 100,
            align: "center",
            slot: true,
            overHidden: true,
          },
          {
            label: '处置单位',
            prop: 'processOrg',
            overHidden: true,
          },
          {
            label: '处置时间',
            prop: 'processTime',
            width: 180,
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      return result
    },
    headBtnOptions() {
      let result = [];
      result.push({
        label: '入库记录',
        emit: "head-storage-record",
        type: "button",
        icon: "",
      });
      result.push({
        label: '批量处置',
        emit: "head-management",
        type: "button",
        icon: "",
      });

      return result
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    rowView(row) {
      this.warehousingVisible = true
      this.$nextTick(() => {
        this.$refs.warehousing.formData(row.id, 'view');
      });
    },
    warehousingHandleClose() {
      this.warehousingVisible = false
    },
    managementHandleClose() {
      this.managementVisible = false
    },
    warehousingSubmit() {
      this.warehousingLoading = true;
      this.$refs.warehousing.$refs.form.validate((valid) => {
        if (valid) {
          this.warehousingVisible = false;
          submit(this.$refs.warehousing.dataForm).then(res => {
            let {msg, data} = res.data;
            if (res.data.code === 200) {
              this.$message.success('操作成功')
              this.isSubmitting = false
              this.warehousingLoading = false
              this.onLoad(this.page)
            } else {
              this.$message.success(msg);
            }
          }).finally(() => {
            this.$refs.warehousing.dataForm = {wasteType: 'solid'}
          })
        }
        this.warehousingLoading = false
      });
    },
    managementSubmit() {
      this.warehousingLoading = true
      /* if(!this.$refs.management.dataForm.documentUrl&&this.$refs.management.dataForm.processType=='1'){
         this.$message.warning('回执文件不能为空');
         this.warehousingLoading = false
         return;
       }*/
      this.$refs.management.$refs.managementForm.validate((valid) => {
        if (valid) {
          managementAdd(this.$refs.management.getData()).then(res => {
            let {msg} = res.data;
            if (res.data.code === 200) {
              this.$message.success('操作成功')
              this.managementVisible = false
              this.warehousingLoading = false
              this.onLoad(this.page)
            } else {
              this.$message.success(msg);
            }
          }).finally(() => {
            this.managementVisible = false;
          })
        }
        this.warehousingLoading = false
      })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    headStorageRecord() {
      this.isSubmitting = false;
      this.warehousingLoading = false;
      this.warehousingVisible = true
    },
    headManagement() {
      if (this.selectionList.length == 0) {
        this.$message.warning('请选择需要处置的数据');
        return;
      }
      const exists = this.selectionList.every(item => item.status === 2);
      if (exists) {
        this.$message.warning('所选数据已全部处置');
        return;
      }
      this.managementVisible = true

      this.$nextTick(() => {
        this.$refs.management.tableData = []

        this.selectionList.forEach(item => {
          if (item.status == 1) {
            let processData = {
              wasteName: item.wasteName,
              quantity: item.quantity,
              receptTime: item.receptTime,
              source: item.source,
              id: item.id
            }
            this.$refs.management.tableData.push(processData)
          }
        })
        this.$refs.management.resetForm()
      });
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          this.tableLoading = true

          remove(row.id).then(() => {
            this.$message({
              type: "success",
              message: "删除成功"
            });
          })
            .then(() => {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
            });
          this.tableLoading = false
        })
    },
    rowManagement(row) {
      this.managementVisible = true
      this.$nextTick(() => {
        let processData = {
          wasteName: row.wasteName,
          quantity: row.quantity,
          receptTime: row.receptTime,
          source: row.source,
          id: row.id
        }
        this.$refs.management.tableData = []
        this.$refs.management.tableData.push(processData)
        this.$refs.management.resetForm()
      });
    },
    rowEdit(row) {
      this.warehousingVisible = true
      this.$nextTick(() => {
        this.$refs.warehousing.formData(row.id);
      });
    },
    managementEdit(row) {
      this.managementVisible = true
      this.$nextTick(() => {
        let processData = {
          wasteName: row.wasteName,
          quantity: row.quantity,
          receptTime: row.receptTime,
          source: row.source,
          id: row.id
        }
        this.$refs.management.tableData = []
        this.$refs.management.tableData.push(processData)
        this.$refs.management.formData(row.processId);
      });

    },

    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty() {
      this.onLoad(this.page)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params)
      ).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>

<style scoped>

</style>
