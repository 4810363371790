var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "qmDialog",
      attrs: {
        title: _vm.title,
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "avue-form",
        {
          ref: "addForm",
          attrs: {
            option: {
              ..._vm.options,
              disabled: _vm.pageType == "view" ? true : false,
            },
          },
          on: { submit: _vm.treeNodeSave },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "cloudControl" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.cloudControl,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cloudControl", $$v)
                    },
                    expression: "form.cloudControl",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "supportHd" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.supportHd,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "supportHd", $$v)
                    },
                    expression: "form.supportHd",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              attrs: { size: "small" },
              on: { click: _vm.closeDialog },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleTreeNodeSave },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }