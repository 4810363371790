<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-23 14:16:50
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-19 15:28:10
 * @Description:
-->
<template>
  <div class="device-box">
    <div class="device-list">
      <!-- <div > -->
        <head-layout head-title="设备清单" ></head-layout>
        <el-container>
          <!-- treeTitle="设备分类" -->
          <CommonTree
            ref="commonTreeCore"
            :titleShow="false"
            :treeData="treeData"
            nodeKey="id"
            searchTitle="typeName"
            :defaultProps="{ label: 'typeName' }"
            @getNodeClick="handleNodeClick"
          />
          <el-main class="main-list">
            <grid-head-layout
              ref="gridHeadLayout"
              :search-columns="searchColumns"
              @grid-head-search="gridHeadSearch"
              @grid-head-empty="gridHeadEmpty"
            ></grid-head-layout>
            <div class="equipment_list" v-loading="tableLoading">
              <el-radio-group v-model="equipmentRadio" style="width: 100%">
                <div class="equipment-table">
                <div class="equipment-table-data">
                  <div class="equipment-item" :style="`borderColor:${equipmentRadio==item.id ? themeColor : ''}`" v-for="item in tableData" :key="item.id" @click="equipmentRadio = item.id">
                    <div class="equipment-item-info" :style="`borderBottomColor:${equipmentRadio==item.id ? themeColor : ''}`">
                      <el-image style="height: 100px" v-if="getDeviceImg(item.files)" :src="getDeviceImg(item.files)" ></el-image>
                      <img src="@/assets/images/notImg.png" alt="" v-else style=" height: 100px">
                      <p class="equipment-item-code">{{ item.equipmentCode }}</p>
                    </div>
                    <div class="equipment-item-name">
                      <el-radio :label="item.id">{{ item.equipmentName }}</el-radio>
                    </div>
                    <!-- <p class="equipment-item-name">{{ item.equipmentName }}</p> -->
                  </div>

                </div>
                </div>
              </el-radio-group>
              <div class="pagination">
                <el-pagination
                  @current-change="handleCurrentChange"
                  @size-change="handleSizeChange"
                  :current-page="page.currentPage"
                  :page-sizes="[10, 20, 30, 40, 50, 100]"
                  :page-size="page.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="page.total"
                  background
                >
              </el-pagination>
              </div>
            </div>
          </el-main>
        </el-container>
      <!-- </div> -->
    </div>
    <div class="inspection">
      <div class="inspection_plan">
        <inspectionPlan ref="inspectionPlan" />
      </div>
      <div class="inspection_records">
        <inspectionRecords ref="inspectionRecords" />
      </div>
    </div>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import headLayout from '@/views/components/layout/head-layout.vue'

import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import * as API from "@/api/equipmentFacilities/equipmentLedger";
import inspectionPlan from './inspectionPlan/index.vue'
import inspectionRecords from './inspectionRecords/index.vue'
import { mapGetters } from "vuex";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    inspectionPlan,
    inspectionRecords,
    headLayout,
  },
  data() {
    return {
      treeData: [],
      searchColumns: [
        {
          // label: "请输入设备编号",
          prop: "equipmentCode",
          placeholder: "请输入设备编号",
          span: 8,
        },
        {
          prop: "equipmentName",
          placeholder: "请输入设备名称",
          span: 8,
        },
        {
          prop: "spc",
          placeholder: "请输入规格型号",
          span: 8,
        },
        {
          prop: "location",
          placeholder: "请输入位置",
          span: 8,
        }
      ],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      query: {},
      equipmentRadio: '',
      nodeData: {},
      tableData: [],
      tableLoading: false,
    }
  },

  computed: {
    ...mapGetters(["userInfo","colorName","themeColor"]),
  },

  created () {
    this.getTreeData()
  },

  watch: {
    equipmentRadio (val) {
      this.getPlanList()
    }
  },

  mounted() {

  },
  methods: {
    /** 左侧列表操作 */
    getTreeData() {
      API.eqLedgeClassTree({orgId: this.userInfo.dept_id}).then((res) => {
        this.treeData = res.data.data;
        if (this.treeData.length) {
          this.$nextTick(() => {
            this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
              this.treeData[0].id
            );
            this.handleNodeClick(this.treeData[0]);
          });
        }
      });
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      // this.queryTypeId = data.id;
      // // this.excelOption.column[0].data = { classHeadId: data.id };
      // this.page.currentPage = 1;
      this.onLoad(this.page);
      // this.getCount();
    },

    // 设备清单列表
    onLoad (page, params={}) {
      this.tableLoading = true
      this.page = page;
      API.eqLedgerInventoryPage({
                current: page.currentPage,
                size: page.pageSize,
                orgId: this.userInfo.dept_id,
                typeId: this.nodeData.id,
                ...params,
            }).then((res) => {
                if (res.data.code == 200) {
                this.tableLoading = false;
                this.tableData = res.data.data.records;
                this.page.total = res.data.data.total;
                this.equipmentRadio = this.tableData[0] ? this.tableData[0].id : ''
                // this.getPlanList()
                // this.$refs.gridLayOut.page.total = res.data.data.total;
                }
            });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    //页数改变
    handleCurrentChange(e) {
        this.page.currentPage = e
        this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    //size改变
    handleSizeChange(e) {
        this.page.pageSize = e
        this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },

    getDeviceImg (files) {
      const filesInfo = files ? JSON.parse(files) : []
      if(filesInfo.length){
        return filesInfo[0].link
      } else {
        return ''
      }
    },

    // changeDevice (val) {
    //   console.log(val)
    //   // this.nodeData = this.tableData.find(item=>item.id == val)
    //   // console.log(this.nodeData)
    // },

    getPlanList () {
      const nodeData = this.tableData.find(item=>item.id == this.equipmentRadio)
      this.$refs.inspectionPlan.init(nodeData)
      this.$refs.inspectionRecords.init(nodeData)

      // this.$refs.inspectionPlan.onLoad({objectId:this.equipmentRadio})
      // this.$refs.inspectionRecords.init({objectCode: nodeData.equipmentCode})
    },
  },
}
</script>
<style scoped lang='scss'>
.device-box{
  display: flex;
  height: 100%;
  justify-content: space-between;
  overflow: scroll;
  .device-list{
    width: 55%;
    min-width: 770px;
  }
  .inspection{
    width: calc(45% - 15px);
    max-width: calc(100% - 750px);
    .inspection_plan,.inspection_records {
      height: calc(50% - 8px);
      overflow-y: auto;
    }
    .inspection_plan{
      margin-bottom: 15px;
    }
  }
}
.equipment-table{
  height: calc(100vh - 300px);
  // padding: 0 12px;
  overflow-y: scroll;
  width: 100%;
}
.equipment-table-data{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
}
.equipment-item{
  display: inline-block;
  width: calc(50% - 20px);
  height: 185px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 6px;
  .equipment-item-info{
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
  }
  .equipment-item-code{
    font-size: 14px;
  }
  .equipment-item-name{
    padding: 10px;
  }
}
.equipment-item-radio{
  border: 1px solid #ccc;

}
.pagination{
  text-align: right;
  // padding: 0 12px 10px 12px;
  padding-right: 12px;
}
::v-deep .common_tree_handle .el-tree{
  height: calc(100vh - 230px) !important;
}
</style>
