<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="排污许可证"
          :showIcon="false"
          @head-add="headAdd"
          @head-romve="headRomve"
        ></head-layout>
        <div class="cardLayout">
          <div class="cardListBig" v-loading="cardLoading">
            <div class="cardList">
              <div
                class="cardBox"
                v-for="(item, index) in cardData"
                :key="index"
              >
                <img class="cardImg" :src="item.imgUrl" />
                <div class="cardTime">
                  <div>{{ item.startTime }}</div>
                  <div>至</div>
                  <div>{{ item.endTime }}</div>
                </div>
                <div class="cardLine"></div>
                <div class="cardBtn">
                  <div
                    :class="`cardBtn-status ${
                      item.isDateRange ? 'shengxiao' : 'shixiao'
                    }`"
                  >
                    {{ item.isDateRange ? "生效" : "失效" }}
                  </div>
                  <div class="cardBtn-icon">
                    <el-button
                      icon="el-icon-edit"
                      @click="headAdd('edit', item)"
                    ></el-button>
                    <el-button
                      icon="el-icon-delete"
                      @click="rowRemove(item)"
                      type="plain"
                    ></el-button>
                  </div>
                </div>
              </div>
              <div class="cardBox" style="min-height: 260px">
                <div
                  style="
                    width: 100%;
                    height: 100%;
                    background-color: #fafafa;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  @click="headAdd('add')"
                >
                  <div style="text-align: center">
                    <i class="el-icon-plus" style="font-size: 48px"></i>
                    <div style="font-size: 14px; color: #575757">
                      添加许可证
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <el-form
          label-position="left"
          :model="addForm"
          :rules="rules"
          ref="addForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="有效期起" prop="startTime">
                <el-date-picker
                  v-model="addForm.startTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择有效期起"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="有效期止" prop="endTime">
                <el-date-picker
                  v-model="addForm.endTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请有效期止"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="许可证扫描件" prop="files">
                <el-upload
                  action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                  :headers="headers"
                  :on-preview="handlePreview"
                  list-type="picture-card"
                  :file-list="fileList"
                  file="file"
                  :on-remove="handleRemoveImg"
                  :on-success="handleUploadSucess"
                  :before-upload="beforeUpload"
                  :on-exceed="handleExceed"
                  accept=".jpeg,.jpg,.gif,.png"
                  :limit="1"
                >
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template slot="footer">
          <el-button
            size="small"
            @click="handleClose"
            v-loading="dialogLoading"
          >
            {{ $t(`cip.cmn.btn.celBtn`) }}
          </el-button>
          <el-button
            size="small"
            type="primary"
            @click="handleSubmit"
            v-loading="dialogLoading"
          >
            {{ $t(`cip.cmn.btn.defBtn`) }}
          </el-button>
        </template>
      </el-dialog>
      <el-dialog :visible.sync="dialogImgVisible" append-to-body>
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";

import { mapGetters } from "vuex";

import { getToken } from "@/util/auth";
import * as API from "@/api/environmentProtect/pollutionEmissions";

export default {
  components: {
    HeadLayout,
  },

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择有效期止"));
      } else {
        const startTime = this.addForm.startTime;
        if (startTime && value <= startTime) {
          callback(new Error("结束日期必须晚于开始日期"));
        } else {
          callback();
        }
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      // if (!this.fileList.length) {
      //   callback(new Error("请上传许可证扫描件"));
      // } else {
      //   callback();
      // }
      console.log("aaa", value);
      if (!value.length) {
        callback(new Error("请上传许可证扫描件"));
      } else {
        callback();
      }
    };
    return {
      tableData: [],

      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      dialogVisible: false,
      dialogImgVisible: false,
      dialogImageUrl: "",
      title: "",
      cardData: [],
      currentId: "", // 当前选中的数据id
      fileList: [],

      addForm: {
        startTime: "",
        endTime: "",
        files: [],
      },
      rules: {
        startTime: [
          { required: true, message: "请选择有效期起", trigger: "change" },
        ],
        endTime: [
          { required: true, validator: validatePass, trigger: "change" },
        ],
        files: [
          {
            required: true,
            validator: validatePass1,
            trigger: "change",
          },
        ],
      },
      optionType: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
  },
  methods: {
    init() {
      // this.onLoad(this.page, {});
      this.getPermitList();
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      API.epeventPage({
        current: page.currentPage,
        size: page.pageSize,
        ...params,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    checkTime(stime, etime) {
      //开始时间
      let arrs = stime.split("-");
      let startTime = new Date(arrs[0], arrs[1], arrs[2]);
      let startTimes = startTime.getTime();
      //结束时间
      let arre = etime.split("-");
      let endTime = new Date(arre[0], arre[1], arre[2]);
      let endTimes = endTime.getTime();
      //当前时间
      let thisDate = new Date();
      let thisDates =
        thisDate.getFullYear() +
        "-0" +
        (thisDate.getMonth() + 1) +
        "-" +
        thisDate.getDate();
      let arrn = thisDates.split("-");
      let nowTime = new Date(arrn[0], arrn[1], arrn[2]);
      let nowTimes = nowTime.getTime();
      if (nowTimes < startTimes || nowTimes > endTimes) {
        return false;
      }
      return true;
    },
    getPermitList() {
      API.pollutionPermitList({ orgId: this.userInfo.dept_id }).then((res) => {
        if (res.data.code == 200) {
          this.cardData = res.data.data.map((item) => {
            return {
              ...item,
              // imgUrl: Object.keys(JSON.parse(item.files)[0]).length?JSON.parse(item.files)[0].link:'',
              imgUrl: JSON.parse(item.files)[0]?.link,
              isDateRange: this.checkTime(item.startTime, item.endTime), // 判断当前日期是否在有效期之前
            };
          });
        }
      });
    },

    headAdd(type, row) {
      this.title = type == "add" ? "新增许可证" : "编辑许可证";
      this.optionType =  type == "add" ? "add" : "edit"
      if (type == "edit") {
        this.currentId = row.id;
        this.getDetail(row.id);
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.$refs.addForm.clearValidate();
      this.$refs.addForm.resetFields();
      this.dialogVisible = false;
      this.fileList = [];
      this.optionType = "";
      this.currentId = ""
    },

    handleSubmit() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.addForm.orgId = this.userInfo.dept_id;
          this.addForm.orgCode = this.userInfo.dept_id;
          if (this.addForm.files.length) {
            this.addForm.files = JSON.stringify(this.addForm.files);
          }

          if (this.optionType == "edit") {
            this.addForm.id = this.currentId;
          }else{
            this.addForm.id = ''
          }
          API.pollutionPermitSubmit(this.addForm).then(() => {
            this.$message.success(
              this.$t("cip.cmn.msg.success.operateSuccess")
            );
           
            this.handleClose();
            this.getPermitList();
          });
        }
      });
    },

    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif" ||
        file.type === "image/png";

      if (!isJPG) {
        this.$message.warning("上传图片只能是图片 格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },

    handleUploadSucess(res, file, fileList) {
      this.addForm.files = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
    },

    handlePreview(file) {
      //判断只有图片可以点击打开放大图
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileName == "jpg" ||
        fileName == "png" ||
        fileName == "jpeg" ||
        fileName == "bmp"
      ) {
        this.dialogImgVisible = true;
        if (file.response) {
          //判断新增和编辑时候 地址不一样
          this.dialogImageUrl = file.response.data.objectUrl
            ? file.response.data.objectUrl
            : file.response.data.link;
        } else {
          this.dialogImageUrl = file.link;
        }
      }
    },
    handleExceed(files, fileList) {
      this.$message.error("超出上传限制文件数量");
    },

    handleRemoveImg(file, fileList) {
      this.addForm.files = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
    },

    // 单条删除
    rowRemove(row) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.pollutionPermitRemove(row.id).then(() => {
          this.getPermitList();
          this.$message.success("操作成功！");
        });
      });
    },
    getDetail(id) {
      API.pollutionPermitDetail({ id }).then((res) => {
        if (res.data.code == 200) {
          this.addForm.startTime = res.data.data.startTime;
          this.addForm.endTime = res.data.data.endTime;
          this.fileList = JSON.parse(res.data.data.files).map((item) => {
            return {
              ...item,
              url: item.link,
              fileUrl: item.link,
            };
          });
          this.addForm.files = JSON.parse(res.data.data.files);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cardLayout {
  // width: calc(100% - 24px);
  padding: 0 12px;
  margin-top: 12px;
}

.cardListBig {
  width: 100%;
  height: calc(100vh - 278px);
  border: 1px solid #cccccc;
  margin-bottom: 12px;
  overflow: auto;
}

.cardList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.cardBox {
  width: calc(25% - 14px);
  border: 1px solid #cccccc;
  // padding: 6px;
  border-radius: 5px;
  margin: 6px;
  padding-top: 6px;
}

.cardImg {
  // width: 100%;
  width: 96%;
  height: 180px;
  margin: 0 auto;
  display: block;
}

.cardTime {
  // width: 100%;
  width: 96%;
  margin: 6px auto;
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // padding: 5px 0;
}
.cardLine {
  width: 100%;
  // height: 1px;
  border-bottom: 1px solid #cccccc;
  // background-color: #c1c2c5;
}
.cardBtn {
  // border-top: 1px solid #cccccc;
  padding: 6px 0;
  // width: 100%;
  width: 96%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cardBtn-status {
    padding: 4px 10px;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
  }

  .shengxiao {
    background-color: #7ac756;
  }

  .shixiao {
    background-color: #85888e;
  }
}
.flex-item {
  display: flex;
}
// .cardLayout {
//   width: calc(100% - 24px);
//   padding: 0 12px;
//   margin-top: 12px;
// }

// .cardListBig {
//   width: 100%;
//   height: calc(100vh - 278px);
//   border: 1px solid #cccccc;
//   margin-bottom: 12px;
//   overflow: auto;
// }

// .cardList {
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
// }

// .cardPagination {
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
// }

// .cardBox {
//   width: calc(25% - 26px);
//   border: 1px solid #cccccc;
//   padding: 6px;
//   border-radius: 5px;
//   margin: 6px;
// }

// .cardImg {
//   width: 100%;
//   height: 180px;
// }

// .cardTitle {
//   width: 100%;
//   font-size: 14px;
//   font-weight: bold;
//   margin: 6px 0;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   text-indent: 6px;
//   cursor: pointer;
// }

// .cardTitle:hover {
//   text-decoration: underline;
// }

// .cardTime {
//   width: 100%;
//   font-size: 12px;
//   color: #333333;
//   text-indent: 6px;
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
// }

// .cardBtn {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-top: 6px;
//   border-top: 1px solid #cccccc;
//   padding-top: 6px;
//   .cardBtn-status {
//     padding: 4px 8px;
//     color: #fff;
//     font-size: 14px;
//     border-radius: 5px;
//   }

//   .shengxiao {
//     background-color: #7ac756;
//   }

//   .shixiao {
//     background-color: #85888e;
//   }
// }
</style>
