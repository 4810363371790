<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-06 11:34:32
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-05-09 09:51:02
 * @Description: 
-->
<template>
  <div class="evaluate">
    <el-tabs type="border-card" @tab-click="handleClick" v-model="activeName">
      <el-tab-pane label="环保舆情" name="publicOpinion"> </el-tab-pane>
      <el-tab-pane label="环保投诉" name="complain"> </el-tab-pane>
    </el-tabs>
    <publicOpinion
      v-show="activeName == 'publicOpinion'"
      ref="publicOpinion"
    ></publicOpinion>
    <complain v-show="activeName == 'complain'" ref="complain"></complain>
  </div>
</template>

<script>
import publicOpinion from "./publicOpinion/index.vue";
import complain from "./complain/index.vue";
export default {
  components: {
    publicOpinion,
    complain,
  },
  data() {
    return {
      activeName: "publicOpinion",
    };
  },
  mounted() {
    this.$refs[this.activeName].init();
  },
  methods: {
    handleClick() {
      console.log('refs',this.$refs.publicOpinion)
      this.$refs[this.activeName].init();
    },
  },
};
</script>

<style lang="scss" scoped></style>
