<template>
  <el-dialog
    :title="
      type == 'add'
        ? '新增告知卡'
        : type == 'view'
        ? '查看告知卡详情'
        : '编辑告知卡'
    "
    :close-on-click-modal="false"
    :visible.sync="visible"
    lock-scroll
    width="1000px"
  >
    <div class="main-content" v-loading="saveLoading">
      <el-form
        :model="form"
        ref="form"
        :inline="true"
        label-width="100px"
        :rules="rules"
        :disabled="type == 'view'"
      >
        <el-form-item label="告知卡名称" prop="cardName">
          <el-input
            v-model="form.cardName"
            type="textarea"
            placeholder="请输入告知卡名称"
            :autosize="true"
            :maxlength="255"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="padding: 4px" ref="capture">
        <div class="table-box">
          <div class="line-1 line">
            <div class="item">
              <div class="label required">风险源</div>
              <div
                class="value need-border"
                style="height: calc(100% - 2px); padding-top: 1px"
              >
                <el-input
                  placeholder="请输入内容"
                  v-model="form.sourceName"
                  class="input-with-select"
                  :maxlength="255"
                  v-if="isSave"
                >
                  <!-- <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="selectFXY"
                  ></el-button> -->
                </el-input>
                <div v-else style="height: 100%; padding: 5px 15px">
                  {{ form.sourceName }}
                </div>
              </div>
            </div>

            <div class="item">
              <div class="label required">风险等级</div>
              <div
                class="value"
                style="height: calc(100% - 2px); padding-top: 1px"
              >
                <el-select
                  v-model="form.riskLevel"
                  placeholder="请选择"
                  v-if="isSave"
                >
                  <el-option
                    v-for="item in D"
                    :key="item.levelCode"
                    :label="item.levelName"
                    :value="item.levelName"
                  >
                  </el-option>
                </el-select>
                <div v-else style="height: 100%; padding: 5px 15px">
                  {{ form.riskLevel }}
                </div>
              </div>
            </div>

            <div class="item">
              <div class="label required">事故类型</div>
              <div
                class="value"
                style="
                  border-right: none;
                  height: calc(100% - 2px);
                  padding-top: 1px;
                "
              >
                <el-input
                  placeholder="请输入内容"
                  v-model="form.event"
                  :disabled="type == 'view'"
                  :maxlength="255"
                  v-if="isSave"
                >
                </el-input>
                <div v-else style="height: 100%; padding: 5px 15px">
                  {{ form.event }}
                </div>
              </div>
            </div>
          </div>
          <div class="line-2 line">
            <div class="item">
              <div class="label not-required">风险管控层级</div>
              <div
                class="value"
                style="height: calc(100% - 2px); padding-top: 1px"
              >
                <el-checkbox-group v-model="controlLevel">
                  <el-checkbox
                    style="margin: 10px"
                    v-for="item in controlLevelArr"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :disabled="type == 'view'"
                  ></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>

            <div class="item">
              <div class="label not-required">责任人</div>
              <div
                class="value"
                style="height: calc(100% - 2px); padding-top: 1px"
              >
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="form.respNickName"
                  maxlength="64"
                  v-if="isSave"
                >
                </el-input>
                <div v-else style="height: 100%; padding: 5px 15px">
                  {{ form.respNickName }}
                </div>
              </div>
            </div>

            <div class="item">
              <div class="label not-required">联系方式</div>
              <div
                class="value"
                style="
                  border-right: none;
                  height: calc(100% - 2px);
                  padding-top: 1px;
                "
              >
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="form.respTel"
                  maxlength="64"
                  v-if="isSave"
                  :disabled="type == 'view'"
                >
                </el-input>
                <div v-else style="height: 100%; padding: 5px 15px">
                  {{ form.respTel }}
                </div>
              </div>
            </div>
          </div>
          <div class="line-3 line">
            <div class="item">
              <div class="label required">风险描述</div>
              <div
                class="value"
                style="height: calc(100% - 2px); padding-top: 1px"
              >
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="form.riskName"
                  :rows="5"
                  :autosize="{ minRows: 7 }"
                  :disabled="type == 'view'"
                  :maxlength="255"
                  v-if="isSave"
                  clearable
                >
                </el-input>
                <div
                  v-else
                  style="height: 100%; padding: 5px 15px; color: #606266"
                >
                  {{ form.riskName }}
                </div>
              </div>
            </div>

            <div class="item">
              <div class="label required">主要控制措施</div>
              <div
                class="value"
                style="
                  border-right: none;
                  height: calc(100% - 2px);
                  padding-top: 1px;
                "
              >
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="form.treatment"
                  :rows="5"
                  v-if="isSave"
                  :autosize="{ minRows: 7 }"
                  :disabled="type == 'view'"
                  :maxlength="500"
                  clearable
                >
                </el-input>
                <div
                  v-else
                  style="height: 100%; padding: 5px 15px; color: #606266"
                >
                  {{ form.treatment }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="line-4 line"
            style="
              border-bottom: 1px solid;
              height: calc(100% - 2px);
              padding-top: 1px;
            "
          >
            <div class="item">
              <div class="label not-required" style="border-bottom: none">
                应急处置措施
              </div>
              <div class="value" style="border-bottom: none">
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="form.erTreatment"
                  :rows="5"
                  :autosize="{ minRows: 7 }"
                  :disabled="type == 'view'"
                  :maxlength="255"
                  v-if="isSave"
                  clearable
                >
                </el-input>
                <div
                  v-else
                  style="height: 100%; padding: 5px 15px; color: #606266"
                >
                  {{ form.erTreatment }}
                </div>
              </div>
            </div>

            <div class="item" style="border-bottom: none">
              <div class="label not-required" style="border-bottom: none">
                安全标志
              </div>
              <div class="value" style="border: none">
                <div class="mark-box">
                  <div class="mark-list">
                    <div
                      style="
                        width: 100px;
                        height: 100px;
                        margin: 10px 10px 0 0;
                        display: inline-block;
                        position: relative;
                      "
                      class="div-item"
                      v-for="(cItem, ci) in siList"
                      :key="ci"
                    >
                      <i
                        class="el-icon-remove"
                        style="
                          font-size: 20px;
                          color: #ff0000;
                          z-index: 100;
                          position: absolute;
                          right: -4px;
                          top: -4px;
                          cursor: pointer;
                        "
                        v-if="isSave"
                        @click="removeSiList(ci)"
                      ></i>
                      <img
                        crossOrigin="anonymous"
                        :src="cItem.picUrl"
                        class="img-item"
                        style="width: 100%; height: 100%"
                      />
                    </div>
                  </div>
                  <el-button type="primary" v-if="isSave" @click="safeClick"
                    >选择</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="line-5 line">
            <div class="item">
              <div class="label not-required" style="border-bottom: none">
                火警电话
              </div>
              <div class="value" style="border-bottom: none">
                <el-input
                  placeholder="请输入内容"
                  v-model="form.fireTel"
                  :disabled="type == 'view'"
                  :maxlength="64"
                >
                </el-input>
              </div>
            </div>

            <div class="item">
              <div class="label not-required" style="border-bottom: none">
                急救电话
              </div>
              <div class="value" style="border-bottom: none">
                <el-input
                  placeholder="请输入内容"
                  v-model="form.medTel"
                  :disabled="type == 'view'"
                  :maxlength="64"
                >
                </el-input>
              </div>
            </div>

            <div class="item">
              <div class="label not-required" style="border-bottom: none">
                公司应急电话
              </div>
              <div class="value" style="border: none">
                <el-input
                  placeholder="请输入内容"
                  v-model="form.comTel"
                  :disabled="type == 'view'"
                  :maxlength="64"
                >
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button size="small" @click="visible = false" v-loading="saveLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button
        size="small"
        type="primary"
        v-if="type != 'view'"
        v-loading="saveLoading"
        @click="handleSave"
      >
        保存
      </el-button>
    </span>
    <!-- <Reference ref="reference" @getSelection="getSelection"></Reference> -->
    <el-dialog
      v-dialog-drag
      title="安全标识"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="safeShow"
      v-if="safeShow"
      width="80%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div style="height: 500px">
        <SafeSelect
          @select-data="selectData"
          @close-dialog="closeSafe"
        ></SafeSelect>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import html2canvas from "html2canvas";
import { getbsrisklecddlevelMap } from "@/api/setting/riskAssessment/index";
import { getDictionaryCode } from "@/api/system/dictbiz";
import Reference from "@/views/business/riskProject/reference.vue";
import SafeSelect from "@/views/siteManagement/safetySigns/signSelectDialog.vue";
import {
  cardList,
  cardSubmit,
  cardRemove,
  cardDetail,
} from "@/api/safetyTtandard/occupationConfig.js";
import { mapGetters } from "vuex";
import { platform } from "@/api/system/user";

export default {
  components: {
    Reference,
    SafeSelect,
  },
  data() {
    return {
      safeShow: false,
      isSave: true,
      form: {
        siList: "",
        controlLevel: "",
        medTel: "120",
        fireTel: "119",
      },
      siList: [],
      saveLoading: false,
      controlLevel: [],
      checkList: [],
      controlLevelArr: [],
      controlLevelMap: {},
      D: [],
      type: "add",
      visible: false,
      btnLoading: false,
      columns: [],
      checkAll: true,
      isIndeterminate: false,
      columnList: [],
      dmItemIconsList: [{ link: "" }],
      rules: {
        cardName: [
          { required: true, message: "请输入告知卡名称", trigger: "blur" },
        ],
      },
      timeout: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    safeClick() {
      this.safeShow = true;
    },
    closeSafe() {
      this.safeShow = false;
    },
    selectData(list) {
      console.log(list);
      let siList = this.siList.concat(list);
      let uniqueArr = siList.filter((item, index, self) => {
        return index === self.findIndex((obj) => obj.picUrl === item.picUrl);
      });
      if (uniqueArr && uniqueArr.length > 9) {
        this.$message({
          message: "至多选择9个安全标识,已为您截取前9个",
          type: "warning",
        });
        this.siList = uniqueArr.slice(0, 9);
        return;
      }
      this.siList = uniqueArr;
    },
    removeSiList(index) {
      this.siList.splice(index, 1);
    },
    init(type, item) {
      this.visible = true;
      this.isSave = true;
      this.getDictionaryCode();
      this.getbsrisklecddlevelMap();
      this.type = type || "add";
      if (this.type == "add") {
        this.form = {
          siList: "",
          controlLevel: "",
          medTel: "120",
          fireTel: "119",
          postId: item.postId,
        };
        this.controlLevel = [];
        this.siList = [];
      } else {
        this.cardDetail(item.id);
        if (this.type == "view") {
          this.isSave = false;
        }
      }
    },
    cardDetail(id) {
      cardDetail({ id }).then((res) => {
        this.form = res.data.data;
        this.controlLevel = this.form.controlLevel.split(",");
        this.siList = this.form.siList.split(",").map((item) => {
          return { picUrl: item };
        });
      });
    },
    selectFXY() {
      this.$refs.reference.init(true);
    },
    // 获取D映射
    getbsrisklecddlevelMap() {
      //  createDept: this.userInfo.dept_id
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
      });
    },
    // 管控层级映射
    getDictionaryCode() {
      getDictionaryCode("risk_control_level").then((res) => {
        this.controlLevelArr = res.data.data;
        this.controlLevelArr.map((item) => {
          this.controlLevelMap[item.dictKey] = item.dictValue;
        });
      });
    },
    getSelection(row) {
      console.log(row, "getSelection");
      //   this.form.riskName = row.riskName;
      //   this.form.event = row.event;
      //   this.form.riskLevel = row.riskLevel;
      //   this.form.sourceCode = row.sourceCode;
      //   this.form.sourceId = row.sourceId;
      //   this.form.sourceName = row.sourceName;
      this.$set(this.form, "riskName", row.riskName);
      this.$set(this.form, "event", row.event);
      this.$set(this.form, "riskLevel", row.riskLevel);
      this.$set(this.form, "sourceCode", row.sourceCode);
      this.$set(this.form, "sourceId", row.sourceId);
      this.$set(this.form, "sourceName", row.sourceName);
      this.$set(this.form, "treatment", row.treatmentStrList.join(","));
      // 选中的风险源
    },
    // 保存（第一步转换图片对象）
    handleSave() {
      // this.saveLoading = true;
      // this.$set(this, "saveLoading", true);
      this.convertToImage();
    },
    // 将base64转成blob 为了后续能上传
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(",");
      //注意base64的最后面中括号和引号是不转译的
      var _arr = arr[1].substring(0, arr[1].length - 2);
      var mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(_arr),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime,
      });
    },

    convertToImage() {
      this.saveLoading = true;
      this.timeout = setTimeout(() => {
        this.$refs.form.validate((valid) => {
          this.saveLoading = true;
          if (valid) {
            if (
              !this.form.sourceName ||
              !this.form.riskLevel ||
              !this.form.event ||
              !this.form.riskName ||
              !this.form.treatment
            ) {
              this.$message({
                message: "请填写*标记的必填内容！",
                type: "warning",
              });
              this.saveLoading = false;
              return;
            }
            this.isSave = false;
            this.$nextTick(() => {
              try {
                html2canvas(this.$refs.capture, { useCORS: true }).then(
                  (canvas) => {
                    // Convert canvas to image
                    let dataUrl = canvas.toDataURL("image/png");
                    let blob = this.dataURLtoBlob(dataUrl);
                    let formData = new FormData();
                    let fileOfBlob = new File(
                      [blob],
                      new Date().getTime() + ".jpg"
                    );
                    formData.append("file", fileOfBlob);
                    platform(formData).then((res) => {
                      if (res.data.code == 200) {
                        console.log("aaa", res.data.data);
                        this.form.picUrl = res.data.data.link;
                        this.form.controlLevel = this.controlLevel.join(",");
                        this.form.siList = this.siList
                          .map((item) => {
                            return item.picUrl;
                          })
                          .join(",");
                        cardSubmit({
                          ...this.form,
                        })
                          .then((res) => {
                            this.saveLoading = false;
                            this.$message({
                              message: "保存成功",
                              type: "success",
                            });
                            this.isSave = true;
                            this.$emit("getStatus", "success");
                            this.visible = false;
                          })
                          .catch((error) => {
                            this.isSave = true;
                            this.saveLoading = false;
                          });
                      }
                    });
                  }
                );
                // 将Canvas转换为图片
                // 将图片转换为文件对象
              } catch (error) {
                console.error("转换出错:", error);
                this.isSave = true;
                this.saveLoading = false;
              }
            });
          } else {
            this.saveLoading = false;
          }
        });
      }, 100);
    },
    canvasToImage(canvas) {
      // 将Canvas转换为DataURL
      return canvas.toDataURL("image/png");
    },
  },
};
</script>

<style lang="scss" scoped>
.table-box {
  border: 1px solid;
  .line {
    width: 100%;
    display: flex;
    flex-direction: row;
    .item {
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 30%;
      .label,
      .value {
        border-right: 1px solid;
        border-bottom: 1px solid;
      }
      .required {
        position: relative;
      }
      .required::before {
        content: "*";
        color: #ff0000;
        margin-left: 4px;
      }
      .not-required {
        position: relative;
      }
      .not-required::before {
        content: "*";
        color: transparent;
        padding-left: 4px;
      }
      .value {
        flex-grow: 1;
      }
      .label {
        text-align: left;
        width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
      }
    }
  }
  .line-2 {
    .item {
      .value {
        display: flex;
        // justify-content: center;
        // align-items: center;
        height: 100%;
      }
    }
  }
  .line-4 {
    .item {
      .value {
        height: 100%;
      }
    }
  }
  .line-3,
  .line-4 {
    .item {
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 50%;
    }
  }
}
::v-deep .table-box .el-textarea__inner {
  border: none;
}
::v-deep .table-box .el-input__inner {
  border: none;
}
::v-deep.table-box .need-border .el-input__inner {
  border: 1px solid #dcdfe6;
}
::v-deep textarea.el-textarea__inner {
  height: 100%;
}
</style>