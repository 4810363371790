<template>
    <el-dialog
      :title="title"
      :visible.sync="dialogShow"
      width="800px"
      class="inspection-dialog"
      v-if="dialogShow"
      modal-append-to-body
      append-to-body
    >
        <div class="basic-container">
            <p class="pub-title">基本信息</p>
            <div class="detail-content">
                <p class="detail-item">
                    <label>区域名称：</label>
                    <span>{{detailData.regionName}}</span>
                </p>
                <p class="detail-item">
                    <label>工程概括：</label>
                    <span>{{detailData.inspectionContent}}</span>
                </p>
            </div>
        </div>
        <div class="basic-container">
            <p class="pub-title">管理员人员</p>
            <grid-layout
                ref="gridLayout"
                :tableOptions="tableOptions"
                :tableData="detailData.basisAreaUserList"
                :tableLoading="false"
            ></grid-layout>
        </div>
        <div class="count-text">危险因素辨识巡检表：一共有<span class="yellow">{{detailData.hiddenSum}}</span>项危险因素其中 合格<span class="blue">{{detailData.qualifiedNum}}</span>项 不合格<span class="red">{{detailData.unqualifiedNum}}</span>项</div>
        <div class="inspection-info-contianer">
            <p class="pub-title">巡检信息</p>
            <div class="info-item" v-for="item in detailData.lineList" :key="item.id">
                <div class="info-name">
                    <label>{{item.inspectionObject}}</label>
                    <el-radio-group v-model="item.checkStatus" disabled>
                        <el-radio label="正常">合格</el-radio>
                        <el-radio label="异常">不合格</el-radio>
                    </el-radio-group>
                </div>
                <div class="standard-container">
                    <p>{{item.inspectionContent}}</p>
                    <div class="standard-text" v-if="item.checkStatus == '异常'">
                        <label>不符合项描述：</label>
                        <span>{{item.inspectionOpinion}}</span>
                    </div>
                    <div class="standard-img">
                        <label>照片：</label>
                        <template v-if="item.photos">
                            <el-image 
                                style="width: 100px; height: 100px"
                                :src="obj.link" 
                                :preview-src-list="[obj.link]" v-for="(obj,index) in JSON.parse(item.photos)" :key="index" >
                            </el-image>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="img-list-container" v-if="!detailData.unqualifiedNum">
            <p class="pub-title">巡检车间形象照片</p>
            <template v-if="detailData.filePhotos">
                <el-image 
                    style="width: 100px; height: 100px"
                    :src="item.link" 
                    :preview-src-list="[item.link]" v-for="(item,index) in JSON.parse(detailData.filePhotos)" :key="index" >
                </el-image>
            </template>
        </div>
    </el-dialog>
</template>
<script>
import GridLayout from "@/views/components/layout/grid-layout";
import {detail,belowStandardDetail} from '@/api/safetyAndEnvironmentalProtection/oneJob'
export default {
    components: {
        GridLayout
    },
    data(){
        return{
            dialogShow:false,
            title:'巡检记录详情',
            tableData:[],
            detailData:{},
            tableOptions: {
                customRowAddBtn: false,
                menuWidth: 150,
                selection: false,
                indexLabel: "序号",
                index: true,
                menu:false,
                column: [{
                    label: '责任单位',
                    prop: "dutyUnitName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '责任人员',
                    prop: "dutyUserName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '联系方式',
                    prop: "dutyUserPhone",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '专业',
                    prop: "postName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '管理人员',
                    prop: "manageUserName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '联系方式',
                    prop: "manageUserPhone",
                    align: 'center',
                    overHidden: true,
                }]
            },
        }
    },
    methods:{
        init(row,title){
            this.title = title
            this.dialogShow = true
            console.log(row.id)
            this.getDetail(row.id)
        },
        getDetail(id){
            let url = this.title == '巡检记录详情' ? detail : belowStandardDetail
            url({id:id}).then(res=>{ 
                this.detailData = res.data.data
            })
        }
    }
}
</script>
<style scoped lang="scss">
    p{
        margin: 0;
        padding: 0;
    }
    .pub-title{
        font-size: 16px;
        font-weight: bold;
        padding: 10px;
    }
    .detail-content{
        .detail-item{
            display: flex;
            padding: 10px;
            label{
                color:#6d6b6b;
            }
        }
    }
    .inspection-info-contianer{
        .info-item{
            padding: 10px;
            .info-name{
                display: flex;
                justify-content: space-between;
            }
        }
    }
    .count-text{
        padding: 10px;
    }
    .standard-container{
        margin-top: 10px;
        .standard-text{
            margin: 10px 0;
        }
        .standard-img{
            margin-top: 10px;
            label{
                vertical-align: top;
            }
        }
    }
    .blue{
        color: #0094ff;
    }
    .yellow{
        color: rgb(255,116,0);
    }
    .red{
        color: rgb(255,0,0);
    }
    .el-image{
        margin-left: 10px;
    }
</style>