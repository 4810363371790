var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: { "head-title": _vm.$t("cip.dc.AiSuanfa.title.suafa") },
      }),
      _c(
        "div",
        {
          staticStyle: {
            height: "60px",
            "background-color": "#fff",
            display: "flex",
            "align-items": "center",
            padding: "0px 20px",
          },
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("cip.dc.AiSuanfa.field.sfFl")) +
              "：\n      "
          ),
          _c("el-link", { staticClass: "btn" }, [
            _vm._v(_vm._s(_vm.$t("cip.dc.AiSuanfa.btn.all"))),
          ]),
        ],
        1
      ),
      _c(
        "el-container",
        {
          directives: [{ name: "bodyHe", rawName: "v-bodyHe" }],
          staticClass: "new-sino-box",
        },
        [
          _c("el-main", { staticClass: "new-sino-main border-box" }, [
            _c(
              "div",
              {
                staticStyle: {
                  height: "64px",
                  "background-color": "#fff",
                  display: "flex",
                  "align-items": "center",
                  padding: "0px 10px",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("cip.dc.AiSuanfa.field.label")) +
                        "：\n            "
                    ),
                    _c("el-input", {
                      staticClass: "form-item",
                      model: {
                        value: _vm.queryList.algorithm_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryList, "algorithm_name", $$v)
                        },
                        expression: "queryList.algorithm_name",
                      },
                    }),
                    _c(
                      "el-button-group",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onLoad },
                          },
                          [_vm._v(_vm._s(_vm.$t("cip.dc.AiSuanfa.btn.search")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onLoad2 },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("cip.dc.AIDComission.chongzhi"))
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "box" },
              _vm._l(_vm.tableData, function (item, index) {
                return _c("div", { key: index, staticClass: "box-item" }, [
                  _c(
                    "div",
                    { staticClass: "box-item-content" },
                    [
                      _c(
                        "el-card",
                        { staticClass: "box-card" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text item",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("./../../../../assets/images/Ai/2.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c("el-divider", {
                            attrs: { "content-position": "left" },
                          }),
                          _c("div", [_vm._v(_vm._s(item.algorithm_name))]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.xunlianFn(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("cip.dc.AiSuanfa.btn.itemBtn")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
            _c(
              "div",
              { staticStyle: { float: "right", height: "60px" } },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.queryList.startIndex,
                    "page-size": _vm.queryList.perPage,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.queryList, "startIndex", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.queryList, "startIndex", $event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }