var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-input-number", {
            attrs: {
              placeholder: "请输入默认值",
              "controls-position": "right",
            },
            model: {
              value: _vm.activeData.__config__.defaultValue,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "defaultValue", $$v)
              },
              expression: "activeData.__config__.defaultValue",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最小值" } },
        [
          _c("el-input-number", {
            attrs: { placeholder: "最小值", "controls-position": "right" },
            model: {
              value: _vm.activeData.min,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "min", $$v)
              },
              expression: "activeData.min",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最大值" } },
        [
          _c("el-input-number", {
            attrs: { placeholder: "最大值", "controls-position": "right" },
            model: {
              value: _vm.activeData.max,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "max", $$v)
              },
              expression: "activeData.max",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "步长" } },
        [
          _c("el-input-number", {
            attrs: { placeholder: "步数", "controls-position": "right" },
            model: {
              value: _vm.activeData.step,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "step", $$v)
              },
              expression: "activeData.step",
            },
          }),
        ],
        1
      ),
      _vm.activeData["show-stops"] !== undefined && _vm.showType === "pc"
        ? _c(
            "el-form-item",
            { attrs: { label: "显示间断点" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.activeData["show-stops"],
                  callback: function ($$v) {
                    _vm.$set(_vm.activeData, "show-stops", $$v)
                  },
                  expression: "activeData['show-stops']",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }