var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-scrollbar", [
        _c(
          "div",
          [
            _c(
              "el-main",
              [
                _c(
                  "el-form",
                  {
                    ref: "formLayout",
                    staticClass: "demo-ruleForm form-container",
                    staticStyle: { width: "99%" },
                    attrs: {
                      model: _vm.dataForm,
                      rules: _vm.rules,
                      "label-width": "180px",
                    },
                  },
                  [
                    _c("head-layout", { attrs: { "head-title": "基本信息" } }),
                    _c(
                      "el-row",
                      {
                        staticClass: "custom-row",
                        staticStyle: { "margin-top": "20px" },
                        attrs: { gutter: 20 },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "企业名称",
                                  prop: "companyName",
                                },
                              },
                              [
                                _c("template", { slot: "label" }, [
                                  _c("span", [_vm._v("企业名称")]),
                                ]),
                                _c("el-input", {
                                  attrs: {
                                    disabled: "true",
                                    "controls-position": "right",
                                  },
                                  model: {
                                    value: _vm.dataForm.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "companyName", $$v)
                                    },
                                    expression: "dataForm.companyName",
                                  },
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "统计月份",
                                  prop: "dateAndTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "month",
                                    format: "yyyy-MM",
                                    "value-format": "yyyy-MM",
                                    disabled:
                                      _vm.type === "view" || _vm.type != "add",
                                  },
                                  on: { change: _vm.chooseDate },
                                  model: {
                                    value: _vm.dataForm.dateAndTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "dateAndTime", $$v)
                                    },
                                    expression: "dataForm.dateAndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "填报日期",
                                  prop: "updateTime",
                                },
                              },
                              [
                                _c("template", { slot: "label" }, [
                                  _c("span", [_vm._v("填报日期:")]),
                                ]),
                                _c("el-date-picker", {
                                  attrs: {
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    disabled: "true",
                                    placeholder: "保存后自动生成",
                                  },
                                  model: {
                                    value: _vm.dataForm.updateTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "updateTime", $$v)
                                    },
                                    expression: "dataForm.updateTime",
                                  },
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "活动类型",
                                  prop: "actionType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "", disabled: "" },
                                    model: {
                                      value: _vm.dataForm.actionType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "actionType",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.actionType",
                                    },
                                  },
                                  _vm._l(_vm.actionType, function (item) {
                                    return _c("el-option", {
                                      key: item.dictKey,
                                      attrs: {
                                        label: item.dictValue,
                                        value: item.dictKey,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("head-layout", {
                      attrs: { "head-title": '"零违章"人员信息' },
                    }),
                    _c(
                      "el-row",
                      {
                        staticClass: "custom-row",
                        staticStyle: { "margin-top": "20px" },
                        attrs: { gutter: 20 },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: '"零违章"个人总数',
                                  prop: "monthPeopleCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.monthPeopleCnt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "monthPeopleCnt",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.monthPeopleCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content:
                                        _vm.dataForm.monthPeopleCntS < 0 ||
                                        _vm.dataForm.monthPeopleCntS == null ||
                                        false
                                          ? "上次填报数据：0"
                                          : "上次填报数据：" +
                                            _vm.dataForm.monthPeopleCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "其中一线员工数量",
                                  prop: "monthFirstlinePeopleCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.monthFirstlinePeopleCnt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "monthFirstlinePeopleCnt",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dataForm.monthFirstlinePeopleCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content:
                                        _vm.dataForm.monthFirstlinePeopleCntS <
                                          0 ||
                                        _vm.dataForm.monthFirstlinePeopleCntS ==
                                          null ||
                                        false
                                          ? "上次填报数据：0"
                                          : "上次填报数据：" +
                                            _vm.dataForm
                                              .monthFirstlinePeopleCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "本年累计人数",
                                  prop: "yearPeopleCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.yearPeopleCnt,
                                    callback: function ($$v) {
                                      _vm.yearPeopleCnt = $$v
                                    },
                                    expression: "yearPeopleCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content:
                                        _vm.dataForm.yearPeopleCntS < 0 ||
                                        _vm.dataForm.yearPeopleCntS == null ||
                                        false
                                          ? "上次填报数据：0"
                                          : "上次填报数据：" +
                                            _vm.dataForm.yearPeopleCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("head-layout", {
                      attrs: { "head-title": '"零违章"组织信息' },
                    }),
                    _c(
                      "el-row",
                      {
                        staticClass: "custom-row",
                        staticStyle: { "margin-top": "20px" },
                        attrs: { gutter: 20 },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: '"零违章"班组数量',
                                  prop: "monthGroupCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.monthGroupCnt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "monthGroupCnt",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.monthGroupCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content:
                                        _vm.dataForm.monthGroupCntS < 0 ||
                                        _vm.dataForm.monthGroupCntS == null ||
                                        false
                                          ? "上次填报数据：0"
                                          : "上次填报数据：" +
                                            _vm.dataForm.monthGroupCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: '"零违章"工段数量',
                                  prop: "monthWorkSectionCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.monthWorkSectionCnt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "monthWorkSectionCnt",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.monthWorkSectionCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content:
                                        _vm.dataForm.monthWorkSectionCntS < 0 ||
                                        _vm.dataForm.monthWorkSectionCntS ==
                                          null ||
                                        false
                                          ? "上次填报数据：0"
                                          : "上次填报数据：" +
                                            _vm.dataForm.monthWorkSectionCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: '"零违章"车间/部门数量',
                                  prop: "monthWorkshopCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.monthWorkshopCnt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "monthWorkshopCnt",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.monthWorkshopCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content:
                                        _vm.dataForm.monthWorkshopCntS < 0 ||
                                        _vm.dataForm.monthWorkshopCntS ==
                                          null ||
                                        false
                                          ? "上次填报数据：0"
                                          : "上次填报数据：" +
                                            _vm.dataForm.monthWorkshopCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: '月度评选"零违章"组织数量',
                                  prop: "monthOrgCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.monthOrgCnt,
                                    callback: function ($$v) {
                                      _vm.monthOrgCnt = $$v
                                    },
                                    expression: "monthOrgCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content:
                                        _vm.dataForm.monthOrgCntS < 0 ||
                                        _vm.dataForm.monthOrgCntS == null ||
                                        false
                                          ? "上次填报数据：0"
                                          : "上次填报数据：" +
                                            _vm.dataForm.monthOrgCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "本年累计组织数",
                                  prop: "yearOrgCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.yearOrgCnt,
                                    callback: function ($$v) {
                                      _vm.yearOrgCnt = $$v
                                    },
                                    expression: "yearOrgCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content:
                                        _vm.dataForm.yearOrgCntS < 0 ||
                                        _vm.dataForm.yearOrgCntS == null ||
                                        false
                                          ? "上次填报数据：0"
                                          : "上次填报数据：" +
                                            _vm.dataForm.yearOrgCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _vm.type != "view"
            ? _c("el-button", { on: { click: _vm.headSave } }, [
                _vm._v("保 存"),
              ])
            : _vm._e(),
          _vm.type != "view"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.headSubmit } },
                [_vm._v("提 交")]
              )
            : _vm._e(),
          _vm.type == "view"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.headCancel } },
                [_vm._v("取 消")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }