<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-29 15:26:19
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-01 16:14:42
 * @Description:
-->
<template>
  <div style="height: 100%">
    <el-container>
      <!-- <CommonTree
        ref="commonTreeCore"
        treeTitle="组织列表"
        :searchTitle="searchTitle"
        :treeData="treeData"
        :defaultProps="treeDfaultProps"
        @getNodeClick="handleNodeClick"
        :isShowdig="false"
        :showCheckbox="false"
        node-key="id"
      /> -->
      <tree-layout
        treeTitle="组织架构"
        :treeLoading="treeLoading"
        :tree-options="treeOptions"
        @tree-node-click="treeNodeClick"
        @include-down="includeDown"
        class="leftTree"
      ></tree-layout>
      <el-main>
        <head-layout head-title="人员全息" />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <!-- <grid-layout
          ref="gridLayOut"

          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          :editType="inline"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"

        >
        <template slot="birthday" slot-scope="{ row }">
          <span>
            {{ row.idCoding && row.idCoding != "" ? getAge(row.idCoding) : "" }}
          </span>
        </template>
        </grid-layout> -->
        <grid-layout
          ref="gridLayout"
          :data-total="page.total"
          @page-size-change="onLoad"
          @page-current-change="onLoad"
          @page-refresh-change="onLoad"
          :page="page"
          @gird-handle-select-click="selectionChange"
          :tableOptions="tableOptions"
          :tableData="tableData"
          :tableLoading="tableLoading"
          @grid-row-detail-click="rowView"
        >
          <template slot="birthday" slot-scope="{ row }">
            <span>
              <!-- {{
                row.identityCard && row.identityCard != ""
                  ? getAge(row.identityCard)
                  : ""
              }} -->
              {{
                row.birthday && row.birthday != ""
                  ? getAgeByBirthday(row.birthday)
                  : ""
              }}
            </span>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import TreeLayout from "@/views/components/layout/tree-layout";

import { getList } from "@/api/system/user";
import {
  remove as delDept,
  getList as deptList,
  getDept,
} from "@/api/system/dept";
import { getDeptTree, getDeptLazyTree } from "@/api/system/dept";
import { deptChildTree } from "@/api/reportTemplate";
import { getAgeFromIdCard, calculateAge } from "@/util/util";

import { mapGetters } from "vuex";
export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    TreeLayout,
  },
  data() {
    return {
      treeLoading: true,
      nodeData: {},
      treeData: [],
      searchTitle: "deptName",
      treeDfaultProps: {
        children: "children",
        label: "deptName",
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      // isInclude: true
    };
  },
  mounted() {
    // this.lazyDeptTree();
    this.onLoad(
      {
        pageSize: 20,
        currentPage: 1,
      },
      {}
    );
  },
  computed: {
    ...mapGetters(["userInfo"]),
    treeOptions() {
      let that = this;
      return {
        defaultExpandAll: false,
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          that.treeLoading = true;
          getDept(that.userInfo.dept_id).then((res) => {
            const parentId =
              node.level === 0 ? res.data.data.parentId : node.data.id;
            getDeptLazyTree(parentId).then((res) => {
              that.treeLoading = false;
              console.log(resolve(
                res.data.data.map((item) => {
                  return {
                    ...item,
                    leaf: !item.hasChildren,
                  };
                })
              ))
              resolve(
                res.data.data.map((item) => {
                  return {
                    ...item,
                    leaf: !item.hasChildren,
                  };
                })
              );
            });
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: "title",
          value: "value",
          children: "children",
        },
      };
    },

    searchColumns() {
      return [
        {
          prop: "realName",
          placeholder: "姓名",
          span: 4,
        },
      ];
    },

    tableOptions() {
      return {
        linklabel: "realName",
        menu: false,
        selection: false,
        column: [
          {
            label: "姓名",
            prop: "realName",
            width: 200,
            align: "center",
          },

          {
            label: "性别",
            prop: "sexName",
            width: 50,
            align: "center",
            // dataType: "number",
            // props: {
            //   label: "dictValue",
            //   value: "dictKey",
            // },
            // dicUrl:
            //   "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
          },
          {
            label: "年龄",
            prop: "birthday",
            width: 60,
            align: "center",
            slot: true,
          },
          {
            label: "岗位",
            prop: "postName",
            align: "center",
          },
          {
            label: "是否启用",
            prop: "isEnabled",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 150,
            align: "center",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          },
        ],
      };
    },
  },
  methods: {
    /** 左侧列表操作 */
    //获取树数据
    lazyDeptTree() {
      deptChildTree().then((res) => {
        this.treeData1 = [res.data.data];
        this.nodeData = res.data.data;
        this.$nextTick(() => {
          this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
            this.nodeData.id
          );
          this.handleNodeClick(this.nodeData);
        });
      });
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.page.currentPage = 1;
      this.query.belongOrgId = data.id;
      this.onLoad(this.page);
    },
    treeNodeClick(node) {
      this.treeDeptId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    /**----end---- */
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      // this.tableLoading = true;
      // this.page = page;
      // mettingHeadPage(
      //   page.currentPage,
      //   page.pageSize,
      //   Object.assign(params, this.query)
      // ).then((res) => {
      //   const data = res.data.data;
      //   this.$refs.gridLayOut.page.total = data.total;
      //   this.tableData = data.records.map((val) => {
      //     val.meetingType = val.meetingType.toString();
      //     return val;
      //   });
      //   this.tableLoading = false;
      //   this.$refs.gridLayOut.selectionClear();
      // });
      this.param = params;
      this.page = page;
      this.tableLoading = true;
      params.personnelTypes = 1
      getList(
        {
          ...params, ...this.query,
          current: page.currentPage,
          size: page.pageSize,
          deptId: this.treeDeptId,
        }
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        // this.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    getAge(data) {
      // 身份证
      return getAgeFromIdCard(data);
    },
    getAgeByBirthday(data) {
      return calculateAge(data);
    },
    rowView(row) {
      this.$router.push({
        path: `/business/safe/personnelHolography/detail`,
        query: {
          id: row.id,
          type: "personnel",
        },
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
