var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.$t(
            "cip.dc.DataAggregation.title.AddDataAggregationoutput"
          ),
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        {
          staticClass: "newZtFormBox",
          staticStyle: { "background-color": "white", "margin-top": "10px" },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                inline: false,
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.DataAggregation.field.AggregationCoding"
                            ),
                            prop: "aggCode",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.DataAggregation.field.AggregationCoding"
                              ),
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.aggCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "aggCode", $$v)
                              },
                              expression: "form.aggCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.DataAggregation.field.Sourcefield"
                            ),
                            prop: "sourceField",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.DataAggregation.field.Sourcefield"
                              ),
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.sourceField,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sourceField", $$v)
                              },
                              expression: "form.sourceField",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.DataAggregation.field.Targetfield"
                            ),
                            prop: "targetField",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.DataAggregation.field.Targetfield"
                              ),
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.targetField,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "targetField", $$v)
                              },
                              expression: "form.targetField",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.DataAggregation.field.Targettable"
                            ),
                            prop: "targetTable",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.DataAggregation.field.Targettable"
                              ),
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.targetTable,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "targetTable", $$v)
                              },
                              expression: "form.targetTable",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.DataAggregation.field.Aggregateflag"
                            ),
                            prop: "taskExecuteOrder",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.DataAggregation.field.Aggregateflag"
                              ),
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.aggFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "aggFlag", $$v)
                              },
                              expression: "form.aggFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }