var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 6 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "always" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [_vm._v("省")]),
                      _c("el-button", {
                        staticStyle: { float: "right", padding: "3px 0" },
                        attrs: { type: "text" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { gutter: 12, span: 24 },
                    },
                    _vm._l(_vm.treeData, function (item, index) {
                      return _c(
                        "el-col",
                        { key: index, attrs: { span: 12 } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              key: index,
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                label: item.code,
                                checked: item.checked,
                                disabled: item.checked,
                              },
                              model: {
                                value: _vm.province,
                                callback: function ($$v) {
                                  _vm.province = $$v
                                },
                                expression: "province",
                              },
                            },
                            [_vm._v(_vm._s(item.name) + "\n            ")]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [_vm._v("市")]),
                      _c("el-button", {
                        staticStyle: { float: "right", padding: "3px 0" },
                        attrs: { type: "text" },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.cityData, function (province, value) {
                    return _c(
                      "el-row",
                      {
                        key: value,
                        staticStyle: { "margin-left": "20px" },
                        attrs: { gutter: 24, span: 24 },
                      },
                      [
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "left" } },
                          [_vm._v(_vm._s(province.provinceName))]
                        ),
                        _vm._l(province.list, function (item, index) {
                          return _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  key: index,
                                  attrs: {
                                    label: item,
                                    checked: item.checked,
                                    disabled: item.checked,
                                  },
                                  model: {
                                    value: _vm.city,
                                    callback: function ($$v) {
                                      _vm.city = $$v
                                    },
                                    expression: "city",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }