<template>
  <el-container class="casually">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="我的记录" name="first"></el-tab-pane>
      <el-tab-pane label="全部" name="second"></el-tab-pane>
    </el-tabs>
    <div class="flex-c">
      <CommonTree v-show="activeName == 'second'" ref="commonTreeCore" treeTitle="组织列表"
        :treeData="treeData" :defaultProps="defaultProps" @getNodeClick="treeNodeClick" :isShowdig="false"
        :showCheckbox="false" node-key="id" />
      <el-main>
        <head-layout head-title="随手拍记录" :head-btn-options="headBtnOptions" @head-romve="headRomve"
          @head-add="headAdd"></head-layout>
        <grid-head-layout ref="gridHeadLayout" v-model="searchForm" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"></grid-head-layout>
        <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading"
          @grid-row-detail-click="rowCodeView" @gird-handle-select-click="selectionChange" :data-total="page.total"
          :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
          <template #hdStatus1="{ row }">
            <div class="status-box" v-html="formatter(row)"></div>
          </template>
          <template #customBtn="{ row }">
            <el-button style="margin: 0 3px" v-if="row.hdStatus != 'submit' && row.createUser == userInfo.user_id
      " type="text" size="small" @click="rowEdit(row)">编辑</el-button>
            <el-button style="margin: 0 3px" type="text" v-if="row.hdStatus == 'submit'" size="small"
              @click="rowCodeView(row)">查看</el-button>
            <el-button style="margin: 0 3px" v-if="row.hdStatus == 'prepare' && row.createUser == userInfo.user_id
      " type="text" size="small" @click="rowDel([row])">删除</el-button>
          </template>
        </grid-layout>
      </el-main>
    </div>
  </el-container>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import * as API from "@/api/riskitemView/casually";

import tableOption from "./tableOption";
import { mapGetters } from "vuex";
import Template from "@/views/message/template/list.vue";
import CommonTree from "@/views/components/com_tree/index";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import {getDeptTree} from "@/api/system/dept";

export default {
  mixins: [tableOption],
  components: { CommonTree, Template, GridLayout, HeadLayout },
  data() {
    return {
      searchForm:{},
      node: {}, //左侧树node
      defaultProps: {
        children: "children",
        label: "title",
      },
      activeName: "first",
      searchColumns: [
        {
          label: "记录编号",
          prop: "shotCode",
          span: 2,
          placeholder: "请输入记录编号",
        },
        {
          label: "内容描述",
          prop: "description",
          span: 3,
          placeholder: "请输入内容描述",
        },
        {
          label: "提交人",
          prop: "reportUserName",
          span: 3,
          display: (item) => {
            return this.activeName == "second";
          },
          placeholder: "请输入提交人",
        },
        {
          label: "业务类型",
          prop: "shotType",
          type: "select",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=SHOT_TYPE`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 2,
          placeholder: "业务类型",
        },
        {
          label: "组织名称",
          prop: "organizationName",
          span: 3,
          display: (item) => {
            return this.activeName == "first";
          },
          placeholder: "所属组织",
        },
        {
          label: "状态",
          prop: "hdStatus",
          span: 2,
          type: "select",
          display: (item) => {
            return this.activeName == "first";
          },
          placeholder: "请选择状态",
          dicData: [
            { dictKey: 'submit', dictValue: '提交' },
            { dictKey: 'prepare', dictValue: '草稿' }
          ],
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          label: "处理进度",
          prop: "operationStatus",
          span: 2,
          type: "select",
          placeholder: "请选择处理进度",
          dicFormatter: (res) => {
            let data = res.filter((item) => item.dictKey != "prepare");
            return data;
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=hd_status",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          prop: "time",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          clearable: true,
          span: 4,
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期",
          type: "daterange",
        },
        {
          activeText: '是否超期',
          prop: "isTimeOut",
          type: 'switch',
          activeValue: 1,
          inactiveValue: 0,
          span: 2,
        },
      ],
      showPage: false,
      treeData: [],
      selectionList: [],
      hd_status: [],
      isCycle: "",
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "primary",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),

    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  async mounted() {
    let hd_status = await API.SHOT_TYPE("hd_status").then(
      (res) => res.data.data
    );
    this.hd_status = hd_status.reduce((prev, cur) => {
      prev[cur.dictKey] = cur.dictValue;
      return prev;
    }, {});
    this.onLoad(this.page, {});
    this.lazyDeptTree();
  },
  methods: {
    //获取树数据
    lazyDeptTree() {
      const parentId = this.userInfo.dept_id;
      getDeptTree('', '', parentId).then((res) => {
        this.treeData = res.data.data;
        this.node = res.data.data[0];
        this.$nextTick(()=>{
          this.$refs.commonTreeCore.setCurrentKey(this.node.id)
        })
      });
    },
    handleClick() {
      this.searchForm={}
      if (this.activeName == "first") {
        this.onLoad(this.page, {});
      } else {
        this.treeNodeClick(this.node);
      }
    },
    treeNodeClick(node) {
      this.node = node;
      this.onLoad(
        { pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 },
        this.searchForm
      );
    },
    formatter(row) {
      if (row.outOfTime) {
        return `<span class="status"  style="background-color:#bd3124;border-color:#bd3124;color: #ffffff">超期未整改</span>`
      } else {
        let status = row.shotType == "1" ? "tempHdStatus" : "tempCnStatus";
        let text = this.hd_status[row[status]] || "";
        return text
          ? `<span class="status ${row[status] || ""}">${this.hd_status[row[status]] || ""
          }</span>`
          : "";
      }

    },
    headAdd() {
      this.$router.push({
        path: `/hiddenTrouble/check/casually/edit`,
        query: {
          type: "add",
        },
      });
    },
    // 搜索
    gridHeadSearch() {
      let searchForm={
        ...this.searchForm
      }
      if (searchForm.time) {
        searchForm.startTime = searchForm.time[0] + " 00:00:00";
        searchForm.endTime = searchForm.time[1] + " 23:59:59";
      } else {
        searchForm.startTime = "";
        searchForm.endTime = "";
      }

      delete searchForm.time;
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = {};
      this.onLoad(this.page, this.searchForm);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    headRomve() {
      this.rowDel(this.selectionList);
    },
    rowDel(row) {
      this.selectionList = row;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let formData = new FormData();
          formData.append("ids", this.ids);
          return API.remove(formData);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    // 获取列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      let organizationId = this.activeName == 'first' ? this.userInfo.dept_id :this.node.id;
      API.getList({
        current: page.currentPage,
        size: page.pageSize,
        ...params,
        reportUserId: this.activeName == 'first' ? this.userInfo.user_id : '',
        organizationId: organizationId,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/hiddenTrouble/check/casually/edit`,
        query: {
          id: row.id,
          type: "edit",
        },
      });
    },
    rowCodeView(row) {
      if (row.hdStatus == "prepare") {
        let path = "/hiddenTrouble/check/casually/edit";
        this.rowView(row, path);
      } else {
        let path =
          row.shotType == "1"
            ? "/hiddenTrouble/riskitemView/checklist/edit"
            : "/hiddenTrouble/civilizedNorm/checklist/edit";
        this.rowView(row, path);
      }
    },
    rowView(row, path) {
      this.$router.push({
        path: path,
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.casually {
  flex-direction: column !important;

  .flex-c {
    display: flex;
  }

  .avue-crud .el-table {
    height: calc(100vh - 334px) !important;
    max-height: calc(100vh - 334px) !important;
  }

  .status-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
  }

  .status {
    border-color: #e9e9eb;
    border-width: 1px;
    border-style: solid;
    color: #909399;
    background: #f4f4f5;
    border-radius: 3px;
    font-size: 12px;
    padding: 0px;
    width: 67px;
    height: 26px;
    line-height: 26px;
    display: inline-block;
    text-align: center;
  }

  .unaccept {
    border-color: rgb(226, 152, 54);
    border-width: 1px;
    border-style: solid;
    color: rgb(226, 152, 54);
    background: rgb(253, 245, 233);
  }

  .unrectify {
    border-color: rgb(86, 92, 199);
    border-width: 1px;
    border-style: solid;
    color: rgb(86, 92, 199);
    background: rgb(232, 235, 248);
  }

  .finished {
    border-color: rgb(122, 199, 86);
    border-width: 1px;
    border-style: solid;
    color: rgb(122, 199, 86);
    background: rgb(238, 248, 232);
  }

  .canceled {
    border-color: rgb(242, 97, 97);
    border-width: 1px;
    border-style: solid;
    color: rgb(242, 97, 97);
    background: rgb(254, 238, 238);
  }

  .unassess {
    border-color: rgb(56, 148, 255);
    border-width: 1px;
    border-style: solid;
    color: rgb(56, 148, 255);
    background: rgb(233, 243, 255);
  }
}

::v-deep .el-tabs__header {
  margin: 0;
  background: #ffffff;
}

::v-deep .el-tabs__content {
  padding: 0 !important;
}
::v-deep .el-tabs--border-card{
  border-left: none;
  border-bottom: none;
}
</style>
