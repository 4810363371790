<template>
  <div class="common_tree_handle">
    <el-input
      size="small"
      :placeholder="$t('cip.cmn.msg.warning.filterateWarning')"
      v-model="filterText"
    >
      <template slot="append" v-if="isShowdig">
        <el-popover placement="top" width="80" v-model="visible">
          <p class="popv_p" @click="addNode()">{{ $t('cip.cmn.btn.addBtn') }}</p>
          <p class="popv_p" @click="editNode()">{{ $t('cip.cmn.btn.editBtn') }}</p>
          <p class="popv_p" @click="deleteNode()">{{ $t('cip.cmn.btn.delBtn') }}</p>
          <el-button slot="reference">
            <i class="el-icon-arrow-down"></i>
          </el-button>
        </el-popover>
      </template>
    </el-input>
    <el-tree
      ref="commonTree"
      :data="treeChangeData"
      :props="defaultProps"
      default-expand-all
      :show-checkbox="showCheckbox"
      @node-click="nodeClick"
      @check-change="handleCheckChange"
      :filter-node-method="filterNode"
      :expand-on-click-node="expandOnClickNode"
    >
    </el-tree>
    <el-dialog
      v-dialog-drag
      :title="dialogTitle"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-if="showTreeDialog"
      :visible.sync="showTreeDialog"
      width="60%"
    >
      <avue-form ref="treeDialog" :option="treeFormOption" v-model="treeForm" v-loading="loading"></avue-form>
      <span slot="footer">
        <el-button size="small" @click="showTreeDialog = false"
        >{{ $t('cip.cmn.btn.celBtn') }}</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="treeNodeSave"
        >{{ $t('cip.cmn.btn.saveBtn') }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      filterText: '',
      treeChangeData: [],
      nodeData: {},
      treeForm: {
        parentId: ''
      },
      showTreeDialog: false,
      dialogTitle: '',
      saveOrUpdate: 'add',
      loading: false
    }
  },

  // treeData            树展示的数据
  // treeFormOption      树的表单配置
  // defaultProps        树展示的数据格式
  // searchName          过滤的字段名称
  // isShowdig           是否展示搜索旁边的下拉
  // showCheckbox        是否开启多选
  // @treeAdd            下拉新增方法
  // @treeEdit           下拉编辑方法
  // @treeDelete         下拉删除方法
  // @nodeClick          节点点击方法
  // @getHandleCheckChange  多选节点选中状态发生变化时的回调

  props: {
    treeData: {
      type: Array,
    },
    treeFormOption: {
      type: Object,
      default: () => ({})
    },
    defaultProps: {
      type: Object,
    },
    searchName: {
      type: String,
      default: ""
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    isShowdig: {
      type: Boolean,
      default: false,
    },
    expandOnClickNode: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    //确认
    treeNodeSave(){
      this.loading = true;
      this.$refs.treeDialog.validate((valid) => {
        if (valid){
          if (this.saveOrUpdate == 'add'){
            this.$emit('treeAdd', this.treeForm, () => {this.showTreeDialog = false; this.nodeData = {};});
          }else{
            this.$emit('treeEdit', this.treeForm, () => {this.showTreeDialog = false; this.nodeData = {};});
          }
        }
      });
      this.loading = false;
    },
    // 新增
    addNode() {
      this.visible = false;
      this.treeForm = {parentId : this.nodeData.id};
      this.saveOrUpdate = 'add';
      this.dialogTitle = this.$t('cip.cmn.btn.addBtn');
      this.showTreeDialog = true;
    },
    // 编辑
    editNode() {
      if (this.nodeData.id) {
        this.treeForm = this.nodeData;
        this.saveOrUpdate = 'edit';
        this.dialogTitle = this.$t('cip.cmn.btn.editBtn');
        this.showTreeDialog = true;
      } else {
        this.$message({
          message: this.$t('cip.cmn.msg.warning.selectEditNodeWarning'),
          type: 'warning'
        });
      }
      this.visible = false;
    },
    // 删除
    deleteNode() {
      if (this.nodeData.id) {
        this.$emit('treeDelete', this.nodeData, () => {this.nodeData = {};})
      } else {
        this.$message({
          message: this.$t('cip.cmn.msg.warning.selectDeleteNodeWarning'),
          type: 'warning'
        });
      }
      this.visible = false;
    },
    nodeClick(data) {
      this.nodeData = data;
      this.$emit('nodeClick', this.nodeData)
    },
    // 多选
    handleCheckChange(data) {
      console.log(data);
      this.$emit('getHandleCheckChange', data)
    },
    //筛选
    filterNode(value, data) {
      if (!value) return true;
      console.log(data);
      return data[this.searchName].indexOf(value) !== -1;
    },
    /**
     * 递归过滤嵌套数组（树形结构）
     * @param arr {Array}要过滤的原始数据
     * @param teamName {Boolean} 你要过滤的关键词
     *
    */
    recursiveFilter(arr, teamName) {
      let result = []
      arr.forEach((item1) => {
        console.log(item1[this.searchName]);
        console.log(teamName);
        if (item1[this.searchName].indexOf(teamName) != -1) {
          result.push(item1)
          if (item1.children) {
            //递归循环
            item1.children = this.recursiveFilter(item1.children, teamName)
          }
        } else {
          if (item1.children) {
            //递归循环
            item1.children = this.recursiveFilter(item1.children, teamName)
          }
        }
      })
      this.treeChangeData = result;
      return result;
    },
  },
  watch: {
    filterText(val) {
      this.$refs.commonTree.filter(val);
    },
    treeData(n) {
      if (n) {
        this.treeChangeData = n;
      }
    }
  },
  mounted() {
  },
}
</script>

<style lang='scss' scoped>
.common_tree_handle {
  // padding-right: 5px;
  box-sizing: border-box;
  border-right: 1px solid #cccccc;
}
.popv_p {
  line-height: 32px;
  text-align: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
</style>
<style lang='scss'>
.common_tree_handle {
  .el-tree {
    /* height:100% !important; */
    height: calc(100vh - 220px) !important;
    overflow-y: scroll;
  }
  .el-tree-node__content {
    height: 28px !important;
    line-height: 28px !important;
  }
}

.common_tree_notag {
  .el-tree {
    height: calc(100vh - 180px) !important;
    overflow-y: scroll;
  }
  .el-tree-node__content {
    height: 28px !important;
    line-height: 28px !important;
  }
}

.el-popover {
  min-width: 80px !important;
}
</style>
