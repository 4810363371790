<script>
import visitorReservation from "@/views/business/safe/itemList/visitorReservation/list.vue";
import visitorRecord from "@/views/business/safe/itemList/visitorRecord/list.vue";
import {mapGetters} from "vuex";

export default {
  components: {visitorRecord, visitorReservation},
data(){
  return {
    activeName:'second',
    dataForm:{},
  }
},
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
  },
  mounted() {
    this.dataForm.id=this.userInfo.dept_id
  },
  methods:{
    handleClick(){

    },
  },
}
</script>

<template>
 <basic-container>
   <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
     <el-tab-pane label="访客预约" name="second"></el-tab-pane>
     <el-tab-pane label="访客记录" name="third"></el-tab-pane>
   </el-tabs>
   <visitorReservation
     v-if="activeName == 'second'"
     ref="visitorReservation"
     :prjId="dataForm.id"
   ></visitorReservation>
   <visitorRecord
     v-if="activeName == 'third'"
     ref="visitorRecord"
     :prjId="dataForm.id"
   ></visitorRecord>
 </basic-container>
</template>

<style scoped lang="scss">
::v-deep .el-tabs__content{
  display: none;
}
</style>
