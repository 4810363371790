<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-05 09:34:41
-->
<template>
  <el-dialog title="选择人员" top="5vh" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" width="1240px" class="qmDialog" @close="closeDialog">
    <el-container>
      <el-main>
        <div class="headers">
          <grid-head-layout ref="gridHeadLayout" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')" :search-columns="searchColumns" @grid-head-search="searchChange" @grid-head-empty="searchReset">
          </grid-head-layout>
        </div>
        <grid-layout ref="gridLayout" class="userSelectGridLayout" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')" :data-total="page.total" @page-size-change="onLoad" @page-current-change="onLoad" @page-refresh-change="onLoad" :page="page" @gird-handle-select-click="selectionChange" :tableOptions="tableOptions" :tableData="tableData" :tableLoading="tableLoading"
          :gridRowBtn="gridRowBtn">
          <template slot="idNumber" slot-scope="{ row }">
            <span>
              {{ row.idNumber && row.idNumber != "" ? getAge(row.idNumber) : "" }}
            </span>
          </template>
          <template slot="radio" slot-scope="{row}">
            <el-radio :label="row.userId" v-model="radioValue" @input="getChooseValue(row)"></el-radio>
          </template>

        </grid-layout>
      </el-main>
    </el-container>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="treeDialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleSave" v-loading="treeDialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    getDeptLazyTree,
    getDeptTree,
    getLazyListDept,
  } from "@/api/system/dept";
  import {
    getUserList,
    getUserInitList
  } from "@/api/safe/gate";
  import {
    getAgeFromIdCard
  } from "@/util/util";
  import GridLayout from "@/views/components/layout/grid-layout";
  import CommonTree from "@/views/components/com_tree";
  import TreeLayout from "@/views/components/layout/tree-layout";
  export default {
    components: {
      GridLayout,
      CommonTree,
      TreeLayout,
    },
    data() {
      return {
        treeLoading: true,
        showDialog: false,
        treeDialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        title: "",
        treeData: [],
        gridRowBtn: [],
        searchTitle: "title",
        defaultProps: {
          label: "title",
          value: "key",
        },
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        tableOptions: {
          menu: false,
          rowKey: 'userTargetId',
          reserveSelection: true,
          column: [{
              label: '姓名',
              prop: "userTargetName",
              cell: true,
            },
            {
              label: "年龄",
              prop: "idNumber",
              align: "center",
              slot: true,
            },
            {
              label: '联系电话',
              prop: "phone",
              cell: true,
            },

            // {
            //   label: this.$t("cip.plat.sys.user.field.roleName"),
            //   prop: "roleName",
            //   cell: true,
            // },
            // {
            //   label: '人员类型',
            //   prop: "userTargetType",
            //   cell: true,
            //   align: "center",
            //   dataType: "number",
            //   props: {
            //     label: "dictValue",
            //     value: "dictKey",
            //   },
            //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=PrjEqGateMachineUserTargetType",
            // },

          ],
        },
        selectionList: [],
        oldSelectionList: [],
        parentPrjId: '',
        parentGateMachineId: '',
        radioValue: '',
        sourceType: 'batch',
        selectionItem: null,
        first: true,
      };
    },
    computed: {
      ids() {
        let ids = [];
        this.selectionList.forEach((ele) => {
          ids.push(ele.id);
        });
        return ids.join(",");
      },
      searchColumns() {
        return [{
          label: "",
          prop: "userTargetName",
          span: 8,
          placeholder: '请输入姓名',
        }, ];
      },

    },
    props: {
      userSure: { // 为true时，点击确认不关闭弹窗，需在父组件手动关闭
        type: Boolean,
        default: false
      }
    },
    methods: {
      //获取年龄
      getAge(data) {
        return getAgeFromIdCard(data);
      },
      init(prjId, rowid, type) {
        this.showDialog = true
        this.parentPrjId = prjId
        this.parentGateMachineId = rowid
        this.oldSelectionList = []
        this.selectionList = []
        this.selectionItem = null
        this.sourceType = type
        if (type === 'only') {
          this.radioValue = rowid && rowid.userId ? rowid.userId : ''
          if (rowid && rowid.userId != '') {
            this.selectionItem = rowid
          }
          console.log(' this.selectionItem===', this.selectionItem)
          this.tableOptions.selection = false
          this.tableOptions.column = [{
              label: '',
              prop: 'radio',
              slot: true,
              width: 80
            }, {
              label: '姓名',
              prop: "userTargetName",
              cell: true,
            },
            {
              label: "年龄",
              prop: "idNumber",
              align: "center",
              slot: true,
            },
            {
              label: '联系电话',
              prop: "phone",
              cell: true,
            },
          ]
        }
        if (type != 'only') {
          if (this.parentGateMachineId) {
            let params = {}
            params.prjId = this.parentPrjId
            params.gateMachineId = this.parentGateMachineId
            getUserList(
              params
            ).then((res) => {
              let data = res.data.data;
              this.oldSelectionList = data
              this.selectionList = data
            });
          }
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.gridLayout.$refs.grid.toggleSelection()
          }, 100);
          this.onLoad(this.page);
        });
      },
      includeDown(e) {
        this.page.currentPage = 1;
        this.query.isInclude = e;
        this.onLoad(this.page);
      },
      onLoad(page, params = {}) {
        this.page = page;
        this.tableLoading = true;
        params.prjId = this.parentPrjId
        getUserInitList(page.currentPage,
          page.pageSize,
          Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          const data = res.data.data;
          this.tableData = data.records;
          this.tableLoading = false;
          this.$nextTick(() => {
            this.$refs.gridLayout.page.total = data.total;
            // let checkData = []
            // if (this.parentGateMachineId) {
            //   if (this.tableData.length) {
            //     this.tableData.map((item, index) => {
            //       let findItem = this.selectionList.find((val) => {
            //         if (val.userTargetId === item.userTargetId) {
            //           return val
            //         } else {
            //           return null
            //         }
            //       })
            //       if (findItem) {
            //         setTimeout(() => {
            //           this.$refs.gridLayout.$refs.grid.toggleRowSelection(this.tableData[index], true);
            //         }, 100);
            //       }
            //     })
            //   }
            // }

          });

        });
      },
      getChooseValue(row) {
        // 将选中的对象保存
        this.selectionItem = row;
      },

      selectionChange(list) {
        // if(this.parentGateMachineId&&this.first){
        //   list=this.oldSelectionList
        //   this.first=false
        // }
        this.selectionList = list;
      },
      searchChange() {
        this.page.currentPage = 1;
        this.onLoad(this.page);
      },
      searchReset() {
        this.page.currentPage = 1;
        this.onLoad(this.page);
      },

      // 新增左侧树节点
      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      handleSave() {
        if (this.sourceType == 'only') {
          if (this.selectionItem) {
            this.showDialog = false;
            this.$emit("callBack", this.selectionItem);
            this.$refs.gridHeadLayout.searchForm = {};
            this.page.currentPage = 1;
          } else {
            this.$message.warning("请选择至少一条数据");
          }
        } else {
          if (this.selectionList.length) {
            this.showDialog = false;
            this.$emit("callBack", this.selectionList);
            this.$refs.gridHeadLayout.searchForm = {};
            this.page.currentPage = 1;
          } else {
            this.$message.warning("请选择至少一条数据");
          }
        }

      },
      closeDialog() {
        this.$refs.gridHeadLayout.searchForm = {};
        this.page.currentPage = 1;
        this.radioValue = ''
        // this.$refs.CommonTree.filterText = ''
        this.showDialog = false;
      },
    },
  };

</script>
<style scoped lang="scss">
  ::v-deep .userSelectGridLayout .avue-crud .el-table {
    height: 50vh !important;
    max-height: 50vh !important;
    box-sizing: border-box;
  }

  ::v-deep .userSelectTreeLayout .el-tree {
    height: 50vh !important;
    max-height: 50vh !important;
    overflow-y: scroll;
  }

  ::v-deep .el-radio__label {
    display: none !important;
  }

</style>
