<template>
    <div style="position: relative">
        <el-container>
            <el-main>
                <grid-head-layout
                    ref="gridHeadLayout"
                    :search-columns="searchColumns"
                    @grid-head-search="gridHeadSearch"
                    @grid-head-empty="gridHeadEmpty"
                ></grid-head-layout>
                <grid-layout ref="gridLayOut"
                    :grid-row-btn="gridRowBtn"
                    :table-options="tableOptions"
                    :table-data="tableData"
                    :table-loading="tableLoading"
                    :data-total="page.total"
                    :page="page"
                    @page-current-change="handleCurrentChange"
                    @page-size-change="handleSizeChange"
                    @page-refresh-change="onLoad"
                    @row-view="rowView"
                    @row-download="rowDownload"
                    @row-remove="rowRemove"
                    @row-setting="rowSetting"
                >
                  <template #synchronizationStatus="{row}">
                    {{row.synchronizationStatus | synchronizationStatusFilter}}
                  </template>
                  <template #customBtn="{row}">
                    <el-button type="text" @click="aaaa(row)">重新同步</el-button>
                  </template>
                </grid-layout>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {getListSync,againSync} from "@/api/training/eduquestions";
import { mapGetters } from 'vuex'
import { downloadFileBlob } from "@/util/util";
import { dateFormat } from '@/util/date'
import { deptChildTree } from "@/api/reportTemplate";
import pdf from "@/components/pdf/pdf.vue"
import UserDetpDialog from '@/views/components/UserDeptDialog/UserDetpDialog.vue'
export default {
    components: {
        HeadLayout,
        GridLayout,
        pdf,
        UserDetpDialog
    },
  filters:{
    synchronizationStatusFilter(val){
      // console.log(2222)
      const obj={
        2:'无需同步',
        3:'同步失败',
        4:'同步成功',
        1:'未同步'
      }
      return obj[val] || '未同步'
    },
  },
    data() {
        return {
            nodeData: {},
            treeData: [],
            searchTitle: "deptName",
            defaultProps: {
                children: "children",
                label: "deptName",
            },
            searchColumns: [
                {
                    prop: "subjectName",
                    placeholder: "题库名称",
                    span: 4,
                },
              {
                prop: "startDate",
                placeholder: "开始",
                span: 4,
                type: 'date-format',
              },
              {
                prop: "endDate",
                placeholder: "结束",
                span: 4,
                type: 'date-format',
              },
            ],
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            query: {},
            dialogVisible: false,
            remaindForm: {
                dateRemind: 1,
                noticeUserId: '',
                noticeUserName: '',
            },
            dialogLoading: false,
            settingId: '',
            headers: { Authorization: this.$store.getters.token },
            // operition: false,
            fileUrl: {},
            imageDiavlog: false,
            userVisible: false,
            show: false
        }
    },

    created() {
        this.lazyDeptTree()
        this.onLoad(this.page)
    },

  computed: {
    ...mapGetters(["permission","language"]),
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 220,
        selection: false,
        linklabel: "fileName",
        column: [
          {
            label: "题库编号",
            align: "center",
            prop: "id",
            overHidden: true,
          },
          {
            label: "题库名称",
            align: "left",
            prop: "subjectName",
            overHidden: true,
          },
          {
            label: "同步状态",
            align: "center",
            prop: "synchronizationStatus",
            slot: true,
            width: 120,
          },
          {
            label: "上传时间",
            align: "center",
            prop: "updateTime",
            width: 220,
          },
          // {
          //   label: "状态",
          //   align: "center",
          //   prop: "fileDesc",
          //   width: 120
          // },
        ],
      };
    },
  },

    methods: {
      aaaa(row){
        this.$confirm("确定重新同步当前数据?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          // debugger
          againSync(row.id).then(() => {
            this.onLoad(this.page);
            this.$message.success('操作成功！')
          })
        });
      },
        showChange(v) {
            this.show = v;
        },
        /** 左侧列表操作 */
        //获取树数据
        lazyDeptTree() {
            deptChildTree().then((res) => {
                this.treeData = [res.data.data];
                this.nodeData = res.data.data;
                this.$nextTick(() => {
                    this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
                        this.nodeData.id
                    );
                    this.handleNodeClick(this.nodeData);
                });
            });
        },
        handleNodeClick(data, node) {
            this.nodeData = data
            this.page.currentPage = 1;
            this.query.belongOrgId = data.id
            this.onLoad(this.page)
        },


        /**----end---- */
        /** 右侧表格操作 */
        onLoad(page, params = {}) {
          this.tableLoading = true;
          this.page = page;
          params.subjectType = this.subjectType
          getListSync(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
            // debugger
            const data = res.data.data;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.tableLoading = false;
            this.$refs.gridLayOut.selectionClear()
          });
        },
        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        // 搜索
        gridHeadSearch(query) {
            // debugger
            const data = this.query
            this.page.currentPage = 1;
            query.certificateType = query.certificateType_search ? query.certificateType_search.toString() : ''
            this.query = { ...data, ...query };
            this.onLoad(this.page)
        },
        // 重置
        gridHeadEmpty(query) {
            this.page.currentPage = 1;
            const data = this.query
            this.query = { ...data, ...query };
            this.onLoad(this.page)
        },

        rowRemove(row, index) {
            this.$confirm("确定删除当前数据?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                enterpriseRemove(row.id).then(() => {
                    this.onLoad(this.page);
                    this.$message.success('操作成功！')
                })
            });
        },

        rowDownload(row) {
            downloadFileBlob(row.files, row.fileName);
        },
        rowView(row) {
            let pos = row.files.lastIndexOf(".")
            const type = row.files.slice(pos + 1, row.files.length)
            this.fileUrl = { src: row.files, name: row.fileName, type }
            this.imageDiavlog = true
        },

        rowSetting(row) {
            this.remaindForm.dateRemind = row.dateRemind ? row.dateRemind : 1
            this.remaindForm.noticeUserId = row.noticeUserId ? row.noticeUserId : ''
            this.remaindForm.noticeUserName = row.noticeUserName ? row.noticeUserName : ''
            this.settingId = row.id
            this.dialogVisible = true
        },
        handleCancel() {
            this.dialogVisible = false
        },
        handleSave() {
            const { settingId, remaindForm } = this
            remaindForm.id = settingId
            enterpriseSubmit({ ...remaindForm }).then(() => {
                this.$message.success('操作成功')
                this.onLoad(this.page)
            })
            this.handleCancel()
        },

        // handleUploadSuccess(res, file, fileList) {
        //     if (res && res.code == 200) {
        //         const fileName = file.name
        //         const files = file.response.data.link
        //         this.$refs.fileUpload.clearFiles();
        //         this.$refs.cedForm.init({ fileName, files, belongOrgId: this.nodeData.id })
        //     }
        // },

        // 设置提醒人
        changeUser() {
            this.userVisible = true
            this.$refs.remaindFormUser.blur()
        },

        selectData(row, deptId, deptName) {
            // console.log("进行输出人员-------------------",row)
            const data = {
                userId: row.id,
                userName: row.realName,
                // orgId: deptId,
                // orgName: deptName,
                // sex: row.sex,
                // sexName: row.sexName
            }
            this.remaindForm.noticeUserId = row.id
            this.remaindForm.noticeUserName = row.realName
            this.userVisible = false
        },

        getFileName(name) {
            let pos = name.lastIndexOf(".")
            return name.slice(0, pos)
        },

        callback() {
            this.page.currentPage = 1
            this.onLoad(this.page)
        }
    }
}
</script>

<style lang="scss" scoped>
.file-name {
    color: #6F91C1;
    cursor: pointer;
    text-decoration: underline;
}
</style>
