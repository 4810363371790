<template>
  <div style="position: relative">
    <page :checkType="checkType"></page>
    <!-- <div>
      <head-layout
        head-title="不定期检查"
        :head-btn-options="headBtnOptions"
        @head-romve="headRomve"
        @head-add="headAdd"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            v-if="row.prjState !== 0"
            type="text"
            size="small"
            @click="rowEdit(row)"
            >编辑</el-button
          >
        </template>
      </grid-layout>
    </div> -->
  </div>
</template>
<script>
import page from "@/views/hiddenTrouble/check/daily/index";
export default {
  components: {
    page,
  },
  data() {
    return {
      // 1日常，2定期，3专项，4季节，5节假日，6不定期
      checkType:5
    };
  },
};
</script>
