var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "riskItemView-checklist" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.MD_SECTION, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: item.label, name: item.value },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "flex-container" },
        [
          _c("CommonTreeNew", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName == 1,
                expression: "activeName == 1",
              },
            ],
            ref: "commonTree",
            attrs: {
              treeTitle: "项目架构",
              defaultProps: _vm.Props,
              showCheckbox: false,
              urlParmas: {
                tenantId: "",
                deptCategory: "",
                parentId: _vm.userInfo.dept_id,
              },
              "node-key": "id",
            },
            on: {
              getNodeClick: _vm.handleNode,
              getTreeData: _vm.commonTreeData,
            },
          }),
          _c("div", { class: _vm.activeName == 1 ? "calcWidth" : "Width" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == "2",
                    expression: "activeName == '2'",
                  },
                ],
              },
              [
                _c("head-layout", {
                  staticClass: "head-titles",
                  attrs: { "head-title": "隐患统计" },
                }),
                _c(
                  "div",
                  { staticClass: "charts-box" },
                  [
                    _c("el-card", { staticClass: "charts-icon" }, [
                      _c("div", {
                        ref: "bar0",
                        staticClass: "icon charts-box no-border-right",
                      }),
                    ]),
                    _c("el-card", { staticClass: "charts-icon" }, [
                      _c("div", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "bar",
                        staticClass: "bar charts-box no-border-right",
                      }),
                    ]),
                    _c("el-card", { staticClass: "charts-icon" }, [
                      _c("div", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "bar1",
                        staticClass: "icon charts-box no-border-right",
                      }),
                    ]),
                  ],
                  1
                ),
                _c("el-card", { staticClass: "pie-box" }, [
                  _c("div", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading1,
                        expression: "loading1",
                      },
                    ],
                    ref: "pie",
                    staticClass: "pie charts-icon",
                  }),
                ]),
              ],
              1
            ),
            _vm.activeName != "2"
              ? _c(
                  "div",
                  { staticClass: "table-box" },
                  [
                    _c("head-layout", {
                      attrs: {
                        "head-title": "隐患清单",
                        "head-btn-options": _vm.headBtnOptions,
                      },
                      on: {
                        "head-romve": _vm.headRomve,
                        "head-add": _vm.headAdd,
                      },
                    }),
                    _c("grid-head-layout", {
                      ref: "gridHeadLayout",
                      attrs: {
                        "search-span": 3,
                        "search-columns": _vm.searchColumns,
                      },
                      on: {
                        "grid-head-search": _vm.gridHeadSearch,
                        "grid-head-empty": _vm.gridHeadEmpty,
                      },
                      model: {
                        value: _vm.searchForm,
                        callback: function ($$v) {
                          _vm.searchForm = $$v
                        },
                        expression: "searchForm",
                      },
                    }),
                    _c("grid-layout", {
                      ref: "gridLayOut",
                      attrs: {
                        "table-options": _vm.tableOption,
                        "table-data": _vm.tableData,
                        "table-loading": _vm.tableLoading,
                        "data-total": _vm.page.total,
                        page: _vm.page,
                      },
                      on: {
                        "grid-row-detail-click": _vm.rowView,
                        "gird-handle-select-click": _vm.selectionChange,
                        "page-current-change": _vm.onLoad,
                        "page-size-change": _vm.onLoad,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "hdLevel",
                            fn: function ({ row }) {
                              return [
                                row.hdLevel
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: _vm.hdLevelIcon[row.hdLevel],
                                          effect: "dark",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(row.$hdLevel) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "hdStatus",
                            fn: function ({ row }) {
                              return [
                                row.hdStatus && !row.outOfTime
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass: "hdStatus",
                                        style: {
                                          backgroundColor:
                                            _vm.hdStatusIcon[row.hdStatus],
                                          borderColor:
                                            _vm.hdStatusIcon[row.hdStatus],
                                        },
                                        attrs: { effect: "dark" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(row.$hdStatus) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                row.outOfTime
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass: "hdStatus",
                                        style: {
                                          backgroundColor: "#bd3124",
                                          borderColor: "#bd3124",
                                        },
                                        attrs: { effect: "dark" },
                                      },
                                      [_vm._v("超期未整改\n            ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "customBtn",
                            fn: function ({ row }) {
                              return [
                                row.hdStatus != "prepare"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { margin: "0 3px" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowView(row)
                                          },
                                        },
                                      },
                                      [_vm._v("查看\n            ")]
                                    )
                                  : _vm._e(),
                                row.assessmentFlag && row.hdStatus == "unassess"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { margin: "0 3px" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowAssess(row)
                                          },
                                        },
                                      },
                                      [_vm._v("评估\n            ")]
                                    )
                                  : _vm._e(),
                                row.hdStatus == "unrectify" && row.rectifyFlag
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { margin: "0 3px" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowRectification(row)
                                          },
                                        },
                                      },
                                      [_vm._v("整改\n            ")]
                                    )
                                  : _vm._e(),
                                row.hdStatus == "unaccept" &&
                                row.acceptUser == _vm.userInfo.user_id &&
                                row.acceptDept == _vm.userInfo.dept_id
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { margin: "0 3px" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowChecked(row)
                                          },
                                        },
                                      },
                                      [_vm._v("验收\n            ")]
                                    )
                                  : _vm._e(),
                                row.hdStatus == "prepare" &&
                                _vm.userInfo.user_id == row.createUser
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { margin: "0 3px" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowEdit(row)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑\n            ")]
                                    )
                                  : _vm._e(),
                                row.hdStatus == "prepare" &&
                                _vm.userInfo.user_id == row.createUser
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { margin: "0 3px" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowRemove([row])
                                          },
                                        },
                                      },
                                      [_vm._v("删除\n            ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        656936850
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }