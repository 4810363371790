var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticStyle: { position: "relative" } },
    [
      _c("CommonTreeNew", {
        ref: "commonTreeCore",
        attrs: {
          treeTitle: "组织架构",
          defaultProps: _vm.defaultProps,
          showCheckbox: false,
          urlParmas: {
            tenantId: "",
            deptCategory: "2",
            parentId: _vm.userInfo.dept_id,
          },
          "node-key": "id",
          isShowdig: false,
          isZoom: true,
          show: _vm.show,
        },
        on: {
          getNodeClick: _vm.handleNodeClick,
          getTreeData: _vm.commonTreeData,
          showChange: _vm.showChange,
        },
      }),
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-title": "职员证照",
              "head-btn-options": _vm.headBtnOptions,
            },
            on: { "head-add": _vm.handleHeadAdd },
          }),
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: { "search-columns": _vm.searchColumns },
            on: {
              "grid-head-search": _vm.gridHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.tableOptions,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.page.total,
              page: _vm.page,
            },
            on: {
              "page-current-change": _vm.handleCurrentChange,
              "page-size-change": _vm.handleSizeChange,
              "page-refresh-change": _vm.onLoad,
              "row-view": _vm.rowView,
              "row-edit": _vm.rowEdit,
            },
            scopedSlots: _vm._u([
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    _vm.permissionList.editBtn
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.rowEdit(row)
                              },
                            },
                          },
                          [_vm._v("\n          编辑\n        ")]
                        )
                      : _vm._e(),
                    _vm.permissionList.viewBtn
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.rowView(row)
                              },
                            },
                          },
                          [_vm._v("\n          查看\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.dialogVisible,
            width: "80%",
            top: "8vh",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }