<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-23 14:16:50
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-08-16 13:59:18
 * @Description: 
-->
<template>
  <div class="main-container">
    <el-container>
      <CommonTree
        ref="commonTreeCore"
        treeTitle="设备分类"
        :treeData="treeData"
        nodeKey="id"
        searchTitle="typeName"
        :defaultProps="{ label: 'typeName' }"
        isShowdig
        @getTreeAdd="getTreeAdd"
        @getTreeEdit="getTreeEdit"
        @getTreeDelete="getTreeDelete"
        @getNodeClick="handleNodeClick"
      />
      <el-main>
        <div class="equimpentStatistics">
          <head-layout head-title="设备统计" />
          <div class="equimpentStatistics-card">
            <el-row :gutter="20">
              <el-col :span="6"
                ><div class="card-item">
                  <div class="card-item-title">设备总数</div>
                  <div class="card-item-num">{{ totalEquipment }}</div>
                </div></el-col
              >
              <el-col :span="6"
                ><div class="card-item">
                  <i class="iconfont icon-wxbgongju"></i>
                  <div class="card-item-title">待检查设备数量</div>
                  <div class="card-item-num">{{ needCheck }}</div>
                </div></el-col
              >
              <el-col :span="6"
                ><div class="card-item">
                  <i class="el-icon-tickets"></i>
                  <div class="card-item-title">待保养设备数量</div>
                  <div class="card-item-num">{{ needMaint }}</div>
                </div></el-col
              >
              <el-col :span="6"
                ><div class="card-item">
                  <i class="iconfont iconicon_setting"></i>
                  <div class="card-item-title">维修中设备数量</div>
                  <div class="card-item-num">{{ repairing }}</div>
                </div></el-col
              >
            </el-row>
          </div>
        </div>
        <div style="background-color: #f4f5f6; width: 100%; height: 10px"></div>
        <div class="equimpentInventory">
          <head-layout
            head-title="设备清单"
            :head-btn-options="headBtnOptions"
            @head-add="headAdd"
            @head-export="headExport"
            @head-import="headImport"
            @head-romve="headRomve"
          />
          <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            ref="gridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @grid-row-detail-click="rowView"
            @page-current-change="handleCurrentChange"
            @page-size-change="handleSizeChange"
            @page-refresh-change="onLoad"
            @row-edit="rowEdit"
            @row-remove="rowRemove"
            @gird-handle-select-click="selectionChange"
          >
          <template #equipmentCode="{row,index}">
            <el-link type="primary" @click="rowView(row)">{{ row.equipmentCode }}</el-link>
          </template>
          <template #qualifiedDate="{row}">
              <span :class="overDue(row.qualifiedDate) ? 'overDue' : ''">{{ row.qualifiedDate }}</span>
          </template>
          <template #eqStatus="{row}">
            <div class="eq-status-box">
              <span class="eq-status" :class="`eq-status-${row.eqStatus}`">{{ getDicValue(statusList,row.eqStatus) }}</span>
            </div>
          </template>
        </grid-layout>
        </div>
      </el-main>
    </el-container>
    <!-- 设备分类左侧树操作 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="设备分类"
      :before-close="handleTreeCancel"
    >
      <avue-form
        :option="treeDialogOption"
        ref="cedForm"
        v-model="treeForm"
        @submit="handleSubmit"
      ></avue-form>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleTreeCancel()"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
    <el-dialog
      title="设备清单导入"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          :upload-after="uploadAfter"
          v-if="excelBox"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{ $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import { mapGetters } from "vuex";

import * as API from "@/api/equipmentFacilities/equipmentLedger";
import { downloadXls } from "@/util/util";
import { exportBlob } from "@/api/common";
import {getToken} from '@/util/auth';
import {getDictionaryCode} from "@/api/system/dictbiz";
import {dateFormat} from "@/util/date";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      treeData: [],
      treeParentData: [],
      dialogVisible: false,
      treeForm: {
        typeName: "",
        parentId: "",
        id: "",
      },
      treeDialogLoading: false,
      excelBox: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.excelFile"),
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            data: { typeId: "" },
            action: "/api/sinoma-hse-prj/eqequipment/import",
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
      nodeData: {},
      tableData: [],
      selectionList: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      queryTypeId: "",
      query: {},
      totalEquipment: 0,
      needCheck: 0,
      needMaint: 0,
      repairing: 0,
      searchColumns: [
        {
          label: "设备编号",
          prop: "equipmentCode",
          placeholder: "请输入设备编号",
          span: 4,
        },
        {
          label: "设备名称",
          prop: "equipmentName",
          placeholder: "请输入设备名称",
          span: 4,
        },
        {
          label: "设备规格型号",
          prop: "spc",
          placeholder: "请输入设备规格型号",
          span: 4,
        },
        {
          label: "状态",
          prop: "eqStatus",
          span: 4,
          type: "select",
          placeholder: "请选择状态",
          dicUrl:"/api/sinoma-system/dict-biz/dictionary?code=eq_status",
          props: {
              label: "dictValue",
              value: "dictKey",
          },
        
        },
        {
          label: "日期",
          prop: "daterange",
          type: "daterange",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          span: 4,
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期",
        },
      ],
      statusList: [],
    };
  },
  computed: {
    ...mapGetters(["permission", "userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      // console.log(ids)
      return ids.join(",");
    },
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
      };
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permissionList.addBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
      }
      buttonBtn.push({
        label: "导出",
        emit: "head-export",
        type: "button",
        icon: "",
        btnOptType: "export",
      });
      buttonBtn.push({
        label: "导入",
        emit: "head-import",
        type: "button",
        icon: "",
        btnOptType: "import",
      });
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: "",
      });
      return buttonBtn;
    },

    tableOptions() {
      return {
        customRowAddBtn: false,
        menuWidth: 200,
        menuFixed: "right",
        selection: true,
        linklabel: "equipmentCode",
        column: [
          {
            label: "设备编号",
            prop: "equipmentCode",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "设备名称",
            prop: "equipmentName",
            align: "left",
            overHidden: true,
          },
          {
            label: "规格型号",
            prop: "spc",
            align: "center",
            overHidden: true,
          },
          {
            label: "启用日期",
            prop: "activationDate",
            align: "center",
            overHidden: true,
            width: '120px'
          },
          { 
            label: "位置",
            prop: "location",
            align: "center",
            overHidden: true,
          },
          {
            label: "下次维保时间",
            prop: "nextMaintDate",
            align: "center",
            width: '120px',
            overHidden: true,
          },
          {
            label: "状态",
            prop: "eqStatus",
            align: "center",
            overHidden: true,
            slot: true
            // dicUrl:"/api/sinoma-system/dict-biz/dictionary?code=eq_status",
            // props: {
            //     label: "dictValue",
            //     value: "dictKey",
            // },
          },
          {
            label: "进场日期",
            prop: "entryDate",
            align: "center",
            width: '120px',
            overHidden: true,
          },
          {
            label: "离场日期",
            prop: "exitDate",
            align: "center",
            width: '120px',
            overHidden: true,
          },
          {
            label: "是否检验合格",
            prop: "qualifiedFlag",
            align: "center",
            overHidden: true,
            dicUrl:"/api/sinoma-system/dict-biz/dictionary-tree?code=yesNo",
            width: '120px',
            props: {
                label: "dictValue",
                value: "dictKey",
            },
          },
          {
            label: "使用单位",
            prop: "useOrgName",
            align: "center",
            overHidden: true,
          },
          {
            label: "检验合格有效期",
            prop: "qualifiedDate",
            align: "center",
            width: '120px',
            slot: true,
            overHidden: true,
          },
          
        ],
      };
    },

    gridRowBtn() {
      let buttonBtn = [];
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },

    treeDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "typeName",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur"],
              },
            ],
          },
          {
            label: "上级名称",
            prop: "parentId",
            type: "tree",
            disabled: false,
            dicData: this.treeParentData,
            props: {
              label: "typeName",
              value: "id",
            },
            rules: [
              {
                validator: this.partentRule,
              },
            ],
          },
        ],
      };
    },
  },
  mounted() {
    this.getDictionaryCode()
    this.getTreeData();
  },
  methods: {
    partentRule(rule, value, callback) {
      if (value === "" || !this.treeForm.id) {
        callback();
      } else if (value == this.treeForm.id) {
        callback(new Error("不能选择自己作为上级!"));
      } else {
        callback();
      }
    },
    /** 左侧列表操作 */
    getTreeData() {
      API.eqLedgeClassTree({orgId:this.userInfo.dept_id}).then((res) => {
        this.treeData = res.data.data;
        if (this.treeData.length) {
          // console.log(this.treeData)
          this.$nextTick(() => {
            this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
              this.treeData[0].id
            );
            this.handleNodeClick(this.treeData[0]);
          });
        }
      });
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.queryTypeId = data.id;
      this.excelOption.column[0].data = { typeId: data.id, orgId: this.userInfo.dept_id };
      this.page.currentPage = 1;
      this.onLoad(this.page);
      this.getCount();
    },
    getTreeAdd() {
      this.treeParentData = this.treeData;
      this.treeForm = { typeName: "", parentId: "", id: "", orgId: this.userInfo.dept_id };
      if (this.nodeData.id) this.treeForm.parentId = this.nodeData.id;
      this.dialogVisible = true;
    },
    getTreeEdit() {
      // treeParentData
      const data = JSON.parse(JSON.stringify(this.treeData));
      const { typeName, id, parentId, orgId } = this.nodeData;
      this.getParentData(data, id);
      this.treeForm = { typeName, id, parentId:parentId>0?parentId:"", orgId };
      this.dialogVisible = true;
    },
    getParentData(data, id) {
      const arr = data.map((val, index) => {
        if (val.id == id) {
          data.splice(index, 1);
        } else if (val.children) {
          this.getParentData(val.children, id);
          return val;
        } else {
          return val;
        }
      });
      this.treeParentData = arr;
    },
    getTreeDelete() {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.eqLedgeClassRemove(this.nodeData.id).then(() => {
          this.getTreeData();
          this.$message.success("操作成功");
        });
      });
    },
    handleTreeCancel() {
      this.dialogVisible = false;
    },
    handleSubmit(data, done) {
      this.$refs.cedForm.validate((valid) => {
        if (valid) {
          this.treeDialogLoading = true;
          API.eqLedgeClassSubmit(this.treeForm)
            .then(() => {
              this.getTreeData();
              this.$message.success("操作成功");
              this.treeDialogLoading = false;
              this.handleTreeCancel();
              done();
            })
            .catch((err) => {
              done();
            })
            .finally(() => {
              this.treeDialogLoading = false;
            });
        }
      });
      // if(this.treeForm.id != this.treeForm.parentId || !this.treeForm.parentId ){
      // }
    },
    handleTreeNodeSave() {
      // if(this.treeForm.id != this.treeForm.parentId || !this.treeForm.parentId ){
      this.$refs.cedForm.submit();
      // }
    },
    // 获取设备统计数量
    getCount() {
      API.eqLedgerInventoryCount({
        orgId: this.userInfo.dept_id,
        typeId: this.queryTypeId,
      }).then((res) => {
        this.totalEquipment = res.data.data.total;
        this.needCheck = res.data.data.needCheck;
        this.needMaint = res.data.data.needMaint;
        this.repairing = res.data.data.repairing;
      });
    },
    // 筛选列表
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      searchForm.startTime = searchForm.daterange ? searchForm.daterange[0] : ''
      searchForm.endTime = searchForm.daterange ? searchForm.daterange[1] : ''
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      let query = {...params}
      delete query.daterange
      API.eqLedgerInventoryPage({
        current: page.currentPage,
        size: page.pageSize,
        orgId: this.userInfo.dept_id,
        typeId: this.queryTypeId,
        ...query,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 批量删除
    headRomve() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return API.eqLedgerInventoryRemove(this.ids);
        })
        .then(() => {
          this.getCount()
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 单条删除
    rowRemove(row) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.eqLedgerInventoryRemove(row.id).then(() => {
          this.getCount()
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    headAdd() {
      this.$router.push({
        path: `/equipmentFacilities/equipmentLedger/edit`,
        query: {
          type: "add",
          typeId: this.queryTypeId,
        },
      });
    },
    rowEdit(row) {
      this.$router.push({
        path: `/equipmentFacilities/equipmentLedger/edit`,
        query: {
          type: "edit",
          typeId: this.queryTypeId,
          id: row.id,
          code: row.equipmentCode,
          name: row.equipmentName,
          orgId: row.orgId,
          orgCode: row.orgId,
          specialFlag: row.specialFlag
        },
      });
    },
    headImport() {
      if (this.nodeData.id) {
        this.excelBox = true;
      } else {
        this.$message.warning("请先选择设备分类");
      }
    },
    headExport() {
      const typeId = this.nodeData.id ? this.nodeData.id : "";
      const deviceName = this.query.deviceName ? this.query.deviceName : "";
      const title = this.nodeData.typeName
        ? this.nodeData.typeName
        : "设备清单";
      exportBlob(
        `/api/sinoma-hse-prj/eqequipment/export?typeId=${typeId}&orgId=${this.userInfo.dept_id}&deviceName=${deviceName}`
      ).then((res) => {
        downloadXls(res.data, title);
      });
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.page.currentPage = 1;
      this.onLoad(this.page);
      done();
    },
    handleTemplate() {
      exportBlob(
        `/api/sinoma-hse-prj/eqequipment/exportTemplate?${
          this.website.tokenHeader
        }=${getToken()}`
      ).then((res) => {
        downloadXls(res.data, "设备清单模板.xlsx");
      });
    },
    rowView(row) {
      this.$router.push({
        path: `/equipmentFacilities/equipmentLedger/edit`,
        query: {
          type: "view",
          typeId: this.queryTypeId,
          id: row.id,
          code: row.equipmentCode,
          name: row.equipmentName,
          specialFlag: row.specialFlag
        },
      });
    },
    getDictionaryCode() {
        getDictionaryCode("eq_status").then((res) => {
            this.statusList = res.data.data;
        });
    },

    getDicValue (dic, key) {
        let data = dic.find(val => val.dictKey == key)
        return data ? data.dictValue : ''
    },

    overDue (date) {
        if(date){
            let newDate = new Date(dateFormat(new Date(), 'yyyy-MM-dd')).getTime()
            let str = new Date(date).getTime()
            return str<newDate
        }
        return false
    }
  },
};
</script>
<style scoped lang="scss">
.main-container {
  width: 100%;
  height: 100%;
  .el-main{
    height: 100%;
  }
}
::v-deep .el-container {
  width: 100%;
  height: 100%;
}
.equimpentStatistics {
  .equimpentStatistics-card {
    padding: 12px;
    height: 100px;
    ::v-deep .el-row{
      height: 100%;
    }
    ::v-deep .el-col{
      height: 100%;
    }
    // padding: 14px 14px 20px;
    .card-item {
      background-color: #1990ff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // padding: 20px 0;
      height: 100%;
      color: #fff;
      position: relative;
      .card-item-title {
        font-size: 14px;
      }
      .card-item-num {
        font-size: 24px;
      }
      i {
        position: absolute;
        left: 5px;
        top: 5px;
      }
    }
  }
}
.equimpentInventory {
  height: calc(100% - 172px);
  overflow: hidden;
}

::v-deep #gridLayout {
  height: calc(100% - 138px) !important;
}
::v-deep .avue-crud {
  height: 100% !important;

  .el-card{
      height: 100% !important;
    .el-card__body  {
      height: 100% !important;
  
      .el-form {
        height: 100% !important;
  
        .el-table {
          height: 100% !important;
        }
      }
  }

  }
}
.eq-status-box{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.eq-status{
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
}
.eq-status-1{ // 启用
  border: 1px solid #67c23a;
  color: #67c23a;
  background-color: #EEF8E8;
}
.eq-status-2{ // 停用
  border: 1px solid #606266;
  color: #606266;
  background-color: #F2F2F3;
}
.eq-status-3{ // 维修中
  border: 1px solid #E49D3F;
  color: #E49D3F;
  background-color: #FDF5E9;
}
.eq-status-4{ // 报废
  border: 1px solid #F69090;
  color: #F69090;
  background-color: #FEEEEE;
}
.overDue{
    color: #C5211B;
}
</style>
