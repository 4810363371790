import riskView from "@/views/hiddenTrouble/riskItemView/riskView";
import {mapGetters} from "vuex";
import { fieldInfo } from "@/api/base/length";
export default {
  mixins:[riskView],
  computed:{
    ...mapGetters([
      'userInfo',
    ]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    tableOption() {
      return {
        // selectable:(row)=>{
        //   return row.hdStatus == 'prepare' && this.userInfo.user_id == row.createUser
        // },
        linklabel: "taskCode",
        column: [{
          label: "任务编号",
          prop: "taskCode",
          align: "left",
          overHidden: true,
          width: 200
        },
          {
            label: "任务名称",
            prop: "taskName",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "分类",
            prop: "checkType",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=check_type`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: fieldInfo.Words5.align,
            minWidth: fieldInfo.Words5.length,
            overHidden: true,
          },
          {
            label: "所属组织",
            prop: "organizationName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true,
            hide:this.activeName  != 'first'
          },
          {
            label: "起草人",
            prop: "createUserName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
            hide:this.activeName  != 'first'
          },
          {
            label: "责任人",
            prop: "respNickName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
            hide:this.activeName  == 'first'
          },
          {
            label: "检查人",
            prop: "nickName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
            hide:this.activeName  == 'first'
          },  {
            label: "发起人",
            prop: "startUserName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
            hide:this.activeName  == 'first'
          },
          {
            label: "检查日期",
            prop: "checkTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "taskStatus",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=CHECK_TASK_STATUS`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: fieldInfo.Words4.align,
            minWidth: fieldInfo.Words4.length,
            overHidden: true,
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },

        ],
      }

    },
  },
  data(){
    return {

      tableData: [],
      tableLoading: true,
      isCycle: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [{
        label: '任务编号',
        prop: "taskCode",
        span: 3,
        placeholder: '请输入任务编号'
      },
        {
          label: '任务名称',
          prop: "taskName",
          span: 3,
          placeholder: '请输入任务名称'
        },
        {
          label: '检查分类',
          prop: "checkType",
          span: 3,
          type: 'select',
          placeholder: '请选择隐患排查分类',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=check_type`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          label: '组织名称',
          prop: "organizationName",
          span: 3,
          placeholder: '请输入组织名称'
        },
        {
          label: '状态',
          prop: "taskStatus",
          span: 3,
          type: 'select',
          placeholder: '请选择状态',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=CHECK_TASK_STATUS`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          label: '开始时间',
          prop: "time",
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          defaultTime:['00:00:00','23:59:59'],
          format:'yyyy-MM-dd',
          valueFormat:'yyyy-MM-dd',
          type: 'daterange',
          span: 4,
          placeholder: '请选择开始时间'
        },
      ],
    }
  },
  mounted() {

  },
  methods:{
    initLine(myChart,data){
      let seriesData=[]
      for (const dataKey in data) {
            let item=data[dataKey]
        seriesData.push({
          name: this.hdLevel[dataKey],
          type: "line",
          itemStyle:{
            color:this.hdLevelColor[dataKey],
          },
          smooth: true,
          symbolSize: 8,
          data: item,
        })
      }

      let option = {
        tooltip: {
          show: true,
          trigger: "axis",
        },
        legend: {
          show: true,
          x: "center",
          y: "35",
          icon:'circle',
          data: this.hdLevelText,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              formatter: "{value}",
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#11366e",
              },
            },
          },
        ],
        series: seriesData
      };
      option && myChart.setOption(option);
    },
    sameReduce(arr) {
      let result = arr.reduce((acc, cur) => {
        let found = acc.find((item) => {
          return item.deptId === cur.deptId
        })
        if (found) {
          found.value += cur.countNumber
        } else {
          acc.push({ deptId: cur.deptId, value: cur.countNumber,name: cur.deptName, })
        }
        return acc
      }, [])
      return result
    },
    initPie(myChart,data){
      let that=this;
      let seriesData=[]
      let seriesData1=[]
      let newData=[]
      seriesDataFun(data)
      function seriesDataFun(){

        seriesData1=data.map(item=>{
            return  item.deptName
        })
        newData=data.map(item=>{
            return   { value: item.countNumber,groupId:item.hdLevel, name: that.hdLevel[item.hdLevel] }
        })
      };
      seriesData=this.sameReduce(data)
      console.log(newData)
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          show: true,
          x: "center",
          y: "15",
          data: seriesData1
        },
        series: [
          {
            name: '隐患总数',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],
            label: {
              show: false,
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: seriesData
          },
          {
            zlevel:1111,
            name: '隐患类型',
            type: 'pie',
            radius: ['45%', '60%'],
            labelLine: {
              length: 30
            },
            itemStyle:{
              color:function (colors) {
                return that.hdLevelColor[colors.data.groupId]
              },
            },
            label: {
              show:false,
            },
            data:newData
          }
        ]
      };
      option && myChart.setOption(option);
    },
  },
}
