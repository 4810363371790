<template>
  <div class="main-content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="库存" name="inventory"></el-tab-pane>
      <el-tab-pane label="出入库记录" name="inOutManagement"></el-tab-pane>
    </el-tabs>
    <div class="inventory-content" v-show="activeName == 'inventory'">
      <div class="left">
        <head-layout
          :head-btn-options="headListOptions"
          head-title="危化品库存情况"
          @head-add="clickcwDialog"
        >
        </head-layout>
        <div class="list" v-loading.body="listLoading">
          <el-row :gutter="10">
            <el-col :span="8" v-for="(item, index) in list" :key="item.id">
              <el-card
                :body-style="{ padding: '10px' }"
                :class="
                  item.selected ? 'list-card-selected list-card' : 'list-card'
                "
              >
                <div class="content" @click="handleItemClick(index, item)">
                  <el-image
                    style="width: 100%; height: 200px"
                    :src="item.imgUrl"
                    @click.stop="showFile(item)"
                    fit="fit"
                  >
                    <div
                      slot="error"
                      class="image-slot"
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                      "
                    >
                      <i
                        class="el-icon-picture-outline"
                        style="font-size: 20px"
                      ></i>
                      <div style="text-align: center; margin-top: 10px">
                        暂无图片
                      </div>
                    </div>
                  </el-image>
                  <el-tooltip placement="top" :content="item.itemName">
                    <div class="name">{{ item.itemName }}</div>
                  </el-tooltip>

                  <el-divider class="el-divider"></el-divider>
                  <div class="bottom">
                    <div class="num">
                      库存量:{{ item.quantity }}{{ item.unitOfMeasure }}
                    </div>
                    <div class="btn">
                      <i
                        class="el-icon-edit"
                        style="margin-right: 8px; cursor: pointer"
                        @click.stop="editItem(item)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="removeList(item)"
                        style="cursor: pointer"
                      ></i>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card :body-style="{ padding: '10px' }" class="up-card">
                <div class="upload" @click="addList">
                  <i class="el-icon-plus" style="font-size: 80px"></i>
                  <div>添加</div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="right">
        <div class="detail">
          <head-layout
            :head-btn-options="headDetailOptions"
            head-title="危化品详情"
            @head-use="headUse"
            @head-purchase="headPurchase"
            @head-return="headReturn"
            @head-handle="headHandle"
          >
          </head-layout>
          <el-form :model="formDetail" label-width="100px">
            <el-form-item label="危化品名称:">
              <div>{{ formDetail.itemName }}</div>
            </el-form-item>
            <el-form-item label="规格型号:">
              <div>{{ formDetail.spec }}</div>
            </el-form-item>
            <el-form-item label="负责人1:">
              <div>{{ formDetail.userName }}</div>
            </el-form-item>
            <el-form-item label="负责人2:">
              <div>{{ formDetail.userNameT }}</div>
            </el-form-item>
            <el-form-item label="易制毒制爆">
              <el-switch v-model="formDetail.easyFlag" disabled></el-switch>
            </el-form-item>
            <el-form-item label="库存量:">
              <div>{{ formDetail.quantity }}{{ formDetail.unitOfMeasure }}</div>
            </el-form-item>
            <el-form-item label="单次领用上限:">
              <div>
                {{ formDetail.issueLimit }}{{ formDetail.unitOfMeasure }}
              </div>
            </el-form-item>
            <el-form-item label="安全使用规范:">
              <div>{{ formDetail.remark }}</div>
            </el-form-item>
            <el-form-item label="安全标识:">
              <el-image
                :src="cItem.link"
                class="img-item"
                style="width: 100px; height: 100px; margin: 10px 10px 0 0"
                v-for="(cItem, ci) in formDetail.dmItemIconsList"
                :key="ci"
                :preview-src-list="listUrls(formDetail.dmItemIconsList)"
              >
              </el-image>
            </el-form-item>
            <el-form-item label="技术说明书:">
              <el-upload
                disabled
                :class="{ uoloadSty: showImg, disUoloadSty: true }"
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                accept=".doc,.docx,.pdf,.zip,.png,.jpg,.rar"
                :limit="1"
                :file-list="detailArray"
                :headers="headers"
                :on-success="uploadSuccess"
                :on-remove="handleFileRemove"
                :on-exceed="uploadExceed"
                :on-preview="(file) => {
                      return showFile(file, 'link');
                    }"
                :before-upload="handleBeforeUpload"
              >

              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="list">
          <head-layout :head-btn-options="[]" head-title="库存批次">
          </head-layout>
          <grid-layout
            ref="gridLayOut"
            :table-options="kctableOption"
            :table-data="kctableData"
            :table-loading="kctableLoading"
            @grid-row-detail-click="kcrowView"
            :data-total="kcPage.total"
            :page="kcPage"
            @page-current-change="getkcList"
            @page-size-change="getkcList"
          >
            <!-- vendorName -->
            <template slot="vendorName" slot-scope="{ row }">
              <div class="vendorName">
                {{ row.dmItemVendor.vendorName }}
              </div>
            </template>
          </grid-layout>
        </div>
      </div>
    </div>
    <div class="inOut-content" v-show="activeName == 'inOutManagement'">
      <head-layout
        head-title="出入库记录"
        :head-btn-options="headBtnOptions"
        @head-romve="headRmove"
        @head-add="headAdd"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridInoutHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="inoutPage.total"
        :page="inoutPage"
        @page-current-change="getInOutList"
        @page-size-change="getInOutList"
      >
        <template slot="recptStatus" slot-scope="{ row }">
          {{ row.recptStatus == "FINISHED" ? "完成" : "草稿" }}
        </template>
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowEdit(row)"
            v-if="
              row.recptStatus != 'FINISHED' && row.createUser == userInfo.id
            "
            >编辑</el-button
          >
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            v-if="
              row.recptStatus != 'FINISHED' && row.createUser == userInfo.id
            "
            @click="rowRemove(row)"
            >删除</el-button
          >
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowView(row)"
            >查看</el-button
          >
        </template>
      </grid-layout>
    </div>
    <!-- 出入库新增弹窗 -->
    <el-dialog title="危化品出入库" :visible.sync="kcDialog" class="qmDialog">
      <div class="content">
        <el-radio-group v-model="dmItemType">
          <el-radio
            :label="item.dictKey"
            v-for="item in dmItemTypeList"
            :key="item.id"
            >{{ item.dictValue }}</el-radio
          >
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="kcDialog = false">取 消</el-button>
        <el-button type="primary" @click="kcDialogRoute">确定</el-button>
      </span>
    </el-dialog>
    <!-- 储位维护列表 -->
    <el-dialog title="储位维护" :visible.sync="cwDialog" class="qmDialog">
      <div class="content cwDialog" style="width: 100%; height: 540px">
        <head-layout
          head-title="储位维护"
          :head-btn-options="headCwOptions"
          @head-remove="cwheadRmove"
          @head-add="cwheadAdd"
        ></head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="cwtableOption"
          :table-data="cwtableData"
          :table-loading="cwtableLoading"
          @grid-row-detail-click="cwrowView"
          @gird-handle-select-click="cwselectionChange"
          :data-total="cwPage.total"
          :page="cwPage"
          @page-current-change="cwonLoad"
          @page-size-change="cwonLoad"
        >
          <template #customBtn="{ row }">
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="cwrowEdit(row)"
              >编辑</el-button
            >
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="cwrowRemove(row)"
              >删除</el-button
            >
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="cwrowQRcode(row)"
              >二维码</el-button
            >
          </template>
        </grid-layout>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cwDialog = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 储位维护新增 -->
    <el-dialog title="储位维护" :visible.sync="cwaddDialog" class="qmDialog">
      <div class="content">
        <el-form
          ref="cwForm"
          :model="cwForm"
          label-width="100px"
          :rules="cwrules"
          :disabled="cwaddType == 'view'"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="储位编号" prop="warehosueCode">
                <el-input
                  maxlength="64"
                  show-word-limit
                  v-model="cwForm.warehosueCode"
                  placeholder="请输入储位编号"
                >
                  <span slot="append">
                    自动生成
                    <el-switch
                      v-model="getcwCode"
                      @change="handleSwitch"
                    ></el-switch>
                  </span>
                </el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4">
              <el-form-item label="自动生成">
                <el-switch
                  v-model="getcwCode"
                  @change="handleSwitch"
                ></el-switch>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <el-form-item label="储位名称" prop="warehosueName">
                <el-input
                  maxlength="255"
                  show-word-limit
                  v-model="cwForm.warehosueName"
                  placeholder="请输入储位名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="位置" prop="location">
                <el-input
                  v-model="cwForm.location"
                  placeholder="请输入储存位置"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cwaddDialog = false">取 消</el-button>
        <el-button type="primary" @click="addcwList">确定</el-button>
      </span>
    </el-dialog>
    <!-- 页面列表新增 -->
    <el-dialog
      :title="listAddtype == 'add' ? '新增' : '编辑'"
      :visible.sync="listDialog"
      class="qmDialog"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="危化品图片" prop="imgUrl">
              <el-upload
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                :show-file-list="true"
                :limit="1"
                :before-upload="checkFileType"
                :on-exceed="handleExceedImgUrl"
                :on-success="handleimgUrlSuccess"
                ref="uploadRef"
                list-type="picture-card"
                accept="image/*"
                :file-list="imgUrlList"
                :multiple="false"
                :on-remove="handleRemove"
                :on-preview="handlePreview"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="危化品名称" prop="itemName">
              <el-input
                placeholder="请输入危化品名称"
                v-model="form.itemName"
                maxlength="255"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格型号">
              <el-input
                v-model="form.spec"
                placeholder="请输入规格型号"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="易制毒制爆">
              <el-switch
                v-model="form.easyFlag"
                @change="easyFlagClick"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="负责人1" prop="userName">
              <!-- <userSelect
                v-model="listForm.respUserId"
                placeholder="请选择人员"
                @change="onChangePerson(arguments, item)"
                clearable
              /> -->
              <el-input
                v-model="form.userName"
                @focus="handleUserModeOpen(1)"
                placeholder="请选择人员"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="负责人2" prop="userName">
              <!-- <userSelect
                v-model="listForm.respUserId"
                placeholder="请选择人员"
                @change="onChangePerson(arguments, item)"
                clearable
              /> -->
              <el-input
                v-model="form.userNameT"
                @focus="handleUserModeOpen(2)"
                placeholder="请选择人员"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单次领用上限" prop="issueLimit">
              <el-input-number
                v-model="form.issueLimit"
                :min="1"
                :max="99999999"
                @input="handleInput"
                placeholder="单次领用上限"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="单位" prop="unitOfMeasure">
              <el-input
                v-model="form.unitOfMeasure"
                placeholder="单位"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="默认储位" prop="defaultWarehouseId">
              <!-- <el-input
                v-model="form.defaultWarehouseId"
                placeholder="默认储位"
              ></el-input> -->
              <el-select
                v-model="form.defaultWarehouseId"
                placeholder="请选择默认储位"
                clearable
              >
                <el-option
                  v-for="item in defaultWarehouseOptions"
                  :key="item.id"
                  :label="item.warehosueName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="安全使用规范">
              <el-input
                maxlength="500"
                show-word-limit
                type="textarea"
                v-model="form.remark"
                placeholder="请输入安全使用规范"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="安全标识">
              <!-- <el-upload
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                :show-file-list="true"
                :before-upload="checkFileType"
                :on-success="handleIconsSuccess"
                :on-remove="handleIconsRemove"
                ref="uploadIconRef"
                list-type="picture-card"
                :file-list="fileList"
                accept="image/*"
                :limit="9"
                :on-exceed="handleExceed"
              >
                <i class="el-icon-plus"></i>
              </el-upload> -->
              <div class="mark-list">
                <div
                  style="
                    width: 100px;
                    height: 100px;
                    margin: 10px 10px 0 0;
                    display: inline-block;
                    position: relative;
                  "
                  class="div-item"
                  v-for="(cItem, ci) in dmItemIconsList"
                  :key="ci"
                >
                  <i
                    class="el-icon-remove"
                    style="
                      font-size: 20px;
                      color: #ff0000;
                      z-index: 100;
                      position: absolute;
                      right: -4px;
                      top: -4px;
                      cursor: pointer;
                    "
                    @click="removeSiList(ci)"
                  ></i>
                  <el-image
                    :src="cItem.link"
                    class="img-item"
                    style="width: 100px; height: 100px; margin: 10px 10px 0 0"
                    :preview-src-list="listUrls(dmItemIconsList)"
                  >
                  </el-image>
                </div>
              </div>
              <el-button type="primary" @click="safeClick">引用</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="技术说明书">
              <el-upload
                :disabled="pageDisabled"
                :class="{ uoloadSty: showImg, disUoloadSty: noneUploadFile }"
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                accept=".doc,.docx,.pdf,.zip,.png,.jpg,.rar"
                :limit="1"
                :file-list="fileArray"
                :headers="headers"
                :on-success="uploadSuccess"
                :on-remove="handleFileRemove"
                :on-exceed="uploadExceed"
                :on-preview="(file) => {
                      return showFile(file, 'link');
                    }"
                :before-upload="handleBeforeUpload"
              >
                <el-button size="small"
                  ><i class="el-icon-upload2"></i> 上传文件</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  支持扩展名: .zip,.rar,.zip,.doc,.docx,.pdf,.png,.jpg
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="listDialog = false" v-loading="saveLoading"
          >取 消</el-button
        >
        <el-button type="primary" @click="saveList" v-loading="saveLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="安全标识"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="safeShow"
      v-if="safeShow"
      width="80%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div style="height: 500px">
        <SafeSelect
          @select-data="safeSelectData"
          @close-dialog="closeSafe"
        ></SafeSelect>
      </div>
    </el-dialog>
    <el-dialog
      title="人员选择"
      :visible.sync="personShow"
      width="80%"
      top="8vh"
    >
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectData"
      ></UserDetpDialog>
      <!-- <UserDeptMultipleDialog
        ref="UserDeptMultipleDialog"
        @select-all-data="selectData"
      ></UserDeptMultipleDialog> -->
    </el-dialog>
    <fileView ref="fileView" title="危化品相关文件预览"></fileView>
    <el-dialog
        :visible.sync="imageVisible"
        width="60%"
        :append-to-body="true">
      <img  style="margin-top:20px ; width: 100%; height: auto; display: block; "  :src="imageUrl" alt="">
    </el-dialog>

    <qrcodeDialog ref="qrcodeDialog"></qrcodeDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonTree from "@/views/components/com_tree/index";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import website from "@/config/website";
import qrcodeDialog from "./components/qrCode.vue";
import SafeSelect from "@/views/siteManagement/safetySigns/signSelectDialog.vue";
// import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";

import {
  getList,
  addList,
  listDetail,
  removeList,
  removecgList,
  exoprtList,
  getcwCode,
  getcwList,
  getcwAllList,
  addcwList,
  listcwDetail,
  getkcList,
  removecwList,
  getcgList,
} from "@/api/hazardousChemicals/index.js";
import { getDictionaryCode } from "@/api/system/dictbiz";
import { getToken } from "@/util/auth";
import {downloadFileBlob} from "@/util/util";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    qrcodeDialog,
    SafeSelect,
    UserDetpDialog,
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
  },

  watch:{
    listDialog(val){
      if(!val){
        this.fileArray = []
      }
    }
  },

  data() {
    return {
      saveLoading: false,
      nowIndex: -1,
      fileList: [],
      listLoading: false,
      defaultWarehouseOptions: [],
      formDetail: {},
      imgUrlList: [],
      list: [],
      cwaddType: "add",
      cwaddDialog: false,
      cwids: "",
      ids: "",
      dmItemIconsList: [],
      cwtableData: [],
      cwtableLoading: false,
      tableLoading: false,
      personShow: false,
      kctableData: [],
      kctableLoading: false,
      cwDialog: false,
      kcDialog: false,
      activeName: "inventory",
      listDialog: false,
      cwForm: {
        warehosueCode: "",
      },
      getcwCode: true,
      form: {
        easyFlag: false,
      },
      cwPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      kcPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      inoutPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      kctableOption: {
        height: 200,
        menu: false,
        selection: false,
        linklabel: "batchCode",
        column: [
          {
            width: 150,
            label: "批次号",
            prop: "batchCode",
            align: "center",
            overHidden: true,
          },
          {
            label: "供应商",
            prop: "vendorName",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "储位",
            prop: "warehouseName",
            align: "center",
            overHidden: true,
          },
          {
            label: "采购日期",
            prop: "recptDate",
            align: "center",
            width: "200",
            overHidden: true,
          },
          {
            label: "有效期",
            prop: "expireDate",
            align: "center",
            width: "150",
            overHidden: true,
          },
          {
            label: "库存量",
            prop: "quantity",
            align: "center",
            overHidden: true,
          },
        ],
      },
      cwtableOption: {
        index: false,
        height: 200,
        menuFixed: "right",
        menuWidth: 140,
        linklabel: "warehosueCode",
        column: [
          {
            label: "储位编号",
            prop: "warehosueCode",
            align: "center",
            overHidden: true,
          },
          {
            label: "储位名称",
            prop: "warehosueName",
            align: "center",
            overHidden: true,
          },
          {
            label: "位置",
            prop: "location",
            align: "center",
            overHidden: true,
          },
        ],
      },
      tableOption: {
        height: 200,
        menuWidth: 150,
        menuFixed: "right",
        linklabel: "recptCode",
        selectable: (row) => {
          return (
            row.recptStatus == "PREPARE" && row.createUser == this.userInfo.id
          );
        },
        column: [
          {
            label: "出入库记录编号",
            prop: "recptCode",
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: "危化品名称",
            prop: "itemName",
            width: 200,
            align: "left",
            overHidden: true,
          },
          {
            label: "出入库类型",
            prop: "operateTypeName",
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: "出入库量",
            prop: "quantity",
            align: "center",
            overHidden: true,
          },
          {
            label: "库存批次",
            prop: "batchCode",
            align: "center",
            overHidden: true,
          },
          {
            label: "出入库时间",
            prop: "recptDate",
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: "申请人",
            prop: "userName",
            align: "center",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "recptStatus",
            align: "center",
            overHidden: true,
            slot: true,
          },
        ],
      },
      headListOptions: [
        {
          label: "储位维护",
          emit: "head-add",
          type: "button",
          btnOptType: "romve",
        },
      ],
      headCwOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          btnOptType: "add",
        },
        {
          label: "删除",
          emit: "head-remove",
          type: "button",
          btnOptType: "remove",
        },
      ],
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          btnOptType: "add",
        },
        {
          label: "删除",
          emit: "head-romve",
          type: "button",
          btnOptType: "romve",
        },
      ],

      headDetailOptions: [
        {
          label: "采购",
          emit: "head-purchase",
          type: "button",
          btnOptType: "romve",
        },
        {
          label: "领用",
          emit: "head-use",
          type: "button",
          btnOptType: "romve",
        },
        {
          label: "归还",
          emit: "head-return",
          type: "button",
          btnOptType: "romve",
        },
        {
          label: "处置",
          emit: "head-handle",
          type: "button",
          btnOptType: "romve",
        },
      ],
      searchColumns: [
        {
          label: "出入库记录编号",
          prop: "recptCode",
          span: 3,
          placeholder: "请输入出入库记录编号",
        },
        {
          label: "危化品名称",
          prop: "itemName",
          span: 3,
          placeholder: "请输入危化品名称",
        },
        {
          label: "出入库类型",
          prop: "operateType",
          span: 3,
          type: "select",
          placeholder: "请选择出入库类型",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=dm_itme_type`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: "申请人",
          prop: "userName",
          type: "input",
          span: 3,
          placeholder: "请输入申请人",
        },
        // {
        //   label: "组织名称",
        //   prop: "organizationName",
        //   span: 3,
        //   placeholder: "请输入组织名称",
        // },
        {
          label: "状态",
          prop: "recptStatus",
          span: 3,
          type: "select",
          placeholder: "请选择状态",
          dicData: [
            { dictValue: "完成", dictKey: "FINISHED" },
            { dictValue: "草稿", dictKey: "PREPARE" },
          ],
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          placeholder: "请选择开始结束时间",
          prop: "expireTimes",
          span: 3,
          type: "daterange",
          format: "yyyy-MM-dd",
        },
      ],
      rules: {
        itemName: [
          { required: true, message: "请填写危化品名称", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请选择负责人1", trigger: "change" },
        ],
        userNameT: [
          { required: true, message: "请选择负责人2", trigger: "change" },
        ],
        issueLimit: [
          { required: true, message: "请输入单次领用上限", trigger: "change" },
        ],
        unitOfMeasure: [
          { required: true, message: "请输入单位", trigger: "blur" },
        ],
        defaultWarehouseId: [
          { required: true, message: "请选择储存位置", trigger: "change" },
        ],
      },
      cwrules: {
        warehosueCode: [
          { required: true, message: "请输入储位编号", trigger: "blur" },
        ],
        warehosueName: [
          { required: true, message: "请输入储位名称", trigger: "blur" },
        ],
        location: [
          { required: true, message: "请输入储存位置", trigger: "blur" },
        ],
      },
      dicCodeMap: { dm_itme_type: "dmItemTypeList" },
      dmItemTypeList: [],
      dmItemType: "1",
      querys: {},
      itemId: "",
      listAddtype: "add",
      personNum: 1,
      saveLoading: false,
      safeShow: false,
      siList: [],
      fileArray: [],
      detailArray: [],
      noneUploadFile: false,
      imageVisible: false,
      imageUrl: ''
    };
  },
  mounted() {
    this.getList();
    this.getcwAllList();
    this.getDictionaryCode("dm_itme_type");
  },
  methods: {
    removeSiList(index) {
      this.dmItemIconsList.splice(index, 1);
    },
    closeSafe() {
      this.safeShow = false;
    },
    safeClick() {
      this.safeShow = true;
    },
    safeSelectData(list) {
      console.log(list);
      let dmItemIconsList = this.dmItemIconsList.concat(list);
      let uniqueArr = dmItemIconsList.filter((item, index, self) => {
        return (
          index ===
          self.findIndex(
            (obj) => (obj.picUrl || obj.link) === (item.picUrl || item.link)
          )
        );
      });

      if (uniqueArr && uniqueArr.length > 9) {
        this.$message({
          message: "至多选择9个安全标识,已为您截取前9个",
          type: "warning",
        });
        this.dmItemIconsList = [];
        uniqueArr.slice(0, 9).map((item) => {
          this.dmItemIconsList.push({
            name: item.name || item.signName,
            extension: item.extension || "",
            url: item.picUrl || item.link,
            link: item.picUrl || item.link,
          });
        });
        return;
      }
      this.dmItemIconsList = [];
      uniqueArr.map((item) => {
        this.dmItemIconsList.push({
          name: item.name || item.signName,
          extension: item.extension || "",
          url: item.picUrl || item.link,
          link: item.picUrl || item.link,
        });
      });
      // this.dmItemIconsList = uniqueArr;
    },
    listUrls(arr) {
      return arr.map((item) => {
        return item.link;
      });
    },
    handleInput(value) {
      // 确保输入的是整数
      if (String(value).includes(".")) {
        this.form.issueLimit = parseInt(value, 10);
      }
    },
    showFile(item,link) {
      //console.log(item);
      if(link){ // link参数代表是技术说明书字段的附件
        const fileExtension = item.name?.slice(((item.name.lastIndexOf(".") - 1) >>> 0) + 2);
        if(["docx", "doc", "zip", "pdf","rar"].includes(fileExtension)){
          downloadFileBlob(item.link, item.name);
        }else{
          this.imageUrl =  item.link;
          this.imageVisible = true
        }
      }else{
        this.$refs.fileView.showFile(link=='link'?item.link:item.imgUrl)
      }


    },
    uploadExceed(limit, files, fileList, column) {
      // console.log(limit, files, fileList, column)
      this.$message.error("只能上传一个文件，请先删除当前文件");
    },
    // 上传之前的函数
    handleBeforeUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length).toLowerCase();
      let arr = [".zip", ".doc", ".docx", ".pdf",".jpg",".png",".zip",".rar"];
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
        return false;
      }
      if (!arr.includes(lastName)) {
        this.$message.warning("不支持上传此类型文件");
        return false;
      }
    },
    // 上传成功
    uploadSuccess(response, file, fileList) {
      // fieldName 表单中向后端传递的字段名
      this.fileArray = fileList.map((item) => {
        if (item.response) {
          item.response.data.url = item.response.data.link;
          item.response.data.name = item.response.data.originalName
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    // 删除文件
    handleFileRemove(file, fileList) {
      this.fileArray = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    headAdd() {
      this.kcDialog = true;
    },
    // 获取字典
    getDictionaryCode(code) {
      getDictionaryCode(code).then((res) => {
        this[this.dicCodeMap[code]] = res.data.data;
      });
    },
    // 采购
    headPurchase() {
      console.log(this.itemId);
      //  /hazardousChemicals/purchase
      this.$router.push({
        path: `/hazardousChemicals/purchase`,
        query: {
          type: "add",
          itemId: this.itemId,
        },
      });
    },
    // 领用
    headUse() {
      this.$router.push({
        path: `/hazardousChemicals/useAdd`,
        query: {
          type: "add",
          itemId: this.itemId,
        },
      });
    },
    // 归还
    headReturn() {
      this.$router.push({
        path: `/hazardousChemicals/return`,
        query: {
          type: "add",
          itemId: this.itemId,
        },
      });
    },
    // 处置
    headHandle() {
      this.$router.push({
        path: `/hazardousChemicals/disposition`,
        query: {
          type: "add",
          itemId: this.itemId,
        },
      });
    },
    kcDialogRoute() {
      switch (this.dmItemType) {
        case "1":
          this.$router.push({
            path: `/hazardousChemicals/purchase`,
            query: {
              type: "add",
            },
          });
          break;
        case "2":
          this.$router.push({
            path: `/hazardousChemicals/useAdd`,
            query: {
              type: "add",
            },
          });
          break;
        case "3":
          this.$router.push({
            path: `/hazardousChemicals/return`,
            query: {
              type: "add",
            },
          });
          break;
        case "4":
          this.$router.push({
            path: `/hazardousChemicals/disposition`,
            query: {
              type: "add",
            },
          });
          break;
      }
    },
    selectData(row) {
      this.personShow = false;
      if (this.personNum == 1) {
        this.form.userId = row.id;
        // this.form.userName = row.realName || row.nickName;
        this.$set(this.form, "userName", row.realName || row.nickName);
      } else {
        this.form.userIdT = row.id;
        // this.form.userNameT = row.realName || row.nickName;
        this.$set(this.form, "userNameT", row.realName || row.nickName);
      }
    },
    handleSwitch() {
      if (this.getcwCode) {
        this.getCwCode();
      } else {
        this.cwForm.warehosueCode = "";
      }
    },
    //获取储位编码
    getCwCode() {
      getcwCode().then((res) => {
        console.log(res);
        this.cwForm.warehosueCode = res.data.data;
        this.$set(this.cwForm, "warehosueCode", this.cwForm.warehosueCode);
      });
    },
    cwrowRemove(row) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          removecwList({ ids: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.cwonLoad(this.cwPage);
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    cwrowView(row) {
      this.cwaddType = "view";
      this.cwForm = row;
      this.cwaddDialog = true;
    },
    cwrowEdit(row) {
      this.cwaddType = "edit";
      this.cwForm = row;
      this.cwaddDialog = true;
    },

    rowRemove(row) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          removecgList({ ids: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getInOutList(this.inoutPage);
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    rowView(row) {
      let url = "";
      switch (row.operateType) {
        case "1":
          url = "/hazardousChemicals/purchase";
          break;
        case "2":
          url = "/hazardousChemicals/useAdd";
          break;
        case "3":
          url = "/hazardousChemicals/return";
          break;
        case "4":
          url = "/hazardousChemicals/disposition";
          break;
      }
      this.$router.push({
        path: url,
        query: {
          type: "view",
          id: row.id,
        },
      });
    },
    rowEdit(row) {
      let url = "";
      switch (row.operateType) {
        case "1":
          url = "/hazardousChemicals/purchase";
          break;
        case "2":
          url = "/hazardousChemicals/useAdd";
          break;
        case "3":
          url = "/hazardousChemicals/return";
          break;
        case "4":
          url = "/hazardousChemicals/disposition";
          break;
      }
      this.$router.push({
        path: url,
        query: {
          type: "edit",
          id: row.id,
          itemId: row.itemId,
        },
      });
    },
    kcrowView(row) {
      this.$router.push({
        path: "/hazardousChemicals/purchase",
        query: {
          type: "view",
          itemId: row.itemId,
          batchCode: row.batchCode,
        },
      });
    },
    cwheadAdd() {
      this.cwForm = {
        warehosueCode: "",
      };
      this.cwaddType = "add";
      this.cwaddDialog = true;
      this.handleSwitch();
    },
    cwheadRmove() {
      if (this.cwids) {
        this.$confirm("确认删除当前选中数据吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then((res) => {
            removecwList({ ids: this.cwids }).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.cwonLoad(this.cwPage);
            });
          })
          .catch((error) => {
            this.$message({
              message: "取消删除",
              type: "success",
            });
          });
      }
    },
    headRmove() {
      if (this.ids) {
        this.$confirm("确认删除当前选中数据吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then((res) => {
            removecgList({ ids: this.ids }).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getInOutList(this.inoutPage);
            });
          })
          .catch((error) => {
            this.$message({
              message: "取消删除",
              type: "success",
            });
          });
      }
    },
    cwselectionChange(arr) {
      this.cwids = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },
    selectionChange(arr) {
      this.ids = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },
    addcwList() {
      let parmas = {
        ...this.cwForm,
        organizationId: this.userInfo.dept_id,
      };
      this.$refs.cwForm.validate((valid) => {
        if (valid) {
          addcwList(parmas)
            .then((res) => {
              console.log(res);
              this.cwaddDialog = false;
              this.cwPage = {
                pageSize: 10,
                currentPage: 1,
                total: 0,
              };
              this.cwonLoad(this.cwPage);
            })
            .catch((error) => {
              this.cwaddDialog = false;
            });
        }
      });
    },
    getcwAllList() {
      let parmas = {
        organizationId: this.userInfo.dept_id,
      };
      getcwAllList(parmas)
        .then((res) => {
          this.defaultWarehouseOptions = res.data.data;
        })
        .catch((error) => {});
    },
    cwonLoad(page) {
      this.cwtableLoading = true;
      this.cwpage = page;
      let parmas = {
        organizationId: this.userInfo.dept_id,
        current: this.cwpage.currentPage,
        size: this.cwpage.pageSize,
      };
      getcwList(parmas)
        .then((res) => {
          this.cwtableData = res.data.data.records;
          this.cwPage.total = res.data.data.total;
          this.cwPage.currentPage = res.data.data.current;
          this.cwtableLoading = false;
        })
        .catch((error) => {
          this.cwtableLoading = false;
        });
    },
    handleUserModeOpen(num) {
      this.personNum = num;
      this.personShow = true;
    },
    clickcwDialog() {
      this.cwDialog = true;
      this.cwonLoad(this.cwPage);
    },
    handleExceedImgUrl(files, fileList) {
      //files指的是你刚上传的文件，fileList指的是你上传之前的文件数组
      this.$set(fileList[0], "raw", files[0]); //raw就是指文件
      this.$set(fileList[0], "name", files[0].name); //name就是指文件名
      this.$refs.uploadRef.clearFiles(); //删除所有上传的文件
      this.$refs.uploadRef.handleStart(files[0]); //handleStart()指的是手动选择文件，Element Plus 的el-upload有说明
      this.$refs.uploadRef.submit(); //手动上传
    },
    handleimgUrlSuccess(res, file, fileList) {
      // this.form.imgUrl = URL.createObjectURL(file.raw);
      this.form.imgUrl = file.response.data.link;
      console.log(this.form.imgUrl, "this.form.imgUrl");
      // this.treeData.push({
      //   extension: file.raw.type,
      //   id: file.response.data.attachId,
      //   link: file.response.data.link,
      //   name: file.response.data.originalName,
      // });
    },
    handleIconsSuccess(res, file, fileList) {
      let list = new Array();
      this.fileList = [];
      this.dmItemIconsList = [];
      console.log(fileList, "handleIconsSuccess");
      fileList.map((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            extension: i.raw.type,
            id: i.response.data.attachId,
            url: i.response.data.link,
            link: i.response.data.link,
          });
          this.dmItemIconsList.push({
            name: i.name,
            extension: i.raw.type,
            id: i.response.data.attachId,
            url: i.response.data.link,
            link: i.response.data.link,
          });
        } else {
          list.push({
            name: i.name,
            url: i.url,
            link: i.link,
          });
          this.dmItemIconsList.push({
            name: i.name,
            url: i.url,
            link: i.link,
          });
        }
      });
      this.fileList = list;
    },
    handleIconsRemove(file, fileList) {
      let list = new Array();
      this.fileList = [];
      this.dmItemIconsList = [];
      console.log(fileList, "handleIconsSuccess");
      fileList.map((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            extension: i.raw.type,
            id: i.response.data.attachId,
            url: i.response.data.link,
            link: i.response.data.link,
          });
          this.dmItemIconsList.push({
            name: i.name,
            extension: i.raw.type,
            id: i.response.data.attachId,
            url: i.response.data.link,
            link: i.response.data.link,
          });
        } else {
          list.push({
            name: i.name,
            url: i.url,
            link: i.link,
          });
          this.dmItemIconsList.push({
            name: i.name,
            url: i.url,
            link: i.link,
          });
        }
      });
      this.fileList = list;
    },
    saveList() {
      let parmas = {
        organizationId: this.userInfo.dept_id,
        ...this.form,
        dmItemIconsList: this.dmItemIconsList,
      };
      parmas.documentUrl = JSON.stringify(this.fileArray)
      console.log(parmas, "saveList");
      this.$refs.form.validate((valid) => {
        console.log(valid);
        this.saveLoading = true;
        if (valid) {
          addList(parmas)
            .then((res) => {
              this.getList();
              this.saveLoading = false;
              this.listDialog = false;
              this.fileArray = [];
              console.log(res);
            })
            .catch((error) => {
              this.saveLoading = false;
            });
        } else {
          this.saveLoading = false;
        }
      });
      console.log(parmas, "this.saveList");
    },
    // 防抖函数
    debounce(func, wait) {
      let timeout;
      return function () {
        const context = this,
          args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    getList() {
      this.listLoading = true;
      // this.page = page;
      let parmas = {
        organizationId: this.userInfo.dept_id,
        // size: this.page.size,
        // current: this.page.currentPage,
      };
      this.nowIndex = -1;
      getList(parmas).then((res) => {
        this.list = res.data.data || [];
        if (this.list.length) {
          this.handleItemClick(0, this.list[0]);
          this.nowIndex = 0;
        } else {
          this.nowIndex = -1;
        }
        this.listLoading = false;
        console.log(res);
      });
    },
    getListDetail(row) {
      this.imgUrlList = [];
      this.fileList = [];
      listDetail({ id: row.id }).then((res) => {
        this.form = JSON.parse(JSON.stringify(res.data.data));
        this.fileList = this.form.dmItemIconsList;
        this.dmItemIconsList = this.form.dmItemIconsList;
        this.fileArray = res.data.data.documentUrl?JSON.parse(res.data.data.documentUrl):[]
        this.detailArray = res.data.data.documentUrl?JSON.parse(res.data.data.documentUrl):[]
        if (this.form.imgUrl) {
          this.imgUrlList = [{ url: this.form.imgUrl }];
        }
        this.formDetail = JSON.parse(JSON.stringify(res.data.data));
        this.formDetail.quantity = row.quantity;
        console.log(this.formDetail, "getListDetail");
      });
    },
    editItem(item) {
      console.log(item);
      this.listAddtype = "edit";
      this.listDialog = true;
      this.getListDetail(item);
    },
    removeList(item) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeList({ ids: item.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getList();
            }
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    addList() {
      this.listAddtype = "add";
      this.fileList = [];
      this.dmItemIconsList = [];
      this.form = {
        easyFlag: false,
      };
      this.imgUrlList = [];
      this.imgUrl = "";
      this.getcwAllList();
      this.fileArray = [];
      this.listDialog = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    getInOutList(page) {
      this.inoutPage = page;
      let parmas = {
        current: this.inoutPage.currentPage,
        size: this.inoutPage.pageSize,
        organizationId: this.userInfo.dept_id,
        ...this.querys,
      };
      this.tableLoading = true;
      getcgList(parmas)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.inoutPage.total = res.data.data.total;
          this.inoutPage.currentPage = res.data.data.current;
          this.tableLoading = false;
          this.$nextTick(() => {
            this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
          });
          this.$refs.gridLayOut.selectionClear();
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    cwrowQRcode(row) {
      this.$refs.qrcodeDialog.init(
        row.baseCodeObject.codeUrl,
        row.warehosueName,
        row.warehosueCode
      );
    },
    handleClick() {
      console.log(this.activeName, 888);
      if (this.activeName == "inventory") {
        this.nowIndex = -1;
        this.getList();
      } else {
        console.log(this.activeName, 9999999999);
        this.inoutPage.currentPage = 1;
        this.getInOutList(this.inoutPage);
      }
    },
    getkcList(page, id) {
      this.kcPage = page;
      this.kctableLoading = true;
      let parmas = {
        itemId: this.list[this.nowIndex].id || id,
        organizationId: this.userInfo.dept_id,
        size: this.kcPage.pageSize,
        current: this.kcPage.currentPage,
      };
      getkcList(parmas)
        .then((res) => {
          this.kctableData = res.data.data.records;
          this.kcPage.total = res.data.data.total;
          this.kctableLoading = false;
        })
        .catch((error) => {
          this.kctableLoading = false;
        });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handlePreview(file) {
      this.showFile({ imgUrl: file.response.data.link });
    },
    handleItemClick(index, row) {
      if (this.nowIndex == index) {
        return;
      }
      this.nowIndex = index;
      this.list.forEach((item) => {
        item.selected = false;
      });
      this.itemId = row.id;
      this.$set(this.list[index], "selected", true);

      this.getListDetail(row);
      this.getkcList(this.kcPage, row.id);
      console.log(index, this.list);
    },
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG = fileType === ".jpg" || fileType == ".png";
      if (!isJPG) {
        this.$message.warning("上传图片只能是 .jpg  .png格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    getImgList(list) {
      return list.map((item) => {
        return item;
      });
    },
    gridInoutHeadSearch(query) {
      console.log("gridInoutHeadSearch");
      this.inoutPage.currentPage = 1;
      if (query.expireTimes) {
        query.recptDateStart = query.expireTimes[0] + " 00:00:00";
        query.recptDateEnd = query.expireTimes[1] + " 23:59:59";
      }
      delete query.expireTimes;
      this.querys = query;
      this.getInOutList(this.inoutPage);
    },
    gridHeadEmpty() {
      console.log(1111);
      this.inoutPage.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.querys = {};
      this.getInOutList(this.inoutPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input-number.el-input-number--small {
  width: 100%;
}
::v-deep .el-tabs__content {
  background-color: transparent;
}
.main-content {
  // background-color: #fff;
  widows: 100%;
  height: 100%;
}
.el-main {
  height: 100%;
  overflow: hidden;
}

::v-deep.inOut-content {
  height: calc(100% - 50px);
  #gridLayout {
    height: calc(100% - 112px);
    .avue-crud {
      height: 100%;
      .el-card {
        height: calc(100% - 46px);
      }
      .avue-crud__pagination {
        height: 46px !important;
      }
      .el-card__body {
        height: 100%;
        .el-form {
          height: 100%;
          .el-table {
            height: 100% !important;
          }
        }
      }
    }
  }
}
::v-deep .inventory-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 60px);
  .left {
    background-color: #fff;
    flex-shrink: 1;
    width: 60%;
    margin-right: 10px;
    height: 100%;
    .el-card__body {
      padding: 10px !important;
      width: 100%;
      height: 100%;
    }
    .list {
      padding: 10px 5px 10px 10px;
      height: calc(100% - 70px);
      overflow-y: scroll;
      overflow-x: hidden;
      .list-card {
        cursor: pointer;
        border-radius: 6px;
        margin-bottom: 10px;
        border: 1px solid transparent !important;
        .name {
          text-align: center;
          white-space: nowrap; /* 确保文本在一行内显示 */
          overflow: hidden; /* 隐藏超出容器的文本 */
          text-overflow: ellipsis; /* 使用省略符号表示被截断的文本 */
        }
        .el-divider {
          width: calc(100% + 20px);
          transform: translateX(-10px);
        }
        .bottom {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    .up-card {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 294px;
      .upload {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .right {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .detail {
      background-color: #fff;
      height: 55%;
      margin-bottom: 10px;
      .el-form {
        overflow-y: scroll;
        height: calc(100% - 76px);
        padding: 10px;
      }
    }
    .list {
      background-color: #fff;
      height: calc(45% - 10px);
      #gridLayout {
        height: calc(100% - 46px);
        .avue-crud {
          height: 100%;
          .el-card {
            height: calc(100% - 46px);
          }
          .avue-crud__pagination {
            height: 46px !important;
          }
          .el-card__body {
            height: 100%;
            .el-form {
              height: 100%;
              .el-table {
                height: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
::v-deep.cwDialog {
  #gridLayout {
    height: calc(100% - 60px);
    .avue-crud {
      height: 100%;
      .el-card {
        height: 100%;
        .el-card__body {
          height: 100%;
          .el-form {
            height: 100%;
            .el-table {
              height: 100% !important;
            }
          }
        }
      }
      .avue-crud__pagination {
        height: auto;
      }
    }
  }
}
.inventory-content .left .list .list-card-selected {
  border: 1px solid #409eff !important;
}
.image-slot {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disUoloadSty {
  ::v-deep .el-upload--picture-card{
    display: none;
  }
  ::v-deep .el-upload__tip{
    display: none;
  }
  ::v-deep .el-upload--text{
    display: none;
  }
}
</style>
