<!--
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-20 15:11:04
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
    <avue-form :option="options" ref="addForm" v-model="form" @submit="treeNodeSave">
      <template slot="birthday">
        <span>
          {{form.idCoding&&form.idCoding!=''?getAge(form.idCoding):''}}
        </span>
      </template>
      <template slot="idFiles">
        <el-upload :class="{ uoloadSty: showImg, disUoloadSty: noneUploadImg }" action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform" :headers="headers" :on-preview="handlePreview" list-type="picture-card" :file-list="fileList" file="file" :on-remove="handleDeleteImgRemove" :on-success="handleIdFilesSuccess" :on-change="uploadImgChange" :before-upload="beforeAvatarUpload"
          accept=".jpeg,.jpg,.gif,.png" :limit="limitCountImg">
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </template>
      <template slot="certificateFiles">
        <el-upload :class="{ uoloadSty: showImg, disUoloadSty: noneUploadImgCer }" action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform" :headers="headers" :on-preview="handlePreview" list-type="picture-card" :file-list="certificateFilesFileList" file="file" :on-remove="handleDeleteImgRemoveCer" :on-success="handleIdFilesSuccessCer" :on-change="uploadImgChangeCer"
          :before-upload="beforeAvatarUpload" accept=".jpeg,.jpg,.gif,.png" :limit="limitCountImg">
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </template>
    </avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button v-if="pageType!='view'" size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </el-dialog>

</template>

<script>
  import {
    prjstructureSubmit
  } from "@/api/safe/itemList";
  import {
    getDetail,
    add
  } from "@/api/safe/thirduser";
  import {
    getAgeFromIdCard,
    getBirthDate
  } from "@/util/util";
  import {
    PATTERN_ID_CARD
  } from "@/util/validate"
  import {
    getToken
  } from "@/util/auth";

  export default {
    data() {
      return {
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        readOnly: false,
        form: {},
        title: '',
        pageType: '',
        fileUrl: "",
        showImg: true, //默认展示
        noneUploadImg: false, //
        noneUploadImgCer: false, //
        fileList: [],
        certificateFilesFileList: [],
        limitCountImg: 2,
        dialogImageUrl: "",
        dialogVisible: false,
      }
    },
    computed: {
      headers: function () {
        return {
          "Sinoma-Auth": getToken()
        };
      },
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "姓名",
              prop: "userName",
              disabled: this.pageType == 'view',
              rules: [{
                required: true,
                message: "请输入姓名",
                trigger: "blur"
              },{ max: 10, message: '姓名不能超过10个字符', trigger: 'blur' }]
            },
            {
              label: "身份证号",
              prop: "idCoding",
              disabled: this.pageType == 'view',
              rules: [{
                required: true,
                validator: this.validateIdCard,
                trigger: 'blur'
              }]
            },
            {
              label: "性别",
              prop: "sex",
              type: "radio",
              disabled: this.pageType == 'view',
              dicData: [{
                  label: '男',
                  value: 1
                },
                {
                  label: '女',
                  value: 2
                },
                {
                  label: '未知',
                  value: 3
                },
              ],
              rules: [{
                required: true,
                message: "请选择性别",
                trigger: "blur"
              }]

            },
            {
              label: "年龄",
              prop: "birthday",
              rules: [{
                required: true,
                message: "请输入年龄",
                trigger: "blur"
              }]
            },
            {
              label: "籍贯",
              prop: "domicile",
              disabled: this.pageType == 'view',
              rules: [{
                required: false,
                message: "请输入籍贯",
                trigger: "blur"
              },{ max: 50, message: '籍贯不能超过50个字符', trigger: 'blur' }]
            },
            {
              label: "工种",
              prop: "post",
              disabled: this.pageType == 'view',
              rules: [{
                required: true,
                message: "请输入工种",
                trigger: "blur"
              },{ max: 20, message: '工种不能超过20个字符', trigger: 'blur' }]
            },
            {
              label: "电话号码",
              prop: "phone",
              disabled: this.pageType == 'view',
              span: 12,
              placeholder: "请输入电话号码",
              rules: [
                {
                  required: true,
                  message: "请输入电话号码",
                  trigger: "blur",
                },
                { pattern: /^1[3456789]\d{9}$/, message: "电话号码格式错误" },
              ],
            },
            {
              label: '是否启用',
              prop: 'isUsed',
              disabled: this.pageType == 'view',
              type: 'switch',
              value: true
            },
            {
              label: '身份证',
              prop: 'idFiles',
              span: 24,

            },
            {
              label: '资质、健康证明',
              prop: 'certificateFiles',
              span: 24,
            },
          ],
        }
      }
    },
    methods: {
      init(type, data) {
        this.form = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.noneUploadImgCer = false
        this.noneUploadImg = false
        this.pageType = type
        if (type == 'edit') {
          this.title = '编辑相关方人员'
          this.form = {
            ...data
          }
          if (this.form.idFiles != '') {
            this.form.idFiles = JSON.parse(this.form.idFiles)
            this.fileList = this.form.idFiles.map((item) => {
              return {
                ...item,
                url:item.link,
                fileUrl: item.link
              }
            })

          }
          if (this.form.certificateFiles != '') {
            this.form.certificateFiles = JSON.parse(this.form.certificateFiles)
            this.certificateFilesFileList = this.form.certificateFiles.map((item) => {
              return {
                ...item,
                url:item.link,
                fileUrl: item.link
              }
            })

          }

          this.noneUploadImg = this.fileList.length >= this.limitCountImg;
          this.noneUploadImgCer = this.certificateFilesFileList.length >= this.limitCountImg;
          this.form.isUsed = this.form.isUsed === 1 ? false : true
        } else if (type == 'view') {
          this.title = '查看相关方人员'
          this.showImg = false
          this.noneUploadImgCer = true
          this.noneUploadImg = true
          this.form = {
            ...data
          }
          if (this.form.idFiles != '') {
            this.form.idFiles = JSON.parse(this.form.idFiles)
            this.fileList = this.form.idFiles.map((item) => {
              return {
                ...item,
                url:item.link,
                fileUrl: item.link
              }
            })

          }
          if (this.form.certificateFiles != '') {
            this.form.certificateFiles = JSON.parse(this.form.certificateFiles)
            this.certificateFilesFileList = this.form.certificateFiles.map((item) => {
              return {
                ...item,
                url:item.link,
                fileUrl: item.link
              }
            })

          }
          this.form.isUsed = this.form.isUsed === 1 ? false : true
        } else {
          this.title = '新增相关方人员'
          this.form.idFiles = []
          this.form.certificateFiles = []
        }
      },
      // 身份证校验
      validateIdCard(rule, value, callback) {
        if (value == '') {
          callback(new Error('请填写身份证号码'));
        } else {
          let valid = PATTERN_ID_CARD.test(value)
          if (valid) {
            callback();
          } else {
            callback(new Error('请填写正确的身份证号码'));
          }
        }

      },
      getAge(data) {
        this.form.birthday = getBirthDate(data)
        return getAgeFromIdCard(data)
      },

      // 新增左侧树节点
      handleTreeNodeSave() {
        if (this.pageType != 'view') {
          this.$refs.addForm.submit();
        }

      },
      //身份证
      handleIdFilesSuccess(res, file, fileList) {
        this.form.idFiles = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item
          }
        });
      },
      // 判断数量，是否显示上传icon
      uploadImgChange(file, fileList) {
        this.noneUploadImg = fileList.length >= this.limitCountImg;
      },
      // 删除图片，判断数量，是否显示icon
      handleDeleteImgRemove(file, fileList) {
        this.form.idFiles = fileList.map((item) => {
          if (item.response) {
            return item.response.data
          } else {
            return item
          }
        });
        this.noneUploadImg = fileList.length >= this.limitCountImg;
      },
      handlePreview(file) {
        //判断只有图片可以点击打开放大图
        const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (
          fileName == "jpg" ||
          fileName == "png" ||
          fileName == "jpeg" ||
          fileName == "bmp"
        ) {
          this.dialogVisible = true;
          if (file.response) {
            //判断新增和编辑时候 地址不一样
            this.dialogImageUrl = file.response.data.objectUrl ? file.response.data.objectUrl : file.response.data.link;
          } else {
            this.dialogImageUrl = file.link;
          }
        }
      },
      //健康证明
      handleIdFilesSuccessCer(res, file, fileList) {
        this.form.certificateFiles = fileList.map((item) => {
          if (item.response) {
            return item.response.data
          } else {
            return item
          }
        });
      },
      // 判断数量，是否显示上传icon
      uploadImgChangeCer(file, fileList) {
        this.noneUploadImgCer = fileList.length >= this.limitCountImg;
      },
      // 删除图片，判断数量，是否显示icon
      handleDeleteImgRemoveCer(file, fileList) {
        this.form.certificateFiles = fileList.map((item) => {
          if (item.response) {
            return item.response.data
          } else {
            return item
          }
        });
        this.noneUploadImgCer = fileList.length >= this.limitCountImg;
      },
      handleAvatarSuccess(res, file, fileList) {
        this.$message({
          type: "success",
          message: "上传成功！",
        });
      },
      beforeUpload(file) {
        const fileName = file.name;
        const fileType = fileName.substring(fileName.lastIndexOf('.'));
        if (fileType !== '.jpg' && fileType !== '.jpeg' && fileType !== '.png' && fileType !== '.pdf' && fileType !== '.excel' && fileType !== '.word' && fileType !== '.doc' && fileType !== '.docx' && fileType !== '.xls' && fileType !== '.xlsx' && fileType !== '.txt') {
          this.$message({
            type: "warning",
            message: "请上传jpg、jpeg、png、pdf、excel、word、doc、docx、xls、xlsx、txt类型文件！",
          });
          return false;
        }
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
          this.$message.warning('上传文件大小不能超过 20MB!');
        }
        return isLt20M;
      },
      beforeAvatarUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        const isJPG = (file.type === 'image/jpeg' || file.type === 'image/jpg' ||
          file.type === 'image/gif' || file.type === 'image/png');

        if (!isJPG) {
          this.$message.warning('上传图片只能是 自定义文件 格式!');
        }
        if (!isLt20M) {
          this.$message.warning('上传文件大小不能超过 20MB!');
        }
        return isJPG && isLt20M;
      },
      treeNodeSave(data) {
        data.isUsed = data.isUsed == true ? 0 : 1
        this.$refs.addForm.validate((valid, done) => {
          if (valid) {
            let age = this.getAge(this.form.idCoding)
            if (age <= 0) {
              this.$message({
                type: "warning",
                message: "年龄不能小于0",
              });
              done()
              return;
            }
            if (this.pageType != 'edit') {
              data.id = ''
            }
            let queryData = {
              ...data
            }
            if (queryData.idFiles instanceof Array) {
              queryData.idFiles = JSON.stringify(queryData.idFiles)
            }
            if (queryData.certificateFiles instanceof Array) {
              queryData.certificateFiles = JSON.stringify(queryData.certificateFiles)
            }
            this.dialogLoading = true
            this.$loading();
            add(queryData).then(res => {
              const {
                msg,
                data
              } = res.data;
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.fileList = [],
              this.certificateFilesFileList = [],
              this.showDialog = false;
              this.dialogLoading = false
              this.$loading().close();
            }).catch(err => {
              done()
              this.$loading().close();
            }).finally(() => {
              done()
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {}
        this.fileList = [],
        this.certificateFilesFileList = [],
        this.$refs.addForm.resetFields();
        this.showDialog = false
      },
    }
  }

</script>
