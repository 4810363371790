var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            permission: _vm.permissionList,
            page: _vm.page,
            "before-open": _vm.beforeOpen,
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "row-del": _vm.rowDel,
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _vm.permission.code_edit
                    ? _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-document-copy",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleCopy(scope.row)
                            },
                          },
                        },
                        [_vm._v("复制\n      ")]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _vm.permission.code_delete
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                        plain: "",
                      },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("删 除\n      ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    plain: "",
                    icon: "el-icon-refresh",
                  },
                  on: { click: _vm.handleBuild },
                },
                [_vm._v("代码生成\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }