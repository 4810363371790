var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "inspection_records-index" },
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-title": "检查记录",
              "head-btn-options": _vm.headBtnOptions,
            },
            on: { "head-add": _vm.headAdd },
          }),
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: { "search-columns": _vm.searchColumns },
            on: {
              "grid-head-search": _vm.gridHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
          _c("grid-layout", {
            ref: "inspectionGridLayOut",
            attrs: {
              "table-options": _vm.tableOptions,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.page.total,
              page: _vm.page,
              editType: _vm.inline,
            },
            on: {
              "page-current-change": _vm.handleCurrentChange,
              "page-size-change": _vm.handleSizeChange,
              "page-refresh-change": _vm.onLoad,
            },
            scopedSlots: _vm._u([
              {
                key: "taskCode",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.rowView(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.taskCode))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }