<template>
    <el-dialog
      title="关联整改记录"
      :visible.sync="dialogShow"
      width="1200px"
      class="inspection-dialog"
      v-if="dialogShow"
      modal-append-to-body
      append-to-body
    >
        <div class="inspection-dialog-main">
            <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="pageCurrentChange"
            @grid-head-empty="resetData"
            ></grid-head-layout>
            <grid-layout
                ref="gridLayout"
                :tableOptions="tableOptions"
                :tableData="tableData"
                :tableLoading="tableLoading"
                :data-total="page.total"
                :page="page"
                @page-size-change="pageCurrentChange"
                @page-current-change="pageCurrentChange"
                @page-refresh-change="resetData"
            >
            <template #hdLevel="{ row }">
              <el-tag
                v-if="row.hdLevel"
                effect="dark"
              >{{ row.$hdLevel }}
              </el-tag>
            </template>
            <template #hdStatus="{ row }">
              <el-tag
                effect="dark"
                class="hdStatus"
                v-if="row.hdStatus && !row.outOfTime"
              >{{ row.$hdStatus }}
              </el-tag
              >
              <el-tag
                effect="dark"
                class="hdStatus"
                v-if="row.outOfTime"
                :style="{ backgroundColor: '#bd3124', borderColor: '#bd3124' }"
              >超期未整改
              </el-tag
              >
            </template>
            <template #customBtn="{ row }">
                <el-button type="text" size="small" @click="toRelevance(row)">关联</el-button>
            </template>
            </grid-layout>
        </div>
    </el-dialog>
</template>
<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { getList,bindRecord } from '@/api/safetyAndEnvironmentalProtection/oneJob'
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {mapGetters} from "vuex";
export default {
    components: {
        GridLayout
    },
    computed:{
        ...mapGetters(["userInfo"]),
    },
    props:{
        checkedProject:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            dialogShow:false,
            activeName:'first',
            timeRange:[],
            dateType:1,
            resObj:{},
            tableData:[],
            page: {
                pageSize: PAGE_CONSTANT.TWENTY,
                currentPage: 1,
                total: 0
            },
            tableLoading:false,
            selectRow:'',
            tableOptions: {
                selection: false,
                indexLabel: "序号",
                index: true,
                column: [{
                    label: "状态",
                    prop: "hdStatus",
                    dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
                    props: {
                        label: 'dictValue',
                        value: 'dictKey',
                    },
                    overHidden: true,
                    align: 'center',
                    slot: true,
                },{
                    label: '隐患描述',
                    prop: "hdDesc",
                    align: 'center',
                    overHidden: true,
                },{
                    label: "隐患级别",
                    prop: "hdLevel",
                    type: 'select',
                    dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
                    props: {
                    label: 'dictValue',
                    value: 'dictKey',
                    },
                    overHidden: true,
                    align: 'center',
                    slot: true,
                },{
                    label: "报告人",
                    prop: "reportUserName",
                    overHidden: true,
                    align: 'center',
                },]
            },
            searchColumns:[{
                label: '状态',
                prop: "hdStatus",
                span: 3,
                type: 'select',
                placeholder: '请选择状态',
                dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
                props: {
                    label: 'dictValue',
                    value: 'dictKey',}
            }]
        }
    },
    methods:{
        init(row){
            this.dialogShow = true
            this.$nextTick(()=>{
                this.selectRow = row
                this.resetData()
            })
        },
        resetData(){
            this.page.pageSize = PAGE_CONSTANT.TWENTY
            this.page.currentPage = 1
            this.getTableData()
        },
        pageCurrentChange(page){
            this.page.pageSize = page.pageSize
            this.page.currentPage = page.currentPage
            this.getTableData()
        },
        getTableData(){
            let {hdStatus} = this.$refs.gridHeadLayout.searchForm
            this.tableLoading = true
            getList({current:this.page.currentPage,size:this.page.pageSize,organizationId:this.checkedProject,hdStatus:hdStatus}).then(res=>{
                let data = res.data.data
                this.tableData = data.records
                this.page.pageSize = data.size
                this.page.currentPage = data.current
                this.page.total = data.total
                this.tableLoading = false
            })
        },
        toRelevance(row){
            bindRecord({hiddenDangerId:row.id,securityInspectionId:this.selectRow.id}).then(res=>{
                console.log(res)
                if(res.data.code == 200){
                    this.$message.success('绑定成功')
                    this.$emit('bindBack')
                    this.dialogShow = false
                }
            })
        }
    },
}
</script>
<style scoped lang="scss">
    .inspection-dialog{
        .inspection-dialog-main{
            padding: 10px;
            height: 500px;
        }
    }
    ::v-deep #gridHeadLayout > div{
        padding: 0!important;
    }
     ::v-deep .avue-crud .el-table{
        height: 320px!important;
        max-height: 320px!important;
     }
</style>
