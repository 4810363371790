<template>
    <div>
        <el-container>
            <el-main>
                <head-layout
                    head-title="职业健康危害分析"
                    :head-btn-options="headBtnOptions"
                    @head-add="handleHeadAdd"
                />
                <grid-layout
                    ref="griLayOut"
                    :grid-row-btn="gridRowBtn"
                    :table-options="tableOPtions"
                    :table-data="tableData"
                    :table-loading="tableLoading"
                    :editType="inline"
                    @page-refresh-change="onLoad"
                    @row-edit="rowEdit"
                    @row-del="rowDel"
                    :spanMethod="spanMethod"
                >
                </grid-layout>
            </el-main>
        </el-container>
        <!-- 操作页 -->
        <el-dialog :visible.sync="dialogVisible" :title="title" :before-close="handleCancel">
            <avue-form :option="dialogOption" ref="cedForm" v-model="form" @submit="handleSubmit">
                <template slot="site">
                    <el-input v-model="form.site" placeholder="请填写/选择区域" maxlength="255">
                        <i slot="suffix" class="el-icon-search input-icon" @click="handleSite"></i>
                    </el-input>
                </template>
            </avue-form>
            <template slot="footer">
                <el-button size="small" @click="handleCancel()" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.celBtn`) }} </el-button>
                <el-button size="small" type="primary" @click="handleSave" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.defBtn`) }} </el-button>
            </template>
        </el-dialog>
        <!-- 区域 -->
            <riskArea ref="riskAreas" @callback="handleCallback"></riskArea>
    </div>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {mapGetters} from 'vuex'
import {ohhazardList, ohhazardSubmit, ohhazardRemove, areaList} from '@/api/healthManage/hazardAnalysis'
import { getPageCode } from "@/api/system/serialNumber";
import riskArea from './compontents/riskArea.vue'
export default {
    name: 'hazardAnalysis', // 危害分析

    components: {
        HeadLayout,
        GridLayout,
        riskArea
    },

    data () {
        return {
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            dialogVisible: false,
            title: '',
            form: {
                site: '',
                hazardName:'',
                remark:''
            },
            dialogLoading: false,
            query: {},
        }
    },

    computed: {
        ...mapGetters(["permission", 'userInfo']),

        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.hazardAnalysis_add, true),
                editBtn: this.vaildData(this.permission.hazardAnalysis_edit, true),
                delBtn: this.vaildData(this.permission.hazardAnalysis_delete, true),
            };
        },

        headBtnOptions () {
            let btnList = []
            if (this.permissionList.addBtn){
                btnList.push({
                    emit: "head-add",
                    type: "button",
                    icon: "",
                    btnOptType: 'add',
                });
            }
            return btnList
        },

        gridRowBtn () {
            let btnList = []
            if (this.permissionList.editBtn){
                btnList.push({
                    label: '编辑',
                    emit: "row-edit",
                    type: "button",
                    icon: "",
                    btnOptType: 'edit',
                });
            }
            if (this.permissionList.delBtn){
                btnList.push({
                    label: '删除',
                    emit: "row-del",
                    type: "button",
                    icon: "",
                    btnOptType: 'del',
                });
            }
            return btnList
        },
        tableOPtions () {
            return {
                selection: false,
                column: [
                    {
                        label: "区域/场所",
                        prop: "site",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "危害因素",
                        prop: "hazardName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "防护措施",
                        prop: "remark",
                        align: "center",
                        overHidden: true,
                    },
                ]
            }
        },

        dialogOption () {
            return {
                size: 'small',
                submitBtn: false,
                emptyBtn: false,
                labelWidth: 120,
                column: [
                    {
                        label: "区域/场所",
                        prop: "site",
                        formslot: true,
                        rules: [{
                            required: true,
                            message: '请选择',
                            trigger: ['change','bulr']
                        }]
                    },
                    {
                        label: "危害因素",
                        prop: "hazardName",
                        rules: [{
                            required: true,
                            message: '请填写',
                            trigger: ['change']
                        }],
                        maxlength: 200,
                    },
                    {
                        label: "防护措施",
                        prop: "remark",
                        type: 'textarea',
                        span: 24,
                        maxlength: 255,
                        showWordLimit: true,
                        rules: [{
                            required: true,
                            message: '请填写',
                            trigger: ['change']
                        }]
                    }
                ]
            }
        },
    },

    created () {
        this.onLoad(this.page)
    },

    methods: {
        handleHeadAdd () {
            // 新增
            this.dialogVisible = true
            this.title = '职业危害新增'
        },

        onLoad(page, params = {}) {
            this.tableLoading = true;
            this.page = page;
            ohhazardList(Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                // this.$refs.gridLayOut.page.total = data.total;
                // this.tableData = data
                this.getNewData(data)
                this.tableLoading = false;
                // this.$refs.gridLayOut.selectionClear()
            });
        },
        getNewData (data) {
            let arr = []
            // [{site: '123',list}]
            data.map(val => {
                let i = 0
                if(arr.length==0){
                    arr.push({site:val.site,list: [val],len: 1})
                } else {
                    arr.map((item, index) => {
                        if(val.site == item.site){
                            item.len ++
                            item.list.push(val)
                        } else {
                            i ++;
                            if(i==arr.length){
                                arr.push({site:val.site,list: [val],len: 1})
                            }
                        }
                    })
                }
            })
            let table = []
            arr.forEach((val)=>{
                table = table.concat(val.list.map((v,index) => {
                    v.len = val.len
                    v.index = index
                    return v
                }))
            })
            this.tableData = table
        },
        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },

        rowEdit (row) {
            for(let key in this.form) {
                if(key in row){
                    this.form[key] = row[key]
                } else {
                    this.form[key] = ''
                }
            }
            this.form.id = row.id
            this.dialogVisible = true
        },
        rowDel (row) {
            this.$confirm("确认删除当前数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    ohhazardRemove(row.id).then((res) => {
                    if (res.data.code == 200) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.onLoad();
                    this.getChartPie();
                    }
                });
                })
                .catch((error) => {
                this.$message({
                    message: "取消删除",
                    type: "success",
                });
            });
        },
        spanMethod ({row, column, rowIndex, columnIndex}) {
            // 合并单元格
            if(columnIndex == 0){
                if(row.index==0){
                    return {
                       rowspan: row.len,
                       colspan: 1
                    }
                } else{
                    return{
                        rowspan: 0,
                        colspan: 1
                    }
                }
            }
        },

        handleCancel () {
            this.$refs.cedForm.resetForm()
            delete this.form.id
            this.dialogVisible = false
        },
        handleSubmit (data,done) {
            this.$refs.cedForm.validate(valid=>{
                if(valid) {
                    this.dialogLoading = true
                    this.form.orgId = this.userInfo.dept_id
                    this.form.orgCode = this.userInfo.deptCode ? this.userInfo.deptCode : ''
                    getPageCode({ ruleCode: "OH_HAZARD_CODE" }).then((res) => {
                        this.form.hazardCode = res.data.data || ""
                        ohhazardSubmit(this.form).then(() => {
                            this.onLoad()
                            this.$message.success('操作成功')
                            this.dialogLoading = false
                            this.handleCancel()
                            done()
                        }).catch(err => {
                            done()
                        }).finally(() => {
                            this.dialogLoading = false
                        })
                    });
                }
            })
        },
        handleSave () {
            this.$refs.cedForm.submit();
        },

        handleSite () {
            this.$refs.riskAreas.init()
        },

        handleCallback (data) {
            this.form.site = data.name
        }
    }
}
</script>

<style lang="scss" scoped>
.input-icon{
    cursor: pointer;
}
</style>
