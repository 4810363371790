var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "文件大小" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入文件大小" },
              model: {
                value: _vm.activeData.fileSize,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "fileSize", _vm._n($$v))
                },
                expression: "activeData.fileSize",
              },
            },
            [
              _c(
                "el-select",
                {
                  style: { width: "66px" },
                  attrs: { slot: "append" },
                  slot: "append",
                  model: {
                    value: _vm.activeData.sizeUnit,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData, "sizeUnit", $$v)
                    },
                    expression: "activeData.sizeUnit",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "KB", value: "KB" } }),
                  _c("el-option", { attrs: { label: "MB", value: "MB" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最大上传数" } },
        [
          _c("el-input-number", {
            attrs: {
              min: 0,
              placeholder: "最大上传数",
              step: 1,
              precision: 0,
              "controls-position": "right",
            },
            model: {
              value: _vm.activeData.limit,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "limit", $$v)
              },
              expression: "activeData.limit",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.disabled,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "disabled", $$v)
              },
              expression: "activeData.disabled",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }