var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "控件标题" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入控件标题" },
            model: {
              value: _vm.activeData.__config__.label,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "label", $$v)
              },
              expression: "activeData.__config__.label",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "占位提示" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入占位提示" },
            model: {
              value: _vm.activeData.placeholder,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "placeholder", $$v)
              },
              expression: "activeData.placeholder",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-input", {
            attrs: {
              value: _vm.setDefaultValue(
                _vm.activeData.__config__.defaultValue
              ),
              placeholder: "请输入默认值",
            },
            on: { input: _vm.onDefaultValueInput },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "前缀" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入前缀" },
            model: {
              value: _vm.activeData.__slot__.prepend,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__slot__, "prepend", $$v)
              },
              expression: "activeData.__slot__.prepend",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "后缀" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入后缀" },
            model: {
              value: _vm.activeData.__slot__.append,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__slot__, "append", $$v)
              },
              expression: "activeData.__slot__.append",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "前图标" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入前图标名称" },
              model: {
                value: _vm.activeData["prefix-icon"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "prefix-icon", $$v)
                },
                expression: "activeData['prefix-icon']",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: {
                    click: function ($event) {
                      return _vm.openIconsDialog("prefix-icon")
                    },
                  },
                  slot: "append",
                },
                [_vm._v("\n        选择\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "后图标" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入后图标名称" },
              model: {
                value: _vm.activeData["suffix-icon"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "suffix-icon", $$v)
                },
                expression: "activeData['suffix-icon']",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: {
                    click: function ($event) {
                      return _vm.openIconsDialog("suffix-icon")
                    },
                  },
                  slot: "append",
                },
                [_vm._v("\n        选择\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最多输入" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入字符长度", type: "number" },
              model: {
                value: _vm.activeData.maxlength,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "maxlength", $$v)
                },
                expression: "activeData.maxlength",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("个字符")])],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否只读" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.activeData.readonly,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData, "readonly", $$v)
                      },
                      expression: "activeData.readonly",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否禁用" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.activeData.disabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.activeData, "disabled", $$v)
                      },
                      expression: "activeData.disabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "是否必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.__config__.required,
              callback: function ($$v) {
                _vm.$set(_vm.activeData.__config__, "required", $$v)
              },
              expression: "activeData.__config__.required",
            },
          }),
        ],
        1
      ),
      _c("iconBox", {
        attrs: {
          visible: _vm.iconsVisible,
          current: _vm.activeData[_vm.currentIconModel],
        },
        on: {
          "update:visible": function ($event) {
            _vm.iconsVisible = $event
          },
          choiceIcon: _vm.setIcon,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }