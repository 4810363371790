var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid-layout", {
        ref: "griLayOut",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableOPtions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          editType: _vm.inline,
          spanMethod: _vm.spanMethod,
        },
        on: { "row-change": _vm.rowChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }