var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: { "head-btn-options": _vm.headBtnOptions },
            on: { "head-cancel": _vm.headCancel },
          }),
          _c("form-layout", {
            ref: "formLayout1",
            attrs: { column: _vm.form1Column, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
          _c("head-layout", { attrs: { "head-title": "项目人员信息" } }),
          _c("form-layout", {
            ref: "formLayout2",
            attrs: { column: _vm.form2Column, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
          _c("head-layout", { attrs: { "head-title": "项目现场信息" } }),
          _c("form-layout", {
            ref: "formLayout3",
            attrs: { column: _vm.form3Column, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _vm.type != "view" ||
          (this.type == "workflow" && this.dataForm.dataState == 3)
            ? _c("el-button", { on: { click: _vm.headSave } }, [
                _vm._v("保 存"),
              ])
            : _vm._e(),
          _vm.type != "view" && this.type != "workflow"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.headSubmit } },
                [_vm._v("提 交")]
              )
            : _vm._e(),
          _vm.type == "view" && this.type != "workflow"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.headCancel } },
                [_vm._v("取 消")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }