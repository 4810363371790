var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.dc.AiResult.title"),
        },
        on: { "head-cancel": _vm.cancelFn },
      }),
      _c(
        "div",
        { staticStyle: { background: "#fff", padding: "20px" } },
        [
          this.$route.query.type != "Resultdetail"
            ? [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: "form",
                      disabled: _vm.disabled,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "cip.dc.AiSuanfa.field.modeName"
                                  ),
                                  required: "",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.model_name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "model_name", $$v)
                                    },
                                    expression: "form.model_name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("cip.dc.AiSuanfa.field.label"),
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.algorithm_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "algorithm_id", $$v)
                                      },
                                      expression: "form.algorithm_id",
                                    },
                                  },
                                  _vm._l(
                                    _vm.algorithmList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.algorithm_name,
                                          value: item.algorithm_id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _vm.$route.query.type === "xunlian"
                            ? _c(
                                "div",
                                { staticStyle: { float: "right" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.xuanlianmoxingFn },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("cip.dc.AiSuanfa.field.xl")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c("div", {
                      staticStyle: { width: "100%", height: "20px" },
                    }),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "left" } },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("cip.dc.AiSuanfa.field.placeholder"))
                        ),
                      ]
                    ),
                    _c("div", {
                      staticStyle: { width: "100%", height: "20px" },
                    }),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "cip.dc.QTemplate.field.DataTheme"
                                  ),
                                  prop: "sourceId",
                                },
                              },
                              [
                                _c("el-cascader", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "show-all-levels": false,
                                    options: _vm.sourceList,
                                    props: {
                                      value: "id",
                                      label: "name",
                                      children: "children",
                                      expandTrigger: "hover",
                                    },
                                  },
                                  on: { change: _vm.sourceChange },
                                  model: {
                                    value: _vm.form.sourceId2,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "sourceId2", $$v)
                                    },
                                    expression: "form.sourceId2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "cip.dc.QTemplate.field.DataObject"
                                  ),
                                  prop: "data_obj_id",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    on: { change: _vm.sourceChange2 },
                                    model: {
                                      value: _vm.form.sourceId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "sourceId", $$v)
                                      },
                                      expression: "form.sourceId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.sourceList2,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.code,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("cip.dc.AIDComission.sort"),
                                },
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: { min: "0", controls: false },
                                  model: {
                                    value: _vm.form.algorithm_sort,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "algorithm_sort", $$v)
                                    },
                                    expression: "form.algorithm_sort",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "left" } },
                      [_vm._v(_vm._s(_vm.$t("cip.dc.AIDComission.tiaocan")))]
                    ),
                    _c("div", {
                      staticStyle: { width: "100%", height: "20px" },
                    }),
                    _c(
                      "el-row",
                      _vm._l(_vm.tcParms, function (item, index) {
                        return _c(
                          "el-col",
                          { key: index, attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: item.argName } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: item.value,
                                    callback: function ($$v) {
                                      _vm.$set(item, "value", $$v)
                                    },
                                    expression: "item.value",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.$route.query.type != "detail"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("cip.dc.AIDComission.choosezhou"))
                              ),
                            ]),
                            _c(
                              "el-table",
                              {
                                ref: "x1table",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  border: "",
                                  data: _vm.columnList,
                                  "header-cell-class-name": _vm.leftheaderStyle,
                                },
                                on: { select: _vm.selectx1 },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "fieldComment",
                                    label: _vm.$t("cip.dc.AiSuanfa.field.zh"),
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "fieldName",
                                    label: _vm.$t(
                                      "cip.dc.AiSuanfa.field.field"
                                    ),
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "dataType",
                                    label: _vm.$t(
                                      "cip.dc.AiSuanfa.field.dataType"
                                    ),
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("cip.dc.AIDComission.isX"),
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": "1",
                                                "inactive-value": "0",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeX(
                                                    $event,
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.x,
                                                callback: function ($$v) {
                                                  _vm.$set(scope.row, "x", $$v)
                                                },
                                                expression: "scope.row.x",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1214012848
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("cip.dc.AIDComission.isY"),
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": "1",
                                                "inactive-value": "0",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeY(
                                                    $event,
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.y,
                                                callback: function ($$v) {
                                                  _vm.$set(scope.row, "y", $$v)
                                                },
                                                expression: "scope.row.y",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3422779152
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("cip.dc.AIDComission.sort"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "tableSclect",
                                                attrs: { clearable: "" },
                                                model: {
                                                  value: scope.row.sortRule,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "sortRule",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.sortRule",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "cip.dc.dataobjDComission.upsort"
                                                    ),
                                                    value: "0",
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "cip.dc.dataobjDComission.downsort"
                                                    ),
                                                    value: "1",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    572039399
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticStyle: { width: "100%", height: "20px" } }),
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("cip.dc.AiSuanfa.field.TrainingResults")) +
                      "\n      "
                  ),
                ]),
                _c("div", { staticStyle: { width: "100%", height: "20px" } }),
                _c("img", {
                  staticStyle: {
                    width: "60vw",
                    clear: "both",
                    display: "block",
                    margin: "auto",
                  },
                  attrs: { src: _vm.xlimage, alt: "" },
                }),
              ]
            : _vm._e(),
          this.$route.query.type == "Resultdetail"
            ? [
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _vm._v(_vm._s(_vm.$t("AiSuanfa.detail.ycjg"))),
                ]),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cip.dc.AiSuanfa.field.x")) +
                            "\n          "
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { plain: "", size: "mini", type: "danger" },
                          },
                          [_vm._v(_vm._s(_vm.xArr))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cip.dc.AiSuanfa.field.y")) +
                            ":"
                        ),
                        _vm._l(_vm.yArr, function (item, index) {
                          return _c(
                            "el-button",
                            {
                              key: index,
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                plain: "",
                                size: "mini",
                                type: "primary",
                              },
                            },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { border: "", data: _vm.tableData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "powerconsumption" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }