<template>
  <div>
    <div class="disFlex">
      <div style="width: calc(50% - 8px)">
        <div class="card-pd-2" style="width: calc(100% - 52px)">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            安全培训情况
          </span>
            <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div>
          </div>
          <div class="card-pd-2-Bottom">
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">
                培训次数
              </div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value1) || 0 }}<span>次</span>
              </div>
              <slider :value="Math.floor(dataForm.value3)"/>
              <div class="botLitte2-info">
                计划培训次数：<span>{{ formatWithCommas(dataForm.value2) || 0 }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ formatWithCommas(dataForm.value3) }}%</span>
              </div>
            </div>
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">
                培训人次
              </div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value4) || 0 }}<span>人次</span>
              </div>
              <slider :value="68"/>
              <div class="botLitte2-info">
                计划培训人次：<span>{{ formatWithCommas(31) }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ formatWithCommas(31) }}%</span>
              </div>
            </div>
            <div class="card-pd-2-BotLitte2" style="padding: 21px 0 0 0;width: calc(33.3% - 10px)">
              <div class="botLitte2-title" style="text-indent: 21px;">
                培训覆盖率
              </div>
              <div id="chart-eighteen">
              </div>
            </div>
          </div>
        </div>
        <div class="card-pd-2" style="width: calc(100% - 52px);margin-top: 16px">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            公司组织的安全培训情况
          </span>
            <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div>
          </div>
          <div class="card-pd-2-Bottom">
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">
                培训次数
              </div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value7) || 0 }}<span>次</span>
              </div>
              <slider :value="Math.floor(dataForm.value8)"/>
              <div class="botLitte2-info">
                计划培训次数：<span>{{ formatWithCommas(dataForm.value8) || 0 }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ formatWithCommas(dataForm.value9) || 0 }}%</span>
              </div>
            </div>
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">
                培训人次
              </div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value10) || 0 }}<span>人次</span>
              </div>
              <slider :value="68"/>
              <div class="botLitte2-info">
                计划培训人次：<span>{{ formatWithCommas(dataForm.value11) || 0 }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ formatWithCommas(dataForm.value12) || 0 }}%</span>
              </div>
            </div>
            <div class="card-pd-2-BotLitte2" style="padding: 21px 0 0 0;width: calc(33.3% - 10px)">
              <div class="botLitte2-title" style="text-indent: 21px;">
                培训覆盖率
              </div>
              <div id="chart-nineteen">
              </div>
            </div>
          </div>
        </div>
        <div class="card-pd-2" style="width: calc(100% - 52px);margin-top: 16px">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            分包商组织的安全培训情况
          </span>
            <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div>
          </div>
          <div class="card-pd-2-Bottom">
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">
                培训次数
              </div>
              <div class="botLitte2-number">
                {{ formatWithCommas(391) }}<span>次</span>
              </div>
              <slider :value="68"/>
              <div class="botLitte2-info">
                计划培训次数：<span>{{ formatWithCommas(31) }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ formatWithCommas(31) }}%</span>
              </div>
            </div>
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">
                培训人次
              </div>
              <div class="botLitte2-number">
                {{ formatWithCommas(391) }}<span>人次</span>
              </div>
              <slider :value="68"/>
              <div class="botLitte2-info">
                计划培训人次：<span>{{ formatWithCommas(31) }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ formatWithCommas(31) }}%</span>
              </div>
            </div>
            <div class="card-pd-2-BotLitte2" style="padding: 21px 0 0 0;width: calc(33.3% - 10px)">
              <div class="botLitte2-title" style="text-indent: 21px;">
                培训覆盖率
              </div>
              <div id="chart-twenty">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: calc(50% - 8px)">
        <div class="card-pd-2" style="width: calc(100% - 52px)">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            下属组织培训次数TOP5
          </span>
            <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div>
          </div>
          <div class="card-pd-2-Bottom">
            <div id="chart-twenty-one"></div>
          </div>
        </div>
        <div class="card-pd-2" style="width: calc(100% - 52px);margin-top: 16px">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            安全培训次数近半年月度统计
          </span>
            <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div>
          </div>
          <div class="card-pd-2-Bottom">
            <div id="chart-twenty-two"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import slider from "@/views/business/board/components/slider";
import {appIndex} from "@/api/user";
import {mapGetters} from "vuex";

export default {
  components: {slider},
  data() {
    return {
      year: '',
      month: '',
      dataForm: {
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
        value7: '',
        value8: '',
        value9: '',
        value10: '',
        value11: '',
        value12: '',
        value13: '',
        value14: '',
      }
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  created() {
    this.year = new Date().getFullYear();
  },
  mounted() {
    setTimeout(() => {
      this.getChartEighteen();
      this.getChartNineteen();
      this.getChartTwenty();
      this.getChartTwentyOne();
      this.getChartTwentyTwo();
    }, 200)
    this.getIndexData();
  },
  methods: {
    formatWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getIndexData() {
      let oneData = new Promise((resolve, reject) => {
        // 安全培训完成次数
        appIndex({
          indexCode: 'TRAIN_COMPLETE_CNT_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      let twoData = new Promise((resolve, reject) => {
        // 安全培训计划次数
        appIndex({
          indexCode: 'TRAIN_PLAN_CNT_Y',
          _year: this.year,
          companyId: this.userInfo.dept_id
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        })
      });

      Promise.all([oneData, twoData]).then((res) => {
        console.log(res, '402');
        this.dataForm.value1 = res[0];
        this.dataForm.value2 = res[1];
        let value3 = ((Number(res[0]) / Number(res[1])) * 100).toFixed(2);
        if (value3 == 'NaN') {
          this.dataForm.value3 = 0;
        } else {
          this.dataForm.value3 = value3;
        }
        this.dataForm.value7 = res[0];
        this.dataForm.value8 = res[1];
        let value9 = ((Number(res[0]) / Number(res[1])) * 100).toFixed(2);
        if (value9 == 'NaN') {
          this.dataForm.value9 = 0;
        } else {
          this.dataForm.value9 = value9;
        }
      })

      // 安全培训人次
      appIndex({
        indexCode: 'TRAIN_PERSON_TIMES_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value4 = res.data.data.records[0]._value;
        }
      })
    },
    getChartEighteen() {
      appIndex({
        indexCode: 'TRAIN_RATE_OF_COVERAGE_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          let value = res.data.data.records[0]._value;
          var myChart = this.$echarts.init(
            document.getElementById("chart-eighteen")
          );
          var option = {
            series: [
              {
                radius: "100%",
                type: "gauge",
                progress: {
                  show: true,
                  itemStyle: {
                    color: "#FB3636",
                  },
                  width: 10,
                  roundCap: true,
                },
                axisLine: {
                  lineStyle: {
                    width: 10
                  },
                  roundCap: true,
                },
                axisTick: {
                  show: true,
                },
                splitLine: {
                  show: false,
                  length: 15,
                  lineStyle: {
                    width: 2,
                    color: "#999",
                  },
                },
                axisLabel: {
                  show: true,
                  distance: 10,
                  color: "#999",
                  fontSize: 10,
                },
                anchor: {
                  show: false,
                  showAbove: true,
                  size: 15,
                  itemStyle: {
                    borderWidth: 5,
                  },
                },
                title: {
                  show: true,
                  fontSize: 20,
                  offsetCenter: [0, "70%"],
                },
                detail: {
                  valueAnimation: true,
                  formatter: "{value}%",
                  fontSize: 28,
                  offsetCenter: [0, "5%"],
                },
                pointer: {
                  show: false,
                },
                data: [
                  {
                    value: value || 0,
                    name: "覆盖率",
                  },
                ],
              },
            ],
          };
          myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        }
      })
    },
    getChartNineteen() {
      appIndex({
        indexCode: 'TRAIN_RATE_OF_COVERAGE_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          let value = res.data.data.records[0]._value;
          var myChart = this.$echarts.init(
            document.getElementById("chart-nineteen")
          );
          var option = {
            series: [
              {
                radius: "100%",
                type: "gauge",
                progress: {
                  show: true,
                  itemStyle: {
                    color: "#FB3636",
                  },
                  width: 10,
                  roundCap: true,
                },
                axisLine: {
                  lineStyle: {
                    width: 10
                  },
                  roundCap: true,
                },
                axisTick: {
                  show: true,
                },
                splitLine: {
                  show: false,
                  length: 15,
                  lineStyle: {
                    width: 2,
                    color: "#999",
                  },
                },
                axisLabel: {
                  show: true,
                  distance: 10,
                  color: "#999",
                  fontSize: 10,
                },
                anchor: {
                  show: false,
                  showAbove: true,
                  size: 15,
                  itemStyle: {
                    borderWidth: 5,
                  },
                },
                title: {
                  show: true,
                  fontSize: 20,
                  offsetCenter: [0, "70%"],
                },
                detail: {
                  valueAnimation: true,
                  formatter: "{value}%",
                  fontSize: 28,
                  offsetCenter: [0, "5%"],
                },
                pointer: {
                  show: false,
                },
                data: [
                  {
                    value: value,
                    name: "覆盖率",
                  },
                ],
              },
            ],
          };
          myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        }
      })
    },
    getChartTwenty() {
      var myChart = this.$echarts.init(
        document.getElementById("chart-twenty")
      );
      var option = {
        series: [
          {
            radius: "100%",
            type: "gauge",
            progress: {
              show: true,
              itemStyle: {
                color: "#FB3636",
              },
              width: 10,
              roundCap: true,
            },
            axisLine: {
              lineStyle: {
                width: 10
              },
              roundCap: true,
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: false,
              length: 15,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              show: true,
              distance: 10,
              color: "#999",
              fontSize: 10,
            },
            anchor: {
              show: false,
              showAbove: true,
              size: 15,
              itemStyle: {
                borderWidth: 5,
              },
            },
            title: {
              show: true,
              fontSize: 20,
              offsetCenter: [0, "70%"],
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}%",
              fontSize: 32,
              offsetCenter: [0, "5%"],
            },
            pointer: {
              show: false,
            },
            data: [
              {
                value: 65,
                name: "覆盖率",
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartTwentyOne() {
      appIndex({
        indexCode: 'ORG_TRAIN_TIMES_TOP5_Y',
        _year: this.year,
        // companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data.records;
          let companyName = new Array();
          let value = new Array();
          data.forEach((item) => {
            companyName.push(item.org_name);
            value.push(item._value);
          })
          var myChart = this.$echarts.init(
            document.getElementById("chart-twenty-one")
          );
          var option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
              }
            },
            grid: { // 让图表占满容器
              top: "40px",
              left: "220px",
              // right: "0px",
              bottom: "25px"
            },
            xAxis: {
              type: 'value',
              axisLabel: {
                color: 'rgba(134, 144, 156, 1)',
              },
              // interval: 30,
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed'
                }
              },
            },
            yAxis: {
              type: 'category',
              axisLabel: {
                color: 'rgba(78, 89, 105, 0.6)',
              },
              nameTextStyle: {//y轴上方单位的颜色
                color: 'rgba(120, 122, 128, 1)'
              },
              name: '单位：次',
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed'
                }
              },
              axisLine: {
                lineStyle: {
                  color: ['#E8E8E8']
                }
              },
              data: companyName
            },
            series: [
              {
                name: '次数',
                type: 'bar',
                stack: 'total',
                color: '#FB3636',
                barWidth: 25,
                emphasis: {
                  focus: 'series'
                },
                itemStyle: {
                  normal: {
                    color: function () {
                      return {
                        type: 'linear',
                        x: 1,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                          offset: 0,
                          color: '#FB3636' // 0% 处的颜色
                        },
                          {
                            offset: 1,
                            color: '#FF8181' // 100% 处的颜色
                          }
                        ],
                      }
                    }
                  }
                },
                tooltip: {
                  valueFormatter: function (value) {
                    return value + ' 次';
                  }
                },
                data: value
              },
            ]
          };
          myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        }
      })
    },
    getChartTwentyTwo() {
      // 安全培训次数近半年月度统计
      appIndex({
        indexCode: 'TRAIN_PER_MONTH_Y',
        _year: this.year,
        // companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data.records.slice(0,6);
          let value1 = new Array();
          let month = new Array();
          data.forEach((item) => {
            value1.push(item._value);
            month.push(item._month + '月');
          })
          var myChart = this.$echarts.init(
            document.getElementById("chart-twenty-two")
          );
          var option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },
            legend: {
              right: '0',
            },
            grid: { // 让图表占满容器
              top: "40px",
              left: "60px",
              right: "0px",
              bottom: "25px"
            },
            xAxis: [
              {
                type: 'category',
                axisTick: {show: false},
                axisLabel: {color: '#b3b5bb'},
                data: month
              },
            ],
            yAxis: [
              {
                type: 'value',
                name: '单位：次',
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: 'dashed'
                  }
                },
              },
            ],
            series: [
              {
                name: '公司组织',
                type: 'bar',
                emphasis: {
                  focus: 'series',
                },
                barWidth: 25,
                color: '#FB3636',
                data: value1,
                itemStyle: {
                  normal: {
                    color: function () {
                      return {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                          offset: 0,
                          color: '#FB3636' // 0% 处的颜色
                        },
                          {
                            offset: 1,
                            color: '#FF8181' // 100% 处的颜色
                          }
                        ],
                      }
                    }
                  }
                },
              },
              // {
              //   name: '分包商组织',
              //   type: 'bar',
              //   barWidth: 25,
              //   emphasis: {
              //     focus: 'series',
              //   },
              //   color: '#FFAB2D',
              //   itemStyle: {
              //     normal: {
              //       color: function () {
              //         return {
              //           type: 'linear',
              //           x: 0,
              //           y: 0,
              //           x2: 0,
              //           y2: 1,
              //           colorStops: [{
              //             offset: 0,
              //             color: '#FFAB2D' // 0% 处的颜色
              //           },
              //             {
              //               offset: 1,
              //               color: '#FFCD82' // 100% 处的颜色
              //             }
              //           ],
              //         }
              //       }
              //     }
              //   },
              //   data: [5900, 4100, 7900, 2500, 6000, 4200],
              // },
            ],
          };
          myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.disFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.card-pd-2 {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-2-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-2-top-Title {
  color: #15161A;
  font-size: 21px;
  font-weight: bold;
}

.card-pd-2-top-btn {
  display: flex;
  justify-content: flex-end;
}

.card-pd-2-top-btn img {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}

.card-pd-2-Bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.card-pd-2-BotLitte2 {
  width: calc(33.3% - 52px);
  border-radius: 8px;
  padding: 21px;
  border: 1px solid #EDEDED;
}

.botLitte2-number {
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
  margin-top: 21px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}

.botLitte2-number span {
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
}

.botLitte2-title {
  width: 100%;
  color: #484A4D;
  font-size: 21px;
}

.botLitte2-info {
  color: #15161A;
  font-size: 18px;
  margin-top: 13px;
}

.botLitte2-info span {
  color: #FB3636;
}

#chart-eighteen {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 21px;
}

#chart-nineteen {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 21px;
}

#chart-twenty {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 21px;
}

#chart-twenty-one {
  width: 100%;
  height: 420px;
}

#chart-twenty-two {
  width: 100%;
  height: 438px;
}

::v-deep .el-slider__runway.disabled .el-slider__bar {
  background-color: #FB3636;
}

::v-deep .el-slider__runway.disabled .el-slider__button {
  border-color: #FB3636;
}
</style>
