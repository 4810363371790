<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="我的考试"
          :head-btn-options="headBtnOptions"
          @head-remove="headRemove"
          @head-add="headAdd"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page.sync="page"
          @row-remove="rowRemove"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
        ></grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import {mapGetters} from "vuex";
import {getEduTree} from "@/api/training/edutree";
import {getList, getDetail, add, update, remove} from "@/api/training/eduuserexam";


export default {
  name: "eduuserexam",
  components: {
    HeadLayout,
    GridLayout,
  },
  data() {
    return {
      query: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.edupaper_add, true),
        viewBtn: this.vaildData(this.permission.edupaper_view, true),
        delBtn: this.vaildData(this.permission.edupaper_delete, true),
        editBtn: this.vaildData(this.permission.edupaper_edit, true)
      };
    },
    searchColumns() {
      return [
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "考试状态",
          prop: "examStatus",
          type: "select",
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EXAM_STATUS',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "专业分类",
          prop: "subjectType",
          dicData: [],
          type: "tree",
          props: {
            label: "title",
            value: "value",
          },
          dataType: 'number',
        },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "作业类型",
          prop: "examType",
          type: "select",
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EXAM_TYPE',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permissionList.addBtn) {
        buttonBtn.push(
          {
            label: '自主练习',
            emit: "head-add",
            type: "button",
            btnOptType: 'primary',
          });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove",
            type: "button",
            icon: "",
            btnOptType: 'remove',
          });
      }
      return buttonBtn;
    },
    gridRowBtn() {
      let buttonBtn = [];
      if (this.permissionList.delBtn) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "row-remove",
            type: "button",
            icon: ""
          })
      }
      return buttonBtn;
    },
    tableOptions() {
      return {
        labelWidth: 120,
        index: true,
        indexLabel: "序号",
        menu: true,
        column: [
          {
            label: "考试状态",
            prop: "examStatus",
            sortable: true,
            type: "select",
            width: 120,
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EXAM_STATUS',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: 'center',
          },
          {
            label: "专业分类",
            prop: "subjectType",
            sortable: true,
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            dataType: 'number',
          },
          {
            label: "答题时长",
            prop: "answerTime",
            sortable: true,
            width: 120,
            align: 'right',
          },
          {
            label: "开始时间",
            prop: "startTime",
            sortable: true,
            type: "datetime",
            width: 170,
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
          },
          {
            label: "结束时间",
            prop: "endTime",
            sortable: true,
            type: "datetime",
            width: 170,
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
          },
          {
            label: "作业类型",
            prop: "examType",
            sortable: true,
            type: "select",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EXAM_TYPE',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: "得分",
            prop: "totalScore",
            sortable: true,
            width: 100,
            align: 'right',
          },
        ]
      }
    },
  },
  methods: {
    initData() {
      getEduTree().then(res => {
        const column = this.findObject(this.$refs.gridLayOut.option.column, 'subjectType')
        column.dicData = res.data.data
        this.findObject(this.$refs.gridHeadLayout.searchOption.column, 'subjectType').dicData = res.data.data
      })
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = this.$refs.gridLayOut.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page)
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(this.$func.join(ids));
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.$refs.gridLayOut.selectionClear();
        });
    },
    rowRemove(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
    headAdd() {
      this.$router.push({
        path: '/training/exam/edupaperselect',
        query: {
          type: 'add',
        }
      });
    }
  },
  mounted() {
    this.initData()
  },
  created() {
    this.onLoad(this.page)
  }
}
</script>

<style scoped>

</style>
