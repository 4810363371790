<template>
    <div>
        <el-button @click="handleChange">切换</el-button>
        <video id="video" autoplay width="900" height="900"></video>
    </div>
</template>

<script>
import '../util/webrtcstreamer';
import "../util/adapter.min";
export default {
    name: 'index1',
    data() {
        return {
            webRtcServer: null
        }
    },
    mounted() {
        //video：需要绑定的video控件ID
        //127.0.0.1:8000：启动webrtc-streamer的设备IP和端口，默认8000
        this.webRtcServer = new WebRtcStreamer('video', location.protocol + '//47.120.24.195:8000')
        //需要查看的rtsp地址
        this.webRtcServer.connect('rtsp://admin:*****HAOren778@60.174.100.175:554/cam/realmonitor?channel=1&subtype=0&unicast=true')
        //rtsp://user:password@ip:port/h264/ch1/main/av_stream--海康
        //rtsp://user:password@ip:port/cam/realmonitor?channel=1&subtype=0--大华
    },
    beforeDestroy() {
        this.webRtcServer.disconnect()
        this.webRtcServer = null
    },
    methods: {
        handleChange() {
            this.webRtcServer.connect('rtsp://admin:*****HAOren778@60.174.100.175:554/cam/realmonitor?channel=1&subtype=0&unicast=true')
        }
    }
}
</script>

<style scoped></style>