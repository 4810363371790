<template>
  <div class="main-content">
    <div class="inventory-content">
      <div class="left">
        <head-layout
          :head-btn-options="headCardListOptions"
          head-title="标识清单"
          @head-forbid="signsType(4)"
          @head-warm="signsType(3)"
          @head-order="signsType(2)"
          @head-tip="signsType(1)"
        >
        </head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <div class="list" v-loading.body="listLoading">
          <el-row :gutter="10">
            <el-col :span="4">
              <el-card
                :body-style="{ padding: '10px' }"
                style="width: 100%; height: 245px"
                class="up-card"
              >
                <div class="upload" @click="addSigns">
                  <i class="el-icon-plus" style="font-size: 60px"></i>
                  <div>添加标识</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="4" v-for="item in list" :key="item.id">
              <el-card :body-style="{ padding: '10px' }" class="list-card">
                <div class="content">
                  <el-image
                    style="width: 100%; height: 150px"
                    :src="item.picUrl"
                    @click.stop="showFile(item)"
                    :fit="fit"
                  >
                    <div
                      slot="error"
                      class="image-slot"
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                      "
                    >
                      <i
                        class="el-icon-picture-outline"
                        style="font-size: 20px"
                      ></i>
                      <div style="text-align: center; margin-top: 10px">
                        暂无图片
                      </div>
                    </div>
                  </el-image>
                  <el-tooltip placement="top" :content="item.signName">
                    <div class="name">{{ item.signName }}</div>
                  </el-tooltip>
                  <el-divider class="el-divider"></el-divider>
                  <div class="bottom">
                    <!-- <div class="num">
                      库存量:{{ item.quantity }}{{ item.unitOfMeasure }}
                    </div> -->
                    <div class="btn">
                      <i
                        class="el-icon-view"
                        style="margin-right: 14px; cursor: pointer"
                        @click.stop="viewItem(item)"
                      ></i>
                      <i
                        class="el-icon-download"
                        style="margin-right: 12px; cursor: pointer"
                        @click="downloadItem(item)"
                      ></i>
                      <i
                        class="el-icon-edit"
                        style="margin-right: 10px; cursor: pointer"
                        @click.stop="editItem(item)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="removeList(item)"
                        style="cursor: pointer"
                      ></i>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 抽屉 -->
    <el-drawer
      :visible.sync="cardDrawer"
      class="qmDialog"
      :direction="direction"
    >
      <!-- 抽屉标题 -->
      <template slot="title">
        <span style="font-size: 28px">{{ this.drawerName }}</span>
      </template>
      <!-- 抽屉body -->
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <br />
          <el-col :span="24">
            <el-form-item
              label="安全标识图片："
              label-width="150px"
              prop="picUrl"
            >
              <template v-if="this.type === 'view'">
                <el-image
                  v-for="(item, index) in picUrlList"
                  :key="index"
                  :src="item.url"
                  style="width: 146px; height: 146px; margin: 0 9px 8px 1px"
                  :preview-src-list="picUrlList.map((val) => val.url)"
                ></el-image
              ></template>
              <el-upload
                v-if="this.type === 'edit'"
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                :show-file-list="true"
                :limit="1"
                :before-upload="checkFileType"
                :on-exceed="handleExceed"
                :on-success="handlepicUrlSuccess"
                ref="uploadRef"
                list-type="picture-card"
                accept=".jpg,.png"
                :file-list="picUrlList"
                :multiple="false"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :disabled="
                  this.drawerName === '查看标识'
                    ? true
                    : this.drawerName === '添加标识'
                    ? false
                    : this.drawerName === ' 编辑标识'
                    ? false
                    : false
                "
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <label slot="label"
                ><span style="color: red">*</span> 安全标识图片：</label
              >
            </el-form-item>
            <br />
          </el-col>
          <el-col :span="20">
            <el-form-item
              label="安全标识名称："
              label-width="150px"
              prop="signName"
            >
              <el-input
                v-model="form.signName"
                placeholder="安全标识名称"
                :disabled="
                  this.drawerName === '查看标识'
                    ? true
                    : this.drawerName === '添加标识'
                    ? false
                    : this.drawerName === ' 编辑标识'
                    ? false
                    : false
                "
              ></el-input>
            </el-form-item>
            <br />
          </el-col>
          <el-col :span="20">
            <el-form-item
              label="安全标识类型："
              label-width="150px"
              prop="signType"
            >
              <el-select
                v-model="form.signType"
                placeholder="安全标识类型"
                :disabled="
                  this.drawerName === '查看标识'
                    ? true
                    : this.drawerName === '添加标识'
                    ? false
                    : this.drawerName === ' 编辑标识'
                    ? false
                    : false
                "
                @change="handleInput"
              >
                <el-option
                  style="color=red"
                  v-for="item in signTypOoptions"
                  :key="item.dictKey"
                  :value="item.dictKey"
                  :label="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <br />
          </el-col>
          <el-col :span="20">
            <el-form-item
              label="标识种类："
              label-width="150px"
              prop="signClass"
            >
              <el-checkbox-group
                v-model="checkList"
                :disabled="
                  this.drawerName === '查看标识'
                    ? true
                    : this.drawerName === '添加标识'
                    ? false
                    : this.drawerName === ' 编辑标识'
                    ? false
                    : false
                "
              >
                <el-checkbox
                  :label="item"
                  v-for="(item, index) in checkedList"
                  :key="index"
                >
                </el-checkbox>
              </el-checkbox-group>
              <label slot="label"
                ><span style="color: red">*</span> 标识种类：</label
              >
            </el-form-item>

            <br />
          </el-col>
          <el-col :span="20">
            <el-form-item
              label="设置范围和地点："
              label-width="150px"
              prop="description"
            >
              <el-input
                type="textarea"
                v-model="form.description"
                placeholder="设置范围和地点"
                :rows="4"
                :disabled="
                  this.drawerName === '查看标识'
                    ? true
                    : this.drawerName === '添加标识'
                    ? false
                    : this.drawerName === ' 编辑标识'
                    ? false
                    : false
                "
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 抽屉取消、保存 -->
      <div
        class="drawer-footer"
        style="position: fixed; bottom: 5px; right: 10px"
      >
        <el-button @click="cardDrawer = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveList"
          :disabled="
            this.drawerName === '查看标识'
              ? true
              : this.drawerName === '添加标识'
              ? false
              : this.drawerName === ' 编辑标识'
              ? false
              : false
          "
          >保存</el-button
        >
      </div>
    </el-drawer>
    <el-dialog
      v-dialog-drag
      title="标识预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDictionaryCode } from "@/api/system/dictbiz";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import website from "@/config/website";
import {
  getSignList,
  getSignDetail,
  addSigns,
  remove,
} from "@/api/bssettings/safetySignsList";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  computed: {},
  data() {
    return {
      fileUrl: "",
      signTypOoptions: [],
      form: {},
      listLoading: true,
      checkList: [], //checkbox
      checkedList: ["H", "J"], //checkbox
      continuefor: true,
      cardDrawer: false, //卡片抽屉
      drawerName: "", //抽屉名称
      isDisabled: true,
      nowIndex: -1,
      list: [],
      fileList: [],
      picUrlList11: [],
      picUrlList: [],
      showFileDialog: false, //图片预览
      query: {},
      type: "",
      page: {
        pageSize: 9999999,
        currentPage: 1,
        total: 0,
      },
      //标识类型
      headCardListOptions: [
        {
          label: "禁止标识",
          emit: "head-forbid",
          type: "button",
          btnOptType: "romve",
          style: { color: "black", "background-color": "#f44545" },
        },
        {
          label: "警告标识",
          emit: "head-warm",
          type: "button",
          btnOptType: "romve",
          style: { color: "black", "background-color": "#ecec0a" },
        },
        {
          label: "指令标识",
          emit: "head-order",
          type: "button",
          btnOptType: "romve",
          style: { color: "white", "background-color": "#409eff" },
        },
        {
          label: "提示标识",
          emit: "head-tip",
          type: "button",
          btnOptType: "romve",
          style: { color: "white", "background-color": "#54af54" },
        },
      ],
      //搜索
      searchColumns: [
        {
          label: "安全标识名称",
          prop: "signName",
          span: 4,
          placeholder: "请输入标识名称",
        },
      ],
      rules: {
        picUrl: [
          {
            trigger: "change",
            validator: (rule, value, callback) => {
              if (this.picUrlList.length === 0) {
                callback(new Error("请上传安全标识图片"));
              } else {
                callback();
              }
            },
          },
        ],
        signName: [
          {
            required: true,
            message: "请填写安全标识名称",
            trigger: ["blur", "change"],
          },
        ],
        signType: [
          {
            required: true,
            message: "请选择安全标识类型",
            trigger: ["blur", "change"],
          },
        ],
        signClass: [
          {
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (this.checkList.length > 0) {
                callback();
              } else {
                callback(new Error("至少选择一个种类"));
              }
            },
          },
        ],
        description: [
          { required: true, message: "请设置范围和地点", trigger: "change" },
        ],
      },
    };
  },

  mounted() {
    this.onLoad(this.page);
    this.initDictData();
  },
  methods: {
    initDictData() {
      //根据字典Code, 初始化字典数组
      getDictionaryCode("bs_sign_type").then((res) => {
        if ((res.data.code = 200)) {
          //获取字典列表
          const ss = res.data.data.map((item) => {
            this.signTypOoptions.push({
              dictKey: item.dictKey,
              dictValue: item.dictValue,
            });
          });
        }
      });
    },
    handleInput(e) {
      //选中字典列表抛出选中值changes
      this.$emit("changes", e);
    },
    signsType(n) {
      switch (n) {
        case 1:
          this.onLoad(this.page, { signType: "1" });
          break;
        case 2:
          this.onLoad(this.page, { signType: "2" });
          break;
        case 3:
          this.onLoad(this.page, { signType: "3" });
          break;
        case 4:
          this.onLoad(this.page, { signType: "4" });
          break;
      }
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.listLoading = true;
      let queryObj = { ...this.query };
      getSignList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, queryObj)
      ).then((res) => {
        this.list = res.data.data.records || [];
        if (this.list.length) {
          this.handleItemClick(0, this.list[0]);
          this.nowIndex = 0;
        } else {
          this.nowIndex = -1;
        }
        this.listLoading = false;
        console.log(res);
      });
    },
    //预览图片
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.picUrl));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },

    // downloadItem(item) {
    //   const xhr = new XMLHttpRequest();
    //   // item.picUrl1 = item.picUrl.replace(/^http:/, "https:");
    //   xhr.open("get", item.picUrl);
    //   xhr.responseType = "arraybuffter";
    //   // xhr.responseType = "blob";
    //   xhr.send();
    //   xhr.onload = function () {
    //     if (this.status === 200 || this.status === 304) {
    //       // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
    //       if ("msSaveOrOpenBlob" in navigator) {
    //         navigator.msSaveOrOpenBlob(item.picUrl, item.signName);
    //         return;
    //       }
    //       console.log(item.signName, "item.signName");
    //       // const href = URL.createObjectURL(this.response);
    //       const a = document.createElement("a");
    //       a.style.display = "none";
    //       a.href = item.picUrl;
    //       a.download = item.signName;
    //       console.log(a.download, " a.download");
    //       document.body.appendChild(a);
    //       // console.log(
    //       //   document.body.appendChild(a),
    //       //   " document.body.appendChild(a)"
    //       // );
    //       a.click();
    //       // console.log(a.click(), "  a.click()");
    //       document.body.removeChild(a);
    //       // URL.revokeObjectURL(href);
    //     }
    //   };
    // },

    // 下载标识图片，跨域了图片名字需要加.jpg后缀，下载后才是图片格式
    downloadItem(item) {
      const itemSignName = item.signName + ".jpg";
      this.getBlob(item.picUrl).then((blob) => {
        this.saveAs(blob, itemSignName);
      });
    },

    getBlob(url) {
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.responseType = "blob";
        (xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        }),
          xhr.send();
      });
    },

    saveAs(blob, filename) {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
    },

    // 删除图片
    handleRemove(file, fileList) {
      this.fileList = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
      this.picUrlList = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
      if (this.fileList.length == 0 && this.picUrlList.length == 0) {
        this.form.picUrl = "";
        this.$refs.form.validateField("fileList");
      }
    },

    handleExceed(files, fileList) {
      //files指的是你刚上传的文件，fileList指的是你上传之前的文件数组
      this.$set(fileList[0], "raw", files[0]); //raw就是指文件
      this.$set(fileList[0], "name", files[0].name); //name就是指文件名
      this.$refs.uploadRef.clearFiles(); //删除所有上传的文件
      this.$refs.uploadRef.handleStart(files[0]); //handleStart()指的是手动选择文件，Element Plus 的el-upload有说明
      this.$refs.uploadRef.submit(); //手动上传
    },

    handlePreview(file) {
      if (file.response) {
        this.showFile({ picUrl: file.response.data.link });
      } else {
        this.showFile({ picUrl: file.url });
      }
    },

    handlepicUrlSuccess(response, file, picList) {
      picList.map((item) => {
        if (item.response) {
          this.picUrlList = this.fileList = {
            uid: item.uid,
            url: item.response.data.link,
            status: item.status,
          };
        } else {
          this.picUrlList = this.fileList = {
            uid: item.uid,
            url: item.url,
            status: item.status,
          };
        }
      });
      this.form.picUrl = this.fileList.url;
      this.$refs.form.clearValidate("picUrl"); //上传后清理校验
    },

    // 保存标识
    saveList() {
      let parmas = {
        ...this.form,
        picUrl: this.picUrlList.url,
        signClass: this.checkList.toString(), //标识种类以字符串返回保存
      };

      this.$refs.form.validate((valid) => {
        if (valid) {
          addSigns(parmas).then((res) => {
            if (res.data.code === 200) {
              this.onLoad(this.page);
              this.cardDrawer = false;
              console.log(res);
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
            } else {
              this.$message.success(msg);
            }
          });
        }
      });
      console.log(parmas, "this.saveList");
    },

    //标识详情
    getListDetail(row) {
      getSignDetail(row.id).then((res) => {
        this.form = res.data.data;
        const cc = this.form.signClass.split(",");
        if (this.continuefor == true) {
          this.checkList = [];
          for (let i = 0; i < cc.length; ++i) {
            // this.checkList.push({ label: cc[i], value: cc[i] });
            this.checkList.push(cc[i]);
          }
        }
        this.picUrlList = [{ url: this.form.picUrl }];
        this.picUrlList.length = this.picUrlList.length;
      });
      this.picUrlList.length = this.picUrlList.length;
    },

    //查看
    viewItem(item) {
      console.log(item);
      this.type = "view";
      this.cardDrawer = true;
      this.drawerName = "查看标识";
      this.isDisabled = !this.isDisabled;
      this.continuefor = true;
      this.getListDetail(item);
    },
    //编辑
    editItem(item) {
      console.log(item);
      this.type = "edit";
      this.cardDrawer = true;
      this.drawerName = "编辑标识";
      this.continuefor = true;
      this.getListDetail(item);
    },
    //删除
    removeList(item) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove(item.id).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.onLoad(this.page);
            }
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },
    addSigns() {
      this.type = "edit";
      this.checkList = [];
      this.form = {};
      this.fileList = [];
      this.picUrlList = [];
      this.picUrl = "";
      this.cardDrawer = true;
      this.drawerName = "添加标识";
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleItemClick(index, row) {
      if (this.nowIndex == index) {
        return;
      }
      this.nowIndex = index;
      this.list.forEach((item) => {
        item.selected = false;
      });
      this.$set(this.list[index], "selected", true);
      this.getListDetail(row);
      console.log(index, this.list);
    },

    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isJPG = fileType === ".jpg" || fileType == ".png";
      if (!isJPG) {
        this.$message.warning("上传图片只能是 .jpg  .png格式!");
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 5MB!");
      }
      return isJPG && isLt5M;
    },
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;

      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 清空

    gridHeadEmpty() {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.query = {};
      this.onLoad(this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
/* 改变el-option文本颜色 */
.custom-option.el-select-dropdown__item {
  color: black; /* 你想要的颜色 */
}

.el-input-number.el-input-number--small {
  width: 100%;
}
::v-deep .el-tabs__content {
  background-color: transparent;
}
.main-content {
  // background-color: #fff;
  widows: 100%;
  height: 100%;
}
.el-main {
  height: 100%;
  overflow: hidden;
}

::v-deep.inOut-content {
  height: calc(100% - 50px);
  #gridLayout {
    height: calc(100% - 112px);
    .avue-crud {
      height: 100%;
      .el-card {
        height: calc(100% - 46px);
      }
      .avue-crud__pagination {
        height: 46px !important;
      }
      .el-card__body {
        height: 100%;
        .el-form {
          height: 100%;
          .el-table {
            height: 100% !important;
          }
        }
      }
    }
  }
}

::v-deep .left {
  position: relative;
  background-color: #fff;
  flex-shrink: 1;
  width: 100%;
  margin-right: 10px;
  height: 100%;

  .el-card__body {
    padding: 10px !important;
    width: 100%;
    height: 100%;
  }
  .list {
    padding: 10px 5px 10px 10px;
    height: calc(100% - 70px);
    overflow-y: scroll;
    overflow-x: hidden;
    .list-card {
      // cursor: pointer;
      border-radius: 6px;
      margin-bottom: 10px;
      border: 1px solid transparent !important;

      .name {
        text-align: center;
        white-space: nowrap; /* 确保文本在一行内显示 */
        overflow: hidden; /* 隐藏超出容器的文本 */
        text-overflow: ellipsis; /* 使用省略符号表示被截断的文本 */
      }
      .el-divider {
        width: calc(100% + 20px);
        transform: translateX(-10px);
      }
      .bottom {
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        justify-content: right;
      }
    }
  }
  .up-card {
    // cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 294px;
    // height: 100px;

    .upload {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.image-slot {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
