<template>
  <el-container style="position: relative">
    <!-- <CommonTree ref="commonTreeCore" treeTitle="组织架构" :searchTitle="searchTitle"
                :treeData="treeData"
                :defaultProps="defaultProps" @getNodeClick="handleNodeClick" :isShowdig="false" :showCheckbox="false"
                node-key="id" :isZoom="true" :show="show" @showChange="showChange"/> -->
    <CommonTreeNew
      ref="commonTreeCore"
      treeTitle="组织架构"
      :defaultProps="defaultProps"
      :showCheckbox="false"
      @getNodeClick="handleNodeClick"
      :urlParmas="{
        tenantId: '',
        deptCategory: '2',
        parentId: userInfo.dept_id,
      }"
      node-key="id"
      @getTreeData="commonTreeData"
      :isShowdig="false"
      :isZoom="true"
      :show="show"
      @showChange="showChange"
    ></CommonTreeNew>
    <el-main>
      <head-layout
        head-title="职员证照"
        :head-btn-options="headBtnOptions"
        @head-add="handleHeadAdd"
      />
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      >
      </grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOptions"
        :table-data="tableData"
        :table-loading="tableLoading"
        :data-total="page.total"
        :page="page"
        @page-current-change="handleCurrentChange"
        @page-size-change="handleSizeChange"
        @page-refresh-change="onLoad"
        @row-view="rowView"
        @row-edit="rowEdit"
      >
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            v-if="permissionList.editBtn"
            type="text"
            size="small"
            @click="rowEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            style="margin: 0 3px"
            type="text"
            v-if="permissionList.viewBtn"
            size="small"
            @click="rowView(row)"
          >
            查看
          </el-button>
        </template>
      </grid-layout>
    </el-main>
    <el-dialog
      title="人员选择"
      :visible.sync="dialogVisible"
      width="80%"
      top="8vh"
      :before-close="handleClose"
    >
      <UserDetpDialog ref="UserDetpDialog" @select-data="selectData" />
    </el-dialog>
  </el-container>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
// import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  employeesHeadPage,
  employeesHeadSubmit,
} from "@/api/responsibility/licenceEmployees";
import { mapGetters } from "vuex";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import { selectChildren } from "@/api/system/dept";
import { getDeptTree } from "@/api/system/dept";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    UserDetpDialog,
  },

  data() {
    return {
      nodeData: {},
      treeData: [],
      searchTitle: "deptName",
      defaultProps: {
        children: "children",
        label: "title",
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      dialogVisible: false,
      show: false,
    };
  },

  created() {
    // this.lazyDeptTree()
  },
  computed: {
    ...mapGetters(["permission", "colorName", "userInfo"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.licenceEmployees_add, true),
        viewBtn: this.vaildData(this.permission.licenceEmployees_view, true),
        editBtn: this.vaildData(this.permission.licenceEmployees_edit, true),
        delBtn: this.vaildData(this.permission.licenceEmployees_delete, true),
      };
    },
    searchColumns() {
      return [
        {
          prop: "userName",
          placeholder: "姓名",
          span: 4,
        },
      ];
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permissionList.addBtn) {
        buttonBtn.push({
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
      }
      return buttonBtn;
    },

    gridRowBtn() {
      let buttonBtn = [];
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.viewBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    tableOptions() {
      return {
        menuWidth: 160,
        selection: false,
        column: [
          {
            label: "姓名",
            prop: "userName",
            align: "center",
            overHidden: true,
          },
          {
            label: "所属公司",
            prop: "orgName",
            align: "left",
          },
          {
            label: "性别",
            prop: "sex",
            align: "center",
            overHidden: true,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
        ],
      };
    },
  },

  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    showChange(v) {
      this.show = v;
    },
    /** 左侧列表操作 */
    commonTreeData(treeData) {
      // if (treeData.length > 0) {
      //     this.handleNodeClick(treeData[0])
      // }
    },
    //获取树数据
    // lazyDeptTree() {
    //   const parentId = this.userInfo.dept_id;
    //   getDeptTree('', '', parentId).then((res) => {
    //       if (res.data.code == 200) {
    //           this.treeData = res.data.data
    //           if (res.data.data.length > 0) {
    //             this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(this.treeData[0].id)
    //               this.handleNodeClick(this.treeData[0])
    //           }
    //       }
    //   });
    //   // selectChildren().then((res) => {
    //   //   this.treeData = res.data.data;
    //   //   this.nodeData = res.data.data[0];
    //   //   this.$nextTick(() => {
    //   //     this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
    //   //       this.nodeData.id
    //   //     );
    //   //     this.handleNodeClick(this.nodeData);
    //   //   });
    //   // });
    // },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.page.currentPage = 1;
      this.query.orgId = data.id;
      this.onLoad(this.page);
    },

    /**----end---- */
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      employeesHeadPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },

    rowEdit(row, index) {
      this.$router.push({
        path: "/business/responsibility/licenceEmployees/form",
        query: {
          row: JSON.stringify(row),
          type: "edit",
        },
      });
    },
    rowView(row) {
      this.$router.push({
        path: "/business/responsibility/licenceEmployees/form",
        query: {
          row: JSON.stringify(row),
          type: "detail",
        },
      });
    },
    handleHeadAdd() {
      this.dialogVisible = true;
    },

    selectData(row, deptId, deptName) {
      const data = {
        userId: row.id,
        userName: row.realName,
        orgId: deptId,
        orgName: deptName,
        sex: row.sex,
        sexName: row.sexName,
      };
      employeesHeadSubmit(data).then((res) => {
        this.page.currentPage = 1;
        this.onLoad(this.page);
        this.dialogVisible = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
