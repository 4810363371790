var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogShow
    ? _c(
        "el-dialog",
        {
          staticClass: "inspection-dialog",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogShow,
            width: "800px",
            "modal-append-to-body": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "basic-container" }, [
            _c("p", { staticClass: "pub-title" }, [_vm._v("基本信息")]),
            _c("div", { staticClass: "detail-content" }, [
              _c("p", { staticClass: "detail-item" }, [
                _c("label", [_vm._v("区域名称：")]),
                _c("span", [_vm._v(_vm._s(_vm.detailData.regionName))]),
              ]),
              _c("p", { staticClass: "detail-item" }, [
                _c("label", [_vm._v("工程概括：")]),
                _c("span", [_vm._v(_vm._s(_vm.detailData.inspectionContent))]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "basic-container" },
            [
              _c("p", { staticClass: "pub-title" }, [_vm._v("管理员人员")]),
              _c("grid-layout", {
                ref: "gridLayout",
                attrs: {
                  tableOptions: _vm.tableOptions,
                  tableData: _vm.detailData.basisAreaUserList,
                  tableLoading: false,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "count-text" }, [
            _vm._v("危险因素辨识巡检表：一共有"),
            _c("span", { staticClass: "yellow" }, [
              _vm._v(_vm._s(_vm.detailData.hiddenSum)),
            ]),
            _vm._v("项危险因素其中 合格"),
            _c("span", { staticClass: "blue" }, [
              _vm._v(_vm._s(_vm.detailData.qualifiedNum)),
            ]),
            _vm._v("项 不合格"),
            _c("span", { staticClass: "red" }, [
              _vm._v(_vm._s(_vm.detailData.unqualifiedNum)),
            ]),
            _vm._v("项"),
          ]),
          _c(
            "div",
            { staticClass: "inspection-info-contianer" },
            [
              _c("p", { staticClass: "pub-title" }, [_vm._v("巡检信息")]),
              _vm._l(_vm.detailData.lineList, function (item) {
                return _c("div", { key: item.id, staticClass: "info-item" }, [
                  _c(
                    "div",
                    { staticClass: "info-name" },
                    [
                      _c("label", [_vm._v(_vm._s(item.inspectionObject))]),
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: item.checkStatus,
                            callback: function ($$v) {
                              _vm.$set(item, "checkStatus", $$v)
                            },
                            expression: "item.checkStatus",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "正常" } }, [
                            _vm._v("合格"),
                          ]),
                          _c("el-radio", { attrs: { label: "异常" } }, [
                            _vm._v("不合格"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "standard-container" }, [
                    _c("p", [_vm._v(_vm._s(item.inspectionContent))]),
                    item.checkStatus == "异常"
                      ? _c("div", { staticClass: "standard-text" }, [
                          _c("label", [_vm._v("不符合项描述：")]),
                          _c("span", [_vm._v(_vm._s(item.inspectionOpinion))]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "standard-img" },
                      [
                        _c("label", [_vm._v("照片：")]),
                        item.photos
                          ? _vm._l(
                              JSON.parse(item.photos),
                              function (obj, index) {
                                return _c("el-image", {
                                  key: index,
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: {
                                    src: obj.link,
                                    "preview-src-list": [obj.link],
                                  },
                                })
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
          !_vm.detailData.unqualifiedNum
            ? _c(
                "div",
                { staticClass: "img-list-container" },
                [
                  _c("p", { staticClass: "pub-title" }, [
                    _vm._v("巡检车间形象照片"),
                  ]),
                  _vm.detailData.filePhotos
                    ? _vm._l(
                        JSON.parse(_vm.detailData.filePhotos),
                        function (item, index) {
                          return _c("el-image", {
                            key: index,
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: item.link,
                              "preview-src-list": [item.link],
                            },
                          })
                        }
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }