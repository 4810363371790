var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "库存", name: "inventory" } }),
          _c("el-tab-pane", {
            attrs: { label: "出入库记录", name: "inOutManagement" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "inventory",
              expression: "activeName == 'inventory'",
            },
          ],
          staticClass: "inventory-content",
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headListOptions,
                  "head-title": "危化品库存情况",
                },
                on: { "head-add": _vm.clickcwDialog },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.listLoading,
                      expression: "listLoading",
                      modifiers: { body: true },
                    },
                  ],
                  staticClass: "list",
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _vm._l(_vm.list, function (item, index) {
                        return _c(
                          "el-col",
                          { key: item.id, attrs: { span: 8 } },
                          [
                            _c(
                              "el-card",
                              {
                                class: item.selected
                                  ? "list-card-selected list-card"
                                  : "list-card",
                                attrs: { "body-style": { padding: "10px" } },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "content",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleItemClick(index, item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-image",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "200px",
                                        },
                                        attrs: { src: item.imgUrl, fit: "fit" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.showFile(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%",
                                              display: "flex",
                                              "justify-content": "center",
                                              "align-items": "center",
                                              "flex-direction": "column",
                                            },
                                            attrs: { slot: "error" },
                                            slot: "error",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-picture-outline",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      暂无图片\n                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "top",
                                          content: item.itemName,
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "name" }, [
                                          _vm._v(_vm._s(item.itemName)),
                                        ]),
                                      ]
                                    ),
                                    _c("el-divider", {
                                      staticClass: "el-divider",
                                    }),
                                    _c("div", { staticClass: "bottom" }, [
                                      _c("div", { staticClass: "num" }, [
                                        _vm._v(
                                          "\n                    库存量:" +
                                            _vm._s(item.quantity) +
                                            _vm._s(item.unitOfMeasure) +
                                            "\n                  "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "btn" }, [
                                        _c("i", {
                                          staticClass: "el-icon-edit",
                                          staticStyle: {
                                            "margin-right": "8px",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editItem(item)
                                            },
                                          },
                                        }),
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.removeList(item)
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "up-card",
                              attrs: { "body-style": { padding: "10px" } },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upload",
                                  on: { click: _vm.addList },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus",
                                    staticStyle: { "font-size": "80px" },
                                  }),
                                  _c("div", [_vm._v("添加")]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "right" }, [
            _c(
              "div",
              { staticClass: "detail" },
              [
                _c("head-layout", {
                  attrs: {
                    "head-btn-options": _vm.headDetailOptions,
                    "head-title": "危化品详情",
                  },
                  on: {
                    "head-use": _vm.headUse,
                    "head-purchase": _vm.headPurchase,
                    "head-return": _vm.headReturn,
                    "head-handle": _vm.headHandle,
                  },
                }),
                _c(
                  "el-form",
                  { attrs: { model: _vm.formDetail, "label-width": "100px" } },
                  [
                    _c("el-form-item", { attrs: { label: "危化品名称:" } }, [
                      _c("div", [_vm._v(_vm._s(_vm.formDetail.itemName))]),
                    ]),
                    _c("el-form-item", { attrs: { label: "规格型号:" } }, [
                      _c("div", [_vm._v(_vm._s(_vm.formDetail.spec))]),
                    ]),
                    _c("el-form-item", { attrs: { label: "负责人1:" } }, [
                      _c("div", [_vm._v(_vm._s(_vm.formDetail.userName))]),
                    ]),
                    _c("el-form-item", { attrs: { label: "负责人2:" } }, [
                      _c("div", [_vm._v(_vm._s(_vm.formDetail.userNameT))]),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "易制毒制爆" } },
                      [
                        _c("el-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.formDetail.easyFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formDetail, "easyFlag", $$v)
                            },
                            expression: "formDetail.easyFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-form-item", { attrs: { label: "库存量:" } }, [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.formDetail.quantity) +
                            _vm._s(_vm.formDetail.unitOfMeasure)
                        ),
                      ]),
                    ]),
                    _c("el-form-item", { attrs: { label: "单次领用上限:" } }, [
                      _c("div", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.formDetail.issueLimit) +
                            _vm._s(_vm.formDetail.unitOfMeasure) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _c("el-form-item", { attrs: { label: "安全使用规范:" } }, [
                      _c("div", [_vm._v(_vm._s(_vm.formDetail.remark))]),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "安全标识:" } },
                      _vm._l(
                        _vm.formDetail.dmItemIconsList,
                        function (cItem, ci) {
                          return _c("el-image", {
                            key: ci,
                            staticClass: "img-item",
                            staticStyle: {
                              width: "100px",
                              height: "100px",
                              margin: "10px 10px 0 0",
                            },
                            attrs: {
                              src: cItem.link,
                              "preview-src-list": _vm.listUrls(
                                _vm.formDetail.dmItemIconsList
                              ),
                            },
                          })
                        }
                      ),
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "技术说明书:" } },
                      [
                        _c("el-upload", {
                          class: { uoloadSty: _vm.showImg, disUoloadSty: true },
                          attrs: {
                            disabled: "",
                            action:
                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                            accept: ".doc,.docx,.pdf,.zip,.png,.jpg,.rar",
                            limit: 1,
                            "file-list": _vm.detailArray,
                            headers: _vm.headers,
                            "on-success": _vm.uploadSuccess,
                            "on-remove": _vm.handleFileRemove,
                            "on-exceed": _vm.uploadExceed,
                            "on-preview": (file) => {
                              return _vm.showFile(file, "link")
                            },
                            "before-upload": _vm.handleBeforeUpload,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "list" },
              [
                _c("head-layout", {
                  attrs: { "head-btn-options": [], "head-title": "库存批次" },
                }),
                _c("grid-layout", {
                  ref: "gridLayOut",
                  attrs: {
                    "table-options": _vm.kctableOption,
                    "table-data": _vm.kctableData,
                    "table-loading": _vm.kctableLoading,
                    "data-total": _vm.kcPage.total,
                    page: _vm.kcPage,
                  },
                  on: {
                    "grid-row-detail-click": _vm.kcrowView,
                    "page-current-change": _vm.getkcList,
                    "page-size-change": _vm.getkcList,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "vendorName",
                      fn: function ({ row }) {
                        return [
                          _c("div", { staticClass: "vendorName" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(row.dmItemVendor.vendorName) +
                                "\n            "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "inOutManagement",
              expression: "activeName == 'inOutManagement'",
            },
          ],
          staticClass: "inOut-content",
        },
        [
          _c("head-layout", {
            attrs: {
              "head-title": "出入库记录",
              "head-btn-options": _vm.headBtnOptions,
            },
            on: { "head-romve": _vm.headRmove, "head-add": _vm.headAdd },
          }),
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: { "search-columns": _vm.searchColumns },
            on: {
              "grid-head-search": _vm.gridInoutHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.tableOption,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.inoutPage.total,
              page: _vm.inoutPage,
            },
            on: {
              "grid-row-detail-click": _vm.rowView,
              "gird-handle-select-click": _vm.selectionChange,
              "page-current-change": _vm.getInOutList,
              "page-size-change": _vm.getInOutList,
            },
            scopedSlots: _vm._u([
              {
                key: "recptStatus",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          row.recptStatus == "FINISHED" ? "完成" : "草稿"
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    row.recptStatus != "FINISHED" &&
                    row.createUser == _vm.userInfo.id
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.rowEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    row.recptStatus != "FINISHED" &&
                    row.createUser == _vm.userInfo.id
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.rowRemove(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowView(row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: { title: "危化品出入库", visible: _vm.kcDialog },
          on: {
            "update:visible": function ($event) {
              _vm.kcDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dmItemType,
                    callback: function ($$v) {
                      _vm.dmItemType = $$v
                    },
                    expression: "dmItemType",
                  },
                },
                _vm._l(_vm.dmItemTypeList, function (item) {
                  return _c(
                    "el-radio",
                    { key: item.id, attrs: { label: item.dictKey } },
                    [_vm._v(_vm._s(item.dictValue))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.kcDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.kcDialogRoute },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: { title: "储位维护", visible: _vm.cwDialog },
          on: {
            "update:visible": function ($event) {
              _vm.cwDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "content cwDialog",
              staticStyle: { width: "100%", height: "540px" },
            },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "储位维护",
                  "head-btn-options": _vm.headCwOptions,
                },
                on: {
                  "head-remove": _vm.cwheadRmove,
                  "head-add": _vm.cwheadAdd,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.cwtableOption,
                  "table-data": _vm.cwtableData,
                  "table-loading": _vm.cwtableLoading,
                  "data-total": _vm.cwPage.total,
                  page: _vm.cwPage,
                },
                on: {
                  "grid-row-detail-click": _vm.cwrowView,
                  "gird-handle-select-click": _vm.cwselectionChange,
                  "page-current-change": _vm.cwonLoad,
                  "page-size-change": _vm.cwonLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.cwrowEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.cwrowRemove(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.cwrowQRcode(row)
                              },
                            },
                          },
                          [_vm._v("二维码")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.cwDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: { title: "储位维护", visible: _vm.cwaddDialog },
          on: {
            "update:visible": function ($event) {
              _vm.cwaddDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "cwForm",
                  attrs: {
                    model: _vm.cwForm,
                    "label-width": "100px",
                    rules: _vm.cwrules,
                    disabled: _vm.cwaddType == "view",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "储位编号",
                                prop: "warehosueCode",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    maxlength: "64",
                                    "show-word-limit": "",
                                    placeholder: "请输入储位编号",
                                  },
                                  model: {
                                    value: _vm.cwForm.warehosueCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cwForm, "warehosueCode", $$v)
                                    },
                                    expression: "cwForm.warehosueCode",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "append" },
                                      slot: "append",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  自动生成\n                  "
                                      ),
                                      _c("el-switch", {
                                        on: { change: _vm.handleSwitch },
                                        model: {
                                          value: _vm.getcwCode,
                                          callback: function ($$v) {
                                            _vm.getcwCode = $$v
                                          },
                                          expression: "getcwCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "储位名称",
                                prop: "warehosueName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "255",
                                  "show-word-limit": "",
                                  placeholder: "请输入储位名称",
                                },
                                model: {
                                  value: _vm.cwForm.warehosueName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cwForm, "warehosueName", $$v)
                                  },
                                  expression: "cwForm.warehosueName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "位置", prop: "location" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入储存位置" },
                                model: {
                                  value: _vm.cwForm.location,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cwForm, "location", $$v)
                                  },
                                  expression: "cwForm.location",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.cwaddDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addcwList } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: {
            title: _vm.listAddtype == "add" ? "新增" : "编辑",
            visible: _vm.listDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.listDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "危化品图片", prop: "imgUrl" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "uploadRef",
                              attrs: {
                                action:
                                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
                                "show-file-list": true,
                                limit: 1,
                                "before-upload": _vm.checkFileType,
                                "on-exceed": _vm.handleExceedImgUrl,
                                "on-success": _vm.handleimgUrlSuccess,
                                "list-type": "picture-card",
                                accept: "image/*",
                                "file-list": _vm.imgUrlList,
                                multiple: false,
                                "on-remove": _vm.handleRemove,
                                "on-preview": _vm.handlePreview,
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "危化品名称", prop: "itemName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入危化品名称",
                              maxlength: "255",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.itemName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "itemName", $$v)
                              },
                              expression: "form.itemName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "规格型号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入规格型号",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.spec,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "spec", $$v)
                              },
                              expression: "form.spec",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "易制毒制爆" } },
                        [
                          _c("el-switch", {
                            on: { change: _vm.easyFlagClick },
                            model: {
                              value: _vm.form.easyFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "easyFlag", $$v)
                              },
                              expression: "form.easyFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "负责人1", prop: "userName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请选择人员" },
                            on: {
                              focus: function ($event) {
                                return _vm.handleUserModeOpen(1)
                              },
                            },
                            model: {
                              value: _vm.form.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userName", $$v)
                              },
                              expression: "form.userName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "负责人2", prop: "userName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请选择人员" },
                            on: {
                              focus: function ($event) {
                                return _vm.handleUserModeOpen(2)
                              },
                            },
                            model: {
                              value: _vm.form.userNameT,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userNameT", $$v)
                              },
                              expression: "form.userNameT",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "单次领用上限", prop: "issueLimit" },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              min: 1,
                              max: 99999999,
                              placeholder: "单次领用上限",
                            },
                            on: { input: _vm.handleInput },
                            model: {
                              value: _vm.form.issueLimit,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "issueLimit", $$v)
                              },
                              expression: "form.issueLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位", prop: "unitOfMeasure" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "单位" },
                            model: {
                              value: _vm.form.unitOfMeasure,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "unitOfMeasure", $$v)
                              },
                              expression: "form.unitOfMeasure",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "默认储位",
                            prop: "defaultWarehouseId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择默认储位",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.defaultWarehouseId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "defaultWarehouseId", $$v)
                                },
                                expression: "form.defaultWarehouseId",
                              },
                            },
                            _vm._l(
                              _vm.defaultWarehouseOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.warehosueName,
                                    value: item.id,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "安全使用规范" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "500",
                              "show-word-limit": "",
                              type: "textarea",
                              placeholder: "请输入安全使用规范",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "安全标识" } },
                        [
                          _c(
                            "div",
                            { staticClass: "mark-list" },
                            _vm._l(_vm.dmItemIconsList, function (cItem, ci) {
                              return _c(
                                "div",
                                {
                                  key: ci,
                                  staticClass: "div-item",
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    margin: "10px 10px 0 0",
                                    display: "inline-block",
                                    position: "relative",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-remove",
                                    staticStyle: {
                                      "font-size": "20px",
                                      color: "#ff0000",
                                      "z-index": "100",
                                      position: "absolute",
                                      right: "-4px",
                                      top: "-4px",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeSiList(ci)
                                      },
                                    },
                                  }),
                                  _c("el-image", {
                                    staticClass: "img-item",
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      margin: "10px 10px 0 0",
                                    },
                                    attrs: {
                                      src: cItem.link,
                                      "preview-src-list": _vm.listUrls(
                                        _vm.dmItemIconsList
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.safeClick },
                            },
                            [_vm._v("引用")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "技术说明书" } },
                        [
                          _c(
                            "el-upload",
                            {
                              class: {
                                uoloadSty: _vm.showImg,
                                disUoloadSty: _vm.noneUploadFile,
                              },
                              attrs: {
                                disabled: _vm.pageDisabled,
                                action:
                                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                accept: ".doc,.docx,.pdf,.zip,.png,.jpg,.rar",
                                limit: 1,
                                "file-list": _vm.fileArray,
                                headers: _vm.headers,
                                "on-success": _vm.uploadSuccess,
                                "on-remove": _vm.handleFileRemove,
                                "on-exceed": _vm.uploadExceed,
                                "on-preview": (file) => {
                                  return _vm.showFile(file, "link")
                                },
                                "before-upload": _vm.handleBeforeUpload,
                              },
                            },
                            [
                              _c("el-button", { attrs: { size: "small" } }, [
                                _c("i", { staticClass: "el-icon-upload2" }),
                                _vm._v(" 上传文件"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "\n                支持扩展名: .zip,.rar,.zip,.doc,.docx,.pdf,.png,.jpg\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.saveLoading,
                      expression: "saveLoading",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      _vm.listDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.saveLoading,
                      expression: "saveLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.saveList },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.safeShow
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              staticClass: "qmDialog",
              attrs: {
                title: "安全标识",
                modal: false,
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.safeShow,
                width: "80%",
                fullscreen: _vm.fullscreen,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.safeShow = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { height: "500px" } },
                [
                  _c("SafeSelect", {
                    on: {
                      "select-data": _vm.safeSelectData,
                      "close-dialog": _vm.closeSafe,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.personShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.personShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c("fileView", {
        ref: "fileView",
        attrs: { title: "危化品相关文件预览" },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.imageVisible,
            width: "60%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.imageVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: {
              "margin-top": "20px",
              width: "100%",
              height: "auto",
              display: "block",
            },
            attrs: { src: _vm.imageUrl, alt: "" },
          }),
        ]
      ),
      _c("qrcodeDialog", { ref: "qrcodeDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }