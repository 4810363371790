<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        :popover-show="false"
        :expandOnClickNode="false"
        treeTitle="培训类型"
        @include-down="includeDown"
        @getNodeClick="treeNodeClick"
      />
      <el-main style="position: relative">
        <head-layout
          :head-btn-options="headBtnOptions"
          head-title="自定义课程"
          @head-remove="headRemove"
          @head-add-tabs="headAdd"
          @head-startUsing="headStartUsing"
          @head-loseEfficacy="headLoseEfficacy"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <div class="tableShowRadio">
          <el-radio v-model="tableRadio" label="2">卡片模式</el-radio>
          <el-radio v-model="tableRadio" label="1">列表模式</el-radio>
        </div>
        <card-layout ref="cardLayout"
                     v-if="tableRadio == '2'"
                     :card-loading="crudLoading"
                     :data-total="page.total"
                     :page="page"
                     :card-data="tableData"
                     :cardProps="cardProps"
                     @card-view="rowView"
                     @page-size-change="onLoad"
                     @page-current-change="onLoad"
        >
          <template slot-scope="scope">
            <div style="display: flex;
    align-items: center;width: 100%;" :style="scope.row.status == 2?'justify-content: space-between;':'justify-content:flex-end'">
              <el-button
                size="mini"
                type="primary"
                style="margin-right: 5px"
                v-if="scope.row.status == 2"
                @click="rowEdit(scope.row)">
                编辑
              </el-button>
              <el-switch
                v-model="scope.row.status == 1"
                active-text="启用"
                inactive-text="失效"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="isEnable(scope.row)">
              </el-switch>
            </div>
          </template>
        </card-layout>
        <grid-layout
          ref="gridLayOut"
          v-if="tableRadio == '1'"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          @row-remove="rowRemove"
          @row-edit="rowEdit"
        >
          <template #courseCover="{ row }">
            <img class="courseCoverImg" v-if="row.courseCover == ''" src="@/assets/training/trainingDefaultPicture.png">
            <img class="courseCoverImg" v-else :src="row.courseCover">
          </template>
          <template #customBtn="{row}">
            <el-button
              type="text"
              size="small"
              style="margin-right: 5px"
              v-if="row.status == 2"
              @click="rowEdit(row)">
              编辑
            </el-button>
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              size="small"-->
            <!--              v-if="row.status == 2||row.status == 3"-->
            <!--              @click="startUsing(row)">-->
            <!--              启用-->
            <!--            </el-button>-->
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              size="small"-->
            <!--              v-if="row.status == 1"-->
            <!--              @click="loseEfficacy(row)">-->
            <!--              失效-->
            <!--            </el-button>-->
            <el-switch
              v-model="row.status == 1"
              active-text="启用"
              inactive-text="失效"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="isEnable(row)">
            </el-switch>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {
  remove,
  saveList,
  getSafetyTrainingTree,
  removeSafetyTrainingTree,
  submitSafetyTrainingTree
} from "@/api/knowledgeClassifcation/knowledge";
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import ServerNameEnum from "@/util/ServerNameEnum";
import {enableInvalidation, getTrainGroupScenePage} from "@/api/onTheJobTraining/training";
import CardLayout from "@/views/components/layout/card-layout";
import Template from "@/views/message/template/list";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      form: {},
      query: {},
      treeReadOnlyFlag: false,
      tableData: [],
      treeLoading: false,
      crudLoading: false,
      treeData: [],
      dicData: [],
      tableRadio: '2',
      selectionList: [],
      node: {}, //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      classifyId: '',
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "value",
        key: "key",
      },
      showDialog: false,
      formType: '',
      formList: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      cardProps: {
        imgUrl: 'courseCover',
        title: 'courseName',
        time: 'createTime'
      },
      type: 1,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        label: "新增",
        emit: "head-add-tabs",
        type: "button",
        icon: "",
        btnOptType: "add",
      });
      if (this.tableRadio == 1) {
        buttonBtn.push({
          label: "启用",
          emit: "head-startUsing",
          type: "button",
          icon: "",
          btnOptType: "loseEfficacy",
        });
        buttonBtn.push({
          label: "失效",
          emit: "head-loseEfficacy",
          type: "button",
          icon: "",
          btnOptType: "loseEfficacy",
        });
      }
      return buttonBtn
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        selection: true,
        linklabel: "courseName",
        column: [
          {
            label: "名称",
            prop: "courseName",
            align: "left",
            width: 240,
            overHidden: true,
          },
          {
            label: "课程",
            prop: 'courseCover',
            slot: true,
            width: 120
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=site_training_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "创建人",
            prop: "userName",
            align: "center",
            width: 150,
            overHidden: true,
          },
          {
            label: "创建单位",
            prop: "deptName",
            align: "center",
            width: 280,
            overHidden: true,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            align: "center",
            width: 240,
            overHidden: true
          },
        ],
      };
    },
    gridHeadBtn() {
      return [];
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "courseName",
          span: 4,
          placeholder: "请输入名称",
        },
        {
          label: "状态",
          prop: "status",
          align: "center",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=site_training_status",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 4,
          placeholder: "请选择状态",
        },
        {
          prop: 'whenIsCreatedDate',
          type: 'datetimerange',
          span: 5,
          startPlaceholder: "创建开始时间",
          endPlaceholder: "创建结束时间",
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
      ];
    },
    gridRowBtn() {
      return [
        // {
        //   label: "编辑",
        //   emit: "row-edit",
        //   type: "button",
        //   icon: "",
        // },

      ];
    },
    treeDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "场景名称",
            prop: "trainingName",
            placeholder: "请输入场景名称",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入场景名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "父场景名称",
            prop: "parentId",
            type: "tree",
            props: {
              label: "title",
              value: "value",
              key: "key",
            },
            placeholder: "父场景名称",
            dicUrl: `${ServerNameEnum.SERVER_TRAINING_}/safetyTrainingTree/tree`,

          },
        ],
      };
    },
    dialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "cateLineName",
            placeholder: "请输入分类名称",
            disabled: !['edit', 'add'].includes(this.formType),
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "分类编号",
            prop: "cateLineCode",
            placeholder: "请输入分类编号",
            maxlength: 20,
            disabled: !['edit', 'add'].includes(this.formType),
            rules: [
              {
                required: true,
                message: "请输入分类编号",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "描述",
            prop: "remark",
            type: "textarea",
            rows: 3,
            span: 24,
            placeholder: "请输入描述",
            maxlength: 200,
            showWordLimit: true,
            disabled: !['edit', 'add'].includes(this.formType),
            rules: [
              {
                required: false,
                message: "请输入描述",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
        ],
      };
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    CardLayout,
    Template
  },
  created() {
    this.initTree()
  },
  mounted() {
    this.classifyId = ''
    this.$refs.gridHeadLayout.searchForm.status = "1";
  },
  methods: {
    isEnable(row) {
      if (row.status == 1) {
        this.loseEfficacy(row)
      } else if (row.status == 2 || row.status == 3) {
        this.startUsing(row)
      } else {
        this.$message.warning("数据状态错误！")
      }
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    // 左侧树新增
    treeMenuAdd(data) {
      this.form = {};
      this.form.parentId = data.id;
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },

    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },
    initTree() {
      getSafetyTrainingTree()
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
          this.onLoad(this.page);
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    // 新增左侧树节点
    treeNodeSave(data, done) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$loading();
          if (this.form.parentId == "") {
            this.form.parentId = 0
          }
          submitSafetyTrainingTree(this.form)
            .then((res) => {
              const {msg, data} = res.data;
              this.treeReadOnlyFlag = true;
              this.$message.success(msg);
              this.initTree();
              this.showTreeDialog = false;
            }).finally(() => {
            this.$loading().close();
          })
            .catch((err) => {
              done();
            });
        }
      });
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren && data.children.length != 0) {
        this.$message({
          type: "warning",
          message: "当前分类下有子级，禁止删除",
        });
        return;
      }
      this.$confirm("确认删除数据", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeSafetyTrainingTree(data.id).then((res) => {
            if (res.data.data) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.initTree();
            } else {
              this.$message.warning("删除失败，当前知识库分类下绑定有维度分类！")
            }
          });
        })
        .catch(() => {
        });
    },

    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      this.query.trainContentId = this.node.id;
      this.classifyId = this.node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    //知识分类新增
    closeDialog() {
      this.formList = {};
      this.$refs.addFormList.resetForm();
      this.showDialog = false
    },
    handleNodeSave() {
      this.$refs.addFormList.submit();
    },
    saveList(data, done) {
      this.$refs.addFormList.validate((valid) => {
        if (valid) {
          this.$loading();
          let params = {
            ...this.formList,
            cateCode: this.node.cateCode,
            cateEn: this.node.cateEn,
            cateName: this.node.cateName,
            cateId: this.node.id,
            knowledgeClassify: this.node.knowledgeClassify
          }
          if (this.formList.id) {
            params.id = this.formList.id
          }
          saveList(params)
            .then((res) => {
              const {msg, data} = res.data;
              this.$message.success(msg);
              this.formList = {};
              this.$refs.addFormList.resetForm();
              this.onLoad(this.page);
              this.showDialog = false;
            }).finally(() => {
            this.$loading().close();
          })
            .catch((err) => {
              done();
            });
        }
      });
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("确定将选择数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove(this.ids).then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.selectionClear();
          });
        })
        .catch(() => {
        });
    },

    rowRemove(row) {
      this.$confirm("确定删除数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove(row.id).then((res) => {
            if (res.data.data) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: "删除成功",
              });
            } else {
              this.$message.warning("删除失败，当前维度下绑定有子知识分类！")
            }
          });
        })
    },
    rowEdit(row) {
      this.$router.push({
        path: '/business/knowledge/onTheJobTrainingScene/add',
        query: {
          row: JSON.stringify(row),
          type: 'edit',
        }
      });
    },
    rowView(row) {
      this.$router.push({
        path: '/business/knowledge/onTheJobTrainingScene/add',
        query: {
          row: JSON.stringify(row),
          type: 'view',
        }
      });
    },
    enabledOrDisabled(id, status) {
      enableInvalidation(id, status).then(res => {
        this.onLoad(this.page);
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      })
    },
    startUsing(row) {
      if (row.totalHours < row.meetLesson) {
        return this.$message({
          type: "warning",
          message: "课程的达标课时不能超过合计课时",
        });
      }
      this.$confirm(row.status == 3 ? "确认是否启用课程" : "确认是否启用课程，一旦启用不可编辑", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.enabledOrDisabled(row.id, 1)
        })
    },
    loseEfficacy(row) {
      this.$confirm("确认需要失效吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.enabledOrDisabled(row.id, 3)
        })
    },
    /**
     * 启用
     */
    headStartUsing() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      for (let i = 0; i < this.selectionList.length; i++) {
        let row = this.selectionList[i];
        if (row.totalHours < row.meetLesson) {
          return this.$message({
            type: "warning",
            message: "[" + row.courseName + "]课程的达标课时不能超过合计课时",
          });
        }
      }
      this.$confirm("确认是否启用课程", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = this.selectionList.map(item => item.id);
          this.enabledOrDisabled(ids.join(","), 1)
        })
    },
    /**
     * 失效
     */
    headLoseEfficacy() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      var ids = this.selectionList.map(item => item.id);
      this.$confirm("确认需要失效吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.enabledOrDisabled(ids.join(","), 3)
        })
    },
    // 新增
    headAdd() {

      this.$router.push({
        path: "/business/knowledge/onTheJobTrainingScene/add",
        query: {
          type: "add",
          classifyId: this.classifyId,
          trainContentId: this.node.id,
          trainContentName: this.node.trainingName
        },
      });
    },

    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    gridHeadEmpty() {
      this.query = {};
      this.initTree()
      this.onLoad(this.page);
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params) {
      this.page = page;
      this.crudLoading = true;
      this.query.type = 2
      this.query.createDept = this.userInfo.dept_id
      // if((params.status == "" || typeof params.status =="undefined")){
      //   this.query.status = null
      // }else {
      //   this.query.status = params.status
      // }
      // if(this.type == 1){
      //   this.query.status = 1
      // }
      let searchForm = this.$refs.gridHeadLayout.searchForm;
      if (searchForm.whenIsCreatedDate) {
        searchForm.startTime = searchForm.whenIsCreatedDate[0]
        searchForm.endTime = searchForm.whenIsCreatedDate[1]
      }
      getTrainGroupScenePage(
        page.currentPage,
        page.pageSize,
        Object.assign(searchForm, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          if (this.tableRadio == '1') {
            this.$refs.gridLayOut.page.total = data.total;
          }
          this.page.total = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
          this.type = 2
          this.selectionClear();
        })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
  },
};
</script>
<style scoped>
.tableShowRadio {
  position: absolute;
  right: 12px;
  top: 62px;
}

.courseCoverImg {
  width: 80px;
  padding: 5px;
}
</style>


