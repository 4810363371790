var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outWardBox" },
    _vm._l(_vm.cardData, function (item, index) {
      return _c("div", { key: index, staticClass: "inWardBox" }, [
        _c("div", { staticStyle: { padding: "16px" } }, [
          item.terminalName != null
            ? _c("span", { staticClass: "titleBox" }, [
                _vm._v(_vm._s(item.terminalName)),
              ])
            : _vm._e(),
          item.terminalName == "" || item.terminalName == null
            ? _c("span", { staticClass: "titleBox" }, [_vm._v(" ")])
            : _vm._e(),
          _c("div", { staticStyle: { margin: "30px 0" } }, [
            _c("div", { staticClass: "contentBox" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t(`cip.cps.monitoring.field.ipLocation`)) +
                  "：" +
                  _vm._s(item.ipLocation) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "contentBox" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t(`cip.cps.monitoring.field.clientId`)) +
                  "：" +
                  _vm._s(item.terminalCoding) +
                  "\n        "
              ),
            ]),
            item.heartbeatTopic <= 0
              ? _c("div", { staticClass: "contentBox" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(`cip.cps.monitoring.field.heartbeatTopic`)
                      ) +
                      "：0 " +
                      _vm._s(_vm.$t(`cip.cps.monitoring.field.second`)) +
                      "\n        "
                  ),
                ])
              : _c("div", { staticClass: "contentBox" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(`cip.cps.monitoring.field.heartbeatTopic`)
                      ) +
                      "：" +
                      _vm._s(item.heartbeatTopic) +
                      " " +
                      _vm._s(_vm.$t(`cip.cps.monitoring.field.second`)) +
                      "\n        "
                  ),
                ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "bottomBox",
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            item.isStatus == 1
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "15px",
                      display: "flex",
                      "line-height": "28px",
                    },
                  },
                  [
                    _c("div", { staticClass: "stateBox" }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t(`cip.cps.monitoring.field.break`)) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            item.isStatus == 2
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "15px",
                      display: "flex",
                      "line-height": "28px",
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "stateBox",
                      staticStyle: { "background-color": "#00a680" },
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t(`cip.cps.monitoring.field.connect`)) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              [
                _vm.permission.monitoring_enable
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "4px" },
                        attrs: { round: "", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.rowEdit(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.editBtn")))]
                    )
                  : _vm._e(),
                item.isStatus == "1" && _vm.permission.monitoring_invalidBtn
                  ? _c(
                      "el-button",
                      {
                        attrs: { round: "", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.valid(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("cip.cmn.btn.validBtn")) + "\n        "
                        ),
                      ]
                    )
                  : _vm.permission.monitoring_editBtn
                  ? _c(
                      "el-button",
                      {
                        attrs: { round: "", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.invalid(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("cip.cmn.btn.invalidBtn")) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }