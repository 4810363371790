var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "board", attrs: { id: "board" } }, [
    _c("div", { staticClass: "block-div h-200" }, [
      _c("div", { staticClass: "title" }, [_vm._v("项目人员")]),
      _c("div", { staticClass: "content flex" }, [
        _c("div", { staticClass: "flex-cloum w-25" }, [
          _c("div", { staticClass: "num" }, [
            _vm._v("\n          " + _vm._s(_vm.dataForm.value1)),
            _c("span", { staticClass: "unit" }, [_vm._v("人")]),
          ]),
          _c("div", { staticClass: "name" }, [_vm._v("在建项目总人数")]),
        ]),
        _c("div", { staticClass: "flex-cloum w-25" }, [
          _c("div", { staticClass: "num" }, [
            _vm._v("\n          " + _vm._s(_vm.dataForm.value2)),
            _c("span", { staticClass: "unit" }, [_vm._v("人")]),
          ]),
          _c("div", { staticClass: "name" }, [_vm._v("企业总人数")]),
        ]),
        _c("div", { staticClass: "flex-cloum w-25" }, [
          _c("div", { staticClass: "num" }, [
            _vm._v("\n          " + _vm._s(_vm.dataForm.value3)),
            _c("span", { staticClass: "unit" }, [_vm._v("人")]),
          ]),
          _c("div", { staticClass: "name" }, [_vm._v("安全管理人员")]),
        ]),
        _c("div", { staticClass: "flex-cloum w-25" }, [
          _c("div", { staticClass: "num" }, [
            _vm._v("\n          " + _vm._s(_vm.dataForm.value4)),
            _c("span", { staticClass: "unit" }, [_vm._v("人")]),
          ]),
          _c("div", { staticClass: "name" }, [_vm._v("第三方总人数")]),
        ]),
      ]),
    ]),
    _vm._m(0),
    _c("div", { staticClass: "flex-div m-t-20" }, [
      _c("div", { staticClass: "flex-cloum-div w-50" }, [
        _c("div", { staticClass: "block-div w-100 h-50" }, [
          _c("div", { staticClass: "title" }, [_vm._v("培训次数")]),
          _c("div", { staticClass: "content flex" }, [
            _c("div", { staticClass: "num" }, [
              _vm._v("\n            " + _vm._s(_vm.dataForm.value9)),
              _vm.dataForm.value9
                ? _c("span", { staticClass: "unit" }, [_vm._v("次")])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "block-div m-t-20 w-100 h-50" }, [
          _c("div", { staticClass: "title" }, [_vm._v("培训人数")]),
          _c("div", { staticClass: "content flex" }, [
            _c("div", { staticClass: "num" }, [
              _vm._v("\n            " + _vm._s(_vm.dataForm.value10)),
              _vm.dataForm.value10
                ? _c("span", { staticClass: "unit" }, [_vm._v("人")])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._m(1),
    ]),
    _vm._m(2),
    _c("div", { staticClass: "flex-div m-t-20" }, [
      _c("div", { staticClass: "block-div w-100" }, [
        _c("div", { staticClass: "title" }, [_vm._v("隐患排查")]),
        _c("div", { staticClass: "content flex" }, [
          _c("div", { staticClass: "flex-cloum w-25" }, [
            _c("div", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.dataForm.value5)),
            ]),
            _c("div", { staticClass: "name" }, [_vm._v("安全检查次数")]),
          ]),
          _c("div", { staticClass: "flex-cloum w-25" }, [
            _c("div", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.dataForm.value6)),
            ]),
            _c("div", { staticClass: "name" }, [_vm._v("隐患数量")]),
          ]),
          _c("div", { staticClass: "flex-cloum w-25" }, [
            _c("div", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.dataForm.value7)),
            ]),
            _c("div", { staticClass: "name" }, [_vm._v("隐患完成数量")]),
          ]),
          _c("div", { staticClass: "flex-cloum w-25" }, [
            _c("div", { staticClass: "num" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.dataForm.value8 ? _vm.dataForm.value8 * 100 + "%" : "0%"
                  ) +
                  "\n          "
              ),
            ]),
            _c("div", { staticClass: "name" }, [_vm._v("隐患整改率")]),
          ]),
        ]),
      ]),
    ]),
    _vm._m(3),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-div m-t-20" }, [
      _c("div", { staticClass: "block-div w-50" }, [
        _c("div", { staticClass: "title" }, [_vm._v("企业人员年龄结构")]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "chart", attrs: { id: "chart-age" } }),
        ]),
      ]),
      _c("div", { staticClass: "block-div w-50" }, [
        _c("div", { staticClass: "title" }, [_vm._v("工种结构")]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "chart", attrs: { id: "chart-job" } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block-div w-50" }, [
      _c("div", { staticClass: "title" }, [_vm._v("培训覆盖率")]),
      _c(
        "div",
        { staticClass: "content", staticStyle: { "padding-right": "10px" } },
        [_c("div", { staticClass: "chart", attrs: { id: "chart-eighteen" } })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-div m-t-20" }, [
      _c("div", { staticClass: "block-div w-100" }, [
        _c("div", { staticClass: "title" }, [_vm._v("专项培训/入场培训")]),
        _c(
          "div",
          { staticClass: "content", staticStyle: { "padding-right": "10px" } },
          [_c("div", { staticClass: "chart", attrs: { id: "chart-train" } })]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-div m-t-20" }, [
      _c("div", { staticClass: "block-div w-100" }, [
        _c("div", { staticClass: "title" }, [_vm._v("作业许可数量")]),
        _c(
          "div",
          { staticClass: "content", staticStyle: { "padding-right": "10px" } },
          [_c("div", { staticClass: "chart", attrs: { id: "chart-task" } })]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }