<template>
  <div class="main-box riskIdentification">
    <div class="flex-container">
      <div class="table-box">
        <head-layout :head-btn-options="headBtnOptions " @head-cancel="headCancel" @head-save="headSave" @head-edit="headEdit" head-title="当前节点信息"></head-layout>
        <el-form class="form-base" ref="dataForm" :model="dataForm" label-width="160px" :disabled="formType == 'view'" :rules="rules">
          <el-col :span="8">
            <el-form-item label="编码" prop="code">
              <el-input v-model="dataForm.code" placeholder="请填写编码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input v-model="dataForm.name" placeholder="请填写名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类型" prop="type">
              <el-select v-model="dataForm.type" placeholder="请选择类型">
                <el-option v-for="item in NODE_TYPES_list" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="外系统编码" prop="outsideCode">
              <el-input v-model="dataForm.outsideCode" placeholder="请填写外系统编码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="外系统ID" prop="outsideId">
              <el-input v-model="dataForm.outsideId" placeholder="请填写外系统ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="请填写备注"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
    </div>

  </div>
</template>
<script>
  import CommonTree from "@/views/components/com_tree/index.vue";
  import Template from "@/views/message/template/list.vue";
  import * as API from "@/api/safetyTtandard/riskIdentification";
  import GridLayout from "@/views/components/layout/grid-layout";
  import {
    getById,
    edit,serverEdit
  } from "@/api/safe/algorithmManagement";
  import {
    NODE_TYPES
  } from '../nodeDict.js'
  export default {
    name: "index",
    components: {
      Template,
      GridLayout,
      CommonTree
    },
    props: {
      selectTree: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        activeName: 'base',
        selectTree: undefined,

        TableLoading: false,
        NODE_TYPES_list: [],
        tableData: [],
        treeData: [],
        query: {},
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        defaultProps: {
          children: "children",
          value: 'id',
          label: "sourceTypeName",
        },
        corePersonnelTreeData: [],

        dataForm: {},
        defaultExpandedKeys: [],
        formType: 'view'
      }
    },
    computed: {
      headBtnOptions() {
        let result = [];
        if (["edit"].includes(this.formType)) {
          result.push({
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          });
          result.push({
            label: "取消",
            emit: "head-cancel",
            type: "button",
            btnOptType: "cancel",
          });
        } else {
          result.push({
            label: "编辑",
            emit: "head-edit",
            type: "button",
            btnOptType: "edit",
          });
        }

        return result;
      },
    },
    watch: {
      selectTree: {
        immediate: true,
        handler: function (data) {
          if (data) this.getFormData();
        },
      },

    },
    mounted() {
      this.NODE_TYPES_list = NODE_TYPES
    },
    methods: {
      getFormData() {
        getById({
          id: this.selectTree.id
        }).then((res) => {
          this.formType = 'view'
          this.dataForm = res.data.data
        })
      },
      handleClick() {
        this.typeTree();
      },
      headEdit() {
        this.formType = 'edit'
      },
      headCancel() {
        this.formType = 'view'
      },
      headSave() {
        let data = {
          ...this.dataForm,
        };
        edit(data).then((res) => {
            this.$message.success('操作成功');
            this.getFormData();
          })
          .catch((err) => {
            done();
          });
      },
    },
  }

</script>
<style scoped lang="scss">
  .riskIdentification {
    background-color: #FFFFFF;

    .common_tree_handle .el-tree {
      height: calc(100vh - 278px) !important;
    }

    .table-box {
      width: 100%;
    }
  }

  .form-base {
    margin-top: 16px;
    margin-right: 10px;
  }

  ::v-deep .introduceTable .avue-crud .el-table {
    height: calc(100vh - 330px) !important;
    max-height: calc(100vh - 330px) !important;
  }

  .introduceTree ::v-deep .common_tree_handle .el-tree {
    height: calc(100vh - 278px) !important;
  }

  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }

  ::v-deep .el-tabs--border-card {
    border-left: none;
    border-bottom: none;
  }

  ::v-deep .el-tabs__content {
    padding: 0 !important;
  }

</style>
