<template>
    <div class="areaUserList">
      <!-- 项目区域-负责人员 -->
      <el-container class="areaUserList_container">
        <el-main>
          <head-layout
            head-title="人员清单"
            :showIcon="false"
            :head-btn-options="headBtnOptions"
            @head-add-tabs="headAdd"
            @head-remove="headRemove"
          />
          <!--  -->
          <grid-layout
            ref="gridLayOut"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @page-current-change="handleCurrentChange"
            @page-size-change="handleSizeChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
            :grid-row-btn="gridRowBtn"
            @row-change="rowChange"
          >
          </grid-layout>
        </el-main>
      </el-container>
    </div>
  </template>
  
  <script>
  import HeadLayout from "@/views/components/layout/head-layout.vue";
  import GridLayout from "@/views/components/layout/grid-layout.vue";
  import {prjAreaUserPage} from '@/api/safe/projectArea.js'

  import { mapGetters } from "vuex";
  import {getDictionaryCode} from '@/api/system/dictbiz.js'
  export default {
    components: {
      HeadLayout,
      GridLayout,
    },

    props: {
      areaId: {
        type: String,
        default: ''
      },
    },
  
    data() {
      return {
        tableData: [],
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        query: {},
        postList: []
      };
    },
  
    computed: {
      ...mapGetters(["permission", "colorName"]),
  
      tableOptions() {
        return {
        //   menu:this.$route.query.type != 'view',
          selection: false,
          column: [
            {
              label: "专业",
              prop: "postId",
              align: "center",
              overHidden: true,
              dicData: this.postList,
              // dicUrl: 
              props: {
                value: 'dictKey',
                label: 'dictValue',
              },
            },
            {
              label: "责任单位",
              prop: "dutyUnitName",
              align: "center",
              minWidth: 200,
              overHidden: true,
            },
            {
              label: "责任人",
              prop: "dutyUserName",
              align: "center",
              overHidden: true,
            },
            {
              label: "联系方式",
              prop: "dutyUserPhone",
              minlength: 120,
              align: "center",
              overHidden: true,
            },
            {
              label: "安全负责人",
              prop: "safetyUserName",
              minWidth: 100,
              align: "center",
              overHidden: true,
            },
            {
              label: "联系方式",
              prop: "safetyUserPhone",
              minlength: 120,
              align: "center",
              overHidden: true,
            },
            {
              label: "管理人员",
              prop: "manageUserName",
              align: "center",
              overHidden: true,
            },
            {
              label: "联系方式",
              prop: "manageUserPhone",
              minlength: 120,
              align: "center",
              overHidden: true,
            },
          ],
        };
      },
  
      gridRowBtn() {
          return [
            {
              label: '选择',
              emit: "row-change",
              type: "button",
              icon: "",
            },
          ];
      },
    },
  
    methods: {
      init (postDicCode) {
        // this.postDicCode = postDicCode
        // this.getDic()
        getDictionaryCode(postDicCode).then((res) => {
            this.postList = res.data.data;
        })
        this.onLoad(this.page)
      },
      // getDic () {
      //   this.postDicCode = this.deptSegment == 1 ? 'ST_MAJOR_CONS' : this.deptSegment == 2 ? 'ST_MAJOR_ENV' : this.deptSegment == 3 ? 'ST_MAJOR_MINE' : ''
      //   getDictionaryCode(this.postDicCode).then((res) => {
      //       this.postList = res.data.data;
      //   })
      // },
      /** 右侧表格操作 */
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.prjAreaId = this.areaId
        prjAreaUserPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query)
        ).then((res) => {
          const data = res.data.data;
          // this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total
          this.tableData = data.records;
          this.tableLoading = false;
          //重新渲染表格，解决勾选框错行问题
          this.$nextTick(() => {
            this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
          });
          this.$refs.gridLayOut.selectionClear();
        });
      },
      //页数改变
      handleCurrentChange(e) {
        this.page.currentPage = e.currentPage;
        this.onLoad(this.page);
      },
      //size改变
      handleSizeChange(e) {
        this.page.currentPage = 1;
        this.page.pageSize = e.pageSize;
        this.onLoad(this.page);
      },
      rowChange (row) {
        this.$emit('prjUserCallback', row)
      }

    },
  };
  </script>
  
  <style lang="scss" scoped>

  </style>
  