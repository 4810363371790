<template>
  <div style="display: flex;justify-content: space-between">
    <div class="treeBox">
      <div class="treeTitle">
        分类
      </div>
      <avue-tree :option="treeOption" ref="avueTree" :data="treeData" @node-click="nodeClick"/>
    </div>
    <div class="rightFormBox">
      <head-layout head-title="行政区划"
                   :head-btn-options="headBtnOptions"
                   @head-add="handleAdd"
                   @head-addChild="handleAddChild"
                   @head-export="handleExport"
                   @head-delete="handleDelete"
                   @head-import="handleImport"></head-layout>
      <div style="padding: 14px">
        <avue-form ref="form" :option="regionOption" v-model="regionForm" @submit="handleSubmit">
          <template slot="code" slot-scope="{}">
            <el-input placeholder="请输入 区划子编号" v-model="regionForm.subCode" :disabled="subCodeDis">
              <template slot="prepend">{{ regionForm.ancestors }}</template>
            </el-input>
          </template>
        </avue-form>
      </div>
      <el-dialog title="行政区划数据导入"
                 append-to-body
                 :visible.sync="excelBox"
                 width="555px">
        <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter">
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              点击下载<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </el-dialog>
      <el-dialog title="行政区划数据调试"
                 append-to-body
                 :visible.sync="debugBox"
                 width="350px">
        <avue-form :option="debugOption" v-model="debugForm"/>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getLazyTree, getDetail, submit, remove} from "@/api/base/region";
import {exportBlob} from "@/api/common";
import {mapGetters} from "vuex";
import {validatenull} from "@/util/validate";
import {downloadXls} from "@/util/util";
import {dateNow} from "@/util/date";
import {getToken} from "@/util/auth";
import NProgress from 'nprogress';
import HeadLayout from "@/views/components/layout/head-layout";
import 'nprogress/nprogress.css';
import {getDictionary} from "@/api/system/dict";

export default {
  components: {HeadLayout},
  data() {
    return {
      topCode: '10',
      treeCode: '',
      subCodeDis: true,
      formDis: true,
      treeParentCode: '',
      treeData: [],
      treeOption: {
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentCode = (node.level === 0) ? "10" : node.data.id;
          getLazyTree(parentCode).then(res => {
            resolve(res.data.data.map(item => {
              return {
                ...item,
                leaf: !item.hasChildren
              }
            }))
          });
        },
        addBtn: false,
        menu: false,
        size: 'small',
        props: {
          labelText: '标题',
          label: 'title',
          value: 'value',
          children: 'children'
        }
      },
      regionForm: {},
      initialForm:{},
      excelBox: false,
      excelForm: {},
      regionLevelData:[],
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: '模板上传',
            prop: 'excelFile',
            type: 'upload',
            drag: true,
            loadText: '模板上传中，请稍等',
            span: 24,
            propsHttp: {
              res: 'data'
            },
            tip: '请上传 .xls,.xlsx 标准格式文件',
            action: "/api/sinoma-system/region/import-region"
          },
          {
            label: "数据覆盖",
            prop: "isCovered",
            type: "switch",
            align: "center",
            width: 80,
            // dicData: [
            //   {
            //     label: "否",
            //     value: 0
            //   },
            //   {
            //     label: "是",
            //     value: 1
            //   }
            // ],
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: 'number',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yesNo",
            value: 0,
            slot: true,
            rules: [
              {
                required: true,
                message: "请选择是否覆盖",
                trigger: "blur"
              }
            ]
          },
          {
            label: '模板下载',
            prop: 'excelTemplate',
            formslot: true,
            span: 24,
          }
        ]
      },
      clickDataBak:{},
      debugBox: false,
      debugForm: {},
      debugOption: {
        labelWidth: 50,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: '省份',
            prop: 'province',
            type: 'select',
            props: {
              label: 'name',
              value: 'code'
            },
            cascader: ['city'],
            dicUrl: '/api/sinoma-system/region/select',
            span: 24,
          },
          {
            label: '地市',
            prop: 'city',
            type: 'select',
            props: {
              label: 'name',
              value: 'code'
            },
            cascader: ['district'],
            dicFlag: false,
            dicUrl: '/api/sinoma-system/region/select?code={{province}}',
            span: 24,
          },
          {
            label: '区县',
            prop: 'district',
            type: 'select',
            props: {
              label: 'name',
              value: 'code'
            },
            dicFlag: false,
            dicUrl: '/api/sinoma-system/region/select?code={{city}}',
            span: 24,
          }
        ]
      }
    };
  },
  watch: {
    'regionForm.subCode'() {
      this.regionForm.code = this.regionForm.parentCode + this.regionForm.subCode;
    },
    'excelForm.isCovered'() {
      if (this.excelForm.isCovered !== '') {
        const column = this.findObject(this.excelOption.column, "excelFile");
        column.action = `/api/sinoma-system/region/import-region?isCovered=${this.excelForm.isCovered}`;
      }
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.region_add, false),
        viewBtn: this.vaildData(this.permission.region_view, false),
        delBtn: this.vaildData(this.permission.region_delete, false),
        editBtn: this.vaildData(this.permission.region_edit, false)
      };
    },
    regionOption() {
      return {
        labelWidth: 100,
        disabled: this.formDis,
        submitBtn: !this.formDis,
        emptyBtn: !this.formDis,
        column: [
          {
            label: "父区划编号",
            prop: "parentCode",
            span: 24,
            disabled: true,
            // rules: [{
            //   required: true,
            //   message: "请输入父区划编号",
            //   trigger: "blur"
            // }]
          },
          {
            label: "父区划名称",
            prop: "parentName",
            span: 24,
            disabled: true,
          },
          {
            label: "祖区划编号",
            prop: "ancestors",
            span: 24,
            display: false,
          },
          {
            label: "区划编号",
            prop: "code",
            disabled: true,
            span: 24,
            // rules: [{
            //   required: true,
            //   message: "请输入区划编号",
            //   trigger: "blur"
            // },
            //   {
            //     pattern: /^[0-9]*$/,
            //     message: "仅支持非负数字",
            //   },
            // ]
          },
          {
            label: "区划子编号",
            prop: "subCode",
            disabled: true,
            display: false,
          },
          {
            label: "区划名称",
            prop: "name",
            span: 24,
            rules: [{
              required: true,
              message: "请输入区划名称",
              trigger: "blur"
            }]
          },
          {
            label: "区划等级",
            prop: "regionLevel",
            type: "radio",
            dicData:this.regionLevelData,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            disabled: false,
            dataType: "number",
            span: 24,
            rules: [{
              required: true,
              message: "请选择区划等级",
              trigger: "blur"
            }]
          },
          {
            label: "区划排序",
            prop: "sort",
            type: "number",
            span: 24,
            rules: [{
              required: true,
              message: "请输入区划排序",
              trigger: "blur"
            }]
          },
          {
            label: "区划备注",
            prop: "remark",
            type: "textarea",
            minRows: 6,
            span: 24,
          },
        ]
      }

    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push(
          {
            label: '新增大洲',
            emit: "head-add"
          });
      buttonBtn.push(
          {
            label: '新增下级',
            emit: "head-addChild"
          });
      buttonBtn.push(
          {
            label: '导入',
            emit: "head-import"
          });
      buttonBtn.push(
          {
            label: '导出',
            emit: "head-export"
          });

      buttonBtn.push(
          {
            label: '删除',
            emit: "head-delete"
          });
      return buttonBtn;
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  mounted() {
    this.getRegionDic();
  },
  methods: {
    initTree() {
      this.treeData = [];
      getLazyTree(this.topCode).then(res => {
        this.treeData = res.data.data.map(item => {
          return {
            ...item,
            leaf: !item.hasChildren
          }
        })
      });
    },
    nodeClick(data) {
      if(Object.keys(this.clickDataBak).length<1) {
        this.clickDataBak = data
        this.getRegionDic();
        this.subCodeDis = true;
        this.formDis = false;
        const column = this.findObject(this.regionOption.column, "parentCode");
        column.disabled = true;
        this.treeCode = data.id;
        this.treeParentCode = data.parentId;
        getDetail(this.treeCode).then(res => {
          this.regionForm = res.data.data;
          this.initialForm = res.data.data;
          this.regionForm.subCode = this.regionForm.code.replace(this.regionForm.parentCode, '');
          let prefix = this.regionForm.code.substring(0, this.regionForm.code.lastIndexOf(this.regionForm.subCode))
          this.regionForm.ancestors = prefix;
          this.$nextTick(() => {
            this.findObject(this.regionOption.column, "regionLevel").disabled = true;
          })
        })
      }else {
        if(this.clickDataBak.id == data.id ){
          this.clickDataBak ={}
          this.$nextTick(() => {
            this.$refs.form.resetForm();
            this.$refs.avueTree.$refs.tree.setCurrentKey(null);
            this.findObject(this.regionOption.column, "regionLevel").disabled = true;
            this.initialForm = {};
            this.treeCode = ''
          })
        }else {
          this.clickDataBak = data
          this.getRegionDic();
          this.subCodeDis = true;
          this.formDis = false;
          const column = this.findObject(this.regionOption.column, "parentCode");
          column.disabled = true;
          this.treeCode = data.id;
          this.treeParentCode = data.parentId;
          getDetail(this.treeCode).then(res => {
            this.regionForm = res.data.data;
            this.initialForm = res.data.data;
            this.regionForm.subCode = this.regionForm.code.replace(this.regionForm.parentCode, '');
            let prefix = this.regionForm.code.substring(0, this.regionForm.code.lastIndexOf(this.regionForm.subCode))
            this.regionForm.ancestors = prefix
            this.$nextTick(() => {
              this.findObject(this.regionOption.column, "regionLevel").disabled = true;
            })
          })
        }
      }
    },
    handleAdd() {
      this.findObject(this.regionOption.column, "regionLevel").disabled = false;
      this.subCodeDis = false;
      this.formDis = false;
      this.$nextTick(() => {
        this.$refs.form.resetForm();
        this.$refs.avueTree.$refs.tree.setCurrentKey(null);
        this.initialForm = {};
        this.treeCode = ''
      })
      getDetail(null).then(res => {
        const data = res.data.data;
        if (JSON.stringify(data) == "{}") {
          return;
        }
        this.regionForm.sort = '0';
        delete this.regionForm.id;
        delete this.regionForm.children;
        delete this.regionForm.parentId;
        this.regionForm.parentCode = data.code;
        this.regionForm.parentName = data.name;
        this.regionForm.regionLevel = data.regionLevel
        this.regionForm.subCode = this.regionForm.code.replace(this.regionForm.parentCode, '');
        this.regionForm.ancestors = data.ancestors.replaceAll(",", "") + data.code;
        this.findObject(this.regionOption.column, "regionLevel").disabled = true;
      })
    },
    handleAddChild() {
      if (this.treeCode == '') {
        this.$message({
          type: "warning",
          message: '请先选择一项区划'
        });
        return;
      }
      this.findObject(this.regionOption.column, "regionLevel").disabled = false;
      this.subCodeDis = false;
      this.formDis = false;
      this.regionForm.parentName = this.initialForm.name;
      this.regionForm.ancestors = this.initialForm.code;
      this.regionForm.parentCode = this.initialForm.code;
      this.regionForm.code = '';
      this.regionForm.id = '';
      this.regionForm.subCode = '';
      this.regionForm.name = '';
      this.regionForm.sort = '';
      this.regionForm.regionLevel = (this.initialForm.regionLevel === 5) ? 5 : this.initialForm.regionLevel + 1;
      for (let i = 0;i <= this.initialForm.regionLevel;i++) {
        console.log(i,'434')
        this.$refs.form.$refs.form.fields[4].$children[1].dic[i].disabled = true;
      }
    },
    getRegionDic() {
      getDictionary({
        code:'region'
      }).then((res)=> {
        this.regionLevelData = res.data.data
      })
    },
    handleSubmit(form, done) {
      done();
      form.code = this.regionForm.ancestors + form.subCode;
      var regExcep = new RegExp('^[0-9]*$');
      if(!regExcep.test(form.subCode)){
        this.$message({
          type: "warning",
          message: '区划编号只能为数字'
        });
        return;
      }
      submit(form).then(() => {
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess")
        });
        this.initTree();
        this.regionForm.subCode = '';
        this.$refs.form.resetForm();
        done();
      }, error => {
        // this.$refs.form.resetForm();
        done();
      });
    },
    handleDelete() {
      if (validatenull(this.regionForm.code)) {
        this.$message.warning("请先选择一项区划");
        return;
      }
      this.$confirm(`确定将 [${this.regionForm.name}] 数据删除?`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
          .then(() => {
            return remove(this.treeCode);
          })
          .then(() => {
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });
            this.initTree();
            this.regionForm.subCode = '';
            this.$refs.form.resetForm();
          });
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.initTree();
      done();
    },
    handleDebug() {
      this.debugBox = true;
    },
    handleImport() {
      this.excelBox = true;
    },
    handleExport() {
      this.$confirm("是否导出行政区划数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        NProgress.start();
        exportBlob(`/api/sinoma-system/region/export-region?${this.website.tokenHeader}=${getToken()}`).then(res => {
          downloadXls(res.data, `行政区划数据${dateNow()}.xlsx`);
          NProgress.done();
        })
      });
    },
    handleTemplate() {
      exportBlob(`/api/sinoma-system/region/export-template?${this.website.tokenHeader}=${getToken()}`).then(res => {
        downloadXls(res.data, "行政区划模板.xlsx");
      })
    },
  }
};
</script>

<style scoped lang="scss">
.treeBox {
  width: 300px;
  height: calc(100vh - 125px);
  background-color: #FFFFFF;
  overflow: auto;
}

.treeTitle {
  width: calc(100% - 20px);
  margin: auto;
  font-size: 14px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid rgb(204, 204, 204);
}

::v-deep .avue-tree {
  min-height: unset;
}

.rightFormBox {
  width: calc(100% - 299px);
  border-left: 1px solid #CCC;
  background-color: #FFFFFF;
}
</style>
