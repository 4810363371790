
<template>



  <basic-container>






    <div style="display: flex;justify-content: space-around;" >

      <!-- <div style="width: 20%; ">
        <el-input :placeholder="$t('cip.cmn.msg.warning.filterateWarning')" v-model="filterText">
        </el-input>
        <div v-bodyHe style="background:#fff;padding:20px">
          <el-tree class="filter-tree" node-key="id" ref="tree" :data="treeData" :props="defaultProps" hiddenTrouble-on-click-node
            @node-click="handleNodeClick" @hiddenTrouble-change="handleCheckChange" @hiddenTrouble="treeCheck" hiddenTrouble-strictly
            :render-content="renderContent" :filter-node-method="filterNode">
          </el-tree>
        </div>



      </div> -->

      <!-- <div style="width: 77%;">
        <el-row style="display:flex;align-items:center;width: 100%;background-color:white">
      <el-col span="4" style="float: left;display:flex;align-items:center;">
        <avue-avatar shape="square" style="background-color: rgb(64, 158, 255);margin-left: 10px;" icon="el-icon-tickets" size="small">
        </avue-avatar>
        <label style="font-size: 14px;margin-left: 6px;"> {{$t("cip.dc.dataobject.title.name")}} </label>
      </el-col>
      <el-col span="20">
        <div style="float: right;">
          <el-button-group style="padding: 6px">
            <el-button size="mini" :disabled=!treeWay @click="opDialog(4)"> {{$t('cip.cmn.btn.addBtn')}}</el-button>
            <el-button size="mini" @click="opDialog(5)">{{$t('cip.cmn.btn.delBtn')}}</el-button>



          </el-button-group>
        </div>
      </el-col>
    </el-row>



        <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;height: 70vh;margin-top: 10px;"
          highlight-current-row>

          <el-table-column header-align="center" align="center" type="selection">
          </el-table-column>

          <el-table-column header-align="center" align="center" prop="code" :label="$t('cip.dc.dataobject.field.code')" width="120">
          </el-table-column>


          <el-table-column align="center" header-align="center" prop="label" :label="$t('cip.dc.dataobject.field.identification')" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="sourceType" :label="$t('cip.dc.dataobject.field.type')"  show-overflow-tooltip>
          </el-table-column>
          <el-table-column header-align="center" align="center" prop="remark" :label="$t('cip.dc.dataobject.field.remark')" show-overflow-tooltip>
          </el-table-column>

          <el-table-column header-align="center" align="center" prop="remark" :label="$t('cip.dc.dataobject.field.edit')" show-overflow-tooltip>

            <template slot-scope="scope">

              <el-button type="text" size="mini" @click="EditList(scope.row)">{{$t('cip.dc.dataobject.field.edit')}}</el-button>
              <el-button type="text" size="mini" @click="SeeData(scope.row)">{{ $t('cip.dc.dataobject.title.tip2') }}</el-button>
            </template>

          </el-table-column>
        </el-table>

        <div class="block" style="float: right; margin: 15px;">

          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage4" :page-sizes="[20, 50,100]" :page-size="20"
            layout="total, sizes, prev, pager, next, jumper" :total="tableTotal">
          </el-pagination>
        </div>

      </div> -->

    </div>


    <!-- 新建目录弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisible1" @close="Addclose">
      <el-form :model="form">
        <span>选择父目录：</span> <span>{{ treeWay2 }}</span>

        <br>
        <span style="color: red;">*</span><span>目录名称：</span>
        <br>
        <el-form-item>
          <el-input v-model="addTreeData.name" autocomplete="off" placeholder="请输入目录"></el-input>
        </el-form-item>
        <span>请选择是否为末级目录：</span>
        <el-select v-model="addTreeData.type" placeholder="请选择是否为末级目录">
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false" style="margin:0px 20px;">{{$t("cancelText")}}</el-button>
        <el-button type="primary" @click="MAddTree" style="margin:0px 20px;">{{$t("submitText")}}</el-button>
      </div>
    </el-dialog>

    <!-- 删除目录弹框 和 删除数据对象 -->

    <el-dialog title="提示" :visible.sync="dialogVisible2">

      <div>确定要删除当前目录？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false" style="margin:0px 20px;">{{$t("cancelText")}}</el-button>
        <el-button type="primary" @click="Mdeltree" style="margin:0px 20px;">{{$t("submitText")}}</el-button>
      </div>
    </el-dialog>



    <el-dialog :title="$t('cip.dc.dataobject.title.tip')" :visible.sync="dialogVisible3">

      <div>{{$t('cip.dc.dataobject.msg.delmsg3')}}</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false" style="margin:0px 20px;">{{$t("cancelText")}}</el-button>
        <el-button type="primary" @click="MDelObjectList" style="margin:0px 20px;">{{$t("submitText")}}</el-button>
      </div>
    </el-dialog>




    <!-- 新建数据对象弹框 -->


    <el-dialog  :visible.sync="dialogVisible4" @close="Addclose2" @open="Addopen2">
      <el-form :model="listAdd" label-position="right" label-width="100px">
        <el-form-item :label="$t('cip.dc.dataobject.field.tree')" :label-width="formLabelWidth">
          {{ treeWay }}
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataobject.field.type')" :label-width="formLabelWidth">
          <el-select v-model="listAdd.sourceType" :placeholder="$t('cip.dc.dataobject.field.type')" @change="ChooseType($event)">
            <el-option v-for="item in TypeOp" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('cip.dc.dataobject.field.code')" :label-width="formLabelWidth">
          <el-input v-model="listAdd.code" autocomplete="off" :placeholder="$t('cip.dc.dataobject.msg.p2')"></el-input>
        </el-form-item>


        <div v-if="listAdd.sourceType == 1">

          <el-form-item :label="$t('cip.dc.dataobject.field.databank')" :label-width="formLabelWidth">
            <el-select v-model="listAdd.metadataInfoDTO.metadataSourceId" :placeholder="$t('cip.dc.dataobject.field.databank')" @change="ChooseSJKOP($event)">
              <!-- SJKOP -->
              <el-option v-for="item in SJKOP" :key="item.id" :label="item.sourceName" :value="item.id"></el-option>
            </el-select>

          </el-form-item>

          <el-form-item :label="$t('cip.dc.dataobject.field.tableName')" :label-width="formLabelWidth">
            <el-select v-model="listAdd.metadataInfoDTO.metadataTableId" :placeholder="$t('cip.dc.dataobject.field.tableName')" @change="ChooseSBMOP($event)">
              <el-option v-for="item in SBMOP" :key="item.id" :label="toStr(item)" :value="item.id"></el-option>
            </el-select>

          </el-form-item>

          <el-form-item :label="$t('cip.dc.dataobject.field.saveField')" :label-width="formLabelWidth">

            <el-table :data="SBZDbc" ref="SBZDbcTable" border size="small" style="width: 100%">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column prop="columnName" :label="$t('cip.dc.dataobject.field.fieldName')">
              </el-table-column>

              <el-table-column prop="columnComment" :label="$t('cip.dc.dataobject.field.fieldComments')">
              </el-table-column>

            </el-table>
          </el-form-item>

          <el-form-item :label="$t('cip.dc.dataobject.field.ConditionField')" :label-width="formLabelWidth">

            <el-table :data="SBZDtj" ref="SBZDtjTable" border size="small" style="width: 100%">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column prop="columnName" :label="$t('cip.dc.dataobject.field.fieldName')" width="180">
              </el-table-column>

              <el-table-column prop="columnComment" :label="$t('cip.dc.dataobject.field.fieldComments')">
              </el-table-column>

              <el-table-column prop="dataDefault" :label="$t('cip.dc.dataobject.field.default')" width="180">
              </el-table-column>
              <el-table-column prop="BCinput" :label="$t('cip.dc.dataobject.field.enterValue')">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.BCinput" size="small"> </el-input>
                </template>
              </el-table-column>
            </el-table>


          </el-form-item>


          <el-form-item :label="$t('cip.dc.dataobject.field.comparisonField')" :label-width="formLabelWidth">
            <el-table :data="SBZDdb" ref="SBZDdbTable" border size="small" style="width: 100%">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column prop="columnName" :label="$t('cip.dc.dataobject.field.fieldName')">
              </el-table-column>

              <el-table-column prop="columnComment" :label="$t('cip.dc.dataobject.field.fieldComments')">
              </el-table-column>

            </el-table>
          </el-form-item>





        </div>




        <div v-if="listAdd.sourceType == 2">


          <el-form-item :label="$t('cip.dc.dataobject.field.Apiinterface')" :label-width="formLabelWidth">
            <el-select v-model="listAdd.metadataInfoDTO.apiId" :placeholder="$t('cip.dc.dataobject.field.Apiinterface')" @change="ChooseAPIOP($event)">
              <el-option v-for="item in APIOP" :key="item.id" :label="item.apiName" :value="item.id" :disabled="item.status != 2"></el-option>
            </el-select>

          </el-form-item>

          <el-form-item :label="$t('cip.dc.dataobject.field.saveField')" :label-width="formLabelWidth">

            <el-table :data="SBZDbc" ref="SBZDbcTable2" border size="small" style="width: 100%">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column prop="fieldName" :label="$t('cip.dc.dataobject.field.fieldName')">
              </el-table-column>

              <el-table-column prop="fieldComment" :label="$t('cip.dc.dataobject.field.fieldComments')">
              </el-table-column>

            </el-table>
          </el-form-item>

          <el-form-item :label="$t('cip.dc.dataobject.field.ConditionField')" :label-width="formLabelWidth">

            <el-table :data="SBZDtj" ref="SBZDtjTable2" border size="small" style="width: 100%">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column prop="paramName" :label="$t('cip.dc.dataobject.field.fieldName')" width="180">
              </el-table-column>

              <el-table-column prop="paramComment" :label="$t('cip.dc.dataobject.field.fieldComments')">
              </el-table-column>

              <el-table-column prop="dataDefault" :label="$t('cip.dc.dataobject.field.default')" width="180">
              </el-table-column>
              <el-table-column prop="BCinput" :label="$t('cip.dc.dataobject.field.enterValue')">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.BCinput" size="small"> </el-input>
                </template>
              </el-table-column>
            </el-table>


          </el-form-item>


          <el-form-item :label="$t('cip.dc.dataobject.field.comparisonField')" :label-width="formLabelWidth">
            <el-table :data="SBZDdb" ref="SBZDdbTable2" border size="small" style="width: 100%">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column prop="fieldName" :label="$t('cip.dc.dataobject.field.fieldName')">
              </el-table-column>

              <el-table-column prop="fieldComment" :label="$t('cip.dc.dataobject.field.fieldComments')">
              </el-table-column>

            </el-table>
          </el-form-item>




        </div>






        <el-form-item :label="$t('cip.dc.dataobject.field.identification')" :label-width="formLabelWidth">
          <el-input v-model="listAdd.label" autocomplete="off" :placeholder="$t('cip.dc.dataobject.msg.p3')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('cip.dc.dataobject.field.remark')" :label-width="formLabelWidth">
          <el-input type="textarea" :rows="3" v-model="listAdd.remark" autocomplete="off" :placeholder="$t('cip.dc.dataobject.msg.p4')"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible4 = false" style="margin:0px 20px;">{{ $t("cancelText") }}</el-button>
        <el-button type="primary" @click="AddObjTable" style="margin:0px 20px;">{{ $t("submitText") }}</el-button>
      </div>
    </el-dialog>

    <el-drawer
    :title="$t('cip.dc.dataobject.title.tip2')"
  :visible.sync="drawer"
  :with-header="false">
  <el-table
      :data="tableData4"
      style="width: 100%">
      <el-table-column
      v-for="(item,index) in tableData4List  " :key="index"
        :prop=item.column_name
        :label=item.column_name
        >
      </el-table-column>

    </el-table>
</el-drawer>







  </basic-container>



</template>

<script>
import {
  getDataTree, SaveAddTree, ObjectList, DelObjTree, DelObjectList, getSourcesList, getSourcesList2, getDataApisList,
  getSourcesTableList, getSourcesTableList2, getColumnsList, getDataApisXQ, AddObjectList, getDataOBJXQ,seeData
} from "@/api/dataAcquisition/index";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  data() {
    return {
      tableData4:[],
      tableData4List:[],
      filterText: '',
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      AddTitle: "新建",
      addTreeData: {
        parentId: "0",
        name: "",
        type: "0"
      },
      ObjParms: {
        query: {
          current: 1,
          size: 20,
        },
        dataObjectTypeId: null,
      },

      ObjParms2: {
        query: {
          current: 1,
          size: 20,
        },
        modelId: null,
      },
      ObjTreeid: "", //删除树id
      TypeOp: [
        {
          label: "数据库",
          value: 1

        },
        {
          label: "API接口",
          value: 2

        },
      ],
      listAdd: {
        modelId: "",
        code: "",
        label: "",
        id: "",//主键ID 修改时传入
        remark: "",  //备注
        sourceType: null,  //类型 1-数据库 2-api
        dataObjectTypeId: "", //数据对象类型id
        //元数据详情
        metadataInfoDTO: {
          apiId: "", //api类型使用
          metadataSourceId: "", // 数据库id 数据库类型使用
          metadataTableId: "", //表id数据库类型使用
          saveFields: [],
          //  {
          //   //保存字段 api类型、数据库类型共用
          //   fieldId: "", //字段id数据库类型使用 对应id
          //   fieldName: "",//字段id数据库类型使用 对应id
          // },
          conditionFields: [],
          //  {
          //   //条件字段 api类型、数据库类型共用
          //   fieldId: "", //字段id 数据库类型使用 对应id
          //   fieldName: "",//字段名称api类型使用 对应reqParams.paramName
          //   defaultValue: "", //默认值 api类型、数据库类型共用 对应resqarams.defaultValue
          //   inputValue: "", //录入值api类型、数据库类型共用 用户输入
          // },
          compareFields: [],
          //  {
          //   //比对字段api类型、数据库类型共用
          //   fieldId: "",//数据库类型使用 对应id
          //   fieldName: "", //api类型使用 对应resParams.paramName
          // },

        }
      },
      tableID: "",
      tableData: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      treeWay: "",
      treeWay2: "",

      SJKOP: [],//数据库下拉
      SBMOP: [],//数据库表名下拉
      APIOP: [],//api下拉
      SBZDbc: [], //表字段 保存
      SBZDtj: [], //表字段 条件
      SBZDdb: [], //表字段 对比
      CanEdit: false, //控制右侧操作
      tableTotal: 0,
      drawer: false, //预览
    };
  },
  mounted() {
    this.getdemotree()
    console.log(this.$t('cip.cmn.btn.defBtn'),"sssssssssssssssssssss111111111111");
  },

  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    toStr(str) {
      let data
      if (str.tableComment) {
        data = str.tableComment
      } else {
        data = str.tableName;
      }
      return decodeURI(data);
    },
    //树
    getdemotree() {
      getDataTree({ object: 0 }).then(res => {
        if (res.status == 200) {
          this.treeData = res.data.data
        }
      })
    },

    //数据对象列表
    getObjectList(e) {
      ObjectList(e).then(res => {
        this.tableData = res.data.data.records
        this.tableTotal = res.data.data.total

        this.listAdd.dataObjectTypeId = e.dataObjectTypeId
      })
    },






    opDialog(e) {
      if (e == 1) {
        this.dialogVisible1 = true
        console.log(this.$refs.tree.getCheckedNodes(), "iiiiiii");

        if (this.$refs.tree.getCheckedNodes().length == 0) {
          this.treeWay2 = ""
        }
      } else if (e == 2) {
        this.dialogVisible1 = true
      } else if (e == 3) {
        if (this.$refs.tree.getCheckedKeys().length > 0) {
          this.dialogVisible2 = true
        } else {
          this.$message({
            message: '请选择要删除的目录',
            type: 'warning'
          });
        }
      } else if (e == 4) {
        // if (this.CanEdit) {
        this.dialogVisible4 = true
        this.AddTitle = "新建"
        // }

      } else if (e == 5) {
        // if (this.CanEdit) {
        if (this.$refs.multipleTable.selection.length > 0 && this.$refs.multipleTable.selection.length < 2) {
          this.dialogVisible3 = true
        }
        if (this.$refs.multipleTable.selection.length == 0) {
          this.$message({
            message: this.$t("cip.dc.dataobject.msg.delmsg1"),
            type: 'warning'
          });
        }
        if (this.$refs.multipleTable.selection.length > 1) {
          this.$message({
            message: this.$t("cip.dc.dataobject.msg.delmsg2"),
            type: 'warning'
          });
        }
      }

      // }
    },

    Addclose() {
      this.addTreeData.parentId = "0"
      this.addTreeData.name = ""
      this.addTreeData.type = "0"
      // this.$refs.tree1.setCheckedKeys([]);
    },

    //点击树节点显示列表
    handleNodeClick(data) {
      this.ObjParms.dataObjectTypeId = data.id
      this.ObjParms2.modelId = data.id
      this.getObjectList(this.ObjParms2)
      //如果为末级目录调取列表
      // if (data.type == "1") {
      //   this.CanEdit = true
      //   this.getObjectList(this.ObjParms)
      //   //获取当前树位置

      var abb = JSON.parse(JSON.stringify(this.treeData))
      var alltree = this.flatten(abb)
      var cc = this.FinTfather(alltree, data.pid).reverse()
      this.treeWay = ""
      cc.forEach((item) => {
        this.treeWay += item + '/'
      })
      this.treeWay += data.name

      // }
      // if (data.type == "0") {
      //   //右侧不显示和操作
      //   this.CanEdit = false
      //   this.tableData = []

      //   var abb = JSON.parse(JSON.stringify(this.treeData))
      //   var alltree = this.flatten(abb)
      //   var cc = this.FinTfather(alltree, data.parentId).reverse()
      //   this.treeWay2 = ""
      //   cc.forEach((item) => {
      //     this.treeWay2 += item + '/'
      //   })
      //   this.treeWay2 += data.name
      //      console.log(this.treeWay2,"ddqqqwwzzz");
      // }

    },


    //扁平化 树
    flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = []
          result = result.concat(arr[i])
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },

    //寻找父级
    FinTfather(arr, fid) {
      let arr2 = []
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id == fid) {
          if (arr[i].pid != "0") {
            fid = arr[i].pid
            arr2.push(arr[i].name)
            this.FinTfather(arr, fid)
          } else {
            arr2.push(arr[i].name)
          }

        }
      }
      return arr2;
    },







    //选中树结构
    handleCheckChange(data) {
      console.log(this.$refs.tree.getCheckedKeys(), "ddddddddd");

      var abb = JSON.parse(JSON.stringify(this.treeData))
      var alltree = this.flatten(abb)
      var cc = this.FinTfather(alltree, data.parentId).reverse()
      this.treeWay2 = ""
      cc.forEach((item) => {
        this.treeWay2 += item + '/'
      })
      this.treeWay2 += data.name

    },
    //树结构单选  储存id
    treeCheck(node, list) {

      if (this.$refs.tree.getCheckedNodes().length > 1) {
        this.$refs.tree.setCheckedKeys([node.id]);
      }
      this.ObjTreeid = node.id

      this.addTreeData.parentId = node.id
      if (this.$refs.tree.getCheckedNodes().length == 0) {
        this.addTreeData.parentId = "0"
      }

    },



    //选中新建父目录时的树   单选
    // treeCheck1(node, list) {
    //   if (this.$refs.tree1.getCheckedNodes().length > 1) {
    //     this.$refs.tree1.setCheckedKeys([node.id]);
    //   }
    //   this.addTreeData.parentId = node.id
    //   if (this.$refs.tree1.getCheckedNodes().length == 0) {
    //     this.addTreeData.parentId = "0"
    //   }
    // },
    //新增树目录
    MAddTree() {
      // if (this.$refs.tree1.getCheckedNodes().length == 0) {
      if (this.$refs.tree.getCheckedNodes().length == 0) {
        this.addTreeData.parentId = undefined
      }
      if (this.addTreeData.name) {
        console.log(this.treeData, "qqqqqqqqqqqqqqqqqqqqq");
        if (!this.treeData || this.treeData.length == 0) {
          SaveAddTree(this.addTreeData).then(res => {
            if (res.status == 200) {
              this.getdemotree()
              this.dialogVisible1 = false
            }
          })
        }


        //  else if (this.$refs.tree1.getCheckedNodes().length>0) {
        else if (this.$refs.tree.getCheckedNodes().length > 0) {
          //  if(this.$refs.tree1.getCheckedNodes()[0].type != 1){
          if (this.$refs.tree.getCheckedNodes()[0].type != 1) {
            SaveAddTree(this.addTreeData).then(res => {
              if (res.status == 200) {
                this.getdemotree()
                this.dialogVisible1 = false
              }
            })
          } else {
            this.$message({
              message: '末级目录下不能新建目录',
              type: 'warning'
            });
          }
        }
        // else if(this.$refs.tree1.getCheckedNodes().length==0){
        else if (this.$refs.tree.getCheckedNodes().length == 0) {
          SaveAddTree(this.addTreeData).then(res => {
            if (res.status == 200) {
              this.getdemotree()
              this.dialogVisible1 = false
            }
          })
        }
        else {
          this.$message({
            message: '末级目录下不能新建目录',
            type: 'warning'
          });
        }

      } else {
        this.$message({
          message: '请填写目录名称',
          type: 'warning'
        });
      }

    },
    //删除树
    Mdeltree() {
      DelObjTree({ id: this.ObjTreeid }).then(res => {
        if (res.status == 200) {
          this.getdemotree()
          this.dialogVisible2 = false
        }
      })
    },

    //删除列表数据
    MDelObjectList() {
      DelObjectList({
        id: this.$refs.multipleTable.selection[0].id
      }).then(res => {
        // this.getObjectList(this.ObjParms)
        this.getObjectList(this.ObjParms2)
        this.dialogVisible3 = false
      })
    },





    //表格分页

    //size改变
    handleSizeChange(e) {
      this.ObjParms.query.size = e
      // this.getObjectList(this.ObjParms)
      this.getObjectList(this.ObjParms2)
    },
    //页数改变
    handleCurrentChange(e) {
      this.ObjParms.query.current = e
      // this.getObjectList(this.ObjParms)
      this.getObjectList(this.ObjParms2)
    },




    //选择类型
    ChooseType(e) {
      //数据库
      if (e == 1) {
        this.MgetSourcesList()
      }
      //api
      if (e == 2) {
        getDataApisList({}).then(res => {
          this.APIOP = res.data.data
        })
      }
    },



    // 获取数据库
    MgetSourcesList() {
      // getSourcesList({}).then(res => {
      //   this.SJKOP = res.data.data
      // })
      getSourcesList2({ modelId: this.ObjParms2.modelId }).then(res => {
        this.SJKOP = res.data.data
      })
    },

    //选择数据库名后 获取表
    ChooseSJKOP(e) {
      // getSourcesTableList({ sourceId: e }).then(res => {
      //   this.SBMOP = res.data.data
      // })
      getSourcesTableList2({ sourceId: e, modelId: this.ObjParms2.modelId }).then(res => {
        this.SBMOP = res.data.data
      })
    },
    //选择表 后获取表字段
    ChooseSBMOP(e) {
      getColumnsList({ sourceId: this.listAdd.metadataInfoDTO.metadataSourceId, tableId: e }).then(res => {
        console.log(res);
        this.SBZDbc = JSON.parse(JSON.stringify(res.data.data))
        // BCinput
        this.SBZDtj = JSON.parse(JSON.stringify(res.data.data))
        this.SBZDtj.forEach((item) => {
          this.$set(item, "BCinput", "")
        })
        this.SBZDdb = JSON.parse(JSON.stringify(res.data.data))
      })
    },








    //末级目录改颜色
    renderContent(h, { node, data, store }) {
      if (data.type == 1) {
        return <span style="font-size:14px;color:red">{node.label}</span>;
      } else {
        return <span style="font-size:14px">{node.label}</span>;
      }
    },
    //末级目录改颜色
    renderContent1(h, { node, data, store }) {
      if (data.type == 1) {
        return <span style="font-size:14px;color:red">{node.label}</span>;
      } else {
        return <span style="font-size:14px">{node.label}</span>;
      }
    },



    //选择api列表后
    ChooseAPIOP(e) {

      // this.listAdd.metadataInfoDTO.apiId = e


      getDataApisXQ(e).then(res => {
        this.SBZDbc = JSON.parse(JSON.stringify(res.data.data.resParams))
        this.SBZDtj = JSON.parse(JSON.stringify(res.data.data.reqParams))
        this.SBZDtj.forEach((item) => {
          this.$set(item, "BCinput", "")
        })
        this.SBZDdb = JSON.parse(JSON.stringify(res.data.data.resParams))
      })
    },




    //新增数据对象 表格数据
    AddObjTable() {


      //数据库类型
      if (this.listAdd.sourceType == 1) {

        var aSBZDbcTable = this.$refs.SBZDbcTable.selection
        var aSBZDdbTable = this.$refs.SBZDdbTable.selection
        var aSBZDtjTable = this.$refs.SBZDtjTable.selection


        this.listAdd.metadataInfoDTO.apiId = undefined
        if (this.AddTitle == "新建") {
          this.listAdd.id = null



        }

        //保存字段
        var a1 = []
        aSBZDbcTable.forEach((item) => {
          a1.push({
            fieldId: item.id,
            // fieldName:item.columnName
          })
        })
        this.listAdd.metadataInfoDTO.saveFields = a1

        //对比字段
        var a2 = []
        aSBZDdbTable.forEach((item) => {
          a2.push({
            fieldId: item.id,
          })
        })
        this.listAdd.metadataInfoDTO.compareFields = a2


        //条件字段
        var a3 = []
        aSBZDtjTable.forEach((item) => {
          a3.push({
            fieldId: item.id,
            defaultValue: "",
            inputValue: item.BCinput
          })
        })
        this.listAdd.metadataInfoDTO.conditionFields = a3



        this.listAdd.modelId = this.ObjParms2.modelId
        this.listAdd.dataObjectTypeId = this.ObjParms2.modelId

        AddObjectList(this.listAdd).then(res => {
          // this.getObjectList(this.ObjParms)
          this.getObjectList(this.ObjParms2)
          this.dialogVisible4 = false
        })









      }

      //api类型
      if (this.listAdd.sourceType == 2) {

        this.listAdd.metadataInfoDTO.metadataSourceId = undefined

        if (this.AddTitle == "新建") {
          this.listAdd.id = null
        }


        var bSBZDbcTable = this.$refs.SBZDbcTable2.selection
        var bSBZDdbTable = this.$refs.SBZDdbTable2.selection
        var bSBZDtjTable = this.$refs.SBZDtjTable2.selection




        //保存字段
        var b1 = []
        bSBZDbcTable.forEach((item) => {
          b1.push({
            fieldName: item.fieldName
          })
        })
        this.listAdd.metadataInfoDTO.saveFields = b1



        //对比字段
        var b2 = []
        bSBZDdbTable.forEach((item) => {
          b2.push({
            fieldName: item.fieldName
          })
        })
        this.listAdd.metadataInfoDTO.compareFields = b2


        //条件字段
        var b3 = []
        bSBZDtjTable.forEach((item) => {
          b3.push({
            // fieldId:item.id,
            fieldName: item.paramName,
            defaultValue: "",
            inputValue: item.BCinput
          })
        })
        this.listAdd.metadataInfoDTO.conditionFields = b3
        this.listAdd.modelId = this.ObjParms2.modelId
        this.listAdd.dataObjectTypeId = this.ObjParms2.modelId

        AddObjectList(this.listAdd).then(res => {
          // this.getObjectList(this.ObjParms)
          this.getObjectList(this.ObjParms2)
          this.dialogVisible4 = false



        })





      }





    },
   //预览数据对象
    SeeData(e){
      // console.log(e,"vvvvvvvvvvvvvvvvvvv");
      this.drawer = true
      seeData({id:e.id}).then(res=>{
        // if(res.data.data)

        this.tableData4 = res.data.data.list
        this.tableData4List = res.data.data.titleCN
        console.log(this.tableData4,"bbbbbbbb");
      })
    },



    //编辑数据对象
    EditList(e) {

      getDataOBJXQ(e.id).then(res => {
        this.dialogVisible4 = true
        this.AddTitle = "编辑"
        this.listAdd.id = e.id
        console.log(res.data.data.code);
        this.listAdd.code = res.data.data.code
        this.listAdd.label = res.data.data.label
        this.listAdd.remark = res.data.data.remark
        this.listAdd.sourceType = res.data.data.sourceType
        this.listAdd.dataObjectTypeId = res.data.data.dataObjectTypeId


        if (this.listAdd.sourceType == 1) {

          this.listAdd.metadataInfoDTO.metadataSourceId = res.data.data.metadataInfoDTO.metadataSourceId

          this.listAdd.metadataInfoDTO.metadataTableId = res.data.data.metadataInfoDTO.metadataTableId

          this.listAdd.metadataInfoDTO.saveFields = res.data.data.metadataInfoDTO.saveFields
          this.listAdd.metadataInfoDTO.conditionFields = res.data.data.metadataInfoDTO.conditionFields
          this.listAdd.metadataInfoDTO.compareFields = res.data.data.metadataInfoDTO.compareFields





          this.MgetSourcesList()
          let cc = res.data.data.metadataInfoDTO.metadataSourceId
          //    getSourcesTableList({ sourceId: cc }).then(res => {
          //   this.SBMOP = res.data.data
          // })
          getSourcesTableList2({ sourceId: cc, modelId: this.ObjParms2.modelId }).then(res => {
            this.SBMOP = res.data.data
          })


          getColumnsList({ sourceId: cc, tableId: res.data.data.metadataInfoDTO.metadataTableId }).then(res => {
            this.SBZDbc = JSON.parse(JSON.stringify(res.data.data))

            console.log(this.SBZDbc, "bbbbbbbbb", this.listAdd.metadataInfoDTO.saveFields);

            this.$nextTick(() => {
              this.SBZDbc.forEach((item, index) => {
                this.listAdd.metadataInfoDTO.saveFields.forEach((item1) => {
                  if (item.id == item1.fieldId) {
                    console.log("...............");
                    this.$refs.SBZDbcTable.toggleRowSelection(this.SBZDbc[index], true)
                  }
                })
              })
            })




            // BCinput
            this.SBZDtj = JSON.parse(JSON.stringify(res.data.data))
            this.SBZDtj.forEach((item) => {
              this.$set(item, "BCinput", "")
            })

            // inputValue
            this.$nextTick(() => {
              this.SBZDtj.forEach((item, index) => {
                // this.listAdd.metadataInfoDTO.compareFields.forEach((item1)=>{
                this.listAdd.metadataInfoDTO.conditionFields.forEach((item1) => {

                  if (item.id == item1.fieldId) {
                    console.log("...............");
                    this.$refs.SBZDtjTable.toggleRowSelection(this.SBZDtj[index], true)
                    item.BCinput = item1.inputValue
                  }
                })
              })
            })



            this.SBZDdb = JSON.parse(JSON.stringify(res.data.data))

            this.$nextTick(() => {
              this.SBZDdb.forEach((item, index) => {
                // this.listAdd.metadataInfoDTO.conditionFields.forEach((item1)=>{
                this.listAdd.metadataInfoDTO.compareFields.forEach((item1) => {

                  if (item.id == item1.fieldId) {
                    console.log("...............");
                    this.$refs.SBZDdbTable.toggleRowSelection(this.SBZDdb[index], true)
                  }
                })
              })
            })


          })







        }

        if (this.listAdd.sourceType == 2) {
          this.listAdd.metadataInfoDTO.apiId = res.data.data.metadataInfoDTO.apiId


          this.listAdd.metadataInfoDTO.saveFields = res.data.data.metadataInfoDTO.saveFields
          this.listAdd.metadataInfoDTO.conditionFields = res.data.data.metadataInfoDTO.conditionFields
          this.listAdd.metadataInfoDTO.compareFields = res.data.data.metadataInfoDTO.compareFields

          getDataApisList({}).then(res => {
            this.APIOP = res.data.data
          })

          getDataApisXQ(res.data.data.metadataInfoDTO.apiId).then(res => {
            this.SBZDbc = JSON.parse(JSON.stringify(res.data.data.resParams))

            this.$nextTick(() => {
              this.SBZDbc.forEach((item, index) => {
                this.listAdd.metadataInfoDTO.saveFields.forEach((item1) => {
                  if (item.fieldName == item1.fieldName) {
                    console.log("...............");
                    this.$refs.SBZDbcTable2.toggleRowSelection(this.SBZDbc[index], true)
                  }
                })
              })
            })

            this.SBZDtj = JSON.parse(JSON.stringify(res.data.data.reqParams))

            this.SBZDtj.forEach((item) => {
              this.$set(item, "BCinput", "")
            })

            this.$nextTick(() => {
              this.SBZDtj.forEach((item, index) => {

                this.listAdd.metadataInfoDTO.conditionFields.forEach((item1) => {
                  console.log(item, ".......222........", item1);
                  if (item.paramName == item1.fieldName) {

                    this.$refs.SBZDtjTable2.toggleRowSelection(this.SBZDtj[index], true)
                    item.BCinput = item1.inputValue

                  }
                })
              })
            })






            this.SBZDdb = JSON.parse(JSON.stringify(res.data.data.resParams))

            this.$nextTick(() => {
              this.SBZDdb.forEach((item, index) => {
                this.listAdd.metadataInfoDTO.compareFields.forEach((item1) => {
                  if (item.fieldName == item1.fieldName) {
                    console.log("...............");
                    this.$refs.SBZDdbTable2.toggleRowSelection(this.SBZDdb[index], true)
                  }
                })
              })
            })


          })


        }

        // this.listAdd.label = res.data.data.label
        // this.listAdd.label = res.data.data.label

      })
    },

    //关闭新增数据弹框时
    Addclose2() {
      for (let key in this.listAdd) {
        if (key != "dataObjectTypeId") {
          this.listAdd[key] = ""
        }

      }
      this.listAdd.metadataInfoDTO = {
        apiId: "",
        metadataSourceId: "",
        metadataTableId: "",
        saveFields: [],
        conditionFields: [],
        compareFields: [],
      }

    },

    //打开新增时清空数据
    Addopen2() {
      if (this.AddTitle == "新建") {
        this.SBZDbc = []
        this.SBZDtj = []
        this.SBZDdb = []
      }


    }



  },




};

</script>
<style lang='less' scoped>
.btbox {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

.dialog-footer1 {
  display: flex;
  justify-content: flex-end;


}
</style>
