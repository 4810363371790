var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
      staticClass: "user-dialog",
      attrs: {
        title: "用户列表",
        modal: true,
        "append-to-body": "",
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.userSelectDialog,
        width: "75%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.userSelectDialog = $event
        },
        click: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              titleShow: false,
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              treeTitle: _vm.$t(`cip.plat.sys.user.title.treeTitle`),
              isShowdig: false,
              showCheckbox: false,
              "popover-show": true,
            },
            on: {
              getNodeClick: _vm.getNodeClick,
              "include-down": _vm.includeDown,
            },
          }),
          _c(
            "el-main",
            [
              _c(
                "div",
                { staticClass: "headers" },
                [
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.searchChange,
                      "grid-head-empty": _vm.searchReset,
                    },
                  }),
                ],
                1
              ),
              _c("grid-layout", {
                ref: "gridLayout",
                attrs: {
                  "head-title": _vm.$t(
                    "cip.plat.sys.user.title.indexHeadTitle"
                  ),
                  gridRowBtn: _vm.gridRowBtn,
                  tableOptions: _vm.tableOptions,
                  tableData: _vm.tableData,
                  tableLoading: _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-single": _vm.gridRowBtnClick,
                  "page-size-change": _vm.onLoad,
                  "page-current-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }