<template>
  <div style="position: relative">
    <div>
      <!-- 通行记录 -->
      <head-layout head-title="通行记录"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad" @page-refresh-change="onLoad">
      </grid-layout>
    </div>

  </div>
</template>

<script>
  import {
    getEmployee,
  } from "@/api/safe/gate";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";


  export default {
    components: {
      GridLayout,
      HeadLayout,
    },
    
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        selectionList: [],
        tableOption: {
          // linklabel: "eqtName",
          selection: false,
          menu: false,
          column: [{
              label: "人员姓名",
              prop: "userName",
              align: "center",
            },
            {
              label: "通行时间",
              prop: "throughTime",
              align: "center",
            },
            {
              label: "项目名称",
              prop: "prjName",
              align: "center",
            },
            {
              label: "闸机名称",
              prop: "machineName",
              align: "center",
            },
            {
              label: "闸机区域",
              prop: "machineInstallSite",
              align: "center",
              overHidden: true,
            },
            {
              label: "闸机编号",
              prop: "machineCode",
              width: 100,
              align: "center",
              overHidden: true,
            },

          ],
        },
        headBtnOptions: [

        ],
        searchColumns: [{
            label: "人员姓名",
            prop: "userName",
            span: 4,
            placeholder: "请输入人员姓名",
          },
          {
            label: "项目名称",
            prop: "prjName",
            span: 4,
            placeholder: "请输入项目名称",
          },
          {
            label: "通行时间",
            prop: "time",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            span: 4,
            type: 'daterange',
            startPlaceholder: "进入时间",
            endPlaceholder: "离开时间",
          },
        ],
        tableData: [],

      };
    },
    computed: {
      ...mapGetters(["permission", "userInfo"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirduser_add, false),
          viewBtn: this.vaildData(this.permission.thirduser_view, false),
          delBtn: this.vaildData(this.permission.thirduser_delete, false),
          editBtn: this.vaildData(this.permission.thirduser_edit, false),
        };
      },
    },

    mounted() {
      this.onLoad(this.page, {});
    },
    methods: {

      // 搜索
      gridHeadSearch(searchForm) {
        if (searchForm.time) {
          searchForm.startThroughTime = searchForm.time[0] + ' 00:00:00';
          searchForm.endThroughTime = searchForm.time[1] + ' 23:59:59';
        } else {
          searchForm.startThroughTime = ''
          searchForm.endThroughTime = ''
        }
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.prjId = this.userInfo.dept_id
        getEmployee(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.tableLoading = false;
          } else {
            this.tableLoading = false;
          }
        });
      },

    },
  };

</script>

<style>
</style>
