<template>
  <div>
    <el-container>
      <!-- <CommonTree
        ref="commonTreeCore"
        treeTitle="组织列表"
        :searchTitle="searchTitle"
        :treeData="treeData"
        :defaultProps="leftDefaultProps"
        @getNodeClick="treeNodeClick"
        :treeExpand="false"
        node-key="id"
      /> -->
      <CommonTreeNew
        ref="commonTree"
        treeTitle="项目架构"
        :defaultProps="Props"
        :showCheckbox="false"
        @getNodeClick="treeNodeClick"
        :urlParmas="{
          tenantId: '',
          deptCategory: '',
          parentId: userInfo.deptId,
        }"
        node-key="id"
        @getTreeData="commonTreeData"
      ></CommonTreeNew>
      <el-container>
        <el-main>
          <head-layout
            head-title="指标数据"
            :head-btn-options="headBtnOptions"
            @head-add="headAddDialog"
          >
          </head-layout>
          <div class="searchTopBox">
            <div class="leftSearch">
              <div style="width: 100%; display: flex; margin-bottom: 6px">
                <el-select
                  style="width=80px"
                  size="mini"
                  class="searchBoxcc"
                  v-model="selectValue"
                  placeholder="请选择组织数据查看形式"
                  :clearable="false"
                >
                  <el-option
                    v-for="item in selectOption"
                    :key="item.opationValue"
                    :label="item.opationLabel"
                    :value="item.opationValue"
                  >
                  </el-option>
                </el-select>
                <el-input
                  style="width=80px"
                  v-model="searchForm.indicatorKey"
                  size="mini"
                  placeholder="请输入指标编号"
                  class="searchBox"
                  clearable
                ></el-input>
                <el-input
                  v-model="searchForm.indicatorName"
                  size="mini"
                  placeholder="请输入指标名称"
                  class="searchBox"
                  clearable
                ></el-input>
                <el-input
                  v-model="searchForm.orgName"
                  size="mini"
                  placeholder="请输入指标子类"
                  class="searchBox"
                  clearable
                ></el-input>
                <el-input
                  v-model="searchForm.year"
                  size="mini"
                  placeholder="请输入指标年份"
                  class="searchBox"
                  clearable
                ></el-input>

                <el-input
                  v-model="searchForm.month"
                  size="mini"
                  placeholder="请输入指标月份"
                  class="searchBox"
                  clearable
                ></el-input>
              </div>
            </div>
            <div class="rightSearch">
              <el-button-group>
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-search"
                  @click="gridHeadSearch"
                ></el-button>
                <el-button
                  size="mini"
                  icon="reset-refresh icon-refresh"
                  @click="gridHeadEmpty"
                  style="height: 28px"
                ></el-button>
              </el-button-group>
            </div>
          </div>
          <grid-layout
            ref="gridLayout"
            :data-total="page.total"
            @page-size-change="onLoad"
            @page-current-change="onLoad"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="selectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
          >
            <template #indicatorKey="{ row, index }">
              <el-link type="primary" @click="rowView(row, index)">{{
                row.indicatorKey
              }}</el-link>
            </template>
            <template #customBtn="{ row, index }">
              <el-button type="text" size="small" @click="rowCopy(row, index)">
                复制
              </el-button>
              <el-button type="text" size="small" @click="rowEdit(row, index)">
                编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="rowRemove(row, index)"
              >
                删除
              </el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>

      <el-dialog
        :title="this.dialogTitle"
        :visible.sync="dialogVisible"
        width="70%"
        hight="140vh"
        v-if="dialogVisible"
      >
        <el-form ref="form" :model="form" :rules="rules">
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="指标编码:"
                label-width="150px"
                prop="indicatorKey"
              >
                <el-input
                  maxlength="20"
                  v-model="form.indicatorKey"
                  clearable
                  :disabled="
                    this.type == 'view' ||
                    this.type == 'copy' ||
                    this.type == 'edit'
                  "
                />
                <label slot="label"
                  ><span style="color: red">*</span> 指标编码:</label
                >
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item
                label="指标名称:"
                label-width="150px"
                prop="indicatorName"
              >
                <el-input
                  maxlength="255"
                  v-model="form.indicatorName"
                  clearable
                  :disabled="this.type == 'view'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="组织:"
                label-width="150px"
                prop="companyName"
              >
                <el-select
                  v-model="form.companyName"
                  placeholder="请选择组织"
                  :disabled="this.type == 'view' || this.type == 'edit'"
                >
                  <el-option :value="treeDataValue" :label="treeDataLabel">
                    <el-tree
                      :data="this.deptDicData"
                      node-key="id"
                      ref="tree"
                      highlight-current
                      :props="defaultProps"
                      @node-click="handleNodeClick"
                    >
                    </el-tree
                  ></el-option>
                </el-select>
                <label slot="label"
                  ><span style="color: red">*</span> 组织:</label
                >
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统计年份" label-width="150px" prop="year">
                <el-input
                  v-model="form.year"
                  clearable
                  :disabled="this.type == 'view' || this.type == 'edit'"
                />
                <label slot="label"
                  ><span style="color: red">*</span> 统计年份:</label
                >
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统计月份:" label-width="150px" prop="month">
                <el-input
                  v-model="form.month"
                  clearable
                  :disabled="this.type == 'view' || this.type == 'edit'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="指标子类:"
                label-width="150px"
                prop="orgName"
              >
                <el-input
                  v-model="form.orgName"
                  clearable
                  :disabled="this.type == 'view' || this.type == 'edit'"
                />
                <label slot="label"
                  ><span style="color: red">*</span> 指标子类:</label
                >
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="指标值:" label-width="150px" prop="value">
                <el-input
                  v-model="form.value"
                  clearable
                  :disabled="this.type == 'view'"
                />
                <label slot="label"
                  ><span style="color: red">*</span> 指标值:</label
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer">
          <el-button
            v-show="this.type != 'view'"
            size="small"
            type="primary"
            @click="confirmDialog"
            >保存</el-button
          >
          <el-button size="small" @click="dialogVisible = false"
            >取消</el-button
          >
        </span>
      </el-dialog>
    </el-container>
  </div>
</template>


<script>
import CommonTree from "@/views/components/com_tree/index";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { getDataList, addData, remove } from "@/api/index/indexDataManage";
import { selectUserChildrenWithProject } from "@/api/system/dept";
import { mapGetters } from "vuex";
export default {
  components: {
    HeadLayout,
    GridLayout,
    CommonTree,
  },

  data() {
    return {
      form: {},
      searchForm: {},
      // 左侧组织树数据
      deptDicData: [],
      treeDataNeed: [], //默认
      selectTreeDataNeed: [], //点击左侧树
      leftDefaultProps: {
        children: "children",
        label: "title",
        value: "id",
      },
      Props: {
        children: "children",
        label: "title",
      },
      CompanyIdNeed: "",
      isCompanyIdAll: "",
      type: "",
      dialogTitle: "",
      dialogVisible: false,
      // 弹窗组织树
      treeData: [],
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "id",
        key: "id",
      },
      treeDataValue: "",
      selectValue: [],
      selectOption: [
        { opationValue: false, opationLabel: "所选组织指标数据" },
        { opationValue: true, opationLabel: "所选组织及下属组织指标数据" },
      ],
      rules: {
        indicatorKey: [
          {
            message: "请输入指标编码",
            trigger: "blur",
          },
        ],
        companyId: [
          {
            message: "请选择组织",
            trigger: "blur",
          },
        ],
        year: [
          {
            message: "请输入统计年份",
            trigger: "blur",
          },
        ],
        month: [
          {
            message: "请输入统计月份",
            trigger: "change",
            validator: (rule, value, callback) => {
              if (
                (this.form.month >= 1 && this.form.month <= 12) ||
                this.form.month == ""
              ) {
                callback();
              } else {
                callback(new Error("请输入统计月份"));
              }
            },
          },
        ],
      },
      listDataLoading: true,
      treeLoading: true,
      tableLoading: true,
      tableData: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      // 查询
      searchColumns: [
        {
          type: "请选择组织数据查看形式",
          prop: "",
          span: 4,
          placeholder: "请选择组织数据查看形式",
        },
        {
          prop: "indicatorKey",
          type: "input",
          span: 4,
          placeholder: "请输入指标编号",
        },
        {
          prop: "indicatorName",
          type: "input",
          span: 4,
          placeholder: "请输入指标名称",
        },
        {
          prop: "orgName",
          type: "input",
          span: 4,
          placeholder: "请输入指标子类",
        },
        {
          prop: "year",
          type: "input",
          span: 3,
          placeholder: "请输入指标年份",
        },
        {
          prop: "month",
          type: "input",
          span: 3,
          placeholder: "请输入指标月份",
        },
      ],

      tableOptions: {
        linklable: "indicatorKey",
        selection: false,
        menu: true,
        border: true,
        searchShow: true,
        searchMenuSpan: 6,
        menuFixed: "right", //操作列冻结
        column: [
          {
            label: "指标编号",
            prop: "indicatorKey",
            align: "center",
            width: 150,
            overHidden: true,
            slot: true,
          },
          {
            label: "指标名称",
            prop: "indicatorName",
            align: "left",
            overHidden: true,
          },
          {
            label: "组织",
            prop: "companyName",
            align: "left",
            overHidden: true,
          },
          {
            label: "指标子类",
            prop: "orgName",
            align: "left",
            overHidden: true,
          },
          {
            label: "指标年份",
            prop: "year",
            width: 90,
            align: "center",
            overHidden: true,
          },
          {
            label: "指标月份",
            prop: "month",
            width: 90,
            align: "center",
            overHidden: true,
          },
          {
            label: "指标值",
            prop: "value",
            width: 90,
            align: "center",
            overHidden: true,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    // treeOptions() {
    //   let that = this;
    //   return {
    //     defaultExpandAll: false,
    //     nodeKey: "id",
    //     lazy: true,
    //     treeLoad: function (node, resolve) {
    //       that.treeLoading = true;
    //       // 控制登录人查看
    //       getDept(that.userInfo.dept_id).then((res) => {
    //         const parentId =
    //           node.level === 0 ? res.data.data.parentId : node.data.id;
    //         // getDeptLazyTree(parentId).then((res) => {
    //         getDeptLazyTree(parentId).then((res) => {
    //           // const treeDataNeed = res.data.data;
    //           that.treeLoading = false;
    //           resolve(
    //             res.data.data.map((item) => {
    //               return {
    //                 ...item,
    //                 leaf: !item.hasChildren,
    //               };
    //             })
    //           );
    //         });
    //       });
    //     },
    //     menu: false,
    //     addBtn: false,
    //     // size: 'mini',
    //     props: {
    //       labelText: this.$t("cip.plat.sys.user.field.title"),
    //       label: "title",
    //       value: "value",
    //       children: "children",
    //     },
    //   };
    // },

    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    // 新增按钮
    headBtnOptions() {
      return [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
      ];
    },
  },

  mounted() {
    // 左侧组织树
    // this.lazyDeptTree();
  },

  created() {},

  methods: {
    handleNodeClick(data, node, nodeData) {
      console.log(data, "data");
      this.treeDataValue = data.value;
      this.treeDataLabel = data.title;
      this.form.companyName = data.title;
      this.form.companyId = data.value;
    },

    // 弹窗新增
    headAddDialog() {
      this.type = "add";
      this.dialogTitle = "指标数据-新增";
      this.form = {};
      if (this.selectTreeDataNeed == "") {
        this.form.companyName = this.treeDataNeed[0].deptName;
        this.form.companyId = this.treeDataNeed[0].id;
      } else {
        this.form.companyName = this.selectTreeDataNeed.deptName;
        this.form.companyId = this.selectTreeDataNeed.id;
      }
      this.dialogVisible = true;
    },
    // 行查看
    rowView(row, index) {
      this.type = "view";
      this.dialogTitle = "指标数据-查看";
      this.form = row;
      this.dialogVisible = true;
    },
    // 行复制
    rowCopy(row, index) {
      this.type = "copy";
      this.dialogTitle = "指标数据-复制";
      this.form = row;
      this.dialogVisible = true;
    },
    // 行编辑
    rowEdit(row, index) {
      this.type = "edit";
      this.dialogTitle = "指标数据-编辑";
      this.form = row;
      this.dialogVisible = true;
    },
    // 行删除
    rowRemove(row, index) {
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        remove(row.id).then((res) => {
          if (res.data.code == 200) {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },

    // 保存数据
    confirmDialog() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type == "copy") {
            this.form.id = "";
          }
          addData(this.form).then((res) => {
            this.onLoad(this.page, {});
            if (res.data.code === 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
            } else {
              this.$message.success(res.data.msg);
            }
            this.dialogVisible = false;
          });
        }
      });
    },
    // 查询
    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page, this.searchForm);
    },
    // 重置
    gridHeadEmpty() {
      this.selectValue = false;
      this.page.currentPage = 1;
      this.searchForm = {};
      this.onLoad(this.page, this.searchForm);
    },

    //获取树数据
    lazyDeptTree() {
      selectUserChildrenWithProject().then((res) => {
        let resData = res.data.data;
        this.treeData = resData;
        this.deptDicData = res.data.data;
        this.treeDataNeed = res.data.data;
        this.CompanyIdNeed = this.treeDataNeed[0].id;
        // this.form.companyName = this.treeDataNeed[0].deptName;
        this.selectValue = false;
        this.onLoad(this.page);
      });
    },
    treeNodeClick(node) {
      this.CompanyIdNeed = node.id;
      this.selectTreeDataNeed = node; //新增弹框组织选择默认数据
      this.selectValue = false;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    onLoad(page) {
      this.page = page;
      this.tableLoading = true;
      let params = {
        isCompanyIdAll: this.selectValue,
        companyId: this.CompanyIdNeed,
        ...this.searchForm,
      };
      getDataList(page.currentPage, page.pageSize, params).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchTopBox {
  padding: 14px 12px;
  width: calc(100% - 24px);
  display: flex;
  justify-content: space-between;
}
.leftSearch {
  width: 90%;
}
.searchBoxcc {
  width: 22% !important;
  margin-right: 5px;
}
.searchBox {
  width: 15% !important;
  margin-right: 5px;
}
</style>








