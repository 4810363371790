var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "10vh",
            title: _vm.title,
            visible: _vm.show,
            width: "70%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _vm.show
            ? _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    size: "medium",
                    height: "70vh",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      label: "排名",
                      width: "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "companyName",
                      align: "center",
                      label: "公司",
                    },
                  }),
                  _vm._l(_vm.tableName, function (item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: "_value" + (index != 0 ? index : ""),
                        align: "center",
                        label: item,
                      },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }