<template>
  <!-- 应急物资 -->
  <div style="position: relative" class="flex-container">
    <!-- <CommonTree
      ref="commonTree"
      treeTitle="项目架构"
      :treeData="treeData"
      :defaultProps="Props"
      :showCheckbox="false"
      @getNodeClick="handleNode"
      node-key="id"
    >
    </CommonTree> -->
    <CommonTreeNew
      ref="commonTree"
      treeTitle="项目架构"
      :defaultProps="Props"
      :showCheckbox="false"
      @getNodeClick="handleNode"
      :urlParmas="{
        tenantId: '',
        deptCategory: '',
        parentId: userInfo.deptId,
      }"
      node-key="id"
      @getTreeData="commonTreeData"
    ></CommonTreeNew>
    <div class="flex-one" style="background-color: #fff">
      <head-layout
        head-title="应急物资"
        :showIcon="false"
        :head-btn-options="headBtnOptions"
        @head-add="handleAdd"
        @head-import="handleImport"
        @head-export="handleExport"
        @head-delete="handleDelete"
      ></head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="currentChange"
        @page-size-change="sizeChange"
      >
        <template #files="{ row }">
          <img class="courseCoverImg" :src="row.imgUrl" />
        </template>
        <template #customBtn="{ row }">
          <el-button
            v-if="row.createUser == userInfo.user_id"
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowEdit(row)"
            >编辑
          </el-button>
          <el-button
            v-if="row.createUser == userInfo.user_id"
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowDel(row)"
            >删除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <add ref="add" @callback="callback"></add>
    <el-dialog
      title="应急物资导入"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          v-model="excelOption.excelForm"
          :upload-after="uploadAfter"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{ $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  ermaterialPage,
  ermaterialRemove,
  ermaterialList,
} from "@/api/contingency/emergencySupplies";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import add from "./add.vue";
import { exportBlob } from "@/api/common";
import { dateNow } from "@/util/date";
import { getToken } from "@/util/auth";
import { downloadXls } from "@/util/util";
import { mapGetters } from "vuex";
import CommonTree from "@/views/components/com_tree/index.vue";
import { getDeptTree } from "@/api/system/dept";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    add,
  },
  data() {
    return {
      treeNode: {},
      treeData: [],
      Props: {
        children: "children",
        label: "title",
      },
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableData: [],
      tableOption: {
        selection: true,
        column: [
          {
            label: "物资名称",
            prop: "materialName",
            align: "center",
            width: 150,
            overHidden: true,
          },
          {
            label: "规格型号",
            prop: "materialSpe",
            align: "center",
            overHidden: true,
          },
          {
            label: "数量",
            prop: "quantity",
            align: "center",
          },
          {
            label: "购置日期",
            prop: "purchaseDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "存放位置",
            prop: "location",
            align: "center",
            overHidden: true,
          },
          {
            label: "责任人",
            prop: "dutyUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "现场照片",
            prop: "files",
            align: "center",
            overHidden: true,
            slot: true,
          },
        ],
      },
      excelBox: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        excelForm: {},
        column: [
          {
            label: "数据导入",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            action: "/api/sinoma-hse-prj/ermaterial/import",
          },
          // {
          //   label: this.$t("cip.plat.sys.user.field.isCovered"),
          //   prop: "isCovered",
          //   type: "select",
          //   align: "center",
          //   width: 80,
          //   dataType: 'number',
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey"
          //   },
          //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yesNo",
          //   value: 0,
          //   slot: true,
          //   rules: [{
          //     required: true,
          //     message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.yes") + this.$t("cip.plat.sys.user.field.no") + this.$t("cip.plat.sys.user.field.isCovered"),
          //     trigger: "blur"
          //   }]
          // },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "导入",
          emit: "head-import",
          type: "button",
          icon: "",
          btnOptType: "import",
        },
        {
          label: "导出",
          emit: "head-export",
          type: "button",
          icon: "",
          btnOptType: "export",
        },
        {
          label: "删除",
          emit: "head-delete",
          type: "button",
          icon: "",
        },
      ];
    },
  },
  watch: {
    "excelForm.isCovered"() {
      if (this.excelForm.isCovered !== "") {
        const column = this.findObject(this.excelOption.column, "excelFile");
        column.action = `/api/sinoma-hse-prj/ermaterial/import?isCovered=${this.excelForm.isCovered}&orgId=${this.userInfo.dept_id}`;
      }
    },
  },
  mounted() {
    // this.getPrjstructureList();
    this.excelOption.column[0].action = `/api/sinoma-hse-prj/ermaterial/import?orgId=${this.userInfo.dept_id}`;
  },
  methods: {
    //项目组织架构
    getPrjstructureList() {
      const parentId = this.userInfo.dept_id;
      getDeptTree("", "", parentId).then((res) => {
        if ((res.data.code = 200)) {
          this.treeData = res.data.data;
          this.$refs.commonTree.setCurrentKey(this.treeData[0].id);
          this.$refs.commonTree.nodeClick(this.treeData[0], {});
        }
      });
    },
    handleNode(node) {
      this.treeNode = node;
      this.onLoad(this.page, {});
    },
    //   新增
    handleAdd() {
      this.$refs.add.init("add");
    },
    // 多选
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    // 全局删除
    handleDelete() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return ermaterialRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page, { planId: this.planId });
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 编辑
    rowEdit(row) {
      this.$refs.add.init("edit", row);
    },
    // 单个删除
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return ermaterialRemove(row.id);
        })
        .then(() => {
          this.onLoad(this.page, { planId: this.planId });
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 导入
    handleImport() {
      this.excelBox = true;
      this.excelOption.excelForm.excelFile = [];
    },
    uploadAfter(res, done, loading, column) {
      window.console.log(column);
      this.excelBox = false;
      this.onLoad(this.page);
      this.excelOption.excelForm.excelFile = [];
      done();
    },
    handleTemplate() {
      exportBlob(
        `/api/sinoma-hse-prj/ermaterial/exportTemplate?${
          this.website.tokenHeader
        }=${getToken()}`
      ).then((res) => {
        downloadXls(res.data, "应急物资模板" + ".xlsx");
      });
    },
    // 导出
    handleExport() {
      this.$confirm("是否导出应急物资数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        exportBlob(
          `/api/sinoma-hse-prj/ermaterial/export?orgId=${
            this.userInfo.dept_id
          }&${this.website.tokenHeader}=${getToken()}`
        ).then((res) => {
          downloadXls(res.data, `应急物资${dateNow()}.xlsx`);
        });
      });
    },
    // 页码--第几页
    currentChange(val) {
      this.page.currentPage = val.currentPage;
      this.onLoad(this.page, { planId: this.planId });
    },
    // 页码--多少页
    sizeChange(val) {
      this.page.pageSize = val.pageSize;
      this.onLoad(this.page, { planId: this.planId });
    },
    // 请求列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;

      ermaterialPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {}, { orgId: this.treeNode.id })
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records.map((item) => {
          return {
            ...item,
            imgUrl: item.files
              ? JSON.parse(item.files).length
                ? JSON.parse(item.files)[0].link
                : ""
              : "",
          };
        });
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
      });
      // ermaterialList({ orgId: this.treeNode.id }).then((res) => {
      //   const data = res.data.data;
      //   this.tableData = data.map((item) => {
      //     return {
      //       ...item,
      //       imgUrl: item.files
      //         ? JSON.parse(item.files).length
      //           ? JSON.parse(item.files)[0].link
      //           : ""
      //         : "",
      //     };
      //   });
      //   this.tableLoading = false;
      //   //重新渲染表格，解决勾选框错行问题
      //   this.$nextTick(() => {
      //     this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
      //   });
      // });
    },
    callback() {
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.courseCoverImg {
  width: 80px;
  padding: 5px;
}
::v-deep .avue-crud .el-table {
  height: calc(100vh - 219px) !important;
  max-height: calc(100vh - 219px) !important;
}
</style>
