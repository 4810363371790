var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "relative" } },
    [
      _c(
        "el-container",
        [
          _c("CommonTreeNew", {
            ref: "commonTree",
            attrs: {
              treeTitle: "项目架构",
              defaultProps: _vm.defaultProps,
              showCheckbox: false,
              urlParmas: {
                tenantId: "",
                deptCategory: "2,5",
                parentId: _vm.userInfo.deptId,
              },
              "node-key": "id",
            },
            on: {
              getNodeClick: _vm.handleNodeClick,
              getTreeData: _vm.commonTreeData,
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "安全技术交底",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-add": _vm.headAdd },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  editType: _vm.inline,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "row-view": _vm.rowView,
                  "row-download": _vm.rowDownload,
                  "row-edit": _vm.rowEdit,
                  "row-remove": _vm.rowRemove,
                  "row-setting": _vm.rowSetting,
                },
                scopedSlots: _vm._u([
                  {
                    key: "hdCode",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.rowView(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.hdCode))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "status",
                    fn: function ({ row, index }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.getDicValue(_vm.statusList, row.status))
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "customBtn",
                    fn: function ({ row, index }) {
                      return [
                        (!row.managerFiles || !row.safetyFiles) &&
                        ((row.handoverId == _vm.userInfo.user_id &&
                          (!row.handoverFiles ||
                            !row.receiveId ||
                            row.receiveId == -1)) ||
                          (row.receiveId == _vm.userInfo.user_id &&
                            !row.receiveFiles) ||
                          (row.managerFlg &&
                            !row.managerFiles &&
                            row.receiveFiles) ||
                          (row.safetyFlg &&
                            !row.safetyFiles &&
                            row.receiveFiles))
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.rowEdit(row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              row.handoverId == _vm.userInfo.user_id &&
                              row.status == 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowRemove(row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.rowView(row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }