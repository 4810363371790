var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainReportBox" },
    [
      _c(
        "div",
        { staticClass: "topReportBox" },
        [
          _c("div", { staticClass: "leftReportTitle" }, [
            _vm._v("\n        填报汇总\n      "),
          ]),
          _c("div", { staticClass: "rightReportSidder" }, [
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "menu-list",
                    attrs: {
                      "default-active": _vm.activeIndex,
                      mode: "horizontal",
                    },
                  },
                  [
                    _vm._l(_vm.topSidderData, function (item) {
                      return [
                        item.children.length == 0
                          ? _c(
                              "el-menu-item",
                              {
                                key: item.id,
                                attrs: { index: item.id },
                                on: {
                                  click: function ($event) {
                                    return _vm.openReport(item)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-menu",
                                  staticStyle: {
                                    "font-size": "20px",
                                    color: "#333333",
                                  },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.reportCategoryName) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _c(
                              "el-submenu",
                              { key: item.id, attrs: { index: item.id } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      attrs: { title: item.reportCategoryName },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-menu",
                                        staticStyle: {
                                          "font-size": "20px",
                                          color: "#333333",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.reportCategoryName)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._l(
                                  item.children,
                                  function (oneChild, oneIndex) {
                                    return _c(
                                      "el-menu-item",
                                      {
                                        key: oneIndex,
                                        staticStyle: {
                                          "background-color": "#FFFFFF",
                                        },
                                        attrs: { index: oneChild.id },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openReport(oneChild)
                                          },
                                        },
                                      },
                                      [
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "oneChildNameBox" },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-s-order",
                                                staticStyle: {
                                                  "font-size": "20px",
                                                  color: "#333333",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "oneChildName" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      oneChild.reportCategoryName
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                      ],
                                      2
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-button",
            {
              staticClass: "closeBtn",
              attrs: { size: "medium", icon: "el-icon-close" },
              on: { click: _vm.occlude },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "rightTags" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.openTag, "tab-remove": _vm.removeTag },
              model: {
                value: _vm.activeTag,
                callback: function ($$v) {
                  _vm.activeTag = $$v
                },
                expression: "activeTag",
              },
            },
            _vm._l(_vm.tagList, function (item) {
              return _c("el-tab-pane", {
                key: item.id,
                attrs: {
                  closable: item.name != "home",
                  label: item.reportCategoryName,
                  name: item.reportCategoryName,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm.showForm.home
        ? _c("Home", { ref: "Home", on: { runApproval: _vm.runApproval } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }