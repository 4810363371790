var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prj-index" },
    [
      _c(
        "div",
        { staticClass: "home-statistics" },
        [
          _c(
            "el-card",
            {
              staticClass: "todo-items card-item",
              attrs: { shadow: "always" },
            },
            [
              _c("div", { staticClass: "svg-icon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "27",
                      height: "25",
                      viewBox: "0 0 27 25",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M13.468 0.896693L1.22166 6.24056C0.814833 6.41808 0.823811 6.99822 1.23594 7.16307L13.4971 12.0675C13.7397 12.1646 14.0106 12.1628 14.2519 12.0627L26.061 7.16074C26.4651 6.99302 26.4741 6.42393 26.0756 6.24347L14.2805 0.902278C14.0226 0.785528 13.7274 0.783498 13.468 0.896693Z",
                        fill: "white",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M2.30545 10.2606C2.42803 10.2071 2.56693 10.205 2.69111 10.2547L13.6665 14.6448C13.7878 14.6933 13.9233 14.6925 14.0439 14.6424L24.601 10.2602C24.7288 10.2071 24.8729 10.2094 24.999 10.2665L26.0558 10.7451C26.4543 10.9255 26.4453 11.4946 26.0413 11.6624L14.0439 16.6424C13.9233 16.6925 13.7878 16.6933 13.6665 16.6448L1.21616 11.6647C0.804037 11.4998 0.79506 10.9197 1.20189 10.7422L2.30545 10.2606Z",
                        fill: "white",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M2.30545 14.3041C2.42803 14.2506 2.56693 14.2484 2.69111 14.2981L13.6665 18.6883C13.7878 18.7368 13.9233 18.7359 14.0439 18.6858L24.601 14.3036C24.7288 14.2506 24.8729 14.2529 24.999 14.31L26.0558 14.7885C26.4543 14.969 26.4453 15.5381 26.0413 15.7058L14.0439 20.6858C13.9233 20.7359 13.7878 20.7368 13.6665 20.6883L1.21616 15.7081C0.804037 15.5433 0.79506 14.9632 1.20189 14.7856L2.30545 14.3041Z",
                        fill: "white",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M2.30545 18.3475C2.42803 18.294 2.56693 18.2919 2.69111 18.3416L13.6665 22.7317C13.7878 22.7802 13.9233 22.7794 14.0439 22.7293L24.601 18.3471C24.7288 18.294 24.8729 18.2963 24.999 18.3534L26.0558 18.832C26.4543 19.0125 26.4453 19.5815 26.0413 19.7493L14.0439 24.7293C13.9233 24.7794 13.7878 24.7803 13.6665 24.7317L1.21616 19.7516C0.804037 19.5867 0.79506 19.0066 1.20189 18.8291L2.30545 18.3475Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-title" }, [_vm._v("待办事项")]),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "num-box" }, [
                    _c(
                      "span",
                      {
                        staticClass: "underline-text num",
                        on: { click: _vm.runTodo },
                      },
                      [_vm._v(_vm._s(_vm.todoNum))]
                    ),
                    _c("span", { staticClass: "unit" }, [_vm._v("项")]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "security-inspection card-item",
              attrs: { shadow: "always" },
            },
            [
              _c("div", { staticClass: "svg-icon" }, [
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      t: "1726190291733",
                      viewBox: "0 0 1024 1024",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "p-id": "26546",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                      width: "27",
                      height: "25",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M867.584 160.192c-149.632-16.928-262.208-57.408-334.592-120.352l-19.04-16.544-20.544 14.656C379.968 118.944 267.776 160 160 160H128v448c0 137.344 121.088 261.92 370.208 380.864l13.088 6.24 13.344-5.728C771.072 883.52 896 755.232 896 608V163.424l-28.416-3.232zM832 608c0 116.8-107.392 223.36-319.328 316.8C299.872 821.024 192 714.464 192 608V222.976c104.672-6.784 211.584-46.688 318.496-118.944C587.232 162.528 695.168 201.536 832 220.256V608z",
                        "p-id": "26547",
                        fill: "#ffffff",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M359.776 468.672a32 32 0 1 0-47.968 42.4l121.792 137.824c12.608 14.24 30.176 21.568 47.904 21.568a64.384 64.384 0 0 0 49.696-23.52l197.6-242.72a32 32 0 0 0-49.632-40.416l-197.6 242.688-121.792-137.824z",
                        "p-id": "26548",
                        fill: "#ffffff",
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-title" }, [_vm._v("安全巡检")]),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "num-box" }, [
                    _c("span", { staticClass: "underline-text num" }, [
                      _vm._v(_vm._s(_vm.safeStatis.projectCount || 0)),
                    ]),
                    _c("span", { staticClass: "unit" }, [_vm._v("次")]),
                  ]),
                  _c("div", { staticClass: "card-b-tips" }, [
                    _c("div", { staticClass: "tips-1" }, [
                      _vm._v(
                        "\n              今日巡检：" +
                          _vm._s(_vm.safeStatis.todayCount || 0) +
                          "次\n            "
                      ),
                    ]),
                    _c("div", { staticClass: "split-line" }, [_vm._v("|")]),
                    _c("div", { staticClass: "tips-2" }, [
                      _vm._v(
                        "人员：" + _vm._s(_vm.safeStatis.userCount || 0) + "人"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "safety-training card-item",
              attrs: { shadow: "always" },
            },
            [
              _c("div", { staticClass: "svg-icon" }, [
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      t: "1726189740886",
                      viewBox: "0 0 1024 1024",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "p-id": "5979",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                      width: "27",
                      height: "25",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M938.666667 786.346667c0 14.933333-11.946667 26.88-26.453334 26.88h-197.973333c14.933333-3.413333 26.026667-16.64 26.026667-32.426667 0 0 0-122.026667-88.32-166.4-37.546667-18.773333-40.106667-15.36-58.88-19.626667l0.426666-52.906666s-20.48-15.786667-26.88-65.706667c-12.8 3.84-17.066667-15.36-17.92-27.306667-0.853333-11.52-7.253333-48.213333 8.106667-44.8-3.413333-24.32-5.546667-46.506667-4.266667-58.026666 3.84-40.96 43.093333-83.626667 103.253334-87.04 70.826667 2.986667 98.56 46.08 102.826666 87.04 1.28 11.52-1.28 33.706667-4.693333 58.026666 15.786667-3.413333 8.96 33.28 8.106667 44.8-0.853333 11.946667-5.12 31.146667-17.92 27.306667-6.4 49.92-26.88 65.706667-26.88 65.706667l0.426666 52.906666s12.8 7.68 67.84 30.72 37.546667 5.973333 82.346667 28.16c70.826667 34.986667 70.826667 132.693333 70.826667 132.693334z",
                        fill: "#ffffff",
                        opacity: ".99",
                        "p-id": "5980",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M539.733333 605.866667c60.586667 25.173333 41.386667 6.4 90.88 31.146666 77.653333 39.253333 77.653333 146.773333 77.653334 146.773334 0 16.213333-13.226667 29.44-29.013334 29.44H114.346667c-16.213333 0-29.013333-13.226667-29.013334-29.44 0 0 0-107.52 77.653334-146.773334 49.066667-24.746667 30.293333-4.693333 90.453333-29.866666 60.586667-25.173333 74.666667-34.133333 74.666667-34.133334l0.426666-58.453333s-22.613333-17.493333-29.44-72.533333c-14.08 4.266667-18.773333-16.64-19.626666-30.293334-0.853333-12.8-8.106667-52.906667 8.96-49.493333-3.413333-26.88-5.973333-51.2-4.693334-64 4.266667-44.8 47.36-92.16 113.493334-95.573333 77.653333 3.413333 108.373333 50.346667 113.066666 95.573333 1.28 12.8-1.28 37.12-5.12 64 17.066667-3.413333 9.813333 36.693333 8.96 49.493333-0.853333 13.226667-5.546667 34.133333-19.626666 29.866667-7.253333 54.613333-29.866667 72.106667-29.866667 72.106667l0.426667 58.026666c0.426667 0.853333 14.506667 8.96 74.666666 34.133334z",
                        fill: "#ffffff",
                        opacity: ".99",
                        "p-id": "5981",
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-title" }, [
                  _vm._v("安全培训率"),
                ]),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "num-box" }, [
                    _c("span", { staticClass: "underline-text num" }, [
                      _vm._v(
                        _vm._s(
                          _vm.safeStatistics[1]
                            ? (
                                (_vm.safeStatistics[1] /
                                  _vm.safeStatistics[3]) *
                                100
                              ).toFixed(2) + "%"
                            : "0%"
                        )
                      ),
                    ]),
                    _c("span", { staticClass: "unit" }, [_vm._v("合格率")]),
                  ]),
                  _c("div", { staticClass: "card-b-tips" }, [
                    _c("div", { staticClass: "tips-1" }, [
                      _vm._v(
                        "已合格：" + _vm._s(_vm.safeStatistics[1] || 0) + "人"
                      ),
                    ]),
                    _c("div", { staticClass: "split-line" }, [_vm._v("|")]),
                    _c("div", { staticClass: "tips-2" }, [
                      _vm._v(
                        "未合格：" + _vm._s(_vm.safeStatistics[2] || 0) + "人"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "safe-production card-item",
              attrs: { shadow: "always" },
            },
            [
              _c("div", { staticClass: "svg-icon" }, [
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      t: "1726189969135",
                      viewBox: "0 0 1024 1024",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "p-id": "12554",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                      width: "27",
                      height: "25",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M30.552 791.273c4.654 16.29 23.272 23.272 37.236 18.618 16.291-4.655 23.273-23.273 18.618-37.236C16.588 581.818 84.08 374.69 237.68 256c11.636-9.31 23.273-16.29 37.236-25.6 25.6-16.29 53.528-30.255 83.782-39.564 58.182-20.945 118.691-30.254 176.873-27.927h25.6c6.982 0 11.636 0 18.618 2.327 30.255 4.655 58.182 11.637 88.436 23.273 235.055 86.11 356.073 346.764 269.964 581.818-4.654 16.291 2.327 32.582 18.618 37.237 16.291 4.654 32.582-2.328 37.237-18.619 41.89-116.363 39.563-239.709 0-349.09h2.327C921.897 239.709 738.043 111.709 537.897 102.4h-48.873c-200.145 9.31-384 137.31-458.472 337.455-39.564 109.381-41.891 232.727 0 351.418z m707.49-386.328a29.498 29.498 0 0 0-41.89 0L521.606 579.491c-4.654-2.327-9.309-2.327-13.963-2.327-32.582 0-58.182 25.6-58.182 58.181s25.6 58.182 58.182 58.182 58.181-25.6 58.181-58.182c0-4.654 0-9.309-2.327-16.29l174.546-174.546c11.636-11.636 11.636-30.254 0-39.564z m228.073 458.473H58.48c-16.29 0-30.255 13.964-30.255 30.255 0 16.29 13.964 30.254 30.255 30.254h907.636c16.291 0 30.255-13.963 30.255-30.254 0-16.291-13.964-30.255-30.255-30.255z",
                        "p-id": "12555",
                        fill: "#ffffff",
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-title" }, [
                  _vm._v("安全生产工时"),
                ]),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "num-box" }, [
                    _c("span", { staticClass: "underline-text num" }, [
                      _vm._v(_vm._s(_vm.statistics.produceWorkingDays * 24)),
                    ]),
                    _c("span", { staticClass: "unit" }, [_vm._v("工时")]),
                  ]),
                  _c("div", { staticClass: "card-b-tips" }, [
                    _c("div", { staticClass: "tips-1" }, [
                      _vm._v(
                        "\n              安全生产：" +
                          _vm._s(_vm.statistics.produceWorkingDays) +
                          "天\n            "
                      ),
                    ]),
                    _c("div", { staticClass: "split-line" }, [_vm._v("|")]),
                    _c("div", { staticClass: "tips-2" }, [
                      _vm._v(
                        "距完工：" + _vm._s(_vm.statistics.finishDay) + "天"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "hazard-source-warning card-item",
              attrs: { shadow: "always" },
            },
            [
              _c("div", { staticClass: "svg-icon" }, [
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      t: "1726190258300",
                      viewBox: "0 0 1024 1024",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "p-id": "24524",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                      width: "27",
                      height: "25",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M540.444 976.593c-195.318 0-352.71-157.393-352.71-352.712 0-79.644 36.029-182.044 113.777-221.866 5.689-3.793 11.378-1.896 17.067 0 5.689 3.792 9.481 9.481 9.481 15.17 0 0 7.585 73.956 39.822 128.948v-3.792C364.09 424.77 358.4 204.8 585.956 117.57c5.688-1.897 13.274-1.897 17.066 1.896 5.69 3.792 7.585 9.481 7.585 15.17v17.067c0 121.363 0 208.592 41.719 284.444 11.378-58.785 51.2-117.57 138.43-142.222 5.688-1.896 11.377 0 17.066 3.793 5.69 3.792 7.585 9.481 7.585 15.17 0 75.852 20.86 125.155 41.719 172.563 18.963 41.718 36.03 81.54 36.03 136.533 0 197.215-159.29 354.608-352.712 354.608zM293.926 451.319C248.415 491.14 223.763 563.2 223.763 623.88c0 174.46 140.326 314.786 314.785 314.786 174.46 0 314.785-140.326 314.785-314.786 0-45.51-15.17-81.54-34.133-121.362-18.963-43.615-41.719-91.023-45.511-161.186-96.711 39.823-91.022 132.741-89.126 151.704 1.896 7.585-3.793 17.067-11.378 18.963-7.585 3.793-17.066 1.896-22.755-5.689-79.645-92.918-81.541-199.111-81.541-343.23-176.356 83.438-170.667 265.482-166.874 377.363 0 22.756 1.896 43.615 0 58.786 0 7.585-5.69 13.274-11.378 15.17-5.689 1.896-13.274 1.896-18.963-1.896-47.407-39.823-68.267-117.57-77.748-161.185z",
                        fill: "#ffffff",
                        "p-id": "24525",
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-title" }, [
                  _vm._v("危险源预警"),
                ]),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "num-box" }, [
                    _c("span", { staticClass: "underline-text num" }, [
                      _vm._v(_vm._s(_vm.statistics.thisMonthNum || 0)),
                    ]),
                    _c("span", { staticClass: "unit" }, [
                      _vm._v("项(本月发布)"),
                    ]),
                  ]),
                  _c("div", { staticClass: "card-b-tips" }, [
                    _c("div", { staticClass: "tips-1" }, [
                      _vm._v(
                        "已辨识：" + _vm._s(_vm.statistics.riskNum || 0) + "项"
                      ),
                    ]),
                    _c("div", { staticClass: "split-line" }, [_vm._v("|")]),
                    _c("div", { staticClass: "tips-2" }, [
                      _vm._v(
                        "\n              累计发布：" +
                          _vm._s(_vm.statistics.totalPublish || 0) +
                          "项\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "home-line-content" }, [
        _c(
          "div",
          { staticClass: "echarts block-box" },
          [
            _vm._m(0),
            _vm.hiddenDangerObj.reportList.length
              ? [
                  _c("div", {
                    staticClass: "content",
                    attrs: { id: "dual-echarts" },
                  }),
                ]
              : _c("el-empty", {
                  staticClass: "content",
                  attrs: { description: "暂无数据" },
                }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "echarts block-box" },
          [
            _vm._m(1),
            _vm.projectHiddenStatisticDTOList.length
              ? _c("div", {
                  staticClass: "content",
                  attrs: { id: "hazards-echarts" },
                })
              : _c("el-empty", {
                  staticClass: "content",
                  attrs: { description: "暂无数据" },
                }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "home-line-content" }, [
        _c(
          "div",
          { staticClass: "echarts block-box" },
          [
            _vm._m(2),
            _vm.inCompanyStatistical.length
              ? _c("div", {
                  staticClass: "content",
                  attrs: { id: "personnel-echarts" },
                })
              : _c("el-empty", {
                  staticClass: "content",
                  attrs: { description: "暂无数据" },
                }),
          ],
          1
        ),
        _c("div", { staticClass: "pre-class-list block-box" }, [
          _vm._m(3),
          !_vm.classList.length
            ? _c(
                "div",
                { staticClass: "class-list content" },
                [_c("el-empty", { attrs: { description: "暂无数据" } })],
                1
              )
            : _c(
                "div",
                { staticClass: "class-list content" },
                _vm._l(_vm.classList, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "list-item" },
                    [
                      _c("el-image", {
                        staticStyle: {
                          width: "260px",
                          height: "120px",
                          "flex-shrink": "0",
                        },
                        attrs: {
                          src: item.filesUser[0].link || "",
                          fit: _vm.fit,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showFile(item.filesUser[0].link)
                          },
                        },
                      }),
                      _c("div", { staticClass: "list-content" }, [
                        _c("div", { staticClass: "meetingContents" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.meetingContents) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
        ]),
      ]),
      _c("ViewImage", { ref: "viewImage" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "icon" }),
      _c("div", { staticClass: "text" }, [_vm._v("一岗双责执行统计")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "icon" }),
      _c("div", { staticClass: "text" }, [_vm._v("安全隐患分析")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "icon" }),
      _c("div", { staticClass: "text" }, [_vm._v("进场人员统计")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "icon" }),
      _c("div", { staticClass: "text" }, [_vm._v("班前会及交底")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }