<template>
  <div style="display: relative">
    <el-container>
      <!-- <CommonTree
                ref="commonTreeCore"
                treeTitle="组织架构"
                :searchTitle="searchTitle"
                :treeData="treeData"
                :defaultProps="defaultProps"
                @getNodeClick="handleNodeClick"
                :isShowdig="false"
                :showCheckbox="false"
                node-key="id"
                :treeExpand="false"
                :defaultExpandedKeys="defaultExpandedKeys"
                /> -->
      <!-- :show="show"
                :isZoom="true"
                @showChange="showChange"  -->
      <CommonTreeNew
        ref="commonTree"
        treeTitle="项目架构"
        :defaultProps="defaultProps"
        :showCheckbox="false"
        @getNodeClick="handleNodeClick"
        :urlParmas="{
          tenantId: '',
          deptCategory: '2,5',
          parentId: userInfo.deptId,
        }"
        node-key="id"
        @getTreeData="commonTreeData"
      ></CommonTreeNew>
      <el-main>
        <head-layout
          head-title="安全技术交底"
          :head-btn-options="headBtnOptions"
          @head-add="headAdd"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <!-- :grid-row-btn="gridRowBtn"  -->
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          :editType="inline"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @row-view="rowView"
          @row-download="rowDownload"
          @row-edit="rowEdit"
          @row-remove="rowRemove"
          @row-setting="rowSetting"
        >
          <template #hdCode="{ row }">
            <el-link type="primary" @click="rowView(row)">{{
              row.hdCode
            }}</el-link>
          </template>
          <template #status="{ row, index }">
            <span>{{ getDicValue(statusList, row.status) }}</span>
          </template>
          <template #customBtn="{ row, index }">
            <!-- 可编辑情况：
                            1. 当前登录人为发起人且未签字
                            2. 当前登录人为被交底人且未签字
                            3. 发起人与被交底人均签字且当前登录人为项目经理或安全员且均为签字
                          -->
            <template
              v-if="
                (!row.managerFiles || !row.safetyFiles) &&
                ((row.handoverId == userInfo.user_id &&
                  (!row.handoverFiles ||
                    !row.receiveId ||
                    row.receiveId == -1)) ||
                  (row.receiveId == userInfo.user_id && !row.receiveFiles) ||
                  (row.managerFlg && !row.managerFiles && row.receiveFiles) ||
                  (row.safetyFlg && !row.safetyFiles && row.receiveFiles))
              "
            >
              <el-button type="text" @click="rowEdit(row)">编辑</el-button>
              <el-button
                type="text"
                v-if="row.handoverId == userInfo.user_id && row.status == 0"
                @click="rowRemove(row)"
                >删除</el-button
              >
            </template>
            <template v-else>
              <el-button type="text" @click="rowView(row)">查看</el-button>
              <!-- <el-button type="text" @click="rowRemove(row)">删除</el-button> -->
            </template>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
// import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  prjSafetyHandoverPage,
  prjSafetyHandoverRemove,
} from "@/api/prjSafetyHandover/index";
import { mapGetters } from "vuex";
import { downloadFileBlob } from "@/util/util";
import { dateFormat } from "@/util/date";
import { treeByCategorys } from "@/api/system/dept";
import { deptChildTree } from "@/api/reportTemplate";
import { getDictionaryCode } from "@/api/system/dictbiz";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
  },

  data() {
    return {
      nodeData: {},
      treeData: [],
      searchTitle: "deptName",
      defaultProps: {
        children: "children",
        label: "title",
      },
      searchColumns: [
        {
          prop: "handoverUser",
          placeholder: "人员姓名",
          span: 4,
        },
        {
          prop: "hdTime",
          placeholder: "交底日期",
          span: 4,
          type: "date-format",
        },
      ],
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      dialogVisible: false,
      remaindForm: {
        dateRemind: 1,
        noticeUserId: "",
        noticeUserName: "",
      },
      dialogLoading: false,
      settingId: "",
      headers: { Authorization: this.$store.getters.token },
      // operition: false,
      fileUrl: {},
      imageDiavlog: false,
      userVisible: false,
      show: false,
      defaultExpandedKeys: [],
      statusList: [],
    };
  },

  created() {
    this.getDictionaryCode();
    //  this.lazyDeptTree()
    // this.onLoad(this.page)
  },

  computed: {
    ...mapGetters(["permission", "colorName", "userInfo"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.licenceEnterprise_add, true),
        viewBtn: this.vaildData(this.permission.licenceEnterprise_view, true),
        editBtn: this.vaildData(this.permission.licenceEnterprise_edit, true),
        delBtn: this.vaildData(this.permission.licenceEnterprise_delete, true),
        downloadBtn: this.vaildData(
          this.permission.licenceEnterprise_download,
          true
        ),
        settingBtn: this.vaildData(
          this.permission.licenceEnterprise_setting,
          true
        ),
      };
    },

    headBtnOptions() {
      let buttonBtn = [];
      if (this.permissionList.addBtn) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },

    tableOptions() {
      return {
        menuFixed: "right",
        customRowAddBtn: false,
        // menuWidth: 260,
        selection: false,
        linklabel: "hdCode",
        column: [
          {
            label: "单据编号",
            prop: "hdCode",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "发起人",
            prop: "handoverUser",
            align: "center",
            overHidden: true,
          },
          {
            label: "区域",
            prop: "areaName",
            align: "center",
            overHidden: true,
          },
          {
            label: "交底日期",
            prop: "hdTime",
            align: "center",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            overHidden: true,
            slot: true,
            // dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=safety_handover_sataus`,
            // props: {
            //     label: 'dictValue',
            //     value: 'dictKey',
            // },
          },
          {
            label: "创建日期",
            prop: "createTime",
            align: "center",
            overHidden: true,
            width: "170px",
          },
        ],
      };
    },
  },

  methods: {
    showChange(v) {
      this.show = v;
    },
    /** 左侧列表操作 */
    //获取树数据
    lazyDeptTree() {
      // treeByCategorys({categorys:5,parentId:this.userInfo.dept_id}).then((res) => {
      deptChildTree().then((res) => {
        this.treeData = [res.data.data];
        this.defaultExpandedKeys = [this.treeData[0].id];
      });
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.page.currentPage = 1;
      this.query.prjId = data.id;
      this.onLoad(this.page);
      // if(data.deptCategory == 5){
      // } else {
      //     this.$refs.gridLayOut.page.total = 0
      //     this.page.total = 0
      //     this.tableData = []
      // }
    },

    getDictionaryCode() {
      getDictionaryCode("safety_handover_sataus").then((res) => {
        this.statusList = res.data.data;
      });
    },

    getDicValue(dic, key) {
      let data = dic.find((val) => val.dictKey == key);
      return data ? data.dictValue : "";
    },

    /**----end---- */
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      // this.operition = false
      this.page = page;
      let queryObj = { ...this.query };
      // delete queryObj.certificateType_search
      prjSafetyHandoverPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, queryObj)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      query.certificateType = query.certificateType_search
        ? query.certificateType_search.toString()
        : "";
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },

    headAdd() {
      if (this.nodeData.id && this.nodeData.deptCategory == 5) {
        const { id, title, deptCode } = this.nodeData;
        // 新增
        this.$router.push({
          path: "/business/prjSafetyHandover/form",
          query: {
            row: JSON.stringify({
              prjId: id,
              prjName: title,
              prjCode: deptCode,
            }),
            type: "add",
          },
        });
      } else {
        this.$message.warning("请先选择项目");
      }
    },

    rowEdit(row, index) {
      this.$router.push({
        path: "/business/prjSafetyHandover/form",
        query: {
          row: JSON.stringify({
            id: row.id,
            managerFlg: row.managerFlg,
            safetyFlg: row.safetyFlg,
          }),
          type: "edit",
        },
      });
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        prjSafetyHandoverRemove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },

    rowView(row) {
      this.$router.push({
        path: "/business/prjSafetyHandover/form",
        query: {
          row: JSON.stringify({
            id: row.id,
            managerFlg: row.managerFlg,
            safetyFlg: row.safetyFlg,
          }),
          type: "view",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
