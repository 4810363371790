<template>
  <div>
    <!--    <head-layout head-title="待我填报"></head-layout>-->
    <div class="homeTopTabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="待填报" name="first"></el-tab-pane>
        <el-tab-pane label="已填报" name="second"></el-tab-pane>
      </el-tabs>
    </div>
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      class="home"
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="rowView"
      @page-current-change="onLoad"
      @page-size-change="onLoad">
      <template #customBtn="{row}">
        <!--        <el-button-->
        <!--          type="text"-->
        <!--          @click="fillEdit(row)"-->
        <!--        > <span v-if="activeName=='first'">填报</span><span v-else>查看</span>-->
        <!--        </el-button>-->
        <el-button
          type="text"
          @click="fill(row)"
        ><span v-if="activeName=='first'">填报</span><span v-else>查看</span>
        </el-button>
        <el-button
          type="text"
          @click="fillHistory(row)"
        ><span v-if="activeName=='first'">填报历史</span>
        </el-button>
      </template>
    </grid-layout>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '政府部门安全生产检查及处罚情况编辑' : (this.safeType == 'view' ? '政府部门安全生产检查及处罚情况查看' : '政府部门安全生产检查及处罚情况新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="protectionShowDialog"
      v-if="protectionShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      @close="globalBoxClose"
    >
      <ProtectionDialog ref="ProtectionDialog" :protectionQuery="protectionQuery"
                        @closeDialog="protectionCloseDialog"></ProtectionDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '安全生产月报报表编辑' : (this.safeType == 'view' ? '安全生产月报报表查看' : '安全生产月报报表新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="safetyShowDialog"
      v-if="safetyShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="globalBoxClose"
    >
      <SafetyDialog ref="SafetyDialog" :safetyQuery="safetyQuery" @closeDialog="safetyCloseDialog"></SafetyDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '安全活动统计报表编辑' : (this.safeType == 'view' ? '安全活动统计报表查看' : '安全活动统计报表新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="actionShowDialog"
      v-if="actionShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="globalBoxClose"
    >
      <ActionDialog ref="ActionDialog" :actionQuery="actionQuery" @closeDialog="actionCloseDialog"></ActionDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '安全生产事故情况报表编辑' : (this.safeType == 'view' ? '安全生产事故情况报表查看' : '安全生产事故情况报表新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="accidentShowDialog"
      v-if="accidentShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="globalBoxClose"
    >
      <AccidentDialog ref="AccidentDialog" :accidentQuery="accidentQuery"
                      @closeDialog="accidentCloseDialog"></AccidentDialog>
    </el-dialog>
    <el-dialog
      :title="this.safeType == 'edit' ? '境外项目人员信息编辑' : (this.safeType == 'view' ? '境外项目人员信息查看' : '境外项目人员信息新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="overseasShowDialog"
      width="70%"
      top="5vh"
      @close="globalBoxClose"
    >
      <OverseasProjectDialog ref="overseasProjectFillingEdit" :overseasProjectQuery="overseasProjectQuery"
                             v-if="overseasShowDialog"
                             @overseasDialog="overseasCloseDialog"></OverseasProjectDialog>
    </el-dialog>
    <el-dialog
      :title="this.safeType == 'edit' ? '境内项目人员信息编辑' : (this.safeType == 'view' ? '境内项目人员信息查看' : '境内项目人员信息新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="domesticShowDialog"
      width="70%"
      top="5vh"
      @close="globalBoxClose"
    >
      <DomesticProjectFormDialog ref="OverseasProjectFillingEdit" :domesticQuery="domesticQuery"
                                 v-if="domesticShowDialog"
                                 @domesticDialog="domesticCloseDialog"></DomesticProjectFormDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '生态环保月报报表编辑' : (this.safeType == 'view' ? '生态环保月报报表查看' : '生态环保月报报表新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="ecologicalShowDialog"
      v-if="ecologicalShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
      @close="globalBoxClose"
    >
      <EcologicalDialog ref="EcologicalDialog" :ecologicalQuery="ecologicalQuery"
                        @closeDialog="ecologicalCloseDialog"></EcologicalDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '政府部门生态环保检查及处罚情况编辑' : (this.safeType == 'view' ? '政府部门生态环保检查及处罚情况查看' : '政府部门生态环保检查及处罚情况新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="governmentDepartmentShowDialog"
      v-if="governmentDepartmentShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      @close="globalBoxClose"
    >
      <GovernmentDepartmentDialog ref="GovernmentDepartmentDialog"
                                  :governmentDepartmentQuery="governmentDepartmentQuery"
                                  @closeDialog="governmentDepartmentCloseDialog"></GovernmentDepartmentDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      :title="this.safeType == 'edit' ? '隐患排查治理统计编辑' : (this.safeType == 'view' ? '隐患排查治理统计查看' : '隐患排查治理统计新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="hazardInvestigationShowDialog"
      v-if="hazardInvestigationShowDialog"
      width="60%"
      :fullscreen="fullscreen"
      @close="globalBoxClose"
    >
      <HazardInvestigationDialog ref="HazardInvestigationDialog" :hazardInvestigationQuery="hazardInvestigationQuery"
                                 @closeDialog="hazardInvestigationCloseDialog"></HazardInvestigationDialog>
    </el-dialog>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {getList} from "@/api/reportReview";
import AccidentDialog from "./components/accidentDialog.vue"
import ActionDialog from "./components/actionDialog.vue"
import DomesticProjectFormDialog from "./components/domesticProjectFormDialog.vue"
import EcologicalDialog from "./components/ecologicalDialog.vue"
import GovernmentDepartmentDialog from "./components/governmentDepartmentDialog.vue"
import HazardInvestigationDialog from "./components/hazardInvestigationDialog.vue"
import OverseasProjectDialog from "./components/overseasProjectDialog.vue"
import ProtectionDialog from "./components/protectionDialog.vue"
import SafetyDialog from "./components/safetyDialog.vue"

export default {
  components: {
    HeadLayout,
    GridLayout,
    AccidentDialog,
    ActionDialog,
    DomesticProjectFormDialog,
    EcologicalDialog,
    GovernmentDepartmentDialog,
    HazardInvestigationDialog,
    OverseasProjectDialog,
    ProtectionDialog,
    SafetyDialog
  },
  data() {
    return {
      fullscreen: false,
      safeType: 'edit',
      protectionQuery: {},
      protectionShowDialog: false,
      safetyQuery: {},
      safetyShowDialog: false,
      actionQuery: {},
      actionShowDialog: false,
      accidentQuery: {},
      accidentShowDialog: false,
      overseasProjectQuery: {},
      overseasShowDialog: false,
      domesticQuery: {},
      domesticShowDialog: false,
      ecologicalQuery: {},
      ecologicalShowDialog: false,
      governmentDepartmentQuery: {},
      governmentDepartmentShowDialog: false,
      hazardInvestigationQuery: {},
      hazardInvestigationShowDialog: false,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      searchColumns: [
        {
          label: "报表名称",
          prop: "reportCode",
          type: "select",
          dicUrl: "/api/sinoma-report/acquisition/attributeFixation/authorityReport?type=fill",
          dicData: [],
          props: {
            label: "templateName",
            value: "templateCode",
          },
          span: 4,
          placeholder: "请选择报表名称",
        },
        {
          label: "填报组织",
          prop: "fillDeptId",
          type: "select",
          dicUrl: "/api/sinoma-report/environment/insideOrOutside/fillSummaryList?type=fill",
          dicData: [],
          props: {
            label: "deptName",
            value: "id",
          },
          span: 4,
          placeholder: "请选择填报组织",
        },
      ],
      tableData: [],
      activeName: "first",
      tableLoading: false,
      tableOption: {
        menu: true,
        index: true,
        indexLabel: "序号",
        selection: false,
        linklabel: "reportName",
        column: [
          {
            label: "报表名称",
            prop: "reportName",
            align: "left",
            overHidden: true,
          },
          {
            label: "填报组织",
            prop: "fillDeptName",
            align: "left",
            overHidden: true,
          },
          {
            label: "统计日期",
            prop: "statDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "通知填报日期",
            prop: "taskInDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "截至日期",
            prop: "deadlineFillingDate",
            align: "center",
            overHidden: true,
          },
          // {
          //   label: "待我填报数",
          //   prop: "fillTotal",
          //   align: "center",
          //   overHidden: true,
          // },
        ]
      }
    }
  },
  mounted() {
    this.onLoad(this.page, {});
    window.addEventListener("storage", (e) => {
      if (e.key === "sinoma-report") {
        this.onLoad(this.page); //刷新列表
        localStorage.setItem("sinoma-report", JSON.stringify(false)); //变成false , 可以来回监听
        //要做的操作
      }
    });
  },
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    globalBoxClose() {
      this.onLoad(this.page, {});
    },
    protectionCloseDialog() {
      this.protectionShowDialog = false;
      this.onLoad(this.page, {});
    },
    safetyCloseDialog() {
      this.safetyShowDialog = false;
      this.onLoad(this.page, {});
    },
    actionCloseDialog() {
      this.actionShowDialog = false;
      this.onLoad(this.page, {});
    },
    accidentCloseDialog() {
      this.accidentShowDialog = false;
      this.onLoad(this.page, {});
    },
    overseasCloseDialog() {
      this.overseasShowDialog = false;
      this.onLoad(this.page, {});
    },
    domesticCloseDialog() {
      this.domesticShowDialog = false;
      this.onLoad(this.page, {});
    },
    ecologicalCloseDialog() {
      this.ecologicalShowDialog = false;
      this.onLoad(this.page, {});
    },
    governmentDepartmentCloseDialog() {
      this.governmentDepartmentShowDialog = false;
      this.onLoad(this.page, {});
    },
    hazardInvestigationCloseDialog() {
      this.hazardInvestigationShowDialog = false;
      this.onLoad(this.page, {});
    },
    handleClick() {
      this.onLoad(this.page, {type: this.activeName});
    },
    fillEdit(row) {

    },
    fill(row) {
      console.log(row, 'row')
      if (row.reportCode != undefined && row.fillDeptId != undefined && row.reportDataId != undefined) {
        if (row.reportCode == "overseasCode") {
          this.overseasShowDialog = true
          this.overseasProjectQuery = {
            id: row.reportDataId,
            row: JSON.stringify(row),
            type: 'edit',
            nodeId: row.fillDeptId,
            unitName: row.fillDeptName
          }
          this.safeType = "edit"
        }
        if (row.reportCode == "domesticCode") {
          this.domesticShowDialog = true
          this.domesticQuery = {
            id: row.reportDataId,
            row: JSON.stringify(row),
            type: 'edit',
            nodeId: row.fillDeptId,
            orgCode: row.fillDeptId,
            unitName: row.fillDeptName,
            statDate: row.statDate + ' 00:00:00',
          }
          this.safeType = "edit"
        }
        if (row.reportCode == "safetyProductionMonthly") {
          this.safetyShowDialog = true
          this.safetyQuery = {
            id: row.reportDataId,
            row: JSON.stringify(row),
            type: 'edit',
            nodeId: row.fillDeptId,
            unitName: row.fillDeptName
          }
          this.safeType = "edit"
        }
        if (row.reportCode == "ecologicalProductionMonthly") {
          this.ecologicalShowDialog = true
          this.ecologicalQuery = {
            id: row.reportDataId,
            row: JSON.stringify(row),
            type: 'edit',
            nodeId: row.fillDeptId,
            unitName: row.fillDeptName
          }
          this.safeType = "edit"
        }
        if (row.reportCode == "governmentDepartmentSafetyProduction") {
          this.governmentDepartmentShowDialog = true
          this.governmentDepartmentQuery = {
            id: row.reportDataId,
            row: JSON.stringify(row),
            type: 'edit',
            nodeId: row.fillDeptId,
            unitName: row.fillDeptName
          }
          this.safeType = "edit"
        }
        if (row.reportCode == "rptAdminPunishStat") {
          this.protectionShowDialog = true
          this.protectionQuery = {
            id: row.reportDataId,
            row: JSON.stringify(row),
            type: 'edit',
            nodeId: row.fillDeptId,
            unitName: row.fillDeptName
          }
          this.safeType = "edit"
        }
        if (row.reportCode == "safetActionStat") {
          this.actionShowDialog = true
          this.actionQuery = {
            id: row.reportDataId,
            row: JSON.stringify(row),
            type: 'edit',
            nodeId: row.fillDeptId,
            unitName: row.fillDeptName
          }
          this.safeType = "edit"
        }
        if (row.reportCode == "safeAccidentStat") {
          this.accidentShowDialog = true
          this.accidentQuery = {
            id: row.reportDataId,
            row: JSON.stringify(row),
            type: 'edit',
            nodeId: row.fillDeptId,
            unitName: row.fillDeptName
          }
          this.safeType = "edit"
        }
        if (row.reportCode == "hazardInvestigation") {
          this.hazardInvestigationShowDialog = true
          this.hazardInvestigationQuery = {
            id: row.reportDataId,
            row: JSON.stringify(row),
            type: 'edit',
            nodeId: row.fillDeptId,
            unitName: row.fillDeptName
          }
          this.safeType = "edit"
        }
      }
    },
    // fill(row) {
    //   window.open('/#/business/reportManager/fillReport/index?isNewWindow=true&reportCode=' + row.reportCode + '&statDate=' + row.statDate + '&fillDeptId=' + row.fillDeptId)
    // },
    fillHistory(row) {
      window.open('/#/business/reportManager/fillReport/index?isNewWindow=true&reportCode=' + row.reportCode + '&fillDeptId=' + row.fillDeptId)
    },
    summary(row) {
      window.open('/#/business/reportManager/summaryReport/index?isNewWindow=true&reportCode=' + row.reportCode + '&statDate=' + row.statDate + '&fillDeptId=' + row.fillDeptId + '&fillDeptName=' + row.fillDeptName)
    },
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      // this.$loading()
      params.fillSummaryType = 'fill'
      params.type = this.activeName
      getList(page.currentPage, page.pageSize, Object.assign(params)).then(res => {
        const data = res.data.data;
        this.tableData = data.records
        this.page.total = data.total;
        this.$refs.gridLayOut.page.total = data.total
        this.tableLoading = false;
        // this.$loading().close()
        this.selectionList = [];
      }).catch(() => {
        this.tableLoading = false;
        // this.$loading().close()
      }).finally(() => {
        this.tableLoading = false;
        // this.$loading().close()
      });
    },
    rowView(row) {
      this.$emit('runApproval', row)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .home .avue-crud .el-table {
  height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}

.homeTopTabs {
  width: calc(100% - 24px);
  padding: 0 12px;
  background-color: #FFF;
}

.homeTopTabs ::v-deep .el-tabs__header {
  margin: 0;
}
</style>
