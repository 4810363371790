<template>
  <basic-container>
    <avue-skeleton :loading="waiting"
                   avatar
                   :rows="8">
      <avue-title style="margin-bottom: 20px; "
                  :styles="{fontSize: '20px'}"
                  :value="process.name"></avue-title>
      <el-card shadow="never"
               style="margin-top: 20px">
        <!-- 自定义表单区域 -->
        <avue-form :option="option"></avue-form>
        <!-- 自定义表单区域 -->
      </el-card>

      <el-card shadow="never"
               style="margin-top: 20px"
               v-if="showExamForm">
        <wf-examine-form ref="examineForm"
                         :process="process"
                         @user-select="handleUserSelect"></wf-examine-form>
      </el-card>
      <div style="height: 120px;"></div>
      <el-row class="foot-item avue-affix"
              :style="{width: layoutType == 'functional'?'100%':isCollapse? 'calc(100% - 68px)': 'calc(100% - 220px)' }"
              id="avue-view">
        <el-button type="primary"
                   size="medium"
                   v-loading="loading"
                   @click="handleSubmit">发起</el-button>
        <el-button v-if="permission.wf_process_draft"
                   type="success"
                   size="medium"
                   v-loading="loading"
                   @click="handleDraft(process.id, process.formKey, form)">存为草稿</el-button>
      </el-row>
    </avue-skeleton>

    <!-- 人员选择弹窗 -->
    <wf-user-select ref="user-select"
                    :check-type="checkType"
                    :default-checked="defaultChecked"
                    @onConfirm="handleUserSelectConfirm"></wf-user-select>
  </basic-container>
</template>

<script>
import WfExamineForm from '@/views/plugin/workflow/process/components/examForm.vue'
import WfUserSelect from '@/views/plugin/workflow/process/components/user-select'

import exForm from '@/views/plugin/workflow/mixins/ex-form'
import draft from '@/views/plugin/workflow/mixins/draft'
import {mapGetters} from "vuex";

export default {
  components: {
    WfUserSelect, WfExamineForm
  },
  mixins: [exForm, draft],
  watch: {
    '$route.query.p': {
      handler(val) {
        if (val) {
          const param = JSON.parse(Buffer.from(val, 'base64').toString())
          const { processId } = param
          if (processId) this.getForm(processId)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["isCollapse","layoutType"]),
    showExamForm() {
      const { hideComment, hideCopy, hideExamine } = this.process
      return !hideComment || !hideCopy || !hideExamine
    }
  },
  data() {
    return {
      defaults: {},
      form: {},
      option: {
        menuBtn: false,
        column: [
          {
            label: '输入框',
            prop: 'attr1'
          }
        ]
      },
      process: {},
      loading: false
    }
  },
  methods: {
    getForm(processId) {
      this.getStartForm(processId).then(res => {
        let { process, startForm } = res
        console.log("process", process)
        console.log("startForm", startForm)
        // 自行处理相关逻辑
        this.waiting = false
      })
    },
    handleSubmit() {
      this.$refs.form.validate((valid, done, msg) => {
        if(valid){
          this.handleStartProcess5(true).then(done => {
            this.$message.success("发起成功")
            this.handleCloseTag('/plugin/workflow/process/send')
            done()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.foot-item {
  position: fixed;
  bottom: 0;
  //margin-left: -20px;
   right: 0;
  z-index: 101;
  height: 66px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
