var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          "table-loading": _vm.loading,
          data: _vm.data,
          "before-open": _vm.beforeOpen,
          permission: _vm.permissionList,
          page: _vm.page,
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "search-change": _vm.searchChange,
          "search-reset": _vm.searchReset,
          "current-change": _vm.currentChange,
          "size-change": _vm.sizeChange,
          "refresh-change": _vm.refreshChange,
          "on-load": _vm.onLoad,
        },
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }