<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-05 19:30:26
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="true"  :append-to-body="true"  :close-on-click-modal="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
    <avue-form :option="{ ...options,disabled:pageType == 'view' ? true : false,}" ref="addForm" :upload-preview="handlePreview" :upload-after="uploadAfter" :upload-error="uploadError" v-model="form" @submit="treeNodeSave">

    </avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    uploadVersion
  } from "@/api/safe/algorithmModel";
  import {
    from
  } from "rxjs";
  import Template from "@/views/message/template/list.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {
    mapGetters
  } from "vuex";
  import {
    getToken
  } from "@/util/auth";

  export default {
    components: {
      Template
    },
    mixins: [organization],
    data() {
      return {
        APITYPE: 'user',
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        title: '',
        isEdit: false,
        searchData: [],
        pageType: '',
      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      headers: function () {
        return {
          "Sinoma-Auth": getToken(),
        };
      },
      pageDisabled() {
        // 如果查看 或者 提交状态则禁用
        if (this.formType == "view") {
          return true;
        } else {
          return false;
        }
      },
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "模型编码",
              prop: "code",
              disabled: true,
              rules: [{
                required: true,
                message: "请输入模型编码",
                trigger: "blur"
              }, {
                max: 64,
                message: '模型编码不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "模型名称",
              prop: "name",
              disabled: true,
              rules: [{
                required: true,
                message: "请输入模型名称",
                trigger: "blur"
              }, {
                max: 64,
                message: '模型名称不能超过64个字符',
                trigger: 'blur'
              }]
            },

            {
              label: "版本号",
              prop: "version",
              rules: [{
                message: "请输入版本号",
                trigger: "blur"
              }, {
                max: 64,
                message: '版本号不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "版本说明",
              prop: "remark",
              rules: [{
                message: "请输入版本说明",
                trigger: "blur"
              }, {
                max: 200,
                message: '版本说明不能超过200个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "模型上传",
              prop: "filePath",
              dataType: "object",
              headers: this.headers,
              type: "upload",
              accept: ".zip,.doc,.docx,.pdf",
              limit: 1,
              fileSize: 50000,
              disabled: this.pageDisabled,
              placeholder: "请上传文件",
              propsHttp: {
                name: "originalName",
                url: "url",
                res: "data",
              },
              fileText: "上传文件",
              tip: "支持扩展名：.zip .doc .docx .pdf",
              span: 24,
              action: "/api/oss/endpoint/object/default/create",
            },
            {
              label: "文件Hash码",
              disabled: true,
              prop: "fileVerifyCode",
              rules: [{
                message: "请输入文件Hash码",
                trigger: "blur"
              }, {
                max: 64,
                message: '文件Hash码不能超过64个字符',
                trigger: 'blur'
              }]
            },
          ],
        }
      },
    },

    methods: {
      init(type, data, nodeType) {
        this.form = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.pageType = type
        this.form.code = nodeType.code
        this.form.name = nodeType.name
        this.form.modelId = nodeType.id
        this.form.fileVerifyCode = 'AAA'
        this.title = '上传版本'
      },
      uploadBefore(file, done, loading, column) {
        done()
      },
      uploadAfter(res, done) {
        this.form.fileName = res.fileName
        this.form.filePath = res.url
        this.form.fileList = [res.id]
        this.form.docId = res.id
        //其余操作
        done()
      },
      uploadError(error, column) {
        this.$message.warning(error)
      },
      // 点击预览
      handlePreview(file, column, done) {
        if (file.url) {
          let that = this;
          let fileName = file.name;
          fetch(file.url)
            .then((res) => res.blob())
            .then((blob) => {
              let objectUrl = URL.createObjectURL(blob); // 创建 url 对象
              that.aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
            })
            .catch((err) => {
              // console.log("Request Failed", err);
            });
        }
      },
      // 下载
      aTagDownload(url, name) {
        const fileUrl = url; // 文件的URL地址
        const link = document.createElement("a"); // 创建一个链接元素
        link.href = fileUrl; //重点（如测试发现下载文件不存在/找不到，检查路径）
        link.download = name; // 设置下载文件文件名
        link.style.display = "none";
        document.body.appendChild(link); // 将链接元素添加到页面中
        link.click(); // 触发链接的点击事件以触发图像下载
        document.body.removeChild(link); // 在完成下载后，从页面中移除链接元素
      },
      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      treeNodeSave(data) {
        this.$refs.addForm.validate((valid, done) => {
          if (valid) {
            this.dialogLoading = true
            let queryData = {
              code: data.code,
              docId: data.docId,
              fileName: data.docId,
              filePath:data.docId,
              fileVerifyCode: "AAA",
              modelId: data.modelId,
              name: data.name,
              remark: data.remark,
              version: data.version,
            }
            uploadVersion(queryData).then(res => {
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(res.data.msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.showDialog = false;
              this.dialogLoading = false
            }).catch(err => {
              done()
            }).finally(() => {
              done()
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {}
        this.searchData = []
        this.$refs.addForm.resetFields();
        this.showDialog = false
      },
    }
  }

</script>
<style lang="scss" scoped>
  .el-autocomplete {
    width: 100%
  }

</style>
