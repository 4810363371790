<template>
  <div style="position: relative">
    <div>
      <!-- 闸机列表 -->
      <head-layout head-title="算法模型" :head-btn-options="headBtnOptions" @head-add="handleAdd" @head-import="handleImport" @head-export="handleExport" @head-user="handleUser"></head-layout>
      <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout class="table-container" ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template #customBtn="{ row }">
          <el-button style="margin: 0 3px" type="text" size="small" @click="batchOpt(row)">部署
          </el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowDel(row)">删除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <addModel ref="addModel" @callBack="saveModel"></addModel>


  </div>
</template>

<script>
  import {
    serverPageList,
    serverBatchAdd,
    serverREfDel,
    serverREfBatchOpt
  } from "@/api/safe/algorithmManagement";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import addModel from "./addModel";
  import {
    getAgeFromIdCard
  } from "@/util/util";
  import {
    exportBlob
  } from "@/api/common";
  import {
    dateNow
  } from "@/util/date";
  import {
    getToken
  } from "@/util/auth";
  import {
    downloadXls
  } from "@/util/util";
  import {
    NODE_SERVER_ALGO_STATUS,
    ALGO_MODEL_CATEGORY
  } from '../../nodeDict.js'

  export default {
    components: {
      addModel,
      GridLayout,
      HeadLayout
    },
    props: {
      selectTree: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        drawerVersion: false,
        drawerIdentify: false,
        page: {
          pageSize: 20,
          pageIndex: 1,
          total: 0,
        },
        selectionList: [],
        tableOption: {
          linklabel: "code",
          menuWidth: 260,
          selection: false,
          column: [{
              label: "模型编码",
              prop: "modelCode",
              align: "center",
              overHidden: true,
            },
            {
              label: "模型名称",
              prop: "modelName",
              align: "center",
              overHidden: true,

            },
            {
              label: "类别",
              prop: "category",
              align: "center",
              props: {
                label: "name",
                value: "value",
              },
              dicData: ALGO_MODEL_CATEGORY,

            },
            {
              label: "当前版本",
              prop: "modelVersion",
              align: "center",
              overHidden: true,
            },
            {
              label: "最新版本",
              prop: "latestVersion",
              align: "center",
              overHidden: true,
            },
            {
              label: "状态",
              prop: "status",
              align: "center",
              props: {
                label: "name",
                value: "value",
              },
              dicData: NODE_SERVER_ALGO_STATUS,

            },
            {
              label: "所属设备",
              prop: "serverName",
              align: "center",
              overHidden: true,
            },

          ],
        },
        checkRowData: '',
        headBtnOptions: [{
            label: "添加模型",
            emit: "head-add",
            type: "button",
            icon: "",
            // btnOptType: "add",
          },

        ],
        searchColumns: [{
            label: "编码/名称",
            prop: "keyword",
            span: 4,
            placeholder: "请输入编码/名称",
          },
          {
            label: "状态",
            prop: "status",
            span: 4,
            placeholder: "请选择状态",
            props: {
              label: "name",
              value: "value",
            },
            type: "select",
            dicData: NODE_SERVER_ALGO_STATUS,
          },


        ],
        tableData: [],

      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirduser_add, false),
          viewBtn: this.vaildData(this.permission.thirduser_view, false),
          delBtn: this.vaildData(this.permission.thirduser_delete, false),
          editBtn: this.vaildData(this.permission.thirduser_edit, false),
        };
      },
    },

    mounted() {
      this.onLoad(this.page, {});
    },
    methods: {
      handleAdd() {
        this.$refs.addModel.init(this.selectTree);
      },
      batchOpt(row) {
        let queryData = {
          operation: "deploy",
          params:[row.id]
        }
        serverREfBatchOpt(queryData).then((res) => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
      },
      //查看
      rowView(row) {
        this.$refs.addModel.init("view", row, this.selectTree);
      },
      openVersion(row) {
        this.checkRowData = row
        this.drawerVersion = true
      },
      openIdentify(row) {
        this.checkRowData = row
        this.drawerIdentify = true
      },
      handleClose() {
        this.drawerVersion = false
      },
      saveModel(data) {
        let that = this
        let newId = data.map((item) => {
          return {modelId:item.id,version:item.version}
        })
        let query = {
          serverId: this.selectTree.id,
          versions: newId
        }
        serverBatchAdd(query).then((res) => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return serverREfDel({
              id: row.id
            });
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.gridLayOut.selectionClear();
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$refs.crud.toggleSelection();
          });
      },
      // 搜索
      gridHeadSearch(searchForm) {
        this.page.pageIndex = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.pageIndex = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.serverId = this.selectTree.id
        params.pageIndex = page.pageIndex
        params.pageSize = page.pageSize
        serverPageList(Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.tableLoading = false;
          } else {
            this.tableLoading = false;
          }
        });
      },

    },
  };

</script>

<style lang="less" scoped>
  ::v-deep .table-container {
    .el-table {
      height: calc(100vh - 334px) !important;
      max-height: calc(100vh - 334px) !important;
    }
  }

</style>
