var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "职业健康危害分析",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-add": _vm.handleHeadAdd },
              }),
              _c("grid-layout", {
                ref: "griLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOPtions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  editType: _vm.inline,
                  spanMethod: _vm.spanMethod,
                },
                on: {
                  "page-refresh-change": _vm.onLoad,
                  "row-edit": _vm.rowEdit,
                  "row-del": _vm.rowDel,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.title,
            "before-close": _vm.handleCancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "avue-form",
            {
              ref: "cedForm",
              attrs: { option: _vm.dialogOption },
              on: { submit: _vm.handleSubmit },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [
              _c(
                "template",
                { slot: "site" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: "请填写/选择区域",
                        maxlength: "255",
                      },
                      model: {
                        value: _vm.form.site,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "site", $$v)
                        },
                        expression: "form.site",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-search input-icon",
                        attrs: { slot: "suffix" },
                        on: { click: _vm.handleSite },
                        slot: "suffix",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + " ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("riskArea", {
        ref: "riskAreas",
        on: { callback: _vm.handleCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }