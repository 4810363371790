var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "排放记录清单",
          showIcon: false,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-add": _vm.headAdd, "head-romve": _vm.headRomve },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-span": 3, "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOption,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "grid-row-detail-click": _vm.detailView,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                !row.status && row.createUser === _vm.userInfo.user_id
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowView(row, "edit")
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    )
                  : _vm._e(),
                !row.status && row.createUser === _vm.userInfo.user_id
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowRemove(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    )
                  : _vm._e(),
                row.status
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowView(row, "view")
                          },
                        },
                      },
                      [_vm._v("查看")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }