<script>
import {prjbasicinfoDetail, prjbasicinfoSubmit, prjQrcode} from "@/api/safe/itemList";
import {dictionaryBiz} from "@/api/reportTasks";
import qrcodeDialog from "@/views/projectSettings/regionManage/qrcode.vue";
import {getAreaData1} from "@/api/base/region";
import {getDeptLazyTree} from "@/api/system/dept";
import ElTreeSelect from '@/components/ElTreeSelect';
import {mapGetters} from "vuex";
export default {
  components: {qrcodeDialog,ElTreeSelect},
  computed:{
    ...mapGetters([
      'userInfo',
    ]),
    // 基础信息按钮设置
    basicFormHeadBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType)) {
        result.push({
          label: "重置",
          emit: "head-reset",
          type: "button",
          btnOptType: "reset",
        });
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        // result.push({
        //   label: "保存并返回",
        //   emit: "head-saveBack",
        //   type: "button",
        //   btnOptType: "saveBack",
        // });
      }
      return result;
    },
  },
  data(){
    let that=this;
    return {
      qrcodeSrc: "",
      formType: "",
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now() - 3600 * 1000 * 24;
        },
      },
      planEndDateOptions: {
        disabledDate(time){
          return time.getTime()  < new Date(that.dataForm.startWorking).getTime();
        },
      },
      rules: {
        prjAs: [
          {
            required: true,
            message: "请输入项目简称",
            trigger: "blur",
          },
        ],
        contractId: [
          {
            required: true,
            message: "请输入备案单位",
            trigger: "change",
          },
        ],
        coordinate: [
          {
            required: true,
            message: "请选择项目坐标",
            trigger: "change",
          },
        ],
        latitude: [
          {
            required: true,
            message: "请选择项目坐标",
            trigger: "change",
          },
        ],
        prjJoinDate: [
          {
            required: true,
            message: "请选择项入网日期",
            trigger: "change",
          },
        ],
        areaId: [
          {
            required: true,
            message: "请选择项目位置",
            trigger: "change",
          },
        ],
        areaRestock: [
          {
            required: true,
            message: "请输入详细位置",
            trigger: "blur",
          },
        ],
        prjType: [
          {
            required: true,
            message: "请选择项目类型",
            trigger: "blur",
          },
        ],
      },
      areaDic: [], // 区域位置
      dataForm:{},
      showPage:true,
      prjDomesticList: [],
      prjTypeList: [],
      stageList: [],
    }
  },
  mounted() {
    this.formType = this.$route.query.type || 'view';
    this.dataForm.id = this.$route.query.id || this.userInfo.dept_id;
    this.getPrjbasicinfoDetail();
  },
  methods:{
    treeLoad(node, resolve){
      const parentId =node.data.id || this.userInfo.dept_id;
      getDeptLazyTree(parentId,{deptCategory:'2'}).then(res=>{
        if(res.data.code == 200){
          let data=res.data.data
          resolve(data);
        }
      })
    },
    nodeClick(data, node, vm) {
      this.$forceUpdate()
      this.dataForm.contractName = data.title;
    },
    //重置
    headReset() {
      this.dataForm.prjAs = "";
      this.dataForm.contractId = "";
      this.dataForm.longitude = "";
      this.dataForm.latitude = "";
      this.dataForm.coordinate = "";
      this.dataForm.areaId = [];
      this.dataForm.areaCode = "";
      this.dataForm.areaRestock = "";
      this.dataForm.phoneCount = "";
    },
    // 保存
    headSave(boolean) {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$loading();
          if (this.dataForm.areaId) {
            this.dataForm.areaId = this.dataForm.areaId.join(",");
          }
          prjbasicinfoSubmit(this.dataForm).then((res) => {
            if (res.data.code == 200) {
              this.$message.success('保存成功');
              this.getPrjbasicinfoDetail();
              this.$loading().close();
            }
          });
        }
      });
    },

    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      prjbasicinfoDetail({
        id: this.dataForm.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.showPage=false
          let data=res.data.data
          this.$set(this,'dataForm',{
            ...res.data.data,
            prjType:String(data.prjType),
            coordinate:data.longitude ? `${data.longitude},${data.latitude}` : "",
            phoneCount:data.phoneCount != -1 ? data.phoneCount : "",
            areaId:data.areaId ? data.areaId.split(",") : [],
          });
          this.oldDataForm = {
            ...res.data.data,
          };
          this.getAreaData();
          this.getDictory();
          const scene = `${this.dataForm.id}&prj_code=${this.dataForm.prjCode}&prj_name=${this.dataForm.prjName}`;

          const toPage = "pages/login/register-account"; // 跳转地址
          prjQrcode({scene, toPage}).then((res) => {
            this.qrcodeSrc = "data:image/png;base64," + res.data.data;
          });
          this.$forceUpdate()
        }
      });
    },
    getDictory() {
      dictionaryBiz("prj_domestic").then((res) => {
        this.prjDomesticList = res.data.data;
        this.dataForm.isDomestic = String(this.dataForm.isDomestic) || "1";
      });
      dictionaryBiz("prj_type").then((res) => {
        this.prjTypeList = res.data.data;
      });
      dictionaryBiz("prj_stage").then((res) => {
        this.stageList = res.data.data;
      });
    },
    // 下载二维码
    downloadQrcode() {
      this.$refs.qrcodeDialog.init(
        this.qrcodeSrc,
        this.dataForm.prjName,
        this.dataForm.prjCode
      );
    },
    //   项目位置
    getAreaData(parentCode) {
      getAreaData1(parentCode, this.areaDic);
    },
    // 区域位置获取
    handleChangeArea(value) {
      const regionList =
        this.$refs["prjAreaCascader"].getCheckedNodes()[0].pathLabels;
      if (regionList && regionList.length != 0) {
        this.dataForm.areaName = regionList.join(",");
      }
    },
    //获取经纬度
    openMap() {
      this.$refs.BaiduMap.initMap()
    },

    handleMapSave(val) {
      this.$set(this.dataForm, "coordinate", `${val.longitude},${val.latitude}`);
    },
  },
}
</script>

<template>
  <el-container v-loading="showPage">
    <div v-if="!showPage" style="width: 100%; background: #ffffff" class="formMain">
      <head-layout
        :showIcon="false"
        head-title="项目概况"
        :head-btn-options="basicFormHeadBtnOptions"
        @head-reset="headReset"
        @head-save="headSave(false)"
        @head-saveBack="headSave(true)"
      ></head-layout>
      <el-form
        ref="dataForm"
        :model="dataForm"
        label-width="160px"
        :disabled="formType == 'view'"
        :rules="rules"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目编码" prop="prjCode">
              <el-input
                v-model="dataForm.prjCode"
                disabled
                placeholder="请输入项目编码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目名称" prop="prjName">
              <el-input
                v-model="dataForm.prjName"
                disabled
                placeholder="请输入项目名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="">
              <div
                style="
                            width: 150px;
                            height: 150px;
                            position: absolute;
                            text-align: center;
                            z-index: 1;
                          "
              >
                <el-image
                  :src="qrcodeSrc"
                  style="width: 100px; height: 100px"
                ></el-image>
                <el-button size="mini" @click.native="downloadQrcode"
                >下载
                </el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目区域" prop="areName">
              <el-input
                v-model="dataForm.areName"
                disabled
                placeholder="请填写项目区域"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="境内外" prop="isDomestic">
              <el-select
                v-model="dataForm.isDomestic"
                placeholder="请选择境内外"
                disabled
              >
                <el-option
                  v-for="item in prjDomesticList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="备案单位" prop="contractId">
              <el-tree-select
                :treeParams="{
                          lazy:true,
                          filterable:true,
                          labels:dataForm.contractName,
                          props:{
                            label: 'title', value: 'id',leaf:'leaf'
                          },
                          load:treeLoad
                          }"
                @node-click="nodeClick"
                v-model="dataForm.contractId"
              ></el-tree-select>
              <!-- <el-input v-model="dataForm.contractId" disabled placeholder="请填写承建单位"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="立项日期" prop="prjDate">
              <el-input
                v-model="dataForm.prjDate"
                disabled
                placeholder="请填写立项日期"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目经理" prop="pmCame">
              <el-input
                v-model="dataForm.pmCame"
                placeholder="请输入项目经理"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="phoneNumber">
              <el-input
                v-model="dataForm.phoneNumber"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目简称" prop="prjAs">
              <el-input
                v-model="dataForm.prjAs"
                placeholder="请填写项目简称"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入网日期" prop="prjJoinDate">
              <avue-date
                style="width: 100%"
                format="yyyy-MM-dd"
                valueFormat="yyyy-MM-dd"
                :picker-options="pickerOptions"
                v-model="dataForm.prjJoinDate"
                placeholder="请选择入网日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卫星电话数量" prop="phoneCount">
              <!-- <el-input v-model="dataForm.phoneCount" placeholder="请填写卫星电话数量" oninput="value = value.replace(/[^\d]/g,'')"  maxlength="50"></el-input> -->
              <el-input
                v-model="dataForm.phoneCount"
                placeholder="请填写卫星电话数量"
                oninput="value = value.replace(/[^\d]/g,'').slice(0, 3)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目坐标" prop="coordinate">
              <el-input
                @focus="openMap"
                v-model="dataForm.coordinate"
                placeholder="请填写项目坐标"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目位置" prop="areaId">
              <el-cascader
                ref="prjAreaCascader"
                filterable
                style="width: 100%"
                v-model="dataForm.areaId"
                :options="areaDic"
                @change="handleChangeArea"
              ></el-cascader>
              <!-- <avue-input-tree checkStrictly v-model="dataForm.areaId" placeholder="请选择" :dic="areaDic"  @change="handleChangeArea"></avue-input-tree> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="详细位置" prop="areaRestock">
              <el-input
                v-model="dataForm.areaRestock"
                placeholder="请填写"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目类型" prop="prjType">
              <el-select
                v-model="dataForm.prjType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in prjTypeList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="建设/运维" prop="stage">
              <el-select
                v-model="dataForm.stage"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in stageList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开工日期" prop="startWorking">
              <avue-date
                style="width: 100%"
                format="yyyy-MM-dd"
                valueFormat="yyyy-MM-dd"
                v-model="dataForm.startWorking"
                placeholder="请选择入网日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束日期" prop="planEndDate">
              <avue-date
                style="width: 100%"
                format="yyyy-MM-dd"
                valueFormat="yyyy-MM-dd"
                :picker-options="planEndDateOptions"
                v-model="dataForm.planEndDate"
                placeholder="请选择入网日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 加入项目扫描二维码弹框 -->
    <qrcodeDialog ref="qrcodeDialog"></qrcodeDialog>
    <BaiduMap v-model="dataForm" ref="BaiduMap" @change="handleMapSave"></BaiduMap>
  </el-container>
</template>

<style scoped lang="scss">
form {
  padding: 10px !important;
  box-sizing: border-box !important;
}
</style>
