<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-15 10:41:27
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-06 16:47:17
 * @Description:
-->
<template>
  <div>
    <head-layout
      head-title="人员清单"
      :head-btn-options="headBtnOptions"
      @head-romve="headRomve"
      @head-add="headAdd"
    ></head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @gird-handle-select-click="selectionChange"
    >
    </grid-layout>
    <el-dialog
      title="新增人员"
      v-dialogDrag
      :visible.sync="dialogVisible"
      :before-close="handleCancel"
      width="40%"
    >
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="人员名称" prop="userName">
          <el-input v-model="form.userName" @focus="handleSelectPerson" placeholder="请选择人员"></el-input>
        </el-form-item>
        <el-form-item label="部门组织" prop="userOrgName">
          <el-input v-model="form.userOrgName" disabled placeholder="选择人员后自动带出"></el-input>
        </el-form-item>
        <el-form-item label="岗位职责" prop="duty">
          <el-input
            type="textarea"
            v-model="form.duty"
            maxlength="200"
            placeholder="请填写岗位职责"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleCancel"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="peopleShow" width="80%" top="8vh">
      <UserDetpDialog
        ref="UserDetpDialog"
        :dept-category="[2, 5]"
        @select-data="selectPeopleData"
      ></UserDetpDialog>
    </el-dialog>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";

import { mapGetters } from "vuex";
import * as API from "@/api/responsibility/safetyOrg";

export default {
  components: {
    GridLayout,
    HeadLayout,
    UserDetpDialog
  },
  data() {
    return {
      tableOption: {
        selection: true,
        menu: false,
        column: [
          {
            label: "人员名称",
            prop: "userName",
            align: "center",
            overHidden: true,
          },

          {
            label: "部门组织",
            prop: "userOrgName",
            align: "left",
            overHidden: true,
          },

          {
            label: "岗位职责",
            prop: "duty",
            align: "center",
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: "",
        },
      ],
      searchColumns: [
        {
          label: "人员名称",
          prop: "userName",
          span: 6,
          placeholder: "请输入人员名称",
        },
        {
          label: "组织部门",
          prop: "userOrgName",
          span: 6,
          placeholder: "请输入组织部门",
        },
        {
          label: "岗位职责",
          prop: "duty",
          span: 6,
          placeholder: "请输入岗位职责",
        }
      ],
      dialogVisible: false,
      dialogLoading: false,
      form: {
        userOrgName: '',
        userOrgId: "",
        soOrgId: "",
        userId: "",
        userName: '',
        tel: ''
      },
      rules: {
        userName: [
          { required: true, message: "请选择人员名称", trigger: "change" },
        ],
      },
      peopleShow: false,
      selectionList: [],
      soOrgId: ''
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },

  methods: {
    initDate(soOrgId) {
      this.soOrgId = soOrgId
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },

    headAdd() {
      this.dialogVisible = true
    },
    handleSelectPerson(){
      this.peopleShow = true
    },
    selectPeopleData(row,deptId,treeDeptName) {
      // console.log('选中的人=>',row,deptId,treeDeptName)
      this.form.userId = row.id
      this.form.userName = row.realName
      this.form.userOrgId = deptId
      this.form.userOrgName = treeDeptName
      this.form.tel	= row.phone
      this.peopleShow = false;
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },

    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      params.soOrgId = this.soOrgId;
      API.getPersonPage(page.currentPage, page.pageSize, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 批量删除
    headRomve() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return API.personRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 单条删除
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.personRemove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    handleCancel() {
      this.$refs.form.resetFields();
      // this.dialogLoading = false;
      this.dialogVisible = false;
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // this.dialogLoading = true;
          this.$loading();
          this.form.soOrgId = this.soOrgId
          API.personSubmit(this.form)
            .then((res) => {
              this.$message.success("操作成功");
              this.handleCancel();
              this.page.currentPage = 1;
              this.onLoad(this.page);
              this.$loading().close();
            })
            .catch(() => {
              this.dialogLoading = false;
            });
        }
      });
    },
    addCallback() {
      this.page.currentPage = 1;
      this.initDate();
    },
  },
};
</script>
