<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-12 10:48:14
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
    <el-steps :active="activeName">
      <el-step title="创建模型" @click.native="handleStepClick(1)"></el-step>
      <el-step title="上传模型" @click.native="handleStepClick(2)"></el-step>
    </el-steps>
    <avue-form v-if="activeName === 1" :option="{ ...options,disabled:pageType == 'view' ? true : false,}" ref="addForm" v-model="form">
      <template slot="tags">
        <div>
          <el-tag v-for="(item,index) in chooseTags" :key="index" closable type="info" @close="handleClose(item)">
            {{ tagsListData.find(tag => tag.id === item)?.enName }}
          </el-tag>

          <el-popover width="70" trigger="click">
            <el-checkbox-group v-model="chooseTags" @change="chooseTag">
              <el-checkbox v-for="item in tagsListData" :key="item.id" :label="item.id"> {{item.enName}}</el-checkbox>
            </el-checkbox-group>
            <template slot="reference"> <i class="el-icon-circle-plus"></i></template>
          </el-popover>
        </div>
      </template>
    </avue-form>
    <avue-form v-if="activeName === 2" :option="{ ...uploadOptions,disabled:pageType == 'view' ? true : false,}" ref="uploadFormRef" :upload-preview="handlePreview" :upload-after="uploadAfter" :upload-error="uploadError" v-model="uploadForm" @submit="treeNodeSave">

    </avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" v-if="activeName==1" @click="nextSave(2)" v-loading="dialogLoading">
        下一步
      </el-button>
      <el-button size="small" type="primary" v-if="activeName==2" @click="nextSave(1)" v-loading="dialogLoading">
        上一步
      </el-button>
      <el-button size="small" type="primary" @click="handleTreeNodeSave" v-if="activeName==2" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    add,
    tagsList,
    edit,
    getById
  } from "@/api/safe/algorithmModel";
  import {
    from
  } from "rxjs";
  import Template from "@/views/message/template/list.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {
    mapGetters
  } from "vuex";
  import {
    ALGO_MODEL_CATEGORY
  } from '../algorithmManagement/nodeDict.js'
  import {
    getToken
  } from "@/util/auth";

  export default {
    components: {
      Template
    },
    mixins: [organization],
    data() {
      return {
        APITYPE: 'user',
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        uploadForm: {},
        title: '',
        isEdit: false,
        searchData: [],
        tagsListData: [],
        chooseTags: [],
        pageType: '',
        activeName: 1

      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      headers: function () {
        return {
          "Sinoma-Auth": getToken(),
        };
      },
      pageDisabled() {
        // 如果查看 或者 提交状态则禁用
        if (this.formType == "view") {
          return true;
        } else {
          return false;
        }
      },
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "模型编码",
              prop: "code",
              rules: [{
                required: true,
                message: "请输入模型编码",
                trigger: "blur"
              }, {
                max: 64,
                message: '模型编码不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "模型名称",
              prop: "name",
              rules: [{
                required: true,
                message: "请输入模型名称",
                trigger: "blur"
              }, {
                max: 64,
                message: '模型名称不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "所属类别",
              prop: "category",
              type: 'select',
              dataType: 'string',
              props: {
                label: "name",
                value: "value",
              },
              dicData: ALGO_MODEL_CATEGORY,
              // dicUrl: "/api/sinoma-system/dict/dictionary?code=PrjEqGateMachineEqtType",
              rules: [{
                required: true,
                message: "请选择所属类别",
                trigger: "blur"
              }]
            },

            {
              label: "输出分类",
              prop: "outputType",
              rules: [{
                message: "请输入输出分类",
                trigger: "blur"
              }, {
                max: 64,
                message: '输出分类不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "图像宽度像素值",
              prop: "widthPx",
              controlsPosition:'',
              type:'number',
            },
            {
              label: "图像高度像素值",
              prop: "heightPx",
              controlsPosition:'',
              type:'number',
            },
            {
              label: "检测阈值",
              prop: "thresholdVal",
              controlsPosition:'',
              type:'number',
            },
            {
              label: "选择标签",
              prop: "tags",
              formslot: true,
            },
            {
              label: "备注",
              prop: "remark",
              rules: [{
                message: "请输入备注",
                trigger: "blur"
              }, {
                max: 64,
                message: '备注不能超过200个字符',
                trigger: 'blur'
              }]
            },

          ],
        }
      },
      uploadOptions() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 140,
          column: [{
              label: "模型编码",
              prop: "code",
              disabled: true,
              rules: [{
                required: true,
                message: "请输入模型编码",
                trigger: "blur"
              }, {
                max: 64,
                message: '模型编码不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "模型名称",
              prop: "name",
              disabled: true,
              rules: [{
                required: true,
                message: "请输入模型名称",
                trigger: "blur"
              }, {
                max: 64,
                message: '模型名称不能超过64个字符',
                trigger: 'blur'
              }]
            },

            {
              label: "版本号",
              prop: "version",
              rules: [{
                message: "请输入版本号",
                trigger: "blur"
              }, {
                max: 64,
                message: '版本号不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "版本说明",
              prop: "remark",
              rules: [{
                message: "请输入版本说明",
                trigger: "blur"
              }, {
                max: 200,
                message: '版本说明不能超过200个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "模型上传",
              prop: "filePath",
              dataType: "object",
              headers: this.headers,
              type: "upload",
              accept: ".zip,.doc,.docx,.pdf",
              limit: 1,
              fileSize: 50000,
              disabled: this.pageDisabled,
              placeholder: "请上传文件",
              propsHttp: {
                name: "originalName",
                url: "url",
                res: "data",
              },
              fileText: "上传文件",
              tip: "支持扩展名：.zip .doc .docx .pdf",
              span: 24,
              action: "/api/oss/endpoint/object/default/create",
            },
            {
              label: "文件Hash码",
              disabled: true,
              prop: "fileVerifyCode",
              rules: [{
                message: "请输入文件Hash码",
                trigger: "blur"
              }, {
                max: 64,
                message: '文件Hash码不能超过64个字符',
                trigger: 'blur'
              }]
            },
          ],
        }
      },
    },

    methods: {
      init(type, data, nodeType) {
        this.form = {}
        this.uploadForm = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.uploadForm.fileVerifyCode = 'AAA'
        this.pageType = type
        this.form.type = nodeType
        this.activeName=1
        this.getTagsList()
        if (type == 'add') {
          this.title = '上传模型'

        } else {
          this.title = '编辑模型'
         this.getRowData(data)
        }

      },

      handleStepClick(tab) {
        if (tab === 2) {
          this.$refs.addForm.validate((valid, done) => {
            if (valid) {
              this.activeName = tab
              done()
            }
          })
        } else {
          this.activeName = tab
        }

      },
      getRowData(data){
        getById({id:data.id,version:data.version}).then((res)=>{
          this.form = {...res.data.data}
          this.uploadForm = {...res.data.data}
          this.chooseTags=res.data.data.tags
          this.uploadForm.fileVerifyCode=res.data.data.versionInfo.fileVerifyCode
        })
      },
      getTagsList() {
        tagsList().then((res) => {
          this.tagsListData = res.data.data

        })
      },

      chooseTag(data) {
        console.log(' this.chooseTags==', this.chooseTags)
        console.log('datas==', data)
      },
      handleClose(tag) {
        this.chooseTags.splice(this.chooseTags.indexOf(tag), 1);
      },
      nextSave(tab) {
        if (tab == 1) {
          this.activeName = tab
        } else {
          this.$refs.addForm.validate((valid, done) => {
            if (valid) {
              this.activeName = tab
              done()
            }
          })
        }
      },
      uploadBefore(file, done, loading, column) {
        done()
      },
      uploadAfter(res, done) {
        this.uploadForm.fileName = res.fileName
        this.uploadForm.filePath = res.url
        this.uploadForm.fileList = [res.id]
        this.uploadForm.docId = res.id
        //其余操作
        done()
      },
      uploadError(error, column) {
        this.$message.warning(error)
      },
      // 点击预览
      handlePreview(file, column, done) {
        if (file.url) {
          let that = this;
          let fileName = file.name;
          fetch(file.url)
            .then((res) => res.blob())
            .then((blob) => {
              let objectUrl = URL.createObjectURL(blob); // 创建 url 对象
              that.aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
            })
            .catch((err) => {
              // console.log("Request Failed", err);
            });
        }
      },
      // 下载
      aTagDownload(url, name) {
        const fileUrl = url; // 文件的URL地址
        const link = document.createElement("a"); // 创建一个链接元素
        link.href = fileUrl; //重点（如测试发现下载文件不存在/找不到，检查路径）
        link.download = name; // 设置下载文件文件名
        link.style.display = "none";
        document.body.appendChild(link); // 将链接元素添加到页面中
        link.click(); // 触发链接的点击事件以触发图像下载
        document.body.removeChild(link); // 在完成下载后，从页面中移除链接元素
      },
      handleTreeNodeSave() {
        this.$refs.uploadFormRef.submit();
      },
      treeNodeSave(data) {
        this.$refs.uploadFormRef.validate((valid, done) => {
          if (valid) {
            let queryData = {
              id: this.pageType == 'add' ? '' : this.form.id,
              enName:this.pageType == 'add' ? '' : this.form.enName,
              availableVersions:this.pageType == 'add' ? '' : this.form.availableVersions,
              wrappingMode:this.pageType == 'add' ? '' : this.form.wrappingMode,
              nodeId: this.pageType == 'add' ? '' : this.form.nodeId,
              code: this.form.code,
              name: this.form.name,
              category: this.form.category,
              outputType: this.form.outputType,
              widthPx: this.form.widthPx,
              heightPx: this.form.heightPx,
              tags: this.chooseTags,
              thresholdVal: this.form.thresholdVal,
              remark: this.form.remark,
              value: {
                code: this.form.code,
                enName:this.pageType == 'add' ? '' : this.form.enName,
                availableVersions:this.pageType == 'add' ? '' : this.form.availableVersions,
                wrappingMode:this.pageType == 'add' ? '' : this.form.wrappingMode,
                nodeId: this.pageType == 'add' ? '' : this.form.nodeId,
                name: this.form.name,
                category: this.form.category,
                outputType: this.form.outputType,
                widthPx: this.form.widthPx,
                heightPx: this.form.heightPx,
                tags: this.chooseTags,
                thresholdVal: this.form.thresholdVal,
                remark: this.form.remark,
              },
              versionInfo: {
                code: this.form.code,
                name: this.form.name,
                docId: data.docId,
                fileList: data.fileList,
                fileName: data.fileName,
                fileVerifyCode: data.fileVerifyCode,
                remark: data.remark,
                version: data.version,
                filePath: data.filePath.value
              },

            }
            const result = Object.fromEntries(
              Object.entries(queryData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
            );
            this.dialogLoading = true
            let url = this.pageType == 'add' ? add : edit
            url(result).then(res => {
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(res.data.msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.showDialog = false;
              this.dialogLoading = false
            }).catch(err => {
              done()
            }).finally(() => {
              done()
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {
          id: '',
          code: '',
          name: '',
          category: '',
          outputType: '',
          widthPx: '',
          heightPx: '',
          tags: '',
          thresholdVal: '',
          remark: '',
        }
        this.searchData = []
        this.uploadForm = {
          code: '',
          name: '',
          docId: '',
          fileList: '',
          fileName: '',
          fileVerifyCode: '',
          remark: '',
          version: '',
          filePath: ''
        }
        this.showDialog = false
      },
    }
  }

</script>
<style lang="scss" scoped>
  .el-autocomplete {
    width: 100%
  }

  .el-steps {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 16px;
  }

  .el-icon-circle-plus {
    font-size: 25px;
    margin-top: 3px;
  }

</style>
