<template>
  <div>
    <h3>训练模型</h3>
    <el-form>
      <el-form-item lable="模型名称">
        <el-input v-model="form.name" placeholder="请输入模型名称"></el-input>
      </el-form-item>
      <el-form-item lable="算法名称">
        <el-input v-model="form.name" placeholder="请输入模型名称"></el-input>
      </el-form-item>
      <el-form-item lable="模型路径">
        <el-input v-model="form.name" placeholder="请输入模型名称"></el-input>
      </el-form-item>
      <el-form-item lable="数据来源">
        <el-select  size="mini" v-model="form.sourceId" @change="sourceChange">
          <el-option v-for= ' (item,index) in sourceList' :key="index" :label="item.desc" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item lable="数据表">
        <el-input v-model="form.name" placeholder="请输入模型名称"></el-input>
      </el-form-item>
    </el-form>
    <el-row :gutter="20">
      <el-col :span="12">
        <p>请选择y特征</p>
        <grid-layout
          ref="gridLayout"
          :tableData="tableData"
          :tableLoading="tableLoading"
          :tableOptions="tableOptions"
        >
        </grid-layout>
      </el-col>
      <el-col :span="12">
        <p>请选择x特征</p>

      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {getTableColumnList,getAlgorithmById,getAlgorithmAllList,getTableListMap} from '@/api/Ai'
  import {listAll} from "@/api/dataAcquisition/DataServices";
export default {
  name: "trainingModel",
  data(){
    return {
      form:{
        name:'',
      },
      tableLoading:false,
      tableData:[],
      tableOptions:{
        column: [
          {
            label: this.$t('datamodel.label.ChineseNameOfData'),
            prop: "tableComment",
            cell: true,
            slot:true
          },
          {
            label: this.$t('datamodel.label.Dataname'),
            prop: "tableName",
            cell: true,
          },
          {
            label: this.$t('datamodel.label.datatype'),
            prop: "dbTypeName",
            cell: true,
          },
          {
            label: this.$t('datamodel.label.Creationtime'),
            prop: "createTime",
            cell: true,
          },
        ],
        sourceList:[],
      }
    }
  },
  created() {

  },
  methods:{
    init(){
      this.getAlgorithmByIdFn();
      this.getTableColumnListFn();
      this.getAlgorithmAllListFn();
      this.getTableListMapFn();
      this.sourceListFn();
    },
    //todo 获取算法详情
    getAlgorithmByIdFn(){
      getAlgorithmById().then((res)=>{
        console.log(res)
      })
    },
    //todo 获取xy特征
    getTableColumnListFn(){
      getTableColumnList().then((res)=>{
        console.log(res)
      })
    },
    //todo 数据来源下拉
    getAlgorithmAllListFn(){
      getAlgorithmAllList().then((res)=>{

      })
    },
    //todo 数据表下拉下拉
    getTableListMapFn(){
      getTableListMap().then((res)=>{

      })
    },
    //todo 数据源下拉
    sourceListFn(){
      listAll({}).then((res)=>{
        this.sourceList = res.data.data;
      })
    },

  }

}
</script>

<style scoped>

</style>
