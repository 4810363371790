<!--
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-07-02 17:03:11
-->
<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="55%"
      @close="closeDialog"
    >
      <div style="height: 420px; overflow-y: auto">
        <avue-form
          :option="options"
          ref="addForm"
          v-model="form"
          @submit="treeNodeSave"
        >
          <template slot="quantity">
            <div style="display: flex; align-items: center">
              <el-input
                style="width: 60%"
                v-model="form.quantity"
                placeholder="请输数量"
                maxlength="10"
                show-word-limit
              ></el-input>
              <el-input
                style="width: 40%; margin-left: 5px"
                v-model="form.unit"
                placeholder="请输入单位"
              ></el-input>
            </div>
          </template>
          <template slot="files">
            <el-upload
              :class="{ uoloadSty: showImg, disUoloadSty: noneUploadImg }"
              action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
              :headers="headers"
              :on-preview="handlePreview"
              list-type="picture-card"
              :file-list="fileList"
              file="file"
              :on-remove="handleDeleteImgRemove"
              :on-success="handleIdFilesSuccess"
              :on-change="uploadImgChange"
              :before-upload="beforeAvatarUpload"
              accept=".jpeg,.jpg,.gif,.png"
              :limit="limitCountImg"
            >
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </template>
        </avue-form>
      </div>
      <span slot="footer">
        <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          v-if="pageType != 'view'"
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
      <el-dialog :visible.sync="dialogVisible" append-to-body>
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </el-dialog>
    <el-dialog
      title="人员选择"
      :visible.sync="peopleShow"
      width="80%"
      top="8vh"
    >
      <UserDetpDialog
        ref="UserDetpDialog"
        :dept-category="[2, 5]"
        @select-data="selectPeopleData"
      ></UserDetpDialog>
    </el-dialog>
  </div>
</template>

<script>
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import {
  ermaterialAdd,
  ermaterialGetDetail,
} from "@/api/contingency/emergencySupplies";

import { mapGetters } from "vuex";

import { getToken } from "@/util/auth";

export default {
  components: {
    UserDetpDialog,
  },
  data() {
    return {
      showDialog: false,
      dialogLoading: false,

      readOnly: false,
      form: {},
      title: "",
      pageType: "",
      fileUrl: "",
      showImg: true, //默认展示
      noneUploadImg: false, //
      noneUploadImgCer: false, //
      fileList: [],

      limitCountImg: 1,
      dialogImageUrl: "",
      dialogVisible: false,
      peopleShow: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
    options() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: "物资名称",
            prop: "materialName",
            disabled: this.pageType == "view",
            maxlength: 125,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入物资名称",
                trigger: "blur",
              }
            ],
          },
          {
            label: "数量",
            prop: "quantity",
            formSlot: true,
            maxlength: 10,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入数量",
                trigger: "blur",
              },
              {
                pattern: /^[1-9]\d*$/,
                message: "请输入正整数",
              }
            ],
          },

          {
            label: "规格型号",
            prop: "materialSpe",
            span: 24,
            type: "textarea",
            disabled: this.pageType == "view",
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: false,
                message: "请输入规格型号",
                trigger: "blur",
              }
            ],
          },
          {
            label: "购置日期",
            prop: "purchaseDate",
            type: "date",
            disabled: this.pageType == "view",
            // format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            rules: [
              {
                required: true,
                message: "请选择购置日期",
                trigger: "change",
              },
            ],
          },
          {
            label: "责任人",
            prop: "dutyUserName",
            // formSlot: true,
            suffixIcon: "el-icon-search",
            rules: [
              {
                required: true,
                message: "请选择责任人",
                trigger: "change",
              },
            ],
            focus: ({ value, column }) => {
              this.peopleShow = true;
            },
          },
          {
            label: "存放位置",
            prop: "location",
            span: 24,
            type: "textarea",
            disabled: this.pageType == "view",
            maxlength: 125,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入存放位置",
                trigger: "blur",
              }
            ],
          },
          {
            label: "现场照片",
            prop: "files",
            span: 24,
          },
        ],
      };
    },
  },
  methods: {
    init(type, data) {
      this.form = {};
      this.showDialog = true;

      this.noneUploadImgCer = false;
      this.noneUploadImg = false;
      this.pageType = type;
      if (type == "edit") {
        this.title = "编辑应急物资";
        this.form = {
          ...data,
        };
        if (this.form.files != "") {
          this.form.files = JSON.parse(this.form.files);
          this.fileList = this.form.files.map((item) => {
            return {
              ...item,
              url: item.link,
              fileUrl: item.link,
            };
          });
        }

        this.noneUploadImg = this.fileList.length >= this.limitCountImg;
      }else {
        this.title = "新增应急物资";
        this.form.orgId = this.userInfo.dept_id;
        this.form.files = []
      }
    },

    selectPeopleData(row, deptId, treeDeptName) {
      this.$set(this.form, "dutyUser", row.id);
      this.$set(this.form, "dutyUserName", row.realName);
      this.peopleShow = false;
    },

    // 新增左侧树节点
    handleTreeNodeSave() {
      if (this.pageType != "view") {
        this.$refs.addForm.submit();
      }
    },
    //身份证
    handleIdFilesSuccess(res, file, fileList) {
      this.form.files = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    // 判断数量，是否显示上传icon
    uploadImgChange(file, fileList) {
      this.noneUploadImg = fileList.length >= this.limitCountImg;
    },
    // 删除图片，判断数量，是否显示icon
    handleDeleteImgRemove(file, fileList) {
      this.form.files = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
      this.noneUploadImg = fileList.length >= this.limitCountImg;
    },
    handlePreview(file) {
      //判断只有图片可以点击打开放大图
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileName == "jpg" ||
        fileName == "png" ||
        fileName == "jpeg" ||
        fileName == "bmp"
      ) {
        this.dialogVisible = true;
        if (file.response) {
          //判断新增和编辑时候 地址不一样
          this.dialogImageUrl = file.response.data.objectUrl
            ? file.response.data.objectUrl
            : file.response.data.link;
        } else {
          this.dialogImageUrl = file.link;
        }
      }
    },
    beforeAvatarUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif" ||
        file.type === "image/png";

      if (!isJPG) {
        this.$message.warning("上传图片只能是 自定义文件 格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    treeNodeSave(data) {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          if (this.pageType != "edit") {
            data.id = "";
          }
          let queryData = {
            ...data,
          };
          if (queryData.files instanceof Array&&queryData.files.length) {
            queryData.files = JSON.stringify(queryData.files);
          }else{
            queryData.files = ""
          }

          this.dialogLoading = true;
          this.$loading();
          ermaterialAdd(queryData)
            .then((res) => {
              const { msg, data } = res.data;

              if (res.data.code == 200) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
              } else {
                this.$message.success(msg);
              }
              this.$emit("callback");
              this.form.unit = ""
              this.form = {};
              done();
              (this.fileList = []), (this.showDialog = false);
              this.dialogLoading = false;
              this.$loading().close();
            })
            .catch((err) => {
              done();
              this.$loading().close();
            })
            .finally(() => {
              done();
              this.dialogLoading = false;
            });
        }
      });
    },
    closeDialog() {
      this.form = {};
      (this.fileList = []), this.$refs.addForm.resetFields();
      this.showDialog = false;
    },
  },
};
</script>
