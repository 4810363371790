var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              loading: _vm.treeLoading,
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              treeTitle: "组织架构",
              isShowdig: false,
              showCheckbox: false,
              "default-expand-all": true,
              treeExpand: true,
              expandOnClickNode: false,
              currentNodeKey: _vm.currentNodeKey,
              defaultExpandedKeys: _vm.defaultExpandedKeys,
              defaultCheckedKeys: _vm.defaultCheckedKeys,
              nodeKey: "id",
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: { "head-title": "本级组织的项目人员汇总信息" },
              }),
              _c("grid-head-layout", {
                ref: "levelHeadLayout",
                attrs: { "search-columns": _vm.levelSearchColumns },
                on: {
                  "grid-head-search": _vm.levelSearch,
                  "grid-head-empty": _vm.levelEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                staticClass: "overseasPerson",
                attrs: {
                  "table-options": _vm.levelTableOptions,
                  "table-data": _vm.levelTableData,
                  "table-loading": _vm.levelTableLoading,
                  "data-total": _vm.levelPage.total,
                  page: _vm.levelPage,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "page-current-change": _vm.levelOnLoad,
                  "page-size-change": _vm.levelOnLoad,
                  "page-refresh-change": _vm.levelOnLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.rowApprove(row)
                              },
                            },
                          },
                          [_vm._v("填报")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.rowApprove(row)
                              },
                            },
                          },
                          [_vm._v("流程详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("head-layout", {
                attrs: { "head-title": "下级组织及项目人员信息" },
              }),
              _c("grid-head-layout", {
                ref: "belowHeadLayout",
                attrs: { "search-columns": _vm.belowSearchColumns },
                on: {
                  "grid-head-search": _vm.belowSearch,
                  "grid-head-empty": _vm.belowEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                staticClass: "overseasPerson",
                attrs: {
                  "table-options": _vm.belowTableOptions,
                  "table-data": _vm.belowTableData,
                  "table-loading": _vm.belowTableLoading,
                  "data-total": _vm.belowPage.total,
                  page: _vm.belowPage,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "page-current-change": _vm.belowOnLoad,
                  "page-size-change": _vm.belowOnLoad,
                  "page-refresh-change": _vm.belowOnLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.rowApprove(row)
                              },
                            },
                          },
                          [_vm._v("填报")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.rowApprove(row)
                              },
                            },
                          },
                          [_vm._v("流程详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }