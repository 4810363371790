var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "row-view": _vm.rowView,
                  "row-download": _vm.rowDownload,
                  "row-remove": _vm.rowRemove,
                  "row-setting": _vm.rowSetting,
                },
                scopedSlots: _vm._u([
                  {
                    key: "synchronizationStatus",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("synchronizationStatusFilter")(
                                row.synchronizationStatus
                              )
                            ) +
                            "\n              "
                        ),
                      ]
                    },
                  },
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.aaaa(row)
                              },
                            },
                          },
                          [_vm._v("重新同步")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }