var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "链接文本" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入链接文本" },
            model: {
              value: _vm.activeData.content,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "content", $$v)
              },
              expression: "activeData.content",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "链接地址" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入链接地址" },
              model: {
                value: _vm.activeData.href,
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "href", $$v)
                },
                expression: "activeData.href",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "85px" },
                  attrs: { slot: "append", placeholder: "请选择" },
                  slot: "append",
                  model: {
                    value: _vm.activeData.target,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeData, "target", $$v)
                    },
                    expression: "activeData.target",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "_self", value: "_self" },
                  }),
                  _c("el-option", {
                    attrs: { label: "_blank", value: "_blank" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "对齐方式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.activeData.textStyle["text-align"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData.textStyle, "text-align", $$v)
                },
                expression: "activeData.textStyle['text-align']",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "left" } }, [
                _vm._v("左对齐"),
              ]),
              _c("el-radio-button", { attrs: { label: "center" } }, [
                _vm._v("居中对齐"),
              ]),
              _c("el-radio-button", { attrs: { label: "right" } }, [
                _vm._v("右对齐"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }