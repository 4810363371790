var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "disFlex" }, [
        _c("div", { staticClass: "card-pd-4-26" }, [
          _c("div", { staticClass: "card-pd-4-26-top" }, [
            _c("div", { staticClass: "card-pd-4-26-topLeft" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value1) || 0)
              ),
              _c("span", [_vm._v("个")]),
            ]),
            _c("img", {
              attrs: { src: require("@/assets/images/board/one.png") },
            }),
          ]),
          _c("div", { staticClass: "card-pd-4-26-bottom" }, [
            _vm._v("\n        在建境内项目、机构、工厂总数量\n      "),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-4-26" }, [
          _c("div", { staticClass: "card-pd-4-26-top" }, [
            _c("div", { staticClass: "card-pd-4-26-topLeft" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value2) || 0)
              ),
              _c("span", [_vm._v("人")]),
            ]),
            _c("img", {
              attrs: { src: require("@/assets/images/board/two.png") },
            }),
          ]),
          _c("div", { staticClass: "card-pd-4-26-bottom" }, [
            _vm._v("\n        在建境内机构总人数量\n      "),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-4-26" }, [
          _c("div", { staticClass: "card-pd-4-26-top" }, [
            _c("div", { staticClass: "card-pd-4-26-topLeft" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value3) || 0)
              ),
              _c("span", [_vm._v("人")]),
            ]),
            _c("img", {
              attrs: { src: require("@/assets/images/board/three.png") },
            }),
          ]),
          _c("div", { staticClass: "card-pd-4-26-bottom" }, [
            _vm._v("\n        专职安全环保人员\n      "),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-4-26" }, [
          _c("div", { staticClass: "card-pd-4-26-top" }, [
            _c("div", { staticClass: "card-pd-4-26-topLeft" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value4) || 0)
              ),
              _c("span", [_vm._v("小时")]),
            ]),
            _c("img", {
              attrs: { src: require("@/assets/images/board/four.png") },
            }),
          ]),
          _c("div", { staticClass: "card-pd-4-26-bottom" }, [
            _vm._v("\n        项目、工厂安全生产工时\n      "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "disFlex" }, [
        _c("div", { staticClass: "card-pd-2" }, [
          _c("div", { staticClass: "card-pd-2-top" }, [
            _c("span", { staticClass: "card-pd-2-top-Title" }, [
              _vm._v("\n          安全环保投入统计\n        "),
            ]),
            _c("div", { staticClass: "card-pd-2-top-btn" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/board/five.png") },
                on: { click: _vm.openDiscount },
              }),
              _c("img", {
                attrs: { src: require("@/assets/images/board/six.png") },
                on: { click: _vm.openColumnar },
              }),
              _c("img", {
                attrs: { src: require("@/assets/images/board/seven.png") },
              }),
              _c("img", {
                attrs: { src: require("@/assets/images/board/eight.png") },
              }),
            ]),
          ]),
          _c("div", { staticClass: "card-pd-2-Bottom" }, [
            _c("div", { staticClass: "card-pd-2-BotLitte" }, [
              _c("div", { staticClass: "priceNumber" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatWithCommas(_vm.dataForm.value5) || 0)
                ),
                _c("span", [_vm._v("万元")]),
              ]),
              _c("div", { staticClass: "lineBox" }),
              _c("div", { staticClass: "priceContent" }, [
                _vm._v("\n            安全环保投入总额\n          "),
              ]),
            ]),
            _c("div", { staticClass: "card-pd-2-BotLitte" }, [
              _c("div", { staticClass: "priceNumber" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatWithCommas(_vm.dataForm.value6) || 0)
                ),
                _c("span", [_vm._v("万元")]),
              ]),
              _c("div", { staticClass: "lineBox" }),
              _c("div", { staticClass: "priceContent" }, [
                _vm._v("\n            安全投入金额\n          "),
              ]),
            ]),
            _c("div", { staticClass: "card-pd-2-BotLitte" }, [
              _c("div", { staticClass: "priceNumber" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatWithCommas(_vm.dataForm.value7) || 0)
                ),
                _c("span", [_vm._v("万元")]),
              ]),
              _c("div", { staticClass: "lineBox" }),
              _c("div", { staticClass: "priceContent" }, [
                _vm._v("\n            环保投入金额\n          "),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2" }, [
          _vm._m(0),
          _c(
            "div",
            { staticStyle: { display: "flex", "margin-top": "22px" } },
            [
              _c("div", { attrs: { id: "chart-one" } }),
              _c("div", { staticClass: "chart-one-info" }, [
                _c("div", { staticClass: "chart-one-info-box" }, [
                  _c("div", { staticClass: "info-circle" }),
                  _c("span", [_vm._v("建设项目")]),
                  _c("span", { staticStyle: { margin: "0 12px" } }, [
                    _vm._v(_vm._s(_vm.formatWithCommas(380)) + "个"),
                  ]),
                  _c("span", [_vm._v("64%")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "chart-one-info-box",
                    staticStyle: { "margin-top": "18px" },
                  },
                  [
                    _c("div", {
                      staticClass: "info-circle",
                      staticStyle: {
                        background:
                          "linear-gradient(180deg, #FFAB2D 0%, #FFCD82 100%)",
                      },
                    }),
                    _c("span", [_vm._v("运维项目")]),
                    _c("span", { staticStyle: { margin: "0 12px" } }, [
                      _vm._v(_vm._s(_vm.formatWithCommas(211)) + "个"),
                    ]),
                    _c("span", [_vm._v("36%")]),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "disFlex" }, [
        _c("div", { staticClass: "card-pd-2" }, [
          _vm._m(1),
          _c("div", { staticClass: "card-pd-2-Bottom" }, [
            _c(
              "div",
              { staticClass: "card-pd-2-BotLitte2" },
              [
                _c("div", { staticClass: "botLitte2-title" }, [
                  _vm._v("\n            项目、机构\n          "),
                ]),
                _c("div", { staticClass: "botLitte2-number" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value8) || 0)
                  ),
                  _c("span", [_vm._v("个")]),
                ]),
                _c("slider", { attrs: { value: 68 } }),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            风险地区项目："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value9) || 0)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            占比："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value10) || 0) +
                        "%"
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "card-pd-2-BotLitte2" },
              [
                _c("div", { staticClass: "botLitte2-title" }, [
                  _vm._v("\n            工厂\n          "),
                ]),
                _c("div", { staticClass: "botLitte2-number" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value11) || 0)
                  ),
                  _c("span", [_vm._v("个")]),
                ]),
                _c("slider", { attrs: { value: 68 } }),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            风险地区工厂："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value12) || 0)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            占比："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value13) || 0) +
                        "%"
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "card-pd-2-BotLitte2" },
              [
                _c("div", { staticClass: "botLitte2-title" }, [
                  _vm._v("\n            人员\n          "),
                ]),
                _c("div", { staticClass: "botLitte2-number" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value14) || 0)
                  ),
                  _c("span", [_vm._v("个")]),
                ]),
                _c("slider", { attrs: { value: 68 } }),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            风险地区人员："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value15) || 0)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            占比："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value16) || 0) +
                        "%"
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2" }, [
          _vm._m(2),
          _c("div", { staticClass: "card-pd-2-Bottom" }, [
            _c(
              "div",
              { staticClass: "card-pd-2-BotLitte2" },
              [
                _c("div", { staticClass: "botLitte2-title" }, [
                  _vm._v("\n            项目、机构\n          "),
                ]),
                _c("div", { staticClass: "botLitte2-number" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value17) || 0)
                  ),
                  _c("span", [_vm._v("个")]),
                ]),
                _c("slider", { attrs: { value: 68 } }),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            风险地区项目："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value18) || 0)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            占比："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value19) || 0) +
                        "%"
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "card-pd-2-BotLitte2" },
              [
                _c("div", { staticClass: "botLitte2-title" }, [
                  _vm._v("\n            工厂\n          "),
                ]),
                _c("div", { staticClass: "botLitte2-number" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value20) || 0)
                  ),
                  _c("span", [_vm._v("个")]),
                ]),
                _c("slider", { attrs: { value: 68 } }),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            风险地区工厂："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value21) || 0)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            占比："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value22) || 0) +
                        "%"
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "card-pd-2-BotLitte2" },
              [
                _c("div", { staticClass: "botLitte2-title" }, [
                  _vm._v("\n            人员\n          "),
                ]),
                _c("div", { staticClass: "botLitte2-number" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value23) || 0)
                  ),
                  _c("span", [_vm._v("个")]),
                ]),
                _c("slider", { attrs: { value: 68 } }),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            风险地区人员："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value24) || 0)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "botLitte2-info" }, [
                  _vm._v("\n            占比："),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatWithCommas(_vm.dataForm.value25) || 0) +
                        "%"
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._m(3),
      _vm._m(4),
      _c("discount", { ref: "discount" }),
      _c("columnar", { ref: "columnar" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v("\n          项目分类统计\n        "),
      ]),
      _c("div", { staticClass: "card-pd-2-top-btn" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/board/five.png") },
        }),
        _c("img", { attrs: { src: require("@/assets/images/board/six.png") } }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/seven.png") },
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/eight.png") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v("\n          境外在建项目、运营机构与人员分布情况\n        "),
      ]),
      _c("div", { staticClass: "card-pd-2-top-btn" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/board/five.png") },
        }),
        _c("img", { attrs: { src: require("@/assets/images/board/six.png") } }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/seven.png") },
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/eight.png") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v("\n          境内在建项目、运营机构与人员分布情况\n        "),
      ]),
      _c("div", { staticClass: "card-pd-2-top-btn" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/board/five.png") },
        }),
        _c("img", { attrs: { src: require("@/assets/images/board/six.png") } }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/seven.png") },
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/board/eight.png") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "disFlex" }, [
      _c("div", { staticClass: "card-pd-2" }, [
        _c("div", { staticClass: "card-pd-2-top" }, [
          _c("span", { staticClass: "card-pd-2-top-Title" }, [
            _vm._v("\n          境外国家项目数TOP5及人数统计\n        "),
          ]),
          _c("div", { staticClass: "card-pd-2-top-btn" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/board/five.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/six.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/seven.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/eight.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { attrs: { id: "chart-two" } }),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-2" }, [
        _c("div", { staticClass: "card-pd-2-top" }, [
          _c("span", { staticClass: "card-pd-2-top-Title" }, [
            _vm._v("\n          境内省区项目数TOP5及人数统计\n        "),
          ]),
          _c("div", { staticClass: "card-pd-2-top-btn" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/board/five.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/six.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/seven.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/eight.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { attrs: { id: "chart-three" } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "disFlex" }, [
      _c("div", { staticClass: "card-pd-2" }, [
        _c("div", { staticClass: "card-pd-2-top" }, [
          _c("span", { staticClass: "card-pd-2-top-Title" }, [
            _vm._v("\n          下属组织在建项目、运营机构数量TOP5\n        "),
          ]),
          _c("div", { staticClass: "card-pd-2-top-btn" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/board/five.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/six.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/seven.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/eight.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { attrs: { id: "chart-four" } }),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-2" }, [
        _c("div", { staticClass: "card-pd-2-top" }, [
          _c("span", { staticClass: "card-pd-2-top-Title" }, [
            _vm._v(
              "\n          下属组织在建项目、运营机构人员分布TOP5\n        "
            ),
          ]),
          _c("div", { staticClass: "card-pd-2-top-btn" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/board/five.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/six.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/seven.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/images/board/eight.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { attrs: { id: "chart-five" } }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }