var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _vm.treeShow
            ? _c("CommonTree", {
                attrs: {
                  loading: _vm.treeLoading,
                  treeData: _vm.treeData,
                  defaultProps: _vm.defaultProps,
                  searchTitle: _vm.searchTitle,
                  treeTitle: "组织架构",
                  isShowdig: false,
                  showCheckbox: false,
                  "default-expand-all": true,
                  treeExpand: true,
                  expandOnClickNode: false,
                  currentNodeKey: _vm.currentNodeKey,
                  defaultExpandedKeys: _vm.defaultExpandedKeys,
                  defaultCheckedKeys: _vm.defaultCheckedKeys,
                  nodeKey: "id",
                },
                on: { getNodeClick: _vm.treeNodeClick },
              })
            : _vm._e(),
          _c(
            "el-main",
            { staticStyle: { position: "relative" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "境外项目人员信息",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-approve": _vm.headApprove,
                  "head-reject": _vm.headReject,
                  "head-urging": _vm.headUrging,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                staticClass: "overseasPerson",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.crudLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        row.dataState === "2"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowApprove(row)
                                  },
                                },
                              },
                              [_vm._v(" 通过\n            ")]
                            )
                          : _vm._e(),
                        row.dataState === "2"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowReject(row)
                                  },
                                },
                              },
                              [_vm._v(" 驳回\n            ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.rejectDiaShow
        ? _c(
            "CommonDialog",
            {
              attrs: { dialogTitle: "审批驳回", width: "500px" },
              on: { cancel: _vm.closeReject, confirm: _vm.rejectSubmit },
            },
            [
              _vm.rejectDiaShow
                ? _c(
                    "el-form",
                    {
                      ref: "rejectForm",
                      attrs: { model: _vm.rejectForm, rules: _vm.rejectRules },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "rejectTop" },
                        [
                          _c(
                            "el-popover",
                            {
                              ref: "popover",
                              attrs: { width: "400", trigger: "manual" },
                              on: {
                                show: _vm.openPopover,
                                hide: _vm.closePopover,
                              },
                              model: {
                                value: _vm.popoverShow,
                                callback: function ($$v) {
                                  _vm.popoverShow = $$v
                                },
                                expression: "popoverShow",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "selectContent" },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入内容" },
                                    on: { input: _vm.inputSearch },
                                    model: {
                                      value: _vm.searchValue,
                                      callback: function ($$v) {
                                        _vm.searchValue = $$v
                                      },
                                      expression: "searchValue",
                                    },
                                  }),
                                  _vm._l(
                                    _vm.selectModelList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass: "selectModel",
                                          style: {
                                            color:
                                              _vm.selectActive == index
                                                ? _vm.themeColor
                                                : "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.chooseSelct(
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.name) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _c("div", { staticClass: "line" }),
                                  _c("el-button", {
                                    staticClass: "addBtn",
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-circle-plus-outline",
                                    },
                                    on: { click: _vm.addMore },
                                  }),
                                ],
                                2
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "approvalBtn",
                                  staticStyle: {
                                    width: "100%",
                                    "text-align": "left",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    disabled: _vm.type == "view",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.popoverShow = true
                                    },
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("请选择常用批复意见\n            ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "comment" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 4, maxRows: 4 },
                              maxlength: "200",
                              "show-word-limit": "",
                              placeholder: "请输入审批意见",
                            },
                            model: {
                              value: _vm.rejectForm.comment,
                              callback: function ($$v) {
                                _vm.$set(_vm.rejectForm, "comment", $$v)
                              },
                              expression: "rejectForm.comment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("process-detail", { ref: "processDetailComponent" }),
      _c("approval-opinion-dialog", {
        ref: "approvalOpinionDialog",
        on: { closeDialog: _vm.getOpinionList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }