var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: {
          size: _vm.size,
          "suffix-icon": "el-icon-user",
          placeholder: _vm.placeholder || "人员选择",
          readonly: "",
          disabled: _vm.disabled,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.handleSelect.apply(null, arguments)
          },
        },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
      _c("wf-user-select", {
        ref: "user-select",
        attrs: { "check-type": _vm.checkType, "default-checked": _vm.value },
        on: { onConfirm: _vm.handleUserSelectConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }