<template>
  <div style="position: relative">
    <div>
      <head-layout
        head-title="任务设置"
        :head-btn-options="headBtnOptions"
        @head-remove="handleDelete"
        @head-add="headAdd"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template #customBtn="{row}">
          <el-button
            style="margin: 0 3px"
            v-if="row.acquisitionStatus == 1"
            type="text"
            size="small"
            @click="rowEdit(row)"
          >编辑
          </el-button>
          <el-button
            style="margin: 0 3px"
            v-if="row.acquisitionStatus == 1"
            type="text"
            size="small"
            @click="rowDel(row)"
          >删除
          </el-button>
<!--          <el-button-->
<!--            style="margin: 0 3px"-->
<!--            type="text"-->
<!--            size="small"-->
<!--            @click="rowView(row)"-->
<!--          >查看-->
<!--          </el-button>-->
          <el-button
            style="margin: 0 3px"
            v-if="row.acquisitionStatus == 3"
            type="text"
            size="small"
            @click="rowPublish(row)"
          >失效
          </el-button>
          <el-button
            style="margin: 0 3px"
            v-if="row.acquisitionStatus == 2 || row.acquisitionStatus == 1"
            type="text"
            size="small"
            @click="rowPublish(row)"
          >发布
          </el-button>
        </template>
      </grid-layout>
    </div>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {attributeRemove, attributeList, attributePublish, rpTaskAttributeGeneration} from "@/api/reportTasks";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      treeData: [],
      selectionList: [],
      tableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        linklabel: "taskCode",
        column: [
          {
            label: "任务编码",
            prop: "taskCode",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "任务名称",
            prop: "taskName",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "编制人",
            prop: "weaveUser",
            align: "center",
            width: 150,
            overHidden: true
          },
          {
            label: "编制单位",
            prop: "weaveDept",
            align: "left",
            overHidden: true
          },
          {
            label: "任务分类",
            prop: "isCycle",
            align: "center",
            dataType: "number",
            width: 120,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=task_classification`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },
          {
            label: "发布状态",
            prop: "acquisitionStatus",
            align: "center",
            dataType: "number",
            width: 100,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=publish_status`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      isCycle: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        },
      ],
      searchColumns: [
        {
          label: '任务名称',
          prop: "taskName",
          span: 4,
          placeholder: '请输入任务名称'
        },
        {
          label: '任务编码',
          prop: "taskCode",
          span: 4,
          placeholder: '请输入任务编码'
        },
        {
          label: '任务分类',
          prop: "isCycle",
          span: 4,
          type: 'select',
          placeholder: '请选择任务分类',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=task_classification`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          label: '发布状态',
          prop: "acquisitionStatus",
          span: 4,
          type: 'select',
          placeholder: '请选择发布状态',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=publish_status`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
      ],
    }
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    // 搜索填报设置
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空填报设置搜索条件
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 获取列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      attributeList(page.currentPage, page.pageSize, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      })
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/reportManager/taskSet/edit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: '',
        }
      })
    },
    // 查看
    rowView(row) {
      this.$router.push({
        path: `/reportManager/taskSet/view`,
        query: {
          id: row.id,
          type: 'view',
          pageType: '',
        }
      })
    },
    // 单删除
    rowDel(row) {
      attributeRemove(row.id).then((res) => {
        if (res.data.code == 200) {
          this.gridHeadSearch();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      })
    },
    // 批量删除
    handleDelete() {
      if (this.selectionList.length == 0) {
        this.$message({
          message: "请选择要删除的数据",
          type: "warning",
        });
        return;
      }
      attributeRemove(this.ids).then((res) => {
        if (res.data.code == 200) {
          this.gridHeadSearch();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      })
    },
    // 新增
    headAdd() {
      this.$router.push({
        path: `/reportManager/taskSet/add`,
        query: {
          type: 'add',
          pageType: '',
        }
      })
    },
    // 发布
    rowPublish(row) {
      if (row.isCycle == 1) {
        attributePublish(row).then((res) => {
          if (res.data.code == 200) {
            this.gridHeadSearch();
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        })
      }
      if (row.isCycle == 2) {
        rpTaskAttributeGeneration(row).then((res) => {
          if (res.data.code == 200) {
            this.gridHeadSearch();
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        })
      }
    },
  }
}
</script>
