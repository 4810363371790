var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "videoMain" }, [
    _c("div", { staticClass: "videoTopBox" }, [
      _c(
        "div",
        { staticClass: "videoTopLeft" },
        [
          _c("avue-input-tree", {
            staticClass: "project-area-tree",
            attrs: {
              "default-expand-all": "",
              placeholder: "请选择组织",
              dic: _vm.deptTreeData,
              size: "small",
              clearable: false,
              props: _vm.deptProps,
            },
            on: { change: _vm.deptTreeDataChange, blur: _vm.deptFocus },
            model: {
              value: _vm.deptId,
              callback: function ($$v) {
                _vm.deptId = $$v
              },
              expression: "deptId",
            },
          }),
          _c("avue-input-tree", {
            staticClass: "project-area-tree",
            attrs: {
              "default-expand-all": "",
              placeholder: "请选择区域分类",
              dic: _vm.areaTypeTreeData,
              props: _vm.inputTreeProps,
              size: "small",
              clearable: false,
            },
            on: { change: _vm.areaTypeTreeDataChange, blur: _vm.typeFocus },
            model: {
              value: _vm.areaTypeId,
              callback: function ($$v) {
                _vm.areaTypeId = $$v
              },
              expression: "areaTypeId",
            },
          }),
          _c("avue-input-tree", {
            staticClass: "project-area-tree",
            attrs: {
              "default-expand-all": "",
              placeholder: "请选择区域",
              dic: _vm.areaTreeData,
              props: _vm.areaTreeProps,
              size: "small",
              clearable: false,
            },
            on: { change: _vm.projectAreaChange, blur: _vm.areaFocus },
            model: {
              value: _vm.areaId,
              callback: function ($$v) {
                _vm.areaId = $$v
              },
              expression: "areaId",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "videoTopLeft" },
        [
          _c(
            "el-popover",
            {
              ref: "addPopover",
              attrs: { placement: "bottom", width: "340", trigger: "manual" },
              on: { show: _vm.openAddPopover, hide: _vm.closeAddPopover },
              model: {
                value: _vm.addShow,
                callback: function ($$v) {
                  _vm.addShow = $$v
                },
                expression: "addShow",
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    model: _vm.boardForm,
                    rules: _vm.boardRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "看板名称", prop: "boardName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入看板名称" },
                        model: {
                          value: _vm.boardForm.boardName,
                          callback: function ($$v) {
                            _vm.$set(_vm.boardForm, "boardName", $$v)
                          },
                          expression: "boardForm.boardName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addBoard },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.addShow = true
                    },
                  },
                  slot: "reference",
                },
                [_vm._v("加入看板")]
              ),
            ],
            1
          ),
          _c(
            "el-popover",
            {
              ref: "boardPopover",
              attrs: { placement: "bottom", width: "250", trigger: "manual" },
              on: { show: _vm.openBoardPopover, hide: _vm.closeBoardPopover },
              model: {
                value: _vm.boardShow,
                callback: function ($$v) {
                  _vm.boardShow = $$v
                },
                expression: "boardShow",
              },
            },
            [
              _c(
                "div",
                { staticClass: "videoBoardBox" },
                _vm._l(_vm.boardList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "videoBoard",
                      style: {
                        color:
                          _vm.boardActiveId == item.id ? _vm.themeColor : "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.chooseBoard(item, index)
                        },
                      },
                    },
                    [
                      _vm._v("\n            " + _vm._s(item.boardName) + " "),
                      _c("i", {
                        staticClass: "el-icon-circle-close",
                        staticStyle: { "margin-left": "12px" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.removeBoard(item)
                          },
                        },
                      }),
                    ]
                  )
                }),
                0
              ),
              _c(
                "el-button",
                {
                  staticStyle: { margin: "0 12px" },
                  attrs: { slot: "reference", type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.boardShow = true
                    },
                  },
                  slot: "reference",
                },
                [_vm._v("查看看板")]
              ),
            ],
            1
          ),
          _c("el-button", {
            staticStyle: { padding: "6px" },
            attrs: { type: "primary", size: "mini", icon: "icon-quanping" },
            on: {
              click: function ($event) {
                _vm.fullVideo = true
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "videoBig" }, [
      _c("div", { staticClass: "videoLeft" }, [
        _c(
          "div",
          { staticClass: "leftTopGrid" },
          [
            _vm._l(_vm.buttonList, function (item, index) {
              return _c("el-button", {
                key: index,
                attrs: {
                  type: item.value == _vm.value ? "primary" : "",
                  size: "mini",
                  icon: item.icon,
                },
                on: {
                  click: function ($event) {
                    return _vm.chooseBtn(item, index)
                  },
                },
              })
            }),
            _c(
              "el-select",
              {
                staticStyle: { "margin-left": "5px" },
                attrs: { placeholder: "请选择分屏数量" },
                on: { change: _vm.chooseValue },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "leftListBox" },
          [
            _vm.value == "1"
              ? _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.chooseRadio },
                    model: {
                      value: _vm.radioBoxGroup,
                      callback: function ($$v) {
                        _vm.radioBoxGroup = $$v
                      },
                      expression: "radioBoxGroup",
                    },
                  },
                  _vm._l(_vm.videoList, function (item, index) {
                    return _c(
                      "el-radio",
                      { key: index, attrs: { label: item.id, border: "" } },
                      [_vm._v(_vm._s(item.name) + "\n          ")]
                    )
                  }),
                  1
                )
              : _c(
                  "el-checkbox-group",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.chooseCheck },
                    model: {
                      value: _vm.checkBoxGroup,
                      callback: function ($$v) {
                        _vm.checkBoxGroup = $$v
                      },
                      expression: "checkBoxGroup",
                    },
                  },
                  _vm._l(_vm.videoList, function (item, index) {
                    return _c(
                      "el-checkbox",
                      { key: index, attrs: { label: item.id, border: "" } },
                      [_vm._v(_vm._s(item.name) + "\n          ")]
                    )
                  }),
                  1
                ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "videoRight" }, [
        _c(
          "div",
          { class: _vm.fullVideo ? "videoMainFullBox" : "videoMainBox" },
          [
            _vm.fullVideo == true
              ? _c("el-button", {
                  staticStyle: {
                    position: "absolute",
                    top: "12px",
                    right: "12px",
                    padding: "6px",
                    "z-index": "10000",
                  },
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "icon-tuichuquanping",
                  },
                  on: {
                    click: function ($event) {
                      _vm.fullVideo = false
                    },
                  },
                })
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.value == "1",
                    expression: "value == '1'",
                  },
                ],
                staticClass: "videoBox",
              },
              [
                _c("div", { staticClass: "oneVideo" }, [
                  _c("video", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.radioUrl != "",
                        expression: "radioUrl != ''",
                      },
                    ],
                    staticClass: "Video",
                    attrs: {
                      id: "video",
                      src: _vm.radioUrl,
                      autoplay: "",
                      controls: "",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.radioUrl == "",
                          expression: "radioUrl == ''",
                        },
                      ],
                      staticStyle: { "line-height": "120px" },
                    },
                    [_vm._v("暂无播放源")]
                  ),
                ]),
              ]
            ),
            _vm.value == "4"
              ? _c(
                  "div",
                  { staticClass: "videoBox" },
                  [
                    _vm._l(_vm.checkBoxGroup, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "fourVideo" },
                        [
                          _c("video", {
                            staticClass: "Video",
                            attrs: {
                              id: "morevideo" + index,
                              src: item,
                              autoplay: "",
                              controls: "",
                            },
                          }),
                        ]
                      )
                    }),
                    _vm._l(_vm.residueNumber, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "fourVideo" },
                        [_vm._v("\n            暂无播放源\n          ")]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.value == "9"
              ? _c(
                  "div",
                  { staticClass: "videoBox" },
                  [
                    _vm._l(_vm.checkBoxGroup, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "nineVideo" },
                        [
                          _c("video", {
                            staticClass: "Video",
                            attrs: {
                              id: "morevideo" + index,
                              src: item,
                              autoplay: "",
                              controls: "",
                            },
                          }),
                        ]
                      )
                    }),
                    _vm._l(_vm.residueNumber, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "nineVideo" },
                        [_vm._v("\n            暂无播放源\n          ")]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.value == "12"
              ? _c(
                  "div",
                  { staticClass: "videoBox" },
                  [
                    _vm._l(_vm.checkBoxGroup, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "twelveVideo" },
                        [
                          _c("video", {
                            staticClass: "Video",
                            attrs: {
                              id: "morevideo" + index,
                              src: item,
                              autoplay: "",
                              controls: "",
                            },
                          }),
                        ]
                      )
                    }),
                    _vm._l(_vm.residueNumber, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "twelveVideo" },
                        [_vm._v("\n            暂无播放源\n          ")]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }