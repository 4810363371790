var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.headTitle ? true : false,
          expression: "!headTitle ? true : false",
        },
      ],
      staticClass: "daily",
      attrs: {
        "element-loading-background": "rgba(255,255,255,1)",
        "element-loading-spinner": "el-icon-loading",
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex-container" },
        [
          _c("CommonTreeNew", {
            ref: "commonTree",
            attrs: {
              treeTitle: "项目架构",
              defaultProps: _vm.Props,
              showCheckbox: false,
              urlParmas: {
                tenantId: "",
                deptCategory: "",
                parentId: _vm.userInfo.deptId,
              },
              "node-key": "id",
            },
            on: {
              getNodeClick: _vm.handleNode,
              getTreeData: _vm.commonTreeData,
            },
          }),
          _c(
            "div",
            { staticClass: "clacWidth" },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": _vm.headTitle,
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-romve": _vm.headRomve, "head-add": _vm.headAdd },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOption,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "gird-handle-select-click": _vm.selectionChange,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        row.actStatus != "FINISHED" &&
                        row.startUserId == _vm.userInfo.user_id
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowEdit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        row.actStatus == "FINISHED"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowView(row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                        row.actStatus == "PREPARE" &&
                        row.startUserId == _vm.userInfo.user_id
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowDel([row])
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                        row.actStatus != "PREPARE"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowCopy(row)
                                  },
                                },
                              },
                              [_vm._v("复制")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "检查类型选择",
            visible: _vm.checkTypeVisible,
            width: "940px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.checkTypeVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "kicket-content" },
            _vm._l(_vm.checkTypeList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "kicket",
                  on: {
                    click: function ($event) {
                      return _vm.selectKicket(item)
                    },
                  },
                },
                [
                  _c("div", { style: _vm.computedStyle }, [
                    _c("img", {
                      staticClass: "img-item",
                      attrs: { src: item.kictectIcon, alt: "" },
                    }),
                  ]),
                  _c("div", { staticClass: "text-item" }, [
                    _vm._v(_vm._s(item.dictValue)),
                  ]),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.checkTypeVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }