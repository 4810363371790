<template>
    <div>
        <head-layout :head-title="$t('cip.dc.AIDComission.title1')">
        </head-layout>

        <div class="headers" style="display: flex;">
            <el-input :placeholder="$t('cip.dc.AIDComission.placeholder1')" v-model="queryList.algorithm_name" class="new-sino-input" size="mini"
                style="width:200px" />
            <el-button-group style="margin-left: 10px;">
                <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
                <el-button size="mini" icon="reset-refresh icon-refresh" @click="czFn"></el-button>
            </el-button-group>

        </div>




        <grid-layout ref="gridLayout" :data-total="total" @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange" @page-refresh-change="onLoad" :page="page"
            @gird-handle-select-click="handleSelectionChange" :tableOptions="tableOptions" :tableData="tableData"
            :tableLoading="tableLoading" :gridRowBtn="gridRowBtn" @grid-edit="handleEdit"  :searchColumns="searchColumns">



        </grid-layout>



    </div>
</template>

<script>


import { getAlgorithmList } from '@/api/Ai/index'

import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
export default {
    components: {
        HeadLayout,
        GridLayout,
        dialogHeadBtn,
        formLayout
    },
    data() {
        return {
            dialogtext: '',
            runState: "",
            btnType: '',
            dialogVisible: false,
            gridRowBtn: [
                {
                    label: this.$t('cip.cmn.btn.editBtn'),
                    emit: "grid-edit",
                    remark: 'edit',
                    type: "text",
                    icon: ""
                },

            ],
            tableOptions: {
                customRowAddBtn: false,
                menuWidth: 200,
                column: [
                    {
                        label: this.$t('cip.dc.AIDComission.name'),
                        prop: "algorithm_name",
                        cell: true,
                    },
                    {
                        label: this.$t('cip.dc.AIDComission.code'),
                        prop: "algorithm_id",
                        cell: true,
                        overHidden: true
                    },
                    {
                        label: this.$t('cip.dc.AIDComission.url'),
                        prop: "training_url",
                        cell: true,
                    },

                    {
                        label: this.$t('cip.dc.AIDComission.sort'),
                        prop: "algorithm_sort",
                        cell: true,
                    },

                    {
                        label: this.$t('cip.dc.AIDComission.createby'),
                        prop: "create_by",
                        cell: true,
                    },

                    {
                        label: this.$t('cip.dc.AIDComission.createDate'),
                        prop: "create_date",
                        cell: true,
                    },




                ],
            },
            page: {
                pageSize: PAGE_CONSTANT.TWENTY,
                currentPage: 1,
                total: 0
            },
            tableLoading: false,
            multipleSelection: [],
            tableData: [],
            total: 0,

            queryList: {
                startIndex: 1,
                perPage: 10,
                algorithm_name: "",
                algorithm_class_id: ""
            },
            loading: false,

        };
    },
    computed: {

    },
    methods: {
        onLoad(page, params = {}) {
            this.loading = true;
            getAlgorithmList(this.queryList).then(res => {
                this.loading = false;

                this.tableData = res.data.data.list
                this.total = res.data.data.total
            });
        },


        handleEdit(item) {
            this.$router.push({
                path: '/suanfaSetEdit',
                query: {
                    id: item.algorithm_id
                }
            })
        },







        czFn() {
            this.queryList.algorithm_name = '';
            this.queryList.startIndex = 1
            this.onLoad()
        },
        handleSelectionChange(val) {
            console.log(val)
            this.multipleSelection = val;
        },


        handleSizeChange(val) {
            this.page.pageSize = val.pageSize
            this.queryList.startIndex = 1
            this.queryList.perPage = val.pageSize;
            this.onLoad()
        },
        handleCurrentChange(val) {
            this.page.currentPage = val.currentPage
            this.queryList.perPage = val.currentPage;
            this.onLoad()
        },

    },
    created() {
        this.onLoad();
    },
    mounted() {
    },
};
</script>
<style scoped>
.headers {
    background: #FFFFFF;
    padding: 14px 12px;
}

.headers ::v-deep .el-button {
    height: 28px;
}
</style>
<style>
.el-button+.el-button {
    margin-left: 0px;
}
</style>


