<template>
  <div class="board" id="board">
    <div class="block-div h-200">
      <div class="title">项目人员</div>
      <div class="content flex">
        <div class="flex-cloum w-25">
          <div class="num">
            {{ dataForm.value1 }}<span class="unit">人</span>
          </div>
          <div class="name">在建项目总人数</div>
        </div>
        <div class="flex-cloum w-25">
          <div class="num">
            {{ dataForm.value2 }}<span class="unit">人</span>
          </div>
          <div class="name">企业总人数</div>
        </div>
        <div class="flex-cloum w-25">
          <div class="num">
            {{ dataForm.value3 }}<span class="unit">人</span>
          </div>
          <div class="name">安全管理人员</div>
        </div>
        <div class="flex-cloum w-25">
          <div class="num">
            {{ dataForm.value4 }}<span class="unit">人</span>
          </div>
          <div class="name">第三方总人数</div>
        </div>
      </div>
    </div>
    <div class="flex-div m-t-20">
      <div class="block-div w-50">
        <div class="title">企业人员年龄结构</div>
        <div class="content">
          <div id="chart-age" class="chart"></div>
        </div>
      </div>
      <div class="block-div w-50">
        <div class="title">工种结构</div>
        <div class="content">
          <div id="chart-job" class="chart"></div>
        </div>
      </div>
    </div>
    <div class="flex-div m-t-20">
      <div class="flex-cloum-div w-50">
        <div class="block-div w-100 h-50">
          <div class="title">培训次数</div>
          <div class="content flex">
            <div class="num">
              {{ dataForm.value9
              }}<span class="unit" v-if="dataForm.value9">次</span>
            </div>
          </div>
        </div>
        <div class="block-div m-t-20 w-100 h-50">
          <div class="title">培训人数</div>
          <div class="content flex">
            <div class="num">
              {{ dataForm.value10
              }}<span class="unit" v-if="dataForm.value10">人</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block-div w-50">
        <div class="title">培训覆盖率</div>
        <div class="content" style="padding-right: 10px">
          <div id="chart-eighteen" class="chart"></div>
        </div>
      </div>
    </div>
    <div class="flex-div m-t-20">
      <div class="block-div w-100">
        <div class="title">专项培训/入场培训</div>
        <div class="content" style="padding-right: 10px">
          <div class="chart" id="chart-train"></div>
        </div>
      </div>
    </div>
    <div class="flex-div m-t-20">
      <div class="block-div w-100">
        <div class="title">隐患排查</div>
        <div class="content flex">
          <div class="flex-cloum w-25">
            <div class="num">{{ dataForm.value5 }}</div>
            <div class="name">安全检查次数</div>
          </div>
          <div class="flex-cloum w-25">
            <div class="num">{{ dataForm.value6 }}</div>
            <div class="name">隐患数量</div>
          </div>
          <div class="flex-cloum w-25">
            <div class="num">{{ dataForm.value7 }}</div>
            <div class="name">隐患完成数量</div>
          </div>
          <div class="flex-cloum w-25">
            <div class="num">
              {{ dataForm.value8 ? dataForm.value8 * 100 + "%" : "0%" }}
            </div>
            <div class="name">隐患整改率</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-div m-t-20">
      <div class="block-div w-100">
        <div class="title">作业许可数量</div>
        <div class="content" style="padding-right: 10px">
          <div id="chart-task" class="chart"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { appIndex } from "@/api/user";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      year: "",
      month: "",
      dataForm: {
        value1: 0,
        value2: 0,
        value3: 0,
        value4: 0,
        value5: 0,
        value6: 0,
        value7: 0,
        value8: 0,
        value9: 0,
        value10: 0,
        value11: 0,
        value12: 0,
        value13: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.year = new Date().getFullYear();
  },
  mounted() {
    this.getIndexData();
    this.getChartAge();
    this.getChartWork();
    this.getChartWorkPermit();
    this.getChartTrain();
    this.getChartEighteen();
  },
  methods: {
    getChartEighteen() {
      //   appIndex({
      //     indexCode: "ZB0071_Y",
      //     _year: this.year,
      //     companyId: this.userInfo.dept_id,
      //   }).then((res) => {
      //     let value = 0;
      //     if (res.data.code == 200 && res.data.data.records.length != 0) {
      //       value =
      //         res.data.data.records[0]._value >= 1
      //           ? 100
      //           : (res.data.data.records[0]._value * 100).toFixed(2);
      //     }
      var myChart = this.$echarts.init(
        document.getElementById("chart-eighteen")
      );
      var option = {
        series: [
          {
            radius: "100%",
            type: "gauge",
            progress: {
              show: true,
              itemStyle: {
                color: "#FB3636",
              },
              width: 10,
              roundCap: true,
            },
            axisLine: {
              lineStyle: {
                width: 10,
              },
              roundCap: true,
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: false,
              length: 15,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              show: true,
              distance: 10,
              color: "#999",
              fontSize: 10,
            },
            anchor: {
              show: false,
              showAbove: true,
              size: 15,
              itemStyle: {
                borderWidth: 5,
              },
            },
            title: {
              show: true,
              fontSize: 20,
              offsetCenter: [0, "70%"],
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}%",
              fontSize: 28,
              offsetCenter: [0, "5%"],
            },
            pointer: {
              show: false,
            },
            data: [
              {
                value: 82.5 || 0,
                name: "覆盖率",
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      //   });
    },
    // 专项培训/入场培训
    getChartTrain() {
      let oneData = new Promise((resolve, reject) => {
        // 在建境内项目数
        appIndex({
          indexCode: "ZB00139_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records);
          } else {
            resolve(0);
          }
        });
      });
      let twoData = new Promise((resolve, reject) => {
        // 在建境内项目数
        appIndex({
          indexCode: "ZB00140_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records);
          } else {
            resolve(0);
          }
        });
      });
      Promise.all([oneData, twoData]).then((res) => {
        let value1 = [];
        let value2 = [];
        let month = [];
        if (res[0].length) {
          let data1 = res[0].slice(0, 6);
          data1.forEach((item) => {
            value1.push(item._value);
            month.push(item._month + "月");
          });
        }

        if (res[1].length) {
          let data2 = res[1].slice(0, 6) || [];
          if (data2.length) {
            month = [];
          }
          data2.forEach((item) => {
            value2.push(item._value);
            month.push(item._month + "月");
          });
        }

        var myChart = this.$echarts.init(
          document.getElementById("chart-train")
        );
        var option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: ["专项培训", "入场培训"],
          },
          grid: {
            // 让图表占满容器
            top: "40px",
            left: "60px",
            right: "0px",
            bottom: "25px",
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLabel: { color: "#b3b5bb" },
              data: month,
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "单位：次",
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
            },
          ],
          series: [
            {
              name: "专项培训",
              type: "bar",
              emphasis: {
                focus: "series",
              },
              barWidth: 25,
              color: "#FB3636",
              data: value1,
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#FB3636", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#FF8181", // 100% 处的颜色
                        },
                      ],
                    };
                  },
                },
              },
            },
            {
              name: "入场培训",
              type: "bar",
              emphasis: {
                focus: "series",
              },
              barWidth: 25,
              color: "#4aa3ff",
              data: value2,
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    // 作业许可数量
    getChartWorkPermit() {
      //   const { startDate, endDate } = this.getLastSixMonthsRange();
      appIndex({
        indexCode: "ZB00141_Y",
        companyId: this.userInfo.dept_id,
        _year: this.year,
      }).then((res) => {
        let value1 = [];
        let month = [];
        let value2 = [];
        if (res.data.code == 200) {
          let data = res.data.data.records.slice(0, 6);
          data.forEach((item) => {
            value1.push(item._value);
            value2.push(item.org_name);
            month.push(item._month + "月");
          });
        }
        var myChart = this.$echarts.init(document.getElementById("chart-task"));
        var option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: ["作业许可数"],
          },
          grid: {
            // 让图表占满容器
            top: "40px",
            left: "60px",
            right: "0px",
            bottom: "25px",
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLabel: { color: "#b3b5bb" },
              data: month,
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "单位：个",
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
            },
          ],
          series: [
            {
              name: "作业许可数",
              type: "bar",
              emphasis: {
                focus: "series",
              },
              barWidth: 25,
              color: "#FB3636",
              data: value1,
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#FB3636", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#FF8181", // 100% 处的颜色
                        },
                      ],
                    };
                  },
                },
              },
            },
          ],
        };
        myChart.setOption(option);
        myChart.resize();
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    //年龄结构
    getChartAge() {
      var myChart = this.$echarts.init(document.getElementById("chart-age"));
      appIndex({
        indexCode: "ZB00135_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        console.log(res, "ZB00135_Y");
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          let optionData =
            res.data.data.records.map((item) => {
              return { name: item.orgName, value: item.value };
            }) || [];
          let sum = optionData.reduce(function (acc, obj) {
            return acc + obj.value;
          }, 0);
          console.log(res.data.data.records, optionData, "7777777777");
          var option = {
            title: {
              text: "总计",
              top: "40%",
              textAlign: "center",
              left: "50%",
              textStyle: {
                color: "#484A4D",
                fontSize: 16,
                fontWeight: 400,
              },
              subtext: sum || 0,
              subtextStyle: {
                color: "#484A4D",
                fontSize: 26,
                fontWeight: 700,
              },
              itemGap: 10, //主副标题间距
            },
            legend: {
              formatter: (name) => {
                // 在这里自定义 legend 的显示文本
                // statModels是后端返回的对象，对应每个参数的总数据
                let str = name;
                optionData.map((item) => {
                  if (item.name == name) {
                    str = `${name}(${item.value})`;
                  }
                });
                return str;
              },
              // 这里的 data 可以是你实际 series 的 name
              right: "0",
              orient: "vertical",
              textStyle: {
                //图例文字的样式
                fontSize: 16,
              },
            },
            tooltip: {
              formatter: (params) => {
                if (params.name != "") {
                  return params.name + " : " + params.value + "\n" + "(个)";
                }
              },
            },

            series: [
              {
                type: "pie",
                radius: ["63%", "90%"],
                avoidLabelOverlap: false,
                padAngle: 2,
                itemStyle: {
                  borderRadius: 4,
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                label: {
                  show: false,
                  position: "center",
                },
                labelLine: {
                  show: false,
                },
                data: optionData,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          };
          myChart.setOption(option);
          myChart.resize();
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        }
      });
    },
    // 工种结构
    getChartWork() {
      var myChart = this.$echarts.init(document.getElementById("chart-job"));
      appIndex({
        indexCode: "ZB00136_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        console.log(res, "ZB00136_Y");
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          let optionData =
            res.data.data.records.map((item) => {
              return { name: item.orgName, value: item.value };
            }) || [];
          let sum = optionData.reduce(function (acc, obj) {
            return acc + obj.value;
          }, 0);
          console.log(res.data.data.records, optionData, "7777777777");
          let option = {
            title: {
              text: "总计",
              top: "40%",
              textAlign: "center",
              left: "50%",
              textStyle: {
                color: "#484A4D",
                fontSize: 16,
                fontWeight: 400,
              },
              subtext: sum || 0,
              subtextStyle: {
                color: "#484A4D",
                fontSize: 26,
                fontWeight: 700,
              },
              itemGap: 10, //主副标题间距
            },
            legend: {
              right: "0",
              orient: "vertical",
            },
            tooltip: {
              formatter: (params) => {
                if (params.name != "") {
                  return params.name + " : " + params.value + "\n" + "(个)";
                }
              },
            },

            series: [
              {
                type: "pie",
                radius: ["63%", "90%"],
                avoidLabelOverlap: false,
                padAngle: 2,
                itemStyle: {
                  borderRadius: 4,
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                label: {
                  show: false,
                  position: "center",
                },
                labelLine: {
                  show: false,
                },
                data: optionData,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          };
          myChart.setOption(option);
          myChart.resize();
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        }
      });
    },
    getIndexData() {
      let oneData = new Promise((resolve, reject) => {
        // 在建境内项目数
        appIndex({
          indexCode: "INDEX_ZB0002_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      let twoData = new Promise((resolve, reject) => {
        // 企业总人数量
        appIndex({
          indexCode: "ZB00130_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });
      let threeData = new Promise((resolve, reject) => {
        // 安全管理人员数
        appIndex({
          indexCode: "ZB00131_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });
      let fourData = new Promise((resolve, reject) => {
        // 第三方总人数
        appIndex({
          indexCode: "ZB00134_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });
      // 项目人员
      Promise.all([oneData, twoData, threeData, fourData]).then((res) => {
        console.log(res, "项目人员数");
        this.dataForm.value1 = this.formatNumberWithCommas(Number(res[0])) || 0;
        this.dataForm.value2 = this.formatNumberWithCommas(Number(res[1])) || 0;
        this.dataForm.value3 = this.formatNumberWithCommas(Number(res[2])) || 0;
        this.dataForm.value4 = this.formatNumberWithCommas(Number(res[3])) || 0;
      });
      // 隐患排查
      let oneData1 = new Promise((resolve, reject) => {
        // 安全检查次数
        appIndex({
          indexCode: "ZB0036_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      let twoData1 = new Promise((resolve, reject) => {
        // 隐患数量
        appIndex({
          indexCode: "ZB00132_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });
      let threeData1 = new Promise((resolve, reject) => {
        // 隐患完成数量
        appIndex({
          indexCode: "ZB00133_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });
      let fourData1 = new Promise((resolve, reject) => {
        // 总体隐患整改率
        appIndex({
          indexCode: "ZB00103_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });
      // 隐患排查
      Promise.all([oneData1, twoData1, threeData1, fourData1]).then((res) => {
        console.log(res, "隐患排查");
        this.dataForm.value5 = this.formatNumberWithCommas(Number(res[0])) || 0;
        this.dataForm.value6 = this.formatNumberWithCommas(Number(res[1])) || 0;
        this.dataForm.value7 = this.formatNumberWithCommas(Number(res[2])) || 0;
        this.dataForm.value8 = Number(res[3]) || 0;
      });
      // 培训次数
      appIndex({
        indexCode: "ZB00137_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value9 = res.data.data.records[0]._value || 0;
        }
      });
      appIndex({
        indexCode: "ZB00138_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value10 = res.data.data.records[0]._value || 0;
        }
      });
    },
    // 数字千分化展示
    formatNumberWithCommas(number) {
      var parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
};
</script>
<style lang="scss" scoped>
.board {
  width: 100%;
  .chart {
    width: 100%;
    height: 100%;
  }
  .block-div {
    height: 300px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  }
  .flex-cloum-div {
    display: flex;
    height: 300px;
    flex-direction: column;
  }
  .flex-cloum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .name {
    margin-top: 20px;
    color: #15161a;
    font-size: 18px;
  }
  .title {
    padding: 20px 0 10px 20px;
    color: #15161a;
    font-size: 20px;
    font-weight: bold;
  }
  .num {
    color: #15161a;
    font-size: 42px;
    font-weight: bold;
  }
  .unit {
    color: #484a4d;
    font-size: 18px;
    margin-left: 8px;
    font-weight: 400;
  }
  .content {
    height: calc(100% - 60px);
  }
  .w-50 {
    width: calc(50% - 10px);
  }
  .w-100 {
    width: 100%;
  }
  .w-25 {
    width: 25%;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .h-50 {
    height: 50%;
  }
  .h-100 {
    height: 100%;
  }
  .m-r-20 {
    margin-right: 20px;
  }
  .m-l-20 {
    margin-left: 20px;
  }
  .h-200 {
    height: 200px;
  }
}
</style>
