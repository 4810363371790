var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.showDialog, width: "55%" },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "420px", "overflow-y": "auto" } },
            [
              _c(
                "avue-form",
                {
                  ref: "addForm",
                  attrs: { option: _vm.options },
                  on: { submit: _vm.treeNodeSave },
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                [
                  _c("template", { slot: "quantity" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "60%" },
                          attrs: {
                            placeholder: "请输数量",
                            maxlength: "10",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.form.quantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "quantity", $$v)
                            },
                            expression: "form.quantity",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: { width: "40%", "margin-left": "5px" },
                          attrs: { placeholder: "请输入单位" },
                          model: {
                            value: _vm.form.unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "unit", $$v)
                            },
                            expression: "form.unit",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "template",
                    { slot: "files" },
                    [
                      _c(
                        "el-upload",
                        {
                          class: {
                            uoloadSty: _vm.showImg,
                            disUoloadSty: _vm.noneUploadImg,
                          },
                          attrs: {
                            action:
                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                            headers: _vm.headers,
                            "on-preview": _vm.handlePreview,
                            "list-type": "picture-card",
                            "file-list": _vm.fileList,
                            file: "file",
                            "on-remove": _vm.handleDeleteImgRemove,
                            "on-success": _vm.handleIdFilesSuccess,
                            "on-change": _vm.uploadImgChange,
                            "before-upload": _vm.beforeAvatarUpload,
                            accept: ".jpeg,.jpg,.gif,.png",
                            limit: _vm.limitCountImg,
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus",
                            attrs: { slot: "default" },
                            slot: "default",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.closeDialog },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _vm.pageType != "view"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.dialogLoading,
                          expression: "dialogLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleTreeNodeSave },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogVisible, "append-to-body": "" },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("img", {
                attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.peopleShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.peopleShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            attrs: { "dept-category": [2, 5] },
            on: { "select-data": _vm.selectPeopleData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }