<template>
  <div class="evaluate">
    <el-tabs
      type="border-card"
      @tab-click="handleClick"
      v-model="activeName">
      <el-tab-pane label="待整改" name="noEvaluate"></el-tab-pane>
      <el-tab-pane label="已整改" name="evaluate"></el-tab-pane>
    </el-tabs>
      <head-layout head-title="文明规范事项清单" ></head-layout>
      <grid-head-layout v-if="showLayout" ref="gridHeadLayout" :search-span="3" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
      <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="detailView" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
        <template #hdLevel="{row}">
          <el-tag v-if="hdLevel[row.hdLevel]" :type="hdLevelIcon[row.hdLevel]"
                  effect="dark">{{hdLevel[row.hdLevel]}}
          </el-tag>
        </template>
        <template #customBtn="{row}">
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowView(row,'edit')" v-if="activeName == 'noEvaluate'">整改</el-button>
          <el-button style="margin: 0 3px" type="text" size="small" @click="rowView(row,'view')" v-if="activeName == 'evaluate'">查看</el-button>
        </template>
      </grid-layout>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import checklist from "@/views/hiddenTrouble/civilizedNorm/rectify/checklist";
import {mapGetters} from "vuex";
import * as  API from "@/api/civilizedNorm/evaluate";
import {getPage} from "@/api/civilizedNorm/rectify";
import riskView from "@/views/hiddenTrouble/civilizedNorm/riskView";

export default {
  mixins:[checklist,riskView],
  components: {
    GridLayout,
    HeadLayout
  },
  computed:{
    ...mapGetters([
      'userInfo',
    ]),
    searchColumns(){
      return [{
        label: '事项编号',
        prop: "cvCode",
        span: 3,
        placeholder: '请输入事项编号'
      },{
        label: '事项描述',
        prop: "cvDesc",
        span: 3,
        placeholder: '请输入事项描述'
      },
        {
          label: '地点',
          prop: "location",
          span: 3,
          placeholder: '请输入地点'
        },
        {
          label: '报告人',
          prop: "reportUserName",
          span: 2,
          placeholder: '请输入报告人'
        },
        {
          label: '责任部门',
          prop: "reportDeptName",
          span: 2,
          placeholder: '请输入责任部门'
        },

        {
          label: '状态',
          prop: "hdStatus",
          span: 2,
          type: 'select',
          placeholder: '请选择状态',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          label: '开始时间',
          prop: "startTime",
          type: 'date-format',
          span: 2,
          placeholder: '请选择开始时间'
        },
        {
          label: '结束时间',
          prop: "endTime",
          type: 'date-format',
          span: 2,
          placeholder: '请选择结束时间'
        },
      ]
    }
  },
  data() {
    return {
      showLayout:true,
      activeName:'noEvaluate',
      treeData: [],
      selectionList: [],
    }
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    handleClick(){
      this.showLayout=false
      this.$nextTick(()=>{
        this.showLayout=true
        this.onLoad(this.page);
      })
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 获取列表数据
    onLoad(page, params={}) {
      this.page = page;
      this.tableLoading = true;
      let url=this.activeName == 'noEvaluate' ? API.getList: getPage
      url({current:page.currentPage, size:page.pageSize,organizationId:this.userInfo.dept_id,currentUserId:this.userInfo.user_id, ...params,hdStatus:this.activeName == 'noEvaluate' ? 'unrectify' : params.hdStatus}).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      })
    },
    detailView(row){
      this.rowView(row,'view','hide')
    },
    rowView(row,type,hide){
      this.$router.push({
        path:this.activeName == 'noEvaluate' ? `/hiddenTrouble/civilizedNorm/rectify/edit`:'/hiddenTrouble/civilizedNorm/checklist/edit',
        query: {
          id: row.id,
          type:type || 'view',hide
        }
      })
    },

  }
}

</script>
<style lang="scss">
.evaluate{
  .el-tabs__content{
    display: none;
  }
  .avue-crud .el-table {
    height: calc(100vh - 314px) !important;
    max-height: calc(100vh - 314px) !important;
  }
}
</style>
