var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c(
      "div",
      [
        _c("head-layout", { attrs: { "head-title": "通行记录" } }),
        _c("grid-head-layout", {
          ref: "gridHeadLayout",
          attrs: { "search-columns": _vm.searchColumns },
          on: {
            "grid-head-search": _vm.gridHeadSearch,
            "grid-head-empty": _vm.gridHeadEmpty,
          },
        }),
        _c("grid-layout", {
          ref: "gridLayOut",
          attrs: {
            "table-options": _vm.tableOption,
            "table-data": _vm.tableData,
            "table-loading": _vm.tableLoading,
            "data-total": _vm.page.total,
            page: _vm.page,
          },
          on: {
            "page-current-change": _vm.onLoad,
            "page-size-change": _vm.onLoad,
            "page-refresh-change": _vm.onLoad,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }