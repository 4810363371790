<template>
  <div>
    <div class="head-search">
      <head-layout head-title="知识维护"></head-layout>
      <searchCommon @searchGrid="searchGrid" @searchClass="searchClass" @searchGridAll="searchGridAll" />
    </div>
    <div class="tag-content">
      <knowledge-classification v-if="showClassification" ref="knowledgeClass"
        @select-tag="selectTag"></knowledge-classification>
    </div>
    <grid-layout ref="gridLayOut" :table-options="tableOptions" :table-data="tableData" :table-loading="tableLoading"
      :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
      <template #customBtn="{ row }">
        <el-button v-if="isAdmin" type="text" size="medium" @click="rowEdit(row)">
          编辑
        </el-button>
        <el-button v-if="isAdmin" type="text" size="medium" @click="rowRemove(row)">
          删除
        </el-button>
        <el-button v-if="row.isPublish == '0'" type="text" size="medium" @click="publish(row, '1')">
          发布
        </el-button>
        <el-button v-if="row.isPublish == '1'" type="text" size="medium" @click="publish(row, '0')">
          取消发布
        </el-button>
      </template>
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import knowledgeClassification from "@/views/business/knowledge/components/knowledge-classification";
import {
  listAll,
  updateIsPublish,
  removeUpload,
} from "@/api/knowledgeClassifcation/knowledge";
import HeadLayout from "@/views/components/layout/head-layout";
import searchCommon from '@/views/business/knowledge/components/searchCommon.vue';
import {deleteProcess} from "@/api/plugin/workflow/ops";
export default {
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      fileName: "",
      fileTypeId: "",
      knowledgeType: "",
      showClassification: false,
      isAdmin: false,
    };
  },

  components: {
    knowledgeClassification,
    GridLayout,
    HeadLayout,
    searchCommon
  },

  computed: {
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        selection: false,
        menuWidth: this.isAdmin ? 160 : 100,
        column: [
          {
            label: "名称",
            prop: "fileName",
            align: 'left',
            overHidden: true,
          },
          {
            label: "上传时间",
            prop: "updateTime",
            width: 180,
            align: 'center',
            overHidden: true,
          },
          {
            label: "浏览次数",
            prop: "browseCount",
            align: 'right',
            width: 120,
            sortable: true,
            overHidden: true,
          },
          {
            label: "收藏次数",
            prop: "collectCount",
            align: 'right',
            width: 120,
            sortable: true,
            overHidden: true,
          },
          {
            label: "下载次数",
            prop: "downloadCount",
            align: 'right',
            width: 120,
            sortable: true,
            overHidden: true,
          },
          {
            label: "分享次数",
            prop: "shareCount",
            align: 'right',
            width: 120,
            sortable: true,
            overHidden: true,
          },
        ],
      };
    },
  },

  created() {
    this.checkAdmin();
  },
  mounted() {
    this.onLoad(this.page);
  },

  methods: {
    // 管理员维护数据
    checkAdmin() {
      if (this.$store.state.user.userInfo.role_name.includes("administrator")) {
        this.isAdmin = true;
      }
    },
    rowEdit(row) {
      this.$router.push({
        path: `/knowledge/knowledgeUpload`,
        query: {
          id: row.id,
          type: "admin",
        },
      });
    },
    rowRemove(row) {
      this.$confirm("警告：该知识被删除后，引用该知识的内容可能会报错！", {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        removeUpload(row.id).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.deleteProcess(row);
            this.onLoad(this.page);
          }
        });
      });
    },
    deleteProcess(row){
      if (row && row.processInstanceId === "") {
        this.$message.warning("该知识已被删除，但流程实例Id为空，对应流程删除失败")
        return
      }
      deleteProcess({ processInstanceId: row.processInstanceId, status: 'force' })
        .catch(() => {
          this.$message({
            type: "error",
            message: "对应流程删除失败",
          });
        })
    },

    searchGrid(val) {
      this.fileName = val;
      this.onLoad(this.page);
    },
    searchClass(val) {
      this.knowledgeType = val
      if (val) {
        this.showClassification = true;
        this.$nextTick(() => {
          this.$refs.knowledgeClass.showList = [];
          this.$refs.knowledgeClass.selectTags = [];
          this.selectTag(this.$refs.knowledgeClass.selectTags);
          this.$refs.knowledgeClass.getList(val);
        })
      }
    },
    searchGridAll(val){
      this.knowledgeType = null
      this.showClassification = false;
      this.fileName = val;
      this.onLoad(this.page);
    },
    onLoad(page) {
      this.page = page;
      this.tableLoading = true;
      listAll(page.currentPage, page.pageSize, this.fileName, this.fileTypeId, this.knowledgeType)
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = true;
        });
    },
    selectTag(list) {
      let fileTypeId = [];
      if (list.length > 0) {
        list.forEach((item) => {
          fileTypeId.push(item.id);
        });
      }
      this.fileTypeId = fileTypeId.toString();
      this.onLoad(this.page);
    },
    publish(row, type) {
      let params = {
        ids: row.id,
        isPublish: type,
      };
      updateIsPublish(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.onLoad(this.page);
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.head-search {
  position: relative;

  .el-select {
    position: absolute;
    left: 20%;
    top: 6px;
  }

  .el-input {
    width: 300px;
    position: absolute;
    left: 47%;
    top: 6px;
  }
}

.tag-content {
  padding: 5px 12px;
  background: #fff;
}

</style>
