var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showType === "pc",
              expression: "showType==='pc'",
            },
          ],
          attrs: { label: "是否手风琴" },
        },
        [
          _c("el-switch", {
            model: {
              value: _vm.activeData.accordion,
              callback: function ($$v) {
                _vm.$set(_vm.activeData, "accordion", $$v)
              },
              expression: "activeData.accordion",
            },
          }),
        ],
        1
      ),
      _c("el-divider", [_vm._v("面板配置")]),
      _c(
        "draggable",
        {
          attrs: {
            list: _vm.activeData.__config__.children,
            animation: 340,
            group: "selectItem",
            handle: ".option-drag",
          },
        },
        _vm._l(_vm.activeData.__config__.children, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "select-item" },
            [
              _c("div", { staticClass: "select-line-icon option-drag" }, [
                _c("i", { staticClass: "icon-ym icon-ym-darg" }),
              ]),
              _c("el-input", {
                attrs: { placeholder: "标签名称", size: "small" },
                model: {
                  value: item.title,
                  callback: function ($$v) {
                    _vm.$set(item, "title", $$v)
                  },
                  expression: "item.title",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "close-btn select-line-icon",
                  on: {
                    click: function ($event) {
                      return _vm.delItem(index, item)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-remove-outline" })]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticStyle: { "margin-left": "29px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "padding-bottom": "0" },
              attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
              on: { click: _vm.addItem },
            },
            [_vm._v("\n      添加面板\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }