<template>
    <el-dialog :title="title" :modal-append-to-body="false" :append-to-body="true" :close-on-click-modal="false" :visible.sync="showDialog" width="60%" @close="closeDialog">
      <avue-form :option="options" ref="addForm" v-model="form" @submit="submitBalck"></avue-form>
      <uploader @getTableData="getTableData" :fileSize="20" accept=".jpg,.png,.pdf,.gif,.jpeg,.ppt,.pptx,.doc,.docx,.xls,.xlsx" :uploadLimit="50" :multiple="true" :fileOptions="fileOptions" ref="uploader" :gridRowBtn="fileGridRowBtn"></uploader>

      <span slot="footer">
        <el-button size="small" @click="closeDialog">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="submitBlackDef" v-loading="dialogLoading">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
  </template>

  <script>
    import uploader from "@/components/file-upload/index";
    import {
        prjblacklistorgheadSubmit
    } from "@/api/block/organization";
    import {
        prjblacklistuserheadSubmit
    } from "@/api/block/personnel";
    export default {
      data() {
        return {
          showDialog: false,
          dialogLoading: false,
          form: {},
          title: '',
          dialogType:'',
          fileOptions:{
              linklabel:'name',
              column:[
                  {
                      label: '附件名',
                      prop: "name",
                      align: "left",
                      minWidth:200,
                      overHidden: true,
                  },
                  {
                      label: '附件大小',
                      prop: "fileSize",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: '附件格式',
                      prop: "extension",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: '上传人',
                      prop: "createUserName",
                      align: "center",
                      overHidden: true,
                  },
                  {
                      label: '上传时间',
                      prop: "createTime",
                      align: "center",
                      overHidden: true,
                  },
              ]
          },
          fileGridRowBtn:[
                {
                    label: '下载',
                    emit: "row-download",
                },
                {
                    label: '删除',
                    emit: "row-del",
                },
            ],
            isRemoveBlack: false
        }
      },
      components:{
        uploader
      },
      props:{
        callback:Function
      },
      computed: {
        options() {
          return {
            size: 'small',
            submitBtn: false,
            emptyBtn: false,
            labelWidth: 120,
            column: [{
                label: this.isRemoveBlack?'是否移除黑名单':'是否拉黑',
                prop: 'blState',
                type: 'switch',
                span:24,
                value: true
              },{
                label: "说明",
                type:'textarea',
                placeholder:'请填写',
                prop: "illustrate",
                span:24,
                maxlength: 225,
                showWordLimit: true,
                rules: [{
                  required: true,
                  message: "请输入说明",
                  trigger: "blur"
                }]
              }
            ],
          }
        }
      },
      methods: {
        init(type, row, isRemoveBlack) { // isRemoveBlack 弹框入口是否是移除黑名单操作
          this.form = {blState:true}
          this.showDialog = true
          this.dialogType=type
          console.log(row)
          if(isRemoveBlack){
            this.isRemoveBlack = true
            if (type == 'organization') {
              this.title = '移除黑名单组织'
            } else {
              this.title = '移除黑名单人员'
              // this.form.userId = row.userId
              this.form.phone = row.phone // 拉黑人员跟全息管理-黑名单人员拉黑都传phone
            }
          }else{
            this.isRemoveBlack = false
            if (type == 'organization') {
              this.title = '拉黑组织'
            } else {
              this.title = '拉黑人员'
              // this.form.userId = row.userId
              this.form.phone = row.phone // 拉黑人员跟全息管理-黑名单人员拉黑都传phone
            }
          }
          this.form.companyCode = row.companyCode
          this.form.prjCode = row.prjCode
        },
        // 上传附件
        //   组合附件数据
        resetFileData(data){
            return JSON.stringify(data)
        },
        //文件上传成功后的回调函数 新增
        getTableData(data){
            this.form.files = this.resetFileData(data)
        },
        // 拉黑提交
        submitBlackDef() {
            this.$refs.addForm.submit();
        },
        submitBalck() {
          if(this.isRemoveBlack){
            this.form.blState = this.form.blState == true ? 0 : 1
          }else{
            this.form.blState = this.form.blState == true ? 1 : 0
          }

          this.$refs.addForm.validate((valid,done) => {
            if (valid) {
                this.dialogLoading = true
                if(this.dialogType == 'organization'){
                    this.organizationSubmit(done)
                }else{
                    this.personnelSubmit(done)
                }
            }
          })
        },
        // 组织保存
        organizationSubmit(done){
            if(this.form.userId){
                delete this.form.userId
            }
            prjblacklistorgheadSubmit(this.form).then(res => {
                const {
                    msg,
                    data
                } = res.data;
                if (res.data.code == 200) {
                    this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                } else {
                    this.$message.success(msg);
                }
              this.callback && this.callback()
                this.form = {}
                this.$refs.addForm.resetFields();
                done()
                this.showDialog = false;
                this.dialogLoading = false
            }).catch(err => {

            }).finally(() => {
              this.form = {blState:true}
              this.$refs.addForm.resetFields();
              done()
              this.dialogLoading = false
            })
        },
        // 人员保存
        personnelSubmit(done){
            console.log('att',this.form)
            prjblacklistuserheadSubmit(this.form).then(res => {
                const {
                    msg,
                    data
                } = res.data;
                if (res.data.code == 200) {
                    this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                } else {
                    this.$message.success(msg);
                }
                this.callback && this.callback()
                this.form = {}
                this.$refs.addForm.resetFields();
                done()
                this.showDialog = false;
                this.dialogLoading = false
            }).catch(err => {

            }).finally(() => {
              this.form = {blState:true}
              this.$refs.addForm.resetFields();
              done()
              this.dialogLoading = false
            })
        },
        closeDialog(){
          this.form = {}
          this.$refs.uploader.initTaleData()
          this.$refs.addForm.resetFields();
          this.showDialog = false
      },
      }
    }

  </script>
