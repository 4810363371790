<template>
  <div style="position: relative">
    <el-container>
      <!-- <CommonTree ref="commonTreeCore" treeTitle="组织架构" :searchTitle="searchTitle" :treeData="treeData"
        :defaultProps="defaultProps" @getNodeClick="handleNodeClick" :isShowdig="false" :showCheckbox="false"
        node-key="id" :isZoom="true" :show="show" @showChange="showChange" /> -->
      <CommonTreeNew
        ref="commonTreeCore"
        treeTitle="组织架构"
        :defaultProps="defaultProps"
        :showCheckbox="false"
        @getNodeClick="handleNodeClick"
        :urlParmas="{
          tenantId: '',
          deptCategory: '2',
          parentId: userInfo.dept_id,
        }"
        node-key="id"
        :isShowdig="false"
        :isZoom="true"
        :show="show"
        @showChange="showChange"
      ></CommonTreeNew>
      <el-main>
        <head-layout
          head-title="行政处罚"
          :head-btn-options="headBtnOptions"
          @head-add="handleHeadAdd"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          :editType="inline"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @row-view="rowView"
          @row-edit="rowEdit"
          @row-del="rowRemove"
        >
          <template #documentName="{ row, index }">
            <el-link type="primary" @click="rowView(row)">{{
              row.documentName
            }}</el-link>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// 安全会议
import CommonTree from "@/views/components/com_tree/index";
// import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  grgovpunishPage,
  grgovpunishRemove,
} from "@/api/responsibility/administrativePenalty";
import { mapGetters } from "vuex";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import { getDeptTree } from "@/api/system/dept";

// import { getLazyDeptTree } from "@/api/system/dept";
import { getLazyTree } from "@/api/base/region";
import { fieldInfo } from "@/api/base/length";
export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    UserDetpDialog,
  },

  data() {
    return {
      nodeData: {},
      treeData: [],
      searchTitle: "title",
      defaultProps: {
        children: "children",
        label: "title",
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      organDic: [], // 处罚单位（接受单位）
      show: false,
    };
  },

  created() {
    // this.lazyDeptTree();
    // getLazyDeptTree({ categorys: "1,2,4" }).then((res) => {
    //     this.organDic = res.data.data;
    //   });
  },

  computed: {
    ...mapGetters(["permission", "colorName", "userInfo"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
      };
    },
    searchColumns() {
      return [
        {
          prop: "acceptOrg",
          placeholder: "处罚单位",
          span: 4,
          type: "tree",
          dicData: this.organDic,
          props: {
            label: "deptName",
            value: "id",
          },
        },
        {
          prop: "changeDate",
          placeholder: "整改时间",
          span: 4,
          type: "date-format",
        },
      ];
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: "add",
      });
      return buttonBtn;
    },

    gridRowBtn() {
      let buttonBtn = [];
      if (this.permissionList.viewBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-del",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    tableOptions() {
      return {
        menuFixed: "right",
        customRowAddBtn: false,
        menuWidth: 160,
        selection: false,
        linkLabel: "documentName",
        column: [
          {
            label: "文书名称",
            prop: "documentName",
            slot: true,
            align: fieldInfo.name_file.align,
            minWidth: fieldInfo.name_file.length,
            overHidden: true,
          },
          {
            label: "执法机构",
            prop: "executeGov",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true,
          },
          {
            label: "处罚地",
            prop: "punishAreaName",
            align: fieldInfo.name_regionAll.align,
            minWidth: fieldInfo.name_regionAll.length,
            overHidden: true,
          },
          {
            label: "境内外",
            prop: "prjDomestic",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary-tree?code=prj_domestic",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: fieldInfo.Words4.align,
            minWidth: fieldInfo.Words4.length,
            overHidden: true,
          },
          {
            label: "处罚金额",
            prop: "fine",
            formatter: (row, value, column) => {
              return new Intl.NumberFormat("en-US").format(value);
            },
            align: fieldInfo.amount.align,
            minWidth: fieldInfo.amount.length,
            overHidden: true,
          },
          {
            label: "下达日期",
            prop: "publishDate",
            align: fieldInfo.date.align,
            minWidth: fieldInfo.date.length,
            overHidden: true,
          },
          {
            label: "整改时间",
            prop: "changeDate",
            align: fieldInfo.date.align,
            minWidth: fieldInfo.date.length,
            overHidden: true,
          },
          {
            label: "整改进度",
            prop: "schedule",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary-tree?code=schedule",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: fieldInfo.Words4.align,
            minWidth: fieldInfo.Words4.length,
            overHidden: true,
          },
          {
            label: "登记时间",
            prop: "createTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },
        ],
      };
    },
  },

  methods: {
    showChange(v) {
      this.show = v;
    },
    /** 左侧列表操作 */
    //获取树数据
    // lazyDeptTree() {
    //   const parentId = this.userInfo.dept_id;
    //   getDeptTree('', '', parentId).then((res) => {
    //       if (res.data.code == 200) {
    //           this.treeData = res.data.data
    //           this.organDic = [res.data.data];
    //           if (res.data.data.length > 0) {
    //               this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(this.treeData[0].id)
    //               this.handleNodeClick(this.treeData[0])
    //           }
    //       }
    //   });
    // },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.page.currentPage = 1;
      this.query.belongOrgId = data.id;
      this.onLoad(this.page);
    },

    /**----end---- */
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      grgovpunishPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records.map((val) => {
          val.prjDomestic = val.prjDomestic.toString();
          val.schedule = val.schedule.toString();
          return val;
        });
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },

    rowEdit(row, index) {
      this.$router.push({
        path: "/business/responsibility/administrativePenalty/edit",
        query: {
          row: JSON.stringify({ id: row.id }),
          type: "edit",
        },
      });
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        grgovpunishRemove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    rowView(row) {
      this.$router.push({
        path: "/business/responsibility/administrativePenalty/view",
        query: {
          row: JSON.stringify({ id: row.id }),
          type: "view",
        },
      });
    },
    // 新增
    handleHeadAdd() {
      if (this.nodeData.id) {
        this.$router.push({
          path: "/business/responsibility/administrativePenalty/add",
          query: {
            row: JSON.stringify({ belongOrgId: this.nodeData.id }),
            type: "add",
          },
        });
      } else {
        this.$message.warning("请先选择左侧组织机构");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-name {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
</style>
