
<template>
    <el-dialog
      title="班前会及交底执行统计"
      :visible.sync="dialogShow"
      width="1200px"
      class="inspection-dialog"
      v-if="dialogShow"
    >
        <div class="inspection-dialog-main">
            <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="initData"
            @grid-head-empty="resetData"
            ></grid-head-layout>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="图示" name="first"></el-tab-pane>
                <el-tab-pane label="表示" name="second"></el-tab-pane>
            </el-tabs>
            <div class="chart-container" id="chart-container" v-show="activeName == 'first'">
                <p class="chart-title">{{dateType=='week'?(timeRange[0]+'至'+timeRange[1]):timeRange}}班前会一岗双责执行统计</p>
                <p class="child-title">参与班前会：{{resObj.sum}}次，<span class="red">未达标人数：{{resObj.unstandards}}人</span></p>
                <div class="chart-main" id="chart-main"></div>
            </div>
            <grid-layout
                v-if="activeName != 'first'"
                ref="gridLayout"
                :tableOptions="tableOptions"
                :tableData="tableData"
                :tableLoading="tableLoading"
                :data-total="page.total"
                :page="page"
                @page-size-change="initData"
                @page-current-change="initData"
                @page-refresh-change="initData"
            ></grid-layout>
        </div>
    </el-dialog>
</template>
<script>
import GridLayout from "@/views/components/layout/grid-layout";
import {beforeClassChart,beforeClassList} from '@/api/safetyAndEnvironmentalProtection/oneJob'
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {dateFormat} from '@/util/date'
import {mapGetters} from "vuex";
export default {
    components: {
        GridLayout
    },
    computed:{
        ...mapGetters(["userInfo"]),
    },
    props:{
        checkedProject:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            dialogShow:false,
            activeName:'first',
            timeRange:[],
            dateType:'week',
            resObj:{},
            tableData:[],
            numStandard:1,
            page: {
                pageSize: PAGE_CONSTANT.TWENTY,
                currentPage: 1,
                total: 0
            },
            tableLoading:false,
            tableOptions: {
                customRowAddBtn: false,
                menuWidth: 150,
                selection: false,
                indexLabel: "序号",
                index: true,
                menu:false,
                column: [{
                    label: '管理人员参与人',
                    prop: "userName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '参与次数',
                    prop: "sum",
                    align: 'center',
                    overHidden: true,
                }]
            },
            searchColumns:[{
                    placeholder: "请选择检查日期类型",
                    prop: "dateType",
                    type: "select",
                    dicData: [{
                        dictValue:'按星期',
                        dictKey:'week'
                    },{
                        dictValue:'按月份',
                        dictKey:'month'
                    }],
                    props: {
                        label: "dictValue",
                        value: "dictKey",
                    },
                    span: 5,
                    change:(val)=>{
                        if(val.value == 'week'){
                            this.searchColumns.splice(1,1,{
                                placeholder: "请选择检查日期",
                                prop: "timeRange",
                                type: "daterange",
                                dicData: [],
                                span: 5,
                                pickerOptions:{
                                    disabledDate(time) {
                                        const weekday = time.getDay() || 7
                                        return weekday != 1&&weekday != 7;
                                    }
                                },
                            })
                            this.numStandard = 1
                            this.$refs.gridHeadLayout.searchForm.timeRange = this.getLastDate();
                        }else{
                            this.searchColumns.splice(1,1,{
                                placeholder: "请选择检查日期",
                                prop: "time",
                                type: "month",
                                dicData: [],
                                span: 5,
                            })
                            this.numStandard = 1
                            this.$refs.gridHeadLayout.searchForm.time = dateFormat(new Date(),'yyyy-MM');
                        }
                        this.$nextTick(()=>{
                            this.$refs.gridHeadLayout?.initSearch();
                        })
                    }
                },{
                    placeholder: "请选择检查日期",
                    prop: "time",
                    type: "daterange",
                    pickerOptions:{
                        disabledDate(time) {
                            const weekday = time.getDay() || 7
                            return weekday != 1;
                        }
                    },
                    dicData: [],
                    span: 5,
                }]
        }
    },
    methods:{
        init(){
            this.dialogShow = true
            this.$nextTick(()=>{
                this.activeName = 'first'
                this.$set(this.$refs.gridHeadLayout.searchForm,'dateType','week')
                this.$set(this.$refs.gridHeadLayout.searchForm,'timeRange' , this.getLastDate())
                setTimeout(()=>{
                    this.getChart()
                })
            })
        },
        initData(page){
            this.page.pageSize = page.pageSize
            this.page.currentPage = page.currentPage
            this.getChart()
            this.getChartTable()
        },
        resetData(){
            this.page.pageSize = PAGE_CONSTANT.TWENTY
            this.page.currentPage = 1
            this.page.total = 0
            this.$set(this.$refs.gridHeadLayout.searchForm,'dateType','week')
            this.$set(this.$refs.gridHeadLayout.searchForm,'timeRange' , this.getLastDate())
            this.getChart()
            this.getChartTable()
        },
        handleClick(val){
            if(val._props.name != 'first'){
                this.getChartTable()
            }else{
                this.getChart()
            }
        },
        getLastDate(){
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * ((start.getDay()||7)-1));
            end.setTime(end.getTime() + 3600 * 1000 * 24 * (7-(end.getDay()||7)));
            return [dateFormat(start,'yyyy-MM-dd'), dateFormat(end,'yyyy-MM-dd')]
        },
        getChart(){
            let {time,timeRange,dateType} = this.$refs.gridHeadLayout.searchForm
            this.timeRange = dateType == 'week'?timeRange:dateFormat(new Date(time),'yyyy-MM')
            this.dateType = dateType
            beforeClassChart({orgId:this.checkedProject,...dateType == 'week'?{startDate:timeRange[0],endDate:timeRange[1]}:{yearAndMonth:this.timeRange}}).then(res=>{
                let data = res.data.data
                var myChart = this.$echarts.init(
                    document.getElementById("chart-main")
                );
                this.resObj = data
                var option = {
                    grid: {
                        top: '20px',
                        left: '30px',
                        bottom:'20px',
                        right:0
                    },
                    color:['rgb(255,0,0)'],
                    xAxis: {
                        type: 'category',
                        data: data.beforeClassUserVOList.map(item=>{return item.userName}),
                        axisLine: {
                            lineStyle: {
                            color: ['#E8E8E8']
                            }
                        },
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        minInterval:1,
                        // min:3,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                    },
                    tooltip: {
                        show:true,
                        formatter:'{b}<br/>{a}: {c}',
                        trigger:'axis'
                    },
                    series:[{
                        name:'班前会管理',
                        data: data.beforeClassUserVOList.map(item=>{return item.sum}),
                        type: 'bar',
                        barWidth: 25,
                        markLine:{
                            silent:true,
                            label:{
                                show:true,
                                position:'insideEndTop',
                                formatter(){
                                    return '达标值：'+this.numStandard+'次'
                                }
                            },
                            data:[{
                                yAxis:1
                            }]
                        }
                    }]
                };
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        getChartTable(){
            let {time,timeRange,dateType} = this.$refs.gridHeadLayout.searchForm
            this.timeRange = dateType == 'week'?timeRange:dateFormat(new Date(time),'yyyy-MM')
            this.dateType = dateType
            this.tableLoading = true
            beforeClassList({orgId:this.checkedProject,...dateType == 'week'?{startDate:timeRange[0],
            endDate:timeRange[1]}:{yearAndMonth:this.timeRange},current:this.page.currentPage,size:this.page.pageSize}).then(res=>{
                let data = res.data.data
                this.tableData = data.records
                this.page.pageSize = data.size
                this.page.currentPage = data.current
                this.page.total = data.total
                this.tableLoading = false
            })
        }
    },
}
</script>
<style scoped lang="scss">
    p{
        margin: 0;
        padding: 0;
    }
    .inspection-dialog{
        .inspection-dialog-main{
            padding: 10px;
            height: 500px;
            .el-tabs{
                margin: 20px 0 10px 0;
            }
        }
    }
    .chart-container{
        text-align: center;
        p{
            margin-bottom: 10px;
        }
        .chart-main{
            height: 300px;
            margin-top: 10px;
        }
    }
    ::v-deep #gridHeadLayout > div{
        padding: 0!important;
    }
     ::v-deep .avue-crud .el-table{
        height: 320px!important;
        max-height: 320px!important;
     }
    .red{
        color: rgb(255,0,0);
    }
</style>

