var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "表单默认值",
        },
        on: {
          "head-add": function ($event) {
            return _vm.dynamicRoute(
              { processDefKey: _vm.processDefKey },
              "start"
            )
          },
          "head-delete": _vm.handleDelete,
        },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.option,
          "table-data": _vm.data,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-view": _vm.handleDetail,
          "row-flow": _vm.handleFlow,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.detailVisible,
            title: _vm.form.processDefinitionName,
            "custom-class": "wf-drawer",
            size: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailVisible = $event
            },
          },
        },
        [
          _vm.detailVisible
            ? _c("task-detail", {
                attrs: {
                  taskId: _vm.form.taskId,
                  processInstanceId: _vm.form.processInsId,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.bpmnVisible,
            "append-to-body": "",
            "destroy-on-close": "",
            title: "流程图",
            width: "70%",
            "custom-class": "wf-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.bpmnVisible = $event
            },
          },
        },
        [
          _c("wf-design", {
            ref: "bpmn",
            staticStyle: { height: "60vh" },
            attrs: { options: _vm.bpmnOption },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }