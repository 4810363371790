<template>
  <div>
    <el-card>
      <div>

        <gg></gg>
      </div>

    </el-card>

</div>
</template>

<script>

import gg from "./src/components/G6Editor/index.vue"
import HeadLayout from "@/views/components/layout/head-layout"
import {
  TaskGl,
} from "@/api/dataAcquisition";
export default {
  name: "projectList",
  data() {
    return {
     
      dialog1: false,
      dialog1Title: "新建项目",
      dialog1Title2:"",
      dialog2: false,
      dialog3: false,
      DisName: false,
      Cg6: {},
      activeName: "first",
      ruleForm: {
        name: "",
        zh_name: "",
        content: "",
      },
      Seedata: {
        resFetchexecflow: {
          nodes: {}
        },
        resLog: {
          data: ""
        }
      },
      showCard: 1,
     
    }
  },
  components: {
    gg,HeadLayout,
  },
  mounted() {
    this.dialog1Title2 = this.$t('cip.dc.projectlist.seetabs.editlabel.NewProject')
   

   let id =  this.$route.query.id
   
    TaskGl({id:id}).then(res=>{
      this.$eventBus.$emit("eventNameq2", res);
    })
  },
  methods: {

   
  
    
   

   
   

   
    
   
   


  }
}
</script>

<style scoped>
 /deep/.el-card__body {
  padding: 0px !important;
}
.box-card {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: rgb(244, 245, 246);
}

.listBox {
  /* height: 70vh; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  /* margin-top: 20px; */
  /* border: 1px solid black; */
}

.box1 {
  width: 30%;
  margin: 15px;
  display: flex;
}

.textbt {
  font-size: 16px;
  color: blue;
  cursor: pointer;
}
</style>
