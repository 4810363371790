<template>
  <div>
    <el-container>
      <div v-if="userDeptType === 'all'" class="UserDeptTree">
        <tree-layout
          treeTitle="组织架构"
          :treeLoading="treeLoading"
          :tree-options="treeOptions"
          @tree-node-click="getNodeClick"
          @include-down="includeDownAll"
          style="margin-right: 5px"
        >
        </tree-layout>
      </div>
      <div v-else>
        <CommonTreeNew
          ref="commonTree"
          class="UserDeptTree"
          :defaultProps="defaultProps"
          :searchTitle="searchTitle"
          :treeTitle="$t(`cip.plat.sys.user.title.treeTitle`)"
          :isShowdig="false"
          :showCheckbox="false"
          :popover-show="false"
          @getNodeClick="getNodeClick"
          @include-down="includeDown"
          :urlParmas="{
            tenantId: '',
            parentId: userInfo.company_id,
            ...treeParams,
          }"
        ></CommonTreeNew>
      </div>
      <el-container>
        <el-main>
          <head-layout
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
          >
          </head-layout>
          <grid-head-layout
            ref="searchFrom"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :search-columns="searchColumns"
            v-model="searchForm"
            @grid-head-search="searchChange"
            @grid-head-empty="searchReset"
          >
          </grid-head-layout>
          <grid-layout
            ref="gridLayout"
            class="UserDeptCrud"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :data-total="page.total"
            @page-size-change="onLoad"
            @page-current-change="onLoad"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="selectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :searchColumns="searchColumns"
            :gridRowBtn="gridRowBtn"
            @role-defBtn="roleDefBtn"
          >
          </grid-layout>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import { getTableList } from "@/api/system/user";
import {
  selectChildren,
  getDeptLazyTreeAll,
  getDeptTree,
} from "@/api/system/dept";
import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index";
import { mapGetters } from "vuex";
import {prjthirduserPage} from "@/api/safe/itemList";

export default {
  name: "user",
  components: {
    TreeLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
  },
  props: {
    deptCategory: [],
    userDeptType: {
      type: String,
      default: "",
    },
    publicParams: {
      type: Object,
      default: {},
    },
    treeParams: {
      type: Object,
      default: {},
    },

  },
  data() {
    return {
      treeLoading: true,
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "key",
      },
      searchForm: {},
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.defBtn"),
          emit: "role-defBtn",
          type: "text",
          icon: "",
        },
      ],
      tableData: [],
      tableLoading: true,
      search: {},
      selectionList: [],
      query: {},
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      props: {
        label: "title",
        value: "key",
      },
      treeDeptId: "",
      treeData: [],
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          label: "",
          prop: "account",
          span: 4,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.account"),
        },
        {
          label: "",
          prop: "userName",
          span: 4,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.realName"),
        },
        {
          label: "用户类型",
          prop: "personnelType",
          type: "select",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicData: [],
          value: "1",
          multiple: true,
          dataType: "string",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type",
          span: 4,
          placeholder: "请选择用户类型",
        },{
          label: "",
          placeholder: "请选择岗位",
          prop: "stPostId",
          type: "tree",
          dicUrl:`/api/sinoma-hse-prj/sinoma-hse-prj/stpost/typeTree?organizationId=${this.userInfo.deptId}`,
          dicFormatter: (res) => {
            return this.setDisabled(res)
          },
          props: {
            label: 'postName',
            value: 'id',
          },
        },
      ];
    },
    treeOptions() {
      let that = this;
      return {
        defaultExpandAll: false,
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          that.treeLoading = true;
          const parentId = node.level === 0 ? 0 : node.data.id;
          getDeptLazyTreeAll(parentId).then((res) => {
            resolve(
              res.data.data.map((item) => {
                that.treeLoading = false;
                return {
                  ...item,
                  leaf: !item.hasChildren,
                };
              })
            );
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: "title",
          value: "value",
          children: "children",
        },
      };
    },
    tableOptions() {
      return {
        customRowAddBtn: false,
        menu: true,
        selection: false,
        column: [
          {
            label: "用户账号",
            prop: "account",
            align: "left",
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "userName",
            width: 150,
            align: "center",
            overHidden: true,
          },
          {
            label: '所属组织',
            prop: "deptName",
            align: "left",
            overHidden: true,
          },
          {
            label: "岗位",
            placeholder: "请选择岗位",
            prop: "stPostId",
            type: "tree",
            dicUrl:`/api/sinoma-hse-prj/sinoma-hse-prj/stpost/typeTree?organizationId=${this.userInfo.deptId}`,
            props: {
              label: 'postName',
              value: 'id',
            },
          },
        ]
      }
    },
    ...mapGetters(["userInfo"]),
  },
  mounted() {

  },
  methods: {
    setDisabled(tree){
      tree.forEach(item => {
        if (item.children) {
          this.setDisabled(item.children)
        }
        item.disabled=item.postType == 2 ? false : true;
      })
      return tree
    },
    roleDefBtn(row) {
      this.$emit("select-data", row, this.treeDeptId, this.treeDeptName);
    },

    getNodeClick(data) {
      this.treeDeptId = data.id;
      this.treeDeptName = data.title;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },

    includeDownAll(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },

    searchReset(data) {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (params.account == "") {
        delete params.account;
      }
      if (params.realName == "") {
        delete params.realName;
      }

      if (params.userTypeName == "") {
        delete params.userTypeName;
      }
      delete params.$userTypeName;
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.param = params;
      this.page = page;
      this.tableLoading = true;
      let query={
        current: page.currentPage,
        size: page.pageSize,
        deptId: this.treeDeptId,
        ...params,...this.publicParams, ...this.query,
      }
      prjthirduserPage(query).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        // this.selectionClear();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}

::v-deep .UserDeptTree .el-tree {
  //height: calc(100vh - 376px) !important;
  height: 60vh !important;
  max-height: 60vh !important;
}

::v-deep .UserDeptCrud .avue-crud .el-table {
  // height: calc(100vh - 376px) !important;
  // max-height: calc(100vh - 376px) !important;
  height: 55vh !important;
  max-height: 55vh !important;
}

::v-deep .el-dialog {
  margin-bottom: 0px !important;
}
</style>
