var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-slider", {
    style: _vm.width,
    attrs: { disabled: "", max: _vm.maxNumber },
    nativeOn: {
      mousemove: function ($event) {
        return _vm.updateTooltipPosition.apply(null, arguments)
      },
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }