var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTreeNew", {
            ref: "commonTree",
            attrs: {
              treeTitle: "项目架构",
              defaultProps: _vm.defaultProps,
              showCheckbox: false,
              urlParmas: {
                tenantId: "",
                deptCategory: "2,5",
                parentId: _vm.userInfo.deptId,
              },
              "node-key": "id",
            },
            on: {
              getNodeClick: _vm.treeNodeClick,
              getTreeData: _vm.commonTreeData,
            },
          }),
          _c(
            "el-main",
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "border-card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "隐患", name: "hiddenDanger" } },
                    [_c("hiddenDanger", { ref: "hiddenDanger" })],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "风险", name: "risk" } },
                    [_c("risk", { ref: "risk" })],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "事故", name: "accident" } },
                    [_c("accident", { ref: "accident" })],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "应急", name: "emergency" } },
                    [_c("emergency", { ref: "emergency" })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }