<template>
  <el-dialog
    title="课程选择"
    :visible.sync="visible"
    height="600px"
    width="80%"
  >
    <div>
      <el-container>
        <CommonTree
          style="flex-shrink: 0"
          :treeData="treeData"
          :defaultProps="defaultProps"
          :searchTitle="searchTitle"
          :isShowdig="false"
          :showCheckbox="false"
          :popover-show="false"
          :expandOnClickNode="false"
          treeTitle="培训类型"
          @getNodeClick="treeNodeClick"
        />
        <el-tabs
          v-model="activeName"
          type="border-card"
          @tab-click="handleClick"
          style="width: calc(100% - 280px); height: 100%"
        >
          <el-tab-pane label="平台课程" name="platformCourses">
            <platform-courses
              ref="platformCourses"
              @getSelectRow="getSelectRow"
              :initTree="initTree"
            ></platform-courses>
          </el-tab-pane>
          <el-tab-pane label="自定义课程" name="customCourses">
            <custom-courses
              ref="customCourses"
              :initTree="initTree"
              @getSelectRow="getSelectRow"
            ></custom-courses>
          </el-tab-pane>
          <el-tab-pane label="我的收藏" name="myCollection">
            <myCollection
              ref="myCollection"
              :initTree="initTree"
              @getSelectRow="getSelectRow"
            ></myCollection>
          </el-tab-pane>
        </el-tabs>
      </el-container>
    </div>
  </el-dialog>
</template>
<script>
import {
  getSafetyTrainingTree,
  removeSafetyTrainingTree,
  submitSafetyTrainingTree,
} from "@/api/knowledgeClassifcation/knowledge";
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import ServerNameEnum from "@/util/ServerNameEnum";
import customCourses from "@/views/business/safetyTtandard/occupationConfig/customCourses.vue";
import myCollection from "@/views/business/safetyTtandard/occupationConfig/myCollection.vue";
import platformCourses from "@/views/business/safetyTtandard/occupationConfig/platformCourses.vue";
export default {
  data() {
    return {
      form: {},
      query: {},
      selectRow: {},
      visible: false,
      name: "platformCourses",
      treeReadOnlyFlag: false,
      tableData: [],
      treeLoading: false,
      crudLoading: false,
      treeData: [],
      dicData: [],
      selectionList: [],
      node: {}, //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      classifyId: "",
      searchTitle: "title",
      activeName: "platformCourses",
      trainingCode: "",
      defaultProps: {
        label: "title",
        value: "vale",
        key: "key",
      },
      showDialog: false,
      formType: "",
      formList: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
    };
  },
  computed: {
    treeDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "培训名称",
            prop: "trainingName",
            placeholder: "请输入培训名称",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入培训名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "培训编码",
            prop: "trainingCode",
            maxlength: 20,
            placeholder: "请输入培训编码",
            rules: [
              {
                required: true,
                message: "请输入培训编码",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "上级类型",
            prop: "parentId",
            type: "tree",
            props: {
              label: "title",
              value: "value",
              key: "key",
            },
            placeholder: "上级类型",
            dicUrl: `${ServerNameEnum.SERVER_KNOWLEDGE}/SafetyTrainingTree/tree`,
          },
          {
            label: "描述",
            prop: "remark",
            maxlength: 20,
            placeholder: "请输入描述",
          },
        ],
      };
    },
    dialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "cateLineName",
            placeholder: "请输入分类名称",
            disabled: !["edit", "add"].includes(this.formType),
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "分类编号",
            prop: "cateLineCode",
            placeholder: "请输入分类编号",
            maxlength: 20,
            disabled: !["edit", "add"].includes(this.formType),
            rules: [
              {
                required: true,
                message: "请输入分类编号",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "描述",
            prop: "remark",
            type: "textarea",
            rows: 3,
            span: 24,
            placeholder: "请输入描述",
            maxlength: 200,
            showWordLimit: true,
            disabled: !["edit", "add"].includes(this.formType),
            rules: [
              {
                required: false,
                message: "请输入描述",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
        ],
      };
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    platformCourses,
    customCourses,
    myCollection,
  },
  created() {
    this.initTree();
  },
  mounted() {},
  methods: {
    init() {
      this.initTree();
      this.visible = true;
    },
    getSelectRow(row) {
      this.selectRow = row;
      console.log(row, "training");
      this.$emit("getSelectRow", row);
      this.close();
    },
    close() {
      this.visible = false;
    },
    handleClick(tab, event) {
      this.name = tab._props.name;
      if (tab._props.name == "platformCourses") {
        this.$refs.platformCourses.query.trainContentCode = this.trainingCode;
        this.$refs.platformCourses.searchReset();
        // this.$refs.platformCourses.onLoad(this.$refs.platformCourses.page,{});
      } else if (tab._props.name == "customCourses") {
        this.$refs.customCourses.query.trainContentCode = this.trainingCode;
        this.$refs.customCourses.searchReset();
        // this.$refs.customCourses.onLoad(this.$refs.customCourses.page,{});
      } else {
        this.$refs.myCollection.query.trainContentCode = this.trainingCode;
        this.$refs.myCollection.searchReset();
      }
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    // 左侧树新增
    treeMenuAdd() {
      // this.form.parentId = data.id;
      this.form = {};
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },

    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },
    initTree() {
      console.log("123");
      getSafetyTrainingTree()
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    // 新增左侧树节点
    treeNodeSave(data, done) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$loading();
          if (this.form.parentId == "") {
            this.form.parentId = 0;
          }
          submitSafetyTrainingTree(this.form)
            .then((res) => {
              const { msg, data } = res.data;
              this.treeReadOnlyFlag = true;
              this.$message.success(msg);
              this.initTree();
              this.showTreeDialog = false;
            })
            .finally(() => {
              this.$loading().close();
            })
            .catch((err) => {
              done();
            });
        }
      });
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren && data.children.length != 0) {
        this.$message({
          type: "warning",
          message: "当前分类下有子级，禁止删除",
        });
        return;
      }
      this.$confirm("确认删除数据", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeSafetyTrainingTree(data.id).then((res) => {
            if (res.data.data) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.initTree();
            } else {
              this.$message.warning(
                "删除失败，当前知识库分类下绑定有维度分类！"
              );
            }
          });
        })
        .catch(() => {});
    },

    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      this.classifyId = this.node.id;
      this.trainingCode = this.node.trainingCode;
      console.log("输出--------", this.name);
      this.$nextTick(() => {
        if (this.name == "platformCourses") {
          this.$refs.platformCourses.query.trainContentId = node.id;
          this.$refs.platformCourses.onLoad(
            this.$refs.platformCourses.page,
            {}
          );
        } else if (this.name == "customCourses") {
          this.$refs.customCourses.query.trainContentId = node.id;
          this.$refs.customCourses.onLoad(this.$refs.customCourses.page, {});
        } else {
          this.$refs.myCollection.query.trainContentId = node.id;
          this.$refs.myCollection.onLoad(this.$refs.myCollection.page, {});
        }
      });
    },
  },
};
</script>
