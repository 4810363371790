var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "班前会",
                  showIcon: false,
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-add": _vm.headAdd, "head-romve": _vm.headRomve },
                scopedSlots: _vm._u([
                  {
                    key: "left",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              "align-items": "center",
                              height: "28px",
                              "margin-right": "5px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("span", {
                                  staticStyle: {
                                    background: "#ffce7b",
                                    border: "1px solid",
                                    width: "22px",
                                    height: "14px",
                                    "margin-right": "3px",
                                  },
                                }),
                                _c("span", [_vm._v("：数据已失效,请及时删除")]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOption,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "cell-style": _vm.cellStyle,
                },
                on: {
                  "grid-row-detail-click": _vm.detailView,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.rowView(row, "view")
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _vm.showHide(row, "edit")
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowView(row, "edit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm.showHide(row, "delete")
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowRemove(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }