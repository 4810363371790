var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            ref: "commonTreeCore",
            attrs: {
              treeTitle: "组织架构",
              searchTitle: _vm.searchTitle,
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              isShowdig: false,
              showCheckbox: false,
              "node-key": "id",
              isZoom: true,
              show: _vm.show,
            },
            on: {
              getNodeClick: _vm.handleNodeClick,
              showChange: _vm.showChange,
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "安全生产标准化企业申报",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-add": _vm.handleHeadAdd },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "row-edit": _vm.rowEdit,
                  "row-del": _vm.rowRemove,
                },
                scopedSlots: _vm._u([
                  {
                    key: "meetingName",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.rowView(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.meetingName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.type == "add"
                ? "新增申报"
                : _vm.type == "view"
                ? "查看申报详情"
                : "编辑申报",
            "close-on-click-modal": false,
            visible: _vm.visible,
            "lock-scroll": "",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.saveLoading,
                  expression: "saveLoading",
                },
              ],
              staticClass: "main-content",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "100px",
                    rules: _vm.rules,
                    disabled: _vm.type == "view",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业名称", prop: "orgName" } },
                            [_c("div", [_vm._v(_vm._s(_vm.form.orgName))])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "企业类型", prop: "orgTypeName" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择企业类型",
                                    "value-key": "dictKey",
                                  },
                                  on: { change: _vm.orgTypeChange },
                                  model: {
                                    value: _vm.orgType,
                                    callback: function ($$v) {
                                      _vm.orgType = $$v
                                    },
                                    expression: "orgType",
                                  },
                                },
                                _vm._l(_vm.orgTypes, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "级别", prop: "safetyLevelName" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择级别",
                                    "value-key": "dictKey",
                                  },
                                  on: { change: _vm.levelChange },
                                  model: {
                                    value: _vm.level,
                                    callback: function ($$v) {
                                      _vm.level = $$v
                                    },
                                    expression: "level",
                                  },
                                },
                                _vm._l(_vm.levels, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "申请年度", prop: "safetyDate" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy",
                                  type: "year",
                                  placeholder: "选择申请年度",
                                },
                                model: {
                                  value: _vm.form.safetyDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "safetyDate", $$v)
                                  },
                                  expression: "form.safetyDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入备注",
                                  maxlength: "255",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.saveLoading,
                      expression: "saveLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
              _vm.type != "view"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.saveLoading,
                          expression: "saveLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("\n        保存\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }