var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common_tree_handle" },
    [
      _c(
        "el-input",
        {
          attrs: {
            size: "small",
            placeholder: _vm.$t("cip.cmn.msg.warning.filterateWarning"),
          },
          model: {
            value: _vm.filterText,
            callback: function ($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText",
          },
        },
        [
          _vm.isShowdig
            ? _c(
                "template",
                { slot: "append" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: { placement: "top", width: "80" },
                      model: {
                        value: _vm.visible,
                        callback: function ($$v) {
                          _vm.visible = $$v
                        },
                        expression: "visible",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "popv_p",
                          on: {
                            click: function ($event) {
                              return _vm.addNode()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("cip.cmn.btn.addBtn")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "popv_p",
                          on: {
                            click: function ($event) {
                              return _vm.editNode()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("cip.cmn.btn.editBtn")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "popv_p",
                          on: {
                            click: function ($event) {
                              return _vm.deleteNode()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("cip.cmn.btn.delBtn")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [_c("i", { staticClass: "el-icon-arrow-down" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("el-tree", {
        ref: "commonTree",
        attrs: {
          data: _vm.treeChangeData,
          props: _vm.defaultProps,
          "default-expand-all": "",
          "show-checkbox": _vm.showCheckbox,
          "filter-node-method": _vm.filterNode,
          "expand-on-click-node": _vm.expandOnClickNode,
        },
        on: {
          "node-click": _vm.nodeClick,
          "check-change": _vm.handleCheckChange,
        },
      }),
      _vm.showTreeDialog
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              attrs: {
                title: _vm.dialogTitle,
                "close-on-click-modal": false,
                "modal-append-to-body": false,
                visible: _vm.showTreeDialog,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showTreeDialog = $event
                },
              },
            },
            [
              _c("avue-form", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "treeDialog",
                attrs: { option: _vm.treeFormOption },
                model: {
                  value: _vm.treeForm,
                  callback: function ($$v) {
                    _vm.treeForm = $$v
                  },
                  expression: "treeForm",
                },
              }),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.showTreeDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.treeNodeSave },
                    },
                    [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.saveBtn")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }