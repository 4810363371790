import * as echarts from 'echarts';
import riskView from "@/views/hiddenTrouble/riskItemView/riskView";
import {mapGetters} from "vuex";
import {fieldInfo} from "@/api/base/length"
export default {
  mixins:[riskView],
  computed:{
    ...mapGetters([
      'userInfo',
    ]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  data(){
    return {
      tableOption: {
        selectable:(row)=>{
          return row.hdStatus == 'prepare' && this.userInfo.user_id == row.createUser
        },
        linklabel: "cvCode",
        column: [{
          label: "事项编号",
          prop: "cvCode",
          align: "left",
          overHidden: true,
          width: 150
        },
          {
            label: "描述",
            prop: "cvDesc",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "地点",
            prop: "location",
            align: fieldInfo.fullAddress.align,
            minWidth: fieldInfo.fullAddress.length,
            overHidden: true
          },
          {
            label: "报告人",
            prop: "reportUserName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true
          },
          {
            label: "报告部门",
            prop: "reportDeptName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true
          },
          {
            label: "责任部门",
            prop: "respDeptName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true
          },
          {
            label: "状态",
            prop: "hdStatus",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: fieldInfo.Words4.align,
            minWidth: fieldInfo.Words4.length,
            overHidden: true
          },
          {
            label: "整改期限",
            prop: "rectifyLimitDate",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      isCycle: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [{
        label: "新增",
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: 'primary',
      },
          {
              label: this.$t("cip.cmn.btn.delBtn"),
              emit: "head-romve",
              type: "button",
              icon: ""
          }
      ],
      searchColumns: [{
        label: '事项编号',
        prop: "cvCode",
        span: 3,
        placeholder: '请输入事项编号'
      },{
        label: '事项描述',
        prop: "cvDesc",
        span: 3,
        placeholder: '请输入事项描述'
      },
        {
          label: '地点',
          prop: "location",
          span: 3,
          placeholder: '请输入地点'
        },
        {
          label: '报告人',
          prop: "reportUserName",
          span: 2,
          placeholder: '请输入报告人'
        },
        {
          label: '责任部门',
          prop: "respDeptName",
          span: 2,
          placeholder: '请输入责任部门'
        },

       {
          label: '状态',
          prop: "hdStatus",
          span: 2,
          type: 'select',
          placeholder: '请选择状态',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          label: '开始时间',
          prop: "startTime",
          type: 'date-format',
          span: 2,
          placeholder: '请选择开始时间'
        },
        {
          label: '结束时间',
          prop: "endTime",
          type: 'date-format',
          span: 2,
          placeholder: '请选择结束时间'
        },
      ],
    }
  },
  mounted() {
    this.myPie = echarts.init(this.$refs.pie);
    this.myBar = echarts.init(this.$refs.bar);

  },
  methods:{
    initLine(myChart,data){
      let seriesData=[]
        seriesData.push({
          name: '',
          type: "line",
          symbolSize: 8,
          data: data.countList,
        })

      let option = {
        tooltip: {
          show: true,
          trigger: "axis",
        },
        legend: {
          show: true,
          x: "center",
          y: "35",
          icon:'circle',
          data: this.hdLevelText,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel:{
              rotate:45,
            },
            data:data.monthList,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              formatter: "{value}",
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#11366e",
              },
            },
          },
        ],
        series: seriesData
      };
      option && myChart.setOption(option);
    },
    sameReduce(arr) {
      let result = arr.reduce((acc, cur) => {
        let found = acc.find((item) => {
          return item.deptId === cur.deptId
        })
        if (found) {
          found.value += cur.countNumber
        } else {
          acc.push({ deptId: cur.deptId, value: cur.countNumber,name: cur.deptName, })
        }
        return acc
      }, [])
      return result
    },
    initPie(myChart,data){
      let that=this;
      let seriesData=[]
      let seriesData1=[]
      let newData=[]
      seriesDataFun(data)
      function seriesDataFun(){

        seriesData1=data.map(item=>{
            return  item.deptName
        })
        newData=data.map(item=>{
            return   { value: item.countNumber,groupId:item.hdLevel, name: that.hdLevel[item.hdLevel] }
        })
      };
      seriesData=this.sameReduce(data)
      console.log(newData)
      let option = {
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          show: true,
          x: "center",
          y: "15",
          data: seriesData1
        },
        series: [
          {
            name: '',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],
            label: {
              show: false,
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: seriesData
          },
          // {
          //   zlevel:1111,
          //   name: '隐患类型',
          //   type: 'pie',
          //   radius: ['45%', '60%'],
          //   labelLine: {
          //     length: 30
          //   },
          //   itemStyle:{
          //     color:function (colors) {
          //       return that.hdLevelColor[colors.data.groupId]
          //     },
          //   },
          //   label: {
          //     show:false,
          //   },
          //   data:newData
          // }
        ]
      };
      option && myChart.setOption(option);
    },
  },
}
