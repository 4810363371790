export default [
  {
    label: '发起流程',
    value: 1
  },
  {
    label: '审核中', // 审核通过
    value: 2
  },
  {
    label: '审核驳回',
    value: 3
  },
  {
    label: '流程终结',
    value: 6
  },
  {
    label: '流程结束',
    value: 7
  },
  {
    label: '审核中', // 到候选人/候选组
    value: 8
  },
  {
    label: '审核中', // 节点被调度
    value: 14
  },
  {
    label: '已撤销',
    value: 17
  },
  {
    label: '已删除',
    value: 18
  },
]
