<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="考试管理"
          :head-btn-options="headBtnOptions"
          @head-add-tabs="headAdd"
          @head-remove="headRemove"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"

          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowDetail"

        >
          <template #customBtn="{ row, index }">
            <el-button
              v-if="row.status === 2"
              size="small"
              type="text"
              @click.stop="rowEdit(row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="row.status === 2"
              size="small"
              type="text"
              @click.stop="rowRemove(row)"
            >
              删除
            </el-button>
            <el-button
              v-if="row.status !== 2"
              size="small"
              type="text"
              @click.stop="processFig(row)"
            >
              流程图
            </el-button>
            <el-button
              v-if="row.status !== 2"
              size="small"
              type="text"
              @click.stop="processProgress(row)"
            >
              流程进度
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <common-dialog
      v-if="bpmnVisible"
      width="70%"
      customClass="wf-dialog"
      :dialogTitle="$t(`cip.desk.workflow.title.indexHeadVisible`)"
      :showBtn="false"
      @cancel="bpmnVisible = false">
      <wf-design ref="bpmn"
                 style="height: 60vh;"
                 :options="bpmnOption"></wf-design>
    </common-dialog>
    <common-dialog
      v-if="circulationVisible"
      width="35%"
      :dialogTitle="$t(`cip.plat.wfOps.process.title.processProgressTitle`)"
      :showBtn="false"
      @cancel="circulationVisible = false">
      <wf-flow :flow="flow"></wf-flow>
    </common-dialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import exForm from "@/views/plugin/workflow/mixins/ex-form";

import {detail} from "@/api/plugin/workflow/process";
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue';
import {mapGetters} from "vuex";
import {getEduTree} from "@/api/training/edutree";
import {getList, remove} from "@/api/training/eduexam";
import CommonDialog from "@/components/CommonDialog";

export default {
  name: "eduexam",
  components: {
    HeadLayout,
    GridLayout,
    WfFlow,
    CommonDialog
  },
  mixins: [exForm],
  data() {
    return {
      bpmnOption: {},
      circulationVisible: false,
      bpmnVisible: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduexam_add, true),
        viewBtn: this.vaildData(this.permission.eduexam_view, true),
        delBtn: this.vaildData(this.permission.eduexam_delete, true),
        editBtn: this.vaildData(this.permission.eduexam_edit, true)
      };
    },
    searchColumns() {
      return [
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "考试编号",
          prop: "code",
          span: 4,
        },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "专业分类",
          prop: "subjectType",
          dicData: [],
          span: 4,
          type: "tree",
          props: {
            label: "title",
            value: "id",
          },
          dataType: 'number',
        },
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "试卷名称",
          prop: "paperName",
          span: 4,
        },
      ]
    },
    tableOptions() {
      return {
        menu: true,
        linklabel: "code",
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "考试编号",
            prop: "code",
            width: 120,
            align: 'left',
            overHidden: true,
          },
          {
            label: "专业分类",
            prop: "subjectType",
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            dataType: 'number',
            align: 'center',
            width: 200,
            overHidden: true,
          },
          {
            label: "试卷名称",
            prop: "paperName",
            align: 'left',
            overHidden: true,
          },
          // {
          //   label: "考试时间",
          //   prop: "examTime",
          //   type: "datetime",
          //   format: "yyyy-MM-dd HH:mm:ss",
          //   valueFormat: "yyyy-MM-dd HH:mm:ss",
          //   width: 200,
          //   align: 'center',
          //   overHidden: true,
          // },
          {
            label: "考试时长(分钟)",
            prop: "answerTime",
            sortable: true,
            width: 140,
            align: 'right',
            overHidden: true,
          },
          {
            label: "发布时间",
            prop: "createTime",
            width: 200,
            align: 'center',
            overHidden: true,
          },
          {
            label: "是否由培训任务生成",
            prop: "eduStatus",
            width: 150,
            align: "center",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: 'number',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=examination_type",
            overHidden: true
          },
          {
            label: "状态",
            prop: "status",
            width: 100,
            align: "center",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: 'number',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=examination_status",
            overHidden: true
          }
        ]
      }
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permissionList.addBtn) {
        buttonBtn.push(
          {
            label: '新增',
            emit: "head-add-tabs",
            type: "button",
            btnOptType: 'primary',
          });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove",
            type: "button",
            btnOptType: 'remove',
          });
      }
      return buttonBtn;
    },
    gridRowBtn(row) {
      let buttonBtn = [];
      if (row.status === 2) {
        if (this.permissionList.editBtn) {
          buttonBtn.push(
            {
              label: this.$t(`cip.cmn.btn.editBtn`),
              emit: "row-edit",
              type: "button",
              icon: ""
            });
        }
        if (this.permissionList.delBtn) {
          buttonBtn.push(
            {
              label: this.$t(`cip.cmn.btn.delBtn`),
              emit: "row-remove",
              type: "button",
              icon: ""
            })
        }
        return buttonBtn;
      } else {
        buttonBtn.push(
          {
            label: "流程进度",
            emit: "process-progress",
            type: "button",
            icon: ""
          })
        buttonBtn.push(
          {
            label: "流程图",
            emit: "process-fig",
            type: "button",
            icon: ""
          })
        return buttonBtn;
      }
    },
  },
  methods: {
    processProgress(row) {
      console.log(row, "row")
      const {taskId, processInstanceId} = row
      this.getTaskDetail(taskId, processInstanceId).then(() => {
        this.waiting = false // 关闭骨架屏
      })
      this.circulationVisible = true
    },
    processFig(row) {
      const {taskId, processInstanceId} = row
      detail({taskId, processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data
        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }
        this.bpmnVisible = true
      })
    },
    initData() {
      getEduTree(this.userInfo.tenantId).then(res => {
        this.findObject(this.$refs.gridHeadLayout.searchOption.column, 'subjectType').dicData = res.data.data
        this.findObject(this.$refs.gridLayOut.option.column, 'subjectType').dicData = res.data.data
      })
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      getList(page.currentPage, page.pageSize, Object.assign(params)).then(res => {
        const data = res.data.data;
        this.tableData = data.records;
        this.tableData.forEach((e) => {
          e.eduStatus = e.status
        })
        this.$refs.gridLayOut.page.total = data.total;
        this.tableLoading = false;
      });
    },
    headAdd() {
      this.$router.push({
        path: '/training/exam/eduexamEdit',
        query: {
          type: 'add',
        }
      });
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      let statusList = []
      selectionList.forEach(e => {
        if (e.status !== 2) {
          statusList.push(e.status)
        }
      })
      if (statusList.length !== 0) {
        this.$message.warning("请确认选择的数据均处于草稿状态！");
        return;
      }
      if (selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据！");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(this.$func.join(ids));
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.$refs.gridLayOut.selectionClear();
        });
    },
    rowDetail(row) {
      this.$router.push({
        path: '/training/exam/eduexamView',
        query: {
          row: JSON.stringify(row),
          type: 'view',
          prop: this.type,
        }
      });
    },
    rowRemove(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
    rowEdit(row) {
      this.$router.push({
        path: '/training/exam/eduexamEdit',
        query: {
          row: JSON.stringify(row),
          type: 'edit',
          prop: this.type,
        }
      });
    },
  },
  mounted() {
    this.initData();
    this.onLoad(this.page)
  },
  created() {
    this.onLoad(this.page)
  }
}
</script>

<style scoped>
::v-deep .bjs-powered-by {
  display: none;
}
</style>
