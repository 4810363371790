<template>
  <div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            安全/环保责任签订数
          </span>
          <!-- <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div> -->
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte">
            <img class="groupImg" src="@/assets/images/board/group.png">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value1) || 0 }}<span>人</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              安全责任书签订数
            </div>
          </div>
          <div class="card-pd-2-BotLitte">
            <img class="groupImg" src="@/assets/images/board/group.png">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value2) || 0 }}<span>人</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              环保责任书签订数
            </div>
          </div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            注册安全/环保工程师证书
          </span>
          <!-- <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png">
            <img src="@/assets/images/board/six.png">
            <img src="@/assets/images/board/seven.png">
            <img src="@/assets/images/board/eight.png">
          </div> -->
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte">
            <img class="groupImg" src="@/assets/images/board/people.png">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value3) || 0 }}<span>人</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              注册安全工程师证书
            </div>
          </div>
          <div class="card-pd-2-BotLitte">
            <img class="groupImg" src="@/assets/images/board/people.png">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value4) || 0 }}<span>人</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">
              注册环保工程师证书
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div style="width: calc(50% - 8px)">
        <div class="card-pd-2" style="width: calc(100% - 52px)">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            持证率统计
          </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div class="bottomChartBox">
              <div class="bottomChartTitle">特种作业人员持证率</div>
              <div id="chart-fourteen"></div>
            </div>
            <div class="bottomChartBox">
              <div class="bottomChartTitle">三类人员持证率</div>
              <div id="chart-fifteen"></div>
            </div>
          </div>
        </div>
        <div class="card-pd-2" style="width: calc(100% - 52px);margin-top: 16px">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            应急演练次数
          </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div class="card-pd-2-BotLitte">
              <img class="groupImg" src="@/assets/images/board/drill.png">
              <div class="priceNumber">
                {{ formatWithCommas(dataForm.value5) || 0 }}<span>次</span>
              </div>
              <div class="lineBox"></div>
              <div class="priceContent">
                境外项目、机构
              </div>
            </div>
            <div class="card-pd-2-BotLitte">
              <img class="groupImg" src="@/assets/images/board/drill.png">
              <div class="priceNumber">
                {{ formatWithCommas(dataForm.value6) || 0 }}<span>次</span>
              </div>
              <div class="lineBox"></div>
              <div class="priceContent">
                境内项目、机构
              </div>
            </div>
          </div>
        </div>
        <div class="card-pd-2" style="width: calc(100% - 52px);margin-top: 16px">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            当前年度“零违章”活动统计
          </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div class="card-pd-2-BotLitte">
              <img class="groupImg" src="@/assets/images/board/activity.png">
              <div class="priceNumber">
                {{ formatWithCommas(dataForm.value7) || 0 }}<span>人</span>
              </div>
              <div class="lineBox"></div>
              <div class="priceContent">
                个人
              </div>
            </div>
            <div class="card-pd-2-BotLitte">
              <img class="groupImg" src="@/assets/images/board/activity.png">
              <div class="priceNumber">
                {{ formatWithCommas(dataForm.value8) || 0 }}<span>人</span>
              </div>
              <div class="lineBox"></div>
              <div class="priceContent">
                班组
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: calc(50% - 8px)">
        <div class="card-pd-2" style="width: calc(100% - 52px)">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            下属组织专职安全、环保管理持证情况统计(百分比)
          </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div id="chart-sixteen"></div>
          </div>
        </div>
        <div class="card-pd-2" style="width: calc(100% - 52px);margin-top: 16px">
          <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title">
            每月“零违章”活动统计
          </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div id="chart-seventeen"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import slider from "@/views/business/board/components/slider";
import {appIndex, appIndexChild} from "@/api/user";
import {mapGetters} from "vuex";

export default {
  components: {slider},
  data() {
    return {
      year: '',
      month: '',
      dataForm: {
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
        value7: '',
        value8: '',
        value9: '',
        value10: '',
      }
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  created() {
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth() + 1;
  },
  mounted() {
    setTimeout(() => {
      this.getChartFourteen();
      this.getChartFifteen();
      this.getChartSixteen();
      this.getChartSeventeen();
    }, 200)
    this.getIndexData();
  },
  methods: {
    formatWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getLastSixMonthsRange() {
      const today = new Date();
      const endYear = today.getFullYear();
      const endMonth = today.getMonth() + 1;
      const endDate = `${endYear}-${String(endMonth).padStart(2, '0')}-01`;
      let startYear = endYear;
      let startMonth = endMonth - 5;
      if (startMonth <= 0) {
        startMonth += 12;
        startYear -= 1;
      }
      const startDate = `${startYear}-${String(startMonth).padStart(2, '0')}-01`;
      return {startDate, endDate};
    },
    getIndexData() {
      // 安全责任书签订数
      appIndex({
        indexCode: 'ZB0055_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value1 = res.data.data.records[0]._value;
        }
      })

      // 环保责任书签订数
      appIndex({
        indexCode: 'ZB0056_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value2 = res.data.data.records[0]._value;
        }
      })

      // 注册安全工程师证书数量
      appIndex({
        indexCode: 'ZB0057_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value3 = res.data.data.records[0]._value;
        }
      })

      // 注册环保工程师证书数量
      appIndex({
        indexCode: 'ZB0058_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value4 = res.data.data.records[0]._value;
        }
      })

      // 境内应急演练次数
      appIndex({
        indexCode: 'ZB0062_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value6 = res.data.data.records[0]._value;
        }
      })

      // 境外应急演练次数
      appIndex({
        indexCode: 'ZB0097_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value5 = res.data.data.records[0]._value;
        }
      })

      // 年度“零违章”个人数量
      appIndex({
        indexCode: 'ZB0063_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value7 = res.data.data.records[0]._value;
        }
      })

      // 年度“零违章”班组数量
      appIndex({
        indexCode: 'ZB0064_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          this.dataForm.value8 = res.data.data.records[0]._value;
        }
      })
    },
    getChartFourteen() {
      // 特种作业人员持证率
      appIndex({
        indexCode: 'ZB0059_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        let value = 100;
        // let value = 0;
        // if (res.data.code == 200 && res.data.data.records.length != 0) {
        //   value = res.data.data.records[0]._value || 0;
        // }
        var myChart = this.$echarts.init(
          document.getElementById("chart-fourteen")
        );
        var option = {
          series: [
            {
              radius: "100%",
              type: "gauge",
              progress: {
                show: true,
                itemStyle: {
                  color: "#FB3636",
                },
                width: 15,
                roundCap: true,
              },
              axisLine: {
                lineStyle: {
                  width: 15
                },
                roundCap: true,
              },
              axisTick: {
                show: true,
              },
              splitLine: {
                show: false,
                length: 15,
                lineStyle: {
                  width: 2,
                  color: "#999",
                },
              },
              axisLabel: {
                show: true,
                distance: 10,
                color: "#999",
                fontSize: 10,
              },
              anchor: {
                show: false,
                showAbove: true,
                size: 15,
                itemStyle: {
                  borderWidth: 5,
                },
              },
              title: {
                show: true,
                fontSize: 20,
                offsetCenter: [0, "70%"],
              },
              detail: {
                valueAnimation: true,
                formatter: "{value}%",
                fontSize: 32,
                offsetCenter: [0, "5%"],
              },
              pointer: {
                show: false,
              },
              data: [
                {
                  value: value,
                  name: "达成率",
                },
              ],
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })
    },
    getChartFifteen() {
      // 三类人员持证率
      appIndex({
        indexCode: 'ZB0060_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        let value = 100;
        // let value = 0;
        // if (res.data.code == 200 && res.data.data.records.length != 0) {
        //   value = res.data.data.records[0]._value || 0;
        // }
        var myChart = this.$echarts.init(
          document.getElementById("chart-fifteen")
        );
        var option = {
          series: [
            {
              radius: "100%",
              type: "gauge",
              progress: {
                show: true,
                itemStyle: {
                  color: "#FB3636",
                },
                width: 15,
                roundCap: true,
              },
              axisLine: {
                lineStyle: {
                  width: 15
                },
                roundCap: true,
              },
              axisTick: {
                show: true,
              },
              splitLine: {
                show: false,
                length: 15,
                lineStyle: {
                  width: 2,
                  color: "#999",
                },
              },
              axisLabel: {
                show: true,
                distance: 10,
                color: "#999",
                fontSize: 10,
              },
              anchor: {
                show: false,
                showAbove: true,
                size: 15,
                itemStyle: {
                  borderWidth: 5,
                },
              },
              title: {
                show: true,
                fontSize: 20,
                offsetCenter: [0, "70%"],
              },
              detail: {
                valueAnimation: true,
                formatter: "{value}%",
                fontSize: 32,
                offsetCenter: [0, "5%"],
              },
              pointer: {
                show: false,
              },
              data: [
                {
                  value: value,
                  name: "达成率",
                },
              ],
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })
    },
    getChartSixteen() {
      // 各公司专职安全、环保管理人员持证人情况统计
      appIndexChild({
        indexCode: 'ZB0061_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id,
        limit: 5
      }).then((res) => {
        let orgName = [];
        let value1 = [100,100,100,100,100]
        // let value1 = [];
        // if (res.data.code == 200 && res.data.data.length != 0) {
        //   let data = res.data.data;
        //   data.forEach((item) => {
        //     orgName.push(item.companyName)
        //     value1.push(item._value);
        //   })
        // }

        var myChart = this.$echarts.init(
          document.getElementById("chart-sixteen")
        );
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
          },
          grid: { // 让图表占满容器
            top: "40px",
            left: "140px",
            right: "40px",
            bottom: "25px"
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              color: 'rgba(134, 144, 156, 1)',
            },
            interval: 30,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
          },
          yAxis: {
            type: 'category',
            axisLabel: {
              color: 'rgba(78, 89, 105, 0.6)',
            },
            nameTextStyle: {//y轴上方单位的颜色
              color: 'rgba(120, 122, 128, 1)'
            },
            name: '单位：%',
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLine: {
              lineStyle: {
                color: ['#E8E8E8']
              }
            },
            data: orgName
          },
          series: [
            {
              name: '百分比',
              type: 'bar',
              stack: 'total',
              color: '#FB3636',
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: 'linear',
                      x: 1,
                      y: 0,
                      x2: 0,
                      y2: 0,
                      colorStops: [{
                        offset: 0,
                        color: '#FB3636' // 0% 处的颜色
                      },
                        {
                          offset: 1,
                          color: '#FF8181' // 100% 处的颜色
                        }
                      ],
                    }
                  }
                }
              },
              barWidth: 25,
              emphasis: {
                focus: 'series'
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' %';
                }
              },
              data: value1
            },
          ]
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })
    },
    getChartSeventeen() {
      const {startDate, endDate} = this.getLastSixMonthsRange();
      // 每月“零违章”活动统计个人/班组
      appIndex({
        indexCode: 'ZB0065_M',
        companyId: this.userInfo.dept_id,
        timeStart: startDate,
        timeEnd: endDate
      }).then((res) => {
        let month = [];
        let value1 = [];
        let value2 = [];
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          let data = res.data.data.records;
          data.forEach((item) => {
            month.push(item._month + '月')
            value1.push(item._value1);
            value2.push(item._value2);
          })
        }

        var myChart = this.$echarts.init(
          document.getElementById("chart-seventeen")
        );
        var option = {
          backgroundColor: "#fff",
          title: {
            left: "0",
            top: "0",
            textStyle: {
              color: "#999",
              fontSize: 12,
              fontWeight: "400",
            },
          },
          color: ["#5B8FF9", "#FFA18E", "#5AD8A6"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
              lineStyle: {
                type: "dashed",
              },
            },
          },
          legend: {
            right: "0",
          },
          grid: {
            left: "120",
            right: "15",
            bottom: "25",
            top: "40",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: month,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(0,0,0,0.5)",
              },
            },
          },
          yAxis: [
            {
              type: "value",
              name: "单位: 人",
              axisLabel: {
                color: "#999",
                textStyle: {
                  fontSize: 12,
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#F3F4F4",
                },
              },
              axisLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "个人",
              type: "line",
              smooth: true,
              data: value1,
              itemStyle: {
                normal: {
                  color: "#F56565",
                  borderColor: "#F56565",
                },
              },
            },
            {
              name: "班组",
              type: "line",
              smooth: true,
              data: value2,
              itemStyle: {
                normal: {
                  color: "#FFAF58",
                  borderColor: "#FFAF58",
                },
              },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.disFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.card-pd-2 {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-2-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-2-top-Title {
  color: #15161A;
  font-size: 21px;
  font-weight: bold;
}

.card-pd-2-top-btn {
  display: flex;
  justify-content: flex-end;
}

.card-pd-2-top-btn img {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}

.card-pd-2-Bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.card-pd-2-BotLitte {
  width: calc(50% - 64px);
  border: 1px solid #EDEDED;
  border-radius: 10px;
  padding: 40px 26px;
  position: relative;
}

.groupImg {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 26px;
  right: 26px;
}

.priceNumber {
  width: 100%;
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
}

.priceNumber span {
  font-weight: 400;
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
}

.lineBox {
  width: 40px;
  height: 3px;
  background-color: #FB3636;
  margin: 21px 0 26px;
}

.priceContent {
  width: 100%;
  color: #15161A;
  font-size: 18px;
}

.bottomChartBox {
  width: calc(50% - 54px);
  border: 1px solid #EDEDED;
  border-radius: 10px;
  padding: 21px;
}

.bottomChartTitle {
  width: 100%;
  color: #484A4D;
  font-size: 21px;
  margin-bottom: 20px;
}

#chart-fourteen {
  width: 209px;
  height: 209px;
  margin: auto;
}

#chart-sixteen {
  width: 100%;
  height: 450px;
}

#chart-seventeen {
  width: 100%;
  height: 378px;
}

#chart-fifteen {
  width: 209px;
  height: 209px;
  margin: auto;
}
</style>
