var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.$route.meta.title,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "newZtFormBox" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                inline: false,
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.injection.field.tablename"),
                            prop: "tablename",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.dc.injection.field.aggregationCode"
                              ),
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.tablename,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "tablename", $$v)
                              },
                              expression: "form.tablename",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.injection.field.keycolumn"),
                            prop: "keycolumn",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "new-form-input",
                            attrs: {
                              type: "textarea",
                              placeholder: _vm.$t(
                                "cip.dc.injection.field.groupingField"
                              ),
                              size: "mini",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.keycolumn,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "keycolumn", $$v)
                              },
                              expression: "form.keycolumn",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }