<template>
  <div>
    <el-container>
        <el-main>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="设备保养" name="maintain">
                    <maintain ref="maintain"/>
                </el-tab-pane>
                <el-tab-pane label="设备维修" name="service">
                    <service ref="service" />
                </el-tab-pane>
            </el-tabs>
        </el-main>
    </el-container>
  </div>
</template>

<script>
import maintain from './maintain/index.vue'
import service from './service/index.vue'
export default {
    components: {
        maintain,
        service,
    },
    data () {
        return {
            activeName: 'maintain',
        }
    },

    mounted () {
        this.handleClick()
    },

    methods: {
        handleClick () {
            // console.log(this.activeName)
            // console.log(this.$refs[this.activeName])
            this.$refs[this.activeName].init()
        }
    }

}
</script>

<style>

</style>