var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "dashboard-container",
    },
    [
      !_vm.ajaxing
        ? [
            _vm.show
              ? [
                  _vm.type === 0
                    ? _c("PortalLayout", { attrs: { layout: _vm.layout } })
                    : _vm._e(),
                  _vm.type === 1
                    ? _c(
                        "div",
                        { staticClass: "custom-page" },
                        [
                          _vm.linkType === 0
                            ? _c(_vm.currentView, { tag: "component" })
                            : _vm._e(),
                          _vm.linkType === 1
                            ? _c("embed", {
                                attrs: {
                                  src: _vm.url,
                                  height: "100%",
                                  type: "text/html",
                                  width: "100%",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _c("div", { staticClass: "portal-layout-nodata" }, [
                  _c("img", {
                    staticClass: "layout-nodata-img",
                    attrs: {
                      alt: "",
                      src: require("@/assets/images/dashboard-nodata.png"),
                    },
                  }),
                  _c("p", { staticClass: "layout-nodata-txt" }, [
                    _vm._v("暂无数据"),
                  ]),
                ]),
          ]
        : _vm._e(),
      _c("Setting", { ref: "Setting", on: { refresh: _vm.refresh } }),
      _c("el-button", {
        staticClass: "setting-btn",
        attrs: { type: "primary", icon: "el-icon-arrow-left", size: "large" },
        on: {
          click: function ($event) {
            return _vm.$refs.Setting.init(_vm.portalId)
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }