var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-box riskIdentification" },
    [
      _c(
        "div",
        { staticClass: "flex-container" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "运行设置", name: "1" } },
                [
                  _c("RuntimeSetting", {
                    attrs: { allData: _vm.runtimeSetting },
                    on: { update: _vm.updateRunTime },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "区域绘制", name: "2" } },
                [
                  _c("RegionSetting", {
                    attrs: { allData: _vm.regionSetting, url: _vm.url },
                    on: { update: _vm.updateRegion },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "联合报警", name: "3" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      staticClass: "form-base form-box",
                      attrs: {
                        model: _vm.dataForm,
                        "label-width": "160px",
                        disabled: _vm.formType == "view",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "长度相对像素", prop: "code" } },
                            [
                              _c(
                                "div",
                                { staticClass: "logic-box" },
                                [
                                  _c("el-input-number", {
                                    model: {
                                      value: _vm.dataForm.lenMinValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "lenMinValue",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.lenMinValue",
                                    },
                                  }),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.dataForm.lenLogicOperator,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "lenLogicOperator",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.lenLogicOperator",
                                      },
                                    },
                                    _vm._l(_vm.taskTypeData, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _c("el-input-number", {
                                    model: {
                                      value: _vm.dataForm.lenMaxValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "lenMaxValue",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.lenMaxValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "长度逻辑运算方式",
                                prop: "type",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择长度逻辑运算方式",
                                  },
                                  model: {
                                    value: _vm.dataForm.operator,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "operator", $$v)
                                    },
                                    expression: "dataForm.operator",
                                  },
                                },
                                _vm._l(_vm.logicTypeData, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "宽度相对像素",
                                prop: "outsideCode",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "logic-box" },
                                [
                                  _c("el-input-number", {
                                    model: {
                                      value: _vm.dataForm.widMinValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "widMinValue",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.widMinValue",
                                    },
                                  }),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.dataForm.widLogicOperator,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "widLogicOperator",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.widLogicOperator",
                                      },
                                    },
                                    _vm._l(_vm.taskTypeData, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _c("el-input-number", {
                                    model: {
                                      value: _vm.dataForm.widMaxValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "widMaxValue",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.widMaxValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("addNodeTree", { ref: "addNodeTree" }),
      _c("addBoxTree", { ref: "addBoxTree" }),
      _c("addServerTree", { ref: "addServerTree" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }