var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "储位二维码",
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        width: "40%",
        "custom-class": "qrcodeDialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { ref: "qrcodeBox", staticClass: "qrcodeBox" }, [
        _c("div", { staticClass: "qrcodeBox_text" }, [
          _vm._v(_vm._s(_vm.qrcodeText)),
        ]),
        _c("div", { staticClass: "qrcodeBox_text" }, [
          _vm._v(_vm._s(_vm.qrcodeCode)),
        ]),
        _c("img", {
          staticStyle: { width: "200px", height: "200px" },
          attrs: { src: _vm.qrcodeSrc, alt: "" },
        }),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.downloadImg } },
            [_vm._v("下载")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }