<template>
  <div>
    <div class="disFlex">
      <div style="width: calc(50% - 8px)">
        <div class="card-pd-2" style="width: calc(100% - 52px)">
          <div class="card-pd-2-top">
            <span class="card-pd-2-top-Title"> 安全培训情况 </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">培训次数</div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value1) || 0 }}<span>次</span>
              </div>
              <slider :value="dataForm.value3" />
              <div class="botLitte2-info">
                计划培训次数：<span>{{
                  formatWithCommas(dataForm.value2) || 0
                }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ dataForm.value3 || 0 }}%</span>
              </div>
            </div>
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">培训人次</div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value4) || 0 }}<span>人次</span>
              </div>
              <slider :value="dataForm.value6" />
              <div class="botLitte2-info">
                计划培训人次：<span>{{
                  formatWithCommas(dataForm.value5) || 0
                }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ dataForm.value6 || 0 }}%</span>
              </div>
            </div>
            <div
              class="card-pd-2-BotLitte2"
              style="padding: 21px 0 0 0; width: calc(33.3% - 10px)"
            >
              <div class="botLitte2-title" style="text-indent: 21px">
                培训覆盖率
              </div>
              <div id="chart-eighteen"></div>
            </div>
          </div>
        </div>
        <div
          class="card-pd-2"
          style="width: calc(100% - 52px); margin-top: 16px"
        >
          <div class="card-pd-2-top">
            <span class="card-pd-2-top-Title"> 公司组织的安全培训情况 </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">培训次数</div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value7) || 0 }}<span>次</span>
              </div>
              <slider :value="dataForm.value9" />
              <div class="botLitte2-info">
                计划培训次数：<span>{{
                  formatWithCommas(dataForm.value8) || 0
                }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ dataForm.value9 || 0 }}%</span>
              </div>
            </div>
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">培训人次</div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value10) || 0 }}<span>人次</span>
              </div>
              <slider :value="dataForm.value12" />
              <div class="botLitte2-info">
                计划培训人次：<span>{{
                  formatWithCommas(dataForm.value11) || 0
                }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ dataForm.value12 || 0 }}%</span>
              </div>
            </div>
            <div
              class="card-pd-2-BotLitte2"
              style="padding: 21px 0 0 0; width: calc(33.3% - 10px)"
            >
              <div class="botLitte2-title" style="text-indent: 21px">
                培训覆盖率
              </div>
              <div id="chart-nineteen"></div>
            </div>
          </div>
        </div>
        <div
          class="card-pd-2"
          style="width: calc(100% - 52px); margin-top: 16px"
        >
          <div class="card-pd-2-top">
            <span class="card-pd-2-top-Title"> 分包商组织的安全培训情况 </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">培训次数</div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value13) || 0 }}<span>次</span>
              </div>
              <slider :value="dataForm.value15" />
              <div class="botLitte2-info">
                计划培训次数：<span>{{
                  formatWithCommas(dataForm.value14) || 0
                }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ dataForm.value15 || 0 }}%</span>
              </div>
            </div>
            <div class="card-pd-2-BotLitte2">
              <div class="botLitte2-title">培训人次</div>
              <div class="botLitte2-number">
                {{ formatWithCommas(dataForm.value16) || 0 }}<span>人次</span>
              </div>
              <slider :value="dataForm.value18" />
              <div class="botLitte2-info">
                计划培训人次：<span>{{
                  formatWithCommas(dataForm.value17) || 0
                }}</span>
              </div>
              <div class="botLitte2-info">
                完成率：<span>{{ dataForm.value18 || 0 }}%</span>
              </div>
            </div>
            <div
              class="card-pd-2-BotLitte2"
              style="padding: 21px 0 0 0; width: calc(33.3% - 10px)"
            >
              <div class="botLitte2-title" style="text-indent: 21px">
                培训覆盖率
              </div>
              <div id="chart-twenty"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: calc(50% - 8px)">
        <div class="card-pd-2" style="width: calc(100% - 52px)">
          <div class="card-pd-2-top">
            <span class="card-pd-2-top-Title"> 下属组织培训次数TOP5 </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div id="chart-twenty-one"></div>
          </div>
        </div>
        <div
          class="card-pd-2"
          style="width: calc(100% - 52px); margin-top: 16px"
        >
          <div class="card-pd-2-top">
            <span class="card-pd-2-top-Title">
              安全培训次数近半年月度统计
            </span>
            <!-- <div class="card-pd-2-top-btn">
              <img src="@/assets/images/board/five.png">
              <img src="@/assets/images/board/six.png">
              <img src="@/assets/images/board/seven.png">
              <img src="@/assets/images/board/eight.png">
            </div> -->
          </div>
          <div class="card-pd-2-Bottom">
            <div id="chart-twenty-two"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import slider from "@/views/business/board/components/slider";
import { appIndex, appIndexChild } from "@/api/user";
import { mapGetters } from "vuex";

export default {
  components: { slider },
  data() {
    return {
      year: "",
      month: "",
      dataForm: {
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: "",
        value6: "",
        value7: "",
        value8: "",
        value9: "",
        value10: "",
        value11: "",
        value12: "",
        value13: "",
        value14: "",
        value15: "",
        value16: "",
        value17: "",
        value18: "",
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth() + 1;
  },
  mounted() {
    setTimeout(() => {
      this.getChartEighteen();
      this.getChartNineteen();
      this.getChartTwenty();
      this.getChartTwentyOne();
      this.getChartTwentyTwo();
    }, 200);
    this.getIndexData();
  },
  methods: {
    formatWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getLastSixMonthsRange() {
      const today = new Date();
      const endYear = today.getFullYear();
      const endMonth = today.getMonth() + 1;
      const endDate = `${endYear}-${String(endMonth).padStart(2, "0")}-01`;
      let startYear = endYear;
      let startMonth = endMonth - 5;
      if (startMonth <= 0) {
        startMonth += 12;
        startYear -= 1;
      }
      const startDate = `${startYear}-${String(startMonth).padStart(
        2,
        "0"
      )}-01`;
      return { startDate, endDate };
    },
    getIndexData() {
      let oneData = new Promise((resolve, reject) => {
        // 安全培训完成次数
        appIndex({
          indexCode: "ZB0067_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      let twoData = new Promise((resolve, reject) => {
        // 安全培训计划次数
        appIndex({
          indexCode: "ZB0068_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      Promise.all([oneData, twoData]).then((res) => {
        this.dataForm.value1 = res[0];
        this.dataForm.value2 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value3 = 0;
          return;
        }
        this.dataForm.value3 =
          res[0] >= res[1]
            ? 100
            : Math.round((Number(res[0]) / Number(res[1])) * 100);
      });

      // 安全培训人次
      let threeData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0069_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      // 计划培训人次
      let fourData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0070_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      Promise.all([threeData, fourData]).then((res) => {
        this.dataForm.value4 = res[0];
        this.dataForm.value5 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value6 = 0;
          return;
        }
        this.dataForm.value6 =
          res[0] >= res[1]
            ? 100
            : Math.round((Number(res[0]) / Number(res[1])) * 100);
      });

      // 公司组织的安全培训次数
      let fiveData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0072_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      // 公司组织的计划培训次数
      let sixData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0073_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      Promise.all([fiveData, sixData]).then((res) => {
        this.dataForm.value7 = res[0];
        this.dataForm.value8 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value9 = 0;
          return;
        }
        this.dataForm.value9 =
          res[0] >= res[1]
            ? 100
            : Math.round((Number(res[0]) / Number(res[1])) * 100);
      });

      // 公司组织的安全培训人次
      let sevenData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0074_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      // 公司组织的计划培训人次
      let eightData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0075_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      Promise.all([sevenData, eightData]).then((res) => {
        this.dataForm.value10 = res[0];
        this.dataForm.value11 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value12 = 0;
          return;
        }
        this.dataForm.value12 = Math.round(
          (Number(res[0]) / Number(res[1])) * 100
        );
      });

      // 分包商组织的安全培训次数
      let nineData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0077_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      // 分包商组织的计划培训次数
      let tenData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0078_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      Promise.all([nineData, tenData]).then((res) => {
        this.dataForm.value13 = res[0];
        this.dataForm.value14 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value15 = 0;
          return;
        }
        this.dataForm.value15 = Math.round(
          (Number(res[0]) / Number(res[1])) * 100
        );
      });

      // 分包商组织的安全培训次数
      let elevenData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0079_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      // 分包商组织的计划培训次数
      let twelveData = new Promise((resolve, reject) => {
        appIndex({
          indexCode: "ZB0080_Y",
          _year: this.year,
          companyId: this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200 && res.data.data.records.length != 0) {
            resolve(res.data.data.records[0]._value);
          } else {
            resolve(0);
          }
        });
      });

      Promise.all([elevenData, twelveData]).then((res) => {
        this.dataForm.value16 = res[0];
        this.dataForm.value17 = res[1];
        if (res[0] == 0 || res[1] == 0) {
          this.dataForm.value18 = 0;
          return;
        }
        this.dataForm.value18 = Math.round(
          (Number(res[0]) / Number(res[1])) * 100
        );
      });
    },
    getChartEighteen() {
      appIndex({
        indexCode: "ZB0071_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        let value = 0;
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          value =
            res.data.data.records[0]._value >= 1
              ? 100
              : (res.data.data.records[0]._value * 100).toFixed(2);
        }
        var myChart = this.$echarts.init(
          document.getElementById("chart-eighteen")
        );
        var option = {
          series: [
            {
              radius: "100%",
              type: "gauge",
              progress: {
                show: true,
                itemStyle: {
                  color: "#FB3636",
                },
                width: 10,
                roundCap: true,
              },
              axisLine: {
                lineStyle: {
                  width: 10,
                },
                roundCap: true,
              },
              axisTick: {
                show: true,
              },
              splitLine: {
                show: false,
                length: 15,
                lineStyle: {
                  width: 2,
                  color: "#999",
                },
              },
              axisLabel: {
                show: true,
                distance: 10,
                color: "#999",
                fontSize: 10,
              },
              anchor: {
                show: false,
                showAbove: true,
                size: 15,
                itemStyle: {
                  borderWidth: 5,
                },
              },
              title: {
                show: true,
                fontSize: 20,
                offsetCenter: [0, "70%"],
              },
              detail: {
                valueAnimation: true,
                formatter: "{value}%",
                fontSize: 28,
                offsetCenter: [0, "5%"],
              },
              pointer: {
                show: false,
              },
              data: [
                {
                  value: value || 0,
                  name: "覆盖率",
                },
              ],
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getChartNineteen() {
      appIndex({
        indexCode: "ZB0076_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        let value = 0;
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          value =
            res.data.data.records[0]._value >= 1
              ? 100
              : (res.data.data.records[0]._value * 100).toFixed(2);
        }
        var myChart = this.$echarts.init(
          document.getElementById("chart-nineteen")
        );
        var option = {
          series: [
            {
              radius: "100%",
              type: "gauge",
              progress: {
                show: true,
                itemStyle: {
                  color: "#FB3636",
                },
                width: 10,
                roundCap: true,
              },
              axisLine: {
                lineStyle: {
                  width: 10,
                },
                roundCap: true,
              },
              axisTick: {
                show: true,
              },
              splitLine: {
                show: false,
                length: 15,
                lineStyle: {
                  width: 2,
                  color: "#999",
                },
              },
              axisLabel: {
                show: true,
                distance: 10,
                color: "#999",
                fontSize: 10,
              },
              anchor: {
                show: false,
                showAbove: true,
                size: 15,
                itemStyle: {
                  borderWidth: 5,
                },
              },
              title: {
                show: true,
                fontSize: 20,
                offsetCenter: [0, "70%"],
              },
              detail: {
                valueAnimation: true,
                formatter: "{value}%",
                fontSize: 28,
                offsetCenter: [0, "5%"],
              },
              pointer: {
                show: false,
              },
              data: [
                {
                  value: value,
                  name: "覆盖率",
                },
              ],
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getChartTwenty() {
      appIndex({
        indexCode: "ZB0081_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
      }).then((res) => {
        let value = 0;
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          value =
            res.data.data.records[0]._value >= 1
              ? 100
              : (res.data.data.records[0]._value * 100).toFixed(2);
        }
        var myChart = this.$echarts.init(
          document.getElementById("chart-twenty")
        );
        var option = {
          series: [
            {
              radius: "100%",
              type: "gauge",
              progress: {
                show: true,
                itemStyle: {
                  color: "#FB3636",
                },
                width: 10,
                roundCap: true,
              },
              axisLine: {
                lineStyle: {
                  width: 10,
                },
                roundCap: true,
              },
              axisTick: {
                show: true,
              },
              splitLine: {
                show: false,
                length: 15,
                lineStyle: {
                  width: 2,
                  color: "#999",
                },
              },
              axisLabel: {
                show: true,
                distance: 10,
                color: "#999",
                fontSize: 10,
              },
              anchor: {
                show: false,
                showAbove: true,
                size: 15,
                itemStyle: {
                  borderWidth: 5,
                },
              },
              title: {
                show: true,
                fontSize: 20,
                offsetCenter: [0, "70%"],
              },
              detail: {
                valueAnimation: true,
                formatter: "{value}%",
                fontSize: 32,
                offsetCenter: [0, "5%"],
              },
              pointer: {
                show: false,
              },
              data: [
                {
                  value: value,
                  name: "覆盖率",
                },
              ],
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getChartTwentyOne() {
      appIndexChild({
        indexCode: "ZB0082_Y",
        _year: this.year,
        companyId: this.userInfo.dept_id,
        limit: 5,
      }).then((res) => {
        let companyName = [];
        let value = [];
        if (res.data.code == 200 && res.data.data.length != 0) {
          let data = res.data.data;
          data.forEach((item) => {
            companyName.push(item.org_name);
            value.push(item._value);
          });
        }
        companyName.reverse();
        companyName = companyName.map(item=>{
          return {
            value: item,
            textStyle:{
              width: 100,
              overflow: 'break'
            }
          }
        })
        value.reverse();
        var myChart = this.$echarts.init(
          document.getElementById("chart-twenty-one")
        );
        var option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          grid: {
            // 让图表占满容器
            top: "40px",
            left: "110px",
            // right: "0px",
            bottom: "25px",
          },
          xAxis: {
            type: "value",
            axisLabel: {
              color: "rgba(134, 144, 156, 1)",
            },
            // interval: 30,
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
          yAxis: {
            type: "category",
            axisLabel: {
              color: "rgba(78, 89, 105, 0.6)",
            },
            nameTextStyle: {
              //y轴上方单位的颜色
              color: "rgba(120, 122, 128, 1)",
            },
            name: "单位：次",
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
              },
            },
            axisLine: {
              lineStyle: {
                color: ["#E8E8E8"],
              },
            },
            data: companyName,
          },
          series: [
            {
              name: "次数",
              type: "bar",
              stack: "total",
              color: "#FB3636",
              barWidth: 25,
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: "linear",
                      x: 1,
                      y: 0,
                      x2: 0,
                      y2: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#FB3636", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#FF8181", // 100% 处的颜色
                        },
                      ],
                    };
                  },
                },
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value + " 次";
                },
              },
              data: value,
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getChartTwentyTwo() {
      const { startDate, endDate } = this.getLastSixMonthsRange();
      // 安全培训次数近半年月度统计
      appIndex({
        indexCode: "ZB0083_M",
        companyId: this.userInfo.dept_id,
        timeStart: startDate,
        timeEnd: endDate,
      }).then((res) => {
        let value1 = [];
        let month = [];
        let value2 = [];
        if (res.data.code == 200) {
          let data = res.data.data.records.slice(0, 6);
          data.forEach((item) => {
            value1.push(item._value);
            value2.push(item.org_name);
            month.push(item._month + "月");
          });
        }
        var myChart = this.$echarts.init(
          document.getElementById("chart-twenty-two")
        );
        var option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            right: "0",
          },
          grid: {
            // 让图表占满容器
            top: "40px",
            left: "60px",
            right: "0px",
            bottom: "25px",
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLabel: { color: "#b3b5bb" },
              data: month,
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "单位：次",
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
            },
            {
              type: "value",
              name: "公司",
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
            },
          ],
          series: [
            {
              // name: '公司组织',
              type: "bar",
              emphasis: {
                focus: "series",
              },
              barWidth: 25,
              color: "#FB3636",
              data: value1,
              itemStyle: {
                normal: {
                  color: function () {
                    return {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#FB3636", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#FF8181", // 100% 处的颜色
                        },
                      ],
                    };
                  },
                },
              },
            },
            {
              // name: '公司组织',
              type: "bar",
              emphasis: {
                focus: "series",
              },
              barWidth: 25,
              color: "#FB3636",
              data: value2,
            },
            // {
            //   name: '分包商组织',
            //   type: 'bar',
            //   barWidth: 25,
            //   emphasis: {
            //     focus: 'series',
            //   },
            //   color: '#FFAB2D',
            //   itemStyle: {
            //     normal: {
            //       color: function () {
            //         return {
            //           type: 'linear',
            //           x: 0,
            //           y: 0,
            //           x2: 0,
            //           y2: 1,
            //           colorStops: [{
            //             offset: 0,
            //             color: '#FFAB2D' // 0% 处的颜色
            //           },
            //             {
            //               offset: 1,
            //               color: '#FFCD82' // 100% 处的颜色
            //             }
            //           ],
            //         }
            //       }
            //     }
            //   },
            //   data: [5900, 4100, 7900, 2500, 6000, 4200],
            // },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.disFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.card-pd-2 {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-2-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-2-top-Title {
  color: #15161a;
  font-size: 21px;
  font-weight: bold;
}

.card-pd-2-top-btn {
  display: flex;
  justify-content: flex-end;
}

.card-pd-2-top-btn img {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}

.card-pd-2-Bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.card-pd-2-BotLitte2 {
  width: calc(33.3% - 52px);
  border-radius: 8px;
  padding: 21px;
  border: 1px solid #ededed;
}

.botLitte2-number {
  color: #15161a;
  font-size: 37px;
  font-weight: bold;
  margin-top: 21px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}

.botLitte2-number span {
  color: #484a4d;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
}

.botLitte2-title {
  width: 100%;
  color: #484a4d;
  font-size: 21px;
}

.botLitte2-info {
  color: #15161a;
  font-size: 18px;
  margin-top: 13px;
}

.botLitte2-info span {
  color: #fb3636;
}

#chart-eighteen {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 21px;
}

#chart-nineteen {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 21px;
}

#chart-twenty {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 21px;
}

#chart-twenty-one {
  width: 100%;
  height: 420px;
}

#chart-twenty-two {
  width: 100%;
  height: 438px;
}

::v-deep .el-slider__runway.disabled .el-slider__bar {
  background-color: #fb3636;
}

::v-deep .el-slider__runway.disabled .el-slider__button {
  border-color: #fb3636;
}
</style>
