var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center",
          attrs: {
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            "append-to-body": "",
            title: "预览",
            width: _vm.formConf.generalWidth,
          },
          on: { open: _vm.onOpen, close: _vm.onClose },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("parser", {
        key: _vm.key,
        ref: "dynamicForm",
        attrs: { "form-conf": _vm.formConf },
        on: { submit: _vm.submitForm },
      }),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.formConf.cancelButtonText || "取 消")),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handelConfirm } },
            [
              _vm._v(
                _vm._s(_vm.formConf.confirmButtonText || "确 定") + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }