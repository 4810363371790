<template>
  <div>
    <head-layout  :head-title="'多数据源配置添加'"
                  :head-btn-options="headBtnOptions"
                  @head-save="headSave('save')"
                  @head-save-back="headSave('back')"
                  @head-cancel="headCancel"
                  ></head-layout>
    <div class="newZtFormBox">
      <el-form :inline="true" :model="form" label-width="120px" :rules="rules" ref="ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="数据源编码:" prop="sourceCode">
              <el-input placeholder="数据源编码" class = 'new-form-input' v-model="form.sourceCode"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数据源名称:" prop="sourceName">
              <el-input  placeholder="数据源名称" class = 'new-form-input' v-model="form.sourceName"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数据源描述:" prop="sourceDesc">
              <el-input  placeholder="数据源描述" class = 'new-form-input' v-model="form.sourceDesc"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="类型:" prop="type">
              <el-select  class = 'new-form-input' v-model="form.type"  placeholder="请选择类型">
                <el-option label="数据库" :value="1"></el-option>
                <el-option label="接口" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">

            <el-form-item label="数据源类型:" prop="sourceType">
              <el-input  placeholder="数据源类型" class = 'new-form-input' v-model="form.sourceType"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数据库名称:" prop="databaseName">
              <el-input  placeholder="数据库名称" class = 'new-form-input' v-model="form.databaseName"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="数据源连接:" prop="sourceConfig">
              <el-input placeholder="数据源连接"   class = 'new-form-input' v-model="form.sourceConfig"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import {saveDataSource, updateDataSource,detailDataSource} from "@/api/dataAcquisition";

export default {
  name: "dataCoonfigAdd",
  data(){
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        sourceCode: [
          { required: true, message: '请输入数据源编码', trigger: 'change' }
        ],
        sourceName: [
          { required: true, message: '请输入数据源编码', trigger: 'change' }
        ],
        sourceDesc: [
          { required: true, message: '请输入数据源编码', trigger: 'change' }
        ],
        sourceType: [
          { required: true, message: '请输入数据员类型', trigger: 'change' }
        ],
        databaseName: [
          { required: true, message: '请输入数据库名称', trigger: 'change' }
        ],
        sourceConfig: [
          { required: true, message: '请输入数据源连接配置', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
      },
      form: {
        sourceCode:'',
        sourceName:'',
        sourceDesc:'',
        type:'',
        databaseName:'',
        sourceConfig:'',
        sourceType:'',
      },
      formLabelWidth: '120px',
    }
  },
  created() {
    this.getDetailDataSource()
  },
  methods:{

    getDetailDataSource(){
      if(this.$route.query.type!=='edit') return

      detailDataSource(this.$route.query.id).then(res=>{
        this.form=res.data.data
      })
    }
    ,
    headSave(type){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.form.id){
            updateDataSource(this.form).then(()=>{
              this.$message.success('修改成功！');
              if(type=='back'){
                this.headCancel()
              }
            })
          }else{
            saveDataSource(this.form).then(()=>{
              this.$message.success('新增成功！');
              if(type=='back'){
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

  }
}
</script>

<style scoped>

</style>
