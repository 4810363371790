<template>
    <el-container class="inspection_plan-index">
        <el-main>
            <head-layout 
                head-title="日常检查计划" 
                :head-btn-options="headBtnOptions"
                @head-add="headAdd"
            />
            <!-- :grid-row-btn="gridRowBtn"  -->
            <grid-layout ref="gridLayOutPlan" 
                :table-options="tableOptions"
                :table-data="listData" 
                :table-loading="tableLoading" 
                :editType="inline" 
            >
                <template #planCode="{row}">
                    <el-link type="primary" @click="rowView(row)">{{ row.planCode }}</el-link>
                </template>
                <template #enableFlag="{ row }">
                    <div class="enableFlag-box">
                        <div :class="row.enableFlag == 'Y' ? 'enableFlag-Y' : 'enableFlag-N'" >
                        {{ statusMap[row.enableFlag] }}
                        </div>
                    </div>
                </template>
                <template #customBtn="{ row }">
                    <el-button v-if="row.enableFlag != 'Y'" type="text" size="small" @click="rowEdit(row)" >编辑</el-button >
                    <el-button v-if="row.enableFlag != 'Y'" type="text" size="small" @click="rowRemove(row)" >删除</el-button >
                    <el-button type="text" size="small" @click="rowView(row)" v-if="row.enableFlag == 'Y'">查看</el-button>
                    <el-button type="text" size="small" @click="rowStartEnd(row)" >{{ row.enableFlag == "Y" ? "停用" : "启用" }}</el-button>
                </template>
            </grid-layout>
        </el-main>
    </el-container>
    <!-- <div class="inspectionPlan">
    </div> -->
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { mapGetters } from "vuex";
import { selectPlansByObjectId } from '@/api/equipmentFacilities/equipmentInspection'
import {
  startEndServe, // 计划启停服务
  removeList, // 删除计划
} from "@/api/check/troubleshootingPlan";
export default {
    components: {
        HeadLayout,
        GridLayout,
    },

    data () {
        return {
            listData: [],
            tableLoading: false,
            statusMap: { Y: "启用", N: "停用" },
            nodeData: {},
        }
    },

    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.troubleshootingPlan_add, true),
                // viewBtn: this.vaildData(this.permission.eduquestions_view, true),
                // editBtn: this.vaildData(this.permission.eduquestions_edit, true),
                // delBtn: this.vaildData(this.permission.eduquestions_delete, true),
            };
        },

        headBtnOptions () {
            let buttonBtn = [];
            if (this.permissionList.addBtn) {
                buttonBtn.push({
                label: this.$t(`cip.cmn.btn.addBtn`),
                emit: "head-add",
                type: "button",
                icon: "",
                btnOptType: "add",
                });
            }
            return buttonBtn
        },
        tableOptions () {
            return {
                selection: false,
                linklabel: 'planCode',
                column: [
                    {
                        label: '计划编号',
                        prop: 'planCode',
                        align: "center",
                        overHidden: true,
                        slot: true
                    },
                    {
                        label: '计划名称',
                        prop: 'planName',
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '执行周期',
                        prop: 'cycle',
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '状态',
                        prop: 'enableFlag',
                        align: "center",
                        overHidden: true,
                        slot: true,
                        // dicData: [
                        //     { dictValue: "启用", dictKey: "Y" },
                        //     { dictValue: "未启用", dictKey: "N" },
                        // ],
                        // props: {
                        //     label: 'dictValue',
                        //     value: 'dictKey',
                        // },
                    },
                ]
            }
        }
    },

    methods: {
        headAdd () {
            this.$router.push({
                path: `/hiddenTrouble/check/troubleshootingPlan/add`,
                query: {
                type: "add",
                },
            });
        }, 

        init (data) {
            this.nodeData = data
            this.tableData = []
            this.onLoad()
        },

        onLoad () {
            // console.log("日常检查计划",this.nodeData)
            if(this.nodeData) {
                let list = []
                selectPlansByObjectId({objectId: this.nodeData.id}).then(res => {
                    console.log(123,res.data.data.records)
                    list = [...res.data.data.records]
                    this.listData = list
                    
                })
            } else {
                this.listData = []
            }
        },

        // 单删
        rowRemove(row) {
            this.$confirm("确认删除当前选中数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                removeList({ ids: row.id }).then((res) => {
                    this.$message.success("删除成功");
                    this.onLoad();
                });
                })
                .catch((error) => {
                    this.$message.success("已取消删除");
            });
        },

        // 编辑
        rowEdit(row) {
            this.$router.push({
                path: `/hiddenTrouble/check/troubleshootingPlan/edit`,
                query: {
                    id: row.id,
                    type: "edit",
                },
            });
        },
        rowView(row) {
            this.$router.push({
                path: `/hiddenTrouble/check/troubleshootingPlan/detail`,
                query: {
                id: row.id,
                type: "view",
                },
            });
        },
        rowStartEnd(row) {
            console.log(row)
            // 启停服务
            this.$confirm(`确认${row.enableFlag == "Y" ? "停用" : "启用"}该计划吗?`,"提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                startEndServe({
                    ids: row.id,
                    enableFlag: row.enableFlag == "Y" ? "N" : "Y",
                }).then((res) => {
                    if (res.data.code == 200) {
                    this.$message({
                        message: row.enableFlag == "Y" ? "停用成功" : "启用成功",
                        type: "success",
                    });
                    this.onLoad();
                    } else {
                    this.$message({
                        message: "操作失败",
                        type: "error",
                    });
                    }
                });
                })
                .catch((error) => {
                this.$message({
                    message: "取消操作",
                    type: "success",
                });
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.enableFlag-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  .enableFlag-Y {
    border: 1px solid #67c23a;
    color: #67c23a;
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
  }
  .enableFlag-N {
    border: 1px solid #606266;
    color: #606266;
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
  }
}
.inspection_plan-index{
    height: 100% !important;   
    overflow: hidden; 
}
::v-deep #gridLayout {
  height: calc(100% - 60px) !important;
}
::v-deep .avue-crud {
  height: 100% !important;

  .el-card{
      height: 100% !important;
    .el-card__body  {
      height: 100% !important;
  
      .el-form {
        height: 100% !important;
  
        .el-table {
          height: 100% !important;
        }
      }
  }

  }
}
</style>