<template>
  <div>
    <el-dialog
      top="10vh"
      :title="title"
      :visible.sync="show"
      width="70%"
    >
      <div id="chartDiscount" v-if="show"></div>
    </el-dialog>
  </div>
</template>
<script>
import {appIndex} from "@/api/user";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      show: false,
      title: '',
      year: '',
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.year = new Date().getFullYear();
  },
  methods: {
    getNowDate() {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth();
      month = month < 10 ? '0' + month : month;
      return year + '-' + month + '-01'
    },
    getChartDiscount(code) {
      let date = new Date();
      let endDate = this.getNowDate();
      let startDate = date.getFullYear() + '-01-01'
      appIndex({
        indexCode: code,
        companyId: this.userInfo.dept_id,
        timeStart: startDate,
        timeEnd: endDate
      }).then((res) => {
        let month = [];
        let value = [];
        let value1 = [];
        let value2 = [];
        if (res.data.code == 200 && res.data.data.records.length != 0) {
          let data = res.data.data.records;
          data.forEach((item) =>{
            month.push(item._month + '月');
            value.push(item._value);
            value1.push(item._value1);
            value2.push(item._value2);
          })
        }
        var myChart = this.$echarts.init(
          document.getElementById("chartDiscount")
        );
        var option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['安全环保总投入','安全投入','环保投入'],
            right: 0
          },
          grid: { // 让图表占满容器
            top: "40px",
            left: "80px",
            right: "40px",
            bottom: "25px"
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: month
          },
          yAxis: {
            type: 'value',
            scale: true
          },
          series: [
            {
              name: '环保投入',
              color: '#58A65B',
              type: 'line',
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 万元';
                }
              },
              data: value2
            },
            {
              name: '安全投入',
              color: '#FFBF6B',
              type: 'line',
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 万元';
                }
              },
              data: value1
            },
            {
              name: '安全环保总投入',
              color: '#FE7271',
              type: 'line',
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' 万元';
                }
              },
              data: value
            },
          ]
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });

      })
    }
  }
}
</script>
<style lang="scss" scoped>
#chartDiscount {
  width: 100%;
  height: 50vh;
}
</style>
