var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prj_area_info" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "项目区域信息",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-submit": _vm.headSubmit,
                  "head-goBack": _vm.headGoBack,
                },
              }),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "avue-form",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          ref: "avueForm",
                          attrs: { option: _vm.formOption },
                          on: { submit: _vm.handleSubmit },
                          model: {
                            value: _vm.dataForm,
                            callback: function ($$v) {
                              _vm.dataForm = $$v
                            },
                            expression: "dataForm",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "areaCode" },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入区域编号",
                                    maxlength: "64",
                                    disabled: _vm.dataForm.id,
                                  },
                                  model: {
                                    value: _vm.dataForm.areaCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "areaCode", $$v)
                                    },
                                    expression: "dataForm.areaCode",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "append" },
                                      slot: "append",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                自动生成\n                                "
                                      ),
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#13ce66",
                                          disabled: _vm.dataForm.id,
                                        },
                                        on: { change: _vm.handleSwitch },
                                        model: {
                                          value: _vm.isAutomatic,
                                          callback: function ($$v) {
                                            _vm.isAutomatic = $$v
                                          },
                                          expression: "isAutomatic",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm.dataForm.id
                      ? _c("div", { staticClass: "qrcode" }, [
                          _c("img", {
                            ref: "qrcodeBox",
                            staticStyle: { width: "150px", height: "150px" },
                            attrs: { src: _vm.qrCodeSrc, alt: "" },
                          }),
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.download.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v("下载")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm.dataForm.id
                ? _c(
                    "div",
                    { staticClass: "area_tabs" },
                    [
                      _c(
                        "el-tabs",
                        {
                          on: { "tab-click": _vm.handleActive },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "负责人员", name: "userList" } },
                            [
                              _c("userList", {
                                ref: "userList",
                                attrs: { areaId: _vm.dataForm.id },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "检查清单", name: "checkList" } },
                            [
                              _c("checkList", {
                                ref: "checkList",
                                attrs: { areaId: _vm.dataForm.id },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: { label: "检查记录", name: "checkRecord" },
                            },
                            [
                              _c("checkRecord", {
                                ref: "checkRecord",
                                attrs: { areaId: _vm.dataForm.id },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: { label: "作业记录", name: "workRecord" },
                            },
                            [
                              _c("workRecord", {
                                ref: "workRecord",
                                attrs: { areaId: _vm.dataForm.id },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "问题清单", name: "proList" } },
                            [
                              _c("proList", {
                                ref: "proList",
                                attrs: { areaId: _vm.dataForm.id },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("qrcodeDialog", { ref: "qrcodeDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }