<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-05 19:29:05
-->
<template>
  <el-dialog :title="title" :modal-append-to-body="true"  :append-to-body="true" :close-on-click-modal="false" :visible.sync="showDialog" width="60%" class="qmDialog" @close="closeDialog">
    <avue-form v-if="activeName === 1" :option="{ ...options,disabled:pageType == 'view' ? true : false,}" ref="addForm" v-model="form" @submit="treeNodeSave">
      <template slot="ifOutputOrigin">
        <el-radio-group v-model="form.ifOutputOrigin">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </template>
      <template slot="logic">
        <div class="logic-box">
          <el-select v-model="form.filed" placeholder="请选择">
            <el-option v-for="item in tagsList" :key="item.id" :label="item.enName" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="form.relationOperator" placeholder="请选择">
            <el-option v-for="item in relationOption" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
          <el-input-number v-model="form.value"></el-input-number>
        </div>
      </template>
    </avue-form>

    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleTreeNodeSave" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    recognizeAdd,
    tagsBatchByIdList,
    getById,
    recognizeEdit
  } from "@/api/safe/algorithmModel";
  import {
    from
  } from "rxjs";
  import Template from "@/views/message/template/list.vue";
  import organization from "@/views/business/safe/itemList/organization";
  import {
    mapGetters
  } from "vuex";
  import {
    ALGO_MODEL_CATEGORY
  } from '../../algorithmManagement/nodeDict.js'


  export default {
    components: {
      Template
    },
    mixins: [organization],
    data() {
      return {
        APITYPE: 'user',
        showDialog: false,
        dialogLoading: false,
        treeReadOnlyFlag: false,
        form: {},
        uploadForm: {},
        title: '',
        isEdit: false,
        searchData: [],
        pageType: '',
        activeName: 1,
        tagsList: [],
        parentDataList: [],
        relationOption: [{
            name: '等于',
            value: '='
          },
          {
            name: '大于等于',
            value: '>='
          },
          {
            name: '小于等于',
            value: '<='
          },
          {
            name: '大于',
            value: '>'
          },
          {
            name: '小于',
            value: '<'
          }
        ]
      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      options() {
        return {
          size: 'small',
          submitBtn: false,
          emptyBtn: false,
          labelWidth: 160,
          column: [{
              label: "识别名称",
              prop: "name",
              span: 24,
              rules: [{
                required: true,
                message: "请输入识别名称",
                trigger: "blur"
              }, {
                max: 64,
                message: '识别名称不能超过64个字符',
                trigger: 'blur'
              }]
            },
            {
              label: "识别标签(英文字符)",
              prop: "tag",
              span: 24,
              rules: [{
                required: true,
                message: "请输入识别标签(英文字符)",
                trigger: "blur"
              }, {
                max: 64,
                message: '识别标签(英文字符)不能超过64个字符',
                trigger: 'blur'
              }]
            },


            {
              label: "是否输出图片",
              prop: "ifOutputOrigin",
              formslot: true,
              span: 24,
              rules: [{
                required: true,
                message: "请选择是否输出图片",
                trigger: "blur"
              },]
            },
            {
              label: "识别逻辑",
              prop: "logic",
              formslot: true,
              span: 24,
            },

            {
              label: "备注",
              prop: "remark",
              span: 24,
              rules: [{
                message: "请输入备注",
                trigger: "blur"
              }, {
                max: 200,
                message: '备注不能超过200个字符',
                trigger: 'blur'
              }]
            },

          ],
        }
      },

    },

    methods: {
      init(type, data, parentData) {
        this.form = {}
        this.showDialog = true
        this.treeReadOnlyFlag = false;
        this.form.ifOutputOrigin=true
        this.pageType = type
        this.parentDataList = parentData
        this.getParentDetail()
        if (type == 'add') {
          this.title = '新增配置'
        } else {
          this.title = '编辑配置'
          this.form ={
            ...data,
            filed:data.logic.items[0].filed,
            relationOperator:data.logic.items[0].relationOperator,
            value:data.logic.items[0].value,
          }
        }

      },
      getParentDetail() {
        getById({
          id: this.parentDataList.id
        }).then((res) => {
          if (res.data && res.data.data && res.data.data.tags) {
            this.getTags(res.data.data.tags)
          }
        })
      },
      getTags(data) {
        tagsBatchByIdList({
          ids: data
        }).then((res) => {
          this.tagsList = res.data.data
        })

      },
      handleTreeNodeSave() {
        this.$refs.addForm.submit();
      },
      treeNodeSave(data) {
        this.$refs.addForm.validate((valid, done) => {
          if (valid) {
            this.dialogLoading = true
            let name=''
            if(this.form.filed&&this.form.filed!=''){
               name=this.tagsList.filter(el => (el.value = this.form.filed))[0].enName
            }

            let queryData = {
              ifOutputOrigin: this.form.ifOutputOrigin,
              modelId: this.parentDataList.id,
              name: this.form.name,
              tag: this.form.tag,
              logic:{
                logicOperator: 'and',
                items: [{
                type: 'item',
                filed:this.form.filed,
                filedName:name,
                filedType: 'String',
                relationOperator:this.form.relationOperator,
                valueType: 'Object',
                value:this.form.value,
              }]
              }

            }
            let url=this.pageType=='add'?recognizeAdd:recognizeEdit
            url(queryData).then(res => {
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(res.data.msg);
              }
              this.$emit('callback')
              this.form = {}
              done()
              this.showDialog = false;
              this.dialogLoading = false
            }).catch(err => {
              done()
            }).finally(() => {
              done()
              this.dialogLoading = false
            })
          }
        })
      },
      closeDialog() {
        this.form = {}
        this.searchData = []
        this.$refs.addForm.resetFields();
        this.showDialog = false
      },
    }
  }

</script>
<style lang="scss" scoped>
  .el-input-number {
    width: 100%;
  }

  .logic-box {
    display: flex;
  }

</style>
