<template>
  <div>
    <el-container>
      <!--      <CommonTree-->
      <!--        treeTitle="模板分类"-->
      <!--        :treeData="treeData"-->
      <!--        :defaultProps="defaultProps"-->
      <!--        :searchTitle="searchTitle"-->
      <!--        @getNodeClick="treeNodeClick"-->
      <!--        :isShowdig="false"-->
      <!--        :showCheckbox="false"-->
      <!--        :treeExpand="false"-->
      <!--      />-->
      <div style="width: 100%">
        <head-layout
          head-title="数据填报"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <div style="padding: 0 12px; background-color: #FFFFFF">
          <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane :label="writeName" name="write"></el-tab-pane>
          </el-tabs>
        </div>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOption"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{row}">
            <el-button
              v-if="row.status == 1 || row.status == 3"
              style="margin: 0 3px"
              type="text"
              size="medium"
              @click="rowFill(row)"
            >填报
            </el-button>
            <el-button
              v-if="row.status !== 1"
              type="text"
              size="medium"
              @click="handleFlow(row)"
            >
              流程图
            </el-button>
            <el-button
              v-if="row.status !== 1"
              type="text"
              size="medium"
              @click="headProgress(row)"
            >
              流程进度
            </el-button>
            <!--            <el-button-->
            <!--              style="margin: 0 3px"-->
            <!--              type="text"-->
            <!--              size="small"-->
            <!--              @click="rowView(row)"-->
            <!--            >查看-->
            <!--            </el-button>-->
          </template>
        </grid-layout>
      </div>
    </el-container>
    <common-dialog
      v-if="bpmnVisible"
      width="70%"
      customClass="wf-dialog"
      :dialogTitle="$t(`cip.desk.workflow.title.indexHeadVisible`)"
      :showBtn="false"
      @cancel="bpmnVisible = false">
      <wf-design ref="bpmn"
                 style="height: 60vh;"
                 :options="bpmnOption"></wf-design>
    </common-dialog>
    <common-dialog
      v-if="circulationVisible"
      width="35%"
      :dialogTitle="$t(`cip.plat.wfOps.process.title.processProgressTitle`)"
      :showBtn="false"
      @cancel="circulationVisible = false">
      <wf-flow :flow="flow"></wf-flow>
    </common-dialog>
  </div>
</template>
<script>
import {getTemplateTree, rpTaskAttributeSubmit} from "@/api/reportWrite";
import CommonTree from "@/views/components/com_tree";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue';
import {detail} from "@/api/plugin/workflow/process";
import {templateList, reportWriteList, getDetailByTaskId} from "@/api/reportTemplate";
import CommonDialog from "@/components/CommonDialog";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    WfFlow,
    CommonDialog
  },
  mixins: [exForm],
  data() {
    return {
      circulationVisible: false,
      bpmnOption: {},
      bpmnVisible: false,
      treeData: [],
      defaultProps: {
        label: "templateName",
        value: "templateCode",
      },
      searchTitle: 'templateName',
      activeName: 'write',
      taskCode: '',
      writeName: '',
      dataStatus: '1',
      acquisitionTaskListType: '',
      tableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        linklabel: "title",
        column: [
          {
            label: "任务标题",
            prop: "title",
            align: "left",
            overHidden: true
          },
          {
            label: "下发时间",
            prop: "createTime",
            width: 180,
            align: "center",
            overHidden: true
          },
          {
            label: "截至时间",
            prop: "deadlineFillingDate",
            width: 180,
            align: "center",
            overHidden: true
          },
          {
            label: "填报人",
            prop: "person",
            width: 120,
            align: "center",
            overHidden: true
          },
          {
            label: "填报单位",
            prop: "fullName",
            align: "left",
            overHidden: true
          },
          {
            label: "填报时间",
            prop: "submissionDate",
            width: 180,
            align: "center",
            overHidden: true
          },
          {
            label: "填报状态",
            prop: "dataStatus",
            align: "center",
            dataType: "number",
            width: 100,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=reportingStatus`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },
          {
            label: "审批状态",
            prop: "status",
            align: "center",
            dataType: "number",
            width: 100,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=process_status`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true
          },
        ],
      },
      tableData: [],
      tableLoading: false,
      acquisitionTaskType: 'fill',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          placeholder: "请选择模板分类",
          span: 4,
          prop: 'templateCode',
          type: 'tree',
          dicData: [],
          props: {
            label: 'templateName',
            value: 'templateCode',
          },
        },
        {
          label: '任务标题',
          prop: "title",
          span: 4,
          placeholder: '请输入任务标题'
        },
        {
          label: '下发时间',
          prop: "createTime",
          type: 'date-format',
          span: 4,
          placeholder: '请选择下发时间'
        },
        {
          label: '截至时间',
          prop: "deadlineFillingDate",
          type: 'date-format',
          span: 4,
          placeholder: '请选择截至时间'
        },
      ],
    }
  },
  mounted() {
    const taskCode = this.$route.query.taskId;
    this.taskCode = taskCode;
    this.onLoad(this.page, {});
  },
  methods: {
    // 查看流程进度
    headProgress(row) {
      const {taskId, processInstanceId} = row
      this.getTaskDetail(taskId, processInstanceId).then(() => {
        this.waiting = false // 关闭骨架屏
      })
      this.circulationVisible = true
    },
    // 查看流程图
    handleFlow(row) {
      const {taskId, processInstanceId} = row
      detail({taskId, processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data

        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }

        this.bpmnVisible = true
      })
    },
    //切换全部/待填报
    handleClick() {
      if (this.activeName == 'all') {
        this.dataStatus = '';
      } else {
        this.dataStatus = '1';
      }
      this.gridHeadSearch(this.$refs.gridHeadLayout.searchForm)
    },
    //点击左侧模板分类
    treeNodeClick(node) {
      this.templateCode = node.templateCode;
      this.gridHeadSearch(this.$refs.gridHeadLayout.searchForm)
    },
    //获取模板分类
    getTemplateTree() {
      getTemplateTree(this.acquisitionTaskListType).then((res) => {
        if (res.data.code == 200) {
          this.treeData = res.data.data
        }
      })
    },
    // 搜索填报设置
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      if (searchForm.createTime !== undefined) {
        searchForm.createTime = new Date(searchForm.createTime)
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        };
        searchForm.createTime = searchForm.createTime.toLocaleString('en-US', options)
          .replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2')
          .replace(',', ' ');
      }
      if (searchForm.deadlineFillingDate !== undefined) {
        searchForm.deadlineFillingDate = new Date(searchForm.deadlineFillingDate)
        const deadlineFillingDates = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        };
        searchForm.deadlineFillingDate = searchForm.deadlineFillingDate.toLocaleString('en-US', deadlineFillingDates)
          .replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2')
          .replace(',', ' ');
      }
      console.log(searchForm, "searchForm")
      this.onLoad(this.page, searchForm);
    },
    // 清空填报设置搜索条件
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    //获取填报列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      if (this.taskCode === undefined) {
        reportWriteList(page.currentPage, page.pageSize, this.dataStatus, this.templateCode, params).then((res) => {
          if (res.data.code == 200) {
            this.tableLoading = false;
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = res.data.data.total;
            if (this.activeName == 'write') {
              this.writeName = '待填报[ ' + res.data.data.total + ' ]'
            }
            if (this.treeData.length == 0) {
              if (res.data.data.records.length > 0) {
                this.acquisitionTaskListType = res.data.data.records[0].acquisitionTaskType
                // this.getTemplateTree();
                this.templateTree();
              }
            }
          }
        })
      } else {
        const taskCode = this.taskCode
        getDetailByTaskId(taskCode).then(res => {
          if (res.data.code == 200) {
            this.tableLoading = false;
            this.tableData.push(res.data.data);
            this.page.total = 1;
            if (this.activeName == 'write') {
              this.writeName = '待填报[ ' + 1 + ' ]'
            }
            if (this.treeData.length == 0) {
              if (res.data.data.records.length > 0) {
                this.acquisitionTaskListType = res.data.data.records[0].acquisitionTaskType
                // this.getTemplateTree();
                this.templateTree();
              }
            }
          }
        })
        this.taskCode = undefined;
      }
    },
    templateTree() {
      const column = this.findObject(this.searchColumns, "templateCode");
      getTemplateTree(this.acquisitionTaskListType).then((res) => {
        if (res.data.code == 200) {
          column.dicData = res.data.data
        }
      })
    },
    //填报
    rowFill(row) {
      var currentTimestamp = new Date().getTime(); // 当前时间
      var deadlineTimestamp = new Date(row.deadlineFillingDate).getTime(); // 截至填报时间
      if (deadlineTimestamp < currentTimestamp) {
        this.$message.warning("已超过时间不能填报！");
      } else {
        this.$router.push({
          path: `/reportManager/reportWrite/edit`,
          query: {
            id: row.id,
            templateCode: row.templateCode,
            type: 'edit',
            pageType: '',
            fullName: row.fullName,
            weaveDept: row.weaveDept,
            templateClassify: row.templateClassify
          }
        })
      }
    },
    //查看
    rowView(row) {
      this.$router.push({
        path: `/reportManager/reportWrite/view`,
        query: {
          id: row.id,
          type: 'view',
          pageType: '',
          fullName: row.fullName,
          weaveDept: row.weaveDept,
          templateClassify: row.templateClassify
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__content {
  padding: 0px !important;
  background-color: transparent;
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 315px) !important;
  max-height: calc(100vh - 315px) !important;
}
::v-deep .bjs-powered-by {
  display: none;
}
</style>
