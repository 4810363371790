<template>
  <div>
    <div class="disFlex">
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value1) || 0 }}<span>次</span>
          </div>
          <img src="@/assets/images/board/one.png" />
        </div>
        <div class="card-pd-4-26-bottom">安全事故次数</div>
      </div>
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value2) || 0 }}<span>人</span>
          </div>
          <img src="@/assets/images/board/two.png" />
        </div>
        <div class="card-pd-4-26-bottom">事故死亡人数</div>
      </div>
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value3) || 0 }}<span>人</span>
          </div>
          <img src="@/assets/images/board/three.png" />
        </div>
        <div class="card-pd-4-26-bottom">事故重伤人数</div>
      </div>
      <div class="card-pd-4-26">
        <div class="card-pd-4-26-top">
          <div class="card-pd-4-26-topLeft">
            {{ formatWithCommas(dataForm.value4) || 0 }}<span>人</span>
          </div>
          <img src="@/assets/images/board/four.png" />
        </div>
        <div class="card-pd-4-26-bottom">事故轻伤人数</div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2" style="width: calc(50% - 8px);
    background: rgb(244, 245, 246);
    padding: 0;box-shadow: none;">
        <div class="disFlex" style="margin: 0;">
          <div class="card-pd-2">
            <div class="card-pd-2-top">
              <span class="card-pd-2-top-Title"> 事故类型TOP4 </span>
              <div class="card-pd-2-top-btn">
                <img src="@/assets/images/board/five.png" />
                <img src="@/assets/images/board/six.png" />
                <img src="@/assets/images/board/seven.png" />
                <img src="@/assets/images/board/eight.png" />
              </div>
            </div>
              <div class="card-pd-2-Bottom">
                <div id="chart-twenty-three"></div>
              </div>
          </div>
          <div class="card-pd-2">
            <div class="card-pd-2-top">
              <span class="card-pd-2-top-Title"> 职业病例 </span>
              <div class="card-pd-2-top-btn">
                <img src="@/assets/images/board/five.png" />
                <img src="@/assets/images/board/six.png" />
                <img src="@/assets/images/board/seven.png" />
                <img src="@/assets/images/board/eight.png" />
              </div>
            </div>
              <div class="card-pd-2-Bottom">
                <div class="card-pd-2-BotLitte" style="width: 100%;">
                  <img class="groupImg" src="@/assets/images/board/hospital.png">
                  <div class="priceNumber">
                    {{ formatWithCommas(dataForm.value5) || 0 }}<span>人</span>
                  </div>
                  <div class="lineBox"></div>
                  <div class="priceContent">职业病例</div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 事故损失统计 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value6) || 0 }}<span>万元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">直接经济损失</div>
          </div>
          <div class="card-pd-2-BotLitte">
            <div class="priceNumber">
              {{ formatWithCommas(dataForm.value7) || 0 }}<span>万元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">间接经济损失</div>
          </div>
          <div class="card-pd-2-BotLitte">
            <div class="priceNumber">
              {{ formatWithCommas(635) }}<span>小时</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">工时损失</div>
          </div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 安全行政处罚统计 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte" style="width: calc(50% - 64px);">
            <img class="groupImg" src="@/assets/images/board/group.png">
            <div class="priceNumber">
              {{ formatWithCommas(29) }}<span>次</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">处罚次数</div>
          </div>
          <div class="card-pd-2-BotLitte" style="width: calc(50% - 64px);">
            <img class="groupImg" src="@/assets/images/board/money.png">
            <div class="priceNumber">
              {{ formatWithCommas(8271) }}<span>元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">处罚金额</div>
          </div>
        </div>
      </div>
      <div class="card-pd-2">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 环保行政处罚统计 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div class="card-pd-2-BotLitte" style="width: calc(50% - 64px);">
            <img class="groupImg" src="@/assets/images/board/group.png">
            <div class="priceNumber">
              {{ formatWithCommas(21) }}<span>次</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">处罚次数</div>
          </div>
          <div class="card-pd-2-BotLitte" style="width: calc(50% - 64px);">
            <img class="groupImg" src="@/assets/images/board/money.png">
            <div class="priceNumber">
              {{ formatWithCommas(8271) }}<span>元</span>
            </div>
            <div class="lineBox"></div>
            <div class="priceContent">处罚金额</div>
          </div>
        </div>
      </div>
    </div>
    <div class="disFlex">
      <div class="card-pd-2" style="width: 100%">
        <div class="card-pd-2-top">
          <span class="card-pd-2-top-Title"> 预警逐月次数统计 </span>
          <div class="card-pd-2-top-btn">
            <img src="@/assets/images/board/five.png" />
            <img src="@/assets/images/board/six.png" />
            <img src="@/assets/images/board/seven.png" />
            <img src="@/assets/images/board/eight.png" />
          </div>
        </div>
        <div class="card-pd-2-Bottom">
          <div id="chart-twenty-four"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import slider from "@/views/business/board/components/slider";
import {appIndex} from "@/api/user";
import {mapGetters} from "vuex";
export default {
  components: { slider },
  data() {
    return {
      year: '',
      month: '',
      dataForm: {
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
        value7: '',
      }
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  created() {
    this.year = new Date().getFullYear();
  },
  mounted() {
    setTimeout(() => {
      this.getChartTwentyThree();
      this.getChartTwentyFour();
    }, 200);
    this.getIndexData();
  },
  methods: {
    formatWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getIndexData() {
      // 安全事故次数
      appIndex({
        indexCode: 'ACCIDENT_CNT_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm.value1 = res.data.data.records[0]._value;
        }
      })

      // 事故死亡人数
      appIndex({
        indexCode: 'ACCIDENT_DEATH_CNT_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm.value2 = res.data.data.records[0]._value;
        }
      })

      // 事故重伤人数
      appIndex({
        indexCode: 'ACCIDENT_SERIOUS_INJURY_CNT_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm.value3 = res.data.data.records[0]._value;
        }
      })

      // 事故轻伤人数
      appIndex({
        indexCode: 'ACCIDENT_MINOR_INJURY_CNT_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm.value4 = res.data.data.records[0]._value;
        }
      })

      // 职业病例
      appIndex({
        indexCode: 'ACCIDENT_MINOR_INJURY_CNT_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm.value5 = res.data.data.records[0]._value;
        }
      })

      // 直接经济损失
      appIndex({
        indexCode: 'ACCIDENT_DIRECT_ECO_LOSS_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm.value6 = res.data.data.records[0]._value;
        }
      })

      // 间接经济损失
      appIndex({
        indexCode: 'ACCIDENT_INDIRECT_ECO_LOSS_Y',
        _year: this.year,
        companyId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm.value7 = res.data.data.records[0]._value;
        }
      })
    },
    getChartTwentyThree() {
      var myChart = this.$echarts.init(
        document.getElementById("chart-twenty-three")
      );
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        grid: {
          // 让图表占满容器
          top: "00px",
          left: "60px",
          right: "40px",
          bottom: "25px",
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "rgba(134, 144, 156, 1)",
          },
          interval: 50,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "category",
          axisLabel: {
            color: "rgba(78, 89, 105, 0.6)",
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: ["#E8E8E8"],
            },
          },
          data: ["物体打击", "机械伤害", "起重伤害", "电"],
        },
        series: [
          {
            name: "次数",
            type: "bar",
            stack: "total",
            color: "#FB3636",
            barWidth: 25,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: function () {
                  return {
                    type: "linear",
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#FB3636", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FF8181", // 100% 处的颜色
                      },
                    ],
                  };
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " 次";
              },
            },
            data: [320, 302, 301, 334, 390],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getChartTwentyFour() {
      var myChart = this.$echarts.init(
        document.getElementById("chart-twenty-four")
      );
      var option = {
        color: ["#FF7410", "#C7C7C8"],
        backgroundColor: "#FFFFFF",
        grid: {
          top: "40px",
          left: "10px",
          right: "0",
          bottom: "0",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            data: ["1月", "2月", "3月", "4月", "5月", "6月"],
          },
        ],
        yAxis: [
          {
            type: "value",
            nameTextStyle: {
              //y轴上方单位的颜色
              color: "rgba(120, 122, 128, 1)",
            },
            name: "单位：次",
          },
        ],
        series: [
          {
            type: "line",
            smooth: true,
            symbol: "none",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(254, 116, 116, 0.3)", // 0% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(255, 244, 243, 0.6)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            lineStyle: {
              color: "rgba(251, 54, 54, 1)",
            },
            emphasis: {
              focus: "series",
            },
            data: [32, 65, 95, 22, 185, 122],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.disFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.card-pd-4-26 {
  width: calc(25% - 64px);
  padding: 26px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-4-26-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-4-26-topLeft {
  line-height: 61px;
  height: 61px;
  color: #15161a;
  font-size: 42px;
  font-weight: bold;
}

.card-pd-4-26-topLeft span {
  color: #484a4d;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
}

.card-pd-4-26-top img {
  width: 61px;
  height: 61px;
  flex-shrink: 0;
}

.card-pd-4-26-bottom {
  width: 100%;
  margin-top: 48px;
  color: #15161a;
  font-size: 18px;
}
.card-pd-2 {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}

.card-pd-2-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-pd-2-top-Title {
  color: #15161a;
  font-size: 21px;
  font-weight: bold;
}

.card-pd-2-top-btn {
  display: flex;
  justify-content: flex-end;
}

.card-pd-2-top-btn img {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}

.card-pd-2-Bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.card-pd-2-BotLitte {
  width: calc(33.3% - 64px);
  border: 1px solid #ededed;
  border-radius: 10px;
  padding: 40px 26px;
  position: relative;
}
.groupImg {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 26px;
  right: 26px;
}
.priceNumber {
  width: 100%;
  color: #15161a;
  font-size: 37px;
  font-weight: bold;
}

.priceNumber span {
  font-weight: 400;
  color: #484a4d;
  font-size: 18px;
  margin-left: 8px;
}

.lineBox {
  width: 40px;
  height: 3px;
  background-color: #fb3636;
  margin: 21px 0 26px;
}

.priceContent {
  width: 100%;
  color: #15161a;
  font-size: 18px;
}

#chart-twenty-three {
  width: 100%;
  height: 210px;
}

#chart-twenty-four {
  width: 100%;
  height: 400px;
}
</style>
