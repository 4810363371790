import {isNumberStr} from '@/views/util'

export default {
    inject: ["getShowType"],
    data() {
        return {}
    },
    computed: {
        showType() {
            return this.getShowType()
        }
    },
    methods: {
        onDefaultValueInput(str) {
            if (Array.isArray(this.activeData.__config__.defaultValue)) {
                // 数组
                this.$set(
                    this.activeData.__config__,
                    'defaultValue',
                    str.split(',').map(val => (isNumberStr(val) ? +val : val))
                )
            } else if (['true', 'false'].indexOf(str) > -1) {
                // 布尔
                this.$set(this.activeData.__config__, 'defaultValue', JSON.parse(str))
            } else {
                // 字符串和数字
                this.$set(
                    this.activeData.__config__,
                    'defaultValue',
                    isNumberStr(str) ? +str : str
                )
            }
        },
        setDefaultValue(val) {
            if (Array.isArray(val)) {
                return val.join(',')
            }
            // if (['string', 'number'].indexOf(typeof val) > -1) {
            //   return val
            // }
            if (typeof val === 'boolean') {
                return `${val}`
            }
            return val
        },
        addReg() {
            this.activeData.__config__.regList.push({
                pattern: '',
                message: ''
            })
        }
    }
}