var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "printData-container app-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "prtBtn",
          attrs: { icon: "el-icon-printer" },
          on: {
            click: function ($event) {
              return _vm.print()
            },
          },
        },
        [_vm._v("打印")]
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "报价单子", name: "offer" } },
            [_c("offer", { ref: "offer" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "水电费用", name: "bill" } },
            [_c("bill", { ref: "bill" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "员工档案", name: "record" } },
            [_c("record", { ref: "record" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "入库通知", name: "storage" } },
            [_c("storage", { ref: "storage" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }