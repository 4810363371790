var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.type == "add"
            ? "新增告知卡"
            : _vm.type == "view"
            ? "查看告知卡详情"
            : "编辑告知卡",
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.saveLoading,
              expression: "saveLoading",
            },
          ],
          staticClass: "main-content",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: true,
                "label-width": "100px",
                rules: _vm.rules,
                disabled: _vm.type == "view",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "告知卡名称", prop: "cardName" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入告知卡名称",
                      maxlength: 255,
                    },
                    model: {
                      value: _vm.form.cardName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cardName", $$v)
                      },
                      expression: "form.cardName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { ref: "capture", staticStyle: { padding: "4px" } }, [
            _c("div", { staticClass: "table-box" }, [
              _c("div", { staticClass: "line-1 line" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label required" }, [
                    _vm._v("风险源"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value need-border",
                      staticStyle: {
                        height: "calc(100% - 2px)",
                        "padding-top": "1px",
                      },
                    },
                    [
                      _vm.isSave
                        ? _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: {
                                placeholder: "请输入内容",
                                maxlength: 255,
                              },
                              model: {
                                value: _vm.form.sourceName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sourceName", $$v)
                                },
                                expression: "form.sourceName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.selectFXY },
                                slot: "append",
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                height: "100%",
                                padding: "5px 15px",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.form.sourceName) +
                                  "\n              "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label required" }, [
                    _vm._v("风险等级"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: {
                        height: "calc(100% - 2px)",
                        "padding-top": "1px",
                      },
                    },
                    [
                      _vm.isSave
                        ? _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.riskLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "riskLevel", $$v)
                                },
                                expression: "form.riskLevel",
                              },
                            },
                            _vm._l(_vm.D, function (item) {
                              return _c("el-option", {
                                key: item.levelCode,
                                attrs: {
                                  label: item.levelName,
                                  value: item.levelName,
                                },
                              })
                            }),
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                height: "100%",
                                padding: "5px 15px",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.form.riskLevel) +
                                  "\n              "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label required" }, [
                    _vm._v("事故类型"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: {
                        "border-right": "none",
                        height: "calc(100% - 2px)",
                        "padding-top": "1px",
                      },
                    },
                    [
                      _vm.isSave
                        ? _c("el-input", {
                            attrs: {
                              placeholder: "请输入内容",
                              disabled: _vm.type == "view",
                              maxlength: 255,
                            },
                            model: {
                              value: _vm.form.event,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "event", $$v)
                              },
                              expression: "form.event",
                            },
                          })
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                height: "100%",
                                padding: "5px 15px",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.form.event) +
                                  "\n              "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "line-2 line" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label not-required" }, [
                    _vm._v("风险管控层级"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: {
                        height: "calc(100% - 2px)",
                        "padding-top": "1px",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.controlLevel,
                            callback: function ($$v) {
                              _vm.controlLevel = $$v
                            },
                            expression: "controlLevel",
                          },
                        },
                        _vm._l(_vm.controlLevelArr, function (item) {
                          return _c("el-checkbox", {
                            key: item.dictKey,
                            staticStyle: { margin: "10px" },
                            attrs: {
                              label: item.dictValue,
                              disabled: _vm.type == "view",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label not-required" }, [
                    _vm._v("责任人"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: {
                        height: "calc(100% - 2px)",
                        "padding-top": "1px",
                      },
                    },
                    [
                      _vm.isSave
                        ? _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入内容",
                              maxlength: "64",
                            },
                            model: {
                              value: _vm.form.respNickName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "respNickName", $$v)
                              },
                              expression: "form.respNickName",
                            },
                          })
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                height: "100%",
                                padding: "5px 15px",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.form.respNickName) +
                                  "\n              "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label not-required" }, [
                    _vm._v("联系方式"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: {
                        "border-right": "none",
                        height: "calc(100% - 2px)",
                        "padding-top": "1px",
                      },
                    },
                    [
                      _vm.isSave
                        ? _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入内容",
                              maxlength: "64",
                              disabled: _vm.type == "view",
                            },
                            model: {
                              value: _vm.form.respTel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "respTel", $$v)
                              },
                              expression: "form.respTel",
                            },
                          })
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                height: "100%",
                                padding: "5px 15px",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.form.respTel) +
                                  "\n              "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "line-3 line" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label required" }, [
                    _vm._v("风险描述"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: {
                        height: "calc(100% - 2px)",
                        "padding-top": "1px",
                      },
                    },
                    [
                      _vm.isSave
                        ? _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入内容",
                              rows: 5,
                              autosize: { minRows: 7 },
                              disabled: _vm.type == "view",
                              maxlength: 255,
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.riskName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "riskName", $$v)
                              },
                              expression: "form.riskName",
                            },
                          })
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                height: "100%",
                                padding: "5px 15px",
                                color: "#606266",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.form.riskName) +
                                  "\n              "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label required" }, [
                    _vm._v("主要控制措施"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: {
                        "border-right": "none",
                        height: "calc(100% - 2px)",
                        "padding-top": "1px",
                      },
                    },
                    [
                      _vm.isSave
                        ? _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入内容",
                              rows: 5,
                              autosize: { minRows: 7 },
                              disabled: _vm.type == "view",
                              maxlength: 500,
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.treatment,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "treatment", $$v)
                              },
                              expression: "form.treatment",
                            },
                          })
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                height: "100%",
                                padding: "5px 15px",
                                color: "#606266",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.form.treatment) +
                                  "\n              "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "line-4 line",
                  staticStyle: {
                    "border-bottom": "1px solid",
                    height: "calc(100% - 2px)",
                    "padding-top": "1px",
                  },
                },
                [
                  _c("div", { staticClass: "item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "label not-required",
                        staticStyle: { "border-bottom": "none" },
                      },
                      [_vm._v("\n              应急处置措施\n            ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "value",
                        staticStyle: { "border-bottom": "none" },
                      },
                      [
                        _vm.isSave
                          ? _c("el-input", {
                              attrs: {
                                type: "textarea",
                                placeholder: "请输入内容",
                                rows: 5,
                                autosize: { minRows: 7 },
                                disabled: _vm.type == "view",
                                maxlength: 255,
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.erTreatment,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "erTreatment", $$v)
                                },
                                expression: "form.erTreatment",
                              },
                            })
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "100%",
                                  padding: "5px 15px",
                                  color: "#606266",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.form.erTreatment) +
                                    "\n              "
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      staticStyle: { "border-bottom": "none" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "label not-required",
                          staticStyle: { "border-bottom": "none" },
                        },
                        [_vm._v("\n              安全标志\n            ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "value",
                          staticStyle: { border: "none" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mark-box" },
                            [
                              _c(
                                "div",
                                { staticClass: "mark-list" },
                                _vm._l(_vm.siList, function (cItem, ci) {
                                  return _c(
                                    "div",
                                    {
                                      key: ci,
                                      staticClass: "div-item",
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px",
                                        margin: "10px 10px 0 0",
                                        display: "inline-block",
                                        position: "relative",
                                      },
                                    },
                                    [
                                      _vm.isSave
                                        ? _c("i", {
                                            staticClass: "el-icon-remove",
                                            staticStyle: {
                                              "font-size": "20px",
                                              color: "#ff0000",
                                              "z-index": "100",
                                              position: "absolute",
                                              right: "-4px",
                                              top: "-4px",
                                              cursor: "pointer",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeSiList(ci)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _c("img", {
                                        staticClass: "img-item",
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: {
                                          crossOrigin: "anonymous",
                                          src: cItem.picUrl,
                                        },
                                      }),
                                    ]
                                  )
                                }),
                                0
                              ),
                              _vm.isSave
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.safeClick },
                                    },
                                    [_vm._v("选择")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "line-5 line" }, [
                _c("div", { staticClass: "item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "label not-required",
                      staticStyle: { "border-bottom": "none" },
                    },
                    [_vm._v("\n              火警电话\n            ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: { "border-bottom": "none" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入内容",
                          disabled: _vm.type == "view",
                          maxlength: 64,
                        },
                        model: {
                          value: _vm.form.fireTel,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fireTel", $$v)
                          },
                          expression: "form.fireTel",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "label not-required",
                      staticStyle: { "border-bottom": "none" },
                    },
                    [_vm._v("\n              急救电话\n            ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: { "border-bottom": "none" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入内容",
                          disabled: _vm.type == "view",
                          maxlength: 64,
                        },
                        model: {
                          value: _vm.form.medTel,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "medTel", $$v)
                          },
                          expression: "form.medTel",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "label not-required",
                      staticStyle: { "border-bottom": "none" },
                    },
                    [_vm._v("\n              公司应急电话\n            ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "value", staticStyle: { border: "none" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入内容",
                          disabled: _vm.type == "view",
                          maxlength: 64,
                        },
                        model: {
                          value: _vm.form.comTel,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "comTel", $$v)
                          },
                          expression: "form.comTel",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.saveLoading,
                  expression: "saveLoading",
                },
              ],
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + "\n    "
              ),
            ]
          ),
          _vm.type != "view"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.saveLoading,
                      expression: "saveLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("\n      保存\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("Reference", {
        ref: "reference",
        on: { getSelection: _vm.getSelection },
      }),
      _vm.safeShow
        ? _c(
            "el-dialog",
            {
              staticClass: "qmDialog",
              attrs: {
                title: "安全标识",
                "modal-append-to-body": true,
                visible: _vm.safeShow,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.safeShow = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { height: "500px" } },
                [
                  _c("SafeSelect", {
                    on: {
                      "select-data": _vm.selectData,
                      "close-dialog": _vm.closeSafe,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }