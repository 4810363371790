var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "disFlex" }, [
      _c("div", { staticClass: "card-pd-4-26" }, [
        _c("div", { staticClass: "card-pd-4-26-top" }, [
          _c("div", { staticClass: "card-pd-4-26-topLeft" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.formatWithCommas(_vm.dataForm.value1) || 0)
            ),
            _c("span", [_vm._v("次")]),
          ]),
          _c("img", {
            attrs: { src: require("@/assets/images/board/one.png") },
          }),
        ]),
        _c("div", { staticClass: "card-pd-4-26-bottom" }, [
          _vm._v("安全事故次数"),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-4-26" }, [
        _c("div", { staticClass: "card-pd-4-26-top" }, [
          _c("div", { staticClass: "card-pd-4-26-topLeft" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.formatWithCommas(_vm.dataForm.value2) || 0)
            ),
            _c("span", [_vm._v("人")]),
          ]),
          _c("img", {
            attrs: { src: require("@/assets/images/board/two.png") },
          }),
        ]),
        _c("div", { staticClass: "card-pd-4-26-bottom" }, [
          _vm._v("事故死亡人数"),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-4-26" }, [
        _c("div", { staticClass: "card-pd-4-26-top" }, [
          _c("div", { staticClass: "card-pd-4-26-topLeft" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.formatWithCommas(_vm.dataForm.value3) || 0)
            ),
            _c("span", [_vm._v("人")]),
          ]),
          _c("img", {
            attrs: { src: require("@/assets/images/board/three.png") },
          }),
        ]),
        _c("div", { staticClass: "card-pd-4-26-bottom" }, [
          _vm._v("事故重伤人数"),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-4-26" }, [
        _c("div", { staticClass: "card-pd-4-26-top" }, [
          _c("div", { staticClass: "card-pd-4-26-topLeft" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.formatWithCommas(_vm.dataForm.value4) || 0)
            ),
            _c("span", [_vm._v("人")]),
          ]),
          _c("img", {
            attrs: { src: require("@/assets/images/board/four.png") },
          }),
        ]),
        _c("div", { staticClass: "card-pd-4-26-bottom" }, [
          _vm._v("事故轻伤人数"),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "disFlex" }, [
      _c(
        "div",
        {
          staticClass: "card-pd-2",
          staticStyle: {
            width: "calc(50% - 8px)",
            background: "rgb(244, 245, 246)",
            padding: "0",
            "box-shadow": "none",
          },
        },
        [
          _c("div", { staticClass: "disFlex", staticStyle: { margin: "0" } }, [
            _vm._m(0),
            _c("div", { staticClass: "card-pd-2" }, [
              _vm._m(1),
              _c("div", { staticClass: "card-pd-2-Bottom" }, [
                _c(
                  "div",
                  {
                    staticClass: "card-pd-2-BotLitte",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("img", {
                      staticClass: "groupImg",
                      attrs: {
                        src: require("@/assets/images/board/hospital.png"),
                      },
                    }),
                    _c("div", { staticClass: "priceNumber" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.formatWithCommas(_vm.dataForm.value5) || 0)
                      ),
                      _c("span", [_vm._v("人")]),
                    ]),
                    _c("div", { staticClass: "lineBox" }),
                    _c("div", { staticClass: "priceContent" }, [
                      _vm._v("职业病例"),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "card-pd-2" }, [
        _vm._m(2),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c("div", { staticClass: "card-pd-2-BotLitte" }, [
            _c("div", { staticClass: "priceNumber" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value6) || 0)
              ),
              _c("span", [_vm._v("万元")]),
            ]),
            _c("div", { staticClass: "lineBox" }),
            _c("div", { staticClass: "priceContent" }, [
              _vm._v("直接经济损失"),
            ]),
          ]),
          _c("div", { staticClass: "card-pd-2-BotLitte" }, [
            _c("div", { staticClass: "priceNumber" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value7) || 0)
              ),
              _c("span", [_vm._v("万元")]),
            ]),
            _c("div", { staticClass: "lineBox" }),
            _c("div", { staticClass: "priceContent" }, [
              _vm._v("间接经济损失"),
            ]),
          ]),
          _c("div", { staticClass: "card-pd-2-BotLitte" }, [
            _c("div", { staticClass: "priceNumber" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.formatWithCommas(_vm.dataForm.value8) || 0)
              ),
              _c("span", [_vm._v("小时")]),
            ]),
            _c("div", { staticClass: "lineBox" }),
            _c("div", { staticClass: "priceContent" }, [_vm._v("工时损失")]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "disFlex" }, [
      _c("div", { staticClass: "card-pd-2" }, [
        _vm._m(3),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c(
            "div",
            {
              staticClass: "card-pd-2-BotLitte",
              staticStyle: { width: "calc(50% - 64px)" },
            },
            [
              _c("img", {
                staticClass: "groupImg",
                attrs: { src: require("@/assets/images/board/group.png") },
              }),
              _c("div", { staticClass: "priceNumber" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatWithCommas(_vm.dataForm.value9) || 0)
                ),
                _c("span", [_vm._v("次")]),
              ]),
              _c("div", { staticClass: "lineBox" }),
              _c("div", { staticClass: "priceContent" }, [_vm._v("处罚次数")]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card-pd-2-BotLitte",
              staticStyle: { width: "calc(50% - 64px)" },
            },
            [
              _c("img", {
                staticClass: "groupImg",
                attrs: { src: require("@/assets/images/board/money.png") },
              }),
              _c("div", { staticClass: "priceNumber" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatWithCommas(_vm.dataForm.value10) || 0)
                ),
                _c("span", [_vm._v("元")]),
              ]),
              _c("div", { staticClass: "lineBox" }),
              _c("div", { staticClass: "priceContent" }, [_vm._v("处罚金额")]),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-2" }, [
        _vm._m(4),
        _c("div", { staticClass: "card-pd-2-Bottom" }, [
          _c(
            "div",
            {
              staticClass: "card-pd-2-BotLitte",
              staticStyle: { width: "calc(50% - 64px)" },
            },
            [
              _c("img", {
                staticClass: "groupImg",
                attrs: { src: require("@/assets/images/board/group.png") },
              }),
              _c("div", { staticClass: "priceNumber" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatWithCommas(_vm.dataForm.value11) || 0)
                ),
                _c("span", [_vm._v("次")]),
              ]),
              _c("div", { staticClass: "lineBox" }),
              _c("div", { staticClass: "priceContent" }, [_vm._v("处罚次数")]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card-pd-2-BotLitte",
              staticStyle: { width: "calc(50% - 64px)" },
            },
            [
              _c("img", {
                staticClass: "groupImg",
                attrs: { src: require("@/assets/images/board/money.png") },
              }),
              _c("div", { staticClass: "priceNumber" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatWithCommas(_vm.dataForm.value12) || 0)
                ),
                _c("span", [_vm._v("元")]),
              ]),
              _c("div", { staticClass: "lineBox" }),
              _c("div", { staticClass: "priceContent" }, [_vm._v("处罚金额")]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2" }, [
      _c("div", { staticClass: "card-pd-2-top" }, [
        _c("span", { staticClass: "card-pd-2-top-Title" }, [
          _vm._v(" 事故类型TOP4 "),
        ]),
      ]),
      _c("div", { staticClass: "card-pd-2-Bottom" }, [
        _c("div", { attrs: { id: "chart-twenty-three" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v(" 职业病例 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v(" 事故损失统计 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v(" 安全行政处罚统计 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-pd-2-top" }, [
      _c("span", { staticClass: "card-pd-2-top-Title" }, [
        _vm._v(" 环保行政处罚统计 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }