<template>
  <div style="position: relative">
    <div>
      <!-- 相关方人员 -->
      <head-layout
        head-title=""
        :head-btn-options="headBtnOptions"
        @head-add="handleAdd"
        @head-import="handleImport"
        @head-export="handleExport"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template slot="birthday" slot-scope="{ row }">
          <span>
            {{ row.idCoding && row.idCoding != "" ? getAge(row.idCoding) : "" }}
          </span>
        </template>
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowUpdate(row)"
            >编辑
          </el-button>
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowDel(row)"
            >删除
          </el-button>
        </template>
      </grid-layout>
    </div>
    <addThirduser
      ref="addThirduser"
      @callback="onLoad(page, {})"
    ></addThirduser>
    <el-dialog
      title="相关方人员导入"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          v-model="excelOption.excelForm"
          :upload-after="uploadAfter"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{ $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList, getDetail, add, update, remove } from "@/api/safe/thirduser";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import addThirduser from "./addThirduser";
import { getAgeFromIdCard } from "@/util/util";
import { exportBlob } from "@/api/common";
import { dateNow } from "@/util/date";
import { getToken } from "@/util/auth";
import { downloadXls } from "@/util/util";

export default {
  components: {
    addThirduser,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      form: {},
      query: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        linklabel: "userName",
        column: [
          {
            label: "姓名",
            prop: "userName",
            width: 200,
            align: "center",
          },
          {
            label: "身份证号码",
            prop: "idCoding",
            width: 240,
            align: "center",
          },
          {
            label: "性别",
            prop: "sex",
            width: 50,
            align: "center",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
          },
          {
            label: "年龄",
            prop: "birthday",
            width: 60,
            align: "center",
            slot: true,
          },
          {
            label: "籍贯",
            prop: "domicile",
            align: "center",
            overHidden: true,
          },
          {
            label: "工种",
            prop: "post",
            width: 100,
            align: "center",
            overHidden: true,
          },
          {
            label: "启用",
            prop: "isUsed",
            width: 100,
            align: "center",
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          },
          {
            label: "维护日期",
            prop: "updateTime",
            width: 200,
            align: "center",
            overHidden: true,
          },
        ],
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "导入",
          emit: "head-import",
          type: "button",
          icon: "",
          btnOptType: "import",
        },
        {
          label: "导出",
          emit: "head-export",
          type: "button",
          icon: "",
          btnOptType: "export",
        },
      ],
      searchColumns: [
        {
          label: "名称",
          prop: "userName",
          span: 4,
          placeholder: "姓名或身份证号",
        },
        {
          label: "人员状态",
          prop: "isUsed",
          span: 4,
          type: "select",
          placeholder: "请选择人员状态",
          dataType: "number",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
        },
        {
          label: "维护日期",
          prop: "updateTime",
          span: 4,
          type: "date",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd HH:mm:ss",
          placeholder: "请选择维护日期",
        },
      ],
      tableData: [],
      excelBox: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        excelForm: {},
        column: [
          {
            label: "数据导入",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            action: "/api/sinoma-hse-prj/thirduser/import",
          },
          // {
          //   label: this.$t("cip.plat.sys.user.field.isCovered"),
          //   prop: "isCovered",
          //   type: "select",
          //   align: "center",
          //   width: 80,
          //   dataType: 'number',
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey"
          //   },
          //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yesNo",
          //   value: 0,
          //   slot: true,
          //   rules: [{
          //     required: true,
          //     message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.user.field.yes") + this.$t("cip.plat.sys.user.field.no") + this.$t("cip.plat.sys.user.field.isCovered"),
          //     trigger: "blur"
          //   }]
          // },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.thirduser_add, false),
        viewBtn: this.vaildData(this.permission.thirduser_view, false),
        delBtn: this.vaildData(this.permission.thirduser_delete, false),
        editBtn: this.vaildData(this.permission.thirduser_edit, false),
      };
    },
  },
  watch: {
    "excelForm.isCovered"() {
      if (this.excelForm.isCovered !== "") {
        const column = this.findObject(this.excelOption.column, "excelFile");
        column.action = `/api/sinoma-hse-prj/thirduser/import?isCovered=${this.excelForm.isCovered}`;
      }
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    handleAdd() {
      this.$refs.addThirduser.init("add");
    },
    rowUpdate(row) {
      this.$refs.addThirduser.init("edit", row);
    },
    //查看
    rowView(row) {
      this.$refs.addThirduser.init("view", row);
    },
    getAge(data) {
      return getAgeFromIdCard(data);
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
    },
    // 导入
    handleImport() {
      this.excelBox = true;
      this.excelOption.excelForm.excelFile = [];
    },
    uploadAfter(res, done, loading, column) {
      window.console.log(column);
      this.excelBox = false;
      this.onLoad(this.page);
      this.excelOption.excelForm.excelFile = [];
      done();
    },
    handleTemplate() {
      exportBlob(
        `/api/sinoma-hse-prj/thirduser/exportTemplate?${
          this.website.tokenHeader
        }=${getToken()}`
      ).then((res) => {
        downloadXls(res.data, "相关方人员模板" + ".xlsx");
      });
    },
    // 导出
    handleExport() {
      this.$confirm("是否导出相关方人员数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        exportBlob(
          `/api/sinoma-hse-prj/thirduser/export?userName=${
            this.$refs.gridHeadLayout.searchForm.userName
              ? this.$refs.gridHeadLayout.searchForm.userName
              : ""
          }&isUsed=${
            this.$refs.gridHeadLayout.searchForm.isUsed
              ? this.$refs.gridHeadLayout.searchForm.isUsed
              : ""
          }&updateTime=${
            this.$refs.gridHeadLayout.searchForm.updateTime
              ? this.$refs.gridHeadLayout.searchForm.updateTime
              : ""
          }&${this.website.tokenHeader}=${getToken()}`
        ).then((res) => {
          downloadXls(res.data, `相关方人员${dateNow()}.xlsx`);
        });
      });
    },
  },
};
</script>

<style>
</style>
