<template>
  <div style="position: relative" class="content">
    <el-container>
      <!-- <CommonTree
        ref="commonTreeCore"
        treeTitle="组织架构"
        :searchTitle="searchTitle"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @getNodeClick="handleNodeClick"
        :isShowdig="false"
        :showCheckbox="false"
        node-key="id"
        :isZoom="true"
        :show="show"
        @showChange="showChange"
      /> -->
      <CommonTreeNew
        ref="commonTree"
        treeTitle="组织架构"
        :defaultProps="defaultProps"
        :showCheckbox="false"
        @getNodeClick="handleNodeClick"
        :urlParmas="{
          tenantId: '',
          deptCategory: '2',
          parentId: userInfo.dept_id,
        }"
        node-key="id"
        @getTreeData="commonTreeData"
        :isShowdig="false"
        :isZoom="true"
        :show="show"
        @showChange="showChange"
      ></CommonTreeNew>
      <el-main style="height: 100%">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          type="border-card"
        >
          <el-tab-pane label="企业证照" name="license"></el-tab-pane>
          <el-tab-pane label="企业安标" name="declaration"></el-tab-pane>
        </el-tabs>
        <div v-if="activeName == 'license'" class="license">
          <head-layout
            head-title="企业证照"
            :head-btn-options="headBtnOptions"
            @head-upload="handleUpload"
          />
          <grid-head-layout
            ref="gridHeadLayout"
            v-if="activeName == 'license'"
            key="license"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            ref="gridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            :editType="inline"
            @page-current-change="handleCurrentChange"
            @page-size-change="handleSizeChange"
            @page-refresh-change="onLoad"
            @row-view="rowView"
            @row-download="rowDownload"
            @row-edit="rowEdit"
            @row-remove="rowRemove"
            @row-setting="rowSetting"
          >
            <template #fileName="{ row, index }">
              <el-link type="primary" @click="rowView(row, index)">{{
                getFileName(row.fileName)
              }}</el-link>
            </template>
          </grid-layout>
        </div>
        <div v-if="activeName == 'declaration'" class="declaration">
          <head-layout
            head-title="企业安标"
            :head-btn-options="orgheadBtnOptions"
            @head-add="orghandleHeadAdd"
          />
          <grid-head-layout
            ref="gridHeadLayout1"
            v-if="activeName == 'declaration'"
            key="declaration"
            @grid-head-search="orggridHeadSearch"
            @grid-head-empty="orggridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            ref="gridLayOut"
            :grid-row-btn="orggridRowBtn"
            :table-options="orgtableOptions"
            :table-data="orgtableData"
            :table-loading="orgtableLoading"
            :data-total="orgpage.total"
            :page="orgpage"
            @grid-row-detail-click="orgrowView"
            @page-current-change="orghandleCurrentChange"
            @page-size-change="orghandleSizeChange"
            @page-refresh-change="orgonLoad"
            @row-edit="orgrowEdit"
            @row-del="orgrowRemove"
          >
            <template #meetingName="{ row }">
              <el-link type="primary" @click="orgrowView(row)">{{
                row.meetingName
              }}</el-link>
            </template>
          </grid-layout>
        </div>
      </el-main>
    </el-container>
    <el-dialog title="设置提醒" :visible.sync="dialogVisible">
      <el-form :model="remaindForm" label-width="120px">
        <el-form-item label="提前提醒天数">
          <el-input-number
            v-model="remaindForm.dateRemind"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="通知人">
          <el-input
            v-model="remaindForm.noticeUserName"
            ref="remaindFormUser"
            @focus="changeUser"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleCancel()"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
    <el-dialog title="证件查看" :visible.sync="imageDiavlog">
      <pdf :src="fileUrl.src" v-if="fileUrl.type == 'pdf'"></pdf>
      <el-image :src="fileUrl.src" v-else></el-image>
    </el-dialog>
    <el-dialog
      title="人员选择"
      :visible.sync="userVisible"
      width="80%"
      top="8vh"
    >
      <UserDetpDialog ref="UserDetpDialog" @select-data="selectData" />
    </el-dialog>
    <el-dialog
      :title="type == 'add' ? '新增' : type == 'view' ? '查看申报详情' : '编辑'"
      :close-on-click-modal="false"
      :visible.sync="visible"
      lock-scroll
      width="1000px"
    >
      <div class="main-content" v-loading="saveLoading">
        <el-form
          :model="form"
          ref="form"
          label-width="100px"
          :rules="rules"
          :disabled="type == 'view'"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="企业名称" prop="orgName">
                <!-- <el-input
                  v-model="form.orgName"
                  placeholder="请输入企业名称"
                  :maxlength="255"
                  disabled
                ></el-input> -->
                <div>{{ form.orgName }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业类型" prop="orgTypeName">
                <el-select
                  v-model="orgType"
                  placeholder="请选择企业类型"
                  value-key="dictKey"
                  @change="orgTypeChange"
                >
                  <el-option
                    v-for="item in orgTypes"
                    :label="item.dictValue"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="级别" prop="safetyLevelName">
                <el-select
                  v-model="level"
                  placeholder="请选择级别"
                  value-key="dictKey"
                  @change="levelChange"
                >
                  <el-option
                    v-for="item in levels"
                    :label="item.dictValue"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="取证日期" prop="safetyDate">
                <el-date-picker
                  v-model="form.safetyDate"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="取证日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  type="textarea"
                  placeholder="请输入备注"
                  v-model="form.remark"
                  maxlength="255"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button
          size="small"
          @click="visible = false"
          v-loading="saveLoading"
        >
          取消
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="orghandleSave"
          v-loading="saveLoading"
          v-if="type != 'view'"
        >
          保存
        </el-button>
      </span>
    </el-dialog>
    <cedForm ref="cedForm" @callback="callback" />
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
// import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  enterprisePage,
  enterpriseSubmit,
  enterpriseRemove,
} from "@/api/responsibility/licenceEnterprise";
import {
  getList,
  addList,
  removeList,
  getDetail,
} from "@/api/setting/safetyProduction/index";
import { getDictionaryCode } from "@/api/system/dictbiz";
import { mapGetters } from "vuex";
import { downloadFileBlob } from "@/util/util";
import { dateFormat } from "@/util/date";
import { getDeptTree } from "@/api/system/dept";
import pdf from "@/components/pdf/pdf.vue";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import cedForm from "./cedForm.vue";
export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
    pdf,
    UserDetpDialog,
    cedForm,
  },

  data() {
    return {
      dateFormat,
      nodeData: {},
      treeData: [],
      searchTitle: "title",
      defaultProps: {
        children: "children",
        label: "title",
      },
      searchColumns: [
        {
          prop: "fileName",
          placeholder: "证件名称",
          span: 4,
        },
        {
          prop: "certificateType_search",
          placeholder: "请选择类型",
          span: 4,
          search: true,
          type: "cascader",
          dicUrl:
            "/api/sinoma-system/dict-biz/dictionary-tree?code=certificate_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          checkStrictly: true,
          // showAllLevels: false,
          // emitPath: false
        },
        {
          prop: "startDate",
          placeholder: "发证日期",
          span: 4,
          type: "date-format",
        },
        {
          prop: "certificateState",
          placeholder: "证件状态",
          span: 4,
          search: true,
          type: "select",
          dicUrl:
            "/api/sinoma-system/dict-biz/dictionary?code=certificate_state",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      dialogVisible: false,
      remaindForm: {
        dateRemind: 1,
        noticeUserId: "",
        noticeUserName: "",
      },
      dialogLoading: false,
      settingId: "",
      headers: { Authorization: this.$store.getters.token },
      // operition: false,
      fileUrl: {},
      imageDiavlog: false,
      userVisible: false,
      show: false,
      activeName: "license",
      form: {
        orgName: "",
        // safetyDate: dateFormat(new Date().getFullYear()),
        safetyDate: "",
      },
      levels: [],
      orgTypes: [],
      level: {},
      orgType: {},
      type: "add",
      orgId: "",
      // searchTitle: "deptName",
      // orgdefaultProps: {
      //   children: "children",
      //   label: "deptName",
      // },
      orgtableData: [],
      orgtableLoading: false,
      orgpage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      orgtotal: 0,
      query: {},
      visible: false,
      rules: {
        orgTypeName: [
          { required: true, message: "请选择企业类型", trigger: "change" },
        ],
        safetyLevelName: [
          {
            required: true,
            message: "请选择级别",
            trigger: "change",
          },
        ],
        safetyDate: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
    };
  },

  created() {
    // this.lazyDeptTree();
    getDictionaryCode("org_type").then((res) => {
      this.orgTypes = res.data.data;
    });
    getDictionaryCode("safety_level").then((res) => {
      this.levels = res.data.data;
    });
    // this.onLoad(this.page)
  },

  computed: {
    ...mapGetters(["permission", "colorName", "userInfo"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.licenceEnterprise_add, true),
        viewBtn: this.vaildData(this.permission.licenceEnterprise_view, true),
        editBtn: this.vaildData(this.permission.licenceEnterprise_edit, true),
        delBtn: this.vaildData(this.permission.licenceEnterprise_delete, true),
        downloadBtn: this.vaildData(
          this.permission.licenceEnterprise_download,
          true
        ),
        settingBtn: this.vaildData(
          this.permission.licenceEnterprise_setting,
          true
        ),
      };
    },

    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        label: "上传",
        emit: "head-upload",
        type: "button",
        icon: "",
        btnOptType: "upload",
      });
      return buttonBtn;
    },

    tableOptions() {
      return {
        menuFixed: "right",
        customRowAddBtn: false,
        menuWidth: 260,
        selection: false,
        // linklabel: "fileName",
        column: [
          {
            label: "证件名称",
            prop: "fileName",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "证件类型",
            prop: "certificateType",
            align: "center",
            cell: true,
            type: "cascader",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary-tree?code=certificate_type`,
            props: {
              label: "dictValue",
              value: "dictKey",
              checkStrictly: true,
            },
            rules: [{ required: true, message: "证件类型", trigger: "change" }],
            minWidth: 180,
          },
          {
            label: "证件编号",
            prop: "certificateCode",
            align: "center",
            overHidden: true,
            cell: true,
            maxlength: 255,
          },
          {
            label: "发证单位",
            prop: "certificateUnit",
            align: "left",
            overHidden: true,
            cell: true,
            maxlength: 255,
          },
          {
            label: "证件状态",
            prop: "certificateState",
            align: "center",
            overHidden: true,
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=certificate_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "发证日期",
            prop: "startDate",
            align: "center",
            cell: true,
            overHidden: true,
            rules: [{ required: true, message: "发证时间" }],
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
          },
          {
            label: "到期日期",
            prop: "endDate",
            align: "center",
            cell: true,
            overHidden: true,
            rules: [{ required: true, message: "到期时间" }],
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
          },
          {
            label: "上传人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
    orgheadBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: "add",
      });
      return buttonBtn;
    },

    orggridRowBtn() {
      let buttonBtn = [];
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-del",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    orgtableOptions() {
      return {
        menuWidth: 260,
        selection: false,
        linklabel: "safetyDate",
        column: [
          {
            label: "取证日期",
            prop: "safetyDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "企业类型",
            prop: "orgTypeName",
            align: "center",
            overHidden: true,
          },
          {
            label: "级别",
            prop: "safetyLevelName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
    gridRowBtn(row) {
      let buttonBtn = [];
      if (this.permissionList.viewBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.downloadBtn) {
        buttonBtn.push({
          label: "下载",
          emit: "row-download",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.settingBtn) {
        buttonBtn.push({
          label: "设置提醒",
          emit: "row-setting",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
  },

  methods: {
    showChange(v) {
      this.show = v;
    },
    handleClick() {
      if (this.activeName == "license") {
        this.onLoad(this.page, {});
      } else {
        this.orgonLoad(this.orgpage, {});
      }
    },
    orgTypeChange() {
      //   this.form.orgType = this.orgType.dictKey;
      //   this.form.orgTypeName = this.orgType.dictValue;
      this.$set(this.form, "orgType", this.orgType.dictKey);
      this.$set(this.form, "orgTypeName", this.orgType.dictValue);
    },
    levelChange() {
      //   this.form.safetyLevel = this.level.dictKey;
      //   this.form.safetyLevelName = this.level.dictValue;
      this.$set(this.form, "safetyLevel", this.level.dictKey);
      this.$set(this.form, "safetyLevelName", this.level.dictValue);
    },
    /** 左侧列表操作 */
    //获取树数据
    // lazyDeptTree() {
    //   const parentId = this.userInfo.dept_id;
    //   getDeptTree('', '', parentId).then((res) => {
    //       if (res.data.code == 200) {
    //           this.treeData = res.data.data
    //           if (res.data.data.length > 0) {
    //             this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(this.treeData[0].id)
    //               this.handleNodeClick(this.treeData[0])
    //           }
    //       }
    //   });
    // },
    commonTreeData(treeData) {
      // console.log('树',treeData)
      // if(treeData.length){
      //   this.handleNodeClick(treeData[0])
      // }
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.page.currentPage = 1;
      this.query.belongOrgId = data.id;
      this.nodeData = data;
      this.orgpage.currentPage = 1;
      this.orgtotal = 0;
      this.form.orgName = this.nodeData.deptName;
      this.form.orgId = this.nodeData.id;
      this.orgId = data.id;
      this.onLoad(this.page);
      this.orgonLoad(this.orgpage);
    },

    /**----end---- */
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      // this.operition = false
      this.page = page;
      let queryObj = { ...this.query };
      delete queryObj.certificateType_search;
      enterprisePage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, queryObj)
      ).then((res) => {
        const data = res.data.data;
        // this.$refs.gridLayOut.page.total = data.total;
        this.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        // this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      query.certificateType = query.certificateType_search
        ? query.certificateType_search.toString()
        : "";
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },

    rowEdit(row, index) {
      this.$refs.cedForm.init(row);
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        enterpriseRemove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },

    rowDownload(row) {
      downloadFileBlob(row.files, row.fileName);
    },
    rowView(row) {
      let pos = row.files.lastIndexOf(".");
      const type = row.files.slice(pos + 1, row.files.length);
      this.fileUrl = { src: row.files, name: row.fileName, type };
      this.imageDiavlog = true;
    },

    rowSetting(row) {
      this.remaindForm.dateRemind = row.dateRemind ? row.dateRemind : 1;
      this.remaindForm.noticeUserId = row.noticeUserId ? row.noticeUserId : "";
      this.remaindForm.noticeUserName = row.noticeUserName
        ? row.noticeUserName
        : "";
      this.settingId = row.id;
      this.dialogVisible = true;
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handleSave() {
      const { settingId, remaindForm } = this;
      remaindForm.id = settingId;
      enterpriseSubmit({ ...remaindForm }).then(() => {
        this.$message.success("操作成功");
        this.onLoad(this.page);
      });
      this.handleCancel();
    },

    handleUpload() {
      if (this.nodeData.id) {
        this.$refs.cedForm.init({ belongOrgId: this.nodeData.id });
        // this.$refs.upload.$el.click()
      } else {
        this.$message.warning("请选择左侧组织");
      }
    },

    // handleUploadSuccess(res, file, fileList) {
    //     if (res && res.code == 200) {
    //         const fileName = file.name
    //         const files = file.response.data.link
    //         this.$refs.fileUpload.clearFiles();
    //         this.$refs.cedForm.init({ fileName, files, belongOrgId: this.nodeData.id })
    //     }
    // },

    // 设置提醒人
    changeUser() {
      this.userVisible = true;
      this.$refs.remaindFormUser.blur();
    },

    selectData(row, deptId, deptName) {
      // console.log("进行输出人员-------------------",row)
      const data = {
        userId: row.id,
        userName: row.realName,
        // orgId: deptId,
        // orgName: deptName,
        // sex: row.sex,
        // sexName: row.sexName
      };
      this.remaindForm.noticeUserId = row.id;
      this.remaindForm.noticeUserName = row.realName;
      this.userVisible = false;
    },

    getFileName(name) {
      let pos = name.lastIndexOf(".");
      return name.slice(0, pos);
    },

    callback() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },

    /** 右侧表格操作 */
    orghandleSave() {
      // console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          addList({ ...this.form })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              }
              this.saveLoading = false;
              this.visible = false;
              this.orgonLoad(this.orgpage);
            })
            .catch((error) => {
              this.$message({
                message: "保存失败",
                type: "warning",
              });
              this.saveLoading = false;
              this.visible = false;
            });
        }
      });
    },
    orgonLoad(page, params = {}) {
      this.orgtableLoading = true;
      this.orgpage = page;
      let parmas = {
        size: this.orgpage.pageSize,
        current: this.orgpage.currentPage,
        orgId: this.orgId,
      };
      getList(parmas)
        .then((res) => {
          const data = res.data.data;
          this.orgpage.total = data.total;
          //   this.$nextTick(() => {
          //     this.$refs.gridLayOut.orgpage.total = data.total;
          //   });
          this.orgtableData = data.records;
          this.orgtableLoading = false;
        })
        .catch((error) => {
          this.orgtableLoading = false;
        });
    },
    //页数改变
    orghandleCurrentChange(e) {
      this.orgpage.currentPage = e.currentPage;
      this.orgonLoad(this.page);
    },
    //size改变
    orghandleSizeChange(e) {
      this.orgpage.pageSize = e.pageSize;
      this.orgonLoad(this.orgpage);
    },
    // 搜索
    orggridHeadSearch(query) {
      const data = this.query;
      this.orgpage.currentPage = 1;
      this.query = { ...data, ...query };
      this.orgonLoad(this.orgpage);
    },
    // 重置
    orggridHeadEmpty(query) {
      this.orgpage.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.orgonLoad(this.orgpage);
    },
    // 编辑
    orgrowEdit(row, index) {
      getDetail({ id: row.id }).then((res) => {
        this.form = res.data.data;
        /**兼容只存了年份的旧数据 */
        if (this.form.safetyDate.length == 4) {
          this.form.safetyDate = `${this.form.safetyDate}-01-01`;
        }
        /**end */
        this.orgType = {
          dictKey: this.form.orgType,
          dictValue: this.form.orgTypeName,
        };
        this.level = {
          dictKey: this.form.safetyLevel,
          dictValue: this.form.safetyLevelName,
        };
        this.type = "edit";
        this.visible = true;
      });
    },
    orgrowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeList({ ids: row.id }).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    orgrowSave(row, index) {},
    //查看详情
    orgrowView(row) {
      getDetail({ id: row.id }).then((res) => {
        this.form = res.data.data;
        this.orgType = {
          dictKey: this.form.orgType,
          dictValue: this.form.orgTypeName,
        };
        this.level = {
          dictKey: this.form.safetyLevel,
          dictValue: this.form.safetyLevelName,
        };
        this.type = "view";
        this.visible = true;
      });
    },

    orghandleHeadAdd() {
      if (this.nodeData.id) {
        this.form = {
          orgName: "",
          safetyDate: this.dateFormat(new Date(), "yyyy-MM-dd"),
        };
        this.$set(this.form, "orgName", this.nodeData.deptName);
        this.$set(this.form, "orgId", this.nodeData.id);
        this.orgType = {};
        this.level = {};
        // this.$set(this.form, "safetyDate", new Date().getFullYear());
        console.log(this.form);
        this.type = "add";
        this.visible = true;
      } else {
        this.$message.warning("请先选择左侧组织机构");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-name {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
::v-deep .el-container {
  height: calc(100% - 12px);
}
::v-deep .el-tabs__content {
  padding: 0 !important;
}
.license,
.declaration {
  height: calc(100% - 44px);
}
::v-deep #gridLayout {
  height: calc(100% - 100px);
  .avue-crud {
    height: 100%;
    .is-never-shadow {
      height: calc(100% - 42px);
      .el-card__body {
        height: 100%;
      }
    }
    .avue-crud__pagination {
      height: auto;
    }
  }
}
::v-deep .el-tabs__header {
  margin-bottom: 0;
}
::v-deep .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}
</style>
