var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("h3", [_vm._v("表格权限控制")]),
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              permission: _vm.permission,
              option: _vm.option,
              data: _vm.data,
            },
            scopedSlots: _vm._u([
              {
                key: "expand",
                fn: function (scope) {
                  return [_vm._v("\n        " + _vm._s(scope) + "\n      ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "basic-container",
        [
          _vm._v("\n    权限开关\n    "),
          _c("el-switch", {
            attrs: {
              "active-value": false,
              "inactive-value": true,
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
            },
            model: {
              value: _vm.text,
              callback: function ($$v) {
                _vm.text = $$v
              },
              expression: "text",
            },
          }),
          _c("p", [
            _vm._v(" 具体参考"),
            _c(
              "a",
              {
                attrs: { href: "https://avuex.avue.top/#/doc/crud-permission" },
              },
              [_vm._v("https://avuex.avue.top/#/doc/crud-permission")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }