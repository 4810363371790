export const NODE_TYPES = [
  {
      name: '节点组',
      value: 'node'
  },
  {
      name: 'AI盒子',
      value: 'ai_box'
  },
  {
      name: 'AI服务器',
      value: 'server'
  },
  {
      name: '摄像头',
      value: 'camera'
  }
];

export const NODE_SERVER_ALGO_STATUS = [
  {
      name: '未部署',
      value: '0'
  },
  {
      name: '部署中',
      value: '1'
  },
  {
      name: '部署失败',
      value: '6'
  },
  {
      name: '待启动',
      value: '2'
  },
  {
      name: '启动中',
      value: '3'
  },
  {
      name: '已启动',
      value: '4'
  }
];

/**
* @Description: 算法模型分类
* @return {*}
*/
export const ALGO_MODEL_CATEGORY = [
  {
      name: '目标检测类',
      value: 'target_detect'
  },
  {
      name: '目标跟踪类',
      value: 'target_track'
  },
  {
      name: '图像分类',
      value: 'image_classify'
  },
  {
      name: '图像分割',
      value: 'image_segment'
  },
  {
      name: '文字识别',
      value: 'char_recognition'
  },
  {
      name: '数字运算',
      value: 'num_operate'
  }
];

/**
* 识别结果推送周期
*/
export const ALGO_RECOGNITION_RESULT_PUSH_CYCLE = [
  { name: '10分钟', value: '10' },
  { name: '30分钟', value: '30' },
  { name: '60分钟', value: '60' }
];

export const STATUS_BOOLEAN = [
  {
      name: '启用',
      value: true
  },
  {
      name: '停用',
      value: false
  }
];
export const CAMERA_APPEARANCE_TYPES = [
  {
      name: '枪形',
      value: '0'
  },
  {
      name: '半球',
      value: '1'
  },
  {
      name: '球形',
      value: '2'
  }
];

export const CAMERA_STREAM_PROTOCOL = [
  {
      name: 'rtmp协议',
      value: 'rtmp'
  },
  {
      name: 'rtsp协议',
      value: 'rtsp'
  },
  {
      name: 'rtsp协议',
      value: 'rtsp'
  },
  {
      name: 'hls协议',
      value: 'hls'
  },
  {
      name: 'flv协议',
      value: 'flv'
  }
];

export const CAMERA_SIGNAL_TYPES = [
  {
      name: '数字',
      value: '0'
  },
  {
      name: '模拟',
      value: '1'
  },
  {
      name: '网络',
      value: '2'
  },
  {
      name: '模拟高清',
      value: '3'
  }
];

/**
* 任务状态
*/
export const ALGO_TASK_STATUS = [
  { name: '未启动', value: '0' },
  { name: '已启动', value: '1' },
  { name: '已停用 ', value: '2' },
  { name: '运行正常', value: '3' },
  { name: '运行异常', value: '4' }
];

/**
* 任务类型
*/
export const ALGO_TASK_CATEGORY = [
  {
      name: '图像算法',
      value: 'CV',
      children: [
          { name: '目标检测', value: 'detection' },
          { name: '图像分割', value: 'segmentation' },
          { name: '目标跟踪', value: 'tracking' },
          { name: '图像分类', value: 'classification' },
          { name: '数值类', value: 'number' },
          { name: '自然语言处理', value: 'NLP' }
      ]
  },
  {
      name: '自然语言处理',
      value: 'NLP',
      children: [{ name: '待补充', value: '1' }]
  }
];

/**
* 逻辑运算符
*/
export const ALGO_TASK_LOGIC_OPERATOR = [
  { name: '与运算', value: 'and' },
  { name: '或运算', value: 'or' }
];

/**
* 大于小于等于
*/
export const ALGO_TASK_COMPARE_TYPE = [
  { name: '>', value: '>' },
  { name: '<', value: '<' },
  { name: '=', value: '=' }
];
