var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "container",
      staticStyle: {
        width: "100%",
        height: "100%",
        "background-color": "#000000",
        margin: "0 auto",
        position: "relative",
      },
      on: { dblclick: _vm.fullscreenSwich },
    },
    [
      _c("div", { staticClass: "buttons-box", attrs: { id: "buttonsBox" } }, [
        _c("div", { staticClass: "buttons-box-left" }, [
          !_vm.playing
            ? _c("i", {
                staticClass: "iconfont icon-play jessibuca-btn",
                on: { click: _vm.playBtnClick },
              })
            : _vm._e(),
          _vm.playing
            ? _c("i", {
                staticClass: "iconfont icon-pause jessibuca-btn",
                on: { click: _vm.pause },
              })
            : _vm._e(),
          _c("i", {
            staticClass: "iconfont icon-stop jessibuca-btn",
            on: { click: _vm.destroy },
          }),
          _vm.isNotMute
            ? _c("i", {
                staticClass: "iconfont icon-audio-high jessibuca-btn",
                on: {
                  click: function ($event) {
                    return _vm.mute()
                  },
                },
              })
            : _vm._e(),
          !_vm.isNotMute
            ? _c("i", {
                staticClass: "iconfont icon-audio-mute jessibuca-btn",
                on: {
                  click: function ($event) {
                    return _vm.cancelMute()
                  },
                },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "buttons-box-right" }, [
          _c("span", { staticClass: "jessibuca-btn" }, [
            _vm._v(_vm._s(_vm.kBps) + " kb/s"),
          ]),
          _c("i", {
            staticClass: "iconfont icon-camera1196054easyiconnet jessibuca-btn",
            staticStyle: { "font-size": "1rem !important" },
            on: { click: _vm.screenshot },
          }),
          _c("i", {
            staticClass: "iconfont icon-shuaxin11 jessibuca-btn",
            on: { click: _vm.playBtnClick },
          }),
          !_vm.fullscreen
            ? _c("i", {
                staticClass: "iconfont icon-weibiaoti10 jessibuca-btn",
                on: { click: _vm.fullscreenSwich },
              })
            : _vm._e(),
          _vm.fullscreen
            ? _c("i", {
                staticClass: "iconfont icon-weibiaoti11 jessibuca-btn",
                on: { click: _vm.fullscreenSwich },
              })
            : _vm._e(),
          _c("i", {
            staticClass: "iconfont icon-video-close jessibuca-btn",
            staticStyle: { "font-size": "28px" },
            on: {
              click: function ($event) {
                return _vm.closeVideo()
              },
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }