<template>
  <div class="mainReportBox">
    <div class="topReportBox"
         style="height: 46px;background-color: #F2F3F5;border-bottom: 1px solid rgb(204, 204, 204)">
      <div class="leftReportTitle">
        报表查看
      </div>
      <div class="rightReportSidder">
        <div style="width: 100%;">
          <el-menu :default-active="activeIndex" mode="horizontal" class="menu-list">
            <template v-for="item in topSidderData">
              <el-menu-item v-if="item.children.length == 0" :index="item.id" :key="item.id" @click="openReport(item)">
                <i class="el-icon-menu" style="font-size: 20px; color: #333333"></i>
                {{ item.reportCategoryName }}
              </el-menu-item>
              <el-submenu v-else :key="item.id" :index="item.id">
                <template slot="title">
                  <div :title="item.reportCategoryName">
                    <i class="el-icon-menu" style="font-size: 20px; color: #333333"></i>
                    <span slot="title">{{ item.reportCategoryName }}</span>
                  </div>
                </template>
                <el-menu-item v-for="(oneChild,oneIndex) in item.children" :index="oneChild.id" :key="oneIndex"
                              @click="openReport(oneChild)" style="background-color: #F2F3F5">
                  <template>
                    <div class="oneChildNameBox">
                      <i class="el-icon-s-order" style="font-size: 20px; color: #333333"></i>
                      <span class="oneChildName">{{ oneChild.reportCategoryName }}</span>
                    </div>
                  </template>
                </el-menu-item>
              </el-submenu>
            </template>
          </el-menu>
        </div>
      </div>
      <el-button size="medium" icon="el-icon-close" class="closeBtn" @click="occlude">关闭</el-button>
    </div>
    <div class="topReportBox">
      <div class="leftReportBox">
        <div class="leftReportTree">
          <CommonTree
              v-show="leftShow"
              ref="CommonTree"
              :loading="treeLoading"
              style="border-right: none"
              treeTitle="组织架构"
              :popoverShow="true"
              :treeData.sync="treeData"
              :defaultProps="defaultProps"
              :searchTitle="searchTitle"
              :currentNodeKey="deptId"
              :defaultExpandedKeys="defaultExpandedKeys"
              :nodeKey="'id'"
              @getNodeClick="treeNodeClick"
              :isShowdig="false"
              :showCheckbox="false"
              :treeExpand="false"
              @include-down="includeDown"
          />
          <el-button class="rightBtn" v-if="leftShow"
                     style="margin-right: 5px;clip-path:polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);background:#d2d3d6"
                     circle
                     type="primary" icon="el-icon-arrow-right" @click="leftShow = !leftShow"></el-button>
          <el-button class="rightBtn" circle v-else type="primary" icon="el-icon-arrow-left"
                     style="margin-right: 5px;clip-path:polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);background:#d2d3d6;left: -20px;"
                     @click="leftShow = !leftShow"></el-button>
        </div>
      </div>
      <div class="rightReportBox" :style="{width:leftShow?'calc(100% - 280px)':'100%'}">

        <div class="rightTags">
          <el-tabs v-model="activeTag"
                   type="card"
                   @tab-click="openTag"
                   @tab-remove="removeTag">
            <el-tab-pane :key="item.id"
                         :closable="item.reportCategoryName != '首页'"
                         v-for="item in tagList"
                         :label="item.reportCategoryName"
                         :name="item.reportCategoryName">
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="iframeBox">
          <iframe class="iframe" :src="iframeUrl" v-if="iframeUrl != ''"></iframe>
          <img src="@/assets/images/reportView.png" v-else>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree";
import {mapGetters} from "vuex";
import Template from "@/views/message/template/list";
import {multiOrganizationCombinationTree, treeReport, deptChildIds} from "@/api/reportTemplate";

export default {
  data() {
    return {
      leftShow: true,
      treeLoading: false,
      includeStatus: false,
      rightShow: false,
      urlPath: '',
      treeData: [],
      topSidderData: [],
      stat_month: "",
      defaultCheckedKeys: [],
      defaultExpandedKeys: [],
      deptIds: "",
      tagList: [{
        reportCategoryName: '首页',
        name: '首页'
      }],
      activeTag: "首页",
      defaultProps: {
        label: "deptName",
        children: 'children'
      },
      props: {
        children: "",
        label: "reportCategoryName",
        path: "reportCategoryCode"
      },
      searchTitle: 'deptName',
      deptId: "",
      activeIndex: '1',
      iframeUrl: ''
    }
  },
  computed: {
    ...mapGetters(['themeColor', 'website', 'screen']),
  },
  components: {
    Template,
    CommonTree
  },
  watch: {
    defaultCheckedKeys: {
      handler: function (newVal) {
        if (newVal) {
          console.log(newVal, '138')
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            // document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  mounted() {
    window.document.title = '报表查看-中材国际智能安全平台';
    var urlParams = new URLSearchParams(window.location.hash);
    if (urlParams.get("stat_month")) {
      this.stat_month = urlParams.get("stat_month");
    }
    if (urlParams.size > 0 && urlParams.get("deptId")) {
      this.treeLoading = true;
      multiOrganizationCombinationTree().then((res) => {
        if (res.data.code == 200) {
          this.treeData = this.treeData.concat(res.data.data);
          this.treeLoading = false;
          this.$nextTick(() => {
            this.deptId = urlParams.get("deptId");
            this.defaultExpandedKeys.push(this.deptId)
          })
          treeReport(
          ).then((res) => {
            if (res.data.code == 200) {
              let data = res.data.data;
              this.replaceData(data);
              this.topSidderData = data;
              if (this.topSidderData) {
                var listData = "";
                for (let i = 0; i < this.topSidderData.length; i++) {
                  listData = this.reportOne(this.topSidderData[i].reportVOList, urlParams.get("reportCode"))
                  if (listData) {
                    break;
                  }
                }
                console.log(listData, '123')
                if (listData) {
                  this.openReport(listData);
                } else {
                  this.$message.warning("没有报表权限!")
                }
              } else {
                this.$message.warning("没有报表权限!")
              }
            }
          })
        }
      })
    } else {
      this.getDeptChildTreeData();
    }
  },
  methods: {
    occlude() {
      window.close();
    },
    checkLeft() {
      this.leftShow = !this.leftShow;
    },
    reportOne(children, reportCode) {
      if (children && children.length > 0) {
        var find = children.find((i) => i.reportCode == reportCode);
        if (find === undefined) {
          children.forEach((i) => {
            this.reportOne(i.reportVOList, reportCode)
          })
        } else {
          return find;
        }
      }
    },
    treeNodeClick(item) {
      this.deptId = item.id;
      if (this.includeStatus) {
        this.getDeptIds(item.id);
      }
      // this.getTopMenu()
      this.getDeptUrl(this.deptId);
    },
    includeDown(e) {
      this.includeStatus = e;
      if (this.urlPath == '') return
      if (this.includeStatus) {
        this.$loading()
        deptChildIds(this.deptId).then((res) => {
          if (res.data.code == 200) {
            this.deptIds = res.data.data;
            if(this.stat_month){
              this.iframeUrl = this.urlPath  + '&deptId=' + this.deptIds + '&stat_month=' + this.stat_month;
            }else {
              this.iframeUrl = this.urlPath  + '&deptId=' + this.deptIds;
            }
            this.$loading().close()
          }
        })
      } else {
        if(this.stat_month){
          this.iframeUrl = this.urlPath  + '&deptId=' + this.deptId + '&stat_month=' + this.stat_month;
        }else {
          this.iframeUrl = this.urlPath  + '&deptId=' + this.deptId;
        }
      }
      // console.log(this.iframeUrl,'160')
      // this.getTopMenu();
    },
    getDeptIds(deptId) {
      deptChildIds(deptId).then((res) => {
        if (res.data.code == 200) {
          this.deptIds = res.data.data
        }
      })
    },
    openTag(item) {
      this.iframeUrl = '';
      this.activeTag = item.name;
      if (item.name == '首页') return
      let data = this.tagList.filter((pane) => {
        if (pane.reportName == item.name) return pane
      })
      if (this.includeStatus) {
        if(this.stat_month){
          this.iframeUrl = data[0].reportReviewPath + '&deptId=' + this.deptIds + '&stat_month=' + this.stat_month;
        }else {
          this.iframeUrl = data[0].reportReviewPath + '&deptId=' + this.deptIds;
        }
      } else {
        if(this.stat_month){
          this.iframeUrl = data[0].reportReviewPath  + '&deptId=' + this.deptId + '&stat_month=' + this.stat_month;
        }else {
          this.iframeUrl = data[0].reportReviewPath  + '&deptId=' + this.deptId;
        }
      }
      this.urlPath = data[0].reportReviewPath;
    },
    openReport(node) {
      if (node.reportReviewPath && node.reportReviewPath != '') {
        let dif = this.tagList.filter((item) => {
          if (item.reportCategoryName == node.reportCategoryName) {
            return item;
          }
        })
        if (dif.length == 0) {
          this.tagList.push(node)
        }
        this.iframeUrl = '';
        if (this.includeStatus) {
          deptChildIds(this.deptId).then((res) => {
            if (res.data.code == 200) {
              this.deptIds = res.data.data;
              if(this.stat_month){
                this.iframeUrl = node.reportReviewPath + '&deptId=' + this.deptIds + '&stat_month=' + this.stat_month;
              }else {
                this.iframeUrl = node.reportReviewPath + '&deptId=' + this.deptIds
              }
            }
          })
        } else {
          if(this.stat_month){
            this.iframeUrl = node.reportReviewPath  + '&deptId=' + this.deptId + '&stat_month=' + this.stat_month;
          }else {
            this.iframeUrl = node.reportReviewPath  + '&deptId=' + this.deptId
          }
        }
        this.activeTag = node.reportName;
        this.urlPath = node.reportReviewPath;
      }
    },
    //取部门下级id集合
    treeDeptId(data, deptListIds) {
      data.forEach(item => {
        deptListIds.push(item.id)
        if (item.children) {
          this.treeDeptId(item.children, deptListIds)
        }
      })
    },
    removeTag(title) {
      this.tagList.forEach((item, index) => {
        if (item.reportCategoryName == title) {
          this.tagList.splice(index, 1);
          if (this.tagList.length > 0) {
            if (title == this.activeTag) {
              console.log(this.tagList[index - 1], '244');
              if (this.tagList[index - 1].name == '首页') {
                this.activeTag = this.tagList[index - 1].name;
                this.iframeUrl = '';
                this.urlPath = '';
              } else {
                this.iframeUrl = '';
                if (this.includeStatus) {
                  if(this.stat_month){
                    this.iframeUrl = this.tagList[index - 1].reportReviewPath + '&deptId=' + this.deptIds + '&stat_month=' + this.stat_month;
                  }else {
                    this.iframeUrl = this.tagList[index - 1].reportReviewPath + '&deptId=' + this.deptIds;
                  }

                } else {
                  if(this.stat_month){
                    this.iframeUrl = this.tagList[index - 1].reportReviewPath + '&deptId=' + this.deptId + '&stat_month=' + this.stat_month;
                  }else {
                    this.iframeUrl = this.tagList[index - 1].reportReviewPath + '&deptId=' + this.deptId;
                  }
                }
                this.urlPath = this.tagList[index - 1]
                this.activeTag = this.tagList[index - 1].reportName;
              }
            }
          }
        }
      })
    },
    getDeptUrl(deptId) {
      if (this.urlPath != '') {
        if (this.includeStatus) {
          deptChildIds(deptId).then((res) => {
            if (res.data.code == 200) {
              this.deptIds = res.data.data;
              if(this.stat_month){
                this.iframeUrl = this.urlPath + '&deptId=' + this.deptIds + '&stat_month=' + this.stat_month;
              }else {
                this.iframeUrl = this.urlPath + '&deptId=' + this.deptIds;
              }
            }
          })
        } else {
          if(this.stat_month){
            this.iframeUrl = this.urlPath + '&deptId=' + deptId + '&stat_month=' + this.stat_month;
          }else {
            this.iframeUrl = this.urlPath + '&deptId=' + deptId;

          }
        }
      }
    },
    // 获取组织架构和顶部导航
    getDeptChildTreeData() {
      this.treeLoading = true;
      multiOrganizationCombinationTree().then((res) => {
        if (res.data.code == 200) {
          this.treeData = this.treeData.concat(res.data.data);
          this.treeLoading = false;
          this.deptId = this.treeData[0].id;
          this.$nextTick(() => {
            this.defaultExpandedKeys.push(this.deptId)
          })
          this.getTopMenu()
        }
      })
    },
    getTopMenu() {
      treeReport(
      ).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.replaceData(data);
          this.topSidderData = data;
        }
      })
    },
    replaceData(data) {
      data.forEach((item) => {
        if (item.reportVOList && item.reportVOList.length != 0 && item.children) {
          item.children = item.children.concat(item.reportVOList);
        } else if (item.reportVOList && item.reportVOList.length != 0 && item.children == undefined) {
          item.children = new Array();
          item.children = item.children.concat(item.reportVOList);
        } else {
          item.children = new Array();
        }
        if (item.reportName) {
          item.reportCategoryName = item.reportName;
        }
        if (item.reportReviewPath == undefined) {
          item.reportReviewPath = '';
        }
        if (item.children && item.children.length != 0) {
          this.replaceData(item.children);
        }
      })
    }
  }
}
</script>
<style>
/*.dark .el-menu--horizontal {*/
/*  background-color: #F2F3F5 !important;*/
/*}*/
.dark .el-menu--horizontal .el-menu {
  background-color: #F2F3F5;
}

.el-menu--horizontal .el-menu--popup {
  box-shadow: unset;
}

.el-popover .el-button--text {
  color: #333333 !important;
}
</style>
<style lang="scss" scoped>
.mainReportBox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: rgb(244, 245, 246);
  width: 100%;
  height: 100%;
}

.topReportBox {
  width: 100%;
  position: relative;
}

.leftReportBox {
  width: 280px;
  height: 100%;
  background-color: #F2F3F5;
  //box-shadow: 2px 0 6px rgba(0, 21, 41, .15);
  border-right: 1px solid rgb(204, 204, 204);
  position: relative;
  z-index: 9;
}

.closeBtn {
  position: absolute;
  right: 12px;
  top: 5px;
}

.closeBtn:hover {
  background: #FFF;
  border: 1px solid #DCDFE6;
  color: #606266;
}

.rightReportBox {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.leftReportTitle {
  width: 280px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  color: #333333;
  background-color: #F2F3F5;
  font-size: 20px;
  letter-spacing: 10px;
  border-right: 1px solid rgb(204, 204, 204);
  //border-bottom: 1px solid #E4E7ED;
  //box-shadow: 2px 0 6px rgba(0, 21, 41, .15);
  //border-bottom: 1px solid rgb(204, 204, 204);
  position: absolute;
  z-index: 99;
}

.leftReportTree {
  width: 100%;
  height: calc(100% - 46px);
  position: relative;
  //box-shadow: 2px 0 6px rgba(0, 21, 41, .35);
}

.rightBtn {
  width: 48px;
  height: 96px;
  transform: rotate(180deg);
  position: absolute;
  left: 264px;
  top: calc(50vh - 48px);
  z-index: 99;
}

::v-deep .leftReportTree .moreBtn {
  color: #303233;
  background-color: #F2F3F5;
  border-color: #DCDFE6;
}

.rightReportSidder {
  width: calc(100% - 391px);
  height: 46px;
  display: flex;
  background-color: #F2F3F5;
  //border-bottom: 1px solid #E4E7ED;
  position: absolute;
  left: 280px;
}
.menu-list{
  //overflow-y: auto;
  display: flex;
  white-space: nowrap;
  width: 100%;
  z-index: 999;
}
::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 148px) !important;
}

.el-menu {
  background-color: transparent;
}

.rightReportSidder .el-menu-item {
  color: #333333 !important;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
}

.el-menu--horizontal > .el-menu-item:hover {
  background-color: unset !important;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none !important;
  color: #333333 !important;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  background-color: #F2F3F5;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #FFFFFF !important;
}

//::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
//  background-color: unset !important;
//}

::v-deep .el-menu .el-submenu__title i:last-child {
  display: none;
}

.el-menu--horizontal .el-menu .el-menu-item {
  height: 46px !important;
  line-height: 46px !important;
  background-color: #FFFFFF !important;
}

.oneChildNameBox {
  height: 46px !important;
  line-height: 46px !important;
  font-size: 14px;
}

.oneChildName {
  color: #333333 !important;
}

.rightTags {
  width: 100%;
  height: 46px;
  background-color: #FFFFFF;
  margin-bottom: 1px;
}

.rightTags .el-tabs--card .el-tabs__header {
  border: none;
}

::v-deep .rightTags .el-tabs__item {
  color: #909399;
  height: 30px;
  line-height: 30px;
  border: 1px solid rgb(204, 204, 204) !important;
  border-radius: 4px;
  margin-right: 8px;
}

::v-deep .rightTags .el-tabs__item.is-active {
  color: #333333;
  background-color: #F2F3F5;
  //border: 1px solid rgb(204, 204, 204) !important;
}

::v-deep .el-tabs--card > .el-tabs__header {
  border-bottom: 1px solid rgb(204, 204, 204);
  height: 30px;
  padding: 8px 16px;
  margin: 0;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}

::v-deep .el-tabs__content {
  background-color: transparent !important;
}

.iframeBox {
  width: calc(100% - 24px);
  height: calc(100vh - 111px);
  padding: 12px;
}

.iframeBox img {
  width: 80%;
  height: calc(100% - 200px);
  margin: 100px auto;
  display: block;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
