var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-box riskIdentification" },
    [
      _c(
        "div",
        { staticClass: "flex-container" },
        [
          _c("nodeCommonTree", {
            ref: "commonTreeCore",
            staticClass: "introduceTree",
            attrs: {
              treeTitle: "节点配置",
              "node-key": "id",
              pageType: "node",
              isLeafIcons: "",
              isShowMore: "",
              isShowdig: "",
              risk: "",
              defaultExpandedKeys: _vm.defaultExpandedKeys,
              treeData: _vm.corePersonnelTreeData,
              defaultProps: _vm.defaultProps,
              showCheckbox: false,
            },
            on: {
              getTreeAdd: _vm.getTreeAdd,
              getTreeAddChild: _vm.getTreeAddChild,
              getTreeDelete: _vm.getTreeDelete,
              getTreeEdit: _vm.getTreeEdit,
              getNodeClick: _vm.TreeNodeClick,
              getTreeDrag: _vm.getTreeDragClick,
            },
          }),
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _vm.activeTab == "node"
                ? _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基础信息", name: "base" } },
                        [
                          _c("nodeBase", {
                            attrs: { selectTree: _vm.selectTree },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeTab == "server" || _vm.activeTab == "ai_box"
                ? _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基础信息", name: "base" } },
                        [
                          _c("boxBase", {
                            attrs: { selectTree: _vm.selectTree },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "算法模型管理", name: "model" } },
                        [
                          _c("boxAlgorithmModel", {
                            attrs: { selectTree: _vm.selectTree },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "算法任务", name: "task" } },
                        [
                          _c("boxAlgorithmTask", {
                            attrs: { selectTree: _vm.selectTree },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("addNodeTree", { ref: "addNodeTree" }),
      _c("addBoxTree", { ref: "addBoxTree" }),
      _c("addServerTree", { ref: "addServerTree" }),
      _c("nodeMove", { ref: "nodeMove", on: { callback: _vm.typeTree } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }