<template>
  <div style="position: relative">
    <el-container>
      <!-- <CommonTree ref="commonTreeCore" treeTitle="组织列表" :searchTitle="searchTitle" :treeData="treeData"
        :defaultProps="defaultProps" @getNodeClick="treeNodeClick" :isShowdig="false" :showCheckbox="false"
        :treeExpand="false" :isZoom="true" :show="show" @showChange="showChange" node-key="id" /> -->
      <!-- <tree-layout treeTitle="组织列表" :tree-options="treeOptions" @tree-node-click="treeNodeClickAll"
                     @include-down="includeDownAll" style="margin-right:5px;"></tree-layout> -->
      <el-main>
        <head-layout head-title="班前会" :showIcon="false" :head-btn-options="headBtnOptions" @head-add="headAdd"
          @head-romve="headRomve">
          <template v-slot:left>
            <div style="display:inline-block;align-items: center;height: 28px;margin-right: 5px;">
              <div style="display:flex;align-items: center;">
                <span
                  style="background: #ffce7b; border: 1px solid; width: 22px; height: 14px; margin-right: 3px;"></span>
                <span>：数据已失效,请及时删除</span>
              </div>
            </div>
          </template>
        </head-layout>
        <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"></grid-head-layout>
        <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading"
          @grid-row-detail-click="detailView" :data-total="page.total" :page="page" @page-current-change="onLoad"
          @page-size-change="onLoad" @gird-handle-select-click="selectionChange" :cell-style="cellStyle">
          <template #customBtn="{ row }">
            <el-button type="text" size="small" @click="rowView(row, 'view')">查看</el-button>
            <el-button v-if="showHide(row, 'edit')" style="margin: 0 3px" type="text" size="small"
              @click="rowView(row, 'edit')">编辑</el-button>
            <el-button v-if="showHide(row, 'delete')" style="margin: 0 3px" type="text" size="small"
              @click="rowRemove(row)">删除</el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>

  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree";

import { treePro } from "@/api/dbtComon/dbtComon";
import { mapGetters } from "vuex";

import * as API from "@/api/beforeClass/beforeClass";
import {
  selectChildren
} from "@/api/system/dept";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableData: [],
      selectionList: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchTitle: 'title',
      deptId: "",
      companyId: "",
      show: false,
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: "",
        },
      ];
    },
    tableOption() {
      return {
        linklabel: "eventCode",
        selectable: (row, index) => {
          return row.status ? false : row.createUser != this.userInfo.user_id ? false : true
        },
        menuFixed: "right",
        menuWidth: 150,
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "会议名称",
            prop: "title",
            align: "left",
            overHidden: true,
            width: 200,
          },
          {
            label: "会议时间",
            prop: "meetingTime",
            align: "left",
            overHidden: true,
            width: 200,
          },
          {
            label: "创建人",
            prop: "createUserName",
            align: "left",
            overHidden: true,
            width: 120,
          },
          {
            label: "单位/部门",
            prop: "createDeptName",
            align: "center",
            overHidden: true,
          },
          {
            label: "会议类型",
            prop: "meetingType",
            align: "center",
            overHidden: true,
          },
          {
            label: "编号",
            prop: "serialNumber",
            align: "center",
            width: 200,
            overHidden: true,
          },
        ],
      };
    },
    searchColumns() {
      return [
        {
          label: '项目状态',
          prop: "draft",
          span: 3,
          type: 'select',
          placeholder: '请选择项目状态',
          dicData: [
            { label: "全部", value: -1 },
            { label: "有效", value: 3 },
            { label: "失效", value: 2 },
          ],
          props: {
            label: 'label',
            value: 'value',
          },
        },
        {
          label: "会议名称",
          prop: "title",
          span: 3,
          placeholder: "请输入会议名称",
        },
        {
          label: "会议日期",
          prop: "daterange",
          type: "daterange",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          span: 3,
          startPlaceholder: "发生日期",
          endPlaceholder: "结束日期",
        },
      ];
    },
  },
  mounted() {
    this.lazyDeptTree();
    var userInfo = this.userInfo;
    var company_id = userInfo.company_id || -1;
    var dept_id = userInfo.dept_id;
    this.deptId = dept_id;
    this.companyId = company_id;
    console.log(userInfo.company_id)
    console.log(userInfo.userType)
    var parentId = company_id == -1 ? dept_id : company_id;
    treePro({ parentId: parentId }).then(res => {
      console.log(res)
      if (res.data.success) {
        var dicData = res.data.data;
        if (dicData.length > 0) {
          this.searchColumns.unshift({
            label: '项目列表',
            prop: "companyId",
            span: 3,
            type: 'select',
            placeholder: '请选择项目',
            dicData: dicData,
            props: {
              label: 'label',
              value: 'value',
            },
          })
        }
      }

    })

    this.onLoad(this.page, {});
  },
  methods: {

    showChange(v) {
      this.show = v;
    },
    //获取树数据
    lazyDeptTree() {
      selectChildren().then((res) => {
        let resData = res.data.data
        this.treeData = resData
      });
    },
    treeNodeClick(node) {
      this.deptId = node.id;
      this.companyId = -1;
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    // 搜索
    gridHeadSearch(searchForm) {
      if (
        this.$refs.gridHeadLayout.searchForm.daterange &&
        this.$refs.gridHeadLayout.searchForm.daterange.length != 0
      ) {
        this.$refs.gridHeadLayout.searchForm.startTime =
          this.$refs.gridHeadLayout.searchForm.daterange[0] + ' 00:00:00';
        this.$refs.gridHeadLayout.searchForm.endTime =
          this.$refs.gridHeadLayout.searchForm.daterange[1] + ' 23:59:59';
      } else {
        delete this.$refs.gridHeadLayout.searchForm.startTime;
        delete this.$refs.gridHeadLayout.searchForm.endTime;
      }
      if (searchForm.companyId) this.companyId = searchForm.companyId;
      else this.companyId = -1;
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.deptId = this.userInfo.dept_id;
      this.onLoad(this.page, searchForm);
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      let that = this;
      this.page = page;
      this.tableLoading = true;
      API.epeventPage({
        current: page.currentPage,
        size: page.pageSize,
        ...params,
        createDept: this.deptId,
        companyId: this.companyId,
      }).then((res) => {
        if (res.data.code == 200) {
          that.tableLoading = false;
          var records = res.data.data.records;
          that.tableData = records;
          that.page.total = res.data.data.total;
          that.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });

    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 批量删除
    headRomve() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      var errMsg = "";
      var userInfo = this.userInfo;
      const role_name = userInfo?.role_name || "";
      if (role_name.indexOf("dbt_beforeclass_manage") == -1) {
        var isRight = selectionList.every(item => {
          if (item.createUser != this.userInfo.user_id) {
            errMsg = "只能删除自己的数据";
            return false;
          }
          if (!item.sameDay) {
            if (item.draft != 1) {
              errMsg = "只能废弃的数据";
              return false;
            }
            return true;
          }
        })
        if (!isRight) {
          this.$message({ type: "warning", message: errMsg, });
          return false;
        }
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return API.removeByClassIds(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });

    },
    // 单条删除
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.removeByClassIds(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    headAdd() {
      this.$router.push({
        path: `/beforeclass/edit`,
        query: {
          type: "add",
        },
      });
    },
    detailView(row) {
      this.rowView(row, "view");
    },
    rowView(row, type, hide) {
      this.$router.push({
        path: "/beforeclass/edit",
        query: {
          id: row.id,
          type: type || "view",
        },
      });
    },
    cellStyle(data) {
      const { row } = data;
      let _style = "";
      if (row.draft == 1 && !row.sameDay) _style = "color:#ffce7b";
      return _style;
    },
    showHide(row, type) {
      let that = this;
      var sameDay = row.sameDay;
      var userInfo = this.userInfo;
      const role_name = userInfo?.role_name || "";
      var draft = row.draft;
      var isShow = false;
      if (role_name.indexOf("dbt_beforeclass_manage") > -1) isShow = true;
      else {
        if (type == "edit") {
          if (!sameDay) isShow = false;
          else {
            isShow = row.createUser === userInfo.user_id;
          }
        }
        if (type == "delete") {
          if (row.createUser === userInfo.user_id) {
            if (sameDay) return true;
            else {
              isShow = row.createUser === userInfo.user_id && draft == 1;
            }
          }
        }
      }
      return isShow;
    }
  },
};
</script>

<style lang="scss" scoped></style>
