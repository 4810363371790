var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "div",
        [
          _c("head-layout", {
            attrs: {
              "head-title": "识别配置",
              "head-btn-options": _vm.headBtnOptions,
            },
            on: {
              "head-add": _vm.handleAdd,
              "head-import": _vm.handleImport,
              "head-export": _vm.handleExport,
              "head-user": _vm.handleUser,
            },
          }),
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: { "search-columns": _vm.searchColumns },
            on: {
              "grid-head-search": _vm.gridHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            staticClass: "table-container",
            attrs: {
              "table-options": _vm.tableOption,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.page.total,
              page: _vm.page,
            },
            on: {
              "grid-row-detail-click": _vm.rowView,
              "gird-handle-select-click": _vm.selectionChange,
              "page-current-change": _vm.onLoad,
              "page-size-change": _vm.onLoad,
            },
            scopedSlots: _vm._u([
              {
                key: "ifOutputOrigin",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(row.ifOutputOrigin ? "是" : "")
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowUpdate(row)
                          },
                        },
                      },
                      [_vm._v("编辑\n        ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowDel(row)
                          },
                        },
                      },
                      [_vm._v("删除\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("addIdentify", {
        ref: "addIdentify",
        on: {
          callback: function ($event) {
            return _vm.onLoad(_vm.page, {})
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }