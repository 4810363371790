var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-button", { on: { click: _vm.handleChange } }, [_vm._v("切换")]),
      _c("video", {
        attrs: { id: "video", autoplay: "", width: "900", height: "900" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }