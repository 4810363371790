<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="rowView"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
    >
      <!-- <template #hdLevel="{ row }">
        <el-tag
          v-if="hdLevel[row.hdLevel]"
          :type="hdLevelIcon[row.hdLevel]"
          effect="dark"
          >{{ hdLevel[row.hdLevel] }}
        </el-tag>
      </template> -->
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";

import { holographyEgPage } from "@/api/safe/safeatyHolography";
import { erplanPage } from "@/api/contingency/emergencyPlan";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      organizationId: "",
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          prop: "planName",
          placeholder: "应急预案名称",
          span: 4,
        },
      ];
    },
    tableOptions() {
      return {
        menu: false,
        selection: false,
        linklabel: "planCode",
        column: [
          {
            label: "预案编号",
            prop: "planCode",
            minWidth: 130,
            overHidden: true,
            align: "center",
          },
          {
            label: "预案名称",
            prop: "planName",
            minWidth: 130,
            align: "left",
            overHidden: true,
          },
          {
            label: "预案类型",
            prop: "planType",
            overHidden: true,
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=er_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "有效日期起",
            prop: "startDate",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "有效日期止",
            prop: "endDate",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "起草人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "erStatus",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=ER_PLAN_STATUS",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "创建时间",
            width: 170,
            prop: "createTime",
            align: "center",
          },
        ],
      };
    },
  },
  methods: {
    init(id) {
      this.organizationId = id;
      this.query = {}
      // this.query.organizationId = id
      this.page.currentPage = 1
      this.$refs.gridHeadLayout.searchForm = {}
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      // params.organizationId = this.organizationId;
      erplanPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query,{orgId:this.organizationId})
        ).then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    //查看
    rowView(row) {
      this.$router.push({
        path: `/business/contingency/emergencyPlan/view`,
        query: {
          type: "view",
          id: row.id,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
