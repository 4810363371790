var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["flex center", { disabled: _vm.disabled }] }, [
    _c(
      "div",
      { staticClass: "flex col mr-16" },
      [
        _c(
          "el-button",
          {
            staticClass: "mb-16",
            attrs: {
              type: "primary",
              plain: "",
              disabled:
                !_vm.canDrawBtn ||
                _vm.isDrawing ||
                _vm.disabled ||
                _vm.canDelete,
            },
            on: { click: _vm.handleStartDraw },
          },
          [_vm._v("开始绘制\n    ")]
        ),
        _c(
          "el-button",
          {
            staticClass: "mb-16 m-0",
            attrs: {
              type: "primary",
              plain: "",
              disabled: !_vm.isDrawing || _vm.disabled,
            },
            on: { click: _vm.handleCancelDraw },
          },
          [_vm._v("结束绘制\n    ")]
        ),
        _c(
          "el-button",
          {
            staticClass: "mb-16 m-0",
            attrs: {
              type: "primary",
              plain: "",
              disabled: !_vm.canDelete || _vm.disabled,
            },
            on: { click: _vm.handleDelete },
          },
          [_vm._v("删除\n    ")]
        ),
        _c(
          "el-button",
          {
            staticClass: "mb-16 m-0",
            attrs: {
              type: "primary",
              plain: "",
              disabled: !_vm.canClear || _vm.disabled,
            },
            on: { click: _vm.handleClear },
          },
          [_vm._v("清空\n    ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "img-style" },
      [
        _vm.url
          ? _c("smPlayer", {
              staticClass: "my-image-ctr",
              staticStyle: { width: "600px", height: "400px" },
              attrs: { videoUrl: _vm.url },
              on: { videoClose: _vm.onVideoClose },
            })
          : _c("img", {
              staticClass: "my-image-ctr",
              attrs: { src: _vm.bgImg },
            }),
        _c("canvas", {
          attrs: { id: "myCanvas", width: "500", height: "350" },
        }),
      ],
      1
    ),
    _c("div", { staticStyle: { width: "100px" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }