<template>
  <div class="main-content">
    <div class="table-box right-10 bottom-10">
      <el-container>
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="风险发生可能性(L)"
            @head-add="opDialog(1)"
            @head-delete="deleteList(1)"
          >
          </head-layout>

          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal1"
            :gridRowBtn="gridRowBtn"
            :page="page1"
            :tableData="tableData1"
            :tableLoading="tableLoading1"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange1"
            @page-current-change="handleCurrentChange1"
            @page-refresh-change="onLoad1"
            @gird-handle-select-click="selectionChange1"
            @grid-see="SeeData1"
            @grid-edit="rowEdit1"
            @grid-romve="rowDel1"
          >
            <template slot="score" slot-scope="{ row }">
              <el-link type="primary" @click="linkDetail1(row)">{{
                row.score
              }}</el-link>
            </template>
            <template #customBtn="{ row, index }">
              <el-button
                size="small"
                type="text"
                @click.stop="rowEdit1(row, index)"
              >
                编辑
              </el-button>
              <el-button
                size="small"
                type="text"
                @click.stop="rowDel1(row, index)"
              >
                删除
              </el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </div>
    <div class="table-box bottom-10">
      <el-container>
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="人员暴露于风险环境频繁程度(E)"
            @head-add="opDialog(2)"
            @head-delete="deleteList(2)"
          >
          </head-layout>

          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal2"
            :gridRowBtn="gridRowBtn"
            :page="page2"
            :tableData="tableData2"
            :tableLoading="tableLoading2"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange2"
            @page-current-change="handleCurrentChange2"
            @page-refresh-change="onLoad2"
            @gird-handle-select-click="selectionChange2"
            @grid-see="SeeData2"
            @grid-edit="rowEdit2"
            @grid-romve="rowDel2"
          >
            <template slot="score" slot-scope="{ row }">
              <el-link type="primary" @click="linkDetail2(row)">{{
                row.score
              }}</el-link>
            </template>
            <template #customBtn="{ row, index }">
              <el-button
                size="small"
                type="text"
                @click.stop="rowEdit2(row, index)"
              >
                编辑
              </el-button>
              <el-button
                size="small"
                type="text"
                @click.stop="rowDel2(row, index)"
              >
                删除
              </el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </div>
    <div class="table-box right-10 bottom-10">
      <el-container>
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="事故发生可能性产生的后果(C)"
            @head-add="opDialog(3)"
            @head-delete="deleteList(3)"
          >
          </head-layout>

          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal3"
            :gridRowBtn="gridRowBtn"
            :page="page3"
            :tableData="tableData3"
            :tableLoading="tableLoading3"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange3"
            @page-current-change="handleCurrentChange3"
            @page-refresh-change="onLoad3"
            @gird-handle-select-click="selectionChange3"
            @grid-see="SeeData3"
            @grid-edit="rowEdit3"
            @grid-romve="rowDel3"
          >
            <template slot="score" slot-scope="{ row }">
              <el-link type="primary" @click="linkDetail3(row)">{{
                row.score
              }}</el-link>
            </template>
            <template #customBtn="{ row, index }">
              <el-button
                size="small"
                type="text"
                @click.stop="rowEdit3(row, index)"
              >
                编辑
              </el-button>
              <el-button
                size="small"
                type="text"
                @click.stop="rowDel3(row, index)"
              >
                删除
              </el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </div>
    <div class="table-box bottom-10">
      <el-container>
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="风险度与风险等级对应关系(D)"
            @head-add="opDialog(4)"
            @head-delete="deleteList(4)"
          >
          </head-layout>

          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal4"
            :gridRowBtn="gridRowBtn"
            :page="page4"
            :tableData="tableData4"
            :tableLoading="tableLoading4"
            :tableOptions="tableOptions4"
            @page-size-change="handleSizeChange4"
            @page-current-change="handleCurrentChange4"
            @page-refresh-change="onLoad4"
            @gird-handle-select-click="selectionChange4"
            @grid-see="SeeData4"
            @grid-edit="rowEdit4"
            @grid-romve="rowDel4"
          >
            <template slot="levelCode" slot-scope="{ row }">
              <el-link type="primary" @click="linkDetail4(row)">{{
                row.levelCode
              }}</el-link>
            </template>
            <template slot="color" slot-scope="{ row }">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-item: center;
                "
              >
                <div
                  :style="{
                    backgroundColor: row.color,
                    width: '20px',
                    height: '20px',
                  }"
                ></div>
              </div>
            </template>
            <template #customBtn="{ row, index }">
              <el-button
                size="small"
                type="text"
                @click.stop="rowEdit4(row, index)"
              >
                编辑
              </el-button>
              <el-button
                size="small"
                type="text"
                @click.stop="rowDel4(row, index)"
              >
                删除
              </el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </div>
    <el-dialog
      v-dialog-drag
      :title="titleNames[nowSub - 1]"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showTreeDialog"
      v-if="showTreeDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <avue-form
        :option="nowSub == 4 ? treeDialogOption4 : treeDialogOption"
        ref="addForm"
        v-model="form"
        @submit="treeNodeSave"
      ></avue-form>
      <span slot="footer">
        <el-button
          size="small"
          @click="showTreeDialog = false"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
          v-loading="treeDialogLoading"
          v-if="type != 'detail'"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import {
  bsRiskLecdLSubmit,
  bsRiskLecdLRemove,
  bsRiskLecdLPage,
  bsRiskLecdLDetail,
  bsrisklecdeSubmit,
  bsrisklecdeRemove,
  bsrisklecdePage,
  bsrisklecdeDetail,
  bsRiskLecdCSubmit,
  bsRiskLecdCRemove,
  bsRiskLecdCPage,
  bsRiskLecdCDetail,
  bsrisklecddlevelSubmit,
  bsrisklecddlevelRemove,
  bsrisklecddlevelPage,
  bsrisklecddlevelDetail,
} from "@/api/setting/riskAssessment/index";
import { mapGetters } from "vuex";
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  data() {
    return {
      type: "add",
      form: {},
      nowSub: 1,
      ids1: "",
      ids2: "",
      ids3: "",
      ids4: "",
      titleNames: [
        "风险发生可能性配置",
        "人员暴露于风险环境频繁程度配置",
        "事故发生可能性产生的后果配置",
        "风险度与风险等级对应关系配置",
      ],
      fullscreen: false,
      tableTotal1: 0,
      tableTotal2: 0,
      tableTotal3: 0,
      tableTotal4: 0,
      tableLoading1: false,
      tableLoading2: false,
      tableLoading3: false,
      tableLoading4: false,
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      page1: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      page2: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      page3: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      page4: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      showTreeDialog: false,
      treeDialogOption: {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "定义",
            prop: "definition",
            type: "input",
            maxlength: 100,
            overHidden: true,
            span: 12,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入定义",
                trigger: "blur",
              },
            ],
          },
          {
            label: "分值",
            prop: "score",
            type: "number",
            maxlength: 100,
            overHidden: true,
            span: 12,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入分值",
                trigger: "blur",
              },
            ],
          },

          {
            label: "量化描述",
            prop: "description",
            type: "textarea",
            maxlength: 100,
            overHidden: true,
            span: 24,
            minRows: "2",
            maxRows: "4",
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入量化描述",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      treeDialogOption4: {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "等级编号",
            prop: "levelCode",
            type: "input",
            maxlength: 100,
            overHidden: true,
            span: 12,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入等级编号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "显示名称",
            prop: "levelName",
            type: "input",
            maxlength: 100,
            overHidden: true,
            span: 12,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入显示名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "计算公式",
            prop: "formula",
            type: "input",
            maxlength: 100,
            overHidden: true,
            span: 12,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入计算公式",
                trigger: "blur",
              },
            ],
          },
          {
            label: "显示颜色",
            prop: "color",
            type: "color",
            maxlength: 100,
            overHidden: true,
            span: 12,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请选择颜色",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 100,
        menuFixed: "right",
        showTooltip: true,
        column: [
          {
            label: "分值",
            prop: "score",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "定义",
            prop: "definition",
            align: "center",
            overHidden: true,
          },
          {
            label: "量化描述",
            prop: "description",
            align: "center",
            overHidden: true,
          },
        ],
      },
      tableOptions4: {
        customRowAddBtn: false,
        menuWidth: 100,
        menuFixed: "right",
        showTooltip: true,
        column: [
          {
            label: "等级编号",
            prop: "levelCode",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "显示名称",
            prop: "levelName",
            align: "center",
            overHidden: true,
          },
          {
            label: "计算公式",
            prop: "formula",
            align: "center",
            overHidden: true,
          },
          {
            label: "显示颜色",
            prop: "color",
            align: "center",
            overHidden: true,
            slot: true,
          },
        ],
      },
      gridRowBtn: [],
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
        },
        {
          label: "删除",
          emit: "head-delete",
          type: "button",
          icon: "",
        },
      ],
    };
  },
  created() {
    this.getbsRiskLecdLPage();
    this.getbsrisklecdePage();
    this.getbsRiskLecdCPage();
    this.getbsrisklecddlevelPage();
    console.log(this.userInfo);
  },
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    // L1 page
    getbsRiskLecdLPage() {
      this.tableLoading1 = true;
      bsRiskLecdLPage({
        current: this.page1.currentPage,
        size: this.page1.pageSize,
        // createDept: this.userInfo.dept_id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.tableData1 = res.data.data.records;
            this.tableTotal1 = res.data.data.total;
          }
          this.tableLoading1 = false;
        })
        .catch((error) => {
          this.tableLoading1 = false;
        });
    },
    // E2 page
    getbsrisklecdePage() {
      this.tableLoading2 = true;
      bsrisklecdePage({
        current: this.page2.currentPage,
        size: this.page2.pageSize,
        // createDept: this.userInfo.dept_id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.tableData2 = res.data.data.records;
            this.tableTotal2 = res.data.data.total;
          }
          this.tableLoading2 = false;
        })
        .catch((error) => {
          this.tableLoading2 = false;
        });
    },
    // C3 page
    getbsRiskLecdCPage() {
      this.tableLoading3 = true;
      bsRiskLecdCPage({
        current: this.page3.currentPage,
        size: this.page3.pageSize,
        // createDept: this.userInfo.dept_id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.tableData3 = res.data.data.records;
            this.tableTotal3 = res.data.data.total;
          }
          this.tableLoading3 = false;
        })
        .catch((error) => {
          this.tableLoading3 = false;
        });
    },
    // D4 page
    getbsrisklecddlevelPage() {
      this.tableLoading4 = true;
      bsrisklecddlevelPage({
        current: this.page4.currentPage,
        size: this.page4.pageSize,
        // createDept: this.userInfo.dept_id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.tableData4 = res.data.data.records;
            this.tableTotal4 = res.data.data.total;
          }
          this.tableLoading4 = false;
        })
        .catch((error) => {
          this.tableLoading4 = false;
        });
    },
    opDialog(i) {
      this.nowSub = i;
      this.type = "add";
      this.form = {};
      this.treeDialogOption4.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });
      this.treeDialogOption.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });

      console.log(this.treeDialogOption, this.type, "this.treeDialogOption");
      this.showTreeDialog = true;
    },
    handleTreeNodeSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.nowSub == 1) {
            bsRiskLecdLSubmit({
              ...this.form,
              // createDept: this.userInfo.dept_id,
            }).then((res) => {
              this.showTreeDialog = false;
              this.getbsRiskLecdLPage();
              this.$message({
                message: "添加成功",
                type: "success",
              });
            });
          } else if (this.nowSub == 2) {
            bsrisklecdeSubmit({
              ...this.form,
              // createDept: this.userInfo.dept_id,
            }).then((res) => {
              this.showTreeDialog = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.getbsrisklecdePage();
            });
          } else if (this.nowSub == 3) {
            bsRiskLecdCSubmit({
              ...this.form,
              // createDept: this.userInfo.dept_id,
            }).then((res) => {
              this.showTreeDialog = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.getbsRiskLecdCPage();
            });
          } else {
            bsrisklecddlevelSubmit({
              ...this.form,
              // createDept: this.userInfo.dept_id,
            }).then((res) => {
              this.showTreeDialog = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.getbsrisklecddlevelPage();
            });
          }
        }
      });
    },

    deleteList(i) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (i == 1) {
            bsRiskLecdLRemove({ ids: this.ids1 }).then((res) => {
              this.getbsRiskLecdLPage();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            });
          } else if (i == 2) {
            bsrisklecdeRemove({ ids: this.ids2 }).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getbsrisklecdePage();
            });
          } else if (i == 3) {
            bsRiskLecdCRemove({ ids: this.ids3 }).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getbsRiskLecdCPage();
            });
          } else {
            bsrisklecddlevelRemove({ ids: this.ids4 }).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getbsrisklecddlevelPage();
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    rowDel1(row) {
      console.log(row, "rowDel1");
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          bsRiskLecdLRemove({ ids: row.id }).then((res) => {
            this.getbsRiskLecdLPage();
            this.$message({
              message: "删除成功",
              type: "success",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    rowDel2(row) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          bsrisklecdeRemove({ ids: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getbsrisklecdePage();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    rowDel3(row) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          bsRiskLecdCRemove({ ids: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getbsRiskLecdCPage();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    rowDel4(row) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          bsrisklecddlevelRemove({ ids: row.id }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getbsrisklecddlevelPage();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    rowEdit1(row) {
      this.type = "edit";
      this.treeDialogOption.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });
      this.showTreeDialog = true;
      this.form = row;
      this.nowSub = 1;
    },
    rowEdit2(row) {
      this.type = "edit";
      this.treeDialogOption.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });
      this.showTreeDialog = true;
      this.form = row;
      this.nowSub = 2;
    },
    rowEdit3(row) {
      this.type = "edit";
      this.treeDialogOption.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });
      this.showTreeDialog = true;
      this.form = row;
      this.nowSub = 3;
    },
    rowEdit4(row) {
      this.type = "edit";
      this.treeDialogOption4.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });
      this.showTreeDialog = true;
      this.form = row;
      this.nowSub = 4;
    },
    handleSizeChange1(e) {
      console.log(e);
      this.page1.pageSize = e.pageSize;
      this.page1.currentPage = 1;
      this.getbsRiskLecdLPage();
    },
    handleSizeChange2(e) {
      this.page1.pageSize = e.pageSize;
      this.page1.currentPage = 1;
      this.getbsrisklecdePage();
    },
    handleSizeChange3(e) {
      this.page1.pageSize = e.pageSize;
      this.page1.currentPage = 1;
      this.getbsRiskLecdCPage();
    },
    handleSizeChange4(e) {
      this.page1.pageSize = e.pageSize;
      this.page1.currentPage = 1;
      this.getbsrisklecddlevelPage();
    },
    handleCurrentChange1(e) {
      this.page1.currentPage = e.currentPage;
      this.getbsRiskLecdLPage();
    },
    handleCurrentChange2(e) {
      this.page1.currentPage = e.currentPage;
      this.getbsrisklecdePage();
    },
    handleCurrentChange3(e) {
      this.page1.currentPage = e.currentPage;
      this.getbsRiskLecdCPage();
    },
    handleCurrentChange4(e) {
      this.page1.currentPage = e.currentPage;
      this.getbsrisklecddlevelPage();
    },
    selectionChange1(arr) {
      this.ids1 = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },
    selectionChange2(arr) {
      this.ids2 = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },
    selectionChange3(arr) {
      this.ids3 = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },
    selectionChange4(arr) {
      this.ids4 = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },
    linkDetail1(row) {
      this.showTreeDialog = true;
      this.type = "detail";
      this.treeDialogOption.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });
      this.form = row;
      this.nowSub = 1;
    },
    linkDetail2(row) {
      this.showTreeDialog = true;
      this.type = "detail";
      this.treeDialogOption.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });
      this.form = row;
      this.nowSub = 2;
    },
    linkDetail3(row) {
      this.showTreeDialog = true;
      this.type = "detail";
      this.treeDialogOption.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });
      this.form = row;
      this.nowSub = 3;
    },
    linkDetail4(row) {
      this.showTreeDialog = true;
      this.type = "detail";
      this.form = row;
      this.nowSub = 4;
      this.treeDialogOption4.column.forEach((item) => {
        item.disabled = this.type == "detail" ? true : false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-container {
  height: 100%;
  overflow: hidden;
  .el-main {
    height: 100%;
    overflow: hidden;
    #gridLayout {
      height: calc(100% - 92px);
      margin-top: 10px;
      .avue-crud {
        height: 100%;
        .el-card {
          height: 100%;
        }
        .el-card__body {
          height: 100%;
          .el-form {
            height: 100%;
            .el-table {
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}
.right-10 {
  margin-right: 10px;
}
.bottom-10 {
  margin-bottom: 10px;
}
.main-content {
  width: 100%;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  .table-box {
    width: calc(50% - 5px);
    height: calc(50% - 5px);
    background-color: white;
  }
}
</style>
