var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.$t("cip.qm.business.qm.sample.field.picture"),
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-add-tabs": _vm.headAddTabs, "head-remove": _vm.headRemove },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "row-remove": _vm.rowRemove,
          "row-edit": _vm.rowEdit,
          "grid-row-detail-click": _vm.rowDetail,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.formShow
            ? _c("form-layout", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "formLayout",
                attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
              })
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v(" " + _vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
              _vm.type != "view"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }