var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "14px 12px 0 12px",
                    "background-color": "#FFFFFF",
                  },
                },
                [
                  _c("avue-input-tree", {
                    directives: [
                      {
                        name: "form",
                        rawName: "v-form",
                        value: _vm.deptId,
                        expression: "deptId",
                      },
                    ],
                    staticClass: "project-area-tree",
                    attrs: {
                      "default-expand-all": "",
                      placeholder: "请选择项目组织",
                      dic: _vm.treeData,
                    },
                    on: { change: _vm.deptChange },
                  }),
                ],
                1
              ),
              _c("CommonTree", {
                attrs: {
                  treeData: _vm.leftTreeData,
                  defaultProps: _vm.defaultProps,
                  searchTitle: _vm.searchTitle,
                  treeTitle: "区域分类",
                  isShowdig: false,
                  showCheckbox: false,
                },
                on: { getNodeClick: _vm.getNodeClick },
              }),
            ],
            1
          ),
          _c(
            "el-container",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "el-main",
                { staticStyle: { width: "40%" } },
                [
                  _c("head-layout", { attrs: { "head-title": "区域" } }),
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: { "search-columns": _vm.leftSearchColumns },
                    on: {
                      "grid-head-search": _vm.leftGridHeadSearch,
                      "grid-head-empty": _vm.leftGridHeadEmpty,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "leftGridLayOut",
                    staticClass: "itemListGrid",
                    attrs: {
                      "table-options": _vm.leftTableOptions,
                      "table-data": _vm.leftTableData,
                      "table-loading": _vm.leftTableLoading,
                      "data-total": _vm.leftPage.total,
                      page: _vm.leftPage,
                      "grid-row-btn": _vm.gridRowBtn,
                    },
                    on: {
                      "page-current-change": _vm.handleLeftCurrentChange,
                      "page-size-change": _vm.handleLeftSizeChange,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.leftSelectionChange,
                      "row-edit": _vm.rowEdit,
                      "row-remove": _vm.rowRemove,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-main",
                { staticStyle: { width: "60%" } },
                [
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.headBtnOptions,
                      "head-title": "摄像头管理",
                    },
                    on: {
                      "head-remove": _vm.rowRemove,
                      "head-add-tabs": _vm.headAddTabs,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "headers" },
                    [
                      _c("grid-head-layout", {
                        ref: "gridHeadLayout",
                        attrs: { "search-columns": _vm.searchColumns },
                        on: {
                          "grid-head-search": _vm.gridHeadSearch,
                          "grid-head-empty": _vm.gridHeadEmpty,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("grid-layout", {
                    ref: "gridLayOut",
                    staticClass: "itemListGrid",
                    attrs: {
                      "grid-row-btn": _vm.gridRowBtn,
                      "table-options": _vm.tableOptions,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.tableLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                    },
                    on: {
                      "gird-handle-select-click": _vm.selectionChange,
                      "page-current-change": _vm.onLoad,
                      "page-size-change": _vm.onLoad,
                      "page-refresh-change": _vm.onLoad,
                      "grid-row-detail-click": _vm.rowDetail,
                      "row-view": _vm.rowDetail,
                      "row-remove": _vm.rowRemove,
                      "row-edit": _vm.rowEdit,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }