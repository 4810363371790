<template>
  <div>
    <el-container>
      <!-- <CommonTree
        ref="commonTreeCore"
        treeTitle="组织"
        :searchTitle="searchTitle"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @getNodeClick="treeNodeClick"
        :isShowdig="false"
        :showCheckbox="false"
        node-key="id"
      /> -->
      <CommonTreeNew
        ref="commonTree"
        treeTitle="项目架构"
        :defaultProps="defaultProps"
        :showCheckbox="false"
        @getNodeClick="treeNodeClick"
        :urlParmas="{
          tenantId: '',
          deptCategory: '2,5',
          parentId: userInfo.deptId,
        }"
        node-key="id"
        @getTreeData="commonTreeData"
      ></CommonTreeNew>
      <el-main>
        <head-layout
          head-title="预警信息"
          :head-btn-options="headBtnOptions"
          @head-add="headAdd"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOption"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @row-edit="rowEdit"
          @row-view="rowDetail"
          @row-remove="rowRemove"
          @grid-row-detail-click="rowDetail"
        >
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import TreeLayout from "@/views/components/layout/tree-layout";
import * as API from "@/api/siteManagement/personEAExit";
import { getDeptLazyTree } from "@/api/system/dept";
import { deptChildTree, templatePreview } from "@/api/reportTemplate";

import { mapGetters } from "vuex";

export default {
  components: {
    TreeLayout,
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      treeDeptId: "",
      treeData: [],
      node: {}, //左侧树node
      searchTitle: "deptName",
      defaultProps: {
        children: "children",
        label: "title",
      },
      query: {},

      tableData: [],
      tableLoading: true,
      isCycle: "",
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          prop: "warningType",
          placeholder: "请输入预警类型",
        },
        {
          prop: "warningTime",
          type: "datetimerange",
          span: 4,
          startPlaceholder: "预警开始时间",
          endPlaceholder: "预警结束时间",
          format: "yyyy-MM-dd HH:mm:ss",
          valueFormat: "yyyy-MM-dd HH:mm:ss",
        },
      ],
      isFirst: true,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
      ];
    },
    tableOption() {
      return {
        selection: false,
        column: [
          {
            label: "预警类型",
            prop: "warningType",
            align: "center",
            overHidden: true,
          },
          {
            label: "详细描述",
            prop: "detailedDescription",
            align: "center",
            overHidden: true,
          },
          {
            label: "设备编号",
            prop: "equipmentNumber",
            align: "center",
            overHidden: true,
          },
          {
            label: "所在区域",
            prop: "region",
            align: "center",
            overHidden: true,
          },
          {
            label: "预警时间",
            prop: "warningTime",
            align: "center",
            overHidden: true,
          },
          {
            label: "照片",
            prop: "picture",
            align: "center",
            overHidden: true,
          },
          {
            label: "视频",
            prop: "video",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
    gridRowBtn() {
      return [
        {
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        },
      ];
    },
  },

  mounted() {
    // this.lazyDeptTree()
  },
  methods: {
    //获取树数据
    lazyDeptTree() {
      deptChildTree().then((res) => {
        this.treeData = [res.data.data];
        this.node = res.data.data;
        this.$nextTick(() => {
          this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
            this.node.id
          );
          this.treeNodeClick(this.node);
        });
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },

    treeNodeClick(node) {
      this.treeOrgId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      if (this.treeOrgId) {
        params.orgId = this.treeOrgId;
      }
      API.getList(page.currentPage, page.pageSize, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          // this.tableData = res.data.data.records;
          // this.page.total = res.data.data.total;
          // this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },

    headAdd() {
      // this.$router.push({
      //   path: `/siteManagement/personEAExit/add`,
      //   query: {
      //     type: "add",
      //   },
      // });
    },
    rowRemove(row) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.remove(row.id).then(() => {
          this.onLoad(this.page);
          this.$message.success("操作成功！");
        });
      });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/siteManagement/personEAExit/add`,
        query: {
          id: row.id,
          type: "edit",
        },
      });
    },
    rowDetail(row) {
      this.$router.push({
        path: `/siteManagement/personEAExit/add`,
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
  },
};
</script>
