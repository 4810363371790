<template>
    <el-container>
        <el-main>

            <head-layout
                head-title=""
                :head-btn-options="headBtnOptions"
                @head-add="headAdd"
                @head-remove="rowRemove"
            />
            <grid-layout
                ref="gridLayOut"
                :grid-row-btn="gridRowBtn"
                :table-options="tableOptions"
                :table-data="tableData"
                :table-loading="tableLoading"
                :data-total="page.total"
                :page="page"
                :editType="inline"
                @page-current-change="handleCurrentChange"
                @page-size-change="handleSizeChange"
                @page-refresh-change="onLoad"
                @gird-handle-select-click="selectionChange"
                @row-edit="rowEdit"
                @row-remove="rowRemove"
            >
                <template #startDate="{row,index}">
                    {{ row.startDate }}至{{ row.endDate }}
                </template>
            </grid-layout>
            <!-- 操作页 -->
            <el-dialog :visible.sync="dialogVisible" :title="title" :before-close="handleCancel">
                <avue-form :option="dialogOption" ref="cedForm" v-model="form" @submit="handleSubmit">
                    <template slot="site">
                        <el-input v-model="form.site" ref="site" @focus="handleChange"></el-input>
                    </template>
                </avue-form>
                <template slot="footer">
                    <el-button size="small" @click="handleCancel()" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.celBtn`) }} </el-button>
                    <el-button size="small" type="primary" @click="handleSave" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.defBtn`) }} </el-button>
                </template>
            </el-dialog>
            <!-- 职业健康危害分析 -->
            <el-dialog :visible.sync="analysisDialog" title="职业危害选择">
                <hazardAnalysis ref="hazardAnalysis" @callback="changeCallback"></hazardAnalysis>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {ohinsurancePage, ohinsuranceSubmit, ohinsuranceRemove, ohhazardcontactPage, ohhazardcontactSubmit, ohhazardcontactRemove} from '@/api/healthManage/healthArchives'
import {mapGetters} from 'vuex'
import hazardAnalysis from './compontents/hazardAnalysis.vue'
export default {
    props: {
        active: {
            type: String,
            default: ''
        },
        documentInfo: {
            type: Object,
            default: ()=>{}
        }
    },

    components: {
        HeadLayout,
        GridLayout,
        hazardAnalysis
    },

    data () {
        return {
            headTitle: '',
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            selectionList: [],
            query: {},
            dialogVisible: false,
            title: '',
            form: {},
            analysisDialog: false
        }
    },

    watch: {
        active: {
            handler () {
                this.init()
            },
            immediate: true,
        },
        "documentInfo.id" : {
            handler () {
                this.init()
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters(["permission", 'userInfo']),

        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.healthArchives_ced_add, true),
                editBtn: this.vaildData(this.permission.healthArchives_ced_edit, true),
                delBtn: this.vaildData(this.permission.healthArchives_ced_delete, true),
            };
        },
        headBtnOptions () {
            let btnList = []
            btnList.push({
                emit: "head-add",
                type: "button",
                icon: "",
                btnOptType: 'add',
            });
            btnList.push({
                label: '移除',
                emit: "head-remove",
                type: "button",
                icon: "",
                btnOptType: 'remove',
            });
            return btnList
        },

        tableOptions () {
            return {
                selection: true,
                // linklabel: 'docUrl',
                column: [
                    {
                        label: "起止日期",
                        prop: "startDate",
                        align: "center",
                        overHidden: true,
                        slot: true,
                    },
                    {
                        label: "区域/场所",
                        prop: "site",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "职业危害因素",
                        prop: "hazardName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "每天平均接触时长(h)",
                        prop: "avgTime",
                        align: "center",
                        overHidden: true,
                    },
                ],
            }
        },

        gridRowBtn () {
            let btnList = []
            if (this.permissionList.editBtn){
                btnList.push({
                    label: '编辑',
                    emit: "row-edit",
                    type: "button",
                    icon: "",
                    btnOptType: 'edit',
                });
            }
            if (this.permissionList.delBtn){
                btnList.push({
                    label: '删除',
                    emit: "row-remove",
                    type: "button",
                    icon: "",
                    btnOptType: 'del',
                });
            }
            return btnList
        },

        dialogOption () {
            return {
                size: 'small',
                submitBtn: false,
                emptyBtn: false,
                labelWidth: 120,
                column: [
                    {
                        label: "区域/场所",
                        prop: "site",
                        placeholder: '请选择职业危害',
                        span: 12,
                        rules: [{
                            required: true,
                            message: '请选择职业危害',
                            trigger: ['change','bulr']
                        }],
                        formslot: true,
                    },
                    {
                        label: "危害因素",
                        prop: "hazardName",
                        placeholder: '请选择职业危害',
                        disabled: true,
                        span: 12,
                    },
                    {
                        label: "起止日期",
                        prop: "seDate",
                        labelWidth: 120,
                        placeholder: "请选择",
                        span:12,
                        type: 'daterange',
                        format: 'yyyy-MM-dd', // 设置日期格式
                        valueFormat: 'yyyy-MM-dd', // 设置数据库中存储的日期格式
                        startPlaceholder: '',
                        endPlaceholder: '',
                        rules: [{
                            required: true,
                            message: "请选择",
                            trigger: "change"
                        }]
                    },
                    {
                        label: "每日平均接触时长(h)",
                        prop: "avgTime",
                        placeholder: '请输入数字',
                        span: 12,
                        rules: [
                            {
                                required: true,
                                message: '请输入数字',
                                trigger: ['change','bulr']
                            },
                            {
                                validator: (rule, value, callback) => {
                                    let reg = /^(0\.[1-9]|[1-9](\.\d)?|1\d(\.\d)?|2[0-3](\.\d)?)$/g
                                    if (!reg.test(value)) {
                                        callback(new Error('请正确填写大于0，小于24的数字'));
                                    } else {
                                        callback();
                                    }
                                },
                                trigger: 'blur'
                            }
                        ]
                    },
                ]
            }
        },
    },

    mounted () {
        // this.init()
    },

    methods: {
        init () {
            this.page.current = 1 
            this.page.pageSize = 20 
            this.onLoad(this.page)
        },
        headAdd () {
            this.title = '职业危害接触史新增'
            this.dialogVisible = true
        },
        rowRemove (row,index) {
            let ids = ''
            if(row) {
                ids = row.id
            } else {
                ids = this.selectionList.map(val=>val.id).toString()
                if(!ids){
                    this.$message.warning('请选择操作数据')
                }
            }
            if(ids){
                this.$confirm("确认删除当前数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    ohhazardcontactRemove(ids).then((res) => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功")
                            this.onLoad(this.page);
                        }
                    });
                }).catch((error) => {
                        this.$message({
                            message: "取消删除",
                            type: "info",
                        });
                    });
            }
        },

        onLoad (page,params={}) {
            this.tableLoading = true; 
            this.page = page;
            this.query = {documentId: this.documentInfo.id}
            ohhazardcontactPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.tableData = data.records;
                this.tableLoading = false;
                this.$refs.gridLayOut.selectionClear()
            });
        },

        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page)
        },
        selectionChange (list) {
            this.selectionList = list
        },

        rowEdit (row, index) {
            this.form = row
            this.title = '职业危害接触史编辑' 
            this.form.seDate = [row.startDate,row.endDate]
            this.dialogVisible = true
        },

        handleCancel () {
            this.$refs.cedForm.resetForm()
            delete this.form.id
            this.dialogVisible = false
        },
        handleSubmit (data,done) {
            this.$refs.cedForm.validate(valid=>{
                if(valid) {
                    this.dialogLoading = true
                    this.form.documentId = this.documentInfo.id
                    this.form.startDate = this.form.seDate[0]
                    this.form.endDate = this.form.seDate[1]
                    delete this.form.seDate
                    ohhazardcontactSubmit(this.form).then(() => {
                        // this.onLoad()
                        this.$message.success('操作成功')
                        this.dialogLoading = false
                        this.handleCancel()
                        this.init()
                        done()
                    }).catch(err => {
                        done()
                    }).finally(() => {
                        this.dialogLoading = false
                    })
                }
            })
        },
        handleSave () {
            this.$refs.cedForm.submit();
        },

        handleChange () {
            this.$refs.site.blur()
            this.analysisDialog = true
            this.$nextTick(()=>{
                this.$refs.hazardAnalysis.onLoad()
            })
        },

        changeCallback (data) {
            this.form.site = data.site
            this.form.hazardName = data.hazardName
            this.form.hazardId = data.id
            this.analysisDialog = false
        }

    }
}
</script>

<style>

</style>