<template>
    <el-dialog
      title="作业许可证执行统计"
      :visible.sync="dialogShow"
      width="1200px"
      class="inspection-dialog"
      v-if="dialogShow"
    >
        <div class="inspection-dialog-main">
            <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="initData"
            @grid-head-empty="resetData"
            ></grid-head-layout>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="图示" name="first"></el-tab-pane>
                <el-tab-pane label="表示" name="second"></el-tab-pane>
            </el-tabs>
            <div class="chart-container" id="chart-container" v-show="activeName == 'first'">
                <p class="chart-title">{{dateType==1?(timeRange[0]+'至'+timeRange[1]):timeRange}}作业许可证一岗双责统计</p>
                <p class="child-title">作业许可证审批总数：{{resObj?.totalCount}}次，<span class="yellow">超期处理：{{resObj?.timeoutCount}}项</span></p>
                <div class="chart-main" id="chart-main"></div>
            </div>
            <grid-layout
                v-if="activeName != 'first'"
                ref="gridLayout"
                :tableOptions="tableOptions"
                :tableData="tableData"
                :tableLoading="tableLoading"
                :data-total="page.total"
                :page="page"
                @page-size-change="initData"
                @page-current-change="initData"
                @page-refresh-change="initData"
            ></grid-layout>
        </div>
    </el-dialog>
</template>
<script>
import GridLayout from "@/views/components/layout/grid-layout";
import {workTicketChart,workTicketList} from '@/api/safetyAndEnvironmentalProtection/oneJob'
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {dateFormat} from '@/util/date'
import {mapGetters} from "vuex";
export default {
    components: {
        GridLayout
    },
    computed:{
        ...mapGetters(["userInfo"]),
    },
    props:{
        checkedProject:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            dialogShow:false,
            activeName:'first',
            timeRange:[],
            dateType:1,
            resObj:{},
            tableData:[],
            page: {
                pageSize: PAGE_CONSTANT.TWENTY,
                currentPage: 1,
                total: 0
            },
            tableLoading:false,
            tableOptions: {
                customRowAddBtn: false,
                menuWidth: 150,
                selection: false,
                indexLabel: "序号",
                index: true,
                menu:false,
                column: [{
                    label: '作业许可证审批人',
                    prop: "approverName",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '审批总数',
                    prop: "approveCount",
                    align: 'center',
                    overHidden: true,
                },{
                    label: '超时次数',
                    prop: "timeoutCount",
                    align: 'center',
                    overHidden: true,
                },]
            },
            searchColumns:[{
                    placeholder: "请选择检查日期类型",
                    prop: "type",
                    type: "select",
                    dicData: [{
                        dictValue:'按日期',
                        dictKey:1
                    },{
                        dictValue:'按月份',
                        dictKey:2
                    }],
                    props: {
                        label: "dictValue",
                        value: "dictKey",
                    },
                    span: 5,
                    change:(val)=>{
                        if(val.value == 1){
                            this.searchColumns.splice(1,1,{
                                placeholder: "请选择检查日期",
                                prop: "timeRange",
                                type: "daterange",
                                dicData: [],
                                span: 5,
                            })
                            // this.$refs.gridHeadLayout.searchForm.timeRange = this.getLastDate();
                               this.$set(this.$refs.gridHeadLayout.searchForm,'timeRange',this.getLastDate())
                        }else{
                            this.searchColumns.splice(1,1,{
                                placeholder: "请选择检查日期",
                                prop: "time",
                                type: "month",
                                dicData: [],
                                span: 5,
                            })
                            // this.$refs.gridHeadLayout.searchForm.time = dateFormat(new Date(),'yyyy-MM');
                            this.$set(this.$refs.gridHeadLayout.searchForm,'time',dateFormat(new Date(),'yyyy-MM'))
                        }
                        // this.$nextTick(()=>{
                        //     this.$refs.gridHeadLayout?.initSearch();
                        // })
                        
                    }
                },{
                    placeholder: "请选择检查日期",
                    prop: "time",
                    type: "daterange",
                    dicData: [],
                    span: 5,
                }]
        }
    },
    methods:{
        init(){
            this.dialogShow = true
            this.$nextTick(()=>{
                this.activeName = 'first'
                this.$set(this.$refs.gridHeadLayout.searchForm,'type',1)
                this.$set(this.$refs.gridHeadLayout.searchForm,'timeRange' , this.getLastDate())
                this.getChart()
            })
        },
        initData(page){
            this.page.pageSize = page.pageSize
            this.page.currentPage = page.currentPage
            this.getChart()
            this.getChartTable()
        },
        resetData(){
            this.page.pageSize = PAGE_CONSTANT.TWENTY
            this.page.currentPage = 1
            this.page.total = 0
            this.$set(this.$refs.gridHeadLayout.searchForm,'type',1)
            this.$refs.gridHeadLayout.searchForm.timeRange = this.getLastDate()
            this.getChart()
            this.getChartTable()
        },
        handleClick(val){
            this.$nextTick(()=>{
                if(val._props.name != 'first'){
                    this.getChartTable()
                }else{
                    this.getChart()
                }
            })
        },
        getLastDate(){
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [dateFormat(start,'yyyy-MM-dd'), dateFormat(end,'yyyy-MM-dd')]
        },
        getChart(){
            let {time,timeRange,type} = this.$refs.gridHeadLayout.searchForm
            this.timeRange = type == 1?timeRange:dateFormat(new Date(time),'yyyy-MM')
            this.dateType = type
            console.log(this.timeRange,'timeRange')
            workTicketChart({orgId:this.checkedProject,...type == 1?{startDate:timeRange[0],endDate:timeRange[1]}:{yearAndMonth:this.timeRange}}).then(res=>{
                let data = res.data.data
                var myChart = this.$echarts.init(
                    document.getElementById("chart-main")
                );
                this.resObj = data
                var option = {
                    grid: {
                        top: '10px',
                        left: '30px',
                        bottom:'20px',
                        right:0
                    },
                    color:['rgb(0, 172, 248)','rgb(255,116,0)'],
                    xAxis: {
                        type: 'category',
                        data: data.detailList.map(item=>{return item.approverName}),
                        axisLine: {
                            lineStyle: {
                            color: ['#E8E8E8']
                            }
                        },
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                    },
                    tooltip: {
                        show:true,
                        formatter:'{a0}: {c0}<br />{a1}: {c1}',
                        trigger:'axis'
                    },
                    series:[{
                        data: data.detailList.map(item=>{return item.approveCount || 0}),
                        type: 'bar',
                        barWidth: 25,
                        name:'审批总数'
                    },{
                        data: data.detailList.map(item=>{return item.timeoutCount || 0}),
                        type: 'bar',
                        barWidth: 25,
                        name:'审批超时'
                    }]
                };
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        getChartTable(){
            let {time,timeRange,type} = this.$refs.gridHeadLayout.searchForm
            this.timeRange = type == 1?timeRange:dateFormat(new Date(time),'yyyy-MM')
            this.dateType = type
            this.tableLoading = true
            workTicketList({orgId:this.checkedProject,...type == 1?{startDate:timeRange[0],endDate:timeRange[1]}:{yearAndMonth:this.timeRange},
            current:this.page.currentPage,size:this.page.pageSize}).then(res=>{
                let data = res.data.data
                this.tableData = data.records
                this.page.pageSize = data.size
                this.page.currentPage = data.current
                this.page.total = data.total
                this.tableLoading = false
            })
        },
    },
}
</script>
<style scoped lang="scss">
    p{
        margin: 0;
        padding: 0;
    }
    .inspection-dialog{
        .inspection-dialog-main{
            padding: 10px;
            height: 500px;
            .el-tabs{
                margin: 20px 0 10px 0;
            }
        }
    }
    .chart-container{
        text-align: center;
        p{
            margin-bottom: 10px;
        }
        .chart-main{
            height: 300px;
            margin-top: 10px;
        }
    }
    ::v-deep #gridHeadLayout > div{
        padding: 0!important;
    }
     ::v-deep .avue-crud .el-table{
        height: 320px!important;
        max-height: 320px!important;
     }
    .yellow{
        color: rgb(255,116,0);
    }
</style>
