var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("common-tree", {
            ref: "commonTree",
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: true,
              loading: _vm.treeDialogLoading,
              showCheckbox: false,
              treeTitle: "试卷分类",
            },
            on: {
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.treeNodeClick,
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "试卷",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-add-tabs": _vm.headAdd,
                  "head-remove": _vm.headRemove,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowDetail,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _vm.permissionList.editBtn && row.paperStatus == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowEdit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑\n          ")]
                            )
                          : _vm._e(),
                        _vm.permissionList.delBtn && row.paperStatus == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowRemove(row)
                                  },
                                },
                              },
                              [_vm._v(" 删除\n          ")]
                            )
                          : _vm._e(),
                        _vm.permissionList.publishBtn && row.paperStatus == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowPublish(row)
                                  },
                                },
                              },
                              [_vm._v(" 发布\n          ")]
                            )
                          : _vm._e(),
                        _vm.permissionList.lapseBtn && row.paperStatus == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowLapse(row)
                                  },
                                },
                              },
                              [_vm._v("失效\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showTreeDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                width: "60%",
                showFullscreen: true,
                dialogTitle: "试卷分类管理",
              },
              on: {
                cancel: function ($event) {
                  _vm.showTreeDialog = false
                },
                confirm: _vm.handleTreeNodeSave,
              },
            },
            [
              _c("avue-form", {
                ref: "addForm",
                attrs: { option: _vm.treeDialogOption },
                on: { submit: _vm.treeNodeSave },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }