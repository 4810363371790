var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogShow
    ? _c(
        "el-dialog",
        {
          staticClass: "inspection-dialog",
          attrs: {
            title: "关联整改记录",
            visible: _vm.dialogShow,
            width: "1200px",
            "modal-append-to-body": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "inspection-dialog-main" },
            [
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.pageCurrentChange,
                  "grid-head-empty": _vm.resetData,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayout",
                attrs: {
                  tableOptions: _vm.tableOptions,
                  tableData: _vm.tableData,
                  tableLoading: _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-size-change": _vm.pageCurrentChange,
                  "page-current-change": _vm.pageCurrentChange,
                  "page-refresh-change": _vm.resetData,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "hdLevel",
                      fn: function ({ row }) {
                        return [
                          row.hdLevel
                            ? _c("el-tag", { attrs: { effect: "dark" } }, [
                                _vm._v(_vm._s(row.$hdLevel) + "\n          "),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "hdStatus",
                      fn: function ({ row }) {
                        return [
                          row.hdStatus && !row.outOfTime
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "hdStatus",
                                  attrs: { effect: "dark" },
                                },
                                [_vm._v(_vm._s(row.$hdStatus) + "\n          ")]
                              )
                            : _vm._e(),
                          row.outOfTime
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "hdStatus",
                                  style: {
                                    backgroundColor: "#bd3124",
                                    borderColor: "#bd3124",
                                  },
                                  attrs: { effect: "dark" },
                                },
                                [_vm._v("超期未整改\n          ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "customBtn",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.toRelevance(row)
                                },
                              },
                            },
                            [_vm._v("关联")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  918539323
                ),
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }