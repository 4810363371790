var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "camera" },
    [
      _c("CommonTree", {
        ref: "commonTreeCore",
        attrs: {
          treeTitle: "组织列表",
          searchTitle: _vm.searchTitle,
          treeData: _vm.treeData,
          defaultProps: _vm.defaultProps,
          isShowdig: false,
          showCheckbox: false,
          "node-key": "id",
        },
        on: { getNodeClick: _vm.treeNodeClick },
      }),
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-title": "随手拍记录",
              "head-btn-options": _vm.gridHeadBtn,
            },
            on: { "head-add": _vm.headAdd },
          }),
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: {
              "btn-span": "1",
              "search-columns": _vm.searchColumns,
              searchSpan: 2,
            },
            on: {
              "grid-head-search": _vm.gridHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "grid-row-btn": _vm.gridRowBtn,
              "table-options": _vm.tableOptions,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.dataTotal,
            },
            on: {
              "page-current-change": _vm.onLoad,
              "gird-handle-select-click": _vm.selectionChange,
              "page-size-change": _vm.onLoad,
              "page-refresh-change": _vm.onLoad,
            },
            scopedSlots: _vm._u([
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowDel([row])
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "avue-dialog camera-dialog",
          attrs: { title: "配置摄像头", visible: _vm.cameraShow, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.cameraShow = $event
            },
          },
        },
        [
          _c("grid-head-layout", {
            ref: "gridHeadLayout1",
            attrs: {
              "btn-span": "1",
              "search-columns": _vm.searchColumns1,
              searchSpan: 2,
            },
            on: {
              "grid-head-search": _vm.gridHeadSearch1,
              "grid-head-empty": _vm.gridHeadEmpty1,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.option,
              "table-data": _vm.cameraData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.cameraTotal,
            },
            on: {
              "gird-handle-select-click": _vm.handleSelectionChange,
              "page-current-change": _vm.onLoad1,
              "page-size-change": _vm.onLoad1,
              "page-refresh-change": _vm.onLoad1,
            },
          }),
          _c(
            "div",
            { staticClass: "avue-dialog__footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.cameraShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }