<template>
  <div style="position: relative">
    <div>
      <head-layout
        head-title="填报模板"
        :head-btn-options="headBtnOptions"
        @head-remove="handleDelete"
        @head-add="headAdd"
      ></head-layout>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template #customBtn="{row}">
          <el-button
            style="margin: 0 3px"
            v-if="row.templateStatus == 1"
            type="text"
            size="small"
            @click="rowCopy(row)"
          >复制
          </el-button>
          <el-button
            style="margin: 0 3px"
            v-if="row.templateStatus == 1"
            type="text"
            size="small"
            @click="rowEdit(row)"
          >编辑
          </el-button>
          <el-button
            style="margin: 0 3px"
            v-if="row.templateStatus == 1"
            type="text"
            size="small"
            @click="rowDel(row)"
          >删除
          </el-button>
          <!--          <el-button-->
          <!--            style="margin: 0 3px"-->
          <!--            type="text"-->
          <!--            size="small"-->
          <!--            @click="rowView(row)"-->
          <!--          >查看-->
          <!--          </el-button>-->
          <el-button
            style="margin: 0 3px"
            v-if="row.templateStatus == 2"
            type="text"
            size="small"
            @click="rowAlter(row)"
          >修改
          </el-button>
          <el-button
            style="margin: 0 3px"
            v-if="row.templateStatus == 3"
            type="text"
            size="small"
            @click="rowPublish(row)"
          >失效
          </el-button>
          <el-button
            style="margin: 0 3px"
            v-if="row.templateStatus == 2 || row.templateStatus == 1"
            type="text"
            size="small"
            @click="rowPublish(row)"
          >发布
          </el-button>
        </template>
      </grid-layout>
      <CommonDialog v-if="classShow" width="30%" dialogTitle="复制填报模板" @cancel="classShow = false" @confirm="submitClass">
        <el-form ref="classForm" :model="classForm" label-width="80px" :rules="classRules">
          <el-form-item label="模板名称" prop="groupName">
            <el-input
              v-model="classForm.templateName"
              placeholder="模板名称"
            ></el-input>
          </el-form-item>
        </el-form>
      </CommonDialog>
    </div>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {getCopy, templateList, templatePublish, templateRemove} from "@/api/reportTemplate";
import {mapGetters} from "vuex";
import CommonDialog from "@/components/CommonDialog";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    CommonDialog
  },
  data() {
    return {
      treeData: [],
      selectionList: [],
      copyRow: {},
      classShow: false,
      classForm: {
        templateName: ""
      },
      classRules: {
        templateName: [
          {required: true, message: '请输入分类名称', trigger: 'blur'}
        ],

      },
      tableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        linklabel: "templateCode",
        column: [
          {
            label: "模板编码",
            prop: "templateCode",
            align: "left",
            overHidden: true,
            width: 260,
          },
          {
            label: "模板名称",
            prop: "templateName",
            align: "left",
            overHidden: true,
            width: 260,
          },
          {
            label: "使用级别",
            prop: "applicableLevels",
            align: "center",
            width: 150,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=usage_level`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true,
          },
          {
            label: "编制人",
            width: 150,
            prop: "weaveUser",
            align: "center",
            overHidden: true
          },
          {
            label: "编制单位",
            prop: "weaveDept",
            align: "left",
            overHidden: true
          },
          {
            label: "发布状态",
            prop: "templateStatus",
            align: "center",
            dataType: "number",
            width: 100,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=publish_status`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        },
      ],
      searchColumns: [
        {
          label: '模板名称',
          prop: "templateName",
          span: 4,
          placeholder: '请输入模板名称'
        },
        {
          label: '模板编码',
          prop: "templateCode",
          span: 4,
          placeholder: '请输入模板编码'
        },
        {
          label: '发布状态',
          prop: "templateStatus",
          span: 4,
          type: 'select',
          placeholder: '请选择发布状态',
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=publish_status`,
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    // 搜索填报设置
    gridHeadSearch(searchForm) {
      console.log(searchForm, '195')
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空填报设置搜索条件
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = true;
      console.log(params, '211')
      templateList(page.currentPage, page.pageSize, this.userInfo.createUser, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      })
    },
    //复制
    rowCopy(row) {
      this.copyRow = row
      this.classShow = true;
    },
    submitClass(){
      getCopy(this.copyRow.id,this.classForm.templateName).then((res) => {
        this.$router.push({
          path: `/reportManager/reportTemplate/edit`,
          query: {
            id: res.data.data.id,
            type: 'edit',
            pageType: '',
          }
        })
        this.classShow = false;
      })
    },
    //编辑
    rowEdit(row) {
      this.$router.push({
        path: `/reportManager/reportTemplate/edit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: '',
        }
      })
    },
    //修改
    rowAlter(row) {
      this.$router.push({
        path: `/reportManager/reportTemplate/edit`,
        query: {
          id: row.id,
          type: 'alter',
        }
      })
    },
    //多选删除
    handleDelete() {
      if (this.selectionList.length == 0) {
        this.$message({
          message: "请选择要删除的数据",
          type: "warning",
        });
        return;
      }
      templateRemove(this.ids).then((res) => {
        if (res.data.code == 200) {
          this.gridHeadSearch();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      })
    },
    //删除
    rowDel(row) {
      templateRemove(row.id).then((res) => {
        if (res.data.code == 200) {
          this.gridHeadSearch();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      })
    },
    //新增
    headAdd() {
      this.$router.push({
        path: `/reportManager/reportTemplate/add`,
        query: {
          type: 'add',
          pageType: '',
        }
      })
    },
    //发布
    rowPublish(row) {
      templatePublish(row).then((res) => {
        if (res.data.code == 200) {
          this.gridHeadSearch();
          this.$message({
            message: "操作成功",
            type: "success",
          });
        }
      })
    },
    //查看
    rowView(row) {
      this.$router.push({
        path: `/reportManager/reportTemplate/view`,
        query: {
          id: row.id,
          type: 'view',
          pageType: '',
        }
      })
    }
  }
}
</script>
