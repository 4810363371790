<template>
<el-card>
  <el-link>404</el-link>
</el-card>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>
