var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("basic-container", { staticClass: "safe-intelligent" }, [
    _c("div", { staticClass: "video" }, [
      _c("div", { staticClass: "big-video" }, [_c("VideoOnePro")], 1),
      _c("div", { staticClass: "small-video" }, [
        _c("div", { staticClass: "top-video" }, [
          _c("div", { staticClass: "video-title" }, [_vm._v("视频监控列表")]),
          _c("ul", { staticClass: "video-list" }, [
            _c("li", [_c("VideoA")], 1),
            _c("li", [_c("VideoB")], 1),
            _c("li", [_c("VideoC")], 1),
            _c("li", [_c("VideoD")], 1),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "bottom-text" },
          [
            _c("div", { staticClass: "video-title" }, [_vm._v("项目基础信息")]),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("label", [_vm._v("子公司:")]),
                  _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("label", [_vm._v("项目进展:")]),
                  _c("span", { staticClass: "green-style" }, [
                    _vm._v(_vm._s(_vm.form.name)),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("label", [_vm._v("项目名称:")]),
                  _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("label", [_vm._v("项目地址:")]),
                  _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("label", [_vm._v("项目业主:")]),
                  _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("label", [_vm._v("项目规模:")]),
                  _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("label", [_vm._v("合同范围:")]),
                  _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("label", [_vm._v("合同周期:")]),
                  _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("label", [_vm._v("项目简介:")]),
                  _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }