<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-06-06 15:15:10
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-07-03 14:49:31
 * @Description: 
-->
<template>
  <div>
    <el-container>
      <!-- <CommonTree
        ref="commonTreeCore"
        treeTitle="组织列表"
        :searchTitle="searchTitle"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @getNodeClick="treeNodeClick"
        :isShowdig="false"
        :showCheckbox="false"
        node-key="id"
      /> -->
      <CommonTreeNew
        ref="commonTree"
        treeTitle="项目架构"
        :defaultProps="defaultProps"
        :showCheckbox="false"
        @getNodeClick="treeNodeClick"
        :urlParmas="{
          tenantId: '',
          deptCategory: '2,5',
          parentId: userInfo.deptId,
        }"
        node-key="id"
        @getTreeData="commonTreeData"
      ></CommonTreeNew>
      <el-main>
        <el-tabs
          type="border-card"
          @tab-click="handleClick"
          v-model="activeName"
        >
          <!-- <el-tab-pane label="整体看板" name="wholeBoard">
           
          </el-tab-pane> -->
          <el-tab-pane label="隐患" name="hiddenDanger">
            <hiddenDanger ref="hiddenDanger"></hiddenDanger>
          </el-tab-pane>
          <el-tab-pane label="风险" name="risk">
            <risk ref="risk"></risk>
          </el-tab-pane>
          <el-tab-pane label="事故" name="accident">
            <accident ref="accident"></accident>
          </el-tab-pane>
          <el-tab-pane label="应急" name="emergency">
            <emergency ref="emergency"></emergency>
          </el-tab-pane>
          <!-- <el-tab-pane label="作业票" name="homeworkTicket">
            <homeworkTicket ref="homeworkTicket"></homeworkTicket>
          </el-tab-pane> -->
        </el-tabs>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree";
import hiddenDanger from "./components/hiddenDanger";
import risk from "./components/risk";
import accident from "./components/accident";
import emergency from "./components/emergency";
import homeworkTicket from "./components/homeworkTicket";
import { deptChildTree } from "@/api/reportTemplate";

import { mapGetters } from "vuex";

export default {
  components: {
    CommonTree,
    hiddenDanger,
    risk,
    accident,
    emergency,
    homeworkTicket,
  },
  data() {
    return {
      treeDeptId: "",
      treeData: [],
      node: {}, //左侧树node
      searchTitle: "deptName",
      defaultProps: {
        children: "children",
        label: "title",
      },
      activeName: "hiddenDanger",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {
    // this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    // this.lazyDeptTree();
  },
  methods: {
    //获取树数据
    lazyDeptTree() {
      deptChildTree().then((res) => {
        this.treeData = [res.data.data];
        this.node = res.data.data;
        this.$nextTick(() => {
          this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
            this.node.id
          );
          this.treeNodeClick(this.node);
        });
      });
    },
    treeNodeClick(node) {
      this.node = node;
      this.handleClick();
    },
    handleClick() {
      this.$refs[this.activeName].init(this.node.id);
    },
  },
};
</script>
