var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              treeTitle: _vm.$t(`cip.plat.sys.user.title.treeTitle`),
              isShowdig: false,
              showCheckbox: false,
              "popover-show": true,
            },
            on: {
              getTreeAdd: _vm.getTreeAdd,
              getTreeEdit: _vm.getTreeEdit,
              getTreeDelete: _vm.getTreeDelete,
              getNodeClick: _vm.getNodeClick,
              "include-down": _vm.includeDown,
            },
          }),
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.headBtnOptions,
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                    },
                    on: {
                      "grid-enable": _vm.handleEnable,
                      "grid-disable": _vm.handleDisable,
                      "grid-import": _vm.handleImport,
                      "grid-export": _vm.handleExport,
                      "grid-unblock": _vm.handleLock,
                      "grid-roleConfig": _vm.handleGrant,
                      "grid-reset": _vm.handleReset,
                      "grid-platformConfig": _vm.handlePlatform,
                      "head-add": _vm.headAdd,
                      "head-romve": _vm.headRomve,
                      "batch-config": _vm.batchConfig,
                    },
                  }),
                  _c("grid-head-layout", {
                    ref: "searchFrom",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.searchChange,
                      "grid-head-empty": _vm.searchReset,
                    },
                    model: {
                      value: _vm.searchForm,
                      callback: function ($$v) {
                        _vm.searchForm = $$v
                      },
                      expression: "searchForm",
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "data-total": _vm.page.total,
                      page: _vm.page,
                      tableOptions: _vm.tableOptions,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                      searchColumns: _vm.searchColumns,
                      gridRowBtn: _vm.gridRowBtn,
                    },
                    on: {
                      "page-size-change": _vm.onLoad,
                      "page-current-change": _vm.onLoad,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                      "grid-edit": _vm.rowEdit,
                      "grid-romve": _vm.rowDel,
                      "role-config": _vm.roleConfig,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.roleConfigBatchDialog
            ? _c(
                "CommonDialog",
                {
                  attrs: {
                    showFullscreen: true,
                    dialogTitle: _vm.$t("cip.plat.sys.user.field.roleConfig"),
                    width: "80%",
                  },
                  on: { cancel: _vm.celRole, confirm: _vm.submitRole },
                },
                [
                  _vm.roleConfigBatchDialog
                    ? _c("roleConfigBatchDialog", {
                        ref: "roleConfigBatchDialogRef",
                        attrs: {
                          roleConfigRow: _vm.roleConfigRow,
                          type: _vm.type,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }