var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page,
            permission: _vm.permissionList,
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "row-del": _vm.rowDel,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _vm.userInfo.role_name.includes("administrator")
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-edit-outline",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleDesign(scope.row.name)
                            },
                          },
                        },
                        [_vm._v("设计\n      ")]
                      )
                    : _vm._e(),
                  _vm.userInfo.role_name.includes("administrator")
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-view",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handlePreview(scope.row.name)
                            },
                          },
                        },
                        [_vm._v("预览\n      ")]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "name",
              fn: function ({ row }) {
                return [
                  _c(
                    "el-tag",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.handlePreview(row.name)
                        },
                      },
                    },
                    [_vm._v(_vm._s(row.name))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-delete",
                    plain: "",
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v("删 除\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }