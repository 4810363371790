var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      staticClass: "qmDialog",
      attrs: {
        title: _vm.title,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        size: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-steps",
        { attrs: { active: _vm.activeName } },
        [
          _c("el-step", {
            attrs: { title: "输入源设置" },
            nativeOn: {
              click: function ($event) {
                return _vm.handleStepClick(1)
              },
            },
          }),
          _c("el-step", {
            attrs: { title: "任务设置" },
            nativeOn: {
              click: function ($event) {
                return _vm.handleStepClick(2)
              },
            },
          }),
          _c("el-step", {
            attrs: { title: "运行配置" },
            nativeOn: {
              click: function ($event) {
                return _vm.handleStepClick(3)
              },
            },
          }),
          _c("el-step", {
            attrs: { title: "输出配置" },
            nativeOn: {
              click: function ($event) {
                return _vm.handleStepClick(4)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-box" },
        [
          _c("avue-form", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName === 1,
                expression: "activeName === 1",
              },
            ],
            ref: "addForm",
            attrs: {
              option: {
                ..._vm.options,
                disabled: _vm.pageType == "view" ? true : false,
              },
            },
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          }),
          _c("avue-form", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName === 2,
                expression: "activeName === 2",
              },
            ],
            ref: "uploadFormRef",
            attrs: {
              option: {
                ..._vm.uploadOptions,
                disabled: _vm.pageType == "view" ? true : false,
              },
            },
            model: {
              value: _vm.uploadForm,
              callback: function ($$v) {
                _vm.uploadForm = $$v
              },
              expression: "uploadForm",
            },
          }),
          _vm.activeName === 3
            ? _c("runSetup", {
                ref: "runSetup",
                attrs: { url: _vm.videoUrl, runSetupData: _vm.runSetupData },
                on: { callback: _vm.getRunSetupData },
              })
            : _vm._e(),
          _c("avue-form", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName === 4,
                expression: "activeName === 4",
              },
            ],
            ref: "pushFormRef",
            attrs: {
              option: {
                ..._vm.pushOptions,
                disabled: _vm.pageType == "view" ? true : false,
              },
            },
            on: { submit: _vm.treeNodeSave },
            model: {
              value: _vm.pushForm,
              callback: function ($$v) {
                _vm.pushForm = $$v
              },
              expression: "pushForm",
            },
          }),
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.closeDialog },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _vm.activeName != 4
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.dialogLoading,
                          expression: "dialogLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.nextSave(+1)
                        },
                      },
                    },
                    [_vm._v("\n        下一步\n      ")]
                  )
                : _vm._e(),
              _vm.activeName != 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.dialogLoading,
                          expression: "dialogLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.backStep(-1)
                        },
                      },
                    },
                    [_vm._v("\n        上一步\n      ")]
                  )
                : _vm._e(),
              _vm.activeName == 4
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.dialogLoading,
                          expression: "dialogLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleTreeNodeSave },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }