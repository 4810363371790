<template>
  <div>
    <!-- 项目进出场日志 -->
    <head-layout
      head-title="项目进出场日志"
      :showIcon="false"
      :head-btn-options="headBtnOptions"
      @head-export="handleExport"
    ></head-layout>
    <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns"  @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      class="itemListGrid"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
    >
    </grid-layout>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { mapGetters } from "vuex";
import { exportBlob } from "@/api/common";
import { dateNow } from "@/util/date";
import { getToken } from "@/util/auth";
import { downloadXls } from "@/util/util";
import { getList } from "@/api/safe/projectEAExitLog";
export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      searchColumns: [
        {
          label: "",
          prop: "personnelName",
          span: 4,
          placeholder: "请输入人员姓名",
        },
      ],
      tableOption: {
        selection: false,
        menu: false,
        column: [
          {
            label: "人员",
            prop: "personnelName",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "组织",
            prop: "organizationName",
            align: "left",
            minWidth: 200,
            overHidden: true,
          },
          {
            label: "类型",
            prop: "wholeState",
            align: "center",
            dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=whole_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            minWidth: 115,
            overHidden: true,
          },
          {
            label: "进出场",
            prop: "changeState",
            align: "center",
            dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=eae_prj_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            minWidth: 80,
            overHidden: true,
          },
          {
            label: "进出场时间",
            prop: "changeDate",
            align: "center",
            minWidth: 170,
            overHidden: true,
          },
          {
            label: "变更人",
            prop: "createUserName",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "变更人组织",
            prop: "createDeptName",
            align: "left",
            minWidth: 200,
            overHidden: true,
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      prjId: "",
    };
  },
  computed: {
    ...mapGetters(["permission",'userInfo']),
    headBtnOptions() {
      if (this.$route.query.type != "view") {
        return [
          {
            label: "导出",
            emit: "head-export",
            type: "button",
            icon: "",
          },
        ];
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.prjId = this.userInfo.dept_id;
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    //   导出
    handleExport() {
      this.$confirm("是否导出项目进出场日志数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        exportBlob(
          `/api/sinoma-hse-prj/prjwholerecord/export?prjId=${
            this.prjId ? this.prjId : ""
          }&${this.website.tokenHeader}=${getToken()}`
        ).then((res) => {
          if (res.data) {
            downloadXls(res.data, `项目进出场日志${dateNow()}.xlsx`);
          }
        });
      });
    },
    // 筛选列表
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      let obj = {
        insideUserName:searchForm.personnelName
      }
      this.onLoad(this.page,obj)
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page)
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      params.prjId = this.prjId;
      getList({current:page.currentPage, size:page.pageSize, ...params}).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.tableData.forEach((ele) => {
            if (ele.wholeState == 1) {
              // 组织内部人员
              ele.personnelName = ele.insideUserName;
              ele.organizationName = ele.insideOrgName;
            } else {
              ele.personnelName = ele.partnerUserName;
              ele.organizationName = ele.partnerOrgName;
            }
          });
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .itemListGrid .avue-crud .el-table {
  height: calc(100vh - 350px) !important;
  max-height: calc(100vh - 350px) !important;
  box-sizing: border-box;
}
</style>
